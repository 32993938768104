import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './objects/FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemCreateInputObjectSchema } from './objects/FlashsaleItemCreateInput.schema'
import { FlashsaleItemUncheckedCreateInputObjectSchema } from './objects/FlashsaleItemUncheckedCreateInput.schema'
import { FlashsaleItemUpdateInputObjectSchema } from './objects/FlashsaleItemUpdateInput.schema'
import { FlashsaleItemUncheckedUpdateInputObjectSchema } from './objects/FlashsaleItemUncheckedUpdateInput.schema'

export const FlashsaleItemUpsertSchema = z.object({
  where: FlashsaleItemWhereUniqueInputObjectSchema,
  create: z.union([
    FlashsaleItemCreateInputObjectSchema,
    FlashsaleItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    FlashsaleItemUpdateInputObjectSchema,
    FlashsaleItemUncheckedUpdateInputObjectSchema,
  ]),
})
