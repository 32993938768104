import { z } from 'zod'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'
import { CouponGroupCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupCreateWithoutDiscountSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutDiscountSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCreateOrConnectWithoutDiscountSettingInput> =
  z
    .object({
      where: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CouponGroupCreateWithoutDiscountSettingInputObjectSchema),
        z.lazy(
          () =>
            CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CouponGroupCreateOrConnectWithoutDiscountSettingInputObjectSchema =
  Schema
