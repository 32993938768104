import { z } from 'zod'
import { DeliveryArgsObjectSchema } from './DeliveryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    delivery: z
      .union([z.boolean(), z.lazy(() => DeliveryArgsObjectSchema)])
      .optional(),
    deliveryId: z.boolean().optional(),
    statusCode: z.boolean().optional(),
    statusText: z.boolean().optional(),
    statusDate: z.boolean().optional(),
    rawData: z.boolean().optional(),
  })
  .strict()

export const DeliveryLogSelectObjectSchema = Schema
