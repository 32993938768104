import { z } from 'zod'
import { AppointmentCreateWithoutTeleconsultInputObjectSchema } from './AppointmentCreateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedCreateWithoutTeleconsultInput.schema'
import { AppointmentCreateOrConnectWithoutTeleconsultInputObjectSchema } from './AppointmentCreateOrConnectWithoutTeleconsultInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateNestedOneWithoutTeleconsultInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AppointmentCreateWithoutTeleconsultInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => AppointmentCreateOrConnectWithoutTeleconsultInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const AppointmentCreateNestedOneWithoutTeleconsultInputObjectSchema =
  Schema
