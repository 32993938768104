import { z } from 'zod'
import { ProcedureItemOrderByWithRelationInputObjectSchema } from './objects/ProcedureItemOrderByWithRelationInput.schema'
import { ProcedureItemWhereInputObjectSchema } from './objects/ProcedureItemWhereInput.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './objects/ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemCountAggregateInputObjectSchema } from './objects/ProcedureItemCountAggregateInput.schema'
import { ProcedureItemMinAggregateInputObjectSchema } from './objects/ProcedureItemMinAggregateInput.schema'
import { ProcedureItemMaxAggregateInputObjectSchema } from './objects/ProcedureItemMaxAggregateInput.schema'
import { ProcedureItemAvgAggregateInputObjectSchema } from './objects/ProcedureItemAvgAggregateInput.schema'
import { ProcedureItemSumAggregateInputObjectSchema } from './objects/ProcedureItemSumAggregateInput.schema'

export const ProcedureItemAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureItemOrderByWithRelationInputObjectSchema,
      ProcedureItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureItemWhereInputObjectSchema.optional(),
  cursor: ProcedureItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureItemCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureItemMinAggregateInputObjectSchema.optional(),
  _max: ProcedureItemMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureItemAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureItemSumAggregateInputObjectSchema.optional(),
})
