import { z } from 'zod'
import { ProcedureSelectObjectSchema } from './ProcedureSelect.schema'
import { ProcedureIncludeObjectSchema } from './ProcedureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureArgs> = z
  .object({
    select: z.lazy(() => ProcedureSelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureArgsObjectSchema = Schema
