import { z } from 'zod'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumPaymentMethodFilterObjectSchema } from './NestedEnumPaymentMethodFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumPaymentMethodWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => PaymentMethodSchema).optional(),
    in: z
      .lazy(() => PaymentMethodSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PaymentMethodSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PaymentMethodSchema),
        z.lazy(() => NestedEnumPaymentMethodWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumPaymentMethodFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumPaymentMethodFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumPaymentMethodWithAggregatesFilterObjectSchema = Schema
