import { z } from 'zod'
import { FlashsaleItemCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateWithoutProcedureInput.schema'
import { FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutProcedureInput.schema'
import { FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutProcedureInput.schema'
import { FlashsaleItemCreateManyProcedureInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyProcedureInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateNestedManyWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemCreateNestedManyWithoutProcedureInputObjectSchema =
  Schema
