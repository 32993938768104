import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalMaxAggregateInputType> =
  z
    .object({
      id: z.literal(true).optional(),
      createdAt: z.literal(true).optional(),
      updatedAt: z.literal(true).optional(),
      productVariantId: z.literal(true).optional(),
      amount: z.literal(true).optional(),
      usage: z.literal(true).optional(),
      useAmount: z.literal(true).optional(),
      useUnit: z.literal(true).optional(),
      useFrequency: z.literal(true).optional(),
      useTime: z.literal(true).optional(),
      useDuration: z.literal(true).optional(),
      useDurationUnit: z.literal(true).optional(),
      refillTime: z.literal(true).optional(),
      refillTimeUnit: z.literal(true).optional(),
      note: z.literal(true).optional(),
      caseSummaryId: z.literal(true).optional(),
    })
    .strict()

export const SuggestedProductItemOriginalMaxAggregateInputObjectSchema = Schema
