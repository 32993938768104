import { z } from 'zod'
import { OfficeHourScalarWhereInputObjectSchema } from './OfficeHourScalarWhereInput.schema'
import { OfficeHourUpdateManyMutationInputObjectSchema } from './OfficeHourUpdateManyMutationInput.schema'
import { OfficeHourUncheckedUpdateManyWithoutOfficeHoursInputObjectSchema } from './OfficeHourUncheckedUpdateManyWithoutOfficeHoursInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUpdateManyWithWhereWithoutBranchInput> =
  z
    .object({
      where: z.lazy(() => OfficeHourScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => OfficeHourUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            OfficeHourUncheckedUpdateManyWithoutOfficeHoursInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OfficeHourUpdateManyWithWhereWithoutBranchInputObjectSchema =
  Schema
