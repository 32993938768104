import { z } from 'zod'
import { RejectReasonCreateManyKycDataInputObjectSchema } from './RejectReasonCreateManyKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateManyKycDataInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => RejectReasonCreateManyKycDataInputObjectSchema),
      z.lazy(() => RejectReasonCreateManyKycDataInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const RejectReasonCreateManyKycDataInputEnvelopeObjectSchema = Schema
