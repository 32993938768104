import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedurePictureOrderByRelationAggregateInputObjectSchema } from './ProcedurePictureOrderByRelationAggregateInput.schema'
import { ProcedureCategoryOrderByRelationAggregateInputObjectSchema } from './ProcedureCategoryOrderByRelationAggregateInput.schema'
import { FlashsaleItemOrderByRelationAggregateInputObjectSchema } from './FlashsaleItemOrderByRelationAggregateInput.schema'
import { CarouselItemOrderByRelationAggregateInputObjectSchema } from './CarouselItemOrderByRelationAggregateInput.schema'
import { ProcedureReviewOrderByRelationAggregateInputObjectSchema } from './ProcedureReviewOrderByRelationAggregateInput.schema'
import { ProcedureReviewSummaryOrderByWithRelationInputObjectSchema } from './ProcedureReviewSummaryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    searchable: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    technologyTr: z.lazy(() => SortOrderSchema).optional(),
    usageTr: z.lazy(() => SortOrderSchema).optional(),
    resultPeriodTr: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    preparationTr: z.lazy(() => SortOrderSchema).optional(),
    conditionTr: z.lazy(() => SortOrderSchema).optional(),
    methodOfUseTr: z.lazy(() => SortOrderSchema).optional(),
    isTreatedByDoctor: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    expireType: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    expireDurationDay: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    branches: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deletedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    pictures: z
      .lazy(() => ProcedurePictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
    categories: z
      .lazy(() => ProcedureCategoryOrderByRelationAggregateInputObjectSchema)
      .optional(),
    flashsaleItem: z
      .lazy(() => FlashsaleItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    carouselItems: z
      .lazy(() => CarouselItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    procedureReviews: z
      .lazy(() => ProcedureReviewOrderByRelationAggregateInputObjectSchema)
      .optional(),
    procedureReviewSummary: z
      .lazy(() => ProcedureReviewSummaryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureOrderByWithRelationInputObjectSchema = Schema
