import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    groupId: z.literal(true).optional(),
  })
  .strict()

export const ConditionSettingAvgAggregateInputObjectSchema = Schema
