import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CasePictureCountOrderByAggregateInputObjectSchema } from './CasePictureCountOrderByAggregateInput.schema'
import { CasePictureAvgOrderByAggregateInputObjectSchema } from './CasePictureAvgOrderByAggregateInput.schema'
import { CasePictureMaxOrderByAggregateInputObjectSchema } from './CasePictureMaxOrderByAggregateInput.schema'
import { CasePictureMinOrderByAggregateInputObjectSchema } from './CasePictureMinOrderByAggregateInput.schema'
import { CasePictureSumOrderByAggregateInputObjectSchema } from './CasePictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    pictureUrl: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CasePictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CasePictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CasePictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CasePictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CasePictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CasePictureOrderByWithAggregationInputObjectSchema = Schema
