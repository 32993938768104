import { z } from 'zod'
import { ProcedureItemCreateWithoutRefundInputObjectSchema } from './ProcedureItemCreateWithoutRefundInput.schema'
import { ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedCreateWithoutRefundInput.schema'
import { ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema } from './ProcedureItemCreateOrConnectWithoutRefundInput.schema'
import { ProcedureItemCreateManyRefundInputEnvelopeObjectSchema } from './ProcedureItemCreateManyRefundInputEnvelope.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemCreateNestedManyWithoutRefundInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureItemCreateWithoutRefundInputObjectSchema),
          z
            .lazy(() => ProcedureItemCreateWithoutRefundInputObjectSchema)
            .array(),
          z.lazy(
            () => ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureItemCreateManyRefundInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureItemCreateNestedManyWithoutRefundInputObjectSchema =
  Schema
