import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    code: z.literal(true).optional(),
    type: z.literal(true).optional(),
    discount: z.literal(true).optional(),
    discountType: z.literal(true).optional(),
    isPublic: z.literal(true).optional(),
    isOnlyNewPatient: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    expireAt: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    usedAt: z.literal(true).optional(),
    groupId: z.literal(true).optional(),
  })
  .strict()

export const CouponMaxAggregateInputObjectSchema = Schema
