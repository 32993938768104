import { z } from 'zod'
import { ProcedurePictureUpdateInputObjectSchema } from './objects/ProcedurePictureUpdateInput.schema'
import { ProcedurePictureUncheckedUpdateInputObjectSchema } from './objects/ProcedurePictureUncheckedUpdateInput.schema'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './objects/ProcedurePictureWhereUniqueInput.schema'

export const ProcedurePictureUpdateOneSchema = z.object({
  data: z.union([
    ProcedurePictureUpdateInputObjectSchema,
    ProcedurePictureUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedurePictureWhereUniqueInputObjectSchema,
})
