import { z } from 'zod'
import { ArticlePictureSelectObjectSchema } from './ArticlePictureSelect.schema'
import { ArticlePictureIncludeObjectSchema } from './ArticlePictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureArgs> = z
  .object({
    select: z.lazy(() => ArticlePictureSelectObjectSchema).optional(),
    include: z.lazy(() => ArticlePictureIncludeObjectSchema).optional(),
  })
  .strict()

export const ArticlePictureArgsObjectSchema = Schema
