import { z } from 'zod'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalCreateOrConnectWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema),
        z.lazy(
          () => ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema =
  Schema
