import { z } from 'zod'
import { OfficeHourFindManySchema } from '../findManyOfficeHour.schema'
import { PartnerArgsObjectSchema } from './PartnerArgs.schema'
import { BranchCountOutputTypeArgsObjectSchema } from './BranchCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    address: z.boolean().optional(),
    subDistrict: z.boolean().optional(),
    subDistrictKey: z.boolean().optional(),
    district: z.boolean().optional(),
    districtKey: z.boolean().optional(),
    province: z.boolean().optional(),
    provinceKey: z.boolean().optional(),
    postcode: z.boolean().optional(),
    googleMapUrl: z.boolean().optional(),
    lat: z.boolean().optional(),
    long: z.boolean().optional(),
    phone: z.boolean().optional(),
    active: z.boolean().optional(),
    isExpertise: z.boolean().optional(),
    officeHours: z
      .union([z.boolean(), z.lazy(() => OfficeHourFindManySchema)])
      .optional(),
    remark: z.boolean().optional(),
    partner: z
      .union([z.boolean(), z.lazy(() => PartnerArgsObjectSchema)])
      .optional(),
    partnerId: z.boolean().optional(),
    v2BranchId: z.boolean().optional(),
    _count: z
      .union([z.boolean(), z.lazy(() => BranchCountOutputTypeArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const BranchSelectObjectSchema = Schema
