import { Box } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  Pagination,
  TextField,
} from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'

const rowsPerPage = [5, 10, 20]

const NotificationListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}
export const NotificationList = () => (
  <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
    <List
      disableSyncWithLocation
      perPage={rowsPerPage[2]}
      pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
      actions={<NotificationListAction />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        size="small"
        rowClick={(_id, _resource, record) => {
          if (record.onesignalMessageId) {
            window.open(
              `https://dashboard.onesignal.com/apps/${
                import.meta.env.NX_ONESIGNAL_PATIENT_APP_ID
              }/notifications/${record.onesignalMessageId}`,
              '_blank',
            )
          }
          return false
        }}
      >
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="message" />
        <LocaleDateField source="startAt" label="Sent At" showTime />
      </Datagrid>
    </List>
  </Box>
)
