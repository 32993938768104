import { z } from 'zod'
import { RejectReasonSelectObjectSchema } from './RejectReasonSelect.schema'
import { RejectReasonIncludeObjectSchema } from './RejectReasonInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonArgs> = z
  .object({
    select: z.lazy(() => RejectReasonSelectObjectSchema).optional(),
    include: z.lazy(() => RejectReasonIncludeObjectSchema).optional(),
  })
  .strict()

export const RejectReasonArgsObjectSchema = Schema
