import { z } from 'zod'
import { KycDataWhereInputObjectSchema } from './objects/KycDataWhereInput.schema'
import { KycDataOrderByWithAggregationInputObjectSchema } from './objects/KycDataOrderByWithAggregationInput.schema'
import { KycDataScalarWhereWithAggregatesInputObjectSchema } from './objects/KycDataScalarWhereWithAggregatesInput.schema'
import { KycDataScalarFieldEnumSchema } from './enums/KycDataScalarFieldEnum.schema'

export const KycDataGroupBySchema = z.object({
  where: KycDataWhereInputObjectSchema.optional(),
  orderBy: z.union([
    KycDataOrderByWithAggregationInputObjectSchema,
    KycDataOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: KycDataScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(KycDataScalarFieldEnumSchema),
})
