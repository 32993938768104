import { z } from 'zod'
import { PatientCreateWithoutCoinTransactionsInputObjectSchema } from './PatientCreateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedCreateWithoutCoinTransactionsInput.schema'
import { PatientCreateOrConnectWithoutCoinTransactionsInputObjectSchema } from './PatientCreateOrConnectWithoutCoinTransactionsInput.schema'
import { PatientUpsertWithoutCoinTransactionsInputObjectSchema } from './PatientUpsertWithoutCoinTransactionsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutCoinTransactionsInputObjectSchema } from './PatientUpdateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedUpdateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedUpdateWithoutCoinTransactionsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutCoinTransactionsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutCoinTransactionsInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => PatientCreateOrConnectWithoutCoinTransactionsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutCoinTransactionsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutCoinTransactionsInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedUpdateWithoutCoinTransactionsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutCoinTransactionsNestedInputObjectSchema =
  Schema
