import { z } from 'zod'
import { HolidaySelectObjectSchema } from './HolidaySelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.HolidayArgs> = z
  .object({
    select: z.lazy(() => HolidaySelectObjectSchema).optional(),
  })
  .strict()

export const HolidayArgsObjectSchema = Schema
