import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantOrderByWithRelationInputObjectSchema } from './VariantOrderByWithRelationInput.schema'
import { WarehouseOrderByWithRelationInputObjectSchema } from './WarehouseOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    warehouseId: z.lazy(() => SortOrderSchema).optional(),
    variant: z
      .lazy(() => VariantOrderByWithRelationInputObjectSchema)
      .optional(),
    warehouse: z
      .lazy(() => WarehouseOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const VariantWarehouseOrderByWithRelationInputObjectSchema = Schema
