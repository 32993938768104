import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpBlacklistCreateManyInput> = z
  .object({
    id: z.number().optional(),
    ipAddress: z.string(),
    createdAt: z.date().optional(),
    expiredAt: z.date(),
  })
  .strict()

export const OtpBlacklistCreateManyInputObjectSchema = Schema
