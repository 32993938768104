import { z } from 'zod'
import { ProductItemCreateNestedManyWithoutDeliveryInputObjectSchema } from './ProductItemCreateNestedManyWithoutDeliveryInput.schema'
import { DeliveryLogCreateNestedManyWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateNestedManyWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateWithoutOrderInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    shippingProvider: z.string(),
    trackingNumber: z.string(),
    status: z.string(),
    deliveredAt: z.date().optional().nullable(),
    partnerName: z.string(),
    productItems: z
      .lazy(() => ProductItemCreateNestedManyWithoutDeliveryInputObjectSchema)
      .optional(),
    deliveryLogs: z
      .lazy(() => DeliveryLogCreateNestedManyWithoutDeliveryInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryCreateWithoutOrderInputObjectSchema = Schema
