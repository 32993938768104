import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithoutBrandInputObjectSchema } from './ProductReviewUpdateWithoutBrandInput.schema'
import { ProductReviewUncheckedUpdateWithoutBrandInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateWithWhereUniqueWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewUpdateWithoutBrandInputObjectSchema),
        z.lazy(() => ProductReviewUncheckedUpdateWithoutBrandInputObjectSchema),
      ]),
    })
    .strict()

export const ProductReviewUpdateWithWhereUniqueWithoutBrandInputObjectSchema =
  Schema
