import { z } from 'zod'
import { OldPatientOrderByWithRelationInputObjectSchema } from './objects/OldPatientOrderByWithRelationInput.schema'
import { OldPatientWhereInputObjectSchema } from './objects/OldPatientWhereInput.schema'
import { OldPatientWhereUniqueInputObjectSchema } from './objects/OldPatientWhereUniqueInput.schema'
import { OldPatientCountAggregateInputObjectSchema } from './objects/OldPatientCountAggregateInput.schema'
import { OldPatientMinAggregateInputObjectSchema } from './objects/OldPatientMinAggregateInput.schema'
import { OldPatientMaxAggregateInputObjectSchema } from './objects/OldPatientMaxAggregateInput.schema'
import { OldPatientAvgAggregateInputObjectSchema } from './objects/OldPatientAvgAggregateInput.schema'
import { OldPatientSumAggregateInputObjectSchema } from './objects/OldPatientSumAggregateInput.schema'

export const OldPatientAggregateSchema = z.object({
  orderBy: z
    .union([
      OldPatientOrderByWithRelationInputObjectSchema,
      OldPatientOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OldPatientWhereInputObjectSchema.optional(),
  cursor: OldPatientWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), OldPatientCountAggregateInputObjectSchema])
    .optional(),
  _min: OldPatientMinAggregateInputObjectSchema.optional(),
  _max: OldPatientMaxAggregateInputObjectSchema.optional(),
  _avg: OldPatientAvgAggregateInputObjectSchema.optional(),
  _sum: OldPatientSumAggregateInputObjectSchema.optional(),
})
