import { z } from 'zod'
import { FlashsaleItemScalarWhereInputObjectSchema } from './FlashsaleItemScalarWhereInput.schema'
import { FlashsaleItemUpdateManyMutationInputObjectSchema } from './FlashsaleItemUpdateManyMutationInput.schema'
import { FlashsaleItemUncheckedUpdateManyWithoutItemsInputObjectSchema } from './FlashsaleItemUncheckedUpdateManyWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => FlashsaleItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateManyWithoutItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInputObjectSchema =
  Schema
