import { z } from 'zod'
import { HealthInfoSelectObjectSchema } from './HealthInfoSelect.schema'
import { HealthInfoIncludeObjectSchema } from './HealthInfoInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoArgs> = z
  .object({
    select: z.lazy(() => HealthInfoSelectObjectSchema).optional(),
    include: z.lazy(() => HealthInfoIncludeObjectSchema).optional(),
  })
  .strict()

export const HealthInfoArgsObjectSchema = Schema
