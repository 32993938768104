import { OpenInNew } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import jsonExport from 'jsonexport/dist'
import { FC, useState } from 'react'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  ImageField,
  List,
  TextField,
  downloadCSV,
} from 'react-admin'
import { CustomButton } from '../../components/CustomButton'
import { CustomBooleanField } from '../../components/CustomField'
import { ImportBrandModal } from './importBrand/importModal'
import { FilterListForm } from './list-section/filterlistForm'

const exporter = (brands: object | object[]) => {
  const BOM = '\uFEFF'

  jsonExport(brands, (err, csv) => {
    downloadCSV(`${BOM}${csv}`, 'product-brands')
    if (err) {
      console.log('Error trying to export list')
    }
  })
}

const BrandListAction: FC<{
  onOpenImportModal: (state: boolean) => void
}> = ({ onOpenImportModal }) => {
  const handleImportClick = () => {
    onOpenImportModal(true)
  }
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <CustomButton
          onClick={handleImportClick}
          label="resources.productBrand.action.import_button"
        />
        <ExportButton />
      </Box>
    </Box>
  )
}
export const ProductBrandList = () => {
  const [openImportModal, setOpenImportModal] = useState<boolean>(false)
  const handleOpenImportModal = (state: boolean) => {
    setOpenImportModal(state)
  }

  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        actions={<BrandListAction onOpenImportModal={handleOpenImportModal} />}
        sort={{ field: 'priority', order: 'DESC' }}
        exporter={exporter}
      >
        <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <ImageField
            source="logo.src"
            sx={{
              textAlign: 'center',
              '& img': {
                width: 'auto !important',
                height: 'auto !important',
                maxWidth: '100px !important',
              },
            }}
            textAlign="center"
            sortable={false}
          />
          <TextField source="name" />
          <CustomBooleanField source="isShow" />
          <TextField source="priority" />
          <TextField source="coporateName" />
          <TextField source="countryName" />
          <FunctionField
            label="Action"
            render={(record: { id: number }) => (
              <IconButton
                onClick={() => {
                  window.open(`/productBrand/${record.id}`, '_blank')
                }}
              >
                <OpenInNew />
              </IconButton>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
      <ImportBrandModal
        open={openImportModal}
        onClose={() => {
          setOpenImportModal(false)
        }}
        disableCloseOnBackdrop
      />
    </Box>
  )
}
