import { z } from 'zod'
import { VariantScalarWhereInputObjectSchema } from './VariantScalarWhereInput.schema'
import { VariantUpdateManyMutationInputObjectSchema } from './VariantUpdateManyMutationInput.schema'
import { VariantUncheckedUpdateManyWithoutVariantsInputObjectSchema } from './VariantUncheckedUpdateManyWithoutVariantsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpdateManyWithWhereWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => VariantScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => VariantUncheckedUpdateManyWithoutVariantsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantUpdateManyWithWhereWithoutProductInputObjectSchema = Schema
