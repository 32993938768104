import { z } from 'zod'
import { OrderCreateNestedOneWithoutDeliveriesInputObjectSchema } from './OrderCreateNestedOneWithoutDeliveriesInput.schema'
import { ProductItemCreateNestedManyWithoutDeliveryInputObjectSchema } from './ProductItemCreateNestedManyWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateWithoutDeliveryLogsInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    shippingProvider: z.string(),
    trackingNumber: z.string(),
    status: z.string(),
    deliveredAt: z.date().optional().nullable(),
    partnerName: z.string(),
    order: z.lazy(() => OrderCreateNestedOneWithoutDeliveriesInputObjectSchema),
    productItems: z
      .lazy(() => ProductItemCreateNestedManyWithoutDeliveryInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryCreateWithoutDeliveryLogsInputObjectSchema = Schema
