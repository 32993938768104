import { z } from 'zod'
import { WaitingBannerOrderByWithRelationInputObjectSchema } from './objects/WaitingBannerOrderByWithRelationInput.schema'
import { WaitingBannerWhereInputObjectSchema } from './objects/WaitingBannerWhereInput.schema'
import { WaitingBannerWhereUniqueInputObjectSchema } from './objects/WaitingBannerWhereUniqueInput.schema'
import { WaitingBannerCountAggregateInputObjectSchema } from './objects/WaitingBannerCountAggregateInput.schema'
import { WaitingBannerMinAggregateInputObjectSchema } from './objects/WaitingBannerMinAggregateInput.schema'
import { WaitingBannerMaxAggregateInputObjectSchema } from './objects/WaitingBannerMaxAggregateInput.schema'
import { WaitingBannerAvgAggregateInputObjectSchema } from './objects/WaitingBannerAvgAggregateInput.schema'
import { WaitingBannerSumAggregateInputObjectSchema } from './objects/WaitingBannerSumAggregateInput.schema'

export const WaitingBannerAggregateSchema = z.object({
  orderBy: z
    .union([
      WaitingBannerOrderByWithRelationInputObjectSchema,
      WaitingBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: WaitingBannerWhereInputObjectSchema.optional(),
  cursor: WaitingBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), WaitingBannerCountAggregateInputObjectSchema])
    .optional(),
  _min: WaitingBannerMinAggregateInputObjectSchema.optional(),
  _max: WaitingBannerMaxAggregateInputObjectSchema.optional(),
  _avg: WaitingBannerAvgAggregateInputObjectSchema.optional(),
  _sum: WaitingBannerSumAggregateInputObjectSchema.optional(),
})
