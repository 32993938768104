import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutSuggestedProductItemsInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryCreateOrConnectWithoutSuggestedProductItemsInputObjectSchema =
  Schema
