import { z } from 'zod'
import { DiagnosisOrderByWithRelationInputObjectSchema } from './objects/DiagnosisOrderByWithRelationInput.schema'
import { DiagnosisWhereInputObjectSchema } from './objects/DiagnosisWhereInput.schema'
import { DiagnosisWhereUniqueInputObjectSchema } from './objects/DiagnosisWhereUniqueInput.schema'
import { DiagnosisScalarFieldEnumSchema } from './enums/DiagnosisScalarFieldEnum.schema'

export const DiagnosisFindManySchema = z.object({
  orderBy: z
    .union([
      DiagnosisOrderByWithRelationInputObjectSchema,
      DiagnosisOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DiagnosisWhereInputObjectSchema.optional(),
  cursor: DiagnosisWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(DiagnosisScalarFieldEnumSchema).optional(),
})
