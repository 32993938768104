import { z } from 'zod'
import { ProcedurePictureCreateManyProcedureInputObjectSchema } from './ProcedurePictureCreateManyProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureCreateManyProcedureInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => ProcedurePictureCreateManyProcedureInputObjectSchema),
        z
          .lazy(() => ProcedurePictureCreateManyProcedureInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProcedurePictureCreateManyProcedureInputEnvelopeObjectSchema =
  Schema
