import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { Branch } from './create-edit-section/branch'
import { PartnerCoverPicture } from './create-edit-section/partnerCoverPicture'
import { PartnerDescription } from './create-edit-section/partnerDescription'
import { PartnerInfo } from './create-edit-section/partnerInfo'
import { transformPartner } from './transform'

const PartnerEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerEditForm = () => {
  return (
    <SimpleForm toolbar={<PartnerEditToolbar />}>
      <PartnerInfo />
      <PartnerDescription />
      <PartnerCoverPicture />
    </SimpleForm>
  )
}

export const PartnerEdit = () => (
  <EditSaveRouteBack
    transform={transformPartner}
    component="div"
    redirect="list"
    mutationMode="pessimistic"
  >
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <PartnerEditForm />
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ p: 2 }}>
          <Box sx={{ p: 2 }}>
            <Branch />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </EditSaveRouteBack>
)
