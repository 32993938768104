import { z } from 'zod'
import { ConfigurationSelectObjectSchema } from './ConfigurationSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationArgs> = z
  .object({
    select: z.lazy(() => ConfigurationSelectObjectSchema).optional(),
  })
  .strict()

export const ConfigurationArgsObjectSchema = Schema
