import { z } from 'zod'
import { ProductReviewSummaryCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutProductInput.schema'
import { ProductReviewSummaryCreateOrConnectWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateOrConnectWithoutProductInput.schema'
import { ProductReviewSummaryUpsertWithoutProductInputObjectSchema } from './ProductReviewSummaryUpsertWithoutProductInput.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryUpdateWithoutProductInputObjectSchema } from './ProductReviewSummaryUpdateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedUpdateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedUpdateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUpdateOneWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductReviewSummaryCreateWithoutProductInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductReviewSummaryCreateOrConnectWithoutProductInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProductReviewSummaryUpsertWithoutProductInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z
        .lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(
            () => ProductReviewSummaryUpdateWithoutProductInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductReviewSummaryUncheckedUpdateWithoutProductInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewSummaryUpdateOneWithoutProductNestedInputObjectSchema =
  Schema
