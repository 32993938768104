import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteProcedurePicture, RelatedProcedurePictureModel, CompleteProcedureCategory, RelatedProcedureCategoryModel, CompleteFlashsaleItem, RelatedFlashsaleItemModel, CompleteCarouselItem, RelatedCarouselItemModel, CompleteProcedureReview, RelatedProcedureReviewModel, CompleteProcedureReviewSummary, RelatedProcedureReviewSummaryModel } from "./index"

export const ProcedureModel = z.object({
  id: z.number().int(),
  active: z.boolean(),
  searchable: z.boolean(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  searchField: z.string(),
  technologyTr: z.object({ en: z.string(), th: z.string() }),
  usageTr: z.object({ en: z.string(), th: z.string() }),
  resultPeriodTr: z.object({ en: z.string(), th: z.string() }),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  preparationTr: z.object({ en: z.string(), th: z.string() }),
  conditionTr: z.object({ en: z.string(), th: z.string() }),
  methodOfUseTr: z.object({ en: z.string(), th: z.string() }),
  isTreatedByDoctor: z.boolean(),
  priority: z.number().int(),
  expireType: z.enum(['DATE', 'DURATION']),
  expireAt: z.date().nullish(),
  expireDurationDay: z.number().int().nullish(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  cost: z.number(),
  commissionRate: z.number(),
  partnerId: z.number().int(),
  branches: jsonSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  v2DrugItemId: z.number().int().nullish(),
})

export const ProcedureModelResponse = z.object({
  id: z.number().int(),
  active: z.boolean(),
  searchable: z.boolean(),
  nameTr: jsonSchema,
  searchField: z.string(),
  technologyTr: jsonSchema,
  usageTr: jsonSchema,
  resultPeriodTr: jsonSchema,
  descriptionTr: jsonSchema,
  preparationTr: jsonSchema,
  conditionTr: jsonSchema,
  methodOfUseTr: jsonSchema,
  isTreatedByDoctor: z.boolean(),
  priority: z.number().int(),
  expireType: z.enum(['DATE', 'DURATION']),
  expireAt: z.date().nullish(),
  expireDurationDay: z.number().int().nullish(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  cost: z.number(),
  commissionRate: z.number(),
  partnerId: z.number().int(),
  branches: jsonSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  v2DrugItemId: z.number().int().nullish(),
})

export interface CompleteProcedure extends z.infer<typeof ProcedureModel> {
  pictures: CompleteProcedurePicture[]
  categories: CompleteProcedureCategory[]
  flashsaleItem: CompleteFlashsaleItem[]
  carouselItems: CompleteCarouselItem[]
  procedureReviews: CompleteProcedureReview[]
  procedureReviewSummary?: CompleteProcedureReviewSummary | null
}

/**
 * RelatedProcedureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureModel: z.ZodSchema<CompleteProcedure> = z.lazy(() => ProcedureModel.extend({
  pictures: RelatedProcedurePictureModel.array(),
  categories: RelatedProcedureCategoryModel.array(),
  flashsaleItem: RelatedFlashsaleItemModel.array(),
  carouselItems: RelatedCarouselItemModel.array(),
  procedureReviews: RelatedProcedureReviewModel.array(),
  procedureReviewSummary: RelatedProcedureReviewSummaryModel.nullish(),
}))
