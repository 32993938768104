import { z } from 'zod'
import { ProductPictureWhereUniqueInputObjectSchema } from './objects/ProductPictureWhereUniqueInput.schema'
import { ProductPictureCreateInputObjectSchema } from './objects/ProductPictureCreateInput.schema'
import { ProductPictureUncheckedCreateInputObjectSchema } from './objects/ProductPictureUncheckedCreateInput.schema'
import { ProductPictureUpdateInputObjectSchema } from './objects/ProductPictureUpdateInput.schema'
import { ProductPictureUncheckedUpdateInputObjectSchema } from './objects/ProductPictureUncheckedUpdateInput.schema'

export const ProductPictureUpsertSchema = z.object({
  where: ProductPictureWhereUniqueInputObjectSchema,
  create: z.union([
    ProductPictureCreateInputObjectSchema,
    ProductPictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductPictureUpdateInputObjectSchema,
    ProductPictureUncheckedUpdateInputObjectSchema,
  ]),
})
