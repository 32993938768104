import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    gender: z.lazy(() => SortOrderSchema).optional(),
    birthdate: z.lazy(() => SortOrderSchema).optional(),
    drugAllergy: z.lazy(() => SortOrderSchema).optional(),
    congenitalDisease: z.lazy(() => SortOrderSchema).optional(),
    medication: z.lazy(() => SortOrderSchema).optional(),
    pregnancyState: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const HealthInfoMinOrderByAggregateInputObjectSchema = Schema
