import {
  CheckCircle,
  ExpandMore as ExpandMoreIcon,
  HighlightOff,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useState } from 'react'
import { useLocaleState } from 'react-admin'
import { VariantModelWithProduct } from './variantInfo'

interface ExpandVariantProps extends IconButtonProps {
  expand: boolean
}

const ExpandVariant = styled((props: ExpandVariantProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const VariantDetailCard: FC<{ variant: VariantModelWithProduct }> = ({
  variant,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const [locale] = useLocaleState()
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Card>
      <CardHeader
        subheader="Variant detail"
        action={
          <ExpandVariant
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandVariant>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={12}>
              <List dense={true} disablePadding>
                <ListItem>
                  <ListItemIcon>ID</ListItemIcon>
                  <ListItemText primary={variant.id} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>SKU</ListItemIcon>
                  <ListItemText primary={variant.sku} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>Cost</ListItemIcon>
                  <ListItemText primary={variant.cost} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>Price</ListItemIcon>
                  <ListItemText primary={variant.sellingPrice} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>Tag price</ListItemIcon>
                  <ListItemText
                    primary={variant.tagPrice || '-'}
                    sx={{ paddingLeft: 1 }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>Description</ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={getTr(variant.descriptionTr, locale as Language)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>Active</ListItemIcon>
                  <ListItemText
                    sx={{ display: 'flex', alignItems: 'center' }}
                    disableTypography
                  >
                    {variant.active ? (
                      <CheckCircle color="success" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>Express shipping</ListItemIcon>
                  <ListItemText
                    sx={{ display: 'flex', alignItems: 'center' }}
                    disableTypography
                  >
                    {variant.canExpress ? (
                      <CheckCircle color="success" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>Product name</ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      variant.product?.nameTr
                        ? getTr(variant.product.nameTr, locale as Language)
                        : '-'
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            {variant.pictures && variant.pictures.length && (
              <Grid item xs={6} md={12} display="flex" justifyContent="center">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: { xs: 350, md: 250 },
                    objectFit: 'contain',
                  }}
                  justifySelf="center"
                  alt={variant.sku}
                  src={variant.pictures[0].src}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}
