import { z } from 'zod'
import { OtpBlacklistOrderByWithRelationInputObjectSchema } from './objects/OtpBlacklistOrderByWithRelationInput.schema'
import { OtpBlacklistWhereInputObjectSchema } from './objects/OtpBlacklistWhereInput.schema'
import { OtpBlacklistWhereUniqueInputObjectSchema } from './objects/OtpBlacklistWhereUniqueInput.schema'
import { OtpBlacklistScalarFieldEnumSchema } from './enums/OtpBlacklistScalarFieldEnum.schema'

export const OtpBlacklistFindManySchema = z.object({
  orderBy: z
    .union([
      OtpBlacklistOrderByWithRelationInputObjectSchema,
      OtpBlacklistOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OtpBlacklistWhereInputObjectSchema.optional(),
  cursor: OtpBlacklistWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(OtpBlacklistScalarFieldEnumSchema).optional(),
})
