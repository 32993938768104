import { z } from 'zod'
import { ProcedureCategoryCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateWithoutProcedureInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedureCategoryCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedureCategoryCreateManyProcedureInputEnvelope.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedureCategoryUpdateManyWithWhereWithoutProcedureInputObjectSchema } from './ProcedureCategoryUpdateManyWithWhereWithoutProcedureInput.schema'
import { ProcedureCategoryScalarWhereInputObjectSchema } from './ProcedureCategoryScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUncheckedUpdateManyWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProcedureCategoryCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureCategoryCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedureCategoryCreateManyProcedureInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpdateManyWithWhereWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpdateManyWithWhereWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedureCategoryScalarWhereInputObjectSchema),
          z.lazy(() => ProcedureCategoryScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureCategoryUncheckedUpdateManyWithoutProcedureNestedInputObjectSchema =
  Schema
