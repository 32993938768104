import { z } from 'zod'
import { VariantArgsObjectSchema } from './VariantArgs.schema'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { BrandArgsObjectSchema } from './BrandArgs.schema'
import { ProductReviewPictureFindManySchema } from '../findManyProductReviewPicture.schema'
import { ProductReviewCountOutputTypeArgsObjectSchema } from './ProductReviewCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    variant: z
      .union([z.boolean(), z.lazy(() => VariantArgsObjectSchema)])
      .optional(),
    variantId: z.boolean().optional(),
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    productId: z.boolean().optional(),
    brand: z
      .union([z.boolean(), z.lazy(() => BrandArgsObjectSchema)])
      .optional(),
    brandId: z.boolean().optional(),
    overallRating: z.boolean().optional(),
    qualityRating: z.boolean().optional(),
    shippingRating: z.boolean().optional(),
    packagingRating: z.boolean().optional(),
    message: z.boolean().optional(),
    orderId: z.boolean().optional(),
    patientId: z.boolean().optional(),
    patientName: z.boolean().optional(),
    isAnonymous: z.boolean().optional(),
    priority: z.boolean().optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProductReviewPictureFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProductReviewCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductReviewSelectObjectSchema = Schema
