import { z } from 'zod'
import { DeliveryLogWhereInputObjectSchema } from './objects/DeliveryLogWhereInput.schema'
import { DeliveryLogOrderByWithAggregationInputObjectSchema } from './objects/DeliveryLogOrderByWithAggregationInput.schema'
import { DeliveryLogScalarWhereWithAggregatesInputObjectSchema } from './objects/DeliveryLogScalarWhereWithAggregatesInput.schema'
import { DeliveryLogScalarFieldEnumSchema } from './enums/DeliveryLogScalarFieldEnum.schema'

export const DeliveryLogGroupBySchema = z.object({
  where: DeliveryLogWhereInputObjectSchema.optional(),
  orderBy: z.union([
    DeliveryLogOrderByWithAggregationInputObjectSchema,
    DeliveryLogOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: DeliveryLogScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(DeliveryLogScalarFieldEnumSchema),
})
