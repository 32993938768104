import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumCouponTypeFilterObjectSchema } from './EnumCouponTypeFilter.schema'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { OrderRelationFilterObjectSchema } from './OrderRelationFilter.schema'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CouponWhereInputObjectSchema),
        z.lazy(() => CouponWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CouponWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CouponWhereInputObjectSchema),
        z.lazy(() => CouponWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    couponId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    code: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumCouponTypeFilterObjectSchema),
        z.lazy(() => CouponTypeSchema),
      ])
      .optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    discount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    coin: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    v2CouponId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    order: z
      .union([
        z.lazy(() => OrderRelationFilterObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CouponWhereInputObjectSchema = Schema
