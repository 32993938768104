import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutPicturesInputObjectSchema } from './CaseCreateWithoutPicturesInput.schema'
import { CaseUncheckedCreateWithoutPicturesInputObjectSchema } from './CaseUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutPicturesInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutPicturesInputObjectSchema = Schema
