import { z } from 'zod'
import { ProductUpdateWithoutVariantsInputObjectSchema } from './ProductUpdateWithoutVariantsInput.schema'
import { ProductUncheckedUpdateWithoutVariantsInputObjectSchema } from './ProductUncheckedUpdateWithoutVariantsInput.schema'
import { ProductCreateWithoutVariantsInputObjectSchema } from './ProductCreateWithoutVariantsInput.schema'
import { ProductUncheckedCreateWithoutVariantsInputObjectSchema } from './ProductUncheckedCreateWithoutVariantsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutVariantsInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductUpdateWithoutVariantsInputObjectSchema),
      z.lazy(() => ProductUncheckedUpdateWithoutVariantsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProductCreateWithoutVariantsInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutVariantsInputObjectSchema),
    ]),
  })
  .strict()

export const ProductUpsertWithoutVariantsInputObjectSchema = Schema
