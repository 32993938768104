import { z } from 'zod'
import { ProcedureCreateWithoutPicturesInputObjectSchema } from './ProcedureCreateWithoutPicturesInput.schema'
import { ProcedureUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedCreateWithoutPicturesInput.schema'
import { ProcedureCreateOrConnectWithoutPicturesInputObjectSchema } from './ProcedureCreateOrConnectWithoutPicturesInput.schema'
import { ProcedureUpsertWithoutPicturesInputObjectSchema } from './ProcedureUpsertWithoutPicturesInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureUpdateWithoutPicturesInputObjectSchema } from './ProcedureUpdateWithoutPicturesInput.schema'
import { ProcedureUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutPicturesInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProcedureCreateOrConnectWithoutPicturesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProcedureUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProcedureUpdateWithoutPicturesInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedUpdateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
