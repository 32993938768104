import * as z from "zod"
import { CompleteAppointment, RelatedAppointmentModel } from "./index"

export const ScheduleModel = z.object({
  id: z.number().int(),
  doctorId: z.number().int(),
  availableAt: z.date(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteSchedule extends z.infer<typeof ScheduleModel> {
  appointment?: CompleteAppointment | null
}

/**
 * RelatedScheduleModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedScheduleModel: z.ZodSchema<CompleteSchedule> = z.lazy(() => ScheduleModel.extend({
  appointment: RelatedAppointmentModel.nullish(),
}))
