import { FileValue } from '../../components/types'

export const transformBrand = async (form: {
  logo: FileValue
  banners: FileValue[]
}) => {
  const { logo, banners } = form
  const result: { [key: string]: unknown } = form

  if (logo?.uploadPromise) {
    const url = await logo.uploadPromise
    if (url && !Array.isArray(url)) {
      result.logo = {
        ...logo,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload logo failed')
    }
  }

  const formBanners = [] as unknown[]
  if (banners) {
    for (const banner of banners) {
      if (banner.uploadPromise) {
        const url = await banner.uploadPromise
        if (url && !Array.isArray(url)) {
          formBanners.push({
            ...banner,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload banners failed')
        }
      } else {
        formBanners.push(banner)
      }
    }
  }
  result.banners = formBanners

  return result
}
