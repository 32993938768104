import { Typography } from '@mui/material'
import { FC } from 'react'
import { FormImageInput } from './formImageInput'
import { FormTextInput } from './formTextInput'

export const LicenseSection: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลใบอนุญาต
      </Typography>
      <FormTextInput source="licenseNumber" label="เลขที่ใบอนุญาต" />
      <FormImageInput source="licensePicture" label="รูปใบอนุญาต" />
    </>
  )
}
