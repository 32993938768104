import { Box, Grid } from '@mui/material'
import {
  AlignmentButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
} from 'ra-input-rich-text'
import { FC } from 'react'
import { required } from 'react-admin'
import { FormTextInput } from '../../../components/FormInput/input'
import { CoverPictureInput } from './coverPicture'
import { ArticlePicturesInput } from './pictures'

const RichTextEditorToolbar = ({
  size,
  ...props
}: {
  size?: 'small' | 'medium' | 'large'
  [key: string]: unknown
}) => {
  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
    </RichTextInputToolbar>
  )
}

export const ArticleDetail: FC = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <CoverPictureInput
          source="coverPicture"
          label="Cover picture"
          validator={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput
          label="Title (TH)"
          source="titleTr.th"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextInput label="Title (EN)" source="titleTr.en" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: 1 }}>
          <RichTextInput
            toolbar={<RichTextEditorToolbar size="medium" />}
            source="detailTr.th"
            label="Detail (TH)"
            validate={required()}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: 1 }}>
          <RichTextInput
            toolbar={<RichTextEditorToolbar size="medium" />}
            source="detailTr.en"
            label="Detail (EN)"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ArticlePicturesInput source="pictures" label="Additional Pictures" />
      </Grid>
    </Grid>
  )
}
