import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCountOutputTypeSelect> = z
  .object({
    rejectReason: z.boolean().optional(),
  })
  .strict()

export const KycDataCountOutputTypeSelectObjectSchema = Schema
