export const getResourcePermission = (resourceName: string) => {
  let resourcePermissionList: string[] = []

  switch (resourceName) {
    // Account
    case 'admin':
      resourcePermissionList = ['ADMIN']
      break
    case 'partnerUser':
      resourcePermissionList = ['ADMIN']
      break

    // Doctor
    case 'doctor':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DOCTOR_ADMIN',
      ]
      break
    case 'doctorCategory':
      resourcePermissionList = ['ADMIN', 'DOCTOR_ADMIN']
      break
    case 'doctorKycData':
      resourcePermissionList = ['ADMIN', 'DOCTOR_ADMIN']
      break
    case 'doctorScheduler':
      resourcePermissionList = ['ADMIN', 'CUSTOMER_SUPPORT', 'DOCTOR_ADMIN']
      break
    case 'appointment':
      resourcePermissionList = ['ADMIN', 'CUSTOMER_SUPPORT', 'DOCTOR_ADMIN']
      break
    case 'doctorQueueMonitoring':
      resourcePermissionList = ['ADMIN', 'DOCTOR_ADMIN']
      break
    case 'doctorConsent':
      resourcePermissionList = ['ADMIN', 'DOCTOR_ADMIN']
      break

    // Patient
    case 'patient':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
      ]
      break
    case 'patientKycData':
      resourcePermissionList = ['ADMIN', 'CUSTOMER_SUPPORT']
      break
    case 'patientConsent':
      resourcePermissionList = ['ADMIN']
      break
    case 'notification':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break

    // Product
    case 'product':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'OPERATION',
        'FINANCE',
        'STORE_ADMIN',
      ]
      break
    case 'productCategory':
      resourcePermissionList = ['ADMIN', 'OPERATION', 'STORE_ADMIN']
      break
    case 'productBrand':
      resourcePermissionList = ['ADMIN', 'OPERATION', 'STORE_ADMIN']
      break
    case 'productVariant':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'OPERATION',
        'FINANCE',
        'STORE_ADMIN',
      ]
      break
    case 'productSearch':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'OPERATION',
        'STORE_ADMIN',
      ]
      break

    // Deal & Clinic
    case 'procedure':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DEAL_ADMIN',
      ]
      break
    case 'procedureCategory':
      resourcePermissionList = ['ADMIN', 'FINANCE', 'DEAL_ADMIN']
      break
    case 'procedureSearch':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DEAL_ADMIN',
      ]
      break
    case 'partner':
      resourcePermissionList = [
        'ADMIN',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DEAL_ADMIN',
      ]
      break
    case 'partnerBranch':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'DEAL_ADMIN',
      ]
      break

    // Carousel
    case 'carouselProcedure':
      resourcePermissionList = ['ADMIN', 'DEAL_ADMIN']
      break
    case 'carouselItemProcedure':
      resourcePermissionList = ['ADMIN', 'DEAL_ADMIN']
      break

    // Flash sale
    case 'flashsaleProduct':
      resourcePermissionList = ['ADMIN', 'MARKETING', 'STORE_ADMIN']
      break
    case 'flashsaleItemProduct':
      resourcePermissionList = ['ADMIN', 'MARKETING', 'STORE_ADMIN']
      break
    case 'flashsaleProcedure':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break
    case 'flashsaleItemProcedure':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break

    // Coupon
    case 'couponGroup':
      resourcePermissionList = ['ADMIN', 'FINANCE', 'MARKETING']
      break
    case 'coupon':
      resourcePermissionList = [
        'ADMIN',
        'PHARMACIST',
        'CUSTOMER_SUPPORT',
        'FINANCE',
        'MARKETING',
      ]
      break

    // Banner
    case 'patientHomeBanner':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break
    case 'productBanner':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break
    case 'procedureBanner':
      resourcePermissionList = ['ADMIN', 'MARKETING', 'DEAL_ADMIN']
      break
    case 'doctorBanner':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break
    case 'waitingBanner':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break

    // Patient app article
    case 'article':
      resourcePermissionList = ['ADMIN', 'MARKETING']
      break

    // Shipping provider
    case 'shippingProvider':
      resourcePermissionList = ['ADMIN', 'OPERATION']
      break

    default:
      break
  }

  return resourcePermissionList
}
