import { z } from 'zod'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { NestedEnumDiscountItemTypeWithAggregatesFilterObjectSchema } from './NestedEnumDiscountItemTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumDiscountItemTypeFilterObjectSchema } from './NestedEnumDiscountItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountItemTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => DiscountItemTypeSchema).optional(),
    in: z
      .lazy(() => DiscountItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountItemTypeSchema),
        z.lazy(
          () => NestedEnumDiscountItemTypeWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumDiscountItemTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumDiscountItemTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumDiscountItemTypeWithAggregatesFilterObjectSchema = Schema
