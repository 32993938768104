import { z } from 'zod'
import { ProductItemCreateWithoutRefundInputObjectSchema } from './ProductItemCreateWithoutRefundInput.schema'
import { ProductItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProductItemUncheckedCreateWithoutRefundInput.schema'
import { ProductItemCreateOrConnectWithoutRefundInputObjectSchema } from './ProductItemCreateOrConnectWithoutRefundInput.schema'
import { ProductItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema } from './ProductItemUpsertWithWhereUniqueWithoutRefundInput.schema'
import { ProductItemCreateManyRefundInputEnvelopeObjectSchema } from './ProductItemCreateManyRefundInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema } from './ProductItemUpdateWithWhereUniqueWithoutRefundInput.schema'
import { ProductItemUpdateManyWithWhereWithoutRefundInputObjectSchema } from './ProductItemUpdateManyWithWhereWithoutRefundInput.schema'
import { ProductItemScalarWhereInputObjectSchema } from './ProductItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateManyWithoutRefundNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema),
          z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema).array(),
          z.lazy(
            () => ProductItemUncheckedCreateWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemUncheckedCreateWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductItemCreateOrConnectWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemCreateOrConnectWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemCreateManyRefundInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () => ProductItemUpdateManyWithWhereWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpdateManyWithWhereWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductItemScalarWhereInputObjectSchema),
          z.lazy(() => ProductItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemUpdateManyWithoutRefundNestedInputObjectSchema = Schema
