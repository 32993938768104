import { z } from 'zod'
import { ProductCategoryProductIdCategoryIdCompoundUniqueInputObjectSchema } from './ProductCategoryProductIdCategoryIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    productId_categoryId: z
      .lazy(
        () => ProductCategoryProductIdCategoryIdCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProductCategoryWhereUniqueInputObjectSchema = Schema
