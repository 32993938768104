import { z } from 'zod'
import { VariantWarehouseCreateWithoutVariantInputObjectSchema } from './VariantWarehouseCreateWithoutVariantInput.schema'
import { VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutVariantInput.schema'
import { VariantWarehouseCreateOrConnectWithoutVariantInputObjectSchema } from './VariantWarehouseCreateOrConnectWithoutVariantInput.schema'
import { VariantWarehouseUpsertWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantWarehouseUpsertWithWhereUniqueWithoutVariantInput.schema'
import { VariantWarehouseCreateManyVariantInputEnvelopeObjectSchema } from './VariantWarehouseCreateManyVariantInputEnvelope.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantWarehouseUpdateWithWhereUniqueWithoutVariantInput.schema'
import { VariantWarehouseUpdateManyWithWhereWithoutVariantInputObjectSchema } from './VariantWarehouseUpdateManyWithWhereWithoutVariantInput.schema'
import { VariantWarehouseScalarWhereInputObjectSchema } from './VariantWarehouseScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUncheckedUpdateManyWithoutVariantNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantWarehouseCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => VariantWarehouseCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => VariantWarehouseCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpdateManyWithWhereWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpdateManyWithWhereWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => VariantWarehouseScalarWhereInputObjectSchema),
          z.lazy(() => VariantWarehouseScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const VariantWarehouseUncheckedUpdateManyWithoutVariantNestedInputObjectSchema =
  Schema
