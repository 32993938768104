import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantOrderByWithRelationInputObjectSchema } from './VariantOrderByWithRelationInput.schema'
import { ProductOrderByWithRelationInputObjectSchema } from './ProductOrderByWithRelationInput.schema'
import { BrandOrderByWithRelationInputObjectSchema } from './BrandOrderByWithRelationInput.schema'
import { ProductReviewPictureOrderByRelationAggregateInputObjectSchema } from './ProductReviewPictureOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    overallRating: z.lazy(() => SortOrderSchema).optional(),
    qualityRating: z.lazy(() => SortOrderSchema).optional(),
    shippingRating: z.lazy(() => SortOrderSchema).optional(),
    packagingRating: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    patientName: z.lazy(() => SortOrderSchema).optional(),
    isAnonymous: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    variant: z
      .lazy(() => VariantOrderByWithRelationInputObjectSchema)
      .optional(),
    product: z
      .lazy(() => ProductOrderByWithRelationInputObjectSchema)
      .optional(),
    brand: z.lazy(() => BrandOrderByWithRelationInputObjectSchema).optional(),
    pictures: z
      .lazy(() => ProductReviewPictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductReviewOrderByWithRelationInputObjectSchema = Schema
