import AddIcon from '@mui/icons-material/Add'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CloseIcon from '@mui/icons-material/Close'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { addMinutes, format } from 'date-fns'
import * as React from 'react'
import { BtnDeleteSchedule } from './buttonDeleteScheuler'
import { ScheduleProps } from './schedule.interface'

interface CalendarSlotInfoProps {
  fetchAvailableSlot: () => Promise<void>
  showInfo: boolean
  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>
  scheduleInfo: ScheduleProps | null
  setScheduleInfo: React.Dispatch<React.SetStateAction<ScheduleProps | null>>
}

export const CalendarSlotInfo = ({
  fetchAvailableSlot,
  showInfo,
  setShowInfo,
  scheduleInfo,
  setScheduleInfo,
}: CalendarSlotInfoProps) => {
  const handleClose = () => {
    setScheduleInfo(null)
    setShowInfo(false)
  }

  if (!scheduleInfo) {
    return null
  }

  const convertAppointmentTest = (status: string) => {
    return status
      .split('_')
      .map((a) => a.charAt(0).toUpperCase() + a.slice(1).toLowerCase())
      .join(' ')
  }

  return (
    <div>
      <Dialog
        open={showInfo}
        onClose={handleClose}
        maxWidth={'sm'}
        fullWidth={true}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          Slot Details
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" color={'black'}>
                Doctor
              </Typography>
              <Typography variant="body1">
                {`${scheduleInfo.doctor.prefix}
              ${scheduleInfo.doctor.firstname} ${scheduleInfo.doctor.lastname}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" color={'black'}>
                Date
              </Typography>
              <Typography variant="body1">
                {format(scheduleInfo.availableAt, 'd MMMM yyyy HH:mm')}-
                {format(addMinutes(scheduleInfo.availableAt, 60), 'HH:mm')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" color={'black'}>
                Status
              </Typography>
              {scheduleInfo.appointment ? (
                <Typography
                  variant="subtitle1"
                  display={'flex'}
                  alignItems={'center'}
                  fontWeight={'500'}
                  color={'#FF4D4F'}
                  component={'div'}
                >
                  <FiberManualRecordIcon />
                  &nbsp;Busy
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  display={'flex'}
                  alignItems={'center'}
                  fontWeight={'500'}
                  color={'#2ABB7F'}
                  component={'div'}
                >
                  <FiberManualRecordIcon />
                  &nbsp;Available
                </Typography>
              )}
            </Grid>
          </Grid>
          {scheduleInfo.appointment ? (
            <>
              <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color={'black'}>
                    Patient
                  </Typography>
                  <Typography variant="body1">
                    {`${scheduleInfo.appointment.patient?.prefix || ''}
              ${scheduleInfo.appointment.patient?.firstname || ''} ${
                      scheduleInfo.appointment.patient?.lastname || ''
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color={'black'}>
                    Type
                  </Typography>
                  <Typography variant="body1">
                    {scheduleInfo.appointment.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color={'black'}>
                    Status
                  </Typography>
                  <Typography
                    variant="body1"
                    display={'flex'}
                    alignItems={'center'}
                    fontWeight={'500'}
                    color={
                      scheduleInfo.appointment.status === 'IN_PROGRESS'
                        ? '#1677FF'
                        : scheduleInfo.appointment.status === 'COMPLETED'
                        ? '#2ABB7F'
                        : '#FF4D4F'
                    }
                    component={'div'}
                  >
                    <FiberManualRecordIcon />
                    &nbsp;
                    {convertAppointmentTest(scheduleInfo.appointment.status)}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#E6E6E6',
          }}
        >
          <div>
            {scheduleInfo?.appointment === null ? (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  window.open(
                    `/appointment/create?scheduleId=${scheduleInfo.id}`,
                    '_blank',
                  )
                }}
                sx={{
                  boxShadow: 'none',
                  background: 'white',
                }}
              >
                Add Appointment
              </Button>
            ) : null}
            {scheduleInfo?.appointment ? (
              <Button
                variant="outlined"
                startIcon={<BorderColorIcon />}
                onClick={() => {
                  window.open(
                    `/appointment/${scheduleInfo?.appointment?.id}`,
                    '_blank',
                  )
                }}
                sx={{
                  boxShadow: 'none',
                  background: 'white',
                }}
              >
                Edit Appointment
              </Button>
            ) : null}
            &nbsp; &nbsp;
            <BtnDeleteSchedule
              schedule={scheduleInfo || null}
              fetchAvailableSlot={fetchAvailableSlot}
              handleClose={handleClose}
            />
          </div>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              boxShadow: 'none',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
