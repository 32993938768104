import { z } from 'zod'
import { ProductItemFindManySchema } from '../findManyProductItem.schema'
import { ProcedureItemFindManySchema } from '../findManyProcedureItem.schema'
import { OrderArgsObjectSchema } from './OrderArgs.schema'
import { RefundCountOutputTypeArgsObjectSchema } from './RefundCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundInclude> = z
  .object({
    productItems: z
      .union([z.boolean(), z.lazy(() => ProductItemFindManySchema)])
      .optional(),
    procedureItems: z
      .union([z.boolean(), z.lazy(() => ProcedureItemFindManySchema)])
      .optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    _count: z
      .union([z.boolean(), z.lazy(() => RefundCountOutputTypeArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const RefundIncludeObjectSchema = Schema
