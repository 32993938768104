import { z } from 'zod'
import { ProcedureUpdateWithoutCategoriesInputObjectSchema } from './ProcedureUpdateWithoutCategoriesInput.schema'
import { ProcedureUncheckedUpdateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedUpdateWithoutCategoriesInput.schema'
import { ProcedureCreateWithoutCategoriesInputObjectSchema } from './ProcedureCreateWithoutCategoriesInput.schema'
import { ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedCreateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutCategoriesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureUpdateWithoutCategoriesInputObjectSchema),
      z.lazy(() => ProcedureUncheckedUpdateWithoutCategoriesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutCategoriesInputObjectSchema),
      z.lazy(() => ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema),
    ]),
  })
  .strict()

export const ProcedureUpsertWithoutCategoriesInputObjectSchema = Schema
