import { z } from 'zod'
import { CaseSummaryDiagnosisUpdateInputObjectSchema } from './objects/CaseSummaryDiagnosisUpdateInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateInputObjectSchema } from './objects/CaseSummaryDiagnosisUncheckedUpdateInput.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereUniqueInput.schema'

export const CaseSummaryDiagnosisUpdateOneSchema = z.object({
  data: z.union([
    CaseSummaryDiagnosisUpdateInputObjectSchema,
    CaseSummaryDiagnosisUncheckedUpdateInputObjectSchema,
  ]),
  where: CaseSummaryDiagnosisWhereUniqueInputObjectSchema,
})
