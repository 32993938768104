import { z } from 'zod'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { NestedEnumCouponTypeWithAggregatesFilterObjectSchema } from './NestedEnumCouponTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumCouponTypeFilterObjectSchema } from './NestedEnumCouponTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumCouponTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => CouponTypeSchema).optional(),
    in: z
      .lazy(() => CouponTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CouponTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CouponTypeSchema),
        z.lazy(() => NestedEnumCouponTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumCouponTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumCouponTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumCouponTypeWithAggregatesFilterObjectSchema = Schema
