import { z } from 'zod'
import { DoctorItemUpdateInputObjectSchema } from './objects/DoctorItemUpdateInput.schema'
import { DoctorItemUncheckedUpdateInputObjectSchema } from './objects/DoctorItemUncheckedUpdateInput.schema'
import { DoctorItemWhereUniqueInputObjectSchema } from './objects/DoctorItemWhereUniqueInput.schema'

export const DoctorItemUpdateOneSchema = z.object({
  data: z.union([
    DoctorItemUpdateInputObjectSchema,
    DoctorItemUncheckedUpdateInputObjectSchema,
  ]),
  where: DoctorItemWhereUniqueInputObjectSchema,
})
