import { z } from 'zod'
import { RejectReasonWhereUniqueInputObjectSchema } from './RejectReasonWhereUniqueInput.schema'
import { RejectReasonUpdateWithoutKycDataInputObjectSchema } from './RejectReasonUpdateWithoutKycDataInput.schema'
import { RejectReasonUncheckedUpdateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedUpdateWithoutKycDataInput.schema'
import { RejectReasonCreateWithoutKycDataInputObjectSchema } from './RejectReasonCreateWithoutKycDataInput.schema'
import { RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedCreateWithoutKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonUpsertWithWhereUniqueWithoutKycDataInput> =
  z
    .object({
      where: z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => RejectReasonUpdateWithoutKycDataInputObjectSchema),
        z.lazy(
          () => RejectReasonUncheckedUpdateWithoutKycDataInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema),
        z.lazy(
          () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const RejectReasonUpsertWithWhereUniqueWithoutKycDataInputObjectSchema =
  Schema
