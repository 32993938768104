import { z } from 'zod'
import { OfficeHourBranchIdWeekdayCompoundUniqueInputObjectSchema } from './OfficeHourBranchIdWeekdayCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    branchId_weekday: z
      .lazy(() => OfficeHourBranchIdWeekdayCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict()

export const OfficeHourWhereUniqueInputObjectSchema = Schema
