import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    doctorFee: z.literal(true).optional(),
    doctorFeeDiscount: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
  })
  .strict()

export const DoctorItemAvgAggregateInputObjectSchema = Schema
