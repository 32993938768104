import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  styled,
  Switch,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Badge, { BadgeProps } from '@mui/material/Badge'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import 'firebase/database'
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { firebaseFirestore } from '../../firebase'
import { adminGatewayClient } from '../../service'
import { AgoraVideo } from './agora'

export enum ConsultingStatus {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  UNSUCCESS = 'UNSUCCESS',
}

interface StyledBadgeProps extends BadgeProps {
  customcolor?: string
}

const StyledBadge = styled(Badge)<StyledBadgeProps>(
  ({ theme, customcolor = '#44b700' }) => ({
    padding: '0.5rem 0',
    '& .MuiBadge-badge': {
      width: '20px',
      height: '20px',
      borderRadius: '15px',
      backgroundColor: customcolor,
      color: customcolor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation:
          customcolor === '#44b700'
            ? 'ripple 1.2s infinite ease-in-out'
            : 'unset',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxHeight: '90vh',
  overflow: 'auto',
}))

export const QueueMonitoring = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [doctorList, setDoctorList] = useState<any[]>([])
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [waitingQueue, setWaitingQueue] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [waitingRoom, setWaitingRoom] = useState<any>(null)
  const [channelName, setChannelName] = useState('')
  const [agoraToken, setAgoraToken] = useState('')
  const [uid, setUid] = useState<number | string | null>(0)
  const [roomStatus, setRoomStatus] = useState('')

  useEffect(() => {
    const db = firebaseFirestore
    const query = collection(db, 'Doctor')
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      const doctors = querySnapshot.docs.map(async (doc) => {
        const _doctor = doc.data()
        const doctorId = _doctor.detail.id
        if (selectedDoctor === doctorId) {
          setSelectedDoctor(doctorId)
          setWaitingQueue(_doctor.waitingQueue || [])
          setWaitingRoom(_doctor.waitingRoom || null)
          setRoomStatus(_doctor.waitingRoom?.status || '')
        }

        return _doctor
      })

      setDoctorList(doctors)
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeQueueByCaseId = async (queue: { caseId: number }) => {
    adminGatewayClient.case.exitWaitingRoom
      .mutate({
        caseId: queue.caseId,
      })
      .then((res) => {
        console.log('removeQueueByCaseId', res)
      })
      .catch((err) => {
        console.error(err)
        alert(err.message)
      })
  }

  const cancelRoom = async (waitingRoom: {
    doctorId: number
    teleconsultId: number
  }) => {
    if (waitingRoom.doctorId) {
      const db = firebaseFirestore
      await setDoc(
        doc(db, 'Doctor/' + waitingRoom.doctorId),
        {
          waitingRoom: null,
        },
        { merge: true },
      )
    }
    if (waitingRoom.teleconsultId) {
      adminGatewayClient.doctor.hangup
        .mutate({
          teleconsultId: waitingRoom.teleconsultId,
          status: ConsultingStatus.UNSUCCESS,
          remark: 'CANCEL FROM ADMIN',
        })
        .then((res) => {
          console.log('cancel call', res)
        })
        .catch((err) => {
          console.error(err)
          alert(err.message)
        })
    }
  }

  const onToggleOnlineStatus = async (doctor: {
    detail: { id: string }
    isOnline: boolean
  }) => {
    const db = firebaseFirestore
    const query = doc(db, `Doctor/${doctor.detail.id}`)

    await setDoc(
      query,
      {
        isOnline: !doctor.isOnline,
      },
      { merge: true },
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectDoctor = (doctor: any) => {
    let _selectedDoctor
    const doctorId = doctor.detail.id
    let _waitingQueue = []
    let _waitingRoom = null
    if (selectedDoctor === doctorId) {
      _selectedDoctor = null
    } else {
      _selectedDoctor = doctorId
      _waitingQueue = doctor.waitingQueue || []
      _waitingRoom = doctor.waitingRoom || null
    }
    setSelectedDoctor(_selectedDoctor)
    setWaitingQueue(_waitingQueue)
    setWaitingRoom(_waitingRoom)
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Item>Doctor</Item>
      </Grid>

      <Grid item xs={4}>
        <Item>Waiting Queue</Item>
      </Grid>

      <Grid item xs={4}>
        <Item>Waiting Room</Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <ul style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
            {doctorList.map((item) => (
              <li
                style={{
                  borderBottom: '1px solid black',
                  cursor: 'pointer',
                  backgroundColor:
                    selectedDoctor === item.detail.id ? 'aliceblue' : 'white',
                }}
                key={item.detail.id}
                onClick={() => onSelectDoctor(item)}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  customcolor={
                    item.waitingRoom
                      ? '#ed5555'
                      : item.isOnline
                      ? '#44b700'
                      : '#d7d7d7'
                  }
                >
                  <Avatar
                    alt={item.detail.firstname}
                    src={item.detail.picture}
                    sx={{ width: 125, height: 125 }}
                  />
                </StyledBadge>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="waitingQueue">
                    <TableBody>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{item.detail.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>
                          {item.detail.prefix} {item.detail.firstname}{' '}
                          {item.detail.lastname}
                          {item.detail?.nickname
                            ? `(${item.detail.nickname})`
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                value={item.isOnline}
                                checked={item.isOnline}
                                onChange={() => onToggleOnlineStatus(item)}
                                name="isOnline"
                                style={{
                                  backgroundColor: item.isOnline
                                    ? '#4caf50'
                                    : '#c7c7c7',
                                  color: item.isOnline ? 'white' : 'black',
                                }}
                              />
                            }
                            label={item.isOnline ? 'Online' : 'Offline'}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </li>
            ))}
          </ul>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          {waitingQueue.length
            ? waitingQueue.map(
                (queue: {
                  caseId: number
                  doctorId: number
                  patientId: number
                }) => (
                  <Item key={queue.caseId} style={{ background: 'ghostwhite' }}>
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="waitingQueue">
                        <TableBody>
                          <TableRow>
                            <TableCell>Case ID</TableCell>
                            <TableCell>{queue.caseId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Doctor ID</TableCell>
                            <TableCell>{queue.doctorId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Patient ID</TableCell>
                            <TableCell>{queue.patientId}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Stack
                      direction={{ sm: 'column', md: 'row' }}
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      mt={2}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          console.log('pickup queue', queue)
                          adminGatewayClient.doctor.pickupCase
                            .mutate({
                              caseId: queue.caseId as number,
                              doctorId: queue.doctorId as number,
                            })
                            .then((res) => {
                              console.log('res pickup case', res)
                            })
                            .catch((err) => {
                              console.error(err)
                              alert(err.message)
                            })
                        }}
                      >
                        pickup
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={async () => {
                          await removeQueueByCaseId(queue)
                        }}
                      >
                        delete
                      </Button>
                    </Stack>
                  </Item>
                ),
              )
            : 'Empty'}
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          {waitingRoom ? (
            <>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="waitingRoom">
                  <TableBody>
                    <TableRow>
                      <TableCell>Case ID</TableCell>
                      <TableCell>{waitingRoom.caseId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Doctor ID</TableCell>
                      <TableCell>{waitingRoom.doctorId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Patient ID</TableCell>
                      <TableCell>{waitingRoom.patientId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Teleconsult ID</TableCell>
                      <TableCell>{waitingRoom.teleconsultId ?? '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>{waitingRoom.status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Start At</TableCell>
                      <TableCell>
                        {waitingRoom.startAt?.toDate().toString() ?? '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Room</TableCell>
                      <TableCell>
                        {JSON.stringify(waitingRoom.room, null, 2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                mt={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    adminGatewayClient.doctor.generateRoomToken
                      .mutate({
                        teleconsultId: waitingRoom.teleconsultId as number,
                        doctorId: waitingRoom.doctorId as number,
                      })
                      .then((res) => {
                        console.log('generateRoomToken', res)
                        setChannelName(res.channelName)
                        setAgoraToken(res.token)
                        setUid(res.uid)
                      })
                      .catch((err) => {
                        console.error(err)
                        alert(err.message)
                      })
                  }}
                >
                  call
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    adminGatewayClient.doctor.hangup
                      .mutate({
                        teleconsultId: waitingRoom.teleconsultId,
                        status: ConsultingStatus.SUCCESS,
                      })
                      .then((res) => {
                        console.log('hangup', res)
                      })
                      .catch((err) => {
                        console.error(err)
                        alert(err.message)
                      })
                  }}
                >
                  hangup
                </Button>
                <Button
                  color="error"
                  onClick={async () => {
                    await cancelRoom(waitingRoom)
                  }}
                >
                  cancel
                </Button>
              </Stack>
              {channelName && (
                <AgoraVideo
                  appId={'2115b0e4a01e418f8107d13083f3fb5f'}
                  channelName={channelName}
                  token={agoraToken}
                  uid={uid}
                  status={roomStatus}
                />
              )}
            </>
          ) : (
            'Empty'
          )}
        </Item>
      </Grid>
    </Grid>
  )
}
