import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    prefix: z.literal(true).optional(),
    firstname: z.literal(true).optional(),
    lastname: z.literal(true).optional(),
    nickname: z.literal(true).optional(),
    gender: z.literal(true).optional(),
    birthDate: z.literal(true).optional(),
    picture: z.literal(true).optional(),
    isTest: z.literal(true).optional(),
    active: z.literal(true).optional(),
    registered: z.literal(true).optional(),
    drugAllergy: z.literal(true).optional(),
    congenitalDisease: z.literal(true).optional(),
    receiveNotifications: z.literal(true).optional(),
    activityNotifications: z.literal(true).optional(),
    chatNotifications: z.literal(true).optional(),
    orderNotifications: z.literal(true).optional(),
    omiseCustomerId: z.literal(true).optional(),
    freshchatUserId: z.literal(true).optional(),
    freshchatRestoreId: z.literal(true).optional(),
    coin: z.literal(true).optional(),
    v2PatientId: z.literal(true).optional(),
  })
  .strict()

export const PatientMinAggregateInputObjectSchema = Schema
