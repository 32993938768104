import { z } from 'zod'
import { NotificationPatientOrderByWithRelationInputObjectSchema } from './objects/NotificationPatientOrderByWithRelationInput.schema'
import { NotificationPatientWhereInputObjectSchema } from './objects/NotificationPatientWhereInput.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './objects/NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientScalarFieldEnumSchema } from './enums/NotificationPatientScalarFieldEnum.schema'

export const NotificationPatientFindFirstSchema = z.object({
  orderBy: z
    .union([
      NotificationPatientOrderByWithRelationInputObjectSchema,
      NotificationPatientOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: NotificationPatientWhereInputObjectSchema.optional(),
  cursor: NotificationPatientWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(NotificationPatientScalarFieldEnumSchema).optional(),
})
