import { z } from 'zod'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './ProcedureReviewPictureWhereUniqueInput.schema'
import { ProcedureReviewPictureUpdateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUpdateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUncheckedUpdateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUncheckedUpdateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () =>
            ProcedureReviewPictureUpdateWithoutProcedureReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureReviewPictureUncheckedUpdateWithoutProcedureReviewInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () =>
            ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInputObjectSchema =
  Schema
