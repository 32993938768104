import { z } from 'zod'
import { FlashsaleItemFindManySchema } from '../findManyFlashsaleItem.schema'
import { FlashsaleCountOutputTypeArgsObjectSchema } from './FlashsaleCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    createdBy: z.boolean().optional(),
    updatedBy: z.boolean().optional(),
    name: z.boolean().optional(),
    description: z.boolean().optional(),
    startAt: z.boolean().optional(),
    endAt: z.boolean().optional(),
    active: z.boolean().optional(),
    items: z
      .union([z.boolean(), z.lazy(() => FlashsaleItemFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => FlashsaleCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const FlashsaleSelectObjectSchema = Schema
