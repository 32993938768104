import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    patientId: z.boolean().optional(),
    orderId: z.boolean().optional(),
    status: z.boolean().optional(),
    type: z.boolean().optional(),
    amount: z.boolean().optional(),
    note: z.boolean().optional(),
  })
  .strict()

export const CoinTransactionSelectObjectSchema = Schema
