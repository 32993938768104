import { z } from 'zod'
import { TeleconsultWhereUniqueInputObjectSchema } from './objects/TeleconsultWhereUniqueInput.schema'
import { TeleconsultCreateInputObjectSchema } from './objects/TeleconsultCreateInput.schema'
import { TeleconsultUncheckedCreateInputObjectSchema } from './objects/TeleconsultUncheckedCreateInput.schema'
import { TeleconsultUpdateInputObjectSchema } from './objects/TeleconsultUpdateInput.schema'
import { TeleconsultUncheckedUpdateInputObjectSchema } from './objects/TeleconsultUncheckedUpdateInput.schema'

export const TeleconsultUpsertSchema = z.object({
  where: TeleconsultWhereUniqueInputObjectSchema,
  create: z.union([
    TeleconsultCreateInputObjectSchema,
    TeleconsultUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    TeleconsultUpdateInputObjectSchema,
    TeleconsultUncheckedUpdateInputObjectSchema,
  ]),
})
