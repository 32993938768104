import { z } from 'zod'
import { ProductCategoryCreateWithoutCategoryInputObjectSchema } from './ProductCategoryCreateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutCategoryInput.schema'
import { ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema } from './ProductCategoryCreateOrConnectWithoutCategoryInput.schema'
import { ProductCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema } from './ProductCategoryUpsertWithWhereUniqueWithoutCategoryInput.schema'
import { ProductCategoryCreateManyCategoryInputEnvelopeObjectSchema } from './ProductCategoryCreateManyCategoryInputEnvelope.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema } from './ProductCategoryUpdateWithWhereUniqueWithoutCategoryInput.schema'
import { ProductCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema } from './ProductCategoryUpdateManyWithWhereWithoutCategoryInput.schema'
import { ProductCategoryScalarWhereInputObjectSchema } from './ProductCategoryScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUpdateManyWithoutCategoryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema),
          z
            .lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCategoryCreateManyCategoryInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductCategoryScalarWhereInputObjectSchema),
          z.lazy(() => ProductCategoryScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCategoryUpdateManyWithoutCategoryNestedInputObjectSchema =
  Schema
