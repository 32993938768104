import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentUncheckedCreateWithoutAcceptedConsentsInput> =
  z
    .object({
      key: z.string(),
      detail: z.string(),
      rejectDetail: z.string().optional().nullable(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
    .strict()

export const ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema =
  Schema
