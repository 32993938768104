import { z } from 'zod'
import { ProcedureCreateNestedOneWithoutCategoriesInputObjectSchema } from './ProcedureCreateNestedOneWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateWithoutCategoryInput> = z
  .object({
    priority: z.number().optional(),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutCategoriesInputObjectSchema,
    ),
  })
  .strict()

export const ProcedureCategoryCreateWithoutCategoryInputObjectSchema = Schema
