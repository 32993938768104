import { z } from 'zod'
import { ProductCategoryCreateWithoutProductInputObjectSchema } from './ProductCategoryCreateWithoutProductInput.schema'
import { ProductCategoryUncheckedCreateWithoutProductInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutProductInput.schema'
import { ProductCategoryCreateOrConnectWithoutProductInputObjectSchema } from './ProductCategoryCreateOrConnectWithoutProductInput.schema'
import { ProductCategoryCreateManyProductInputEnvelopeObjectSchema } from './ProductCategoryCreateManyProductInputEnvelope.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUncheckedCreateNestedManyWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductCategoryCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCategoryCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCategoryUncheckedCreateNestedManyWithoutProductInputObjectSchema =
  Schema
