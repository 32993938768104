import { z } from 'zod'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumSymptomDurationUnitNullableFilterObjectSchema } from './NestedEnumSymptomDurationUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumSymptomDurationUnitNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => SymptomDurationUnitSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => SymptomDurationUnitSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => SymptomDurationUnitSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => SymptomDurationUnitSchema),
          z.lazy(
            () =>
              NestedEnumSymptomDurationUnitNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumSymptomDurationUnitNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumSymptomDurationUnitNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumSymptomDurationUnitNullableWithAggregatesFilterObjectSchema =
  Schema
