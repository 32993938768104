import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentTransactionIdPaymentGatewayCompoundUniqueInput> =
  z
    .object({
      transactionId: z.string(),
      paymentGateway: z.lazy(() => PaymentGatewaySchema),
    })
    .strict()

export const PaymentTransactionIdPaymentGatewayCompoundUniqueInputObjectSchema =
  Schema
