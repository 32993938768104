import { z } from 'zod'
import { ProcedurePictureWhereInputObjectSchema } from './objects/ProcedurePictureWhereInput.schema'
import { ProcedurePictureOrderByWithAggregationInputObjectSchema } from './objects/ProcedurePictureOrderByWithAggregationInput.schema'
import { ProcedurePictureScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedurePictureScalarWhereWithAggregatesInput.schema'
import { ProcedurePictureScalarFieldEnumSchema } from './enums/ProcedurePictureScalarFieldEnum.schema'

export const ProcedurePictureGroupBySchema = z.object({
  where: ProcedurePictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedurePictureOrderByWithAggregationInputObjectSchema,
    ProcedurePictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedurePictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedurePictureScalarFieldEnumSchema),
})
