import { z } from 'zod'
import { KycDataCreateWithoutPatientInputObjectSchema } from './KycDataCreateWithoutPatientInput.schema'
import { KycDataUncheckedCreateWithoutPatientInputObjectSchema } from './KycDataUncheckedCreateWithoutPatientInput.schema'
import { KycDataCreateOrConnectWithoutPatientInputObjectSchema } from './KycDataCreateOrConnectWithoutPatientInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataUncheckedCreateNestedOneWithoutPatientInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => KycDataCreateWithoutPatientInputObjectSchema),
          z.lazy(() => KycDataUncheckedCreateWithoutPatientInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => KycDataCreateOrConnectWithoutPatientInputObjectSchema)
        .optional(),
      connect: z.lazy(() => KycDataWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const KycDataUncheckedCreateNestedOneWithoutPatientInputObjectSchema =
  Schema
