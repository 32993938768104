import { z } from 'zod'
import { HealthInfoCreateInputObjectSchema } from './objects/HealthInfoCreateInput.schema'
import { HealthInfoUncheckedCreateInputObjectSchema } from './objects/HealthInfoUncheckedCreateInput.schema'

export const HealthInfoCreateOneSchema = z.object({
  data: z.union([
    HealthInfoCreateInputObjectSchema,
    HealthInfoUncheckedCreateInputObjectSchema,
  ]),
})
