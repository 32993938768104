import { z } from 'zod'
import { ConditionSettingOrderByWithRelationInputObjectSchema } from './objects/ConditionSettingOrderByWithRelationInput.schema'
import { ConditionSettingWhereInputObjectSchema } from './objects/ConditionSettingWhereInput.schema'
import { ConditionSettingWhereUniqueInputObjectSchema } from './objects/ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingScalarFieldEnumSchema } from './enums/ConditionSettingScalarFieldEnum.schema'

export const ConditionSettingFindManySchema = z.object({
  orderBy: z
    .union([
      ConditionSettingOrderByWithRelationInputObjectSchema,
      ConditionSettingOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ConditionSettingWhereInputObjectSchema.optional(),
  cursor: ConditionSettingWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ConditionSettingScalarFieldEnumSchema).optional(),
})
