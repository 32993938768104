import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ReceiptCountOrderByAggregateInputObjectSchema } from './ReceiptCountOrderByAggregateInput.schema'
import { ReceiptAvgOrderByAggregateInputObjectSchema } from './ReceiptAvgOrderByAggregateInput.schema'
import { ReceiptMaxOrderByAggregateInputObjectSchema } from './ReceiptMaxOrderByAggregateInput.schema'
import { ReceiptMinOrderByAggregateInputObjectSchema } from './ReceiptMinOrderByAggregateInput.schema'
import { ReceiptSumOrderByAggregateInputObjectSchema } from './ReceiptSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptOrderByWithAggregationInput> = z
  .object({
    receiptNumber: z.lazy(() => SortOrderSchema).optional(),
    receiptUrl: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    receiptData: z.lazy(() => SortOrderSchema).optional(),
    isValid: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ReceiptCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => ReceiptAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => ReceiptMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => ReceiptMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => ReceiptSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const ReceiptOrderByWithAggregationInputObjectSchema = Schema
