import { z } from 'zod'
import { ProcedureReviewPictureCreateInputObjectSchema } from './objects/ProcedureReviewPictureCreateInput.schema'
import { ProcedureReviewPictureUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewPictureUncheckedCreateInput.schema'

export const ProcedureReviewPictureCreateOneSchema = z.object({
  data: z.union([
    ProcedureReviewPictureCreateInputObjectSchema,
    ProcedureReviewPictureUncheckedCreateInputObjectSchema,
  ]),
})
