import { z } from 'zod'
import { SuggestedProductItemOrderByWithRelationInputObjectSchema } from './objects/SuggestedProductItemOrderByWithRelationInput.schema'
import { SuggestedProductItemWhereInputObjectSchema } from './objects/SuggestedProductItemWhereInput.schema'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemCountAggregateInputObjectSchema } from './objects/SuggestedProductItemCountAggregateInput.schema'
import { SuggestedProductItemMinAggregateInputObjectSchema } from './objects/SuggestedProductItemMinAggregateInput.schema'
import { SuggestedProductItemMaxAggregateInputObjectSchema } from './objects/SuggestedProductItemMaxAggregateInput.schema'
import { SuggestedProductItemAvgAggregateInputObjectSchema } from './objects/SuggestedProductItemAvgAggregateInput.schema'
import { SuggestedProductItemSumAggregateInputObjectSchema } from './objects/SuggestedProductItemSumAggregateInput.schema'

export const SuggestedProductItemAggregateSchema = z.object({
  orderBy: z
    .union([
      SuggestedProductItemOrderByWithRelationInputObjectSchema,
      SuggestedProductItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProductItemWhereInputObjectSchema.optional(),
  cursor: SuggestedProductItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      SuggestedProductItemCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: SuggestedProductItemMinAggregateInputObjectSchema.optional(),
  _max: SuggestedProductItemMaxAggregateInputObjectSchema.optional(),
  _avg: SuggestedProductItemAvgAggregateInputObjectSchema.optional(),
  _sum: SuggestedProductItemSumAggregateInputObjectSchema.optional(),
})
