import { Box } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BrandForm } from './create-edit/brandForm'
import { transformBrand } from './transform'

const ProductBrandCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="productBrand" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}
export const ProductBrandCreate = () => (
  <CreateSaveRouteBack redirect="list" transform={transformBrand}>
    <SimpleForm toolbar={<ProductBrandCreateToolbar />}>
      <BrandForm />
    </SimpleForm>
  </CreateSaveRouteBack>
)
