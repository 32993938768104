import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateManyVariantInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number().optional(),
    flashsaleId: z.number(),
    startAt: z.date(),
    endAt: z.date(),
    price: z.number(),
    saleLimit: z.number(),
    sold: z.number(),
  })
  .strict()

export const FlashsaleItemCreateManyVariantInputObjectSchema = Schema
