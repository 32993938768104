import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutProductItemsOriginalInput> =
  z
    .object({
      where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => OrderCreateWithoutProductItemsOriginalInputObjectSchema),
        z.lazy(
          () =>
            OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OrderCreateOrConnectWithoutProductItemsOriginalInputObjectSchema =
  Schema
