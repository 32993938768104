import { z } from 'zod'
import { RefundCreateWithoutProcedureItemsInputObjectSchema } from './RefundCreateWithoutProcedureItemsInput.schema'
import { RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProcedureItemsInput.schema'
import { RefundCreateOrConnectWithoutProcedureItemsInputObjectSchema } from './RefundCreateOrConnectWithoutProcedureItemsInput.schema'
import { RefundUpsertWithoutProcedureItemsInputObjectSchema } from './RefundUpsertWithoutProcedureItemsInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundUpdateWithoutProcedureItemsInputObjectSchema } from './RefundUpdateWithoutProcedureItemsInput.schema'
import { RefundUncheckedUpdateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedUpdateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateOneWithoutProcedureItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => RefundCreateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => RefundCreateOrConnectWithoutProcedureItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => RefundUpsertWithoutProcedureItemsInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => RefundWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => RefundUpdateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => RefundUncheckedUpdateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const RefundUpdateOneWithoutProcedureItemsNestedInputObjectSchema =
  Schema
