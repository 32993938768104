import { z } from 'zod'
import { BranchUpdateInputObjectSchema } from './objects/BranchUpdateInput.schema'
import { BranchUncheckedUpdateInputObjectSchema } from './objects/BranchUncheckedUpdateInput.schema'
import { BranchWhereUniqueInputObjectSchema } from './objects/BranchWhereUniqueInput.schema'

export const BranchUpdateOneSchema = z.object({
  data: z.union([
    BranchUpdateInputObjectSchema,
    BranchUncheckedUpdateInputObjectSchema,
  ]),
  where: BranchWhereUniqueInputObjectSchema,
})
