import {
  NavigateNext as NavigateNextIcon,
  SimCardDownloadRounded as SimCardDownloadRoundedIcon,
} from '@mui/icons-material'
import { Alert, Button, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { FileField, FileInput, required, useTranslate } from 'react-admin'
import { v4 as uuidv4 } from 'uuid'
import { FormDivider } from '../../../components/FormInput/divider'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'
import { RecordError } from './importModal'

const UPLOAD_PRODUCT_EXCEL_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1Ke2c01fY-MqJM6Sg5bUpXXx7kHp7lqsxNLpB3E9Vb2c/edit#gid=652660027'

const imageRequiredValidation = (value: unknown[]) => {
  if (!value || value.length === 0) {
    return 'Picture is required'
  }

  return undefined
}

interface ProductImportFormProps {
  recordErrors: RecordError[] | null
  setAllowUpload: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProductImportForm: FC<ProductImportFormProps> = ({
  recordErrors,
  setAllowUpload,
}) => {
  const translate = useTranslate()
  const uuid = uuidv4()

  return (
    <Grid container spacing={1} width={'100%'}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          รูปภาพ
        </Typography>
        <FormDivider />
        <ImageStorageInput
          onChange={() => setAllowUpload(false)}
          source="pictures"
          label={false}
          multiple={true}
          validate={[required(), imageRequiredValidation]}
          accept={{ 'image/*': [] }}
          upload={async (file, { onProgress, abortController }) => {
            const uploadUrlMeta =
              await adminGatewayClient.product.generateMassUploadPictureUrl.mutate(
                {
                  extension: file.name.split('.').pop() ?? '',
                  fieldPath: 'pictures',
                  uuid,
                },
              )
            await axios.request({
              method: 'PUT',
              url: uploadUrlMeta.uploadUrl,
              headers: {
                'content-type': file.type,
              },
              data: file,
              onUploadProgress: (progressEvent) => {
                onProgress(progressEvent.loaded / progressEvent.total)
              },
              signal: abortController.signal,
            })

            return uploadUrlMeta
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Excel file
        </Typography>
        <FormDivider />
        <FileInput
          source="productItems"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          validate={required()}
          onChange={() => setAllowUpload(false)}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {recordErrors &&
          recordErrors.map((recordErr, index) => (
            <Alert severity="error" key={index}>
              <Typography sx={{ display: 'flex', alignItem: 'center' }}>
                record: {recordErr.index + 1} column: {recordErr.column}{' '}
                <NavigateNextIcon />
                {recordErr.message}
              </Typography>
            </Alert>
          ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          endIcon={<SimCardDownloadRoundedIcon />}
          size="small"
          href={UPLOAD_PRODUCT_EXCEL_TEMPLATE_URL}
          target="_blank"
        >
          {translate('resources.product.action.download_import_template')}
        </Button>
      </Grid>
    </Grid>
  )
}
