import { z } from 'zod'
import { CaseCreateWithoutCaseSummaryInputObjectSchema } from './CaseCreateWithoutCaseSummaryInput.schema'
import { CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedCreateWithoutCaseSummaryInput.schema'
import { CaseCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './CaseCreateOrConnectWithoutCaseSummaryInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutCaseSummaryInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutCaseSummaryInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutCaseSummaryInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CaseCreateNestedOneWithoutCaseSummaryInputObjectSchema = Schema
