import { z } from 'zod'
import { GenderSchema } from '../enums/Gender.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    prefix: z.string().optional().nullable(),
    firstname: z.string().optional().nullable(),
    lastname: z.string().optional().nullable(),
    nickname: z.string().optional().nullable(),
    gender: z
      .lazy(() => GenderSchema)
      .optional()
      .nullable(),
    birthDate: z.date().optional().nullable(),
    picture: z.string().optional().nullable(),
    isTest: z.boolean().optional(),
    active: z.boolean().optional(),
    registered: z.boolean().optional(),
    drugAllergy: z.string().optional().nullable(),
    congenitalDisease: z.string().optional().nullable(),
    receiveNotifications: z.boolean().optional(),
    activityNotifications: z.boolean().optional(),
    chatNotifications: z.boolean().optional(),
    orderNotifications: z.boolean().optional(),
    omiseCustomerId: z.string().optional(),
    freshchatUserId: z.string().optional().nullable(),
    freshchatRestoreId: z.string().optional().nullable(),
    coin: z.number().optional(),
    v2PatientId: z.number().optional().nullable(),
  })
  .strict()

export const PatientCreateManyInputObjectSchema = Schema
