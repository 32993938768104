import { z } from 'zod'
import { ProcedureReviewOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewOrderByWithRelationInput.schema'
import { ProcedureReviewWhereInputObjectSchema } from './objects/ProcedureReviewWhereInput.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './objects/ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewCountAggregateInputObjectSchema } from './objects/ProcedureReviewCountAggregateInput.schema'
import { ProcedureReviewMinAggregateInputObjectSchema } from './objects/ProcedureReviewMinAggregateInput.schema'
import { ProcedureReviewMaxAggregateInputObjectSchema } from './objects/ProcedureReviewMaxAggregateInput.schema'
import { ProcedureReviewAvgAggregateInputObjectSchema } from './objects/ProcedureReviewAvgAggregateInput.schema'
import { ProcedureReviewSumAggregateInputObjectSchema } from './objects/ProcedureReviewSumAggregateInput.schema'

export const ProcedureReviewAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewOrderByWithRelationInputObjectSchema,
      ProcedureReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureReviewCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureReviewMinAggregateInputObjectSchema.optional(),
  _max: ProcedureReviewMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureReviewAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureReviewSumAggregateInputObjectSchema.optional(),
})
