import { z } from 'zod'
import { ProcedurePictureCreateInputObjectSchema } from './objects/ProcedurePictureCreateInput.schema'
import { ProcedurePictureUncheckedCreateInputObjectSchema } from './objects/ProcedurePictureUncheckedCreateInput.schema'

export const ProcedurePictureCreateOneSchema = z.object({
  data: z.union([
    ProcedurePictureCreateInputObjectSchema,
    ProcedurePictureUncheckedCreateInputObjectSchema,
  ]),
})
