import { z } from 'zod'
import { ProcedureReviewArgsObjectSchema } from './ProcedureReviewArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    url: z.boolean().optional(),
    procedureReview: z
      .union([z.boolean(), z.lazy(() => ProcedureReviewArgsObjectSchema)])
      .optional(),
    procedureReviewId: z.boolean().optional(),
  })
  .strict()

export const ProcedureReviewPictureSelectObjectSchema = Schema
