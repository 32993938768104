import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    sku: z.string().optional(),
  })
  .strict()

export const VariantWhereUniqueInputObjectSchema = Schema
