import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { BooleanInput, DateTimeInput, required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import {
  CustomNumberInput,
  CustomTextInput,
} from '../../../components/CustomInput'

interface CarouselProcedureInfoProps {
  mode: 'CREATE' | 'EDIT'
}

export const CarouselProcedureInfo: FC<CarouselProcedureInfoProps> = ({
  mode,
}) => {
  const { watch } = useFormContext()
  const startDate = watch('startAt') ?? ''

  const endDateValidation = (value: Date) => {
    if (startDate && value <= startDate) {
      return 'End date must greater than start date'
    }

    return undefined
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomTextInput
            source="nameTr.th"
            label="Name (TH)"
            fullWidth
            validate={required()}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomTextInput source="nameTr.en" label="Name (EN)" fullWidth />
        </Box>
      </Grid>
      {mode === 'EDIT' && (
        <Grid item xs={12} md={2}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <BooleanInput source="active" label="Active" />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DateTimeInput
            source="startAt"
            validate={required()}
            sx={{ flexGrow: 1 }}
          />
          <DateTimeInput
            source="endAt"
            validate={[required(), endDateValidation]}
            sx={{ flexGrow: 1 }}
          />
          <CustomNumberInput
            source="priority"
            defaultValue={0}
            step={1}
            inputProps={{ style: { textAlign: 'center' } }}
            validate={required()}
            // sx={{ marginTop: '0px !important', flexGrow: 0, width: 'auto' }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
