import { z } from 'zod'
import { CouponGroupArgsObjectSchema } from './CouponGroupArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    code: z.boolean().optional(),
    type: z.boolean().optional(),
    titleTr: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    discount: z.boolean().optional(),
    discountType: z.boolean().optional(),
    isPublic: z.boolean().optional(),
    isOnlyNewPatient: z.boolean().optional(),
    startAt: z.boolean().optional(),
    expireAt: z.boolean().optional(),
    orderId: z.boolean().optional(),
    patientId: z.boolean().optional(),
    usedAt: z.boolean().optional(),
    group: z
      .union([z.boolean(), z.lazy(() => CouponGroupArgsObjectSchema)])
      .optional(),
    groupId: z.boolean().optional(),
  })
  .strict()

export const CouponSelectObjectSchema = Schema
