import { Box } from '@mui/material'

export const DiscountField = (discount: number, discountType: string) => {
  let statment = ''
  switch (discountType) {
    case 'FIXED':
      statment = `฿ ${discount}`
      break
    case 'PERCENT':
      statment = `${discount}%`
      break
    default:
      statment = [discount, discountType].join(' ')
      break
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>{statment}</Box>
  )
}
