import { z } from 'zod'
import { IdentityCreateWithoutPatientInputObjectSchema } from './IdentityCreateWithoutPatientInput.schema'
import { IdentityUncheckedCreateWithoutPatientInputObjectSchema } from './IdentityUncheckedCreateWithoutPatientInput.schema'
import { IdentityCreateOrConnectWithoutPatientInputObjectSchema } from './IdentityCreateOrConnectWithoutPatientInput.schema'
import { IdentityUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './IdentityUpsertWithWhereUniqueWithoutPatientInput.schema'
import { IdentityCreateManyPatientInputEnvelopeObjectSchema } from './IdentityCreateManyPatientInputEnvelope.schema'
import { IdentityWhereUniqueInputObjectSchema } from './IdentityWhereUniqueInput.schema'
import { IdentityUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './IdentityUpdateWithWhereUniqueWithoutPatientInput.schema'
import { IdentityUpdateManyWithWhereWithoutPatientInputObjectSchema } from './IdentityUpdateManyWithWhereWithoutPatientInput.schema'
import { IdentityScalarWhereInputObjectSchema } from './IdentityScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityUncheckedUpdateManyWithoutPatientNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema),
          z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema).array(),
          z.lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => IdentityCreateOrConnectWithoutPatientInputObjectSchema),
          z
            .lazy(() => IdentityCreateOrConnectWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () => IdentityUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                IdentityUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => IdentityCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => IdentityWhereUniqueInputObjectSchema),
          z.lazy(() => IdentityWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => IdentityWhereUniqueInputObjectSchema),
          z.lazy(() => IdentityWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => IdentityWhereUniqueInputObjectSchema),
          z.lazy(() => IdentityWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => IdentityWhereUniqueInputObjectSchema),
          z.lazy(() => IdentityWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () => IdentityUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                IdentityUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () => IdentityUpdateManyWithWhereWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () => IdentityUpdateManyWithWhereWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => IdentityScalarWhereInputObjectSchema),
          z.lazy(() => IdentityScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const IdentityUncheckedUpdateManyWithoutPatientNestedInputObjectSchema =
  Schema
