import { z } from 'zod'
import { ShippingProviderCreateWithoutOrdersInputObjectSchema } from './ShippingProviderCreateWithoutOrdersInput.schema'
import { ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutOrdersInput.schema'
import { ShippingProviderCreateOrConnectWithoutOrdersInputObjectSchema } from './ShippingProviderCreateOrConnectWithoutOrdersInput.schema'
import { ShippingProviderUpsertWithoutOrdersInputObjectSchema } from './ShippingProviderUpsertWithoutOrdersInput.schema'
import { ShippingProviderWhereUniqueInputObjectSchema } from './ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderUpdateWithoutOrdersInputObjectSchema } from './ShippingProviderUpdateWithoutOrdersInput.schema'
import { ShippingProviderUncheckedUpdateWithoutOrdersInputObjectSchema } from './ShippingProviderUncheckedUpdateWithoutOrdersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderUpdateOneWithoutOrdersNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ShippingProviderCreateWithoutOrdersInputObjectSchema),
          z.lazy(
            () => ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ShippingProviderCreateOrConnectWithoutOrdersInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ShippingProviderUpsertWithoutOrdersInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z
        .lazy(() => ShippingProviderWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(() => ShippingProviderUpdateWithoutOrdersInputObjectSchema),
          z.lazy(
            () => ShippingProviderUncheckedUpdateWithoutOrdersInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ShippingProviderUpdateOneWithoutOrdersNestedInputObjectSchema =
  Schema
