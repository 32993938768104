import { z } from 'zod'
import { ScheduleUpdateWithoutAppointmentInputObjectSchema } from './ScheduleUpdateWithoutAppointmentInput.schema'
import { ScheduleUncheckedUpdateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedUpdateWithoutAppointmentInput.schema'
import { ScheduleCreateWithoutAppointmentInputObjectSchema } from './ScheduleCreateWithoutAppointmentInput.schema'
import { ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedCreateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleUpsertWithoutAppointmentInput> = z
  .object({
    update: z.union([
      z.lazy(() => ScheduleUpdateWithoutAppointmentInputObjectSchema),
      z.lazy(() => ScheduleUncheckedUpdateWithoutAppointmentInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ScheduleCreateWithoutAppointmentInputObjectSchema),
      z.lazy(() => ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema),
    ]),
  })
  .strict()

export const ScheduleUpsertWithoutAppointmentInputObjectSchema = Schema
