import { z } from 'zod'
import { CoinTransactionWhereInputObjectSchema } from './CoinTransactionWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionListRelationFilter> = z
  .object({
    every: z.lazy(() => CoinTransactionWhereInputObjectSchema).optional(),
    some: z.lazy(() => CoinTransactionWhereInputObjectSchema).optional(),
    none: z.lazy(() => CoinTransactionWhereInputObjectSchema).optional(),
  })
  .strict()

export const CoinTransactionListRelationFilterObjectSchema = Schema
