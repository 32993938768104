import { z } from 'zod'
import { SuggestedProductItemCreateInputObjectSchema } from './objects/SuggestedProductItemCreateInput.schema'
import { SuggestedProductItemUncheckedCreateInputObjectSchema } from './objects/SuggestedProductItemUncheckedCreateInput.schema'

export const SuggestedProductItemCreateOneSchema = z.object({
  data: z.union([
    SuggestedProductItemCreateInputObjectSchema,
    SuggestedProductItemUncheckedCreateInputObjectSchema,
  ]),
})
