import { z } from 'zod'
import { DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutProductItemsInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      shippingProvider: z.string(),
      trackingNumber: z.string(),
      status: z.string(),
      deliveredAt: z.date().optional().nullable(),
      partnerName: z.string(),
      orderId: z.number(),
      deliveryLogs: z
        .lazy(
          () =>
            DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema =
  Schema
