import * as z from "zod"
import { CompleteAcceptedConsent, RelatedAcceptedConsentModel, CompleteKycData, RelatedKycDataModel, CompleteHealthInfo, RelatedHealthInfoModel, CompleteNotificationPatient, RelatedNotificationPatientModel, CompleteAddress, RelatedAddressModel, CompleteIdentity, RelatedIdentityModel, CompleteCoinTransaction, RelatedCoinTransactionModel, CompleteMyReview, RelatedMyReviewModel } from "./index"

export const PatientModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  prefix: z.string().nullish(),
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
  nickname: z.string().nullish(),
  gender: z.enum(['MALE', 'FEMALE']).nullish(),
  birthDate: z.date().nullish(),
  picture: z.string().nullish(),
  isTest: z.boolean(),
  active: z.boolean(),
  registered: z.boolean(),
  drugAllergy: z.string().nullish(),
  congenitalDisease: z.string().nullish(),
  receiveNotifications: z.boolean(),
  activityNotifications: z.boolean(),
  chatNotifications: z.boolean(),
  orderNotifications: z.boolean(),
  omiseCustomerId: z.string(),
  freshchatUserId: z.string().nullish(),
  freshchatRestoreId: z.string().nullish(),
  coin: z.number(),
  v2PatientId: z.number().int().nullish(),
})

export interface CompletePatient extends z.infer<typeof PatientModel> {
  acceptedConsents: CompleteAcceptedConsent[]
  kycData?: CompleteKycData | null
  healthCheckUpInfo?: CompleteHealthInfo | null
  notifications: CompleteNotificationPatient[]
  address: CompleteAddress[]
  identities: CompleteIdentity[]
  coinTransactions: CompleteCoinTransaction[]
  myReviews: CompleteMyReview[]
}

/**
 * RelatedPatientModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPatientModel: z.ZodSchema<CompletePatient> = z.lazy(() => PatientModel.extend({
  acceptedConsents: RelatedAcceptedConsentModel.array(),
  kycData: RelatedKycDataModel.nullish(),
  healthCheckUpInfo: RelatedHealthInfoModel.nullish(),
  notifications: RelatedNotificationPatientModel.array(),
  address: RelatedAddressModel.array(),
  identities: RelatedIdentityModel.array(),
  coinTransactions: RelatedCoinTransactionModel.array(),
  myReviews: RelatedMyReviewModel.array(),
}))
