import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    discount: z.literal(true).optional(),
    minTotal: z.literal(true).optional(),
    maxDiscount: z.literal(true).optional(),
    quota: z.literal(true).optional(),
    quotaPerUser: z.literal(true).optional(),
    createdBy: z.literal(true).optional(),
    updatedBy: z.literal(true).optional(),
    v2CreatedBy: z.literal(true).optional(),
    v2UpdatedBy: z.literal(true).optional(),
  })
  .strict()

export const CouponGroupAvgAggregateInputObjectSchema = Schema
