import { z } from 'zod'
import { BrandWhereInputObjectSchema } from './BrandWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandRelationFilter> = z
  .object({
    is: z.lazy(() => BrandWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => BrandWhereInputObjectSchema).optional(),
  })
  .strict()

export const BrandRelationFilterObjectSchema = Schema
