import { z } from 'zod'
import { TeleconsultCreateManyMissedCallCaseInputObjectSchema } from './TeleconsultCreateManyMissedCallCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateManyMissedCallCaseInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => TeleconsultCreateManyMissedCallCaseInputObjectSchema),
        z
          .lazy(() => TeleconsultCreateManyMissedCallCaseInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const TeleconsultCreateManyMissedCallCaseInputEnvelopeObjectSchema =
  Schema
