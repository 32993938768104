import { z } from 'zod'
import { SuggestedProcedureItemSelectObjectSchema } from './SuggestedProcedureItemSelect.schema'
import { SuggestedProcedureItemIncludeObjectSchema } from './SuggestedProcedureItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemArgs> = z
  .object({
    select: z.lazy(() => SuggestedProcedureItemSelectObjectSchema).optional(),
    include: z.lazy(() => SuggestedProcedureItemIncludeObjectSchema).optional(),
  })
  .strict()

export const SuggestedProcedureItemArgsObjectSchema = Schema
