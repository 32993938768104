import { z } from 'zod'
import { CouponGroupWhereInputObjectSchema } from './CouponGroupWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupRelationFilter> = z
  .object({
    is: z.lazy(() => CouponGroupWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => CouponGroupWhereInputObjectSchema).optional(),
  })
  .strict()

export const CouponGroupRelationFilterObjectSchema = Schema
