import { z } from 'zod'

export const ArticlePictureScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'articleId',
  'url',
  'rank',
])
