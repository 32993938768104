import { z } from 'zod'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumNotificationActionTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => NotificationActionTypeSchema).optional(),
    })
    .strict()

export const EnumNotificationActionTypeFieldUpdateOperationsInputObjectSchema =
  Schema
