import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SkuCountOrderByAggregateInputObjectSchema } from './SkuCountOrderByAggregateInput.schema'
import { SkuAvgOrderByAggregateInputObjectSchema } from './SkuAvgOrderByAggregateInput.schema'
import { SkuMaxOrderByAggregateInputObjectSchema } from './SkuMaxOrderByAggregateInput.schema'
import { SkuMinOrderByAggregateInputObjectSchema } from './SkuMinOrderByAggregateInput.schema'
import { SkuSumOrderByAggregateInputObjectSchema } from './SkuSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    sku: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    _count: z.lazy(() => SkuCountOrderByAggregateInputObjectSchema).optional(),
    _avg: z.lazy(() => SkuAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => SkuMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => SkuMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => SkuSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const SkuOrderByWithAggregationInputObjectSchema = Schema
