import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DiscountSettingCountOrderByAggregateInputObjectSchema } from './DiscountSettingCountOrderByAggregateInput.schema'
import { DiscountSettingAvgOrderByAggregateInputObjectSchema } from './DiscountSettingAvgOrderByAggregateInput.schema'
import { DiscountSettingMaxOrderByAggregateInputObjectSchema } from './DiscountSettingMaxOrderByAggregateInput.schema'
import { DiscountSettingMinOrderByAggregateInputObjectSchema } from './DiscountSettingMinOrderByAggregateInput.schema'
import { DiscountSettingSumOrderByAggregateInputObjectSchema } from './DiscountSettingSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    scope: z.lazy(() => SortOrderSchema).optional(),
    itemType: z.lazy(() => SortOrderSchema).optional(),
    items: z.lazy(() => SortOrderSchema).optional(),
    settingGroupId: z.lazy(() => SortOrderSchema).optional(),
    groupId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => DiscountSettingCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => DiscountSettingAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => DiscountSettingMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => DiscountSettingMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => DiscountSettingSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DiscountSettingOrderByWithAggregationInputObjectSchema = Schema
