import { z } from 'zod'
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema'
import { UserUpdateWithoutPartnerInputObjectSchema } from './UserUpdateWithoutPartnerInput.schema'
import { UserUncheckedUpdateWithoutPartnerInputObjectSchema } from './UserUncheckedUpdateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserUpdateWithWhereUniqueWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => UserWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => UserUpdateWithoutPartnerInputObjectSchema),
      z.lazy(() => UserUncheckedUpdateWithoutPartnerInputObjectSchema),
    ]),
  })
  .strict()

export const UserUpdateWithWhereUniqueWithoutPartnerInputObjectSchema = Schema
