import { z } from 'zod'
import { BrandSelectObjectSchema } from './BrandSelect.schema'
import { BrandIncludeObjectSchema } from './BrandInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandArgs> = z
  .object({
    select: z.lazy(() => BrandSelectObjectSchema).optional(),
    include: z.lazy(() => BrandIncludeObjectSchema).optional(),
  })
  .strict()

export const BrandArgsObjectSchema = Schema
