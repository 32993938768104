import { z } from 'zod'
import { CoinTransactionScalarWhereInputObjectSchema } from './CoinTransactionScalarWhereInput.schema'
import { CoinTransactionUpdateManyMutationInputObjectSchema } from './CoinTransactionUpdateManyMutationInput.schema'
import { CoinTransactionUncheckedUpdateManyWithoutCoinTransactionsInputObjectSchema } from './CoinTransactionUncheckedUpdateManyWithoutCoinTransactionsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUpdateManyWithWhereWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => CoinTransactionScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CoinTransactionUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            CoinTransactionUncheckedUpdateManyWithoutCoinTransactionsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoinTransactionUpdateManyWithWhereWithoutPatientInputObjectSchema =
  Schema
