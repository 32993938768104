import { z } from 'zod'
import { AppointmentUncheckedCreateNestedOneWithoutScheduleInputObjectSchema } from './AppointmentUncheckedCreateNestedOneWithoutScheduleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    doctorId: z.number(),
    availableAt: z.date(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    appointment: z
      .lazy(
        () =>
          AppointmentUncheckedCreateNestedOneWithoutScheduleInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ScheduleUncheckedCreateInputObjectSchema = Schema
