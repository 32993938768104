import { z } from 'zod'
import { SuggestedProcedureItemWhereInputObjectSchema } from './SuggestedProcedureItemWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemListRelationFilter> = z
  .object({
    every: z
      .lazy(() => SuggestedProcedureItemWhereInputObjectSchema)
      .optional(),
    some: z.lazy(() => SuggestedProcedureItemWhereInputObjectSchema).optional(),
    none: z.lazy(() => SuggestedProcedureItemWhereInputObjectSchema).optional(),
  })
  .strict()

export const SuggestedProcedureItemListRelationFilterObjectSchema = Schema
