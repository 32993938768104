import { z } from 'zod'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumUseFrequencyFilter> = z
  .object({
    equals: z.lazy(() => UseFrequencySchema).optional(),
    in: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => UseFrequencySchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => UseFrequencySchema),
        z.lazy(() => NestedEnumUseFrequencyFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumUseFrequencyFilterObjectSchema = Schema
