import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateManyOrderInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    shippingProvider: z.string(),
    trackingNumber: z.string(),
    status: z.string(),
    deliveredAt: z.date().optional().nullable(),
    partnerName: z.string(),
  })
  .strict()

export const DeliveryCreateManyOrderInputObjectSchema = Schema
