import { Add as AddIcon, Publish as PublishIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { FC, useState } from 'react'
import {
  Datagrid,
  EditButton,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import {
  CustomBooleanField,
  CustomI18nField,
} from '../../../components/CustomField'
import { FormDivider } from '../../../components/FormInput/divider'
import { FlashsaleProductImportModal } from '../importFlashsale/importModal'

export const FlashsaleItemSection: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const record = useRecordContext()
  const translate = useTranslate()

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.flashsaleItemProduct.title.product_item')}
        </Typography>
        <ButtonGroup>
          <Button
            variant="outlined"
            startIcon={<PublishIcon />}
            sx={{ mb: 1 }}
            onClick={() => setOpenModal(true)}
          >
            {translate('resources.flashsaleProduct.action.import_button')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ mb: 1 }}
            onClick={() => {
              window.open(
                `/flashsaleItemProduct/create?flashsaleId=${record.id}`,
                '_blank',
              )
            }}
          >
            {translate('resources.flashsaleProduct.action.add_flashsale_item')}
          </Button>
        </ButtonGroup>
      </Box>
      <FormDivider />
      <ReferenceManyField
        reference="flashsaleItemProduct"
        target="flashsaleId"
        perPage={999}
        sort={{ field: 'priority', order: 'DESC' }}
      >
        <Datagrid
          sx={{ width: '100%', minWidth: '800px', overflowX: 'auto' }}
          bulkActionButtons={false}
        >
          <ReferenceField
            label="ID"
            reference="productVariant"
            source="variantId"
            link={false}
          >
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField
            label="SKU"
            reference="productVariant"
            source="variantId"
            link={false}
          >
            <TextField source="sku" />
          </ReferenceField>
          <ReferenceField
            label="Name"
            reference="productVariant"
            source="variantId"
            link={false}
          >
            <ReferenceField reference="product" source="productId" link={false}>
              <CustomI18nField fieldTr="nameTr" />
            </ReferenceField>{' '}
            <CustomI18nField fieldTr="descriptionTr" />
          </ReferenceField>
          <ReferenceField
            label="Cost"
            reference="productVariant"
            source="variantId"
            link={false}
            textAlign="right"
          >
            <NumberField source="cost" />
          </ReferenceField>
          <ReferenceField
            label="Normal Price"
            reference="productVariant"
            source="variantId"
            link={false}
            textAlign="right"
          >
            <NumberField source="sellingPrice" />
          </ReferenceField>
          <NumberField label="Campaign Price" source="price" sortable={false} />
          <NumberField label="Sold" source="sold" sortable={false} />
          <NumberField label="Quota" source="saleLimit" sortable={false} />
          <CustomBooleanField source="active" label="Active" sortable={false} />
          <TextField label="Priority" source="priority" sortable={false} />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <FlashsaleProductImportModal
        open={openModal}
        mode={'UPDATE'}
        onClose={() => {
          setOpenModal(false)
        }}
        disableCloseOnBackdrop
      />
    </>
  )
}
