import { z } from 'zod'
import { ProductPictureOrderByWithRelationInputObjectSchema } from './objects/ProductPictureOrderByWithRelationInput.schema'
import { ProductPictureWhereInputObjectSchema } from './objects/ProductPictureWhereInput.schema'
import { ProductPictureWhereUniqueInputObjectSchema } from './objects/ProductPictureWhereUniqueInput.schema'
import { ProductPictureScalarFieldEnumSchema } from './enums/ProductPictureScalarFieldEnum.schema'

export const ProductPictureFindManySchema = z.object({
  orderBy: z
    .union([
      ProductPictureOrderByWithRelationInputObjectSchema,
      ProductPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductPictureWhereInputObjectSchema.optional(),
  cursor: ProductPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductPictureScalarFieldEnumSchema).optional(),
})
