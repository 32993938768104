import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionOrderByRelationAggregateInput> = z
  .object({
    _count: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const CoinTransactionOrderByRelationAggregateInputObjectSchema = Schema
