import { z } from 'zod'
import { NotificationPatientCreateWithoutPatientInputObjectSchema } from './NotificationPatientCreateWithoutPatientInput.schema'
import { NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutPatientInput.schema'
import { NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema } from './NotificationPatientCreateOrConnectWithoutPatientInput.schema'
import { NotificationPatientCreateManyPatientInputEnvelopeObjectSchema } from './NotificationPatientCreateManyPatientInputEnvelope.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateNestedManyWithoutPatientInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => NotificationPatientCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationPatientCreateWithoutPatientInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => NotificationPatientCreateManyPatientInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationPatientCreateNestedManyWithoutPatientInputObjectSchema =
  Schema
