import { z } from 'zod'
import { SegmentCreateWithoutNotificationsInputObjectSchema } from './SegmentCreateWithoutNotificationsInput.schema'
import { SegmentUncheckedCreateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedCreateWithoutNotificationsInput.schema'
import { SegmentCreateOrConnectWithoutNotificationsInputObjectSchema } from './SegmentCreateOrConnectWithoutNotificationsInput.schema'
import { SegmentUpsertWithWhereUniqueWithoutNotificationsInputObjectSchema } from './SegmentUpsertWithWhereUniqueWithoutNotificationsInput.schema'
import { SegmentWhereUniqueInputObjectSchema } from './SegmentWhereUniqueInput.schema'
import { SegmentUpdateWithWhereUniqueWithoutNotificationsInputObjectSchema } from './SegmentUpdateWithWhereUniqueWithoutNotificationsInput.schema'
import { SegmentUpdateManyWithWhereWithoutNotificationsInputObjectSchema } from './SegmentUpdateManyWithWhereWithoutNotificationsInput.schema'
import { SegmentScalarWhereInputObjectSchema } from './SegmentScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUpdateManyWithoutNotificationsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema),
          z
            .lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema)
            .array(),
          z.lazy(
            () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => SegmentCreateOrConnectWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () => SegmentCreateOrConnectWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              SegmentUpsertWithWhereUniqueWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SegmentUpsertWithWhereUniqueWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      set: z
        .union([
          z.lazy(() => SegmentWhereUniqueInputObjectSchema),
          z.lazy(() => SegmentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => SegmentWhereUniqueInputObjectSchema),
          z.lazy(() => SegmentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => SegmentWhereUniqueInputObjectSchema),
          z.lazy(() => SegmentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => SegmentWhereUniqueInputObjectSchema),
          z.lazy(() => SegmentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              SegmentUpdateWithWhereUniqueWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SegmentUpdateWithWhereUniqueWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              SegmentUpdateManyWithWhereWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SegmentUpdateManyWithWhereWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => SegmentScalarWhereInputObjectSchema),
          z.lazy(() => SegmentScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const SegmentUpdateManyWithoutNotificationsNestedInputObjectSchema =
  Schema
