import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { EnumAppointmentTypeFilterObjectSchema } from './EnumAppointmentTypeFilter.schema'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { EnumAppointmentStatusFilterObjectSchema } from './EnumAppointmentStatusFilter.schema'
import { AppointmentStatusSchema } from '../enums/AppointmentStatus.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { CaseRelationFilterObjectSchema } from './CaseRelationFilter.schema'
import { CaseWhereInputObjectSchema } from './CaseWhereInput.schema'
import { ScheduleRelationFilterObjectSchema } from './ScheduleRelationFilter.schema'
import { ScheduleWhereInputObjectSchema } from './ScheduleWhereInput.schema'
import { TeleconsultRelationFilterObjectSchema } from './TeleconsultRelationFilter.schema'
import { TeleconsultWhereInputObjectSchema } from './TeleconsultWhereInput.schema'
import { CaseSummaryRelationFilterObjectSchema } from './CaseSummaryRelationFilter.schema'
import { CaseSummaryWhereInputObjectSchema } from './CaseSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => AppointmentWhereInputObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => AppointmentWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => AppointmentWhereInputObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    doctorId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumAppointmentTypeFilterObjectSchema),
        z.lazy(() => AppointmentTypeSchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumAppointmentStatusFilterObjectSchema),
        z.lazy(() => AppointmentStatusSchema),
      ])
      .optional(),
    caseId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    scheduleId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    appointmentAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    cancelAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    cancelDetail: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    teleconsultId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    case: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional(),
    schedule: z
      .union([
        z.lazy(() => ScheduleRelationFilterObjectSchema),
        z.lazy(() => ScheduleWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    teleconsult: z
      .union([
        z.lazy(() => TeleconsultRelationFilterObjectSchema),
        z.lazy(() => TeleconsultWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    followUpCaseSummary: z
      .union([
        z.lazy(() => CaseSummaryRelationFilterObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const AppointmentWhereInputObjectSchema = Schema
