import { z } from 'zod'
import { IdentityTypeSchema } from '../enums/IdentityType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumIdentityTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => IdentityTypeSchema).optional(),
  })
  .strict()

export const EnumIdentityTypeFieldUpdateOperationsInputObjectSchema = Schema
