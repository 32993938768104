import { z } from 'zod'
import { OldPatientCreateInputObjectSchema } from './objects/OldPatientCreateInput.schema'
import { OldPatientUncheckedCreateInputObjectSchema } from './objects/OldPatientUncheckedCreateInput.schema'

export const OldPatientCreateOneSchema = z.object({
  data: z.union([
    OldPatientCreateInputObjectSchema,
    OldPatientUncheckedCreateInputObjectSchema,
  ]),
})
