import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentUpdateWithoutConsentInputObjectSchema } from './AcceptedConsentUpdateWithoutConsentInput.schema'
import { AcceptedConsentUncheckedUpdateWithoutConsentInputObjectSchema } from './AcceptedConsentUncheckedUpdateWithoutConsentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateWithWhereUniqueWithoutConsentInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => AcceptedConsentUpdateWithoutConsentInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedUpdateWithoutConsentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentUpdateWithWhereUniqueWithoutConsentInputObjectSchema =
  Schema
