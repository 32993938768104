import { z } from 'zod'

export const CouponScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'couponId',
  'code',
  'type',
  'descriptionTr',
  'discount',
  'coin',
  'orderId',
  'v2CouponId',
])
