import { z } from 'zod'
import { AddressWhereUniqueInputObjectSchema } from './AddressWhereUniqueInput.schema'
import { AddressUpdateWithoutPatientInputObjectSchema } from './AddressUpdateWithoutPatientInput.schema'
import { AddressUncheckedUpdateWithoutPatientInputObjectSchema } from './AddressUncheckedUpdateWithoutPatientInput.schema'
import { AddressCreateWithoutPatientInputObjectSchema } from './AddressCreateWithoutPatientInput.schema'
import { AddressUncheckedCreateWithoutPatientInputObjectSchema } from './AddressUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressUpsertWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AddressWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => AddressUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => AddressUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => AddressCreateWithoutPatientInputObjectSchema),
        z.lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const AddressUpsertWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
