import { z } from 'zod'
import { OldPatientOrderByWithRelationInputObjectSchema } from './objects/OldPatientOrderByWithRelationInput.schema'
import { OldPatientWhereInputObjectSchema } from './objects/OldPatientWhereInput.schema'
import { OldPatientWhereUniqueInputObjectSchema } from './objects/OldPatientWhereUniqueInput.schema'
import { OldPatientScalarFieldEnumSchema } from './enums/OldPatientScalarFieldEnum.schema'

export const OldPatientFindFirstSchema = z.object({
  orderBy: z
    .union([
      OldPatientOrderByWithRelationInputObjectSchema,
      OldPatientOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OldPatientWhereInputObjectSchema.optional(),
  cursor: OldPatientWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(OldPatientScalarFieldEnumSchema).optional(),
})
