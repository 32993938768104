import { z } from 'zod'
import { DiagnosisSelectObjectSchema } from './DiagnosisSelect.schema'
import { DiagnosisIncludeObjectSchema } from './DiagnosisInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisArgs> = z
  .object({
    select: z.lazy(() => DiagnosisSelectObjectSchema).optional(),
    include: z.lazy(() => DiagnosisIncludeObjectSchema).optional(),
  })
  .strict()

export const DiagnosisArgsObjectSchema = Schema
