import { FC } from 'react'
import {
  FileStorageInput,
  FileStorageInputWithMultipleAndCropProps,
} from './FileStorageInput'

export const ImageStorageInput: FC<FileStorageInputWithMultipleAndCropProps> = (
  props,
) => {
  return (
    <FileStorageInput
      {...props}
      labelMultiple={props.labelMultiple || 'ra.input.image.upload_several'}
      labelSingle={props.labelSingle || 'ra.input.image.upload_single'}
      fileType="IMAGE"
    />
  )
}
