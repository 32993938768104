import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    status: z.literal(true).optional(),
    expiredAt: z.literal(true).optional(),
    shouldExpiresAt: z.literal(true).optional(),
    orderNumber: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    customerName: z.literal(true).optional(),
    customerTelNo: z.literal(true).optional(),
    orderType: z.literal(true).optional(),
    purchaseOrigin: z.literal(true).optional(),
    purchaseRef: z.literal(true).optional(),
    editedProductItemsAt: z.literal(true).optional(),
    editedProductItemsBy: z.literal(true).optional(),
    shippingFee: z.literal(true).optional(),
    shippingFeeDiscount: z.literal(true).optional(),
    shippingType: z.literal(true).optional(),
    shippingProviderId: z.literal(true).optional(),
    recipient: z.literal(true).optional(),
    shippingAddress: z.literal(true).optional(),
    shippingTelNo: z.literal(true).optional(),
    subDistrict: z.literal(true).optional(),
    subDistrictKey: z.literal(true).optional(),
    district: z.literal(true).optional(),
    districtKey: z.literal(true).optional(),
    province: z.literal(true).optional(),
    provinceKey: z.literal(true).optional(),
    postcode: z.literal(true).optional(),
    isPaid: z.literal(true).optional(),
    isHold: z.literal(true).optional(),
    approvedBy: z.literal(true).optional(),
    approvedAt: z.literal(true).optional(),
    canceledBy: z.literal(true).optional(),
    canceledAt: z.literal(true).optional(),
    cancelChannel: z.literal(true).optional(),
    noteToPatient: z.literal(true).optional(),
    useCoin: z.literal(true).optional(),
    v2InvoiceId: z.literal(true).optional(),
    holdingNote: z.literal(true).optional(),
    holdingBy: z.literal(true).optional(),
    holdingAt: z.literal(true).optional(),
    isDeclinedSuggestedItems: z.literal(true).optional(),
    declinedSuggestedItemsReason: z.literal(true).optional(),
  })
  .strict()

export const OrderMaxAggregateInputObjectSchema = Schema
