import { z } from 'zod'
import { ProcedureCategoryWhereInputObjectSchema } from './objects/ProcedureCategoryWhereInput.schema'
import { ProcedureCategoryOrderByWithAggregationInputObjectSchema } from './objects/ProcedureCategoryOrderByWithAggregationInput.schema'
import { ProcedureCategoryScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureCategoryScalarWhereWithAggregatesInput.schema'
import { ProcedureCategoryScalarFieldEnumSchema } from './enums/ProcedureCategoryScalarFieldEnum.schema'

export const ProcedureCategoryGroupBySchema = z.object({
  where: ProcedureCategoryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureCategoryOrderByWithAggregationInputObjectSchema,
    ProcedureCategoryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProcedureCategoryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureCategoryScalarFieldEnumSchema),
})
