import { z } from 'zod'
import { VariantArgsObjectSchema } from './VariantArgs.schema'
import { WarehouseArgsObjectSchema } from './WarehouseArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseInclude> = z
  .object({
    variant: z
      .union([z.boolean(), z.lazy(() => VariantArgsObjectSchema)])
      .optional(),
    warehouse: z
      .union([z.boolean(), z.lazy(() => WarehouseArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const VariantWarehouseIncludeObjectSchema = Schema
