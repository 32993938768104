import { z } from 'zod'
import { DiagnosisOrderByWithRelationInputObjectSchema } from './objects/DiagnosisOrderByWithRelationInput.schema'
import { DiagnosisWhereInputObjectSchema } from './objects/DiagnosisWhereInput.schema'
import { DiagnosisWhereUniqueInputObjectSchema } from './objects/DiagnosisWhereUniqueInput.schema'
import { DiagnosisCountAggregateInputObjectSchema } from './objects/DiagnosisCountAggregateInput.schema'
import { DiagnosisMinAggregateInputObjectSchema } from './objects/DiagnosisMinAggregateInput.schema'
import { DiagnosisMaxAggregateInputObjectSchema } from './objects/DiagnosisMaxAggregateInput.schema'
import { DiagnosisAvgAggregateInputObjectSchema } from './objects/DiagnosisAvgAggregateInput.schema'
import { DiagnosisSumAggregateInputObjectSchema } from './objects/DiagnosisSumAggregateInput.schema'

export const DiagnosisAggregateSchema = z.object({
  orderBy: z
    .union([
      DiagnosisOrderByWithRelationInputObjectSchema,
      DiagnosisOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DiagnosisWhereInputObjectSchema.optional(),
  cursor: DiagnosisWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), DiagnosisCountAggregateInputObjectSchema])
    .optional(),
  _min: DiagnosisMinAggregateInputObjectSchema.optional(),
  _max: DiagnosisMaxAggregateInputObjectSchema.optional(),
  _avg: DiagnosisAvgAggregateInputObjectSchema.optional(),
  _sum: DiagnosisSumAggregateInputObjectSchema.optional(),
})
