import { z } from 'zod'
import { ShippingProviderCreateNestedOneWithoutAreasInputObjectSchema } from './ShippingProviderCreateNestedOneWithoutAreasInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaCreateInput> = z
  .object({
    postCode: z.string(),
    shippingProvider: z.lazy(
      () => ShippingProviderCreateNestedOneWithoutAreasInputObjectSchema,
    ),
  })
  .strict()

export const ExpressShippingAreaCreateInputObjectSchema = Schema
