import { z } from 'zod'
import { OfficeHourSelectObjectSchema } from './OfficeHourSelect.schema'
import { OfficeHourIncludeObjectSchema } from './OfficeHourInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourArgs> = z
  .object({
    select: z.lazy(() => OfficeHourSelectObjectSchema).optional(),
    include: z.lazy(() => OfficeHourIncludeObjectSchema).optional(),
  })
  .strict()

export const OfficeHourArgsObjectSchema = Schema
