import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomTextInput } from '../../components/CustomInput'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { LoadingBackdrop } from '../../components/LoadingBackdrop'
import { FormState } from '../../enum'
import { RejectButton } from './edit-approve-section/buttonReject'
import { SaveAndApproveButton } from './edit-approve-section/buttonSaveAndApprove'
import { CertificateSection } from './edit-approve-section/certificate'
import { ClinicHospitalSection } from './edit-approve-section/clinicHospital'
import { LicenseSection } from './edit-approve-section/license'
import { ProfileSection } from './edit-approve-section/profile'
import { SpecialtyAndExpertiseSection } from './edit-approve-section/specialtyAndExpertise'
import { transformDoctorKycData } from './transform'

const DoctorKycDataEditToolbar = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { getValues } = useFormContext()

  const canReject = () => {
    let canReject = false
    const { ...data } = getValues()

    if (data?.validator) {
      for (const value of Object.values(data.validator)) {
        if (value === '0') {
          canReject = true
          break
        }
      }
    }

    return canReject
  }

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <LoadingBackdrop open={isLoading} />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctorKycData" />
        <SaveButton alwaysEnable />
        {getValues()?.registerStatus === 'SUBMITTED' && [
          <SaveAndApproveButton
            disabled={canReject()}
            setIsLoading={setIsLoading}
          />,
          <RejectButton disabled={!canReject()} setIsLoading={setIsLoading} />,
        ]}
      </Box>
    </Toolbar>
  )
}

const DoctorKycDataEditForm = () => {
  return (
    <SimpleForm toolbar={<DoctorKycDataEditToolbar />}>
      <ProfileSection />
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <LicenseSection state={FormState.EDIT} />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <SpecialtyAndExpertiseSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ClinicHospitalSection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CertificateSection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Reject Note
          </Typography>
          <CustomTextInput fullWidth source="rejectNote" />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const DoctorKycDataEdit = () => (
  <EditSaveRouteBack
    transform={transformDoctorKycData}
    mutationMode="pessimistic"
    redirect="list"
  >
    <Card>
      <CardContent sx={{ p: 2 }}>
        <DoctorKycDataEditForm />
      </CardContent>
    </Card>
  </EditSaveRouteBack>
)
