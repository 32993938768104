import { z } from 'zod'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'
import { NestedEnumAppointmentEventNullableFilterObjectSchema } from './NestedEnumAppointmentEventNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumAppointmentEventNullableFilter> = z
  .object({
    equals: z
      .lazy(() => AppointmentEventSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => AppointmentEventSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => AppointmentEventSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => AppointmentEventSchema),
        z.lazy(() => NestedEnumAppointmentEventNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumAppointmentEventNullableFilterObjectSchema = Schema
