import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutCoinTransactionsInputObjectSchema } from './PatientCreateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedCreateWithoutCoinTransactionsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutCoinTransactionsInput> =
  z
    .object({
      where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PatientCreateWithoutCoinTransactionsInputObjectSchema),
        z.lazy(
          () => PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PatientCreateOrConnectWithoutCoinTransactionsInputObjectSchema =
  Schema
