import { z } from 'zod'
import { NotificationPatientWhereInputObjectSchema } from './NotificationPatientWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientListRelationFilter> = z
  .object({
    every: z.lazy(() => NotificationPatientWhereInputObjectSchema).optional(),
    some: z.lazy(() => NotificationPatientWhereInputObjectSchema).optional(),
    none: z.lazy(() => NotificationPatientWhereInputObjectSchema).optional(),
  })
  .strict()

export const NotificationPatientListRelationFilterObjectSchema = Schema
