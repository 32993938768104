import { z } from 'zod'
import { FlashsaleItemCreateNestedManyWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateNestedManyWithoutFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    createdBy: z.number().optional(),
    updatedBy: z.number().optional(),
    name: z.string(),
    description: z.string().optional().nullable(),
    startAt: z.date(),
    endAt: z.date(),
    active: z.boolean().optional(),
    items: z
      .lazy(
        () => FlashsaleItemCreateNestedManyWithoutFlashsaleInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const FlashsaleCreateInputObjectSchema = Schema
