import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { NullableEnumGenderFieldUpdateOperationsInputObjectSchema } from './NullableEnumGenderFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { AcceptedConsentUpdateManyWithoutPatientNestedInputObjectSchema } from './AcceptedConsentUpdateManyWithoutPatientNestedInput.schema'
import { KycDataUpdateOneWithoutPatientNestedInputObjectSchema } from './KycDataUpdateOneWithoutPatientNestedInput.schema'
import { HealthInfoUpdateOneWithoutPatientNestedInputObjectSchema } from './HealthInfoUpdateOneWithoutPatientNestedInput.schema'
import { NotificationPatientUpdateManyWithoutPatientNestedInputObjectSchema } from './NotificationPatientUpdateManyWithoutPatientNestedInput.schema'
import { AddressUpdateManyWithoutPatientNestedInputObjectSchema } from './AddressUpdateManyWithoutPatientNestedInput.schema'
import { CoinTransactionUpdateManyWithoutPatientNestedInputObjectSchema } from './CoinTransactionUpdateManyWithoutPatientNestedInput.schema'
import { MyReviewUpdateManyWithoutPatientNestedInputObjectSchema } from './MyReviewUpdateManyWithoutPatientNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateWithoutIdentitiesInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    prefix: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    firstname: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    lastname: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    nickname: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    gender: z
      .union([
        z.lazy(() => GenderSchema),
        z.lazy(() => NullableEnumGenderFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    birthDate: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    picture: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isTest: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    active: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    registered: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    drugAllergy: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    receiveNotifications: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    activityNotifications: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    chatNotifications: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    orderNotifications: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    omiseCustomerId: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    freshchatUserId: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    freshchatRestoreId: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    coin: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    v2PatientId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    acceptedConsents: z
      .lazy(
        () => AcceptedConsentUpdateManyWithoutPatientNestedInputObjectSchema,
      )
      .optional(),
    kycData: z
      .lazy(() => KycDataUpdateOneWithoutPatientNestedInputObjectSchema)
      .optional(),
    healthCheckUpInfo: z
      .lazy(() => HealthInfoUpdateOneWithoutPatientNestedInputObjectSchema)
      .optional(),
    notifications: z
      .lazy(
        () =>
          NotificationPatientUpdateManyWithoutPatientNestedInputObjectSchema,
      )
      .optional(),
    address: z
      .lazy(() => AddressUpdateManyWithoutPatientNestedInputObjectSchema)
      .optional(),
    coinTransactions: z
      .lazy(
        () => CoinTransactionUpdateManyWithoutPatientNestedInputObjectSchema,
      )
      .optional(),
    myReviews: z
      .lazy(() => MyReviewUpdateManyWithoutPatientNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const PatientUpdateWithoutIdentitiesInputObjectSchema = Schema
