import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutShippingProviderInputObjectSchema } from './OrderUpdateWithoutShippingProviderInput.schema'
import { OrderUncheckedUpdateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedUpdateWithoutShippingProviderInput.schema'
import { OrderCreateWithoutShippingProviderInputObjectSchema } from './OrderCreateWithoutShippingProviderInput.schema'
import { OrderUncheckedCreateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedCreateWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithWhereUniqueWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => OrderUpdateWithoutShippingProviderInputObjectSchema),
        z.lazy(
          () => OrderUncheckedUpdateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema),
        z.lazy(
          () => OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OrderUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema =
  Schema
