import { z } from 'zod'
import { AppointmentUpdateWithoutTeleconsultInputObjectSchema } from './AppointmentUpdateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedUpdateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedUpdateWithoutTeleconsultInput.schema'
import { AppointmentCreateWithoutTeleconsultInputObjectSchema } from './AppointmentCreateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedCreateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpsertWithoutTeleconsultInput> = z
  .object({
    update: z.union([
      z.lazy(() => AppointmentUpdateWithoutTeleconsultInputObjectSchema),
      z.lazy(
        () => AppointmentUncheckedUpdateWithoutTeleconsultInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => AppointmentCreateWithoutTeleconsultInputObjectSchema),
      z.lazy(
        () => AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const AppointmentUpsertWithoutTeleconsultInputObjectSchema = Schema
