import { Avatar, SxProps } from '@mui/material'
import get from 'lodash.get'
import { FC } from 'react'
import { FieldProps, useRecordContext } from 'react-admin'
import { imagekit } from '../imagekit'

interface Props extends FieldProps {
  source: string
  alt?: string // namimg for fallback broken image
  sx?: SxProps
  size?: string
}

export const AvatarField: FC<Props> = ({ source, alt, size = '25', sx }) => {
  const record = useRecordContext()
  if (!record) return null
  // image kit set size by query string
  const _url = get(record, source) as string
  let url = ''
  if (_url) {
    if (_url.startsWith(imagekit.options.urlEndpoint)) {
      // url = imagekit.url({
      //   src: _url,
      //   transformation: [{ width: size, height: size, focus: 'face' }],
      // })
      url = _url
    } else {
      url = _url
    }
  }
  // default image
  return (
    <Avatar
      alt={alt}
      src={url}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
    />
  )
}
