import { Box, Divider } from '@mui/material'
import axios from 'axios'
import { SaveButton, SimpleForm, Toolbar, required } from 'react-admin'
import { CustomTextInput } from '../../components/CustomInput'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ImageStorageInput } from '../../components/ImageStorageInput'
import { adminGatewayClient } from '../../service'
import { PasswordSection } from './crete-edit-section/password'
import { RoleSelectInput } from './crete-edit-section/roleInput'
import { transformAdmin } from './transform'

const AdminEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="admin" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

export const AdminEdit = () => (
  <EditSaveRouteBack transform={transformAdmin}>
    <SimpleForm toolbar={<AdminEditToolbar />}>
      <ImageStorageInput
        source="picture"
        label="Picture"
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.admin.generateUploadPictureUrl.query({
              extension: file.name.split('.').pop() ?? '',
            })
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
      ></ImageStorageInput>
      <CustomTextInput source="username" validate={required()} />
      <Divider sx={{ width: '100%', mb: 2 }} />
      <PasswordSection />
      <Divider sx={{ width: '100%', mb: 2 }} />
      <RoleSelectInput />
      <CustomTextInput source="prefix" />
      <CustomTextInput source="firstname" />
      <CustomTextInput source="lastname" />
      <CustomTextInput source="nickname" />
    </SimpleForm>
  </EditSaveRouteBack>
)
