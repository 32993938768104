import { z } from 'zod'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { NestedEnumNotificationActionTypeFilterObjectSchema } from './NestedEnumNotificationActionTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumNotificationActionTypeFilter> = z
  .object({
    equals: z.lazy(() => NotificationActionTypeSchema).optional(),
    in: z
      .lazy(() => NotificationActionTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => NotificationActionTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => NotificationActionTypeSchema),
        z.lazy(() => NestedEnumNotificationActionTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumNotificationActionTypeFilterObjectSchema = Schema
