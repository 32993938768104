import { z } from 'zod'
import { PartnerCreateNestedOneWithoutUsersInputObjectSchema } from './PartnerCreateNestedOneWithoutUsersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    email: z.string(),
    password: z.string(),
    active: z.boolean().optional(),
    partner: z.lazy(() => PartnerCreateNestedOneWithoutUsersInputObjectSchema),
  })
  .strict()

export const UserCreateInputObjectSchema = Schema
