import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { NestedEnumUseTimeWithAggregatesFilterObjectSchema } from './NestedEnumUseTimeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumUseTimeFilterObjectSchema } from './NestedEnumUseTimeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUseTimeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => UseTimeSchema).optional(),
    in: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => NestedEnumUseTimeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumUseTimeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumUseTimeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumUseTimeWithAggregatesFilterObjectSchema = Schema
