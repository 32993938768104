import { z } from 'zod'

export const PartnerScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'type',
  'nameTr',
  'logo',
  'descriptionTr',
  'website',
  'phone',
  'active',
  'isExpertise',
  'priority',
  'isSXEasy',
  'searchField',
  'taxId',
  'v2HospitalId',
  'v2StoreId',
])
