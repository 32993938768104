import * as z from "zod"
import { CompleteOrder, RelatedOrderModel, CompleteProductItem, RelatedProductItemModel, CompleteDeliveryLog, RelatedDeliveryLogModel } from "./index"

export const DeliveryModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  shippingProvider: z.string(),
  trackingNumber: z.string(),
  status: z.string(),
  deliveredAt: z.date().nullish(),
  partnerName: z.string(),
  orderId: z.number().int(),
})

export interface CompleteDelivery extends z.infer<typeof DeliveryModel> {
  order: CompleteOrder
  productItems: CompleteProductItem[]
  deliveryLogs: CompleteDeliveryLog[]
}

/**
 * RelatedDeliveryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDeliveryModel: z.ZodSchema<CompleteDelivery> = z.lazy(() => DeliveryModel.extend({
  order: RelatedOrderModel,
  productItems: RelatedProductItemModel.array(),
  deliveryLogs: RelatedDeliveryLogModel.array(),
}))
