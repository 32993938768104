import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { EnumPaymentMethodFilterObjectSchema } from './EnumPaymentMethodFilter.schema'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { EnumProcedureUsageStatusFilterObjectSchema } from './EnumProcedureUsageStatusFilter.schema'
import { ProcedureUsageStatusSchema } from '../enums/ProcedureUsageStatus.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProcedureOrderScalarWhereInputObjectSchema),
        z.lazy(() => ProcedureOrderScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProcedureOrderScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProcedureOrderScalarWhereInputObjectSchema),
        z.lazy(() => ProcedureOrderScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    voucherNumber: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    orderNumber: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedureItemId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedureNameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    patientName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    patientTelNo: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    paymentMethod: z
      .union([
        z.lazy(() => EnumPaymentMethodFilterObjectSchema),
        z.lazy(() => PaymentMethodSchema),
      ])
      .optional(),
    installmentTerm: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    couponCode: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    flashsalePrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    cost: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    priceAbsorbedBySkinX: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    priceAbsorbedByPartner: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commission: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commissionRate: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    vat: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    vatRate: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    netIncome: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumProcedureUsageStatusFilterObjectSchema),
        z.lazy(() => ProcedureUsageStatusSchema),
      ])
      .optional(),
    usedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    expireAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    note: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    partnerId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    v2StoreVoucherId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
  })
  .strict()

export const ProcedureOrderScalarWhereInputObjectSchema = Schema
