import { z } from 'zod'
import { FlashsaleCreateNestedOneWithoutItemsInputObjectSchema } from './FlashsaleCreateNestedOneWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateWithoutVariantInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number().optional(),
    startAt: z.date(),
    endAt: z.date(),
    price: z.number(),
    saleLimit: z.number(),
    sold: z.number(),
    flashsale: z.lazy(
      () => FlashsaleCreateNestedOneWithoutItemsInputObjectSchema,
    ),
  })
  .strict()

export const FlashsaleItemCreateWithoutVariantInputObjectSchema = Schema
