import { z } from 'zod'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'
import { CouponUncheckedCreateNestedManyWithoutGroupInputObjectSchema } from './CouponUncheckedCreateNestedManyWithoutGroupInput.schema'
import { DiscountSettingUncheckedCreateNestedManyWithoutGroupInputObjectSchema } from './DiscountSettingUncheckedCreateNestedManyWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CouponGroupUncheckedCreateWithoutConditionSettingInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      active: z.boolean().optional(),
      prefix: z.string().optional().nullable(),
      code: z.string().optional().nullable(),
      type: z.lazy(() => CouponTypeSchema),
      titleTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      tags: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
      picture: z.string(),
      discount: z.number(),
      discountType: z.lazy(() => DiscountTypeSchema),
      minTotal: z.number(),
      maxDiscount: z.number().optional().nullable(),
      startAt: z.date(),
      expireAt: z.date(),
      isPublic: z.boolean(),
      isOnlyNewPatient: z.boolean().optional(),
      note: z.string(),
      quota: z.number().optional(),
      quotaPerUser: z.number().optional().nullable(),
      createdBy: z.number().optional(),
      updatedBy: z.number().optional(),
      v2CreatedBy: z.number().optional().nullable(),
      v2UpdatedBy: z.number().optional().nullable(),
      coupons: z
        .lazy(
          () => CouponUncheckedCreateNestedManyWithoutGroupInputObjectSchema,
        )
        .optional(),
      discountSetting: z
        .lazy(
          () =>
            DiscountSettingUncheckedCreateNestedManyWithoutGroupInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema =
  Schema
