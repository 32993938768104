import { z } from 'zod'
import { NotificationPatientCreateWithoutNotificationInputObjectSchema } from './NotificationPatientCreateWithoutNotificationInput.schema'
import { NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutNotificationInput.schema'
import { NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema } from './NotificationPatientCreateOrConnectWithoutNotificationInput.schema'
import { NotificationPatientCreateManyNotificationInputEnvelopeObjectSchema } from './NotificationPatientCreateManyNotificationInputEnvelope.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateNestedManyWithoutNotificationInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => NotificationPatientCreateWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateWithoutNotificationInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            NotificationPatientCreateManyNotificationInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationPatientCreateNestedManyWithoutNotificationInputObjectSchema =
  Schema
