import { z } from 'zod'
import { BranchCreateManyPartnerInputObjectSchema } from './BranchCreateManyPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCreateManyPartnerInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => BranchCreateManyPartnerInputObjectSchema),
      z.lazy(() => BranchCreateManyPartnerInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const BranchCreateManyPartnerInputEnvelopeObjectSchema = Schema
