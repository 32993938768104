import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DeliveryLogCountOrderByAggregateInputObjectSchema } from './DeliveryLogCountOrderByAggregateInput.schema'
import { DeliveryLogAvgOrderByAggregateInputObjectSchema } from './DeliveryLogAvgOrderByAggregateInput.schema'
import { DeliveryLogMaxOrderByAggregateInputObjectSchema } from './DeliveryLogMaxOrderByAggregateInput.schema'
import { DeliveryLogMinOrderByAggregateInputObjectSchema } from './DeliveryLogMinOrderByAggregateInput.schema'
import { DeliveryLogSumOrderByAggregateInputObjectSchema } from './DeliveryLogSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deliveryId: z.lazy(() => SortOrderSchema).optional(),
    statusCode: z.lazy(() => SortOrderSchema).optional(),
    statusText: z.lazy(() => SortOrderSchema).optional(),
    statusDate: z.lazy(() => SortOrderSchema).optional(),
    rawData: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => DeliveryLogCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => DeliveryLogAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => DeliveryLogMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => DeliveryLogMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => DeliveryLogSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryLogOrderByWithAggregationInputObjectSchema = Schema
