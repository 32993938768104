import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutShippingProviderInputObjectSchema } from './OrderCreateWithoutShippingProviderInput.schema'
import { OrderUncheckedCreateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedCreateWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema),
        z.lazy(
          () => OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OrderCreateOrConnectWithoutShippingProviderInputObjectSchema =
  Schema
