import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteProcedureCategory, RelatedProcedureCategoryModel } from "./index"

export const CategoryModel = z.object({
  id: z.number().int(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  priority: z.number().int(),
  icon: z.string().nullish(),
  active: z.boolean(),
  path: z.string(),
  parentId: z.number().int().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export const CategoryModelResponse = z.object({
  id: z.number().int(),
  nameTr: jsonSchema,
  descriptionTr: jsonSchema,
  priority: z.number().int(),
  icon: z.string().nullish(),
  active: z.boolean(),
  path: z.string(),
  parentId: z.number().int().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteCategory extends z.infer<typeof CategoryModel> {
  parent?: CompleteCategory | null
  children: CompleteCategory[]
  procedures: CompleteProcedureCategory[]
}

/**
 * RelatedCategoryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCategoryModel: z.ZodSchema<CompleteCategory> = z.lazy(() => CategoryModel.extend({
  parent: RelatedCategoryModel.nullish(),
  children: RelatedCategoryModel.array(),
  procedures: RelatedProcedureCategoryModel.array(),
}))
