import { z } from 'zod'
import { CarouselItemCreateWithoutCarouselInputObjectSchema } from './CarouselItemCreateWithoutCarouselInput.schema'
import { CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedCreateWithoutCarouselInput.schema'
import { CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema } from './CarouselItemCreateOrConnectWithoutCarouselInput.schema'
import { CarouselItemUpsertWithWhereUniqueWithoutCarouselInputObjectSchema } from './CarouselItemUpsertWithWhereUniqueWithoutCarouselInput.schema'
import { CarouselItemCreateManyCarouselInputEnvelopeObjectSchema } from './CarouselItemCreateManyCarouselInputEnvelope.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithWhereUniqueWithoutCarouselInputObjectSchema } from './CarouselItemUpdateWithWhereUniqueWithoutCarouselInput.schema'
import { CarouselItemUpdateManyWithWhereWithoutCarouselInputObjectSchema } from './CarouselItemUpdateManyWithWhereWithoutCarouselInput.schema'
import { CarouselItemScalarWhereInputObjectSchema } from './CarouselItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUncheckedUpdateManyWithoutCarouselNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema),
          z
            .lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema)
            .array(),
          z.lazy(
            () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () => CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpsertWithWhereUniqueWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpsertWithWhereUniqueWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CarouselItemCreateManyCarouselInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpdateWithWhereUniqueWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpdateWithWhereUniqueWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpdateManyWithWhereWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpdateManyWithWhereWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CarouselItemScalarWhereInputObjectSchema),
          z.lazy(() => CarouselItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CarouselItemUncheckedUpdateManyWithoutCarouselNestedInputObjectSchema =
  Schema
