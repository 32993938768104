import { z } from 'zod'
import { ExpressShippingAreaFindManySchema } from '../findManyExpressShippingArea.schema'
import { OrderFindManySchema } from '../findManyOrder.schema'
import { ShippingProviderCountOutputTypeArgsObjectSchema } from './ShippingProviderCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderInclude> = z
  .object({
    areas: z
      .union([z.boolean(), z.lazy(() => ExpressShippingAreaFindManySchema)])
      .optional(),
    orders: z
      .union([z.boolean(), z.lazy(() => OrderFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ShippingProviderCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ShippingProviderIncludeObjectSchema = Schema
