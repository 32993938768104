import { z } from 'zod'
import { AddressCreateManyPatientInputObjectSchema } from './AddressCreateManyPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressCreateManyPatientInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => AddressCreateManyPatientInputObjectSchema),
      z.lazy(() => AddressCreateManyPatientInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const AddressCreateManyPatientInputEnvelopeObjectSchema = Schema
