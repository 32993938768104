import { z } from 'zod'
import { DiagnosisUpdateInputObjectSchema } from './objects/DiagnosisUpdateInput.schema'
import { DiagnosisUncheckedUpdateInputObjectSchema } from './objects/DiagnosisUncheckedUpdateInput.schema'
import { DiagnosisWhereUniqueInputObjectSchema } from './objects/DiagnosisWhereUniqueInput.schema'

export const DiagnosisUpdateOneSchema = z.object({
  data: z.union([
    DiagnosisUpdateInputObjectSchema,
    DiagnosisUncheckedUpdateInputObjectSchema,
  ]),
  where: DiagnosisWhereUniqueInputObjectSchema,
})
