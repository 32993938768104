import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientMaxAggregateInputType> = z
  .object({
    patientId: z.literal(true).optional(),
    notificationId: z.literal(true).optional(),
    readAt: z.literal(true).optional(),
  })
  .strict()

export const NotificationPatientMaxAggregateInputObjectSchema = Schema
