import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCountAggregateInputType> = z
  .object({
    key: z.literal(true).optional(),
    detail: z.literal(true).optional(),
    rejectDetail: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ConsentCountAggregateInputObjectSchema = Schema
