import { z } from 'zod'
import { NotificationUncheckedCreateNestedManyWithoutSegmentsInputObjectSchema } from './NotificationUncheckedCreateNestedManyWithoutSegmentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    segmentId: z.string(),
    notifications: z
      .lazy(
        () =>
          NotificationUncheckedCreateNestedManyWithoutSegmentsInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const SegmentUncheckedCreateInputObjectSchema = Schema
