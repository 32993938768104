import { Typography } from '@mui/material'
import { FC } from 'react'
import { TextFieldProps, useRecordContext } from 'react-admin'

const APPROVE_STATUS_COLOR: { [key: string]: string } = {
  PENDING: '#ffc46d',
  APPROVED: '#64b95e',
  REJECTED: '#ff434e',
}

export const StatusField: FC<TextFieldProps> = (props) => {
  const record = useRecordContext()
  if (!record) return null

  return (
    <Typography
      {...props}
      style={{
        color: APPROVE_STATUS_COLOR[record.status] ?? '#000000',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      {record.status}
    </Typography>
  )
}
