import { z } from 'zod'
import { DeliveryUpdateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUpdateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedUpdateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedUpdateWithoutDeliveryLogsInput.schema'
import { DeliveryCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedCreateWithoutDeliveryLogsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpsertWithoutDeliveryLogsInput> = z
  .object({
    update: z.union([
      z.lazy(() => DeliveryUpdateWithoutDeliveryLogsInputObjectSchema),
      z.lazy(() => DeliveryUncheckedUpdateWithoutDeliveryLogsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => DeliveryCreateWithoutDeliveryLogsInputObjectSchema),
      z.lazy(() => DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema),
    ]),
  })
  .strict()

export const DeliveryUpsertWithoutDeliveryLogsInputObjectSchema = Schema
