import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    weekday: z.literal(true).optional(),
    openAt: z.literal(true).optional(),
    closeAt: z.literal(true).optional(),
    timezone: z.literal(true).optional(),
    branchId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const OfficeHourCountAggregateInputObjectSchema = Schema
