import { z } from 'zod'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponUpdateWithoutGroupInputObjectSchema } from './CouponUpdateWithoutGroupInput.schema'
import { CouponUncheckedUpdateWithoutGroupInputObjectSchema } from './CouponUncheckedUpdateWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponUpdateWithWhereUniqueWithoutGroupInput> = z
  .object({
    where: z.lazy(() => CouponWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => CouponUpdateWithoutGroupInputObjectSchema),
      z.lazy(() => CouponUncheckedUpdateWithoutGroupInputObjectSchema),
    ]),
  })
  .strict()

export const CouponUpdateWithWhereUniqueWithoutGroupInputObjectSchema = Schema
