import { z } from 'zod'
import { CasePictureWhereUniqueInputObjectSchema } from './CasePictureWhereUniqueInput.schema'
import { CasePictureCreateWithoutCaseInputObjectSchema } from './CasePictureCreateWithoutCaseInput.schema'
import { CasePictureUncheckedCreateWithoutCaseInputObjectSchema } from './CasePictureUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureCreateOrConnectWithoutCaseInput> = z
  .object({
    where: z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema),
      z.lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CasePictureCreateOrConnectWithoutCaseInputObjectSchema = Schema
