import { z } from 'zod'
import { ProcedureUpdateWithoutPicturesInputObjectSchema } from './ProcedureUpdateWithoutPicturesInput.schema'
import { ProcedureUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedUpdateWithoutPicturesInput.schema'
import { ProcedureCreateWithoutPicturesInputObjectSchema } from './ProcedureCreateWithoutPicturesInput.schema'
import { ProcedureUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureUpdateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProcedureUncheckedUpdateWithoutPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProcedureUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ProcedureUpsertWithoutPicturesInputObjectSchema = Schema
