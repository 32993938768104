import { z } from 'zod'
import { CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUpsertWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUpsertWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => CaseSummaryUpsertWithoutCaseSummaryDiagnosisInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => CaseSummaryUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema =
  Schema
