import { z } from 'zod'
import { PaymentWhereUniqueInputObjectSchema } from './PaymentWhereUniqueInput.schema'
import { PaymentCreateWithoutOrderInputObjectSchema } from './PaymentCreateWithoutOrderInput.schema'
import { PaymentUncheckedCreateWithoutOrderInputObjectSchema } from './PaymentUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => PaymentWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema),
      z.lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const PaymentCreateOrConnectWithoutOrderInputObjectSchema = Schema
