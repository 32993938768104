import { HomeWork } from '@mui/icons-material'
import {
  Alert,
  Box,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useEffect } from 'react'
import {
  BooleanInput,
  CheckboxGroupInput,
  OptionTextFunc,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  useChoicesContext,
  useLocaleState,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomBooleanField } from '../../../components/CustomField'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormAutoCompleteInput } from '../../../components/FormInput/input'

const tr_resource = 'resources.procedure'

export const BranchCheckboxInput: FC = () => {
  const [locale] = useLocaleState()
  const { availableChoices } = useChoicesContext()
  const { watch, setValue } = useFormContext()
  const partnerIdSelected = watch('partnerId')
  const translate = useTranslate()

  const optionTextPartner: OptionTextFunc = (choice) => {
    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        {getTr(choice.nameTr, locale as Language)}{' '}
        <CustomBooleanField
          record={(choice: { active: boolean }) => choice.active}
        />
      </Box>
    )
  }

  useEffect(() => {
    // reset branches every time partner changed
    setValue('branches', [])
    // if there are choices and valid partner id (bug sometime)
    if (
      availableChoices &&
      availableChoices[0]?.partnerId === partnerIdSelected
    ) {
      const choices = availableChoices.map((c) => c.id)
      setValue('branches', choices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerIdSelected])

  // handle empty case
  return availableChoices && availableChoices.length > 0 ? (
    <CheckboxGroupInput
      label={false}
      optionText={optionTextPartner}
      row={false}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        padding: '1rem',
        gap: '1rem',
        color: 'text.disabled',
      }}
    >
      <HomeWork fontSize="large" color="disabled" />
      <Typography variant="body2">
        {translate([tr_resource, 'validation', 'no_branch'].join('.'))}
      </Typography>
    </Box>
  )
}

export const ClinicSection: FC = () => {
  const [locale] = useLocaleState()
  const { watch } = useFormContext()
  const translate = useTranslate()
  const partnerIdSelected = watch('partnerId')

  const optionTextPartner: OptionTextFunc = (choice) => {
    return getTr(choice.nameTr, locale as Language)
  }

  const filterToQueryTr = (searchText: string) => {
    if (searchText) {
      return {
        searchField_contains: searchText.toLowerCase().trim(),
      }
    }
    return { id: { in: [] } }
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate([tr_resource, 'title', 'partner_section'].join('.'))}
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            source="partnerId"
            reference="partner"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <FormAutoCompleteInput
              label={[tr_resource, 'fields', 'partner'].join('.')}
              fullWidth
              optionText={optionTextPartner}
              debounce={100}
              filterToQuery={filterToQueryTr}
              validate={required()}
            />
          </ReferenceInput>
          <BooleanInput source="isTreatedByDoctor" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            variant="standard"
            sx={{ width: '100%' }}
          >
            <FormLabel component="legend">
              {translate([tr_resource, 'title', 'branches'].join('.'))}
            </FormLabel>
            {partnerIdSelected ? (
              <ReferenceArrayInput
                source="branches"
                reference="partnerBranch"
                perPage={999}
                sort={{ field: 'id', order: 'ASC' }}
                filter={{ partnerId: partnerIdSelected }}
              >
                <BranchCheckboxInput />
              </ReferenceArrayInput>
            ) : (
              <Box sx={{ mt: 1 }}>
                <Alert severity="info">
                  {translate(
                    [tr_resource, 'validation', 'select_clinic'].join('.'),
                  )}
                </Alert>
              </Box>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
