import { z } from 'zod'
import { PartnerCreateWithoutBranchesInputObjectSchema } from './PartnerCreateWithoutBranchesInput.schema'
import { PartnerUncheckedCreateWithoutBranchesInputObjectSchema } from './PartnerUncheckedCreateWithoutBranchesInput.schema'
import { PartnerCreateOrConnectWithoutBranchesInputObjectSchema } from './PartnerCreateOrConnectWithoutBranchesInput.schema'
import { PartnerUpsertWithoutBranchesInputObjectSchema } from './PartnerUpsertWithoutBranchesInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerUpdateWithoutBranchesInputObjectSchema } from './PartnerUpdateWithoutBranchesInput.schema'
import { PartnerUncheckedUpdateWithoutBranchesInputObjectSchema } from './PartnerUncheckedUpdateWithoutBranchesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutBranchesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PartnerCreateWithoutBranchesInputObjectSchema),
          z.lazy(() => PartnerUncheckedCreateWithoutBranchesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PartnerCreateOrConnectWithoutBranchesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PartnerUpsertWithoutBranchesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PartnerUpdateWithoutBranchesInputObjectSchema),
          z.lazy(() => PartnerUncheckedUpdateWithoutBranchesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PartnerUpdateOneRequiredWithoutBranchesNestedInputObjectSchema =
  Schema
