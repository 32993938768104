import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'
import { NotificationArgsObjectSchema } from './NotificationArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientInclude> = z
  .object({
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    notification: z
      .union([z.boolean(), z.lazy(() => NotificationArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const NotificationPatientIncludeObjectSchema = Schema
