import { z } from 'zod'
import { RefundCreateWithoutOrderInputObjectSchema } from './RefundCreateWithoutOrderInput.schema'
import { RefundUncheckedCreateWithoutOrderInputObjectSchema } from './RefundUncheckedCreateWithoutOrderInput.schema'
import { RefundCreateOrConnectWithoutOrderInputObjectSchema } from './RefundCreateOrConnectWithoutOrderInput.schema'
import { RefundUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './RefundUpsertWithWhereUniqueWithoutOrderInput.schema'
import { RefundCreateManyOrderInputEnvelopeObjectSchema } from './RefundCreateManyOrderInputEnvelope.schema'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './RefundUpdateWithWhereUniqueWithoutOrderInput.schema'
import { RefundUpdateManyWithWhereWithoutOrderInputObjectSchema } from './RefundUpdateManyWithWhereWithoutOrderInput.schema'
import { RefundScalarWhereInputObjectSchema } from './RefundScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateManyWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => RefundCreateWithoutOrderInputObjectSchema),
        z.lazy(() => RefundCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => RefundUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => RefundCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(() => RefundUpsertWithWhereUniqueWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundUpsertWithWhereUniqueWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => RefundCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => RefundWhereUniqueInputObjectSchema),
        z.lazy(() => RefundWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => RefundWhereUniqueInputObjectSchema),
        z.lazy(() => RefundWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => RefundWhereUniqueInputObjectSchema),
        z.lazy(() => RefundWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => RefundWhereUniqueInputObjectSchema),
        z.lazy(() => RefundWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(() => RefundUpdateWithWhereUniqueWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundUpdateWithWhereUniqueWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => RefundUpdateManyWithWhereWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundUpdateManyWithWhereWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => RefundScalarWhereInputObjectSchema),
        z.lazy(() => RefundScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const RefundUpdateManyWithoutOrderNestedInputObjectSchema = Schema
