import { z } from 'zod'
import { HolidayCreateInputObjectSchema } from './objects/HolidayCreateInput.schema'
import { HolidayUncheckedCreateInputObjectSchema } from './objects/HolidayUncheckedCreateInput.schema'

export const HolidayCreateOneSchema = z.object({
  data: z.union([
    HolidayCreateInputObjectSchema,
    HolidayUncheckedCreateInputObjectSchema,
  ]),
})
