import { z } from 'zod'
import { OtpBlacklistUpdateInputObjectSchema } from './objects/OtpBlacklistUpdateInput.schema'
import { OtpBlacklistUncheckedUpdateInputObjectSchema } from './objects/OtpBlacklistUncheckedUpdateInput.schema'
import { OtpBlacklistWhereUniqueInputObjectSchema } from './objects/OtpBlacklistWhereUniqueInput.schema'

export const OtpBlacklistUpdateOneSchema = z.object({
  data: z.union([
    OtpBlacklistUpdateInputObjectSchema,
    OtpBlacklistUncheckedUpdateInputObjectSchema,
  ]),
  where: OtpBlacklistWhereUniqueInputObjectSchema,
})
