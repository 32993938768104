import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    active: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    carouselId: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
  })
  .strict()

export const CarouselItemMaxAggregateInputObjectSchema = Schema
