import { z } from 'zod'
import { CouponGroupCountOutputTypeSelectObjectSchema } from './CouponGroupCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => CouponGroupCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const CouponGroupCountOutputTypeArgsObjectSchema = Schema
