import { z } from 'zod'
import { VariantArgsObjectSchema } from './VariantArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureSelect> = z
  .object({
    id: z.boolean().optional(),
    url: z.boolean().optional(),
    variant: z
      .union([z.boolean(), z.lazy(() => VariantArgsObjectSchema)])
      .optional(),
    variantSku: z.boolean().optional(),
    rank: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const VariantPictureSelectObjectSchema = Schema
