import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { VariantUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema } from './VariantUpdateOneRequiredWithoutProductReviewsNestedInput.schema'
import { ProductUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema } from './ProductUpdateOneRequiredWithoutProductReviewsNestedInput.schema'
import { BrandUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema } from './BrandUpdateOneRequiredWithoutProductReviewsNestedInput.schema'
import { ProductReviewPictureUpdateManyWithoutProductReviewNestedInputObjectSchema } from './ProductReviewPictureUpdateManyWithoutProductReviewNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    overallRating: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    qualityRating: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    shippingRating: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    packagingRating: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    message: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    orderId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patientId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patientName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    isAnonymous: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    variant: z
      .lazy(
        () =>
          VariantUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema,
      )
      .optional(),
    product: z
      .lazy(
        () =>
          ProductUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema,
      )
      .optional(),
    brand: z
      .lazy(
        () =>
          BrandUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema,
      )
      .optional(),
    pictures: z
      .lazy(
        () =>
          ProductReviewPictureUpdateManyWithoutProductReviewNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProductReviewUpdateInputObjectSchema = Schema
