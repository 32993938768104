import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    price: z.literal(true).optional(),
    freeShippingMinimumPrice: z.literal(true).optional(),
  })
  .strict()

export const ShippingProviderAvgAggregateInputObjectSchema = Schema
