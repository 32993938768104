import { z } from 'zod'
import { NotificationFindManySchema } from '../findManyNotification.schema'
import { SegmentCountOutputTypeArgsObjectSchema } from './SegmentCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentInclude> = z
  .object({
    notifications: z
      .union([z.boolean(), z.lazy(() => NotificationFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => SegmentCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const SegmentIncludeObjectSchema = Schema
