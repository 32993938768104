import { z } from 'zod'
import { PartnerTypeSchema } from '../enums/PartnerType.schema'
import { NestedEnumPartnerTypeWithAggregatesFilterObjectSchema } from './NestedEnumPartnerTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumPartnerTypeFilterObjectSchema } from './NestedEnumPartnerTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.EnumPartnerTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => PartnerTypeSchema).optional(),
    in: z
      .lazy(() => PartnerTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PartnerTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PartnerTypeSchema),
        z.lazy(() => NestedEnumPartnerTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumPartnerTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumPartnerTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumPartnerTypeWithAggregatesFilterObjectSchema = Schema
