import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    transactionId: z.boolean().optional(),
    amount: z.boolean().optional(),
    currency: z.boolean().optional(),
    paymentAt: z.boolean().optional(),
    paymentGateway: z.boolean().optional(),
    paymentMethod: z.boolean().optional(),
    installmentTerm: z.boolean().optional(),
    status: z.boolean().optional(),
    confirmedBy: z.boolean().optional(),
    evidencePicture: z.boolean().optional(),
    reminderNote: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
  })
  .strict()

export const PaymentSelectObjectSchema = Schema
