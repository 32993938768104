import { z } from 'zod'
import { DeliveryLogSelectObjectSchema } from './DeliveryLogSelect.schema'
import { DeliveryLogIncludeObjectSchema } from './DeliveryLogInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogArgs> = z
  .object({
    select: z.lazy(() => DeliveryLogSelectObjectSchema).optional(),
    include: z.lazy(() => DeliveryLogIncludeObjectSchema).optional(),
  })
  .strict()

export const DeliveryLogArgsObjectSchema = Schema
