import { z } from 'zod'
import { ProcedureCategoryProcedureIdCategoryIdCompoundUniqueInputObjectSchema } from './ProcedureCategoryProcedureIdCategoryIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    procedureId_categoryId: z
      .lazy(
        () =>
          ProcedureCategoryProcedureIdCategoryIdCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProcedureCategoryWhereUniqueInputObjectSchema = Schema
