import { z } from 'zod'
import { AppointmentCreateWithoutCaseInputObjectSchema } from './AppointmentCreateWithoutCaseInput.schema'
import { AppointmentUncheckedCreateWithoutCaseInputObjectSchema } from './AppointmentUncheckedCreateWithoutCaseInput.schema'
import { AppointmentCreateOrConnectWithoutCaseInputObjectSchema } from './AppointmentCreateOrConnectWithoutCaseInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateNestedOneWithoutCaseInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => AppointmentCreateWithoutCaseInputObjectSchema),
        z.lazy(() => AppointmentUncheckedCreateWithoutCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => AppointmentCreateOrConnectWithoutCaseInputObjectSchema)
      .optional(),
    connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const AppointmentCreateNestedOneWithoutCaseInputObjectSchema = Schema
