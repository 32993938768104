import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithoutDeliveryInputObjectSchema } from './ProductItemUpdateWithoutDeliveryInput.schema'
import { ProductItemUncheckedUpdateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedUpdateWithoutDeliveryInput.schema'
import { ProductItemCreateWithoutDeliveryInputObjectSchema } from './ProductItemCreateWithoutDeliveryInput.schema'
import { ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpsertWithWhereUniqueWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductItemUpdateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => ProductItemUncheckedUpdateWithoutDeliveryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema =
  Schema
