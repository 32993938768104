import { z } from 'zod'
import { ProcedureReviewSummaryCreateInputObjectSchema } from './objects/ProcedureReviewSummaryCreateInput.schema'
import { ProcedureReviewSummaryUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewSummaryUncheckedCreateInput.schema'

export const ProcedureReviewSummaryCreateOneSchema = z.object({
  data: z.union([
    ProcedureReviewSummaryCreateInputObjectSchema,
    ProcedureReviewSummaryUncheckedCreateInputObjectSchema,
  ]),
})
