import { z } from 'zod'
import { PartnerWhereInputObjectSchema } from './objects/PartnerWhereInput.schema'
import { PartnerOrderByWithAggregationInputObjectSchema } from './objects/PartnerOrderByWithAggregationInput.schema'
import { PartnerScalarWhereWithAggregatesInputObjectSchema } from './objects/PartnerScalarWhereWithAggregatesInput.schema'
import { PartnerScalarFieldEnumSchema } from './enums/PartnerScalarFieldEnum.schema'

export const PartnerGroupBySchema = z.object({
  where: PartnerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    PartnerOrderByWithAggregationInputObjectSchema,
    PartnerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: PartnerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(PartnerScalarFieldEnumSchema),
})
