import { z } from 'zod'

export const ProcedureItemScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'procedureId',
  'partnerId',
  'nameTr',
  'picture',
  'descriptionTr',
  'quantity',
  'tagPrice',
  'sellingPrice',
  'cost',
  'priceAbsorbedBySkinX',
  'priceAbsorbedByPartner',
  'commissionRate',
  'flashSaleId',
  'expireType',
  'expireAt',
  'expireDurationDay',
  'orderId',
  'refundId',
  'v2DrugItemId',
  'reviewId',
  'reviewStartAt',
  'reviewExpireAt',
])
