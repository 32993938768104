import { z } from 'zod'
import { ProductCategorySelectObjectSchema } from './ProductCategorySelect.schema'
import { ProductCategoryIncludeObjectSchema } from './ProductCategoryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryArgs> = z
  .object({
    select: z.lazy(() => ProductCategorySelectObjectSchema).optional(),
    include: z.lazy(() => ProductCategoryIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductCategoryArgsObjectSchema = Schema
