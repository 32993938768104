import { z } from 'zod'
import { ProductBannerOrderByWithRelationInputObjectSchema } from './objects/ProductBannerOrderByWithRelationInput.schema'
import { ProductBannerWhereInputObjectSchema } from './objects/ProductBannerWhereInput.schema'
import { ProductBannerWhereUniqueInputObjectSchema } from './objects/ProductBannerWhereUniqueInput.schema'
import { ProductBannerCountAggregateInputObjectSchema } from './objects/ProductBannerCountAggregateInput.schema'
import { ProductBannerMinAggregateInputObjectSchema } from './objects/ProductBannerMinAggregateInput.schema'
import { ProductBannerMaxAggregateInputObjectSchema } from './objects/ProductBannerMaxAggregateInput.schema'
import { ProductBannerAvgAggregateInputObjectSchema } from './objects/ProductBannerAvgAggregateInput.schema'
import { ProductBannerSumAggregateInputObjectSchema } from './objects/ProductBannerSumAggregateInput.schema'

export const ProductBannerAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductBannerOrderByWithRelationInputObjectSchema,
      ProductBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductBannerWhereInputObjectSchema.optional(),
  cursor: ProductBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductBannerCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductBannerMinAggregateInputObjectSchema.optional(),
  _max: ProductBannerMaxAggregateInputObjectSchema.optional(),
  _avg: ProductBannerAvgAggregateInputObjectSchema.optional(),
  _sum: ProductBannerSumAggregateInputObjectSchema.optional(),
})
