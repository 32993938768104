import { z } from 'zod'
import { PaymentCreateManyOrderInputObjectSchema } from './PaymentCreateManyOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentCreateManyOrderInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => PaymentCreateManyOrderInputObjectSchema),
      z.lazy(() => PaymentCreateManyOrderInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const PaymentCreateManyOrderInputEnvelopeObjectSchema = Schema
