import { z } from 'zod'
import { CategoryCreateNestedOneWithoutProceduresInputObjectSchema } from './CategoryCreateNestedOneWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateWithoutProcedureInput> = z
  .object({
    priority: z.number().optional(),
    category: z.lazy(
      () => CategoryCreateNestedOneWithoutProceduresInputObjectSchema,
    ),
  })
  .strict()

export const ProcedureCategoryCreateWithoutProcedureInputObjectSchema = Schema
