import { z } from 'zod'
import { ExpressShippingAreaCreateInputObjectSchema } from './objects/ExpressShippingAreaCreateInput.schema'
import { ExpressShippingAreaUncheckedCreateInputObjectSchema } from './objects/ExpressShippingAreaUncheckedCreateInput.schema'

export const ExpressShippingAreaCreateOneSchema = z.object({
  data: z.union([
    ExpressShippingAreaCreateInputObjectSchema,
    ExpressShippingAreaUncheckedCreateInputObjectSchema,
  ]),
})
