import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.DeliveryLogCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    deliveryId: z.number().optional().nullable(),
    statusCode: z.string(),
    statusText: z.string(),
    statusDate: z.date(),
    rawData: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
  })
  .strict()

export const DeliveryLogCreateManyInputObjectSchema = Schema
