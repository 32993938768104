import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumKycChannelFilterObjectSchema } from './EnumKycChannelFilter.schema'
import { KycChannelSchema } from '../enums/KycChannel.schema'
import { EnumKycStatusFilterObjectSchema } from './EnumKycStatusFilter.schema'
import { KycStatusSchema } from '../enums/KycStatus.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumGenderNullableFilterObjectSchema } from './EnumGenderNullableFilter.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'
import { RejectReasonListRelationFilterObjectSchema } from './RejectReasonListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => KycDataWhereInputObjectSchema),
        z.lazy(() => KycDataWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => KycDataWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => KycDataWhereInputObjectSchema),
        z.lazy(() => KycDataWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    channel: z
      .union([
        z.lazy(() => EnumKycChannelFilterObjectSchema),
        z.lazy(() => KycChannelSchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumKycStatusFilterObjectSchema),
        z.lazy(() => KycStatusSchema),
      ])
      .optional(),
    approvedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    approvedBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    prefix: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    firstname: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    lastname: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    gender: z
      .union([
        z.lazy(() => EnumGenderNullableFilterObjectSchema),
        z.lazy(() => GenderSchema),
      ])
      .optional()
      .nullable(),
    birthDate: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    idCardNumber: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    idCardPicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    selfieIdCardPicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    passportNumber: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    passportPicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    selfiePassportPicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    rejectAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    rejectBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
    rejectReason: z
      .lazy(() => RejectReasonListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const KycDataWhereInputObjectSchema = Schema
