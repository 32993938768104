import { z } from 'zod'
import { BranchWhereInputObjectSchema } from './BranchWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchListRelationFilter> = z
  .object({
    every: z.lazy(() => BranchWhereInputObjectSchema).optional(),
    some: z.lazy(() => BranchWhereInputObjectSchema).optional(),
    none: z.lazy(() => BranchWhereInputObjectSchema).optional(),
  })
  .strict()

export const BranchListRelationFilterObjectSchema = Schema
