import { z } from 'zod'
import { HealthInfoUpdateWithoutOrderInputObjectSchema } from './HealthInfoUpdateWithoutOrderInput.schema'
import { HealthInfoUncheckedUpdateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedUpdateWithoutOrderInput.schema'
import { HealthInfoCreateWithoutOrderInputObjectSchema } from './HealthInfoCreateWithoutOrderInput.schema'
import { HealthInfoUncheckedCreateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoUpsertWithoutOrderInput> = z
  .object({
    update: z.union([
      z.lazy(() => HealthInfoUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => HealthInfoCreateWithoutOrderInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const HealthInfoUpsertWithoutOrderInputObjectSchema = Schema
