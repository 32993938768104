import { z } from 'zod'
import { NotificationCreateWithoutPatientsInputObjectSchema } from './NotificationCreateWithoutPatientsInput.schema'
import { NotificationUncheckedCreateWithoutPatientsInputObjectSchema } from './NotificationUncheckedCreateWithoutPatientsInput.schema'
import { NotificationCreateOrConnectWithoutPatientsInputObjectSchema } from './NotificationCreateOrConnectWithoutPatientsInput.schema'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCreateNestedOneWithoutPatientsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => NotificationCreateWithoutPatientsInputObjectSchema),
          z.lazy(
            () => NotificationUncheckedCreateWithoutPatientsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => NotificationCreateOrConnectWithoutPatientsInputObjectSchema)
        .optional(),
      connect: z
        .lazy(() => NotificationWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const NotificationCreateNestedOneWithoutPatientsInputObjectSchema =
  Schema
