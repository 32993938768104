import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumShippingTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => ShippingTypeSchema).optional(),
  })
  .strict()

export const EnumShippingTypeFieldUpdateOperationsInputObjectSchema = Schema
