import { z } from 'zod'
import { NotificationPatientWhereUniqueInputObjectSchema } from './objects/NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientCreateInputObjectSchema } from './objects/NotificationPatientCreateInput.schema'
import { NotificationPatientUncheckedCreateInputObjectSchema } from './objects/NotificationPatientUncheckedCreateInput.schema'
import { NotificationPatientUpdateInputObjectSchema } from './objects/NotificationPatientUpdateInput.schema'
import { NotificationPatientUncheckedUpdateInputObjectSchema } from './objects/NotificationPatientUncheckedUpdateInput.schema'

export const NotificationPatientUpsertSchema = z.object({
  where: NotificationPatientWhereUniqueInputObjectSchema,
  create: z.union([
    NotificationPatientCreateInputObjectSchema,
    NotificationPatientUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    NotificationPatientUpdateInputObjectSchema,
    NotificationPatientUncheckedUpdateInputObjectSchema,
  ]),
})
