import { z } from 'zod'
import { HealthInfoWhereInputObjectSchema } from './HealthInfoWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoRelationFilter> = z
  .object({
    is: z
      .lazy(() => HealthInfoWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => HealthInfoWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const HealthInfoRelationFilterObjectSchema = Schema
