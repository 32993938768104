import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressSelect> = z
  .object({
    id: z.boolean().optional(),
    recipient: z.boolean().optional(),
    address: z.boolean().optional(),
    phone: z.boolean().optional(),
    province: z.boolean().optional(),
    district: z.boolean().optional(),
    subDistrict: z.boolean().optional(),
    postCode: z.boolean().optional(),
    provinceKey: z.boolean().optional(),
    districtKey: z.boolean().optional(),
    subDistrictKey: z.boolean().optional(),
    note: z.boolean().optional(),
    tag: z.boolean().optional(),
    isDefault: z.boolean().optional(),
    patientId: z.boolean().optional(),
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const AddressSelectObjectSchema = Schema
