import { z } from 'zod'
import { CaseCreateWithoutFollowupCaseInputObjectSchema } from './CaseCreateWithoutFollowupCaseInput.schema'
import { CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedCreateWithoutFollowupCaseInput.schema'
import { CaseCreateOrConnectWithoutFollowupCaseInputObjectSchema } from './CaseCreateOrConnectWithoutFollowupCaseInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUncheckedCreateNestedOneWithoutFollowupCaseInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutFollowupCaseInputObjectSchema),
          z.lazy(() => CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseCreateOrConnectWithoutFollowupCaseInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseUncheckedCreateNestedOneWithoutFollowupCaseInputObjectSchema =
  Schema
