import { z } from 'zod'
import { ProductCreateWithoutPicturesInputObjectSchema } from './ProductCreateWithoutPicturesInput.schema'
import { ProductUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductUncheckedCreateWithoutPicturesInput.schema'
import { ProductCreateOrConnectWithoutPicturesInputObjectSchema } from './ProductCreateOrConnectWithoutPicturesInput.schema'
import { ProductUpsertWithoutPicturesInputObjectSchema } from './ProductUpsertWithoutPicturesInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutPicturesInputObjectSchema } from './ProductUpdateWithoutPicturesInput.schema'
import { ProductUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProductUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutPicturesInputObjectSchema),
          z.lazy(() => ProductUncheckedCreateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProductCreateOrConnectWithoutPicturesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProductUpdateWithoutPicturesInputObjectSchema),
          z.lazy(() => ProductUncheckedUpdateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
