import { FC } from 'react'
import { useInput, Validator } from 'react-admin'
import { CustomPasswordInput } from '../../../components/CustomInput'

export interface PasswordSectionProps {
  validate?: Validator | Validator[]
}

export const PasswordSection: FC<PasswordSectionProps> = ({ validate }) => {
  const {
    field: { value: password },
  } = useInput({ source: 'password' })
  return (
    <>
      <CustomPasswordInput source="password" validate={validate} />
      <CustomPasswordInput
        source="confirmPassword"
        disabled={!password}
        validate={(value, values) =>
          values.password && value !== values.password
            ? 'The passwords do not match'
            : null
        }
      />
    </>
  )
}
