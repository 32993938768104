import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryCreateWithoutCategoryInputObjectSchema } from './ProductCategoryCreateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateOrConnectWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema =
  Schema
