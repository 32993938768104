import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureReviewCountOrderByAggregateInputObjectSchema } from './ProcedureReviewCountOrderByAggregateInput.schema'
import { ProcedureReviewAvgOrderByAggregateInputObjectSchema } from './ProcedureReviewAvgOrderByAggregateInput.schema'
import { ProcedureReviewMaxOrderByAggregateInputObjectSchema } from './ProcedureReviewMaxOrderByAggregateInput.schema'
import { ProcedureReviewMinOrderByAggregateInputObjectSchema } from './ProcedureReviewMinOrderByAggregateInput.schema'
import { ProcedureReviewSumOrderByAggregateInputObjectSchema } from './ProcedureReviewSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    overallRating: z.lazy(() => SortOrderSchema).optional(),
    environmentRating: z.lazy(() => SortOrderSchema).optional(),
    serviceRating: z.lazy(() => SortOrderSchema).optional(),
    specialtyRating: z.lazy(() => SortOrderSchema).optional(),
    valueRating: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    patientName: z.lazy(() => SortOrderSchema).optional(),
    isAnonymous: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedureReviewCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedureReviewAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedureReviewMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedureReviewMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedureReviewSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureReviewOrderByWithAggregationInputObjectSchema = Schema
