import { Box, Card, CardContent } from '@mui/material'
import { Show, SimpleForm, Toolbar } from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ConditionSetting } from './show-section/conditionSetting'
import { CouponGroupInfo } from './show-section/couponGroup'
import { CouponListInGroup } from './show-section/couponList'
import { DiscountSetting } from './show-section/discountSertting'

const CouponGroupShowToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="couponGroup" />
      </Box>
    </Toolbar>
  )
}

export const CouponGroupShow = () => {
  return (
    <Show>
      <Card>
        <CardContent>
          <SimpleForm toolbar={false}>
            <CouponGroupInfo />
            <DiscountSetting />
            <ConditionSetting />
            <CouponListInGroup />
          </SimpleForm>
        </CardContent>
      </Card>
      <CouponGroupShowToolbar />
    </Show>
  )
}
