import { z } from 'zod'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { CaseCreateNestedOneWithoutPreviousCaseInputObjectSchema } from './CaseCreateNestedOneWithoutPreviousCaseInput.schema'
import { CasePictureCreateNestedManyWithoutCaseInputObjectSchema } from './CasePictureCreateNestedManyWithoutCaseInput.schema'
import { AppointmentCreateNestedOneWithoutCaseInputObjectSchema } from './AppointmentCreateNestedOneWithoutCaseInput.schema'
import { TeleconsultCreateNestedOneWithoutCaseInputObjectSchema } from './TeleconsultCreateNestedOneWithoutCaseInput.schema'
import { TeleconsultCreateNestedManyWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateNestedManyWithoutMissedCallCaseInput.schema'
import { CaseSummaryCreateNestedOneWithoutCaseInputObjectSchema } from './CaseSummaryCreateNestedOneWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateWithoutPreviousCaseInput> = z
  .object({
    patientId: z.number(),
    doctorId: z.number(),
    symptom: z.string(),
    drugAllergy: z.string().optional().nullable(),
    congenitalDisease: z.string().optional().nullable(),
    symptomDuration: z.number().optional().nullable(),
    symptomDurationUnit: z
      .lazy(() => SymptomDurationUnitSchema)
      .optional()
      .nullable(),
    status: z.lazy(() => CaseStatusSchema).optional(),
    consultingChannel: z.lazy(() => ConsultingChannelSchema),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    v2RecordId: z.number().optional().nullable(),
    followupCase: z
      .lazy(() => CaseCreateNestedOneWithoutPreviousCaseInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(() => CasePictureCreateNestedManyWithoutCaseInputObjectSchema)
      .optional(),
    appointment: z
      .lazy(() => AppointmentCreateNestedOneWithoutCaseInputObjectSchema)
      .optional(),
    teleconsult: z
      .lazy(() => TeleconsultCreateNestedOneWithoutCaseInputObjectSchema)
      .optional(),
    missedCallTeleconsult: z
      .lazy(
        () => TeleconsultCreateNestedManyWithoutMissedCallCaseInputObjectSchema,
      )
      .optional(),
    caseSummary: z
      .lazy(() => CaseSummaryCreateNestedOneWithoutCaseInputObjectSchema)
      .optional(),
  })
  .strict()

export const CaseCreateWithoutPreviousCaseInputObjectSchema = Schema
