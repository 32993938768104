import { z } from 'zod'
import { BrandBannerCreateNestedManyWithoutBrandInputObjectSchema } from './BrandBannerCreateNestedManyWithoutBrandInput.schema'
import { ProductCreateNestedManyWithoutBrandInputObjectSchema } from './ProductCreateNestedManyWithoutBrandInput.schema'
import { ProductReviewCreateNestedManyWithoutBrandInputObjectSchema } from './ProductReviewCreateNestedManyWithoutBrandInput.schema'
import { ProductReviewSummaryCreateNestedManyWithoutBrandInputObjectSchema } from './ProductReviewSummaryCreateNestedManyWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateInput> = z
  .object({
    name: z.string(),
    isShow: z.boolean().optional(),
    coporateName: z.string(),
    countryName: z.string(),
    logo: z.string().optional().nullable(),
    priority: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    banners: z
      .lazy(() => BrandBannerCreateNestedManyWithoutBrandInputObjectSchema)
      .optional(),
    products: z
      .lazy(() => ProductCreateNestedManyWithoutBrandInputObjectSchema)
      .optional(),
    productReviews: z
      .lazy(() => ProductReviewCreateNestedManyWithoutBrandInputObjectSchema)
      .optional(),
    productReviewSummaries: z
      .lazy(
        () => ProductReviewSummaryCreateNestedManyWithoutBrandInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const BrandCreateInputObjectSchema = Schema
