import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumNotificationTypeFilterObjectSchema } from './EnumNotificationTypeFilter.schema'
import { NotificationTypeSchema } from '../enums/NotificationType.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumNotificationActionTypeFilterObjectSchema } from './EnumNotificationActionTypeFilter.schema'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumAppointmentEventNullableFilterObjectSchema } from './EnumAppointmentEventNullableFilter.schema'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'
import { SegmentListRelationFilterObjectSchema } from './SegmentListRelationFilter.schema'
import { NotificationPatientListRelationFilterObjectSchema } from './NotificationPatientListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => NotificationWhereInputObjectSchema),
        z.lazy(() => NotificationWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => NotificationWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => NotificationWhereInputObjectSchema),
        z.lazy(() => NotificationWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumNotificationTypeFilterObjectSchema),
        z.lazy(() => NotificationTypeSchema),
      ])
      .optional(),
    picture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    title: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    message: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    actionType: z
      .union([
        z.lazy(() => EnumNotificationActionTypeFilterObjectSchema),
        z.lazy(() => NotificationActionTypeSchema),
      ])
      .optional(),
    action: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    onesignalMessageId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    startAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    appointmentId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    appointmentEvent: z
      .union([
        z.lazy(() => EnumAppointmentEventNullableFilterObjectSchema),
        z.lazy(() => AppointmentEventSchema),
      ])
      .optional()
      .nullable(),
    segments: z.lazy(() => SegmentListRelationFilterObjectSchema).optional(),
    patients: z
      .lazy(() => NotificationPatientListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const NotificationWhereInputObjectSchema = Schema
