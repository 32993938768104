import * as z from "zod"
import { CompletePatient, RelatedPatientModel } from "./index"

export const CoinTransactionModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  patientId: z.number().int(),
  orderId: z.number().int().nullish(),
  status: z.enum(['PENDING', 'SUCCESS']),
  type: z.enum(['EARN', 'USE']),
  amount: z.number(),
  note: z.string().nullish(),
})

export interface CompleteCoinTransaction extends z.infer<typeof CoinTransactionModel> {
  patient: CompletePatient
}

/**
 * RelatedCoinTransactionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCoinTransactionModel: z.ZodSchema<CompleteCoinTransaction> = z.lazy(() => CoinTransactionModel.extend({
  patient: RelatedPatientModel,
}))
