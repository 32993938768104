import { z } from 'zod'
import { ExpressShippingAreaUpdateInputObjectSchema } from './objects/ExpressShippingAreaUpdateInput.schema'
import { ExpressShippingAreaUncheckedUpdateInputObjectSchema } from './objects/ExpressShippingAreaUncheckedUpdateInput.schema'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './objects/ExpressShippingAreaWhereUniqueInput.schema'

export const ExpressShippingAreaUpdateOneSchema = z.object({
  data: z.union([
    ExpressShippingAreaUpdateInputObjectSchema,
    ExpressShippingAreaUncheckedUpdateInputObjectSchema,
  ]),
  where: ExpressShippingAreaWhereUniqueInputObjectSchema,
})
