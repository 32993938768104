import { z } from 'zod'
import { RejectReasonOrderByWithRelationInputObjectSchema } from './objects/RejectReasonOrderByWithRelationInput.schema'
import { RejectReasonWhereInputObjectSchema } from './objects/RejectReasonWhereInput.schema'
import { RejectReasonWhereUniqueInputObjectSchema } from './objects/RejectReasonWhereUniqueInput.schema'
import { RejectReasonCountAggregateInputObjectSchema } from './objects/RejectReasonCountAggregateInput.schema'
import { RejectReasonMinAggregateInputObjectSchema } from './objects/RejectReasonMinAggregateInput.schema'
import { RejectReasonMaxAggregateInputObjectSchema } from './objects/RejectReasonMaxAggregateInput.schema'
import { RejectReasonAvgAggregateInputObjectSchema } from './objects/RejectReasonAvgAggregateInput.schema'
import { RejectReasonSumAggregateInputObjectSchema } from './objects/RejectReasonSumAggregateInput.schema'

export const RejectReasonAggregateSchema = z.object({
  orderBy: z
    .union([
      RejectReasonOrderByWithRelationInputObjectSchema,
      RejectReasonOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: RejectReasonWhereInputObjectSchema.optional(),
  cursor: RejectReasonWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), RejectReasonCountAggregateInputObjectSchema])
    .optional(),
  _min: RejectReasonMinAggregateInputObjectSchema.optional(),
  _max: RejectReasonMaxAggregateInputObjectSchema.optional(),
  _avg: RejectReasonAvgAggregateInputObjectSchema.optional(),
  _sum: RejectReasonSumAggregateInputObjectSchema.optional(),
})
