import { z } from 'zod'
import { HomeBannerCreateInputObjectSchema } from './objects/HomeBannerCreateInput.schema'
import { HomeBannerUncheckedCreateInputObjectSchema } from './objects/HomeBannerUncheckedCreateInput.schema'

export const HomeBannerCreateOneSchema = z.object({
  data: z.union([
    HomeBannerCreateInputObjectSchema,
    HomeBannerUncheckedCreateInputObjectSchema,
  ]),
})
