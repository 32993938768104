import { z } from 'zod'
import { HomeBannerWhereUniqueInputObjectSchema } from './objects/HomeBannerWhereUniqueInput.schema'
import { HomeBannerCreateInputObjectSchema } from './objects/HomeBannerCreateInput.schema'
import { HomeBannerUncheckedCreateInputObjectSchema } from './objects/HomeBannerUncheckedCreateInput.schema'
import { HomeBannerUpdateInputObjectSchema } from './objects/HomeBannerUpdateInput.schema'
import { HomeBannerUncheckedUpdateInputObjectSchema } from './objects/HomeBannerUncheckedUpdateInput.schema'

export const HomeBannerUpsertSchema = z.object({
  where: HomeBannerWhereUniqueInputObjectSchema,
  create: z.union([
    HomeBannerCreateInputObjectSchema,
    HomeBannerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    HomeBannerUpdateInputObjectSchema,
    HomeBannerUncheckedUpdateInputObjectSchema,
  ]),
})
