import { z } from 'zod'
import { AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateNestedOneWithoutFollowUpCaseSummaryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const AppointmentCreateNestedOneWithoutFollowUpCaseSummaryInputObjectSchema =
  Schema
