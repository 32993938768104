import { z } from 'zod'
import { ProcedureCategoryOrderByWithRelationInputObjectSchema } from './objects/ProcedureCategoryOrderByWithRelationInput.schema'
import { ProcedureCategoryWhereInputObjectSchema } from './objects/ProcedureCategoryWhereInput.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './objects/ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryCountAggregateInputObjectSchema } from './objects/ProcedureCategoryCountAggregateInput.schema'
import { ProcedureCategoryMinAggregateInputObjectSchema } from './objects/ProcedureCategoryMinAggregateInput.schema'
import { ProcedureCategoryMaxAggregateInputObjectSchema } from './objects/ProcedureCategoryMaxAggregateInput.schema'
import { ProcedureCategoryAvgAggregateInputObjectSchema } from './objects/ProcedureCategoryAvgAggregateInput.schema'
import { ProcedureCategorySumAggregateInputObjectSchema } from './objects/ProcedureCategorySumAggregateInput.schema'

export const ProcedureCategoryAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureCategoryOrderByWithRelationInputObjectSchema,
      ProcedureCategoryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureCategoryWhereInputObjectSchema.optional(),
  cursor: ProcedureCategoryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureCategoryCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureCategoryMinAggregateInputObjectSchema.optional(),
  _max: ProcedureCategoryMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureCategoryAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureCategorySumAggregateInputObjectSchema.optional(),
})
