import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderAvgOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    procedureItemId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    installmentTerm: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    flashsalePrice: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedBySkinX: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedByPartner: z.lazy(() => SortOrderSchema).optional(),
    commission: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    vat: z.lazy(() => SortOrderSchema).optional(),
    vatRate: z.lazy(() => SortOrderSchema).optional(),
    netIncome: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    v2StoreVoucherId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const ProcedureOrderAvgOrderByAggregateInputObjectSchema = Schema
