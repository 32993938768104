import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { CaseUpdateOneRequiredWithoutCaseSummaryNestedInputObjectSchema } from './CaseUpdateOneRequiredWithoutCaseSummaryNestedInput.schema'
import { CaseSummaryDiagnosisUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './CaseSummaryDiagnosisUpdateManyWithoutCaseSummaryNestedInput.schema'
import { AppointmentUpdateOneWithoutFollowUpCaseSummaryNestedInputObjectSchema } from './AppointmentUpdateOneWithoutFollowUpCaseSummaryNestedInput.schema'
import { SuggestedProductItemUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProductItemUpdateManyWithoutCaseSummaryNestedInput.schema'
import { SuggestedProductItemOriginalUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProductItemOriginalUpdateManyWithoutCaseSummaryNestedInput.schema'
import { SuggestedProcedureItemUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProcedureItemUpdateManyWithoutCaseSummaryNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    presentIllness: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    peNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isTeleMedNotRecommend: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    recommendation: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorFee: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorFeeDiscount: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    commissionRate: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    commissionPrice: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    netDoctorFee: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    pharmacistNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    recommedNumberOfTreatment: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    additionalSuggestedProcedure: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedSuggestedProductItemAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedSuggestedProductItemBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    v2CaseSummaryId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    caseUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    case: z
      .lazy(
        () => CaseUpdateOneRequiredWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    followUpAppointment: z
      .lazy(
        () =>
          AppointmentUpdateOneWithoutFollowUpCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProductItems: z
      .lazy(
        () =>
          SuggestedProductItemUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(
        () =>
          SuggestedProductItemOriginalUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProcedureItems: z
      .lazy(
        () =>
          SuggestedProcedureItemUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryUpdateInputObjectSchema = Schema
