import { z } from 'zod'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientUpdateWithoutPatientInputObjectSchema } from './NotificationPatientUpdateWithoutPatientInput.schema'
import { NotificationPatientUncheckedUpdateWithoutPatientInputObjectSchema } from './NotificationPatientUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => NotificationPatientUpdateWithoutPatientInputObjectSchema),
        z.lazy(
          () =>
            NotificationPatientUncheckedUpdateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationPatientUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
