import { z } from 'zod'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientCreateWithoutNotificationInputObjectSchema } from './NotificationPatientCreateWithoutNotificationInput.schema'
import { NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutNotificationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateOrConnectWithoutNotificationInput> =
  z
    .object({
      where: z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => NotificationPatientCreateWithoutNotificationInputObjectSchema,
        ),
        z.lazy(
          () =>
            NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema =
  Schema
