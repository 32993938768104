import { z } from 'zod'
import { ProductPictureWhereUniqueInputObjectSchema } from './ProductPictureWhereUniqueInput.schema'
import { ProductPictureCreateWithoutProductInputObjectSchema } from './ProductPictureCreateWithoutProductInput.schema'
import { ProductPictureUncheckedCreateWithoutProductInputObjectSchema } from './ProductPictureUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureCreateOrConnectWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductPictureCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductPictureCreateOrConnectWithoutProductInputObjectSchema =
  Schema
