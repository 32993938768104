import { FileValue } from '../../components/types'

interface TwoLanguageField {
  en: string
  th: string
}

export const transformProductCategory = async (form: {
  nameTr: TwoLanguageField
  descriptionTr: TwoLanguageField
  icon: FileValue
}) => {
  const { nameTr, descriptionTr, icon } = form
  const result: { [key: string]: unknown } = form

  if (!nameTr.en) {
    ;(result.nameTr as TwoLanguageField).en = ''
  }
  if (!descriptionTr.en) {
    ;(result.descriptionTr as TwoLanguageField).en = ''
  }
  if (!result.parentId) {
    result.parentId = null
  }

  if (icon?.uploadPromise) {
    const url = await icon.uploadPromise
    if (url && !Array.isArray(url)) {
      result.icon = {
        ...icon,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }

  result.path = ''

  return result
}
