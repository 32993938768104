import { z } from 'zod'
import { ProcedureReviewWhereInputObjectSchema } from './ProcedureReviewWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewListRelationFilter> = z
  .object({
    every: z.lazy(() => ProcedureReviewWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProcedureReviewWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProcedureReviewWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewListRelationFilterObjectSchema = Schema
