import { z } from 'zod'
import { ConsentWhereUniqueInputObjectSchema } from './objects/ConsentWhereUniqueInput.schema'
import { ConsentCreateInputObjectSchema } from './objects/ConsentCreateInput.schema'
import { ConsentUncheckedCreateInputObjectSchema } from './objects/ConsentUncheckedCreateInput.schema'
import { ConsentUpdateInputObjectSchema } from './objects/ConsentUpdateInput.schema'
import { ConsentUncheckedUpdateInputObjectSchema } from './objects/ConsentUncheckedUpdateInput.schema'

export const ConsentUpsertSchema = z.object({
  where: ConsentWhereUniqueInputObjectSchema,
  create: z.union([
    ConsentCreateInputObjectSchema,
    ConsentUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ConsentUpdateInputObjectSchema,
    ConsentUncheckedUpdateInputObjectSchema,
  ]),
})
