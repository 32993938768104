import { z } from 'zod'
import { NotificationCreateNestedOneWithoutPatientsInputObjectSchema } from './NotificationCreateNestedOneWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateWithoutPatientInput> = z
  .object({
    readAt: z.date().optional().nullable(),
    notification: z.lazy(
      () => NotificationCreateNestedOneWithoutPatientsInputObjectSchema,
    ),
  })
  .strict()

export const NotificationPatientCreateWithoutPatientInputObjectSchema = Schema
