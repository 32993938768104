import { z } from 'zod'
import { CoinTransactionCreateWithoutPatientInputObjectSchema } from './CoinTransactionCreateWithoutPatientInput.schema'
import { CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedCreateWithoutPatientInput.schema'
import { CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema } from './CoinTransactionCreateOrConnectWithoutPatientInput.schema'
import { CoinTransactionCreateManyPatientInputEnvelopeObjectSchema } from './CoinTransactionCreateManyPatientInputEnvelope.schema'
import { CoinTransactionWhereUniqueInputObjectSchema } from './CoinTransactionWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionCreateNestedManyWithoutPatientInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema)
            .array(),
          z.lazy(
            () => CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CoinTransactionCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CoinTransactionCreateNestedManyWithoutPatientInputObjectSchema =
  Schema
