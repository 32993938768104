import { z } from 'zod'
import { FlashsaleItemCreateWithoutVariantInputObjectSchema } from './FlashsaleItemCreateWithoutVariantInput.schema'
import { FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutVariantInput.schema'
import { FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutVariantInput.schema'
import { FlashsaleItemUpsertWithWhereUniqueWithoutVariantInputObjectSchema } from './FlashsaleItemUpsertWithWhereUniqueWithoutVariantInput.schema'
import { FlashsaleItemCreateManyVariantInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyVariantInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithWhereUniqueWithoutVariantInputObjectSchema } from './FlashsaleItemUpdateWithWhereUniqueWithoutVariantInput.schema'
import { FlashsaleItemUpdateManyWithWhereWithoutVariantInputObjectSchema } from './FlashsaleItemUpdateManyWithWhereWithoutVariantInput.schema'
import { FlashsaleItemScalarWhereInputObjectSchema } from './FlashsaleItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUncheckedUpdateManyWithoutVariantNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateManyWithWhereWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateManyWithWhereWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemUncheckedUpdateManyWithoutVariantNestedInputObjectSchema =
  Schema
