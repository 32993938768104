import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { WarehouseCountOrderByAggregateInputObjectSchema } from './WarehouseCountOrderByAggregateInput.schema'
import { WarehouseAvgOrderByAggregateInputObjectSchema } from './WarehouseAvgOrderByAggregateInput.schema'
import { WarehouseMaxOrderByAggregateInputObjectSchema } from './WarehouseMaxOrderByAggregateInput.schema'
import { WarehouseMinOrderByAggregateInputObjectSchema } from './WarehouseMinOrderByAggregateInput.schema'
import { WarehouseSumOrderByAggregateInputObjectSchema } from './WarehouseSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => WarehouseCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => WarehouseAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => WarehouseMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => WarehouseMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => WarehouseSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const WarehouseOrderByWithAggregationInputObjectSchema = Schema
