import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { CasePictureFindManySchema } from '../findManyCasePicture.schema'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'
import { TeleconsultArgsObjectSchema } from './TeleconsultArgs.schema'
import { TeleconsultFindManySchema } from '../findManyTeleconsult.schema'
import { CaseSummaryArgsObjectSchema } from './CaseSummaryArgs.schema'
import { CaseCountOutputTypeArgsObjectSchema } from './CaseCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseInclude> = z
  .object({
    followupCase: z
      .union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)])
      .optional(),
    previousCase: z
      .union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => CasePictureFindManySchema)])
      .optional(),
    appointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
    teleconsult: z
      .union([z.boolean(), z.lazy(() => TeleconsultArgsObjectSchema)])
      .optional(),
    missedCallTeleconsult: z
      .union([z.boolean(), z.lazy(() => TeleconsultFindManySchema)])
      .optional(),
    caseSummary: z
      .union([z.boolean(), z.lazy(() => CaseSummaryArgsObjectSchema)])
      .optional(),
    _count: z
      .union([z.boolean(), z.lazy(() => CaseCountOutputTypeArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const CaseIncludeObjectSchema = Schema
