import { z } from 'zod'
import { ProductItemCreateWithoutOrderInputObjectSchema } from './ProductItemCreateWithoutOrderInput.schema'
import { ProductItemUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemUncheckedCreateWithoutOrderInput.schema'
import { ProductItemCreateOrConnectWithoutOrderInputObjectSchema } from './ProductItemCreateOrConnectWithoutOrderInput.schema'
import { ProductItemUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './ProductItemUpsertWithWhereUniqueWithoutOrderInput.schema'
import { ProductItemCreateManyOrderInputEnvelopeObjectSchema } from './ProductItemCreateManyOrderInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './ProductItemUpdateWithWhereUniqueWithoutOrderInput.schema'
import { ProductItemUpdateManyWithWhereWithoutOrderInputObjectSchema } from './ProductItemUpdateManyWithWhereWithoutOrderInput.schema'
import { ProductItemScalarWhereInputObjectSchema } from './ProductItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateManyWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProductItemCreateWithoutOrderInputObjectSchema),
        z.lazy(() => ProductItemCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => ProductItemUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => ProductItemUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => ProductItemCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => ProductItemCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => ProductItemUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
        ),
        z
          .lazy(
            () => ProductItemUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => ProductItemCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => ProductItemUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
        ),
        z
          .lazy(
            () => ProductItemUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(
          () => ProductItemUpdateManyWithWhereWithoutOrderInputObjectSchema,
        ),
        z
          .lazy(
            () => ProductItemUpdateManyWithWhereWithoutOrderInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => ProductItemScalarWhereInputObjectSchema),
        z.lazy(() => ProductItemScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const ProductItemUpdateManyWithoutOrderNestedInputObjectSchema = Schema
