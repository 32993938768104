import { z } from 'zod'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ConfigurationWhereInputObjectSchema),
        z.lazy(() => ConfigurationWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ConfigurationWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ConfigurationWhereInputObjectSchema),
        z.lazy(() => ConfigurationWhereInputObjectSchema).array(),
      ])
      .optional(),
    key: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    value: z.lazy(() => JsonFilterObjectSchema).optional(),
  })
  .strict()

export const ConfigurationWhereInputObjectSchema = Schema
