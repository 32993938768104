import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { PartnerCountOrderByAggregateInputObjectSchema } from './PartnerCountOrderByAggregateInput.schema'
import { PartnerAvgOrderByAggregateInputObjectSchema } from './PartnerAvgOrderByAggregateInput.schema'
import { PartnerMaxOrderByAggregateInputObjectSchema } from './PartnerMaxOrderByAggregateInput.schema'
import { PartnerMinOrderByAggregateInputObjectSchema } from './PartnerMinOrderByAggregateInput.schema'
import { PartnerSumOrderByAggregateInputObjectSchema } from './PartnerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    logo: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    website: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    isExpertise: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    isSXEasy: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    taxId: z.lazy(() => SortOrderSchema).optional(),
    v2HospitalId: z.lazy(() => SortOrderSchema).optional(),
    v2StoreId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => PartnerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => PartnerAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => PartnerMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => PartnerMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => PartnerSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const PartnerOrderByWithAggregationInputObjectSchema = Schema
