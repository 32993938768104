import { z } from 'zod'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(
        () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
      ),
      data: z.union([
        z.lazy(
          () =>
            SuggestedProductItemOriginalUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
