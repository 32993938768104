import { z } from 'zod'
import { ProcedurePictureFindManySchema } from '../findManyProcedurePicture.schema'
import { ProcedureCategoryFindManySchema } from '../findManyProcedureCategory.schema'
import { FlashsaleItemFindManySchema } from '../findManyFlashsaleItem.schema'
import { CarouselItemFindManySchema } from '../findManyCarouselItem.schema'
import { ProcedureReviewFindManySchema } from '../findManyProcedureReview.schema'
import { ProcedureReviewSummaryArgsObjectSchema } from './ProcedureReviewSummaryArgs.schema'
import { ProcedureCountOutputTypeArgsObjectSchema } from './ProcedureCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureSelect> = z
  .object({
    id: z.boolean().optional(),
    active: z.boolean().optional(),
    searchable: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    searchField: z.boolean().optional(),
    technologyTr: z.boolean().optional(),
    usageTr: z.boolean().optional(),
    resultPeriodTr: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    preparationTr: z.boolean().optional(),
    conditionTr: z.boolean().optional(),
    methodOfUseTr: z.boolean().optional(),
    isTreatedByDoctor: z.boolean().optional(),
    priority: z.boolean().optional(),
    expireType: z.boolean().optional(),
    expireAt: z.boolean().optional(),
    expireDurationDay: z.boolean().optional(),
    tagPrice: z.boolean().optional(),
    sellingPrice: z.boolean().optional(),
    cost: z.boolean().optional(),
    commissionRate: z.boolean().optional(),
    partnerId: z.boolean().optional(),
    branches: z.boolean().optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProcedurePictureFindManySchema)])
      .optional(),
    categories: z
      .union([z.boolean(), z.lazy(() => ProcedureCategoryFindManySchema)])
      .optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    deletedAt: z.boolean().optional(),
    v2DrugItemId: z.boolean().optional(),
    flashsaleItem: z
      .union([z.boolean(), z.lazy(() => FlashsaleItemFindManySchema)])
      .optional(),
    carouselItems: z
      .union([z.boolean(), z.lazy(() => CarouselItemFindManySchema)])
      .optional(),
    procedureReviews: z
      .union([z.boolean(), z.lazy(() => ProcedureReviewFindManySchema)])
      .optional(),
    procedureReviewSummary: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureReviewSummaryArgsObjectSchema),
      ])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureSelectObjectSchema = Schema
