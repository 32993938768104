import { z } from 'zod'
import { ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './ProcedureReviewSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryCreateNestedOneWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProcedureReviewSummaryCreateOrConnectWithoutProcedureInputObjectSchema,
        )
        .optional(),
      connect: z
        .lazy(() => ProcedureReviewSummaryWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewSummaryCreateNestedOneWithoutProcedureInputObjectSchema =
  Schema
