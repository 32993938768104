import { z } from 'zod'
import { RejectReasonWhereInputObjectSchema } from './objects/RejectReasonWhereInput.schema'
import { RejectReasonOrderByWithAggregationInputObjectSchema } from './objects/RejectReasonOrderByWithAggregationInput.schema'
import { RejectReasonScalarWhereWithAggregatesInputObjectSchema } from './objects/RejectReasonScalarWhereWithAggregatesInput.schema'
import { RejectReasonScalarFieldEnumSchema } from './enums/RejectReasonScalarFieldEnum.schema'

export const RejectReasonGroupBySchema = z.object({
  where: RejectReasonWhereInputObjectSchema.optional(),
  orderBy: z.union([
    RejectReasonOrderByWithAggregationInputObjectSchema,
    RejectReasonOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: RejectReasonScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(RejectReasonScalarFieldEnumSchema),
})
