import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemCreateWithoutDeliveryInputObjectSchema } from './ProductItemCreateWithoutDeliveryInput.schema'
import { ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemCreateOrConnectWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema = Schema
