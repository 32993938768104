import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { VariantRelationFilterObjectSchema } from './VariantRelationFilter.schema'
import { VariantWhereInputObjectSchema } from './VariantWhereInput.schema'
import { WarehouseRelationFilterObjectSchema } from './WarehouseRelationFilter.schema'
import { WarehouseWhereInputObjectSchema } from './WarehouseWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => VariantWarehouseWhereInputObjectSchema),
        z.lazy(() => VariantWarehouseWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => VariantWarehouseWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => VariantWarehouseWhereInputObjectSchema),
        z.lazy(() => VariantWarehouseWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    warehouseId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    variant: z
      .union([
        z.lazy(() => VariantRelationFilterObjectSchema),
        z.lazy(() => VariantWhereInputObjectSchema),
      ])
      .optional(),
    warehouse: z
      .union([
        z.lazy(() => WarehouseRelationFilterObjectSchema),
        z.lazy(() => WarehouseWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const VariantWarehouseWhereInputObjectSchema = Schema
