import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithoutRefundInputObjectSchema } from './ProductItemUpdateWithoutRefundInput.schema'
import { ProductItemUncheckedUpdateWithoutRefundInputObjectSchema } from './ProductItemUncheckedUpdateWithoutRefundInput.schema'
import { ProductItemCreateWithoutRefundInputObjectSchema } from './ProductItemCreateWithoutRefundInput.schema'
import { ProductItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProductItemUncheckedCreateWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpsertWithWhereUniqueWithoutRefundInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductItemUpdateWithoutRefundInputObjectSchema),
        z.lazy(() => ProductItemUncheckedUpdateWithoutRefundInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema),
        z.lazy(() => ProductItemUncheckedCreateWithoutRefundInputObjectSchema),
      ]),
    })
    .strict()

export const ProductItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema =
  Schema
