import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithoutProductInputObjectSchema } from './ProductReviewUpdateWithoutProductInput.schema'
import { ProductReviewUncheckedUpdateWithoutProductInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewUpdateWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
