import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUpdateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedUpdateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedUpdateWithoutWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantWarehouseUpdateWithoutWarehouseInputObjectSchema),
        z.lazy(
          () =>
            VariantWarehouseUncheckedUpdateWithoutWarehouseInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInputObjectSchema =
  Schema
