import { z } from 'zod'
import { ProcedureOrderCreateInputObjectSchema } from './objects/ProcedureOrderCreateInput.schema'
import { ProcedureOrderUncheckedCreateInputObjectSchema } from './objects/ProcedureOrderUncheckedCreateInput.schema'

export const ProcedureOrderCreateOneSchema = z.object({
  data: z.union([
    ProcedureOrderCreateInputObjectSchema,
    ProcedureOrderUncheckedCreateInputObjectSchema,
  ]),
})
