import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { ArticlePictureListRelationFilterObjectSchema } from './ArticlePictureListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ArticleWhereInputObjectSchema),
        z.lazy(() => ArticleWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ArticleWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ArticleWhereInputObjectSchema),
        z.lazy(() => ArticleWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    coverPicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    titleTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    detailTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    pictures: z
      .lazy(() => ArticlePictureListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const ArticleWhereInputObjectSchema = Schema
