import { Typography } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { required, useTranslate } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'

const tr_resource = 'resources.procedure'

const imageRequiredValidation = (value: unknown[]) => {
  if (!value || value.length === 0) {
    return 'Picture is required'
  }

  return undefined
}

const imageMaximumValidation = (pics: unknown[]) => {
  if (pics.length > 5) {
    return 'Maximum 5 pictures'
  }

  return undefined
}

export const ProcedurePictureSecion: FC = () => {
  const translate = useTranslate()

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate([tr_resource, 'title', 'picture_section'].join('.'))}
      </Typography>
      <FormDivider />
      <ImageStorageInput
        source="pictures"
        label={false}
        multiple={true}
        validate={[required(), imageRequiredValidation, imageMaximumValidation]}
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.procedure.generateUploadPictureUrl.query({
              extension: file.name.split('.').pop() ?? '',
              fieldPath: 'pictures',
            })
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
      ></ImageStorageInput>
    </>
  )
}
