import { z } from 'zod'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'
import { BrandCreateWithoutBannersInputObjectSchema } from './BrandCreateWithoutBannersInput.schema'
import { BrandUncheckedCreateWithoutBannersInputObjectSchema } from './BrandUncheckedCreateWithoutBannersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateOrConnectWithoutBannersInput> = z
  .object({
    where: z.lazy(() => BrandWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => BrandCreateWithoutBannersInputObjectSchema),
      z.lazy(() => BrandUncheckedCreateWithoutBannersInputObjectSchema),
    ]),
  })
  .strict()

export const BrandCreateOrConnectWithoutBannersInputObjectSchema = Schema
