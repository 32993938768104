import { z } from 'zod'
import { ShippingProviderWhereUniqueInputObjectSchema } from './objects/ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderCreateInputObjectSchema } from './objects/ShippingProviderCreateInput.schema'
import { ShippingProviderUncheckedCreateInputObjectSchema } from './objects/ShippingProviderUncheckedCreateInput.schema'
import { ShippingProviderUpdateInputObjectSchema } from './objects/ShippingProviderUpdateInput.schema'
import { ShippingProviderUncheckedUpdateInputObjectSchema } from './objects/ShippingProviderUncheckedUpdateInput.schema'

export const ShippingProviderUpsertSchema = z.object({
  where: ShippingProviderWhereUniqueInputObjectSchema,
  create: z.union([
    ShippingProviderCreateInputObjectSchema,
    ShippingProviderUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ShippingProviderUpdateInputObjectSchema,
    ShippingProviderUncheckedUpdateInputObjectSchema,
  ]),
})
