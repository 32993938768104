import { z } from 'zod'
import { PartnerArgsObjectSchema } from './PartnerArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderInclude> = z
  .object({
    partner: z
      .union([z.boolean(), z.lazy(() => PartnerArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProcedureOrderIncludeObjectSchema = Schema
