import { z } from 'zod'
import { PartnerUpdateWithoutBranchesInputObjectSchema } from './PartnerUpdateWithoutBranchesInput.schema'
import { PartnerUncheckedUpdateWithoutBranchesInputObjectSchema } from './PartnerUncheckedUpdateWithoutBranchesInput.schema'
import { PartnerCreateWithoutBranchesInputObjectSchema } from './PartnerCreateWithoutBranchesInput.schema'
import { PartnerUncheckedCreateWithoutBranchesInputObjectSchema } from './PartnerUncheckedCreateWithoutBranchesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpsertWithoutBranchesInput> = z
  .object({
    update: z.union([
      z.lazy(() => PartnerUpdateWithoutBranchesInputObjectSchema),
      z.lazy(() => PartnerUncheckedUpdateWithoutBranchesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutBranchesInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutBranchesInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerUpsertWithoutBranchesInputObjectSchema = Schema
