import { z } from 'zod'
import { ProcedureCategoryCreateInputObjectSchema } from './objects/ProcedureCategoryCreateInput.schema'
import { ProcedureCategoryUncheckedCreateInputObjectSchema } from './objects/ProcedureCategoryUncheckedCreateInput.schema'

export const ProcedureCategoryCreateOneSchema = z.object({
  data: z.union([
    ProcedureCategoryCreateInputObjectSchema,
    ProcedureCategoryUncheckedCreateInputObjectSchema,
  ]),
})
