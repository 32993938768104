import { z } from 'zod'
import { VariantPictureOrderByWithRelationInputObjectSchema } from './objects/VariantPictureOrderByWithRelationInput.schema'
import { VariantPictureWhereInputObjectSchema } from './objects/VariantPictureWhereInput.schema'
import { VariantPictureWhereUniqueInputObjectSchema } from './objects/VariantPictureWhereUniqueInput.schema'
import { VariantPictureCountAggregateInputObjectSchema } from './objects/VariantPictureCountAggregateInput.schema'
import { VariantPictureMinAggregateInputObjectSchema } from './objects/VariantPictureMinAggregateInput.schema'
import { VariantPictureMaxAggregateInputObjectSchema } from './objects/VariantPictureMaxAggregateInput.schema'
import { VariantPictureAvgAggregateInputObjectSchema } from './objects/VariantPictureAvgAggregateInput.schema'
import { VariantPictureSumAggregateInputObjectSchema } from './objects/VariantPictureSumAggregateInput.schema'

export const VariantPictureAggregateSchema = z.object({
  orderBy: z
    .union([
      VariantPictureOrderByWithRelationInputObjectSchema,
      VariantPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantPictureWhereInputObjectSchema.optional(),
  cursor: VariantPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), VariantPictureCountAggregateInputObjectSchema])
    .optional(),
  _min: VariantPictureMinAggregateInputObjectSchema.optional(),
  _max: VariantPictureMaxAggregateInputObjectSchema.optional(),
  _avg: VariantPictureAvgAggregateInputObjectSchema.optional(),
  _sum: VariantPictureSumAggregateInputObjectSchema.optional(),
})
