import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryCountAggregateInputType> =
  z
    .object({
      id: z.literal(true).optional(),
      createdAt: z.literal(true).optional(),
      updatedAt: z.literal(true).optional(),
      procedureId: z.literal(true).optional(),
      partnerId: z.literal(true).optional(),
      overallRating: z.literal(true).optional(),
      environmentRating: z.literal(true).optional(),
      serviceRating: z.literal(true).optional(),
      specialtyRating: z.literal(true).optional(),
      valueRating: z.literal(true).optional(),
      countForRating5: z.literal(true).optional(),
      countForRating4: z.literal(true).optional(),
      countForRating3: z.literal(true).optional(),
      countForRating2: z.literal(true).optional(),
      countForRating1: z.literal(true).optional(),
      _all: z.literal(true).optional(),
    })
    .strict()

export const ProcedureReviewSummaryCountAggregateInputObjectSchema = Schema
