import { z } from 'zod'
import { PartnerCreateNestedOneWithoutSkuInputObjectSchema } from './PartnerCreateNestedOneWithoutSkuInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    sku: z.string(),
    variantId: z.number(),
    cost: z.number(),
    active: z.boolean(),
    partner: z.lazy(() => PartnerCreateNestedOneWithoutSkuInputObjectSchema),
  })
  .strict()

export const SkuCreateInputObjectSchema = Schema
