import { z } from 'zod'
import { CouponScalarWhereInputObjectSchema } from './CouponScalarWhereInput.schema'
import { CouponUpdateManyMutationInputObjectSchema } from './CouponUpdateManyMutationInput.schema'
import { CouponUncheckedUpdateManyWithoutCouponsInputObjectSchema } from './CouponUncheckedUpdateManyWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponUpdateManyWithWhereWithoutOrderInput> = z
  .object({
    where: z.lazy(() => CouponScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => CouponUpdateManyMutationInputObjectSchema),
      z.lazy(() => CouponUncheckedUpdateManyWithoutCouponsInputObjectSchema),
    ]),
  })
  .strict()

export const CouponUpdateManyWithWhereWithoutOrderInputObjectSchema = Schema
