import { z } from 'zod'
import { SegmentWhereInputObjectSchema } from './SegmentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentListRelationFilter> = z
  .object({
    every: z.lazy(() => SegmentWhereInputObjectSchema).optional(),
    some: z.lazy(() => SegmentWhereInputObjectSchema).optional(),
    none: z.lazy(() => SegmentWhereInputObjectSchema).optional(),
  })
  .strict()

export const SegmentListRelationFilterObjectSchema = Schema
