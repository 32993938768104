import { z } from 'zod'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'
import { CaseCreateNestedOneWithoutTeleconsultInputObjectSchema } from './CaseCreateNestedOneWithoutTeleconsultInput.schema'
import { CaseCreateNestedOneWithoutMissedCallTeleconsultInputObjectSchema } from './CaseCreateNestedOneWithoutMissedCallTeleconsultInput.schema'
import { AppointmentCreateNestedOneWithoutTeleconsultInputObjectSchema } from './AppointmentCreateNestedOneWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateInput> = z
  .object({
    doctorId: z.number(),
    patientId: z.number(),
    videoRoomId: z.string().optional().nullable(),
    doctorVideoRoomUid: z.number().optional().nullable(),
    patientVideoRoomUid: z.number().optional().nullable(),
    startAt: z.date().optional().nullable(),
    endAt: z.date().optional().nullable(),
    callTime: z.number().optional().nullable(),
    status: z.lazy(() => ConsultingStatusSchema),
    remark: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    case: z
      .lazy(() => CaseCreateNestedOneWithoutTeleconsultInputObjectSchema)
      .optional(),
    missedCallCase: z
      .lazy(
        () => CaseCreateNestedOneWithoutMissedCallTeleconsultInputObjectSchema,
      )
      .optional(),
    appointment: z
      .lazy(() => AppointmentCreateNestedOneWithoutTeleconsultInputObjectSchema)
      .optional(),
  })
  .strict()

export const TeleconsultCreateInputObjectSchema = Schema
