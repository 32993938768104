import { Box, Chip, Grid, Typography } from '@mui/material'
import {
  ArrayField,
  BooleanInput,
  FunctionField,
  ImageField,
  NumberField,
  SimpleShowLayout,
  TextField,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import {
  CustomBooleanField,
  CustomI18nField,
} from '../../../components/CustomField'
import { DiscountField } from '../../../components/DiscountField'
import { FormDivider } from '../../../components/FormInput/divider'
import { LocaleDateField } from '../../../components/LocaleDateField'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { useI18nFormatter } from '../../../utils'

const TagList = () => {
  const { isLoading, data } = useListContext()
  const i18nFormatter = useI18nFormatter()
  return isLoading ? null : (
    <Grid container spacing={1}>
      {data.map((d, index) => (
        <Grid item xs="auto" key={`grid_tag_${d}_${index}`}>
          <Chip key={`tag_${d}`} label={i18nFormatter(d)} />
        </Grid>
      ))}
    </Grid>
  )
}

export const CouponGroupInfo = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()

  const { setValue } = useFormContext()

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const activeValue = e.target.checked
    try {
      await adminGatewayClient.couponGroup.setActive.mutate({
        id: Number(id),
        active: activeValue,
      })
      notify(`Coupon group is ${activeValue ? 'active' : 'inactive'}`, {
        type: activeValue ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', record)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const handleIsPublicChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const isPublicValue = e.target.checked
    try {
      await adminGatewayClient.couponGroup.setIsPublic.mutate({
        id: Number(id),
        isPublic: isPublicValue,
      })
      notify(
        `Coupon group is ${
          isPublicValue ? 'shown on public' : 'hidden on public'
        }`,
        {
          type: isPublicValue ? 'success' : 'warning',
        },
      )
      refresh()
    } catch (e) {
      setValue('isPublic', !isPublicValue)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Group {'('}
            {record && record.prefix ? `Prefix: ${record.prefix}` : null}{' '}
            {record && record.code ? `Code: ${record.code}` : null}
            {')'}
          </Typography>
        </Box>
        {record?.id ? (
          <Box sx={{ display: 'flex' }}>
            {/* <BooleanInput
              source="active"
              onChange={(e) => handleActiveChanged(record.id, e)}
            /> */}
            <BooleanInput
              source="isPublic"
              onChange={(e) => handleIsPublicChanged(record.id, e)}
            />
          </Box>
        ) : null}
      </Box>
      <FormDivider />
      <SimpleShowLayout>
        <ImageField
          source="picture.src"
          label="resources.couponGroup.fields.picture"
          sx={{
            '& img': { width: 'auto !important', height: '100% !important' },
          }}
        />
        <CustomI18nField source="titleTr" fieldTr="titleTr" />
        <CustomI18nField source="descriptionTr" fieldTr="descriptionTr" />
        <TextField source="note" />
        <ArrayField source="tags">
          <TagList />
        </ArrayField>
      </SimpleShowLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <TextField source="prefix" emptyText="-" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <TextField source="code" emptyText="-" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <TextField source="type" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <LocaleDateField showTime source="startAt" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <LocaleDateField showTime source="expireAt" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <LocaleDateField showTime source="createdAt" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <FunctionField
              source="discount"
              display="inline-block"
              render={(record: { discount: number; discountType: string }) =>
                DiscountField(record.discount, record.discountType)
              }
              onClick={(e: { stopPropagation: () => void }) =>
                e.stopPropagation()
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <NumberField source="minTotal" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <NumberField
              source="maxDiscount"
              prefix="฿"
              emptyText="Unlimited"
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <NumberField source="quota" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <NumberField source="quotaPerUser" emptyText="Unlimited" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SimpleShowLayout>
            <CustomBooleanField source="isOnlyNewPatient" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </>
  )
}
