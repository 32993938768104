import { z } from 'zod'
import { CategoryUpdateWithoutProceduresInputObjectSchema } from './CategoryUpdateWithoutProceduresInput.schema'
import { CategoryUncheckedUpdateWithoutProceduresInputObjectSchema } from './CategoryUncheckedUpdateWithoutProceduresInput.schema'
import { CategoryCreateWithoutProceduresInputObjectSchema } from './CategoryCreateWithoutProceduresInput.schema'
import { CategoryUncheckedCreateWithoutProceduresInputObjectSchema } from './CategoryUncheckedCreateWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryUpsertWithoutProceduresInput> = z
  .object({
    update: z.union([
      z.lazy(() => CategoryUpdateWithoutProceduresInputObjectSchema),
      z.lazy(() => CategoryUncheckedUpdateWithoutProceduresInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CategoryCreateWithoutProceduresInputObjectSchema),
      z.lazy(() => CategoryUncheckedCreateWithoutProceduresInputObjectSchema),
    ]),
  })
  .strict()

export const CategoryUpsertWithoutProceduresInputObjectSchema = Schema
