import { z } from 'zod'
import { OldPatientUpdateInputObjectSchema } from './objects/OldPatientUpdateInput.schema'
import { OldPatientUncheckedUpdateInputObjectSchema } from './objects/OldPatientUncheckedUpdateInput.schema'
import { OldPatientWhereUniqueInputObjectSchema } from './objects/OldPatientWhereUniqueInput.schema'

export const OldPatientUpdateOneSchema = z.object({
  data: z.union([
    OldPatientUpdateInputObjectSchema,
    OldPatientUncheckedUpdateInputObjectSchema,
  ]),
  where: OldPatientWhereUniqueInputObjectSchema,
})
