import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateManyCarouselInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    procedureId: z.number(),
  })
  .strict()

export const CarouselItemCreateManyCarouselInputObjectSchema = Schema
