import { z } from 'zod'
import { ProcedureBannerCreateInputObjectSchema } from './objects/ProcedureBannerCreateInput.schema'
import { ProcedureBannerUncheckedCreateInputObjectSchema } from './objects/ProcedureBannerUncheckedCreateInput.schema'

export const ProcedureBannerCreateOneSchema = z.object({
  data: z.union([
    ProcedureBannerCreateInputObjectSchema,
    ProcedureBannerUncheckedCreateInputObjectSchema,
  ]),
})
