import { z } from 'zod'
import { ProcedureCategoryCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateWithoutProcedureInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureCategoryCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedureCategoryCreateManyProcedureInputEnvelope.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUncheckedCreateNestedManyWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProcedureCategoryCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureCategoryCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedureCategoryCreateManyProcedureInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureCategoryUncheckedCreateNestedManyWithoutProcedureInputObjectSchema =
  Schema
