import { Divider } from '@mui/material'
import { FC } from 'react'
import { required } from 'react-admin'
import { FormDateInput } from './formDateInput'
import { FormSelectInput } from './formSelectInput'
import { FormTextInput } from './formTextInput'

const validateIdNumber = (
  value: string,
  values: { [key: string]: unknown },
) => {
  if (values['idCardNumber'] || values['passportNumber']) {
    return
  }
  return 'กรุณากรอกเลขบัตรประจำตัวประชาชนหรือเลขพาสปอร์ต'
}

export const TextSection: FC = () => {
  return (
    <>
      <FormTextInput
        source="firstname"
        label="ชื่อจริง"
        validate={required()}
      />
      <FormTextInput source="lastname" label="นามสกุล" validate={required()} />
      <Divider sx={{ mb: 2.5 }} />
      <FormSelectInput
        source="gender"
        label="เพศ"
        choices={[
          { id: 'MALE', name: 'ชาย' },
          { id: 'FEMALE', name: 'หญิง' },
        ]}
        validate={required()}
      />
      <FormDateInput source="birthDate" label="วันเกิด" validate={required()} />
      <Divider sx={{ mb: 2.5 }} />
      <FormTextInput
        source="idCardNumber"
        label="เลขบัตรประจำตัวประชาชน"
        validate={validateIdNumber}
      />
      <FormTextInput
        source="passportNumber"
        label="เลขพาสปอร์ต"
        validate={validateIdNumber}
      />
    </>
  )
}
