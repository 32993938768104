import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureBannerCountOrderByAggregateInputObjectSchema } from './ProcedureBannerCountOrderByAggregateInput.schema'
import { ProcedureBannerAvgOrderByAggregateInputObjectSchema } from './ProcedureBannerAvgOrderByAggregateInput.schema'
import { ProcedureBannerMaxOrderByAggregateInputObjectSchema } from './ProcedureBannerMaxOrderByAggregateInput.schema'
import { ProcedureBannerMinOrderByAggregateInputObjectSchema } from './ProcedureBannerMinOrderByAggregateInput.schema'
import { ProcedureBannerSumOrderByAggregateInputObjectSchema } from './ProcedureBannerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureBannerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    actionUri: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedureBannerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedureBannerAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedureBannerMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedureBannerMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedureBannerSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureBannerOrderByWithAggregationInputObjectSchema = Schema
