import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { UserInfo } from './create-edit-section/userInfo'
import { transformPartnerUser } from './transform'

const PartnerUserCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partnerUser" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerUserCreateForm = () => {
  return (
    <SimpleForm toolbar={<PartnerUserCreateToolbar />}>
      <UserInfo />
    </SimpleForm>
  )
}

export const PartnerUserCreate = () => (
  <CreateSaveRouteBack transform={transformPartnerUser} redirect="list">
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <PartnerUserCreateForm />
        </CardContent>
      </Card>
    </Box>
  </CreateSaveRouteBack>
)
