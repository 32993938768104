import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { ProductCategoryUncheckedCreateNestedManyWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedCreateNestedManyWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CategoryUncheckedCreateWithoutChildrenInput> = z
  .object({
    id: z.number().optional(),
    active: z.boolean().optional(),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    priority: z.number().optional(),
    icon: z.string().optional().nullable(),
    path: z.string(),
    parentId: z.number().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    products: z
      .lazy(
        () =>
          ProductCategoryUncheckedCreateNestedManyWithoutCategoryInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CategoryUncheckedCreateWithoutChildrenInputObjectSchema = Schema
