import { Box, Card, CardContent } from '@mui/material'
import { FC } from 'react'
import { Create, SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { useSearchParams } from 'react-router-dom'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ProcedureInfoSection } from './create-edit-section/procedureInfo'

const FlashsaleItemProcedureCreateToolbar: FC<{
  flashsaleId: number
}> = ({ flashsaleId }) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton
          action="edit"
          resource="flashsaleProcedure"
          id={flashsaleId}
        />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const FlashsaleItemProcedureCreateForm: FC<{
  flashsaleId: number
}> = ({ flashsaleId }) => {
  return (
    <SimpleForm
      toolbar={
        <FlashsaleItemProcedureCreateToolbar flashsaleId={flashsaleId} />
      }
    >
      <ProcedureInfoSection />
    </SimpleForm>
  )
}

export const FlashsaleItemProcedureCreate = () => {
  const [searchParams] = useSearchParams()
  const flashsaleId = Number(searchParams.get('flashsaleId'))

  return (
    <Create
      redirect={() => `flashsaleProcedure/${flashsaleId}`}
      sx={{
        alignSelf: 'center',
        paddingBottom: '2rem',
        width: '100%',
        maxWidth: '700px',
      }}
    >
      <Box>
        <Card>
          <CardContent sx={{ p: 2, borderRadius: '1rem' }}>
            <FlashsaleItemProcedureCreateForm flashsaleId={flashsaleId} />
          </CardContent>
        </Card>
      </Box>
    </Create>
  )
}
