import { z } from 'zod'
import { VariantWarehouseOrderByWithRelationInputObjectSchema } from './objects/VariantWarehouseOrderByWithRelationInput.schema'
import { VariantWarehouseWhereInputObjectSchema } from './objects/VariantWarehouseWhereInput.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './objects/VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseScalarFieldEnumSchema } from './enums/VariantWarehouseScalarFieldEnum.schema'

export const VariantWarehouseFindFirstSchema = z.object({
  orderBy: z
    .union([
      VariantWarehouseOrderByWithRelationInputObjectSchema,
      VariantWarehouseOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantWarehouseWhereInputObjectSchema.optional(),
  cursor: VariantWarehouseWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(VariantWarehouseScalarFieldEnumSchema).optional(),
})
