import { Add as AddIcon } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useState } from 'react'
import { ReferenceInput } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomButton } from '../../components/CustomButton'
import { CustomAutoCompleteInput } from '../../components/CustomInput'
import { handleFilterFullnameQuery } from '../../utils'
import CreateForm from './createForm'
import { ExportAppointmentReportButton } from './exportAppointmentReportButton'
interface FilterListFormProps {
  fetchAvailableSlot: () => Promise<void>
  setDoctorId: React.Dispatch<React.SetStateAction<number | null>>
  setPatientId: React.Dispatch<React.SetStateAction<number | null>>
}

export const FilterListForm = ({
  fetchAvailableSlot,
  setDoctorId,
  setPatientId,
}: FilterListFormProps) => {
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false)
  const form = useForm({
    defaultValues: {
      doctorId: null,
      patientId: null,
    },
  })

  form.watch((value, { name, type }) => {
    setDoctorId(value.doctorId ?? null)
    setPatientId(value.patientId ?? null)
  })

  const doctorOptionText = (choice: {
    prefix: string
    firstname: string
    lastname: string
  }) => {
    return `${choice.prefix} ${choice.firstname} ${choice.lastname}`
  }

  const patientOptionText = (choice: {
    firstname: string
    lastname: string
  }) => {
    return `${choice.firstname} ${choice.lastname}`
  }

  return (
    <FormProvider {...form}>
      <form>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <Box sx={{ flexGrow: 2 }}>
            <ReferenceInput
              source="doctorId"
              reference="doctor"
              sort={{ field: 'id', order: 'ASC' }}
            >
              <CustomAutoCompleteInput
                optionText={doctorOptionText}
                filterToQuery={handleFilterFullnameQuery}
                label="Search by Doctor"
                helperText={false}
                disableClearable={false}
              />
            </ReferenceInput>
          </Box>
          <Box sx={{ flexGrow: 2 }}>
            <ReferenceInput
              source="patientId"
              reference="patient"
              sort={{ field: 'id', order: 'ASC' }}
            >
              <CustomAutoCompleteInput
                optionText={patientOptionText}
                filterToQuery={handleFilterFullnameQuery}
                label="Search by Patient"
                helperText={false}
                disableClearable={false}
              />
            </ReferenceInput>
          </Box>
          <Box sx={{ flexGrow: 2 }} />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <CreateForm
              fetchAvailableSlot={fetchAvailableSlot}
              openCreateForm={openCreateForm}
              setOpenCreateForm={setOpenCreateForm}
            />
            <CustomButton
              icon={<AddIcon />}
              size="medium"
              variant="contained"
              label="resources.doctorScheduler.action.create_slot"
              onClick={() => setOpenCreateForm(true)}
            />
            <ExportAppointmentReportButton />
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
