import { z } from 'zod'

export const OfficeHourScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'weekday',
  'openAt',
  'closeAt',
  'timezone',
  'branchId',
])
