import { z } from 'zod'
import { RejectReasonCreateWithoutKycDataInputObjectSchema } from './RejectReasonCreateWithoutKycDataInput.schema'
import { RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedCreateWithoutKycDataInput.schema'
import { RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema } from './RejectReasonCreateOrConnectWithoutKycDataInput.schema'
import { RejectReasonCreateManyKycDataInputEnvelopeObjectSchema } from './RejectReasonCreateManyKycDataInputEnvelope.schema'
import { RejectReasonWhereUniqueInputObjectSchema } from './RejectReasonWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonUncheckedCreateNestedManyWithoutKycDataInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema),
          z
            .lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema)
            .array(),
          z.lazy(
            () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () => RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => RejectReasonCreateManyKycDataInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const RejectReasonUncheckedCreateNestedManyWithoutKycDataInputObjectSchema =
  Schema
