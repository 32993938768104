import { Box, Card, CardContent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ProcedureInfoSection } from './create-edit-section/procedureInfo'

const CarouselItemProcedureEditToolbar: FC<{
  onSetCarouselId: (id: number) => void
}> = ({ onSetCarouselId }) => {
  const record = useRecordContext()
  useEffect(() => {
    if (record && record.carouselId) {
      onSetCarouselId(record.carouselId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton
          action="edit"
          resource="carouselProcedure"
          id={record.carouselId}
        />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const CarouselItemProcedureEditForm: FC<{
  onSetCarouselId: (id: number) => void
}> = ({ onSetCarouselId }) => {
  const handleSetCarouselId = (id: number) => {
    onSetCarouselId(id)
  }
  return (
    <SimpleForm
      toolbar={
        <CarouselItemProcedureEditToolbar
          onSetCarouselId={handleSetCarouselId}
        />
      }
    >
      <ProcedureInfoSection title="Edit Carousel item (procedure)" />
    </SimpleForm>
  )
}

export const CarouselItemProcedureEdit = () => {
  const [carouselId, setCarouselId] = useState<number>()

  return (
    <Edit
      mutationMode="pessimistic"
      component="div"
      redirect={() => `carouselProcedure/${carouselId}`}
      sx={{
        alignSelf: 'center',
        paddingBottom: '2rem',
        width: '100%',
        maxWidth: '700px',
      }}
    >
      <Box>
        <Card>
          <CardContent sx={{ p: 2, borderRadius: '1rem' }}>
            <CarouselItemProcedureEditForm onSetCarouselId={setCarouselId} />
          </CardContent>
        </Card>
      </Box>
    </Edit>
  )
}
