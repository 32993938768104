import { z } from 'zod'
import { AcceptedConsentWhereInputObjectSchema } from './objects/AcceptedConsentWhereInput.schema'
import { AcceptedConsentOrderByWithAggregationInputObjectSchema } from './objects/AcceptedConsentOrderByWithAggregationInput.schema'
import { AcceptedConsentScalarWhereWithAggregatesInputObjectSchema } from './objects/AcceptedConsentScalarWhereWithAggregatesInput.schema'
import { AcceptedConsentScalarFieldEnumSchema } from './enums/AcceptedConsentScalarFieldEnum.schema'

export const AcceptedConsentGroupBySchema = z.object({
  where: AcceptedConsentWhereInputObjectSchema.optional(),
  orderBy: z.union([
    AcceptedConsentOrderByWithAggregationInputObjectSchema,
    AcceptedConsentOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: AcceptedConsentScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(AcceptedConsentScalarFieldEnumSchema),
})
