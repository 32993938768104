import * as z from "zod"
import { CompleteProduct, RelatedProductModel, CompleteBrand, RelatedBrandModel } from "./index"

export const ProductReviewSummaryModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  productId: z.number().int(),
  brandId: z.number().int(),
  overallRating: z.number(),
  qualityRating: z.number(),
  shippingRating: z.number(),
  packagingRating: z.number(),
  countForRating5: z.number().int(),
  countForRating4: z.number().int(),
  countForRating3: z.number().int(),
  countForRating2: z.number().int(),
  countForRating1: z.number().int(),
})

export interface CompleteProductReviewSummary extends z.infer<typeof ProductReviewSummaryModel> {
  product: CompleteProduct
  brand: CompleteBrand
}

/**
 * RelatedProductReviewSummaryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductReviewSummaryModel: z.ZodSchema<CompleteProductReviewSummary> = z.lazy(() => ProductReviewSummaryModel.extend({
  product: RelatedProductModel,
  brand: RelatedBrandModel,
}))
