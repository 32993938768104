import { z } from 'zod'
import { ProductItemOriginalUpdateInputObjectSchema } from './objects/ProductItemOriginalUpdateInput.schema'
import { ProductItemOriginalUncheckedUpdateInputObjectSchema } from './objects/ProductItemOriginalUncheckedUpdateInput.schema'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './objects/ProductItemOriginalWhereUniqueInput.schema'

export const ProductItemOriginalUpdateOneSchema = z.object({
  data: z.union([
    ProductItemOriginalUpdateInputObjectSchema,
    ProductItemOriginalUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductItemOriginalWhereUniqueInputObjectSchema,
})
