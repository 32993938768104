import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptInclude> = z
  .object({
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ReceiptIncludeObjectSchema = Schema
