import { z } from 'zod'

export const PaymentStatusSchema = z.enum([
  'IN_PROGRESS',
  'PENDING',
  'SUCCESS',
  'FAILED',
  'EXPIRED',
  'DISPUTED',
  'DISPUTE_WON',
  'DISPUTE_LOST',
])
