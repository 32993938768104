import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'
import { NotificationArgsObjectSchema } from './NotificationArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientSelect> = z
  .object({
    patientId: z.boolean().optional(),
    notificationId: z.boolean().optional(),
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    notification: z
      .union([z.boolean(), z.lazy(() => NotificationArgsObjectSchema)])
      .optional(),
    readAt: z.boolean().optional(),
  })
  .strict()

export const NotificationPatientSelectObjectSchema = Schema
