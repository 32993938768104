import { z } from 'zod'
import { BrandBannerFindManySchema } from '../findManyBrandBanner.schema'
import { ProductFindManySchema } from '../findManyProduct.schema'
import { ProductReviewFindManySchema } from '../findManyProductReview.schema'
import { ProductReviewSummaryFindManySchema } from '../findManyProductReviewSummary.schema'
import { BrandCountOutputTypeArgsObjectSchema } from './BrandCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandInclude> = z
  .object({
    banners: z
      .union([z.boolean(), z.lazy(() => BrandBannerFindManySchema)])
      .optional(),
    products: z
      .union([z.boolean(), z.lazy(() => ProductFindManySchema)])
      .optional(),
    productReviews: z
      .union([z.boolean(), z.lazy(() => ProductReviewFindManySchema)])
      .optional(),
    productReviewSummaries: z
      .union([z.boolean(), z.lazy(() => ProductReviewSummaryFindManySchema)])
      .optional(),
    _count: z
      .union([z.boolean(), z.lazy(() => BrandCountOutputTypeArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const BrandIncludeObjectSchema = Schema
