import { z } from 'zod'
import { DeliveryLogWhereUniqueInputObjectSchema } from './DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogUpdateWithoutDeliveryInputObjectSchema } from './DeliveryLogUpdateWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedUpdateWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedUpdateWithoutDeliveryInput.schema'
import { DeliveryLogCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedCreateWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => DeliveryLogUpdateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => DeliveryLogUncheckedUpdateWithoutDeliveryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => DeliveryLogCreateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema =
  Schema
