import { Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { BooleanInput, email, required } from 'react-admin'
import {
  FormNumberInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { FormSelectInput } from './formSelectInput'
import { ProfilePictureInput } from './profilePicture'

export const ProfileSection: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลเบื้องต้น
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <ProfilePictureInput
            source="picture"
            label="รูปคุณหมอ"
            validator={required()}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BooleanInput
                source="isRecommend"
                defaultValue={false}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    width: 'max-content',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <BooleanInput
                source="isOnline"
                label={'Is Online'}
                defaultValue={true}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    width: 'max-content',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <FormNumberInput source="videoCallFee" validate={required()} />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              <BooleanInput
                source="isVideo"
                defaultValue={true}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    width: 'max-content',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormNumberInput source="chatFee" />
            </Grid>
            <Grid item xs={12} md={6}>
              <BooleanInput
                source="isChat"
                defaultValue={false}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    width: 'max-content',
                  },
                }}
              />
            </Grid>
          </Grid>

          <BooleanInput
            source="isOpenQueue"
            label={'Open for queues'}
            defaultValue={false}
            sx={{
              '& .MuiFormControlLabel-root': {
                width: 'max-content',
              },
            }}
          />
          <BooleanInput
            source="isCanMakeAppointment"
            label={'Open for appointment'}
            defaultValue={true}
            sx={{
              '& .MuiFormControlLabel-root': {
                width: 'max-content',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <FormTextInput
            source="prefix"
            label="คำนำหน้า"
            validate={required()}
          />
          <FormTextInput
            source="firstname"
            label="ชื่อจริง"
            validate={required()}
          />
          <FormTextInput
            source="lastname"
            label="นามสกุล"
            validate={required()}
          />
          <Divider sx={{ mb: 2.5 }} />
          <FormSelectInput
            source="gender"
            label="เพศ"
            choices={[
              { id: 'MALE', name: 'ชาย' },
              { id: 'FEMALE', name: 'หญิง' },
            ]}
          />
          <FormTextInput
            source="nickname"
            label="ชื่อเล่น"
            validate={required()}
          />
          <FormTextInput source="phone" label="เบอร์โทรศัพท์" />

          <FormTextInput
            disabled={isEdit}
            source="email"
            validate={[required(), email()]}
          />
        </Grid>
      </Grid>
    </>
  )
}
