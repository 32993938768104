import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CouponOrderByRelationAggregateInputObjectSchema } from './CouponOrderByRelationAggregateInput.schema'
import { DiscountSettingOrderByRelationAggregateInputObjectSchema } from './DiscountSettingOrderByRelationAggregateInput.schema'
import { ConditionSettingOrderByRelationAggregateInputObjectSchema } from './ConditionSettingOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    prefix: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    tags: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    discount: z.lazy(() => SortOrderSchema).optional(),
    discountType: z.lazy(() => SortOrderSchema).optional(),
    minTotal: z.lazy(() => SortOrderSchema).optional(),
    maxDiscount: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    isPublic: z.lazy(() => SortOrderSchema).optional(),
    isOnlyNewPatient: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    quota: z.lazy(() => SortOrderSchema).optional(),
    quotaPerUser: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2CreatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2UpdatedBy: z.lazy(() => SortOrderSchema).optional(),
    coupons: z
      .lazy(() => CouponOrderByRelationAggregateInputObjectSchema)
      .optional(),
    discountSetting: z
      .lazy(() => DiscountSettingOrderByRelationAggregateInputObjectSchema)
      .optional(),
    conditionSetting: z
      .lazy(() => ConditionSettingOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CouponGroupOrderByWithRelationInputObjectSchema = Schema
