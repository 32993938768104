import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCountOutputTypeSelect> = z
  .object({
    pictures: z.boolean().optional(),
    categories: z.boolean().optional(),
    flashsaleItem: z.boolean().optional(),
    carouselItems: z.boolean().optional(),
    procedureReviews: z.boolean().optional(),
  })
  .strict()

export const ProcedureCountOutputTypeSelectObjectSchema = Schema
