import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureOrderCountOrderByAggregateInputObjectSchema } from './ProcedureOrderCountOrderByAggregateInput.schema'
import { ProcedureOrderAvgOrderByAggregateInputObjectSchema } from './ProcedureOrderAvgOrderByAggregateInput.schema'
import { ProcedureOrderMaxOrderByAggregateInputObjectSchema } from './ProcedureOrderMaxOrderByAggregateInput.schema'
import { ProcedureOrderMinOrderByAggregateInputObjectSchema } from './ProcedureOrderMinOrderByAggregateInput.schema'
import { ProcedureOrderSumOrderByAggregateInputObjectSchema } from './ProcedureOrderSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    voucherNumber: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    orderNumber: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    procedureItemId: z.lazy(() => SortOrderSchema).optional(),
    procedureNameTr: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    patientName: z.lazy(() => SortOrderSchema).optional(),
    patientTelNo: z.lazy(() => SortOrderSchema).optional(),
    paymentMethod: z.lazy(() => SortOrderSchema).optional(),
    installmentTerm: z.lazy(() => SortOrderSchema).optional(),
    couponCode: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    flashsalePrice: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedBySkinX: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedByPartner: z.lazy(() => SortOrderSchema).optional(),
    commission: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    vat: z.lazy(() => SortOrderSchema).optional(),
    vatRate: z.lazy(() => SortOrderSchema).optional(),
    netIncome: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    usedAt: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    v2StoreVoucherId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedureOrderCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedureOrderAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedureOrderMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedureOrderMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedureOrderSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureOrderOrderByWithAggregationInputObjectSchema = Schema
