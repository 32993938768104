import { z } from 'zod'
import { MyReviewWhereUniqueInputObjectSchema } from './MyReviewWhereUniqueInput.schema'
import { MyReviewUpdateWithoutPatientInputObjectSchema } from './MyReviewUpdateWithoutPatientInput.schema'
import { MyReviewUncheckedUpdateWithoutPatientInputObjectSchema } from './MyReviewUncheckedUpdateWithoutPatientInput.schema'
import { MyReviewCreateWithoutPatientInputObjectSchema } from './MyReviewCreateWithoutPatientInput.schema'
import { MyReviewUncheckedCreateWithoutPatientInputObjectSchema } from './MyReviewUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewUpsertWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => MyReviewUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => MyReviewUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema),
        z.lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const MyReviewUpsertWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
