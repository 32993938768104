import { z } from 'zod'
import { SuggestedProductItemOriginalWhereInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereInput.schema'
import { SuggestedProductItemOriginalOrderByWithAggregationInputObjectSchema } from './objects/SuggestedProductItemOriginalOrderByWithAggregationInput.schema'
import { SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema } from './objects/SuggestedProductItemOriginalScalarWhereWithAggregatesInput.schema'
import { SuggestedProductItemOriginalScalarFieldEnumSchema } from './enums/SuggestedProductItemOriginalScalarFieldEnum.schema'

export const SuggestedProductItemOriginalGroupBySchema = z.object({
  where: SuggestedProductItemOriginalWhereInputObjectSchema.optional(),
  orderBy: z.union([
    SuggestedProductItemOriginalOrderByWithAggregationInputObjectSchema,
    SuggestedProductItemOriginalOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    SuggestedProductItemOriginalScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(SuggestedProductItemOriginalScalarFieldEnumSchema),
})
