import { z } from 'zod'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './objects/ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureCreateInputObjectSchema } from './objects/ProcedurePictureCreateInput.schema'
import { ProcedurePictureUncheckedCreateInputObjectSchema } from './objects/ProcedurePictureUncheckedCreateInput.schema'
import { ProcedurePictureUpdateInputObjectSchema } from './objects/ProcedurePictureUpdateInput.schema'
import { ProcedurePictureUncheckedUpdateInputObjectSchema } from './objects/ProcedurePictureUncheckedUpdateInput.schema'

export const ProcedurePictureUpsertSchema = z.object({
  where: ProcedurePictureWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedurePictureCreateInputObjectSchema,
    ProcedurePictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedurePictureUpdateInputObjectSchema,
    ProcedurePictureUncheckedUpdateInputObjectSchema,
  ]),
})
