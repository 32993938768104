import { z } from 'zod'
import { ProcedureCategoryCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutCategoryInput.schema'
import { ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateOrConnectWithoutCategoryInput.schema'
import { ProcedureCategoryCreateManyCategoryInputEnvelopeObjectSchema } from './ProcedureCategoryCreateManyCategoryInputEnvelope.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateNestedManyWithoutCategoryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema),
          z
            .lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedureCategoryCreateManyCategoryInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureCategoryCreateNestedManyWithoutCategoryInputObjectSchema =
  Schema
