import { z } from 'zod'
import { KycDataUpdateInputObjectSchema } from './objects/KycDataUpdateInput.schema'
import { KycDataUncheckedUpdateInputObjectSchema } from './objects/KycDataUncheckedUpdateInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './objects/KycDataWhereUniqueInput.schema'

export const KycDataUpdateOneSchema = z.object({
  data: z.union([
    KycDataUpdateInputObjectSchema,
    KycDataUncheckedUpdateInputObjectSchema,
  ]),
  where: KycDataWhereUniqueInputObjectSchema,
})
