import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'
import { ProcedureUsageStatusSchema } from '../enums/ProcedureUsageStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProcedureOrderCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    voucherNumber: z.string(),
    orderId: z.number(),
    orderNumber: z.string(),
    procedureId: z.number(),
    procedureItemId: z.number(),
    procedureNameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    patientId: z.number(),
    patientName: z.string(),
    patientTelNo: z.string(),
    paymentMethod: z.lazy(() => PaymentMethodSchema),
    installmentTerm: z.number().optional().nullable(),
    couponCode: z.string().optional().nullable(),
    sellingPrice: z.number(),
    flashsalePrice: z.number().optional().nullable(),
    cost: z.number(),
    priceAbsorbedBySkinX: z.number().optional(),
    priceAbsorbedByPartner: z.number().optional(),
    commission: z.number(),
    commissionRate: z.number(),
    vat: z.number(),
    vatRate: z.number().optional(),
    netIncome: z.number(),
    status: z.lazy(() => ProcedureUsageStatusSchema),
    usedAt: z.date().optional().nullable(),
    expireAt: z.date(),
    note: z.string().optional().nullable(),
    partnerId: z.number(),
    v2StoreVoucherId: z.number().optional().nullable(),
  })
  .strict()

export const ProcedureOrderCreateManyInputObjectSchema = Schema
