import { Datagrid, List, TextField } from 'react-admin'
import { AvatarField } from '../../components/AvatarField'

export const AdminList = () => (
  <List disableSyncWithLocation>
    <Datagrid size="small" rowClick="edit">
      <TextField source="id" />
      <AvatarField source="picture.src" label="Picture" sortable={false} />
      <TextField source="username" />
      <TextField source="role" />
      <TextField source="prefix" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="nickname" />
    </Datagrid>
  </List>
)
