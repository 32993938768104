import { z } from 'zod'
import { SkuScalarWhereInputObjectSchema } from './SkuScalarWhereInput.schema'
import { SkuUpdateManyMutationInputObjectSchema } from './SkuUpdateManyMutationInput.schema'
import { SkuUncheckedUpdateManyWithoutSkuInputObjectSchema } from './SkuUncheckedUpdateManyWithoutSkuInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuUpdateManyWithWhereWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => SkuScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => SkuUpdateManyMutationInputObjectSchema),
      z.lazy(() => SkuUncheckedUpdateManyWithoutSkuInputObjectSchema),
    ]),
  })
  .strict()

export const SkuUpdateManyWithWhereWithoutPartnerInputObjectSchema = Schema
