import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerCreateWithoutCoverPicturesInputObjectSchema } from './PartnerCreateWithoutCoverPicturesInput.schema'
import { PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema } from './PartnerUncheckedCreateWithoutCoverPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutCoverPicturesInput> =
  z
    .object({
      where: z.lazy(() => PartnerWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PartnerCreateWithoutCoverPicturesInputObjectSchema),
        z.lazy(
          () => PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PartnerCreateOrConnectWithoutCoverPicturesInputObjectSchema =
  Schema
