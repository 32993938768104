import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Create, SaveButton, SimpleForm, useTranslate } from 'react-admin'
import { FlashsaleProductInfo } from './create-edit-section/flashsaleProductInfo'

const FlashsaleProductCreateToolbar = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}
  >
    <SaveButton />
  </Box>
)

const FlashsaleProductCreateForm = () => {
  const translate = useTranslate()
  return (
    <SimpleForm toolbar={false} sx={{ p: '16px !important' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {translate('resources.flashsaleProduct.title.campaign_section')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9}>
          <FlashsaleProductInfo mode="CREATE" />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FlashsaleProductCreateToolbar />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const FlashsaleProductCreate = () => {
  return (
    <Create redirect="list">
      <Card>
        <CardContent>
          <FlashsaleProductCreateForm />
        </CardContent>
      </Card>
    </Create>
  )
}
