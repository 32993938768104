import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { BrandArgsObjectSchema } from './BrandArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryInclude> = z
  .object({
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    brand: z
      .union([z.boolean(), z.lazy(() => BrandArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProductReviewSummaryIncludeObjectSchema = Schema
