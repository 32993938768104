import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DiagnosisCountOrderByAggregateInputObjectSchema } from './DiagnosisCountOrderByAggregateInput.schema'
import { DiagnosisAvgOrderByAggregateInputObjectSchema } from './DiagnosisAvgOrderByAggregateInput.schema'
import { DiagnosisMaxOrderByAggregateInputObjectSchema } from './DiagnosisMaxOrderByAggregateInput.schema'
import { DiagnosisMinOrderByAggregateInputObjectSchema } from './DiagnosisMinOrderByAggregateInput.schema'
import { DiagnosisSumOrderByAggregateInputObjectSchema } from './DiagnosisSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    codePlain: z.lazy(() => SortOrderSchema).optional(),
    term: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => DiagnosisCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => DiagnosisAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => DiagnosisMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => DiagnosisMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => DiagnosisSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DiagnosisOrderByWithAggregationInputObjectSchema = Schema
