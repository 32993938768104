import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumAddressTagNullableFilterObjectSchema } from './EnumAddressTagNullableFilter.schema'
import { AddressTagSchema } from '../enums/AddressTag.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => AddressWhereInputObjectSchema),
        z.lazy(() => AddressWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => AddressWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => AddressWhereInputObjectSchema),
        z.lazy(() => AddressWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    recipient: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    address: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    phone: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    province: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    district: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    subDistrict: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    postCode: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    provinceKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    districtKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    subDistrictKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    note: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    tag: z
      .union([
        z.lazy(() => EnumAddressTagNullableFilterObjectSchema),
        z.lazy(() => AddressTagSchema),
      ])
      .optional()
      .nullable(),
    isDefault: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const AddressWhereInputObjectSchema = Schema
