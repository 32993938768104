import { z } from 'zod'
import { DoctorItemWhereInputObjectSchema } from './objects/DoctorItemWhereInput.schema'
import { DoctorItemOrderByWithAggregationInputObjectSchema } from './objects/DoctorItemOrderByWithAggregationInput.schema'
import { DoctorItemScalarWhereWithAggregatesInputObjectSchema } from './objects/DoctorItemScalarWhereWithAggregatesInput.schema'
import { DoctorItemScalarFieldEnumSchema } from './enums/DoctorItemScalarFieldEnum.schema'

export const DoctorItemGroupBySchema = z.object({
  where: DoctorItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    DoctorItemOrderByWithAggregationInputObjectSchema,
    DoctorItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: DoctorItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(DoctorItemScalarFieldEnumSchema),
})
