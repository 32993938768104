import { z } from 'zod'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateManyInput> = z
  .object({
    id: z.number().optional(),
    patientId: z.number(),
    doctorId: z.number(),
    symptom: z.string(),
    drugAllergy: z.string().optional().nullable(),
    congenitalDisease: z.string().optional().nullable(),
    symptomDuration: z.number().optional().nullable(),
    symptomDurationUnit: z
      .lazy(() => SymptomDurationUnitSchema)
      .optional()
      .nullable(),
    followupCaseId: z.number().optional().nullable(),
    status: z.lazy(() => CaseStatusSchema).optional(),
    consultingChannel: z.lazy(() => ConsultingChannelSchema),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    v2RecordId: z.number().optional().nullable(),
  })
  .strict()

export const CaseCreateManyInputObjectSchema = Schema
