import { z } from 'zod'
import { VariantWarehouseCreateManyWarehouseInputObjectSchema } from './VariantWarehouseCreateManyWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateManyWarehouseInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => VariantWarehouseCreateManyWarehouseInputObjectSchema),
        z
          .lazy(() => VariantWarehouseCreateManyWarehouseInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const VariantWarehouseCreateManyWarehouseInputEnvelopeObjectSchema =
  Schema
