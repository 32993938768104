import { z } from 'zod'
import { ShippingProviderSelectObjectSchema } from './ShippingProviderSelect.schema'
import { ShippingProviderIncludeObjectSchema } from './ShippingProviderInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderArgs> = z
  .object({
    select: z.lazy(() => ShippingProviderSelectObjectSchema).optional(),
    include: z.lazy(() => ShippingProviderIncludeObjectSchema).optional(),
  })
  .strict()

export const ShippingProviderArgsObjectSchema = Schema
