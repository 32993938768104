import { z } from 'zod'
import { ConsentCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedCreateWithoutAcceptedConsentsInput.schema'
import { ConsentCreateOrConnectWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateOrConnectWithoutAcceptedConsentsInput.schema'
import { ConsentUpsertWithoutAcceptedConsentsInputObjectSchema } from './ConsentUpsertWithoutAcceptedConsentsInput.schema'
import { ConsentWhereUniqueInputObjectSchema } from './ConsentWhereUniqueInput.schema'
import { ConsentUpdateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUpdateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedUpdateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentUpdateOneRequiredWithoutAcceptedConsentsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ConsentCreateWithoutAcceptedConsentsInputObjectSchema),
          z.lazy(
            () =>
              ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ConsentCreateOrConnectWithoutAcceptedConsentsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ConsentUpsertWithoutAcceptedConsentsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ConsentWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ConsentUpdateWithoutAcceptedConsentsInputObjectSchema),
          z.lazy(
            () =>
              ConsentUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ConsentUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema =
  Schema
