import { z } from 'zod'
import { PatientCreateNestedOneWithoutNotificationsInputObjectSchema } from './PatientCreateNestedOneWithoutNotificationsInput.schema'
import { NotificationCreateNestedOneWithoutPatientsInputObjectSchema } from './NotificationCreateNestedOneWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateInput> = z
  .object({
    readAt: z.date().optional().nullable(),
    patient: z.lazy(
      () => PatientCreateNestedOneWithoutNotificationsInputObjectSchema,
    ),
    notification: z.lazy(
      () => NotificationCreateNestedOneWithoutPatientsInputObjectSchema,
    ),
  })
  .strict()

export const NotificationPatientCreateInputObjectSchema = Schema
