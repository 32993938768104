import { z } from 'zod'
import { CaseSummaryWhereInputObjectSchema } from './CaseSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryRelationFilter> = z
  .object({
    is: z.lazy(() => CaseSummaryWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => CaseSummaryWhereInputObjectSchema).optional(),
  })
  .strict()

export const CaseSummaryRelationFilterObjectSchema = Schema
