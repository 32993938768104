import { Box } from '@mui/material'
import { FC } from 'react'
import {
  AutocompleteInput,
  FilterPayload,
  ReferenceInput,
  Validator,
} from 'react-admin'

export interface FormReferenceInputProps {
  source: string
  reference: string
  optionText: string
  label: string
  validator?: Validator
  filter?: FilterPayload
}

export const FormReferenceInput: FC<FormReferenceInputProps> = ({
  source,
  reference,
  optionText,
  label,
  validator,
  filter,
}) => {
  const filterToQuery = (searchText: string) => {
    const filterObject = {} as { [key: string]: string }
    filterObject[optionText] = searchText
    return filterObject
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <ReferenceInput
            source={source}
            reference={reference}
            sort={{ field: 'id', order: 'ASC' }}
            filter={filter}
          >
            <AutocompleteInput
              fullWidth
              optionText={optionText}
              label={label}
              filterToQuery={filterToQuery}
              disableClearable
              sx={{ '& legend': { maxWidth: '100% !important' }, mb: 1 }}
              validate={validator}
            />
          </ReferenceInput>
        </Box>
      </Box>
    </Box>
  )
}
