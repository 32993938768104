import { z } from 'zod'
import { CarouselWhereInputObjectSchema } from './objects/CarouselWhereInput.schema'
import { CarouselOrderByWithAggregationInputObjectSchema } from './objects/CarouselOrderByWithAggregationInput.schema'
import { CarouselScalarWhereWithAggregatesInputObjectSchema } from './objects/CarouselScalarWhereWithAggregatesInput.schema'
import { CarouselScalarFieldEnumSchema } from './enums/CarouselScalarFieldEnum.schema'

export const CarouselGroupBySchema = z.object({
  where: CarouselWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CarouselOrderByWithAggregationInputObjectSchema,
    CarouselOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CarouselScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CarouselScalarFieldEnumSchema),
})
