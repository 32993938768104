import { z } from 'zod'
import { ReviewInitializePayloadModel } from './reviewInitializePayload.model'

export const MarkUsedProcedureOrderEventModel = z.object({
  orderId: z.number().int(),
  myReviewPayloadList: z.array(ReviewInitializePayloadModel),
})
export type MarkUsedProcedureOrderEventModel = z.infer<
  typeof MarkUsedProcedureOrderEventModel
>
