import { z } from 'zod'
import { BranchCreateWithoutOfficeHoursInputObjectSchema } from './BranchCreateWithoutOfficeHoursInput.schema'
import { BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedCreateWithoutOfficeHoursInput.schema'
import { BranchCreateOrConnectWithoutOfficeHoursInputObjectSchema } from './BranchCreateOrConnectWithoutOfficeHoursInput.schema'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCreateNestedOneWithoutOfficeHoursInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => BranchCreateWithoutOfficeHoursInputObjectSchema),
        z.lazy(() => BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => BranchCreateOrConnectWithoutOfficeHoursInputObjectSchema)
      .optional(),
    connect: z.lazy(() => BranchWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const BranchCreateNestedOneWithoutOfficeHoursInputObjectSchema = Schema
