import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { AppointmentOrderByWithRelationInputObjectSchema } from './AppointmentOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    availableAt: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    appointment: z
      .lazy(() => AppointmentOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ScheduleOrderByWithRelationInputObjectSchema = Schema
