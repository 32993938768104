import { z } from 'zod'
import { KycChannelSchema } from '../enums/KycChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumKycChannelFilter> = z
  .object({
    equals: z.lazy(() => KycChannelSchema).optional(),
    in: z
      .lazy(() => KycChannelSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => KycChannelSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => KycChannelSchema),
        z.lazy(() => NestedEnumKycChannelFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumKycChannelFilterObjectSchema = Schema
