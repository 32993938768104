import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoSelect> = z
  .object({
    id: z.boolean().optional(),
    url: z.boolean().optional(),
    thumbnail: z.boolean().optional(),
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    productId: z.boolean().optional(),
    rank: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const ProductVideoSelectObjectSchema = Schema
