import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateManyInput> = z
  .object({
    id: z.number().optional(),
    name: z.string(),
    isShow: z.boolean().optional(),
    coporateName: z.string(),
    countryName: z.string(),
    logo: z.string().optional().nullable(),
    priority: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const BrandCreateManyInputObjectSchema = Schema
