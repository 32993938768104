import { z } from 'zod'
import { HolidayOrderByWithRelationInputObjectSchema } from './objects/HolidayOrderByWithRelationInput.schema'
import { HolidayWhereInputObjectSchema } from './objects/HolidayWhereInput.schema'
import { HolidayWhereUniqueInputObjectSchema } from './objects/HolidayWhereUniqueInput.schema'
import { HolidayCountAggregateInputObjectSchema } from './objects/HolidayCountAggregateInput.schema'
import { HolidayMinAggregateInputObjectSchema } from './objects/HolidayMinAggregateInput.schema'
import { HolidayMaxAggregateInputObjectSchema } from './objects/HolidayMaxAggregateInput.schema'
import { HolidayAvgAggregateInputObjectSchema } from './objects/HolidayAvgAggregateInput.schema'
import { HolidaySumAggregateInputObjectSchema } from './objects/HolidaySumAggregateInput.schema'

export const HolidayAggregateSchema = z.object({
  orderBy: z
    .union([
      HolidayOrderByWithRelationInputObjectSchema,
      HolidayOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HolidayWhereInputObjectSchema.optional(),
  cursor: HolidayWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), HolidayCountAggregateInputObjectSchema])
    .optional(),
  _min: HolidayMinAggregateInputObjectSchema.optional(),
  _max: HolidayMaxAggregateInputObjectSchema.optional(),
  _avg: HolidayAvgAggregateInputObjectSchema.optional(),
  _sum: HolidaySumAggregateInputObjectSchema.optional(),
})
