import { z } from 'zod'
import { CarouselOrderByWithRelationInputObjectSchema } from './objects/CarouselOrderByWithRelationInput.schema'
import { CarouselWhereInputObjectSchema } from './objects/CarouselWhereInput.schema'
import { CarouselWhereUniqueInputObjectSchema } from './objects/CarouselWhereUniqueInput.schema'
import { CarouselScalarFieldEnumSchema } from './enums/CarouselScalarFieldEnum.schema'

export const CarouselFindManySchema = z.object({
  orderBy: z
    .union([
      CarouselOrderByWithRelationInputObjectSchema,
      CarouselOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CarouselWhereInputObjectSchema.optional(),
  cursor: CarouselWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CarouselScalarFieldEnumSchema).optional(),
})
