import { z } from 'zod'
import { CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutSuggestedProductItemsOriginalInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () =>
            CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () =>
            CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryUpsertWithoutSuggestedProductItemsOriginalInputObjectSchema =
  Schema
