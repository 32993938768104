import { z } from 'zod'

export const ProcedureReviewScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'procedureId',
  'partnerId',
  'overallRating',
  'environmentRating',
  'serviceRating',
  'specialtyRating',
  'valueRating',
  'message',
  'orderId',
  'patientId',
  'patientName',
  'isAnonymous',
  'priority',
])
