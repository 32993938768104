import { z } from 'zod'
import { PatientUpdateWithoutMyReviewsInputObjectSchema } from './PatientUpdateWithoutMyReviewsInput.schema'
import { PatientUncheckedUpdateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedUpdateWithoutMyReviewsInput.schema'
import { PatientCreateWithoutMyReviewsInputObjectSchema } from './PatientCreateWithoutMyReviewsInput.schema'
import { PatientUncheckedCreateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedCreateWithoutMyReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutMyReviewsInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutMyReviewsInputObjectSchema),
      z.lazy(() => PatientUncheckedUpdateWithoutMyReviewsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutMyReviewsInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutMyReviewsInputObjectSchema),
    ]),
  })
  .strict()

export const PatientUpsertWithoutMyReviewsInputObjectSchema = Schema
