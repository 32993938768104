import { Typography } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { required } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'

const imageRequiredValidation = (value: unknown[]) => {
  if (!value || value.length === 0) {
    return 'Picture is required'
  }

  return undefined
}

export const PartnerCoverPicture: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        รูปภาพ
      </Typography>
      <FormDivider />
      <ImageStorageInput
        source="coverPictures"
        label={false}
        multiple={true}
        validate={[required(), imageRequiredValidation]}
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.partner.generateUploadCoverPictureUrl.query(
              {
                extension: file.name.split('.').pop() ?? '',
              },
            )
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
      ></ImageStorageInput>
    </>
  )
}
