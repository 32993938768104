import { z } from 'zod'
import { PartnerUpdateWithoutUsersInputObjectSchema } from './PartnerUpdateWithoutUsersInput.schema'
import { PartnerUncheckedUpdateWithoutUsersInputObjectSchema } from './PartnerUncheckedUpdateWithoutUsersInput.schema'
import { PartnerCreateWithoutUsersInputObjectSchema } from './PartnerCreateWithoutUsersInput.schema'
import { PartnerUncheckedCreateWithoutUsersInputObjectSchema } from './PartnerUncheckedCreateWithoutUsersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpsertWithoutUsersInput> = z
  .object({
    update: z.union([
      z.lazy(() => PartnerUpdateWithoutUsersInputObjectSchema),
      z.lazy(() => PartnerUncheckedUpdateWithoutUsersInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutUsersInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutUsersInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerUpsertWithoutUsersInputObjectSchema = Schema
