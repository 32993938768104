import { z } from 'zod'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { PatientUpdateOneRequiredWithoutNotificationsNestedInputObjectSchema } from './PatientUpdateOneRequiredWithoutNotificationsNestedInput.schema'
import { NotificationUpdateOneRequiredWithoutPatientsNestedInputObjectSchema } from './NotificationUpdateOneRequiredWithoutPatientsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUpdateInput> = z
  .object({
    readAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    patient: z
      .lazy(
        () =>
          PatientUpdateOneRequiredWithoutNotificationsNestedInputObjectSchema,
      )
      .optional(),
    notification: z
      .lazy(
        () =>
          NotificationUpdateOneRequiredWithoutPatientsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const NotificationPatientUpdateInputObjectSchema = Schema
