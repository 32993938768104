import { z } from 'zod'
import { AppointmentCreateWithoutTeleconsultInputObjectSchema } from './AppointmentCreateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedCreateWithoutTeleconsultInput.schema'
import { AppointmentCreateOrConnectWithoutTeleconsultInputObjectSchema } from './AppointmentCreateOrConnectWithoutTeleconsultInput.schema'
import { AppointmentUpsertWithoutTeleconsultInputObjectSchema } from './AppointmentUpsertWithoutTeleconsultInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentUpdateWithoutTeleconsultInputObjectSchema } from './AppointmentUpdateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedUpdateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedUpdateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUncheckedUpdateOneWithoutTeleconsultNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AppointmentCreateWithoutTeleconsultInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => AppointmentCreateOrConnectWithoutTeleconsultInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => AppointmentUpsertWithoutTeleconsultInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => AppointmentUpdateWithoutTeleconsultInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedUpdateWithoutTeleconsultInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const AppointmentUncheckedUpdateOneWithoutTeleconsultNestedInputObjectSchema =
  Schema
