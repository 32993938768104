import { Box } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { Validator } from 'react-admin'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'

export interface FormImageInputProps {
  source: string
  label: string
  multiple?: boolean
  validator?: Validator
}

export const FormImageInput: FC<FormImageInputProps> = ({
  source,
  label,
  multiple,
  validator,
}) => {
  return (
    <Box>
      <ImageStorageInput
        validate={validator}
        source={source}
        label={label}
        multiple={multiple ?? false}
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.doctor.generateUploadPictureUrl.query({
              extension: file.name.split('.').pop() ?? '',
              fieldPath: source,
            })
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
      ></ImageStorageInput>
    </Box>
  )
}
