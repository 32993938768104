import { z } from 'zod'
import { WarehouseOrderByWithRelationInputObjectSchema } from './objects/WarehouseOrderByWithRelationInput.schema'
import { WarehouseWhereInputObjectSchema } from './objects/WarehouseWhereInput.schema'
import { WarehouseWhereUniqueInputObjectSchema } from './objects/WarehouseWhereUniqueInput.schema'
import { WarehouseScalarFieldEnumSchema } from './enums/WarehouseScalarFieldEnum.schema'

export const WarehouseFindManySchema = z.object({
  orderBy: z
    .union([
      WarehouseOrderByWithRelationInputObjectSchema,
      WarehouseOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: WarehouseWhereInputObjectSchema.optional(),
  cursor: WarehouseWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(WarehouseScalarFieldEnumSchema).optional(),
})
