import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductItemOrderByRelationAggregateInputObjectSchema } from './ProductItemOrderByRelationAggregateInput.schema'
import { ProcedureItemOrderByRelationAggregateInputObjectSchema } from './ProcedureItemOrderByRelationAggregateInput.schema'
import { OrderOrderByWithRelationInputObjectSchema } from './OrderOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    amount: z.lazy(() => SortOrderSchema).optional(),
    currency: z.lazy(() => SortOrderSchema).optional(),
    refundAt: z.lazy(() => SortOrderSchema).optional(),
    paymentGateway: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    reason: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    productItems: z
      .lazy(() => ProductItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    order: z.lazy(() => OrderOrderByWithRelationInputObjectSchema).optional(),
  })
  .strict()

export const RefundOrderByWithRelationInputObjectSchema = Schema
