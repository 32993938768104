import { z } from 'zod'
import { ProcedureCategoryOrderByWithRelationInputObjectSchema } from './objects/ProcedureCategoryOrderByWithRelationInput.schema'
import { ProcedureCategoryWhereInputObjectSchema } from './objects/ProcedureCategoryWhereInput.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './objects/ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryScalarFieldEnumSchema } from './enums/ProcedureCategoryScalarFieldEnum.schema'

export const ProcedureCategoryFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProcedureCategoryOrderByWithRelationInputObjectSchema,
      ProcedureCategoryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureCategoryWhereInputObjectSchema.optional(),
  cursor: ProcedureCategoryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureCategoryScalarFieldEnumSchema).optional(),
})
