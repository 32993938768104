import { z } from 'zod'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantCreateWithoutPicturesInputObjectSchema } from './VariantCreateWithoutPicturesInput.schema'
import { VariantUncheckedCreateWithoutPicturesInputObjectSchema } from './VariantUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateOrConnectWithoutPicturesInput> = z
  .object({
    where: z.lazy(() => VariantWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => VariantCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => VariantUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const VariantCreateOrConnectWithoutPicturesInputObjectSchema = Schema
