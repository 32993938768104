import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { IntNullableWithAggregatesFilterObjectSchema } from './IntNullableWithAggregatesFilter.schema'
import { EnumCoinTransactionStatusWithAggregatesFilterObjectSchema } from './EnumCoinTransactionStatusWithAggregatesFilter.schema'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'
import { EnumCoinTransactionTypeWithAggregatesFilterObjectSchema } from './EnumCoinTransactionTypeWithAggregatesFilter.schema'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'
import { FloatWithAggregatesFilterObjectSchema } from './FloatWithAggregatesFilter.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionScalarWhereWithAggregatesInput> =
  z
    .object({
      AND: z
        .union([
          z.lazy(
            () => CoinTransactionScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => CoinTransactionScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      OR: z
        .lazy(() => CoinTransactionScalarWhereWithAggregatesInputObjectSchema)
        .array()
        .optional(),
      NOT: z
        .union([
          z.lazy(
            () => CoinTransactionScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => CoinTransactionScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      id: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      createdAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      patientId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      orderId: z
        .union([
          z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
          z.number(),
        ])
        .optional()
        .nullable(),
      status: z
        .union([
          z.lazy(
            () => EnumCoinTransactionStatusWithAggregatesFilterObjectSchema,
          ),
          z.lazy(() => CoinTransactionStatusSchema),
        ])
        .optional(),
      type: z
        .union([
          z.lazy(() => EnumCoinTransactionTypeWithAggregatesFilterObjectSchema),
          z.lazy(() => CoinTransactionTypeSchema),
        ])
        .optional(),
      amount: z
        .union([
          z.lazy(() => FloatWithAggregatesFilterObjectSchema),
          z.number(),
        ])
        .optional(),
      note: z
        .union([
          z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
          z.string(),
        ])
        .optional()
        .nullable(),
    })
    .strict()

export const CoinTransactionScalarWhereWithAggregatesInputObjectSchema = Schema
