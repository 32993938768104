import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { FlashsaleItemListRelationFilterObjectSchema } from './FlashsaleItemListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => FlashsaleWhereInputObjectSchema),
        z.lazy(() => FlashsaleWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => FlashsaleWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => FlashsaleWhereInputObjectSchema),
        z.lazy(() => FlashsaleWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    createdBy: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    updatedBy: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    name: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    description: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    startAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    endAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    items: z.lazy(() => FlashsaleItemListRelationFilterObjectSchema).optional(),
  })
  .strict()

export const FlashsaleWhereInputObjectSchema = Schema
