import { z } from 'zod'
import { ConditionSettingWhereUniqueInputObjectSchema } from './objects/ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingCreateInputObjectSchema } from './objects/ConditionSettingCreateInput.schema'
import { ConditionSettingUncheckedCreateInputObjectSchema } from './objects/ConditionSettingUncheckedCreateInput.schema'
import { ConditionSettingUpdateInputObjectSchema } from './objects/ConditionSettingUpdateInput.schema'
import { ConditionSettingUncheckedUpdateInputObjectSchema } from './objects/ConditionSettingUncheckedUpdateInput.schema'

export const ConditionSettingUpsertSchema = z.object({
  where: ConditionSettingWhereUniqueInputObjectSchema,
  create: z.union([
    ConditionSettingCreateInputObjectSchema,
    ConditionSettingUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ConditionSettingUpdateInputObjectSchema,
    ConditionSettingUncheckedUpdateInputObjectSchema,
  ]),
})
