import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material'
import { useState } from 'react'
import { required, useEditContext, useRedirect } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FormSelectInput,
  FormTextInput,
} from '../../components/FormInput/input'
import { adminGatewayClient } from '../../service'
interface cancelFormProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
}

const Reasons = [
  'อาการดีขึ้น',
  'ติดธุระ ไม่สะดวกในวัน',
  'แพทย์ไม่สะดวก ให้ยกเลิก',
  'ขอเลื่อนนัดเป็นวันอื่น',
  'ต้องการเปลี่ยนแพทย์',
  'อื่นๆ (ระบุ)',
]

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '20%',
    minWidth: '300px',
  },
  '& .MuiDialogActions-root': {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}))

export default function CancelForm({
  openModal,
  setOpenModal,
}: cancelFormProps) {
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false)
  const { record } = useEditContext()
  const redirect = useRedirect()
  const form = useForm({
    defaultValues: {
      reason: '',
      otherReason: '',
    },
  })

  const handleSelectChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reason = event.target.value
    if (reason === Reasons.at(-1)) {
      setIsOtherReason(true)
    } else {
      setIsOtherReason(false)
    }
  }

  const onSubmit = async (data: { reason: string; otherReason: string }) => {
    if (record.id && data.reason) {
      try {
        await adminGatewayClient.appointment.cancelAppointment.mutate({
          appointmentId: record.id,
          cancelDetail: isOtherReason ? data.otherReason : data.reason,
        })
        redirect('list', 'doctorScheduler')
      } catch (err: unknown) {
        alert(err)
      }
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    form.reset()
    setIsOtherReason(false)
  }
  return (
    <FormProvider {...form}>
      <BootstrapDialog open={openModal}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>Cancel appointment</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12}>
                <FormSelectInput
                  source="reason"
                  label="เหตุผล"
                  choices={Reasons.map((reason: string) => ({
                    id: reason,
                    name: reason,
                  }))}
                  onChange={(e) => {
                    handleSelectChanged(e)
                  }}
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12}>
                {isOtherReason && (
                  <FormTextInput
                    label="ระบุ"
                    source="otherReason"
                    validate={required()}
                    resettable
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => handleCloseModal()}>
              Close
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<CancelScheduleSendIcon />}
              type="submit"
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </FormProvider>
  )
}
