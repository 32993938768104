import { z } from 'zod'
import { DoctorItemCreateWithoutOrderInputObjectSchema } from './DoctorItemCreateWithoutOrderInput.schema'
import { DoctorItemUncheckedCreateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedCreateWithoutOrderInput.schema'
import { DoctorItemCreateOrConnectWithoutOrderInputObjectSchema } from './DoctorItemCreateOrConnectWithoutOrderInput.schema'
import { DoctorItemUpsertWithoutOrderInputObjectSchema } from './DoctorItemUpsertWithoutOrderInput.schema'
import { DoctorItemWhereUniqueInputObjectSchema } from './DoctorItemWhereUniqueInput.schema'
import { DoctorItemUpdateWithoutOrderInputObjectSchema } from './DoctorItemUpdateWithoutOrderInput.schema'
import { DoctorItemUncheckedUpdateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemUpdateOneWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => DoctorItemCreateWithoutOrderInputObjectSchema),
        z.lazy(() => DoctorItemUncheckedCreateWithoutOrderInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => DoctorItemCreateOrConnectWithoutOrderInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => DoctorItemUpsertWithoutOrderInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => DoctorItemWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => DoctorItemUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => DoctorItemUncheckedUpdateWithoutOrderInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const DoctorItemUpdateOneWithoutOrderNestedInputObjectSchema = Schema
