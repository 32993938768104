import { z } from 'zod'
import { CaseSummaryDiagnosisCreateNestedManyWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateNestedManyWithoutDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCreateInput> = z
  .object({
    codePlain: z.string(),
    term: z.string(),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisCreateNestedManyWithoutDiagnosisInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const DiagnosisCreateInputObjectSchema = Schema
