import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    coin: z.literal(true).optional(),
    v2PatientId: z.literal(true).optional(),
  })
  .strict()

export const PatientAvgAggregateInputObjectSchema = Schema
