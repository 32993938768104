import { z } from 'zod'
import { WarehouseOrderByWithRelationInputObjectSchema } from './objects/WarehouseOrderByWithRelationInput.schema'
import { WarehouseWhereInputObjectSchema } from './objects/WarehouseWhereInput.schema'
import { WarehouseWhereUniqueInputObjectSchema } from './objects/WarehouseWhereUniqueInput.schema'
import { WarehouseCountAggregateInputObjectSchema } from './objects/WarehouseCountAggregateInput.schema'
import { WarehouseMinAggregateInputObjectSchema } from './objects/WarehouseMinAggregateInput.schema'
import { WarehouseMaxAggregateInputObjectSchema } from './objects/WarehouseMaxAggregateInput.schema'
import { WarehouseAvgAggregateInputObjectSchema } from './objects/WarehouseAvgAggregateInput.schema'
import { WarehouseSumAggregateInputObjectSchema } from './objects/WarehouseSumAggregateInput.schema'

export const WarehouseAggregateSchema = z.object({
  orderBy: z
    .union([
      WarehouseOrderByWithRelationInputObjectSchema,
      WarehouseOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: WarehouseWhereInputObjectSchema.optional(),
  cursor: WarehouseWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), WarehouseCountAggregateInputObjectSchema])
    .optional(),
  _min: WarehouseMinAggregateInputObjectSchema.optional(),
  _max: WarehouseMaxAggregateInputObjectSchema.optional(),
  _avg: WarehouseAvgAggregateInputObjectSchema.optional(),
  _sum: WarehouseSumAggregateInputObjectSchema.optional(),
})
