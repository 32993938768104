import { z } from 'zod'
import { SuggestedProductItemOriginalCreateManyCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalCreateManyCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalCreateManyCaseSummaryInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () =>
            SuggestedProductItemOriginalCreateManyCaseSummaryInputObjectSchema,
        ),
        z
          .lazy(
            () =>
              SuggestedProductItemOriginalCreateManyCaseSummaryInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const SuggestedProductItemOriginalCreateManyCaseSummaryInputEnvelopeObjectSchema =
  Schema
