import { z } from 'zod'
import { NotificationCountOutputTypeSelectObjectSchema } from './NotificationCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => NotificationCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const NotificationCountOutputTypeArgsObjectSchema = Schema
