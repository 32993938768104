import { z } from 'zod'
import { CaseUpdateWithoutAppointmentInputObjectSchema } from './CaseUpdateWithoutAppointmentInput.schema'
import { CaseUncheckedUpdateWithoutAppointmentInputObjectSchema } from './CaseUncheckedUpdateWithoutAppointmentInput.schema'
import { CaseCreateWithoutAppointmentInputObjectSchema } from './CaseCreateWithoutAppointmentInput.schema'
import { CaseUncheckedCreateWithoutAppointmentInputObjectSchema } from './CaseUncheckedCreateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutAppointmentInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutAppointmentInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutAppointmentInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutAppointmentInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutAppointmentInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutAppointmentInputObjectSchema = Schema
