import { z } from 'zod'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInputObjectSchema =
  Schema
