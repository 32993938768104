import { z } from 'zod'
import { SalesTypeSchema } from '../enums/SalesType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumSalesTypeFilter> = z
  .object({
    equals: z.lazy(() => SalesTypeSchema).optional(),
    in: z
      .lazy(() => SalesTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => SalesTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => SalesTypeSchema),
        z.lazy(() => NestedEnumSalesTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumSalesTypeFilterObjectSchema = Schema
