import { z } from 'zod'
import { SkuVariantIdPartnerIdCompoundUniqueInputObjectSchema } from './SkuVariantIdPartnerIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    variantId_partnerId: z
      .lazy(() => SkuVariantIdPartnerIdCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict()

export const SkuWhereUniqueInputObjectSchema = Schema
