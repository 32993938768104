import { Box } from '@mui/material'
import { FC } from 'react'
import { Validator } from 'react-admin'
import { CustomTextInput } from '../../../components/CustomInput'

export interface FormTextInputProps {
  source: string
  label: string
  validator?: Validator
}

export const FormTextInput: FC<FormTextInputProps> = ({
  source,
  label,
  validator,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <CustomTextInput
            sx={{ mb: 1 }}
            fullWidth
            source={source}
            label={label}
            validate={validator}
          />
        </Box>
      </Box>
    </Box>
  )
}
