import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteCouponGroup, RelatedCouponGroupModel } from "./index"

export const DiscountSettingModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  scope: z.enum(['ALL', 'ONLY', 'EXCEPT']),
  itemType: z.enum(['DOCTOR', 'DOCTOR_CATEGORY', 'VARIANT', 'VARIANT_FLASHSALE', 'VARIANT_NO_FLASHSALE', 'VARIANT_BRAND', 'VARIANT_CATEGORY', 'PROCEDURE', 'PROCEDURE_FLASHSALE', 'PROCEDURE_NO_FLASHSALE', 'PROCEDURE_PARTNER', 'PROCEDURE_CATEGORY', 'SHIPPING_TYPE', 'SHIPPING_PROVIDER']),
  items: jsonSchema,
  settingGroupId: z.string().nullish(),
  groupId: z.number().int(),
})

export interface CompleteDiscountSetting extends z.infer<typeof DiscountSettingModel> {
  group: CompleteCouponGroup
}

/**
 * RelatedDiscountSettingModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDiscountSettingModel: z.ZodSchema<CompleteDiscountSetting> = z.lazy(() => DiscountSettingModel.extend({
  group: RelatedCouponGroupModel,
}))
