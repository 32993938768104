import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CouponCountOrderByAggregateInputObjectSchema } from './CouponCountOrderByAggregateInput.schema'
import { CouponAvgOrderByAggregateInputObjectSchema } from './CouponAvgOrderByAggregateInput.schema'
import { CouponMaxOrderByAggregateInputObjectSchema } from './CouponMaxOrderByAggregateInput.schema'
import { CouponMinOrderByAggregateInputObjectSchema } from './CouponMinOrderByAggregateInput.schema'
import { CouponSumOrderByAggregateInputObjectSchema } from './CouponSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    discount: z.lazy(() => SortOrderSchema).optional(),
    discountType: z.lazy(() => SortOrderSchema).optional(),
    isPublic: z.lazy(() => SortOrderSchema).optional(),
    isOnlyNewPatient: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    usedAt: z.lazy(() => SortOrderSchema).optional(),
    groupId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CouponCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => CouponAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => CouponMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => CouponMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => CouponSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const CouponOrderByWithAggregationInputObjectSchema = Schema
