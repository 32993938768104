import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DeliveryCountOrderByAggregateInputObjectSchema } from './DeliveryCountOrderByAggregateInput.schema'
import { DeliveryAvgOrderByAggregateInputObjectSchema } from './DeliveryAvgOrderByAggregateInput.schema'
import { DeliveryMaxOrderByAggregateInputObjectSchema } from './DeliveryMaxOrderByAggregateInput.schema'
import { DeliveryMinOrderByAggregateInputObjectSchema } from './DeliveryMinOrderByAggregateInput.schema'
import { DeliverySumOrderByAggregateInputObjectSchema } from './DeliverySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    shippingProvider: z.lazy(() => SortOrderSchema).optional(),
    trackingNumber: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    deliveredAt: z.lazy(() => SortOrderSchema).optional(),
    partnerName: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => DeliveryCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => DeliveryAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => DeliveryMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => DeliveryMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => DeliverySumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const DeliveryOrderByWithAggregationInputObjectSchema = Schema
