import { z } from 'zod'
import { VariantCreateWithoutPicturesInputObjectSchema } from './VariantCreateWithoutPicturesInput.schema'
import { VariantUncheckedCreateWithoutPicturesInputObjectSchema } from './VariantUncheckedCreateWithoutPicturesInput.schema'
import { VariantCreateOrConnectWithoutPicturesInputObjectSchema } from './VariantCreateOrConnectWithoutPicturesInput.schema'
import { VariantUpsertWithoutPicturesInputObjectSchema } from './VariantUpsertWithoutPicturesInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantUpdateWithoutPicturesInputObjectSchema } from './VariantUpdateWithoutPicturesInput.schema'
import { VariantUncheckedUpdateWithoutPicturesInputObjectSchema } from './VariantUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantCreateWithoutPicturesInputObjectSchema),
          z.lazy(() => VariantUncheckedCreateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => VariantCreateOrConnectWithoutPicturesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => VariantUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => VariantUpdateWithoutPicturesInputObjectSchema),
          z.lazy(() => VariantUncheckedUpdateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const VariantUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
