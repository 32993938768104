import { z } from 'zod'
import { ProcedureReviewCountOutputTypeSelectObjectSchema } from './ProcedureReviewCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => ProcedureReviewCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureReviewCountOutputTypeArgsObjectSchema = Schema
