import { z } from 'zod'
import { OrderCreateWithoutPaymentsInputObjectSchema } from './OrderCreateWithoutPaymentsInput.schema'
import { OrderUncheckedCreateWithoutPaymentsInputObjectSchema } from './OrderUncheckedCreateWithoutPaymentsInput.schema'
import { OrderCreateOrConnectWithoutPaymentsInputObjectSchema } from './OrderCreateOrConnectWithoutPaymentsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutPaymentsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutPaymentsInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutPaymentsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutPaymentsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutPaymentsInputObjectSchema = Schema
