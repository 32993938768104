import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BrandBannerOrderByRelationAggregateInputObjectSchema } from './BrandBannerOrderByRelationAggregateInput.schema'
import { ProductOrderByRelationAggregateInputObjectSchema } from './ProductOrderByRelationAggregateInput.schema'
import { ProductReviewOrderByRelationAggregateInputObjectSchema } from './ProductReviewOrderByRelationAggregateInput.schema'
import { ProductReviewSummaryOrderByRelationAggregateInputObjectSchema } from './ProductReviewSummaryOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    isShow: z.lazy(() => SortOrderSchema).optional(),
    coporateName: z.lazy(() => SortOrderSchema).optional(),
    countryName: z.lazy(() => SortOrderSchema).optional(),
    logo: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    banners: z
      .lazy(() => BrandBannerOrderByRelationAggregateInputObjectSchema)
      .optional(),
    products: z
      .lazy(() => ProductOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productReviews: z
      .lazy(() => ProductReviewOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productReviewSummaries: z
      .lazy(() => ProductReviewSummaryOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const BrandOrderByWithRelationInputObjectSchema = Schema
