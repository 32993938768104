import { z } from 'zod'

export const ProductVideoScalarFieldEnumSchema = z.enum([
  'id',
  'url',
  'thumbnail',
  'productId',
  'rank',
  'createdAt',
  'updatedAt',
])
