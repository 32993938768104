import { z } from 'zod'

export const ArticleScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'coverPicture',
  'titleTr',
  'detailTr',
])
