import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CaseCountOrderByAggregateInputObjectSchema } from './CaseCountOrderByAggregateInput.schema'
import { CaseAvgOrderByAggregateInputObjectSchema } from './CaseAvgOrderByAggregateInput.schema'
import { CaseMaxOrderByAggregateInputObjectSchema } from './CaseMaxOrderByAggregateInput.schema'
import { CaseMinOrderByAggregateInputObjectSchema } from './CaseMinOrderByAggregateInput.schema'
import { CaseSumOrderByAggregateInputObjectSchema } from './CaseSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    symptom: z.lazy(() => SortOrderSchema).optional(),
    drugAllergy: z.lazy(() => SortOrderSchema).optional(),
    congenitalDisease: z.lazy(() => SortOrderSchema).optional(),
    symptomDuration: z.lazy(() => SortOrderSchema).optional(),
    symptomDurationUnit: z.lazy(() => SortOrderSchema).optional(),
    followupCaseId: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    consultingChannel: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    v2RecordId: z.lazy(() => SortOrderSchema).optional(),
    _count: z.lazy(() => CaseCountOrderByAggregateInputObjectSchema).optional(),
    _avg: z.lazy(() => CaseAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => CaseMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => CaseMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => CaseSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const CaseOrderByWithAggregationInputObjectSchema = Schema
