import { z } from 'zod'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumAppointmentTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => AppointmentTypeSchema).optional(),
    })
    .strict()

export const EnumAppointmentTypeFieldUpdateOperationsInputObjectSchema = Schema
