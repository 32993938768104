import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutDeliveriesInputObjectSchema } from './OrderCreateWithoutDeliveriesInput.schema'
import { OrderUncheckedCreateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedCreateWithoutDeliveriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutDeliveriesInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutDeliveriesInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutDeliveriesInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutDeliveriesInputObjectSchema = Schema
