import { z } from 'zod'
import { ProcedureBannerWhereUniqueInputObjectSchema } from './objects/ProcedureBannerWhereUniqueInput.schema'
import { ProcedureBannerCreateInputObjectSchema } from './objects/ProcedureBannerCreateInput.schema'
import { ProcedureBannerUncheckedCreateInputObjectSchema } from './objects/ProcedureBannerUncheckedCreateInput.schema'
import { ProcedureBannerUpdateInputObjectSchema } from './objects/ProcedureBannerUpdateInput.schema'
import { ProcedureBannerUncheckedUpdateInputObjectSchema } from './objects/ProcedureBannerUncheckedUpdateInput.schema'

export const ProcedureBannerUpsertSchema = z.object({
  where: ProcedureBannerWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureBannerCreateInputObjectSchema,
    ProcedureBannerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureBannerUpdateInputObjectSchema,
    ProcedureBannerUncheckedUpdateInputObjectSchema,
  ]),
})
