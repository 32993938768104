import { z } from 'zod'
import { PatientUpdateWithoutKycDataInputObjectSchema } from './PatientUpdateWithoutKycDataInput.schema'
import { PatientUncheckedUpdateWithoutKycDataInputObjectSchema } from './PatientUncheckedUpdateWithoutKycDataInput.schema'
import { PatientCreateWithoutKycDataInputObjectSchema } from './PatientCreateWithoutKycDataInput.schema'
import { PatientUncheckedCreateWithoutKycDataInputObjectSchema } from './PatientUncheckedCreateWithoutKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutKycDataInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutKycDataInputObjectSchema),
      z.lazy(() => PatientUncheckedUpdateWithoutKycDataInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutKycDataInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutKycDataInputObjectSchema),
    ]),
  })
  .strict()

export const PatientUpsertWithoutKycDataInputObjectSchema = Schema
