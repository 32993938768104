import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'
import { RejectReasonFindManySchema } from '../findManyRejectReason.schema'
import { KycDataCountOutputTypeArgsObjectSchema } from './KycDataCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataInclude> = z
  .object({
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    rejectReason: z
      .union([z.boolean(), z.lazy(() => RejectReasonFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => KycDataCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const KycDataIncludeObjectSchema = Schema
