import { z } from 'zod'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureUpdateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUpdateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedUpdateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedUpdateWithoutProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(
          () => ProductReviewPictureUpdateWithoutProductReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProductReviewPictureUncheckedUpdateWithoutProductReviewInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInputObjectSchema =
  Schema
