import { z } from 'zod'
import { SegmentWhereUniqueInputObjectSchema } from './SegmentWhereUniqueInput.schema'
import { SegmentCreateWithoutNotificationsInputObjectSchema } from './SegmentCreateWithoutNotificationsInput.schema'
import { SegmentUncheckedCreateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedCreateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentCreateOrConnectWithoutNotificationsInput> =
  z
    .object({
      where: z.lazy(() => SegmentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema),
        z.lazy(
          () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SegmentCreateOrConnectWithoutNotificationsInputObjectSchema =
  Schema
