import * as z from "zod"

export const OrderNotificationModel = z.object({
  id: z.number().int(),
  orderId: z.number().int(),
  onesignalMessageId: z.string(),
  orderItemType: z.enum(['PRODUCT', 'PROCEDURE']),
  title: z.string(),
  createdAt: z.date(),
  sendAt: z.date(),
  procedureId: z.number().int().nullish(),
})
