import { z } from 'zod'
import { PatientUpdateWithoutCoinTransactionsInputObjectSchema } from './PatientUpdateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedUpdateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedUpdateWithoutCoinTransactionsInput.schema'
import { PatientCreateWithoutCoinTransactionsInputObjectSchema } from './PatientCreateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedCreateWithoutCoinTransactionsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutCoinTransactionsInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutCoinTransactionsInputObjectSchema),
      z.lazy(
        () => PatientUncheckedUpdateWithoutCoinTransactionsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutCoinTransactionsInputObjectSchema),
      z.lazy(
        () => PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const PatientUpsertWithoutCoinTransactionsInputObjectSchema = Schema
