import { z } from 'zod'
import { VariantCreateWithoutPicturesInputObjectSchema } from './VariantCreateWithoutPicturesInput.schema'
import { VariantUncheckedCreateWithoutPicturesInputObjectSchema } from './VariantUncheckedCreateWithoutPicturesInput.schema'
import { VariantCreateOrConnectWithoutPicturesInputObjectSchema } from './VariantCreateOrConnectWithoutPicturesInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateNestedOneWithoutPicturesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => VariantCreateWithoutPicturesInputObjectSchema),
        z.lazy(() => VariantUncheckedCreateWithoutPicturesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => VariantCreateOrConnectWithoutPicturesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const VariantCreateNestedOneWithoutPicturesInputObjectSchema = Schema
