import { z } from 'zod'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(
          () => SuggestedProcedureItemUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
