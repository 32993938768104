import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentUpdateWithoutPatientInputObjectSchema } from './AcceptedConsentUpdateWithoutPatientInput.schema'
import { AcceptedConsentUncheckedUpdateWithoutPatientInputObjectSchema } from './AcceptedConsentUncheckedUpdateWithoutPatientInput.schema'
import { AcceptedConsentCreateWithoutPatientInputObjectSchema } from './AcceptedConsentCreateWithoutPatientInput.schema'
import { AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema } from './AcceptedConsentUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpsertWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => AcceptedConsentUpdateWithoutPatientInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedUpdateWithoutPatientInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => AcceptedConsentCreateWithoutPatientInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentUpsertWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
