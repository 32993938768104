import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumShippingTypeFilterObjectSchema } from './NestedEnumShippingTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumShippingTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ShippingTypeSchema).optional(),
    in: z
      .lazy(() => ShippingTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ShippingTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ShippingTypeSchema),
        z.lazy(() => NestedEnumShippingTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumShippingTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumShippingTypeFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumShippingTypeWithAggregatesFilterObjectSchema = Schema
