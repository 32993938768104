import { z } from 'zod'
import { DeliveryCreateManyOrderInputObjectSchema } from './DeliveryCreateManyOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateManyOrderInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => DeliveryCreateManyOrderInputObjectSchema),
      z.lazy(() => DeliveryCreateManyOrderInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const DeliveryCreateManyOrderInputEnvelopeObjectSchema = Schema
