import { z } from 'zod'
import { ProductReviewPictureSelectObjectSchema } from './ProductReviewPictureSelect.schema'
import { ProductReviewPictureIncludeObjectSchema } from './ProductReviewPictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureArgs> = z
  .object({
    select: z.lazy(() => ProductReviewPictureSelectObjectSchema).optional(),
    include: z.lazy(() => ProductReviewPictureIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductReviewPictureArgsObjectSchema = Schema
