import { z } from 'zod'
import { ArticlePictureOrderByWithRelationInputObjectSchema } from './objects/ArticlePictureOrderByWithRelationInput.schema'
import { ArticlePictureWhereInputObjectSchema } from './objects/ArticlePictureWhereInput.schema'
import { ArticlePictureWhereUniqueInputObjectSchema } from './objects/ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureScalarFieldEnumSchema } from './enums/ArticlePictureScalarFieldEnum.schema'

export const ArticlePictureFindManySchema = z.object({
  orderBy: z
    .union([
      ArticlePictureOrderByWithRelationInputObjectSchema,
      ArticlePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ArticlePictureWhereInputObjectSchema.optional(),
  cursor: ArticlePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ArticlePictureScalarFieldEnumSchema).optional(),
})
