import { z } from 'zod'
import { IdentityScalarWhereInputObjectSchema } from './IdentityScalarWhereInput.schema'
import { IdentityUpdateManyMutationInputObjectSchema } from './IdentityUpdateManyMutationInput.schema'
import { IdentityUncheckedUpdateManyWithoutIdentitiesInputObjectSchema } from './IdentityUncheckedUpdateManyWithoutIdentitiesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityUpdateManyWithWhereWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => IdentityScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => IdentityUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => IdentityUncheckedUpdateManyWithoutIdentitiesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const IdentityUpdateManyWithWhereWithoutPatientInputObjectSchema = Schema
