import { Add as AddIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import {
  ConditionItemTypeSchema,
  ConditionScopeSchema,
} from '@ssch-backend/coupons'
import { ShippingTypeSchema } from '@ssch-backend/orders'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useEffect, useState } from 'react'
import {
  ArrayInput,
  Button,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  ReferenceArrayInput,
  SimpleFormIterator,
  useLocaleState,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormAutoCompleteArrayInput } from '../../../components/FormInput/input'
import { handleFilterFullnameQuery, toCamelCase } from '../../../utils'

const conditionSettingSx = {
  margin: '1.25rem 0',
}

export const getItemTypeReferenceKey = (
  itemTypeEnum: keyof typeof ConditionItemTypeSchema.enum,
) => {
  let result = ''
  switch (itemTypeEnum) {
    case 'VARIANT':
    case 'VARIANT_NO_FLASHSALE':
      result = 'productVariantSearch'
      break
    case 'VARIANT_BRAND':
    case 'VARIANT_CATEGORY':
      result = toCamelCase(itemTypeEnum.replace('VARIANT', 'product'))
      break
    case 'VARIANT_FLASHSALE':
      result = 'flashsaleItemProduct'
      break
    case 'PROCEDURE_NO_FLASHSALE':
      result = 'procedure'
      break
    case 'PROCEDURE_PARTNER':
      result = 'partner'
      break
    case 'PROCEDURE_FLASHSALE':
      result = 'flashsaleItemProcedure'
      break
    default: {
      result = toCamelCase(itemTypeEnum)
      break
    }
  }
  return result
}

export const optionTextConditionSettingItem = (params: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  itemTypeEnum: keyof typeof ConditionItemTypeSchema.enum
  locale: string
}) => {
  const { data, itemTypeEnum, locale } = params
  let result = ''
  switch (itemTypeEnum) {
    case 'DOCTOR':
      result = [
        `(ID: ${data.id})`,
        data.prefix,
        data.firstname,
        data.lastname,
      ].join(' ')
      break
    case 'VARIANT':
    case 'VARIANT_NO_FLASHSALE':
      result = [
        `(SKU: ${data.sku})`,
        data.productNameTr && getTr(data.productNameTr, locale as Language),
        data.descriptionTr && getTr(data.descriptionTr, locale as Language),
      ].join(' ')
      break
    case 'VARIANT_BRAND':
    case 'SHIPPING_PROVIDER':
      result = [`(ID: ${data.id})`, data.name].join(' ')
      break
    case 'DOCTOR_CATEGORY':
    case 'VARIANT_CATEGORY':
    case 'PROCEDURE_CATEGORY':
    case 'PROCEDURE':
    case 'PROCEDURE_NO_FLASHSALE':
    case 'PROCEDURE_PARTNER':
      result = [
        `(ID: ${data.id})`,
        data.nameTr && getTr(data.nameTr, locale as Language),
      ].join(' ')
      break
    case 'VARIANT_FLASHSALE':
      result = [
        `(SKU: ${data.variant.sku})`,
        data.variant.product.nameTr &&
          getTr(data.variant.product.nameTr, locale as Language),
        data.variant.descriptionTr &&
          getTr(data.variant.descriptionTr, locale as Language),
        `, Flashsale: (ID: ${data.flashsaleId})`,
        data.flashsale.name,
      ].join(' ')
      break
    case 'PROCEDURE_FLASHSALE':
      result = [
        `Procedure: (ID: ${data.procedure.id})`,
        data.procedure.nameTr &&
          getTr(data.procedure.nameTr, locale as Language),
        `, Flashsale: (ID: ${data.flashsaleId})`,
        data.flashsale.name,
      ].join(' ')
      break
    default:
      break
  }
  return result
}

const ReferenceArrayAutoCompleteItemInput: FC<
  Required<FormDataConsumerRenderParams> & { [key: string]: unknown }
> = ({ formData, scopedFormData, getSource, ...rest }) => {
  const [locale] = useLocaleState()
  const { setValue } = useFormContext()
  const [onlyAutocompleteArrayInput, setOnlyAutocompleteArrayInput] =
    useState<boolean>(false)

  useEffect(() => {
    setValue(getSource('items'), [])
    if (
      scopedFormData.itemType === ConditionItemTypeSchema.Enum.SHIPPING_TYPE
    ) {
      setOnlyAutocompleteArrayInput(true)
    } else {
      setOnlyAutocompleteArrayInput(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedFormData.itemType])

  useEffect(() => {
    if (scopedFormData.scope !== ConditionScopeSchema.enum.ALL) return
    setValue(getSource('items'), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedFormData.scope])

  const filterToQuery = (
    textSearch: string,
    itemTypeEnum: keyof typeof ConditionItemTypeSchema.enum,
  ) => {
    const filter: { [key: string]: unknown } = {}
    if (!textSearch) return { id: { in: [] } }
    switch (itemTypeEnum) {
      case 'DOCTOR':
        filter.OR = handleFilterFullnameQuery(textSearch).OR
        break
      case 'PROCEDURE':
      case 'PROCEDURE_NO_FLASHSALE':
      case 'PROCEDURE_PARTNER':
        filter.OR = [
          Number(textSearch) && { id: Number(textSearch) },
          { searchField: textSearch },
        ]
        break
      case 'DOCTOR_CATEGORY':
      case 'PROCEDURE_CATEGORY':
      case 'VARIANT_CATEGORY':
        filter.OR = [Number(textSearch) && { id: Number(textSearch) }]
        break
      case 'VARIANT':
      case 'VARIANT_NO_FLASHSALE':
        filter.textSearch = { contains: textSearch.trim() }
        break
      case 'VARIANT_BRAND':
        filter.OR = [
          Number(textSearch) && { id: Number(textSearch) },
          { name: textSearch },
          { coporateName: textSearch },
        ]
        break
      case 'VARIANT_FLASHSALE': {
        const number = Number(textSearch)
        filter.OR = [
          number && { id: number },
          number && { flashsaleId: number },
          number && { variantId: number },
          number && { variant: { size: number } },
          { flashsale: { name: { contains: textSearch } } },
          { variant: { sku: { contains: textSearch } } },
          { variant: { product: { searchField: { contains: textSearch } } } },
        ]
        filter.flashsale = {
          endAt: { gt: new Date() },
        }
        break
      }
      case 'PROCEDURE_FLASHSALE': {
        const number = Number(textSearch)
        filter.OR = [
          number && { id: number },
          number && { flashsaleId: number },
          number && { procedureId: number },
          { flashsale: { name: { contains: textSearch } } },
          { procedure: { searchField: { contains: textSearch } } },
        ]
        filter.flashsale = {
          endAt: { gt: new Date() },
        }
        break
      }
      default:
        filter.id = { in: [] }
        break
    }
    return filter
  }

  const filterList = (
    itemTypeEnum: keyof typeof ConditionItemTypeSchema.enum,
  ) => {
    switch (itemTypeEnum) {
      case 'VARIANT_FLASHSALE':
      case 'PROCEDURE_FLASHSALE':
        return {
          flashsale: {
            endAt: { gt: new Date() },
          },
        }
      default:
        return undefined
    }
  }

  return !onlyAutocompleteArrayInput ? (
    <ReferenceArrayInput
      source={getSource('items')}
      reference={getItemTypeReferenceKey(scopedFormData.itemType)}
      sort={{ field: 'id', order: 'ASC' }}
      filter={filterList(scopedFormData.itemType)}
      {...rest}
    >
      <FormAutoCompleteArrayInput
        fullWidth
        sx={{ ...conditionSettingSx, maxWidth: 'calc(50% - 2rem)' }}
        disabled={scopedFormData.scope === ConditionScopeSchema.Enum.ALL}
        optionText={(choice) =>
          optionTextConditionSettingItem({
            data: choice,
            itemTypeEnum: scopedFormData.itemType,
            locale,
          })
        }
        debounce={100}
        filterToQuery={(searchText) =>
          filterToQuery(searchText.trim(), scopedFormData.itemType)
        }
        helperText={false}
      />
    </ReferenceArrayInput>
  ) : (
    <FormAutoCompleteArrayInput
      source={getSource('items')}
      fullWidth
      disabled={scopedFormData.scope === ConditionScopeSchema.Enum.ALL}
      sx={{ ...conditionSettingSx, maxWidth: 'calc(50% - 2rem)' }}
      choices={Object.values(ShippingTypeSchema.Enum).map((option) => ({
        id: option,
        name: ['resources.couponGroup.title.shippingType', option].join('.'),
      }))}
      helperText={false}
    />
  )
}

export const ConditionSettingSection: FC<{
  hide?: boolean
}> = ({ hide = false }) => {
  const translate = useTranslate()

  return !hide ? (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.couponGroup.title.condition_setting')}
        </Typography>
      </Box>
      <FormDivider />
      <ArrayInput
        label=""
        source="conditionSetting"
        sx={{ marginBottom: '2rem' }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableReordering
          disableAdd={false}
          disableClear
          addButton={
            <Button
              variant="outlined"
              label="resources.couponGroup.action.condition_setting.add_button"
              startIcon={<AddIcon />}
              sx={{ padding: '0.5rem 1rem' }}
            />
          }
        >
          {/* <FormSelectInput
            source="itemType"
            validate={required()}
            choices={Object.values(ConditionItemTypeSchema.Enum).map(
              (option) => ({
                id: option,
                name: getCouponConditionItemTypeLabel(option)[
                  locale === 'th' ? 'th' : 'en'
                ],
              }),
            )}
            sx={{
              ...conditionSettingSx,
              width: '25%',
            }}
            helperText={false}
          />
          <SelectInput
            source="scope"
            validate={required()}
            InputLabelProps={{ shrink: true }}
            choices={Object.values(ConditionScopeSchema.Enum).map((option) => ({
              id: option,
              name: getCouponConditionScopeLabel(option)[
                locale === 'th' ? 'th' : 'en'
              ],
            }))}
            sx={{ ...conditionSettingSx, width: '25%' }}
            helperText={false}
          /> */}
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) =>
              scopedFormData && getSource && scopedFormData.itemType ? (
                <ReferenceArrayAutoCompleteItemInput
                  formData={formData}
                  scopedFormData={scopedFormData}
                  getSource={getSource}
                  rest
                />
              ) : null
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  ) : null
}
