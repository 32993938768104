import { z } from 'zod'
import { CarouselCreateWithoutItemsInputObjectSchema } from './CarouselCreateWithoutItemsInput.schema'
import { CarouselUncheckedCreateWithoutItemsInputObjectSchema } from './CarouselUncheckedCreateWithoutItemsInput.schema'
import { CarouselCreateOrConnectWithoutItemsInputObjectSchema } from './CarouselCreateOrConnectWithoutItemsInput.schema'
import { CarouselWhereUniqueInputObjectSchema } from './CarouselWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselCreateNestedOneWithoutItemsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CarouselCreateWithoutItemsInputObjectSchema),
        z.lazy(() => CarouselUncheckedCreateWithoutItemsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CarouselCreateOrConnectWithoutItemsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CarouselWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CarouselCreateNestedOneWithoutItemsInputObjectSchema = Schema
