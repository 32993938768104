import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SegmentCountOrderByAggregateInputObjectSchema } from './SegmentCountOrderByAggregateInput.schema'
import { SegmentAvgOrderByAggregateInputObjectSchema } from './SegmentAvgOrderByAggregateInput.schema'
import { SegmentMaxOrderByAggregateInputObjectSchema } from './SegmentMaxOrderByAggregateInput.schema'
import { SegmentMinOrderByAggregateInputObjectSchema } from './SegmentMinOrderByAggregateInput.schema'
import { SegmentSumOrderByAggregateInputObjectSchema } from './SegmentSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    segmentId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => SegmentCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => SegmentAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => SegmentMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => SegmentMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => SegmentSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const SegmentOrderByWithAggregationInputObjectSchema = Schema
