import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummarySumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    doctorFee: z.literal(true).optional(),
    doctorFeeDiscount: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    commissionPrice: z.literal(true).optional(),
    netDoctorFee: z.literal(true).optional(),
    followUpAppointmentId: z.literal(true).optional(),
    recommedNumberOfTreatment: z.literal(true).optional(),
    editedSuggestedProductItemBy: z.literal(true).optional(),
    v2CaseSummaryId: z.literal(true).optional(),
  })
  .strict()

export const CaseSummarySumAggregateInputObjectSchema = Schema
