import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { AutocompleteInput, ReferenceInput, useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomSelectInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      phone: undefined,
      specialtyId: undefined,
      mainHospitalId: undefined,
      active: undefined,
      registerStatus: undefined,
      name: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.registerStatus
    delete newFilter.name

    if (values.registerStatus) {
      newFilter.registerStatus_equals = values.registerStatus
    }
    if (values.name) {
      newFilter.OR = [{ firstname: values.name }, { lastname: values.name }]
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }
  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  const filterSpecialty = (searchText: string) => ({ nameEn: searchText })
  const filterHospital = (searchText: string) => ({ nameEn: searchText })

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          registerStatus_equals: {
            formKey: 'registerStatus',
          },
          OR: {
            formKey: 'name',
            valuePathForForm: '[0].firstname',
          },
          active: {
            formKey: 'active',
          },
          phone: { formKey: 'phone' },
          specialtyId: { formKey: 'specialtyId' },
          mainHospitalId: { formKey: 'mainHospitalId' },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="name"
            label="ชื่อ-สกุล"
          />
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="phone"
            label="เบอร์โทรศัพท์"
          />
          <ReferenceInput
            source="specialtyId"
            reference="doctorSpecialty"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText="nameTh"
              label="ความเชี่ยวชาญพิเศษ"
              helperText={false}
              filterToQuery={filterSpecialty}
              disableClearable
              sx={{ '& legend': { maxWidth: '100% !important' } }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="mainHospitalId"
            reference="doctorClinicHospital"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <AutocompleteInput
              label="คลินิก/โรงพยาบาลหลัก"
              optionText="name"
              helperText={false}
              filterToQuery={filterHospital}
              disableClearable
              sx={{ '& legend': { maxWidth: '100% !important' } }}
            />
          </ReferenceInput>
          <CustomSelectInput
            source="active"
            label="สถานะบัญชี"
            helperText={false}
            sx={{ mt: 0.5 }}
            choices={[
              { id: true, name: 'YES' },
              { id: false, name: 'NO' },
            ]}
          />
          <CustomSelectInput
            source="registerStatus"
            label="สถานะการสมัคร"
            helperText={false}
            sx={{ mt: 0.5 }}
            choices={[
              { id: 'SUBMITTED', name: 'SUBMITTED' },
              { id: 'APPROVED', name: 'APPROVED' },
              { id: 'REJECTED', name: 'REJECTED' },
            ]}
          />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
