import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCountOutputTypeSelect> = z
  .object({
    officeHours: z.boolean().optional(),
  })
  .strict()

export const BranchCountOutputTypeSelectObjectSchema = Schema
