import { z } from 'zod'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { OrderCreateNestedOneWithoutDoctorItemInputObjectSchema } from './OrderCreateNestedOneWithoutDoctorItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    caseId: z.number(),
    doctorFee: z.number(),
    doctorFeeDiscount: z.number(),
    consultingChannel: z.lazy(() => ConsultingChannelSchema),
    doctorName: z.string(),
    doctorId: z.number(),
    order: z.lazy(() => OrderCreateNestedOneWithoutDoctorItemInputObjectSchema),
  })
  .strict()

export const DoctorItemCreateInputObjectSchema = Schema
