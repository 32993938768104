import * as z from "zod"
import { CompleteProcedure, RelatedProcedureModel, CompleteCategory, RelatedCategoryModel } from "./index"

export const ProcedureCategoryModel = z.object({
  id: z.number().int(),
  procedureId: z.number().int(),
  categoryId: z.number().int(),
  priority: z.number().int(),
})

export interface CompleteProcedureCategory extends z.infer<typeof ProcedureCategoryModel> {
  procedure: CompleteProcedure
  category: CompleteCategory
}

/**
 * RelatedProcedureCategoryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureCategoryModel: z.ZodSchema<CompleteProcedureCategory> = z.lazy(() => ProcedureCategoryModel.extend({
  procedure: RelatedProcedureModel,
  category: RelatedCategoryModel,
}))
