import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumCoinTransactionStatusFilterObjectSchema } from './EnumCoinTransactionStatusFilter.schema'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'
import { EnumCoinTransactionTypeFilterObjectSchema } from './EnumCoinTransactionTypeFilter.schema'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CoinTransactionWhereInputObjectSchema),
        z.lazy(() => CoinTransactionWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CoinTransactionWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CoinTransactionWhereInputObjectSchema),
        z.lazy(() => CoinTransactionWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => EnumCoinTransactionStatusFilterObjectSchema),
        z.lazy(() => CoinTransactionStatusSchema),
      ])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumCoinTransactionTypeFilterObjectSchema),
        z.lazy(() => CoinTransactionTypeSchema),
      ])
      .optional(),
    amount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    note: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CoinTransactionWhereInputObjectSchema = Schema
