import { z } from 'zod'
import { NotificationPatientPatientIdNotificationIdCompoundUniqueInputObjectSchema } from './NotificationPatientPatientIdNotificationIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientWhereUniqueInput> = z
  .object({
    patientId_notificationId: z
      .lazy(
        () =>
          NotificationPatientPatientIdNotificationIdCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const NotificationPatientWhereUniqueInputObjectSchema = Schema
