import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DiagnosisOrderByWithRelationInputObjectSchema } from './DiagnosisOrderByWithRelationInput.schema'
import { CaseSummaryOrderByWithRelationInputObjectSchema } from './CaseSummaryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    diagnosisId: z.lazy(() => SortOrderSchema).optional(),
    caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
    diagnosis: z
      .lazy(() => DiagnosisOrderByWithRelationInputObjectSchema)
      .optional(),
    caseSummary: z
      .lazy(() => CaseSummaryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema = Schema
