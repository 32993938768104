import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalSumAggregateInputType> =
  z
    .object({
      id: z.literal(true).optional(),
      productVariantId: z.literal(true).optional(),
      amount: z.literal(true).optional(),
      useAmount: z.literal(true).optional(),
      useDuration: z.literal(true).optional(),
      refillTime: z.literal(true).optional(),
      caseSummaryId: z.literal(true).optional(),
    })
    .strict()

export const SuggestedProductItemOriginalSumAggregateInputObjectSchema = Schema
