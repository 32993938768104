import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutNotificationsInputObjectSchema } from './PatientCreateWithoutNotificationsInput.schema'
import { PatientUncheckedCreateWithoutNotificationsInputObjectSchema } from './PatientUncheckedCreateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutNotificationsInput> =
  z
    .object({
      where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PatientCreateWithoutNotificationsInputObjectSchema),
        z.lazy(
          () => PatientUncheckedCreateWithoutNotificationsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PatientCreateOrConnectWithoutNotificationsInputObjectSchema =
  Schema
