import { z } from 'zod'
import { CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelopeObjectSchema } from './CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelope.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUpdateManyWithWhereWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUpdateManyWithWhereWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisScalarWhereInputObjectSchema } from './CaseSummaryDiagnosisScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpdateManyWithoutDiagnosisNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () => CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpdateManyWithWhereWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpdateManyWithWhereWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisUpdateManyWithoutDiagnosisNestedInputObjectSchema =
  Schema
