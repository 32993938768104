import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutAppointmentInputObjectSchema } from './CaseCreateWithoutAppointmentInput.schema'
import { CaseUncheckedCreateWithoutAppointmentInputObjectSchema } from './CaseUncheckedCreateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutAppointmentInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutAppointmentInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutAppointmentInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutAppointmentInputObjectSchema = Schema
