import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCountOutputTypeSelect> = z
  .object({
    areas: z.boolean().optional(),
    orders: z.boolean().optional(),
  })
  .strict()

export const ShippingProviderCountOutputTypeSelectObjectSchema = Schema
