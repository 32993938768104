import { z } from 'zod'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'
import { NotificationCreateWithoutPatientsInputObjectSchema } from './NotificationCreateWithoutPatientsInput.schema'
import { NotificationUncheckedCreateWithoutPatientsInputObjectSchema } from './NotificationUncheckedCreateWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCreateOrConnectWithoutPatientsInput> =
  z
    .object({
      where: z.lazy(() => NotificationWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => NotificationCreateWithoutPatientsInputObjectSchema),
        z.lazy(
          () => NotificationUncheckedCreateWithoutPatientsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationCreateOrConnectWithoutPatientsInputObjectSchema =
  Schema
