import { z } from 'zod'
import { ProductPictureWhereInputObjectSchema } from './objects/ProductPictureWhereInput.schema'
import { ProductPictureOrderByWithAggregationInputObjectSchema } from './objects/ProductPictureOrderByWithAggregationInput.schema'
import { ProductPictureScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductPictureScalarWhereWithAggregatesInput.schema'
import { ProductPictureScalarFieldEnumSchema } from './enums/ProductPictureScalarFieldEnum.schema'

export const ProductPictureGroupBySchema = z.object({
  where: ProductPictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductPictureOrderByWithAggregationInputObjectSchema,
    ProductPictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductPictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductPictureScalarFieldEnumSchema),
})
