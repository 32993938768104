import { z } from 'zod'
import { TeleconsultCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateOrConnectWithoutMissedCallCaseInput.schema'
import { TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInput.schema'
import { TeleconsultCreateManyMissedCallCaseInputEnvelopeObjectSchema } from './TeleconsultCreateManyMissedCallCaseInputEnvelope.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultUpdateWithWhereUniqueWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUpdateWithWhereUniqueWithoutMissedCallCaseInput.schema'
import { TeleconsultUpdateManyWithWhereWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUpdateManyWithWhereWithoutMissedCallCaseInput.schema'
import { TeleconsultScalarWhereInputObjectSchema } from './TeleconsultScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUncheckedUpdateManyWithoutMissedCallCaseNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema),
          z
            .lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => TeleconsultCreateManyMissedCallCaseInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              TeleconsultUpdateWithWhereUniqueWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultUpdateWithWhereUniqueWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              TeleconsultUpdateManyWithWhereWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultUpdateManyWithWhereWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => TeleconsultScalarWhereInputObjectSchema),
          z.lazy(() => TeleconsultScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const TeleconsultUncheckedUpdateManyWithoutMissedCallCaseNestedInputObjectSchema =
  Schema
