import { z } from 'zod'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumExpireTypeFilterObjectSchema } from './NestedEnumExpireTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumExpireTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ExpireTypeSchema).optional(),
    in: z
      .lazy(() => ExpireTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ExpireTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ExpireTypeSchema),
        z.lazy(() => NestedEnumExpireTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumExpireTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumExpireTypeFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumExpireTypeWithAggregatesFilterObjectSchema = Schema
