import { z } from 'zod'
import { SkuCreateWithoutPartnerInputObjectSchema } from './SkuCreateWithoutPartnerInput.schema'
import { SkuUncheckedCreateWithoutPartnerInputObjectSchema } from './SkuUncheckedCreateWithoutPartnerInput.schema'
import { SkuCreateOrConnectWithoutPartnerInputObjectSchema } from './SkuCreateOrConnectWithoutPartnerInput.schema'
import { SkuUpsertWithWhereUniqueWithoutPartnerInputObjectSchema } from './SkuUpsertWithWhereUniqueWithoutPartnerInput.schema'
import { SkuCreateManyPartnerInputEnvelopeObjectSchema } from './SkuCreateManyPartnerInputEnvelope.schema'
import { SkuWhereUniqueInputObjectSchema } from './SkuWhereUniqueInput.schema'
import { SkuUpdateWithWhereUniqueWithoutPartnerInputObjectSchema } from './SkuUpdateWithWhereUniqueWithoutPartnerInput.schema'
import { SkuUpdateManyWithWhereWithoutPartnerInputObjectSchema } from './SkuUpdateManyWithWhereWithoutPartnerInput.schema'
import { SkuScalarWhereInputObjectSchema } from './SkuScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuUncheckedUpdateManyWithoutPartnerNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => SkuCreateWithoutPartnerInputObjectSchema),
          z.lazy(() => SkuCreateWithoutPartnerInputObjectSchema).array(),
          z.lazy(() => SkuUncheckedCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuUncheckedCreateWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => SkuCreateOrConnectWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuCreateOrConnectWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(() => SkuUpsertWithWhereUniqueWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuUpsertWithWhereUniqueWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => SkuCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => SkuWhereUniqueInputObjectSchema),
          z.lazy(() => SkuWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => SkuWhereUniqueInputObjectSchema),
          z.lazy(() => SkuWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => SkuWhereUniqueInputObjectSchema),
          z.lazy(() => SkuWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => SkuWhereUniqueInputObjectSchema),
          z.lazy(() => SkuWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(() => SkuUpdateWithWhereUniqueWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuUpdateWithWhereUniqueWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(() => SkuUpdateManyWithWhereWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuUpdateManyWithWhereWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => SkuScalarWhereInputObjectSchema),
          z.lazy(() => SkuScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const SkuUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema =
  Schema
