import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUpdateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedUpdateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedUpdateWithoutWarehouseInput.schema'
import { VariantWarehouseCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => VariantWarehouseUpdateWithoutWarehouseInputObjectSchema),
        z.lazy(
          () =>
            VariantWarehouseUncheckedUpdateWithoutWarehouseInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema),
        z.lazy(
          () =>
            VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInputObjectSchema =
  Schema
