import { z } from 'zod'
import { PatientCreateWithoutMyReviewsInputObjectSchema } from './PatientCreateWithoutMyReviewsInput.schema'
import { PatientUncheckedCreateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedCreateWithoutMyReviewsInput.schema'
import { PatientCreateOrConnectWithoutMyReviewsInputObjectSchema } from './PatientCreateOrConnectWithoutMyReviewsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateNestedOneWithoutMyReviewsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PatientCreateWithoutMyReviewsInputObjectSchema),
        z.lazy(() => PatientUncheckedCreateWithoutMyReviewsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PatientCreateOrConnectWithoutMyReviewsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PatientCreateNestedOneWithoutMyReviewsInputObjectSchema = Schema
