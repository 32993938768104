import { z } from 'zod'
import { ProcedureCreateNestedOneWithoutCategoriesInputObjectSchema } from './ProcedureCreateNestedOneWithoutCategoriesInput.schema'
import { CategoryCreateNestedOneWithoutProceduresInputObjectSchema } from './CategoryCreateNestedOneWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateInput> = z
  .object({
    priority: z.number().optional(),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutCategoriesInputObjectSchema,
    ),
    category: z.lazy(
      () => CategoryCreateNestedOneWithoutProceduresInputObjectSchema,
    ),
  })
  .strict()

export const ProcedureCategoryCreateInputObjectSchema = Schema
