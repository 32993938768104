import { z } from 'zod'
import { BrandBannerScalarWhereInputObjectSchema } from './BrandBannerScalarWhereInput.schema'
import { BrandBannerUpdateManyMutationInputObjectSchema } from './BrandBannerUpdateManyMutationInput.schema'
import { BrandBannerUncheckedUpdateManyWithoutBannersInputObjectSchema } from './BrandBannerUncheckedUpdateManyWithoutBannersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerUpdateManyWithWhereWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => BrandBannerScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => BrandBannerUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => BrandBannerUncheckedUpdateManyWithoutBannersInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const BrandBannerUpdateManyWithWhereWithoutBrandInputObjectSchema =
  Schema
