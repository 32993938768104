import { z } from 'zod'
import { BrandBannerUpdateInputObjectSchema } from './objects/BrandBannerUpdateInput.schema'
import { BrandBannerUncheckedUpdateInputObjectSchema } from './objects/BrandBannerUncheckedUpdateInput.schema'
import { BrandBannerWhereUniqueInputObjectSchema } from './objects/BrandBannerWhereUniqueInput.schema'

export const BrandBannerUpdateOneSchema = z.object({
  data: z.union([
    BrandBannerUpdateInputObjectSchema,
    BrandBannerUncheckedUpdateInputObjectSchema,
  ]),
  where: BrandBannerWhereUniqueInputObjectSchema,
})
