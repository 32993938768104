import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { ArticleRelationFilterObjectSchema } from './ArticleRelationFilter.schema'
import { ArticleWhereInputObjectSchema } from './ArticleWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ArticlePictureWhereInputObjectSchema),
        z.lazy(() => ArticlePictureWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ArticlePictureWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ArticlePictureWhereInputObjectSchema),
        z.lazy(() => ArticlePictureWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    articleId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    url: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    rank: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    article: z
      .union([
        z.lazy(() => ArticleRelationFilterObjectSchema),
        z.lazy(() => ArticleWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ArticlePictureWhereInputObjectSchema = Schema
