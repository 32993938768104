import { z } from 'zod'

export const OmsWarehouseModel = z.object({
  code: z.string(),
  storeCode: z.string(),
  marketplaceCode: z.string(),
  marketplaceWarehouseId: z.number(),
})
export type OmsWarehouseModel = z.infer<typeof OmsWarehouseModel>

export const OmsCategoryModel = z.object({
  code: z.string(),
  name: z.string(),
})
export type OmsCategoryModel = z.infer<typeof OmsCategoryModel>
// Create Order
export const OmsCustomerDetailModel = z.object({
  first_name: z.string(),
  last_name: z.string().optional(),
  customer_email: z.string(),
})

export const OmsCustomerAddresslModel = z.object({
  address_type: z.string(),
  first_name: z.string(),
  last_name: z.string().optional(),
  address_line1: z.string(),
  address_line2: z.string(),
  address_city: z.string(),
  address_state: z.string(),
  postcode: z.string(),
  country_code: z.string(),
  email: z.string().optional(),
  primary_phone: z.string(),
})

export const OmsCreateItemModel = z.object({
  line_item_id: z.string(),
  item_sku: z.string(),
  item_quantity: z.number(),
  item_price: z.number(),
  paid_price: z.number(),
  shipping_charges: z.number(),
  shipping_type: z.string(),
  shipping_method: z.string(),
  seller_discount: z.number(),
  tax_amount: z.string(),
  status: z.string(),
  channel_discount: z.number(),
  is_self_service: z.boolean(),
  warehouse_code: z.string(),
})

export const OmsCreateOrderModel = z.object({
  order: z.object({
    order_number: z.string(),
    store_code: z.string(),
    marketplace_code: z.string(),
    email: z.string(),
    payment_method: z.string(),
    cod: z.boolean(),
    currency_code: z.string(),
    delivery_info: z.string(),
    order_date: z.string(),
    items_total: z.number(),
    order_total: z.number(),
    payment_total: z.number(),
    tax_amount: z.number(),
    order_status: z.string(),
    customer_details: OmsCustomerDetailModel,
    order_items: OmsCreateItemModel.array(),
    customer_addresses: OmsCustomerAddresslModel.array(),
    is_historical_order: z.boolean(),
    is_mp_unpaid: z.boolean(),
    is_fbl_order: z.boolean(),
    isReturn: z.boolean(),
    isExchange: z.boolean(),
    is_duplicate: z.boolean(),
    is_custom_exchange: z.boolean(),
  }),
})

export type OmsCreateOrderModel = z.infer<typeof OmsCreateOrderModel>

// Create Catalogue Product
export const OmsCatalogueImageModel = z.object({
  title: z.string(),
  url: z.string(),
})
export const OmsCatalogueStateModel = z.enum([
  'new',
  'draft',
  'ready_to_go',
  'under_review',
  'listed',
  'deleted',
  'delisted',
  'listing_inprocess',
  'rejected',
  'prohibited',
])
export type OmsCatalogueStateModel = z.infer<typeof OmsCatalogueStateModel>
export const OmsCreateCatalogueModel = z.object({
  product: z.object({
    sku: z.string(), // using ISKU
    name: z.string(), // `SKINX-{ISKU}`
    description: z.string(), // `{product.nameTr.th} # {variant.descriptionTr.th}`
    price: z.number(), // using variant.tagPrice if not set use variant.sellingPrice instead.
    selling_price: z.number().nullish(),
    allocated_stock: z.number().default(0),
    upc: z.string().nullish(),
    brand: z.string(),
    category: OmsCategoryModel,
    multiwarehouse_stock: z
      .object({
        marketplace_warehouse_id: z.number(),
        warehouse_stock: z.number(),
      })
      .array(),
    height: z.number().default(1).optional(),
    width: z.number().default(1).optional(),
    length: z.number().default(1).optional(),
    weight: z.number().default(1).optional(),
    store_code: z.string(),
    marketplace_code: z.string(), // using ISKU
    state: OmsCatalogueStateModel.default('listed'),
    images: OmsCatalogueImageModel.array(),
  }),
})

export type OmsCreateCatalogueModel = z.infer<typeof OmsCreateCatalogueModel>

export const OmsUpdateCatalogueModel = z.object({
  product: z.object({
    name: z.string(), // `SKINX-{ISKU}`
    description: z.string(), // `{product.nameTr.th} # {variant.descriptionTr.th}`
    price: z.number(), // using variant.tagPrice if not set use variant.sellingPrice instead.
    selling_price: z.number().nullish(),
    allocated_stock: z.number(), // using variant.tagPrice that update from oms hook
    brand: z.string(),
    category: OmsCategoryModel,
  }),
})

export type OmsUpdateCatalogueModel = z.infer<typeof OmsUpdateCatalogueModel>

export const OmsStorageTypeModel = z.enum([
  `standard`,
  `temperature_controlled`,
  `deep_freeze`,
  `STANDARD`,
  `flammable_goods`,
  `secure_storage`,
  `dangerous_goods`,
  `b2b`,
  `b2c`,
])
export type OmsStorageTypeModel = z.infer<typeof OmsStorageTypeModel>

export const OmsCreateInventoryProductModel = z.object({
  inventory_product: z.object({
    inventory_sku: z.string(),
    sellable: z.number().default(0),
    total_stock: z.number().default(0),
    low_inventory_threshold: z.number().default(0),
    name: z.string(), // `{product.nameTr.th} # {variant.descriptionTr.th}`
    cost_price: z.number(),
    height: z.number().default(1).optional(),
    width: z.number().default(1).optional(),
    length: z.number().default(1).optional(),
    weight: z.number().default(1).optional(),
    sr_number_required: z.boolean().default(false),
    upc: z.string().default(`9999999`).optional(),
    storage_type: OmsStorageTypeModel.default('standard'),
    active: z.boolean(),
  }),
})

export type OmsCreateInventoryProductModel = z.infer<
  typeof OmsCreateInventoryProductModel
>

export const OmsUpdateInventoryProductModel = z.object({
  inventory_product: z.object({
    inventory_sku: z.string(),
    name: z.string(), // `{product.nameTr.th} # {variant.descriptionTr.th}`
    cost_price: z.number(),
    active: z.boolean(),
  }),
})

export type OmsUpdateInventoryProductModel = z.infer<
  typeof OmsUpdateInventoryProductModel
>

export const OmsCreateInventoryProductStockLocationModel = z.object({
  stock_location: z.object({
    quantity: z.number(),
    location_id: z.number(),
    is_usable: z.boolean(),
  }),
})

export type OmsCreateInventoryProductStockLocationModel = z.infer<
  typeof OmsCreateInventoryProductStockLocationModel
>
