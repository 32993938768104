import { z } from 'zod'
import { SegmentWhereInputObjectSchema } from './objects/SegmentWhereInput.schema'
import { SegmentOrderByWithAggregationInputObjectSchema } from './objects/SegmentOrderByWithAggregationInput.schema'
import { SegmentScalarWhereWithAggregatesInputObjectSchema } from './objects/SegmentScalarWhereWithAggregatesInput.schema'
import { SegmentScalarFieldEnumSchema } from './enums/SegmentScalarFieldEnum.schema'

export const SegmentGroupBySchema = z.object({
  where: SegmentWhereInputObjectSchema.optional(),
  orderBy: z.union([
    SegmentOrderByWithAggregationInputObjectSchema,
    SegmentOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: SegmentScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(SegmentScalarFieldEnumSchema),
})
