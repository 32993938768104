import * as z from "zod"
import { CompleteProcedure, RelatedProcedureModel } from "./index"

export const ProcedureReviewSummaryModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  procedureId: z.number().int(),
  partnerId: z.number().int(),
  overallRating: z.number(),
  environmentRating: z.number(),
  serviceRating: z.number(),
  specialtyRating: z.number(),
  valueRating: z.number(),
  countForRating5: z.number().int(),
  countForRating4: z.number().int(),
  countForRating3: z.number().int(),
  countForRating2: z.number().int(),
  countForRating1: z.number().int(),
})

export interface CompleteProcedureReviewSummary extends z.infer<typeof ProcedureReviewSummaryModel> {
  procedure: CompleteProcedure
}

/**
 * RelatedProcedureReviewSummaryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureReviewSummaryModel: z.ZodSchema<CompleteProcedureReviewSummary> = z.lazy(() => ProcedureReviewSummaryModel.extend({
  procedure: RelatedProcedureModel,
}))
