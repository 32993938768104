import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    doctorVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    patientVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    callTime: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    missedCallCaseId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const TeleconsultSumOrderByAggregateInputObjectSchema = Schema
