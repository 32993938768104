import { z } from 'zod'
import { ReceiptUpdateWithoutOrderInputObjectSchema } from './ReceiptUpdateWithoutOrderInput.schema'
import { ReceiptUncheckedUpdateWithoutOrderInputObjectSchema } from './ReceiptUncheckedUpdateWithoutOrderInput.schema'
import { ReceiptCreateWithoutOrderInputObjectSchema } from './ReceiptCreateWithoutOrderInput.schema'
import { ReceiptUncheckedCreateWithoutOrderInputObjectSchema } from './ReceiptUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptUpsertWithoutOrderInput> = z
  .object({
    update: z.union([
      z.lazy(() => ReceiptUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => ReceiptUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ReceiptCreateWithoutOrderInputObjectSchema),
      z.lazy(() => ReceiptUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const ReceiptUpsertWithoutOrderInputObjectSchema = Schema
