import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumWeekdayFilterObjectSchema } from './EnumWeekdayFilter.schema'
import { WeekdaySchema } from '../enums/Weekday.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => OfficeHourScalarWhereInputObjectSchema),
        z.lazy(() => OfficeHourScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => OfficeHourScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => OfficeHourScalarWhereInputObjectSchema),
        z.lazy(() => OfficeHourScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    weekday: z
      .union([
        z.lazy(() => EnumWeekdayFilterObjectSchema),
        z.lazy(() => WeekdaySchema),
      ])
      .optional(),
    openAt: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    closeAt: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    timezone: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    branchId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const OfficeHourScalarWhereInputObjectSchema = Schema
