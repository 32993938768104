import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
