import { z } from 'zod'
import { VariantSelectObjectSchema } from './VariantSelect.schema'
import { VariantIncludeObjectSchema } from './VariantInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantArgs> = z
  .object({
    select: z.lazy(() => VariantSelectObjectSchema).optional(),
    include: z.lazy(() => VariantIncludeObjectSchema).optional(),
  })
  .strict()

export const VariantArgsObjectSchema = Schema
