import { z } from 'zod'
import { CarouselItemCreateWithoutProcedureInputObjectSchema } from './CarouselItemCreateWithoutProcedureInput.schema'
import { CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema } from './CarouselItemUncheckedCreateWithoutProcedureInput.schema'
import { CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema } from './CarouselItemCreateOrConnectWithoutProcedureInput.schema'
import { CarouselItemCreateManyProcedureInputEnvelopeObjectSchema } from './CarouselItemCreateManyProcedureInputEnvelope.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUncheckedCreateNestedManyWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CarouselItemCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => CarouselItemCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () => CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CarouselItemCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CarouselItemUncheckedCreateNestedManyWithoutProcedureInputObjectSchema =
  Schema
