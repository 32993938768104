import { z } from 'zod'
import { ProductReviewCreateInputObjectSchema } from './objects/ProductReviewCreateInput.schema'
import { ProductReviewUncheckedCreateInputObjectSchema } from './objects/ProductReviewUncheckedCreateInput.schema'

export const ProductReviewCreateOneSchema = z.object({
  data: z.union([
    ProductReviewCreateInputObjectSchema,
    ProductReviewUncheckedCreateInputObjectSchema,
  ]),
})
