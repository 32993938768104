import { z } from 'zod'
import { ProductReviewPictureCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateOrConnectWithoutProductReviewInput.schema'
import { ProductReviewPictureCreateManyProductReviewInputEnvelopeObjectSchema } from './ProductReviewPictureCreateManyProductReviewInputEnvelope.schema'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './ProductReviewPictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureUncheckedCreateNestedManyWithoutProductReviewInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            ProductReviewPictureCreateManyProductReviewInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewPictureUncheckedCreateNestedManyWithoutProductReviewInputObjectSchema =
  Schema
