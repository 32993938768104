import { z } from 'zod'
import { UserCreateManyPartnerInputObjectSchema } from './UserCreateManyPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserCreateManyPartnerInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => UserCreateManyPartnerInputObjectSchema),
      z.lazy(() => UserCreateManyPartnerInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const UserCreateManyPartnerInputEnvelopeObjectSchema = Schema
