import { Role } from '@prisma/client/admins'
import { FC } from 'react'
import { required, usePermissions } from 'react-admin'
import { CustomSelectInput } from '../../../components/CustomInput'

type RoleOption = {
  id: Role
  name: Role
}

export const RoleSelectInput: FC = () => {
  const { isLoading, permissions } = usePermissions()

  if (isLoading) {
    return null
  }

  const allRoles: RoleOption[] = (Object.keys(Role) as (keyof typeof Role)[])
    .filter(
      (roleKey) =>
        roleKey !== 'SUPER_ADMIN' &&
        roleKey !== 'ADMIN' &&
        roleKey !== 'LOGISTIC',
    )
    .map((roleKey) => ({ id: Role[roleKey], name: Role[roleKey] }))

  const adminRoles: RoleOption[] =
    permissions === Role.SUPER_ADMIN
      ? [{ id: Role.ADMIN, name: Role.ADMIN }]
      : []

  const choices: RoleOption[] = adminRoles.concat(allRoles)

  return (
    <CustomSelectInput source="role" validate={required()} choices={choices} />
  )
}
