import { z } from 'zod'
import { ProcedureOrderOrderByWithRelationInputObjectSchema } from './objects/ProcedureOrderOrderByWithRelationInput.schema'
import { ProcedureOrderWhereInputObjectSchema } from './objects/ProcedureOrderWhereInput.schema'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './objects/ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderCountAggregateInputObjectSchema } from './objects/ProcedureOrderCountAggregateInput.schema'
import { ProcedureOrderMinAggregateInputObjectSchema } from './objects/ProcedureOrderMinAggregateInput.schema'
import { ProcedureOrderMaxAggregateInputObjectSchema } from './objects/ProcedureOrderMaxAggregateInput.schema'
import { ProcedureOrderAvgAggregateInputObjectSchema } from './objects/ProcedureOrderAvgAggregateInput.schema'
import { ProcedureOrderSumAggregateInputObjectSchema } from './objects/ProcedureOrderSumAggregateInput.schema'

export const ProcedureOrderAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureOrderOrderByWithRelationInputObjectSchema,
      ProcedureOrderOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureOrderWhereInputObjectSchema.optional(),
  cursor: ProcedureOrderWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureOrderCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureOrderMinAggregateInputObjectSchema.optional(),
  _max: ProcedureOrderMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureOrderAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureOrderSumAggregateInputObjectSchema.optional(),
})
