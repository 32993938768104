import { z } from 'zod'
import { CarouselItemFindManySchema } from '../findManyCarouselItem.schema'
import { CarouselCountOutputTypeArgsObjectSchema } from './CarouselCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    createdBy: z.boolean().optional(),
    updatedBy: z.boolean().optional(),
    active: z.boolean().optional(),
    priority: z.boolean().optional(),
    startAt: z.boolean().optional(),
    endAt: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    searchField: z.boolean().optional(),
    items: z
      .union([z.boolean(), z.lazy(() => CarouselItemFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CarouselCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CarouselSelectObjectSchema = Schema
