import { z } from 'zod'
import { WeekdaySchema } from '../enums/Weekday.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumWeekdayFilterObjectSchema } from './NestedEnumWeekdayFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.NestedEnumWeekdayWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => WeekdaySchema).optional(),
    in: z
      .lazy(() => WeekdaySchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => WeekdaySchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => WeekdaySchema),
        z.lazy(() => NestedEnumWeekdayWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumWeekdayFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumWeekdayFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumWeekdayWithAggregatesFilterObjectSchema = Schema
