import { z } from 'zod'
import { ProductReviewPictureWhereInputObjectSchema } from './objects/ProductReviewPictureWhereInput.schema'
import { ProductReviewPictureOrderByWithAggregationInputObjectSchema } from './objects/ProductReviewPictureOrderByWithAggregationInput.schema'
import { ProductReviewPictureScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductReviewPictureScalarWhereWithAggregatesInput.schema'
import { ProductReviewPictureScalarFieldEnumSchema } from './enums/ProductReviewPictureScalarFieldEnum.schema'

export const ProductReviewPictureGroupBySchema = z.object({
  where: ProductReviewPictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductReviewPictureOrderByWithAggregationInputObjectSchema,
    ProductReviewPictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProductReviewPictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductReviewPictureScalarFieldEnumSchema),
})
