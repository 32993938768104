import { z } from 'zod'
import { ProcedureCategoryCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutCategoryInput.schema'
import { ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateOrConnectWithoutCategoryInput.schema'
import { ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema } from './ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInput.schema'
import { ProcedureCategoryCreateManyCategoryInputEnvelopeObjectSchema } from './ProcedureCategoryCreateManyCategoryInputEnvelope.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema } from './ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInput.schema'
import { ProcedureCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema } from './ProcedureCategoryUpdateManyWithWhereWithoutCategoryInput.schema'
import { ProcedureCategoryScalarWhereInputObjectSchema } from './ProcedureCategoryScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateManyWithoutCategoryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema),
          z
            .lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedureCategoryCreateManyCategoryInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedureCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedureCategoryScalarWhereInputObjectSchema),
          z.lazy(() => ProcedureCategoryScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureCategoryUpdateManyWithoutCategoryNestedInputObjectSchema =
  Schema
