import { z } from 'zod'
import { BrandUpdateWithoutProductReviewSummariesInputObjectSchema } from './BrandUpdateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedUpdateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedUpdateWithoutProductReviewSummariesInput.schema'
import { BrandCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewSummariesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpsertWithoutProductReviewSummariesInput> =
  z
    .object({
      update: z.union([
        z.lazy(() => BrandUpdateWithoutProductReviewSummariesInputObjectSchema),
        z.lazy(
          () =>
            BrandUncheckedUpdateWithoutProductReviewSummariesInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => BrandCreateWithoutProductReviewSummariesInputObjectSchema),
        z.lazy(
          () =>
            BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const BrandUpsertWithoutProductReviewSummariesInputObjectSchema = Schema
