import { z } from 'zod'
import { ConsentCountOutputTypeSelectObjectSchema } from './ConsentCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => ConsentCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const ConsentCountOutputTypeArgsObjectSchema = Schema
