import { Box } from '@mui/material'
import { FC } from 'react'
import {
  AutocompleteInput,
  FilterPayload,
  RadioButtonGroupInput,
  ReferenceInput,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomTextInput } from '../../../components/CustomInput'

export interface FormReferenceInputProps {
  source: string
  reference: string
  optionText: string
  label: string
  filter?: FilterPayload
}

export const FormReferenceInput: FC<FormReferenceInputProps> = ({
  source,
  reference,
  optionText,
  label,
  filter,
}) => {
  const { getValues } = useFormContext()

  const isValid = (fieldKey: string): boolean => {
    let isValid = true
    const { ...data } = getValues()
    if (data?.validator) {
      isValid = data?.validator[fieldKey] !== '0'
    }

    return isValid
  }

  const filterToQuery = (searchText: string) => {
    const filterObject = {} as { [key: string]: string }
    filterObject[optionText] = searchText
    return filterObject
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <ReferenceInput
            source={source}
            reference={reference}
            sort={{ field: 'id', order: 'ASC' }}
            filter={filter}
          >
            <AutocompleteInput
              fullWidth
              optionText={optionText}
              label={label}
              filterToQuery={filterToQuery}
              disableClearable
              sx={{ '& legend': { maxWidth: '100% !important', mb: 1 } }}
            />
          </ReferenceInput>
        </Box>
        {!isValid(source) && (
          <CustomTextInput
            sx={{
              '& label, input': { color: 'red' },
              '& fieldset': {
                borderColor: 'red !important',
              },
              mb: 1,
            }}
            fullWidth
            source={`errorReason.${source}`}
            label={`สาเหตุ`}
            placeholder={`สาเหตุที่ข้อมูล ${label} ผิด`}
            color="error"
          />
        )}
      </Box>
      <Box>
        <RadioButtonGroupInput
          label={false}
          sx={{ mt: -0.1 }}
          source={`validator.${source}`}
          defaultValue="1"
          choices={[
            { id: '1', name: 'ถูกต้อง' },
            { id: '0', name: 'ไม่ถูกต้อง' },
          ]}
        />
      </Box>
    </Box>
  )
}
