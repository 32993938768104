import { z } from 'zod'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponUpdateWithoutOrderInputObjectSchema } from './CouponUpdateWithoutOrderInput.schema'
import { CouponUncheckedUpdateWithoutOrderInputObjectSchema } from './CouponUncheckedUpdateWithoutOrderInput.schema'
import { CouponCreateWithoutOrderInputObjectSchema } from './CouponCreateWithoutOrderInput.schema'
import { CouponUncheckedCreateWithoutOrderInputObjectSchema } from './CouponUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponUpsertWithWhereUniqueWithoutOrderInput> = z
  .object({
    where: z.lazy(() => CouponWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => CouponUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => CouponUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CouponCreateWithoutOrderInputObjectSchema),
      z.lazy(() => CouponUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const CouponUpsertWithWhereUniqueWithoutOrderInputObjectSchema = Schema
