import { z } from 'zod'

export const CouponScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'code',
  'type',
  'titleTr',
  'descriptionTr',
  'discount',
  'discountType',
  'isPublic',
  'isOnlyNewPatient',
  'startAt',
  'expireAt',
  'orderId',
  'patientId',
  'usedAt',
  'groupId',
])
