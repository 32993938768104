import { z } from 'zod'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumAuxiliaryLabelNullableFilter> = z
  .object({
    equals: z
      .lazy(() => AuxiliaryLabelSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => AuxiliaryLabelSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => AuxiliaryLabelSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => AuxiliaryLabelSchema),
        z.lazy(() => NestedEnumAuxiliaryLabelNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const NestedEnumAuxiliaryLabelNullableFilterObjectSchema = Schema
