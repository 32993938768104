import { z } from 'zod'
import { SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema } from './objects/SuggestedProductItemOriginalOrderByWithRelationInput.schema'
import { SuggestedProductItemOriginalWhereInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereInput.schema'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalScalarFieldEnumSchema } from './enums/SuggestedProductItemOriginalScalarFieldEnum.schema'

export const SuggestedProductItemOriginalFindManySchema = z.object({
  orderBy: z
    .union([
      SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema,
      SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProductItemOriginalWhereInputObjectSchema.optional(),
  cursor: SuggestedProductItemOriginalWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z
    .array(SuggestedProductItemOriginalScalarFieldEnumSchema)
    .optional(),
})
