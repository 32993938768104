import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseCreateWithoutVariantInputObjectSchema } from './VariantWarehouseCreateWithoutVariantInput.schema'
import { VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateOrConnectWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => VariantWarehouseCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseCreateOrConnectWithoutVariantInputObjectSchema =
  Schema
