import { z } from 'zod'
import { ProcedureReviewPictureWhereInputObjectSchema } from './ProcedureReviewPictureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureListRelationFilter> = z
  .object({
    every: z
      .lazy(() => ProcedureReviewPictureWhereInputObjectSchema)
      .optional(),
    some: z.lazy(() => ProcedureReviewPictureWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProcedureReviewPictureWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewPictureListRelationFilterObjectSchema = Schema
