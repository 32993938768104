import { z } from 'zod'
import { IdentityTypeSchema } from '../enums/IdentityType.schema'
import { NestedEnumIdentityTypeWithAggregatesFilterObjectSchema } from './NestedEnumIdentityTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumIdentityTypeFilterObjectSchema } from './NestedEnumIdentityTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumIdentityTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => IdentityTypeSchema).optional(),
    in: z
      .lazy(() => IdentityTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => IdentityTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => IdentityTypeSchema),
        z.lazy(() => NestedEnumIdentityTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumIdentityTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumIdentityTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumIdentityTypeWithAggregatesFilterObjectSchema = Schema
