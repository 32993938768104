import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumProductItemTypeFilterObjectSchema } from './EnumProductItemTypeFilter.schema'
import { ProductItemTypeSchema } from '../enums/ProductItemType.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => OrderNotificationWhereInputObjectSchema),
        z.lazy(() => OrderNotificationWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => OrderNotificationWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => OrderNotificationWhereInputObjectSchema),
        z.lazy(() => OrderNotificationWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    onesignalMessageId: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    orderItemType: z
      .union([
        z.lazy(() => EnumProductItemTypeFilterObjectSchema),
        z.lazy(() => ProductItemTypeSchema),
      ])
      .optional(),
    title: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    sendAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
  })
  .strict()

export const OrderNotificationWhereInputObjectSchema = Schema
