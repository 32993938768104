import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureInclude> = z
  .object({
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
  })
  .strict()

export const CasePictureIncludeObjectSchema = Schema
