import { z } from 'zod'
import { ProcedureBannerUpdateInputObjectSchema } from './objects/ProcedureBannerUpdateInput.schema'
import { ProcedureBannerUncheckedUpdateInputObjectSchema } from './objects/ProcedureBannerUncheckedUpdateInput.schema'
import { ProcedureBannerWhereUniqueInputObjectSchema } from './objects/ProcedureBannerWhereUniqueInput.schema'

export const ProcedureBannerUpdateOneSchema = z.object({
  data: z.union([
    ProcedureBannerUpdateInputObjectSchema,
    ProcedureBannerUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureBannerWhereUniqueInputObjectSchema,
})
