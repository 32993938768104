import { z } from 'zod'
import { VariantPictureWhereUniqueInputObjectSchema } from './objects/VariantPictureWhereUniqueInput.schema'
import { VariantPictureCreateInputObjectSchema } from './objects/VariantPictureCreateInput.schema'
import { VariantPictureUncheckedCreateInputObjectSchema } from './objects/VariantPictureUncheckedCreateInput.schema'
import { VariantPictureUpdateInputObjectSchema } from './objects/VariantPictureUpdateInput.schema'
import { VariantPictureUncheckedUpdateInputObjectSchema } from './objects/VariantPictureUncheckedUpdateInput.schema'

export const VariantPictureUpsertSchema = z.object({
  where: VariantPictureWhereUniqueInputObjectSchema,
  create: z.union([
    VariantPictureCreateInputObjectSchema,
    VariantPictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    VariantPictureUpdateInputObjectSchema,
    VariantPictureUncheckedUpdateInputObjectSchema,
  ]),
})
