import { z } from 'zod'
import { ReceiptCreateWithoutOrderInputObjectSchema } from './ReceiptCreateWithoutOrderInput.schema'
import { ReceiptUncheckedCreateWithoutOrderInputObjectSchema } from './ReceiptUncheckedCreateWithoutOrderInput.schema'
import { ReceiptCreateOrConnectWithoutOrderInputObjectSchema } from './ReceiptCreateOrConnectWithoutOrderInput.schema'
import { ReceiptUpsertWithoutOrderInputObjectSchema } from './ReceiptUpsertWithoutOrderInput.schema'
import { ReceiptWhereUniqueInputObjectSchema } from './ReceiptWhereUniqueInput.schema'
import { ReceiptUpdateWithoutOrderInputObjectSchema } from './ReceiptUpdateWithoutOrderInput.schema'
import { ReceiptUncheckedUpdateWithoutOrderInputObjectSchema } from './ReceiptUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptUpdateOneWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ReceiptCreateWithoutOrderInputObjectSchema),
        z.lazy(() => ReceiptUncheckedCreateWithoutOrderInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ReceiptCreateOrConnectWithoutOrderInputObjectSchema)
      .optional(),
    upsert: z.lazy(() => ReceiptUpsertWithoutOrderInputObjectSchema).optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => ReceiptWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => ReceiptUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => ReceiptUncheckedUpdateWithoutOrderInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ReceiptUpdateOneWithoutOrderNestedInputObjectSchema = Schema
