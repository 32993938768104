import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { format } from 'date-fns'
import { FC } from 'react'
import { required, useGetList, useLocaleState, useTranslate } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormSelectInput } from '../../../components/FormInput/input'

export const IconSection: FC = () => {
  const [locale] = useLocaleState()
  const translate = useTranslate()
  const { watch } = useFormContext()
  const title = watch('titleTr.th')
  const tags = watch('tags')
  const expireAt = watch('expireAt')
  const pictureType = watch('picture')

  const { data: couponPictures, isLoading: loading } =
    useGetList('couponPicture')
  const coupons = couponPictures || []
  const couponSelected = coupons.find((coupon) => coupon.type === pictureType)

  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.couponGroup.title.icon')}
        </Typography>
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormSelectInput
            source="picture"
            translateChoice
            defaultValue={'SX'}
            choices={coupons.map((coupon) => ({
              id: coupon.type,
              name: `resources.couponGroup.icon.${coupon.type.toLowerCase()}`,
            }))}
            isLoading={loading}
            validate={required()}
          />
        </Grid>
        <Grid item>
          <Box sx={{ color: 'text.disabled', paddingBottom: '0.5rem' }}>
            <Typography variant="body2">
              {translate('resources.couponGroup.title.coupon_preview')}
            </Typography>
          </Box>
          <Card
            sx={{
              padding: '12px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'space-between',
              width: '351px',
              height: '134px',
              boxShadow: '0px 4px 24px 0px #616B7812',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {loading ? (
                <Skeleton variant="rounded" width={56} height={56} />
              ) : (
                <CardMedia
                  component="img"
                  sx={{ width: '56px', height: '56px' }}
                  image={couponSelected ? couponSelected.picture : ''}
                  alt={couponSelected ? couponSelected.id : 'Not selected'}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '0 1rem',
                }}
              >
                <Box
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '12rem',
                  }}
                >
                  <Typography
                    component="div"
                    variant="h6"
                    noWrap
                    sx={{ fontSize: '16px' }}
                  >
                    {title ||
                      translate('resources.couponGroup.title.coupon_title')}
                  </Typography>
                </Box>
                <Box display="flex" gap={1} flexWrap="wrap">
                  {tags?.map((tag: { en: string; th: string }) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={getTr(tag, locale as Language)}
                      sx={{
                        color:
                          pictureType === 'SHIPPING' ? '#1D4ED8' : '#1F8280',
                        borderRadius: '4px',
                        borderColor:
                          pictureType === 'SHIPPING' ? '#DBEAFE' : '#D2F5F0',
                        fontSize: '12px',
                      }}
                      key={tag.en}
                    />
                  ))}
                </Box>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="div"
                  sx={{ fontSize: '12px' }}
                >
                  {[
                    translate('resources.couponGroup.title.coupon_expireAt'),
                    format(expireAt || new Date(), 'dd MMM yyyy'),
                  ].join(': ')}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Checkbox
                size="medium"
                defaultChecked
                sx={{
                  '&.Mui-checked': {
                    color: '#44BDB7',
                  },
                }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
