import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryCreateWithoutProductInputObjectSchema } from './ProductCategoryCreateWithoutProductInput.schema'
import { ProductCategoryUncheckedCreateWithoutProductInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateOrConnectWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryCreateOrConnectWithoutProductInputObjectSchema =
  Schema
