import { z } from 'zod'
import { ProcedureReviewWhereInputObjectSchema } from './ProcedureReviewWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewRelationFilter> = z
  .object({
    is: z.lazy(() => ProcedureReviewWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => ProcedureReviewWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewRelationFilterObjectSchema = Schema
