import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumPaymentGatewayFilterObjectSchema } from './NestedEnumPaymentGatewayFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumPaymentGatewayWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => PaymentGatewaySchema).optional(),
    in: z
      .lazy(() => PaymentGatewaySchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PaymentGatewaySchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PaymentGatewaySchema),
        z.lazy(() => NestedEnumPaymentGatewayWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumPaymentGatewayFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumPaymentGatewayFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumPaymentGatewayWithAggregatesFilterObjectSchema = Schema
