import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CaseOrderByWithRelationInputObjectSchema } from './CaseOrderByWithRelationInput.schema'
import { ScheduleOrderByWithRelationInputObjectSchema } from './ScheduleOrderByWithRelationInput.schema'
import { TeleconsultOrderByWithRelationInputObjectSchema } from './TeleconsultOrderByWithRelationInput.schema'
import { CaseSummaryOrderByWithRelationInputObjectSchema } from './CaseSummaryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    scheduleId: z.lazy(() => SortOrderSchema).optional(),
    appointmentAt: z.lazy(() => SortOrderSchema).optional(),
    cancelAt: z.lazy(() => SortOrderSchema).optional(),
    cancelDetail: z.lazy(() => SortOrderSchema).optional(),
    teleconsultId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    case: z.lazy(() => CaseOrderByWithRelationInputObjectSchema).optional(),
    schedule: z
      .lazy(() => ScheduleOrderByWithRelationInputObjectSchema)
      .optional(),
    teleconsult: z
      .lazy(() => TeleconsultOrderByWithRelationInputObjectSchema)
      .optional(),
    followUpCaseSummary: z
      .lazy(() => CaseSummaryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const AppointmentOrderByWithRelationInputObjectSchema = Schema
