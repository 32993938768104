import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ConsentCountOrderByAggregateInputObjectSchema } from './ConsentCountOrderByAggregateInput.schema'
import { ConsentMaxOrderByAggregateInputObjectSchema } from './ConsentMaxOrderByAggregateInput.schema'
import { ConsentMinOrderByAggregateInputObjectSchema } from './ConsentMinOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentOrderByWithAggregationInput> = z
  .object({
    key: z.lazy(() => SortOrderSchema).optional(),
    detail: z.lazy(() => SortOrderSchema).optional(),
    rejectDetail: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ConsentCountOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z.lazy(() => ConsentMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => ConsentMinOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const ConsentOrderByWithAggregationInputObjectSchema = Schema
