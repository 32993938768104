import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Card,
  CardContent,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import {
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  ImageField,
  List,
  TextField,
  useRedirect,
} from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CustomBooleanField } from '../../components/CustomField'
import { FileStoragePreview } from '../../components/FileStoragePreview'
import { FileValue } from '../../components/types'
import { adminGatewayClient } from '../../service'
import { ActiveButton } from './ActiveButton'

export const ProductBannerFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, mt: 8, width: 200 }}>
    <CardContent>
      <FilterLiveSearch source="name" />
      <FilterList label="Showing" icon={<VisibilityIcon />}>
        <FilterListItem label="Yes" value={{ active: true }} />
        <FilterListItem label="No" value={{ active: false }} />
      </FilterList>
    </CardContent>
  </Card>
)

export const ProductBannerList = () => {
  const queryClient = useQueryClient()
  const { data, isLoading, error } = useQuery(
    ['productBanner', 'active'],
    () => {
      return adminGatewayClient.productBanner.findMany.query({
        where: { active: true },
        orderBy: { rank: 'asc' },
      })
    },
  )
  const { mutate } = useMutation(
    async (input: { id: number; rank: number }) => {
      return adminGatewayClient.productBanner.setActive.mutate(input)
    },
    {
      onMutate: async (input) => {
        await queryClient.cancelQueries(['productBanner', 'active'])

        // Snapshot the previous value
        const previousProductBanners = queryClient.getQueryData([
          'productBanner',
          'active',
        ])

        // Optimistically update to the new value
        queryClient.setQueryData(
          ['productBanner', 'active'],
          (old?: { id: number; rank: number }[]) => {
            if (!old) {
              return []
            }
            return old
              .map((banner) => {
                if (banner.id === input.id) {
                  return {
                    ...banner,
                    rank: input.rank,
                  }
                }
                if (banner.rank >= input.rank) {
                  return {
                    ...banner,
                    rank: banner.rank + 1,
                  }
                }
                return banner
              })
              .sort((a, b) => a.rank - b.rank)
          },
        )

        // Return a context object with the snapshotted value
        return { previousProductBanners }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (_, __, context) => {
        queryClient.setQueryData(
          ['productBanner', 'active'],
          context?.previousProductBanners,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(['productBanner', 'active'])
      },
    },
  )
  return (
    <Stack spacing={2}>
      <Card sx={{ mt: 3, p: 2 }}>
        <Typography>
          Active banners {data ? `(${data.length}/10)` : null}
        </Typography>
        <Container>
          {isLoading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : error || !data ? (
            <LoadingContainer>error</LoadingContainer>
          ) : (
            <ProductBannerActiveList
              setOrder={mutate}
              items={data.map((banner) => ({
                id: banner.id,
                rank: banner.rank,
                file: {
                  src: banner.picture.src,
                  title: banner.name,
                },
              }))}
            />
          )}
        </Container>
      </Card>
      <List disableSyncWithLocation aside={<ProductBannerFilterSidebar />}>
        <Datagrid size="small" rowClick="edit">
          <TextField source="id" />
          <ImageField source="picture.src" label="Picture" />
          <TextField source="name" />
          <TextField source="actionUri" />
          <CustomBooleanField source="active" />
          <ActiveButton />
        </Datagrid>
      </List>
    </Stack>
  )
}

const Container = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
}))
const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

interface ProductBannerActiveListProps {
  items: { id: number; rank: number; file: FileValue }[]
  setOrder: (input: { id: number; rank: number }) => void
}

const ProductBannerActiveList: FC<ProductBannerActiveListProps> = ({
  items,
  setOrder,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 20,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  )

  const [activeId, setActiveId] = useState<number | null>(null)

  const getIndex = (id: number) => items.findIndex((item) => item.id === id)
  const activeIndex = activeId ? getIndex(activeId) : -1

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }
        setActiveId(active.id as number)
      }}
      onDragEnd={({ over }) => {
        setActiveId(null)
        if (over) {
          const overIndex = getIndex(over.id as number)
          if (activeIndex !== overIndex) {
            if (overIndex === 0) {
              setOrder({ id: activeId as number, rank: 0 })
            } else {
              if (activeIndex < overIndex) {
                const overItem = items[overIndex]
                setOrder({ id: activeId as number, rank: overItem.rank + 1 })
              } else {
                const prevItem = items[overIndex - 1]
                setOrder({ id: activeId as number, rank: prevItem.rank + 1 })
              }
            }
          }
        }
      }}
    >
      <SortableContext items={items}>
        <ListContainer>
          {items.map((item) => (
            <SortableProductBanner
              key={item.id}
              id={item.id}
              file={item.file}
            />
          ))}
        </ListContainer>
      </SortableContext>
    </DndContext>
  )
}

const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: 128,
}))

interface SortableProductBanner {
  id: UniqueIdentifier
  file: FileValue
}

const SortableProductBanner: FC<SortableProductBanner> = ({ id, file }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const redirect = useRedirect()

  return (
    <ItemContainer
      style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <FileStoragePreview
        file={file}
        fileType="IMAGE"
        onClick={() => redirect('edit', 'productBanner', id)}
      />
    </ItemContainer>
  )
}

const ItemContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
}))
