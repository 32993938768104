import { z } from 'zod'
import { AppointmentCreateWithoutCaseInputObjectSchema } from './AppointmentCreateWithoutCaseInput.schema'
import { AppointmentUncheckedCreateWithoutCaseInputObjectSchema } from './AppointmentUncheckedCreateWithoutCaseInput.schema'
import { AppointmentCreateOrConnectWithoutCaseInputObjectSchema } from './AppointmentCreateOrConnectWithoutCaseInput.schema'
import { AppointmentUpsertWithoutCaseInputObjectSchema } from './AppointmentUpsertWithoutCaseInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentUpdateWithoutCaseInputObjectSchema } from './AppointmentUpdateWithoutCaseInput.schema'
import { AppointmentUncheckedUpdateWithoutCaseInputObjectSchema } from './AppointmentUncheckedUpdateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpdateOneWithoutCaseNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => AppointmentCreateWithoutCaseInputObjectSchema),
        z.lazy(() => AppointmentUncheckedCreateWithoutCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => AppointmentCreateOrConnectWithoutCaseInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => AppointmentUpsertWithoutCaseInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => AppointmentUpdateWithoutCaseInputObjectSchema),
        z.lazy(() => AppointmentUncheckedUpdateWithoutCaseInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const AppointmentUpdateOneWithoutCaseNestedInputObjectSchema = Schema
