import { z } from 'zod'
import { ProductItemCreateWithoutDeliveryInputObjectSchema } from './ProductItemCreateWithoutDeliveryInput.schema'
import { ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateWithoutDeliveryInput.schema'
import { ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema } from './ProductItemCreateOrConnectWithoutDeliveryInput.schema'
import { ProductItemUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema } from './ProductItemUpsertWithWhereUniqueWithoutDeliveryInput.schema'
import { ProductItemCreateManyDeliveryInputEnvelopeObjectSchema } from './ProductItemCreateManyDeliveryInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema } from './ProductItemUpdateWithWhereUniqueWithoutDeliveryInput.schema'
import { ProductItemUpdateManyWithWhereWithoutDeliveryInputObjectSchema } from './ProductItemUpdateManyWithWhereWithoutDeliveryInput.schema'
import { ProductItemScalarWhereInputObjectSchema } from './ProductItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUncheckedUpdateManyWithoutDeliveryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema),
          z
            .lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductItemUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemCreateManyDeliveryInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductItemUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductItemUpdateManyWithWhereWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemUpdateManyWithWhereWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductItemScalarWhereInputObjectSchema),
          z.lazy(() => ProductItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemUncheckedUpdateManyWithoutDeliveryNestedInputObjectSchema =
  Schema
