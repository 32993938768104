import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerCreateWithoutSkuInputObjectSchema } from './PartnerCreateWithoutSkuInput.schema'
import { PartnerUncheckedCreateWithoutSkuInputObjectSchema } from './PartnerUncheckedCreateWithoutSkuInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutSkuInput> = z
  .object({
    where: z.lazy(() => PartnerWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutSkuInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutSkuInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerCreateOrConnectWithoutSkuInputObjectSchema = Schema
