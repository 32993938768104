import { z } from 'zod'
import { PatientCreateWithoutNotificationsInputObjectSchema } from './PatientCreateWithoutNotificationsInput.schema'
import { PatientUncheckedCreateWithoutNotificationsInputObjectSchema } from './PatientUncheckedCreateWithoutNotificationsInput.schema'
import { PatientCreateOrConnectWithoutNotificationsInputObjectSchema } from './PatientCreateOrConnectWithoutNotificationsInput.schema'
import { PatientUpsertWithoutNotificationsInputObjectSchema } from './PatientUpsertWithoutNotificationsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutNotificationsInputObjectSchema } from './PatientUpdateWithoutNotificationsInput.schema'
import { PatientUncheckedUpdateWithoutNotificationsInputObjectSchema } from './PatientUncheckedUpdateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutNotificationsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutNotificationsInputObjectSchema),
          z.lazy(
            () => PatientUncheckedCreateWithoutNotificationsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PatientCreateOrConnectWithoutNotificationsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutNotificationsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutNotificationsInputObjectSchema),
          z.lazy(
            () => PatientUncheckedUpdateWithoutNotificationsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutNotificationsNestedInputObjectSchema =
  Schema
