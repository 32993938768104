import { z } from 'zod'
import { OfficeHourCreateManyBranchInputObjectSchema } from './OfficeHourCreateManyBranchInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCreateManyBranchInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => OfficeHourCreateManyBranchInputObjectSchema),
      z.lazy(() => OfficeHourCreateManyBranchInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const OfficeHourCreateManyBranchInputEnvelopeObjectSchema = Schema
