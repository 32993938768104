import { z } from 'zod'
import { HomeBannerOrderByWithRelationInputObjectSchema } from './objects/HomeBannerOrderByWithRelationInput.schema'
import { HomeBannerWhereInputObjectSchema } from './objects/HomeBannerWhereInput.schema'
import { HomeBannerWhereUniqueInputObjectSchema } from './objects/HomeBannerWhereUniqueInput.schema'
import { HomeBannerCountAggregateInputObjectSchema } from './objects/HomeBannerCountAggregateInput.schema'
import { HomeBannerMinAggregateInputObjectSchema } from './objects/HomeBannerMinAggregateInput.schema'
import { HomeBannerMaxAggregateInputObjectSchema } from './objects/HomeBannerMaxAggregateInput.schema'
import { HomeBannerAvgAggregateInputObjectSchema } from './objects/HomeBannerAvgAggregateInput.schema'
import { HomeBannerSumAggregateInputObjectSchema } from './objects/HomeBannerSumAggregateInput.schema'

export const HomeBannerAggregateSchema = z.object({
  orderBy: z
    .union([
      HomeBannerOrderByWithRelationInputObjectSchema,
      HomeBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HomeBannerWhereInputObjectSchema.optional(),
  cursor: HomeBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), HomeBannerCountAggregateInputObjectSchema])
    .optional(),
  _min: HomeBannerMinAggregateInputObjectSchema.optional(),
  _max: HomeBannerMaxAggregateInputObjectSchema.optional(),
  _avg: HomeBannerAvgAggregateInputObjectSchema.optional(),
  _sum: HomeBannerSumAggregateInputObjectSchema.optional(),
})
