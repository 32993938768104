import { z } from 'zod'
import { DeliveryOrderByWithRelationInputObjectSchema } from './objects/DeliveryOrderByWithRelationInput.schema'
import { DeliveryWhereInputObjectSchema } from './objects/DeliveryWhereInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './objects/DeliveryWhereUniqueInput.schema'
import { DeliveryCountAggregateInputObjectSchema } from './objects/DeliveryCountAggregateInput.schema'
import { DeliveryMinAggregateInputObjectSchema } from './objects/DeliveryMinAggregateInput.schema'
import { DeliveryMaxAggregateInputObjectSchema } from './objects/DeliveryMaxAggregateInput.schema'
import { DeliveryAvgAggregateInputObjectSchema } from './objects/DeliveryAvgAggregateInput.schema'
import { DeliverySumAggregateInputObjectSchema } from './objects/DeliverySumAggregateInput.schema'

export const DeliveryAggregateSchema = z.object({
  orderBy: z
    .union([
      DeliveryOrderByWithRelationInputObjectSchema,
      DeliveryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DeliveryWhereInputObjectSchema.optional(),
  cursor: DeliveryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), DeliveryCountAggregateInputObjectSchema])
    .optional(),
  _min: DeliveryMinAggregateInputObjectSchema.optional(),
  _max: DeliveryMaxAggregateInputObjectSchema.optional(),
  _avg: DeliveryAvgAggregateInputObjectSchema.optional(),
  _sum: DeliverySumAggregateInputObjectSchema.optional(),
})
