import { z } from 'zod'
import { PatientCreateWithoutKycDataInputObjectSchema } from './PatientCreateWithoutKycDataInput.schema'
import { PatientUncheckedCreateWithoutKycDataInputObjectSchema } from './PatientUncheckedCreateWithoutKycDataInput.schema'
import { PatientCreateOrConnectWithoutKycDataInputObjectSchema } from './PatientCreateOrConnectWithoutKycDataInput.schema'
import { PatientUpsertWithoutKycDataInputObjectSchema } from './PatientUpsertWithoutKycDataInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutKycDataInputObjectSchema } from './PatientUpdateWithoutKycDataInput.schema'
import { PatientUncheckedUpdateWithoutKycDataInputObjectSchema } from './PatientUncheckedUpdateWithoutKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutKycDataNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutKycDataInputObjectSchema),
          z.lazy(() => PatientUncheckedCreateWithoutKycDataInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PatientCreateOrConnectWithoutKycDataInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutKycDataInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutKycDataInputObjectSchema),
          z.lazy(() => PatientUncheckedUpdateWithoutKycDataInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutKycDataNestedInputObjectSchema =
  Schema
