import { z } from 'zod'
import { SegmentUpdateInputObjectSchema } from './objects/SegmentUpdateInput.schema'
import { SegmentUncheckedUpdateInputObjectSchema } from './objects/SegmentUncheckedUpdateInput.schema'
import { SegmentWhereUniqueInputObjectSchema } from './objects/SegmentWhereUniqueInput.schema'

export const SegmentUpdateOneSchema = z.object({
  data: z.union([
    SegmentUpdateInputObjectSchema,
    SegmentUncheckedUpdateInputObjectSchema,
  ]),
  where: SegmentWhereUniqueInputObjectSchema,
})
