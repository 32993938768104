import { z } from 'zod'
import { CarouselWhereUniqueInputObjectSchema } from './CarouselWhereUniqueInput.schema'
import { CarouselCreateWithoutItemsInputObjectSchema } from './CarouselCreateWithoutItemsInput.schema'
import { CarouselUncheckedCreateWithoutItemsInputObjectSchema } from './CarouselUncheckedCreateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselCreateOrConnectWithoutItemsInput> = z
  .object({
    where: z.lazy(() => CarouselWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CarouselCreateWithoutItemsInputObjectSchema),
      z.lazy(() => CarouselUncheckedCreateWithoutItemsInputObjectSchema),
    ]),
  })
  .strict()

export const CarouselCreateOrConnectWithoutItemsInputObjectSchema = Schema
