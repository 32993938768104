import { z } from 'zod'
import { MyReviewUpdateInputObjectSchema } from './objects/MyReviewUpdateInput.schema'
import { MyReviewUncheckedUpdateInputObjectSchema } from './objects/MyReviewUncheckedUpdateInput.schema'
import { MyReviewWhereUniqueInputObjectSchema } from './objects/MyReviewWhereUniqueInput.schema'

export const MyReviewUpdateOneSchema = z.object({
  data: z.union([
    MyReviewUpdateInputObjectSchema,
    MyReviewUncheckedUpdateInputObjectSchema,
  ]),
  where: MyReviewWhereUniqueInputObjectSchema,
})
