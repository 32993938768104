import * as z from "zod"
import { CompleteDoctorItem, RelatedDoctorItemModel, CompleteProductItemOriginal, RelatedProductItemOriginalModel, CompleteProductItem, RelatedProductItemModel, CompleteProcedureItem, RelatedProcedureItemModel, CompleteCoupon, RelatedCouponModel, CompleteShippingProvider, RelatedShippingProviderModel, CompletePayment, RelatedPaymentModel, CompleteRefund, RelatedRefundModel, CompleteDelivery, RelatedDeliveryModel, CompleteHealthInfo, RelatedHealthInfoModel, CompleteReceipt, RelatedReceiptModel } from "./index"

export const OrderModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  status: z.enum(['PENDING_REVIEW', 'AWAITING_PAYMENT', 'PAYMENT_IN_PROGRESS', 'PAYMENT_PENDING', 'PAYMENT_SUCCESS', 'PAYMENT_FAILED', 'SENT_TO_DRUGSTORE', 'ON_DELIVERY', 'COMPLETED', 'CANCELED', 'REFUNDED']),
  expiredAt: z.date().nullish(),
  shouldExpiresAt: z.date().nullish(),
  orderNumber: z.string(),
  patientId: z.number().int(),
  customerName: z.string(),
  customerTelNo: z.string(),
  orderType: z.enum(['TELECONSULT', 'STORE']),
  purchaseOrigin: z.enum(['SKINX_PATIENT_APP', 'SKINX_BACKOFFICE']).nullish(),
  purchaseRef: z.string().nullish(),
  editedProductItemsAt: z.date().nullish(),
  editedProductItemsBy: z.number().int().nullish(),
  shippingFee: z.number(),
  shippingFeeDiscount: z.number(),
  shippingType: z.enum(['NORMAL', 'EXPRESS']).nullish(),
  shippingProviderId: z.number().int().nullish(),
  recipient: z.string().nullish(),
  shippingAddress: z.string().nullish(),
  shippingTelNo: z.string().nullish(),
  subDistrict: z.string().nullish(),
  subDistrictKey: z.string().nullish(),
  district: z.string().nullish(),
  districtKey: z.string().nullish(),
  province: z.string().nullish(),
  provinceKey: z.string().nullish(),
  postcode: z.string().nullish(),
  isPaid: z.boolean(),
  isHold: z.boolean(),
  approvedBy: z.number().int().nullish(),
  approvedAt: z.date().nullish(),
  canceledBy: z.number().int().nullish(),
  canceledAt: z.date().nullish(),
  cancelChannel: z.enum(['PHARMACIST', 'CRON', 'OMS']).nullish(),
  noteToPatient: z.string().nullish(),
  useCoin: z.number(),
  v2InvoiceId: z.number().int().nullish(),
  holdingNote: z.string().nullish(),
  holdingBy: z.number().int().nullish(),
  holdingAt: z.date().nullish(),
  isDeclinedSuggestedItems: z.boolean(),
  declinedSuggestedItemsReason: z.enum(['SELF_BOUGHT', 'WANT_TO_CHANGE_ITEMS', 'WANT_TO_USE_COUPON_DISCOUNT', 'TOO_EXPENSIVE']).nullish(),
})

export interface CompleteOrder extends z.infer<typeof OrderModel> {
  doctorItem?: CompleteDoctorItem | null
  productItemsOriginal: CompleteProductItemOriginal[]
  productItems: CompleteProductItem[]
  procedureItems: CompleteProcedureItem[]
  coupons: CompleteCoupon[]
  shippingProvider?: CompleteShippingProvider | null
  payments: CompletePayment[]
  refunds: CompleteRefund[]
  deliveries: CompleteDelivery[]
  healthInfo?: CompleteHealthInfo | null
  receipt?: CompleteReceipt | null
}

/**
 * RelatedOrderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedOrderModel: z.ZodSchema<CompleteOrder> = z.lazy(() => OrderModel.extend({
  doctorItem: RelatedDoctorItemModel.nullish(),
  productItemsOriginal: RelatedProductItemOriginalModel.array(),
  productItems: RelatedProductItemModel.array(),
  procedureItems: RelatedProcedureItemModel.array(),
  coupons: RelatedCouponModel.array(),
  shippingProvider: RelatedShippingProviderModel.nullish(),
  payments: RelatedPaymentModel.array(),
  refunds: RelatedRefundModel.array(),
  deliveries: RelatedDeliveryModel.array(),
  healthInfo: RelatedHealthInfoModel.nullish(),
  receipt: RelatedReceiptModel.nullish(),
}))
