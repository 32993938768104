import { z } from 'zod'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(
        () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
      ),
      update: z.union([
        z.lazy(
          () =>
            SuggestedProductItemOriginalUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemOriginalUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () =>
            SuggestedProductItemOriginalCreateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
