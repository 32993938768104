import { Typography } from '@mui/material'
import { FC } from 'react'
import { FormImageInput } from './formImageInput'

export const CertificateSection: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ประกาศนียบัตร
      </Typography>
      <FormImageInput
        source="certificate"
        label="รูปประกาศนียบัตร"
        multiple={true}
      />
    </>
  )
}
