import { z } from 'zod'
import { AcceptedConsentSelectObjectSchema } from './AcceptedConsentSelect.schema'
import { AcceptedConsentIncludeObjectSchema } from './AcceptedConsentInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentArgs> = z
  .object({
    select: z.lazy(() => AcceptedConsentSelectObjectSchema).optional(),
    include: z.lazy(() => AcceptedConsentIncludeObjectSchema).optional(),
  })
  .strict()

export const AcceptedConsentArgsObjectSchema = Schema
