import { z } from 'zod'
import { WaitingBannerUpdateInputObjectSchema } from './objects/WaitingBannerUpdateInput.schema'
import { WaitingBannerUncheckedUpdateInputObjectSchema } from './objects/WaitingBannerUncheckedUpdateInput.schema'
import { WaitingBannerWhereUniqueInputObjectSchema } from './objects/WaitingBannerWhereUniqueInput.schema'

export const WaitingBannerUpdateOneSchema = z.object({
  data: z.union([
    WaitingBannerUpdateInputObjectSchema,
    WaitingBannerUncheckedUpdateInputObjectSchema,
  ]),
  where: WaitingBannerWhereUniqueInputObjectSchema,
})
