import { z } from 'zod'
import { CasePictureWhereUniqueInputObjectSchema } from './objects/CasePictureWhereUniqueInput.schema'
import { CasePictureCreateInputObjectSchema } from './objects/CasePictureCreateInput.schema'
import { CasePictureUncheckedCreateInputObjectSchema } from './objects/CasePictureUncheckedCreateInput.schema'
import { CasePictureUpdateInputObjectSchema } from './objects/CasePictureUpdateInput.schema'
import { CasePictureUncheckedUpdateInputObjectSchema } from './objects/CasePictureUncheckedUpdateInput.schema'

export const CasePictureUpsertSchema = z.object({
  where: CasePictureWhereUniqueInputObjectSchema,
  create: z.union([
    CasePictureCreateInputObjectSchema,
    CasePictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CasePictureUpdateInputObjectSchema,
    CasePictureUncheckedUpdateInputObjectSchema,
  ]),
})
