import { z } from 'zod'
import { CouponGroupWhereInputObjectSchema } from './objects/CouponGroupWhereInput.schema'
import { CouponGroupOrderByWithAggregationInputObjectSchema } from './objects/CouponGroupOrderByWithAggregationInput.schema'
import { CouponGroupScalarWhereWithAggregatesInputObjectSchema } from './objects/CouponGroupScalarWhereWithAggregatesInput.schema'
import { CouponGroupScalarFieldEnumSchema } from './enums/CouponGroupScalarFieldEnum.schema'

export const CouponGroupGroupBySchema = z.object({
  where: CouponGroupWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CouponGroupOrderByWithAggregationInputObjectSchema,
    CouponGroupOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CouponGroupScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CouponGroupScalarFieldEnumSchema),
})
