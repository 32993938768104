import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleDoctorIdAvailableAtCompoundUniqueInput> =
  z
    .object({
      doctorId: z.number(),
      availableAt: z.date(),
    })
    .strict()

export const ScheduleDoctorIdAvailableAtCompoundUniqueInputObjectSchema = Schema
