import { z } from 'zod'
import { MyReviewOrderByWithRelationInputObjectSchema } from './objects/MyReviewOrderByWithRelationInput.schema'
import { MyReviewWhereInputObjectSchema } from './objects/MyReviewWhereInput.schema'
import { MyReviewWhereUniqueInputObjectSchema } from './objects/MyReviewWhereUniqueInput.schema'
import { MyReviewScalarFieldEnumSchema } from './enums/MyReviewScalarFieldEnum.schema'

export const MyReviewFindFirstSchema = z.object({
  orderBy: z
    .union([
      MyReviewOrderByWithRelationInputObjectSchema,
      MyReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: MyReviewWhereInputObjectSchema.optional(),
  cursor: MyReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(MyReviewScalarFieldEnumSchema).optional(),
})
