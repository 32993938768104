import * as z from "zod"
import { CompleteVariant, RelatedVariantModel } from "./index"

export const VariantPictureModel = z.object({
  id: z.number().int(),
  url: z.string(),
  variantSku: z.string(),
  rank: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteVariantPicture extends z.infer<typeof VariantPictureModel> {
  variant: CompleteVariant
}

/**
 * RelatedVariantPictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVariantPictureModel: z.ZodSchema<CompleteVariantPicture> = z.lazy(() => VariantPictureModel.extend({
  variant: RelatedVariantModel,
}))
