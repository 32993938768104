import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { CaseRelationFilterObjectSchema } from './CaseRelationFilter.schema'
import { CaseWhereInputObjectSchema } from './CaseWhereInput.schema'
import { CaseSummaryDiagnosisListRelationFilterObjectSchema } from './CaseSummaryDiagnosisListRelationFilter.schema'
import { AppointmentRelationFilterObjectSchema } from './AppointmentRelationFilter.schema'
import { AppointmentWhereInputObjectSchema } from './AppointmentWhereInput.schema'
import { SuggestedProductItemListRelationFilterObjectSchema } from './SuggestedProductItemListRelationFilter.schema'
import { SuggestedProductItemOriginalListRelationFilterObjectSchema } from './SuggestedProductItemOriginalListRelationFilter.schema'
import { SuggestedProcedureItemListRelationFilterObjectSchema } from './SuggestedProcedureItemListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CaseSummaryWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    caseId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    presentIllness: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    peNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    isTeleMedNotRecommend: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    recommendation: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    doctorFee: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    doctorFeeDiscount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commissionRate: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commissionPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    netDoctorFee: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    doctorNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    pharmacistNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    followUpAppointmentId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    recommedNumberOfTreatment: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    additionalSuggestedProcedure: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    editedSuggestedProductItemAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    editedSuggestedProductItemBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    v2CaseSummaryId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    caseUrl: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    case: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional(),
    caseSummaryDiagnosis: z
      .lazy(() => CaseSummaryDiagnosisListRelationFilterObjectSchema)
      .optional(),
    followUpAppointment: z
      .union([
        z.lazy(() => AppointmentRelationFilterObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    suggestedProductItems: z
      .lazy(() => SuggestedProductItemListRelationFilterObjectSchema)
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(() => SuggestedProductItemOriginalListRelationFilterObjectSchema)
      .optional(),
    suggestedProcedureItems: z
      .lazy(() => SuggestedProcedureItemListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const CaseSummaryWhereInputObjectSchema = Schema
