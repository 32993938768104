import { FC } from 'react'
import {
  AutocompleteArrayInputProps,
  AutocompleteInputProps,
  DateInputProps,
  NumberInputProps,
  SelectArrayInputProps,
  SelectInputProps,
  TextInputProps,
} from 'react-admin'
import {
  CustomAutoCompleteArrayInput,
  CustomAutoCompleteInput,
  CustomDateInput,
  CustomDateTimeInput,
  CustomNumberInput,
  CustomPasswordInput,
  CustomSelectArrayInput,
  CustomSelectInput,
  CustomTextInput,
} from '../../components/CustomInput'

const mainStyle = { width: '100%', margin: '8px 0 0 0 !important' }

export const FormTextInput: FC<TextInputProps> = ({ sx, ...props }) => (
  <CustomTextInput {...props} sx={{ ...mainStyle, ...sx }} />
)

export const FormNumberInput: FC<NumberInputProps> = ({ sx, ...props }) => (
  <CustomNumberInput {...props} sx={{ ...mainStyle, ...sx }} />
)

export const FormSelectInput: FC<SelectInputProps> = ({ sx, ...props }) => (
  <CustomSelectInput {...props} sx={{ ...mainStyle, ...sx }} />
)

export const FormAutoCompleteInput: FC<AutocompleteInputProps> = ({
  sx,
  ...props
}) => <CustomAutoCompleteInput {...props} sx={{ ...mainStyle, ...sx }} />

export const FormAutoCompleteArrayInput: FC<AutocompleteArrayInputProps> = ({
  sx,
  ...props
}) => <CustomAutoCompleteArrayInput {...props} sx={{ ...mainStyle, ...sx }} />

export const FormSelectArrayInput: FC<SelectArrayInputProps> = (props) => (
  <CustomSelectArrayInput {...props} sx={mainStyle} />
)

export const FormDateInput: FC<DateInputProps> = ({ sx, ...props }) => (
  <CustomDateInput {...props} sx={{ ...mainStyle, ...sx }} />
)

export const FormDateTimeInput: FC<DateInputProps> = ({ sx, ...props }) => (
  <CustomDateTimeInput {...props} sx={{ ...mainStyle, ...sx }} />
)

export const FormPasswordInput: FC<DateInputProps> = ({ sx, ...props }) => (
  <CustomPasswordInput {...props} sx={{ ...mainStyle, ...sx }} />
)
