import { z } from 'zod'
import { ProductItemTypeSchema } from '../enums/ProductItemType.schema'
import { NestedEnumProductItemTypeWithAggregatesFilterObjectSchema } from './NestedEnumProductItemTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumProductItemTypeFilterObjectSchema } from './NestedEnumProductItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumProductItemTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ProductItemTypeSchema).optional(),
    in: z
      .lazy(() => ProductItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ProductItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ProductItemTypeSchema),
        z.lazy(() => NestedEnumProductItemTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumProductItemTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumProductItemTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumProductItemTypeWithAggregatesFilterObjectSchema = Schema
