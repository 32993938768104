import { z } from 'zod'
import { ProductItemOriginalOrderByWithRelationInputObjectSchema } from './objects/ProductItemOriginalOrderByWithRelationInput.schema'
import { ProductItemOriginalWhereInputObjectSchema } from './objects/ProductItemOriginalWhereInput.schema'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './objects/ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalCountAggregateInputObjectSchema } from './objects/ProductItemOriginalCountAggregateInput.schema'
import { ProductItemOriginalMinAggregateInputObjectSchema } from './objects/ProductItemOriginalMinAggregateInput.schema'
import { ProductItemOriginalMaxAggregateInputObjectSchema } from './objects/ProductItemOriginalMaxAggregateInput.schema'
import { ProductItemOriginalAvgAggregateInputObjectSchema } from './objects/ProductItemOriginalAvgAggregateInput.schema'
import { ProductItemOriginalSumAggregateInputObjectSchema } from './objects/ProductItemOriginalSumAggregateInput.schema'

export const ProductItemOriginalAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductItemOriginalOrderByWithRelationInputObjectSchema,
      ProductItemOriginalOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductItemOriginalWhereInputObjectSchema.optional(),
  cursor: ProductItemOriginalWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ProductItemOriginalCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ProductItemOriginalMinAggregateInputObjectSchema.optional(),
  _max: ProductItemOriginalMaxAggregateInputObjectSchema.optional(),
  _avg: ProductItemOriginalAvgAggregateInputObjectSchema.optional(),
  _sum: ProductItemOriginalSumAggregateInputObjectSchema.optional(),
})
