import { z } from 'zod'
import { CaseUpdateWithoutPreviousCaseInputObjectSchema } from './CaseUpdateWithoutPreviousCaseInput.schema'
import { CaseUncheckedUpdateWithoutPreviousCaseInputObjectSchema } from './CaseUncheckedUpdateWithoutPreviousCaseInput.schema'
import { CaseCreateWithoutPreviousCaseInputObjectSchema } from './CaseCreateWithoutPreviousCaseInput.schema'
import { CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema } from './CaseUncheckedCreateWithoutPreviousCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutPreviousCaseInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutPreviousCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutPreviousCaseInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutPreviousCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutPreviousCaseInputObjectSchema = Schema
