import { z } from 'zod'
import { VariantWarehouseVariantIdWarehouseIdCompoundUniqueInputObjectSchema } from './VariantWarehouseVariantIdWarehouseIdCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    variantId_warehouseId: z
      .lazy(
        () =>
          VariantWarehouseVariantIdWarehouseIdCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantWarehouseWhereUniqueInputObjectSchema = Schema
