import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutCaseInputObjectSchema } from './CaseSummaryCreateWithoutCaseInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutCaseInput> = z
  .object({
    where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseSummaryCreateWithoutCaseInputObjectSchema),
      z.lazy(() => CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseSummaryCreateOrConnectWithoutCaseInputObjectSchema = Schema
