import { z } from 'zod'
import { ArticlePictureScalarWhereInputObjectSchema } from './ArticlePictureScalarWhereInput.schema'
import { ArticlePictureUpdateManyMutationInputObjectSchema } from './ArticlePictureUpdateManyMutationInput.schema'
import { ArticlePictureUncheckedUpdateManyWithoutPicturesInputObjectSchema } from './ArticlePictureUncheckedUpdateManyWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureUpdateManyWithWhereWithoutArticleInput> =
  z
    .object({
      where: z.lazy(() => ArticlePictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ArticlePictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ArticlePictureUncheckedUpdateManyWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ArticlePictureUpdateManyWithWhereWithoutArticleInputObjectSchema =
  Schema
