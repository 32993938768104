import z from 'zod'
import { PaymentMethodSchema } from '../generated/trpc/schemas'

const PaymentMethodEnum = PaymentMethodSchema.enum

export const CreditCardPaymentMethodModel = z
  .object({
    paymentMethod: z.literal(PaymentMethodEnum.CREDIT_CARD),
    customerId: z.string().startsWith('cust').optional(),
    cardId: z.string().startsWith('card').optional(),
    cardToken: z.string().startsWith('token').optional(),
  })
  .refine(
    (data) => {
      return (data.customerId && data.cardId) || data.cardToken
    },
    {
      message: 'customerId and cardId or cardToken is required',
    },
  )
export type CreditCardPaymentMethodModel = z.infer<
  typeof CreditCardPaymentMethodModel
>

export const MobileBankingPaymentMethodModel = z.object({
  paymentMethod: z.enum([
    PaymentMethodEnum.MOBILE_BANKING_BBL,
    PaymentMethodEnum.MOBILE_BANKING_KTB,
    PaymentMethodEnum.MOBILE_BANKING_SCB,
    PaymentMethodEnum.MOBILE_BANKING_KBANK,
    PaymentMethodEnum.MOBILE_BANKING_KMA,
  ]),
  platform: z.enum(['ios', 'android']),
})
export type MobileBankingPaymentMethodModel = z.infer<
  typeof MobileBankingPaymentMethodModel
>

export const InstallmentPaymentMethodModel = z.object({
  paymentMethod: z.enum([
    PaymentMethodEnum.INSTALLMENT_BAY,
    PaymentMethodEnum.INSTALLMENT_BBL,
    PaymentMethodEnum.INSTALLMENT_KTC,
    PaymentMethodEnum.INSTALLMENT_FIRST_CHOICE,
    PaymentMethodEnum.INSTALLMENT_SCB,
    PaymentMethodEnum.INSTALLMENT_KBANK,
    PaymentMethodEnum.INSTALLMENT_TTB,
    PaymentMethodEnum.INSTALLMENT_UOB,
  ]),
  installmentTerm: z.number().int(),
})
export type InstallmentPaymentMethodModel = z.infer<
  typeof InstallmentPaymentMethodModel
>

export const PromptPayPaymentMethodModel = z.object({
  paymentMethod: z.enum([PaymentMethodEnum.PROMPTPAY]),
})
export type PromptPayPaymentMethodModel = z.infer<
  typeof PromptPayPaymentMethodModel
>

export const PaymentMethodModel = z.union([
  CreditCardPaymentMethodModel,
  MobileBankingPaymentMethodModel,
  InstallmentPaymentMethodModel,
  PromptPayPaymentMethodModel,
])

export type PaymentMethodModel = z.infer<typeof PaymentMethodModel>

export const DiscountItemTypeSchema = z.enum([
  'DOCTOR',
  'DOCTOR_CATEGORY',
  'VARIANT',
  'VARIANT_FLASHSALE',
  'VARIANT_NO_FLASHSALE',
  'VARIANT_BRAND',
  'VARIANT_CATEGORY',
  'PROCEDURE',
  'PROCEDURE_FLASHSALE',
  'PROCEDURE_NO_FLASHSALE',
  'PROCEDURE_PARTNER',
  'PROCEDURE_CATEGORY',
  'SHIPPING_TYPE',
  'SHIPPING_PROVIDER',
])

export const ConditionScopeSchema = z.enum(['ALL', 'ONLY', 'EXCEPT'])
export type ConditionScopeType = z.infer<typeof ConditionScopeSchema>
