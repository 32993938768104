import { z } from 'zod'
import { ConsentOrderByWithRelationInputObjectSchema } from './objects/ConsentOrderByWithRelationInput.schema'
import { ConsentWhereInputObjectSchema } from './objects/ConsentWhereInput.schema'
import { ConsentWhereUniqueInputObjectSchema } from './objects/ConsentWhereUniqueInput.schema'
import { ConsentCountAggregateInputObjectSchema } from './objects/ConsentCountAggregateInput.schema'
import { ConsentMinAggregateInputObjectSchema } from './objects/ConsentMinAggregateInput.schema'
import { ConsentMaxAggregateInputObjectSchema } from './objects/ConsentMaxAggregateInput.schema'

export const ConsentAggregateSchema = z.object({
  orderBy: z
    .union([
      ConsentOrderByWithRelationInputObjectSchema,
      ConsentOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ConsentWhereInputObjectSchema.optional(),
  cursor: ConsentWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ConsentCountAggregateInputObjectSchema])
    .optional(),
  _min: ConsentMinAggregateInputObjectSchema.optional(),
  _max: ConsentMaxAggregateInputObjectSchema.optional(),
})
