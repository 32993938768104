import { z } from 'zod'
import { GenderSchema } from '../enums/Gender.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumGenderFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => GenderSchema).optional(),
  })
  .strict()

export const EnumGenderFieldUpdateOperationsInputObjectSchema = Schema
