import { z } from 'zod'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { AppointmentStatusSchema } from '../enums/AppointmentStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInput> =
  z
    .object({
      id: z.number().optional(),
      doctorId: z.number(),
      patientId: z.number(),
      type: z.lazy(() => AppointmentTypeSchema),
      status: z.lazy(() => AppointmentStatusSchema),
      caseId: z.number(),
      scheduleId: z.number().optional().nullable(),
      appointmentAt: z.date(),
      cancelAt: z.date().optional().nullable(),
      cancelDetail: z.string().optional().nullable(),
      teleconsultId: z.number().optional().nullable(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
    .strict()

export const AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema =
  Schema
