import * as z from "zod"
import { CompleteVariant, RelatedVariantModel, CompleteProduct, RelatedProductModel, CompleteBrand, RelatedBrandModel, CompleteProductReviewPicture, RelatedProductReviewPictureModel } from "./index"

export const ProductReviewModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  variantId: z.number().int(),
  productId: z.number().int(),
  brandId: z.number().int(),
  overallRating: z.number().int(),
  qualityRating: z.number().int(),
  shippingRating: z.number().int(),
  packagingRating: z.number().int(),
  message: z.string().nullish(),
  orderId: z.number().int(),
  patientId: z.number().int(),
  patientName: z.string(),
  isAnonymous: z.boolean(),
  priority: z.number().int(),
})

export interface CompleteProductReview extends z.infer<typeof ProductReviewModel> {
  variant: CompleteVariant
  product: CompleteProduct
  brand: CompleteBrand
  pictures: CompleteProductReviewPicture[]
}

/**
 * RelatedProductReviewModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductReviewModel: z.ZodSchema<CompleteProductReview> = z.lazy(() => ProductReviewModel.extend({
  variant: RelatedVariantModel,
  product: RelatedProductModel,
  brand: RelatedBrandModel,
  pictures: RelatedProductReviewPictureModel.array(),
}))
