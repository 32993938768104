import { z } from 'zod'
import { CoverPictureWhereUniqueInputObjectSchema } from './CoverPictureWhereUniqueInput.schema'
import { CoverPictureCreateWithoutPartnerInputObjectSchema } from './CoverPictureCreateWithoutPartnerInput.schema'
import { CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema } from './CoverPictureUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureCreateOrConnectWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CoverPictureCreateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema = Schema
