import { z } from 'zod'
import { GenderSchema } from '../enums/Gender.schema'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    orderId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    name: z.string(),
    gender: z.lazy(() => GenderSchema),
    birthdate: z.date(),
    drugAllergy: z.string().optional().nullable(),
    congenitalDisease: z.string().optional().nullable(),
    medication: z.string().optional().nullable(),
    pregnancyState: z
      .lazy(() => PregnancyStateSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const HealthInfoUncheckedCreateInputObjectSchema = Schema
