import { Datagrid, List, TextField } from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'

export const PatientConsentList = () => (
  <List disableSyncWithLocation>
    <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
      <TextField source="key" />
      <LocaleDateField
        source="createdAt"
        options={{ day: 'numeric', month: 'long', year: 'numeric' }}
      />
      <LocaleDateField
        source="updatedAt"
        options={{ day: 'numeric', month: 'long', year: 'numeric' }}
      />
    </Datagrid>
  </List>
)
