import { z } from 'zod'
import { ProcedureReviewSummaryWhereInputObjectSchema } from './ProcedureReviewSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryRelationFilter> = z
  .object({
    is: z
      .lazy(() => ProcedureReviewSummaryWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => ProcedureReviewSummaryWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const ProcedureReviewSummaryRelationFilterObjectSchema = Schema
