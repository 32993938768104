import { z } from 'zod'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'
import { CouponGroupCreateWithoutCouponsInputObjectSchema } from './CouponGroupCreateWithoutCouponsInput.schema'
import { CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema } from './CouponGroupUncheckedCreateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCreateOrConnectWithoutCouponsInput> =
  z
    .object({
      where: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CouponGroupCreateWithoutCouponsInputObjectSchema),
        z.lazy(() => CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema),
      ]),
    })
    .strict()

export const CouponGroupCreateOrConnectWithoutCouponsInputObjectSchema = Schema
