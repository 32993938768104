export interface FileValue {
  thumbnailSrc?: string // for video file
  type?: string // for video file
  rawFile?: File | null // upload first time (before hold)
  src: string
  title?: string
  id?: string
  uploadPromise?: Promise<
    UplodedFileField | [UplodedFileField, UplodedFileField] | null
  >
}

export interface UplodedFileField {
  uploadUrl: string
  expires: Date
  contentType?: string
  gsPath: string
  viewUrl: string
}

export enum ProgressStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export interface Progress {
  progress: number | null
  status: ProgressStatus
  abortController: AbortController
}

export interface ProgressState {
  [key: string]: Progress
}
