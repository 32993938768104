import { z } from 'zod'
import { DrugTypeSchema } from '../enums/DrugType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NullableEnumDrugTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => DrugTypeSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumDrugTypeFieldUpdateOperationsInputObjectSchema = Schema
