import { z } from 'zod'
import { HealthInfoCreateWithoutPatientInputObjectSchema } from './HealthInfoCreateWithoutPatientInput.schema'
import { HealthInfoUncheckedCreateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedCreateWithoutPatientInput.schema'
import { HealthInfoCreateOrConnectWithoutPatientInputObjectSchema } from './HealthInfoCreateOrConnectWithoutPatientInput.schema'
import { HealthInfoUpsertWithoutPatientInputObjectSchema } from './HealthInfoUpsertWithoutPatientInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'
import { HealthInfoUpdateWithoutPatientInputObjectSchema } from './HealthInfoUpdateWithoutPatientInput.schema'
import { HealthInfoUncheckedUpdateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoUpdateOneWithoutPatientNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => HealthInfoCreateWithoutPatientInputObjectSchema),
        z.lazy(() => HealthInfoUncheckedCreateWithoutPatientInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => HealthInfoCreateOrConnectWithoutPatientInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => HealthInfoUpsertWithoutPatientInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => HealthInfoUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => HealthInfoUncheckedUpdateWithoutPatientInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const HealthInfoUpdateOneWithoutPatientNestedInputObjectSchema = Schema
