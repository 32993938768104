import { z } from 'zod'
import { ProductCategoryCreateInputObjectSchema } from './objects/ProductCategoryCreateInput.schema'
import { ProductCategoryUncheckedCreateInputObjectSchema } from './objects/ProductCategoryUncheckedCreateInput.schema'

export const ProductCategoryCreateOneSchema = z.object({
  data: z.union([
    ProductCategoryCreateInputObjectSchema,
    ProductCategoryUncheckedCreateInputObjectSchema,
  ]),
})
