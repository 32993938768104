import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CaseSummaryDiagnosisCountOrderByAggregateInputObjectSchema } from './CaseSummaryDiagnosisCountOrderByAggregateInput.schema'
import { CaseSummaryDiagnosisAvgOrderByAggregateInputObjectSchema } from './CaseSummaryDiagnosisAvgOrderByAggregateInput.schema'
import { CaseSummaryDiagnosisMaxOrderByAggregateInputObjectSchema } from './CaseSummaryDiagnosisMaxOrderByAggregateInput.schema'
import { CaseSummaryDiagnosisMinOrderByAggregateInputObjectSchema } from './CaseSummaryDiagnosisMinOrderByAggregateInput.schema'
import { CaseSummaryDiagnosisSumOrderByAggregateInputObjectSchema } from './CaseSummaryDiagnosisSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      diagnosisId: z.lazy(() => SortOrderSchema).optional(),
      caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => CaseSummaryDiagnosisCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => CaseSummaryDiagnosisAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => CaseSummaryDiagnosisMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => CaseSummaryDiagnosisMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => CaseSummaryDiagnosisSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisOrderByWithAggregationInputObjectSchema =
  Schema
