import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CarouselCountOrderByAggregateInputObjectSchema } from './CarouselCountOrderByAggregateInput.schema'
import { CarouselAvgOrderByAggregateInputObjectSchema } from './CarouselAvgOrderByAggregateInput.schema'
import { CarouselMaxOrderByAggregateInputObjectSchema } from './CarouselMaxOrderByAggregateInput.schema'
import { CarouselMinOrderByAggregateInputObjectSchema } from './CarouselMinOrderByAggregateInput.schema'
import { CarouselSumOrderByAggregateInputObjectSchema } from './CarouselSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CarouselCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => CarouselAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => CarouselMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => CarouselMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => CarouselSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const CarouselOrderByWithAggregationInputObjectSchema = Schema
