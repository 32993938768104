import { z } from 'zod'
import { FlashsaleItemWhereInputObjectSchema } from './objects/FlashsaleItemWhereInput.schema'
import { FlashsaleItemOrderByWithAggregationInputObjectSchema } from './objects/FlashsaleItemOrderByWithAggregationInput.schema'
import { FlashsaleItemScalarWhereWithAggregatesInputObjectSchema } from './objects/FlashsaleItemScalarWhereWithAggregatesInput.schema'
import { FlashsaleItemScalarFieldEnumSchema } from './enums/FlashsaleItemScalarFieldEnum.schema'

export const FlashsaleItemGroupBySchema = z.object({
  where: FlashsaleItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    FlashsaleItemOrderByWithAggregationInputObjectSchema,
    FlashsaleItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: FlashsaleItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(FlashsaleItemScalarFieldEnumSchema),
})
