import { z } from 'zod'
import { UsageSchema } from '../enums/Usage.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NullableEnumUsageFieldUpdateOperationsInput> = z
  .object({
    set: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const NullableEnumUsageFieldUpdateOperationsInputObjectSchema = Schema
