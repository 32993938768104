import { z } from 'zod'
import { NotificationCreateNestedManyWithoutSegmentsInputObjectSchema } from './NotificationCreateNestedManyWithoutSegmentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentCreateInput> = z
  .object({
    segmentId: z.string(),
    notifications: z
      .lazy(() => NotificationCreateNestedManyWithoutSegmentsInputObjectSchema)
      .optional(),
  })
  .strict()

export const SegmentCreateInputObjectSchema = Schema
