import { z } from 'zod'
import { RejectReasonWhereUniqueInputObjectSchema } from './objects/RejectReasonWhereUniqueInput.schema'
import { RejectReasonCreateInputObjectSchema } from './objects/RejectReasonCreateInput.schema'
import { RejectReasonUncheckedCreateInputObjectSchema } from './objects/RejectReasonUncheckedCreateInput.schema'
import { RejectReasonUpdateInputObjectSchema } from './objects/RejectReasonUpdateInput.schema'
import { RejectReasonUncheckedUpdateInputObjectSchema } from './objects/RejectReasonUncheckedUpdateInput.schema'

export const RejectReasonUpsertSchema = z.object({
  where: RejectReasonWhereUniqueInputObjectSchema,
  create: z.union([
    RejectReasonCreateInputObjectSchema,
    RejectReasonUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    RejectReasonUpdateInputObjectSchema,
    RejectReasonUncheckedUpdateInputObjectSchema,
  ]),
})
