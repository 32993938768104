import { z } from 'zod'
import { OfficeHourWhereUniqueInputObjectSchema } from './objects/OfficeHourWhereUniqueInput.schema'
import { OfficeHourCreateInputObjectSchema } from './objects/OfficeHourCreateInput.schema'
import { OfficeHourUncheckedCreateInputObjectSchema } from './objects/OfficeHourUncheckedCreateInput.schema'
import { OfficeHourUpdateInputObjectSchema } from './objects/OfficeHourUpdateInput.schema'
import { OfficeHourUncheckedUpdateInputObjectSchema } from './objects/OfficeHourUncheckedUpdateInput.schema'

export const OfficeHourUpsertSchema = z.object({
  where: OfficeHourWhereUniqueInputObjectSchema,
  create: z.union([
    OfficeHourCreateInputObjectSchema,
    OfficeHourUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    OfficeHourUpdateInputObjectSchema,
    OfficeHourUncheckedUpdateInputObjectSchema,
  ]),
})
