import { z } from 'zod'
import { VariantWarehouseWhereInputObjectSchema } from './objects/VariantWarehouseWhereInput.schema'
import { VariantWarehouseOrderByWithAggregationInputObjectSchema } from './objects/VariantWarehouseOrderByWithAggregationInput.schema'
import { VariantWarehouseScalarWhereWithAggregatesInputObjectSchema } from './objects/VariantWarehouseScalarWhereWithAggregatesInput.schema'
import { VariantWarehouseScalarFieldEnumSchema } from './enums/VariantWarehouseScalarFieldEnum.schema'

export const VariantWarehouseGroupBySchema = z.object({
  where: VariantWarehouseWhereInputObjectSchema.optional(),
  orderBy: z.union([
    VariantWarehouseOrderByWithAggregationInputObjectSchema,
    VariantWarehouseOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: VariantWarehouseScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(VariantWarehouseScalarFieldEnumSchema),
})
