import { z } from 'zod'
import { IdentityIdentityIdTypeCompoundUniqueInputObjectSchema } from './IdentityIdentityIdTypeCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    identityId_type: z
      .lazy(() => IdentityIdentityIdTypeCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict()

export const IdentityWhereUniqueInputObjectSchema = Schema
