import { z } from 'zod'
import { ProcedureOrderByWithRelationInputObjectSchema } from './objects/ProcedureOrderByWithRelationInput.schema'
import { ProcedureWhereInputObjectSchema } from './objects/ProcedureWhereInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './objects/ProcedureWhereUniqueInput.schema'
import { ProcedureScalarFieldEnumSchema } from './enums/ProcedureScalarFieldEnum.schema'

export const ProcedureFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedureOrderByWithRelationInputObjectSchema,
      ProcedureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureWhereInputObjectSchema.optional(),
  cursor: ProcedureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureScalarFieldEnumSchema).optional(),
})
