import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    icon: z.literal(true).optional(),
    active: z.literal(true).optional(),
    path: z.literal(true).optional(),
    parentId: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
  })
  .strict()

export const CategoryMinAggregateInputObjectSchema = Schema
