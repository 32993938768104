import { z } from 'zod'
import { OrderNotificationWhereInputObjectSchema } from './objects/OrderNotificationWhereInput.schema'
import { OrderNotificationOrderByWithAggregationInputObjectSchema } from './objects/OrderNotificationOrderByWithAggregationInput.schema'
import { OrderNotificationScalarWhereWithAggregatesInputObjectSchema } from './objects/OrderNotificationScalarWhereWithAggregatesInput.schema'
import { OrderNotificationScalarFieldEnumSchema } from './enums/OrderNotificationScalarFieldEnum.schema'

export const OrderNotificationGroupBySchema = z.object({
  where: OrderNotificationWhereInputObjectSchema.optional(),
  orderBy: z.union([
    OrderNotificationOrderByWithAggregationInputObjectSchema,
    OrderNotificationOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    OrderNotificationScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(OrderNotificationScalarFieldEnumSchema),
})
