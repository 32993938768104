import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BrandBannerCountOrderByAggregateInputObjectSchema } from './BrandBannerCountOrderByAggregateInput.schema'
import { BrandBannerAvgOrderByAggregateInputObjectSchema } from './BrandBannerAvgOrderByAggregateInput.schema'
import { BrandBannerMaxOrderByAggregateInputObjectSchema } from './BrandBannerMaxOrderByAggregateInput.schema'
import { BrandBannerMinOrderByAggregateInputObjectSchema } from './BrandBannerMinOrderByAggregateInput.schema'
import { BrandBannerSumOrderByAggregateInputObjectSchema } from './BrandBannerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => BrandBannerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => BrandBannerAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => BrandBannerMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => BrandBannerMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => BrandBannerSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const BrandBannerOrderByWithAggregationInputObjectSchema = Schema
