import { z } from 'zod'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NullableEnumSymptomDurationUnitFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => SymptomDurationUnitSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumSymptomDurationUnitFieldUpdateOperationsInputObjectSchema =
  Schema
