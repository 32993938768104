import { z } from 'zod'
import { ProductPictureOrderByWithRelationInputObjectSchema } from './objects/ProductPictureOrderByWithRelationInput.schema'
import { ProductPictureWhereInputObjectSchema } from './objects/ProductPictureWhereInput.schema'
import { ProductPictureWhereUniqueInputObjectSchema } from './objects/ProductPictureWhereUniqueInput.schema'
import { ProductPictureCountAggregateInputObjectSchema } from './objects/ProductPictureCountAggregateInput.schema'
import { ProductPictureMinAggregateInputObjectSchema } from './objects/ProductPictureMinAggregateInput.schema'
import { ProductPictureMaxAggregateInputObjectSchema } from './objects/ProductPictureMaxAggregateInput.schema'
import { ProductPictureAvgAggregateInputObjectSchema } from './objects/ProductPictureAvgAggregateInput.schema'
import { ProductPictureSumAggregateInputObjectSchema } from './objects/ProductPictureSumAggregateInput.schema'

export const ProductPictureAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductPictureOrderByWithRelationInputObjectSchema,
      ProductPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductPictureWhereInputObjectSchema.optional(),
  cursor: ProductPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductPictureCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductPictureMinAggregateInputObjectSchema.optional(),
  _max: ProductPictureMaxAggregateInputObjectSchema.optional(),
  _avg: ProductPictureAvgAggregateInputObjectSchema.optional(),
  _sum: ProductPictureSumAggregateInputObjectSchema.optional(),
})
