import { z } from 'zod'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { NestedEnumAppointmentTypeFilterObjectSchema } from './NestedEnumAppointmentTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumAppointmentTypeFilter> = z
  .object({
    equals: z.lazy(() => AppointmentTypeSchema).optional(),
    in: z
      .lazy(() => AppointmentTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => AppointmentTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => AppointmentTypeSchema),
        z.lazy(() => NestedEnumAppointmentTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumAppointmentTypeFilterObjectSchema = Schema
