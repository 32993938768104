import { z } from 'zod'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'
import { HealthInfoCreateWithoutPatientInputObjectSchema } from './HealthInfoCreateWithoutPatientInput.schema'
import { HealthInfoUncheckedCreateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoCreateOrConnectWithoutPatientInput> = z
  .object({
    where: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => HealthInfoCreateWithoutPatientInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const HealthInfoCreateOrConnectWithoutPatientInputObjectSchema = Schema
