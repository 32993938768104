import { Box, Button } from '@mui/material'
import { AppointmentStatusSchema } from '@ssch-backend/teleconsults'
import { useEffect } from 'react'
import { ReferenceInput, useListContext, useLocaleState } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomAutoCompleteInput,
  CustomDateInput,
  CustomSelectInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import { getAppointmentStatusLabel } from '../../../enumLabel'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const [locale] = useLocaleState()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      doctorId: undefined,
      patientId: undefined,
      phone: undefined,
      appointmentFrom: undefined,
      appointmentTo: undefined,
      status: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.appointmentFrom
    delete newFilter.appointmentTo
    delete newFilter.status

    if (values.appointmentFrom) {
      newFilter.appointmentAt_DATE_gte = new Date(
        values.appointmentFrom as string,
      )
    }
    if (values.appointmentTo) {
      newFilter.appointmentAt_DATE_lte = new Date(
        values.appointmentTo as string,
      )
    }
    if (values.status) {
      newFilter.status_equals = values.status
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }
  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          doctorId: { formKey: 'doctorId' },
          patientId: { formKey: 'patientId' },
          phone: { formKey: 'phone' },
          appointmentAt_DATE_gte: { formKey: 'appointmentFrom' },
          appointmentAt_DATE_lte: { formKey: 'appointmentTo' },
          status: { formKey: 'status' },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterName = (searchText: string) => ({
    OR: [{ firstname: searchText }, { lastname: searchText }],
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <ReferenceInput
            source="doctorId"
            reference="doctor"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <CustomAutoCompleteInput
              optionText={(choice: {
                prefix: string | null
                firstname: string
                lastname: string
              }) =>
                `${choice.prefix || ''} ${choice.firstname} ${choice.lastname}`
              }
              label="Doctor name"
              helperText={false}
              filterToQuery={filterName}
              sx={{ '& legend': { maxWidth: '100% !important' }, flexGrow: 1 }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="patientId"
            reference="patient"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <CustomAutoCompleteInput
              optionText={(choice: {
                prefix: string | null
                firstname: string
                lastname: string
              }) => `${choice.firstname} ${choice.lastname}`}
              label="Patient name"
              helperText={false}
              filterToQuery={filterName}
              sx={{ '& legend': { maxWidth: '100% !important' }, flexGrow: 1 }}
            />
          </ReferenceInput>
          <CustomTextInput
            resettable
            helperText={false}
            source="phone"
            label="เบอร์โทรศัพท์"
          />
          <CustomDateInput
            source="appointmentFrom"
            label="Appointment from"
            helperText={false}
          />
          <CustomDateInput
            source="appointmentTo"
            label="Appointment to"
            helperText={false}
          />
          <CustomSelectInput
            source="status"
            label="Status"
            helperText={false}
            sx={{ mt: 0.5 }}
            choices={Object.values(AppointmentStatusSchema.Enum).map(
              (option) => ({
                id: option,
                name: getAppointmentStatusLabel(
                  option as keyof typeof AppointmentStatusSchema.Enum,
                )[locale === 'th' ? 'th' : 'en'],
              }),
            )}
          />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
