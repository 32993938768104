import { z } from 'zod'
import { ProcedureOrderWhereInputObjectSchema } from './ProcedureOrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderListRelationFilter> = z
  .object({
    every: z.lazy(() => ProcedureOrderWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProcedureOrderWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProcedureOrderWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureOrderListRelationFilterObjectSchema = Schema
