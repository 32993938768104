import { z } from 'zod'
import { ArticlePictureOrderByWithRelationInputObjectSchema } from './objects/ArticlePictureOrderByWithRelationInput.schema'
import { ArticlePictureWhereInputObjectSchema } from './objects/ArticlePictureWhereInput.schema'
import { ArticlePictureWhereUniqueInputObjectSchema } from './objects/ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureCountAggregateInputObjectSchema } from './objects/ArticlePictureCountAggregateInput.schema'
import { ArticlePictureMinAggregateInputObjectSchema } from './objects/ArticlePictureMinAggregateInput.schema'
import { ArticlePictureMaxAggregateInputObjectSchema } from './objects/ArticlePictureMaxAggregateInput.schema'
import { ArticlePictureAvgAggregateInputObjectSchema } from './objects/ArticlePictureAvgAggregateInput.schema'
import { ArticlePictureSumAggregateInputObjectSchema } from './objects/ArticlePictureSumAggregateInput.schema'

export const ArticlePictureAggregateSchema = z.object({
  orderBy: z
    .union([
      ArticlePictureOrderByWithRelationInputObjectSchema,
      ArticlePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ArticlePictureWhereInputObjectSchema.optional(),
  cursor: ArticlePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ArticlePictureCountAggregateInputObjectSchema])
    .optional(),
  _min: ArticlePictureMinAggregateInputObjectSchema.optional(),
  _max: ArticlePictureMaxAggregateInputObjectSchema.optional(),
  _avg: ArticlePictureAvgAggregateInputObjectSchema.optional(),
  _sum: ArticlePictureSumAggregateInputObjectSchema.optional(),
})
