import { z } from 'zod'
import { MyReviewSelectObjectSchema } from './MyReviewSelect.schema'
import { MyReviewIncludeObjectSchema } from './MyReviewInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewArgs> = z
  .object({
    select: z.lazy(() => MyReviewSelectObjectSchema).optional(),
    include: z.lazy(() => MyReviewIncludeObjectSchema).optional(),
  })
  .strict()

export const MyReviewArgsObjectSchema = Schema
