import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisMaxOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    codePlain: z.lazy(() => SortOrderSchema).optional(),
    term: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const DiagnosisMaxOrderByAggregateInputObjectSchema = Schema
