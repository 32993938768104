import { z } from 'zod'
import { ConditionSettingWhereUniqueInputObjectSchema } from './ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingUpdateWithoutGroupInputObjectSchema } from './ConditionSettingUpdateWithoutGroupInput.schema'
import { ConditionSettingUncheckedUpdateWithoutGroupInputObjectSchema } from './ConditionSettingUncheckedUpdateWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingUpdateWithWhereUniqueWithoutGroupInput> =
  z
    .object({
      where: z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ConditionSettingUpdateWithoutGroupInputObjectSchema),
        z.lazy(
          () => ConditionSettingUncheckedUpdateWithoutGroupInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ConditionSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema =
  Schema
