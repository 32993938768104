import { z } from 'zod'
import { SegmentCreateInputObjectSchema } from './objects/SegmentCreateInput.schema'
import { SegmentUncheckedCreateInputObjectSchema } from './objects/SegmentUncheckedCreateInput.schema'

export const SegmentCreateOneSchema = z.object({
  data: z.union([
    SegmentCreateInputObjectSchema,
    SegmentUncheckedCreateInputObjectSchema,
  ]),
})
