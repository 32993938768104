import * as z from "zod"
import { CompleteProcedureReview, RelatedProcedureReviewModel } from "./index"

export const ProcedureReviewPictureModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  url: z.string(),
  procedureReviewId: z.number().int(),
})

export interface CompleteProcedureReviewPicture extends z.infer<typeof ProcedureReviewPictureModel> {
  procedureReview: CompleteProcedureReview
}

/**
 * RelatedProcedureReviewPictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureReviewPictureModel: z.ZodSchema<CompleteProcedureReviewPicture> = z.lazy(() => ProcedureReviewPictureModel.extend({
  procedureReview: RelatedProcedureReviewModel,
}))
