import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { useAsync } from '../../components/useAsync'
import { adminGatewayClient, isTRPCClientError } from '../../service'

export const ActiveButton = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()

  const { isLoading, execute } = useAsync(async () => {
    try {
      if (!record.active) {
        await adminGatewayClient.waitingBanner.setActive.mutate({
          id: Number(record.id),
          rank: 0,
        })
      } else {
        await adminGatewayClient.waitingBanner.setInactive.mutate({
          id: Number(record.id),
        })
      }
    } catch (e) {
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
    refresh()
  }, false)

  return (
    <Button
      label={record.active ? 'Inactive' : 'Active'}
      onClick={(e) => {
        e.stopPropagation()
        execute()
      }}
      variant="contained"
      disabled={isLoading}
    >
      {record.active ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </Button>
  )
}
