import { z } from 'zod'
import { ProductReviewSummaryWhereInputObjectSchema } from './ProductReviewSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryRelationFilter> = z
  .object({
    is: z
      .lazy(() => ProductReviewSummaryWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => ProductReviewSummaryWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const ProductReviewSummaryRelationFilterObjectSchema = Schema
