import { Apple, Email, Facebook, Google } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { MuiTelInput } from 'mui-tel-input'
import { useEffect, useState } from 'react'
import {
  DateInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomSelectInput,
  CustomTextInput,
} from '../../components/CustomInput'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ImageStorageInput } from '../../components/ImageStorageInput'
import { adminGatewayClient } from '../../service'
import { transformPatient } from './transform'

const PatientEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="patient" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}
interface IUnpaidOrder {
  id: number
  status: string
  patientId: number
  orderType: string
  isPaid: boolean
}
const PatientEditForm = () => {
  const { watch, setValue } = useFormContext()
  const record = useRecordContext()
  const translate = useTranslate()
  const [isUnpaidCart, setIsUnpaidCart] = useState(false)
  const [isWaitingRoomExisted, setIsWaitingRoomExisted] = useState(false)
  const [isWaitToSummarize, setIsWaitToSummarize] = useState(false)
  const [unpaidOrder, setUnpaidOrder] = useState<IUnpaidOrder | null>(null)
  useEffect(() => {
    if (record.id) {
      adminGatewayClient.teleconsult.checkUnpaidTeleconsult
        .query({ patientId: Number(record.id) })
        .then((res) => {
          const {
            isUnpaidCart,
            isWaitingRoomExisted,
            isWaitToSummarize,
            unpaidOrder,
          } = res
          setIsUnpaidCart(isUnpaidCart)
          setIsWaitingRoomExisted(isWaitingRoomExisted)
          setIsWaitToSummarize(isWaitToSummarize)
          setUnpaidOrder(unpaidOrder)
        })
        .catch((err: { message?: string }) => {
          console.log('Error checkUnpaidTeleconsult: ' + (err.message ?? ''))
        })
    }
  }, [record.id])
  const getIdentityIdByType = (type: string): string => {
    let identityId = null
    if (record?.identities) {
      for (const data of record.identities) {
        if (data.type === type) {
          identityId = data.identityId
          break
        }
      }
    }
    return identityId
  }

  const [phoneNumber, setPhoneNumber] = useState<string>(
    getIdentityIdByType('PHONE'),
  )
  const identities = watch('identities')

  useEffect(() => {
    if (identities && identities.length) {
      setPhoneNumber(getIdentityIdByType('PHONE'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identities])

  const handlePhoneNumberChange = (number: string) => {
    setPhoneNumber(number)
    setValue('phoneNumber', number.replace(/\s/g, ''))
  }

  const noDataMessage = 'ไม่มีข้อมูล'

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate('resources.patient.title.general')}
      </Typography>
      <Grid container spacing={2} sx={{ padding: '0 1rem' }}>
        <Grid item xs={12} lg={5}>
          <ImageStorageInput
            source="picture"
            label="รูปคนไข้"
            accept={{ 'image/*': [] }}
            upload={async (file, { onProgress, abortController }) => {
              const uploadUrlMeta =
                await adminGatewayClient.patient.generateUploadPictureUrl.query(
                  {
                    extension: file.name.split('.').pop() ?? '',
                  },
                )
              await axios.request({
                method: 'PUT',
                url: uploadUrlMeta.uploadUrl,
                headers: {
                  'content-type': file.type,
                },
                data: file,
                onUploadProgress: (progressEvent) => {
                  onProgress(progressEvent.loaded / progressEvent.total)
                },
                signal: abortController.signal,
              })
              return uploadUrlMeta
            }}
          ></ImageStorageInput>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
              flexDirection: 'column',
            }}
          >
            <CustomTextInput source="firstname" />
            <CustomTextInput source="lastname" />
            <Divider sx={{ mb: 2.5 }} />
            <CustomSelectInput
              source="gender"
              choices={[
                { id: 'MALE', name: 'ชาย' },
                { id: 'FEMALE', name: 'หญิง' },
              ]}
              validate={required()}
              sx={{ margin: '0' }}
            />
            <CustomTextInput source="nickname" />
            <DateInput source="birthDate" />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3 }} />
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate('resources.patient.title.identity')}
      </Typography>
      <Grid container sx={{ padding: '0 1rem' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <MuiTelInput
            label={translate('resources.patient.fields.phoneNumber')}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            margin="none"
          />
          <Chip
            icon={<Email />}
            label={getIdentityIdByType('EMAIL') || noDataMessage}
            variant="outlined"
          />
          <Chip
            icon={<Facebook />}
            label={getIdentityIdByType('FACEBOOK') || noDataMessage}
            variant="outlined"
          />
          <Chip
            icon={<Google />}
            label={getIdentityIdByType('GOOGLE') || noDataMessage}
            variant="outlined"
          />
          <Chip
            icon={<Apple />}
            label={getIdentityIdByType('APPLE') || noDataMessage}
            variant="outlined"
          />
        </Box>
      </Grid>
      <Divider sx={{ width: '100%', my: 3 }} />
      <Typography variant="h5" sx={{ mb: 2 }}>
        รายการค้างจ่าย Teleconsult
      </Typography>
      <Grid container sx={{ padding: '0 1rem' }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Chip
            label={`กำลังพบแพทย์: ${isWaitingRoomExisted ? 'ใช่' : 'ไม่ใช่'}`}
            color={isWaitingRoomExisted ? 'error' : 'default'}
            variant="outlined"
          />
          <Chip
            label={`กำลังรอหมอสรุปการรักษา: ${
              isWaitToSummarize ? 'ใช่' : 'ไม่ใช่'
            }`}
            color={isWaitToSummarize ? 'error' : 'default'}
            variant="outlined"
          />
          <Chip
            label={`ตะกร้าสินค้า: ${
              isUnpaidCart ? 'ค้างชำระค่าหมอ' : 'ไม่พบรายการ'
            }`}
            color={isUnpaidCart ? 'error' : 'default'}
            variant="outlined"
          />
          <Chip
            label={`ออเดอร์ค้างชำระ: ${
              unpaidOrder
                ? `ID: ${unpaidOrder.id} Status: ${unpaidOrder?.status}}`
                : 'ไม่พบรายการ'
            }`}
            color={unpaidOrder ? 'error' : 'default'}
            variant="outlined"
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography>
            * กำลังรอหมอสรุปการรักษา: ติดต่อหมอเพื่อสรุปผลการรักษาให้คนไข้
          </Typography>
          <Typography>
            * ตะกร้าสินค้า: ให้คนไข้ไปเลือกของที่หมอสั่งให้ ตรงแถบชำระเงินในหน้า
            Home
          </Typography>
          <Typography>
            * ออเดอร์ค้างชำระ: ให้คนไข้ตรวจสอบออเดอร์ในหน้ารายการสั่งซื้อ
            (กดจากรูปคนที่แถบเมนูด้านล่าง)
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

export const PatientEdit = () => {
  const notify = useNotify()
  const navigate = useNavigate()

  const onSuccess = () => {
    notify('ra.notification.updated', {
      messageArgs: { smart_count: 1 },
    })

    navigate(-1)
  }

  return (
    <EditSaveRouteBack
      mutationMode="pessimistic"
      transform={transformPatient}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<PatientEditToolbar />}>
        <PatientEditForm />
      </SimpleForm>
    </EditSaveRouteBack>
  )
}
