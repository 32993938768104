import { z } from 'zod'
import { CoinTransactionWhereUniqueInputObjectSchema } from './CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionUpdateWithoutPatientInputObjectSchema } from './CoinTransactionUpdateWithoutPatientInput.schema'
import { CoinTransactionUncheckedUpdateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedUpdateWithoutPatientInput.schema'
import { CoinTransactionCreateWithoutPatientInputObjectSchema } from './CoinTransactionCreateWithoutPatientInput.schema'
import { CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUpsertWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => CoinTransactionUpdateWithoutPatientInputObjectSchema),
        z.lazy(
          () => CoinTransactionUncheckedUpdateWithoutPatientInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema),
        z.lazy(
          () => CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoinTransactionUpsertWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
