import { z } from 'zod'

export const OrderStatusSchema = z.enum([
  'PENDING_REVIEW',
  'AWAITING_PAYMENT',
  'PAYMENT_IN_PROGRESS',
  'PAYMENT_PENDING',
  'PAYMENT_SUCCESS',
  'PAYMENT_FAILED',
  'SENT_TO_DRUGSTORE',
  'ON_DELIVERY',
  'COMPLETED',
  'CANCELED',
  'REFUNDED',
])
