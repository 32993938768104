import UploadFileIcon from '@mui/icons-material/UploadFile'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  styled,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'
import {
  CommonInputProps,
  FileInputClasses,
  InputHelperText,
  Labeled,
  LabeledProps,
  sanitizeInputRestProps,
  shallowEqual,
  useInput,
} from 'react-admin'
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from 'react-dropzone'
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop'
import short from 'short-uuid'
import { FileStorageInputPreviewContainer } from './FileStorageInputPreviewContainer'
import { FileStoragePreview } from './FileStoragePreview'
import { Lightbox, useLightbox } from './Lightbox/Lightbox'
import { LightboxVideo, useLightboxVideo } from './Lightbox/LightboxVideo'
import {
  FileValue,
  ProgressState,
  ProgressStatus,
  UplodedFileField,
} from './types'

interface FileStorageInputProps extends CommonInputProps {
  accept?: DropzoneOptions['accept']
  maxSize?: DropzoneOptions['maxSize']
  minSize?: DropzoneOptions['minSize']
  options?: DropzoneOptions
  children?: ReactNode
  labelMultiple?: string
  labelSingle?: string
  fileType?: 'IMAGE' | 'VIDEO'
  upload: (
    file: File,
    ctx: {
      onProgress: (progress: number) => void
      abortController: AbortController
    },
  ) => Promise<UplodedFileField | [UplodedFileField, UplodedFileField]>
  disabled?: boolean
}

type MultipleAndCropType = (
  | { multiple: true; canCrop?: false }
  | { multiple?: false; canCrop?: boolean }
) & { cropOption?: { aspect?: number; circularCrop?: boolean } }

export type FileStorageInputWithMultipleAndCropProps = FileStorageInputProps &
  MultipleAndCropType

type FileForm = FileValue[] | FileValue | null

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0',
    borderBottom: '2px solid #aaaaaa',
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(1)}`,
  },
}))

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 80,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export const FileStorageInput: FC<FileStorageInputWithMultipleAndCropProps> = (
  props,
) => {
  const {
    accept,
    helperText,
    maxSize,
    minSize,
    multiple = false,
    label,
    options = {},
    resource,
    source,
    validate,
    fileType,
    upload,
    disabled = false,
    canCrop,
    cropOption = { aspect: 1, circularCrop: false },
    ...rest
  } = props
  const { onDrop: onDropProp } = options

  const imgCropRef = useRef<HTMLImageElement>(null)
  const [imgCropSrc, setImgCropSrc] = useState('')
  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false)
  const [crop, setCrop] = useState<Crop | undefined>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [tempDropProps, setTempDropProps] = useState<{
    event: DropEvent
  } | null>(null)

  const onInputImageForCroppingLoad = (
    e: React.SyntheticEvent<HTMLImageElement>,
  ) => {
    const { width, height } = e.currentTarget
    const defaultCrop = centerAspectCrop(width, height, cropOption.aspect ?? 1)
    setCrop(defaultCrop)
  }

  const getCroppedImageFile = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cropData = completedCrop!
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const image = imgCropRef!.current!

    return new Promise<Blob>((resolve, reject) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (ctx) {
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio
        // const pixelRatio = 1

        canvas.width = Math.floor(cropData.width * scaleX * pixelRatio)
        canvas.height = Math.floor(cropData.height * scaleY * pixelRatio)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'

        const cropX = cropData.x * scaleX
        const cropY = cropData.y * scaleY

        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2

        ctx.save()

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 3) Rotate around the origin
        ctx.rotate(0)
        // 2) Scale the image
        ctx.scale(1, 1)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)

        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
        )

        ctx.restore()

        // return the canvas image as a blob
        ctx.canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          } else {
            reject('Blob is null')
          }
        })
      }
    })
  }

  const handleCloseImageCropper = async (reason: string) => {
    if (reason === 'save' && completedCrop && imgCropRef?.current) {
      const croppedImgBlob = await getCroppedImageFile()
      const croppedImgFile = new File([croppedImgBlob], 'cropped-img.png', {
        type: 'image/png',
      })

      cropAndUpload(
        [croppedImgFile],
        [],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tempDropProps!.event,
      )

      setImgCropSrc('')
      setIsImageCropperOpen(false)
      setTempDropProps(null)
    } else if (reason === 'cancel') {
      setImgCropSrc('')
      setIsImageCropperOpen(false)
    }
  }

  const transformFile = (file: File | string | FileValue): FileValue => {
    if (file instanceof File) {
      const transformedFile = {
        rawFile: file,
        src: URL.createObjectURL(file),
        title: file.name,
      }
      return transformedFile
    }
    if (typeof file === 'string') {
      const url = new URL(file)
      const name = url.pathname.split('/').pop() ?? 'unknown'
      const transformedFile = {
        src: file,
        title: name,
      }

      return transformedFile
    }

    return file
  }

  const transformFiles = (files: File | string | FileValue[]) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const _validate = validate
    ? Array.isArray(validate)
      ? validate
      : [validate]
    : []
  const fileValidator = (value: FileForm) => {
    if (
      value &&
      typeof value === 'object' &&
      (Array.isArray(value) ? value : [value]).some((file) =>
        file.id
          ? uploadProgress[file.id]?.status !== ProgressStatus.success
          : false,
      )
    ) {
      return 'ra.validation.file_invalid'
    }
    return null
  }

  const {
    id,
    field: { onChange: _onChange, value: _value },
    fieldState,
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    format: transformFiles,
    parse: transformFiles,
    source,
    validate: [fileValidator, ..._validate],
    ...rest,
  })
  const { isTouched, error, invalid } = fieldState
  const onChange: (files: FileForm) => void = _onChange
  const value: FileForm = _value
  const files: FileValue[] = value
    ? Array.isArray(value)
      ? value
      : [value]
    : []

  // add state for upload progress
  const [uploadProgress, setUploadProgress] = useState<ProgressState>({})
  useEffect(() => {
    return () => {
      // abort all upload request
      Object.values(uploadProgress).forEach((progress) => {
        if (progress.status === ProgressStatus.loading) {
          progress.abortController.abort()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrop = (
    newFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ) => {
    if (canCrop && !multiple && fileType === 'IMAGE') {
      const imageInput = document.getElementById(id) as HTMLInputElement
      if (imageInput.files && imageInput.files.length > 0) {
        setCrop(undefined)
        setTempDropProps({ event })
        setIsImageCropperOpen(true)

        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setImgCropSrc(reader.result?.toString() || '')
        })

        reader.readAsDataURL(imageInput.files[0])
      }
    } else {
      cropAndUpload(newFiles, rejectedFiles, event)
    }
  }

  const cropAndUpload = (
    newFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ) => {
    const uploadFile = (file: File) => {
      const id = short.generate()
      const abortController = new AbortController()
      setUploadProgress((prev) => ({
        ...prev,
        [id]: {
          progress: null,
          status: ProgressStatus.loading,
          abortController,
        },
      }))
      // upload file here
      const uploadPromise = upload(file, {
        onProgress: (progress) => {
          setUploadProgress((prev) => ({
            ...prev,
            [id]: { ...prev[id], progress: progress },
          }))
        },
        abortController,
      })
        .then((res) => {
          setUploadProgress((prev) => ({
            ...prev,
            [id]: { ...prev[id], status: ProgressStatus.success },
          }))
          return res
        })
        .catch(() => {
          setUploadProgress((prev) => ({
            ...prev,
            [id]: { ...prev[id], status: ProgressStatus.error },
          }))
          return null
        })

      return {
        id,
        rawFile: file,
        type: file.type,
        src: URL.createObjectURL(file),
        title: file.name,
        uploadPromise,
      }
    }
    if (multiple) {
      onChange([...files, ...newFiles.map(uploadFile)])
    } else {
      onChange(uploadFile(newFiles[0]))
    }

    if (onDropProp) {
      onDropProp(newFiles, rejectedFiles, event)
    }
  }

  const onRemove = (file: FileValue) => async () => {
    const id = file.id
    if (id) {
      // delete progress state
      setUploadProgress((prev) => {
        const { [id]: removeProgress, ...rest } = prev
        removeProgress.abortController.abort()
        return rest
      })
    }
    if (file.rawFile) {
      URL.revokeObjectURL(file.src)
    }
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file),
      )
      onChange(filteredFiles)
    } else {
      onChange(null)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    ...options,
    onDrop,
  })

  const { open, index, openLightbox, closeLightbox } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    fileType === 'IMAGE' ? useLightbox() : useLightboxVideo()

  const customSanitizeInput = (defaultSanitizedInput: {
    labelSingle: unknown
    labelMultiple: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): any => {
    const { labelSingle, labelMultiple, ...rest } = defaultSanitizedInput
    return rest
  }

  return (
    <StyledLabeled
      htmlFor={id}
      label={label}
      className={clsx('ra-input', `ra-input-${source}`)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      color={(isTouched || isSubmitted) && invalid && 'error'}
      disabled={disabled}
      {...customSanitizeInput(sanitizeInputRestProps(rest))}
    >
      <>
        {disabled ? null : (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', pt: 3 }}>
            <Button
              {...getRootProps({ 'data-testid': 'dropzone' })}
              type="button"
              sx={{
                px: 1,
                backgroundColor: '#5bd1c7',
                borderRadius: '10px',
                color: 'white',
                ':hover': { backgroundColor: '#5bd1c7' },
              }}
              onClick={() => {
                document.getElementById(id)?.click()
              }}
            >
              <UploadFileIcon />
              <input
                id={id}
                name={id}
                {...getInputProps()}
                onClick={(event: MouseEvent<HTMLInputElement>) => {
                  const { target } = event
                  if (target) {
                    ;(target as HTMLInputElement).value = ''
                  }
                }}
              />
              <Typography sx={{ ml: 1 }}>
                {fileType === 'IMAGE' ? 'แนบรูปภาพ' : 'แนบวิดีโอ'}
              </Typography>
            </Button>
          </Box>
        )}

        <FormHelperText error={(isTouched || isSubmitted) && invalid}>
          <InputHelperText
            touched={isTouched || isSubmitted}
            error={error?.message}
            helperText={helperText}
          />
        </FormHelperText>

        <BootstrapDialog open={isImageCropperOpen}>
          {!!imgCropSrc && (
            <Box
              sx={{
                display: 'flex',
                borderBottom: '1px solid #aaaaaa',
                justifyContent: 'center',
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                circularCrop={cropOption.circularCrop ?? false}
                aspect={cropOption.aspect ?? 1}
              >
                <img
                  ref={imgCropRef}
                  src={imgCropSrc}
                  alt="cropping"
                  onLoad={onInputImageForCroppingLoad}
                />
              </ReactCrop>
            </Box>
          )}

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleCloseImageCropper('cancel')
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleCloseImageCropper('save')
              }}
            >
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>

        <div className="previews">
          {files.map((file, index) => (
            <FileStorageInputPreviewContainer
              key={`previews-${index}`}
              onRemove={onRemove(file)}
              className={FileInputClasses.removeButton}
              progress={file.id ? uploadProgress[file.id] : undefined}
            >
              <FileStoragePreview
                file={file}
                fileType={fileType}
                onClick={() => openLightbox(index)}
              ></FileStoragePreview>
            </FileStorageInputPreviewContainer>
          ))}
          {fileType === 'IMAGE' ? (
            <Lightbox
              open={open}
              close={closeLightbox}
              index={index}
              slides={files.map((file) => ({
                src: file.src,
                alt: file.title || new URL(file.src).pathname.split('/').pop(),
              }))}
              animation={{
                fade: 0,
                swipe: 0,
              }}
            />
          ) : null}
          {fileType === 'VIDEO' ? (
            <LightboxVideo
              open={open}
              close={closeLightbox}
              index={index}
              slides={files.map((file) => ({
                type: 'video',
                sources: [
                  {
                    src: file.src,
                    type: file.rawFile
                      ? file.rawFile.type
                      : file.type ?? 'video/mp4',
                  },
                ],
                alt: file.title || new URL(file.src).pathname.split('/').pop(),
              }))}
              animation={{
                fade: 0,
                swipe: 0,
              }}
            />
          ) : null}
        </div>
      </>
    </StyledLabeled>
  )
}

interface StyledLabeledProps extends LabeledProps {
  disabled?: boolean
}

const PREFIX = 'RaFileInput'

const StyledLabeled = styled(Labeled, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})<StyledLabeledProps>(({ theme, disabled }) => {
  return {
    width: '100%',
    '& .previews': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    [`& .${FileInputClasses.dropZone}`]: {
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      fontFamily: theme.typography.fontFamily,
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: disabled
        ? theme.palette.action.disabled
        : theme.palette.getContrastText(theme.palette.background.default),
    },
    [`& .${FileInputClasses.removeButton}`]: {
      position: 'relative',
      '& button': {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        minWidth: theme.spacing(2),
        opacity: 0,
        pointerEvents: disabled ? 'none' : 'auto',
      },
      '&:hover button': {
        opacity: disabled ? 0 : 1,
      },
    },
  }
})
