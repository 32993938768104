import { z } from 'zod'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaCreateWithoutShippingProviderInput.schema'
import { ExpressShippingAreaUncheckedCreateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUncheckedCreateWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaCreateOrConnectWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () =>
            ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema,
        ),
        z.lazy(
          () =>
            ExpressShippingAreaUncheckedCreateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ExpressShippingAreaCreateOrConnectWithoutShippingProviderInputObjectSchema =
  Schema
