import { z } from 'zod'
import { AcceptedConsentCreateNestedManyWithoutConsentInputObjectSchema } from './AcceptedConsentCreateNestedManyWithoutConsentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCreateInput> = z
  .object({
    key: z.string(),
    detail: z.string(),
    rejectDetail: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    acceptedConsents: z
      .lazy(
        () => AcceptedConsentCreateNestedManyWithoutConsentInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ConsentCreateInputObjectSchema = Schema
