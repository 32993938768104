import { z } from 'zod'
import { CaseCreateWithoutCaseSummaryInputObjectSchema } from './CaseCreateWithoutCaseSummaryInput.schema'
import { CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedCreateWithoutCaseSummaryInput.schema'
import { CaseCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './CaseCreateOrConnectWithoutCaseSummaryInput.schema'
import { CaseUpsertWithoutCaseSummaryInputObjectSchema } from './CaseUpsertWithoutCaseSummaryInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseUpdateWithoutCaseSummaryInputObjectSchema } from './CaseUpdateWithoutCaseSummaryInput.schema'
import { CaseUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedUpdateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutCaseSummaryInputObjectSchema),
          z.lazy(() => CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseCreateOrConnectWithoutCaseSummaryInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CaseUpsertWithoutCaseSummaryInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CaseUpdateWithoutCaseSummaryInputObjectSchema),
          z.lazy(() => CaseUncheckedUpdateWithoutCaseSummaryInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const CaseUpdateOneRequiredWithoutCaseSummaryNestedInputObjectSchema =
  Schema
