import { z } from 'zod'
import { KycChannelSchema } from '../enums/KycChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumKycChannelFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => KycChannelSchema).optional(),
  })
  .strict()

export const EnumKycChannelFieldUpdateOperationsInputObjectSchema = Schema
