import { z } from 'zod'
import { PartnerUpdateWithoutCoverPicturesInputObjectSchema } from './PartnerUpdateWithoutCoverPicturesInput.schema'
import { PartnerUncheckedUpdateWithoutCoverPicturesInputObjectSchema } from './PartnerUncheckedUpdateWithoutCoverPicturesInput.schema'
import { PartnerCreateWithoutCoverPicturesInputObjectSchema } from './PartnerCreateWithoutCoverPicturesInput.schema'
import { PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema } from './PartnerUncheckedCreateWithoutCoverPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpsertWithoutCoverPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => PartnerUpdateWithoutCoverPicturesInputObjectSchema),
      z.lazy(() => PartnerUncheckedUpdateWithoutCoverPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutCoverPicturesInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerUpsertWithoutCoverPicturesInputObjectSchema = Schema
