import { z } from 'zod'
import { CouponGroupSelectObjectSchema } from './CouponGroupSelect.schema'
import { CouponGroupIncludeObjectSchema } from './CouponGroupInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupArgs> = z
  .object({
    select: z.lazy(() => CouponGroupSelectObjectSchema).optional(),
    include: z.lazy(() => CouponGroupIncludeObjectSchema).optional(),
  })
  .strict()

export const CouponGroupArgsObjectSchema = Schema
