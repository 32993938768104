import { z } from 'zod'

export const ProductItemOriginalScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'sku',
  'variantId',
  'nameTr',
  'picture',
  'descriptionTr',
  'drugType',
  'quantity',
  'tagPrice',
  'sellingPrice',
  'sellingUnit',
  'cost',
  'flashSaleId',
  'orderId',
  'v2DrugItemId',
  'canExpress',
  'warehouse',
])
