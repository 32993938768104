import { z } from 'zod'

export const SkuScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'sku',
  'variantId',
  'cost',
  'active',
  'partnerId',
])
