import { z } from 'zod'
import { RefundSelectObjectSchema } from './RefundSelect.schema'
import { RefundIncludeObjectSchema } from './RefundInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundArgs> = z
  .object({
    select: z.lazy(() => RefundSelectObjectSchema).optional(),
    include: z.lazy(() => RefundIncludeObjectSchema).optional(),
  })
  .strict()

export const RefundArgsObjectSchema = Schema
