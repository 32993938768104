import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithoutProcedureInputObjectSchema } from './FlashsaleItemUpdateWithoutProcedureInput.schema'
import { FlashsaleItemUncheckedUpdateWithoutProcedureInputObjectSchema } from './FlashsaleItemUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => FlashsaleItemUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
