import { z } from 'zod'
import { CaseCreateWithoutAppointmentInputObjectSchema } from './CaseCreateWithoutAppointmentInput.schema'
import { CaseUncheckedCreateWithoutAppointmentInputObjectSchema } from './CaseUncheckedCreateWithoutAppointmentInput.schema'
import { CaseCreateOrConnectWithoutAppointmentInputObjectSchema } from './CaseCreateOrConnectWithoutAppointmentInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutAppointmentInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutAppointmentInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutAppointmentInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutAppointmentInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CaseCreateNestedOneWithoutAppointmentInputObjectSchema = Schema
