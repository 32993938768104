import { z } from 'zod'
import { IdentityUpdateInputObjectSchema } from './objects/IdentityUpdateInput.schema'
import { IdentityUncheckedUpdateInputObjectSchema } from './objects/IdentityUncheckedUpdateInput.schema'
import { IdentityWhereUniqueInputObjectSchema } from './objects/IdentityWhereUniqueInput.schema'

export const IdentityUpdateOneSchema = z.object({
  data: z.union([
    IdentityUpdateInputObjectSchema,
    IdentityUncheckedUpdateInputObjectSchema,
  ]),
  where: IdentityWhereUniqueInputObjectSchema,
})
