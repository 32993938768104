import { z } from 'zod'
import { CaseSummaryDiagnosisSelectObjectSchema } from './CaseSummaryDiagnosisSelect.schema'
import { CaseSummaryDiagnosisIncludeObjectSchema } from './CaseSummaryDiagnosisInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisArgs> = z
  .object({
    select: z.lazy(() => CaseSummaryDiagnosisSelectObjectSchema).optional(),
    include: z.lazy(() => CaseSummaryDiagnosisIncludeObjectSchema).optional(),
  })
  .strict()

export const CaseSummaryDiagnosisArgsObjectSchema = Schema
