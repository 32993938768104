import { z } from 'zod'
import { SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema } from './objects/SuggestedProductItemOriginalOrderByWithRelationInput.schema'
import { SuggestedProductItemOriginalWhereInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereInput.schema'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalCountAggregateInputObjectSchema } from './objects/SuggestedProductItemOriginalCountAggregateInput.schema'
import { SuggestedProductItemOriginalMinAggregateInputObjectSchema } from './objects/SuggestedProductItemOriginalMinAggregateInput.schema'
import { SuggestedProductItemOriginalMaxAggregateInputObjectSchema } from './objects/SuggestedProductItemOriginalMaxAggregateInput.schema'
import { SuggestedProductItemOriginalAvgAggregateInputObjectSchema } from './objects/SuggestedProductItemOriginalAvgAggregateInput.schema'
import { SuggestedProductItemOriginalSumAggregateInputObjectSchema } from './objects/SuggestedProductItemOriginalSumAggregateInput.schema'

export const SuggestedProductItemOriginalAggregateSchema = z.object({
  orderBy: z
    .union([
      SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema,
      SuggestedProductItemOriginalOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProductItemOriginalWhereInputObjectSchema.optional(),
  cursor: SuggestedProductItemOriginalWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      SuggestedProductItemOriginalCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: SuggestedProductItemOriginalMinAggregateInputObjectSchema.optional(),
  _max: SuggestedProductItemOriginalMaxAggregateInputObjectSchema.optional(),
  _avg: SuggestedProductItemOriginalAvgAggregateInputObjectSchema.optional(),
  _sum: SuggestedProductItemOriginalSumAggregateInputObjectSchema.optional(),
})
