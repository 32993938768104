import { z } from 'zod'
import { ShippingProviderOrderByWithRelationInputObjectSchema } from './objects/ShippingProviderOrderByWithRelationInput.schema'
import { ShippingProviderWhereInputObjectSchema } from './objects/ShippingProviderWhereInput.schema'
import { ShippingProviderWhereUniqueInputObjectSchema } from './objects/ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderCountAggregateInputObjectSchema } from './objects/ShippingProviderCountAggregateInput.schema'
import { ShippingProviderMinAggregateInputObjectSchema } from './objects/ShippingProviderMinAggregateInput.schema'
import { ShippingProviderMaxAggregateInputObjectSchema } from './objects/ShippingProviderMaxAggregateInput.schema'
import { ShippingProviderAvgAggregateInputObjectSchema } from './objects/ShippingProviderAvgAggregateInput.schema'
import { ShippingProviderSumAggregateInputObjectSchema } from './objects/ShippingProviderSumAggregateInput.schema'

export const ShippingProviderAggregateSchema = z.object({
  orderBy: z
    .union([
      ShippingProviderOrderByWithRelationInputObjectSchema,
      ShippingProviderOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ShippingProviderWhereInputObjectSchema.optional(),
  cursor: ShippingProviderWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ShippingProviderCountAggregateInputObjectSchema])
    .optional(),
  _min: ShippingProviderMinAggregateInputObjectSchema.optional(),
  _max: ShippingProviderMaxAggregateInputObjectSchema.optional(),
  _avg: ShippingProviderAvgAggregateInputObjectSchema.optional(),
  _sum: ShippingProviderSumAggregateInputObjectSchema.optional(),
})
