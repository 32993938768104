import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutCaseSummaryInputObjectSchema } from './CaseCreateWithoutCaseSummaryInput.schema'
import { CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutCaseSummaryInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutCaseSummaryInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutCaseSummaryInputObjectSchema = Schema
