import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { BrandBannerUncheckedUpdateManyWithoutBrandNestedInputObjectSchema } from './BrandBannerUncheckedUpdateManyWithoutBrandNestedInput.schema'
import { ProductUncheckedUpdateManyWithoutBrandNestedInputObjectSchema } from './ProductUncheckedUpdateManyWithoutBrandNestedInput.schema'
import { ProductReviewUncheckedUpdateManyWithoutBrandNestedInputObjectSchema } from './ProductReviewUncheckedUpdateManyWithoutBrandNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUncheckedUpdateWithoutProductReviewSummariesInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      name: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isShow: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      coporateName: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      countryName: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      logo: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      priority: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      banners: z
        .lazy(
          () =>
            BrandBannerUncheckedUpdateManyWithoutBrandNestedInputObjectSchema,
        )
        .optional(),
      products: z
        .lazy(
          () => ProductUncheckedUpdateManyWithoutBrandNestedInputObjectSchema,
        )
        .optional(),
      productReviews: z
        .lazy(
          () =>
            ProductReviewUncheckedUpdateManyWithoutBrandNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const BrandUncheckedUpdateWithoutProductReviewSummariesInputObjectSchema =
  Schema
