import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUncheckedCreateWithoutProcedureInput> =
  z
    .object({
      id: z.number().optional(),
      categoryId: z.number(),
      priority: z.number().optional(),
    })
    .strict()

export const ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema =
  Schema
