import { z } from 'zod'
import { CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUncheckedCreateNestedManyWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUncheckedCreateNestedManyWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedCreateNestedManyWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    caseId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    presentIllness: z.string().optional().nullable(),
    peNote: z.string().optional().nullable(),
    isTeleMedNotRecommend: z.boolean(),
    recommendation: z.string(),
    doctorFee: z.number(),
    doctorFeeDiscount: z.number(),
    commissionRate: z.number().optional(),
    commissionPrice: z.number().optional(),
    netDoctorFee: z.number().optional(),
    doctorNote: z.string().optional().nullable(),
    pharmacistNote: z.string().optional().nullable(),
    followUpAppointmentId: z.number().optional().nullable(),
    recommedNumberOfTreatment: z.number().optional().nullable(),
    additionalSuggestedProcedure: z.string().optional().nullable(),
    editedSuggestedProductItemAt: z.date().optional().nullable(),
    editedSuggestedProductItemBy: z.number().optional().nullable(),
    v2CaseSummaryId: z.number().optional().nullable(),
    caseUrl: z.string().optional().nullable(),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProductItems: z
      .lazy(
        () =>
          SuggestedProductItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(
        () =>
          SuggestedProductItemOriginalUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProcedureItems: z
      .lazy(
        () =>
          SuggestedProcedureItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryUncheckedCreateInputObjectSchema = Schema
