import * as z from "zod"
import { CompletePatient, RelatedPatientModel, CompleteNotification, RelatedNotificationModel } from "./index"

export const NotificationPatientModel = z.object({
  patientId: z.number().int(),
  notificationId: z.number().int(),
  readAt: z.date().nullish(),
})

export interface CompleteNotificationPatient extends z.infer<typeof NotificationPatientModel> {
  patient: CompletePatient
  notification: CompleteNotification
}

/**
 * RelatedNotificationPatientModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedNotificationPatientModel: z.ZodSchema<CompleteNotificationPatient> = z.lazy(() => NotificationPatientModel.extend({
  patient: RelatedPatientModel,
  notification: RelatedNotificationModel,
}))
