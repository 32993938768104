import { z } from 'zod'
import { GenderSchema } from '../enums/Gender.schema'
import { NestedEnumGenderFilterObjectSchema } from './NestedEnumGenderFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumGenderFilter> = z
  .object({
    equals: z.lazy(() => GenderSchema).optional(),
    in: z
      .lazy(() => GenderSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => GenderSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => GenderSchema),
        z.lazy(() => NestedEnumGenderFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumGenderFilterObjectSchema = Schema
