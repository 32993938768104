import { z } from 'zod'
import { TeleconsultCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateOrConnectWithoutMissedCallCaseInput.schema'
import { TeleconsultCreateManyMissedCallCaseInputEnvelopeObjectSchema } from './TeleconsultCreateManyMissedCallCaseInputEnvelope.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUncheckedCreateNestedManyWithoutMissedCallCaseInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema),
          z
            .lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => TeleconsultCreateManyMissedCallCaseInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
          z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const TeleconsultUncheckedCreateNestedManyWithoutMissedCallCaseInputObjectSchema =
  Schema
