import { z } from 'zod'
import { AddressSelectObjectSchema } from './AddressSelect.schema'
import { AddressIncludeObjectSchema } from './AddressInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressArgs> = z
  .object({
    select: z.lazy(() => AddressSelectObjectSchema).optional(),
    include: z.lazy(() => AddressIncludeObjectSchema).optional(),
  })
  .strict()

export const AddressArgsObjectSchema = Schema
