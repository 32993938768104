import { z } from 'zod'
import { CoinTransactionWhereUniqueInputObjectSchema } from './objects/CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionCreateInputObjectSchema } from './objects/CoinTransactionCreateInput.schema'
import { CoinTransactionUncheckedCreateInputObjectSchema } from './objects/CoinTransactionUncheckedCreateInput.schema'
import { CoinTransactionUpdateInputObjectSchema } from './objects/CoinTransactionUpdateInput.schema'
import { CoinTransactionUncheckedUpdateInputObjectSchema } from './objects/CoinTransactionUncheckedUpdateInput.schema'

export const CoinTransactionUpsertSchema = z.object({
  where: CoinTransactionWhereUniqueInputObjectSchema,
  create: z.union([
    CoinTransactionCreateInputObjectSchema,
    CoinTransactionUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CoinTransactionUpdateInputObjectSchema,
    CoinTransactionUncheckedUpdateInputObjectSchema,
  ]),
})
