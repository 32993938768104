import { z } from 'zod'
import { ConditionSettingCreateInputObjectSchema } from './objects/ConditionSettingCreateInput.schema'
import { ConditionSettingUncheckedCreateInputObjectSchema } from './objects/ConditionSettingUncheckedCreateInput.schema'

export const ConditionSettingCreateOneSchema = z.object({
  data: z.union([
    ConditionSettingCreateInputObjectSchema,
    ConditionSettingUncheckedCreateInputObjectSchema,
  ]),
})
