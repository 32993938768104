import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCountOutputTypeSelect> = z
  .object({
    variantWarehouses: z.boolean().optional(),
  })
  .strict()

export const WarehouseCountOutputTypeSelectObjectSchema = Schema
