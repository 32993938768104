import { z } from 'zod'
import { DiscountSettingWhereUniqueInputObjectSchema } from './DiscountSettingWhereUniqueInput.schema'
import { DiscountSettingUpdateWithoutGroupInputObjectSchema } from './DiscountSettingUpdateWithoutGroupInput.schema'
import { DiscountSettingUncheckedUpdateWithoutGroupInputObjectSchema } from './DiscountSettingUncheckedUpdateWithoutGroupInput.schema'
import { DiscountSettingCreateWithoutGroupInputObjectSchema } from './DiscountSettingCreateWithoutGroupInput.schema'
import { DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema } from './DiscountSettingUncheckedCreateWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingUpsertWithWhereUniqueWithoutGroupInput> =
  z
    .object({
      where: z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => DiscountSettingUpdateWithoutGroupInputObjectSchema),
        z.lazy(
          () => DiscountSettingUncheckedUpdateWithoutGroupInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => DiscountSettingCreateWithoutGroupInputObjectSchema),
        z.lazy(
          () => DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DiscountSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema =
  Schema
