import { z } from 'zod'
import { KycDataUpdateWithoutRejectReasonInputObjectSchema } from './KycDataUpdateWithoutRejectReasonInput.schema'
import { KycDataUncheckedUpdateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedUpdateWithoutRejectReasonInput.schema'
import { KycDataCreateWithoutRejectReasonInputObjectSchema } from './KycDataCreateWithoutRejectReasonInput.schema'
import { KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedCreateWithoutRejectReasonInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataUpsertWithoutRejectReasonInput> = z
  .object({
    update: z.union([
      z.lazy(() => KycDataUpdateWithoutRejectReasonInputObjectSchema),
      z.lazy(() => KycDataUncheckedUpdateWithoutRejectReasonInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => KycDataCreateWithoutRejectReasonInputObjectSchema),
      z.lazy(() => KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema),
    ]),
  })
  .strict()

export const KycDataUpsertWithoutRejectReasonInputObjectSchema = Schema
