import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'
import { PaymentStatusSchema } from '../enums/PaymentStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentCreateManyOrderInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    transactionId: z.string().optional().nullable(),
    amount: z.number(),
    currency: z.string(),
    paymentAt: z.date().optional().nullable(),
    paymentGateway: z
      .lazy(() => PaymentGatewaySchema)
      .optional()
      .nullable(),
    paymentMethod: z.lazy(() => PaymentMethodSchema),
    installmentTerm: z.number().optional().nullable(),
    status: z.lazy(() => PaymentStatusSchema).optional(),
    confirmedBy: z.number().optional().nullable(),
    evidencePicture: z.string().optional().nullable(),
    reminderNote: z.string().optional().nullable(),
  })
  .strict()

export const PaymentCreateManyOrderInputObjectSchema = Schema
