import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NotificationUncheckedUpdateManyWithoutSegmentsNestedInputObjectSchema } from './NotificationUncheckedUpdateManyWithoutSegmentsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUncheckedUpdateInput> = z
  .object({
    id: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    segmentId: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    notifications: z
      .lazy(
        () =>
          NotificationUncheckedUpdateManyWithoutSegmentsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const SegmentUncheckedUpdateInputObjectSchema = Schema
