import { z } from 'zod'
import { ConditionSettingScalarWhereInputObjectSchema } from './ConditionSettingScalarWhereInput.schema'
import { ConditionSettingUpdateManyMutationInputObjectSchema } from './ConditionSettingUpdateManyMutationInput.schema'
import { ConditionSettingUncheckedUpdateManyWithoutConditionSettingInputObjectSchema } from './ConditionSettingUncheckedUpdateManyWithoutConditionSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingUpdateManyWithWhereWithoutGroupInput> =
  z
    .object({
      where: z.lazy(() => ConditionSettingScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ConditionSettingUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ConditionSettingUncheckedUpdateManyWithoutConditionSettingInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ConditionSettingUpdateManyWithWhereWithoutGroupInputObjectSchema =
  Schema
