import { z } from 'zod'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductCreateWithoutVariantsInputObjectSchema } from './ProductCreateWithoutVariantsInput.schema'
import { ProductUncheckedCreateWithoutVariantsInputObjectSchema } from './ProductUncheckedCreateWithoutVariantsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateOrConnectWithoutVariantsInput> = z
  .object({
    where: z.lazy(() => ProductWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductCreateWithoutVariantsInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutVariantsInputObjectSchema),
    ]),
  })
  .strict()

export const ProductCreateOrConnectWithoutVariantsInputObjectSchema = Schema
