import { z } from 'zod'
import { ProcedureUsageStatusSchema } from '../enums/ProcedureUsageStatus.schema'
import { NestedEnumProcedureUsageStatusWithAggregatesFilterObjectSchema } from './NestedEnumProcedureUsageStatusWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumProcedureUsageStatusFilterObjectSchema } from './NestedEnumProcedureUsageStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.EnumProcedureUsageStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ProcedureUsageStatusSchema).optional(),
    in: z
      .lazy(() => ProcedureUsageStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ProcedureUsageStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ProcedureUsageStatusSchema),
        z.lazy(
          () => NestedEnumProcedureUsageStatusWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z
      .lazy(() => NestedEnumProcedureUsageStatusFilterObjectSchema)
      .optional(),
    _max: z
      .lazy(() => NestedEnumProcedureUsageStatusFilterObjectSchema)
      .optional(),
  })
  .strict()

export const EnumProcedureUsageStatusWithAggregatesFilterObjectSchema = Schema
