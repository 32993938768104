import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    sku: z.string(),
    variantId: z.number(),
    cost: z.number(),
    active: z.boolean(),
    partnerId: z.number(),
  })
  .strict()

export const SkuCreateManyInputObjectSchema = Schema
