import { z } from 'zod'
import { PartnerCreateWithoutUsersInputObjectSchema } from './PartnerCreateWithoutUsersInput.schema'
import { PartnerUncheckedCreateWithoutUsersInputObjectSchema } from './PartnerUncheckedCreateWithoutUsersInput.schema'
import { PartnerCreateOrConnectWithoutUsersInputObjectSchema } from './PartnerCreateOrConnectWithoutUsersInput.schema'
import { PartnerUpsertWithoutUsersInputObjectSchema } from './PartnerUpsertWithoutUsersInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerUpdateWithoutUsersInputObjectSchema } from './PartnerUpdateWithoutUsersInput.schema'
import { PartnerUncheckedUpdateWithoutUsersInputObjectSchema } from './PartnerUncheckedUpdateWithoutUsersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutUsersNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PartnerCreateWithoutUsersInputObjectSchema),
          z.lazy(() => PartnerUncheckedCreateWithoutUsersInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PartnerCreateOrConnectWithoutUsersInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PartnerUpsertWithoutUsersInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PartnerUpdateWithoutUsersInputObjectSchema),
          z.lazy(() => PartnerUncheckedUpdateWithoutUsersInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PartnerUpdateOneRequiredWithoutUsersNestedInputObjectSchema =
  Schema
