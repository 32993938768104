import { z } from 'zod'

export const ProcedureOrderScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'voucherNumber',
  'orderId',
  'orderNumber',
  'procedureId',
  'procedureItemId',
  'procedureNameTr',
  'patientId',
  'patientName',
  'patientTelNo',
  'paymentMethod',
  'installmentTerm',
  'couponCode',
  'sellingPrice',
  'flashsalePrice',
  'cost',
  'priceAbsorbedBySkinX',
  'priceAbsorbedByPartner',
  'commission',
  'commissionRate',
  'vat',
  'vatRate',
  'netIncome',
  'status',
  'usedAt',
  'expireAt',
  'note',
  'partnerId',
  'v2StoreVoucherId',
])
