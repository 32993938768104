import { z } from 'zod'
import { OrderCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsOriginalInput.schema'
import { OrderCreateOrConnectWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateOrConnectWithoutProductItemsOriginalInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutProductItemsOriginalInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutProductItemsOriginalInputObjectSchema),
          z.lazy(
            () =>
              OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            OrderCreateOrConnectWithoutProductItemsOriginalInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const OrderCreateNestedOneWithoutProductItemsOriginalInputObjectSchema =
  Schema
