import { z } from 'zod'

export const AppointmentScalarFieldEnumSchema = z.enum([
  'id',
  'doctorId',
  'patientId',
  'type',
  'status',
  'caseId',
  'scheduleId',
  'appointmentAt',
  'cancelAt',
  'cancelDetail',
  'teleconsultId',
  'createdAt',
  'updatedAt',
])
