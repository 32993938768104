import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    coin: z.lazy(() => SortOrderSchema).optional(),
    v2PatientId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const PatientSumOrderByAggregateInputObjectSchema = Schema
