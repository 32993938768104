import { Box, Grid, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { TimeView } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { endOfMonth, startOfMonth } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { ReferenceInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomAutoCompleteInput } from '../../../components/CustomInput'
import { FormDivider } from '../../../components/FormInput/divider'
import { adminGatewayClient } from '../../../service'
import { handleFilterFullnameQuery } from '../../../utils'
export const ScheduleDetail: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [shouldDisableTimeList, setShouldDisableTime] = useState<Date[]>([])
  let fetchTimeout: NodeJS.Timeout | null = null
  const { watch, setValue } = useFormContext()
  const availableAt = watch('schedule.availableAt')
  const appointmentAt = watch('appointmentAt')
  const caseId = watch('caseId')
  const doctorId = watch('doctorId')
  const doctorOptionText = (choice: {
    prefix: string
    firstname: string
    lastname: string
  }) => {
    return `${choice.prefix} ${choice.firstname} ${choice.lastname}`
  }

  useEffect(() => {
    if (appointmentAt === null) {
      setValue('appointmentAt', availableAt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAvailableSlot = async (date: Date) => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout)
    }
    fetchTimeout = setTimeout(async () => {
      setIsLoading(true)
      try {
        const onFocusYear = date.getFullYear()
        const onFocusMonth = date.getMonth()
        const response =
          await adminGatewayClient.schedule.getScheduleByDateRange.query({
            doctorId,
            patientId: null,
            from: startOfMonth(new Date(onFocusYear, onFocusMonth, 1)),
            to: endOfMonth(new Date(onFocusYear, onFocusMonth + 1, 0)),
          })
        const _shouldDisableTimeList = []
        for (const slot of response) {
          const appointment = slot.appointment || null
          if (appointment) {
            _shouldDisableTimeList.push(appointment.appointmentAt)
          }
        }
        setShouldDisableTime(_shouldDisableTimeList)
      } catch (error) {
        console.error(error)
        alert(error)
      } finally {
        setIsLoading(false)
      }
    }, 1000)
  }
  const shouldDisableTime = (value: Date, view: TimeView) => {
    return shouldDisableTimeList.some(
      (date) => date.getTime() === value.getTime(),
    )
  }

  const onOpenDatetimePicker = async () => {
    let date = new Date()
    if (availableAt) {
      date = availableAt
    }
    await fetchAvailableSlot(date)
  }

  const handleAppointmentAt = (value: Date | null) => {
    setValue('appointmentAt', value)
    setValue('schedule.appointmentAt', value)
  }

  return (
    <Box sx={{ mb: 3, width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Schedule Detail
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          <Box sx={{ mt: 1 }}>
            <ReferenceInput
              source="doctorId"
              reference="doctor"
              sort={{ field: 'id', order: 'ASC' }}
            >
              <CustomAutoCompleteInput
                optionText={doctorOptionText}
                filterToQuery={handleFilterFullnameQuery}
                label="แพทย์"
                helperText={false}
                disableClearable={false}
                onChange={() => handleAppointmentAt(null)}
                disabled={!caseId}
              />
            </ReferenceInput>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Box sx={{ mt: 1 }}>
            <DateTimePicker
              label="วันนัดหมาย"
              value={appointmentAt}
              minutesStep={60}
              shouldDisableTime={shouldDisableTime}
              onOpen={onOpenDatetimePicker}
              onMonthChange={fetchAvailableSlot}
              onYearChange={fetchAvailableSlot}
              onAccept={handleAppointmentAt}
              timeSteps={{ minutes: 60 }}
              ampm={false}
              disablePast
              format="d MMMM yyyy HH:mm"
              closeOnSelect={false}
              sx={{ width: '100%' }}
              disabled={!caseId || isLoading}
              loading={isLoading}
              renderLoading={() => <CircularProgress />}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
