import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { CaseSummaryDiagnosisListRelationFilterObjectSchema } from './CaseSummaryDiagnosisListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => DiagnosisWhereInputObjectSchema),
        z.lazy(() => DiagnosisWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => DiagnosisWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => DiagnosisWhereInputObjectSchema),
        z.lazy(() => DiagnosisWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    codePlain: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    term: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    caseSummaryDiagnosis: z
      .lazy(() => CaseSummaryDiagnosisListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const DiagnosisWhereInputObjectSchema = Schema
