import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomDateInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      code: null,
      fromDate: null,
      toDate: null,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.code
    delete newFilter.fromDate
    delete newFilter.toDate

    if (values.code) {
      newFilter.code_contains = (values.code as string).trim()
    }
    if (values.fromDate) {
      newFilter.startAt_DATE_gte = new Date(values.fromDate as string)
    }
    if (values.toDate) {
      newFilter.expireAt_DATE_lte = new Date(values.toDate as string)
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }

  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          code_contains: {
            formKey: 'code',
          },
          startAt_DATE_gte: {
            formKey: 'fromDate',
          },
          expireAt_DATE_lte: {
            formKey: 'toDate',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="code"
          />
          <CustomDateInput
            sx={{ flexGrow: 1 }}
            source="fromDate"
            label="เลือกวันที่ (ตั้งแต่)"
            helperText={false}
          />
          <CustomDateInput
            sx={{ flexGrow: 1 }}
            source="toDate"
            label="เลือกวันที่ (จนถึง)"
            helperText={false}
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
