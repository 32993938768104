import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientPatientIdNotificationIdCompoundUniqueInput> =
  z
    .object({
      patientId: z.number(),
      notificationId: z.number(),
    })
    .strict()

export const NotificationPatientPatientIdNotificationIdCompoundUniqueInputObjectSchema =
  Schema
