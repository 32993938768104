import { z } from 'zod'
import { ProductBannerWhereUniqueInputObjectSchema } from './objects/ProductBannerWhereUniqueInput.schema'
import { ProductBannerCreateInputObjectSchema } from './objects/ProductBannerCreateInput.schema'
import { ProductBannerUncheckedCreateInputObjectSchema } from './objects/ProductBannerUncheckedCreateInput.schema'
import { ProductBannerUpdateInputObjectSchema } from './objects/ProductBannerUpdateInput.schema'
import { ProductBannerUncheckedUpdateInputObjectSchema } from './objects/ProductBannerUncheckedUpdateInput.schema'

export const ProductBannerUpsertSchema = z.object({
  where: ProductBannerWhereUniqueInputObjectSchema,
  create: z.union([
    ProductBannerCreateInputObjectSchema,
    ProductBannerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductBannerUpdateInputObjectSchema,
    ProductBannerUncheckedUpdateInputObjectSchema,
  ]),
})
