import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryCreateWithoutProductItemsInputObjectSchema } from './DeliveryCreateWithoutProductItemsInput.schema'
import { DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutProductItemsInput> =
  z
    .object({
      where: z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => DeliveryCreateWithoutProductItemsInputObjectSchema),
        z.lazy(
          () => DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DeliveryCreateOrConnectWithoutProductItemsInputObjectSchema =
  Schema
