import { z } from 'zod'
import { WeekdaySchema } from '../enums/Weekday.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    weekday: z.lazy(() => WeekdaySchema),
    openAt: z.string(),
    closeAt: z.string(),
    timezone: z.string(),
    branchId: z.number(),
  })
  .strict()

export const OfficeHourUncheckedCreateInputObjectSchema = Schema
