import { z } from 'zod'
import { CaseUpdateWithoutCaseSummaryInputObjectSchema } from './CaseUpdateWithoutCaseSummaryInput.schema'
import { CaseUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedUpdateWithoutCaseSummaryInput.schema'
import { CaseCreateWithoutCaseSummaryInputObjectSchema } from './CaseCreateWithoutCaseSummaryInput.schema'
import { CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutCaseSummaryInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutCaseSummaryInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutCaseSummaryInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutCaseSummaryInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutCaseSummaryInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutCaseSummaryInputObjectSchema = Schema
