import { z } from 'zod'
import { FlashsaleWhereInputObjectSchema } from './objects/FlashsaleWhereInput.schema'
import { FlashsaleOrderByWithAggregationInputObjectSchema } from './objects/FlashsaleOrderByWithAggregationInput.schema'
import { FlashsaleScalarWhereWithAggregatesInputObjectSchema } from './objects/FlashsaleScalarWhereWithAggregatesInput.schema'
import { FlashsaleScalarFieldEnumSchema } from './enums/FlashsaleScalarFieldEnum.schema'

export const FlashsaleGroupBySchema = z.object({
  where: FlashsaleWhereInputObjectSchema.optional(),
  orderBy: z.union([
    FlashsaleOrderByWithAggregationInputObjectSchema,
    FlashsaleOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: FlashsaleScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(FlashsaleScalarFieldEnumSchema),
})
