import { ContentCopy, OpenInNew } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { FC, useState } from 'react'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  Pagination,
  TextField,
  useNotify,
} from 'react-admin'
import { CustomButton } from '../../components/CustomButton'
import { CustomBooleanField } from '../../components/CustomField'
import { ProductImportModal } from './importProduct/importModal'
import { FilterListForm } from './list-section/filterlistForm'

const rowsPerPage = [5, 10, 20]

const ProductListAction: FC<{
  onOpenImportModal: (state: boolean) => void
}> = ({ onOpenImportModal }) => {
  const handleImportClick = () => {
    onOpenImportModal(true)
  }

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
        <CustomButton
          onClick={handleImportClick}
          // label="resources.product.action.import_button"
        />
      </Box>
    </Box>
  )
}

const renderBrand = (record: { brand: { name: string } }) => {
  return <span>{record.brand.name}</span>
}

const renderCategory = (record: {
  categories: { id: number; nameTr: { en: string; th: string } }[]
}) => {
  return (
    <div>
      {record.categories?.map((o) => (
        <p key={`cat-${o.id}`} style={{ margin: '0.25rem 0' }}>
          {o.nameTr?.th ?? ''} {o.nameTr?.en ? `/ ${o.nameTr.en}` : ''}
        </p>
      )) ?? []}
    </div>
  )
}

export const ProductList = () => {
  const notify = useNotify()
  const [openImportModal, setOpenImportModal] = useState(false)

  const handleOpenImportModal = (state: boolean) => {
    setOpenImportModal(state)
  }

  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={
          <ProductListAction onOpenImportModal={handleOpenImportModal} />
        }
      >
        <Datagrid size="small" rowClick="edit">
          <TextField source="id" />
          <FunctionField label="ชื่อแบรนด์" render={renderBrand} />
          <TextField source="nameTr.th" label="ชื่อสินค้า" />
          <FunctionField label="หมวดหมู่" render={renderCategory} />
          <CustomBooleanField source="active" label="เปิดใช้งาน" />
          <FunctionField
            label="Action"
            render={(record: { id: number }) => (
              <>
                <IconButton
                  onClick={() => {
                    const appPatientDomain = import.meta.env
                      .NX_APP_PATIENT_DOMAIN
                    navigator.clipboard
                      .writeText(`${appPatientDomain}/product/${record.id}`)
                      .then(() => {
                        notify(`Copied to clipboard!`, {
                          type: 'success',
                        })
                      })
                      .catch((error) => {
                        console.error('Error copying to clipboard:', error)
                        notify(
                          'Failed to copy to clipboard. Please try again.',
                          {
                            type: 'success',
                          },
                        )
                      })
                  }}
                >
                  <ContentCopy />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(`/product/${record.id}`, '_blank')
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
      <ProductImportModal
        open={openImportModal}
        onClose={() => {
          handleOpenImportModal(false)
        }}
        disableCloseOnBackdrop={true}
      />
    </Box>
  )
}
