import { z } from 'zod'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'
import { KycDataCreateWithoutRejectReasonInputObjectSchema } from './KycDataCreateWithoutRejectReasonInput.schema'
import { KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedCreateWithoutRejectReasonInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCreateOrConnectWithoutRejectReasonInput> =
  z
    .object({
      where: z.lazy(() => KycDataWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => KycDataCreateWithoutRejectReasonInputObjectSchema),
        z.lazy(
          () => KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const KycDataCreateOrConnectWithoutRejectReasonInputObjectSchema = Schema
