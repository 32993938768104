import { Box, Card, CardContent } from '@mui/material'
import { useState } from 'react'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ConditionSettingSection } from './create-section/conditionSetting'
import { CreateCouponSection } from './create-section/createCoupon'
import { DiscountSettingSection } from './create-section/discountSetting'
import { IconSection } from './create-section/icon'
import { transformCreateCouponGroup } from './transform'

const CouponGroupCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="couponGroup" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const CouponGroupCreateForm = () => {
  const [hideSettingSections, setHideSettingSections] = useState<boolean>(false)
  return (
    <SimpleForm toolbar={<CouponGroupCreateToolbar />}>
      <CreateCouponSection setHideSettingSections={setHideSettingSections} />
      <DiscountSettingSection hide={hideSettingSections} />
      {/* Temporary hide ConditionSettingSection as Pisut.T requested*/}
      <ConditionSettingSection hide />
      <IconSection />
    </SimpleForm>
  )
}

export const CouponGroupCreate = () => (
  <CreateSaveRouteBack
    redirect="list"
    sx={{
      alignSelf: 'center',
      paddingBottom: '2rem',
      maxWidth: '900px',
    }}
    transform={transformCreateCouponGroup}
  >
    <Card>
      <CardContent sx={{ p: 2 }}>
        <CouponGroupCreateForm />
      </CardContent>
    </Card>
  </CreateSaveRouteBack>
)
