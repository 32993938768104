import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { ProcedureItemCreateNestedManyWithoutRefundInputObjectSchema } from './ProcedureItemCreateNestedManyWithoutRefundInput.schema'
import { OrderCreateNestedOneWithoutRefundsInputObjectSchema } from './OrderCreateNestedOneWithoutRefundsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCreateWithoutProductItemsInput> = z
  .object({
    amount: z.number(),
    currency: z.string(),
    refundAt: z.date(),
    paymentGateway: z.lazy(() => PaymentGatewaySchema),
    status: z.lazy(() => RefundStatusSchema),
    reason: z.string(),
    procedureItems: z
      .lazy(() => ProcedureItemCreateNestedManyWithoutRefundInputObjectSchema)
      .optional(),
    order: z.lazy(() => OrderCreateNestedOneWithoutRefundsInputObjectSchema),
  })
  .strict()

export const RefundCreateWithoutProductItemsInputObjectSchema = Schema
