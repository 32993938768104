import { z } from 'zod'
import { OrderUpdateWithoutRefundsInputObjectSchema } from './OrderUpdateWithoutRefundsInput.schema'
import { OrderUncheckedUpdateWithoutRefundsInputObjectSchema } from './OrderUncheckedUpdateWithoutRefundsInput.schema'
import { OrderCreateWithoutRefundsInputObjectSchema } from './OrderCreateWithoutRefundsInput.schema'
import { OrderUncheckedCreateWithoutRefundsInputObjectSchema } from './OrderUncheckedCreateWithoutRefundsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutRefundsInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutRefundsInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutRefundsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutRefundsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutRefundsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutRefundsInputObjectSchema = Schema
