import { FC } from 'react'
import { DateFieldProps, FunctionField } from 'react-admin'

export const LocaleDateField: FC<DateFieldProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <FunctionField
      render={(record: any) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const date = record[props.source!] as Date | null
        if (!date) {
          return '-'
        }

        const str = []

        if (props.showDate ?? true) {
          const dateStr = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`
          str.push(dateStr)
        }

        if (props.showTime) {
          const timeStr = `${date.getHours().toString().padStart(2, '0')}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}`
          str.push(timeStr)
        }

        return str.join(' ')
      }}
    />
  )
}
