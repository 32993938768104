import { z } from 'zod'
import { ProcedureReviewSummaryWhereInputObjectSchema } from './objects/ProcedureReviewSummaryWhereInput.schema'
import { ProcedureReviewSummaryOrderByWithAggregationInputObjectSchema } from './objects/ProcedureReviewSummaryOrderByWithAggregationInput.schema'
import { ProcedureReviewSummaryScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureReviewSummaryScalarWhereWithAggregatesInput.schema'
import { ProcedureReviewSummaryScalarFieldEnumSchema } from './enums/ProcedureReviewSummaryScalarFieldEnum.schema'

export const ProcedureReviewSummaryGroupBySchema = z.object({
  where: ProcedureReviewSummaryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureReviewSummaryOrderByWithAggregationInputObjectSchema,
    ProcedureReviewSummaryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProcedureReviewSummaryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureReviewSummaryScalarFieldEnumSchema),
})
