import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { CaseSummaryDiagnosisFindManySchema } from '../findManyCaseSummaryDiagnosis.schema'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'
import { SuggestedProductItemFindManySchema } from '../findManySuggestedProductItem.schema'
import { SuggestedProductItemOriginalFindManySchema } from '../findManySuggestedProductItemOriginal.schema'
import { SuggestedProcedureItemFindManySchema } from '../findManySuggestedProcedureItem.schema'
import { CaseSummaryCountOutputTypeArgsObjectSchema } from './CaseSummaryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryInclude> = z
  .object({
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    caseSummaryDiagnosis: z
      .union([z.boolean(), z.lazy(() => CaseSummaryDiagnosisFindManySchema)])
      .optional(),
    followUpAppointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
    suggestedProductItems: z
      .union([z.boolean(), z.lazy(() => SuggestedProductItemFindManySchema)])
      .optional(),
    suggestedProductItemsOriginal: z
      .union([
        z.boolean(),
        z.lazy(() => SuggestedProductItemOriginalFindManySchema),
      ])
      .optional(),
    suggestedProcedureItems: z
      .union([z.boolean(), z.lazy(() => SuggestedProcedureItemFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CaseSummaryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CaseSummaryIncludeObjectSchema = Schema
