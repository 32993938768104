import { z } from 'zod'
import { ProductCreateWithoutCategoriesInputObjectSchema } from './ProductCreateWithoutCategoriesInput.schema'
import { ProductUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProductUncheckedCreateWithoutCategoriesInput.schema'
import { ProductCreateOrConnectWithoutCategoriesInputObjectSchema } from './ProductCreateOrConnectWithoutCategoriesInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutCategoriesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProductCreateWithoutCategoriesInputObjectSchema),
        z.lazy(() => ProductUncheckedCreateWithoutCategoriesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ProductCreateOrConnectWithoutCategoriesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ProductCreateNestedOneWithoutCategoriesInputObjectSchema = Schema
