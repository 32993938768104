import { z } from 'zod'
import { CouponGroupCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutConditionSettingInput.schema'
import { CouponGroupCreateOrConnectWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateOrConnectWithoutConditionSettingInput.schema'
import { CouponGroupUpsertWithoutConditionSettingInputObjectSchema } from './CouponGroupUpsertWithoutConditionSettingInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'
import { CouponGroupUpdateWithoutConditionSettingInputObjectSchema } from './CouponGroupUpdateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedUpdateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedUpdateWithoutConditionSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupUpdateOneRequiredWithoutConditionSettingNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CouponGroupCreateWithoutConditionSettingInputObjectSchema,
          ),
          z.lazy(
            () =>
              CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CouponGroupCreateOrConnectWithoutConditionSettingInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => CouponGroupUpsertWithoutConditionSettingInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => CouponGroupUpdateWithoutConditionSettingInputObjectSchema,
          ),
          z.lazy(
            () =>
              CouponGroupUncheckedUpdateWithoutConditionSettingInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CouponGroupUpdateOneRequiredWithoutConditionSettingNestedInputObjectSchema =
  Schema
