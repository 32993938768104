import { Grid, SelectChangeEvent, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { adminGatewayClient } from '../../../service'
import { validateHttpsUrl } from '../../../utils'

const getProvince = async () => {
  const province =
    await adminGatewayClient.shippingAddress.getThaiAddressProvince.query()
  return Object.entries(province).map(([key, value]) => value.provinceTr)
}
const getDistrict = async (province: string) => {
  const district =
    await adminGatewayClient.shippingAddress.getThaiAddressDistrictByProvince.query(
      { provinceKey: province },
    )
  return Object.entries(district).map(([key, value]) => value.districtTr)
}
const getSubDistrict = async (province: string, district: string) => {
  const subDistrict =
    await adminGatewayClient.shippingAddress.getThaiAddressSubDistrictByProvinceAndDistrict.query(
      { provinceKey: province, districtKey: district },
    )
  return Object.entries(subDistrict).map(([key, value]) => ({
    ...value.subDistrictTr,
    postCode: value.postCode,
  }))
}

export const BranchAddress = () => {
  const [provinceList, setProvinceList] = useState<
    { th: string; en: string }[]
  >([])
  const [districtList, setDistrictList] = useState<
    { th: string; en: string }[]
  >([])
  const [subDistrictList, setSubDistrictList] = useState<
    { th: string; en: string; postCode: string[] }[]
  >([])
  const [postCodeList, setpostCodeList] = useState<
    { id: string; name: string }[]
  >([])
  const { getValues, setValue } = useFormContext()
  const { ...branch } = getValues()

  const fetchProvince = useCallback(async () => {
    const _provinceList = await getProvince()
    setProvinceList(_provinceList)
  }, [])

  const fetchDistrict = useCallback(async (provinceKey: string) => {
    const _districtList = await getDistrict(provinceKey)
    setDistrictList(_districtList)
  }, [])

  const fetchSubDistrict = useCallback(
    async (provinceKey: string, subDistrictKey: string) => {
      const _subDistrictList = await getSubDistrict(provinceKey, subDistrictKey)
      setSubDistrictList(_subDistrictList)
    },
    [],
  )

  useEffect(() => {
    const fetchData = async () => {
      await fetchProvince()
      if (branch.provinceKey) {
        await fetchDistrict(branch.provinceKey)
        if (branch.districtKey) {
          await fetchSubDistrict(branch.provinceKey, branch.districtKey)
        }
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (branch.subDistrictKey && subDistrictList.length) {
      const _postCode =
        subDistrictList.find((sd) => sd.en === branch.subDistrictKey)
          ?.postCode || []
      setpostCodeList(_postCode.map((p) => ({ id: p, name: p })))
    }
  }, [branch.subDistrictKey, subDistrictList])

  const handleChangeProvince = (event: ChangeEvent<HTMLInputElement>) => {
    const _province = provinceList.find((p) => p.en === event.target.value)
    if (_province) {
      setValue('province', _province.th)
      setValue('provinceKey', _province.en)
      setValue('district', '')
      setValue('districtKey', '')
      setValue('subDistrict', '')
      setValue('subDistrictKey', '')
      setValue('postcode', '')
      fetchDistrict(_province.en)
    }
  }

  const handleChangeDistrict = (event: SelectChangeEvent) => {
    const _district = districtList.find((d) => d.en === event.target.value)
    if (_district) {
      setValue('district', _district.th)
      setValue('districtKey', _district.en)
      setValue('subDistrict', '')
      setValue('subDistrictKey', '')
      setValue('postcode', '')
      fetchSubDistrict(branch.provinceKey, _district.en)
    }
  }

  const handleChangeSubDistrict = (event: SelectChangeEvent) => {
    const _subDistrict = subDistrictList.find(
      (sd) => sd.en === event.target.value,
    )
    if (_subDistrict) {
      setValue('subDistrict', _subDistrict.th)
      setValue('subDistrictKey', _subDistrict.en)
      setValue('postcode', '')
    }
  }

  const handleChangepostCode = (event: SelectChangeEvent) => {
    setValue('postcode', event.target.value)
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลที่อยู่
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextInput
            label="ที่อยู่"
            source="address"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelectInput
            label="จังหวัด"
            choices={provinceList}
            optionText="th"
            optionValue="en"
            source="provinceKey"
            onChange={(e) => {
              handleChangeProvince(e)
            }}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelectInput
            label="อำเภอ/เขต"
            choices={districtList}
            optionText="th"
            optionValue="en"
            source="districtKey"
            onChange={(e) => {
              handleChangeDistrict(e)
            }}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelectInput
            label="ตำบล/แขวง"
            choices={subDistrictList}
            optionText="th"
            optionValue="en"
            source="subDistrictKey"
            onChange={(e) => {
              handleChangeSubDistrict(e)
            }}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSelectInput
            label="รหัสไปรษณีย์"
            choices={postCodeList}
            source="postcode"
            onChange={(e) => {
              handleChangepostCode(e)
            }}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="Location (Google map link)"
            source="googleMapUrl"
            validate={validateHttpsUrl}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormNumberInput source="lat" />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormNumberInput source="long" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="เบอร์ติดต่อ"
            source="phone"
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  )
}
