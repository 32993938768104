import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { CaseSummaryDiagnosisFindManySchema } from '../findManyCaseSummaryDiagnosis.schema'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'
import { SuggestedProductItemFindManySchema } from '../findManySuggestedProductItem.schema'
import { SuggestedProductItemOriginalFindManySchema } from '../findManySuggestedProductItemOriginal.schema'
import { SuggestedProcedureItemFindManySchema } from '../findManySuggestedProcedureItem.schema'
import { CaseSummaryCountOutputTypeArgsObjectSchema } from './CaseSummaryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummarySelect> = z
  .object({
    id: z.boolean().optional(),
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    caseId: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    presentIllness: z.boolean().optional(),
    peNote: z.boolean().optional(),
    caseSummaryDiagnosis: z
      .union([z.boolean(), z.lazy(() => CaseSummaryDiagnosisFindManySchema)])
      .optional(),
    isTeleMedNotRecommend: z.boolean().optional(),
    recommendation: z.boolean().optional(),
    doctorFee: z.boolean().optional(),
    doctorFeeDiscount: z.boolean().optional(),
    commissionRate: z.boolean().optional(),
    commissionPrice: z.boolean().optional(),
    netDoctorFee: z.boolean().optional(),
    doctorNote: z.boolean().optional(),
    pharmacistNote: z.boolean().optional(),
    followUpAppointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
    followUpAppointmentId: z.boolean().optional(),
    recommedNumberOfTreatment: z.boolean().optional(),
    additionalSuggestedProcedure: z.boolean().optional(),
    editedSuggestedProductItemAt: z.boolean().optional(),
    editedSuggestedProductItemBy: z.boolean().optional(),
    suggestedProductItems: z
      .union([z.boolean(), z.lazy(() => SuggestedProductItemFindManySchema)])
      .optional(),
    suggestedProductItemsOriginal: z
      .union([
        z.boolean(),
        z.lazy(() => SuggestedProductItemOriginalFindManySchema),
      ])
      .optional(),
    suggestedProcedureItems: z
      .union([z.boolean(), z.lazy(() => SuggestedProcedureItemFindManySchema)])
      .optional(),
    v2CaseSummaryId: z.boolean().optional(),
    caseUrl: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CaseSummaryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CaseSummarySelectObjectSchema = Schema
