import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DoctorItemCountOrderByAggregateInputObjectSchema } from './DoctorItemCountOrderByAggregateInput.schema'
import { DoctorItemAvgOrderByAggregateInputObjectSchema } from './DoctorItemAvgOrderByAggregateInput.schema'
import { DoctorItemMaxOrderByAggregateInputObjectSchema } from './DoctorItemMaxOrderByAggregateInput.schema'
import { DoctorItemMinOrderByAggregateInputObjectSchema } from './DoctorItemMinOrderByAggregateInput.schema'
import { DoctorItemSumOrderByAggregateInputObjectSchema } from './DoctorItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    doctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    consultingChannel: z.lazy(() => SortOrderSchema).optional(),
    doctorName: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => DoctorItemCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => DoctorItemAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => DoctorItemMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => DoctorItemMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => DoctorItemSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DoctorItemOrderByWithAggregationInputObjectSchema = Schema
