import { z } from 'zod'
import { HealthInfoUpdateInputObjectSchema } from './objects/HealthInfoUpdateInput.schema'
import { HealthInfoUncheckedUpdateInputObjectSchema } from './objects/HealthInfoUncheckedUpdateInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './objects/HealthInfoWhereUniqueInput.schema'

export const HealthInfoUpdateOneSchema = z.object({
  data: z.union([
    HealthInfoUpdateInputObjectSchema,
    HealthInfoUncheckedUpdateInputObjectSchema,
  ]),
  where: HealthInfoWhereUniqueInputObjectSchema,
})
