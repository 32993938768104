import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BrandOrderByWithRelationInputObjectSchema } from './BrandOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    brand: z.lazy(() => BrandOrderByWithRelationInputObjectSchema).optional(),
  })
  .strict()

export const BrandBannerOrderByWithRelationInputObjectSchema = Schema
