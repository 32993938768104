import { z } from 'zod'
import { FlashsaleUpdateWithoutItemsInputObjectSchema } from './FlashsaleUpdateWithoutItemsInput.schema'
import { FlashsaleUncheckedUpdateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedUpdateWithoutItemsInput.schema'
import { FlashsaleCreateWithoutItemsInputObjectSchema } from './FlashsaleCreateWithoutItemsInput.schema'
import { FlashsaleUncheckedCreateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedCreateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleUpsertWithoutItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => FlashsaleUpdateWithoutItemsInputObjectSchema),
      z.lazy(() => FlashsaleUncheckedUpdateWithoutItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => FlashsaleCreateWithoutItemsInputObjectSchema),
      z.lazy(() => FlashsaleUncheckedCreateWithoutItemsInputObjectSchema),
    ]),
  })
  .strict()

export const FlashsaleUpsertWithoutItemsInputObjectSchema = Schema
