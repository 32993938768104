import { Box } from '@mui/material'
import { FC, useState } from 'react'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  Pagination,
  TextField,
} from 'react-admin'
import { CustomButton } from '../../components/CustomButton'
import { CustomBooleanField } from '../../components/CustomField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FlashsaleProductImportModal } from './importFlashsale/importModal'

const FlashsaleProductListActions: FC<{
  onOpenImportModal: (state: boolean) => void
}> = ({ onOpenImportModal }) => {
  const handleImportClick = () => {
    onOpenImportModal(true)
  }

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <CustomButton
          onClick={handleImportClick}
          label="resources.flashsaleProduct.action.import_button"
        />
        <ExportButton />
      </Box>
    </Box>
  )
}

export const FlashsaleProductList = () => {
  const [openImportModal, setOpenImportModal] = useState(false)
  const rowsPerPage = [5, 10, 20, 30, 50]

  const handleOpenImportModal = (state: boolean) => {
    setOpenImportModal(state)
  }

  return (
    <Box>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[4]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={
          <FlashsaleProductListActions
            onOpenImportModal={handleOpenImportModal}
          />
        }
        sort={{ field: 'startAt', order: 'DESC' }}
      >
        <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <LocaleDateField source="startAt" />
          <LocaleDateField source="endAt" />
          <TextField source="name" sortable={false} />
          <TextField source="description" sortable={false} />
          <CustomBooleanField source="active" sortable={false} />
        </Datagrid>
      </List>
      <FlashsaleProductImportModal
        open={openImportModal}
        onClose={() => {
          handleOpenImportModal(false)
        }}
        disableCloseOnBackdrop
      />
    </Box>
  )
}
