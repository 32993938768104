import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    type: z.literal(true).optional(),
    logo: z.literal(true).optional(),
    website: z.literal(true).optional(),
    phone: z.literal(true).optional(),
    active: z.literal(true).optional(),
    isExpertise: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    isSXEasy: z.literal(true).optional(),
    searchField: z.literal(true).optional(),
    taxId: z.literal(true).optional(),
    v2HospitalId: z.literal(true).optional(),
    v2StoreId: z.literal(true).optional(),
  })
  .strict()

export const PartnerMaxAggregateInputObjectSchema = Schema
