import { z } from 'zod'
import { FlashsaleItemOrderByWithRelationInputObjectSchema } from './objects/FlashsaleItemOrderByWithRelationInput.schema'
import { FlashsaleItemWhereInputObjectSchema } from './objects/FlashsaleItemWhereInput.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './objects/FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemScalarFieldEnumSchema } from './enums/FlashsaleItemScalarFieldEnum.schema'

export const FlashsaleItemFindManySchema = z.object({
  orderBy: z
    .union([
      FlashsaleItemOrderByWithRelationInputObjectSchema,
      FlashsaleItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: FlashsaleItemWhereInputObjectSchema.optional(),
  cursor: FlashsaleItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(FlashsaleItemScalarFieldEnumSchema).optional(),
})
