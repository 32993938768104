import * as z from "zod"
import { CompleteFlashsale, RelatedFlashsaleModel, CompleteVariant, RelatedVariantModel } from "./index"

export const FlashsaleItemModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  active: z.boolean(),
  priority: z.number().int(),
  flashsaleId: z.number().int(),
  variantId: z.number().int(),
  startAt: z.date(),
  endAt: z.date(),
  price: z.number(),
  saleLimit: z.number().int(),
  sold: z.number().int(),
})

export interface CompleteFlashsaleItem extends z.infer<typeof FlashsaleItemModel> {
  flashsale: CompleteFlashsale
  variant: CompleteVariant
}

/**
 * RelatedFlashsaleItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedFlashsaleItemModel: z.ZodSchema<CompleteFlashsaleItem> = z.lazy(() => FlashsaleItemModel.extend({
  flashsale: RelatedFlashsaleModel,
  variant: RelatedVariantModel,
}))
