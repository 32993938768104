import { z } from 'zod'
import { VariantPictureWhereInputObjectSchema } from './objects/VariantPictureWhereInput.schema'
import { VariantPictureOrderByWithAggregationInputObjectSchema } from './objects/VariantPictureOrderByWithAggregationInput.schema'
import { VariantPictureScalarWhereWithAggregatesInputObjectSchema } from './objects/VariantPictureScalarWhereWithAggregatesInput.schema'
import { VariantPictureScalarFieldEnumSchema } from './enums/VariantPictureScalarFieldEnum.schema'

export const VariantPictureGroupBySchema = z.object({
  where: VariantPictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    VariantPictureOrderByWithAggregationInputObjectSchema,
    VariantPictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: VariantPictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(VariantPictureScalarFieldEnumSchema),
})
