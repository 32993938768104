import { z } from 'zod'
import { CaseSummaryDiagnosisWhereInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereInput.schema'
import { CaseSummaryDiagnosisOrderByWithAggregationInputObjectSchema } from './objects/CaseSummaryDiagnosisOrderByWithAggregationInput.schema'
import { CaseSummaryDiagnosisScalarWhereWithAggregatesInputObjectSchema } from './objects/CaseSummaryDiagnosisScalarWhereWithAggregatesInput.schema'
import { CaseSummaryDiagnosisScalarFieldEnumSchema } from './enums/CaseSummaryDiagnosisScalarFieldEnum.schema'

export const CaseSummaryDiagnosisGroupBySchema = z.object({
  where: CaseSummaryDiagnosisWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CaseSummaryDiagnosisOrderByWithAggregationInputObjectSchema,
    CaseSummaryDiagnosisOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    CaseSummaryDiagnosisScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CaseSummaryDiagnosisScalarFieldEnumSchema),
})
