import { z } from 'zod'

export const TeleconsultScalarFieldEnumSchema = z.enum([
  'id',
  'doctorId',
  'patientId',
  'videoRoomId',
  'doctorVideoRoomUid',
  'patientVideoRoomUid',
  'startAt',
  'endAt',
  'callTime',
  'status',
  'caseId',
  'missedCallCaseId',
  'remark',
  'createdAt',
  'updatedAt',
])
