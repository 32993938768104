import { z } from 'zod'
import { NotificationPatientScalarWhereInputObjectSchema } from './NotificationPatientScalarWhereInput.schema'
import { NotificationPatientUpdateManyMutationInputObjectSchema } from './NotificationPatientUpdateManyMutationInput.schema'
import { NotificationPatientUncheckedUpdateManyWithoutPatientsInputObjectSchema } from './NotificationPatientUncheckedUpdateManyWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUpdateManyWithWhereWithoutNotificationInput> =
  z
    .object({
      where: z.lazy(() => NotificationPatientScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => NotificationPatientUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            NotificationPatientUncheckedUpdateManyWithoutPatientsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationPatientUpdateManyWithWhereWithoutNotificationInputObjectSchema =
  Schema
