import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumGenderFilterObjectSchema } from './EnumGenderFilter.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumPregnancyStateNullableFilterObjectSchema } from './EnumPregnancyStateNullableFilter.schema'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => HealthInfoWhereInputObjectSchema),
        z.lazy(() => HealthInfoWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => HealthInfoWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => HealthInfoWhereInputObjectSchema),
        z.lazy(() => HealthInfoWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    name: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    gender: z
      .union([
        z.lazy(() => EnumGenderFilterObjectSchema),
        z.lazy(() => GenderSchema),
      ])
      .optional(),
    birthdate: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    drugAllergy: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    medication: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    pregnancyState: z
      .union([
        z.lazy(() => EnumPregnancyStateNullableFilterObjectSchema),
        z.lazy(() => PregnancyStateSchema),
      ])
      .optional()
      .nullable(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const HealthInfoWhereInputObjectSchema = Schema
