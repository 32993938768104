import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BrandCountOrderByAggregateInputObjectSchema } from './BrandCountOrderByAggregateInput.schema'
import { BrandAvgOrderByAggregateInputObjectSchema } from './BrandAvgOrderByAggregateInput.schema'
import { BrandMaxOrderByAggregateInputObjectSchema } from './BrandMaxOrderByAggregateInput.schema'
import { BrandMinOrderByAggregateInputObjectSchema } from './BrandMinOrderByAggregateInput.schema'
import { BrandSumOrderByAggregateInputObjectSchema } from './BrandSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    isShow: z.lazy(() => SortOrderSchema).optional(),
    coporateName: z.lazy(() => SortOrderSchema).optional(),
    countryName: z.lazy(() => SortOrderSchema).optional(),
    logo: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => BrandCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => BrandAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => BrandMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => BrandMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => BrandSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const BrandOrderByWithAggregationInputObjectSchema = Schema
