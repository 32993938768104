import { z } from 'zod'
import { ProductBannerCreateInputObjectSchema } from './objects/ProductBannerCreateInput.schema'
import { ProductBannerUncheckedCreateInputObjectSchema } from './objects/ProductBannerUncheckedCreateInput.schema'

export const ProductBannerCreateOneSchema = z.object({
  data: z.union([
    ProductBannerCreateInputObjectSchema,
    ProductBannerUncheckedCreateInputObjectSchema,
  ]),
})
