import { z } from 'zod'
import { ShippingProviderUpdateWithoutOrdersInputObjectSchema } from './ShippingProviderUpdateWithoutOrdersInput.schema'
import { ShippingProviderUncheckedUpdateWithoutOrdersInputObjectSchema } from './ShippingProviderUncheckedUpdateWithoutOrdersInput.schema'
import { ShippingProviderCreateWithoutOrdersInputObjectSchema } from './ShippingProviderCreateWithoutOrdersInput.schema'
import { ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutOrdersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderUpsertWithoutOrdersInput> = z
  .object({
    update: z.union([
      z.lazy(() => ShippingProviderUpdateWithoutOrdersInputObjectSchema),
      z.lazy(
        () => ShippingProviderUncheckedUpdateWithoutOrdersInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ShippingProviderCreateWithoutOrdersInputObjectSchema),
      z.lazy(
        () => ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ShippingProviderUpsertWithoutOrdersInputObjectSchema = Schema
