import { z } from 'zod'
import { ConditionItemTypeSchema } from '../enums/ConditionItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumConditionItemTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => ConditionItemTypeSchema).optional(),
    })
    .strict()

export const EnumConditionItemTypeFieldUpdateOperationsInputObjectSchema =
  Schema
