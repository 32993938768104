import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUpdateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedUpdateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedUpdateWithoutFlashsaleInput.schema'
import { FlashsaleItemCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => FlashsaleItemUpdateWithoutFlashsaleInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateWithoutFlashsaleInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInputObjectSchema =
  Schema
