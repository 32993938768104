import { FileValue } from '../../components/types'

export const transformPartner = async (form: {
  logo: FileValue
  coverPictures: FileValue[]
}) => {
  const { logo, coverPictures } = form
  const result: { [key: string]: unknown } = form

  if (logo?.uploadPromise) {
    const url = await logo.uploadPromise
    if (url && !Array.isArray(url)) {
      result.logo = {
        ...logo,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload logo failed')
    }
  }
  const formCoverPictures = [] as unknown[]
  if (coverPictures && coverPictures.length) {
    let rank = 1
    for (const pic of coverPictures) {
      delete pic.id
      if (pic.uploadPromise) {
        const url = await pic.uploadPromise
        if (url && !Array.isArray(url)) {
          formCoverPictures.push({
            ...pic,
            rank,
            url: {
              src: pic.src,
              gsPath: url.gsPath,
            },
          })
        } else {
          throw new Error('Upload cover pictures failed')
        }
      } else {
        formCoverPictures.push({ rank, url: pic })
      }
      rank++
    }
  }
  result.coverPictures = formCoverPictures
  return result
}
