import { Box, Grid, Typography } from '@mui/material'
import { BooleanInput, email, required } from 'react-admin'
import { useParams } from 'react-router-dom'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormPasswordInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { ReferencePartner } from '../../../components/Reference/ReferencePartner'
export const UserInfo = () => {
  const { id } = useParams()
  const isEditPage = !!id
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูล Partner
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput source="email" validate={[required(), email()]} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormPasswordInput
            source="password"
            validate={isEditPage ? undefined : required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferencePartner validate={required()} />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BooleanInput source="active" defaultValue={true} />
      </Box>
    </>
  )
}
