import { z } from 'zod'
import { ProductBannerUpdateInputObjectSchema } from './objects/ProductBannerUpdateInput.schema'
import { ProductBannerUncheckedUpdateInputObjectSchema } from './objects/ProductBannerUncheckedUpdateInput.schema'
import { ProductBannerWhereUniqueInputObjectSchema } from './objects/ProductBannerWhereUniqueInput.schema'

export const ProductBannerUpdateOneSchema = z.object({
  data: z.union([
    ProductBannerUpdateInputObjectSchema,
    ProductBannerUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductBannerWhereUniqueInputObjectSchema,
})
