import { z } from 'zod'
import { AcceptedConsentCreateManyConsentInputObjectSchema } from './AcceptedConsentCreateManyConsentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateManyConsentInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => AcceptedConsentCreateManyConsentInputObjectSchema),
        z.lazy(() => AcceptedConsentCreateManyConsentInputObjectSchema).array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const AcceptedConsentCreateManyConsentInputEnvelopeObjectSchema = Schema
