import { ConfirmProvider } from 'material-ui-confirm'
import { ComponentProps, ComponentType } from 'react'
import { Layout as DefaultLayout, LayoutProps } from 'react-admin'
import { SidebarMenu } from '../components/SidebarMenu'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Component = ComponentType<any>

export const combineComponents = (components: Component[]): Component => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: ComponentProps<Component>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => children as JSX.Element,
  )
}

const AppContexts = combineComponents([
  // Add your contexts here
  ConfirmProvider,
])

export const Layout = (props: LayoutProps) => (
  <AppContexts>
    <DefaultLayout {...props} menu={SidebarMenu} />
  </AppContexts>
)
