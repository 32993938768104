import { z } from 'zod'
import { DeliveryLogWhereInputObjectSchema } from './DeliveryLogWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogListRelationFilter> = z
  .object({
    every: z.lazy(() => DeliveryLogWhereInputObjectSchema).optional(),
    some: z.lazy(() => DeliveryLogWhereInputObjectSchema).optional(),
    none: z.lazy(() => DeliveryLogWhereInputObjectSchema).optional(),
  })
  .strict()

export const DeliveryLogListRelationFilterObjectSchema = Schema
