import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.OldPatientAvgAggregateInputType> = z
  .object({
    patientId: z.literal(true).optional(),
  })
  .strict()

export const OldPatientAvgAggregateInputObjectSchema = Schema
