import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { OrderUpdateOneRequiredWithoutDeliveriesNestedInputObjectSchema } from './OrderUpdateOneRequiredWithoutDeliveriesNestedInput.schema'
import { ProductItemUpdateManyWithoutDeliveryNestedInputObjectSchema } from './ProductItemUpdateManyWithoutDeliveryNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateWithoutDeliveryLogsInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    shippingProvider: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    trackingNumber: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    status: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    deliveredAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    partnerName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    order: z
      .lazy(
        () => OrderUpdateOneRequiredWithoutDeliveriesNestedInputObjectSchema,
      )
      .optional(),
    productItems: z
      .lazy(() => ProductItemUpdateManyWithoutDeliveryNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryUpdateWithoutDeliveryLogsInputObjectSchema = Schema
