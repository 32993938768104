import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureOrderByWithRelationInputObjectSchema } from './ProcedureOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryOrderByWithRelationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      procedureId: z.lazy(() => SortOrderSchema).optional(),
      partnerId: z.lazy(() => SortOrderSchema).optional(),
      overallRating: z.lazy(() => SortOrderSchema).optional(),
      environmentRating: z.lazy(() => SortOrderSchema).optional(),
      serviceRating: z.lazy(() => SortOrderSchema).optional(),
      specialtyRating: z.lazy(() => SortOrderSchema).optional(),
      valueRating: z.lazy(() => SortOrderSchema).optional(),
      countForRating5: z.lazy(() => SortOrderSchema).optional(),
      countForRating4: z.lazy(() => SortOrderSchema).optional(),
      countForRating3: z.lazy(() => SortOrderSchema).optional(),
      countForRating2: z.lazy(() => SortOrderSchema).optional(),
      countForRating1: z.lazy(() => SortOrderSchema).optional(),
      procedure: z
        .lazy(() => ProcedureOrderByWithRelationInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewSummaryOrderByWithRelationInputObjectSchema = Schema
