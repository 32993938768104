import { z } from 'zod'
import { BranchScalarWhereInputObjectSchema } from './BranchScalarWhereInput.schema'
import { BranchUpdateManyMutationInputObjectSchema } from './BranchUpdateManyMutationInput.schema'
import { BranchUncheckedUpdateManyWithoutBranchesInputObjectSchema } from './BranchUncheckedUpdateManyWithoutBranchesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpdateManyWithWhereWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => BranchScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => BranchUpdateManyMutationInputObjectSchema),
      z.lazy(() => BranchUncheckedUpdateManyWithoutBranchesInputObjectSchema),
    ]),
  })
  .strict()

export const BranchUpdateManyWithWhereWithoutPartnerInputObjectSchema = Schema
