import { z } from 'zod'
import { WeekdaySchema } from '../enums/Weekday.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.EnumWeekdayFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => WeekdaySchema).optional(),
  })
  .strict()

export const EnumWeekdayFieldUpdateOperationsInputObjectSchema = Schema
