import { z } from 'zod'
import { ProcedureCategoryCreateManyProcedureInputObjectSchema } from './ProcedureCategoryCreateManyProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateManyProcedureInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => ProcedureCategoryCreateManyProcedureInputObjectSchema),
        z
          .lazy(() => ProcedureCategoryCreateManyProcedureInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProcedureCategoryCreateManyProcedureInputEnvelopeObjectSchema =
  Schema
