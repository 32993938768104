import { z } from 'zod'
import { ProcedureItemWhereInputObjectSchema } from './ProcedureItemWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemListRelationFilter> = z
  .object({
    every: z.lazy(() => ProcedureItemWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProcedureItemWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProcedureItemWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureItemListRelationFilterObjectSchema = Schema
