import { z } from 'zod'
import { CaseUpdateWithoutTeleconsultInputObjectSchema } from './CaseUpdateWithoutTeleconsultInput.schema'
import { CaseUncheckedUpdateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedUpdateWithoutTeleconsultInput.schema'
import { CaseCreateWithoutTeleconsultInputObjectSchema } from './CaseCreateWithoutTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutTeleconsultInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutTeleconsultInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutTeleconsultInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutTeleconsultInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutTeleconsultInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutTeleconsultInputObjectSchema = Schema
