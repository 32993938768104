import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'
import { NestedEnumWarehouseCodeWithAggregatesFilterObjectSchema } from './NestedEnumWarehouseCodeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumWarehouseCodeFilterObjectSchema } from './NestedEnumWarehouseCodeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumWarehouseCodeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => WarehouseCodeSchema).optional(),
    in: z
      .lazy(() => WarehouseCodeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => WarehouseCodeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => WarehouseCodeSchema),
        z.lazy(() => NestedEnumWarehouseCodeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumWarehouseCodeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumWarehouseCodeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumWarehouseCodeWithAggregatesFilterObjectSchema = Schema
