import { z } from 'zod'
import { CoinTransactionCreateInputObjectSchema } from './objects/CoinTransactionCreateInput.schema'
import { CoinTransactionUncheckedCreateInputObjectSchema } from './objects/CoinTransactionUncheckedCreateInput.schema'

export const CoinTransactionCreateOneSchema = z.object({
  data: z.union([
    CoinTransactionCreateInputObjectSchema,
    CoinTransactionUncheckedCreateInputObjectSchema,
  ]),
})
