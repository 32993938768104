import { z } from 'zod'
import { IdentityWhereUniqueInputObjectSchema } from './IdentityWhereUniqueInput.schema'
import { IdentityCreateWithoutPatientInputObjectSchema } from './IdentityCreateWithoutPatientInput.schema'
import { IdentityUncheckedCreateWithoutPatientInputObjectSchema } from './IdentityUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityCreateOrConnectWithoutPatientInput> = z
  .object({
    where: z.lazy(() => IdentityWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema),
      z.lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const IdentityCreateOrConnectWithoutPatientInputObjectSchema = Schema
