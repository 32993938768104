import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryProcedureIdCategoryIdCompoundUniqueInput> =
  z
    .object({
      procedureId: z.number(),
      categoryId: z.number(),
    })
    .strict()

export const ProcedureCategoryProcedureIdCategoryIdCompoundUniqueInputObjectSchema =
  Schema
