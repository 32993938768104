import { z } from 'zod'
import { DoctorItemCreateWithoutOrderInputObjectSchema } from './DoctorItemCreateWithoutOrderInput.schema'
import { DoctorItemUncheckedCreateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedCreateWithoutOrderInput.schema'
import { DoctorItemCreateOrConnectWithoutOrderInputObjectSchema } from './DoctorItemCreateOrConnectWithoutOrderInput.schema'
import { DoctorItemWhereUniqueInputObjectSchema } from './DoctorItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemUncheckedCreateNestedOneWithoutOrderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DoctorItemCreateWithoutOrderInputObjectSchema),
          z.lazy(() => DoctorItemUncheckedCreateWithoutOrderInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => DoctorItemCreateOrConnectWithoutOrderInputObjectSchema)
        .optional(),
      connect: z.lazy(() => DoctorItemWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const DoctorItemUncheckedCreateNestedOneWithoutOrderInputObjectSchema =
  Schema
