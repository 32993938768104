import { z } from 'zod'
import { PartnerCreateWithoutSkuInputObjectSchema } from './PartnerCreateWithoutSkuInput.schema'
import { PartnerUncheckedCreateWithoutSkuInputObjectSchema } from './PartnerUncheckedCreateWithoutSkuInput.schema'
import { PartnerCreateOrConnectWithoutSkuInputObjectSchema } from './PartnerCreateOrConnectWithoutSkuInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutSkuInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PartnerCreateWithoutSkuInputObjectSchema),
        z.lazy(() => PartnerUncheckedCreateWithoutSkuInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PartnerCreateOrConnectWithoutSkuInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PartnerCreateNestedOneWithoutSkuInputObjectSchema = Schema
