import * as z from "zod"
import { CompletePartner, RelatedPartnerModel } from "./index"

export const SkuModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  sku: z.string(),
  variantId: z.number().int(),
  cost: z.number(),
  active: z.boolean(),
  partnerId: z.number().int(),
})

export interface CompleteSku extends z.infer<typeof SkuModel> {
  partner: CompletePartner
}

/**
 * RelatedSkuModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedSkuModel: z.ZodSchema<CompleteSku> = z.lazy(() => SkuModel.extend({
  partner: RelatedPartnerModel,
}))
