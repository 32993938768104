import { Typography } from '@mui/material'
import { AppointmentTypeSchema } from '@ssch-backend/teleconsults'
import { FC } from 'react'
import { TextFieldProps, useLocaleState, useRecordContext } from 'react-admin'
import { getAppointmentTypeLabel } from '../../../enumLabel'

export const AppointmentTypeField: FC<TextFieldProps> = (props) => {
  const [locale] = useLocaleState()
  const record = useRecordContext()
  if (!record) return null

  return (
    <Typography {...props} sx={{ fontSize: '14px' }}>
      {
        getAppointmentTypeLabel(
          record.type as keyof typeof AppointmentTypeSchema.Enum,
        )[locale === 'th' ? 'th' : 'en']
      }
    </Typography>
  )
}
