import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumUsageNullableFilterObjectSchema } from './EnumUsageNullableFilter.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { EnumUnitNullableFilterObjectSchema } from './EnumUnitNullableFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { EnumUseFrequencyFilterObjectSchema } from './EnumUseFrequencyFilter.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseTimeFilterObjectSchema } from './EnumUseTimeFilter.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumUseDurationUnitNullableFilterObjectSchema } from './EnumUseDurationUnitNullableFilter.schema'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'
import { EnumRefillTimeUnitNullableFilterObjectSchema } from './EnumRefillTimeUnitNullableFilter.schema'
import { RefillTimeUnitSchema } from '../enums/RefillTimeUnit.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema'
import { CaseSummaryRelationFilterObjectSchema } from './CaseSummaryRelationFilter.schema'
import { CaseSummaryWhereInputObjectSchema } from './CaseSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => SuggestedProductItemWhereInputObjectSchema),
        z.lazy(() => SuggestedProductItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => SuggestedProductItemWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => SuggestedProductItemWhereInputObjectSchema),
        z.lazy(() => SuggestedProductItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    caseSummaryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    productVariantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    amount: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    usage: z
      .union([
        z.lazy(() => EnumUsageNullableFilterObjectSchema),
        z.lazy(() => UsageSchema),
      ])
      .optional()
      .nullable(),
    useAmount: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    useUnit: z
      .union([
        z.lazy(() => EnumUnitNullableFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional()
      .nullable(),
    useFrequency: z
      .union([
        z.lazy(() => EnumUseFrequencyFilterObjectSchema),
        z.lazy(() => UseFrequencySchema),
      ])
      .optional(),
    useTime: z
      .union([
        z.lazy(() => EnumUseTimeFilterObjectSchema),
        z.lazy(() => UseTimeSchema),
      ])
      .optional(),
    useDuration: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    useDurationUnit: z
      .union([
        z.lazy(() => EnumUseDurationUnitNullableFilterObjectSchema),
        z.lazy(() => UseDurationUnitSchema),
      ])
      .optional()
      .nullable(),
    refillTime: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    refillTimeUnit: z
      .union([
        z.lazy(() => EnumRefillTimeUnitNullableFilterObjectSchema),
        z.lazy(() => RefillTimeUnitSchema),
      ])
      .optional()
      .nullable(),
    note: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    warehouse: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
    caseSummary: z
      .union([
        z.lazy(() => CaseSummaryRelationFilterObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const SuggestedProductItemWhereInputObjectSchema = Schema
