import { z } from 'zod'
import { CouponFindManySchema } from '../findManyCoupon.schema'
import { DiscountSettingFindManySchema } from '../findManyDiscountSetting.schema'
import { ConditionSettingFindManySchema } from '../findManyConditionSetting.schema'
import { CouponGroupCountOutputTypeArgsObjectSchema } from './CouponGroupCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupInclude> = z
  .object({
    coupons: z
      .union([z.boolean(), z.lazy(() => CouponFindManySchema)])
      .optional(),
    discountSetting: z
      .union([z.boolean(), z.lazy(() => DiscountSettingFindManySchema)])
      .optional(),
    conditionSetting: z
      .union([z.boolean(), z.lazy(() => ConditionSettingFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CouponGroupCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CouponGroupIncludeObjectSchema = Schema
