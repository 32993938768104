import { z } from 'zod'
import { SkuWhereUniqueInputObjectSchema } from './SkuWhereUniqueInput.schema'
import { SkuUpdateWithoutPartnerInputObjectSchema } from './SkuUpdateWithoutPartnerInput.schema'
import { SkuUncheckedUpdateWithoutPartnerInputObjectSchema } from './SkuUncheckedUpdateWithoutPartnerInput.schema'
import { SkuCreateWithoutPartnerInputObjectSchema } from './SkuCreateWithoutPartnerInput.schema'
import { SkuUncheckedCreateWithoutPartnerInputObjectSchema } from './SkuUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuUpsertWithWhereUniqueWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => SkuWhereUniqueInputObjectSchema),
    update: z.union([
      z.lazy(() => SkuUpdateWithoutPartnerInputObjectSchema),
      z.lazy(() => SkuUncheckedUpdateWithoutPartnerInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => SkuCreateWithoutPartnerInputObjectSchema),
      z.lazy(() => SkuUncheckedCreateWithoutPartnerInputObjectSchema),
    ]),
  })
  .strict()

export const SkuUpsertWithWhereUniqueWithoutPartnerInputObjectSchema = Schema
