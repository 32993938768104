import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryCreateWithoutOrderInputObjectSchema } from './DeliveryCreateWithoutOrderInput.schema'
import { DeliveryUncheckedCreateWithoutOrderInputObjectSchema } from './DeliveryUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema),
      z.lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const DeliveryCreateOrConnectWithoutOrderInputObjectSchema = Schema
