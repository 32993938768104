import AddIcon from '@mui/icons-material/Add'
import { Button, Typography } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin'
import { CustomBooleanField } from '../../../components/CustomField'
import { FormDivider } from '../../../components/FormInput/divider'

export const SkuSection: FC = () => {
  interface Warehouse {
    id: number
    name: string
    code: string
  }

  interface VariantWarehouse {
    id: number
    variantId: number
    warehouseId: number
    warehouse: Warehouse
  }

  interface Variant {
    id: number
    sku: string
    variantWarehouses: VariantWarehouse[]
  }
  const record = useRecordContext()

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        SKU
      </Typography>
      <FormDivider />
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{ mb: 1 }}
        onClick={() => {
          window.open(`/productVariant/create?productId=${record.id}`, '_blank')
        }}
      >
        เพิ่ม SKU
      </Button>
      <ReferenceManyField
        reference="productVariant"
        target="productId"
        perPage={999}
      >
        <Datagrid
          sx={{ width: '100%', minWidth: '800px', overflowX: 'auto' }}
          bulkActionButtons={false}
          rowClick="edit"
        >
          <TextField label="ID" source="id" sortable={false} />
          <TextField label="SKU" source="sku" sortable={false} />
          <FunctionField
            label="Label (en,th)"
            render={({
              descriptionTr,
            }: {
              descriptionTr: { en: string; th: string }
            }) =>
              descriptionTr &&
              [descriptionTr.en, `(${descriptionTr.th})`].join(' ')
            }
          />
          <FunctionField
            label="Warehouse"
            render={(record: Variant) =>
              record.variantWarehouses && record.variantWarehouses.length > 0
                ? record.variantWarehouses
                    .map(
                      (warehouseData: VariantWarehouse) =>
                        warehouseData.warehouse.code,
                    )
                    .join(', ')
                : '-'
            }
          />
          <FunctionField
            label="Size & Unit"
            render={(record: { size: number; unit: number }) =>
              record && [record.size, record.unit].join(' ')
            }
          />
          <TextField label="Cost" source="cost" sortable={false} />
          <TextField label="Tag price" source="tagPrice" sortable={false} />
          <TextField label="Price" source="sellingPrice" sortable={false} />
          <CustomBooleanField source="active" label="Active" sortable={false} />
          <CustomBooleanField
            source="canExpress"
            label="Express shipping"
            sortable={false}
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}
