import { z } from 'zod'
import { VariantWarehouseUpdateInputObjectSchema } from './objects/VariantWarehouseUpdateInput.schema'
import { VariantWarehouseUncheckedUpdateInputObjectSchema } from './objects/VariantWarehouseUncheckedUpdateInput.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './objects/VariantWarehouseWhereUniqueInput.schema'

export const VariantWarehouseUpdateOneSchema = z.object({
  data: z.union([
    VariantWarehouseUpdateInputObjectSchema,
    VariantWarehouseUncheckedUpdateInputObjectSchema,
  ]),
  where: VariantWarehouseWhereUniqueInputObjectSchema,
})
