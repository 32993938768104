import { z } from 'zod'
import { VariantArgsObjectSchema } from './VariantArgs.schema'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { BrandArgsObjectSchema } from './BrandArgs.schema'
import { ProductReviewPictureFindManySchema } from '../findManyProductReviewPicture.schema'
import { ProductReviewCountOutputTypeArgsObjectSchema } from './ProductReviewCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewInclude> = z
  .object({
    variant: z
      .union([z.boolean(), z.lazy(() => VariantArgsObjectSchema)])
      .optional(),
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    brand: z
      .union([z.boolean(), z.lazy(() => BrandArgsObjectSchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProductReviewPictureFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProductReviewCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductReviewIncludeObjectSchema = Schema
