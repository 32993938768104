import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoInclude> = z
  .object({
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProductVideoIncludeObjectSchema = Schema
