import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutSuggestedProcedureItemsInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () =>
            CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryCreateOrConnectWithoutSuggestedProcedureItemsInputObjectSchema =
  Schema
