import { z } from 'zod'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'
import { CategoryArgsObjectSchema } from './CategoryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryInclude> = z
  .object({
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    category: z
      .union([z.boolean(), z.lazy(() => CategoryArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProcedureCategoryIncludeObjectSchema = Schema
