import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { ProductRelationFilterObjectSchema } from './ProductRelationFilter.schema'
import { ProductWhereInputObjectSchema } from './ProductWhereInput.schema'
import { CategoryRelationFilterObjectSchema } from './CategoryRelationFilter.schema'
import { CategoryWhereInputObjectSchema } from './CategoryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductCategoryWhereInputObjectSchema),
        z.lazy(() => ProductCategoryWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductCategoryWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductCategoryWhereInputObjectSchema),
        z.lazy(() => ProductCategoryWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    productId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    categoryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    product: z
      .union([
        z.lazy(() => ProductRelationFilterObjectSchema),
        z.lazy(() => ProductWhereInputObjectSchema),
      ])
      .optional(),
    category: z
      .union([
        z.lazy(() => CategoryRelationFilterObjectSchema),
        z.lazy(() => CategoryWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductCategoryWhereInputObjectSchema = Schema
