import { z } from 'zod'

export const ConsultingStatusSchema = z.enum([
  'IN_QUEUE',
  'WAITING',
  'IN_PROGRESS',
  'SUCCESS',
  'UNSUCCESS',
  'MISSED_CALL',
])
