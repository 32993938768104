import { z } from 'zod'
import { OtpUpdateInputObjectSchema } from './objects/OtpUpdateInput.schema'
import { OtpUncheckedUpdateInputObjectSchema } from './objects/OtpUncheckedUpdateInput.schema'
import { OtpWhereUniqueInputObjectSchema } from './objects/OtpWhereUniqueInput.schema'

export const OtpUpdateOneSchema = z.object({
  data: z.union([
    OtpUpdateInputObjectSchema,
    OtpUncheckedUpdateInputObjectSchema,
  ]),
  where: OtpWhereUniqueInputObjectSchema,
})
