import { z } from 'zod'
import { BrandCreateWithoutBannersInputObjectSchema } from './BrandCreateWithoutBannersInput.schema'
import { BrandUncheckedCreateWithoutBannersInputObjectSchema } from './BrandUncheckedCreateWithoutBannersInput.schema'
import { BrandCreateOrConnectWithoutBannersInputObjectSchema } from './BrandCreateOrConnectWithoutBannersInput.schema'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateNestedOneWithoutBannersInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => BrandCreateWithoutBannersInputObjectSchema),
        z.lazy(() => BrandUncheckedCreateWithoutBannersInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => BrandCreateOrConnectWithoutBannersInputObjectSchema)
      .optional(),
    connect: z.lazy(() => BrandWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const BrandCreateNestedOneWithoutBannersInputObjectSchema = Schema
