import { z } from 'zod'
import { WaitingBannerWhereInputObjectSchema } from './objects/WaitingBannerWhereInput.schema'
import { WaitingBannerOrderByWithAggregationInputObjectSchema } from './objects/WaitingBannerOrderByWithAggregationInput.schema'
import { WaitingBannerScalarWhereWithAggregatesInputObjectSchema } from './objects/WaitingBannerScalarWhereWithAggregatesInput.schema'
import { WaitingBannerScalarFieldEnumSchema } from './enums/WaitingBannerScalarFieldEnum.schema'

export const WaitingBannerGroupBySchema = z.object({
  where: WaitingBannerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    WaitingBannerOrderByWithAggregationInputObjectSchema,
    WaitingBannerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: WaitingBannerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(WaitingBannerScalarFieldEnumSchema),
})
