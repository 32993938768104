import { z } from 'zod'
import { AddressTagSchema } from '../enums/AddressTag.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressCreateManyInput> = z
  .object({
    id: z.number().optional(),
    recipient: z.string(),
    address: z.string(),
    phone: z.string(),
    province: z.string(),
    district: z.string(),
    subDistrict: z.string(),
    postCode: z.string(),
    provinceKey: z.string(),
    districtKey: z.string(),
    subDistrictKey: z.string(),
    note: z.string().optional().nullable(),
    tag: z
      .lazy(() => AddressTagSchema)
      .optional()
      .nullable(),
    isDefault: z.boolean().optional(),
    patientId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const AddressCreateManyInputObjectSchema = Schema
