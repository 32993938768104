import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'
import { EnumCoinTransactionStatusFieldUpdateOperationsInputObjectSchema } from './EnumCoinTransactionStatusFieldUpdateOperationsInput.schema'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'
import { EnumCoinTransactionTypeFieldUpdateOperationsInputObjectSchema } from './EnumCoinTransactionTypeFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUncheckedUpdateManyInput> = z
  .object({
    id: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patientId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    orderId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => CoinTransactionStatusSchema),
        z.lazy(
          () => EnumCoinTransactionStatusFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional(),
    type: z
      .union([
        z.lazy(() => CoinTransactionTypeSchema),
        z.lazy(
          () => EnumCoinTransactionTypeFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional(),
    amount: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    note: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const CoinTransactionUncheckedUpdateManyInputObjectSchema = Schema
