import { ReferenceInput, Validator } from 'react-admin'
import { FormAutoCompleteInput } from '../FormInput/input'
interface IReferentPartner {
  source?: string | undefined
  reference?: string | undefined
  label?: string | undefined
  validate?: Validator | Validator[] | undefined
}

const handleFilter = (searchText: string) => {
  let where: { [key: string]: unknown } = {}
  if (searchText && searchText.trim().length >= 3) {
    where = {
      searchField_contains: searchText.toLowerCase().trim(),
    }
  }
  return where
}
export const ReferencePartner = ({
  source,
  reference,
  label,
  validate,
}: IReferentPartner) => {
  return (
    <ReferenceInput
      allowEmpty
      source={source ?? 'partnerId'}
      reference={reference ?? 'partner'}
      enableGetChoices={({ q }) => q && q.length > 2}
      validate={validate}
    >
      <FormAutoCompleteInput
        label={label ?? 'Partner'}
        optionText="nameTr.th"
        filterToQuery={handleFilter}
        TextFieldProps={{
          placeholder: 'Type at least 3 characters to search',
        }}
      />
    </ReferenceInput>
  )
}
