import { z } from 'zod'
import { SegmentFindManySchema } from '../findManySegment.schema'
import { NotificationPatientFindManySchema } from '../findManyNotificationPatient.schema'
import { NotificationCountOutputTypeArgsObjectSchema } from './NotificationCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    type: z.boolean().optional(),
    picture: z.boolean().optional(),
    title: z.boolean().optional(),
    message: z.boolean().optional(),
    actionType: z.boolean().optional(),
    action: z.boolean().optional(),
    onesignalMessageId: z.boolean().optional(),
    startAt: z.boolean().optional(),
    appointmentId: z.boolean().optional(),
    appointmentEvent: z.boolean().optional(),
    segments: z
      .union([z.boolean(), z.lazy(() => SegmentFindManySchema)])
      .optional(),
    patients: z
      .union([z.boolean(), z.lazy(() => NotificationPatientFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => NotificationCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NotificationSelectObjectSchema = Schema
