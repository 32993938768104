import { z } from 'zod'
import { ScheduleCreateWithoutAppointmentInputObjectSchema } from './ScheduleCreateWithoutAppointmentInput.schema'
import { ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedCreateWithoutAppointmentInput.schema'
import { ScheduleCreateOrConnectWithoutAppointmentInputObjectSchema } from './ScheduleCreateOrConnectWithoutAppointmentInput.schema'
import { ScheduleUpsertWithoutAppointmentInputObjectSchema } from './ScheduleUpsertWithoutAppointmentInput.schema'
import { ScheduleWhereUniqueInputObjectSchema } from './ScheduleWhereUniqueInput.schema'
import { ScheduleUpdateWithoutAppointmentInputObjectSchema } from './ScheduleUpdateWithoutAppointmentInput.schema'
import { ScheduleUncheckedUpdateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedUpdateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleUpdateOneWithoutAppointmentNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ScheduleCreateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ScheduleCreateOrConnectWithoutAppointmentInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ScheduleUpsertWithoutAppointmentInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => ScheduleWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ScheduleUpdateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => ScheduleUncheckedUpdateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ScheduleUpdateOneWithoutAppointmentNestedInputObjectSchema = Schema
