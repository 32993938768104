import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CouponGroupCountOrderByAggregateInputObjectSchema } from './CouponGroupCountOrderByAggregateInput.schema'
import { CouponGroupAvgOrderByAggregateInputObjectSchema } from './CouponGroupAvgOrderByAggregateInput.schema'
import { CouponGroupMaxOrderByAggregateInputObjectSchema } from './CouponGroupMaxOrderByAggregateInput.schema'
import { CouponGroupMinOrderByAggregateInputObjectSchema } from './CouponGroupMinOrderByAggregateInput.schema'
import { CouponGroupSumOrderByAggregateInputObjectSchema } from './CouponGroupSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    prefix: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    tags: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    discount: z.lazy(() => SortOrderSchema).optional(),
    discountType: z.lazy(() => SortOrderSchema).optional(),
    minTotal: z.lazy(() => SortOrderSchema).optional(),
    maxDiscount: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    isPublic: z.lazy(() => SortOrderSchema).optional(),
    isOnlyNewPatient: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    quota: z.lazy(() => SortOrderSchema).optional(),
    quotaPerUser: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2CreatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2UpdatedBy: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CouponGroupCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CouponGroupAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CouponGroupMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CouponGroupMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CouponGroupSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CouponGroupOrderByWithAggregationInputObjectSchema = Schema
