import { z } from 'zod'
import { WeekdaySchema } from '../enums/Weekday.schema'
import { BranchCreateNestedOneWithoutOfficeHoursInputObjectSchema } from './BranchCreateNestedOneWithoutOfficeHoursInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    weekday: z.lazy(() => WeekdaySchema),
    openAt: z.string(),
    closeAt: z.string(),
    timezone: z.string(),
    branch: z.lazy(
      () => BranchCreateNestedOneWithoutOfficeHoursInputObjectSchema,
    ),
  })
  .strict()

export const OfficeHourCreateInputObjectSchema = Schema
