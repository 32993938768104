import { z } from 'zod'
import { WaitingBannerWhereUniqueInputObjectSchema } from './objects/WaitingBannerWhereUniqueInput.schema'
import { WaitingBannerCreateInputObjectSchema } from './objects/WaitingBannerCreateInput.schema'
import { WaitingBannerUncheckedCreateInputObjectSchema } from './objects/WaitingBannerUncheckedCreateInput.schema'
import { WaitingBannerUpdateInputObjectSchema } from './objects/WaitingBannerUpdateInput.schema'
import { WaitingBannerUncheckedUpdateInputObjectSchema } from './objects/WaitingBannerUncheckedUpdateInput.schema'

export const WaitingBannerUpsertSchema = z.object({
  where: WaitingBannerWhereUniqueInputObjectSchema,
  create: z.union([
    WaitingBannerCreateInputObjectSchema,
    WaitingBannerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    WaitingBannerUpdateInputObjectSchema,
    WaitingBannerUncheckedUpdateInputObjectSchema,
  ]),
})
