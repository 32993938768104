import { z } from 'zod'
import { CasePictureScalarWhereInputObjectSchema } from './CasePictureScalarWhereInput.schema'
import { CasePictureUpdateManyMutationInputObjectSchema } from './CasePictureUpdateManyMutationInput.schema'
import { CasePictureUncheckedUpdateManyWithoutPicturesInputObjectSchema } from './CasePictureUncheckedUpdateManyWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureUpdateManyWithWhereWithoutCaseInput> =
  z
    .object({
      where: z.lazy(() => CasePictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CasePictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => CasePictureUncheckedUpdateManyWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CasePictureUpdateManyWithWhereWithoutCaseInputObjectSchema = Schema
