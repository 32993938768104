import { ContentCopy } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceOneField,
  TextField,
  useNotify,
} from 'react-admin'
import { CustomBooleanField } from '../../components/CustomField'
import { FilterListForm } from './list-section/filterlistForm'
const rowsPerPage = [5, 10, 20, 30]

const ProcedureListActions = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}

const renderCategory = (record: {
  categories: { category: { id: number; nameTr: { en: string; th: string } } }[]
}) => {
  return (
    <div>
      {record.categories?.map((o) => (
        <p key={`cat-${o.category.id}`} style={{ margin: '0.25rem 0' }}>
          {o.category?.nameTr?.th ?? ''}{' '}
          {o.category?.nameTr?.en ? `/ ${o.category.nameTr.en}` : ''}
        </p>
      )) ?? '-'}
    </div>
  )
}

export const ProcedureList = () => {
  const notify = useNotify()
  return (
    <Box>
      <List
        perPage={rowsPerPage[3]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={<ProcedureListActions />}
        disableSyncWithLocation
      >
        <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <ReferenceOneField
            label="Clinic (en,th)"
            source="partnerId"
            reference="partner"
            target="id"
            sortable={false}
          >
            <FunctionField
              render={({ nameTr }: { nameTr: { en: string; th: string } }) => {
                return (
                  <div>
                    <p style={{ margin: '0.25rem 0' }}>TH: {nameTr.th ?? ''}</p>
                    <p style={{ margin: '0.25rem 0' }}>EN: {nameTr.en ?? ''}</p>
                  </div>
                )
              }}
            />
          </ReferenceOneField>
          <FunctionField
            label="Name (en,th)"
            render={({ nameTr }: { nameTr: { en: string; th: string } }) => {
              return (
                <div>
                  <p style={{ margin: '0.25rem 0' }}>TH: {nameTr.th ?? ''}</p>
                  <p style={{ margin: '0.25rem 0' }}>EN: {nameTr.en ?? ''}</p>
                </div>
              )
            }}
          />
          <NumberField source="tagPrice" />
          <NumberField label="Price" source="sellingPrice" />
          <NumberField source="cost" />
          <TextField source="priority" textAlign="center" />
          <FunctionField label="Category" render={renderCategory} />
          <CustomBooleanField source="active" label="Active" />
          <FunctionField
            label="Deal Link"
            render={(record: { id: number }) => (
              <Button
                variant="outlined"
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<ContentCopy />}
                onClick={() => {
                  const appPatientDomain = import.meta.env.NX_APP_PATIENT_DOMAIN
                  navigator.clipboard
                    .writeText(`${appPatientDomain}/procedure/${record.id}`)
                    .then(() => {
                      notify(`Copied to clipboard!`, {
                        type: 'success',
                      })
                    })
                    .catch((error) => {
                      console.error('Error copying to clipboard:', error)
                      notify('Failed to copy to clipboard. Please try again.', {
                        type: 'success',
                      })
                    })
                }}
              >
                Copy Link
              </Button>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
    </Box>
  )
}
