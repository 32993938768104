import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationWhereUniqueInput> = z
  .object({
    key: z.string().optional(),
  })
  .strict()

export const ConfigurationWhereUniqueInputObjectSchema = Schema
