import { Grid } from '@mui/material'
import { FC } from 'react'
import { FormImageInput } from './formImageInput'

export const ImageSection: FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <FormImageInput source="idCardPicture" label="รูปบัตรประจำตัวประชาชน" />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormImageInput
          source="selfieIdCardPicture"
          label="รูปตัวจริงตรงกับบัตรประจำตัวประชาชน"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormImageInput source="passportPicture" label="รูปพาสปอร์ต" />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormImageInput
          source="selfiePassportPicture"
          label="รูปตัวจริงตรงกับพาสปอร์ต"
        />
      </Grid>
    </Grid>
  )
}
