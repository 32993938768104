import { z } from 'zod'
import { OtpBlacklistWhereInputObjectSchema } from './objects/OtpBlacklistWhereInput.schema'
import { OtpBlacklistOrderByWithAggregationInputObjectSchema } from './objects/OtpBlacklistOrderByWithAggregationInput.schema'
import { OtpBlacklistScalarWhereWithAggregatesInputObjectSchema } from './objects/OtpBlacklistScalarWhereWithAggregatesInput.schema'
import { OtpBlacklistScalarFieldEnumSchema } from './enums/OtpBlacklistScalarFieldEnum.schema'

export const OtpBlacklistGroupBySchema = z.object({
  where: OtpBlacklistWhereInputObjectSchema.optional(),
  orderBy: z.union([
    OtpBlacklistOrderByWithAggregationInputObjectSchema,
    OtpBlacklistOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: OtpBlacklistScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(OtpBlacklistScalarFieldEnumSchema),
})
