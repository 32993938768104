import { z } from 'zod'
import { ProductItemTypeSchema } from '../enums/ProductItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumProductItemTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => ProductItemTypeSchema).optional(),
    })
    .strict()

export const EnumProductItemTypeFieldUpdateOperationsInputObjectSchema = Schema
