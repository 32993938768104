import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    v2HospitalId: z.lazy(() => SortOrderSchema).optional(),
    v2StoreId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const PartnerSumOrderByAggregateInputObjectSchema = Schema
