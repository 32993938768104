import { Box } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomTextInput } from '../../../components/CustomInput'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'

export interface FormImageInputProps {
  source: string
  label: string
  multiple?: boolean
  disabled?: boolean
}

export const FormImageInput: FC<FormImageInputProps> = ({
  source,
  label,
  multiple,
  disabled,
}) => {
  const { getValues } = useFormContext()

  const isValid = (fieldKey: string): boolean => {
    let isValid = true
    const { ...data } = getValues()
    if (data?.validator) {
      isValid = data?.validator[fieldKey] !== '0'
    }

    return isValid
  }

  return (
    <Box>
      <ImageStorageInput
        source={source}
        label={label}
        multiple={multiple ?? false}
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.doctor.generateUploadPictureUrl.query({
              extension: file.name.split('.').pop() ?? '',
              fieldPath: source,
            })
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
        disabled={disabled ?? false}
      ></ImageStorageInput>
      <RadioButtonGroupInput
        fullWidth
        label={false}
        helperText={false}
        source={`validator.${source}`}
        defaultValue="1"
        choices={[
          { id: '1', name: 'ถูกต้อง' },
          { id: '0', name: 'ไม่ถูกต้อง' },
        ]}
      />
      {!isValid(source) && (
        <CustomTextInput
          sx={{
            '& label, input': { color: 'red' },
            '& fieldset': {
              borderColor: 'red !important',
            },
          }}
          fullWidth
          source={`errorReason.${source}`}
          label={`สาเหตุ`}
          placeholder={`สาเหตุที่ข้อมูล ${label} ผิด`}
          color="error"
        />
      )}
    </Box>
  )
}
