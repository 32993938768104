import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumOrderStatusFilterObjectSchema } from './EnumOrderStatusFilter.schema'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumOrderTypeFilterObjectSchema } from './EnumOrderTypeFilter.schema'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { EnumPurchaseOriginNullableFilterObjectSchema } from './EnumPurchaseOriginNullableFilter.schema'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { EnumShippingTypeNullableFilterObjectSchema } from './EnumShippingTypeNullableFilter.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { EnumCancelChannelNullableFilterObjectSchema } from './EnumCancelChannelNullableFilter.schema'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'
import { EnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema } from './EnumDeclinedSuggestedItemsReasonNullableFilter.schema'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { DoctorItemRelationFilterObjectSchema } from './DoctorItemRelationFilter.schema'
import { DoctorItemWhereInputObjectSchema } from './DoctorItemWhereInput.schema'
import { ProductItemOriginalListRelationFilterObjectSchema } from './ProductItemOriginalListRelationFilter.schema'
import { ProductItemListRelationFilterObjectSchema } from './ProductItemListRelationFilter.schema'
import { ProcedureItemListRelationFilterObjectSchema } from './ProcedureItemListRelationFilter.schema'
import { CouponListRelationFilterObjectSchema } from './CouponListRelationFilter.schema'
import { ShippingProviderRelationFilterObjectSchema } from './ShippingProviderRelationFilter.schema'
import { ShippingProviderWhereInputObjectSchema } from './ShippingProviderWhereInput.schema'
import { PaymentListRelationFilterObjectSchema } from './PaymentListRelationFilter.schema'
import { RefundListRelationFilterObjectSchema } from './RefundListRelationFilter.schema'
import { DeliveryListRelationFilterObjectSchema } from './DeliveryListRelationFilter.schema'
import { HealthInfoRelationFilterObjectSchema } from './HealthInfoRelationFilter.schema'
import { HealthInfoWhereInputObjectSchema } from './HealthInfoWhereInput.schema'
import { ReceiptRelationFilterObjectSchema } from './ReceiptRelationFilter.schema'
import { ReceiptWhereInputObjectSchema } from './ReceiptWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => OrderWhereInputObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => OrderWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => OrderWhereInputObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumOrderStatusFilterObjectSchema),
        z.lazy(() => OrderStatusSchema),
      ])
      .optional(),
    expiredAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    shouldExpiresAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    orderNumber: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    customerName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    customerTelNo: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    orderType: z
      .union([
        z.lazy(() => EnumOrderTypeFilterObjectSchema),
        z.lazy(() => OrderTypeSchema),
      ])
      .optional(),
    purchaseOrigin: z
      .union([
        z.lazy(() => EnumPurchaseOriginNullableFilterObjectSchema),
        z.lazy(() => PurchaseOriginSchema),
      ])
      .optional()
      .nullable(),
    purchaseRef: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    editedProductItemsAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    editedProductItemsBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    shippingFee: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    shippingFeeDiscount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    shippingType: z
      .union([
        z.lazy(() => EnumShippingTypeNullableFilterObjectSchema),
        z.lazy(() => ShippingTypeSchema),
      ])
      .optional()
      .nullable(),
    shippingProviderId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    recipient: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    shippingAddress: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    shippingTelNo: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    subDistrict: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    subDistrictKey: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    district: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    districtKey: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    province: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    provinceKey: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    postcode: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    isPaid: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    isHold: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    approvedBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    approvedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    canceledBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    canceledAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    cancelChannel: z
      .union([
        z.lazy(() => EnumCancelChannelNullableFilterObjectSchema),
        z.lazy(() => CancelChannelSchema),
      ])
      .optional()
      .nullable(),
    noteToPatient: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    useCoin: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    v2InvoiceId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    holdingNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    holdingBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    holdingAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    isDeclinedSuggestedItems: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    declinedSuggestedItemsReason: z
      .union([
        z.lazy(
          () => EnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema,
        ),
        z.lazy(() => DeclinedSuggestedItemsReasonSchema),
      ])
      .optional()
      .nullable(),
    doctorItem: z
      .union([
        z.lazy(() => DoctorItemRelationFilterObjectSchema),
        z.lazy(() => DoctorItemWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    productItemsOriginal: z
      .lazy(() => ProductItemOriginalListRelationFilterObjectSchema)
      .optional(),
    productItems: z
      .lazy(() => ProductItemListRelationFilterObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemListRelationFilterObjectSchema)
      .optional(),
    coupons: z.lazy(() => CouponListRelationFilterObjectSchema).optional(),
    shippingProvider: z
      .union([
        z.lazy(() => ShippingProviderRelationFilterObjectSchema),
        z.lazy(() => ShippingProviderWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    payments: z.lazy(() => PaymentListRelationFilterObjectSchema).optional(),
    refunds: z.lazy(() => RefundListRelationFilterObjectSchema).optional(),
    deliveries: z.lazy(() => DeliveryListRelationFilterObjectSchema).optional(),
    healthInfo: z
      .union([
        z.lazy(() => HealthInfoRelationFilterObjectSchema),
        z.lazy(() => HealthInfoWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    receipt: z
      .union([
        z.lazy(() => ReceiptRelationFilterObjectSchema),
        z.lazy(() => ReceiptWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const OrderWhereInputObjectSchema = Schema
