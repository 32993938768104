import { z } from 'zod'
import { VariantUpdateWithoutFlashsaleItemInputObjectSchema } from './VariantUpdateWithoutFlashsaleItemInput.schema'
import { VariantUncheckedUpdateWithoutFlashsaleItemInputObjectSchema } from './VariantUncheckedUpdateWithoutFlashsaleItemInput.schema'
import { VariantCreateWithoutFlashsaleItemInputObjectSchema } from './VariantCreateWithoutFlashsaleItemInput.schema'
import { VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema } from './VariantUncheckedCreateWithoutFlashsaleItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpsertWithoutFlashsaleItemInput> = z
  .object({
    update: z.union([
      z.lazy(() => VariantUpdateWithoutFlashsaleItemInputObjectSchema),
      z.lazy(() => VariantUncheckedUpdateWithoutFlashsaleItemInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => VariantCreateWithoutFlashsaleItemInputObjectSchema),
      z.lazy(() => VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema),
    ]),
  })
  .strict()

export const VariantUpsertWithoutFlashsaleItemInputObjectSchema = Schema
