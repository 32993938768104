import { z } from 'zod'
import { AppointmentUpdateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUpdateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpsertWithoutFollowUpCaseSummaryInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => AppointmentUpdateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AppointmentUpsertWithoutFollowUpCaseSummaryInputObjectSchema =
  Schema
