import { z } from 'zod'
import { AppointmentCreateNestedOneWithoutScheduleInputObjectSchema } from './AppointmentCreateNestedOneWithoutScheduleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleCreateInput> = z
  .object({
    doctorId: z.number(),
    availableAt: z.date(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    appointment: z
      .lazy(() => AppointmentCreateNestedOneWithoutScheduleInputObjectSchema)
      .optional(),
  })
  .strict()

export const ScheduleCreateInputObjectSchema = Schema
