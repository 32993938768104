import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductBannerCountOrderByAggregateInputObjectSchema } from './ProductBannerCountOrderByAggregateInput.schema'
import { ProductBannerAvgOrderByAggregateInputObjectSchema } from './ProductBannerAvgOrderByAggregateInput.schema'
import { ProductBannerMaxOrderByAggregateInputObjectSchema } from './ProductBannerMaxOrderByAggregateInput.schema'
import { ProductBannerMinOrderByAggregateInputObjectSchema } from './ProductBannerMinOrderByAggregateInput.schema'
import { ProductBannerSumOrderByAggregateInputObjectSchema } from './ProductBannerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductBannerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    actionUri: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductBannerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductBannerAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductBannerMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductBannerMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductBannerSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductBannerOrderByWithAggregationInputObjectSchema = Schema
