import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateManyInput> = z
  .object({
    id: z.number().optional(),
    procedureId: z.number(),
    categoryId: z.number(),
    priority: z.number().optional(),
  })
  .strict()

export const ProcedureCategoryCreateManyInputObjectSchema = Schema
