import { z } from 'zod'
import { BranchOrderByWithRelationInputObjectSchema } from './objects/BranchOrderByWithRelationInput.schema'
import { BranchWhereInputObjectSchema } from './objects/BranchWhereInput.schema'
import { BranchWhereUniqueInputObjectSchema } from './objects/BranchWhereUniqueInput.schema'
import { BranchScalarFieldEnumSchema } from './enums/BranchScalarFieldEnum.schema'

export const BranchFindManySchema = z.object({
  orderBy: z
    .union([
      BranchOrderByWithRelationInputObjectSchema,
      BranchOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: BranchWhereInputObjectSchema.optional(),
  cursor: BranchWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(BranchScalarFieldEnumSchema).optional(),
})
