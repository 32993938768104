import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserCreateManyPartnerInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    email: z.string(),
    password: z.string(),
    active: z.boolean().optional(),
  })
  .strict()

export const UserCreateManyPartnerInputObjectSchema = Schema
