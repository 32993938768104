import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProductItemUncheckedCreateWithoutRefundInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    sku: z.string(),
    variantId: z.number(),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    picture: z.string(),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    drugType: z
      .lazy(() => DrugTypeSchema)
      .optional()
      .nullable(),
    quantity: z.number(),
    tagPrice: z.number().optional().nullable(),
    sellingPrice: z.number(),
    sellingUnit: z.lazy(() => UnitSchema).optional(),
    cost: z.number(),
    flashSaleId: z.number().optional().nullable(),
    orderId: z.number(),
    deliveryId: z.number().optional().nullable(),
    v2DrugItemId: z.number().optional().nullable(),
    canExpress: z.boolean().optional(),
    reviewId: z.number().optional().nullable(),
    reviewStartAt: z.date().optional().nullable(),
    reviewExpireAt: z.date().optional().nullable(),
    warehouse: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict()

export const ProductItemUncheckedCreateWithoutRefundInputObjectSchema = Schema
