import { z } from 'zod'
import { OfficeHourOrderByWithRelationInputObjectSchema } from './objects/OfficeHourOrderByWithRelationInput.schema'
import { OfficeHourWhereInputObjectSchema } from './objects/OfficeHourWhereInput.schema'
import { OfficeHourWhereUniqueInputObjectSchema } from './objects/OfficeHourWhereUniqueInput.schema'
import { OfficeHourScalarFieldEnumSchema } from './enums/OfficeHourScalarFieldEnum.schema'

export const OfficeHourFindFirstSchema = z.object({
  orderBy: z
    .union([
      OfficeHourOrderByWithRelationInputObjectSchema,
      OfficeHourOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OfficeHourWhereInputObjectSchema.optional(),
  cursor: OfficeHourWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(OfficeHourScalarFieldEnumSchema).optional(),
})
