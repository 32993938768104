import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { CategoryUpdateOneRequiredWithoutProceduresNestedInputObjectSchema } from './CategoryUpdateOneRequiredWithoutProceduresNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateWithoutProcedureInput> = z
  .object({
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    category: z
      .lazy(
        () => CategoryUpdateOneRequiredWithoutProceduresNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProcedureCategoryUpdateWithoutProcedureInputObjectSchema = Schema
