import { z } from 'zod'
import { ProductCreateWithoutCategoriesInputObjectSchema } from './ProductCreateWithoutCategoriesInput.schema'
import { ProductUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProductUncheckedCreateWithoutCategoriesInput.schema'
import { ProductCreateOrConnectWithoutCategoriesInputObjectSchema } from './ProductCreateOrConnectWithoutCategoriesInput.schema'
import { ProductUpsertWithoutCategoriesInputObjectSchema } from './ProductUpsertWithoutCategoriesInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutCategoriesInputObjectSchema } from './ProductUpdateWithoutCategoriesInput.schema'
import { ProductUncheckedUpdateWithoutCategoriesInputObjectSchema } from './ProductUncheckedUpdateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutCategoriesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutCategoriesInputObjectSchema),
          z.lazy(
            () => ProductUncheckedCreateWithoutCategoriesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProductCreateOrConnectWithoutCategoriesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutCategoriesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProductUpdateWithoutCategoriesInputObjectSchema),
          z.lazy(
            () => ProductUncheckedUpdateWithoutCategoriesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutCategoriesNestedInputObjectSchema =
  Schema
