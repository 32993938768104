import { z } from 'zod'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'
import { BrandCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewSummariesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateOrConnectWithoutProductReviewSummariesInput> =
  z
    .object({
      where: z.lazy(() => BrandWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => BrandCreateWithoutProductReviewSummariesInputObjectSchema),
        z.lazy(
          () =>
            BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const BrandCreateOrConnectWithoutProductReviewSummariesInputObjectSchema =
  Schema
