import { z } from 'zod'
import { ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureCreateManyProcedureReviewInputEnvelopeObjectSchema } from './ProcedureReviewPictureCreateManyProcedureReviewInputEnvelope.schema'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './ProcedureReviewPictureWhereUniqueInput.schema'
import { ProcedureReviewPictureUpdateWithWhereUniqueWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUpdateWithWhereUniqueWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureScalarWhereInputObjectSchema } from './ProcedureReviewPictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureUncheckedUpdateManyWithoutProcedureReviewNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureUpsertWithWhereUniqueWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            ProcedureReviewPictureCreateManyProcedureReviewInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureUpdateWithWhereUniqueWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureUpdateWithWhereUniqueWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedureReviewPictureScalarWhereInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewPictureUncheckedUpdateManyWithoutProcedureReviewNestedInputObjectSchema =
  Schema
