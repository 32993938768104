import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { EnumSymptomDurationUnitNullableFilterObjectSchema } from './EnumSymptomDurationUnitNullableFilter.schema'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumCaseStatusFilterObjectSchema } from './EnumCaseStatusFilter.schema'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { EnumConsultingChannelFilterObjectSchema } from './EnumConsultingChannelFilter.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { CaseRelationFilterObjectSchema } from './CaseRelationFilter.schema'
import { CasePictureListRelationFilterObjectSchema } from './CasePictureListRelationFilter.schema'
import { AppointmentRelationFilterObjectSchema } from './AppointmentRelationFilter.schema'
import { AppointmentWhereInputObjectSchema } from './AppointmentWhereInput.schema'
import { TeleconsultRelationFilterObjectSchema } from './TeleconsultRelationFilter.schema'
import { TeleconsultWhereInputObjectSchema } from './TeleconsultWhereInput.schema'
import { TeleconsultListRelationFilterObjectSchema } from './TeleconsultListRelationFilter.schema'
import { CaseSummaryRelationFilterObjectSchema } from './CaseSummaryRelationFilter.schema'
import { CaseSummaryWhereInputObjectSchema } from './CaseSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CaseWhereInputObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CaseWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CaseWhereInputObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    doctorId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    symptom: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    drugAllergy: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    symptomDuration: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    symptomDurationUnit: z
      .union([
        z.lazy(() => EnumSymptomDurationUnitNullableFilterObjectSchema),
        z.lazy(() => SymptomDurationUnitSchema),
      ])
      .optional()
      .nullable(),
    followupCaseId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => EnumCaseStatusFilterObjectSchema),
        z.lazy(() => CaseStatusSchema),
      ])
      .optional(),
    consultingChannel: z
      .union([
        z.lazy(() => EnumConsultingChannelFilterObjectSchema),
        z.lazy(() => ConsultingChannelSchema),
      ])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    v2RecordId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    followupCase: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    previousCase: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    pictures: z
      .lazy(() => CasePictureListRelationFilterObjectSchema)
      .optional(),
    appointment: z
      .union([
        z.lazy(() => AppointmentRelationFilterObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    teleconsult: z
      .union([
        z.lazy(() => TeleconsultRelationFilterObjectSchema),
        z.lazy(() => TeleconsultWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    missedCallTeleconsult: z
      .lazy(() => TeleconsultListRelationFilterObjectSchema)
      .optional(),
    caseSummary: z
      .union([
        z.lazy(() => CaseSummaryRelationFilterObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const CaseWhereInputObjectSchema = Schema
