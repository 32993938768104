import { z } from 'zod'
import { CaseUpdateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseUpdateWithoutMissedCallTeleconsultInput.schema'
import { CaseUncheckedUpdateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseUncheckedUpdateWithoutMissedCallTeleconsultInput.schema'
import { CaseCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseCreateWithoutMissedCallTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutMissedCallTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutMissedCallTeleconsultInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutMissedCallTeleconsultInputObjectSchema),
      z.lazy(
        () => CaseUncheckedUpdateWithoutMissedCallTeleconsultInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutMissedCallTeleconsultInputObjectSchema),
      z.lazy(
        () => CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const CaseUpsertWithoutMissedCallTeleconsultInputObjectSchema = Schema
