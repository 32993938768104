import { z } from 'zod'
import { OldPatientWhereInputObjectSchema } from './objects/OldPatientWhereInput.schema'
import { OldPatientOrderByWithAggregationInputObjectSchema } from './objects/OldPatientOrderByWithAggregationInput.schema'
import { OldPatientScalarWhereWithAggregatesInputObjectSchema } from './objects/OldPatientScalarWhereWithAggregatesInput.schema'
import { OldPatientScalarFieldEnumSchema } from './enums/OldPatientScalarFieldEnum.schema'

export const OldPatientGroupBySchema = z.object({
  where: OldPatientWhereInputObjectSchema.optional(),
  orderBy: z.union([
    OldPatientOrderByWithAggregationInputObjectSchema,
    OldPatientOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: OldPatientScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(OldPatientScalarFieldEnumSchema),
})
