import { Box, Divider, Typography } from '@mui/material'
import axios from 'axios'
import {
  BooleanInput,
  NumberInput,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import {
  CustomSelectInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import { FormDivider } from '../../../components/FormInput/divider'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { countries } from '../constant'

const bannersValidation = (value: unknown[]) => {
  if (value && value.length > 5) {
    return 'Maximum number of banner is 5'
  }

  return undefined
}

export const BrandForm = ({ title }: { title?: string }) => {
  const record = useRecordContext()
  const { setValue } = useFormContext()
  const notify = useNotify()

  const handleIsShowChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const isShow = e.target.checked
    try {
      await adminGatewayClient.productBrand.setIsShow.mutate({
        id: Number(id),
        isShow,
      })
      notify(`Brand is ${isShow ? 'shown' : 'hidden'}`, {
        type: isShow ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('isShow', !isShow)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }
  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {title || 'Create Brand'}
        </Typography>
        {record?.id ? (
          <BooleanInput
            source="isShow"
            onChange={(e) => handleIsShowChanged(record.id, e)}
          />
        ) : null}
      </Box>
      <FormDivider />
      <Box
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          gridGap: '20px',
        }}
      >
        <CustomTextInput source="name" required />
        <CustomTextInput source="coporateName" required />
        <CustomSelectInput
          source="countryName"
          required
          choices={countries}
          sx={{ marginTop: 0 }}
        />
        <NumberInput source="priority" required min={0} defaultValue={0} />
      </Box>
      <Divider sx={{ width: '100%', mb: 2 }} />
      <Box
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          gridGap: '20px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '20%', minWidth: 'fit-content' }}>
          <ImageStorageInput
            source="logo"
            accept={{ 'image/*': [] }}
            canCrop={true}
            cropOption={{ aspect: 1 }}
            upload={async (file, { onProgress, abortController }) => {
              // get upload URL
              const uploadUrlMeta =
                await adminGatewayClient.productBrand.generateUploadPictureUrl.query(
                  {
                    extension: file.name.split('.').pop() ?? '',
                    fieldPath: 'logo',
                  },
                )
              // upload to temp
              await axios.request({
                method: 'PUT',
                url: uploadUrlMeta.uploadUrl,
                headers: {
                  'content-type': file.type,
                },
                data: file,
                onUploadProgress: (progressEvent) => {
                  onProgress(progressEvent.loaded / progressEvent.total)
                },
                signal: abortController.signal,
              })
              return uploadUrlMeta
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ width: '100%', mb: 2 }} />
      <Box
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          gridGap: '20px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <ImageStorageInput
          source="banners"
          label="Banners"
          multiple={true}
          validate={[bannersValidation]}
          accept={{ 'image/*': [] }}
          upload={async (file, { onProgress, abortController }) => {
            // get upload URL
            const uploadUrlMeta =
              await adminGatewayClient.productBrand.generateUploadPictureUrl.query(
                {
                  extension: file.name.split('.').pop() ?? '',
                  fieldPath: 'banner',
                },
              )
            // upload to temp
            await axios.request({
              method: 'PUT',
              url: uploadUrlMeta.uploadUrl,
              headers: {
                'content-type': file.type,
              },
              data: file,
              onUploadProgress: (progressEvent) => {
                onProgress(progressEvent.loaded / progressEvent.total)
              },
              signal: abortController.signal,
            })
            return uploadUrlMeta
          }}
        />
      </Box>
    </>
  )
}
