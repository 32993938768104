import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumExpireTypeFilterObjectSchema } from './EnumExpireTypeFilter.schema'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { OrderRelationFilterObjectSchema } from './OrderRelationFilter.schema'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'
import { RefundRelationFilterObjectSchema } from './RefundRelationFilter.schema'
import { RefundWhereInputObjectSchema } from './RefundWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProcedureItemWhereInputObjectSchema),
        z.lazy(() => ProcedureItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProcedureItemWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProcedureItemWhereInputObjectSchema),
        z.lazy(() => ProcedureItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    partnerId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    picture: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    quantity: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    tagPrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    cost: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    priceAbsorbedBySkinX: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    priceAbsorbedByPartner: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commissionRate: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    flashSaleId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    expireType: z
      .union([
        z.lazy(() => EnumExpireTypeFilterObjectSchema),
        z.lazy(() => ExpireTypeSchema),
      ])
      .optional(),
    expireAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    expireDurationDay: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    refundId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    v2DrugItemId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    reviewId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    reviewStartAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    reviewExpireAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    order: z
      .union([
        z.lazy(() => OrderRelationFilterObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema),
      ])
      .optional(),
    refund: z
      .union([
        z.lazy(() => RefundRelationFilterObjectSchema),
        z.lazy(() => RefundWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ProcedureItemWhereInputObjectSchema = Schema
