import { FileValue } from '../../components/types'

export const transformDoctorBanner = async (form: { picture: FileValue }) => {
  const { picture } = form
  if (picture.uploadPromise) {
    const url = await picture.uploadPromise
    if (url && !Array.isArray(url)) {
      const transformed = {
        ...form,
        picture: {
          ...picture,
          gsPath: url.gsPath,
        },
      }
      return transformed
    }
    throw new Error('Upload failed')
  }
  return form
}
