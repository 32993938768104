import { z } from 'zod'
import { FlashsaleCreateWithoutItemsInputObjectSchema } from './FlashsaleCreateWithoutItemsInput.schema'
import { FlashsaleUncheckedCreateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedCreateWithoutItemsInput.schema'
import { FlashsaleCreateOrConnectWithoutItemsInputObjectSchema } from './FlashsaleCreateOrConnectWithoutItemsInput.schema'
import { FlashsaleUpsertWithoutItemsInputObjectSchema } from './FlashsaleUpsertWithoutItemsInput.schema'
import { FlashsaleWhereUniqueInputObjectSchema } from './FlashsaleWhereUniqueInput.schema'
import { FlashsaleUpdateWithoutItemsInputObjectSchema } from './FlashsaleUpdateWithoutItemsInput.schema'
import { FlashsaleUncheckedUpdateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedUpdateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleUpdateOneRequiredWithoutItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleCreateWithoutItemsInputObjectSchema),
          z.lazy(() => FlashsaleUncheckedCreateWithoutItemsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => FlashsaleCreateOrConnectWithoutItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => FlashsaleUpsertWithoutItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => FlashsaleWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => FlashsaleUpdateWithoutItemsInputObjectSchema),
          z.lazy(() => FlashsaleUncheckedUpdateWithoutItemsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleUpdateOneRequiredWithoutItemsNestedInputObjectSchema =
  Schema
