import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCreateWithoutProcedureReviewInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      url: z.string(),
    })
    .strict()

export const ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema =
  Schema
