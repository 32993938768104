import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { KycDataOrderByWithRelationInputObjectSchema } from './KycDataOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    kycDataId: z.lazy(() => SortOrderSchema).optional(),
    field: z.lazy(() => SortOrderSchema).optional(),
    reason: z.lazy(() => SortOrderSchema).optional(),
    kycData: z
      .lazy(() => KycDataOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const RejectReasonOrderByWithRelationInputObjectSchema = Schema
