import { z } from 'zod'
import { CouponGroupOrderByWithRelationInputObjectSchema } from './objects/CouponGroupOrderByWithRelationInput.schema'
import { CouponGroupWhereInputObjectSchema } from './objects/CouponGroupWhereInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './objects/CouponGroupWhereUniqueInput.schema'
import { CouponGroupCountAggregateInputObjectSchema } from './objects/CouponGroupCountAggregateInput.schema'
import { CouponGroupMinAggregateInputObjectSchema } from './objects/CouponGroupMinAggregateInput.schema'
import { CouponGroupMaxAggregateInputObjectSchema } from './objects/CouponGroupMaxAggregateInput.schema'
import { CouponGroupAvgAggregateInputObjectSchema } from './objects/CouponGroupAvgAggregateInput.schema'
import { CouponGroupSumAggregateInputObjectSchema } from './objects/CouponGroupSumAggregateInput.schema'

export const CouponGroupAggregateSchema = z.object({
  orderBy: z
    .union([
      CouponGroupOrderByWithRelationInputObjectSchema,
      CouponGroupOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CouponGroupWhereInputObjectSchema.optional(),
  cursor: CouponGroupWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CouponGroupCountAggregateInputObjectSchema])
    .optional(),
  _min: CouponGroupMinAggregateInputObjectSchema.optional(),
  _max: CouponGroupMaxAggregateInputObjectSchema.optional(),
  _avg: CouponGroupAvgAggregateInputObjectSchema.optional(),
  _sum: CouponGroupSumAggregateInputObjectSchema.optional(),
})
