import { z } from 'zod'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUncheckedUpdateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(
          () => SuggestedProductItemUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
