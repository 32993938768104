import { z } from 'zod'
import { SkuWhereUniqueInputObjectSchema } from './SkuWhereUniqueInput.schema'
import { SkuUpdateWithoutPartnerInputObjectSchema } from './SkuUpdateWithoutPartnerInput.schema'
import { SkuUncheckedUpdateWithoutPartnerInputObjectSchema } from './SkuUncheckedUpdateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuUpdateWithWhereUniqueWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => SkuWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => SkuUpdateWithoutPartnerInputObjectSchema),
      z.lazy(() => SkuUncheckedUpdateWithoutPartnerInputObjectSchema),
    ]),
  })
  .strict()

export const SkuUpdateWithWhereUniqueWithoutPartnerInputObjectSchema = Schema
