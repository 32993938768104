import { z } from 'zod'
import { IdentityWhereUniqueInputObjectSchema } from './IdentityWhereUniqueInput.schema'
import { IdentityUpdateWithoutPatientInputObjectSchema } from './IdentityUpdateWithoutPatientInput.schema'
import { IdentityUncheckedUpdateWithoutPatientInputObjectSchema } from './IdentityUncheckedUpdateWithoutPatientInput.schema'
import { IdentityCreateWithoutPatientInputObjectSchema } from './IdentityCreateWithoutPatientInput.schema'
import { IdentityUncheckedCreateWithoutPatientInputObjectSchema } from './IdentityUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityUpsertWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => IdentityWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => IdentityUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => IdentityUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema),
        z.lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const IdentityUpsertWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
