import { z } from 'zod'
import { OrderCreateWithoutDeliveriesInputObjectSchema } from './OrderCreateWithoutDeliveriesInput.schema'
import { OrderUncheckedCreateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedCreateWithoutDeliveriesInput.schema'
import { OrderCreateOrConnectWithoutDeliveriesInputObjectSchema } from './OrderCreateOrConnectWithoutDeliveriesInput.schema'
import { OrderUpsertWithoutDeliveriesInputObjectSchema } from './OrderUpsertWithoutDeliveriesInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutDeliveriesInputObjectSchema } from './OrderUpdateWithoutDeliveriesInput.schema'
import { OrderUncheckedUpdateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedUpdateWithoutDeliveriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutDeliveriesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutDeliveriesInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutDeliveriesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutDeliveriesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutDeliveriesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutDeliveriesInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutDeliveriesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutDeliveriesNestedInputObjectSchema =
  Schema
