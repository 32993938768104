import { z } from 'zod'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewCreateOrConnectWithoutPicturesInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureReviewCreateWithoutPicturesInputObjectSchema),
        z.lazy(
          () => ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewCreateOrConnectWithoutPicturesInputObjectSchema =
  Schema
