import { z } from 'zod'
import { VariantCreateWithoutFlashsaleItemInputObjectSchema } from './VariantCreateWithoutFlashsaleItemInput.schema'
import { VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema } from './VariantUncheckedCreateWithoutFlashsaleItemInput.schema'
import { VariantCreateOrConnectWithoutFlashsaleItemInputObjectSchema } from './VariantCreateOrConnectWithoutFlashsaleItemInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateNestedOneWithoutFlashsaleItemInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantCreateWithoutFlashsaleItemInputObjectSchema),
          z.lazy(
            () => VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => VariantCreateOrConnectWithoutFlashsaleItemInputObjectSchema)
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const VariantCreateNestedOneWithoutFlashsaleItemInputObjectSchema =
  Schema
