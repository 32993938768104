import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { PartnerTypeSchema } from '../enums/PartnerType.schema'
import { EnumPartnerTypeFieldUpdateOperationsInputObjectSchema } from './EnumPartnerTypeFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { UserUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema } from './UserUncheckedUpdateManyWithoutPartnerNestedInput.schema'
import { BranchUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema } from './BranchUncheckedUpdateManyWithoutPartnerNestedInput.schema'
import { SkuUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema } from './SkuUncheckedUpdateManyWithoutPartnerNestedInput.schema'
import { ProcedureOrderUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema } from './ProcedureOrderUncheckedUpdateManyWithoutPartnerNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.PartnerUncheckedUpdateWithoutCoverPicturesInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      type: z
        .union([
          z.lazy(() => PartnerTypeSchema),
          z.lazy(() => EnumPartnerTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      nameTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      logo: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      descriptionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      website: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      phone: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isExpertise: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      priority: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isSXEasy: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      searchField: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      taxId: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      v2HospitalId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      v2StoreId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      users: z
        .lazy(
          () => UserUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema,
        )
        .optional(),
      branches: z
        .lazy(
          () => BranchUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema,
        )
        .optional(),
      sku: z
        .lazy(() => SkuUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema)
        .optional(),
      ProcedureOrder: z
        .lazy(
          () =>
            ProcedureOrderUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const PartnerUncheckedUpdateWithoutCoverPicturesInputObjectSchema =
  Schema
