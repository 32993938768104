import { z } from 'zod'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryUpdateWithoutBrandInputObjectSchema } from './ProductReviewSummaryUpdateWithoutBrandInput.schema'
import { ProductReviewSummaryUncheckedUpdateWithoutBrandInputObjectSchema } from './ProductReviewSummaryUncheckedUpdateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUpdateWithWhereUniqueWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewSummaryUpdateWithoutBrandInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedUpdateWithoutBrandInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewSummaryUpdateWithWhereUniqueWithoutBrandInputObjectSchema =
  Schema
