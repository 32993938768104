import { z } from 'zod'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalUpdateWithoutOrderInputObjectSchema } from './ProductItemOriginalUpdateWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedUpdateWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedUpdateWithoutOrderInput.schema'
import { ProductItemOriginalCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductItemOriginalUpdateWithoutOrderInputObjectSchema),
        z.lazy(
          () => ProductItemOriginalUncheckedUpdateWithoutOrderInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema),
        z.lazy(
          () => ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInputObjectSchema =
  Schema
