import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateManyProcedureInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    flashsaleId: z.number(),
    startAt: z.date(),
    endAt: z.date(),
    price: z.number(),
    priceAbsorbedBySkinX: z.number().optional(),
    priceAbsorbedByPartner: z.number().optional(),
    saleLimit: z.number(),
    sold: z.number(),
  })
  .strict()

export const FlashsaleItemCreateManyProcedureInputObjectSchema = Schema
