import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUncheckedCreateWithoutProductInput> =
  z
    .object({
      id: z.number().optional(),
      url: z.string(),
      thumbnail: z.string(),
      rank: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
    .strict()

export const ProductVideoUncheckedCreateWithoutProductInputObjectSchema = Schema
