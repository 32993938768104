export const transformNotification = async (form: { patientIds: string }) => {
  const { patientIds } = form
  const result: { [key: string]: unknown } = form
  if (patientIds) {
    result.patientIds = patientIds.split(',').map((id) => Number(id))
  } else {
    result.patientIds = []
  }
  return result
}
