import { z } from 'zod'
import { RefundCreateWithoutProductItemsInputObjectSchema } from './RefundCreateWithoutProductItemsInput.schema'
import { RefundUncheckedCreateWithoutProductItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProductItemsInput.schema'
import { RefundCreateOrConnectWithoutProductItemsInputObjectSchema } from './RefundCreateOrConnectWithoutProductItemsInput.schema'
import { RefundUpsertWithoutProductItemsInputObjectSchema } from './RefundUpsertWithoutProductItemsInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundUpdateWithoutProductItemsInputObjectSchema } from './RefundUpdateWithoutProductItemsInput.schema'
import { RefundUncheckedUpdateWithoutProductItemsInputObjectSchema } from './RefundUncheckedUpdateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateOneWithoutProductItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => RefundCreateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => RefundUncheckedCreateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => RefundCreateOrConnectWithoutProductItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => RefundUpsertWithoutProductItemsInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => RefundWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => RefundUpdateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => RefundUncheckedUpdateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const RefundUpdateOneWithoutProductItemsNestedInputObjectSchema = Schema
