import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { required, useTranslate } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormTextInput } from '../../../components/FormInput/input'

const tr_resource = 'resources.procedure'

export const ProcedurePropertySection: FC = () => {
  const translate = useTranslate()

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate([tr_resource, 'title', 'property_section'].join('.'))}
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="descriptionTr.th"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="descriptionTr.en"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="technologyTr.th"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="technologyTr.en"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="methodOfUseTr.th" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="methodOfUseTr.en" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="usageTr.th" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="usageTr.en" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="resultPeriodTr.th" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="resultPeriodTr.en" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="preparationTr.th"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="preparationTr.en"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="conditionTr.th"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source="conditionTr.en"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  )
}
