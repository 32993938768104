import { z } from 'zod'
import { WarehouseSelectObjectSchema } from './WarehouseSelect.schema'
import { WarehouseIncludeObjectSchema } from './WarehouseInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseArgs> = z
  .object({
    select: z.lazy(() => WarehouseSelectObjectSchema).optional(),
    include: z.lazy(() => WarehouseIncludeObjectSchema).optional(),
  })
  .strict()

export const WarehouseArgsObjectSchema = Schema
