import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './objects/VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseCreateInputObjectSchema } from './objects/VariantWarehouseCreateInput.schema'
import { VariantWarehouseUncheckedCreateInputObjectSchema } from './objects/VariantWarehouseUncheckedCreateInput.schema'
import { VariantWarehouseUpdateInputObjectSchema } from './objects/VariantWarehouseUpdateInput.schema'
import { VariantWarehouseUncheckedUpdateInputObjectSchema } from './objects/VariantWarehouseUncheckedUpdateInput.schema'

export const VariantWarehouseUpsertSchema = z.object({
  where: VariantWarehouseWhereUniqueInputObjectSchema,
  create: z.union([
    VariantWarehouseCreateInputObjectSchema,
    VariantWarehouseUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    VariantWarehouseUpdateInputObjectSchema,
    VariantWarehouseUncheckedUpdateInputObjectSchema,
  ]),
})
