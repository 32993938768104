import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { EnumNotificationTypeWithAggregatesFilterObjectSchema } from './EnumNotificationTypeWithAggregatesFilter.schema'
import { NotificationTypeSchema } from '../enums/NotificationType.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'
import { StringWithAggregatesFilterObjectSchema } from './StringWithAggregatesFilter.schema'
import { EnumNotificationActionTypeWithAggregatesFilterObjectSchema } from './EnumNotificationActionTypeWithAggregatesFilter.schema'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { IntNullableWithAggregatesFilterObjectSchema } from './IntNullableWithAggregatesFilter.schema'
import { EnumAppointmentEventNullableWithAggregatesFilterObjectSchema } from './EnumAppointmentEventNullableWithAggregatesFilter.schema'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => NotificationScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => NotificationScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => NotificationScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => NotificationScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => NotificationScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    id: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumNotificationTypeWithAggregatesFilterObjectSchema),
        z.lazy(() => NotificationTypeSchema),
      ])
      .optional(),
    picture: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    title: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    message: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    actionType: z
      .union([
        z.lazy(
          () => EnumNotificationActionTypeWithAggregatesFilterObjectSchema,
        ),
        z.lazy(() => NotificationActionTypeSchema),
      ])
      .optional(),
    action: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    onesignalMessageId: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    startAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    appointmentId: z
      .union([
        z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
        z.number(),
      ])
      .optional()
      .nullable(),
    appointmentEvent: z
      .union([
        z.lazy(
          () => EnumAppointmentEventNullableWithAggregatesFilterObjectSchema,
        ),
        z.lazy(() => AppointmentEventSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const NotificationScalarWhereWithAggregatesInputObjectSchema = Schema
