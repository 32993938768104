import * as z from "zod"
import { CompleteCasePicture, RelatedCasePictureModel, CompleteAppointment, RelatedAppointmentModel, CompleteTeleconsult, RelatedTeleconsultModel, CompleteCaseSummary, RelatedCaseSummaryModel } from "./index"

export const CaseModel = z.object({
  id: z.number().int(),
  patientId: z.number().int(),
  doctorId: z.number().int(),
  symptom: z.string(),
  drugAllergy: z.string().nullish(),
  congenitalDisease: z.string().nullish(),
  symptomDuration: z.number().nullish(),
  symptomDurationUnit: z.enum(['DAY', 'MONTH', 'YEAR']).nullish(),
  followupCaseId: z.number().int().nullish(),
  status: z.enum(['CREATED', 'IN_PROGRESS', 'WAIT_TO_SUMMARIZE', 'WAIT_TO_PAYMENT', 'COMPLETED', 'FAILED']),
  consultingChannel: z.enum(['VIDEO', 'CHAT']),
  createdAt: z.date(),
  updatedAt: z.date(),
  v2RecordId: z.number().int().nullish(),
})

export interface CompleteCase extends z.infer<typeof CaseModel> {
  followupCase?: CompleteCase | null
  previousCase?: CompleteCase | null
  pictures: CompleteCasePicture[]
  appointment?: CompleteAppointment | null
  teleconsult?: CompleteTeleconsult | null
  missedCallTeleconsult: CompleteTeleconsult[]
  caseSummary?: CompleteCaseSummary | null
}

/**
 * RelatedCaseModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCaseModel: z.ZodSchema<CompleteCase> = z.lazy(() => CaseModel.extend({
  followupCase: RelatedCaseModel.nullish(),
  previousCase: RelatedCaseModel.nullish(),
  pictures: RelatedCasePictureModel.array(),
  appointment: RelatedAppointmentModel.nullish(),
  teleconsult: RelatedTeleconsultModel.nullish(),
  missedCallTeleconsult: RelatedTeleconsultModel.array(),
  caseSummary: RelatedCaseSummaryModel.nullish(),
}))
