import { z } from 'zod'
import { ConsentWhereInputObjectSchema } from './ConsentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentRelationFilter> = z
  .object({
    is: z.lazy(() => ConsentWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => ConsentWhereInputObjectSchema).optional(),
  })
  .strict()

export const ConsentRelationFilterObjectSchema = Schema
