import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OrderOrderByWithRelationInputObjectSchema } from './OrderOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptOrderByWithRelationInput> = z
  .object({
    receiptNumber: z.lazy(() => SortOrderSchema).optional(),
    receiptUrl: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    receiptData: z.lazy(() => SortOrderSchema).optional(),
    isValid: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    order: z.lazy(() => OrderOrderByWithRelationInputObjectSchema).optional(),
  })
  .strict()

export const ReceiptOrderByWithRelationInputObjectSchema = Schema
