import { z } from 'zod'
import { CouponGroupCreateInputObjectSchema } from './objects/CouponGroupCreateInput.schema'
import { CouponGroupUncheckedCreateInputObjectSchema } from './objects/CouponGroupUncheckedCreateInput.schema'

export const CouponGroupCreateOneSchema = z.object({
  data: z.union([
    CouponGroupCreateInputObjectSchema,
    CouponGroupUncheckedCreateInputObjectSchema,
  ]),
})
