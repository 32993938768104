import { z } from 'zod'
import { DeliveryLogScalarWhereInputObjectSchema } from './DeliveryLogScalarWhereInput.schema'
import { DeliveryLogUpdateManyMutationInputObjectSchema } from './DeliveryLogUpdateManyMutationInput.schema'
import { DeliveryLogUncheckedUpdateManyWithoutDeliveryLogsInputObjectSchema } from './DeliveryLogUncheckedUpdateManyWithoutDeliveryLogsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogUpdateManyWithWhereWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => DeliveryLogScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => DeliveryLogUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            DeliveryLogUncheckedUpdateManyWithoutDeliveryLogsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DeliveryLogUpdateManyWithWhereWithoutDeliveryInputObjectSchema =
  Schema
