import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NullableEnumShippingTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => ShippingTypeSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumShippingTypeFieldUpdateOperationsInputObjectSchema =
  Schema
