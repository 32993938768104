import { z } from 'zod'
import { PatientCreateWithoutIdentitiesInputObjectSchema } from './PatientCreateWithoutIdentitiesInput.schema'
import { PatientUncheckedCreateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedCreateWithoutIdentitiesInput.schema'
import { PatientCreateOrConnectWithoutIdentitiesInputObjectSchema } from './PatientCreateOrConnectWithoutIdentitiesInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateNestedOneWithoutIdentitiesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PatientCreateWithoutIdentitiesInputObjectSchema),
        z.lazy(() => PatientUncheckedCreateWithoutIdentitiesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PatientCreateOrConnectWithoutIdentitiesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PatientCreateNestedOneWithoutIdentitiesInputObjectSchema = Schema
