import { z } from 'zod'
import { ProcedureCreateWithoutCategoriesInputObjectSchema } from './ProcedureCreateWithoutCategoriesInput.schema'
import { ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedCreateWithoutCategoriesInput.schema'
import { ProcedureCreateOrConnectWithoutCategoriesInputObjectSchema } from './ProcedureCreateOrConnectWithoutCategoriesInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateNestedOneWithoutCategoriesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutCategoriesInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProcedureCreateOrConnectWithoutCategoriesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProcedureCreateNestedOneWithoutCategoriesInputObjectSchema = Schema
