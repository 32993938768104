import { z } from 'zod'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateManyMissedCallCaseInput> = z
  .object({
    id: z.number().optional(),
    doctorId: z.number(),
    patientId: z.number(),
    videoRoomId: z.string().optional().nullable(),
    doctorVideoRoomUid: z.number().optional().nullable(),
    patientVideoRoomUid: z.number().optional().nullable(),
    startAt: z.date().optional().nullable(),
    endAt: z.date().optional().nullable(),
    callTime: z.number().optional().nullable(),
    status: z.lazy(() => ConsultingStatusSchema),
    caseId: z.number().optional().nullable(),
    remark: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const TeleconsultCreateManyMissedCallCaseInputObjectSchema = Schema
