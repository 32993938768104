import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { NullableEnumUsageFieldUpdateOperationsInputObjectSchema } from './NullableEnumUsageFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableEnumUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUnitFieldUpdateOperationsInput.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseFrequencyFieldUpdateOperationsInputObjectSchema } from './EnumUseFrequencyFieldUpdateOperationsInput.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { EnumUseTimeFieldUpdateOperationsInputObjectSchema } from './EnumUseTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'
import { NullableEnumUseDurationUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUseDurationUnitFieldUpdateOperationsInput.schema'
import { RefillTimeUnitSchema } from '../enums/RefillTimeUnit.schema'
import { NullableEnumRefillTimeUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumRefillTimeUnitFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.SuggestedProductItemUpdateManyMutationInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    productVariantId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    amount: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    usage: z
      .union([
        z.lazy(() => UsageSchema),
        z.lazy(() => NullableEnumUsageFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    useAmount: z
      .union([
        z.number(),
        z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    useUnit: z
      .union([
        z.lazy(() => UnitSchema),
        z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    useFrequency: z
      .union([
        z.lazy(() => UseFrequencySchema),
        z.lazy(() => EnumUseFrequencyFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    useTime: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => EnumUseTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    useDuration: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    useDurationUnit: z
      .union([
        z.lazy(() => UseDurationUnitSchema),
        z.lazy(
          () =>
            NullableEnumUseDurationUnitFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    refillTime: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    refillTimeUnit: z
      .union([
        z.lazy(() => RefillTimeUnitSchema),
        z.lazy(
          () =>
            NullableEnumRefillTimeUnitFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    note: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    warehouse: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
  })
  .strict()

export const SuggestedProductItemUpdateManyMutationInputObjectSchema = Schema
