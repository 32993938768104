import { z } from 'zod'
import { BrandCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewSummariesInput.schema'
import { BrandCreateOrConnectWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateOrConnectWithoutProductReviewSummariesInput.schema'
import { BrandUpsertWithoutProductReviewSummariesInputObjectSchema } from './BrandUpsertWithoutProductReviewSummariesInput.schema'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'
import { BrandUpdateWithoutProductReviewSummariesInputObjectSchema } from './BrandUpdateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedUpdateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedUpdateWithoutProductReviewSummariesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpdateOneRequiredWithoutProductReviewSummariesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => BrandCreateWithoutProductReviewSummariesInputObjectSchema,
          ),
          z.lazy(
            () =>
              BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            BrandCreateOrConnectWithoutProductReviewSummariesInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => BrandUpsertWithoutProductReviewSummariesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => BrandWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => BrandUpdateWithoutProductReviewSummariesInputObjectSchema,
          ),
          z.lazy(
            () =>
              BrandUncheckedUpdateWithoutProductReviewSummariesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const BrandUpdateOneRequiredWithoutProductReviewSummariesNestedInputObjectSchema =
  Schema
