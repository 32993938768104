import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { ScheduleArgsObjectSchema } from './ScheduleArgs.schema'
import { TeleconsultArgsObjectSchema } from './TeleconsultArgs.schema'
import { CaseSummaryArgsObjectSchema } from './CaseSummaryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentSelect> = z
  .object({
    id: z.boolean().optional(),
    doctorId: z.boolean().optional(),
    patientId: z.boolean().optional(),
    type: z.boolean().optional(),
    status: z.boolean().optional(),
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    caseId: z.boolean().optional(),
    schedule: z
      .union([z.boolean(), z.lazy(() => ScheduleArgsObjectSchema)])
      .optional(),
    scheduleId: z.boolean().optional(),
    appointmentAt: z.boolean().optional(),
    cancelAt: z.boolean().optional(),
    cancelDetail: z.boolean().optional(),
    teleconsult: z
      .union([z.boolean(), z.lazy(() => TeleconsultArgsObjectSchema)])
      .optional(),
    teleconsultId: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    followUpCaseSummary: z
      .union([z.boolean(), z.lazy(() => CaseSummaryArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const AppointmentSelectObjectSchema = Schema
