import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummarySumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    doctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    commissionPrice: z.lazy(() => SortOrderSchema).optional(),
    netDoctorFee: z.lazy(() => SortOrderSchema).optional(),
    followUpAppointmentId: z.lazy(() => SortOrderSchema).optional(),
    recommedNumberOfTreatment: z.lazy(() => SortOrderSchema).optional(),
    editedSuggestedProductItemBy: z.lazy(() => SortOrderSchema).optional(),
    v2CaseSummaryId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const CaseSummarySumOrderByAggregateInputObjectSchema = Schema
