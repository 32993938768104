import { z } from 'zod'

export const BranchScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'nameTr',
  'address',
  'subDistrict',
  'subDistrictKey',
  'district',
  'districtKey',
  'province',
  'provinceKey',
  'postcode',
  'googleMapUrl',
  'lat',
  'long',
  'phone',
  'active',
  'isExpertise',
  'remark',
  'partnerId',
  'v2BranchId',
])
