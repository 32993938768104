import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    name: z.literal(true).optional(),
    isShow: z.literal(true).optional(),
    coporateName: z.literal(true).optional(),
    countryName: z.literal(true).optional(),
    logo: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const BrandCountAggregateInputObjectSchema = Schema
