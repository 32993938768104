import { Box, Grid, Typography } from '@mui/material'
import { ProductTypeSchema, SalesTypeSchema } from '@ssch-backend/products'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC } from 'react'
import {
  BooleanInput,
  OptionTextFunc,
  ReferenceInput,
  required,
  useGetList,
  useLocaleState,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteArrayInput,
  FormAutoCompleteInput,
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import {
  getProductSalesTypeLabel,
  getProductTypeLabel,
} from '../../../enumLabel'
import { adminGatewayClient, isTRPCClientError } from '../../../service'

export const ProductInfoSection: FC = () => {
  const [locale] = useLocaleState()
  const { data: dataCategory, isLoading: loadingCategory } = useGetList(
    'productCategory',
    {
      pagination: { page: 1, perPage: 999 },
    },
  )
  const record = useRecordContext()
  const notify = useNotify()
  const { setValue } = useFormContext()

  const optionTextCategory: OptionTextFunc = (choice) => {
    const parentCategory = dataCategory?.find(
      (element) => element.id === choice.parentId,
    )

    return `${getTr(parentCategory.nameTr, locale as Language)}: ${getTr(
      choice.nameTr,
      locale as Language,
    )}`
  }

  const filterToQuery = (optionText: string) => (searchText: string) => {
    const filterObject = {} as { [key: string]: string }
    filterObject[optionText] = searchText
    return filterObject
  }

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const active = e.target.checked
    try {
      await adminGatewayClient.product.setActive.mutate({
        id: Number(id),
        active,
      })
      notify(`Product is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', !active)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const handleSearchableChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const searchable = e.target.checked
    try {
      await adminGatewayClient.product.setSearchable.mutate({
        id: Number(id),
        searchable,
      })
      notify(
        `Product is set ${searchable ? 'able to search' : 'unable to search'}`,
        {
          type: searchable ? 'success' : 'warning',
        },
      )
    } catch (e) {
      setValue('searchable', !searchable)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Product Info.
        </Typography>
        {record?.id && (
          <Box display={'flex'}>
            <BooleanInput
              source="searchable"
              onChange={(e) => handleSearchableChanged(record.id, e)}
            />
            <BooleanInput
              source="active"
              onChange={(e) => handleActiveChanged(record.id, e)}
            />
          </Box>
        )}
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อสินค้า"
            source="nameTr.th"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อสินค้า (Eng.)"
            source="nameTr.en"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="เลขทะเบียน / อย."
            source="regNo"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReferenceInput
            source="brandId"
            reference="productBrand"
            sort={{ field: 'id', order: 'ASC' }}
          >
            <FormAutoCompleteInput
              fullWidth
              optionText="name"
              label="แบรนด์"
              debounce={100}
              filterToQuery={filterToQuery('name')}
              validate={required()}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormSelectInput
            label={'ประเภทสินค้า'}
            source="type"
            validate={required()}
            choices={Object.values(ProductTypeSchema.Enum).map((option) => ({
              id: option,
              name: getProductTypeLabel(
                option as keyof typeof ProductTypeSchema.Enum,
              )[locale === 'th' ? 'th' : 'en'],
            }))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutoCompleteArrayInput
            fullWidth
            filterSelectedOptions={true}
            source="categories"
            choices={dataCategory
              ?.filter((c) => c.parentId && c.active)
              .sort((a, b) => {
                if (a.parentId !== b.parentId) {
                  return a.parentId - b.parentId
                }
                return b.priority - a.priority
              })}
            loading={loadingCategory}
            optionText={optionTextCategory}
            parse={(value) => value && value.map((v: number) => ({ id: v }))}
            format={(value) => value && value.map((v: { id: number }) => v.id)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormNumberInput label={'Priority'} source="priority" min={0} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormSelectInput
            label={'ประเภทการขาย'}
            source="salesType"
            validate={required()}
            choices={Object.values(SalesTypeSchema.Enum).map((option) => ({
              id: option,
              name: getProductSalesTypeLabel(
                option as keyof typeof SalesTypeSchema.Enum,
              )[locale === 'th' ? 'th' : 'en'],
            }))}
          />
        </Grid>
      </Grid>
    </>
  )
}
