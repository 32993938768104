import { z } from 'zod'
import { ShippingProviderWhereUniqueInputObjectSchema } from './ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderCreateWithoutAreasInputObjectSchema } from './ShippingProviderCreateWithoutAreasInput.schema'
import { ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutAreasInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCreateOrConnectWithoutAreasInput> =
  z
    .object({
      where: z.lazy(() => ShippingProviderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ShippingProviderCreateWithoutAreasInputObjectSchema),
        z.lazy(
          () => ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ShippingProviderCreateOrConnectWithoutAreasInputObjectSchema =
  Schema
