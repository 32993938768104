import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { HolidayCountOrderByAggregateInputObjectSchema } from './HolidayCountOrderByAggregateInput.schema'
import { HolidayAvgOrderByAggregateInputObjectSchema } from './HolidayAvgOrderByAggregateInput.schema'
import { HolidayMaxOrderByAggregateInputObjectSchema } from './HolidayMaxOrderByAggregateInput.schema'
import { HolidayMinOrderByAggregateInputObjectSchema } from './HolidayMinOrderByAggregateInput.schema'
import { HolidaySumOrderByAggregateInputObjectSchema } from './HolidaySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.HolidayOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    date: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => HolidayCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => HolidayAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => HolidayMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => HolidayMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => HolidaySumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const HolidayOrderByWithAggregationInputObjectSchema = Schema
