import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { VariantInfoSection } from './create-edit-section/variantInfo'
import { VariantPictureSecion } from './create-edit-section/variantPicture'
import { transformProductVariant } from './transform'

const ProductVariantCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="productVariant" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProductVariantCreateForm = () => {
  return (
    <SimpleForm toolbar={<ProductVariantCreateToolbar />}>
      <VariantInfoSection title="Add SKU" />
      <VariantPictureSecion />
    </SimpleForm>
  )
}

export const ProductVariantCreate = () => (
  <CreateSaveRouteBack transform={transformProductVariant} redirect="list">
    <Card>
      <CardContent sx={{ p: 2 }}>
        <ProductVariantCreateForm />
      </CardContent>
    </Card>
  </CreateSaveRouteBack>
)
