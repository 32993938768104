import { z } from 'zod'

export const UseFrequencySchema = z.enum([
  'ONCE_DAILY',
  'ONCE_DAILY_AFTER_MEAL',
  'ONCE_DAILY_BEFORE_MEAL',
  'ONCE_DAILY_WITH_MEAL',
  'TWICE_DAILY',
  'TWICE_DAILY_AFTER_MEAL',
  'TWICE_DAILY_BEFORE_MEAL',
  'TWICE_DAILY_WITH_MEAL',
  'THRICE_DAILY',
  'THRICE_DAILY_AFTER_MEAL',
  'THRICE_DAILY_BEFORE_MEAL',
  'THRICE_DAILY_WITH_MEAL',
  'FOUR_TIMES_DAILY',
  'FOUR_TIMES_DAILY_AFTER_MEAL',
  'FOUR_TIMES_DAILY_BEFORE_MEAL',
  'FOUR_TIMES_DAILY_WITH_MEAL',
  'FIVE_TIMES_DAILY',
  'EVERY_OTHER_DAY',
  'ONCE_A_WEEK',
  'TWICE_A_WEEK',
  'THRICE_A_WEEK',
  'EVERY_4_TO_6_HR',
  'EVERY_4_HR',
  'EVERY_6_HR',
  'EVERY_8_HR',
  'EVERY_12_HR',
  'EVERY_24_HR',
  'NA',
])
