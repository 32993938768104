import { z } from 'zod'
import { BranchSelectObjectSchema } from './BranchSelect.schema'
import { BranchIncludeObjectSchema } from './BranchInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchArgs> = z
  .object({
    select: z.lazy(() => BranchSelectObjectSchema).optional(),
    include: z.lazy(() => BranchIncludeObjectSchema).optional(),
  })
  .strict()

export const BranchArgsObjectSchema = Schema
