import { z } from 'zod'
import { OrderNotificationOrderByWithRelationInputObjectSchema } from './objects/OrderNotificationOrderByWithRelationInput.schema'
import { OrderNotificationWhereInputObjectSchema } from './objects/OrderNotificationWhereInput.schema'
import { OrderNotificationWhereUniqueInputObjectSchema } from './objects/OrderNotificationWhereUniqueInput.schema'
import { OrderNotificationScalarFieldEnumSchema } from './enums/OrderNotificationScalarFieldEnum.schema'

export const OrderNotificationFindManySchema = z.object({
  orderBy: z
    .union([
      OrderNotificationOrderByWithRelationInputObjectSchema,
      OrderNotificationOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OrderNotificationWhereInputObjectSchema.optional(),
  cursor: OrderNotificationWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(OrderNotificationScalarFieldEnumSchema).optional(),
})
