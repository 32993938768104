import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutHealthInfoInputObjectSchema } from './OrderCreateWithoutHealthInfoInput.schema'
import { OrderUncheckedCreateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedCreateWithoutHealthInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutHealthInfoInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutHealthInfoInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutHealthInfoInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutHealthInfoInputObjectSchema = Schema
