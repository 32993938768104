import { z } from 'zod'
import { SalesTypeSchema } from '../enums/SalesType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumSalesTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => SalesTypeSchema).optional(),
  })
  .strict()

export const EnumSalesTypeFieldUpdateOperationsInputObjectSchema = Schema
