import { z } from 'zod'
import { OrderUpdateWithoutDoctorItemInputObjectSchema } from './OrderUpdateWithoutDoctorItemInput.schema'
import { OrderUncheckedUpdateWithoutDoctorItemInputObjectSchema } from './OrderUncheckedUpdateWithoutDoctorItemInput.schema'
import { OrderCreateWithoutDoctorItemInputObjectSchema } from './OrderCreateWithoutDoctorItemInput.schema'
import { OrderUncheckedCreateWithoutDoctorItemInputObjectSchema } from './OrderUncheckedCreateWithoutDoctorItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutDoctorItemInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutDoctorItemInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutDoctorItemInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutDoctorItemInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutDoctorItemInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutDoctorItemInputObjectSchema = Schema
