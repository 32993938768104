import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.OldPatientSelect> = z
  .object({
    patientId: z.boolean().optional(),
  })
  .strict()

export const OldPatientSelectObjectSchema = Schema
