import { z } from 'zod'
import { NotificationPatientCreateWithoutNotificationInputObjectSchema } from './NotificationPatientCreateWithoutNotificationInput.schema'
import { NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutNotificationInput.schema'
import { NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema } from './NotificationPatientCreateOrConnectWithoutNotificationInput.schema'
import { NotificationPatientUpsertWithWhereUniqueWithoutNotificationInputObjectSchema } from './NotificationPatientUpsertWithWhereUniqueWithoutNotificationInput.schema'
import { NotificationPatientCreateManyNotificationInputEnvelopeObjectSchema } from './NotificationPatientCreateManyNotificationInputEnvelope.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientUpdateWithWhereUniqueWithoutNotificationInputObjectSchema } from './NotificationPatientUpdateWithWhereUniqueWithoutNotificationInput.schema'
import { NotificationPatientUpdateManyWithWhereWithoutNotificationInputObjectSchema } from './NotificationPatientUpdateManyWithWhereWithoutNotificationInput.schema'
import { NotificationPatientScalarWhereInputObjectSchema } from './NotificationPatientScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUncheckedUpdateManyWithoutNotificationNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => NotificationPatientCreateWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateWithoutNotificationInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateOrConnectWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpsertWithWhereUniqueWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpsertWithWhereUniqueWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            NotificationPatientCreateManyNotificationInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpdateWithWhereUniqueWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpdateWithWhereUniqueWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpdateManyWithWhereWithoutNotificationInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpdateManyWithWhereWithoutNotificationInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => NotificationPatientScalarWhereInputObjectSchema),
          z.lazy(() => NotificationPatientScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationPatientUncheckedUpdateManyWithoutNotificationNestedInputObjectSchema =
  Schema
