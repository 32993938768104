import { z } from 'zod'
import { ProcedurePictureCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateWithoutProcedureInput.schema'
import { ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureUncheckedCreateWithoutProcedureInput.schema'
import { ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateOrConnectWithoutProcedureInput.schema'
import { ProcedurePictureCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedurePictureCreateManyProcedureInputEnvelope.schema'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './ProcedurePictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureCreateNestedManyWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedurePictureCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => ProcedurePictureCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedurePictureCreateManyProcedureInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedurePictureCreateNestedManyWithoutProcedureInputObjectSchema =
  Schema
