import { z } from 'zod'
import { WarehouseWhereUniqueInputObjectSchema } from './WarehouseWhereUniqueInput.schema'
import { WarehouseCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedCreateWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCreateOrConnectWithoutVariantWarehousesInput> =
  z
    .object({
      where: z.lazy(() => WarehouseWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => WarehouseCreateWithoutVariantWarehousesInputObjectSchema),
        z.lazy(
          () =>
            WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const WarehouseCreateOrConnectWithoutVariantWarehousesInputObjectSchema =
  Schema
