import { z } from 'zod'
import { BranchUpdateWithoutOfficeHoursInputObjectSchema } from './BranchUpdateWithoutOfficeHoursInput.schema'
import { BranchUncheckedUpdateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedUpdateWithoutOfficeHoursInput.schema'
import { BranchCreateWithoutOfficeHoursInputObjectSchema } from './BranchCreateWithoutOfficeHoursInput.schema'
import { BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedCreateWithoutOfficeHoursInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpsertWithoutOfficeHoursInput> = z
  .object({
    update: z.union([
      z.lazy(() => BranchUpdateWithoutOfficeHoursInputObjectSchema),
      z.lazy(() => BranchUncheckedUpdateWithoutOfficeHoursInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => BranchCreateWithoutOfficeHoursInputObjectSchema),
      z.lazy(() => BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema),
    ]),
  })
  .strict()

export const BranchUpsertWithoutOfficeHoursInputObjectSchema = Schema
