import { z } from 'zod'
import { ProductReviewSummaryOrderByWithRelationInputObjectSchema } from './objects/ProductReviewSummaryOrderByWithRelationInput.schema'
import { ProductReviewSummaryWhereInputObjectSchema } from './objects/ProductReviewSummaryWhereInput.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryScalarFieldEnumSchema } from './enums/ProductReviewSummaryScalarFieldEnum.schema'

export const ProductReviewSummaryFindManySchema = z.object({
  orderBy: z
    .union([
      ProductReviewSummaryOrderByWithRelationInputObjectSchema,
      ProductReviewSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewSummaryWhereInputObjectSchema.optional(),
  cursor: ProductReviewSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductReviewSummaryScalarFieldEnumSchema).optional(),
})
