import { z } from 'zod'
import { SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateManyCaseSummaryInputEnvelopeObjectSchema } from './SuggestedProcedureItemCreateManyCaseSummaryInputEnvelope.schema'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './SuggestedProcedureItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUncheckedCreateNestedManyWithoutCaseSummaryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            SuggestedProcedureItemCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const SuggestedProcedureItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema =
  Schema
