import { z } from 'zod'
import { DiagnosisWhereUniqueInputObjectSchema } from './DiagnosisWhereUniqueInput.schema'
import { DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      where: z.lazy(() => DiagnosisWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
