import { z } from 'zod'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumCoinTransactionStatusFilterObjectSchema } from './NestedEnumCoinTransactionStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumCoinTransactionStatusWithAggregatesFilter> =
  z
    .object({
      equals: z.lazy(() => CoinTransactionStatusSchema).optional(),
      in: z
        .lazy(() => CoinTransactionStatusSchema)
        .array()
        .optional(),
      notIn: z
        .lazy(() => CoinTransactionStatusSchema)
        .array()
        .optional(),
      not: z
        .union([
          z.lazy(() => CoinTransactionStatusSchema),
          z.lazy(
            () =>
              NestedEnumCoinTransactionStatusWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional(),
      _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumCoinTransactionStatusFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumCoinTransactionStatusFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumCoinTransactionStatusWithAggregatesFilterObjectSchema =
  Schema
