import { z } from 'zod'
import { MyReviewCreateInputObjectSchema } from './objects/MyReviewCreateInput.schema'
import { MyReviewUncheckedCreateInputObjectSchema } from './objects/MyReviewUncheckedCreateInput.schema'

export const MyReviewCreateOneSchema = z.object({
  data: z.union([
    MyReviewCreateInputObjectSchema,
    MyReviewUncheckedCreateInputObjectSchema,
  ]),
})
