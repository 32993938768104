import { z } from 'zod'
import { MyReviewOrderItemIdOrderItemTypeCompoundUniqueInputObjectSchema } from './MyReviewOrderItemIdOrderItemTypeCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    orderItemId_orderItemType: z
      .lazy(
        () => MyReviewOrderItemIdOrderItemTypeCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const MyReviewWhereUniqueInputObjectSchema = Schema
