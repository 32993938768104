import { z } from 'zod'
import { BrandBannerCreateInputObjectSchema } from './objects/BrandBannerCreateInput.schema'
import { BrandBannerUncheckedCreateInputObjectSchema } from './objects/BrandBannerUncheckedCreateInput.schema'

export const BrandBannerCreateOneSchema = z.object({
  data: z.union([
    BrandBannerCreateInputObjectSchema,
    BrandBannerUncheckedCreateInputObjectSchema,
  ]),
})
