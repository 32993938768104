import { z } from 'zod'
import { ExpressShippingAreaScalarWhereInputObjectSchema } from './ExpressShippingAreaScalarWhereInput.schema'
import { ExpressShippingAreaUpdateManyMutationInputObjectSchema } from './ExpressShippingAreaUpdateManyMutationInput.schema'
import { ExpressShippingAreaUncheckedUpdateManyWithoutAreasInputObjectSchema } from './ExpressShippingAreaUncheckedUpdateManyWithoutAreasInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => ExpressShippingAreaScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ExpressShippingAreaUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ExpressShippingAreaUncheckedUpdateManyWithoutAreasInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInputObjectSchema =
  Schema
