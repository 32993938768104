import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deliveryId: z.lazy(() => SortOrderSchema).optional(),
    statusCode: z.lazy(() => SortOrderSchema).optional(),
    statusText: z.lazy(() => SortOrderSchema).optional(),
    statusDate: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const DeliveryLogMinOrderByAggregateInputObjectSchema = Schema
