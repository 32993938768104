import { z } from 'zod'
import { AcceptedConsentCreateWithoutConsentInputObjectSchema } from './AcceptedConsentCreateWithoutConsentInput.schema'
import { AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema } from './AcceptedConsentUncheckedCreateWithoutConsentInput.schema'
import { AcceptedConsentCreateOrConnectWithoutConsentInputObjectSchema } from './AcceptedConsentCreateOrConnectWithoutConsentInput.schema'
import { AcceptedConsentCreateManyConsentInputEnvelopeObjectSchema } from './AcceptedConsentCreateManyConsentInputEnvelope.schema'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateNestedManyWithoutConsentInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AcceptedConsentCreateWithoutConsentInputObjectSchema),
          z
            .lazy(() => AcceptedConsentCreateWithoutConsentInputObjectSchema)
            .array(),
          z.lazy(
            () => AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => AcceptedConsentCreateOrConnectWithoutConsentInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentCreateOrConnectWithoutConsentInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => AcceptedConsentCreateManyConsentInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const AcceptedConsentCreateNestedManyWithoutConsentInputObjectSchema =
  Schema
