import { z } from 'zod'
import { PatientUpdateWithoutNotificationsInputObjectSchema } from './PatientUpdateWithoutNotificationsInput.schema'
import { PatientUncheckedUpdateWithoutNotificationsInputObjectSchema } from './PatientUncheckedUpdateWithoutNotificationsInput.schema'
import { PatientCreateWithoutNotificationsInputObjectSchema } from './PatientCreateWithoutNotificationsInput.schema'
import { PatientUncheckedCreateWithoutNotificationsInputObjectSchema } from './PatientUncheckedCreateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutNotificationsInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutNotificationsInputObjectSchema),
      z.lazy(() => PatientUncheckedUpdateWithoutNotificationsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutNotificationsInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutNotificationsInputObjectSchema),
    ]),
  })
  .strict()

export const PatientUpsertWithoutNotificationsInputObjectSchema = Schema
