import { z } from 'zod'
import { ConditionSettingUpdateInputObjectSchema } from './objects/ConditionSettingUpdateInput.schema'
import { ConditionSettingUncheckedUpdateInputObjectSchema } from './objects/ConditionSettingUncheckedUpdateInput.schema'
import { ConditionSettingWhereUniqueInputObjectSchema } from './objects/ConditionSettingWhereUniqueInput.schema'

export const ConditionSettingUpdateOneSchema = z.object({
  data: z.union([
    ConditionSettingUpdateInputObjectSchema,
    ConditionSettingUncheckedUpdateInputObjectSchema,
  ]),
  where: ConditionSettingWhereUniqueInputObjectSchema,
})
