import { z } from 'zod'
import { ProductReviewScalarWhereInputObjectSchema } from './ProductReviewScalarWhereInput.schema'
import { ProductReviewUpdateManyMutationInputObjectSchema } from './ProductReviewUpdateManyMutationInput.schema'
import { ProductReviewUncheckedUpdateManyWithoutProductReviewsInputObjectSchema } from './ProductReviewUncheckedUpdateManyWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateManyWithWhereWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewUncheckedUpdateManyWithoutProductReviewsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewUpdateManyWithWhereWithoutBrandInputObjectSchema =
  Schema
