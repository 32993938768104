import { z } from 'zod'
import { CaseSummaryUpdateWithoutCaseInputObjectSchema } from './CaseSummaryUpdateWithoutCaseInput.schema'
import { CaseSummaryUncheckedUpdateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutCaseInput.schema'
import { CaseSummaryCreateWithoutCaseInputObjectSchema } from './CaseSummaryCreateWithoutCaseInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutCaseInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseSummaryUpdateWithoutCaseInputObjectSchema),
      z.lazy(() => CaseSummaryUncheckedUpdateWithoutCaseInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseSummaryCreateWithoutCaseInputObjectSchema),
      z.lazy(() => CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseSummaryUpsertWithoutCaseInputObjectSchema = Schema
