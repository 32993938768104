import { z } from 'zod'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.DiscountSettingUncheckedCreateWithoutGroupInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      scope: z.lazy(() => DiscountScopeSchema),
      itemType: z.lazy(() => DiscountItemTypeSchema),
      items: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      settingGroupId: z.string().optional().nullable(),
    })
    .strict()

export const DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema =
  Schema
