import { Box, Button } from '@mui/material'
import { useListContext } from 'react-admin'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CustomTextInput } from '../../../components/CustomInput'
import { ReferencePartner } from '../../../components/Reference/ReferencePartner'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      email: undefined,
      partnerId: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }

  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          email: {
            formKey: 'email',
          },
          partnerId: {
            formKey: 'partnerId',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="email"
            label="ค้นหา..."
          />
          <Box sx={{ flexGrow: 1, paddingTop: '0.75rem' }}>
            <ReferencePartner />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
