import { z } from 'zod'

export const NotificationScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'type',
  'picture',
  'title',
  'message',
  'actionType',
  'action',
  'onesignalMessageId',
  'startAt',
  'appointmentId',
  'appointmentEvent',
])
