import { z } from 'zod'
import { OtpOrderByWithRelationInputObjectSchema } from './objects/OtpOrderByWithRelationInput.schema'
import { OtpWhereInputObjectSchema } from './objects/OtpWhereInput.schema'
import { OtpWhereUniqueInputObjectSchema } from './objects/OtpWhereUniqueInput.schema'
import { OtpScalarFieldEnumSchema } from './enums/OtpScalarFieldEnum.schema'

export const OtpFindFirstSchema = z.object({
  orderBy: z
    .union([
      OtpOrderByWithRelationInputObjectSchema,
      OtpOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OtpWhereInputObjectSchema.optional(),
  cursor: OtpWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(OtpScalarFieldEnumSchema).optional(),
})
