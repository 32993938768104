import { z } from 'zod'
import { ProcedureReviewSummaryOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewSummaryOrderByWithRelationInput.schema'
import { ProcedureReviewSummaryWhereInputObjectSchema } from './objects/ProcedureReviewSummaryWhereInput.schema'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProcedureReviewSummaryWhereUniqueInput.schema'
import { ProcedureReviewSummaryCountAggregateInputObjectSchema } from './objects/ProcedureReviewSummaryCountAggregateInput.schema'
import { ProcedureReviewSummaryMinAggregateInputObjectSchema } from './objects/ProcedureReviewSummaryMinAggregateInput.schema'
import { ProcedureReviewSummaryMaxAggregateInputObjectSchema } from './objects/ProcedureReviewSummaryMaxAggregateInput.schema'
import { ProcedureReviewSummaryAvgAggregateInputObjectSchema } from './objects/ProcedureReviewSummaryAvgAggregateInput.schema'

export const ProcedureReviewSummaryAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewSummaryOrderByWithRelationInputObjectSchema,
      ProcedureReviewSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewSummaryWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ProcedureReviewSummaryCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ProcedureReviewSummaryMinAggregateInputObjectSchema.optional(),
  _max: ProcedureReviewSummaryMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureReviewSummaryAvgAggregateInputObjectSchema.optional(),
})
