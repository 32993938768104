import * as z from "zod"
import { CompleteProductItem, RelatedProductItemModel, CompleteProcedureItem, RelatedProcedureItemModel, CompleteOrder, RelatedOrderModel } from "./index"

export const RefundModel = z.object({
  id: z.number().int(),
  amount: z.number(),
  currency: z.string(),
  refundAt: z.date(),
  paymentGateway: z.enum(['OMISE', 'GBPRIME', 'MPAY', 'OFFLINE']),
  status: z.enum(['IN_PROGRESS', 'SUCCESS']),
  reason: z.string(),
  orderId: z.number().int(),
})

export interface CompleteRefund extends z.infer<typeof RefundModel> {
  productItems: CompleteProductItem[]
  procedureItems: CompleteProcedureItem[]
  order: CompleteOrder
}

/**
 * RelatedRefundModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedRefundModel: z.ZodSchema<CompleteRefund> = z.lazy(() => RefundModel.extend({
  productItems: RelatedProductItemModel.array(),
  procedureItems: RelatedProcedureItemModel.array(),
  order: RelatedOrderModel,
}))
