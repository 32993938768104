import { z } from 'zod'

export const DeliveryLogScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'deliveryId',
  'statusCode',
  'statusText',
  'statusDate',
  'rawData',
])
