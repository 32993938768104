import { z } from 'zod'
import { DeliveryOrderByWithRelationInputObjectSchema } from './objects/DeliveryOrderByWithRelationInput.schema'
import { DeliveryWhereInputObjectSchema } from './objects/DeliveryWhereInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './objects/DeliveryWhereUniqueInput.schema'
import { DeliveryScalarFieldEnumSchema } from './enums/DeliveryScalarFieldEnum.schema'

export const DeliveryFindManySchema = z.object({
  orderBy: z
    .union([
      DeliveryOrderByWithRelationInputObjectSchema,
      DeliveryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DeliveryWhereInputObjectSchema.optional(),
  cursor: DeliveryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(DeliveryScalarFieldEnumSchema).optional(),
})
