import { z } from 'zod'
import { CasePictureCreateManyCaseInputObjectSchema } from './CasePictureCreateManyCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureCreateManyCaseInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => CasePictureCreateManyCaseInputObjectSchema),
      z.lazy(() => CasePictureCreateManyCaseInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const CasePictureCreateManyCaseInputEnvelopeObjectSchema = Schema
