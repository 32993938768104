import { z } from 'zod'
import { DeliveryUpdateInputObjectSchema } from './objects/DeliveryUpdateInput.schema'
import { DeliveryUncheckedUpdateInputObjectSchema } from './objects/DeliveryUncheckedUpdateInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './objects/DeliveryWhereUniqueInput.schema'

export const DeliveryUpdateOneSchema = z.object({
  data: z.union([
    DeliveryUpdateInputObjectSchema,
    DeliveryUncheckedUpdateInputObjectSchema,
  ]),
  where: DeliveryWhereUniqueInputObjectSchema,
})
