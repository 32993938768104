import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { FlashsaleCountOrderByAggregateInputObjectSchema } from './FlashsaleCountOrderByAggregateInput.schema'
import { FlashsaleAvgOrderByAggregateInputObjectSchema } from './FlashsaleAvgOrderByAggregateInput.schema'
import { FlashsaleMaxOrderByAggregateInputObjectSchema } from './FlashsaleMaxOrderByAggregateInput.schema'
import { FlashsaleMinOrderByAggregateInputObjectSchema } from './FlashsaleMinOrderByAggregateInput.schema'
import { FlashsaleSumOrderByAggregateInputObjectSchema } from './FlashsaleSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => FlashsaleCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => FlashsaleAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => FlashsaleMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => FlashsaleMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => FlashsaleSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const FlashsaleOrderByWithAggregationInputObjectSchema = Schema
