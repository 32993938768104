import { z } from 'zod'
import { WarehouseUpdateInputObjectSchema } from './objects/WarehouseUpdateInput.schema'
import { WarehouseUncheckedUpdateInputObjectSchema } from './objects/WarehouseUncheckedUpdateInput.schema'
import { WarehouseWhereUniqueInputObjectSchema } from './objects/WarehouseWhereUniqueInput.schema'

export const WarehouseUpdateOneSchema = z.object({
  data: z.union([
    WarehouseUpdateInputObjectSchema,
    WarehouseUncheckedUpdateInputObjectSchema,
  ]),
  where: WarehouseWhereUniqueInputObjectSchema,
})
