import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './objects/CarouselItemWhereUniqueInput.schema'
import { CarouselItemCreateInputObjectSchema } from './objects/CarouselItemCreateInput.schema'
import { CarouselItemUncheckedCreateInputObjectSchema } from './objects/CarouselItemUncheckedCreateInput.schema'
import { CarouselItemUpdateInputObjectSchema } from './objects/CarouselItemUpdateInput.schema'
import { CarouselItemUncheckedUpdateInputObjectSchema } from './objects/CarouselItemUncheckedUpdateInput.schema'

export const CarouselItemUpsertSchema = z.object({
  where: CarouselItemWhereUniqueInputObjectSchema,
  create: z.union([
    CarouselItemCreateInputObjectSchema,
    CarouselItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CarouselItemUpdateInputObjectSchema,
    CarouselItemUncheckedUpdateInputObjectSchema,
  ]),
})
