import { z } from 'zod'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumDrugTypeNullableFilterObjectSchema } from './NestedEnumDrugTypeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumDrugTypeNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => DrugTypeSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => DrugTypeSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => DrugTypeSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => DrugTypeSchema),
          z.lazy(
            () => NestedEnumDrugTypeNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumDrugTypeNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumDrugTypeNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumDrugTypeNullableWithAggregatesFilterObjectSchema = Schema
