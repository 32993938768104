import { z } from 'zod'
import { ProductVideoCreateManyProductInputObjectSchema } from './ProductVideoCreateManyProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoCreateManyProductInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => ProductVideoCreateManyProductInputObjectSchema),
      z.lazy(() => ProductVideoCreateManyProductInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const ProductVideoCreateManyProductInputEnvelopeObjectSchema = Schema
