import { z } from 'zod'
import { CarouselItemFindManySchema } from '../findManyCarouselItem.schema'
import { CarouselCountOutputTypeArgsObjectSchema } from './CarouselCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselInclude> = z
  .object({
    items: z
      .union([z.boolean(), z.lazy(() => CarouselItemFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CarouselCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CarouselIncludeObjectSchema = Schema
