import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    onesignalMessageId: z.literal(true).optional(),
    orderItemType: z.literal(true).optional(),
    title: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    sendAt: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const OrderNotificationCountAggregateInputObjectSchema = Schema
