import { z } from 'zod'
import { OrderCreateWithoutReceiptInputObjectSchema } from './OrderCreateWithoutReceiptInput.schema'
import { OrderUncheckedCreateWithoutReceiptInputObjectSchema } from './OrderUncheckedCreateWithoutReceiptInput.schema'
import { OrderCreateOrConnectWithoutReceiptInputObjectSchema } from './OrderCreateOrConnectWithoutReceiptInput.schema'
import { OrderUpsertWithoutReceiptInputObjectSchema } from './OrderUpsertWithoutReceiptInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutReceiptInputObjectSchema } from './OrderUpdateWithoutReceiptInput.schema'
import { OrderUncheckedUpdateWithoutReceiptInputObjectSchema } from './OrderUncheckedUpdateWithoutReceiptInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutReceiptNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutReceiptInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutReceiptInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutReceiptInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutReceiptInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutReceiptInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutReceiptInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutReceiptNestedInputObjectSchema =
  Schema
