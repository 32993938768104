import { z } from 'zod'
import { NotificationScalarWhereInputObjectSchema } from './NotificationScalarWhereInput.schema'
import { NotificationUpdateManyMutationInputObjectSchema } from './NotificationUpdateManyMutationInput.schema'
import { NotificationUncheckedUpdateManyWithoutNotificationsInputObjectSchema } from './NotificationUncheckedUpdateManyWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpdateManyWithWhereWithoutSegmentsInput> =
  z
    .object({
      where: z.lazy(() => NotificationScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => NotificationUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            NotificationUncheckedUpdateManyWithoutNotificationsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationUpdateManyWithWhereWithoutSegmentsInputObjectSchema =
  Schema
