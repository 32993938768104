import { z } from 'zod'
import { ProductItemTypeSchema } from '../enums/ProductItemType.schema'
import { NestedEnumProductItemTypeFilterObjectSchema } from './NestedEnumProductItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumProductItemTypeFilter> = z
  .object({
    equals: z.lazy(() => ProductItemTypeSchema).optional(),
    in: z
      .lazy(() => ProductItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ProductItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ProductItemTypeSchema),
        z.lazy(() => NestedEnumProductItemTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumProductItemTypeFilterObjectSchema = Schema
