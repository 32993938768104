import * as z from "zod"
import { CompleteCase, RelatedCaseModel, CompleteCaseSummaryDiagnosis, RelatedCaseSummaryDiagnosisModel, CompleteAppointment, RelatedAppointmentModel, CompleteSuggestedProductItem, RelatedSuggestedProductItemModel, CompleteSuggestedProductItemOriginal, RelatedSuggestedProductItemOriginalModel, CompleteSuggestedProcedureItem, RelatedSuggestedProcedureItemModel } from "./index"

export const CaseSummaryModel = z.object({
  id: z.number().int(),
  caseId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  presentIllness: z.string().nullish(),
  peNote: z.string().nullish(),
  isTeleMedNotRecommend: z.boolean(),
  recommendation: z.string(),
  doctorFee: z.number(),
  doctorFeeDiscount: z.number(),
  commissionRate: z.number(),
  commissionPrice: z.number(),
  netDoctorFee: z.number(),
  doctorNote: z.string().nullish(),
  pharmacistNote: z.string().nullish(),
  followUpAppointmentId: z.number().int().nullish(),
  recommedNumberOfTreatment: z.number().int().nullish(),
  additionalSuggestedProcedure: z.string().nullish(),
  editedSuggestedProductItemAt: z.date().nullish(),
  editedSuggestedProductItemBy: z.number().int().nullish(),
  v2CaseSummaryId: z.number().int().nullish(),
  caseUrl: z.string().nullish(),
})

export interface CompleteCaseSummary extends z.infer<typeof CaseSummaryModel> {
  case: CompleteCase
  caseSummaryDiagnosis: CompleteCaseSummaryDiagnosis[]
  followUpAppointment?: CompleteAppointment | null
  suggestedProductItems: CompleteSuggestedProductItem[]
  suggestedProductItemsOriginal: CompleteSuggestedProductItemOriginal[]
  suggestedProcedureItems: CompleteSuggestedProcedureItem[]
}

/**
 * RelatedCaseSummaryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCaseSummaryModel: z.ZodSchema<CompleteCaseSummary> = z.lazy(() => CaseSummaryModel.extend({
  case: RelatedCaseModel,
  caseSummaryDiagnosis: RelatedCaseSummaryDiagnosisModel.array(),
  followUpAppointment: RelatedAppointmentModel.nullish(),
  suggestedProductItems: RelatedSuggestedProductItemModel.array(),
  suggestedProductItemsOriginal: RelatedSuggestedProductItemOriginalModel.array(),
  suggestedProcedureItems: RelatedSuggestedProcedureItemModel.array(),
}))
