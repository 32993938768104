import { z } from 'zod'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureUpdateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUpdateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedUpdateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedUpdateWithoutProductReviewInput.schema'
import { ProductReviewPictureCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedCreateWithoutProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () => ProductReviewPictureUpdateWithoutProductReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProductReviewPictureUncheckedUpdateWithoutProductReviewInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInputObjectSchema =
  Schema
