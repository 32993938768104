import { z } from 'zod'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProcedureReviewSummaryWhereUniqueInput.schema'
import { ProcedureReviewSummaryCreateInputObjectSchema } from './objects/ProcedureReviewSummaryCreateInput.schema'
import { ProcedureReviewSummaryUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewSummaryUncheckedCreateInput.schema'
import { ProcedureReviewSummaryUpdateInputObjectSchema } from './objects/ProcedureReviewSummaryUpdateInput.schema'
import { ProcedureReviewSummaryUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewSummaryUncheckedUpdateInput.schema'

export const ProcedureReviewSummaryUpsertSchema = z.object({
  where: ProcedureReviewSummaryWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureReviewSummaryCreateInputObjectSchema,
    ProcedureReviewSummaryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureReviewSummaryUpdateInputObjectSchema,
    ProcedureReviewSummaryUncheckedUpdateInputObjectSchema,
  ]),
})
