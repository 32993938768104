import { z } from 'zod'
import { OrderTypeSchema } from '../enums/OrderType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumOrderTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => OrderTypeSchema).optional(),
  })
  .strict()

export const EnumOrderTypeFieldUpdateOperationsInputObjectSchema = Schema
