import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCreateManyProcedureReviewInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      url: z.string(),
    })
    .strict()

export const ProcedureReviewPictureCreateManyProcedureReviewInputObjectSchema =
  Schema
