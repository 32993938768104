import { z } from 'zod'
import { CaseSummaryDiagnosisScalarWhereInputObjectSchema } from './CaseSummaryDiagnosisScalarWhereInput.schema'
import { CaseSummaryDiagnosisUpdateManyMutationInputObjectSchema } from './CaseSummaryDiagnosisUpdateManyMutationInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CaseSummaryDiagnosisUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema =
  Schema
