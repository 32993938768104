import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseCreateWithoutMissedCallTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutMissedCallTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutMissedCallTeleconsultInput> =
  z
    .object({
      where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CaseCreateWithoutMissedCallTeleconsultInputObjectSchema),
        z.lazy(
          () =>
            CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseCreateOrConnectWithoutMissedCallTeleconsultInputObjectSchema =
  Schema
