import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUpdateWithoutProcedureInput.schema'
import { ProcedureCategoryUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUncheckedUpdateWithoutProcedureInput.schema'
import { ProcedureCategoryCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateWithoutProcedureInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProcedureCategoryUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProcedureCategoryCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryUpsertWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
