import { z } from 'zod'

export const CoverPictureScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'rank',
  'url',
  'partnerId',
])
