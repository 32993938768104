import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    url: z.literal(true).optional(),
    productReviewId: z.literal(true).optional(),
  })
  .strict()

export const ProductReviewPictureMinAggregateInputObjectSchema = Schema
