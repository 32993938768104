import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { PatientUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema } from './PatientUpdateOneRequiredWithoutAcceptedConsentsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateWithoutConsentInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patient: z
      .lazy(
        () =>
          PatientUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const AcceptedConsentUpdateWithoutConsentInputObjectSchema = Schema
