import { z } from 'zod'
import { ArticleWhereUniqueInputObjectSchema } from './ArticleWhereUniqueInput.schema'
import { ArticleCreateWithoutPicturesInputObjectSchema } from './ArticleCreateWithoutPicturesInput.schema'
import { ArticleUncheckedCreateWithoutPicturesInputObjectSchema } from './ArticleUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleCreateOrConnectWithoutPicturesInput> = z
  .object({
    where: z.lazy(() => ArticleWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ArticleCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ArticleUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ArticleCreateOrConnectWithoutPicturesInputObjectSchema = Schema
