import { z } from 'zod'
import { BranchWhereUniqueInputObjectSchema } from './objects/BranchWhereUniqueInput.schema'
import { BranchCreateInputObjectSchema } from './objects/BranchCreateInput.schema'
import { BranchUncheckedCreateInputObjectSchema } from './objects/BranchUncheckedCreateInput.schema'
import { BranchUpdateInputObjectSchema } from './objects/BranchUpdateInput.schema'
import { BranchUncheckedUpdateInputObjectSchema } from './objects/BranchUncheckedUpdateInput.schema'

export const BranchUpsertSchema = z.object({
  where: BranchWhereUniqueInputObjectSchema,
  create: z.union([
    BranchCreateInputObjectSchema,
    BranchUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    BranchUpdateInputObjectSchema,
    BranchUncheckedUpdateInputObjectSchema,
  ]),
})
