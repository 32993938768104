import * as z from "zod"
import { CompleteSegment, RelatedSegmentModel, CompleteNotificationPatient, RelatedNotificationPatientModel } from "./index"

export const NotificationModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  type: z.enum(['ORDER', 'CHAT', 'ACTIVITY', 'NOTICE', 'PROMOTION']),
  picture: z.string().nullish(),
  title: z.string(),
  message: z.string(),
  actionType: z.enum(['NO_ACTION', 'NAVIGATE', 'URL']),
  action: z.string().nullish(),
  onesignalMessageId: z.string().nullish(),
  startAt: z.date(),
  appointmentId: z.number().int().nullish(),
  appointmentEvent: z.enum(['DAY_BEFORE_APPOINTMENT', 'ALMOST_APPOINTMENT', 'AT_APPOINTMENT', 'NOT_JOIN_APPOINTMENT', 'CANCEL']).nullish(),
})

export interface CompleteNotification extends z.infer<typeof NotificationModel> {
  segments: CompleteSegment[]
  patients: CompleteNotificationPatient[]
}

/**
 * RelatedNotificationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedNotificationModel: z.ZodSchema<CompleteNotification> = z.lazy(() => NotificationModel.extend({
  segments: RelatedSegmentModel.array(),
  patients: RelatedNotificationPatientModel.array(),
}))
