import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { HealthInfoCountOrderByAggregateInputObjectSchema } from './HealthInfoCountOrderByAggregateInput.schema'
import { HealthInfoAvgOrderByAggregateInputObjectSchema } from './HealthInfoAvgOrderByAggregateInput.schema'
import { HealthInfoMaxOrderByAggregateInputObjectSchema } from './HealthInfoMaxOrderByAggregateInput.schema'
import { HealthInfoMinOrderByAggregateInputObjectSchema } from './HealthInfoMinOrderByAggregateInput.schema'
import { HealthInfoSumOrderByAggregateInputObjectSchema } from './HealthInfoSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    gender: z.lazy(() => SortOrderSchema).optional(),
    birthdate: z.lazy(() => SortOrderSchema).optional(),
    drugAllergy: z.lazy(() => SortOrderSchema).optional(),
    congenitalDisease: z.lazy(() => SortOrderSchema).optional(),
    medication: z.lazy(() => SortOrderSchema).optional(),
    pregnancyState: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => HealthInfoCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => HealthInfoAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => HealthInfoMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => HealthInfoMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => HealthInfoSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const HealthInfoOrderByWithAggregationInputObjectSchema = Schema
