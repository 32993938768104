import { z } from 'zod'
import { CouponGroupCreateWithoutCouponsInputObjectSchema } from './CouponGroupCreateWithoutCouponsInput.schema'
import { CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema } from './CouponGroupUncheckedCreateWithoutCouponsInput.schema'
import { CouponGroupCreateOrConnectWithoutCouponsInputObjectSchema } from './CouponGroupCreateOrConnectWithoutCouponsInput.schema'
import { CouponGroupUpsertWithoutCouponsInputObjectSchema } from './CouponGroupUpsertWithoutCouponsInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'
import { CouponGroupUpdateWithoutCouponsInputObjectSchema } from './CouponGroupUpdateWithoutCouponsInput.schema'
import { CouponGroupUncheckedUpdateWithoutCouponsInputObjectSchema } from './CouponGroupUncheckedUpdateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupUpdateOneRequiredWithoutCouponsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CouponGroupCreateWithoutCouponsInputObjectSchema),
          z.lazy(
            () => CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CouponGroupCreateOrConnectWithoutCouponsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CouponGroupUpsertWithoutCouponsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CouponGroupUpdateWithoutCouponsInputObjectSchema),
          z.lazy(
            () => CouponGroupUncheckedUpdateWithoutCouponsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CouponGroupUpdateOneRequiredWithoutCouponsNestedInputObjectSchema =
  Schema
