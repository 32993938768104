import { z } from 'zod'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumExpireTypeFilter> = z
  .object({
    equals: z.lazy(() => ExpireTypeSchema).optional(),
    in: z
      .lazy(() => ExpireTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ExpireTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ExpireTypeSchema),
        z.lazy(() => NestedEnumExpireTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumExpireTypeFilterObjectSchema = Schema
