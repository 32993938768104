import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    coverPicture: z.literal(true).optional(),
    titleTr: z.literal(true).optional(),
    detailTr: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ArticleCountAggregateInputObjectSchema = Schema
