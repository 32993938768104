import { z } from 'zod'
import { CouponCreateWithoutOrderInputObjectSchema } from './CouponCreateWithoutOrderInput.schema'
import { CouponUncheckedCreateWithoutOrderInputObjectSchema } from './CouponUncheckedCreateWithoutOrderInput.schema'
import { CouponCreateOrConnectWithoutOrderInputObjectSchema } from './CouponCreateOrConnectWithoutOrderInput.schema'
import { CouponCreateManyOrderInputEnvelopeObjectSchema } from './CouponCreateManyOrderInputEnvelope.schema'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponUncheckedCreateNestedManyWithoutOrderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CouponCreateWithoutOrderInputObjectSchema),
          z.lazy(() => CouponCreateWithoutOrderInputObjectSchema).array(),
          z.lazy(() => CouponUncheckedCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => CouponUncheckedCreateWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => CouponCreateOrConnectWithoutOrderInputObjectSchema),
          z
            .lazy(() => CouponCreateOrConnectWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CouponCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CouponWhereUniqueInputObjectSchema),
          z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CouponUncheckedCreateNestedManyWithoutOrderInputObjectSchema =
  Schema
