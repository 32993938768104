import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUpdateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedUpdateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedUpdateWithoutFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => FlashsaleItemUpdateWithoutFlashsaleInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateWithoutFlashsaleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInputObjectSchema =
  Schema
