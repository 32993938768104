import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductReviewCountOrderByAggregateInputObjectSchema } from './ProductReviewCountOrderByAggregateInput.schema'
import { ProductReviewAvgOrderByAggregateInputObjectSchema } from './ProductReviewAvgOrderByAggregateInput.schema'
import { ProductReviewMaxOrderByAggregateInputObjectSchema } from './ProductReviewMaxOrderByAggregateInput.schema'
import { ProductReviewMinOrderByAggregateInputObjectSchema } from './ProductReviewMinOrderByAggregateInput.schema'
import { ProductReviewSumOrderByAggregateInputObjectSchema } from './ProductReviewSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    overallRating: z.lazy(() => SortOrderSchema).optional(),
    qualityRating: z.lazy(() => SortOrderSchema).optional(),
    shippingRating: z.lazy(() => SortOrderSchema).optional(),
    packagingRating: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    patientName: z.lazy(() => SortOrderSchema).optional(),
    isAnonymous: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductReviewCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductReviewAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductReviewMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductReviewMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductReviewSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductReviewOrderByWithAggregationInputObjectSchema = Schema
