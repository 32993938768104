import { z } from 'zod'
import { VariantPictureCreateWithoutVariantInputObjectSchema } from './VariantPictureCreateWithoutVariantInput.schema'
import { VariantPictureUncheckedCreateWithoutVariantInputObjectSchema } from './VariantPictureUncheckedCreateWithoutVariantInput.schema'
import { VariantPictureCreateOrConnectWithoutVariantInputObjectSchema } from './VariantPictureCreateOrConnectWithoutVariantInput.schema'
import { VariantPictureCreateManyVariantInputEnvelopeObjectSchema } from './VariantPictureCreateManyVariantInputEnvelope.schema'
import { VariantPictureWhereUniqueInputObjectSchema } from './VariantPictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureUncheckedCreateNestedManyWithoutVariantInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantPictureCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => VariantPictureCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => VariantPictureUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => VariantPictureCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => VariantPictureCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const VariantPictureUncheckedCreateNestedManyWithoutVariantInputObjectSchema =
  Schema
