import * as z from "zod"

export const OtpModel = z.object({
  id: z.number().int(),
  ipAddress: z.string(),
  phone: z.string(),
  reference: z.string(),
  createdAt: z.date(),
  expiredAt: z.date(),
  verifiedAt: z.date().nullish(),
  otp: z.string(),
})
