import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithoutRefundInputObjectSchema } from './ProductItemUpdateWithoutRefundInput.schema'
import { ProductItemUncheckedUpdateWithoutRefundInputObjectSchema } from './ProductItemUncheckedUpdateWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateWithWhereUniqueWithoutRefundInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductItemUpdateWithoutRefundInputObjectSchema),
        z.lazy(() => ProductItemUncheckedUpdateWithoutRefundInputObjectSchema),
      ]),
    })
    .strict()

export const ProductItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema =
  Schema
