import { z } from 'zod'
import { RefundUpdateInputObjectSchema } from './objects/RefundUpdateInput.schema'
import { RefundUncheckedUpdateInputObjectSchema } from './objects/RefundUncheckedUpdateInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './objects/RefundWhereUniqueInput.schema'

export const RefundUpdateOneSchema = z.object({
  data: z.union([
    RefundUpdateInputObjectSchema,
    RefundUncheckedUpdateInputObjectSchema,
  ]),
  where: RefundWhereUniqueInputObjectSchema,
})
