import { z } from 'zod'
import { SegmentWhereUniqueInputObjectSchema } from './SegmentWhereUniqueInput.schema'
import { SegmentUpdateWithoutNotificationsInputObjectSchema } from './SegmentUpdateWithoutNotificationsInput.schema'
import { SegmentUncheckedUpdateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedUpdateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUpdateWithWhereUniqueWithoutNotificationsInput> =
  z
    .object({
      where: z.lazy(() => SegmentWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => SegmentUpdateWithoutNotificationsInputObjectSchema),
        z.lazy(
          () => SegmentUncheckedUpdateWithoutNotificationsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SegmentUpdateWithWhereUniqueWithoutNotificationsInputObjectSchema =
  Schema
