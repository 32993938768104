import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { EnumConditionScopeWithAggregatesFilterObjectSchema } from './EnumConditionScopeWithAggregatesFilter.schema'
import { ConditionScopeSchema } from '../enums/ConditionScope.schema'
import { EnumConditionItemTypeWithAggregatesFilterObjectSchema } from './EnumConditionItemTypeWithAggregatesFilter.schema'
import { ConditionItemTypeSchema } from '../enums/ConditionItemType.schema'
import { JsonNullableWithAggregatesFilterObjectSchema } from './JsonNullableWithAggregatesFilter.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingScalarWhereWithAggregatesInput> =
  z
    .object({
      AND: z
        .union([
          z.lazy(
            () => ConditionSettingScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => ConditionSettingScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      OR: z
        .lazy(() => ConditionSettingScalarWhereWithAggregatesInputObjectSchema)
        .array()
        .optional(),
      NOT: z
        .union([
          z.lazy(
            () => ConditionSettingScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => ConditionSettingScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      id: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      createdAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      scope: z
        .union([
          z.lazy(() => EnumConditionScopeWithAggregatesFilterObjectSchema),
          z.lazy(() => ConditionScopeSchema),
        ])
        .optional(),
      itemType: z
        .union([
          z.lazy(() => EnumConditionItemTypeWithAggregatesFilterObjectSchema),
          z.lazy(() => ConditionItemTypeSchema),
        ])
        .optional(),
      items: z
        .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
        .optional(),
      settingGroupId: z
        .union([
          z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
          z.string(),
        ])
        .optional()
        .nullable(),
      groupId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
    })
    .strict()

export const ConditionSettingScalarWhereWithAggregatesInputObjectSchema = Schema
