import { z } from 'zod'
import { FlashsaleItemUpdateInputObjectSchema } from './objects/FlashsaleItemUpdateInput.schema'
import { FlashsaleItemUncheckedUpdateInputObjectSchema } from './objects/FlashsaleItemUncheckedUpdateInput.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './objects/FlashsaleItemWhereUniqueInput.schema'

export const FlashsaleItemUpdateOneSchema = z.object({
  data: z.union([
    FlashsaleItemUpdateInputObjectSchema,
    FlashsaleItemUncheckedUpdateInputObjectSchema,
  ]),
  where: FlashsaleItemWhereUniqueInputObjectSchema,
})
