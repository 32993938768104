import { z } from 'zod'
import { AddressCreateInputObjectSchema } from './objects/AddressCreateInput.schema'
import { AddressUncheckedCreateInputObjectSchema } from './objects/AddressUncheckedCreateInput.schema'

export const AddressCreateOneSchema = z.object({
  data: z.union([
    AddressCreateInputObjectSchema,
    AddressUncheckedCreateInputObjectSchema,
  ]),
})
