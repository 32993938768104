import { z } from 'zod'
import { ProcedureItemUpdateInputObjectSchema } from './objects/ProcedureItemUpdateInput.schema'
import { ProcedureItemUncheckedUpdateInputObjectSchema } from './objects/ProcedureItemUncheckedUpdateInput.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './objects/ProcedureItemWhereUniqueInput.schema'

export const ProcedureItemUpdateOneSchema = z.object({
  data: z.union([
    ProcedureItemUpdateInputObjectSchema,
    ProcedureItemUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureItemWhereUniqueInputObjectSchema,
})
