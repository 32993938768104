import { z } from 'zod'
import { HealthInfoCreateWithoutOrderInputObjectSchema } from './HealthInfoCreateWithoutOrderInput.schema'
import { HealthInfoUncheckedCreateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedCreateWithoutOrderInput.schema'
import { HealthInfoCreateOrConnectWithoutOrderInputObjectSchema } from './HealthInfoCreateOrConnectWithoutOrderInput.schema'
import { HealthInfoUpsertWithoutOrderInputObjectSchema } from './HealthInfoUpsertWithoutOrderInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'
import { HealthInfoUpdateWithoutOrderInputObjectSchema } from './HealthInfoUpdateWithoutOrderInput.schema'
import { HealthInfoUncheckedUpdateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoUncheckedUpdateOneWithoutOrderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => HealthInfoCreateWithoutOrderInputObjectSchema),
          z.lazy(() => HealthInfoUncheckedCreateWithoutOrderInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => HealthInfoCreateOrConnectWithoutOrderInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => HealthInfoUpsertWithoutOrderInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => HealthInfoUpdateWithoutOrderInputObjectSchema),
          z.lazy(() => HealthInfoUncheckedUpdateWithoutOrderInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const HealthInfoUncheckedUpdateOneWithoutOrderNestedInputObjectSchema =
  Schema
