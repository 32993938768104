import { z } from 'zod'
import { ProcedurePictureOrderByWithRelationInputObjectSchema } from './objects/ProcedurePictureOrderByWithRelationInput.schema'
import { ProcedurePictureWhereInputObjectSchema } from './objects/ProcedurePictureWhereInput.schema'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './objects/ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureScalarFieldEnumSchema } from './enums/ProcedurePictureScalarFieldEnum.schema'

export const ProcedurePictureFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedurePictureOrderByWithRelationInputObjectSchema,
      ProcedurePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedurePictureWhereInputObjectSchema.optional(),
  cursor: ProcedurePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedurePictureScalarFieldEnumSchema).optional(),
})
