import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeNullableWithAggregatesFilterObjectSchema } from './DateTimeNullableWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientScalarWhereWithAggregatesInput> =
  z
    .object({
      AND: z
        .union([
          z.lazy(
            () => NotificationPatientScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      OR: z
        .lazy(
          () => NotificationPatientScalarWhereWithAggregatesInputObjectSchema,
        )
        .array()
        .optional(),
      NOT: z
        .union([
          z.lazy(
            () => NotificationPatientScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      patientId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      notificationId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      readAt: z
        .union([
          z.lazy(() => DateTimeNullableWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional()
        .nullable(),
    })
    .strict()

export const NotificationPatientScalarWhereWithAggregatesInputObjectSchema =
  Schema
