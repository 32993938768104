import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpSelect> = z
  .object({
    id: z.boolean().optional(),
    ipAddress: z.boolean().optional(),
    phone: z.boolean().optional(),
    reference: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    expiredAt: z.boolean().optional(),
    verifiedAt: z.boolean().optional(),
    otp: z.boolean().optional(),
  })
  .strict()

export const OtpSelectObjectSchema = Schema
