import { z } from 'zod'
import { KycDataWhereInputObjectSchema } from './KycDataWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataRelationFilter> = z
  .object({
    is: z
      .lazy(() => KycDataWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => KycDataWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const KycDataRelationFilterObjectSchema = Schema
