import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithoutVariantInputObjectSchema } from './VariantWarehouseUpdateWithoutVariantInput.schema'
import { VariantWarehouseUncheckedUpdateWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedUpdateWithoutVariantInput.schema'
import { VariantWarehouseCreateWithoutVariantInputObjectSchema } from './VariantWarehouseCreateWithoutVariantInput.schema'
import { VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpsertWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => VariantWarehouseUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantWarehouseUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => VariantWarehouseCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantWarehouseUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseUpsertWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
