import { EventClickArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { FC, useState } from 'react'
import './calendar.scss'
import { CalendarSlotInfo } from './calendarSlotInfo'
import { CalendarSlotInfoProps, ScheduleProps } from './schedule.interface'
interface FullCalendarProps {
  fetchAvailableSlot: () => Promise<void>
  availableSlot: CalendarSlotInfoProps[]
  setFrom: React.Dispatch<React.SetStateAction<Date>>
  setTo: React.Dispatch<React.SetStateAction<Date>>
}
const renderEventContent = (arg: { event: CalendarSlotInfoProps }) => {
  const eventInfo = arg.event
  const { title, extendedProps } = eventInfo
  const { slot } = extendedProps
  return (
    <div className={`item ${slot.appointment ? 'busy' : 'available'}`}>
      <strong style={{ whiteSpace: 'pre-wrap' }}>{title}</strong>
    </div>
  )
}

export const FullCalendarApp: FC<FullCalendarProps> = ({
  fetchAvailableSlot,
  availableSlot,
  setFrom,
  setTo,
}) => {
  let fetchTimeout: NodeJS.Timeout | null = null
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleProps | null>(null)

  const handleEventClick = (e: EventClickArg) => {
    const { slot } = e.event.extendedProps
    setScheduleInfo(slot)
    setShowInfo(true)
  }

  return (
    <div className="full-calendar">
      <FullCalendar
        allDaySlot={false}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          center: 'dayGridMonth,timeGridWeek',
        }}
        eventClassNames={'event-content'}
        nowIndicator
        eventClick={handleEventClick}
        datesSet={(e) => {
          if (fetchTimeout) {
            clearTimeout(fetchTimeout)
          }
          fetchTimeout = setTimeout(() => {
            setFrom(e.start)
            setTo(e.end)
          }, 500)
        }}
        events={availableSlot}
        eventContent={renderEventContent}
      />
      <CalendarSlotInfo
        fetchAvailableSlot={fetchAvailableSlot}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        scheduleInfo={scheduleInfo}
        setScheduleInfo={setScheduleInfo}
      />
    </div>
  )
}
