import { z } from 'zod'
import { DiagnosisCreateInputObjectSchema } from './objects/DiagnosisCreateInput.schema'
import { DiagnosisUncheckedCreateInputObjectSchema } from './objects/DiagnosisUncheckedCreateInput.schema'

export const DiagnosisCreateOneSchema = z.object({
  data: z.union([
    DiagnosisCreateInputObjectSchema,
    DiagnosisUncheckedCreateInputObjectSchema,
  ]),
})
