import { z } from 'zod'
import { OrderSelectObjectSchema } from './OrderSelect.schema'
import { OrderIncludeObjectSchema } from './OrderInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderArgs> = z
  .object({
    select: z.lazy(() => OrderSelectObjectSchema).optional(),
    include: z.lazy(() => OrderIncludeObjectSchema).optional(),
  })
  .strict()

export const OrderArgsObjectSchema = Schema
