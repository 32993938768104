import { z } from 'zod'
import { ProcedureReviewSummaryOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewSummaryOrderByWithRelationInput.schema'
import { ProcedureReviewSummaryWhereInputObjectSchema } from './objects/ProcedureReviewSummaryWhereInput.schema'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProcedureReviewSummaryWhereUniqueInput.schema'
import { ProcedureReviewSummaryScalarFieldEnumSchema } from './enums/ProcedureReviewSummaryScalarFieldEnum.schema'

export const ProcedureReviewSummaryFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewSummaryOrderByWithRelationInputObjectSchema,
      ProcedureReviewSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewSummaryWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureReviewSummaryScalarFieldEnumSchema).optional(),
})
