import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    type: z.literal(true).optional(),
    picture: z.literal(true).optional(),
    title: z.literal(true).optional(),
    message: z.literal(true).optional(),
    actionType: z.literal(true).optional(),
    action: z.literal(true).optional(),
    onesignalMessageId: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    appointmentId: z.literal(true).optional(),
    appointmentEvent: z.literal(true).optional(),
  })
  .strict()

export const NotificationMinAggregateInputObjectSchema = Schema
