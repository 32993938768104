import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutProductItemsInputObjectSchema } from './OrderCreateWithoutProductItemsInput.schema'
import { OrderUncheckedCreateWithoutProductItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutProductItemsInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutProductItemsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutProductItemsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutProductItemsInputObjectSchema = Schema
