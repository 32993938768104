import { Typography } from '@mui/material'
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator'
import axios from 'axios'
import { FC } from 'react'
import { FormDivider } from '../../../components/FormInput/divider'
import { VideoStorageInput } from '../../../components/VideoStorageInput'
import { adminGatewayClient } from '../../../service'

export const ProductVideoSecion: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        วิดีโอ
      </Typography>
      <FormDivider />
      <VideoStorageInput
        source="video"
        label={false}
        accept={{ 'video/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.product.generateUploadVideoUrl.query({
              extension: file.name.split('.').pop() ?? '',
              fieldPath: 'video',
            })

          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })

          // get thumbnail
          const thumbnail = await generateVideoThumbnails(file, 0, 'base64')
          const thumbnailFile = dataURLtoFile(thumbnail[0])

          const uploadThumbnailUrlMeta =
            await adminGatewayClient.product.generateUploadPictureUrl.query({
              extension: 'jpg',
              fieldPath: 'video-thumbnail',
            })

          await axios.request({
            method: 'PUT',
            url: uploadThumbnailUrlMeta.uploadUrl,
            headers: {
              'content-type': thumbnailFile.type,
            },
            data: thumbnailFile,
            signal: abortController.signal,
          })

          return [uploadUrlMeta, uploadThumbnailUrlMeta]
        }}
      ></VideoStorageInput>
    </>
  )
}

function dataURLtoFile(dataurl: string) {
  const arr = dataurl.split(',')
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = arr[0]!.match(/:(.*?);/)![1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], 'name', { type: mime })
}
