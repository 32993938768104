import { z } from 'zod'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { ShippingProviderUpdateOneRequiredWithoutAreasNestedInputObjectSchema } from './ShippingProviderUpdateOneRequiredWithoutAreasNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaUpdateInput> = z
  .object({
    postCode: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    shippingProvider: z
      .lazy(
        () =>
          ShippingProviderUpdateOneRequiredWithoutAreasNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ExpressShippingAreaUpdateInputObjectSchema = Schema
