import { z } from 'zod'
import { VariantCreateNestedOneWithoutProductReviewsInputObjectSchema } from './VariantCreateNestedOneWithoutProductReviewsInput.schema'
import { ProductCreateNestedOneWithoutProductReviewsInputObjectSchema } from './ProductCreateNestedOneWithoutProductReviewsInput.schema'
import { BrandCreateNestedOneWithoutProductReviewsInputObjectSchema } from './BrandCreateNestedOneWithoutProductReviewsInput.schema'
import { ProductReviewPictureCreateNestedManyWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateNestedManyWithoutProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    overallRating: z.number(),
    qualityRating: z.number(),
    shippingRating: z.number(),
    packagingRating: z.number(),
    message: z.string().optional().nullable(),
    orderId: z.number(),
    patientId: z.number(),
    patientName: z.string(),
    isAnonymous: z.boolean(),
    priority: z.number(),
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutProductReviewsInputObjectSchema,
    ),
    product: z.lazy(
      () => ProductCreateNestedOneWithoutProductReviewsInputObjectSchema,
    ),
    brand: z.lazy(
      () => BrandCreateNestedOneWithoutProductReviewsInputObjectSchema,
    ),
    pictures: z
      .lazy(
        () =>
          ProductReviewPictureCreateNestedManyWithoutProductReviewInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProductReviewCreateInputObjectSchema = Schema
