import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultSelect> = z
  .object({
    id: z.boolean().optional(),
    doctorId: z.boolean().optional(),
    patientId: z.boolean().optional(),
    videoRoomId: z.boolean().optional(),
    doctorVideoRoomUid: z.boolean().optional(),
    patientVideoRoomUid: z.boolean().optional(),
    startAt: z.boolean().optional(),
    endAt: z.boolean().optional(),
    callTime: z.boolean().optional(),
    status: z.boolean().optional(),
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    caseId: z.boolean().optional(),
    missedCallCase: z
      .union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)])
      .optional(),
    missedCallCaseId: z.boolean().optional(),
    appointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
    remark: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const TeleconsultSelectObjectSchema = Schema
