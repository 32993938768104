import { z } from 'zod'
import { OtpSelectObjectSchema } from './OtpSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpArgs> = z
  .object({
    select: z.lazy(() => OtpSelectObjectSchema).optional(),
  })
  .strict()

export const OtpArgsObjectSchema = Schema
