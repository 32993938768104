import { z } from 'zod'
import { CasePictureCreateWithoutCaseInputObjectSchema } from './CasePictureCreateWithoutCaseInput.schema'
import { CasePictureUncheckedCreateWithoutCaseInputObjectSchema } from './CasePictureUncheckedCreateWithoutCaseInput.schema'
import { CasePictureCreateOrConnectWithoutCaseInputObjectSchema } from './CasePictureCreateOrConnectWithoutCaseInput.schema'
import { CasePictureUpsertWithWhereUniqueWithoutCaseInputObjectSchema } from './CasePictureUpsertWithWhereUniqueWithoutCaseInput.schema'
import { CasePictureCreateManyCaseInputEnvelopeObjectSchema } from './CasePictureCreateManyCaseInputEnvelope.schema'
import { CasePictureWhereUniqueInputObjectSchema } from './CasePictureWhereUniqueInput.schema'
import { CasePictureUpdateWithWhereUniqueWithoutCaseInputObjectSchema } from './CasePictureUpdateWithWhereUniqueWithoutCaseInput.schema'
import { CasePictureUpdateManyWithWhereWithoutCaseInputObjectSchema } from './CasePictureUpdateManyWithWhereWithoutCaseInput.schema'
import { CasePictureScalarWhereInputObjectSchema } from './CasePictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureUpdateManyWithoutCaseNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema),
        z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema).array(),
        z.lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema),
        z
          .lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => CasePictureCreateOrConnectWithoutCaseInputObjectSchema),
        z
          .lazy(() => CasePictureCreateOrConnectWithoutCaseInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => CasePictureUpsertWithWhereUniqueWithoutCaseInputObjectSchema,
        ),
        z
          .lazy(
            () => CasePictureUpsertWithWhereUniqueWithoutCaseInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => CasePictureCreateManyCaseInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
        z.lazy(() => CasePictureWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => CasePictureUpdateWithWhereUniqueWithoutCaseInputObjectSchema,
        ),
        z
          .lazy(
            () => CasePictureUpdateWithWhereUniqueWithoutCaseInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(
          () => CasePictureUpdateManyWithWhereWithoutCaseInputObjectSchema,
        ),
        z
          .lazy(
            () => CasePictureUpdateManyWithWhereWithoutCaseInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => CasePictureScalarWhereInputObjectSchema),
        z.lazy(() => CasePictureScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const CasePictureUpdateManyWithoutCaseNestedInputObjectSchema = Schema
