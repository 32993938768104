import { z } from 'zod'
import { ExpressShippingAreaWhereInputObjectSchema } from './ExpressShippingAreaWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaListRelationFilter> = z
  .object({
    every: z.lazy(() => ExpressShippingAreaWhereInputObjectSchema).optional(),
    some: z.lazy(() => ExpressShippingAreaWhereInputObjectSchema).optional(),
    none: z.lazy(() => ExpressShippingAreaWhereInputObjectSchema).optional(),
  })
  .strict()

export const ExpressShippingAreaListRelationFilterObjectSchema = Schema
