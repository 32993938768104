import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema } from './CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpdateWithoutDiagnosisInput> =
  z
    .object({
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      caseSummary: z
        .lazy(
          () =>
            CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisUpdateWithoutDiagnosisInputObjectSchema =
  Schema
