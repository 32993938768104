import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureReviewOrderByWithRelationInputObjectSchema } from './ProcedureReviewOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureOrderByWithRelationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      url: z.lazy(() => SortOrderSchema).optional(),
      procedureReviewId: z.lazy(() => SortOrderSchema).optional(),
      procedureReview: z
        .lazy(() => ProcedureReviewOrderByWithRelationInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewPictureOrderByWithRelationInputObjectSchema = Schema
