import { z } from 'zod'
import { OrderCreateWithoutCouponsInputObjectSchema } from './OrderCreateWithoutCouponsInput.schema'
import { OrderUncheckedCreateWithoutCouponsInputObjectSchema } from './OrderUncheckedCreateWithoutCouponsInput.schema'
import { OrderCreateOrConnectWithoutCouponsInputObjectSchema } from './OrderCreateOrConnectWithoutCouponsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutCouponsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutCouponsInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutCouponsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutCouponsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutCouponsInputObjectSchema = Schema
