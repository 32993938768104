import { z } from 'zod'
import { CategoryCreateWithoutChildrenInputObjectSchema } from './CategoryCreateWithoutChildrenInput.schema'
import { CategoryUncheckedCreateWithoutChildrenInputObjectSchema } from './CategoryUncheckedCreateWithoutChildrenInput.schema'
import { CategoryCreateOrConnectWithoutChildrenInputObjectSchema } from './CategoryCreateOrConnectWithoutChildrenInput.schema'
import { CategoryUpsertWithoutChildrenInputObjectSchema } from './CategoryUpsertWithoutChildrenInput.schema'
import { CategoryWhereUniqueInputObjectSchema } from './CategoryWhereUniqueInput.schema'
import { CategoryUpdateWithoutChildrenInputObjectSchema } from './CategoryUpdateWithoutChildrenInput.schema'
import { CategoryUncheckedUpdateWithoutChildrenInputObjectSchema } from './CategoryUncheckedUpdateWithoutChildrenInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryUpdateOneWithoutChildrenNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CategoryCreateWithoutChildrenInputObjectSchema),
        z.lazy(() => CategoryUncheckedCreateWithoutChildrenInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CategoryCreateOrConnectWithoutChildrenInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => CategoryUpsertWithoutChildrenInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => CategoryWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => CategoryUpdateWithoutChildrenInputObjectSchema),
        z.lazy(() => CategoryUncheckedUpdateWithoutChildrenInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CategoryUpdateOneWithoutChildrenNestedInputObjectSchema = Schema
