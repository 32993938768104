import { z } from 'zod'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NullableEnumAppointmentEventFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => AppointmentEventSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumAppointmentEventFieldUpdateOperationsInputObjectSchema =
  Schema
