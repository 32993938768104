import { z } from 'zod'

export const SuggestedProcedureItemScalarFieldEnumSchema = z.enum([
  'id',
  'caseSummaryId',
  'procedureId',
  'createdAt',
  'updatedAt',
  'amount',
  'note',
])
