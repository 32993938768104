import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CarouselOrderByWithRelationInputObjectSchema } from './CarouselOrderByWithRelationInput.schema'
import { ProcedureOrderByWithRelationInputObjectSchema } from './ProcedureOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    carouselId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    carousel: z
      .lazy(() => CarouselOrderByWithRelationInputObjectSchema)
      .optional(),
    procedure: z
      .lazy(() => ProcedureOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const CarouselItemOrderByWithRelationInputObjectSchema = Schema
