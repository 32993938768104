import { Add as AddIcon, Publish as PublishIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { FC, useState } from 'react'
import {
  Datagrid,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import {
  CustomBooleanField,
  CustomI18nField,
} from '../../../components/CustomField'
import { FormDivider } from '../../../components/FormInput/divider'
import { CarouselProcedureImportModal } from '../importCarousel/importModal'

export const CarouselItemSection: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const record = useRecordContext()
  const translate = useTranslate()

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.carouselItemProcedure.title.procedure_item')}
        </Typography>
        <ButtonGroup>
          <Button
            variant="outlined"
            startIcon={<PublishIcon />}
            sx={{ mb: 1 }}
            onClick={() => setOpenModal(true)}
          >
            {translate('resources.carouselProcedure.action.import_button')}
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ mb: 1 }}
            onClick={() => {
              window.open(
                `/carouselItemProcedure/create?carouselId=${record.id}`,
                '_blank',
              )
            }}
          >
            {translate('resources.carouselProcedure.action.add_carousel_item')}
          </Button>
        </ButtonGroup>
      </Box>
      <FormDivider />
      <ReferenceManyField
        reference="carouselItemProcedure"
        target="carouselId"
        perPage={999}
        sort={{ field: 'priority', order: 'DESC' }}
      >
        <Datagrid
          sx={{ width: '100%', minWidth: '800px', overflowX: 'auto' }}
          bulkActionButtons={false}
        >
          <ReferenceField
            label="ID"
            reference="procedure"
            source="procedureId"
            link={false}
          >
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField
            label="Name"
            reference="procedure"
            source="procedureId"
            link={false}
          >
            <CustomI18nField fieldTr="nameTr" />
          </ReferenceField>
          <CustomBooleanField
            source="active"
            label="Carousel item active"
            sortable={false}
          />
          <ReferenceField
            label="Procedure active"
            reference="procedure"
            source="procedureId"
            link={false}
          >
            <CustomBooleanField
              source="active"
              label="Procedure active"
              sortable={false}
            />
          </ReferenceField>

          <TextField label="Priority" source="priority" sortable={false} />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <CarouselProcedureImportModal
        open={openModal}
        mode={'UPDATE'}
        onClose={() => {
          setOpenModal(false)
        }}
        disableCloseOnBackdrop
      />
    </>
  )
}
