import { z } from 'zod'
import { ProcedureOrderUpdateInputObjectSchema } from './objects/ProcedureOrderUpdateInput.schema'
import { ProcedureOrderUncheckedUpdateInputObjectSchema } from './objects/ProcedureOrderUncheckedUpdateInput.schema'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './objects/ProcedureOrderWhereUniqueInput.schema'

export const ProcedureOrderUpdateOneSchema = z.object({
  data: z.union([
    ProcedureOrderUpdateInputObjectSchema,
    ProcedureOrderUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureOrderWhereUniqueInputObjectSchema,
})
