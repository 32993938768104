import { z } from 'zod'
import { PartnerCreateWithoutUsersInputObjectSchema } from './PartnerCreateWithoutUsersInput.schema'
import { PartnerUncheckedCreateWithoutUsersInputObjectSchema } from './PartnerUncheckedCreateWithoutUsersInput.schema'
import { PartnerCreateOrConnectWithoutUsersInputObjectSchema } from './PartnerCreateOrConnectWithoutUsersInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutUsersInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PartnerCreateWithoutUsersInputObjectSchema),
        z.lazy(() => PartnerUncheckedCreateWithoutUsersInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PartnerCreateOrConnectWithoutUsersInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PartnerCreateNestedOneWithoutUsersInputObjectSchema = Schema
