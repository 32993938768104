import { z } from 'zod'
import { CaseCreateWithoutPreviousCaseInputObjectSchema } from './CaseCreateWithoutPreviousCaseInput.schema'
import { CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema } from './CaseUncheckedCreateWithoutPreviousCaseInput.schema'
import { CaseCreateOrConnectWithoutPreviousCaseInputObjectSchema } from './CaseCreateOrConnectWithoutPreviousCaseInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutPreviousCaseInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutPreviousCaseInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutPreviousCaseInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CaseCreateNestedOneWithoutPreviousCaseInputObjectSchema = Schema
