import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { VariantRelationFilterObjectSchema } from './VariantRelationFilter.schema'
import { VariantWhereInputObjectSchema } from './VariantWhereInput.schema'
import { ProductRelationFilterObjectSchema } from './ProductRelationFilter.schema'
import { ProductWhereInputObjectSchema } from './ProductWhereInput.schema'
import { BrandRelationFilterObjectSchema } from './BrandRelationFilter.schema'
import { BrandWhereInputObjectSchema } from './BrandWhereInput.schema'
import { ProductReviewPictureListRelationFilterObjectSchema } from './ProductReviewPictureListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductReviewWhereInputObjectSchema),
        z.lazy(() => ProductReviewWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductReviewWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductReviewWhereInputObjectSchema),
        z.lazy(() => ProductReviewWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    productId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    brandId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    overallRating: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    qualityRating: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    shippingRating: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    packagingRating: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    message: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    patientName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    isAnonymous: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    variant: z
      .union([
        z.lazy(() => VariantRelationFilterObjectSchema),
        z.lazy(() => VariantWhereInputObjectSchema),
      ])
      .optional(),
    product: z
      .union([
        z.lazy(() => ProductRelationFilterObjectSchema),
        z.lazy(() => ProductWhereInputObjectSchema),
      ])
      .optional(),
    brand: z
      .union([
        z.lazy(() => BrandRelationFilterObjectSchema),
        z.lazy(() => BrandWhereInputObjectSchema),
      ])
      .optional(),
    pictures: z
      .lazy(() => ProductReviewPictureListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const ProductReviewWhereInputObjectSchema = Schema
