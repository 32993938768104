import { z } from 'zod'
import { AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUpsertWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUpsertWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentUpdateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUpdateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpdateOneWithoutFollowUpCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => AppointmentUpsertWithoutFollowUpCaseSummaryInputObjectSchema,
        )
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => AppointmentUpdateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              AppointmentUncheckedUpdateWithoutFollowUpCaseSummaryInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const AppointmentUpdateOneWithoutFollowUpCaseSummaryNestedInputObjectSchema =
  Schema
