import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureReviewSummaryCountOrderByAggregateInputObjectSchema } from './ProcedureReviewSummaryCountOrderByAggregateInput.schema'
import { ProcedureReviewSummaryAvgOrderByAggregateInputObjectSchema } from './ProcedureReviewSummaryAvgOrderByAggregateInput.schema'
import { ProcedureReviewSummaryMaxOrderByAggregateInputObjectSchema } from './ProcedureReviewSummaryMaxOrderByAggregateInput.schema'
import { ProcedureReviewSummaryMinOrderByAggregateInputObjectSchema } from './ProcedureReviewSummaryMinOrderByAggregateInput.schema'
import { ProcedureReviewSummarySumOrderByAggregateInputObjectSchema } from './ProcedureReviewSummarySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      procedureId: z.lazy(() => SortOrderSchema).optional(),
      partnerId: z.lazy(() => SortOrderSchema).optional(),
      overallRating: z.lazy(() => SortOrderSchema).optional(),
      environmentRating: z.lazy(() => SortOrderSchema).optional(),
      serviceRating: z.lazy(() => SortOrderSchema).optional(),
      specialtyRating: z.lazy(() => SortOrderSchema).optional(),
      valueRating: z.lazy(() => SortOrderSchema).optional(),
      countForRating5: z.lazy(() => SortOrderSchema).optional(),
      countForRating4: z.lazy(() => SortOrderSchema).optional(),
      countForRating3: z.lazy(() => SortOrderSchema).optional(),
      countForRating2: z.lazy(() => SortOrderSchema).optional(),
      countForRating1: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(
          () => ProcedureReviewSummaryCountOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _avg: z
        .lazy(() => ProcedureReviewSummaryAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ProcedureReviewSummaryMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ProcedureReviewSummaryMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ProcedureReviewSummarySumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewSummaryOrderByWithAggregationInputObjectSchema =
  Schema
