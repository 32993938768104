/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from '@mui/material'
import {
  ValidateProductImportSKUSchema,
  ValidateProductImportSchema,
} from '@ssch-backend/products'
import { TRPCError } from '@trpc/server'
import { FC, useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { FieldValues } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { ZodError, z } from 'zod'
import { CustomButton } from '../../../components/CustomButton'
import { FileValue } from '../../../components/types'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { ProductImportForm } from './importForm'

type ProductItemDataCreate = z.infer<
  typeof ValidateProductImportSchema.shape.data.element
>

type ProductImageCreate = z.infer<
  typeof ValidateProductImportSchema.shape.pictures.element
>

export interface RecordError {
  column: string
  index: number
  message: string
}

const ProductItemFromXLSXSchema = z.object({
  'Brand*': z.string().optional(),
  'SubCategory*': z.string().optional(),
  Product_Image: z.string().optional(),
  'Name_TH*': z.string().optional(),
  'Name_EN*': z.string().optional(),
  'RegNo*': z.string().optional(), // TODO -> type should be string
  'ProductType*': ValidateProductImportSchema.shape.data.element
    .pick({
      productType: true,
    })
    .shape.productType.optional(),
  'SaleType*': ValidateProductImportSchema.shape.data.element
    .pick({
      salesType: true,
    })
    .shape.salesType.optional(),
  AuxiliaryLabel: ValidateProductImportSchema.shape.data.element
    .pick({
      auxiliaryLabel: true,
    })
    .shape.auxiliaryLabel.optional(),
  DrugType: ValidateProductImportSchema.shape.data.element
    .pick({
      drugType: true,
    })
    .shape.drugType.optional(),
  ProductForm1: z.string().optional(),
  ProductForm2: z.string().optional(),
  ProductForm3: z.string().optional(),
  SkinType1: z.string().optional(),
  SkinType2: z.string().optional(),
  SkinType3: z.string().optional(),
  'Concern1*': z.string().optional(),
  'Concern2*': z.string().optional(),
  'Concern3*': z.string().optional(),
  'Attribute1*': z.string().optional(),
  'Attribute2*': z.string().optional(),
  'Attribute3*': z.string().optional(),
  'Property_TH*': z.string().optional(),
  'Property_EN*': z.string().optional(),
  'Description_TH*': z.string().optional(),
  'Description_EN*': z.string().optional(),
  'SideEffect_TH*': z.string().optional(),
  'SideEffect_EN*': z.string().optional(),
  Caution_TH: z.string().optional(),
  Caution_EN: z.string().optional(),
  Ingredient: z.string().optional(),
  Usage: ValidateProductImportSchema.shape.data.element
    .pick({
      usage: true,
    })
    .shape.usage.optional(),
  UseAmount: z.number().optional(),
  UseUnit: ValidateProductImportSchema.shape.data.element.shape.use
    .pick({
      unit: true,
    })
    .shape.unit.optional(),
  'UseFrequency*': ValidateProductImportSchema.shape.data.element.shape.use
    .pick({
      frequency: true,
    })
    .shape.frequency.optional(),
  'UseTime*': ValidateProductImportSchema.shape.data.element.shape.use
    .pick({
      time: true,
    })
    .shape.time.optional(),
  Instruction_TH: z.string().optional(),
  Instruction_EN: z.string().optional(),
  PharmacyNote: z.string().optional(),
  // 'SKU*': z.string({ required_error: 'SKU* field is required' }),
  'SKU*': z.preprocess((val) => {
    if (typeof val === 'number') {
      return val.toString()
    }
    return val
  }, z.string()),
  SKU_Image: z.string(),
  'SKU_Description_TH*': z.string(),
  'SKU_Description_EN*': z.string(),
  Size: z.number().default(1),
  Unit: ValidateProductImportSchema.shape.data.element.shape.sku.element.pick({
    unit: true,
  }).shape.unit,
  'SellingUnit*':
    ValidateProductImportSchema.shape.data.element.shape.sku.element.pick({
      sellingUnit: true,
    }).shape.sellingUnit,
  'Cost*': z.number(),
  TagPrice: z.number().nullish(),
  'SellingPrice*': z.number(),
  CanExpress: z.string().optional(),
  Warehouse:
    ValidateProductImportSchema.shape.data.element.shape.sku.element.pick({
      warehouse: true,
    }).shape.warehouse,
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  maxWidth: '800px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 100px)',
}

interface ProductImportModalProps {
  open?: boolean
  onClose?: () => void
  disableCloseOnBackdrop?: boolean
}

export const ProductImportModal: FC<ProductImportModalProps> = ({
  open = false,
  onClose,
  disableCloseOnBackdrop = false,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const [recordErrors, setRecordErrors] = useState<RecordError[] | null>(null)
  const [allowUpload, setAllowUpload] = useState(false)
  const [productData, setProductData] =
    useState<z.infer<typeof ValidateProductImportSchema>>()

  const handleClose = () => {
    setRecordErrors(null)
    setAllowUpload(false)
    onClose && onClose()
  }

  const readXlsxProductItemsFile = (file: File) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const bstr = e.target?.result
        const workbook = XLSX.read(bstr, { type: 'binary' })
        const readFile = XLSX.utils.sheet_to_json<
          z.infer<typeof ProductItemFromXLSXSchema>
        >(workbook.Sheets[workbook.SheetNames[0]])

        if (readFile.length > 100) {
          reject(
            new TRPCError({
              code: 'BAD_REQUEST',
              message: 'Excel maximum 100 rows',
            }),
          )
        }

        let productNameParentIndex = 0
        const productResult: ProductItemDataCreate[] = []

        readFile.forEach((x) => {
          try {
            const validateData = ProductItemFromXLSXSchema.parse(x)

            if (validateData['Name_TH*'] || validateData['Name_EN*']) {
              productNameParentIndex = productResult.length

              productResult.push({
                brand: validateData['Brand*']!,
                subCategory: validateData['SubCategory*']!,
                productPictures: validateData.Product_Image,
                name: {
                  th: validateData['Name_TH*']!,
                  en: validateData['Name_EN*']!,
                },
                regNo: validateData['RegNo*']?.toString() as string,
                productType: validateData['ProductType*']!,
                salesType: validateData['SaleType*']!,
                auxiliaryLabel: validateData.AuxiliaryLabel,
                drugType: validateData.DrugType,
                productForm: {
                  form1: validateData.ProductForm1,
                  form2: validateData.ProductForm2,
                  form3: validateData.ProductForm3,
                },
                skinType: {
                  type1: validateData.SkinType1,
                  type2: validateData.SkinType2,
                  type3: validateData.SkinType3,
                },
                concern: {
                  concern1: validateData['Concern1*']!,
                  concern2: validateData['Concern2*']!,
                  concern3: validateData['Concern3*']!,
                },
                attribute: {
                  attribute1: validateData['Attribute1*']!,
                  attribute2: validateData['Attribute2*']!,
                  attribute3: validateData['Attribute3*']!,
                },
                property: {
                  th: validateData['Property_TH*']!,
                  en: validateData['Property_EN*']!,
                },
                description: {
                  th: validateData['Description_TH*']!,
                  en: validateData['Description_EN*']!,
                },
                sideEffect: {
                  th: validateData['SideEffect_TH*']!,
                  en: validateData['SideEffect_EN*']!,
                },
                caution: {
                  th: validateData['Caution_TH']!,
                  en: validateData['Caution_EN']!,
                },
                ingredient: validateData.Ingredient,
                usage: validateData.Usage,
                use: {
                  amount: validateData.UseAmount,
                  unit: validateData.UseUnit,
                  frequency: validateData['UseFrequency*']!,
                  time: validateData['UseTime*']!,
                },
                instruction: {
                  th: validateData.Instruction_TH!,
                  en: validateData.Instruction_EN!,
                },
                pharmacyNote: validateData.PharmacyNote,
                sku: [],
              })
            }

            const skuObj = ValidateProductImportSKUSchema.parse({
              sku: validateData['SKU*'],
              picture: validateData.SKU_Image,
              description: {
                th: validateData['SKU_Description_TH*'],
                en: validateData['SKU_Description_EN*'],
              },
              size: validateData.Size,
              unit: validateData.Unit,
              sellingUnit: validateData['SellingUnit*'],
              cost: validateData['Cost*'],
              tagPrice: validateData.TagPrice,
              sellingPrice: validateData['SellingPrice*'],
              canExpress: Boolean(validateData.CanExpress),
              warehouse: validateData.Warehouse,
            })

            productResult[productNameParentIndex].sku.push(skuObj)
          } catch (error) {
            if (error instanceof ZodError) {
              console.log('zod error:', error.issues)
              reject(
                new TRPCError({
                  code: 'BAD_REQUEST',
                  message:
                    error?.issues
                      ?.map((issue) => `${issue.path.pop()}: ${issue.message}`)
                      .join(',') ?? 'Excel format is incorrect',
                }),
              )
            }
          }
        })
        resolve(productResult)
      }
      reader.readAsBinaryString(file)
    })
  }

  const transformPictures = async (pictures: FileValue[]) => {
    const formPictures = []

    if (pictures) {
      for (const pic of pictures) {
        if (pic.uploadPromise) {
          const url = await pic.uploadPromise

          if (url && !Array.isArray(url)) {
            formPictures.push({
              rawFile: pic.rawFile,
              src: pic.src,
              name: pic.title,
              gsPath: url.gsPath,
            })
          } else {
            throw new Error('Upload pictures failed')
          }
        } else {
          formPictures.push(pic)
        }
      }
    }

    return formPictures
  }

  const handleError = (error: unknown) => {
    if (isTRPCClientError(error) || error instanceof TRPCError) {
      notify(error.message, { type: 'error' })
    }
  }

  const handleSubmitValidateForm = async (product: FieldValues) => {
    setRecordErrors(null)
    try {
      const formPictures = (await transformPictures(
        product.pictures,
      )) as ProductImageCreate[]

      const dataFromXLSX = (await readXlsxProductItemsFile(
        product.productItems.rawFile,
      )) as ProductItemDataCreate[]

      const data: z.infer<typeof ValidateProductImportSchema> = {
        data: dataFromXLSX,
        pictures: formPictures,
      }

      const response =
        await adminGatewayClient.product.validateProductImports.mutate(data)

      if (!response.valid) {
        notify('validation failed', { type: 'error' })
        setRecordErrors(response.errors)
      } else {
        notify('validation success', { type: 'success' })
        setProductData(data)
        setAllowUpload(true)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleSubmitForm = async () => {
    if (!productData) return

    try {
      const response =
        await adminGatewayClient.product.bulkImportProduct.mutate(productData)

      if (response?.success) {
        notify('ra.notification.created', {
          messageArgs: { smart_count: 1 },
          type: 'success',
        })
        handleClose()
        refresh()
      }
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => !disableCloseOnBackdrop && handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title={translate('resources.product.title.import_modal')}
          subheader={translate('resources.product.title.product')}
        />
        <SimpleForm
          onSubmit={allowUpload ? handleSubmitForm : handleSubmitValidateForm}
          toolbar={<ProductImportModalToolbar allowUpload={allowUpload} />}
        >
          <CardContent sx={{ p: 0, width: '100%' }}>
            <ProductImportForm
              recordErrors={recordErrors}
              setAllowUpload={setAllowUpload}
            />
          </CardContent>
        </SimpleForm>
      </Card>
    </Modal>
  )
}

const ProductImportModalToolbar: FC<{
  allowUpload: boolean
}> = ({ allowUpload }) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#fff',
      }}
    >
      <CardActions sx={{ display: 'flex', gap: 2 }}>
        <CustomButton
          disabled={allowUpload}
          type="submit"
          icon={<DoneOutlineIcon />}
          label="resources.product.action.validate_xlsx_file"
        />
        <SaveButton disabled={!allowUpload} />
      </CardActions>
    </Toolbar>
  )
}
