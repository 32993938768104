import { z } from 'zod'

export const WeekdaySchema = z.enum([
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
])
