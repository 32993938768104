import { z } from 'zod'
import { CaseSummaryArgsObjectSchema } from './CaseSummaryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemSelect> = z
  .object({
    id: z.boolean().optional(),
    caseSummary: z
      .union([z.boolean(), z.lazy(() => CaseSummaryArgsObjectSchema)])
      .optional(),
    caseSummaryId: z.boolean().optional(),
    procedureId: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    amount: z.boolean().optional(),
    note: z.boolean().optional(),
  })
  .strict()

export const SuggestedProcedureItemSelectObjectSchema = Schema
