import { z } from 'zod'
import { CarouselCreateWithoutItemsInputObjectSchema } from './CarouselCreateWithoutItemsInput.schema'
import { CarouselUncheckedCreateWithoutItemsInputObjectSchema } from './CarouselUncheckedCreateWithoutItemsInput.schema'
import { CarouselCreateOrConnectWithoutItemsInputObjectSchema } from './CarouselCreateOrConnectWithoutItemsInput.schema'
import { CarouselUpsertWithoutItemsInputObjectSchema } from './CarouselUpsertWithoutItemsInput.schema'
import { CarouselWhereUniqueInputObjectSchema } from './CarouselWhereUniqueInput.schema'
import { CarouselUpdateWithoutItemsInputObjectSchema } from './CarouselUpdateWithoutItemsInput.schema'
import { CarouselUncheckedUpdateWithoutItemsInputObjectSchema } from './CarouselUncheckedUpdateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselUpdateOneRequiredWithoutItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CarouselCreateWithoutItemsInputObjectSchema),
          z.lazy(() => CarouselUncheckedCreateWithoutItemsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CarouselCreateOrConnectWithoutItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CarouselUpsertWithoutItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CarouselWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CarouselUpdateWithoutItemsInputObjectSchema),
          z.lazy(() => CarouselUncheckedUpdateWithoutItemsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const CarouselUpdateOneRequiredWithoutItemsNestedInputObjectSchema =
  Schema
