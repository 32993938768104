import { z } from 'zod'
import { ProcedureReviewUpdateInputObjectSchema } from './objects/ProcedureReviewUpdateInput.schema'
import { ProcedureReviewUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewUncheckedUpdateInput.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './objects/ProcedureReviewWhereUniqueInput.schema'

export const ProcedureReviewUpdateOneSchema = z.object({
  data: z.union([
    ProcedureReviewUpdateInputObjectSchema,
    ProcedureReviewUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureReviewWhereUniqueInputObjectSchema,
})
