import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { BooleanInput, DateTimeInput, required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomTextInput } from '../../../components/CustomInput'

interface FlashsaleProductInfoProps {
  mode: 'CREATE' | 'EDIT'
}

export const FlashsaleProductInfo: FC<FlashsaleProductInfoProps> = ({
  mode,
}) => {
  const { watch } = useFormContext()
  const startDate = watch('startAt') ?? ''

  const endDateValidation = (value: Date) => {
    if (startDate && value <= startDate) {
      return 'End date must greater than start date'
    } else if (value <= new Date()) {
      return 'End date must greater than current date'
    }

    return undefined
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={9}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CustomTextInput source="name" fullWidth multiline />
          {mode === 'EDIT' && <BooleanInput source="active" label="Active" />}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DateTimeInput source="startAt" validate={required()} />
          <DateTimeInput
            source="endAt"
            validate={[required(), endDateValidation]}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextInput source="description" fullWidth multiline />
      </Grid>
    </Grid>
  )
}
