import { DiscountSetting } from '@prisma/client/coupons'
import {
  DoctorItem,
  Prisma as PrismaOrder,
  ProcedureItem,
  ProductItem,
} from '@prisma/client/orders'
import { preprocessDate } from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'
import {
  DoctorItemModel,
  OrderModel,
  PaymentModel,
  ProcedureItemModelResponse,
  ProductItemModelResponse,
} from '../generated/zod'

export type ProductItemWithCategory = ProductItem & {
  categoryIds: number[]
  brandId: number
}

// MOCK item group
export const ITEM_GROUP = {
  PRODUCTS: 'PRODUCTS',
  PROCEDURES: 'PROCEDURES',
  DELIVERY: 'DELIVERY',
  DOCTOR_FEE: 'DOCTOR_FEE',
} as const

export type ITEM_GROUP = typeof ITEM_GROUP[keyof typeof ITEM_GROUP]

/** Order data with includes fetched from DB, for combining with data fetched from other table to create `OrderReceiptRenderData` (see `CreateOrderReceiptDataPayload`) */
export type CreateOrderReceiptDataFetchedOrder = PrismaOrder.OrderGetPayload<{
  include: {
    doctorItem: true
    productItems: true
    procedureItems: true
    coupons: true
    payments: {
      select: {
        id: true
        paymentMethod: true
        installmentTerm: true
        paymentAt: true
        amount: true
      }
    }
  }
}>

/** Order data with includes from DB, extended with other data needed for constructing `OrderReceiptRenderData` to keep in receipt table (for rendering into pdf at a later time) */
export type CreateOrderReceiptDataPayload = Omit<
  CreateOrderReceiptDataFetchedOrder,
  'doctorItem' | 'productItems' | 'procedureItems'
> & {
  productItems: ProductItemWithCategory[]
  procedureItems: (ProcedureItem & { categoryIds: number[] })[]
  doctorItem: (DoctorItem & { categoryIds: number[] }) | null
  discountSettings: DiscountSetting[]
  receiptNumber: string
}

/** Item data display in a table of order receipt PDF */
const ReceiptPDFTableRowItemSchema = z.object({
  name: z.string(),
  amount: z.string(),
  price: z.string(),
})

/** Render data of order receipt, used for rendering into a PDF or for export */
export const OrderReceiptRenderDataSchema = OrderModel.pick({
  orderNumber: true,
  orderType: true,
  customerName: true,
  customerTelNo: true,
}).extend({
  createdAt: z.preprocess(preprocessDate, z.date()),
  doctorItem: DoctorItemModel.extend({
    categoryIds: z.number().array(),
  })
    .merge(
      z.object({
        createdAt: z.preprocess(preprocessDate, z.date()),
        updatedAt: z.preprocess(preprocessDate, z.date()),
      }),
    )
    .nullable(),
  productItems: ProductItemModelResponse.extend({
    categoryIds: z.number().array(),
    brandId: z.number(),
  })
    .merge(
      z.object({
        createdAt: z.preprocess(preprocessDate, z.date()),
        updatedAt: z.preprocess(preprocessDate, z.date()),
        reviewStartAt: z.preprocess(preprocessDate, z.date()).nullish(),
        reviewExpireAt: z.preprocess(preprocessDate, z.date()).nullish(),
      }),
    )
    .array(),
  procedureItems: ProcedureItemModelResponse.extend({
    categoryIds: z.number().array(),
  })
    .merge(
      z.object({
        createdAt: z.preprocess(preprocessDate, z.date()),
        updatedAt: z.preprocess(preprocessDate, z.date()),
        expireAt: z.preprocess(preprocessDate, z.date()).nullish(),
        reviewStartAt: z.preprocess(preprocessDate, z.date()).nullish(),
        reviewExpireAt: z.preprocess(preprocessDate, z.date()).nullish(),
      }),
    )
    .array(),
  payment: PaymentModel.pick({
    id: true,
    paymentMethod: true,
    installmentTerm: true,
    amount: true,
  }).extend({ paymentAt: z.preprocess(preprocessDate, z.date()).nullish() }),
  discount: z.object({
    discountText: z.string(),
    shippingDiscount: z.number().default(0),
    generalDiscount: z.number().default(0),
    useCoinDiscount: z.number().default(0),
    discountOnProduct: z.number().default(0),
    discountOnProcedure: z.number().default(0),
  }),
  totalQuantity: z.number(),
  totalPrice: z.number(),
  displayShippingAddress: z.string(),
  totalPriceBeforeVAT: z.number(),
  totalPriceNonVAT: z.number(),
  vat: z.number(),
  tableItems: z.record(
    z.nativeEnum(ITEM_GROUP),
    ReceiptPDFTableRowItemSchema.array(),
  ),
  receiptNumber: z.string(),
  isTaxInvoice: z.boolean(),
})

/** Item data display in a table of order receipt PDF */
export type ReceiptPDFTableRowItem = z.infer<
  typeof ReceiptPDFTableRowItemSchema
>

/** Render data of order receipt, used for rendering into a PDF or for export */
export type OrderReceiptRenderData = z.infer<
  typeof OrderReceiptRenderDataSchema
>
