import { z } from 'zod'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemCreateOrConnectWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema =
  Schema
