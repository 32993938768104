import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    deliveryId: z.literal(true).optional(),
    statusCode: z.literal(true).optional(),
    statusText: z.literal(true).optional(),
    statusDate: z.literal(true).optional(),
  })
  .strict()

export const DeliveryLogMinAggregateInputObjectSchema = Schema
