import { z } from 'zod'
import { FlashsaleWhereUniqueInputObjectSchema } from './FlashsaleWhereUniqueInput.schema'
import { FlashsaleCreateWithoutItemsInputObjectSchema } from './FlashsaleCreateWithoutItemsInput.schema'
import { FlashsaleUncheckedCreateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedCreateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCreateOrConnectWithoutItemsInput> = z
  .object({
    where: z.lazy(() => FlashsaleWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => FlashsaleCreateWithoutItemsInputObjectSchema),
      z.lazy(() => FlashsaleUncheckedCreateWithoutItemsInputObjectSchema),
    ]),
  })
  .strict()

export const FlashsaleCreateOrConnectWithoutItemsInputObjectSchema = Schema
