import { z } from 'zod'
import { MyReviewWhereUniqueInputObjectSchema } from './MyReviewWhereUniqueInput.schema'
import { MyReviewCreateWithoutPatientInputObjectSchema } from './MyReviewCreateWithoutPatientInput.schema'
import { MyReviewUncheckedCreateWithoutPatientInputObjectSchema } from './MyReviewUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewCreateOrConnectWithoutPatientInput> = z
  .object({
    where: z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema),
      z.lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const MyReviewCreateOrConnectWithoutPatientInputObjectSchema = Schema
