import { z } from 'zod'
import { OrderCreateWithoutHealthInfoInputObjectSchema } from './OrderCreateWithoutHealthInfoInput.schema'
import { OrderUncheckedCreateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedCreateWithoutHealthInfoInput.schema'
import { OrderCreateOrConnectWithoutHealthInfoInputObjectSchema } from './OrderCreateOrConnectWithoutHealthInfoInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutHealthInfoInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutHealthInfoInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutHealthInfoInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutHealthInfoInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutHealthInfoInputObjectSchema = Schema
