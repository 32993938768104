import { z } from 'zod'
import { OtpCreateInputObjectSchema } from './objects/OtpCreateInput.schema'
import { OtpUncheckedCreateInputObjectSchema } from './objects/OtpUncheckedCreateInput.schema'

export const OtpCreateOneSchema = z.object({
  data: z.union([
    OtpCreateInputObjectSchema,
    OtpUncheckedCreateInputObjectSchema,
  ]),
})
