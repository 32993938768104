import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithoutDeliveryInputObjectSchema } from './ProductItemUpdateWithoutDeliveryInput.schema'
import { ProductItemUncheckedUpdateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedUpdateWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateWithWhereUniqueWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductItemUpdateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => ProductItemUncheckedUpdateWithoutDeliveryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema =
  Schema
