import { z } from 'zod'
import { OtpBlacklistWhereUniqueInputObjectSchema } from './objects/OtpBlacklistWhereUniqueInput.schema'
import { OtpBlacklistCreateInputObjectSchema } from './objects/OtpBlacklistCreateInput.schema'
import { OtpBlacklistUncheckedCreateInputObjectSchema } from './objects/OtpBlacklistUncheckedCreateInput.schema'
import { OtpBlacklistUpdateInputObjectSchema } from './objects/OtpBlacklistUpdateInput.schema'
import { OtpBlacklistUncheckedUpdateInputObjectSchema } from './objects/OtpBlacklistUncheckedUpdateInput.schema'

export const OtpBlacklistUpsertSchema = z.object({
  where: OtpBlacklistWhereUniqueInputObjectSchema,
  create: z.union([
    OtpBlacklistCreateInputObjectSchema,
    OtpBlacklistUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    OtpBlacklistUpdateInputObjectSchema,
    OtpBlacklistUncheckedUpdateInputObjectSchema,
  ]),
})
