import { z } from 'zod'
import { DiscountSettingCreateManyGroupInputObjectSchema } from './DiscountSettingCreateManyGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingCreateManyGroupInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => DiscountSettingCreateManyGroupInputObjectSchema),
      z.lazy(() => DiscountSettingCreateManyGroupInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const DiscountSettingCreateManyGroupInputEnvelopeObjectSchema = Schema
