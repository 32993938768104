import { z } from 'zod'
import { KycDataCreateNestedOneWithoutRejectReasonInputObjectSchema } from './KycDataCreateNestedOneWithoutRejectReasonInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    field: z.string(),
    reason: z.string(),
    kycData: z
      .lazy(() => KycDataCreateNestedOneWithoutRejectReasonInputObjectSchema)
      .optional(),
  })
  .strict()

export const RejectReasonCreateInputObjectSchema = Schema
