import { z } from 'zod'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'
import { KycDataCreateWithoutPatientInputObjectSchema } from './KycDataCreateWithoutPatientInput.schema'
import { KycDataUncheckedCreateWithoutPatientInputObjectSchema } from './KycDataUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCreateOrConnectWithoutPatientInput> = z
  .object({
    where: z.lazy(() => KycDataWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => KycDataCreateWithoutPatientInputObjectSchema),
      z.lazy(() => KycDataUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const KycDataCreateOrConnectWithoutPatientInputObjectSchema = Schema
