import { z } from 'zod'
import { DeliveryScalarWhereInputObjectSchema } from './DeliveryScalarWhereInput.schema'
import { DeliveryUpdateManyMutationInputObjectSchema } from './DeliveryUpdateManyMutationInput.schema'
import { DeliveryUncheckedUpdateManyWithoutDeliveriesInputObjectSchema } from './DeliveryUncheckedUpdateManyWithoutDeliveriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateManyWithWhereWithoutOrderInput> = z
  .object({
    where: z.lazy(() => DeliveryScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => DeliveryUpdateManyMutationInputObjectSchema),
      z.lazy(
        () => DeliveryUncheckedUpdateManyWithoutDeliveriesInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const DeliveryUpdateManyWithWhereWithoutOrderInputObjectSchema = Schema
