import { FC } from 'react'
import { Create, CreateProps, useNotify } from 'react-admin'
import { useNavigate } from 'react-router-dom'

type CreateSaveRouteBackProps = CreateProps & {
  children: React.ReactNode
}
export const CreateSaveRouteBack: FC<CreateSaveRouteBackProps> = ({
  children,
  ...restProps
}) => {
  const notify = useNotify()
  const navigate = useNavigate()

  const onSuccess = () => {
    notify('ra.notification.created', { messageArgs: { smart_count: 1 } })
    navigate(-1)
  }
  return (
    <Create
      {...restProps}
      mutationOptions={{ ...restProps.mutationOptions, onSuccess }}
    >
      {children}
    </Create>
  )
}
