import { z } from 'zod'
import { DiscountSettingOrderByWithRelationInputObjectSchema } from './objects/DiscountSettingOrderByWithRelationInput.schema'
import { DiscountSettingWhereInputObjectSchema } from './objects/DiscountSettingWhereInput.schema'
import { DiscountSettingWhereUniqueInputObjectSchema } from './objects/DiscountSettingWhereUniqueInput.schema'
import { DiscountSettingScalarFieldEnumSchema } from './enums/DiscountSettingScalarFieldEnum.schema'

export const DiscountSettingFindFirstSchema = z.object({
  orderBy: z
    .union([
      DiscountSettingOrderByWithRelationInputObjectSchema,
      DiscountSettingOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DiscountSettingWhereInputObjectSchema.optional(),
  cursor: DiscountSettingWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(DiscountSettingScalarFieldEnumSchema).optional(),
})
