import { z } from 'zod'
import { RefundCreateWithoutOrderInputObjectSchema } from './RefundCreateWithoutOrderInput.schema'
import { RefundUncheckedCreateWithoutOrderInputObjectSchema } from './RefundUncheckedCreateWithoutOrderInput.schema'
import { RefundCreateOrConnectWithoutOrderInputObjectSchema } from './RefundCreateOrConnectWithoutOrderInput.schema'
import { RefundCreateManyOrderInputEnvelopeObjectSchema } from './RefundCreateManyOrderInputEnvelope.schema'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCreateNestedManyWithoutOrderInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => RefundCreateWithoutOrderInputObjectSchema),
        z.lazy(() => RefundCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => RefundUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => RefundCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => RefundCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => RefundCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => RefundWhereUniqueInputObjectSchema),
        z.lazy(() => RefundWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const RefundCreateNestedManyWithoutOrderInputObjectSchema = Schema
