import { z } from 'zod'
import { OrderCreateWithoutShippingProviderInputObjectSchema } from './OrderCreateWithoutShippingProviderInput.schema'
import { OrderUncheckedCreateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedCreateWithoutShippingProviderInput.schema'
import { OrderCreateOrConnectWithoutShippingProviderInputObjectSchema } from './OrderCreateOrConnectWithoutShippingProviderInput.schema'
import { OrderUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema } from './OrderUpsertWithWhereUniqueWithoutShippingProviderInput.schema'
import { OrderCreateManyShippingProviderInputEnvelopeObjectSchema } from './OrderCreateManyShippingProviderInputEnvelope.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema } from './OrderUpdateWithWhereUniqueWithoutShippingProviderInput.schema'
import { OrderUpdateManyWithWhereWithoutShippingProviderInputObjectSchema } from './OrderUpdateManyWithWhereWithoutShippingProviderInput.schema'
import { OrderScalarWhereInputObjectSchema } from './OrderScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateManyWithoutShippingProviderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema),
          z
            .lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema)
            .array(),
          z.lazy(
            () => OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => OrderCreateOrConnectWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderCreateOrConnectWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              OrderUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => OrderCreateManyShippingProviderInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => OrderWhereUniqueInputObjectSchema),
          z.lazy(() => OrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => OrderWhereUniqueInputObjectSchema),
          z.lazy(() => OrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => OrderWhereUniqueInputObjectSchema),
          z.lazy(() => OrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => OrderWhereUniqueInputObjectSchema),
          z.lazy(() => OrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              OrderUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              OrderUpdateManyWithWhereWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderUpdateManyWithWhereWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => OrderScalarWhereInputObjectSchema),
          z.lazy(() => OrderScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateManyWithoutShippingProviderNestedInputObjectSchema =
  Schema
