import { z } from 'zod'
import { SuggestedProductItemOrderByWithRelationInputObjectSchema } from './objects/SuggestedProductItemOrderByWithRelationInput.schema'
import { SuggestedProductItemWhereInputObjectSchema } from './objects/SuggestedProductItemWhereInput.schema'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemScalarFieldEnumSchema } from './enums/SuggestedProductItemScalarFieldEnum.schema'

export const SuggestedProductItemFindFirstSchema = z.object({
  orderBy: z
    .union([
      SuggestedProductItemOrderByWithRelationInputObjectSchema,
      SuggestedProductItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProductItemWhereInputObjectSchema.optional(),
  cursor: SuggestedProductItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(SuggestedProductItemScalarFieldEnumSchema).optional(),
})
