import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { PartnerCoverPicture } from './create-edit-section/partnerCoverPicture'
import { PartnerDescription } from './create-edit-section/partnerDescription'
import { PartnerInfo } from './create-edit-section/partnerInfo'
import { transformPartner } from './transform'

const PartnerCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerCreateForm = () => {
  return (
    <SimpleForm toolbar={<PartnerCreateToolbar />}>
      <PartnerInfo />
      <PartnerDescription />
      <PartnerCoverPicture />
    </SimpleForm>
  )
}

export const PartnerCreate = () => (
  <CreateSaveRouteBack transform={transformPartner} redirect="list">
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <PartnerCreateForm />
        </CardContent>
      </Card>
    </Box>
  </CreateSaveRouteBack>
)
