import { z } from 'zod'
import { CoinTransactionWhereInputObjectSchema } from './objects/CoinTransactionWhereInput.schema'
import { CoinTransactionOrderByWithAggregationInputObjectSchema } from './objects/CoinTransactionOrderByWithAggregationInput.schema'
import { CoinTransactionScalarWhereWithAggregatesInputObjectSchema } from './objects/CoinTransactionScalarWhereWithAggregatesInput.schema'
import { CoinTransactionScalarFieldEnumSchema } from './enums/CoinTransactionScalarFieldEnum.schema'

export const CoinTransactionGroupBySchema = z.object({
  where: CoinTransactionWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CoinTransactionOrderByWithAggregationInputObjectSchema,
    CoinTransactionOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CoinTransactionScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CoinTransactionScalarFieldEnumSchema),
})
