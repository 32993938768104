import { z } from 'zod'
import { TeleconsultUpdateWithoutCaseInputObjectSchema } from './TeleconsultUpdateWithoutCaseInput.schema'
import { TeleconsultUncheckedUpdateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedUpdateWithoutCaseInput.schema'
import { TeleconsultCreateWithoutCaseInputObjectSchema } from './TeleconsultCreateWithoutCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpsertWithoutCaseInput> = z
  .object({
    update: z.union([
      z.lazy(() => TeleconsultUpdateWithoutCaseInputObjectSchema),
      z.lazy(() => TeleconsultUncheckedUpdateWithoutCaseInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => TeleconsultCreateWithoutCaseInputObjectSchema),
      z.lazy(() => TeleconsultUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const TeleconsultUpsertWithoutCaseInputObjectSchema = Schema
