import { z } from 'zod'
import { ConditionSettingWhereInputObjectSchema } from './objects/ConditionSettingWhereInput.schema'
import { ConditionSettingOrderByWithAggregationInputObjectSchema } from './objects/ConditionSettingOrderByWithAggregationInput.schema'
import { ConditionSettingScalarWhereWithAggregatesInputObjectSchema } from './objects/ConditionSettingScalarWhereWithAggregatesInput.schema'
import { ConditionSettingScalarFieldEnumSchema } from './enums/ConditionSettingScalarFieldEnum.schema'

export const ConditionSettingGroupBySchema = z.object({
  where: ConditionSettingWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ConditionSettingOrderByWithAggregationInputObjectSchema,
    ConditionSettingOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ConditionSettingScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ConditionSettingScalarFieldEnumSchema),
})
