import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateManyInput> = z
  .object({
    id: z.number().optional(),
    variantId: z.number(),
    warehouseId: z.number(),
  })
  .strict()

export const VariantWarehouseCreateManyInputObjectSchema = Schema
