import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OtpCountOrderByAggregateInputObjectSchema } from './OtpCountOrderByAggregateInput.schema'
import { OtpAvgOrderByAggregateInputObjectSchema } from './OtpAvgOrderByAggregateInput.schema'
import { OtpMaxOrderByAggregateInputObjectSchema } from './OtpMaxOrderByAggregateInput.schema'
import { OtpMinOrderByAggregateInputObjectSchema } from './OtpMinOrderByAggregateInput.schema'
import { OtpSumOrderByAggregateInputObjectSchema } from './OtpSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    ipAddress: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    reference: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    expiredAt: z.lazy(() => SortOrderSchema).optional(),
    verifiedAt: z.lazy(() => SortOrderSchema).optional(),
    otp: z.lazy(() => SortOrderSchema).optional(),
    _count: z.lazy(() => OtpCountOrderByAggregateInputObjectSchema).optional(),
    _avg: z.lazy(() => OtpAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => OtpMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => OtpMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => OtpSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const OtpOrderByWithAggregationInputObjectSchema = Schema
