import { z } from 'zod'
import { SegmentWhereUniqueInputObjectSchema } from './objects/SegmentWhereUniqueInput.schema'
import { SegmentCreateInputObjectSchema } from './objects/SegmentCreateInput.schema'
import { SegmentUncheckedCreateInputObjectSchema } from './objects/SegmentUncheckedCreateInput.schema'
import { SegmentUpdateInputObjectSchema } from './objects/SegmentUpdateInput.schema'
import { SegmentUncheckedUpdateInputObjectSchema } from './objects/SegmentUncheckedUpdateInput.schema'

export const SegmentUpsertSchema = z.object({
  where: SegmentWhereUniqueInputObjectSchema,
  create: z.union([
    SegmentCreateInputObjectSchema,
    SegmentUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    SegmentUpdateInputObjectSchema,
    SegmentUncheckedUpdateInputObjectSchema,
  ]),
})
