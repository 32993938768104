import { z } from 'zod'
import { AppointmentCreateWithoutScheduleInputObjectSchema } from './AppointmentCreateWithoutScheduleInput.schema'
import { AppointmentUncheckedCreateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedCreateWithoutScheduleInput.schema'
import { AppointmentCreateOrConnectWithoutScheduleInputObjectSchema } from './AppointmentCreateOrConnectWithoutScheduleInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateNestedOneWithoutScheduleInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AppointmentCreateWithoutScheduleInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedCreateWithoutScheduleInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => AppointmentCreateOrConnectWithoutScheduleInputObjectSchema)
        .optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const AppointmentCreateNestedOneWithoutScheduleInputObjectSchema = Schema
