import { z } from 'zod'
import { HolidayOrderByWithRelationInputObjectSchema } from './objects/HolidayOrderByWithRelationInput.schema'
import { HolidayWhereInputObjectSchema } from './objects/HolidayWhereInput.schema'
import { HolidayWhereUniqueInputObjectSchema } from './objects/HolidayWhereUniqueInput.schema'
import { HolidayScalarFieldEnumSchema } from './enums/HolidayScalarFieldEnum.schema'

export const HolidayFindManySchema = z.object({
  orderBy: z
    .union([
      HolidayOrderByWithRelationInputObjectSchema,
      HolidayOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HolidayWhereInputObjectSchema.optional(),
  cursor: HolidayWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(HolidayScalarFieldEnumSchema).optional(),
})
