import { z } from 'zod'
import { KycDataCountOutputTypeSelectObjectSchema } from './KycDataCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => KycDataCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const KycDataCountOutputTypeArgsObjectSchema = Schema
