import { z } from 'zod'
import { OfficeHourUpdateInputObjectSchema } from './objects/OfficeHourUpdateInput.schema'
import { OfficeHourUncheckedUpdateInputObjectSchema } from './objects/OfficeHourUncheckedUpdateInput.schema'
import { OfficeHourWhereUniqueInputObjectSchema } from './objects/OfficeHourWhereUniqueInput.schema'

export const OfficeHourUpdateOneSchema = z.object({
  data: z.union([
    OfficeHourUpdateInputObjectSchema,
    OfficeHourUncheckedUpdateInputObjectSchema,
  ]),
  where: OfficeHourWhereUniqueInputObjectSchema,
})
