import { z } from 'zod'
import { WarehouseCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedCreateWithoutVariantWarehousesInput.schema'
import { WarehouseCreateOrConnectWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateOrConnectWithoutVariantWarehousesInput.schema'
import { WarehouseWhereUniqueInputObjectSchema } from './WarehouseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCreateNestedOneWithoutVariantWarehousesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => WarehouseCreateWithoutVariantWarehousesInputObjectSchema,
          ),
          z.lazy(
            () =>
              WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            WarehouseCreateOrConnectWithoutVariantWarehousesInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => WarehouseWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const WarehouseCreateNestedOneWithoutVariantWarehousesInputObjectSchema =
  Schema
