import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CategoryOrderByRelationAggregateInputObjectSchema } from './CategoryOrderByRelationAggregateInput.schema'
import { ProcedureCategoryOrderByRelationAggregateInputObjectSchema } from './ProcedureCategoryOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    icon: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    path: z.lazy(() => SortOrderSchema).optional(),
    parentId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    parent: z
      .lazy(() => CategoryOrderByWithRelationInputObjectSchema)
      .optional(),
    children: z
      .lazy(() => CategoryOrderByRelationAggregateInputObjectSchema)
      .optional(),
    procedures: z
      .lazy(() => ProcedureCategoryOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CategoryOrderByWithRelationInputObjectSchema = Schema
