import { z } from 'zod'
import { ProcedureReviewCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutPicturesInput.schema'
import { ProcedureReviewCreateOrConnectWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateOrConnectWithoutPicturesInput.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewCreateNestedOneWithoutPicturesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureReviewCreateWithoutPicturesInputObjectSchema),
          z.lazy(
            () =>
              ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProcedureReviewCreateOrConnectWithoutPicturesInputObjectSchema,
        )
        .optional(),
      connect: z
        .lazy(() => ProcedureReviewWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewCreateNestedOneWithoutPicturesInputObjectSchema =
  Schema
