import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { KycDataRelationFilterObjectSchema } from './KycDataRelationFilter.schema'
import { KycDataWhereInputObjectSchema } from './KycDataWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => RejectReasonWhereInputObjectSchema),
        z.lazy(() => RejectReasonWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => RejectReasonWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => RejectReasonWhereInputObjectSchema),
        z.lazy(() => RejectReasonWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    kycDataId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    field: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    reason: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    kycData: z
      .union([
        z.lazy(() => KycDataRelationFilterObjectSchema),
        z.lazy(() => KycDataWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const RejectReasonWhereInputObjectSchema = Schema
