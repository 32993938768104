import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ProductInfoSection } from './create-edit-section/productInfo'
import { ProductPictureSecion } from './create-edit-section/productPicture'
import { ProductPropertySection } from './create-edit-section/productProperty'
import { ProductUsageSecion } from './create-edit-section/productUsage'
import { ProductVideoSecion } from './create-edit-section/productVideo'
import { transformProduct } from './transform'

const ProductCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="product" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProductCreateForm = () => {
  return (
    <SimpleForm toolbar={<ProductCreateToolbar />}>
      <ProductInfoSection />
      <ProductPropertySection />
      <ProductUsageSecion />
      <ProductPictureSecion />
      <ProductVideoSecion />
    </SimpleForm>
  )
}

export const ProductCreate = () => (
  <CreateSaveRouteBack transform={transformProduct} redirect="list">
    <Card>
      <CardContent sx={{ p: 2 }}>
        <ProductCreateForm />
      </CardContent>
    </Card>
  </CreateSaveRouteBack>
)
