import { z } from 'zod'

export const PaymentScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'transactionId',
  'amount',
  'currency',
  'paymentAt',
  'paymentGateway',
  'paymentMethod',
  'installmentTerm',
  'status',
  'confirmedBy',
  'evidencePicture',
  'reminderNote',
  'orderId',
])
