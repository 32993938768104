import { Box, Chip, Divider } from '@mui/material'
import {
  DiscountItemTypeSchema,
  DiscountScopeSchema,
} from '@ssch-backend/coupons'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  Pagination,
  ReferenceArrayField,
  RowClickFunction,
  SingleFieldList,
  useListContext,
  useLocaleState,
  useTranslate,
} from 'react-admin'
import { z } from 'zod'
import { groupBySettingGroupId } from '../../utils'
import {
  getItemTypeReferenceKey,
  optionTextDiscountSettingItem,
} from '../couponGroup/create-section/discountSetting'

interface ListViewProps extends ListProps {
  rowClick: string | RowClickFunction | false
}

type DiscountItem = z.infer<typeof DiscountItemTypeSchema>
type DiscountScope = z.infer<typeof DiscountScopeSchema>

interface DiscountSettingProps {
  itemType: DiscountItem
  items: Array<unknown> | null
}

const rowsPerPage = [5, 10, 20]

const DiscountSettingsRender = () => {
  const { isLoading, data: settings } = useListContext()
  const translate = useTranslate()
  const [locale] = useLocaleState()
  const groups = groupBySettingGroupId(settings || [])
  const groupKeys = Object.keys(groups)

  return (
    <>
      {groupKeys.map((group, index) => (
        <Box key={group}>
          <Datagrid
            size="small"
            bulkActionButtons={false}
            data={groups[group]}
            isLoading={isLoading}
          >
            <FunctionField
              source="type"
              render={(record: { itemType: DiscountItem }) =>
                translate(
                  [
                    'resources.couponGroup.title',
                    'discountItemType',
                    record.itemType.split('_')[0],
                  ].join('.'),
                )
              }
            />
            <FunctionField
              source="itemType"
              render={(record: { itemType: DiscountItem }) =>
                translate(
                  [
                    'resources.couponGroupDiscountSetting.title',
                    'itemType',
                    record.itemType,
                  ].join('.'),
                )
              }
            />
            <FunctionField
              source="scope"
              render={(record: { scope: DiscountScope }) =>
                translate(
                  ['resources.couponGroup.title', 'scope', record.scope].join(
                    '.',
                  ),
                )
              }
            />
            <FunctionField
              source="items"
              render={(record: DiscountSettingProps) =>
                record.items && (
                  <ReferenceArrayField
                    reference={getItemTypeReferenceKey(record.itemType)}
                    source="items"
                  >
                    <SingleFieldList>
                      <FunctionField
                        render={(items: unknown) => (
                          <Chip
                            label={optionTextDiscountSettingItem({
                              data: items,
                              itemTypeEnum: record.itemType,
                              locale,
                            })}
                            clickable
                            size="small"
                            sx={{ margin: '0.2rem' }}
                          />
                        )}
                      />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )
              }
            />
          </Datagrid>
          <Divider
            textAlign="left"
            sx={{
              display: index + 1 < groupKeys.length ? 'flex' : 'none',
              padding: '0.5rem',
              backgroundColor: '#f6f6f6',
            }}
          >
            {translate('operator.or')}
          </Divider>
        </Box>
      ))}
    </>
  )
}

export const DiscountSettngList = (props: Partial<ListViewProps>) => {
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        resource="couponGroupDiscountSetting"
        {...props}
      >
        <DiscountSettingsRender />
      </List>
    </Box>
  )
}
