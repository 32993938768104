import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteOrder, RelatedOrderModel, CompleteDelivery, RelatedDeliveryModel, CompleteRefund, RelatedRefundModel } from "./index"

export const ProductItemModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  sku: z.string(),
  variantId: z.number().int(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  picture: z.string(),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  quantity: z.number().int(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  sellingUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']),
  cost: z.number(),
  flashSaleId: z.number().int().nullish(),
  orderId: z.number().int(),
  deliveryId: z.number().int().nullish(),
  refundId: z.number().int().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  canExpress: z.boolean(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
  warehouse: jsonSchema,
})

export const ProductItemModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  sku: z.string(),
  variantId: z.number().int(),
  nameTr: jsonSchema,
  picture: z.string(),
  descriptionTr: jsonSchema,
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  quantity: z.number().int(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  sellingUnit: z.enum(['TUBE', 'STRIP', 'BOTTLE', 'BOX', 'JAR', 'SACHET', 'PACK', 'UNIT', 'ROLL', 'SHEET', 'TABLET', 'SET', 'STICK', 'CAPSULE', 'CASSETTE', 'ML', 'GRAM', 'AMPULE', 'PIECE', 'MACHINE', 'LUMP', 'HANDLE', 'PRESS', 'MEASURING_SPOON']),
  cost: z.number(),
  flashSaleId: z.number().int().nullish(),
  orderId: z.number().int(),
  deliveryId: z.number().int().nullish(),
  refundId: z.number().int().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  canExpress: z.boolean(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
  warehouse: jsonSchema,
})

export interface CompleteProductItem extends z.infer<typeof ProductItemModel> {
  order: CompleteOrder
  delivery?: CompleteDelivery | null
  refund?: CompleteRefund | null
}

/**
 * RelatedProductItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductItemModel: z.ZodSchema<CompleteProductItem> = z.lazy(() => ProductItemModel.extend({
  order: RelatedOrderModel,
  delivery: RelatedDeliveryModel.nullish(),
  refund: RelatedRefundModel.nullish(),
}))
