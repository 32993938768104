import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { EnumOrderItemTypeFilterObjectSchema } from './EnumOrderItemTypeFilter.schema'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumDrugTypeNullableFilterObjectSchema } from './EnumDrugTypeNullableFilter.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => MyReviewScalarWhereInputObjectSchema),
        z.lazy(() => MyReviewScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => MyReviewScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => MyReviewScalarWhereInputObjectSchema),
        z.lazy(() => MyReviewScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    picture: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    titleTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    subtitleTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    orderItemId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    orderItemType: z
      .union([
        z.lazy(() => EnumOrderItemTypeFilterObjectSchema),
        z.lazy(() => OrderItemTypeSchema),
      ])
      .optional(),
    productId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    procedureId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    quantity: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    drugType: z
      .union([
        z.lazy(() => EnumDrugTypeNullableFilterObjectSchema),
        z.lazy(() => DrugTypeSchema),
      ])
      .optional()
      .nullable(),
    reviewId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    reviewStartAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    reviewExpireAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
  })
  .strict()

export const MyReviewScalarWhereInputObjectSchema = Schema
