import { z } from 'zod'
import { CaseCreateNestedOneWithoutCaseSummaryInputObjectSchema } from './CaseCreateNestedOneWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateNestedManyWithoutCaseSummaryInput.schema'
import { AppointmentCreateNestedOneWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateNestedOneWithoutFollowUpCaseSummaryInput.schema'
import { SuggestedProductItemCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateNestedManyWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalCreateNestedManyWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateNestedManyWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateNestedManyWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    presentIllness: z.string().optional().nullable(),
    peNote: z.string().optional().nullable(),
    isTeleMedNotRecommend: z.boolean(),
    recommendation: z.string(),
    doctorFee: z.number(),
    doctorFeeDiscount: z.number(),
    commissionRate: z.number().optional(),
    commissionPrice: z.number().optional(),
    netDoctorFee: z.number().optional(),
    doctorNote: z.string().optional().nullable(),
    pharmacistNote: z.string().optional().nullable(),
    recommedNumberOfTreatment: z.number().optional().nullable(),
    additionalSuggestedProcedure: z.string().optional().nullable(),
    editedSuggestedProductItemAt: z.date().optional().nullable(),
    editedSuggestedProductItemBy: z.number().optional().nullable(),
    v2CaseSummaryId: z.number().optional().nullable(),
    caseUrl: z.string().optional().nullable(),
    case: z.lazy(() => CaseCreateNestedOneWithoutCaseSummaryInputObjectSchema),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    followUpAppointment: z
      .lazy(
        () =>
          AppointmentCreateNestedOneWithoutFollowUpCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProductItems: z
      .lazy(
        () =>
          SuggestedProductItemCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(
        () =>
          SuggestedProductItemOriginalCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
    suggestedProcedureItems: z
      .lazy(
        () =>
          SuggestedProcedureItemCreateNestedManyWithoutCaseSummaryInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryCreateInputObjectSchema = Schema
