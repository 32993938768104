import { z } from 'zod'
import { WarehouseCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedCreateWithoutVariantWarehousesInput.schema'
import { WarehouseCreateOrConnectWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateOrConnectWithoutVariantWarehousesInput.schema'
import { WarehouseUpsertWithoutVariantWarehousesInputObjectSchema } from './WarehouseUpsertWithoutVariantWarehousesInput.schema'
import { WarehouseWhereUniqueInputObjectSchema } from './WarehouseWhereUniqueInput.schema'
import { WarehouseUpdateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUpdateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedUpdateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedUpdateWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUpdateOneRequiredWithoutVariantWarehousesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => WarehouseCreateWithoutVariantWarehousesInputObjectSchema,
          ),
          z.lazy(
            () =>
              WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            WarehouseCreateOrConnectWithoutVariantWarehousesInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => WarehouseUpsertWithoutVariantWarehousesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => WarehouseWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => WarehouseUpdateWithoutVariantWarehousesInputObjectSchema,
          ),
          z.lazy(
            () =>
              WarehouseUncheckedUpdateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const WarehouseUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema =
  Schema
