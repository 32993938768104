import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'
import { ProductItemFindManySchema } from '../findManyProductItem.schema'
import { DeliveryLogFindManySchema } from '../findManyDeliveryLog.schema'
import { DeliveryCountOutputTypeArgsObjectSchema } from './DeliveryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryInclude> = z
  .object({
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    productItems: z
      .union([z.boolean(), z.lazy(() => ProductItemFindManySchema)])
      .optional(),
    deliveryLogs: z
      .union([z.boolean(), z.lazy(() => DeliveryLogFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => DeliveryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const DeliveryIncludeObjectSchema = Schema
