import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemCreateWithoutCarouselInputObjectSchema } from './CarouselItemCreateWithoutCarouselInput.schema'
import { CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedCreateWithoutCarouselInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateOrConnectWithoutCarouselInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema =
  Schema
