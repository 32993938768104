import { z } from 'zod'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './objects/ProcedureReviewPictureWhereUniqueInput.schema'
import { ProcedureReviewPictureCreateInputObjectSchema } from './objects/ProcedureReviewPictureCreateInput.schema'
import { ProcedureReviewPictureUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewPictureUncheckedCreateInput.schema'
import { ProcedureReviewPictureUpdateInputObjectSchema } from './objects/ProcedureReviewPictureUpdateInput.schema'
import { ProcedureReviewPictureUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewPictureUncheckedUpdateInput.schema'

export const ProcedureReviewPictureUpsertSchema = z.object({
  where: ProcedureReviewPictureWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureReviewPictureCreateInputObjectSchema,
    ProcedureReviewPictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureReviewPictureUpdateInputObjectSchema,
    ProcedureReviewPictureUncheckedUpdateInputObjectSchema,
  ]),
})
