import { z } from 'zod'
import { SuggestedProcedureItemCreateInputObjectSchema } from './objects/SuggestedProcedureItemCreateInput.schema'
import { SuggestedProcedureItemUncheckedCreateInputObjectSchema } from './objects/SuggestedProcedureItemUncheckedCreateInput.schema'

export const SuggestedProcedureItemCreateOneSchema = z.object({
  data: z.union([
    SuggestedProcedureItemCreateInputObjectSchema,
    SuggestedProcedureItemUncheckedCreateInputObjectSchema,
  ]),
})
