import { z } from 'zod'
import { FlashsaleCreateWithoutItemsInputObjectSchema } from './FlashsaleCreateWithoutItemsInput.schema'
import { FlashsaleUncheckedCreateWithoutItemsInputObjectSchema } from './FlashsaleUncheckedCreateWithoutItemsInput.schema'
import { FlashsaleCreateOrConnectWithoutItemsInputObjectSchema } from './FlashsaleCreateOrConnectWithoutItemsInput.schema'
import { FlashsaleWhereUniqueInputObjectSchema } from './FlashsaleWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCreateNestedOneWithoutItemsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => FlashsaleCreateWithoutItemsInputObjectSchema),
        z.lazy(() => FlashsaleUncheckedCreateWithoutItemsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => FlashsaleCreateOrConnectWithoutItemsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => FlashsaleWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const FlashsaleCreateNestedOneWithoutItemsInputObjectSchema = Schema
