import { z } from 'zod'
import { OtpWhereInputObjectSchema } from './objects/OtpWhereInput.schema'
import { OtpOrderByWithAggregationInputObjectSchema } from './objects/OtpOrderByWithAggregationInput.schema'
import { OtpScalarWhereWithAggregatesInputObjectSchema } from './objects/OtpScalarWhereWithAggregatesInput.schema'
import { OtpScalarFieldEnumSchema } from './enums/OtpScalarFieldEnum.schema'

export const OtpGroupBySchema = z.object({
  where: OtpWhereInputObjectSchema.optional(),
  orderBy: z.union([
    OtpOrderByWithAggregationInputObjectSchema,
    OtpOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: OtpScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(OtpScalarFieldEnumSchema),
})
