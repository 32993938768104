import { z } from 'zod'
import { ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUpsertWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUpsertWithoutProcedureInput.schema'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './ProcedureReviewSummaryWhereUniqueInput.schema'
import { ProcedureReviewSummaryUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUpdateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryUpdateOneWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProcedureReviewSummaryCreateOrConnectWithoutProcedureInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => ProcedureReviewSummaryUpsertWithoutProcedureInputObjectSchema,
        )
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z
        .lazy(() => ProcedureReviewSummaryWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(
            () => ProcedureReviewSummaryUpdateWithoutProcedureInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewSummaryUpdateOneWithoutProcedureNestedInputObjectSchema =
  Schema
