import { z } from 'zod'
import { FlashsaleArgsObjectSchema } from './FlashsaleArgs.schema'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemInclude> = z
  .object({
    flashsale: z
      .union([z.boolean(), z.lazy(() => FlashsaleArgsObjectSchema)])
      .optional(),
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const FlashsaleItemIncludeObjectSchema = Schema
