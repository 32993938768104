import { z } from 'zod'
import { MyReviewCreateManyPatientInputObjectSchema } from './MyReviewCreateManyPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewCreateManyPatientInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => MyReviewCreateManyPatientInputObjectSchema),
      z.lazy(() => MyReviewCreateManyPatientInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const MyReviewCreateManyPatientInputEnvelopeObjectSchema = Schema
