import { z } from 'zod'
import { AcceptedConsentFindManySchema } from '../findManyAcceptedConsent.schema'
import { KycDataArgsObjectSchema } from './KycDataArgs.schema'
import { HealthInfoArgsObjectSchema } from './HealthInfoArgs.schema'
import { NotificationPatientFindManySchema } from '../findManyNotificationPatient.schema'
import { AddressFindManySchema } from '../findManyAddress.schema'
import { IdentityFindManySchema } from '../findManyIdentity.schema'
import { CoinTransactionFindManySchema } from '../findManyCoinTransaction.schema'
import { MyReviewFindManySchema } from '../findManyMyReview.schema'
import { PatientCountOutputTypeArgsObjectSchema } from './PatientCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    prefix: z.boolean().optional(),
    firstname: z.boolean().optional(),
    lastname: z.boolean().optional(),
    nickname: z.boolean().optional(),
    gender: z.boolean().optional(),
    birthDate: z.boolean().optional(),
    picture: z.boolean().optional(),
    isTest: z.boolean().optional(),
    active: z.boolean().optional(),
    registered: z.boolean().optional(),
    acceptedConsents: z
      .union([z.boolean(), z.lazy(() => AcceptedConsentFindManySchema)])
      .optional(),
    kycData: z
      .union([z.boolean(), z.lazy(() => KycDataArgsObjectSchema)])
      .optional(),
    drugAllergy: z.boolean().optional(),
    congenitalDisease: z.boolean().optional(),
    healthCheckUpInfo: z
      .union([z.boolean(), z.lazy(() => HealthInfoArgsObjectSchema)])
      .optional(),
    receiveNotifications: z.boolean().optional(),
    activityNotifications: z.boolean().optional(),
    chatNotifications: z.boolean().optional(),
    orderNotifications: z.boolean().optional(),
    notifications: z
      .union([z.boolean(), z.lazy(() => NotificationPatientFindManySchema)])
      .optional(),
    omiseCustomerId: z.boolean().optional(),
    freshchatUserId: z.boolean().optional(),
    freshchatRestoreId: z.boolean().optional(),
    address: z
      .union([z.boolean(), z.lazy(() => AddressFindManySchema)])
      .optional(),
    identities: z
      .union([z.boolean(), z.lazy(() => IdentityFindManySchema)])
      .optional(),
    coin: z.boolean().optional(),
    coinTransactions: z
      .union([z.boolean(), z.lazy(() => CoinTransactionFindManySchema)])
      .optional(),
    v2PatientId: z.boolean().optional(),
    myReviews: z
      .union([z.boolean(), z.lazy(() => MyReviewFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => PatientCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const PatientSelectObjectSchema = Schema
