import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    channel: z.literal(true).optional(),
    status: z.literal(true).optional(),
    approvedAt: z.literal(true).optional(),
    approvedBy: z.literal(true).optional(),
    prefix: z.literal(true).optional(),
    firstname: z.literal(true).optional(),
    lastname: z.literal(true).optional(),
    gender: z.literal(true).optional(),
    birthDate: z.literal(true).optional(),
    idCardNumber: z.literal(true).optional(),
    idCardPicture: z.literal(true).optional(),
    selfieIdCardPicture: z.literal(true).optional(),
    passportNumber: z.literal(true).optional(),
    passportPicture: z.literal(true).optional(),
    selfiePassportPicture: z.literal(true).optional(),
    rejectAt: z.literal(true).optional(),
    rejectBy: z.literal(true).optional(),
  })
  .strict()

export const KycDataMaxAggregateInputObjectSchema = Schema
