import { z } from 'zod'
import { ProductReviewUpdateInputObjectSchema } from './objects/ProductReviewUpdateInput.schema'
import { ProductReviewUncheckedUpdateInputObjectSchema } from './objects/ProductReviewUncheckedUpdateInput.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './objects/ProductReviewWhereUniqueInput.schema'

export const ProductReviewUpdateOneSchema = z.object({
  data: z.union([
    ProductReviewUpdateInputObjectSchema,
    ProductReviewUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductReviewWhereUniqueInputObjectSchema,
})
