import { z } from 'zod'
import { CarouselItemCreateManyProcedureInputObjectSchema } from './CarouselItemCreateManyProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateManyProcedureInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => CarouselItemCreateManyProcedureInputObjectSchema),
      z.lazy(() => CarouselItemCreateManyProcedureInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const CarouselItemCreateManyProcedureInputEnvelopeObjectSchema = Schema
