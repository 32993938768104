import { z } from 'zod'
import { AddressWhereUniqueInputObjectSchema } from './objects/AddressWhereUniqueInput.schema'
import { AddressCreateInputObjectSchema } from './objects/AddressCreateInput.schema'
import { AddressUncheckedCreateInputObjectSchema } from './objects/AddressUncheckedCreateInput.schema'
import { AddressUpdateInputObjectSchema } from './objects/AddressUpdateInput.schema'
import { AddressUncheckedUpdateInputObjectSchema } from './objects/AddressUncheckedUpdateInput.schema'

export const AddressUpsertSchema = z.object({
  where: AddressWhereUniqueInputObjectSchema,
  create: z.union([
    AddressCreateInputObjectSchema,
    AddressUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    AddressUpdateInputObjectSchema,
    AddressUncheckedUpdateInputObjectSchema,
  ]),
})
