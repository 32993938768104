import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumPaymentGatewayFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => PaymentGatewaySchema).optional(),
  })
  .strict()

export const EnumPaymentGatewayFieldUpdateOperationsInputObjectSchema = Schema
