import { z } from 'zod'
import { ProductItemOriginalOrderByWithRelationInputObjectSchema } from './objects/ProductItemOriginalOrderByWithRelationInput.schema'
import { ProductItemOriginalWhereInputObjectSchema } from './objects/ProductItemOriginalWhereInput.schema'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './objects/ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalScalarFieldEnumSchema } from './enums/ProductItemOriginalScalarFieldEnum.schema'

export const ProductItemOriginalFindManySchema = z.object({
  orderBy: z
    .union([
      ProductItemOriginalOrderByWithRelationInputObjectSchema,
      ProductItemOriginalOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductItemOriginalWhereInputObjectSchema.optional(),
  cursor: ProductItemOriginalWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductItemOriginalScalarFieldEnumSchema).optional(),
})
