import { z } from 'zod'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumUseTimeFilter> = z
  .object({
    equals: z.lazy(() => UseTimeSchema).optional(),
    in: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => UseTimeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => UseTimeSchema),
        z.lazy(() => NestedEnumUseTimeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumUseTimeFilterObjectSchema = Schema
