import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    presentIllness: z.literal(true).optional(),
    peNote: z.literal(true).optional(),
    isTeleMedNotRecommend: z.literal(true).optional(),
    recommendation: z.literal(true).optional(),
    doctorFee: z.literal(true).optional(),
    doctorFeeDiscount: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    commissionPrice: z.literal(true).optional(),
    netDoctorFee: z.literal(true).optional(),
    doctorNote: z.literal(true).optional(),
    pharmacistNote: z.literal(true).optional(),
    followUpAppointmentId: z.literal(true).optional(),
    recommedNumberOfTreatment: z.literal(true).optional(),
    additionalSuggestedProcedure: z.literal(true).optional(),
    editedSuggestedProductItemAt: z.literal(true).optional(),
    editedSuggestedProductItemBy: z.literal(true).optional(),
    v2CaseSummaryId: z.literal(true).optional(),
    caseUrl: z.literal(true).optional(),
  })
  .strict()

export const CaseSummaryMaxAggregateInputObjectSchema = Schema
