import { z } from 'zod'
import { CarouselItemWhereInputObjectSchema } from './objects/CarouselItemWhereInput.schema'
import { CarouselItemOrderByWithAggregationInputObjectSchema } from './objects/CarouselItemOrderByWithAggregationInput.schema'
import { CarouselItemScalarWhereWithAggregatesInputObjectSchema } from './objects/CarouselItemScalarWhereWithAggregatesInput.schema'
import { CarouselItemScalarFieldEnumSchema } from './enums/CarouselItemScalarFieldEnum.schema'

export const CarouselItemGroupBySchema = z.object({
  where: CarouselItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CarouselItemOrderByWithAggregationInputObjectSchema,
    CarouselItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CarouselItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CarouselItemScalarFieldEnumSchema),
})
