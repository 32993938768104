import { z } from 'zod'
import { SuggestedProductItemWhereInputObjectSchema } from './SuggestedProductItemWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemListRelationFilter> = z
  .object({
    every: z.lazy(() => SuggestedProductItemWhereInputObjectSchema).optional(),
    some: z.lazy(() => SuggestedProductItemWhereInputObjectSchema).optional(),
    none: z.lazy(() => SuggestedProductItemWhereInputObjectSchema).optional(),
  })
  .strict()

export const SuggestedProductItemListRelationFilterObjectSchema = Schema
