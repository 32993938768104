import { z } from 'zod'
import { FlashsaleWhereUniqueInputObjectSchema } from './objects/FlashsaleWhereUniqueInput.schema'
import { FlashsaleCreateInputObjectSchema } from './objects/FlashsaleCreateInput.schema'
import { FlashsaleUncheckedCreateInputObjectSchema } from './objects/FlashsaleUncheckedCreateInput.schema'
import { FlashsaleUpdateInputObjectSchema } from './objects/FlashsaleUpdateInput.schema'
import { FlashsaleUncheckedUpdateInputObjectSchema } from './objects/FlashsaleUncheckedUpdateInput.schema'

export const FlashsaleUpsertSchema = z.object({
  where: FlashsaleWhereUniqueInputObjectSchema,
  create: z.union([
    FlashsaleCreateInputObjectSchema,
    FlashsaleUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    FlashsaleUpdateInputObjectSchema,
    FlashsaleUncheckedUpdateInputObjectSchema,
  ]),
})
