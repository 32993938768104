import { z } from 'zod'
import { CaseSelectObjectSchema } from './CaseSelect.schema'
import { CaseIncludeObjectSchema } from './CaseInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseArgs> = z
  .object({
    select: z.lazy(() => CaseSelectObjectSchema).optional(),
    include: z.lazy(() => CaseIncludeObjectSchema).optional(),
  })
  .strict()

export const CaseArgsObjectSchema = Schema
