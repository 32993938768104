import { z } from 'zod'
import { TeleconsultCreateWithoutAppointmentInputObjectSchema } from './TeleconsultCreateWithoutAppointmentInput.schema'
import { TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema } from './TeleconsultUncheckedCreateWithoutAppointmentInput.schema'
import { TeleconsultCreateOrConnectWithoutAppointmentInputObjectSchema } from './TeleconsultCreateOrConnectWithoutAppointmentInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateNestedOneWithoutAppointmentInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => TeleconsultCreateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => TeleconsultCreateOrConnectWithoutAppointmentInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const TeleconsultCreateNestedOneWithoutAppointmentInputObjectSchema =
  Schema
