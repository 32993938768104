import { z } from 'zod'
import { ProductReviewSummaryScalarWhereInputObjectSchema } from './ProductReviewSummaryScalarWhereInput.schema'
import { ProductReviewSummaryUpdateManyMutationInputObjectSchema } from './ProductReviewSummaryUpdateManyMutationInput.schema'
import { ProductReviewSummaryUncheckedUpdateManyWithoutProductReviewSummariesInputObjectSchema } from './ProductReviewSummaryUncheckedUpdateManyWithoutProductReviewSummariesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUpdateManyWithWhereWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewSummaryScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewSummaryUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedUpdateManyWithoutProductReviewSummariesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewSummaryUpdateManyWithWhereWithoutBrandInputObjectSchema =
  Schema
