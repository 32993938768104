import {
  CheckCircle,
  ExpandMore as ExpandMoreIcon,
  HighlightOff,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useState } from 'react'
import { useLocaleState, useTranslate } from 'react-admin'
import { ProcedureModelWithProduct } from './procedureInfo'

interface ExpandProcedureProps extends IconButtonProps {
  expand: boolean
}

const tr_resource = 'resources.flashsaleItemProcedure'

const ExpandProcedure = styled((props: ExpandProcedureProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const ProcedureDetailCard: FC<{
  Procedure: ProcedureModelWithProduct
}> = ({ Procedure }) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const [locale] = useLocaleState()
  const translate = useTranslate()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Card>
      <CardHeader
        subheader={translate(
          [tr_resource, 'title', 'procedure_detail'].join('.'),
        )}
        action={
          <ExpandProcedure
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandProcedure>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={12}>
              <List dense={true} disablePadding>
                <ListItem>
                  <ListItemIcon>
                    {translate([tr_resource, 'fields', 'id'].join('.'))}
                  </ListItemIcon>
                  <ListItemText primary={Procedure.id} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {translate([tr_resource, 'fields', 'cost'].join('.'))}
                  </ListItemIcon>
                  <ListItemText
                    primary={Intl.NumberFormat().format(Procedure.cost)}
                    sx={{ paddingLeft: 1 }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {translate(
                      [tr_resource, 'fields', 'sellingPrice'].join('.'),
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={Intl.NumberFormat().format(Procedure.sellingPrice)}
                    sx={{ paddingLeft: 1 }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {translate([tr_resource, 'fields', 'tagPrice'].join('.'))}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      Procedure.tagPrice
                        ? Intl.NumberFormat().format(Procedure.tagPrice)
                        : '-'
                    }
                    sx={{ paddingLeft: 1 }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {translate([tr_resource, 'fields', 'nameTr'].join('.'))}
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={getTr(Procedure.nameTr, locale as Language)}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {translate([tr_resource, 'fields', 'active'].join('.'))}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ display: 'flex', alignItems: 'center' }}
                    disableTypography
                  >
                    {Procedure.active ? (
                      <CheckCircle color="success" />
                    ) : (
                      <HighlightOff color="error" />
                    )}
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            {Procedure.pictures && Procedure.pictures.length && (
              <Grid item xs={6} md={12} display="flex" justifyContent="center">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: { xs: 350, md: 250 },
                    objectFit: 'contain',
                  }}
                  justifySelf="center"
                  src={Procedure.pictures[0].src}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  )
}
