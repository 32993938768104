import { z } from 'zod'
import { ProductReviewOrderByWithRelationInputObjectSchema } from './objects/ProductReviewOrderByWithRelationInput.schema'
import { ProductReviewWhereInputObjectSchema } from './objects/ProductReviewWhereInput.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './objects/ProductReviewWhereUniqueInput.schema'
import { ProductReviewCountAggregateInputObjectSchema } from './objects/ProductReviewCountAggregateInput.schema'
import { ProductReviewMinAggregateInputObjectSchema } from './objects/ProductReviewMinAggregateInput.schema'
import { ProductReviewMaxAggregateInputObjectSchema } from './objects/ProductReviewMaxAggregateInput.schema'
import { ProductReviewAvgAggregateInputObjectSchema } from './objects/ProductReviewAvgAggregateInput.schema'
import { ProductReviewSumAggregateInputObjectSchema } from './objects/ProductReviewSumAggregateInput.schema'

export const ProductReviewAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductReviewOrderByWithRelationInputObjectSchema,
      ProductReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewWhereInputObjectSchema.optional(),
  cursor: ProductReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductReviewCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductReviewMinAggregateInputObjectSchema.optional(),
  _max: ProductReviewMaxAggregateInputObjectSchema.optional(),
  _avg: ProductReviewAvgAggregateInputObjectSchema.optional(),
  _sum: ProductReviewSumAggregateInputObjectSchema.optional(),
})
