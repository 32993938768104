import { z } from 'zod'
import { CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUpsertWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUpsertWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryUpdateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUpdateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateOneWithoutFollowUpAppointmentNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => CaseSummaryUpsertWithoutFollowUpAppointmentInputObjectSchema,
        )
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => CaseSummaryUpdateWithoutFollowUpAppointmentInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryUpdateOneWithoutFollowUpAppointmentNestedInputObjectSchema =
  Schema
