import { z } from 'zod'
import { ProductItemWhereInputObjectSchema } from './objects/ProductItemWhereInput.schema'
import { ProductItemOrderByWithAggregationInputObjectSchema } from './objects/ProductItemOrderByWithAggregationInput.schema'
import { ProductItemScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductItemScalarWhereWithAggregatesInput.schema'
import { ProductItemScalarFieldEnumSchema } from './enums/ProductItemScalarFieldEnum.schema'

export const ProductItemGroupBySchema = z.object({
  where: ProductItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductItemOrderByWithAggregationInputObjectSchema,
    ProductItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductItemScalarFieldEnumSchema),
})
