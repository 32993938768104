import { z } from 'zod'
import { OrderNotificationWhereUniqueInputObjectSchema } from './objects/OrderNotificationWhereUniqueInput.schema'
import { OrderNotificationCreateInputObjectSchema } from './objects/OrderNotificationCreateInput.schema'
import { OrderNotificationUncheckedCreateInputObjectSchema } from './objects/OrderNotificationUncheckedCreateInput.schema'
import { OrderNotificationUpdateInputObjectSchema } from './objects/OrderNotificationUpdateInput.schema'
import { OrderNotificationUncheckedUpdateInputObjectSchema } from './objects/OrderNotificationUncheckedUpdateInput.schema'

export const OrderNotificationUpsertSchema = z.object({
  where: OrderNotificationWhereUniqueInputObjectSchema,
  create: z.union([
    OrderNotificationCreateInputObjectSchema,
    OrderNotificationUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    OrderNotificationUpdateInputObjectSchema,
    OrderNotificationUncheckedUpdateInputObjectSchema,
  ]),
})
