import { z } from 'zod'

export const DiscountSettingScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'scope',
  'itemType',
  'items',
  'settingGroupId',
  'groupId',
])
