import { z } from 'zod'
import { PatientUpdateWithoutIdentitiesInputObjectSchema } from './PatientUpdateWithoutIdentitiesInput.schema'
import { PatientUncheckedUpdateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedUpdateWithoutIdentitiesInput.schema'
import { PatientCreateWithoutIdentitiesInputObjectSchema } from './PatientCreateWithoutIdentitiesInput.schema'
import { PatientUncheckedCreateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedCreateWithoutIdentitiesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutIdentitiesInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutIdentitiesInputObjectSchema),
      z.lazy(() => PatientUncheckedUpdateWithoutIdentitiesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutIdentitiesInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutIdentitiesInputObjectSchema),
    ]),
  })
  .strict()

export const PatientUpsertWithoutIdentitiesInputObjectSchema = Schema
