import { Typography } from '@mui/material'
import { FC } from 'react'
import { required } from 'react-admin'
import { FormReferenceArrayInput } from './formReferenceArrayInput'
import { FormReferenceInput } from './formReferenceInput'

export const SpecialtyAndExpertiseSection: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ความเชี่ยวชาญพิเศษ & ความชำนาญ
      </Typography>
      <FormReferenceInput
        source="specialtyId"
        reference="doctorSpecialty"
        optionText="nameTh"
        label="ความเชี่ยวชาญพิเศษ"
        validator={required()}
      />
      <FormReferenceArrayInput
        source="expertise"
        reference="doctorExpertise"
        optionText="nameTh"
        label="ความชำนาญ"
      />
    </>
  )
}
