import { z } from 'zod'
import { CategoryUpdateWithoutChildrenInputObjectSchema } from './CategoryUpdateWithoutChildrenInput.schema'
import { CategoryUncheckedUpdateWithoutChildrenInputObjectSchema } from './CategoryUncheckedUpdateWithoutChildrenInput.schema'
import { CategoryCreateWithoutChildrenInputObjectSchema } from './CategoryCreateWithoutChildrenInput.schema'
import { CategoryUncheckedCreateWithoutChildrenInputObjectSchema } from './CategoryUncheckedCreateWithoutChildrenInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryUpsertWithoutChildrenInput> = z
  .object({
    update: z.union([
      z.lazy(() => CategoryUpdateWithoutChildrenInputObjectSchema),
      z.lazy(() => CategoryUncheckedUpdateWithoutChildrenInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CategoryCreateWithoutChildrenInputObjectSchema),
      z.lazy(() => CategoryUncheckedCreateWithoutChildrenInputObjectSchema),
    ]),
  })
  .strict()

export const CategoryUpsertWithoutChildrenInputObjectSchema = Schema
