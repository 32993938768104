import { z } from 'zod'
import { OrderCreateWithoutDeliveriesInputObjectSchema } from './OrderCreateWithoutDeliveriesInput.schema'
import { OrderUncheckedCreateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedCreateWithoutDeliveriesInput.schema'
import { OrderCreateOrConnectWithoutDeliveriesInputObjectSchema } from './OrderCreateOrConnectWithoutDeliveriesInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutDeliveriesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutDeliveriesInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutDeliveriesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutDeliveriesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutDeliveriesInputObjectSchema = Schema
