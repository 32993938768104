import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureCreateManyInput> = z
  .object({
    id: z.number().optional(),
    url: z.string(),
    rank: z.number().optional(),
    procedureId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const ProcedurePictureCreateManyInputObjectSchema = Schema
