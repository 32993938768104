import { z } from 'zod'
import { ProductVideoCreateWithoutProductInputObjectSchema } from './ProductVideoCreateWithoutProductInput.schema'
import { ProductVideoUncheckedCreateWithoutProductInputObjectSchema } from './ProductVideoUncheckedCreateWithoutProductInput.schema'
import { ProductVideoCreateOrConnectWithoutProductInputObjectSchema } from './ProductVideoCreateOrConnectWithoutProductInput.schema'
import { ProductVideoCreateManyProductInputEnvelopeObjectSchema } from './ProductVideoCreateManyProductInputEnvelope.schema'
import { ProductVideoWhereUniqueInputObjectSchema } from './ProductVideoWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUncheckedCreateNestedManyWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductVideoCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductVideoCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductVideoUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductVideoUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductVideoCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductVideoCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductVideoCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductVideoUncheckedCreateNestedManyWithoutProductInputObjectSchema =
  Schema
