import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalCountOrderByAggregateInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      sku: z.lazy(() => SortOrderSchema).optional(),
      variantId: z.lazy(() => SortOrderSchema).optional(),
      nameTr: z.lazy(() => SortOrderSchema).optional(),
      picture: z.lazy(() => SortOrderSchema).optional(),
      descriptionTr: z.lazy(() => SortOrderSchema).optional(),
      drugType: z.lazy(() => SortOrderSchema).optional(),
      quantity: z.lazy(() => SortOrderSchema).optional(),
      tagPrice: z.lazy(() => SortOrderSchema).optional(),
      sellingPrice: z.lazy(() => SortOrderSchema).optional(),
      sellingUnit: z.lazy(() => SortOrderSchema).optional(),
      cost: z.lazy(() => SortOrderSchema).optional(),
      flashSaleId: z.lazy(() => SortOrderSchema).optional(),
      orderId: z.lazy(() => SortOrderSchema).optional(),
      v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
      canExpress: z.lazy(() => SortOrderSchema).optional(),
      warehouse: z.lazy(() => SortOrderSchema).optional(),
    })
    .strict()

export const ProductItemOriginalCountOrderByAggregateInputObjectSchema = Schema
