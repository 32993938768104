import { DataProvider, Identifier, SortPayload } from 'react-admin'
import { service } from './service'

const CUSTOM_PRIMARYKEY_RESOURCES = {
  patientConsent: {
    name: 'key',
    isNumber: false,
  },
  doctorConsent: {
    name: 'key',
    isNumber: false,
  },
} as { [key: string]: { name: string; isNumber: boolean } }

const OPERATIONS = new Set([
  'equals',
  'not',
  'in',
  'notIn',
  'lt',
  'lte',
  'gt',
  'gte',
  'contains',
  'startsWith',
  'endsWith',
  'arrayContains',
])

const ARRAY_OPERATIONS = new Set(['OR', 'AND'])

const convertFilter = (
  resourceName: string,
  filter: { [key: string]: unknown },
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const where: { [key: string]: any } = {}
  Object.keys(filter).forEach((key) => {
    if (ARRAY_OPERATIONS.has(key)) {
      const transformedArrayOpFilter = []

      for (const elem of filter[key] as { [key: string]: unknown }[]) {
        transformedArrayOpFilter.push(convertFilter(resourceName, elem))
      }

      where[key] = transformedArrayOpFilter
    } else {
      const splitIndex = key.lastIndexOf('_')
      let field = key
      let operation
      switch (typeof filter[key]) {
        case 'string':
          operation = 'contains'
          break
        case 'object':
          operation = undefined
          break
        default:
          operation = 'equals'
          break
      }
      let isDate = false
      let isToDate = false
      if (splitIndex !== -1) {
        const _field = key.substring(0, splitIndex)
        const _op = key.substring(splitIndex + 1)
        if (OPERATIONS.has(_op)) {
          field = _field

          if (_op === 'arrayContains') {
            operation = 'array_contains'
          } else {
            operation = _op
          }
        }

        const splitDateIndex = _field.lastIndexOf('_')
        if (
          splitDateIndex !== -1 &&
          _field.substring(splitDateIndex + 1) === 'DATE'
        ) {
          isDate = true
          field = _field.substring(0, splitDateIndex)
          if (_op === 'lte') {
            isToDate = true
          }
        }
      }

      if (CUSTOM_PRIMARYKEY_RESOURCES[resourceName]) {
        field = CUSTOM_PRIMARYKEY_RESOURCES[resourceName].name
      }
      if (!where[field]) {
        where[field] = {}
      }

      let value = filter[key]
      if (isDate) {
        const dateValue = new Date(filter[key] as string)
        if (isToDate) {
          dateValue.setDate(dateValue.getDate() + 1)
        }
        value = dateValue
      }
      if (operation) {
        where[field][operation] = value
      } else {
        where[field] = value
      }
    }
  })

  return where
}

const convertSort = (
  resourceName: string,
  sortData: SortPayload,
): { [key: string]: string }[] => {
  const orderResult: { [key: string]: string }[] = []

  const fieldList = sortData.field.split(',')
  const orderList = sortData.order.split(',')
  if (fieldList.length !== orderList.length) {
    console.warn('Order data is incorrect', sortData)
    return []
  }

  for (const [index, field] of fieldList.entries()) {
    if (!CUSTOM_PRIMARYKEY_RESOURCES[resourceName] || field !== 'id') {
      orderResult.push({ [field]: orderList[index].toLowerCase() })
    }
  }

  return orderResult
}

const dataProvider: DataProvider = {
  async getList(resource, params) {
    const result = (await service[resource].list.query({
      orderBy: convertSort(resource, params.sort),
      take: params.pagination.perPage,
      skip: params.pagination.perPage * (params.pagination.page - 1),
      where: convertFilter(resource, params.filter),
    })) as any

    const data = result.data.map((datum: { [x: string]: unknown }) => {
      if (!datum.id) {
        datum['id'] = datum[CUSTOM_PRIMARYKEY_RESOURCES[resource].name]
      }
      return datum
    })

    return { total: result.total, data }
  },
  async getOne(resource, params) {
    const primaryKey = CUSTOM_PRIMARYKEY_RESOURCES[resource]?.name ?? 'id'
    const whereClause = {} as { [key: string]: string | number }
    whereClause[primaryKey] =
      primaryKey === 'id' || CUSTOM_PRIMARYKEY_RESOURCES[resource]?.isNumber
        ? Number(params.id)
        : params.id

    const result = await service[resource].findUnique.query({
      where: whereClause,
    })
    if (!result) {
      throw new Error('Not found')
    }

    if (!(result as any).id) {
      result['id'] = result[CUSTOM_PRIMARYKEY_RESOURCES[resource].name]
    }

    return {
      data: result,
    }
  },
  async getMany(resource, params) {
    const primaryKey = CUSTOM_PRIMARYKEY_RESOURCES[resource]?.name ?? 'id'
    const whereClause = {} as { [key: string]: Identifier[] }
    whereClause[primaryKey] =
      primaryKey === 'id' || CUSTOM_PRIMARYKEY_RESOURCES[resource]?.isNumber
        ? params.ids.map(Number)
        : params.ids

    const result = await service[resource].findMany.query({
      where: { id: { in: params.ids.map(Number) } },
    })
    return {
      data: (result as any).map((datum: { [x: string]: any }) => {
        if (!datum.id) {
          datum['id'] = datum[CUSTOM_PRIMARYKEY_RESOURCES[resource].name]
        }
        return datum
      }),
    }
  },
  async getManyReference(resource, params) {
    return service[resource].list.query({
      orderBy: convertSort(resource, params.sort),
      take: params.pagination.perPage,
      skip: params.pagination.perPage * (params.pagination.page - 1),
      where: {
        ...convertFilter(resource, params.filter),
        [params.target]: Number(params.id),
      },
    })
  },
  async create(resource, params) {
    const result = await service[resource].createOne.mutate({
      data: params.data,
    })
    return { data: result }
  },
  async update(resource, params) {
    const primaryKey = CUSTOM_PRIMARYKEY_RESOURCES[resource]?.name ?? 'id'
    const whereClause = {} as { [key: string]: string | number }
    whereClause[primaryKey] =
      primaryKey === 'id' || CUSTOM_PRIMARYKEY_RESOURCES[resource]?.isNumber
        ? Number(params.id)
        : params.id

    const submitData = { ...params.data }
    if (primaryKey !== 'id') {
      delete submitData.id
    }

    const result = await service[resource].updateOne.mutate({
      where: whereClause,
      data: submitData,
    })

    if (!(result as any).id) {
      result['id'] = result[CUSTOM_PRIMARYKEY_RESOURCES[resource].name]
    }

    return { data: result }
  },
  async updateMany(resource, params) {
    const result = await Promise.all(
      params.ids.map((id) =>
        service[resource].updateOne.mutate({
          where: { id: Number(id) },
          data: params.data,
        }),
      ),
    )
    return { data: result }
  },
  async delete(resource, params) {
    const result = await service[resource].deleteOne.mutate({
      where: { id: Number(params.id) },
    })
    return { data: result }
  },
  async deleteMany(resource, params) {
    const result = await Promise.all(
      params.ids.map((id) =>
        service[resource].deleteOne.mutate({ where: { id: Number(id) } }),
      ),
    )
    return { data: result }
  },
}

export default dataProvider
