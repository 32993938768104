import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCountAggregateInputType> =
  z
    .object({
      id: z.literal(true).optional(),
      createdAt: z.literal(true).optional(),
      updatedAt: z.literal(true).optional(),
      url: z.literal(true).optional(),
      procedureReviewId: z.literal(true).optional(),
      _all: z.literal(true).optional(),
    })
    .strict()

export const ProcedureReviewPictureCountAggregateInputObjectSchema = Schema
