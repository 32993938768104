import { z } from 'zod'
import { ShippingProviderCountOutputTypeSelectObjectSchema } from './ShippingProviderCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => ShippingProviderCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const ShippingProviderCountOutputTypeArgsObjectSchema = Schema
