import { setTrValue } from '@ssch-backend/utils/translation'

export function preprocessInt(arg: unknown) {
  if (typeof arg === 'string') {
    return parseInt(arg)
  }
  return arg
}

export function preprocessDate(arg: unknown) {
  if (typeof arg === 'string') {
    return new Date(arg)
  }
  return arg
}

export function preprocessFieldTr(arg: unknown) {
  if (!arg || typeof arg !== 'object') {
    return arg
  }

  const obj = arg as { [key: string]: unknown }
  for (const index in obj) {
    if (index.endsWith('Tr')) {
      obj[index] = setTrValue(obj[index] as { th: string; en: string } | null)
    }
  }
  return obj
}

// transformArrayInt is a function that transforms a string to an array of numbers
// it must be used after zod string validation because trpc open-api does not support array
export function transformArrayInt(arg: unknown) {
  if (typeof arg === 'string') {
    return arg.split(',').flatMap((x) => {
      const value = parseInt(x)
      if (isNaN(value)) {
        return []
      }
      return [value]
    })
  }
  return []
}

export function transformArray(arg: unknown) {
  if (typeof arg === 'string') {
    return arg.split(',').flatMap((x) => {
      const value = x.trim()
      return [value]
    })
  }
  return []
}
