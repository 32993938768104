import { z } from 'zod'
import { CasePictureCreateWithoutCaseInputObjectSchema } from './CasePictureCreateWithoutCaseInput.schema'
import { CasePictureUncheckedCreateWithoutCaseInputObjectSchema } from './CasePictureUncheckedCreateWithoutCaseInput.schema'
import { CasePictureCreateOrConnectWithoutCaseInputObjectSchema } from './CasePictureCreateOrConnectWithoutCaseInput.schema'
import { CasePictureCreateManyCaseInputEnvelopeObjectSchema } from './CasePictureCreateManyCaseInputEnvelope.schema'
import { CasePictureWhereUniqueInputObjectSchema } from './CasePictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureUncheckedCreateNestedManyWithoutCaseInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema),
          z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema).array(),
          z.lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema),
          z
            .lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => CasePictureCreateOrConnectWithoutCaseInputObjectSchema),
          z
            .lazy(() => CasePictureCreateOrConnectWithoutCaseInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CasePictureCreateManyCaseInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
          z.lazy(() => CasePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CasePictureUncheckedCreateNestedManyWithoutCaseInputObjectSchema =
  Schema
