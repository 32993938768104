import { z } from 'zod'
import { OrderNotificationOrderByWithRelationInputObjectSchema } from './objects/OrderNotificationOrderByWithRelationInput.schema'
import { OrderNotificationWhereInputObjectSchema } from './objects/OrderNotificationWhereInput.schema'
import { OrderNotificationWhereUniqueInputObjectSchema } from './objects/OrderNotificationWhereUniqueInput.schema'
import { OrderNotificationCountAggregateInputObjectSchema } from './objects/OrderNotificationCountAggregateInput.schema'
import { OrderNotificationMinAggregateInputObjectSchema } from './objects/OrderNotificationMinAggregateInput.schema'
import { OrderNotificationMaxAggregateInputObjectSchema } from './objects/OrderNotificationMaxAggregateInput.schema'
import { OrderNotificationAvgAggregateInputObjectSchema } from './objects/OrderNotificationAvgAggregateInput.schema'
import { OrderNotificationSumAggregateInputObjectSchema } from './objects/OrderNotificationSumAggregateInput.schema'

export const OrderNotificationAggregateSchema = z.object({
  orderBy: z
    .union([
      OrderNotificationOrderByWithRelationInputObjectSchema,
      OrderNotificationOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OrderNotificationWhereInputObjectSchema.optional(),
  cursor: OrderNotificationWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), OrderNotificationCountAggregateInputObjectSchema])
    .optional(),
  _min: OrderNotificationMinAggregateInputObjectSchema.optional(),
  _max: OrderNotificationMaxAggregateInputObjectSchema.optional(),
  _avg: OrderNotificationAvgAggregateInputObjectSchema.optional(),
  _sum: OrderNotificationSumAggregateInputObjectSchema.optional(),
})
