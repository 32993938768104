import { Box, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { BooleanInput, ReferenceInput, required } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteInput,
  FormTextInput,
} from '../../../components/FormInput/input'

const handleFilter = (searchText: string) => {
  if (searchText && searchText.trim().length >= 3) {
    return {
      searchField_contains: searchText.toLowerCase().trim(),
    }
  }
  return { id: 0 }
}

export const BranchInfo = () => {
  const [searchParams] = useSearchParams()
  const { setValue } = useFormContext()
  useEffect(() => {
    const partnerId = Number(searchParams.get('partnerId'))
    if (!isNaN(partnerId)) {
      setValue('partnerId', partnerId)
    }
  }, [searchParams, setValue])

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูล Branch
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อสาขา"
            source="nameTr.th"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อสาขา (Eng.)"
            source="nameTr.en"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferenceInput
            allowEmpty
            source="partnerId"
            reference="partner"
            enableGetChoices={({ q }) => q && q.length > 2}
            validate={required()}
          >
            <FormAutoCompleteInput
              label="Partner"
              optionText="nameTr.th"
              filterToQuery={handleFilter}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BooleanInput source="active" defaultValue={true} />
            <BooleanInput source="isExpertise" defaultValue={false} />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
