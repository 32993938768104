import { z } from 'zod'
import { PartnerArgsObjectSchema } from './PartnerArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    voucherNumber: z.boolean().optional(),
    orderId: z.boolean().optional(),
    orderNumber: z.boolean().optional(),
    procedureId: z.boolean().optional(),
    procedureItemId: z.boolean().optional(),
    procedureNameTr: z.boolean().optional(),
    patientId: z.boolean().optional(),
    patientName: z.boolean().optional(),
    patientTelNo: z.boolean().optional(),
    paymentMethod: z.boolean().optional(),
    installmentTerm: z.boolean().optional(),
    couponCode: z.boolean().optional(),
    sellingPrice: z.boolean().optional(),
    flashsalePrice: z.boolean().optional(),
    cost: z.boolean().optional(),
    priceAbsorbedBySkinX: z.boolean().optional(),
    priceAbsorbedByPartner: z.boolean().optional(),
    commission: z.boolean().optional(),
    commissionRate: z.boolean().optional(),
    vat: z.boolean().optional(),
    vatRate: z.boolean().optional(),
    netIncome: z.boolean().optional(),
    status: z.boolean().optional(),
    usedAt: z.boolean().optional(),
    expireAt: z.boolean().optional(),
    note: z.boolean().optional(),
    partner: z
      .union([z.boolean(), z.lazy(() => PartnerArgsObjectSchema)])
      .optional(),
    partnerId: z.boolean().optional(),
    v2StoreVoucherId: z.boolean().optional(),
  })
  .strict()

export const ProcedureOrderSelectObjectSchema = Schema
