import { z } from 'zod'
import { ProductCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewSummaryInput.schema'
import { ProductCreateOrConnectWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateOrConnectWithoutProductReviewSummaryInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutProductReviewSummaryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductCreateWithoutProductReviewSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductCreateOrConnectWithoutProductReviewSummaryInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProductCreateNestedOneWithoutProductReviewSummaryInputObjectSchema =
  Schema
