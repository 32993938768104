import { z } from 'zod'
import { BrandCreateWithoutBannersInputObjectSchema } from './BrandCreateWithoutBannersInput.schema'
import { BrandUncheckedCreateWithoutBannersInputObjectSchema } from './BrandUncheckedCreateWithoutBannersInput.schema'
import { BrandCreateOrConnectWithoutBannersInputObjectSchema } from './BrandCreateOrConnectWithoutBannersInput.schema'
import { BrandUpsertWithoutBannersInputObjectSchema } from './BrandUpsertWithoutBannersInput.schema'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'
import { BrandUpdateWithoutBannersInputObjectSchema } from './BrandUpdateWithoutBannersInput.schema'
import { BrandUncheckedUpdateWithoutBannersInputObjectSchema } from './BrandUncheckedUpdateWithoutBannersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpdateOneRequiredWithoutBannersNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => BrandCreateWithoutBannersInputObjectSchema),
          z.lazy(() => BrandUncheckedCreateWithoutBannersInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => BrandCreateOrConnectWithoutBannersInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => BrandUpsertWithoutBannersInputObjectSchema)
        .optional(),
      connect: z.lazy(() => BrandWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => BrandUpdateWithoutBannersInputObjectSchema),
          z.lazy(() => BrandUncheckedUpdateWithoutBannersInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const BrandUpdateOneRequiredWithoutBannersNestedInputObjectSchema =
  Schema
