export enum Language {
  EN = 'en',
  TH = 'th',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTr(tr: any, lang: Language) {
  if (lang in tr && typeof tr[lang] === 'string') {
    return tr[lang] as string
  }
  return 'Not supported language'
}

export function setTrValue(val: { th: string; en: string } | null): {
  th: string
  en: string
} {
  const tr = {
    th: '',
    en: '',
  }
  if (val?.th) {
    tr.th = val.th.toString()
  }
  if (val?.en) {
    tr.en = val.en.toString()
  }
  return tr
}
