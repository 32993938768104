import { z } from 'zod'
import { PatientSelectObjectSchema } from './PatientSelect.schema'
import { PatientIncludeObjectSchema } from './PatientInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientArgs> = z
  .object({
    select: z.lazy(() => PatientSelectObjectSchema).optional(),
    include: z.lazy(() => PatientIncludeObjectSchema).optional(),
  })
  .strict()

export const PatientArgsObjectSchema = Schema
