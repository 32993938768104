import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryUpdateWithoutProductInputObjectSchema } from './ProductCategoryUpdateWithoutProductInput.schema'
import { ProductCategoryUncheckedUpdateWithoutProductInputObjectSchema } from './ProductCategoryUncheckedUpdateWithoutProductInput.schema'
import { ProductCategoryCreateWithoutProductInputObjectSchema } from './ProductCategoryCreateWithoutProductInput.schema'
import { ProductCategoryUncheckedCreateWithoutProductInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUpsertWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductCategoryUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryUpsertWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
