import { z } from 'zod'
import { CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
