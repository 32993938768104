import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { EnumDrugTypeNullableFilterObjectSchema } from './EnumDrugTypeNullableFilter.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { EnumUnitFilterObjectSchema } from './EnumUnitFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema'
import { OrderRelationFilterObjectSchema } from './OrderRelationFilter.schema'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductItemOriginalWhereInputObjectSchema),
        z.lazy(() => ProductItemOriginalWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductItemOriginalWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductItemOriginalWhereInputObjectSchema),
        z.lazy(() => ProductItemOriginalWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    sku: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    picture: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    drugType: z
      .union([
        z.lazy(() => EnumDrugTypeNullableFilterObjectSchema),
        z.lazy(() => DrugTypeSchema),
      ])
      .optional()
      .nullable(),
    quantity: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    tagPrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    sellingUnit: z
      .union([
        z.lazy(() => EnumUnitFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional(),
    cost: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    flashSaleId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    v2DrugItemId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    canExpress: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    warehouse: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
    order: z
      .union([
        z.lazy(() => OrderRelationFilterObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductItemOriginalWhereInputObjectSchema = Schema
