import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'
import { ProductItemFindManySchema } from '../findManyProductItem.schema'
import { DeliveryLogFindManySchema } from '../findManyDeliveryLog.schema'
import { DeliveryCountOutputTypeArgsObjectSchema } from './DeliveryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliverySelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    shippingProvider: z.boolean().optional(),
    trackingNumber: z.boolean().optional(),
    status: z.boolean().optional(),
    deliveredAt: z.boolean().optional(),
    partnerName: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
    productItems: z
      .union([z.boolean(), z.lazy(() => ProductItemFindManySchema)])
      .optional(),
    deliveryLogs: z
      .union([z.boolean(), z.lazy(() => DeliveryLogFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => DeliveryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const DeliverySelectObjectSchema = Schema
