import { z } from 'zod'
import { HealthInfoCreateWithoutPatientInputObjectSchema } from './HealthInfoCreateWithoutPatientInput.schema'
import { HealthInfoUncheckedCreateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedCreateWithoutPatientInput.schema'
import { HealthInfoCreateOrConnectWithoutPatientInputObjectSchema } from './HealthInfoCreateOrConnectWithoutPatientInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoUncheckedCreateNestedOneWithoutPatientInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => HealthInfoCreateWithoutPatientInputObjectSchema),
          z.lazy(
            () => HealthInfoUncheckedCreateWithoutPatientInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => HealthInfoCreateOrConnectWithoutPatientInputObjectSchema)
        .optional(),
      connect: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const HealthInfoUncheckedCreateNestedOneWithoutPatientInputObjectSchema =
  Schema
