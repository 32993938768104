import { z } from 'zod'
import { ProductVideoWhereUniqueInputObjectSchema } from './ProductVideoWhereUniqueInput.schema'
import { ProductVideoUpdateWithoutProductInputObjectSchema } from './ProductVideoUpdateWithoutProductInput.schema'
import { ProductVideoUncheckedUpdateWithoutProductInputObjectSchema } from './ProductVideoUncheckedUpdateWithoutProductInput.schema'
import { ProductVideoCreateWithoutProductInputObjectSchema } from './ProductVideoCreateWithoutProductInput.schema'
import { ProductVideoUncheckedCreateWithoutProductInputObjectSchema } from './ProductVideoUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUpsertWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductVideoUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductVideoUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductVideoCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductVideoUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductVideoUpsertWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
