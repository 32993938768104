import { z } from 'zod'
import { SegmentSelectObjectSchema } from './SegmentSelect.schema'
import { SegmentIncludeObjectSchema } from './SegmentInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentArgs> = z
  .object({
    select: z.lazy(() => SegmentSelectObjectSchema).optional(),
    include: z.lazy(() => SegmentIncludeObjectSchema).optional(),
  })
  .strict()

export const SegmentArgsObjectSchema = Schema
