import { z } from 'zod'
import { FlashsaleItemCreateWithoutVariantInputObjectSchema } from './FlashsaleItemCreateWithoutVariantInput.schema'
import { FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutVariantInput.schema'
import { FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutVariantInput.schema'
import { FlashsaleItemCreateManyVariantInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyVariantInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUncheckedCreateNestedManyWithoutVariantInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemUncheckedCreateNestedManyWithoutVariantInputObjectSchema =
  Schema
