import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseVariantIdWarehouseIdCompoundUniqueInput> =
  z
    .object({
      variantId: z.number(),
      warehouseId: z.number(),
    })
    .strict()

export const VariantWarehouseVariantIdWarehouseIdCompoundUniqueInputObjectSchema =
  Schema
