import { z } from 'zod'
import { CasePictureUpdateInputObjectSchema } from './objects/CasePictureUpdateInput.schema'
import { CasePictureUncheckedUpdateInputObjectSchema } from './objects/CasePictureUncheckedUpdateInput.schema'
import { CasePictureWhereUniqueInputObjectSchema } from './objects/CasePictureWhereUniqueInput.schema'

export const CasePictureUpdateOneSchema = z.object({
  data: z.union([
    CasePictureUpdateInputObjectSchema,
    CasePictureUncheckedUpdateInputObjectSchema,
  ]),
  where: CasePictureWhereUniqueInputObjectSchema,
})
