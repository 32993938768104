import { z } from 'zod'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateWithoutProcedureInput.schema'
import { ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureCreateOrConnectWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedurePictureCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () =>
            ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema =
  Schema
