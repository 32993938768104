import { z } from 'zod'
import { VariantPictureCreateManyVariantInputObjectSchema } from './VariantPictureCreateManyVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureCreateManyVariantInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => VariantPictureCreateManyVariantInputObjectSchema),
      z.lazy(() => VariantPictureCreateManyVariantInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const VariantPictureCreateManyVariantInputEnvelopeObjectSchema = Schema
