import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInput> =
  z
    .object({
      id: z.number().optional(),
      procedureId: z.number(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      amount: z.number(),
      note: z.string().optional().nullable(),
    })
    .strict()

export const SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema =
  Schema
