import { z } from 'zod'
import { JsonValue } from '../generated/zod'

export const ProcedureBranchModel = z.object({
  id: z.number(),
  name: z.string(),
})
export type ProcedureBranchModel = z.infer<typeof ProcedureBranchModel>

export const toProcedureBranches = (branches: JsonValue) => {
  return branches as ProcedureBranchModel[]
}
