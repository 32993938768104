import { z } from 'zod'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchUpdateWithoutPartnerInputObjectSchema } from './BranchUpdateWithoutPartnerInput.schema'
import { BranchUncheckedUpdateWithoutPartnerInputObjectSchema } from './BranchUncheckedUpdateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpdateWithWhereUniqueWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => BranchWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => BranchUpdateWithoutPartnerInputObjectSchema),
        z.lazy(() => BranchUncheckedUpdateWithoutPartnerInputObjectSchema),
      ]),
    })
    .strict()

export const BranchUpdateWithWhereUniqueWithoutPartnerInputObjectSchema = Schema
