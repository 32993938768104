import { z } from 'zod'
import { CaseCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseCreateWithoutMissedCallTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutMissedCallTeleconsultInput.schema'
import { CaseCreateOrConnectWithoutMissedCallTeleconsultInputObjectSchema } from './CaseCreateOrConnectWithoutMissedCallTeleconsultInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutMissedCallTeleconsultInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutMissedCallTeleconsultInputObjectSchema),
          z.lazy(
            () =>
              CaseUncheckedCreateWithoutMissedCallTeleconsultInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseCreateOrConnectWithoutMissedCallTeleconsultInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseCreateNestedOneWithoutMissedCallTeleconsultInputObjectSchema =
  Schema
