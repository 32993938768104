import { z } from 'zod'
import { ProductItemOriginalCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedCreateWithoutOrderInput.schema'
import { ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateOrConnectWithoutOrderInput.schema'
import { ProductItemOriginalCreateManyOrderInputEnvelopeObjectSchema } from './ProductItemOriginalCreateManyOrderInputEnvelope.schema'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './ProductItemOriginalWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalCreateNestedManyWithoutOrderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemOriginalCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemOriginalCreateNestedManyWithoutOrderInputObjectSchema =
  Schema
