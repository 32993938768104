import { z } from 'zod'
import { ConditionItemTypeSchema } from '../enums/ConditionItemType.schema'
import { NestedEnumConditionItemTypeFilterObjectSchema } from './NestedEnumConditionItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumConditionItemTypeFilter> = z
  .object({
    equals: z.lazy(() => ConditionItemTypeSchema).optional(),
    in: z
      .lazy(() => ConditionItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConditionItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConditionItemTypeSchema),
        z.lazy(() => NestedEnumConditionItemTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumConditionItemTypeFilterObjectSchema = Schema
