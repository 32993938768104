import { z } from 'zod'
import { OldPatientSelectObjectSchema } from './OldPatientSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.OldPatientArgs> = z
  .object({
    select: z.lazy(() => OldPatientSelectObjectSchema).optional(),
  })
  .strict()

export const OldPatientArgsObjectSchema = Schema
