import { z } from 'zod'
import { CaseSummaryOrderByWithRelationInputObjectSchema } from './objects/CaseSummaryOrderByWithRelationInput.schema'
import { CaseSummaryWhereInputObjectSchema } from './objects/CaseSummaryWhereInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './objects/CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCountAggregateInputObjectSchema } from './objects/CaseSummaryCountAggregateInput.schema'
import { CaseSummaryMinAggregateInputObjectSchema } from './objects/CaseSummaryMinAggregateInput.schema'
import { CaseSummaryMaxAggregateInputObjectSchema } from './objects/CaseSummaryMaxAggregateInput.schema'
import { CaseSummaryAvgAggregateInputObjectSchema } from './objects/CaseSummaryAvgAggregateInput.schema'

export const CaseSummaryAggregateSchema = z.object({
  orderBy: z
    .union([
      CaseSummaryOrderByWithRelationInputObjectSchema,
      CaseSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CaseSummaryWhereInputObjectSchema.optional(),
  cursor: CaseSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CaseSummaryCountAggregateInputObjectSchema])
    .optional(),
  _min: CaseSummaryMinAggregateInputObjectSchema.optional(),
  _max: CaseSummaryMaxAggregateInputObjectSchema.optional(),
  _avg: CaseSummaryAvgAggregateInputObjectSchema.optional(),
})
