import { z } from 'zod'
import { DeliveryCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedCreateWithoutDeliveryLogsInput.schema'
import { DeliveryCreateOrConnectWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateOrConnectWithoutDeliveryLogsInput.schema'
import { DeliveryUpsertWithoutDeliveryLogsInputObjectSchema } from './DeliveryUpsertWithoutDeliveryLogsInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryUpdateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUpdateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedUpdateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedUpdateWithoutDeliveryLogsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateOneWithoutDeliveryLogsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryCreateWithoutDeliveryLogsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => DeliveryCreateOrConnectWithoutDeliveryLogsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => DeliveryUpsertWithoutDeliveryLogsInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => DeliveryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => DeliveryUpdateWithoutDeliveryLogsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedUpdateWithoutDeliveryLogsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const DeliveryUpdateOneWithoutDeliveryLogsNestedInputObjectSchema =
  Schema
