import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumUnitNullableFilterObjectSchema } from './NestedEnumUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumUnitNullableWithAggregatesFilter> = z
  .object({
    equals: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UnitSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UnitSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UnitSchema),
        z.lazy(() => NestedEnumUnitNullableWithAggregatesFilterObjectSchema),
      ])
      .optional()
      .nullable(),
    _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumUnitNullableFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumUnitNullableFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumUnitNullableWithAggregatesFilterObjectSchema = Schema
