import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    orderNumber: z.string().optional(),
  })
  .strict()

export const OrderWhereUniqueInputObjectSchema = Schema
