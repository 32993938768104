import {
  CloseRounded as CloseRoundedIcon,
  Publish as PublishIcon,
} from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from '@mui/material'
import { TRPCError } from '@trpc/server'
import { FC } from 'react'
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { FieldValues } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { CarouselProcedureImportForm } from './importForm'

interface CarouselItemProcedureCreate {
  procedureId: number
  priority: number
}
const CarouselProceductImportToolbar: FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <CardActions sx={{ display: 'flex', gap: 2 }}>
        <Button label="cancel" onClick={() => onCancel()} />
        <SaveButton label="import" icon={<PublishIcon />} alwaysEnable />
      </CardActions>
    </Toolbar>
  )
}

export const CarouselProcedureImportModal: FC<{
  open?: boolean
  disableCloseOnBackdrop?: boolean
  mode?: 'CREATE' | 'UPDATE'
  onClose?: () => void
}> = ({
  open = false,
  disableCloseOnBackdrop = false,
  mode = 'CREATE',
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const readXlsxProcedureItemsFile = (file: File) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const bstr = e.target?.result
        const workbook = XLSX.read(bstr, { type: 'binary' })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const readedFile = XLSX.utils.sheet_to_json<any>(
          workbook.Sheets[workbook.SheetNames[0]],
        )

        const result = readedFile.map((x, index) => {
          if (!x['Item ID'] || isNaN(Number(x['Item ID']))) {
            reject(
              new TRPCError({
                code: 'FORBIDDEN',
                message: 'Excel format is incorrect',
              }),
            )
            return null
          }

          return {
            procedureId: Number(x['Item ID']),
            priority: readedFile.length - index,
          }
        }) as CarouselItemProcedureCreate[]
        resolve(result)
      }
      reader.readAsBinaryString(file)
    })
  }

  const validateCarouselItemProcedures = (
    items: CarouselItemProcedureCreate[],
  ) => {
    // Check duplicated item
    const uniqueSetItems = new Set(items.map((deal) => deal.procedureId))
    const duplicatedItems = Array.from(
      new Set(
        items.filter((item) => {
          if (uniqueSetItems.has(item.procedureId)) {
            uniqueSetItems.delete(item.procedureId)
            return false
          } else {
            return item.procedureId + ' '
          }
        }),
      ),
    )

    if (duplicatedItems.length > 0) {
      throw new TRPCError({
        code: 'FORBIDDEN',
        message: `Procedure id: ${duplicatedItems.map(
          (item) => item.procedureId,
        )} is/are shown more than once`,
      })
    }
  }

  const handleSubmitForm = async (carousel: FieldValues) => {
    try {
      const procedures = (await readXlsxProcedureItemsFile(
        carousel.items.rawFile,
      )) as CarouselItemProcedureCreate[]
      validateCarouselItemProcedures(procedures)
      const payload = {
        nameTr: carousel.nameTr,
        startAt: new Date(carousel.startAt),
        endAt: new Date(carousel.endAt),
        items: procedures,
      }
      if (mode === 'UPDATE') {
        await adminGatewayClient.carouselProcedure.updateItemsByXlsx.mutate({
          where: { id: carousel.id },
          data: payload.items,
          replace: carousel.replace,
        })
        notify(
          `Carousel: ${payload.nameTr.th} and ${payload.items.length} items were updated`,
          { type: 'success' },
        )
      } else {
        await adminGatewayClient.carouselProcedure.createOneByXlsx.mutate({
          data: payload,
        })
        notify(
          `Carousel: ${payload.nameTr.th} and ${payload.items.length} items were created`,
          { type: 'success' },
        )
      }
      handleClose()
      refresh()
    } catch (e) {
      if (isTRPCClientError(e) || e instanceof TRPCError) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => !disableCloseOnBackdrop && handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title={translate(
            'resources.carouselItemProcedure.title.import_modal',
          )}
          subheader={translate('resources.procedure.title.procedure')}
        />
        <SimpleForm
          toolbar={
            <CarouselProceductImportToolbar onCancel={() => handleClose()} />
          }
          onSubmit={handleSubmitForm}
        >
          <CardContent sx={{ p: 0 }}>
            <CarouselProcedureImportForm mode={mode} />
          </CardContent>
        </SimpleForm>
      </Card>
    </Modal>
  )
}
