import { z } from 'zod'
import { CaseCreateWithoutAppointmentInputObjectSchema } from './CaseCreateWithoutAppointmentInput.schema'
import { CaseUncheckedCreateWithoutAppointmentInputObjectSchema } from './CaseUncheckedCreateWithoutAppointmentInput.schema'
import { CaseCreateOrConnectWithoutAppointmentInputObjectSchema } from './CaseCreateOrConnectWithoutAppointmentInput.schema'
import { CaseUpsertWithoutAppointmentInputObjectSchema } from './CaseUpsertWithoutAppointmentInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseUpdateWithoutAppointmentInputObjectSchema } from './CaseUpdateWithoutAppointmentInput.schema'
import { CaseUncheckedUpdateWithoutAppointmentInputObjectSchema } from './CaseUncheckedUpdateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutAppointmentNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutAppointmentInputObjectSchema),
          z.lazy(() => CaseUncheckedCreateWithoutAppointmentInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseCreateOrConnectWithoutAppointmentInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CaseUpsertWithoutAppointmentInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CaseUpdateWithoutAppointmentInputObjectSchema),
          z.lazy(() => CaseUncheckedUpdateWithoutAppointmentInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const CaseUpdateOneRequiredWithoutAppointmentNestedInputObjectSchema =
  Schema
