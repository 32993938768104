import type { AdminGatewayRouter } from '@ssch-backend/gateways/admin-gateway'
import {
  createTRPCProxyClient,
  httpBatchLink,
  TRPCClientError,
} from '@trpc/client'
import superjson from 'superjson'
import { firebaseAuth, firebaseAuthInitialized } from './firebase'

interface Service {
  [resource: string]:
    | {
        list: {
          query: (params?: unknown) => Promise<never>
        }
        findUnique: {
          query: (params?: unknown) => Promise<never>
        }
        findMany: {
          query: (params?: unknown) => Promise<never>
        }
        createOne: {
          mutate: (params?: unknown) => Promise<never>
        }
        updateOne: {
          mutate: (params?: unknown) => Promise<never>
        }
        deleteOne: {
          mutate: (params?: unknown) => Promise<never>
        }
      } & {
        mutate: (params?: unknown) => Promise<never>
        query: (params?: unknown) => Promise<never>
      }
}

console.log('import.meta.env.NX_API_URL', import.meta.env.NX_API_URL)
console.log('process.env.NX_API_URL', process.env.NX_API_URL)

export const adminGatewayClient = createTRPCProxyClient<AdminGatewayRouter>({
  links: [
    httpBatchLink({
      url: import.meta.env.NX_API_URL,
      async headers() {
        await firebaseAuthInitialized
        if (firebaseAuth.currentUser) {
          const token = await firebaseAuth.currentUser.getIdToken()
          return {
            Authorization: `Bearer ${token}`,
          }
        }
        return {}
      },
    }),
  ],
  transformer: superjson,
})

export function isTRPCClientError(
  cause: unknown,
): cause is TRPCClientError<AdminGatewayRouter> {
  return cause instanceof TRPCClientError
}

export const service: Service = adminGatewayClient as never
