import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import { Link, styled } from '@mui/material'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { FileValue } from './types'

export interface FileStorageInputPreviewProps {
  file: FileValue
  fileType?: 'IMAGE' | 'VIDEO' | 'FILE'
  onClick?: () => void
}

export const FileStoragePreview: FC<FileStorageInputPreviewProps> = ({
  file,
  fileType = 'FILE',
  onClick,
}) => {
  const title = file.title || new URL(file.src).pathname.split('/').pop()
  const [thumbnail, setThumbnail] = useState('')

  useEffect(() => {
    if (fileType === 'VIDEO') {
      if (file.rawFile) {
        const rawFile = file.rawFile
        const getBlobThumbnailUrl = async () => {
          const blobThumbnail = await getVideoThumbnail(rawFile)

          setThumbnail(URL.createObjectURL(blobThumbnail))
        }
        getBlobThumbnailUrl()
      } else {
        if (file.thumbnailSrc) {
          setThumbnail(file.thumbnailSrc)
        }
      }
    } else {
      setThumbnail(file.src)
    }
  }, [file.rawFile, file.src, file.thumbnailSrc, fileType])

  return (
    <Root>
      {fileType !== 'FILE' ? (
        <StyledImage
          title={title}
          alt={title}
          src={thumbnail}
          className={clsx(
            ImageFieldClasses.image,
            onClick && ImageFieldClasses.clickable,
          )}
          onClick={onClick}
        />
      ) : (
        <StyledIcon>
          <InsertDriveFile
            sx={{ fontSize: 72, color: (theme) => theme.palette.grey[400] }}
          />
        </StyledIcon>
      )}

      <Link
        href={file.src}
        variant="caption"
        noWrap
        align="center"
        sx={{ width: 200, px: 1 }}
        download
        target="_blank"
      >
        {title}
      </Link>
    </Root>
  )
}

const StyledIcon = styled('div')(({ theme }) => ({
  margin: '0.25rem',
  width: 200,
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [`& .${ImageFieldClasses.clickable}`]: {
    cursor: 'pointer',
  },
}))

const PREFIX = 'RaFileStorageField'

const ImageFieldClasses = {
  image: `${PREFIX}-image`,
  clickable: `${PREFIX}-clickable`,
}

const StyledImage = styled('img')(({ theme }) => ({
  margin: '0.25rem',
  width: 200,
  height: 100,
  objectFit: 'contain',
}))

function getVideoThumbnail(file: File, seekTo = 0.0) {
  return new Promise<Blob>((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video')
    videoPlayer.setAttribute('src', URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener('error', (_ex) => {
      reject('error when loading video file')
    })

    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.')
        return
      }

      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo
      }, 200)

      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo)

        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas')
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight

        // draw the video frame to canvas
        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)

          // return the canvas image as a blob
          ctx.canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob)
            } else {
              reject('Blob is null')
            }
          })
        }
      })
    })
  })
}
