import { z } from 'zod'
import { ProcedurePictureCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateWithoutProcedureInput.schema'
import { ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedurePictureUncheckedCreateWithoutProcedureInput.schema'
import { ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateOrConnectWithoutProcedureInput.schema'
import { ProcedurePictureUpsertWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedurePictureUpsertWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedurePictureCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedurePictureCreateManyProcedureInputEnvelope.schema'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedurePictureUpdateManyWithWhereWithoutProcedureInputObjectSchema } from './ProcedurePictureUpdateManyWithWhereWithoutProcedureInput.schema'
import { ProcedurePictureScalarWhereInputObjectSchema } from './ProcedurePictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureUncheckedUpdateManyWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedurePictureCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => ProcedurePictureCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedurePictureUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProcedurePictureCreateManyProcedureInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedurePictureUpdateManyWithWhereWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedurePictureUpdateManyWithWhereWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedurePictureScalarWhereInputObjectSchema),
          z.lazy(() => ProcedurePictureScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedurePictureUncheckedUpdateManyWithoutProcedureNestedInputObjectSchema =
  Schema
