import { z } from 'zod'
import { ConditionItemTypeSchema } from '../enums/ConditionItemType.schema'
import { NestedEnumConditionItemTypeWithAggregatesFilterObjectSchema } from './NestedEnumConditionItemTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumConditionItemTypeFilterObjectSchema } from './NestedEnumConditionItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumConditionItemTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ConditionItemTypeSchema).optional(),
    in: z
      .lazy(() => ConditionItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConditionItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConditionItemTypeSchema),
        z.lazy(
          () => NestedEnumConditionItemTypeWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z
      .lazy(() => NestedEnumConditionItemTypeFilterObjectSchema)
      .optional(),
    _max: z
      .lazy(() => NestedEnumConditionItemTypeFilterObjectSchema)
      .optional(),
  })
  .strict()

export const EnumConditionItemTypeWithAggregatesFilterObjectSchema = Schema
