import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ProductInfoSection } from './create-edit-section/productInfo'
import { ProductPictureSecion } from './create-edit-section/productPicture'
import { ProductPropertySection } from './create-edit-section/productProperty'
import { ProductUsageSecion } from './create-edit-section/productUsage'
import { ProductVideoSecion } from './create-edit-section/productVideo'
import { SkuSection } from './create-edit-section/sku'
import { transformProduct } from './transform'

const ProductEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="product" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProductEditForm = () => {
  return (
    <SimpleForm toolbar={<ProductEditToolbar />}>
      <ProductInfoSection />
      <ProductPropertySection />
      <ProductUsageSecion />
      <ProductPictureSecion />
      <ProductVideoSecion />
    </SimpleForm>
  )
}

export const ProductEdit = () => (
  <EditSaveRouteBack
    transform={transformProduct}
    component="div"
    redirect="list"
    mutationMode="pessimistic"
  >
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <ProductEditForm />
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ p: 2 }}>
          <Box sx={{ p: 2 }}>
            <SkuSection />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </EditSaveRouteBack>
)
