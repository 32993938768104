import { z } from 'zod'
import { KycDataOrderByWithRelationInputObjectSchema } from './objects/KycDataOrderByWithRelationInput.schema'
import { KycDataWhereInputObjectSchema } from './objects/KycDataWhereInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './objects/KycDataWhereUniqueInput.schema'
import { KycDataCountAggregateInputObjectSchema } from './objects/KycDataCountAggregateInput.schema'
import { KycDataMinAggregateInputObjectSchema } from './objects/KycDataMinAggregateInput.schema'
import { KycDataMaxAggregateInputObjectSchema } from './objects/KycDataMaxAggregateInput.schema'
import { KycDataAvgAggregateInputObjectSchema } from './objects/KycDataAvgAggregateInput.schema'
import { KycDataSumAggregateInputObjectSchema } from './objects/KycDataSumAggregateInput.schema'

export const KycDataAggregateSchema = z.object({
  orderBy: z
    .union([
      KycDataOrderByWithRelationInputObjectSchema,
      KycDataOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: KycDataWhereInputObjectSchema.optional(),
  cursor: KycDataWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), KycDataCountAggregateInputObjectSchema])
    .optional(),
  _min: KycDataMinAggregateInputObjectSchema.optional(),
  _max: KycDataMaxAggregateInputObjectSchema.optional(),
  _avg: KycDataAvgAggregateInputObjectSchema.optional(),
  _sum: KycDataSumAggregateInputObjectSchema.optional(),
})
