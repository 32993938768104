import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { ProductReviewRelationFilterObjectSchema } from './ProductReviewRelationFilter.schema'
import { ProductReviewWhereInputObjectSchema } from './ProductReviewWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductReviewPictureWhereInputObjectSchema),
        z.lazy(() => ProductReviewPictureWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductReviewPictureWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductReviewPictureWhereInputObjectSchema),
        z.lazy(() => ProductReviewPictureWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    url: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    productReviewId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    productReview: z
      .union([
        z.lazy(() => ProductReviewRelationFilterObjectSchema),
        z.lazy(() => ProductReviewWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductReviewPictureWhereInputObjectSchema = Schema
