import { z } from 'zod'
import { ExpressShippingAreaWhereInputObjectSchema } from './objects/ExpressShippingAreaWhereInput.schema'
import { ExpressShippingAreaOrderByWithAggregationInputObjectSchema } from './objects/ExpressShippingAreaOrderByWithAggregationInput.schema'
import { ExpressShippingAreaScalarWhereWithAggregatesInputObjectSchema } from './objects/ExpressShippingAreaScalarWhereWithAggregatesInput.schema'
import { ExpressShippingAreaScalarFieldEnumSchema } from './enums/ExpressShippingAreaScalarFieldEnum.schema'

export const ExpressShippingAreaGroupBySchema = z.object({
  where: ExpressShippingAreaWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ExpressShippingAreaOrderByWithAggregationInputObjectSchema,
    ExpressShippingAreaOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ExpressShippingAreaScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ExpressShippingAreaScalarFieldEnumSchema),
})
