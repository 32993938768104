import { z } from 'zod'
import { SuggestedProductItemOriginalScalarWhereInputObjectSchema } from './SuggestedProductItemOriginalScalarWhereInput.schema'
import { SuggestedProductItemOriginalUpdateManyMutationInputObjectSchema } from './SuggestedProductItemOriginalUpdateManyMutationInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateManyWithoutSuggestedProductItemsOriginalInputObjectSchema } from './SuggestedProductItemOriginalUncheckedUpdateManyWithoutSuggestedProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(
        () => SuggestedProductItemOriginalScalarWhereInputObjectSchema,
      ),
      data: z.union([
        z.lazy(
          () => SuggestedProductItemOriginalUpdateManyMutationInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProductItemOriginalUncheckedUpdateManyWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema =
  Schema
