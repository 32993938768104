import { z } from 'zod'
import { ProcedureReviewPictureOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewPictureOrderByWithRelationInput.schema'
import { ProcedureReviewPictureWhereInputObjectSchema } from './objects/ProcedureReviewPictureWhereInput.schema'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './objects/ProcedureReviewPictureWhereUniqueInput.schema'
import { ProcedureReviewPictureScalarFieldEnumSchema } from './enums/ProcedureReviewPictureScalarFieldEnum.schema'

export const ProcedureReviewPictureFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewPictureOrderByWithRelationInputObjectSchema,
      ProcedureReviewPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewPictureWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureReviewPictureScalarFieldEnumSchema).optional(),
})
