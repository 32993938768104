import { FC } from 'react'
import { Edit, EditProps, useNotify } from 'react-admin'
import { useNavigate } from 'react-router-dom'

type EditSaveRouteBackProps = EditProps & {
  children: React.ReactNode
}
export const EditSaveRouteBack: FC<EditSaveRouteBackProps> = ({
  children,
  ...restProps
}) => {
  const notify = useNotify()
  const navigate = useNavigate()

  const onSuccess = () => {
    notify('ra.notification.updated', {
      messageArgs: { smart_count: 1 },
    })

    if (window.history.length > 1) {
      navigate(-1)
    } else {
      window.close()
    }
  }
  return (
    <Edit
      {...restProps}
      mutationMode="pessimistic"
      mutationOptions={{ ...restProps.mutationOptions, onSuccess }}
    >
      {children}
    </Edit>
  )
}
