import { Typography } from '@mui/material'
import { FC } from 'react'
import { OptionTextFunc, useGetList } from 'react-admin'
import { FormAutoCompleteArrayInput } from '../../../components/FormInput/input'
import { useI18nFormatter } from '../../../utils'

export const CategorySection: FC = () => {
  const i18nFormatter = useI18nFormatter()
  const { data: dataCategory, isLoading: loadingCategory } = useGetList(
    'doctorCategory',
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'priority', order: 'DESC' },
      filter: { active: true },
    },
  )

  const optionTextCategory: OptionTextFunc = (choice) => {
    return i18nFormatter(choice.nameTr)
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Category
      </Typography>
      <FormAutoCompleteArrayInput
        fullWidth
        filterSelectedOptions={true}
        source="doctorCategories"
        loading={loadingCategory}
        choices={dataCategory}
        optionText={optionTextCategory}
        label="หมวดหมู่แพทย์"
        parse={(value) => value && value.map((v: number) => ({ id: v }))}
        format={(value) => value && value.map((v: { id: number }) => v.id)}
      />
    </>
  )
}
