import { z } from 'zod'
import { UserFindManySchema } from '../findManyUser.schema'
import { BranchFindManySchema } from '../findManyBranch.schema'
import { CoverPictureFindManySchema } from '../findManyCoverPicture.schema'
import { SkuFindManySchema } from '../findManySku.schema'
import { ProcedureOrderFindManySchema } from '../findManyProcedureOrder.schema'
import { PartnerCountOutputTypeArgsObjectSchema } from './PartnerCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    type: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    logo: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    website: z.boolean().optional(),
    phone: z.boolean().optional(),
    active: z.boolean().optional(),
    isExpertise: z.boolean().optional(),
    priority: z.boolean().optional(),
    isSXEasy: z.boolean().optional(),
    users: z.union([z.boolean(), z.lazy(() => UserFindManySchema)]).optional(),
    branches: z
      .union([z.boolean(), z.lazy(() => BranchFindManySchema)])
      .optional(),
    coverPictures: z
      .union([z.boolean(), z.lazy(() => CoverPictureFindManySchema)])
      .optional(),
    searchField: z.boolean().optional(),
    sku: z.union([z.boolean(), z.lazy(() => SkuFindManySchema)]).optional(),
    ProcedureOrder: z
      .union([z.boolean(), z.lazy(() => ProcedureOrderFindManySchema)])
      .optional(),
    taxId: z.boolean().optional(),
    v2HospitalId: z.boolean().optional(),
    v2StoreId: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => PartnerCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const PartnerSelectObjectSchema = Schema
