import { z } from 'zod'
import { AppointmentUpdateWithoutCaseInputObjectSchema } from './AppointmentUpdateWithoutCaseInput.schema'
import { AppointmentUncheckedUpdateWithoutCaseInputObjectSchema } from './AppointmentUncheckedUpdateWithoutCaseInput.schema'
import { AppointmentCreateWithoutCaseInputObjectSchema } from './AppointmentCreateWithoutCaseInput.schema'
import { AppointmentUncheckedCreateWithoutCaseInputObjectSchema } from './AppointmentUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpsertWithoutCaseInput> = z
  .object({
    update: z.union([
      z.lazy(() => AppointmentUpdateWithoutCaseInputObjectSchema),
      z.lazy(() => AppointmentUncheckedUpdateWithoutCaseInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => AppointmentCreateWithoutCaseInputObjectSchema),
      z.lazy(() => AppointmentUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const AppointmentUpsertWithoutCaseInputObjectSchema = Schema
