import { z } from 'zod'
import { ProductVideoScalarWhereInputObjectSchema } from './ProductVideoScalarWhereInput.schema'
import { ProductVideoUpdateManyMutationInputObjectSchema } from './ProductVideoUpdateManyMutationInput.schema'
import { ProductVideoUncheckedUpdateManyWithoutVideoInputObjectSchema } from './ProductVideoUncheckedUpdateManyWithoutVideoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUpdateManyWithWhereWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductVideoScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductVideoUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => ProductVideoUncheckedUpdateManyWithoutVideoInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductVideoUpdateManyWithWhereWithoutProductInputObjectSchema =
  Schema
