import { z } from 'zod'
import { ProcedureUpdateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUpdateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutProcedureReviewSummaryInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => ProcedureUpdateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureUpsertWithoutProcedureReviewSummaryInputObjectSchema =
  Schema
