import { z } from 'zod'
import { CouponModelResponse } from '../orders'

export const CouponValidateItem = CouponModelResponse.pick({
  code: true,
  type: true,
  descriptionTr: true,
  discount: true,
  coin: true,
})
export type CouponValidateItemType = z.infer<typeof CouponValidateItem>

export const CouponValidateResponse = z.object({
  coinCashback: z
    .object({
      error: z.string().nullable().default(null),
      data: CouponValidateItem.nullable(),
    })
    .nullable(),
  general: z
    .object({
      error: z.string().nullable().default(null),
      data: CouponValidateItem.nullable(),
    })
    .nullable(),
  shipping: z
    .object({
      error: z.string().nullable().default(null),
      data: CouponValidateItem.nullable(),
    })
    .nullable(),
  canUseCoin: z
    .object({
      error: z.string().nullable().default(null),
      amount: z.number().default(0),
    })
    .nullable(),
})
export type CouponValidateResponseType = z.infer<typeof CouponValidateResponse>
