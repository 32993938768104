import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    articleId: z.literal(true).optional(),
    url: z.literal(true).optional(),
    rank: z.literal(true).optional(),
  })
  .strict()

export const ArticlePictureMaxAggregateInputObjectSchema = Schema
