import { z } from 'zod'
import { NotificationCreateWithoutSegmentsInputObjectSchema } from './NotificationCreateWithoutSegmentsInput.schema'
import { NotificationUncheckedCreateWithoutSegmentsInputObjectSchema } from './NotificationUncheckedCreateWithoutSegmentsInput.schema'
import { NotificationCreateOrConnectWithoutSegmentsInputObjectSchema } from './NotificationCreateOrConnectWithoutSegmentsInput.schema'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCreateNestedManyWithoutSegmentsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => NotificationCreateWithoutSegmentsInputObjectSchema),
          z
            .lazy(() => NotificationCreateWithoutSegmentsInputObjectSchema)
            .array(),
          z.lazy(
            () => NotificationUncheckedCreateWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationUncheckedCreateWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => NotificationCreateOrConnectWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationCreateOrConnectWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationCreateNestedManyWithoutSegmentsInputObjectSchema =
  Schema
