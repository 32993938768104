import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCountOutputTypeSelect> = z
  .object({
    productItems: z.boolean().optional(),
    deliveryLogs: z.boolean().optional(),
  })
  .strict()

export const DeliveryCountOutputTypeSelectObjectSchema = Schema
