import { z } from 'zod'
import { ProductReviewOrderByWithRelationInputObjectSchema } from './objects/ProductReviewOrderByWithRelationInput.schema'
import { ProductReviewWhereInputObjectSchema } from './objects/ProductReviewWhereInput.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './objects/ProductReviewWhereUniqueInput.schema'
import { ProductReviewScalarFieldEnumSchema } from './enums/ProductReviewScalarFieldEnum.schema'

export const ProductReviewFindManySchema = z.object({
  orderBy: z
    .union([
      ProductReviewOrderByWithRelationInputObjectSchema,
      ProductReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewWhereInputObjectSchema.optional(),
  cursor: ProductReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductReviewScalarFieldEnumSchema).optional(),
})
