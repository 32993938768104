import { z } from 'zod'
import { ProductCategoryCreateWithoutCategoryInputObjectSchema } from './ProductCategoryCreateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutCategoryInput.schema'
import { ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema } from './ProductCategoryCreateOrConnectWithoutCategoryInput.schema'
import { ProductCategoryCreateManyCategoryInputEnvelopeObjectSchema } from './ProductCategoryCreateManyCategoryInputEnvelope.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateNestedManyWithoutCategoryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema),
          z
            .lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryCreateOrConnectWithoutCategoryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCategoryCreateManyCategoryInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCategoryCreateNestedManyWithoutCategoryInputObjectSchema =
  Schema
