import { z } from 'zod'
import { ProductCategoryCreateManyCategoryInputObjectSchema } from './ProductCategoryCreateManyCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateManyCategoryInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => ProductCategoryCreateManyCategoryInputObjectSchema),
        z
          .lazy(() => ProductCategoryCreateManyCategoryInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProductCategoryCreateManyCategoryInputEnvelopeObjectSchema = Schema
