import { z } from 'zod'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaUpdateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUpdateWithoutShippingProviderInput.schema'
import { ExpressShippingAreaUncheckedUpdateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUncheckedUpdateWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(
          () =>
            ExpressShippingAreaUpdateWithoutShippingProviderInputObjectSchema,
        ),
        z.lazy(
          () =>
            ExpressShippingAreaUncheckedUpdateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema =
  Schema
