import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ArticleOrderByWithRelationInputObjectSchema } from './ArticleOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    articleId: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    article: z
      .lazy(() => ArticleOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ArticlePictureOrderByWithRelationInputObjectSchema = Schema
