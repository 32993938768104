import { z } from 'zod'
import { OrderCreateWithoutProcedureItemsInputObjectSchema } from './OrderCreateWithoutProcedureItemsInput.schema'
import { OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProcedureItemsInput.schema'
import { OrderCreateOrConnectWithoutProcedureItemsInputObjectSchema } from './OrderCreateOrConnectWithoutProcedureItemsInput.schema'
import { OrderUpsertWithoutProcedureItemsInputObjectSchema } from './OrderUpsertWithoutProcedureItemsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutProcedureItemsInputObjectSchema } from './OrderUpdateWithoutProcedureItemsInput.schema'
import { OrderUncheckedUpdateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedUpdateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutProcedureItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutProcedureItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutProcedureItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => OrderUncheckedUpdateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutProcedureItemsNestedInputObjectSchema =
  Schema
