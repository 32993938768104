import polyglotI18nProvider from 'ra-i18n-polyglot'
import { TranslationMessages } from 'react-admin'
import { i18nEn } from './i18n/en'
import { i18nTh } from './i18n/th'

const translations: { [key: string]: TranslationMessages } = {
  en: i18nEn,
  th: i18nTh,
}

const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'th', // default locale
  [
    { locale: 'en', name: 'English' },
    { locale: 'th', name: 'ไทย' },
  ],
  { allowMissing: true },
)

export default i18nProvider
