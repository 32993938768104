import { PartnerTypeSchema } from '@ssch-backend/partners'
import {
  NotificationActionTypeSchema,
  NotificationTypeSchema,
} from '@ssch-backend/patients'
import {
  AuxiliaryLabelSchema,
  DrugTypeSchema,
  ProductAttribute,
  ProductConcern,
  ProductForm,
  ProductTypeSchema,
  SalesTypeSchema,
  SkinType,
  UnitSchema,
  UsageSchema,
  UseFrequencySchema,
  UseTimeSchema,
} from '@ssch-backend/products'
import {
  AppointmentStatusSchema,
  AppointmentTypeSchema,
  ConsultingChannelSchema,
  SymptomDurationUnitSchema,
} from '@ssch-backend/teleconsults'

// Product Enum -----------------------------------------------------------------------------------

export const getProductTypeLabel = (
  productTypeEnum: keyof typeof ProductTypeSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productTypeEnum) {
    case 'DRUG':
      value.en = 'Drug'
      value.th = 'ยา'
      break
    case 'TOOLS':
      value.en = 'Tools'
      value.th = 'อุปกรณ์ เครื่องมือ วัสดุต่างๆ'
      break
    case 'SUPPLEMENTS':
      value.en = 'Supplement'
      value.th = 'อาหารเสริม'
      break
    case 'SKINCARE':
      value.en = 'Skincare'
      value.th = 'ผลิตภัณฑ์ดูแลผิว'
      break
    default:
      break
  }

  return value
}

export const getProductSalesTypeLabel = (
  productSalesTypeEnum: keyof typeof SalesTypeSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productSalesTypeEnum) {
    case 'MERCHANDISE':
      value.en = 'Merchandise'
      value.th = 'สินค้าขาย'
      break
    case 'FREEBIES':
      value.en = 'Freebies'
      value.th = 'ของแถม'
      break
    default:
      break
  }

  return value
}

export const getProductDrugTypeLabel = (
  productDrugTypeEnum: keyof typeof DrugTypeSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productDrugTypeEnum) {
    case 'HOUSEHOLD':
      value.en = 'Household'
      value.th = 'ยาสามัญประจำบ้าน'
      break
    case 'DANGEROUS':
      value.en = 'Dangerous drugs'
      value.th = 'ยาอันตราย'
      break
    case 'SPECIAL_CONTROLLED':
      value.en = 'Controlled drugs'
      value.th = 'ยาควบคุม'
      break
    default:
      break
  }

  return value
}

export const getProductSkinTypeLabel = (
  productSkinTypeEnum: keyof typeof SkinType,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productSkinTypeEnum) {
    case 'ALL':
      value.en = 'All'
      value.th = 'ทุกสภาพผิว'
      break
    case 'COMBINATION':
      value.en = 'Combination'
      value.th = 'ผิวผสม'
      break
    case 'SENSITIVE':
      value.en = 'Sensitive'
      value.th = 'ผิวแพ้ง่าย'
      break
    case 'OILY':
      value.en = 'Oily'
      value.th = 'ผิวมัน'
      break
    case 'NORMAL':
      value.en = 'Normal'
      value.th = 'ผิวธรรมดา'
      break
    case 'DRY':
      value.en = 'Dry'
      value.th = 'ผิวแห้ง'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    default:
      break
  }

  return value
}

export const getProductAuxiliaryLabelLabel = (
  productAuxiliaryLabelEnum: keyof typeof AuxiliaryLabelSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productAuxiliaryLabelEnum) {
    case 'FOR_EXTERNAL_USE_ONLY':
      value.en = 'For external use only'
      value.th = 'ยาใช้ภายนอก ห้ามรับประทาน'
      break
    case 'CONTINUE_THIS_MEDICATION_UNTIL_FINISHED':
      value.en = 'Continue this medication until finished'
      value.th = 'ใช้หรือรับประทานยานี้ติดต่อกันจนหมด'
      break
    case 'CONSULT_DOCTOR_OR_PHARMACIST_IF_PREGNANT_OR_BREASTFEEDING':
      value.en =
        'Consult the doctor or pharmacist if you are pregnant or breastfeeding'
      value.th = 'สตรีมีครรภ์และให้นมบุตร โปรดปรึกษาแพทย์หรือเภสัชกรก่อนใช้ยา'
      break
    case 'AS_NEEDED':
      value.en = 'As needed'
      value.th = 'ยาตามอาการ หากหายแล้วให้หยุดยา หรือใช้ตามแพทย์สั่ง'
      break
    case 'TAKE_AFTER_MEAL_IMMEDIATELY_DO_NOT_TAKE_ON_EMPTY_STOMACH':
      value.en = 'Take after meal immediately. Do not take on empty stomach.'
      value.th = 'รับประทานหลังอาหารทันที ห้ามรับประทานยานี้ตอนท้องว่าง'
      break
    case 'MAY_MAKE_YOU_FEEL_DROWSY':
      value.en =
        'May make you feel drowsy. Do not drive or operate heavy machinery while taking this medication.'
      value.th =
        'ยานี้อาจทำให้ง่วงนอน ห้ามดื่มเหล้าและเลี่ยงการขับขี่รถหรือควบคุมเครื่องจักร'
      break
    case 'ONCE_MIXED_CAN_ONLY_BE_KEPT_7_DAYS':
      value.en = 'Once mixed, this medication can only be kept for 7 days.'
      value.th = 'ยานี้เมื่อผสมน้ำแล้วทิ้งไว้นานเกิน 7 วัน จะเสื่อมคุณภาพ'
      break
    case 'ONCE_MIXED_KEEP_IN_REFRIGERATOR_DO_NOT_FREEZE':
      value.en = 'Once mixed, keep in refrigerator. Do not freeze.'
      value.th = 'ยานี้เมื่อผสมน้ำแล้ว ให้เก็บในตู้เย็นช่องธรรมดา'
      break
    case 'USE_THIS_MEDICATION_ACCORDING_TO_DOCTOR_ADVICE':
      value.en =
        'Use this mediacation according to what has been adviced by the doctor.'
      value.th = 'ใช้ยานี้ตามจำนวนและระยะเวลาที่แพทย์สั่งเท่านั้น'
      break
    case 'DO_NOT_TAKE_WITH_MILK_ANTACIDS_CALCIUM_IRON_ZINC_MAGNESIUM_MANGANESE':
      value.en =
        'Do not take with milk, Antacids, Calcium, Iron, Zinc, Magnesium, and Manganese.'
      value.th =
        'ห้ามรับประทานพร้อมนม ยาลดกรด และธาตุเหล็ก สังกะสี แมกนีเซียม แมงกานีส แคลเซียม'
      break
    case 'SEE_PACKAGE_INSERT_FOR_COMPLETE_INFORMATION':
      value.en = 'See package insert for complete infomation.'
      value.th = 'ดูตามคำแนะนำข้างกล่อง'
      break
    case 'TAKE_AS_INSTRUCTED':
      value.en = 'Take as instructed'
      value.th = 'รับประทานตามคำแนะนำของแพทย์'
      break
    case 'THIS_MEDICINE_MUST_BE_STORED_AT_2_TO_8_CELSIUS':
      value.en = 'This medicine must be stored at 2 to 8 celsius.'
      value.th = 'ยานี้ต้องเก็บในอุณหภูมิ 2 - 8 องศาเซลเซียส (ห้ามแช่แข็ง)'
      break
    case 'DO_NOT_DONATE_BLOOD_WHILE_TAKING_THIS_MEDICINE':
      value.en = 'Do not donate blood while taking this medicine.'
      value.th = 'ห้ามบริจาคโลหิต ระหว่างรับประทานยานี้'
      break
    case 'MUST_USE_BIRTH_CONTROL_WHILE_TAKING_THIS_MEDICINE':
      value.en = 'Must use birth control while taking this medicine.'
      value.th = 'ต้องคุมกำเนิด ระหว่างรับประทานยานี้'
      break
    default:
      break
  }

  return value
}

export const getProductFormLabel = (
  productFormLabelEnum: keyof typeof ProductForm,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productFormLabelEnum) {
    case 'MASK':
      value.en = 'Mask'
      value.th = 'มาส์ก'
      break
    case 'POWDER':
      value.en = 'Powder'
      value.th = 'พาวเดอร์ / แป้ง'
      break
    case 'TONER':
      value.en = 'Toner'
      value.th = 'โทนเนอร์'
      break
    case 'SERUM':
      value.en = 'Serum'
      value.th = 'เซรั่ม'
      break
    case 'ESSENCES':
      value.en = 'Essences'
      value.th = 'เอสเซนเอสเซนส์'
      break
    case 'LOTION':
      value.en = 'Lotion'
      value.th = 'โลชั่น'
      break
    case 'SOLUTION':
      value.en = 'Solution'
      value.th = 'โซลูชัน'
      break
    case 'EMULSION':
      value.en = 'Emulsion'
      value.th = 'อิมัลชัน / ยาน้ำนม'
      break
    case 'SUSPENSION':
      value.en = 'Suspension'
      value.th = 'ซัสเพนชัน / ยาเเขวนลอย'
      break
    case 'OINTMENT':
      value.en = 'Ointment'
      value.th = 'ออยท์เมนท์ / ขี้ผึ้ง'
      break
    case 'CREAM':
      value.en = 'Cream'
      value.th = 'ครีม'
      break
    case 'PASTE':
      value.en = 'Paste'
      value.th = 'เพสท์'
      break
    case 'GEL':
      value.en = 'Gel'
      value.th = 'เจล'
      break
    case 'GEL_CREAM':
      value.en = 'Gel-cream'
      value.th = 'เจล-ครีม'
      break
    case 'TAB_TABLET':
      value.en = 'Tab / Tablet'
      value.th = 'เม็ด'
      break
    case 'CAP_CAPSULE':
      value.en = 'Cap / Capsule'
      value.th = 'แคปซูล'
      break
    case 'SYRUP':
      value.en = 'Syrup'
      value.th = 'ซีรัป / ยาน้ำเชื่อม'
      break
    case 'SPRAY':
      value.en = 'Spray'
      value.th = 'สเปรย์ / ยาพ่น'
      break
    case 'WATER_PROOF':
      value.en = 'Water proof'
      value.th = 'กันน้ำ'
      break
    case 'LIGHT_TEXTURE':
      value.en = 'Light texture'
      value.th = 'เนื้อบางเบา'
      break
    case 'DUST':
      value.en = 'Dust'
      value.th = 'ผง'
      break
    case 'WATER':
      value.en = 'Water'
      value.th = 'น้ำ'
      break
    case 'FOAM':
      value.en = 'Foam'
      value.th = 'โฟม'
      break
    case 'WHIPPED_FOAM':
      value.en = 'Whipped Foam'
      value.th = 'วิปโฟม'
      break
    case 'OIL':
      value.en = 'Oil'
      value.th = 'ออยล์'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    case 'SHAMPOO':
      value.en = 'Shampoo'
      value.th = 'แชมพู'
      break
    case 'PATCH':
      value.en = 'Patch'
      value.th = 'แผ่นแปะ'
      break
    default:
      break
  }

  return value
}

export const getProductConcernLabel = (
  productConcernEnum: keyof typeof ProductConcern,
): { en: string; th: string } => {
  const value = { en: '', th: '' }
  switch (productConcernEnum) {
    case 'BRIGHTENING':
      value.en = 'Brightening'
      value.th = 'เพื่อผิวกระจ่างใส'
      break
    case 'MOISTURIZING':
      value.en = 'Moisturizing'
      value.th = 'เพิ่มความชุ่มชื้น'
      break
    case 'ANTI_AGING':
      value.en = 'Anti-Aging'
      value.th = 'ผิวอ่อนวัย'
      break
    case 'SKIN_CLEANSING':
      value.en = 'Skinx Cleansing'
      value.th = 'ทำความสะอาดผิว'
      break
    case 'SOOTHING':
      value.en = 'Sooting'
      value.th = 'ปลอบประโลมผิว'
      break
    case 'PORE_CARE':
      value.en = 'Pore Care'
      value.th = 'กระชับรูขุมขน'
      break
    case 'OILINESS_CONTROL':
      value.en = 'Oiliness Control'
      value.th = 'คุมความมัน'
      break
    case 'ACNE_CARE':
      value.en = 'Acne-Care'
      value.th = 'รักษาสิว'
      break
    case 'SKIN_REJUVENATION':
      value.en = 'Skin Rejivenation'
      value.th = 'ฟื้นฟูผิว'
      break
    case 'HYPER_PIGMENTATION':
      value.en = 'Hyper-Pigmentation'
      value.th = 'ดูเเลจุดด่างดำ'
      break
    case 'SKIN_FIRM':
      value.en = 'Skin Firm'
      value.th = 'ผิวกระชับ'
      break
    case 'SKIN_CARE':
      value.en = 'Skin Care'
      value.th = 'บำรุงผิว'
      break
    case 'UV_PROTECTION':
      value.en = 'UV Protection'
      value.th = 'กันแดด'
      break
    case 'OTHER':
      value.en = 'Other'
      value.th = 'อื่นๆ'
      break
    default:
      break
  }

  return value
}

export const getProductAttributeLabel = (
  productAttributeEnum: keyof typeof ProductAttribute,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productAttributeEnum) {
    case 'ALCOHOL_FREE':
      value.en = 'Alcohol-free'
      value.th = 'ปราศจากแอลกอฮอล์'
      break
    case 'PARABEN_FREE':
      value.en = 'Paraben-free'
      value.th = 'ปราศจากพาราเบน'
      break
    case 'SILICONE_FREE':
      value.en = 'Silicone-free'
      value.th = 'ปราศจากซิลิโคน'
      break
    case 'SULFATE_FREE':
      value.en = 'Sulfate-free'
      value.th = 'ปราศจากซัลเฟต'
      break
    case 'ALLERGEN_FREE':
      value.en = 'Allergen-free'
      value.th = 'ปราศจากสารก่อภูมิเเพ้'
      break
    case 'CRUELTY_FREE':
      value.en = 'Cruelty-free'
      value.th = 'ผลิตภัณฑ์นี้ไม่ผ่านการทดสอบในสัตว์'
      break
    case 'FRAGRANCE_FREE':
      value.en = 'Fragrance-free'
      value.th = 'ปราศจากน้ำหอม'
      break
    case 'VEGAN':
      value.en = 'Vegan'
      value.th = 'ปราศจากส่วนผสมจากสัตว์'
      break
    case 'PREGNANCY_FRIENDLY':
      value.en = 'Pregnancy-friendly'
      value.th = 'ปลอดภัยสำหรับคนตั้งครรภ์'
      break
    case 'NON_COMEDOGENIC':
      value.en = 'Non-comedogenic'
      value.th = 'ปราศจากส่วนผสมที่ทำให้อุดตัน'
      break
    case 'OIL_FREE':
      value.en = 'Oil-free'
      value.th = 'ปราศจากน้ำมัน'
      break
    case 'FUNGAL_ACNE_SAFE':
      value.en = 'Fungal Acne-Safe'
      value.th = 'ปลอดภัยสำหรับสิวยีสต์ / ไม่มีอาหารสำหรับสิวยีสต์'
      break
    case 'ECZEMA_SAFE':
      value.en = 'Eczema-Safe'
      value.th = 'ปลอดภัยสำหรับผื่นผิวหนังอักเสบ'
      break
    case 'CORALS_SAFE':
      value.en = 'Corals-Safe'
      value.th = 'ไม่ทำร้ายประการัง'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    default:
      break
  }

  return value
}

export const getProductUsageValueLabel = (
  productUsageEnum: keyof typeof UsageSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productUsageEnum) {
    case 'TAKE':
      value.en = 'Take'
      value.th = 'รับประทานครั้งละ'
      break
    case 'APPLY':
      value.en = 'Apply'
      value.th = 'ทา'
      break
    case 'APPLY_ACNE':
      value.en = 'Apply on acne'
      value.th = 'แต้มสิว'
      break
    case 'APPLY_DARK_SPOT':
      value.en = 'Apply on dark spot'
      value.th = 'แต้มรอยดำ'
      break
    case 'SPRAY':
      value.en = 'Spray'
      value.th = 'พ่น'
      break
    case 'SHAMPOO':
      value.en = 'Shampoo'
      value.th = 'สระผม'
      break
    case 'APPLY_AFFECTED_AREA_AND_LEAVE_FOR':
      value.en = 'Apply on affected area and leave for'
      value.th = 'ฟอก'
      break
    case 'EYE_DROPS':
      value.en = 'Eye drops'
      value.th = 'หยอดตา'
      break
    case 'PUT_ON':
      value.en = 'Put on'
      value.th = 'ใส่'
      break
    case 'CLEANSE_FACE':
      value.en = 'Cleanse the face'
      value.th = 'ล้างหน้า'
      break
    case 'INSERT_VAGINA':
      value.en = 'Insert into the vagina'
      value.th = 'สอดช่องคลอด'
      break
    case 'APPLY_30_MIN_BEFORE_SUN':
      value.en = 'Apply 30 mins before sun exposure'
      value.th = 'ทาก่อนออกแดด 30 นาที'
      break
    case 'APPLY_PEA_SIZE':
      value.en = 'Apply pea-sized amount'
      value.th = 'ทาปริมาณเมล็ดถั่ว'
      break
    case 'SHOWER':
      value.en = 'Shower'
      value.th = 'อาบน้ำ'
      break
    case 'DISSOLVE_ROOM_TEMP_WATER':
      value.en = 'Dissolve in room temperature water'
      value.th = 'ละลายน้ำอุณหภูมิห้อง'
      break
    case 'APPLY_LEAVE_FOR_AND_RINSE':
      value.en = 'Apply, leave for recommened time and rinse well'
      value.th = 'ทาทิ้งไว้ตามเวลาที่กำหนด แล้วล้างออก'
      break
    case 'SQUEEZE_IN_A_BRUSH':
      value.en = 'Squeeze in a brush'
      value.th = 'บีบใส่แปรง'
      break
    case 'CLEAN':
      value.en = 'Clean'
      value.th = 'ทำความสะอาด'
      break
    case 'SLIGHTLY_DISSOLVED_WAIT_FOR_BUBBLE_THEN_DRINK':
      value.en = 'Slightly Dissolved wait for bubble then drink'
      value.th = 'ละลายน้ำเล็กน้อย รอฟองหมด แล้วดื่มครั้งละ'
      break
    case 'PUT_ON_HAIR':
      value.en = 'Put on hair'
      value.th = 'หยอดผม'
      break
    case 'CLEAN_HIDDEN_SPOT':
      value.en = 'Clean hidden spot'
      value.th = 'ทำความสะอาดจุดซ่อนเร้น'
      break
    case 'BRUSH':
      value.en = 'Brush'
      value.th = 'ใช้แปรงฟัน'
      break
    case 'PASTE':
      value.en = 'Paste'
      value.th = 'แปะ'
      break
    case 'THROAT_SPRAY':
      value.en = 'Throat spray'
      value.th = 'พ่นคอ'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    default:
      break
  }

  return value
}

export const getProductUnitValueLabel = (
  productUnitEnum: keyof typeof UnitSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productUnitEnum) {
    case 'TUBE':
      value.en = 'Tube'
      value.th = 'หลอด'
      break
    case 'STRIP':
      value.en = 'Blister / Strip'
      value.th = 'แผง'
      break
    case 'BOTTLE':
      value.en = 'Bottle'
      value.th = 'ขวด'
      break
    case 'BOX':
      value.en = 'Box'
      value.th = 'กล่อง'
      break
    case 'JAR':
      value.en = 'Jar'
      value.th = 'กระปุก'
      break
    case 'SACHET':
      value.en = 'Sachet'
      value.th = 'ซอง'
      break
    case 'PACK':
      value.en = 'Pack'
      value.th = 'ห่อ'
      break
    case 'UNIT':
      value.en = 'Unit'
      value.th = 'หน่วย'
      break
    case 'ROLL':
      value.en = 'Roll'
      value.th = 'ม้วน'
      break
    case 'SHEET':
      value.en = 'Sheet'
      value.th = 'แผ่น'
      break
    case 'TABLET':
      value.en = 'Tablet'
      value.th = 'เม็ด'
      break
    case 'SET':
      value.en = 'Set'
      value.th = 'ชุด'
      break
    case 'STICK':
      value.en = 'Stick'
      value.th = 'แท่ง'
      break
    case 'CAPSULE':
      value.en = 'Capsule'
      value.th = 'แคปซูล'
      break
    case 'CASSETTE':
      value.en = 'Cassette'
      value.th = 'ตลับ'
      break
    case 'ML':
      value.en = 'mL'
      value.th = 'มล. / มิลลิลิตร'
      break
    case 'GRAM':
      value.en = 'g'
      value.th = 'ก. / กรัม'
      break
    case 'AMPULE':
      value.en = 'Ampule'
      value.th = 'แอมพูล'
      break
    case 'PIECE':
      value.en = 'Piece'
      value.th = 'ชิ้น'
      break
    case 'HANDLE':
      value.en = 'ด้าม'
      value.th = 'Handle'
      break
    case 'MEASURING_SPOON':
      value.en = 'Measuring Spoon'
      value.th = 'ช้อนตวง'
      break
    case 'LUMP':
      value.en = 'Lump'
      value.th = 'ก้อน'
      break
    case 'PRESS':
      value.en = 'Press'
      value.th = 'กด'
      break
    case 'MACHINE':
      value.en = 'Machine'
      value.th = 'เครื่อง'
      break
    default:
      break
  }

  return value
}

export const getProductUseFrequencyLabel = (
  productUseFrequencyEnum: keyof typeof UseFrequencySchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productUseFrequencyEnum) {
    case 'ONCE_DAILY':
      value.en = 'Once daily'
      value.th = 'วันละ 1 ครั้ง'
      break
    case 'ONCE_DAILY_AFTER_MEAL':
      value.en = 'Once daily after meal'
      value.th = 'วันละ 1 ครั้ง หลังอาหาร'
      break
    case 'ONCE_DAILY_BEFORE_MEAL':
      value.en = 'Once daily before meal'
      value.th = 'วันละ 1 ครั้ง ก่อนอาหาร'
      break
    case 'ONCE_DAILY_WITH_MEAL':
      value.en = 'Once daily with meal'
      value.th = 'วันละ 1 ครั้ง พร้อมอาหาร'
      break
    case 'TWICE_DAILY':
      value.en = 'Twice daily'
      value.th = 'วันละ 2 ครั้ง'
      break
    case 'TWICE_DAILY_AFTER_MEAL':
      value.en = 'Twice daily after meal'
      value.th = 'วันละ 2 ครั้ง หลังอาหาร'
      break
    case 'TWICE_DAILY_BEFORE_MEAL':
      value.en = 'Twice daily before meal'
      value.th = 'วันละ 2 ครั้ง ก่อนอาหาร'
      break
    case 'TWICE_DAILY_WITH_MEAL':
      value.en = 'Twice daily with meal'
      value.th = 'วันละ 2 ครั้ง พร้อมอาหาร'
      break
    case 'THRICE_DAILY':
      value.en = 'Three times daily'
      value.th = 'วันละ 3 ครั้ง'
      break
    case 'THRICE_DAILY_AFTER_MEAL':
      value.en = 'Three times daily after meal'
      value.th = 'วันละ 3 ครั้ง หลังอาหาร'
      break
    case 'THRICE_DAILY_BEFORE_MEAL':
      value.en = 'Three times daily before meal'
      value.th = 'วันละ 3 ครั้ง ก่อนอาหาร'
      break
    case 'THRICE_DAILY_WITH_MEAL':
      value.en = 'Three times daily with meal'
      value.th = 'วันละ 3 ครั้ง พร้อมอาหาร'
      break
    case 'FOUR_TIMES_DAILY':
      value.en = 'Four times daily'
      value.th = 'วันละ 4 ครั้ง'
      break
    case 'FOUR_TIMES_DAILY_AFTER_MEAL':
      value.en = 'Four times daily after meal'
      value.th = 'วันละ 4 ครั้ง หลังอาหาร'
      break
    case 'FOUR_TIMES_DAILY_BEFORE_MEAL':
      value.en = 'Four times daily before meal'
      value.th = 'วันละ 4 ครั้ง ก่อนอาหาร'
      break
    case 'FOUR_TIMES_DAILY_WITH_MEAL':
      value.en = 'Four times daily with meal'
      value.th = 'วันละ 4 ครั้ง พร้อมอาหาร'
      break
    case 'FIVE_TIMES_DAILY':
      value.en = 'Five times daily'
      value.th = 'วันละ 5 ครั้ง'
      break
    case 'EVERY_OTHER_DAY':
      value.en = 'Every other day'
      value.th = 'วันเว้นวัน'
      break
    case 'ONCE_A_WEEK':
      value.en = 'Once a week'
      value.th = 'สัปดาห์ละ 1 ครั้ง'
      break
    case 'TWICE_A_WEEK':
      value.en = 'Twice a week'
      value.th = 'สัปดาห์ละ 2 ครั้ง'
      break
    case 'THRICE_A_WEEK':
      value.en = 'Three times a week'
      value.th = 'สัปดาห์ละ 3 ครั้ง'
      break
    case 'EVERY_4_TO_6_HR':
      value.en = 'Every 4-6 hrs.'
      value.th = 'ทุก 4-6 ชั่วโมง'
      break
    case 'EVERY_4_HR':
      value.en = 'Every 4 hrs.'
      value.th = 'ทุก 4 ชั่วโมง'
      break
    case 'EVERY_6_HR':
      value.en = 'Every 6 hrs.'
      value.th = 'ทุก 6 ชั่วโมง'
      break
    case 'EVERY_8_HR':
      value.en = 'Every 8 hrs.'
      value.th = 'ทุก 8 ชั่วโมง'
      break
    case 'EVERY_12_HR':
      value.en = 'Every 12 hrs.'
      value.th = 'ทุก 12 ชั่วโมง'
      break
    case 'EVERY_24_HR':
      value.en = 'Every 24 hrs.'
      value.th = 'ทุก 24 ชั่วโมง'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    default:
      break
  }

  return value
}

export const getProductUseTimeLabel = (
  productUseTimeEnum: keyof typeof UseTimeSchema.Enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (productUseTimeEnum) {
    case 'AS_NEED':
      value.en = 'As needed'
      value.th = 'เมื่อมีอาการ'
      break
    case 'MORNING':
      value.en = 'Morning'
      value.th = 'เช้า'
      break
    case 'NOON':
      value.en = 'Noon'
      value.th = 'กลางวัน'
      break
    case 'EVENING':
      value.en = 'Evening'
      value.th = 'เย็น'
      break
    case 'BEFORE_BEDTIME':
      value.en = 'Before bedtime'
      value.th = 'ก่อนนอน'
      break
    case 'MORNING_AND_EVENING':
      value.en = 'Morning and evening'
      value.th = 'เช้าและเย็น'
      break
    case 'MORNING_AND_BEFORE_BEDTIME':
      value.en = 'Morning and before bedtime'
      value.th = 'เช้าและก่อนนอน'
      break
    case 'NOON_AND_BEFORE_BEDTIME':
      value.en = 'Noon and Before bedtime'
      value.th = 'กลางวันและก่อนนอน'
      break
    case 'MORNING_NOON_AND_EVENING':
      value.en = 'Morning, Noon and Evening'
      value.th = 'เช้า กลางวัน เย็น'
      break
    case 'MORNING_NOON_EVENING_AND_BEFORE_BEDTIME':
      value.en = 'Morning, Noon, Evening and Before bedtime'
      value.th = 'เช้า กลางวัน เย็น ก่อนนอน'
      break
    case 'EVERY_MONDAY':
      value.en = 'Every Monday'
      value.th = 'ทุกวันจันทร์'
      break
    case 'EVERY_TUESDAY':
      value.en = 'Every Tuesday'
      value.th = 'ทุกวันอังคาร'
      break
    case 'EVERY_WEDNESDAY':
      value.en = 'Every Wednesday'
      value.th = 'ทุกวันพุธ'
      break
    case 'EVERY_THURSDAY':
      value.en = 'Every Thursday'
      value.th = 'ทุกวันพุฤหัสบดี'
      break
    case 'EVERY_FRIDAY':
      value.en = 'Every Friday'
      value.th = 'ทุกวันศุกร์'
      break
    case 'EVERY_SATURDAY':
      value.en = 'Every Saturday'
      value.th = 'ทุกวันเสาร์'
      break
    case 'EVERY_SUNDAY':
      value.en = 'Every Sunday'
      value.th = 'ทุกวันอาทิตย์'
      break
    case 'TUESDAY_AND_THURSDAY':
      value.en = 'Tuesday and Thursday'
      value.th = 'ทุกวันอังคาร และ พฤหัสบดี'
      break
    case 'MONDAY_WEDNESDAY_AND_FRIDAY':
      value.en = 'Monday, Wednesday and Friday'
      value.th = 'ทุกวันจันทร์ พุธ ศุกร์'
      break
    case 'ON_EVEN_DAY':
      value.en = 'On even day'
      value.th = 'ทุกวันคู่'
      break
    case 'ON_ODD_DAY':
      value.en = 'On odd day'
      value.th = 'ทุกวันคี่'
      break
    case 'AT_6_10_14_18_22_HR':
      value.en = 'At 6am,10am,2pm,6pm and 10pm'
      value.th = 'เวลา 6:00, 10:00, 14:00, 18:00, 22:00น.'
      break
    case 'IMMEDIATELY':
      value.en = 'Immediately'
      value.th = 'ทันทีที่ได้รับยา'
      break
    case 'NA':
      value.en = 'N/A'
      value.th = 'ไม่ระบุ'
      break
    default:
      break
  }

  return value
}

// Teleconsult Enum -----------------------------------------------------------------------------------

export const getSymptomDurationUnitLabel = (
  symptomDurationUnitEnum: keyof typeof SymptomDurationUnitSchema.Enum,
) => {
  const value = { en: '', th: '' }

  switch (symptomDurationUnitEnum) {
    case 'DAY':
      value.en = 'Day'
      value.th = 'วัน'
      break
    case 'MONTH':
      value.en = 'Month'
      value.th = 'เดือน'
      break
    case 'YEAR':
      value.en = 'Year'
      value.th = 'ปี'
      break
    default:
      break
  }

  return value
}

export const getConsultingChannelLabel = (
  consultingChannelEnum: keyof typeof ConsultingChannelSchema.Enum,
) => {
  const value = { en: '', th: '' }

  switch (consultingChannelEnum) {
    case 'VIDEO':
      value.en = 'Video call'
      value.th = 'วิดีโอคอล'
      break
    case 'CHAT':
      value.en = 'Chat'
      value.th = 'แชท'
      break
    default:
      break
  }

  return value
}

export const getAppointmentStatusLabel = (
  appointmentStatusEnum: keyof typeof AppointmentStatusSchema.Enum,
) => {
  const value = { en: '', th: '' }

  switch (appointmentStatusEnum) {
    case 'IN_PROGRESS':
      value.en = 'IN_PROGRESS'
      value.th = 'อยู่ระหว่างนัด'
      break
    case 'CANCELED':
      value.en = 'CANCELED'
      value.th = 'ยกเลิกนัด'
      break
    case 'WAIT_TO_SUMMARIZE':
      value.en = 'WAIT_TO_SUMMARIZE'
      value.th = 'รอแพทย์สรุป'
      break
    case 'COMPLETED':
      value.en = 'COMPLETED'
      value.th = 'มาตามนัด'
      break
    case 'FAILED':
      value.en = 'FAILED'
      value.th = 'ไม่มาตามนัด'
      break
    default:
      break
  }

  return value
}

export const getAppointmentTypeLabel = (
  appointmentTypeEnum: keyof typeof AppointmentTypeSchema.Enum,
) => {
  const value = { en: '', th: '' }

  switch (appointmentTypeEnum) {
    case 'APPOINTMENT':
      value.en = 'APPOINTMENT'
      value.th = 'นัดหมาย'
      break
    case 'FOLLOW_UP':
      value.en = 'FOLLOW_UP'
      value.th = 'ติดตามอาการ'
      break
    default:
      break
  }

  return value
}

// Partner Enum -----------------------------------------------------------------------------------

export const getPartnerTypeLabel = (
  partnerType: keyof typeof PartnerTypeSchema.Enum,
) => {
  const value = { en: '', th: '' }
  switch (partnerType) {
    case 'CLINIC':
      value.en = 'Clinic'
      value.th = 'คลินิก'
      break
    case 'HOSPITAL':
      value.en = 'Hospital'
      value.th = 'โรงพยาบาล'
      break
    default:
      break
  }

  return value
}

// Notification Enum -----------------------------------------------------------------------------------

export const getNotificationTypeSchemaLabel = (
  notificationTypeEnum: keyof typeof NotificationTypeSchema.enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }
  switch (notificationTypeEnum) {
    case 'ORDER':
      value.en = 'Order'
      value.th = 'Order'
      break
    case 'CHAT':
      value.en = 'Chat'
      value.th = 'Chat'
      break
    case 'ACTIVITY':
      value.en = 'Activity'
      value.th = 'Activity'
      break
    case 'NOTICE':
      value.en = 'Notice'
      value.th = 'Notice'
      break
    case 'PROMOTION':
      value.en = 'Promotion'
      value.th = 'Promotion'
      break
    default:
      break
  }

  return value
}

export const getNotificationActionTypeSchemaLabel = (
  notificationActionTypeEnum: keyof typeof NotificationActionTypeSchema.enum,
): { en: string; th: string } => {
  const value = { en: '', th: '' }

  switch (notificationActionTypeEnum) {
    case 'NO_ACTION':
      value.en = 'No Action'
      value.th = 'No Action'
      break
    case 'NAVIGATE':
      value.en = 'Navigate'
      value.th = 'Navigate'
      break
    case 'URL':
      value.en = 'Url'
      value.th = 'Url'
      break
    default:
      break
  }

  return value
}
