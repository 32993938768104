import { z } from 'zod'
import { HealthInfoCreateWithoutOrderInputObjectSchema } from './HealthInfoCreateWithoutOrderInput.schema'
import { HealthInfoUncheckedCreateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedCreateWithoutOrderInput.schema'
import { HealthInfoCreateOrConnectWithoutOrderInputObjectSchema } from './HealthInfoCreateOrConnectWithoutOrderInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoCreateNestedOneWithoutOrderInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => HealthInfoCreateWithoutOrderInputObjectSchema),
        z.lazy(() => HealthInfoUncheckedCreateWithoutOrderInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => HealthInfoCreateOrConnectWithoutOrderInputObjectSchema)
      .optional(),
    connect: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const HealthInfoCreateNestedOneWithoutOrderInputObjectSchema = Schema
