import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutAddressInputObjectSchema } from './PatientCreateWithoutAddressInput.schema'
import { PatientUncheckedCreateWithoutAddressInputObjectSchema } from './PatientUncheckedCreateWithoutAddressInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutAddressInput> = z
  .object({
    where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PatientCreateWithoutAddressInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutAddressInputObjectSchema),
    ]),
  })
  .strict()

export const PatientCreateOrConnectWithoutAddressInputObjectSchema = Schema
