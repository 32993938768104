import { z } from 'zod'
import { CouponCreateManyGroupInputObjectSchema } from './CouponCreateManyGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponCreateManyGroupInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => CouponCreateManyGroupInputObjectSchema),
      z.lazy(() => CouponCreateManyGroupInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const CouponCreateManyGroupInputEnvelopeObjectSchema = Schema
