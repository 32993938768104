import { z } from 'zod'
import { ProductItemScalarWhereInputObjectSchema } from './ProductItemScalarWhereInput.schema'
import { ProductItemUpdateManyMutationInputObjectSchema } from './ProductItemUpdateManyMutationInput.schema'
import { ProductItemUncheckedUpdateManyWithoutProductItemsInputObjectSchema } from './ProductItemUncheckedUpdateManyWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateManyWithWhereWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => ProductItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductItemUncheckedUpdateManyWithoutProductItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemUpdateManyWithWhereWithoutDeliveryInputObjectSchema =
  Schema
