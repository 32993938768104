import { z } from 'zod'
import { DiscountSettingCreateInputObjectSchema } from './objects/DiscountSettingCreateInput.schema'
import { DiscountSettingUncheckedCreateInputObjectSchema } from './objects/DiscountSettingUncheckedCreateInput.schema'

export const DiscountSettingCreateOneSchema = z.object({
  data: z.union([
    DiscountSettingCreateInputObjectSchema,
    DiscountSettingUncheckedCreateInputObjectSchema,
  ]),
})
