import { z } from 'zod'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderUpdateWithoutPartnerInputObjectSchema } from './ProcedureOrderUpdateWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedUpdateWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedUpdateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderUpdateWithWhereUniqueWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => ProcedureOrderWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureOrderUpdateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => ProcedureOrderUncheckedUpdateWithoutPartnerInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureOrderUpdateWithWhereUniqueWithoutPartnerInputObjectSchema =
  Schema
