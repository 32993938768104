import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { NestedEnumPaymentGatewayNullableWithAggregatesFilterObjectSchema } from './NestedEnumPaymentGatewayNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumPaymentGatewayNullableFilterObjectSchema } from './NestedEnumPaymentGatewayNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumPaymentGatewayNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => PaymentGatewaySchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => PaymentGatewaySchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => PaymentGatewaySchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => PaymentGatewaySchema),
          z.lazy(
            () =>
              NestedEnumPaymentGatewayNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumPaymentGatewayNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumPaymentGatewayNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const EnumPaymentGatewayNullableWithAggregatesFilterObjectSchema = Schema
