import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import {
  DiscountItemTypeSchema,
  DiscountScopeSchema,
} from '@ssch-backend/coupons'
import { ShippingTypeSchema } from '@ssch-backend/orders'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useEffect, useState } from 'react'
import {
  ArrayInput,
  Button,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  ReferenceArrayInput,
  SelectInput,
  SimpleFormIterator,
  required,
  useLocaleState,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteArrayInput,
  FormSelectInput,
} from '../../../components/FormInput/input'
import { handleFilterFullnameQuery, toCamelCase } from '../../../utils'

const discountSettingSx = {
  margin: '0.6rem 0',
}

export const getItemTypeReferenceKey = (
  itemTypeEnum: keyof typeof DiscountItemTypeSchema.enum,
) => {
  let result = ''
  switch (itemTypeEnum) {
    case 'VARIANT':
    case 'VARIANT_NO_FLASHSALE':
      result = 'productVariantSearch'
      break
    case 'VARIANT_BRAND':
    case 'VARIANT_CATEGORY':
      result = toCamelCase(itemTypeEnum.replace('VARIANT', 'product'))
      break
    case 'VARIANT_FLASHSALE':
      result = 'flashsaleItemProduct'
      break
    case 'PROCEDURE_NO_FLASHSALE':
      result = 'procedure'
      break
    case 'PROCEDURE_PARTNER':
      result = 'partner'
      break
    case 'PROCEDURE_FLASHSALE':
      result = 'flashsaleItemProcedure'
      break
    default: {
      result = toCamelCase(itemTypeEnum)
      break
    }
  }
  return result
}

export const optionTextDiscountSettingItem = (params: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  itemTypeEnum: keyof typeof DiscountItemTypeSchema.enum
  locale: string
}) => {
  const { data, itemTypeEnum, locale } = params
  let result = ''
  switch (itemTypeEnum) {
    case 'DOCTOR':
      result = [
        `(ID: ${data.id})`,
        data.prefix,
        data.firstname,
        data.lastname,
      ].join(' ')
      break
    case 'VARIANT':
    case 'VARIANT_NO_FLASHSALE':
      result = [
        `(SKU: ${data.sku})`,
        data.productNameTr && getTr(data.productNameTr, locale as Language),
        data.descriptionTr && getTr(data.descriptionTr, locale as Language),
      ].join(' ')
      break
    case 'VARIANT_BRAND':
    case 'SHIPPING_PROVIDER':
      result = [`(ID: ${data.id})`, data.name].join(' ')
      break
    case 'DOCTOR_CATEGORY':
    case 'VARIANT_CATEGORY':
    case 'PROCEDURE_CATEGORY':
    case 'PROCEDURE':
    case 'PROCEDURE_NO_FLASHSALE':
    case 'PROCEDURE_PARTNER':
      result = [
        `(ID: ${data.id})`,
        data.nameTr && getTr(data.nameTr, locale as Language),
      ].join(' ')
      break
    case 'VARIANT_FLASHSALE':
      result =
        data.variant &&
        [
          `(SKU: ${data.variant.sku})`,
          data.variant.product.nameTr &&
            getTr(data.variant.product.nameTr, locale as Language),
          data.variant.descriptionTr &&
            getTr(data.variant.descriptionTr, locale as Language),
          `, Flashsale: (ID: ${data.flashsaleId})`,
          data.flashsale.name,
        ].join(' ')
      break
    case 'PROCEDURE_FLASHSALE':
      result =
        data.procedure &&
        [
          `Procedure: (ID: ${data.procedure.id})`,
          data.procedure.nameTr &&
            getTr(data.procedure.nameTr, locale as Language),
          `, Flashsale: (ID: ${data.flashsaleId})`,
          data.flashsale.name,
        ].join(' ')
      break
    default:
      break
  }
  return result
}

const ConsumerFormSelectFlashsaleCondition: FC<
  Required<FormDataConsumerRenderParams> & { source?: string }
> = ({ scopedFormData, getSource, source = 'flashsaleCondition' }) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue(getSource(source), null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedFormData.discountItemType])
  // Disable flashsale when discountItemType neither Product nor Procedure
  const disabled =
    !scopedFormData.discountItemType ||
    ![
      DiscountItemTypeSchema.Enum.PROCEDURE,
      DiscountItemTypeSchema.Enum.VARIANT,
    ].includes(scopedFormData.discountItemType)
  return (
    <FormSelectInput
      source={getSource(source)}
      disabled={disabled}
      validate={disabled ? [] : required()}
      choices={Object.values(DiscountItemTypeSchema.Enum)
        .filter(
          (option) =>
            option === scopedFormData.discountItemType ||
            (option.includes(scopedFormData.discountItemType) &&
              option.includes('FLASHSALE')),
        )
        .map((option) => ({
          id: option,
          name: ['resources.couponGroup.title', source, option].join('.'),
        }))}
      sx={{
        ...discountSettingSx,
        width: '25%',
      }}
      helperText={false}
    />
  )
}

const ConsumerFormSelectItemType: FC<
  Required<FormDataConsumerRenderParams> & { source?: string }
> = ({ scopedFormData, getSource, source = 'itemType' }) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue(getSource(source), null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedFormData.discountItemType])

  return (
    <FormSelectInput
      source={getSource(source)}
      disabled={
        !scopedFormData.discountItemType ||
        ([
          DiscountItemTypeSchema.Enum.PROCEDURE,
          DiscountItemTypeSchema.Enum.VARIANT,
        ].includes(scopedFormData.discountItemType) &&
          !scopedFormData.flashsaleCondition)
      }
      validate={required()}
      choices={Object.values(DiscountItemTypeSchema.Enum)
        .filter(
          (option) =>
            option.includes(scopedFormData.discountItemType) &&
            !option.includes('FLASHSALE'),
        )
        .map((option) => ({
          id: option,
          name: ['resources.couponGroup.title', source, option].join('.'),
        }))}
      sx={{
        ...discountSettingSx,
        width: 'calc(50% - 2rem)',
      }}
      helperText={false}
    />
  )
}

const ReferenceArrayAutoCompleteItemInput: FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Required<FormDataConsumerRenderParams> & { [key: string]: any }
> = ({
  formData,
  scopedFormData,
  getSource,
  itemType,
  source = 'items',
  ...rest
}) => {
  const [locale] = useLocaleState()
  const { setValue } = useFormContext()
  const [onlyAutocompleteArrayInput, setOnlyAutocompleteArrayInput] =
    useState<boolean>(false)

  useEffect(() => {
    setValue(getSource(source), [])
    if (itemType === DiscountItemTypeSchema.Enum.SHIPPING_TYPE) {
      setOnlyAutocompleteArrayInput(true)
    } else {
      setOnlyAutocompleteArrayInput(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scopedFormData.discountItemType,
    scopedFormData.flashsaleCondition,
    itemType,
  ])

  useEffect(() => {
    if (scopedFormData.scope !== DiscountScopeSchema.enum.ALL) return
    setValue(getSource(source), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedFormData.scope])

  const filterToQuery = (
    textSearch: string,
    itemTypeEnum: keyof typeof DiscountItemTypeSchema.enum,
  ) => {
    const filter: { [key: string]: unknown } = {}
    if (!textSearch) return { id: { in: [] } }
    switch (itemTypeEnum) {
      case 'DOCTOR':
        filter.OR = handleFilterFullnameQuery(textSearch).OR
        break
      case 'PROCEDURE':
      case 'PROCEDURE_NO_FLASHSALE':
      case 'PROCEDURE_PARTNER':
        filter.OR = [
          Number(textSearch) && { id: Number(textSearch) },
          { searchField: textSearch },
        ]
        break
      case 'DOCTOR_CATEGORY':
      case 'PROCEDURE_CATEGORY':
      case 'VARIANT_CATEGORY':
        filter.OR = [Number(textSearch) && { id: Number(textSearch) }]
        break
      case 'VARIANT':
      case 'VARIANT_NO_FLASHSALE':
        filter.textSearch = { contains: textSearch.trim() }
        break
      case 'VARIANT_BRAND':
        filter.OR = [
          Number(textSearch) && { id: Number(textSearch) },
          { name: textSearch },
          { coporateName: textSearch },
        ]
        break
      case 'VARIANT_FLASHSALE': {
        const number = Number(textSearch)
        filter.OR = [
          number && { id: number },
          number && { flashsaleId: number },
          number && { variantId: number },
          number && { variant: { size: number } },
          { flashsale: { name: { contains: textSearch } } },
          { variant: { sku: { contains: textSearch } } },
          { variant: { product: { searchField: { contains: textSearch } } } },
        ]
        filter.flashsale = {
          endAt: { gt: new Date() },
        }
        break
      }
      case 'PROCEDURE_FLASHSALE': {
        const number = Number(textSearch)
        filter.OR = [
          number && { id: number },
          number && { flashsaleId: number },
          number && { procedureId: number },
          { flashsale: { name: { contains: textSearch } } },
          { procedure: { searchField: { contains: textSearch } } },
        ]
        filter.flashsale = {
          endAt: { gt: new Date() },
        }
        break
      }
      default:
        filter.id = { in: [] }
        break
    }
    return filter
  }

  const filterList = (
    itemTypeEnum: keyof typeof DiscountItemTypeSchema.enum,
  ) => {
    switch (itemTypeEnum) {
      case 'VARIANT_FLASHSALE':
      case 'PROCEDURE_FLASHSALE':
        return {
          flashsale: {
            endAt: { gt: new Date() },
          },
        }
      default:
        return undefined
    }
  }

  return !onlyAutocompleteArrayInput ? (
    <ReferenceArrayInput
      source={getSource(source)}
      reference={getItemTypeReferenceKey(itemType)}
      sort={{ field: 'id', order: 'ASC' }}
      filter={filterList(itemType)}
      perPage={itemType.includes('CATEGORY') ? 999 : undefined}
      {...rest}
    >
      <FormAutoCompleteArrayInput
        fullWidth
        sx={{ ...discountSettingSx, maxWidth: 'calc(50% - 2rem)' }}
        disabled={scopedFormData.scope === DiscountScopeSchema.Enum.ALL}
        optionText={(choice) =>
          optionTextDiscountSettingItem({
            data: choice,
            itemTypeEnum: itemType,
            locale,
          })
        }
        debounce={100}
        filterToQuery={(searchText) =>
          filterToQuery(searchText.trim(), itemType)
        }
        helperText={false}
      />
    </ReferenceArrayInput>
  ) : (
    <FormAutoCompleteArrayInput
      source={getSource(source)}
      fullWidth
      disabled={scopedFormData.scope === DiscountScopeSchema.Enum.ALL}
      sx={{ ...discountSettingSx, maxWidth: 'calc(50% - 2rem)' }}
      choices={Object.values(ShippingTypeSchema.Enum).map((option) => ({
        id: option,
        name: ['resources.couponGroup.title.shippingType', option].join('.'),
      }))}
      helperText={false}
    />
  )
}

export const DiscountSettingSection: FC<{
  hide?: boolean
}> = ({ hide = false }) => {
  const { watch } = useFormContext()
  const translate = useTranslate()
  const discountSetting = watch('discountSetting')

  return !hide ? (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.couponGroup.title.discount_setting')}
        </Typography>
      </Box>
      <FormDivider />
      <ArrayInput
        defaultValue={[{}]}
        label=""
        source="discountSetting"
        sx={{ marginBottom: '2rem' }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableReordering
          disableAdd={false}
          disableClear
          getItemLabel={(index) => `#${index + 1}`}
          addButton={
            <Button
              variant="outlined"
              label="resources.couponGroup.action.discount_setting.add_button"
              startIcon={<AddIcon />}
              sx={{ padding: '0.5rem 1rem' }}
            />
          }
          removeButton={
            <IconButton size="small" color="error" sx={{ alignSelf: 'center' }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          }
          disableRemove={
            discountSetting && discountSetting.length > 1 ? false : true
          }
          sx={{
            [`& .RaSimpleFormIterator-line`]: {
              position: 'relative',
            },
            [`& > ul > li`]: {
              paddingBottom: '2rem',
              marginTop: '2rem',
            },
            [`& > ul > li:first-child`]: {
              marginTop: '0',
            },
            [`& .RaSimpleFormIterator-line:after`]: {
              content: `"${translate('operator.or')}"`,
              position: 'absolute',
              bottom: '-11px',
              background: 'white',
              padding: '0 10px',
              left: '30px',
              fontSize: '0.9rem',
            },
            [`& .RaSimpleFormIterator-line:last-child:after`]: {
              content: 'none',
            },
            [`& .RaSimpleFormIterator-action`]: {
              display: 'flex',
            },
          }}
        >
          <FormSelectInput
            source="discountItemType"
            validate={required()}
            choices={Object.values(DiscountItemTypeSchema.Enum)
              .filter((option) => !option.includes('_'))
              .map((option) => ({
                id: option,
                name: [
                  'resources.couponGroup.title',
                  'discountItemType',
                  option,
                ].join('.'),
              }))}
            sx={{
              ...discountSettingSx,
              width: 'calc(25% - 1rem)',
            }}
            helperText={false}
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) =>
              scopedFormData &&
              getSource && (
                <ConsumerFormSelectFlashsaleCondition
                  formData={formData}
                  scopedFormData={scopedFormData}
                  getSource={getSource}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) =>
              scopedFormData &&
              getSource && (
                <ConsumerFormSelectItemType
                  formData={formData}
                  scopedFormData={scopedFormData}
                  getSource={getSource}
                />
              )
            }
          </FormDataConsumer>
          <SelectInput
            source="scope"
            validate={required()}
            InputLabelProps={{ shrink: true }}
            choices={Object.values(DiscountScopeSchema.Enum).map((option) => ({
              id: option,
              name: ['resources.couponGroup.title.scope', option].join('.'),
            }))}
            sx={{ ...discountSettingSx, width: '50%' }}
            helperText={false}
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) =>
              scopedFormData && getSource && scopedFormData.itemType ? (
                <ReferenceArrayAutoCompleteItemInput
                  formData={formData}
                  scopedFormData={scopedFormData}
                  getSource={getSource}
                  itemType={
                    [
                      DiscountItemTypeSchema.Enum.VARIANT,
                      DiscountItemTypeSchema.Enum.PROCEDURE,
                    ].includes(scopedFormData.itemType)
                      ? scopedFormData.flashsaleCondition
                      : scopedFormData.itemType
                  }
                  rest
                />
              ) : null
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  ) : null
}
