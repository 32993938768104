import { z } from 'zod'
import { ProductCreateWithoutProductReviewsInputObjectSchema } from './ProductCreateWithoutProductReviewsInput.schema'
import { ProductUncheckedCreateWithoutProductReviewsInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewsInput.schema'
import { ProductCreateOrConnectWithoutProductReviewsInputObjectSchema } from './ProductCreateOrConnectWithoutProductReviewsInput.schema'
import { ProductUpsertWithoutProductReviewsInputObjectSchema } from './ProductUpsertWithoutProductReviewsInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutProductReviewsInputObjectSchema } from './ProductUpdateWithoutProductReviewsInput.schema'
import { ProductUncheckedUpdateWithoutProductReviewsInputObjectSchema } from './ProductUncheckedUpdateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutProductReviewsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => ProductUncheckedCreateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProductCreateOrConnectWithoutProductReviewsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutProductReviewsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProductUpdateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => ProductUncheckedUpdateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema =
  Schema
