import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCountOutputTypeSelect> = z
  .object({
    banners: z.boolean().optional(),
    products: z.boolean().optional(),
    productReviews: z.boolean().optional(),
    productReviewSummaries: z.boolean().optional(),
  })
  .strict()

export const BrandCountOutputTypeSelectObjectSchema = Schema
