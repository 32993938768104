import { z } from 'zod'
import { OrderCreateWithoutShippingProviderInputObjectSchema } from './OrderCreateWithoutShippingProviderInput.schema'
import { OrderUncheckedCreateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedCreateWithoutShippingProviderInput.schema'
import { OrderCreateOrConnectWithoutShippingProviderInputObjectSchema } from './OrderCreateOrConnectWithoutShippingProviderInput.schema'
import { OrderCreateManyShippingProviderInputEnvelopeObjectSchema } from './OrderCreateManyShippingProviderInputEnvelope.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedManyWithoutShippingProviderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema),
          z
            .lazy(() => OrderCreateWithoutShippingProviderInputObjectSchema)
            .array(),
          z.lazy(
            () => OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderUncheckedCreateWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => OrderCreateOrConnectWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OrderCreateOrConnectWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => OrderCreateManyShippingProviderInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => OrderWhereUniqueInputObjectSchema),
          z.lazy(() => OrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const OrderCreateNestedManyWithoutShippingProviderInputObjectSchema =
  Schema
