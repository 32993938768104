import { z } from 'zod'
import { FlashsaleItemOrderByWithRelationInputObjectSchema } from './objects/FlashsaleItemOrderByWithRelationInput.schema'
import { FlashsaleItemWhereInputObjectSchema } from './objects/FlashsaleItemWhereInput.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './objects/FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemCountAggregateInputObjectSchema } from './objects/FlashsaleItemCountAggregateInput.schema'
import { FlashsaleItemMinAggregateInputObjectSchema } from './objects/FlashsaleItemMinAggregateInput.schema'
import { FlashsaleItemMaxAggregateInputObjectSchema } from './objects/FlashsaleItemMaxAggregateInput.schema'
import { FlashsaleItemAvgAggregateInputObjectSchema } from './objects/FlashsaleItemAvgAggregateInput.schema'
import { FlashsaleItemSumAggregateInputObjectSchema } from './objects/FlashsaleItemSumAggregateInput.schema'

export const FlashsaleItemAggregateSchema = z.object({
  orderBy: z
    .union([
      FlashsaleItemOrderByWithRelationInputObjectSchema,
      FlashsaleItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: FlashsaleItemWhereInputObjectSchema.optional(),
  cursor: FlashsaleItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), FlashsaleItemCountAggregateInputObjectSchema])
    .optional(),
  _min: FlashsaleItemMinAggregateInputObjectSchema.optional(),
  _max: FlashsaleItemMaxAggregateInputObjectSchema.optional(),
  _avg: FlashsaleItemAvgAggregateInputObjectSchema.optional(),
  _sum: FlashsaleItemSumAggregateInputObjectSchema.optional(),
})
