import { z } from 'zod'
import { ShippingProviderWhereInputObjectSchema } from './ShippingProviderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderRelationFilter> = z
  .object({
    is: z
      .lazy(() => ShippingProviderWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => ShippingProviderWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const ShippingProviderRelationFilterObjectSchema = Schema
