import { z } from 'zod'
import { ProcedureReviewCreateNestedOneWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    url: z.string(),
    procedureReview: z.lazy(
      () => ProcedureReviewCreateNestedOneWithoutPicturesInputObjectSchema,
    ),
  })
  .strict()

export const ProcedureReviewPictureCreateInputObjectSchema = Schema
