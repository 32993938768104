import { z } from 'zod'
import { ProductCreateWithoutVideoInputObjectSchema } from './ProductCreateWithoutVideoInput.schema'
import { ProductUncheckedCreateWithoutVideoInputObjectSchema } from './ProductUncheckedCreateWithoutVideoInput.schema'
import { ProductCreateOrConnectWithoutVideoInputObjectSchema } from './ProductCreateOrConnectWithoutVideoInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutVideoInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProductCreateWithoutVideoInputObjectSchema),
        z.lazy(() => ProductUncheckedCreateWithoutVideoInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ProductCreateOrConnectWithoutVideoInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ProductCreateNestedOneWithoutVideoInputObjectSchema = Schema
