import { z } from 'zod'
import { BranchWhereInputObjectSchema } from './objects/BranchWhereInput.schema'
import { BranchOrderByWithAggregationInputObjectSchema } from './objects/BranchOrderByWithAggregationInput.schema'
import { BranchScalarWhereWithAggregatesInputObjectSchema } from './objects/BranchScalarWhereWithAggregatesInput.schema'
import { BranchScalarFieldEnumSchema } from './enums/BranchScalarFieldEnum.schema'

export const BranchGroupBySchema = z.object({
  where: BranchWhereInputObjectSchema.optional(),
  orderBy: z.union([
    BranchOrderByWithAggregationInputObjectSchema,
    BranchOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: BranchScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(BranchScalarFieldEnumSchema),
})
