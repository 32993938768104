import { z } from 'zod'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'
import { NestedEnumConsultingStatusWithAggregatesFilterObjectSchema } from './NestedEnumConsultingStatusWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumConsultingStatusFilterObjectSchema } from './NestedEnumConsultingStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumConsultingStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ConsultingStatusSchema).optional(),
    in: z
      .lazy(() => ConsultingStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConsultingStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConsultingStatusSchema),
        z.lazy(
          () => NestedEnumConsultingStatusWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumConsultingStatusFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumConsultingStatusFilterObjectSchema).optional(),
  })
  .strict()

export const EnumConsultingStatusWithAggregatesFilterObjectSchema = Schema
