import { z } from 'zod'
import { CaseCreateWithoutPicturesInputObjectSchema } from './CaseCreateWithoutPicturesInput.schema'
import { CaseUncheckedCreateWithoutPicturesInputObjectSchema } from './CaseUncheckedCreateWithoutPicturesInput.schema'
import { CaseCreateOrConnectWithoutPicturesInputObjectSchema } from './CaseCreateOrConnectWithoutPicturesInput.schema'
import { CaseUpsertWithoutPicturesInputObjectSchema } from './CaseUpsertWithoutPicturesInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseUpdateWithoutPicturesInputObjectSchema } from './CaseUpdateWithoutPicturesInput.schema'
import { CaseUncheckedUpdateWithoutPicturesInputObjectSchema } from './CaseUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutPicturesInputObjectSchema),
          z.lazy(() => CaseUncheckedCreateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseCreateOrConnectWithoutPicturesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CaseUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CaseUpdateWithoutPicturesInputObjectSchema),
          z.lazy(() => CaseUncheckedUpdateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const CaseUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
