import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithoutVariantInputObjectSchema } from './FlashsaleItemUpdateWithoutVariantInput.schema'
import { FlashsaleItemUncheckedUpdateWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedUpdateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => FlashsaleItemUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpdateWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
