import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutDoctorItemInputObjectSchema } from './OrderCreateWithoutDoctorItemInput.schema'
import { OrderUncheckedCreateWithoutDoctorItemInputObjectSchema } from './OrderUncheckedCreateWithoutDoctorItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutDoctorItemInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutDoctorItemInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutDoctorItemInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutDoctorItemInputObjectSchema = Schema
