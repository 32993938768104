import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ReferenceInput, required } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomAutoCompleteInput } from '../../components/CustomInput'
import { adminGatewayClient } from '../../service'
import { handleFilterFullnameQuery } from '../../utils'
import IOSSwitch from './iosSwitchStyle'
interface CreateFormProps {
  fetchAvailableSlot: () => Promise<void>
  openCreateForm: boolean
  setOpenCreateForm: Dispatch<SetStateAction<boolean>>
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: `${theme.spacing(2)} 0`,
  },
  '& .MuiDialogActions-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
}))

export default function CreateForm({
  fetchAvailableSlot,
  openCreateForm,
  setOpenCreateForm,
}: CreateFormProps) {
  const [date, setDate] = useState<Date | null>(null)
  const [startHour, setStartHour] = useState<string>('')
  const [startMinute, setStartMinute] = useState<string>('')
  const [endHour, setEndHour] = useState<string>('')
  const [endMinute, setEndMinute] = useState<string>('')
  const [isRepeated, setIsRepeated] = useState<boolean>(false)
  const [timeOption, setTimeOption] = useState<{
    hours: string[]
    minutes: string[]
  }>({
    hours: [],
    minutes: [],
  })
  const [endHourOptions, setEndHourOptions] = useState<string[]>([])
  const [endMinuteOptions, setEndMinuteOptions] = useState<string[]>([])
  const minutePerSlot = 60

  useEffect(() => {
    const _timeOption: { hours: string[]; minutes: string[] } = {
      hours: [],
      minutes: [],
    }
    for (let hour = 0; hour <= 24; hour++) {
      const hourFormatted = hour.toString().padStart(2, '0')
      _timeOption.hours.push(hourFormatted)
    }
    for (let minute = 0; minute < 60; minute += minutePerSlot) {
      _timeOption.minutes.push(minute.toString().padStart(2, '0'))
    }
    setTimeOption(_timeOption)
  }, [])

  useEffect(() => {
    const handleEndHourOptions = () => {
      const result = timeOption.hours.filter((hour) => {
        if (
          Number(hour) < Number(startHour) ||
          (Number(hour) === Number(startHour) &&
            Number(startMinute) >= Number(timeOption.minutes.slice(-1)))
        ) {
          return false
        }
        return true
      })
      if (!result.length || (endHour && !result.includes(endHour))) {
        setEndHour('')
      }
      setEndHourOptions(result)
    }

    const handleEndMinuteOptions = () => {
      const result = timeOption.minutes.filter((minute) => {
        if (endHour === '24' && minute !== '00') {
          return false
        } else if (
          Number(startHour) === Number(endHour) &&
          Number(minute) <= Number(startMinute)
        ) {
          return false
        }
        return true
      })
      if (!result.length || (endMinute && !result.includes(endMinute))) {
        setEndMinute('')
      }
      setEndMinuteOptions(result)
    }

    handleEndHourOptions()
    handleEndMinuteOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startHour, startMinute, endHour])

  const form = useForm({
    defaultValues: {
      doctorId: null,
    },
  })

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value ? new Date(event.target.value) : null)
  }

  const handleStartTimeChange = (event: SelectChangeEvent<string>) => {
    const unit = event.target.name?.split('-')[1]
    if (unit === 'hour') {
      setStartHour(event.target.value)
    } else if (unit === 'minute') {
      setStartMinute(event.target.value)
    }
  }

  const handleEndTimeChange = (event: SelectChangeEvent<string>) => {
    const unit = event.target.name?.split('-')[1]
    if (unit === 'hour') {
      setEndHour(event.target.value)
    } else if (unit === 'minute') {
      setEndMinute(event.target.value)
    }
  }

  const handleIsRepeatedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsRepeated(!isRepeated)
  }

  const onSubmit = async (data: { doctorId: number | null }) => {
    const { doctorId } = data
    if (doctorId && date && startHour && startMinute && endHour && endMinute) {
      const startDateTime = new Date(date)
      const endDateTime = new Date(date)
      startDateTime.setHours(Number(startHour))
      startDateTime.setMinutes(Number(startMinute))
      endDateTime.setHours(Number(endHour) === 24 ? 0 : Number(endHour))
      endDateTime.setMinutes(Number(endMinute))
      if (endHour === '24' && endMinute === '00') {
        endDateTime.setDate(date.getDate() + 1)
      }
      await adminGatewayClient.schedule.createSchedule
        .mutate({
          doctorId: doctorId,
          from: startDateTime,
          to: endDateTime,
          isRepeated,
        })
        .then((res) => {
          handleClose('close')
          fetchAvailableSlot()
        })
        .catch((err) => {
          alert(err.message)
        })
    }
  }

  const handleClose = (reason: string) => {
    if (reason === 'close') {
      setOpenCreateForm(false)
      setDate(null)
      setStartHour('')
      setStartMinute('')
      setEndHour('')
      setEndMinute('')
      setIsRepeated(false)
      setEndHourOptions([])
      setEndMinuteOptions([])
    }
  }

  const doctorOptionText = (choice: {
    prefix: string
    firstname: string
    lastname: string
  }) => {
    return `${choice.prefix} ${choice.firstname} ${choice.lastname}`
  }

  return (
    <div>
      <FormProvider {...form}>
        <BootstrapDialog
          open={openCreateForm}
          onClose={(e, reason) => handleClose(reason)}
        >
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogTitle>Create available slot</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12}>
                  <ReferenceInput
                    source="doctorId"
                    reference="doctor"
                    sort={{ field: 'id', order: 'ASC' }}
                  >
                    <CustomAutoCompleteInput
                      optionText={doctorOptionText}
                      filterToQuery={handleFilterFullnameQuery}
                      label="Doctor"
                      helperText={false}
                      disableClearable={false}
                      sx={{ marginTop: '8px' }}
                      isRequired
                      validate={required()}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="date"
                    label="Date"
                    type="date"
                    fullWidth
                    value={date ? date.toISOString().slice(0, 10) : ''}
                    sx={{ '& legend': { maxWidth: '100% !important' } }}
                    onChange={handleDateChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    id="start-time-label"
                    sx={{ marginBottom: '0.5rem' }}
                  >
                    Start time
                  </InputLabel>
                  <Box
                    gap="1rem"
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="start-hour-label">hour*</InputLabel>
                      <Select
                        labelId="start-hour-label"
                        id="start-hour-select"
                        name="start-hour"
                        value={startHour}
                        label="hour*"
                        sx={{ '& legend': { maxWidth: '100% !important' } }}
                        onChange={handleStartTimeChange}
                        required
                      >
                        {timeOption.hours.slice(0, -1).map((hour) => (
                          <MenuItem key={`start_hour_${hour}`} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="start-minute-label">minute*</InputLabel>
                      <Select
                        labelId="start-minute-label"
                        id="start-minute-select"
                        name="start-minute"
                        value={startMinute}
                        label="minute*"
                        sx={{ '& legend': { maxWidth: '100% !important' } }}
                        onChange={handleStartTimeChange}
                        disabled={!startHour}
                        required
                      >
                        {timeOption.minutes.map((minute) => (
                          <MenuItem
                            key={`start_minute_${minute}`}
                            value={minute}
                          >
                            {minute}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    id="end-time-label"
                    sx={{ marginBottom: '0.5rem' }}
                  >
                    End time
                  </InputLabel>
                  <Box
                    gap="1rem"
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="end-hour-label">hour*</InputLabel>
                      <Select
                        labelId="end-hour-label"
                        id="end-hour-select"
                        name="end-hour"
                        value={endHour}
                        label="hour*"
                        sx={{ '& legend': { maxWidth: '100% !important' } }}
                        onChange={handleEndTimeChange}
                        disabled={!startMinute}
                        required
                      >
                        {endHourOptions.map((hour) => (
                          <MenuItem key={`end_hour_${hour}`} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="end-minute-label">minute*</InputLabel>
                      <Select
                        labelId="end-minute-label"
                        id="end-minute-select"
                        name="end-minute"
                        value={endMinute}
                        label="minute*"
                        sx={{ '& legend': { maxWidth: '100% !important' } }}
                        onChange={handleEndTimeChange}
                        disabled={!endHour}
                        required
                      >
                        {endMinuteOptions.map((minute) => (
                          <MenuItem key={`end_minute_${minute}`} value={minute}>
                            {minute}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Typography
                    style={{ color: '#D22B2B', fontSize: '1rem', margin: 0 }}
                  >
                    * Hour 24th is 0th on the next day
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DialogContentText>Repeat (Weekly)</DialogContentText>
                    <IOSSwitch
                      checked={isRepeated}
                      onChange={handleIsRepeatedChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose('close')}>Cancel</Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </FormProvider>
    </div>
  )
}
