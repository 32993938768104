import { z } from 'zod'
import { ArticlePictureCreateInputObjectSchema } from './objects/ArticlePictureCreateInput.schema'
import { ArticlePictureUncheckedCreateInputObjectSchema } from './objects/ArticlePictureUncheckedCreateInput.schema'

export const ArticlePictureCreateOneSchema = z.object({
  data: z.union([
    ArticlePictureCreateInputObjectSchema,
    ArticlePictureUncheckedCreateInputObjectSchema,
  ]),
})
