import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumGenderNullableFilterObjectSchema } from './EnumGenderNullableFilter.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { AcceptedConsentListRelationFilterObjectSchema } from './AcceptedConsentListRelationFilter.schema'
import { KycDataRelationFilterObjectSchema } from './KycDataRelationFilter.schema'
import { KycDataWhereInputObjectSchema } from './KycDataWhereInput.schema'
import { HealthInfoRelationFilterObjectSchema } from './HealthInfoRelationFilter.schema'
import { HealthInfoWhereInputObjectSchema } from './HealthInfoWhereInput.schema'
import { NotificationPatientListRelationFilterObjectSchema } from './NotificationPatientListRelationFilter.schema'
import { AddressListRelationFilterObjectSchema } from './AddressListRelationFilter.schema'
import { IdentityListRelationFilterObjectSchema } from './IdentityListRelationFilter.schema'
import { CoinTransactionListRelationFilterObjectSchema } from './CoinTransactionListRelationFilter.schema'
import { MyReviewListRelationFilterObjectSchema } from './MyReviewListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => PatientWhereInputObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => PatientWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => PatientWhereInputObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    prefix: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    firstname: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    lastname: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    nickname: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    gender: z
      .union([
        z.lazy(() => EnumGenderNullableFilterObjectSchema),
        z.lazy(() => GenderSchema),
      ])
      .optional()
      .nullable(),
    birthDate: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    picture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    isTest: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    registered: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    drugAllergy: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    receiveNotifications: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    activityNotifications: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    chatNotifications: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    orderNotifications: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    omiseCustomerId: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    freshchatUserId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    freshchatRestoreId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    coin: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    v2PatientId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    acceptedConsents: z
      .lazy(() => AcceptedConsentListRelationFilterObjectSchema)
      .optional(),
    kycData: z
      .union([
        z.lazy(() => KycDataRelationFilterObjectSchema),
        z.lazy(() => KycDataWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    healthCheckUpInfo: z
      .union([
        z.lazy(() => HealthInfoRelationFilterObjectSchema),
        z.lazy(() => HealthInfoWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    notifications: z
      .lazy(() => NotificationPatientListRelationFilterObjectSchema)
      .optional(),
    address: z.lazy(() => AddressListRelationFilterObjectSchema).optional(),
    identities: z.lazy(() => IdentityListRelationFilterObjectSchema).optional(),
    coinTransactions: z
      .lazy(() => CoinTransactionListRelationFilterObjectSchema)
      .optional(),
    myReviews: z.lazy(() => MyReviewListRelationFilterObjectSchema).optional(),
  })
  .strict()

export const PatientWhereInputObjectSchema = Schema
