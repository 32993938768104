import { z } from 'zod'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponCreateWithoutGroupInputObjectSchema } from './CouponCreateWithoutGroupInput.schema'
import { CouponUncheckedCreateWithoutGroupInputObjectSchema } from './CouponUncheckedCreateWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponCreateOrConnectWithoutGroupInput> = z
  .object({
    where: z.lazy(() => CouponWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CouponCreateWithoutGroupInputObjectSchema),
      z.lazy(() => CouponUncheckedCreateWithoutGroupInputObjectSchema),
    ]),
  })
  .strict()

export const CouponCreateOrConnectWithoutGroupInputObjectSchema = Schema
