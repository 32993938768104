import { z } from 'zod'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundCreateWithoutProcedureItemsInputObjectSchema } from './RefundCreateWithoutProcedureItemsInput.schema'
import { RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCreateOrConnectWithoutProcedureItemsInput> =
  z
    .object({
      where: z.lazy(() => RefundWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => RefundCreateWithoutProcedureItemsInputObjectSchema),
        z.lazy(
          () => RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const RefundCreateOrConnectWithoutProcedureItemsInputObjectSchema =
  Schema
