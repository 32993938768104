import { z } from 'zod'
import { WarehouseWhereInputObjectSchema } from './WarehouseWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseRelationFilter> = z
  .object({
    is: z.lazy(() => WarehouseWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => WarehouseWhereInputObjectSchema).optional(),
  })
  .strict()

export const WarehouseRelationFilterObjectSchema = Schema
