import { z } from 'zod'
import { OrderCreateWithoutProcedureItemsInputObjectSchema } from './OrderCreateWithoutProcedureItemsInput.schema'
import { OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProcedureItemsInput.schema'
import { OrderCreateOrConnectWithoutProcedureItemsInputObjectSchema } from './OrderCreateOrConnectWithoutProcedureItemsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutProcedureItemsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutProcedureItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const OrderCreateNestedOneWithoutProcedureItemsInputObjectSchema = Schema
