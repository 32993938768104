import { z } from 'zod'
import { DiagnosisCountOutputTypeSelectObjectSchema } from './DiagnosisCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => DiagnosisCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const DiagnosisCountOutputTypeArgsObjectSchema = Schema
