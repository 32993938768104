import { FileValue } from '../../components/types'

export const transformArticle = async (form: {
  pictures: FileValue[]
  coverPicture: FileValue
}) => {
  const { pictures, coverPicture } = form
  const result: { [key: string]: unknown } = form

  if (coverPicture?.uploadPromise) {
    const url = await coverPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.coverPicture = {
        ...coverPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }

  const formPictures = [] as unknown[]
  if (pictures) {
    for (const pic of pictures) {
      if (pic.uploadPromise) {
        const url = await pic.uploadPromise
        if (url && !Array.isArray(url)) {
          formPictures.push({
            ...pic,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload failed')
        }
      } else {
        formPictures.push(pic)
      }
    }
  }
  result.pictures = formPictures

  return result
}
