import { z } from 'zod'
import { ProductItemSelectObjectSchema } from './ProductItemSelect.schema'
import { ProductItemIncludeObjectSchema } from './ProductItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemArgs> = z
  .object({
    select: z.lazy(() => ProductItemSelectObjectSchema).optional(),
    include: z.lazy(() => ProductItemIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductItemArgsObjectSchema = Schema
