import { z } from 'zod'
import { VariantUpdateWithoutPicturesInputObjectSchema } from './VariantUpdateWithoutPicturesInput.schema'
import { VariantUncheckedUpdateWithoutPicturesInputObjectSchema } from './VariantUncheckedUpdateWithoutPicturesInput.schema'
import { VariantCreateWithoutPicturesInputObjectSchema } from './VariantCreateWithoutPicturesInput.schema'
import { VariantUncheckedCreateWithoutPicturesInputObjectSchema } from './VariantUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => VariantUpdateWithoutPicturesInputObjectSchema),
      z.lazy(() => VariantUncheckedUpdateWithoutPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => VariantCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => VariantUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const VariantUpsertWithoutPicturesInputObjectSchema = Schema
