import { z } from 'zod'
import { HomeBannerOrderByWithRelationInputObjectSchema } from './objects/HomeBannerOrderByWithRelationInput.schema'
import { HomeBannerWhereInputObjectSchema } from './objects/HomeBannerWhereInput.schema'
import { HomeBannerWhereUniqueInputObjectSchema } from './objects/HomeBannerWhereUniqueInput.schema'
import { HomeBannerScalarFieldEnumSchema } from './enums/HomeBannerScalarFieldEnum.schema'

export const HomeBannerFindManySchema = z.object({
  orderBy: z
    .union([
      HomeBannerOrderByWithRelationInputObjectSchema,
      HomeBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HomeBannerWhereInputObjectSchema.optional(),
  cursor: HomeBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(HomeBannerScalarFieldEnumSchema).optional(),
})
