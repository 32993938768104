import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      id: z.number().optional(),
      codePlain: z.string(),
      term: z.string(),
    })
    .strict()

export const DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
