import { z } from 'zod'
import { ScheduleCreateWithoutAppointmentInputObjectSchema } from './ScheduleCreateWithoutAppointmentInput.schema'
import { ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedCreateWithoutAppointmentInput.schema'
import { ScheduleCreateOrConnectWithoutAppointmentInputObjectSchema } from './ScheduleCreateOrConnectWithoutAppointmentInput.schema'
import { ScheduleWhereUniqueInputObjectSchema } from './ScheduleWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleCreateNestedOneWithoutAppointmentInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ScheduleCreateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ScheduleCreateOrConnectWithoutAppointmentInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ScheduleWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ScheduleCreateNestedOneWithoutAppointmentInputObjectSchema = Schema
