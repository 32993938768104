import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ShippingProviderCountOrderByAggregateInputObjectSchema } from './ShippingProviderCountOrderByAggregateInput.schema'
import { ShippingProviderAvgOrderByAggregateInputObjectSchema } from './ShippingProviderAvgOrderByAggregateInput.schema'
import { ShippingProviderMaxOrderByAggregateInputObjectSchema } from './ShippingProviderMaxOrderByAggregateInput.schema'
import { ShippingProviderMinOrderByAggregateInputObjectSchema } from './ShippingProviderMinOrderByAggregateInput.schema'
import { ShippingProviderSumOrderByAggregateInputObjectSchema } from './ShippingProviderSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    detail: z.lazy(() => SortOrderSchema).optional(),
    moreDetail: z.lazy(() => SortOrderSchema).optional(),
    shippingExpectation: z.lazy(() => SortOrderSchema).optional(),
    trackingUrl: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    price: z.lazy(() => SortOrderSchema).optional(),
    freeShippingMinimumPrice: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ShippingProviderCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ShippingProviderAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ShippingProviderMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ShippingProviderMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ShippingProviderSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ShippingProviderOrderByWithAggregationInputObjectSchema = Schema
