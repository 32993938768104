import { z } from 'zod'
import { ExpressShippingAreaOrderByWithRelationInputObjectSchema } from './objects/ExpressShippingAreaOrderByWithRelationInput.schema'
import { ExpressShippingAreaWhereInputObjectSchema } from './objects/ExpressShippingAreaWhereInput.schema'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './objects/ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaScalarFieldEnumSchema } from './enums/ExpressShippingAreaScalarFieldEnum.schema'

export const ExpressShippingAreaFindFirstSchema = z.object({
  orderBy: z
    .union([
      ExpressShippingAreaOrderByWithRelationInputObjectSchema,
      ExpressShippingAreaOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ExpressShippingAreaWhereInputObjectSchema.optional(),
  cursor: ExpressShippingAreaWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ExpressShippingAreaScalarFieldEnumSchema).optional(),
})
