import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './objects/CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateInputObjectSchema } from './objects/CaseSummaryCreateInput.schema'
import { CaseSummaryUncheckedCreateInputObjectSchema } from './objects/CaseSummaryUncheckedCreateInput.schema'
import { CaseSummaryUpdateInputObjectSchema } from './objects/CaseSummaryUpdateInput.schema'
import { CaseSummaryUncheckedUpdateInputObjectSchema } from './objects/CaseSummaryUncheckedUpdateInput.schema'

export const CaseSummaryUpsertSchema = z.object({
  where: CaseSummaryWhereUniqueInputObjectSchema,
  create: z.union([
    CaseSummaryCreateInputObjectSchema,
    CaseSummaryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CaseSummaryUpdateInputObjectSchema,
    CaseSummaryUncheckedUpdateInputObjectSchema,
  ]),
})
