import { Publish as PublishIcon } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Button, ButtonProps } from 'react-admin'

export const CustomButton = (props: CustomButtonProps) => {
  const {
    onClick,
    label = 'ra.action.import',
    icon = defaultIcon,
    ...rest
  } = props
  const handleClick = useCallback(
    (event: any) => {
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    [onClick],
  )

  return (
    <Button onClick={handleClick} label={label} {...sanitizeRestProps(rest)}>
      {icon}
    </Button>
  )
}

const defaultIcon = <PublishIcon />

const sanitizeRestProps = ({
  resource,
  ...rest
}: Omit<CustomButtonProps, 'label'>) => rest

interface Props {
  icon?: JSX.Element
  label?: string
  onClick?: (e: Event) => void
  disabled?: boolean
}

export type CustomButtonProps = Props & ButtonProps

CustomButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
}
