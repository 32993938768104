import { z } from 'zod'
import { ProductUpdateWithoutCategoriesInputObjectSchema } from './ProductUpdateWithoutCategoriesInput.schema'
import { ProductUncheckedUpdateWithoutCategoriesInputObjectSchema } from './ProductUncheckedUpdateWithoutCategoriesInput.schema'
import { ProductCreateWithoutCategoriesInputObjectSchema } from './ProductCreateWithoutCategoriesInput.schema'
import { ProductUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProductUncheckedCreateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutCategoriesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductUpdateWithoutCategoriesInputObjectSchema),
      z.lazy(() => ProductUncheckedUpdateWithoutCategoriesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProductCreateWithoutCategoriesInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutCategoriesInputObjectSchema),
    ]),
  })
  .strict()

export const ProductUpsertWithoutCategoriesInputObjectSchema = Schema
