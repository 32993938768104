import { z } from 'zod'
import { CategoryWhereUniqueInputObjectSchema } from './CategoryWhereUniqueInput.schema'
import { CategoryCreateWithoutProceduresInputObjectSchema } from './CategoryCreateWithoutProceduresInput.schema'
import { CategoryUncheckedCreateWithoutProceduresInputObjectSchema } from './CategoryUncheckedCreateWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryCreateOrConnectWithoutProceduresInput> =
  z
    .object({
      where: z.lazy(() => CategoryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CategoryCreateWithoutProceduresInputObjectSchema),
        z.lazy(() => CategoryUncheckedCreateWithoutProceduresInputObjectSchema),
      ]),
    })
    .strict()

export const CategoryCreateOrConnectWithoutProceduresInputObjectSchema = Schema
