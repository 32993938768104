import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { WeekdaySchema } from '../enums/Weekday.schema'
import { EnumWeekdayFieldUpdateOperationsInputObjectSchema } from './EnumWeekdayFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BranchUpdateOneRequiredWithoutOfficeHoursNestedInputObjectSchema } from './BranchUpdateOneRequiredWithoutOfficeHoursNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    weekday: z
      .union([
        z.lazy(() => WeekdaySchema),
        z.lazy(() => EnumWeekdayFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    openAt: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    closeAt: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    timezone: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    branch: z
      .lazy(
        () => BranchUpdateOneRequiredWithoutOfficeHoursNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const OfficeHourUpdateInputObjectSchema = Schema
