import { z } from 'zod'
import { CoinTransactionCreateWithoutPatientInputObjectSchema } from './CoinTransactionCreateWithoutPatientInput.schema'
import { CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedCreateWithoutPatientInput.schema'
import { CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema } from './CoinTransactionCreateOrConnectWithoutPatientInput.schema'
import { CoinTransactionUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './CoinTransactionUpsertWithWhereUniqueWithoutPatientInput.schema'
import { CoinTransactionCreateManyPatientInputEnvelopeObjectSchema } from './CoinTransactionCreateManyPatientInputEnvelope.schema'
import { CoinTransactionWhereUniqueInputObjectSchema } from './CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './CoinTransactionUpdateWithWhereUniqueWithoutPatientInput.schema'
import { CoinTransactionUpdateManyWithWhereWithoutPatientInputObjectSchema } from './CoinTransactionUpdateManyWithWhereWithoutPatientInput.schema'
import { CoinTransactionScalarWhereInputObjectSchema } from './CoinTransactionScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUpdateManyWithoutPatientNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema)
            .array(),
          z.lazy(
            () => CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CoinTransactionUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CoinTransactionCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
          z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CoinTransactionUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CoinTransactionUpdateManyWithWhereWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoinTransactionUpdateManyWithWhereWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CoinTransactionScalarWhereInputObjectSchema),
          z.lazy(() => CoinTransactionScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CoinTransactionUpdateManyWithoutPatientNestedInputObjectSchema =
  Schema
