import { z } from 'zod'
import { RefundWhereInputObjectSchema } from './RefundWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundListRelationFilter> = z
  .object({
    every: z.lazy(() => RefundWhereInputObjectSchema).optional(),
    some: z.lazy(() => RefundWhereInputObjectSchema).optional(),
    none: z.lazy(() => RefundWhereInputObjectSchema).optional(),
  })
  .strict()

export const RefundListRelationFilterObjectSchema = Schema
