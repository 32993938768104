import { z } from 'zod'

export const CoinTransactionScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'patientId',
  'orderId',
  'status',
  'type',
  'amount',
  'note',
])
