import { z } from 'zod'
import { OrderUpdateWithoutPaymentsInputObjectSchema } from './OrderUpdateWithoutPaymentsInput.schema'
import { OrderUncheckedUpdateWithoutPaymentsInputObjectSchema } from './OrderUncheckedUpdateWithoutPaymentsInput.schema'
import { OrderCreateWithoutPaymentsInputObjectSchema } from './OrderCreateWithoutPaymentsInput.schema'
import { OrderUncheckedCreateWithoutPaymentsInputObjectSchema } from './OrderUncheckedCreateWithoutPaymentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutPaymentsInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutPaymentsInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutPaymentsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutPaymentsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutPaymentsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutPaymentsInputObjectSchema = Schema
