import { z } from 'zod'
import { CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelopeObjectSchema } from './CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelope.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisScalarWhereInputObjectSchema } from './CaseSummaryDiagnosisScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpdateManyWithoutCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisUpdateManyWithoutCaseSummaryNestedInputObjectSchema =
  Schema
