import { z } from 'zod'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisUpdateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUpdateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedUpdateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
