import * as z from "zod"
import { CompleteOrder, RelatedOrderModel } from "./index"

export const HealthInfoModel = z.object({
  id: z.number().int(),
  orderId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  name: z.string(),
  gender: z.enum(['MALE', 'FEMALE']),
  birthdate: z.date(),
  drugAllergy: z.string().nullish(),
  congenitalDisease: z.string().nullish(),
  medication: z.string().nullish(),
  pregnancyState: z.enum(['NOT_PREGNANT', 'CURRENTLY_PREGNANT', 'CURRENTLY_BREAST_FEED', 'PLAN_TO_PREGNANT']).nullish(),
})

export interface CompleteHealthInfo extends z.infer<typeof HealthInfoModel> {
  order: CompleteOrder
}

/**
 * RelatedHealthInfoModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedHealthInfoModel: z.ZodSchema<CompleteHealthInfo> = z.lazy(() => HealthInfoModel.extend({
  order: RelatedOrderModel,
}))
