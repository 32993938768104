import { z } from 'zod'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentCreateWithoutFollowUpCaseSummaryInput.schema'
import { AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema } from './AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => AppointmentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => AppointmentCreateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            AppointmentUncheckedCreateWithoutFollowUpCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AppointmentCreateOrConnectWithoutFollowUpCaseSummaryInputObjectSchema =
  Schema
