import { z } from 'zod'
import { ShippingProviderArgsObjectSchema } from './ShippingProviderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaInclude> = z
  .object({
    shippingProvider: z
      .union([z.boolean(), z.lazy(() => ShippingProviderArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ExpressShippingAreaIncludeObjectSchema = Schema
