import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewCreateWithoutBrandInputObjectSchema } from './ProductReviewCreateWithoutBrandInput.schema'
import { ProductReviewUncheckedCreateWithoutBrandInputObjectSchema } from './ProductReviewUncheckedCreateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateOrConnectWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductReviewCreateWithoutBrandInputObjectSchema),
        z.lazy(() => ProductReviewUncheckedCreateWithoutBrandInputObjectSchema),
      ]),
    })
    .strict()

export const ProductReviewCreateOrConnectWithoutBrandInputObjectSchema = Schema
