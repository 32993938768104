import { z } from 'zod'
import {
  ConditionSettingModel,
  CouponGroupModel,
  DiscountSettingModel,
} from '../generated/zod'

const AllScopeDiscountSettingModel = DiscountSettingModel.pick({
  itemType: true,
  settingGroupId: true,
}).extend({
  scope: z.literal('ALL'),
})
const NumberTypeDiscountSettingModel = z.object({
  scope: z.enum(['ONLY', 'EXCEPT']),
  itemType: z.enum([
    'DOCTOR',
    'DOCTOR_CATEGORY',
    'VARIANT',
    'VARIANT_BRAND',
    'VARIANT_CATEGORY',
    'VARIANT_FLASHSALE',
    'VARIANT_NO_FLASHSALE',
    'PROCEDURE',
    'PROCEDURE_PARTNER',
    'PROCEDURE_CATEGORY',
    'PROCEDURE_FLASHSALE',
    'PROCEDURE_NO_FLASHSALE',
    'SHIPPING_PROVIDER',
  ]),
  items: z.array(z.number()),
  settingGroupId: z.string().nullish(),
})
const StringTypeDiscountSettingModel = z.object({
  scope: z.enum(['ONLY', 'EXCEPT']),
  itemType: z.enum(['SHIPPING_TYPE']),
  items: z.array(z.string()),
  settingGroupId: z.string().nullish(),
})

export const CouponGroupDiscountSettingModel = z.union([
  AllScopeDiscountSettingModel,
  NumberTypeDiscountSettingModel,
  StringTypeDiscountSettingModel,
])

const AllScopeConditionSettingModel = ConditionSettingModel.pick({
  itemType: true,
  settingGroupId: true,
}).extend({
  scope: z.literal('ALL'),
})
const NumberTypeConditionSettingModel = z.object({
  scope: z.enum(['ONLY', 'EXCEPT']),
  itemType: z.enum([
    'DOCTOR',
    'DOCTOR_CATEGORY',
    'VARIANT',
    'VARIANT_BRAND',
    'VARIANT_CATEGORY',
    'VARIANT_FLASHSALE',
    'VARIANT_NO_FLASHSALE',
    'PROCEDURE',
    'PROCEDURE_PARTNER',
    'PROCEDURE_CATEGORY',
    'PROCEDURE_FLASHSALE',
    'PROCEDURE_NO_FLASHSALE',
    'SHIPPING_PROVIDER',
  ]),
  items: z.array(z.number()),
  settingGroupId: z.string().nullish(),
})
const StringTypeConditionSettingModel = z.object({
  scope: z.enum(['ONLY', 'EXCEPT']),
  itemType: z.enum(['SHIPPING_TYPE']),
  items: z.array(z.string()),
  settingGroupId: z.string().nullish(),
})
export const CouponGroupConditionSettingModel = z.union([
  AllScopeConditionSettingModel,
  NumberTypeConditionSettingModel,
  StringTypeConditionSettingModel,
])

export const CouponGroupSchema = CouponGroupModel.omit({
  id: true,
  createdAt: true,
  v2CreatedBy: true,
  v2UpdatedBy: true,
}).extend({
  tags: z.array(
    z.object({
      th: z.string(),
      en: z.string(),
    }),
  ),
  discountSetting: z.array(CouponGroupDiscountSettingModel),
  conditionSetting: z.array(CouponGroupConditionSettingModel),
})
