import { z } from 'zod'
import { OrderNotificationUpdateInputObjectSchema } from './objects/OrderNotificationUpdateInput.schema'
import { OrderNotificationUncheckedUpdateInputObjectSchema } from './objects/OrderNotificationUncheckedUpdateInput.schema'
import { OrderNotificationWhereUniqueInputObjectSchema } from './objects/OrderNotificationWhereUniqueInput.schema'

export const OrderNotificationUpdateOneSchema = z.object({
  data: z.union([
    OrderNotificationUpdateInputObjectSchema,
    OrderNotificationUncheckedUpdateInputObjectSchema,
  ]),
  where: OrderNotificationWhereUniqueInputObjectSchema,
})
