import { z } from 'zod'
import { PaymentCreateWithoutOrderInputObjectSchema } from './PaymentCreateWithoutOrderInput.schema'
import { PaymentUncheckedCreateWithoutOrderInputObjectSchema } from './PaymentUncheckedCreateWithoutOrderInput.schema'
import { PaymentCreateOrConnectWithoutOrderInputObjectSchema } from './PaymentCreateOrConnectWithoutOrderInput.schema'
import { PaymentCreateManyOrderInputEnvelopeObjectSchema } from './PaymentCreateManyOrderInputEnvelope.schema'
import { PaymentWhereUniqueInputObjectSchema } from './PaymentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentCreateNestedManyWithoutOrderInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema),
        z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => PaymentCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => PaymentCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => PaymentWhereUniqueInputObjectSchema),
        z.lazy(() => PaymentWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const PaymentCreateNestedManyWithoutOrderInputObjectSchema = Schema
