import { z } from 'zod'
import { AddressOrderByWithRelationInputObjectSchema } from './objects/AddressOrderByWithRelationInput.schema'
import { AddressWhereInputObjectSchema } from './objects/AddressWhereInput.schema'
import { AddressWhereUniqueInputObjectSchema } from './objects/AddressWhereUniqueInput.schema'
import { AddressCountAggregateInputObjectSchema } from './objects/AddressCountAggregateInput.schema'
import { AddressMinAggregateInputObjectSchema } from './objects/AddressMinAggregateInput.schema'
import { AddressMaxAggregateInputObjectSchema } from './objects/AddressMaxAggregateInput.schema'
import { AddressAvgAggregateInputObjectSchema } from './objects/AddressAvgAggregateInput.schema'
import { AddressSumAggregateInputObjectSchema } from './objects/AddressSumAggregateInput.schema'

export const AddressAggregateSchema = z.object({
  orderBy: z
    .union([
      AddressOrderByWithRelationInputObjectSchema,
      AddressOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: AddressWhereInputObjectSchema.optional(),
  cursor: AddressWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), AddressCountAggregateInputObjectSchema])
    .optional(),
  _min: AddressMinAggregateInputObjectSchema.optional(),
  _max: AddressMaxAggregateInputObjectSchema.optional(),
  _avg: AddressAvgAggregateInputObjectSchema.optional(),
  _sum: AddressSumAggregateInputObjectSchema.optional(),
})
