import { z } from 'zod'
import { ProcedureReviewPictureSelectObjectSchema } from './ProcedureReviewPictureSelect.schema'
import { ProcedureReviewPictureIncludeObjectSchema } from './ProcedureReviewPictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureArgs> = z
  .object({
    select: z.lazy(() => ProcedureReviewPictureSelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureReviewPictureIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewPictureArgsObjectSchema = Schema
