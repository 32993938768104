import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductReviewPictureCountOrderByAggregateInputObjectSchema } from './ProductReviewPictureCountOrderByAggregateInput.schema'
import { ProductReviewPictureAvgOrderByAggregateInputObjectSchema } from './ProductReviewPictureAvgOrderByAggregateInput.schema'
import { ProductReviewPictureMaxOrderByAggregateInputObjectSchema } from './ProductReviewPictureMaxOrderByAggregateInput.schema'
import { ProductReviewPictureMinOrderByAggregateInputObjectSchema } from './ProductReviewPictureMinOrderByAggregateInput.schema'
import { ProductReviewPictureSumOrderByAggregateInputObjectSchema } from './ProductReviewPictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      url: z.lazy(() => SortOrderSchema).optional(),
      productReviewId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => ProductReviewPictureCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => ProductReviewPictureAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ProductReviewPictureMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ProductReviewPictureMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ProductReviewPictureSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProductReviewPictureOrderByWithAggregationInputObjectSchema =
  Schema
