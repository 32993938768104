import { z } from 'zod'
import { ProcedureBannerSelectObjectSchema } from './ProcedureBannerSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureBannerArgs> = z
  .object({
    select: z.lazy(() => ProcedureBannerSelectObjectSchema).optional(),
  })
  .strict()

export const ProcedureBannerArgsObjectSchema = Schema
