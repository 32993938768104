import { z } from 'zod'
import { IdentityWhereUniqueInputObjectSchema } from './IdentityWhereUniqueInput.schema'
import { IdentityUpdateWithoutPatientInputObjectSchema } from './IdentityUpdateWithoutPatientInput.schema'
import { IdentityUncheckedUpdateWithoutPatientInputObjectSchema } from './IdentityUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => IdentityWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => IdentityUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => IdentityUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const IdentityUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
