import { z } from 'zod'
import { ProcedureItemOrderByWithRelationInputObjectSchema } from './objects/ProcedureItemOrderByWithRelationInput.schema'
import { ProcedureItemWhereInputObjectSchema } from './objects/ProcedureItemWhereInput.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './objects/ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemScalarFieldEnumSchema } from './enums/ProcedureItemScalarFieldEnum.schema'

export const ProcedureItemFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedureItemOrderByWithRelationInputObjectSchema,
      ProcedureItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureItemWhereInputObjectSchema.optional(),
  cursor: ProcedureItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureItemScalarFieldEnumSchema).optional(),
})
