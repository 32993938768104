import { z } from 'zod'

export const HealthInfoScalarFieldEnumSchema = z.enum([
  'id',
  'orderId',
  'createdAt',
  'updatedAt',
  'name',
  'gender',
  'birthdate',
  'drugAllergy',
  'congenitalDisease',
  'medication',
  'pregnancyState',
])
