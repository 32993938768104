import { z } from 'zod'
import { VariantCreateNestedOneWithoutPicturesInputObjectSchema } from './VariantCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureCreateInput> = z
  .object({
    url: z.string(),
    rank: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutPicturesInputObjectSchema,
    ),
  })
  .strict()

export const VariantPictureCreateInputObjectSchema = Schema
