import { z } from 'zod'
import { DiscountSettingWhereInputObjectSchema } from './objects/DiscountSettingWhereInput.schema'
import { DiscountSettingOrderByWithAggregationInputObjectSchema } from './objects/DiscountSettingOrderByWithAggregationInput.schema'
import { DiscountSettingScalarWhereWithAggregatesInputObjectSchema } from './objects/DiscountSettingScalarWhereWithAggregatesInput.schema'
import { DiscountSettingScalarFieldEnumSchema } from './enums/DiscountSettingScalarFieldEnum.schema'

export const DiscountSettingGroupBySchema = z.object({
  where: DiscountSettingWhereInputObjectSchema.optional(),
  orderBy: z.union([
    DiscountSettingOrderByWithAggregationInputObjectSchema,
    DiscountSettingOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: DiscountSettingScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(DiscountSettingScalarFieldEnumSchema),
})
