import { z } from 'zod'
import { ArticleCreateWithoutPicturesInputObjectSchema } from './ArticleCreateWithoutPicturesInput.schema'
import { ArticleUncheckedCreateWithoutPicturesInputObjectSchema } from './ArticleUncheckedCreateWithoutPicturesInput.schema'
import { ArticleCreateOrConnectWithoutPicturesInputObjectSchema } from './ArticleCreateOrConnectWithoutPicturesInput.schema'
import { ArticleWhereUniqueInputObjectSchema } from './ArticleWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleCreateNestedOneWithoutPicturesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ArticleCreateWithoutPicturesInputObjectSchema),
        z.lazy(() => ArticleUncheckedCreateWithoutPicturesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ArticleCreateOrConnectWithoutPicturesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ArticleWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ArticleCreateNestedOneWithoutPicturesInputObjectSchema = Schema
