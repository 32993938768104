import { z } from 'zod'
import { VariantPictureUpdateInputObjectSchema } from './objects/VariantPictureUpdateInput.schema'
import { VariantPictureUncheckedUpdateInputObjectSchema } from './objects/VariantPictureUncheckedUpdateInput.schema'
import { VariantPictureWhereUniqueInputObjectSchema } from './objects/VariantPictureWhereUniqueInput.schema'

export const VariantPictureUpdateOneSchema = z.object({
  data: z.union([
    VariantPictureUpdateInputObjectSchema,
    VariantPictureUncheckedUpdateInputObjectSchema,
  ]),
  where: VariantPictureWhereUniqueInputObjectSchema,
})
