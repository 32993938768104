import { z } from 'zod'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountItemTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => DiscountItemTypeSchema).optional(),
    })
    .strict()

export const EnumDiscountItemTypeFieldUpdateOperationsInputObjectSchema = Schema
