import { z } from 'zod'
import { TeleconsultWhereInputObjectSchema } from './objects/TeleconsultWhereInput.schema'
import { TeleconsultOrderByWithAggregationInputObjectSchema } from './objects/TeleconsultOrderByWithAggregationInput.schema'
import { TeleconsultScalarWhereWithAggregatesInputObjectSchema } from './objects/TeleconsultScalarWhereWithAggregatesInput.schema'
import { TeleconsultScalarFieldEnumSchema } from './enums/TeleconsultScalarFieldEnum.schema'

export const TeleconsultGroupBySchema = z.object({
  where: TeleconsultWhereInputObjectSchema.optional(),
  orderBy: z.union([
    TeleconsultOrderByWithAggregationInputObjectSchema,
    TeleconsultOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: TeleconsultScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(TeleconsultScalarFieldEnumSchema),
})
