import { z } from 'zod'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductCreateWithoutPicturesInputObjectSchema } from './ProductCreateWithoutPicturesInput.schema'
import { ProductUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateOrConnectWithoutPicturesInput> = z
  .object({
    where: z.lazy(() => ProductWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ProductCreateOrConnectWithoutPicturesInputObjectSchema = Schema
