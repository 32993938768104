import * as z from "zod"
import { CompleteOrder, RelatedOrderModel } from "./index"

export const DoctorItemModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  caseId: z.number().int(),
  doctorFee: z.number(),
  doctorFeeDiscount: z.number(),
  consultingChannel: z.enum(['VIDEO', 'CHAT']),
  doctorName: z.string(),
  doctorId: z.number().int(),
  orderId: z.number().int(),
})

export interface CompleteDoctorItem extends z.infer<typeof DoctorItemModel> {
  order: CompleteOrder
}

/**
 * RelatedDoctorItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDoctorItemModel: z.ZodSchema<CompleteDoctorItem> = z.lazy(() => DoctorItemModel.extend({
  order: RelatedOrderModel,
}))
