import { z } from 'zod'
import { KycDataCreateWithoutRejectReasonInputObjectSchema } from './KycDataCreateWithoutRejectReasonInput.schema'
import { KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedCreateWithoutRejectReasonInput.schema'
import { KycDataCreateOrConnectWithoutRejectReasonInputObjectSchema } from './KycDataCreateOrConnectWithoutRejectReasonInput.schema'
import { KycDataUpsertWithoutRejectReasonInputObjectSchema } from './KycDataUpsertWithoutRejectReasonInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'
import { KycDataUpdateWithoutRejectReasonInputObjectSchema } from './KycDataUpdateWithoutRejectReasonInput.schema'
import { KycDataUncheckedUpdateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedUpdateWithoutRejectReasonInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataUpdateOneWithoutRejectReasonNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => KycDataCreateWithoutRejectReasonInputObjectSchema),
          z.lazy(
            () => KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => KycDataCreateOrConnectWithoutRejectReasonInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => KycDataUpsertWithoutRejectReasonInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => KycDataWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => KycDataUpdateWithoutRejectReasonInputObjectSchema),
          z.lazy(
            () => KycDataUncheckedUpdateWithoutRejectReasonInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const KycDataUpdateOneWithoutRejectReasonNestedInputObjectSchema = Schema
