import { z } from 'zod'
import { DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUpsertWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUpsertWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisWhereUniqueInputObjectSchema } from './DiagnosisWhereUniqueInput.schema'
import { DiagnosisUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => DiagnosisUpsertWithoutCaseSummaryDiagnosisInputObjectSchema)
        .optional(),
      connect: z.lazy(() => DiagnosisWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => DiagnosisUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const DiagnosisUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema =
  Schema
