import { Typography } from '@mui/material'
import { FC } from 'react'
import { TextFieldProps, useRecordContext } from 'react-admin'

const REGISTER_STATUS_COLOR: { [key: string]: string } = {
  SUBMITTED: '#ffc46d',
  APPROVED: '#64b95e',
  REJECTED: '#ff434e',
}

export const RegisterStatusField: FC<TextFieldProps> = (props) => {
  const record = useRecordContext()
  if (!record) return null

  return (
    <Typography
      {...props}
      style={{
        color: REGISTER_STATUS_COLOR[record.registerStatus] ?? '#000000',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      {record.registerStatus}
    </Typography>
  )
}
