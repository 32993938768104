import { z } from 'zod'
import { ProductReviewCountOutputTypeSelectObjectSchema } from './ProductReviewCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => ProductReviewCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const ProductReviewCountOutputTypeArgsObjectSchema = Schema
