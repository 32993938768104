import { z } from 'zod'
import { PaymentWhereUniqueInputObjectSchema } from './PaymentWhereUniqueInput.schema'
import { PaymentUpdateWithoutOrderInputObjectSchema } from './PaymentUpdateWithoutOrderInput.schema'
import { PaymentUncheckedUpdateWithoutOrderInputObjectSchema } from './PaymentUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentUpdateWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => PaymentWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => PaymentUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => PaymentUncheckedUpdateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const PaymentUpdateWithWhereUniqueWithoutOrderInputObjectSchema = Schema
