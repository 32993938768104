import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
  apiKey: import.meta.env.NX_FIREBASE_KEY,
  authDomain: import.meta.env.NX_AUTH_DOMAIN,
  projectId: import.meta.env.NX_PROJECT_ID,
})

export const firebaseAuth = getAuth()
firebaseAuth.tenantId = import.meta.env.NX_AUTH_TENANT_ID
// have to log this to make it update build
console.log(`current tenantId: ${firebaseAuth.tenantId}`)
// console.log(`current tenantId: ${import.meta.env.NX_AUTH_TENANT_ID}`)

export const firebaseAuthInitialized = new Promise((resolve) => {
  const unsub = onAuthStateChanged(firebaseAuth, (user) => {
    resolve(true)
    unsub()
  })
})

export const firebaseFirestore = getFirestore(firebaseApp)
