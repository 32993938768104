import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    quantity: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    priceAbsorbedBySkinX: z.literal(true).optional(),
    priceAbsorbedByPartner: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    flashSaleId: z.literal(true).optional(),
    expireDurationDay: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    refundId: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
    reviewId: z.literal(true).optional(),
  })
  .strict()

export const ProcedureItemSumAggregateInputObjectSchema = Schema
