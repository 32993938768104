import { z } from 'zod'
import { ProductItemCreateWithoutOrderInputObjectSchema } from './ProductItemCreateWithoutOrderInput.schema'
import { ProductItemUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemUncheckedCreateWithoutOrderInput.schema'
import { ProductItemCreateOrConnectWithoutOrderInputObjectSchema } from './ProductItemCreateOrConnectWithoutOrderInput.schema'
import { ProductItemCreateManyOrderInputEnvelopeObjectSchema } from './ProductItemCreateManyOrderInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemCreateNestedManyWithoutOrderInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProductItemCreateWithoutOrderInputObjectSchema),
        z.lazy(() => ProductItemCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => ProductItemUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => ProductItemUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => ProductItemCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => ProductItemCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => ProductItemCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
        z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const ProductItemCreateNestedManyWithoutOrderInputObjectSchema = Schema
