import { Grid, Typography } from '@mui/material'
import {
  UnitSchema,
  UsageSchema,
  UseFrequencySchema,
  UseTimeSchema,
  getUseTimeChoicesByUseFrequency,
} from '@ssch-backend/products'
import { ChangeEvent, FC } from 'react'
import { RaRecord, required, useLocaleState } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import {
  getProductUnitValueLabel,
  getProductUsageValueLabel,
  getProductUseFrequencyLabel,
  getProductUseTimeLabel,
} from '../../../enumLabel'

export const ProductUsageSecion: FC = () => {
  const [locale] = useLocaleState()
  const { watch, setValue } = useFormContext()

  const selectedUseFrequency = watch('useFrequency')

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        วิธีการใช้งาน
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormSelectInput
                label={'การใช้งาน'}
                source="usage"
                validate={required()}
                choices={Object.keys(UsageSchema.Enum).map((key: unknown) => {
                  return {
                    id: key,
                    name: getProductUsageValueLabel(
                      key as keyof typeof UsageSchema.Enum,
                    )[locale === 'th' ? 'th' : 'en'],
                  }
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumberInput
                label={'ปริมาณต่อครั้ง'}
                source="useAmount"
                validate={required()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormSelectInput
                label={'หน่วย'}
                source="useUnit"
                validate={required()}
                choices={Object.keys(UnitSchema.Enum).map((key: unknown) => {
                  return {
                    id: key,
                    name: getProductUnitValueLabel(
                      key as keyof typeof UnitSchema.Enum,
                    )[locale === 'th' ? 'th' : 'en'],
                  }
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormSelectInput
                label={'ความถี่ (ครั้ง)'}
                source="useFrequency"
                validate={required()}
                onChange={(e: ChangeEvent<HTMLInputElement> | RaRecord) => {
                  setValue('useFrequency', e.target.value)
                  setValue('useTime', null)
                }}
                choices={Object.keys(UseFrequencySchema.Enum).map(
                  (key: unknown) => {
                    return {
                      id: key,
                      name: getProductUseFrequencyLabel(
                        key as keyof typeof UseFrequencySchema.Enum,
                      )[locale === 'th' ? 'th' : 'en'],
                    }
                  },
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelectInput
                label={'ความถี่ (เวลา)'}
                source="useTime"
                validate={required()}
                disabled={!selectedUseFrequency}
                choices={getUseTimeChoicesByUseFrequency(
                  selectedUseFrequency,
                ).map((key: unknown) => {
                  return {
                    id: key,
                    name: getProductUseTimeLabel(
                      key as keyof typeof UseTimeSchema.Enum,
                    )[locale === 'th' ? 'th' : 'en'],
                  }
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="วิธีการใช้งาน / คำแนะนำ"
            source="instructionTr.th"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="วิธีการใช้งาน / คำแนะนำ (Eng.)"
            source="instructionTr.en"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput label="คำแนะนำสำหรับร้านยา" source="pharmacyNote" />
        </Grid>
      </Grid>
    </>
  )
}
