import { z } from 'zod'
import { UserFindManySchema } from '../findManyUser.schema'
import { BranchFindManySchema } from '../findManyBranch.schema'
import { CoverPictureFindManySchema } from '../findManyCoverPicture.schema'
import { SkuFindManySchema } from '../findManySku.schema'
import { ProcedureOrderFindManySchema } from '../findManyProcedureOrder.schema'
import { PartnerCountOutputTypeArgsObjectSchema } from './PartnerCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerInclude> = z
  .object({
    users: z.union([z.boolean(), z.lazy(() => UserFindManySchema)]).optional(),
    branches: z
      .union([z.boolean(), z.lazy(() => BranchFindManySchema)])
      .optional(),
    coverPictures: z
      .union([z.boolean(), z.lazy(() => CoverPictureFindManySchema)])
      .optional(),
    sku: z.union([z.boolean(), z.lazy(() => SkuFindManySchema)]).optional(),
    ProcedureOrder: z
      .union([z.boolean(), z.lazy(() => ProcedureOrderFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => PartnerCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const PartnerIncludeObjectSchema = Schema
