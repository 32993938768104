import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithoutProcedureInputObjectSchema } from './FlashsaleItemUpdateWithoutProcedureInput.schema'
import { FlashsaleItemUncheckedUpdateWithoutProcedureInputObjectSchema } from './FlashsaleItemUncheckedUpdateWithoutProcedureInput.schema'
import { FlashsaleItemCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateWithoutProcedureInput.schema'
import { FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => FlashsaleItemUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => FlashsaleItemCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
