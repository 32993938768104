import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUpdateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedUpdateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedUpdateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureCategoryUpdateWithoutCategoryInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedUpdateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema =
  Schema
