import { z } from 'zod'
import { CaseSummaryOrderByWithRelationInputObjectSchema } from './objects/CaseSummaryOrderByWithRelationInput.schema'
import { CaseSummaryWhereInputObjectSchema } from './objects/CaseSummaryWhereInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './objects/CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryScalarFieldEnumSchema } from './enums/CaseSummaryScalarFieldEnum.schema'

export const CaseSummaryFindFirstSchema = z.object({
  orderBy: z
    .union([
      CaseSummaryOrderByWithRelationInputObjectSchema,
      CaseSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CaseSummaryWhereInputObjectSchema.optional(),
  cursor: CaseSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CaseSummaryScalarFieldEnumSchema).optional(),
})
