import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { FlashsaleItemUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedCreateNestedManyWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateNestedManyWithoutVariantInput.schema'
import { VariantWarehouseUncheckedCreateNestedManyWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedCreateNestedManyWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.VariantUncheckedCreateWithoutPicturesInput> = z
  .object({
    id: z.number().optional(),
    sku: z.string(),
    productId: z.number(),
    active: z.boolean().optional(),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    size: z.number().optional().nullable(),
    unit: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    sellingUnit: z.lazy(() => UnitSchema),
    tagPrice: z.number().optional().nullable(),
    sellingPrice: z.number(),
    cost: z.number().optional(),
    rank: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    v2DrugItemId: z.number().optional().nullable(),
    canExpress: z.boolean().optional(),
    inventory: z.number().optional(),
    inventoryUpdatedAt: z.date().optional().nullable(),
    flashsaleItem: z
      .lazy(
        () =>
          FlashsaleItemUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
      )
      .optional(),
    productReviews: z
      .lazy(
        () =>
          ProductReviewUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
      )
      .optional(),
    variantWarehouses: z
      .lazy(
        () =>
          VariantWarehouseUncheckedCreateNestedManyWithoutVariantInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantUncheckedCreateWithoutPicturesInputObjectSchema = Schema
