import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'
import { NotificationRelationFilterObjectSchema } from './NotificationRelationFilter.schema'
import { NotificationWhereInputObjectSchema } from './NotificationWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => NotificationPatientWhereInputObjectSchema),
        z.lazy(() => NotificationPatientWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => NotificationPatientWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => NotificationPatientWhereInputObjectSchema),
        z.lazy(() => NotificationPatientWhereInputObjectSchema).array(),
      ])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    notificationId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    readAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
    notification: z
      .union([
        z.lazy(() => NotificationRelationFilterObjectSchema),
        z.lazy(() => NotificationWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NotificationPatientWhereInputObjectSchema = Schema
