import { z } from 'zod'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderCreateWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderCreateOrConnectWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => ProcedureOrderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureOrderCreateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureOrderCreateOrConnectWithoutPartnerInputObjectSchema =
  Schema
