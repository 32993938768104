import { z } from 'zod'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'
import { ProcedureReviewPictureFindManySchema } from '../findManyProcedureReviewPicture.schema'
import { ProcedureReviewCountOutputTypeArgsObjectSchema } from './ProcedureReviewCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewInclude> = z
  .object({
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProcedureReviewPictureFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureReviewCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureReviewIncludeObjectSchema = Schema
