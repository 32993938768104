import { ContentCopy, OpenInNew } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceOneField,
  TextField,
  useNotify,
} from 'react-admin'
import { CustomBooleanField } from '../../components/CustomField'
import { FilterListForm } from './list-section/filterlistForm'

const rowsPerPage = [5, 10, 20]

const ProductVariantListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}
export const ProductVariantList = () => {
  const notify = useNotify()
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={<ProductVariantListAction />}
      >
        <Datagrid size="small" rowClick="edit">
          <TextField label="ID" source="id" />
          <TextField label="SKU" source="sku" />
          <ReferenceOneField
            label="Product (en,th)"
            reference="product"
            target="id"
            source="productId"
            sortable={false}
          >
            <FunctionField
              render={({ nameTr }: { nameTr: { en: string; th: string } }) =>
                nameTr && [nameTr.en, `(${nameTr.th})`].join(' ')
              }
            />
          </ReferenceOneField>
          <FunctionField
            label="Label (en,th)"
            render={({
              descriptionTr,
            }: {
              descriptionTr: { en: string; th: string }
            }) =>
              descriptionTr &&
              [descriptionTr.en, `(${descriptionTr.th})`].join(' ')
            }
          />
          <FunctionField
            label="Size & Unit"
            render={(record: { size: number; unit: number }) =>
              record && [record.size, record.unit].join(' ')
            }
          />
          <NumberField source="tagPrice" />
          <NumberField label="Price" source="sellingPrice" />
          <NumberField source="cost" />
          <CustomBooleanField source="active" label="Active" />
          <CustomBooleanField source="canExpress" label="Express shipping" />
          <FunctionField
            label="Action"
            render={(record: { id: number; productId: number }) => (
              <>
                <IconButton
                  onClick={() => {
                    const appPatientDomain = import.meta.env
                      .NX_APP_PATIENT_DOMAIN
                    navigator.clipboard
                      .writeText(
                        `${appPatientDomain}/product/${record.productId}`,
                      )
                      .then(() => {
                        notify(`Copied to clipboard!`, {
                          type: 'success',
                        })
                      })
                      .catch((error) => {
                        console.error('Error copying to clipboard:', error)
                        notify(
                          'Failed to copy to clipboard. Please try again.',
                          {
                            type: 'success',
                          },
                        )
                      })
                  }}
                >
                  <ContentCopy />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(`/productVariant/${record.id}`, '_blank')
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
    </Box>
  )
}
