import { z } from 'zod'
import { HealthInfoWhereInputObjectSchema } from './objects/HealthInfoWhereInput.schema'
import { HealthInfoOrderByWithAggregationInputObjectSchema } from './objects/HealthInfoOrderByWithAggregationInput.schema'
import { HealthInfoScalarWhereWithAggregatesInputObjectSchema } from './objects/HealthInfoScalarWhereWithAggregatesInput.schema'
import { HealthInfoScalarFieldEnumSchema } from './enums/HealthInfoScalarFieldEnum.schema'

export const HealthInfoGroupBySchema = z.object({
  where: HealthInfoWhereInputObjectSchema.optional(),
  orderBy: z.union([
    HealthInfoOrderByWithAggregationInputObjectSchema,
    HealthInfoOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: HealthInfoScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(HealthInfoScalarFieldEnumSchema),
})
