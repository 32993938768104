import { z } from 'zod'
import { BrandBannerWhereUniqueInputObjectSchema } from './objects/BrandBannerWhereUniqueInput.schema'
import { BrandBannerCreateInputObjectSchema } from './objects/BrandBannerCreateInput.schema'
import { BrandBannerUncheckedCreateInputObjectSchema } from './objects/BrandBannerUncheckedCreateInput.schema'
import { BrandBannerUpdateInputObjectSchema } from './objects/BrandBannerUpdateInput.schema'
import { BrandBannerUncheckedUpdateInputObjectSchema } from './objects/BrandBannerUncheckedUpdateInput.schema'

export const BrandBannerUpsertSchema = z.object({
  where: BrandBannerWhereUniqueInputObjectSchema,
  create: z.union([
    BrandBannerCreateInputObjectSchema,
    BrandBannerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    BrandBannerUpdateInputObjectSchema,
    BrandBannerUncheckedUpdateInputObjectSchema,
  ]),
})
