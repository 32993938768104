import { z } from 'zod'
import { PartnerCreateWithoutCoverPicturesInputObjectSchema } from './PartnerCreateWithoutCoverPicturesInput.schema'
import { PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema } from './PartnerUncheckedCreateWithoutCoverPicturesInput.schema'
import { PartnerCreateOrConnectWithoutCoverPicturesInputObjectSchema } from './PartnerCreateOrConnectWithoutCoverPicturesInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutCoverPicturesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PartnerCreateWithoutCoverPicturesInputObjectSchema),
          z.lazy(
            () => PartnerUncheckedCreateWithoutCoverPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PartnerCreateOrConnectWithoutCoverPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const PartnerCreateNestedOneWithoutCoverPicturesInputObjectSchema =
  Schema
