import { z } from 'zod'
import { ProductCategoryUpdateInputObjectSchema } from './objects/ProductCategoryUpdateInput.schema'
import { ProductCategoryUncheckedUpdateInputObjectSchema } from './objects/ProductCategoryUncheckedUpdateInput.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './objects/ProductCategoryWhereUniqueInput.schema'

export const ProductCategoryUpdateOneSchema = z.object({
  data: z.union([
    ProductCategoryUpdateInputObjectSchema,
    ProductCategoryUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductCategoryWhereUniqueInputObjectSchema,
})
