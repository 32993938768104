import { z } from 'zod'
import { AcceptedConsentFindManySchema } from '../findManyAcceptedConsent.schema'
import { ConsentCountOutputTypeArgsObjectSchema } from './ConsentCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentSelect> = z
  .object({
    key: z.boolean().optional(),
    detail: z.boolean().optional(),
    rejectDetail: z.boolean().optional(),
    acceptedConsents: z
      .union([z.boolean(), z.lazy(() => AcceptedConsentFindManySchema)])
      .optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ConsentCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ConsentSelectObjectSchema = Schema
