import { z } from 'zod'
import { ProcedureOrderSelectObjectSchema } from './ProcedureOrderSelect.schema'
import { ProcedureOrderIncludeObjectSchema } from './ProcedureOrderInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderArgs> = z
  .object({
    select: z.lazy(() => ProcedureOrderSelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureOrderIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureOrderArgsObjectSchema = Schema
