import { z } from 'zod'
import { SegmentWhereUniqueInputObjectSchema } from './SegmentWhereUniqueInput.schema'
import { SegmentUpdateWithoutNotificationsInputObjectSchema } from './SegmentUpdateWithoutNotificationsInput.schema'
import { SegmentUncheckedUpdateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedUpdateWithoutNotificationsInput.schema'
import { SegmentCreateWithoutNotificationsInputObjectSchema } from './SegmentCreateWithoutNotificationsInput.schema'
import { SegmentUncheckedCreateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedCreateWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUpsertWithWhereUniqueWithoutNotificationsInput> =
  z
    .object({
      where: z.lazy(() => SegmentWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => SegmentUpdateWithoutNotificationsInputObjectSchema),
        z.lazy(
          () => SegmentUncheckedUpdateWithoutNotificationsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema),
        z.lazy(
          () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SegmentUpsertWithWhereUniqueWithoutNotificationsInputObjectSchema =
  Schema
