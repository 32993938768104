import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCountOutputTypeSelect> = z
  .object({
    segments: z.boolean().optional(),
    patients: z.boolean().optional(),
  })
  .strict()

export const NotificationCountOutputTypeSelectObjectSchema = Schema
