import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithoutProductInputObjectSchema } from './ProductReviewUpdateWithoutProductInput.schema'
import { ProductReviewUncheckedUpdateWithoutProductInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutProductInput.schema'
import { ProductReviewCreateWithoutProductInputObjectSchema } from './ProductReviewCreateWithoutProductInput.schema'
import { ProductReviewUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpsertWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductReviewUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductReviewCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewUpsertWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
