import { z } from 'zod'
import { CoverPictureWhereUniqueInputObjectSchema } from './CoverPictureWhereUniqueInput.schema'
import { CoverPictureUpdateWithoutPartnerInputObjectSchema } from './CoverPictureUpdateWithoutPartnerInput.schema'
import { CoverPictureUncheckedUpdateWithoutPartnerInputObjectSchema } from './CoverPictureUncheckedUpdateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureUpdateWithWhereUniqueWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CoverPictureUpdateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => CoverPictureUncheckedUpdateWithoutPartnerInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoverPictureUpdateWithWhereUniqueWithoutPartnerInputObjectSchema =
  Schema
