import { z } from 'zod'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { DoctorItemUncheckedCreateNestedOneWithoutOrderInputObjectSchema } from './DoctorItemUncheckedCreateNestedOneWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedCreateNestedManyWithoutOrderInput.schema'
import { ProductItemUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './ProductItemUncheckedCreateNestedManyWithoutOrderInput.schema'
import { ProcedureItemUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './ProcedureItemUncheckedCreateNestedManyWithoutOrderInput.schema'
import { CouponUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './CouponUncheckedCreateNestedManyWithoutOrderInput.schema'
import { PaymentUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './PaymentUncheckedCreateNestedManyWithoutOrderInput.schema'
import { RefundUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './RefundUncheckedCreateNestedManyWithoutOrderInput.schema'
import { DeliveryUncheckedCreateNestedManyWithoutOrderInputObjectSchema } from './DeliveryUncheckedCreateNestedManyWithoutOrderInput.schema'
import { HealthInfoUncheckedCreateNestedOneWithoutOrderInputObjectSchema } from './HealthInfoUncheckedCreateNestedOneWithoutOrderInput.schema'
import { ReceiptUncheckedCreateNestedOneWithoutOrderInputObjectSchema } from './ReceiptUncheckedCreateNestedOneWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUncheckedCreateWithoutShippingProviderInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      status: z.lazy(() => OrderStatusSchema).optional(),
      expiredAt: z.date().optional().nullable(),
      shouldExpiresAt: z.date().optional().nullable(),
      orderNumber: z.string(),
      patientId: z.number(),
      customerName: z.string(),
      customerTelNo: z.string().optional(),
      orderType: z.lazy(() => OrderTypeSchema),
      purchaseOrigin: z
        .lazy(() => PurchaseOriginSchema)
        .optional()
        .nullable(),
      purchaseRef: z.string().optional().nullable(),
      editedProductItemsAt: z.date().optional().nullable(),
      editedProductItemsBy: z.number().optional().nullable(),
      shippingFee: z.number().optional(),
      shippingFeeDiscount: z.number().optional(),
      shippingType: z
        .lazy(() => ShippingTypeSchema)
        .optional()
        .nullable(),
      recipient: z.string().optional().nullable(),
      shippingAddress: z.string().optional().nullable(),
      shippingTelNo: z.string().optional().nullable(),
      subDistrict: z.string().optional().nullable(),
      subDistrictKey: z.string().optional().nullable(),
      district: z.string().optional().nullable(),
      districtKey: z.string().optional().nullable(),
      province: z.string().optional().nullable(),
      provinceKey: z.string().optional().nullable(),
      postcode: z.string().optional().nullable(),
      isPaid: z.boolean().optional(),
      isHold: z.boolean().optional(),
      approvedBy: z.number().optional().nullable(),
      approvedAt: z.date().optional().nullable(),
      canceledBy: z.number().optional().nullable(),
      canceledAt: z.date().optional().nullable(),
      cancelChannel: z
        .lazy(() => CancelChannelSchema)
        .optional()
        .nullable(),
      noteToPatient: z.string().optional().nullable(),
      useCoin: z.number().optional(),
      v2InvoiceId: z.number().optional().nullable(),
      holdingNote: z.string().optional().nullable(),
      holdingBy: z.number().optional().nullable(),
      holdingAt: z.date().optional().nullable(),
      isDeclinedSuggestedItems: z.boolean().optional(),
      declinedSuggestedItemsReason: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .optional()
        .nullable(),
      doctorItem: z
        .lazy(
          () => DoctorItemUncheckedCreateNestedOneWithoutOrderInputObjectSchema,
        )
        .optional(),
      productItemsOriginal: z
        .lazy(
          () =>
            ProductItemOriginalUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      productItems: z
        .lazy(
          () =>
            ProductItemUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      procedureItems: z
        .lazy(
          () =>
            ProcedureItemUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      coupons: z
        .lazy(
          () => CouponUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      payments: z
        .lazy(
          () => PaymentUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      refunds: z
        .lazy(
          () => RefundUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      deliveries: z
        .lazy(
          () => DeliveryUncheckedCreateNestedManyWithoutOrderInputObjectSchema,
        )
        .optional(),
      healthInfo: z
        .lazy(
          () => HealthInfoUncheckedCreateNestedOneWithoutOrderInputObjectSchema,
        )
        .optional(),
      receipt: z
        .lazy(
          () => ReceiptUncheckedCreateNestedOneWithoutOrderInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const OrderUncheckedCreateWithoutShippingProviderInputObjectSchema =
  Schema
