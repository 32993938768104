import { FileValue } from '../../components/types'

export const transformProcedure = async (form: {
  pictures: FileValue[]
  priority: number | null
  expireAt: string | Date
}) => {
  const { pictures, priority } = form
  const result: { [key: string]: unknown } = form

  const formPictures = [] as unknown[]
  if (pictures) {
    for (const pic of pictures) {
      if (pic.uploadPromise) {
        const url = await pic.uploadPromise
        if (url && !Array.isArray(url)) {
          formPictures.push({
            ...pic,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload pictures failed')
        }
      } else {
        formPictures.push(pic)
      }
    }
  }
  result.pictures = formPictures
  result.priority = Number(priority) || 0

  return result
}
