import { z } from 'zod'
import { ProductItemTypeSchema } from '../enums/ProductItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    orderId: z.number(),
    onesignalMessageId: z.string(),
    orderItemType: z.lazy(() => ProductItemTypeSchema),
    title: z.string(),
    createdAt: z.date().optional(),
    sendAt: z.date().optional(),
    procedureId: z.number().optional().nullable(),
  })
  .strict()

export const OrderNotificationUncheckedCreateInputObjectSchema = Schema
