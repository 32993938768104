import { z } from 'zod'
import { VariantCreateManyProductInputObjectSchema } from './VariantCreateManyProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateManyProductInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => VariantCreateManyProductInputObjectSchema),
      z.lazy(() => VariantCreateManyProductInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const VariantCreateManyProductInputEnvelopeObjectSchema = Schema
