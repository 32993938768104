import { FileValue } from '../../components/types'

export const transformProductVariant = async (form: {
  pictures: FileValue[] | FileValue
  priority: number | null
}) => {
  const { pictures, priority } = form
  const result: { [key: string]: unknown } = form

  const formPictures = [] as unknown[]
  if (pictures) {
    if (Array.isArray(pictures)) {
      for (const pic of pictures) {
        if (pic.uploadPromise) {
          const url = await pic.uploadPromise
          if (url && !Array.isArray(url)) {
            formPictures.push({
              ...pic,
              gsPath: url.gsPath,
            })
          } else {
            throw new Error('Upload picture failed')
          }
        } else {
          formPictures.push(pic)
        }
      }
    } else {
      if (pictures?.uploadPromise) {
        const url = await pictures.uploadPromise
        if (url && !Array.isArray(url)) {
          formPictures.push({
            ...pictures,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload picture failed')
        }
      }
    }
  }
  result.pictures = formPictures

  result.priority = Number(priority) || 0

  return result
}
