import { z } from 'zod'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NullableEnumUseDurationUnitFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => UseDurationUnitSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumUseDurationUnitFieldUpdateOperationsInputObjectSchema =
  Schema
