import { z } from 'zod'
import { AcceptedConsentOrderByWithRelationInputObjectSchema } from './objects/AcceptedConsentOrderByWithRelationInput.schema'
import { AcceptedConsentWhereInputObjectSchema } from './objects/AcceptedConsentWhereInput.schema'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './objects/AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentCountAggregateInputObjectSchema } from './objects/AcceptedConsentCountAggregateInput.schema'
import { AcceptedConsentMinAggregateInputObjectSchema } from './objects/AcceptedConsentMinAggregateInput.schema'
import { AcceptedConsentMaxAggregateInputObjectSchema } from './objects/AcceptedConsentMaxAggregateInput.schema'
import { AcceptedConsentAvgAggregateInputObjectSchema } from './objects/AcceptedConsentAvgAggregateInput.schema'
import { AcceptedConsentSumAggregateInputObjectSchema } from './objects/AcceptedConsentSumAggregateInput.schema'

export const AcceptedConsentAggregateSchema = z.object({
  orderBy: z
    .union([
      AcceptedConsentOrderByWithRelationInputObjectSchema,
      AcceptedConsentOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: AcceptedConsentWhereInputObjectSchema.optional(),
  cursor: AcceptedConsentWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), AcceptedConsentCountAggregateInputObjectSchema])
    .optional(),
  _min: AcceptedConsentMinAggregateInputObjectSchema.optional(),
  _max: AcceptedConsentMaxAggregateInputObjectSchema.optional(),
  _avg: AcceptedConsentAvgAggregateInputObjectSchema.optional(),
  _sum: AcceptedConsentSumAggregateInputObjectSchema.optional(),
})
