import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantCountOrderByAggregateInputObjectSchema } from './VariantCountOrderByAggregateInput.schema'
import { VariantAvgOrderByAggregateInputObjectSchema } from './VariantAvgOrderByAggregateInput.schema'
import { VariantMaxOrderByAggregateInputObjectSchema } from './VariantMaxOrderByAggregateInput.schema'
import { VariantMinOrderByAggregateInputObjectSchema } from './VariantMinOrderByAggregateInput.schema'
import { VariantSumOrderByAggregateInputObjectSchema } from './VariantSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    sku: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    size: z.lazy(() => SortOrderSchema).optional(),
    unit: z.lazy(() => SortOrderSchema).optional(),
    sellingUnit: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    canExpress: z.lazy(() => SortOrderSchema).optional(),
    inventory: z.lazy(() => SortOrderSchema).optional(),
    inventoryUpdatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => VariantCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => VariantAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => VariantMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => VariantMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => VariantSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const VariantOrderByWithAggregationInputObjectSchema = Schema
