import { z } from 'zod'
import { SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUncheckedCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateOrConnectWithoutCaseSummaryInput.schema'
import { SuggestedProductItemCreateManyCaseSummaryInputEnvelopeObjectSchema } from './SuggestedProductItemCreateManyCaseSummaryInputEnvelope.schema'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './SuggestedProductItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemUncheckedCreateNestedManyWithoutCaseSummaryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            SuggestedProductItemCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const SuggestedProductItemUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema =
  Schema
