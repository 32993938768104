import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductVideoCountOrderByAggregateInputObjectSchema } from './ProductVideoCountOrderByAggregateInput.schema'
import { ProductVideoAvgOrderByAggregateInputObjectSchema } from './ProductVideoAvgOrderByAggregateInput.schema'
import { ProductVideoMaxOrderByAggregateInputObjectSchema } from './ProductVideoMaxOrderByAggregateInput.schema'
import { ProductVideoMinOrderByAggregateInputObjectSchema } from './ProductVideoMinOrderByAggregateInput.schema'
import { ProductVideoSumOrderByAggregateInputObjectSchema } from './ProductVideoSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    thumbnail: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductVideoCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductVideoAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductVideoMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductVideoMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductVideoSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductVideoOrderByWithAggregationInputObjectSchema = Schema
