import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateManyBrandInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    variantId: z.number(),
    productId: z.number(),
    overallRating: z.number(),
    qualityRating: z.number(),
    shippingRating: z.number(),
    packagingRating: z.number(),
    message: z.string().optional().nullable(),
    orderId: z.number(),
    patientId: z.number(),
    patientName: z.string(),
    isAnonymous: z.boolean(),
    priority: z.number(),
  })
  .strict()

export const ProductReviewCreateManyBrandInputObjectSchema = Schema
