import { z } from 'zod'
import { OrderUpdateWithoutCouponsInputObjectSchema } from './OrderUpdateWithoutCouponsInput.schema'
import { OrderUncheckedUpdateWithoutCouponsInputObjectSchema } from './OrderUncheckedUpdateWithoutCouponsInput.schema'
import { OrderCreateWithoutCouponsInputObjectSchema } from './OrderCreateWithoutCouponsInput.schema'
import { OrderUncheckedCreateWithoutCouponsInputObjectSchema } from './OrderUncheckedCreateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutCouponsInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutCouponsInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutCouponsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutCouponsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutCouponsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutCouponsInputObjectSchema = Schema
