import { z } from 'zod'
import { MyReviewWhereUniqueInputObjectSchema } from './MyReviewWhereUniqueInput.schema'
import { MyReviewUpdateWithoutPatientInputObjectSchema } from './MyReviewUpdateWithoutPatientInput.schema'
import { MyReviewUncheckedUpdateWithoutPatientInputObjectSchema } from './MyReviewUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => MyReviewUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => MyReviewUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const MyReviewUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
