import { z } from 'zod'
import {
  AppointmentEventSchema,
  NotificationActionTypeSchema,
  NotificationTypeSchema,
} from '../../generated/trpc/schemas'

export const CreateOneSignalNotificationSchema = z.object({
  title: z.string(),
  message: z.string(),
  actionType: z.lazy(() => NotificationActionTypeSchema).optional(),
  action: z.string().optional().nullable(),
  startAt: z.date().optional(),
  patientIds: z.number().array(),
  procedureId: z.number().nullish(),
  meta: z.record(z.any()).optional(),
  ttl: z.number().optional(),
  priority: z.enum(['HIGH', 'NORMAL']).default('NORMAL'),
})

export const CustomSendNotificationSchema =
  CreateOneSignalNotificationSchema.merge(
    z.object({
      type: z.lazy(() => NotificationTypeSchema),
      picture: z.string().optional().nullable(),
      isSavedToDB: z.boolean().default(true),
      appointmentId: z.number().optional(),
      appointmentEvent: z.lazy(() => AppointmentEventSchema).optional(),
    }),
  ).superRefine(({ message, isSavedToDB }, ctx) => {
    if (isSavedToDB && message.length > 190) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Message must be less than 190 characters',
      })
    }
  })
