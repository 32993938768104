import { z } from 'zod'
import { SuggestedProductItemOriginalCreateInputObjectSchema } from './objects/SuggestedProductItemOriginalCreateInput.schema'
import { SuggestedProductItemOriginalUncheckedCreateInputObjectSchema } from './objects/SuggestedProductItemOriginalUncheckedCreateInput.schema'

export const SuggestedProductItemOriginalCreateOneSchema = z.object({
  data: z.union([
    SuggestedProductItemOriginalCreateInputObjectSchema,
    SuggestedProductItemOriginalUncheckedCreateInputObjectSchema,
  ]),
})
