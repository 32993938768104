import { z } from 'zod'

export const CouponGroupScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'active',
  'prefix',
  'code',
  'type',
  'titleTr',
  'descriptionTr',
  'tags',
  'picture',
  'discount',
  'discountType',
  'minTotal',
  'maxDiscount',
  'startAt',
  'expireAt',
  'isPublic',
  'isOnlyNewPatient',
  'note',
  'quota',
  'quotaPerUser',
  'createdBy',
  'updatedBy',
  'v2CreatedBy',
  'v2UpdatedBy',
])
