import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CasePictureOrderByRelationAggregateInputObjectSchema } from './CasePictureOrderByRelationAggregateInput.schema'
import { AppointmentOrderByWithRelationInputObjectSchema } from './AppointmentOrderByWithRelationInput.schema'
import { TeleconsultOrderByWithRelationInputObjectSchema } from './TeleconsultOrderByWithRelationInput.schema'
import { TeleconsultOrderByRelationAggregateInputObjectSchema } from './TeleconsultOrderByRelationAggregateInput.schema'
import { CaseSummaryOrderByWithRelationInputObjectSchema } from './CaseSummaryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    symptom: z.lazy(() => SortOrderSchema).optional(),
    drugAllergy: z.lazy(() => SortOrderSchema).optional(),
    congenitalDisease: z.lazy(() => SortOrderSchema).optional(),
    symptomDuration: z.lazy(() => SortOrderSchema).optional(),
    symptomDurationUnit: z.lazy(() => SortOrderSchema).optional(),
    followupCaseId: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    consultingChannel: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    v2RecordId: z.lazy(() => SortOrderSchema).optional(),
    followupCase: z
      .lazy(() => CaseOrderByWithRelationInputObjectSchema)
      .optional(),
    previousCase: z
      .lazy(() => CaseOrderByWithRelationInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(() => CasePictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
    appointment: z
      .lazy(() => AppointmentOrderByWithRelationInputObjectSchema)
      .optional(),
    teleconsult: z
      .lazy(() => TeleconsultOrderByWithRelationInputObjectSchema)
      .optional(),
    missedCallTeleconsult: z
      .lazy(() => TeleconsultOrderByRelationAggregateInputObjectSchema)
      .optional(),
    caseSummary: z
      .lazy(() => CaseSummaryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const CaseOrderByWithRelationInputObjectSchema = Schema
