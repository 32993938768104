import { z } from 'zod'
import { SuggestedProductItemOriginalUpdateInputObjectSchema } from './objects/SuggestedProductItemOriginalUpdateInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateInputObjectSchema } from './objects/SuggestedProductItemOriginalUncheckedUpdateInput.schema'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereUniqueInput.schema'

export const SuggestedProductItemOriginalUpdateOneSchema = z.object({
  data: z.union([
    SuggestedProductItemOriginalUpdateInputObjectSchema,
    SuggestedProductItemOriginalUncheckedUpdateInputObjectSchema,
  ]),
  where: SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
})
