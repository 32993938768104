import { z } from 'zod'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountScopeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => DiscountScopeSchema).optional(),
  })
  .strict()

export const EnumDiscountScopeFieldUpdateOperationsInputObjectSchema = Schema
