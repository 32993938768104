import { z } from 'zod'
import { AcceptedConsentFindManySchema } from '../findManyAcceptedConsent.schema'
import { ConsentCountOutputTypeArgsObjectSchema } from './ConsentCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentInclude> = z
  .object({
    acceptedConsents: z
      .union([z.boolean(), z.lazy(() => AcceptedConsentFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ConsentCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ConsentIncludeObjectSchema = Schema
