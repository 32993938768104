import { z } from 'zod'
import { IdentitySelectObjectSchema } from './IdentitySelect.schema'
import { IdentityIncludeObjectSchema } from './IdentityInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityArgs> = z
  .object({
    select: z.lazy(() => IdentitySelectObjectSchema).optional(),
    include: z.lazy(() => IdentityIncludeObjectSchema).optional(),
  })
  .strict()

export const IdentityArgsObjectSchema = Schema
