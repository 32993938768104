import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteUser, RelatedUserModel, CompleteBranch, RelatedBranchModel, CompleteCoverPicture, RelatedCoverPictureModel, CompleteSku, RelatedSkuModel, CompleteProcedureOrder, RelatedProcedureOrderModel } from "./index"

export const PartnerModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  type: z.enum(['CLINIC', 'HOSPITAL']),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  logo: z.string(),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  website: z.string(),
  phone: z.string(),
  active: z.boolean(),
  isExpertise: z.boolean(),
  priority: z.number().int(),
  isSXEasy: z.boolean(),
  searchField: z.string(),
  taxId: z.string().nullish(),
  v2HospitalId: z.number().int().nullish(),
  v2StoreId: z.number().int().nullish(),
})

export const PartnerModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  type: z.enum(['CLINIC', 'HOSPITAL']),
  nameTr: jsonSchema,
  logo: z.string(),
  descriptionTr: jsonSchema,
  website: z.string(),
  phone: z.string(),
  active: z.boolean(),
  isExpertise: z.boolean(),
  priority: z.number().int(),
  isSXEasy: z.boolean(),
  searchField: z.string(),
  taxId: z.string().nullish(),
  v2HospitalId: z.number().int().nullish(),
  v2StoreId: z.number().int().nullish(),
})

export interface CompletePartner extends z.infer<typeof PartnerModel> {
  users: CompleteUser[]
  branches: CompleteBranch[]
  coverPictures: CompleteCoverPicture[]
  sku: CompleteSku[]
  ProcedureOrder: CompleteProcedureOrder[]
}

/**
 * RelatedPartnerModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPartnerModel: z.ZodSchema<CompletePartner> = z.lazy(() => PartnerModel.extend({
  users: RelatedUserModel.array(),
  branches: RelatedBranchModel.array(),
  coverPictures: RelatedCoverPictureModel.array(),
  sku: RelatedSkuModel.array(),
  ProcedureOrder: RelatedProcedureOrderModel.array(),
}))
