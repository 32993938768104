import { z } from 'zod'
import { WarehouseCreateNestedOneWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateNestedOneWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateWithoutVariantInput> = z
  .object({
    warehouse: z.lazy(
      () => WarehouseCreateNestedOneWithoutVariantWarehousesInputObjectSchema,
    ),
  })
  .strict()

export const VariantWarehouseCreateWithoutVariantInputObjectSchema = Schema
