import { z } from 'zod'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductCreateWithoutCategoriesInputObjectSchema } from './ProductCreateWithoutCategoriesInput.schema'
import { ProductUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProductUncheckedCreateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateOrConnectWithoutCategoriesInput> = z
  .object({
    where: z.lazy(() => ProductWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductCreateWithoutCategoriesInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutCategoriesInputObjectSchema),
    ]),
  })
  .strict()

export const ProductCreateOrConnectWithoutCategoriesInputObjectSchema = Schema
