import { Box, Grid, Typography } from '@mui/material'
import { ProcedureModel } from '@ssch-backend/procedures'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useEffect, useState } from 'react'
import {
  BooleanInput,
  OptionTextFunc,
  ReferenceInput,
  required,
  useChoicesContext,
  useLocaleState,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteInput,
  FormNumberInput,
} from '../../../components/FormInput/input'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { ProcedureDetailCard } from './procedureDetail'

const ProcedureModelWithProduct = ProcedureModel.extend({
  pictures: z
    .array(z.object({ src: z.string(), gsPath: z.string() }))
    .optional(),
})

export type ProcedureModelWithProduct = z.infer<
  typeof ProcedureModelWithProduct
>

const ProcedureAutoCompleteInput = ({
  disabled = false,
  setSelectedProcedure,
  optionTextProcedure,
  ...props
}: {
  disabled: boolean
  setSelectedProcedure: (Procedure: ProcedureModelWithProduct | null) => void
  optionTextProcedure: OptionTextFunc
}) => {
  const { selectedChoices } = useChoicesContext()

  useEffect(() => {
    if (selectedChoices[0]) {
      setSelectedProcedure(selectedChoices[0] as ProcedureModelWithProduct)
    }
  }, [selectedChoices, setSelectedProcedure])

  const filterToQuery = (textSearch: string) => {
    if (textSearch) {
      return {
        textSearch: { contains: textSearch.trim() },
      }
    }
    return { id: { in: [] } }
  }

  const handleProcedureChange = (id: number | undefined) => {
    if (!id) {
      setSelectedProcedure(null)
    }
  }

  return (
    <FormAutoCompleteInput
      {...props}
      label="resources.carouselItemProcedure.fields.procedure"
      fullWidth
      optionText={optionTextProcedure}
      debounce={100}
      filterToQuery={filterToQuery}
      validate={required()}
      parse={(id) => parseInt(id) || undefined}
      onChange={(id) => handleProcedureChange(id)}
      disabled={disabled}
    />
  )
}

export const ProcedureInfoSection: FC<{ title?: string }> = ({ title }) => {
  const [procedureData, setProcedureData] =
    useState<ProcedureModelWithProduct | null>(null)
  const [searchParams] = useSearchParams()
  const record = useRecordContext()
  const { setValue } = useFormContext()
  const notify = useNotify()
  const [locale] = useLocaleState()
  const translate = useTranslate()

  useEffect(() => {
    const carouselId = Number(searchParams.get('carouselId'))
    if (!isNaN(carouselId)) {
      setValue('carouselId', carouselId)
    }
  }, [searchParams, setValue])

  const handleSetSelectedProcedure = (
    Procedure: ProcedureModelWithProduct | null,
  ) => {
    setProcedureData(Procedure)
  }

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const active = e.target.checked
    try {
      await adminGatewayClient.carouselItemProcedure.setActive.mutate({
        id: Number(id),
        active,
      })
      notify(`Carousel item is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', !active)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const handleOptionTextProcedure: OptionTextFunc = (choice) => {
    return getTr(choice.nameTr, locale as Language)
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {title ||
            translate('resources.carouselItemProcedure.title.add_carousel')}
        </Typography>
        {record?.id && (
          <BooleanInput
            source="active"
            onChange={(e) => handleActiveChanged(record.id, e)}
          />
        )}
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ReferenceInput source="procedureId" reference="procedureSearch">
            <ProcedureAutoCompleteInput
              optionTextProcedure={handleOptionTextProcedure}
              setSelectedProcedure={handleSetSelectedProcedure}
              disabled={record?.id ? true : false}
            />
          </ReferenceInput>
          {procedureData && <ProcedureDetailCard Procedure={procedureData} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <FormNumberInput
              source="priority"
              defaultValue={0}
              min={0}
              step={1}
              inputProps={{ style: { textAlign: 'center' } }}
              validate={required()}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
