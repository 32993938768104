import { z } from 'zod'
import { BrandBannerCreateWithoutBrandInputObjectSchema } from './BrandBannerCreateWithoutBrandInput.schema'
import { BrandBannerUncheckedCreateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedCreateWithoutBrandInput.schema'
import { BrandBannerCreateOrConnectWithoutBrandInputObjectSchema } from './BrandBannerCreateOrConnectWithoutBrandInput.schema'
import { BrandBannerCreateManyBrandInputEnvelopeObjectSchema } from './BrandBannerCreateManyBrandInputEnvelope.schema'
import { BrandBannerWhereUniqueInputObjectSchema } from './BrandBannerWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerUncheckedCreateNestedManyWithoutBrandInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema),
          z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema).array(),
          z.lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema),
          z
            .lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => BrandBannerCreateOrConnectWithoutBrandInputObjectSchema),
          z
            .lazy(() => BrandBannerCreateOrConnectWithoutBrandInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => BrandBannerCreateManyBrandInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
          z.lazy(() => BrandBannerWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const BrandBannerUncheckedCreateNestedManyWithoutBrandInputObjectSchema =
  Schema
