import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    address: z.lazy(() => SortOrderSchema).optional(),
    subDistrict: z.lazy(() => SortOrderSchema).optional(),
    subDistrictKey: z.lazy(() => SortOrderSchema).optional(),
    district: z.lazy(() => SortOrderSchema).optional(),
    districtKey: z.lazy(() => SortOrderSchema).optional(),
    province: z.lazy(() => SortOrderSchema).optional(),
    provinceKey: z.lazy(() => SortOrderSchema).optional(),
    postcode: z.lazy(() => SortOrderSchema).optional(),
    googleMapUrl: z.lazy(() => SortOrderSchema).optional(),
    lat: z.lazy(() => SortOrderSchema).optional(),
    long: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    isExpertise: z.lazy(() => SortOrderSchema).optional(),
    remark: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    v2BranchId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const BranchMinOrderByAggregateInputObjectSchema = Schema
