import { z } from 'zod'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NullableEnumPregnancyStateFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => PregnancyStateSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumPregnancyStateFieldUpdateOperationsInputObjectSchema =
  Schema
