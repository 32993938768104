import { z } from 'zod'
import { SuggestedProductItemCreateManyCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateManyCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemCreateManyCaseSummaryInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () => SuggestedProductItemCreateManyCaseSummaryInputObjectSchema,
        ),
        z
          .lazy(
            () => SuggestedProductItemCreateManyCaseSummaryInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const SuggestedProductItemCreateManyCaseSummaryInputEnvelopeObjectSchema =
  Schema
