import { FC } from 'react'
import { Descendant } from 'slate'

export type LinkElement = { type: 'link'; url: string; children: Descendant[] }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Element: FC<any> = (props) => {
  const { attributes, children, element } = props
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Leaf: FC<any> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.italic) {
    children = <i>{children}</i>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkComponent = ({ attributes, children, element }: any) => {
  return (
    <a {...attributes} href={element.url}>
      {children}
    </a>
  )
}
