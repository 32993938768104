import { Typography } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormReferenceArrayInput } from './formReferenceArrayInput'

export const PartnerSection: FC = () => {
  const { watch } = useFormContext()
  const selectedDoctorPartners = watch('doctorPartners')
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Partners
      </Typography>
      <FormReferenceArrayInput
        source="doctorPartners"
        reference="partner"
        optionText="nameTr.th"
        label="คลินิก/โรงพยาบาล"
        filter={
          selectedDoctorPartners
            ? { id_notIn: selectedDoctorPartners }
            : undefined
        }
      />
    </>
  )
}
