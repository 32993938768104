import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutReceiptInputObjectSchema } from './OrderCreateWithoutReceiptInput.schema'
import { OrderUncheckedCreateWithoutReceiptInputObjectSchema } from './OrderUncheckedCreateWithoutReceiptInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutReceiptInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutReceiptInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutReceiptInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutReceiptInputObjectSchema = Schema
