import { z } from 'zod'
import { PartnerTypeSchema } from '../enums/PartnerType.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { BranchUncheckedCreateNestedManyWithoutPartnerInputObjectSchema } from './BranchUncheckedCreateNestedManyWithoutPartnerInput.schema'
import { CoverPictureUncheckedCreateNestedManyWithoutPartnerInputObjectSchema } from './CoverPictureUncheckedCreateNestedManyWithoutPartnerInput.schema'
import { SkuUncheckedCreateNestedManyWithoutPartnerInputObjectSchema } from './SkuUncheckedCreateNestedManyWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedCreateNestedManyWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedCreateNestedManyWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.PartnerUncheckedCreateWithoutUsersInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    type: z.lazy(() => PartnerTypeSchema),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    logo: z.string(),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    website: z.string(),
    phone: z.string(),
    active: z.boolean().optional(),
    isExpertise: z.boolean(),
    priority: z.number().optional(),
    isSXEasy: z.boolean().optional(),
    searchField: z.string(),
    taxId: z.string().optional().nullable(),
    v2HospitalId: z.number().optional().nullable(),
    v2StoreId: z.number().optional().nullable(),
    branches: z
      .lazy(
        () => BranchUncheckedCreateNestedManyWithoutPartnerInputObjectSchema,
      )
      .optional(),
    coverPictures: z
      .lazy(
        () =>
          CoverPictureUncheckedCreateNestedManyWithoutPartnerInputObjectSchema,
      )
      .optional(),
    sku: z
      .lazy(() => SkuUncheckedCreateNestedManyWithoutPartnerInputObjectSchema)
      .optional(),
    ProcedureOrder: z
      .lazy(
        () =>
          ProcedureOrderUncheckedCreateNestedManyWithoutPartnerInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const PartnerUncheckedCreateWithoutUsersInputObjectSchema = Schema
