import { z } from 'zod'
import { SuggestedProcedureItemWhereInputObjectSchema } from './objects/SuggestedProcedureItemWhereInput.schema'
import { SuggestedProcedureItemOrderByWithAggregationInputObjectSchema } from './objects/SuggestedProcedureItemOrderByWithAggregationInput.schema'
import { SuggestedProcedureItemScalarWhereWithAggregatesInputObjectSchema } from './objects/SuggestedProcedureItemScalarWhereWithAggregatesInput.schema'
import { SuggestedProcedureItemScalarFieldEnumSchema } from './enums/SuggestedProcedureItemScalarFieldEnum.schema'

export const SuggestedProcedureItemGroupBySchema = z.object({
  where: SuggestedProcedureItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    SuggestedProcedureItemOrderByWithAggregationInputObjectSchema,
    SuggestedProcedureItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    SuggestedProcedureItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(SuggestedProcedureItemScalarFieldEnumSchema),
})
