import { z } from 'zod'
import { OrderCreateWithoutHealthInfoInputObjectSchema } from './OrderCreateWithoutHealthInfoInput.schema'
import { OrderUncheckedCreateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedCreateWithoutHealthInfoInput.schema'
import { OrderCreateOrConnectWithoutHealthInfoInputObjectSchema } from './OrderCreateOrConnectWithoutHealthInfoInput.schema'
import { OrderUpsertWithoutHealthInfoInputObjectSchema } from './OrderUpsertWithoutHealthInfoInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutHealthInfoInputObjectSchema } from './OrderUpdateWithoutHealthInfoInput.schema'
import { OrderUncheckedUpdateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedUpdateWithoutHealthInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutHealthInfoNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutHealthInfoInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutHealthInfoInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutHealthInfoInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutHealthInfoInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutHealthInfoInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutHealthInfoInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutHealthInfoNestedInputObjectSchema =
  Schema
