import { z } from 'zod'
import { KycDataCreateInputObjectSchema } from './objects/KycDataCreateInput.schema'
import { KycDataUncheckedCreateInputObjectSchema } from './objects/KycDataUncheckedCreateInput.schema'

export const KycDataCreateOneSchema = z.object({
  data: z.union([
    KycDataCreateInputObjectSchema,
    KycDataUncheckedCreateInputObjectSchema,
  ]),
})
