import { z } from 'zod'
import { ArticleSelectObjectSchema } from './ArticleSelect.schema'
import { ArticleIncludeObjectSchema } from './ArticleInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleArgs> = z
  .object({
    select: z.lazy(() => ArticleSelectObjectSchema).optional(),
    include: z.lazy(() => ArticleIncludeObjectSchema).optional(),
  })
  .strict()

export const ArticleArgsObjectSchema = Schema
