import { z } from 'zod'

export const PaymentMethodSchema = z.enum([
  'NO_PAYMENT_REQUIRED',
  'CREDIT_CARD',
  'INSTALLMENT_SCB',
  'INSTALLMENT_KBANK',
  'INSTALLMENT_BAY',
  'INSTALLMENT_BBL',
  'INSTALLMENT_FIRST_CHOICE',
  'INSTALLMENT_KTC',
  'INSTALLMENT_UOB',
  'INSTALLMENT_TTB',
  'PROMPTPAY',
  'MOBILE_BANKING',
  'MOBILE_BANKING_SCB',
  'MOBILE_BANKING_KBANK',
  'MOBILE_BANKING_BBL',
  'MOBILE_BANKING_KTB',
  'MOBILE_BANKING_KMA',
])
