import { z } from 'zod'
import { CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateNestedOneWithoutSuggestedProductItemsOriginalInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseSummaryCreateNestedOneWithoutSuggestedProductItemsOriginalInputObjectSchema =
  Schema
