import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { PatientOrderByWithRelationInputObjectSchema } from './PatientOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    recipient: z.lazy(() => SortOrderSchema).optional(),
    address: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    province: z.lazy(() => SortOrderSchema).optional(),
    district: z.lazy(() => SortOrderSchema).optional(),
    subDistrict: z.lazy(() => SortOrderSchema).optional(),
    postCode: z.lazy(() => SortOrderSchema).optional(),
    provinceKey: z.lazy(() => SortOrderSchema).optional(),
    districtKey: z.lazy(() => SortOrderSchema).optional(),
    subDistrictKey: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    tag: z.lazy(() => SortOrderSchema).optional(),
    isDefault: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    patient: z
      .lazy(() => PatientOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const AddressOrderByWithRelationInputObjectSchema = Schema
