import { z } from 'zod'
import { SegmentCountOutputTypeSelectObjectSchema } from './SegmentCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => SegmentCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const SegmentCountOutputTypeArgsObjectSchema = Schema
