import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureCreateWithoutProductReviewInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      url: z.string(),
    })
    .strict()

export const ProductReviewPictureCreateWithoutProductReviewInputObjectSchema =
  Schema
