import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteOrder, RelatedOrderModel } from "./index"

export const CouponModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  couponId: z.number().int(),
  code: z.string(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  discount: z.number(),
  coin: z.number(),
  orderId: z.number().int(),
  v2CouponId: z.number().int().nullish(),
})

export const CouponModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  couponId: z.number().int(),
  code: z.string(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  descriptionTr: jsonSchema,
  discount: z.number(),
  coin: z.number(),
  orderId: z.number().int(),
  v2CouponId: z.number().int().nullish(),
})

export interface CompleteCoupon extends z.infer<typeof CouponModel> {
  order: CompleteOrder
}

/**
 * RelatedCouponModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCouponModel: z.ZodSchema<CompleteCoupon> = z.lazy(() => CouponModel.extend({
  order: RelatedOrderModel,
}))
