import { z } from 'zod'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { PatientUpdateOneRequiredWithoutNotificationsNestedInputObjectSchema } from './PatientUpdateOneRequiredWithoutNotificationsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUpdateWithoutNotificationInput> =
  z
    .object({
      readAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      patient: z
        .lazy(
          () =>
            PatientUpdateOneRequiredWithoutNotificationsNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const NotificationPatientUpdateWithoutNotificationInputObjectSchema =
  Schema
