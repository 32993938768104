import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { EnumAppointmentTypeFieldUpdateOperationsInputObjectSchema } from './EnumAppointmentTypeFieldUpdateOperationsInput.schema'
import { AppointmentStatusSchema } from '../enums/AppointmentStatus.schema'
import { EnumAppointmentStatusFieldUpdateOperationsInputObjectSchema } from './EnumAppointmentStatusFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { CaseUpdateOneRequiredWithoutAppointmentNestedInputObjectSchema } from './CaseUpdateOneRequiredWithoutAppointmentNestedInput.schema'
import { ScheduleUpdateOneWithoutAppointmentNestedInputObjectSchema } from './ScheduleUpdateOneWithoutAppointmentNestedInput.schema'
import { TeleconsultUpdateOneWithoutAppointmentNestedInputObjectSchema } from './TeleconsultUpdateOneWithoutAppointmentNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpdateWithoutFollowUpCaseSummaryInput> =
  z
    .object({
      doctorId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      patientId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      type: z
        .union([
          z.lazy(() => AppointmentTypeSchema),
          z.lazy(
            () => EnumAppointmentTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      status: z
        .union([
          z.lazy(() => AppointmentStatusSchema),
          z.lazy(
            () => EnumAppointmentStatusFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      appointmentAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      cancelAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      cancelDetail: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      case: z
        .lazy(
          () => CaseUpdateOneRequiredWithoutAppointmentNestedInputObjectSchema,
        )
        .optional(),
      schedule: z
        .lazy(() => ScheduleUpdateOneWithoutAppointmentNestedInputObjectSchema)
        .optional(),
      teleconsult: z
        .lazy(
          () => TeleconsultUpdateOneWithoutAppointmentNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const AppointmentUpdateWithoutFollowUpCaseSummaryInputObjectSchema =
  Schema
