import { Box, Card, CardContent, Divider, Grid } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BankSection } from './create-edit-section/bank'
import { CategorySection } from './create-edit-section/category'
import { CertificateSection } from './create-edit-section/certificate'
import { ClinicHospitalSection } from './create-edit-section/clinicHospital'
import { IdCardSection } from './create-edit-section/idCard'
import { LicenseSection } from './create-edit-section/license'
import { PartnerSection } from './create-edit-section/partner'
import { ProfileSection } from './create-edit-section/profile'
import { SpecialtyAndExpertiseSection } from './create-edit-section/specialtyAndExpertise'
import { transformDoctor } from './transform'

const DoctorCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctor" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const DoctorCreateForm = () => {
  return (
    <SimpleForm toolbar={<DoctorCreateToolbar />}>
      <ProfileSection />
      <Divider sx={{ width: '100%', mb: 3, borderColor: 'black' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <BankSection />
        </Grid>
        <Grid item xs={12} lg={4}>
          <IdCardSection />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LicenseSection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', mb: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <SpecialtyAndExpertiseSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ClinicHospitalSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PartnerSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CategorySection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', mb: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CertificateSection />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const DoctorCreate = () => (
  <CreateSaveRouteBack transform={transformDoctor}>
    <Card>
      <CardContent sx={{ p: 2 }}>
        <DoctorCreateForm />
      </CardContent>
    </Card>
  </CreateSaveRouteBack>
)
