import { z } from 'zod'
import { CasePictureWhereInputObjectSchema } from './objects/CasePictureWhereInput.schema'
import { CasePictureOrderByWithAggregationInputObjectSchema } from './objects/CasePictureOrderByWithAggregationInput.schema'
import { CasePictureScalarWhereWithAggregatesInputObjectSchema } from './objects/CasePictureScalarWhereWithAggregatesInput.schema'
import { CasePictureScalarFieldEnumSchema } from './enums/CasePictureScalarFieldEnum.schema'

export const CasePictureGroupBySchema = z.object({
  where: CasePictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CasePictureOrderByWithAggregationInputObjectSchema,
    CasePictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CasePictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CasePictureScalarFieldEnumSchema),
})
