import { z } from 'zod'
import { ProcedureReviewPictureUpdateInputObjectSchema } from './objects/ProcedureReviewPictureUpdateInput.schema'
import { ProcedureReviewPictureUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewPictureUncheckedUpdateInput.schema'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './objects/ProcedureReviewPictureWhereUniqueInput.schema'

export const ProcedureReviewPictureUpdateOneSchema = z.object({
  data: z.union([
    ProcedureReviewPictureUpdateInputObjectSchema,
    ProcedureReviewPictureUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureReviewPictureWhereUniqueInputObjectSchema,
})
