import { z } from 'zod'
import { DeliveryCreateInputObjectSchema } from './objects/DeliveryCreateInput.schema'
import { DeliveryUncheckedCreateInputObjectSchema } from './objects/DeliveryUncheckedCreateInput.schema'

export const DeliveryCreateOneSchema = z.object({
  data: z.union([
    DeliveryCreateInputObjectSchema,
    DeliveryUncheckedCreateInputObjectSchema,
  ]),
})
