import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    type: z.literal(true).optional(),
    status: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    scheduleId: z.literal(true).optional(),
    appointmentAt: z.literal(true).optional(),
    cancelAt: z.literal(true).optional(),
    cancelDetail: z.literal(true).optional(),
    teleconsultId: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
  })
  .strict()

export const AppointmentMinAggregateInputObjectSchema = Schema
