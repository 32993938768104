import { z } from 'zod'
import { DoctorItemSelectObjectSchema } from './DoctorItemSelect.schema'
import { DoctorItemIncludeObjectSchema } from './DoctorItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemArgs> = z
  .object({
    select: z.lazy(() => DoctorItemSelectObjectSchema).optional(),
    include: z.lazy(() => DoctorItemIncludeObjectSchema).optional(),
  })
  .strict()

export const DoctorItemArgsObjectSchema = Schema
