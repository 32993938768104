import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCountOutputTypeSelect> = z
  .object({
    caseSummaryDiagnosis: z.boolean().optional(),
    suggestedProductItems: z.boolean().optional(),
    suggestedProductItemsOriginal: z.boolean().optional(),
    suggestedProcedureItems: z.boolean().optional(),
  })
  .strict()

export const CaseSummaryCountOutputTypeSelectObjectSchema = Schema
