import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './objects/AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentCreateInputObjectSchema } from './objects/AcceptedConsentCreateInput.schema'
import { AcceptedConsentUncheckedCreateInputObjectSchema } from './objects/AcceptedConsentUncheckedCreateInput.schema'
import { AcceptedConsentUpdateInputObjectSchema } from './objects/AcceptedConsentUpdateInput.schema'
import { AcceptedConsentUncheckedUpdateInputObjectSchema } from './objects/AcceptedConsentUncheckedUpdateInput.schema'

export const AcceptedConsentUpsertSchema = z.object({
  where: AcceptedConsentWhereUniqueInputObjectSchema,
  create: z.union([
    AcceptedConsentCreateInputObjectSchema,
    AcceptedConsentUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    AcceptedConsentUpdateInputObjectSchema,
    AcceptedConsentUncheckedUpdateInputObjectSchema,
  ]),
})
