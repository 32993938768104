import { z } from 'zod'
import { CaseUpdateWithoutFollowupCaseInputObjectSchema } from './CaseUpdateWithoutFollowupCaseInput.schema'
import { CaseUncheckedUpdateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedUpdateWithoutFollowupCaseInput.schema'
import { CaseCreateWithoutFollowupCaseInputObjectSchema } from './CaseCreateWithoutFollowupCaseInput.schema'
import { CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedCreateWithoutFollowupCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutFollowupCaseInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutFollowupCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutFollowupCaseInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutFollowupCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutFollowupCaseInputObjectSchema = Schema
