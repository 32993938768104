import { Box } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  Pagination,
  ReferenceField,
  TextField,
} from 'react-admin'
import {
  CustomBooleanField,
  CustomI18nField,
} from '../../components/CustomField'
import { FilterListForm } from './list-section/filterlistForm'

const rowsPerPage = [5, 10, 20]

const PartnerUserListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}

export const PartnerUserList = () => {
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={<PartnerUserListAction />}
      >
        <Datagrid size="small" rowClick="edit">
          <TextField source="id" />
          <TextField source="email" />
          <ReferenceField reference="partner" source="partnerId" link={false}>
            <CustomI18nField fieldTr="nameTr" />
          </ReferenceField>
          <CustomBooleanField source="active" />
        </Datagrid>
      </List>
    </Box>
  )
}
