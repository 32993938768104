import { z } from 'zod'
import { SuggestedProcedureItemUpdateInputObjectSchema } from './objects/SuggestedProcedureItemUpdateInput.schema'
import { SuggestedProcedureItemUncheckedUpdateInputObjectSchema } from './objects/SuggestedProcedureItemUncheckedUpdateInput.schema'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './objects/SuggestedProcedureItemWhereUniqueInput.schema'

export const SuggestedProcedureItemUpdateOneSchema = z.object({
  data: z.union([
    SuggestedProcedureItemUpdateInputObjectSchema,
    SuggestedProcedureItemUncheckedUpdateInputObjectSchema,
  ]),
  where: SuggestedProcedureItemWhereUniqueInputObjectSchema,
})
