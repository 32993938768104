import { z } from 'zod'
import { CarouselArgsObjectSchema } from './CarouselArgs.schema'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    active: z.boolean().optional(),
    priority: z.boolean().optional(),
    carousel: z
      .union([z.boolean(), z.lazy(() => CarouselArgsObjectSchema)])
      .optional(),
    carouselId: z.boolean().optional(),
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    procedureId: z.boolean().optional(),
  })
  .strict()

export const CarouselItemSelectObjectSchema = Schema
