import { z } from 'zod'
import { ProductItemCreateWithoutRefundInputObjectSchema } from './ProductItemCreateWithoutRefundInput.schema'
import { ProductItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProductItemUncheckedCreateWithoutRefundInput.schema'
import { ProductItemCreateOrConnectWithoutRefundInputObjectSchema } from './ProductItemCreateOrConnectWithoutRefundInput.schema'
import { ProductItemCreateManyRefundInputEnvelopeObjectSchema } from './ProductItemCreateManyRefundInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUncheckedCreateNestedManyWithoutRefundInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema),
          z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema).array(),
          z.lazy(
            () => ProductItemUncheckedCreateWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemUncheckedCreateWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductItemCreateOrConnectWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemCreateOrConnectWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemCreateManyRefundInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemUncheckedCreateNestedManyWithoutRefundInputObjectSchema =
  Schema
