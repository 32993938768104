import { z } from 'zod'
import { ProductVideoWhereUniqueInputObjectSchema } from './objects/ProductVideoWhereUniqueInput.schema'
import { ProductVideoCreateInputObjectSchema } from './objects/ProductVideoCreateInput.schema'
import { ProductVideoUncheckedCreateInputObjectSchema } from './objects/ProductVideoUncheckedCreateInput.schema'
import { ProductVideoUpdateInputObjectSchema } from './objects/ProductVideoUpdateInput.schema'
import { ProductVideoUncheckedUpdateInputObjectSchema } from './objects/ProductVideoUncheckedUpdateInput.schema'

export const ProductVideoUpsertSchema = z.object({
  where: ProductVideoWhereUniqueInputObjectSchema,
  create: z.union([
    ProductVideoCreateInputObjectSchema,
    ProductVideoUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductVideoUpdateInputObjectSchema,
    ProductVideoUncheckedUpdateInputObjectSchema,
  ]),
})
