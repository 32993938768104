import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { CarouselUpdateOneRequiredWithoutItemsNestedInputObjectSchema } from './CarouselUpdateOneRequiredWithoutItemsNestedInput.schema'
import { ProcedureUpdateOneRequiredWithoutCarouselItemsNestedInputObjectSchema } from './ProcedureUpdateOneRequiredWithoutCarouselItemsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    active: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    carousel: z
      .lazy(() => CarouselUpdateOneRequiredWithoutItemsNestedInputObjectSchema)
      .optional(),
    procedure: z
      .lazy(
        () =>
          ProcedureUpdateOneRequiredWithoutCarouselItemsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CarouselItemUpdateInputObjectSchema = Schema
