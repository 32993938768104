import { z } from 'zod'
import { CouponGroupArgsObjectSchema } from './CouponGroupArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingInclude> = z
  .object({
    group: z
      .union([z.boolean(), z.lazy(() => CouponGroupArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const DiscountSettingIncludeObjectSchema = Schema
