import { z } from 'zod'
import { ProductReviewCreateWithoutPicturesInputObjectSchema } from './ProductReviewCreateWithoutPicturesInput.schema'
import { ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductReviewUncheckedCreateWithoutPicturesInput.schema'
import { ProductReviewCreateOrConnectWithoutPicturesInputObjectSchema } from './ProductReviewCreateOrConnectWithoutPicturesInput.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateNestedOneWithoutPicturesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutPicturesInputObjectSchema),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProductReviewCreateOrConnectWithoutPicturesInputObjectSchema,
        )
        .optional(),
      connect: z
        .lazy(() => ProductReviewWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProductReviewCreateNestedOneWithoutPicturesInputObjectSchema =
  Schema
