import { z } from 'zod'
import { ConditionSettingCreateWithoutGroupInputObjectSchema } from './ConditionSettingCreateWithoutGroupInput.schema'
import { ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema } from './ConditionSettingUncheckedCreateWithoutGroupInput.schema'
import { ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema } from './ConditionSettingCreateOrConnectWithoutGroupInput.schema'
import { ConditionSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema } from './ConditionSettingUpsertWithWhereUniqueWithoutGroupInput.schema'
import { ConditionSettingCreateManyGroupInputEnvelopeObjectSchema } from './ConditionSettingCreateManyGroupInputEnvelope.schema'
import { ConditionSettingWhereUniqueInputObjectSchema } from './ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema } from './ConditionSettingUpdateWithWhereUniqueWithoutGroupInput.schema'
import { ConditionSettingUpdateManyWithWhereWithoutGroupInputObjectSchema } from './ConditionSettingUpdateManyWithWhereWithoutGroupInput.schema'
import { ConditionSettingScalarWhereInputObjectSchema } from './ConditionSettingScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingUncheckedUpdateManyWithoutGroupNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ConditionSettingCreateWithoutGroupInputObjectSchema),
          z
            .lazy(() => ConditionSettingCreateWithoutGroupInputObjectSchema)
            .array(),
          z.lazy(
            () => ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ConditionSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ConditionSettingCreateManyGroupInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ConditionSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ConditionSettingUpdateManyWithWhereWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingUpdateManyWithWhereWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ConditionSettingScalarWhereInputObjectSchema),
          z.lazy(() => ConditionSettingScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ConditionSettingUncheckedUpdateManyWithoutGroupNestedInputObjectSchema =
  Schema
