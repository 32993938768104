import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumWarehouseCodeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => WarehouseCodeSchema).optional(),
  })
  .strict()

export const EnumWarehouseCodeFieldUpdateOperationsInputObjectSchema = Schema
