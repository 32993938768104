import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantPictureCountOrderByAggregateInputObjectSchema } from './VariantPictureCountOrderByAggregateInput.schema'
import { VariantPictureAvgOrderByAggregateInputObjectSchema } from './VariantPictureAvgOrderByAggregateInput.schema'
import { VariantPictureMaxOrderByAggregateInputObjectSchema } from './VariantPictureMaxOrderByAggregateInput.schema'
import { VariantPictureMinOrderByAggregateInputObjectSchema } from './VariantPictureMinOrderByAggregateInput.schema'
import { VariantPictureSumOrderByAggregateInputObjectSchema } from './VariantPictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    variantSku: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => VariantPictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => VariantPictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => VariantPictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => VariantPictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => VariantPictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const VariantPictureOrderByWithAggregationInputObjectSchema = Schema
