import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'
import { EnumWarehouseCodeFieldUpdateOperationsInputObjectSchema } from './EnumWarehouseCodeFieldUpdateOperationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUncheckedUpdateWithoutVariantWarehousesInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      name: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      code: z
        .union([
          z.lazy(() => WarehouseCodeSchema),
          z.lazy(() => EnumWarehouseCodeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const WarehouseUncheckedUpdateWithoutVariantWarehousesInputObjectSchema =
  Schema
