import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ExpressShippingAreaCountOrderByAggregateInputObjectSchema } from './ExpressShippingAreaCountOrderByAggregateInput.schema'
import { ExpressShippingAreaAvgOrderByAggregateInputObjectSchema } from './ExpressShippingAreaAvgOrderByAggregateInput.schema'
import { ExpressShippingAreaMaxOrderByAggregateInputObjectSchema } from './ExpressShippingAreaMaxOrderByAggregateInput.schema'
import { ExpressShippingAreaMinOrderByAggregateInputObjectSchema } from './ExpressShippingAreaMinOrderByAggregateInput.schema'
import { ExpressShippingAreaSumOrderByAggregateInputObjectSchema } from './ExpressShippingAreaSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      postCode: z.lazy(() => SortOrderSchema).optional(),
      shippingProviderId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => ExpressShippingAreaCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => ExpressShippingAreaAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ExpressShippingAreaMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ExpressShippingAreaMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ExpressShippingAreaSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ExpressShippingAreaOrderByWithAggregationInputObjectSchema = Schema
