import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { Dispatch, FC, MouseEvent, ReactNode, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { adminGatewayClient } from '../../../service'

interface RejectButtonProps {
  disabled: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const displayIncorrectFieldList = (data: { [key: string]: any }) => {
  const errorList = [] as ReactNode[]

  Object.keys(data.validator).forEach((key) => {
    if (data.validator[key] === '0') {
      errorList.push(
        <TableRow>
          <TableCell>{key}</TableCell>
          <TableCell>{data.errorReason[key] ?? '-'}</TableCell>
        </TableRow>,
      )
    }
  })

  return errorList
}

export const RejectButton: FC<RejectButtonProps> = ({
  disabled,
  setIsLoading,
}) => {
  const navigate = useNavigate()
  const { getValues } = useFormContext()
  const confirm = useConfirm()

  const handleClickReject = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const { id, ...data } = getValues()
    confirm({
      title: 'Confirm patient KYC rejection',
      description: (
        <>
          <Typography sx={{ mb: 1 }}>
            Do you want to reject{' '}
            <b>
              {data.prefix} {data.firstname} {data.lastname}
            </b>{' '}
            KYC?
          </Typography>
          <Typography>Incorrect field:</Typography>
          <TableContainer
            sx={{ mb: 2, border: '1px solid black', borderRadius: '4px' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Field</b>
                  </TableCell>
                  <TableCell>
                    <b>Reason</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{displayIncorrectFieldList(data)}</TableBody>
            </Table>
          </TableContainer>
        </>
      ),
    })
      .then(() => {
        setIsLoading(true)
        const rejectFields = [] as { field: string; reason: string }[]
        Object.keys(data.validator).forEach((key) => {
          if (data.validator[key] === '0') {
            rejectFields.push({
              field: key,
              reason: (data.errorReason[key] as string) ?? '',
            })
          }
        })

        adminGatewayClient.patientKycData.reject
          .mutate({
            kycDataId: id,
            rejectReason: rejectFields,
          })
          .then(() => {
            setIsLoading(false)
            navigate(-1)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={handleClickReject}
      sx={{
        backgroundColor: '#FF434E',
        ':disabled': {
          opacity: '0.3',
          backgroundColor: '#FF434E',
          color: 'white',
        },
      }}
    >
      Reject
    </Button>
  )
}
