import { z } from 'zod'
import { VariantWarehouseCreateInputObjectSchema } from './objects/VariantWarehouseCreateInput.schema'
import { VariantWarehouseUncheckedCreateInputObjectSchema } from './objects/VariantWarehouseUncheckedCreateInput.schema'

export const VariantWarehouseCreateOneSchema = z.object({
  data: z.union([
    VariantWarehouseCreateInputObjectSchema,
    VariantWarehouseUncheckedCreateInputObjectSchema,
  ]),
})
