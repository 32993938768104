import { z } from 'zod'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumCoinTransactionTypeFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => CoinTransactionTypeSchema).optional(),
    })
    .strict()

export const EnumCoinTransactionTypeFieldUpdateOperationsInputObjectSchema =
  Schema
