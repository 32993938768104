import { z } from 'zod'
import { CoverPictureWhereInputObjectSchema } from './objects/CoverPictureWhereInput.schema'
import { CoverPictureOrderByWithAggregationInputObjectSchema } from './objects/CoverPictureOrderByWithAggregationInput.schema'
import { CoverPictureScalarWhereWithAggregatesInputObjectSchema } from './objects/CoverPictureScalarWhereWithAggregatesInput.schema'
import { CoverPictureScalarFieldEnumSchema } from './enums/CoverPictureScalarFieldEnum.schema'

export const CoverPictureGroupBySchema = z.object({
  where: CoverPictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CoverPictureOrderByWithAggregationInputObjectSchema,
    CoverPictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CoverPictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CoverPictureScalarFieldEnumSchema),
})
