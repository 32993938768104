import { z } from 'zod'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumCoinTransactionTypeFilter> = z
  .object({
    equals: z.lazy(() => CoinTransactionTypeSchema).optional(),
    in: z
      .lazy(() => CoinTransactionTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CoinTransactionTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CoinTransactionTypeSchema),
        z.lazy(() => NestedEnumCoinTransactionTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumCoinTransactionTypeFilterObjectSchema = Schema
