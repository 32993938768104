import { z } from 'zod'
import { DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisWhereUniqueInputObjectSchema } from './DiagnosisWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
          z.lazy(
            () =>
              DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            DiagnosisCreateOrConnectWithoutCaseSummaryDiagnosisInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => DiagnosisWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
