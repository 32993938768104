import { z } from 'zod'
import { BrandCountOutputTypeSelectObjectSchema } from './BrandCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => BrandCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const BrandCountOutputTypeArgsObjectSchema = Schema
