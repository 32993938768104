import { z } from 'zod'
import { ProcedureItemCreateInputObjectSchema } from './objects/ProcedureItemCreateInput.schema'
import { ProcedureItemUncheckedCreateInputObjectSchema } from './objects/ProcedureItemUncheckedCreateInput.schema'

export const ProcedureItemCreateOneSchema = z.object({
  data: z.union([
    ProcedureItemCreateInputObjectSchema,
    ProcedureItemUncheckedCreateInputObjectSchema,
  ]),
})
