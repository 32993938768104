import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { EnumCouponTypeFieldUpdateOperationsInputObjectSchema } from './EnumCouponTypeFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'
import { EnumDiscountTypeFieldUpdateOperationsInputObjectSchema } from './EnumDiscountTypeFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { CouponUncheckedUpdateManyWithoutGroupNestedInputObjectSchema } from './CouponUncheckedUpdateManyWithoutGroupNestedInput.schema'
import { ConditionSettingUncheckedUpdateManyWithoutGroupNestedInputObjectSchema } from './ConditionSettingUncheckedUpdateManyWithoutGroupNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CouponGroupUncheckedUpdateWithoutDiscountSettingInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      prefix: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      code: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      type: z
        .union([
          z.lazy(() => CouponTypeSchema),
          z.lazy(() => EnumCouponTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      titleTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      descriptionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      tags: z
        .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
        .optional(),
      picture: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      discount: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      discountType: z
        .union([
          z.lazy(() => DiscountTypeSchema),
          z.lazy(() => EnumDiscountTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      minTotal: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      maxDiscount: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      startAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      expireAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isPublic: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isOnlyNewPatient: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      note: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      quota: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      quotaPerUser: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      createdBy: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedBy: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2CreatedBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      v2UpdatedBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      coupons: z
        .lazy(
          () => CouponUncheckedUpdateManyWithoutGroupNestedInputObjectSchema,
        )
        .optional(),
      conditionSetting: z
        .lazy(
          () =>
            ConditionSettingUncheckedUpdateManyWithoutGroupNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CouponGroupUncheckedUpdateWithoutDiscountSettingInputObjectSchema =
  Schema
