import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.OldPatientScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => OldPatientScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => OldPatientScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => OldPatientScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => OldPatientScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => OldPatientScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const OldPatientScalarWhereWithAggregatesInputObjectSchema = Schema
