import { z } from 'zod'
import { ConditionSettingCreateManyGroupInputObjectSchema } from './ConditionSettingCreateManyGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingCreateManyGroupInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => ConditionSettingCreateManyGroupInputObjectSchema),
      z.lazy(() => ConditionSettingCreateManyGroupInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const ConditionSettingCreateManyGroupInputEnvelopeObjectSchema = Schema
