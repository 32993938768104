import { z } from 'zod'

export const ProductCreateEventModel = z.object({
  productIds: z.array(z.number()),
})
export type ProductCreateEventModel = z.infer<typeof ProductCreateEventModel>

export const ProductUpdateEventModel = z.object({
  productIds: z.array(z.number()),
})
export type ProductUpdateEventModel = z.infer<typeof ProductUpdateEventModel>

export const VariantCreateEventModel = z.object({
  variantId: z.number(),
})
export type VariantCreateEventModel = z.infer<typeof VariantCreateEventModel>

export const VariantUpdateEventModel = z.object({
  variantId: z.number(),
})
export type VariantUpdateEventModel = z.infer<typeof VariantUpdateEventModel>

export const BrandCreateEventModel = z.object({
  brandIds: z.array(z.number()),
})
export type BrandCreateEventModel = z.infer<typeof BrandCreateEventModel>

export const BrandUpdateEventModel = z.object({
  brandIds: z.array(z.number()),
})
export type BrandUpdateEventModel = z.infer<typeof BrandUpdateEventModel>

export const ProductReviewCreateEventModel = z.object({
  productReviewId: z.number().int(),
  orderProductItemId: z.number().int(),
  patientId: z.number().int(),
  receivedCoin: z.number(),
})
export type ProductReviewCreateEventModel = z.infer<
  typeof ProductReviewCreateEventModel
>
