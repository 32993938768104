import { z } from 'zod'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewUpdateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedUpdateWithoutProcedureInput.schema'
import { ProcedureReviewCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProcedureReviewUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => ProcedureReviewUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
