import { z } from 'zod'
import { CouponGroupUpdateInputObjectSchema } from './objects/CouponGroupUpdateInput.schema'
import { CouponGroupUncheckedUpdateInputObjectSchema } from './objects/CouponGroupUncheckedUpdateInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './objects/CouponGroupWhereUniqueInput.schema'

export const CouponGroupUpdateOneSchema = z.object({
  data: z.union([
    CouponGroupUpdateInputObjectSchema,
    CouponGroupUncheckedUpdateInputObjectSchema,
  ]),
  where: CouponGroupWhereUniqueInputObjectSchema,
})
