import { z } from 'zod'
import { WeekdaySchema } from '../enums/Weekday.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourBranchIdWeekdayCompoundUniqueInput> = z
  .object({
    branchId: z.number(),
    weekday: z.lazy(() => WeekdaySchema),
  })
  .strict()

export const OfficeHourBranchIdWeekdayCompoundUniqueInputObjectSchema = Schema
