import { z } from 'zod'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateOrConnectWithoutProcedureReviewSummaryInput> =
  z
    .object({
      where: z.lazy(() => ProcedureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCreateOrConnectWithoutProcedureReviewSummaryInputObjectSchema =
  Schema
