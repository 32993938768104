import { z } from 'zod'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchUpdateWithoutPartnerInputObjectSchema } from './BranchUpdateWithoutPartnerInput.schema'
import { BranchUncheckedUpdateWithoutPartnerInputObjectSchema } from './BranchUncheckedUpdateWithoutPartnerInput.schema'
import { BranchCreateWithoutPartnerInputObjectSchema } from './BranchCreateWithoutPartnerInput.schema'
import { BranchUncheckedCreateWithoutPartnerInputObjectSchema } from './BranchUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpsertWithWhereUniqueWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => BranchWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => BranchUpdateWithoutPartnerInputObjectSchema),
        z.lazy(() => BranchUncheckedUpdateWithoutPartnerInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema),
        z.lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema),
      ]),
    })
    .strict()

export const BranchUpsertWithWhereUniqueWithoutPartnerInputObjectSchema = Schema
