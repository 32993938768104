import { z } from 'zod'
import { CarouselWhereUniqueInputObjectSchema } from './objects/CarouselWhereUniqueInput.schema'
import { CarouselCreateInputObjectSchema } from './objects/CarouselCreateInput.schema'
import { CarouselUncheckedCreateInputObjectSchema } from './objects/CarouselUncheckedCreateInput.schema'
import { CarouselUpdateInputObjectSchema } from './objects/CarouselUpdateInput.schema'
import { CarouselUncheckedUpdateInputObjectSchema } from './objects/CarouselUncheckedUpdateInput.schema'

export const CarouselUpsertSchema = z.object({
  where: CarouselWhereUniqueInputObjectSchema,
  create: z.union([
    CarouselCreateInputObjectSchema,
    CarouselUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CarouselUpdateInputObjectSchema,
    CarouselUncheckedUpdateInputObjectSchema,
  ]),
})
