import { z } from 'zod'
import { VariantCreateWithoutVariantWarehousesInputObjectSchema } from './VariantCreateWithoutVariantWarehousesInput.schema'
import { VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './VariantUncheckedCreateWithoutVariantWarehousesInput.schema'
import { VariantCreateOrConnectWithoutVariantWarehousesInputObjectSchema } from './VariantCreateOrConnectWithoutVariantWarehousesInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateNestedOneWithoutVariantWarehousesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantCreateWithoutVariantWarehousesInputObjectSchema),
          z.lazy(
            () =>
              VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => VariantCreateOrConnectWithoutVariantWarehousesInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const VariantCreateNestedOneWithoutVariantWarehousesInputObjectSchema =
  Schema
