import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdBy: z.literal(true).optional(),
    updatedBy: z.literal(true).optional(),
  })
  .strict()

export const FlashsaleAvgAggregateInputObjectSchema = Schema
