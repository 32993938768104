import { z } from 'zod'
import { TeleconsultSelectObjectSchema } from './TeleconsultSelect.schema'
import { TeleconsultIncludeObjectSchema } from './TeleconsultInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultArgs> = z
  .object({
    select: z.lazy(() => TeleconsultSelectObjectSchema).optional(),
    include: z.lazy(() => TeleconsultIncludeObjectSchema).optional(),
  })
  .strict()

export const TeleconsultArgsObjectSchema = Schema
