import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    expireDurationDay: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
  })
  .strict()

export const ProcedureAvgAggregateInputObjectSchema = Schema
