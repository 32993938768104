import * as z from "zod"
import { CompletePatient, RelatedPatientModel, CompleteRejectReason, RelatedRejectReasonModel } from "./index"

export const KycDataModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  patientId: z.number().int(),
  channel: z.enum(['MANUAL', 'SCB']),
  status: z.enum(['APPROVED', 'PENDING', 'REJECTED']),
  approvedAt: z.date().nullish(),
  approvedBy: z.number().int().nullish(),
  prefix: z.string().nullish(),
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
  gender: z.enum(['MALE', 'FEMALE']).nullish(),
  birthDate: z.date().nullish(),
  idCardNumber: z.string().nullish(),
  idCardPicture: z.string().nullish(),
  selfieIdCardPicture: z.string().nullish(),
  passportNumber: z.string().nullish(),
  passportPicture: z.string().nullish(),
  selfiePassportPicture: z.string().nullish(),
  rejectAt: z.date().nullish(),
  rejectBy: z.number().int().nullish(),
})

export interface CompleteKycData extends z.infer<typeof KycDataModel> {
  patient: CompletePatient
  rejectReason: CompleteRejectReason[]
}

/**
 * RelatedKycDataModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedKycDataModel: z.ZodSchema<CompleteKycData> = z.lazy(() => KycDataModel.extend({
  patient: RelatedPatientModel,
  rejectReason: RelatedRejectReasonModel.array(),
}))
