import { z } from 'zod'
import { ProcedureCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateWithoutCarouselItemsInput.schema'
import { ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureUncheckedCreateWithoutCarouselItemsInput.schema'
import { ProcedureCreateOrConnectWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateOrConnectWithoutCarouselItemsInput.schema'
import { ProcedureUpsertWithoutCarouselItemsInputObjectSchema } from './ProcedureUpsertWithoutCarouselItemsInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureUpdateWithoutCarouselItemsInputObjectSchema } from './ProcedureUpdateWithoutCarouselItemsInput.schema'
import { ProcedureUncheckedUpdateWithoutCarouselItemsInputObjectSchema } from './ProcedureUncheckedUpdateWithoutCarouselItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpdateOneRequiredWithoutCarouselItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutCarouselItemsInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProcedureCreateOrConnectWithoutCarouselItemsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProcedureUpsertWithoutCarouselItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProcedureUpdateWithoutCarouselItemsInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedUpdateWithoutCarouselItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureUpdateOneRequiredWithoutCarouselItemsNestedInputObjectSchema =
  Schema
