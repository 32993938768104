import { z } from 'zod'
import { DiscountSettingCreateWithoutGroupInputObjectSchema } from './DiscountSettingCreateWithoutGroupInput.schema'
import { DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema } from './DiscountSettingUncheckedCreateWithoutGroupInput.schema'
import { DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema } from './DiscountSettingCreateOrConnectWithoutGroupInput.schema'
import { DiscountSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema } from './DiscountSettingUpsertWithWhereUniqueWithoutGroupInput.schema'
import { DiscountSettingCreateManyGroupInputEnvelopeObjectSchema } from './DiscountSettingCreateManyGroupInputEnvelope.schema'
import { DiscountSettingWhereUniqueInputObjectSchema } from './DiscountSettingWhereUniqueInput.schema'
import { DiscountSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema } from './DiscountSettingUpdateWithWhereUniqueWithoutGroupInput.schema'
import { DiscountSettingUpdateManyWithWhereWithoutGroupInputObjectSchema } from './DiscountSettingUpdateManyWithWhereWithoutGroupInput.schema'
import { DiscountSettingScalarWhereInputObjectSchema } from './DiscountSettingScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingUncheckedUpdateManyWithoutGroupNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DiscountSettingCreateWithoutGroupInputObjectSchema),
          z
            .lazy(() => DiscountSettingCreateWithoutGroupInputObjectSchema)
            .array(),
          z.lazy(
            () => DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              DiscountSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DiscountSettingUpsertWithWhereUniqueWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => DiscountSettingCreateManyGroupInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              DiscountSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DiscountSettingUpdateWithWhereUniqueWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              DiscountSettingUpdateManyWithWhereWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DiscountSettingUpdateManyWithWhereWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => DiscountSettingScalarWhereInputObjectSchema),
          z.lazy(() => DiscountSettingScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const DiscountSettingUncheckedUpdateManyWithoutGroupNestedInputObjectSchema =
  Schema
