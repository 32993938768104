import { z } from 'zod'
import { ProcedureUpdateInputObjectSchema } from './objects/ProcedureUpdateInput.schema'
import { ProcedureUncheckedUpdateInputObjectSchema } from './objects/ProcedureUncheckedUpdateInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './objects/ProcedureWhereUniqueInput.schema'

export const ProcedureUpdateOneSchema = z.object({
  data: z.union([
    ProcedureUpdateInputObjectSchema,
    ProcedureUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureWhereUniqueInputObjectSchema,
})
