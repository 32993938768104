import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUncheckedCreateWithoutBrandInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      productId: z.number(),
      overallRating: z.number().optional(),
      qualityRating: z.number().optional(),
      shippingRating: z.number().optional(),
      packagingRating: z.number().optional(),
      countForRating5: z.number().optional(),
      countForRating4: z.number().optional(),
      countForRating3: z.number().optional(),
      countForRating2: z.number().optional(),
      countForRating1: z.number().optional(),
    })
    .strict()

export const ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema =
  Schema
