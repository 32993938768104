import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { ReferenceInput, useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomAutoCompleteInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      partnerId: undefined,
      searchField: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }
  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  const filterPartner = (searchText: string) => ({ searchField: searchText })

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          partnerId: {
            formKey: 'partnerId',
          },
          searchField: {
            formKey: 'searchField',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <ReferenceInput
            source="partnerId"
            reference="partner"
            sort={{ field: 'searchField', order: 'ASC' }}
          >
            <CustomAutoCompleteInput
              sx={{ mt: 0.5, flexGrow: 1 }}
              optionText={(c: { nameTr: { th: string; en: string } }) => {
                return `${c.nameTr.th} (${c.nameTr.en})`
              }}
              label="Clinic"
              helperText={false}
              filterToQuery={filterPartner}
            />
          </ReferenceInput>
          <CustomTextInput
            sx={{ mt: 0.5, flexGrow: 1 }}
            resettable
            helperText={false}
            source="searchField"
            label="Name"
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
