import { z } from 'zod'
import { OrderCreateWithoutDoctorItemInputObjectSchema } from './OrderCreateWithoutDoctorItemInput.schema'
import { OrderUncheckedCreateWithoutDoctorItemInputObjectSchema } from './OrderUncheckedCreateWithoutDoctorItemInput.schema'
import { OrderCreateOrConnectWithoutDoctorItemInputObjectSchema } from './OrderCreateOrConnectWithoutDoctorItemInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutDoctorItemInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutDoctorItemInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutDoctorItemInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutDoctorItemInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutDoctorItemInputObjectSchema = Schema
