import { z } from 'zod'
import { SkuCreateWithoutPartnerInputObjectSchema } from './SkuCreateWithoutPartnerInput.schema'
import { SkuUncheckedCreateWithoutPartnerInputObjectSchema } from './SkuUncheckedCreateWithoutPartnerInput.schema'
import { SkuCreateOrConnectWithoutPartnerInputObjectSchema } from './SkuCreateOrConnectWithoutPartnerInput.schema'
import { SkuCreateManyPartnerInputEnvelopeObjectSchema } from './SkuCreateManyPartnerInputEnvelope.schema'
import { SkuWhereUniqueInputObjectSchema } from './SkuWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuUncheckedCreateNestedManyWithoutPartnerInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => SkuCreateWithoutPartnerInputObjectSchema),
          z.lazy(() => SkuCreateWithoutPartnerInputObjectSchema).array(),
          z.lazy(() => SkuUncheckedCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuUncheckedCreateWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => SkuCreateOrConnectWithoutPartnerInputObjectSchema),
          z
            .lazy(() => SkuCreateOrConnectWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => SkuCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => SkuWhereUniqueInputObjectSchema),
          z.lazy(() => SkuWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const SkuUncheckedCreateNestedManyWithoutPartnerInputObjectSchema =
  Schema
