import * as z from "zod"
import { CompleteProduct, RelatedProductModel } from "./index"

export const ProductPictureModel = z.object({
  id: z.number().int(),
  url: z.string(),
  productId: z.number().int(),
  rank: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteProductPicture extends z.infer<typeof ProductPictureModel> {
  product: CompleteProduct
}

/**
 * RelatedProductPictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductPictureModel: z.ZodSchema<CompleteProductPicture> = z.lazy(() => ProductPictureModel.extend({
  product: RelatedProductModel,
}))
