import { z } from 'zod'

export const OrderNotificationScalarFieldEnumSchema = z.enum([
  'id',
  'orderId',
  'onesignalMessageId',
  'orderItemType',
  'title',
  'createdAt',
  'sendAt',
  'procedureId',
])
