import { z } from 'zod'
import { ProductReviewPictureOrderByWithRelationInputObjectSchema } from './objects/ProductReviewPictureOrderByWithRelationInput.schema'
import { ProductReviewPictureWhereInputObjectSchema } from './objects/ProductReviewPictureWhereInput.schema'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './objects/ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureCountAggregateInputObjectSchema } from './objects/ProductReviewPictureCountAggregateInput.schema'
import { ProductReviewPictureMinAggregateInputObjectSchema } from './objects/ProductReviewPictureMinAggregateInput.schema'
import { ProductReviewPictureMaxAggregateInputObjectSchema } from './objects/ProductReviewPictureMaxAggregateInput.schema'
import { ProductReviewPictureAvgAggregateInputObjectSchema } from './objects/ProductReviewPictureAvgAggregateInput.schema'
import { ProductReviewPictureSumAggregateInputObjectSchema } from './objects/ProductReviewPictureSumAggregateInput.schema'

export const ProductReviewPictureAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductReviewPictureOrderByWithRelationInputObjectSchema,
      ProductReviewPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewPictureWhereInputObjectSchema.optional(),
  cursor: ProductReviewPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ProductReviewPictureCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ProductReviewPictureMinAggregateInputObjectSchema.optional(),
  _max: ProductReviewPictureMaxAggregateInputObjectSchema.optional(),
  _avg: ProductReviewPictureAvgAggregateInputObjectSchema.optional(),
  _sum: ProductReviewPictureSumAggregateInputObjectSchema.optional(),
})
