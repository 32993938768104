import { z } from 'zod'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumConsultingChannelFilter> = z
  .object({
    equals: z.lazy(() => ConsultingChannelSchema).optional(),
    in: z
      .lazy(() => ConsultingChannelSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConsultingChannelSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConsultingChannelSchema),
        z.lazy(() => NestedEnumConsultingChannelFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumConsultingChannelFilterObjectSchema = Schema
