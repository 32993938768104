import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureCategoryCountOrderByAggregateInputObjectSchema } from './ProcedureCategoryCountOrderByAggregateInput.schema'
import { ProcedureCategoryAvgOrderByAggregateInputObjectSchema } from './ProcedureCategoryAvgOrderByAggregateInput.schema'
import { ProcedureCategoryMaxOrderByAggregateInputObjectSchema } from './ProcedureCategoryMaxOrderByAggregateInput.schema'
import { ProcedureCategoryMinOrderByAggregateInputObjectSchema } from './ProcedureCategoryMinOrderByAggregateInput.schema'
import { ProcedureCategorySumOrderByAggregateInputObjectSchema } from './ProcedureCategorySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    categoryId: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedureCategoryCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedureCategoryAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedureCategoryMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedureCategoryMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedureCategorySumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureCategoryOrderByWithAggregationInputObjectSchema = Schema
