import { z } from 'zod'
import { ProductItemUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateNestedManyWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutOrderInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    shippingProvider: z.string(),
    trackingNumber: z.string(),
    status: z.string(),
    deliveredAt: z.date().optional().nullable(),
    partnerName: z.string(),
    productItems: z
      .lazy(
        () =>
          ProductItemUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema,
      )
      .optional(),
    deliveryLogs: z
      .lazy(
        () =>
          DeliveryLogUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const DeliveryUncheckedCreateWithoutOrderInputObjectSchema = Schema
