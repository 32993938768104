import { z } from 'zod'
import { CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryCreateOrConnectWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateNestedOneWithoutSuggestedProcedureItemsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutSuggestedProcedureItemsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseSummaryCreateNestedOneWithoutSuggestedProcedureItemsInputObjectSchema =
  Schema
