import { z } from 'zod'
import { KycDataUpdateWithoutPatientInputObjectSchema } from './KycDataUpdateWithoutPatientInput.schema'
import { KycDataUncheckedUpdateWithoutPatientInputObjectSchema } from './KycDataUncheckedUpdateWithoutPatientInput.schema'
import { KycDataCreateWithoutPatientInputObjectSchema } from './KycDataCreateWithoutPatientInput.schema'
import { KycDataUncheckedCreateWithoutPatientInputObjectSchema } from './KycDataUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataUpsertWithoutPatientInput> = z
  .object({
    update: z.union([
      z.lazy(() => KycDataUpdateWithoutPatientInputObjectSchema),
      z.lazy(() => KycDataUncheckedUpdateWithoutPatientInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => KycDataCreateWithoutPatientInputObjectSchema),
      z.lazy(() => KycDataUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const KycDataUpsertWithoutPatientInputObjectSchema = Schema
