import { z } from 'zod'
import { PaymentStatusSchema } from '../enums/PaymentStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumPaymentStatusFilter> = z
  .object({
    equals: z.lazy(() => PaymentStatusSchema).optional(),
    in: z
      .lazy(() => PaymentStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => PaymentStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => PaymentStatusSchema),
        z.lazy(() => NestedEnumPaymentStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumPaymentStatusFilterObjectSchema = Schema
