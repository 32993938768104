import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompletePartner, RelatedPartnerModel } from "./index"

export const ProcedureOrderModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  voucherNumber: z.string(),
  orderId: z.number().int(),
  orderNumber: z.string(),
  procedureId: z.number().int(),
  procedureItemId: z.number().int(),
  procedureNameTr: z.object({ en: z.string(), th: z.string() }),
  patientId: z.number().int(),
  patientName: z.string(),
  patientTelNo: z.string(),
  paymentMethod: z.enum(['NO_PAYMENT_REQUIRED', 'CREDIT_CARD', 'INSTALLMENT_SCB', 'INSTALLMENT_KBANK', 'INSTALLMENT_BAY', 'INSTALLMENT_BBL', 'INSTALLMENT_FIRST_CHOICE', 'INSTALLMENT_KTC', 'INSTALLMENT_UOB', 'INSTALLMENT_TTB', 'PROMPTPAY', 'MOBILE_BANKING', 'MOBILE_BANKING_SCB', 'MOBILE_BANKING_KBANK', 'MOBILE_BANKING_BBL', 'MOBILE_BANKING_KTB', 'MOBILE_BANKING_KMA']),
  installmentTerm: z.number().int().nullish(),
  couponCode: z.string().nullish(),
  sellingPrice: z.number(),
  flashsalePrice: z.number().nullish(),
  cost: z.number(),
  priceAbsorbedBySkinX: z.number(),
  priceAbsorbedByPartner: z.number(),
  commission: z.number(),
  commissionRate: z.number(),
  vat: z.number(),
  vatRate: z.number(),
  netIncome: z.number(),
  status: z.enum(['UNUSED', 'USED', 'REFUNDED']),
  usedAt: z.date().nullish(),
  expireAt: z.date(),
  note: z.string().nullish(),
  partnerId: z.number().int(),
  v2StoreVoucherId: z.number().int().nullish(),
})

export const ProcedureOrderModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  voucherNumber: z.string(),
  orderId: z.number().int(),
  orderNumber: z.string(),
  procedureId: z.number().int(),
  procedureItemId: z.number().int(),
  procedureNameTr: jsonSchema,
  patientId: z.number().int(),
  patientName: z.string(),
  patientTelNo: z.string(),
  paymentMethod: z.enum(['NO_PAYMENT_REQUIRED', 'CREDIT_CARD', 'INSTALLMENT_SCB', 'INSTALLMENT_KBANK', 'INSTALLMENT_BAY', 'INSTALLMENT_BBL', 'INSTALLMENT_FIRST_CHOICE', 'INSTALLMENT_KTC', 'INSTALLMENT_UOB', 'INSTALLMENT_TTB', 'PROMPTPAY', 'MOBILE_BANKING', 'MOBILE_BANKING_SCB', 'MOBILE_BANKING_KBANK', 'MOBILE_BANKING_BBL', 'MOBILE_BANKING_KTB', 'MOBILE_BANKING_KMA']),
  installmentTerm: z.number().int().nullish(),
  couponCode: z.string().nullish(),
  sellingPrice: z.number(),
  flashsalePrice: z.number().nullish(),
  cost: z.number(),
  priceAbsorbedBySkinX: z.number(),
  priceAbsorbedByPartner: z.number(),
  commission: z.number(),
  commissionRate: z.number(),
  vat: z.number(),
  vatRate: z.number(),
  netIncome: z.number(),
  status: z.enum(['UNUSED', 'USED', 'REFUNDED']),
  usedAt: z.date().nullish(),
  expireAt: z.date(),
  note: z.string().nullish(),
  partnerId: z.number().int(),
  v2StoreVoucherId: z.number().int().nullish(),
})

export interface CompleteProcedureOrder extends z.infer<typeof ProcedureOrderModel> {
  partner: CompletePartner
}

/**
 * RelatedProcedureOrderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureOrderModel: z.ZodSchema<CompleteProcedureOrder> = z.lazy(() => ProcedureOrderModel.extend({
  partner: RelatedPartnerModel,
}))
