import { z } from 'zod'
import { DeliveryWhereInputObjectSchema } from './objects/DeliveryWhereInput.schema'
import { DeliveryOrderByWithAggregationInputObjectSchema } from './objects/DeliveryOrderByWithAggregationInput.schema'
import { DeliveryScalarWhereWithAggregatesInputObjectSchema } from './objects/DeliveryScalarWhereWithAggregatesInput.schema'
import { DeliveryScalarFieldEnumSchema } from './enums/DeliveryScalarFieldEnum.schema'

export const DeliveryGroupBySchema = z.object({
  where: DeliveryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    DeliveryOrderByWithAggregationInputObjectSchema,
    DeliveryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: DeliveryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(DeliveryScalarFieldEnumSchema),
})
