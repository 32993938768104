import { z } from 'zod'
import { HomeBannerWhereInputObjectSchema } from './objects/HomeBannerWhereInput.schema'
import { HomeBannerOrderByWithAggregationInputObjectSchema } from './objects/HomeBannerOrderByWithAggregationInput.schema'
import { HomeBannerScalarWhereWithAggregatesInputObjectSchema } from './objects/HomeBannerScalarWhereWithAggregatesInput.schema'
import { HomeBannerScalarFieldEnumSchema } from './enums/HomeBannerScalarFieldEnum.schema'

export const HomeBannerGroupBySchema = z.object({
  where: HomeBannerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    HomeBannerOrderByWithAggregationInputObjectSchema,
    HomeBannerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: HomeBannerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(HomeBannerScalarFieldEnumSchema),
})
