import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ConfigurationUncheckedCreateInput> = z
  .object({
    key: z.string(),
    value: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
  })
  .strict()

export const ConfigurationUncheckedCreateInputObjectSchema = Schema
