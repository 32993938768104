import { z } from 'zod'
import { BrandBannerWhereUniqueInputObjectSchema } from './BrandBannerWhereUniqueInput.schema'
import { BrandBannerUpdateWithoutBrandInputObjectSchema } from './BrandBannerUpdateWithoutBrandInput.schema'
import { BrandBannerUncheckedUpdateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedUpdateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerUpdateWithWhereUniqueWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => BrandBannerUpdateWithoutBrandInputObjectSchema),
        z.lazy(() => BrandBannerUncheckedUpdateWithoutBrandInputObjectSchema),
      ]),
    })
    .strict()

export const BrandBannerUpdateWithWhereUniqueWithoutBrandInputObjectSchema =
  Schema
