import { z } from 'zod'
import { CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema } from './objects/CaseSummaryDiagnosisOrderByWithRelationInput.schema'
import { CaseSummaryDiagnosisWhereInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereInput.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisScalarFieldEnumSchema } from './enums/CaseSummaryDiagnosisScalarFieldEnum.schema'

export const CaseSummaryDiagnosisFindManySchema = z.object({
  orderBy: z
    .union([
      CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema,
      CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CaseSummaryDiagnosisWhereInputObjectSchema.optional(),
  cursor: CaseSummaryDiagnosisWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CaseSummaryDiagnosisScalarFieldEnumSchema).optional(),
})
