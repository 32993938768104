import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NullableEnumUnitFieldUpdateOperationsInput> = z
  .object({
    set: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const NullableEnumUnitFieldUpdateOperationsInputObjectSchema = Schema
