import { z } from 'zod'
import { CoinTransactionUpdateInputObjectSchema } from './objects/CoinTransactionUpdateInput.schema'
import { CoinTransactionUncheckedUpdateInputObjectSchema } from './objects/CoinTransactionUncheckedUpdateInput.schema'
import { CoinTransactionWhereUniqueInputObjectSchema } from './objects/CoinTransactionWhereUniqueInput.schema'

export const CoinTransactionUpdateOneSchema = z.object({
  data: z.union([
    CoinTransactionUpdateInputObjectSchema,
    CoinTransactionUncheckedUpdateInputObjectSchema,
  ]),
  where: CoinTransactionWhereUniqueInputObjectSchema,
})
