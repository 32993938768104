import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { EnumDiscountScopeWithAggregatesFilterObjectSchema } from './EnumDiscountScopeWithAggregatesFilter.schema'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'
import { EnumDiscountItemTypeWithAggregatesFilterObjectSchema } from './EnumDiscountItemTypeWithAggregatesFilter.schema'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { JsonNullableWithAggregatesFilterObjectSchema } from './JsonNullableWithAggregatesFilter.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingScalarWhereWithAggregatesInput> =
  z
    .object({
      AND: z
        .union([
          z.lazy(
            () => DiscountSettingScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      OR: z
        .lazy(() => DiscountSettingScalarWhereWithAggregatesInputObjectSchema)
        .array()
        .optional(),
      NOT: z
        .union([
          z.lazy(
            () => DiscountSettingScalarWhereWithAggregatesInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingScalarWhereWithAggregatesInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      id: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
      createdAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.lazy(() => DateTimeWithAggregatesFilterObjectSchema),
          z.date(),
        ])
        .optional(),
      scope: z
        .union([
          z.lazy(() => EnumDiscountScopeWithAggregatesFilterObjectSchema),
          z.lazy(() => DiscountScopeSchema),
        ])
        .optional(),
      itemType: z
        .union([
          z.lazy(() => EnumDiscountItemTypeWithAggregatesFilterObjectSchema),
          z.lazy(() => DiscountItemTypeSchema),
        ])
        .optional(),
      items: z
        .lazy(() => JsonNullableWithAggregatesFilterObjectSchema)
        .optional(),
      settingGroupId: z
        .union([
          z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
          z.string(),
        ])
        .optional()
        .nullable(),
      groupId: z
        .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
        .optional(),
    })
    .strict()

export const DiscountSettingScalarWhereWithAggregatesInputObjectSchema = Schema
