import { z } from 'zod'
import { SegmentOrderByWithRelationInputObjectSchema } from './objects/SegmentOrderByWithRelationInput.schema'
import { SegmentWhereInputObjectSchema } from './objects/SegmentWhereInput.schema'
import { SegmentWhereUniqueInputObjectSchema } from './objects/SegmentWhereUniqueInput.schema'
import { SegmentCountAggregateInputObjectSchema } from './objects/SegmentCountAggregateInput.schema'
import { SegmentMinAggregateInputObjectSchema } from './objects/SegmentMinAggregateInput.schema'
import { SegmentMaxAggregateInputObjectSchema } from './objects/SegmentMaxAggregateInput.schema'
import { SegmentAvgAggregateInputObjectSchema } from './objects/SegmentAvgAggregateInput.schema'
import { SegmentSumAggregateInputObjectSchema } from './objects/SegmentSumAggregateInput.schema'

export const SegmentAggregateSchema = z.object({
  orderBy: z
    .union([
      SegmentOrderByWithRelationInputObjectSchema,
      SegmentOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SegmentWhereInputObjectSchema.optional(),
  cursor: SegmentWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), SegmentCountAggregateInputObjectSchema])
    .optional(),
  _min: SegmentMinAggregateInputObjectSchema.optional(),
  _max: SegmentMaxAggregateInputObjectSchema.optional(),
  _avg: SegmentAvgAggregateInputObjectSchema.optional(),
  _sum: SegmentSumAggregateInputObjectSchema.optional(),
})
