import { z } from 'zod'
import { FlashsaleWhereInputObjectSchema } from './FlashsaleWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleRelationFilter> = z
  .object({
    is: z.lazy(() => FlashsaleWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => FlashsaleWhereInputObjectSchema).optional(),
  })
  .strict()

export const FlashsaleRelationFilterObjectSchema = Schema
