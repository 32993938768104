import { z } from 'zod'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { NestedEnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema } from './NestedEnumDeclinedSuggestedItemsReasonNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumDeclinedSuggestedItemsReasonNullableFilter> =
  z
    .object({
      equals: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => DeclinedSuggestedItemsReasonSchema),
          z.lazy(
            () =>
              NestedEnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
    })
    .strict()

export const EnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema = Schema
