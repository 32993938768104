import { z } from 'zod'
import { OfficeHourCreateWithoutBranchInputObjectSchema } from './OfficeHourCreateWithoutBranchInput.schema'
import { OfficeHourUncheckedCreateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedCreateWithoutBranchInput.schema'
import { OfficeHourCreateOrConnectWithoutBranchInputObjectSchema } from './OfficeHourCreateOrConnectWithoutBranchInput.schema'
import { OfficeHourUpsertWithWhereUniqueWithoutBranchInputObjectSchema } from './OfficeHourUpsertWithWhereUniqueWithoutBranchInput.schema'
import { OfficeHourCreateManyBranchInputEnvelopeObjectSchema } from './OfficeHourCreateManyBranchInputEnvelope.schema'
import { OfficeHourWhereUniqueInputObjectSchema } from './OfficeHourWhereUniqueInput.schema'
import { OfficeHourUpdateWithWhereUniqueWithoutBranchInputObjectSchema } from './OfficeHourUpdateWithWhereUniqueWithoutBranchInput.schema'
import { OfficeHourUpdateManyWithWhereWithoutBranchInputObjectSchema } from './OfficeHourUpdateManyWithWhereWithoutBranchInput.schema'
import { OfficeHourScalarWhereInputObjectSchema } from './OfficeHourScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUncheckedUpdateManyWithoutBranchNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema),
          z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema).array(),
          z.lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema),
          z
            .lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => OfficeHourCreateOrConnectWithoutBranchInputObjectSchema),
          z
            .lazy(() => OfficeHourCreateOrConnectWithoutBranchInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () => OfficeHourUpsertWithWhereUniqueWithoutBranchInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OfficeHourUpsertWithWhereUniqueWithoutBranchInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => OfficeHourCreateManyBranchInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
          z.lazy(() => OfficeHourWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () => OfficeHourUpdateWithWhereUniqueWithoutBranchInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                OfficeHourUpdateWithWhereUniqueWithoutBranchInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () => OfficeHourUpdateManyWithWhereWithoutBranchInputObjectSchema,
          ),
          z
            .lazy(
              () => OfficeHourUpdateManyWithWhereWithoutBranchInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => OfficeHourScalarWhereInputObjectSchema),
          z.lazy(() => OfficeHourScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const OfficeHourUncheckedUpdateManyWithoutBranchNestedInputObjectSchema =
  Schema
