import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './app/app'
console.log(import.meta.env.NX_DEEPLINK_PATIENT_APP)
console.log(import.meta.env.NX_DEEPLINK_PATIENT_APP)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
