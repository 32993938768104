import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { NullableEnumGenderFieldUpdateOperationsInputObjectSchema } from './NullableEnumGenderFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { KycDataUncheckedUpdateOneWithoutPatientNestedInputObjectSchema } from './KycDataUncheckedUpdateOneWithoutPatientNestedInput.schema'
import { HealthInfoUncheckedUpdateOneWithoutPatientNestedInputObjectSchema } from './HealthInfoUncheckedUpdateOneWithoutPatientNestedInput.schema'
import { NotificationPatientUncheckedUpdateManyWithoutPatientNestedInputObjectSchema } from './NotificationPatientUncheckedUpdateManyWithoutPatientNestedInput.schema'
import { AddressUncheckedUpdateManyWithoutPatientNestedInputObjectSchema } from './AddressUncheckedUpdateManyWithoutPatientNestedInput.schema'
import { IdentityUncheckedUpdateManyWithoutPatientNestedInputObjectSchema } from './IdentityUncheckedUpdateManyWithoutPatientNestedInput.schema'
import { CoinTransactionUncheckedUpdateManyWithoutPatientNestedInputObjectSchema } from './CoinTransactionUncheckedUpdateManyWithoutPatientNestedInput.schema'
import { MyReviewUncheckedUpdateManyWithoutPatientNestedInputObjectSchema } from './MyReviewUncheckedUpdateManyWithoutPatientNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUncheckedUpdateWithoutAcceptedConsentsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      prefix: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      firstname: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      lastname: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      nickname: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      gender: z
        .union([
          z.lazy(() => GenderSchema),
          z.lazy(
            () => NullableEnumGenderFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      birthDate: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      picture: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      isTest: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      registered: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      drugAllergy: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      congenitalDisease: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      receiveNotifications: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      activityNotifications: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      chatNotifications: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      orderNotifications: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      omiseCustomerId: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      freshchatUserId: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      freshchatRestoreId: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      coin: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2PatientId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      kycData: z
        .lazy(
          () => KycDataUncheckedUpdateOneWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      healthCheckUpInfo: z
        .lazy(
          () =>
            HealthInfoUncheckedUpdateOneWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      notifications: z
        .lazy(
          () =>
            NotificationPatientUncheckedUpdateManyWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      address: z
        .lazy(
          () => AddressUncheckedUpdateManyWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      identities: z
        .lazy(
          () =>
            IdentityUncheckedUpdateManyWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      coinTransactions: z
        .lazy(
          () =>
            CoinTransactionUncheckedUpdateManyWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
      myReviews: z
        .lazy(
          () =>
            MyReviewUncheckedUpdateManyWithoutPatientNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const PatientUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema =
  Schema
