import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryUpdateWithoutOrderInputObjectSchema } from './DeliveryUpdateWithoutOrderInput.schema'
import { DeliveryUncheckedUpdateWithoutOrderInputObjectSchema } from './DeliveryUncheckedUpdateWithoutOrderInput.schema'
import { DeliveryCreateWithoutOrderInputObjectSchema } from './DeliveryCreateWithoutOrderInput.schema'
import { DeliveryUncheckedCreateWithoutOrderInputObjectSchema } from './DeliveryUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpsertWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => DeliveryUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => DeliveryUncheckedUpdateWithoutOrderInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema),
        z.lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const DeliveryUpsertWithWhereUniqueWithoutOrderInputObjectSchema = Schema
