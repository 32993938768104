import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './objects/ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryCreateInputObjectSchema } from './objects/ProcedureCategoryCreateInput.schema'
import { ProcedureCategoryUncheckedCreateInputObjectSchema } from './objects/ProcedureCategoryUncheckedCreateInput.schema'
import { ProcedureCategoryUpdateInputObjectSchema } from './objects/ProcedureCategoryUpdateInput.schema'
import { ProcedureCategoryUncheckedUpdateInputObjectSchema } from './objects/ProcedureCategoryUncheckedUpdateInput.schema'

export const ProcedureCategoryUpsertSchema = z.object({
  where: ProcedureCategoryWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureCategoryCreateInputObjectSchema,
    ProcedureCategoryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureCategoryUpdateInputObjectSchema,
    ProcedureCategoryUncheckedUpdateInputObjectSchema,
  ]),
})
