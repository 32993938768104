import { z } from 'zod'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'
import { EnumExpireTypeFieldUpdateOperationsInputObjectSchema } from './EnumExpireTypeFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { ProcedurePictureUpdateManyWithoutProcedureNestedInputObjectSchema } from './ProcedurePictureUpdateManyWithoutProcedureNestedInput.schema'
import { ProcedureCategoryUpdateManyWithoutProcedureNestedInputObjectSchema } from './ProcedureCategoryUpdateManyWithoutProcedureNestedInput.schema'
import { FlashsaleItemUpdateManyWithoutProcedureNestedInputObjectSchema } from './FlashsaleItemUpdateManyWithoutProcedureNestedInput.schema'
import { CarouselItemUpdateManyWithoutProcedureNestedInputObjectSchema } from './CarouselItemUpdateManyWithoutProcedureNestedInput.schema'
import { ProcedureReviewUpdateManyWithoutProcedureNestedInputObjectSchema } from './ProcedureReviewUpdateManyWithoutProcedureNestedInput.schema'
import { ProcedureReviewSummaryUpdateOneWithoutProcedureNestedInputObjectSchema } from './ProcedureReviewSummaryUpdateOneWithoutProcedureNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProcedureUpdateInput> = z
  .object({
    active: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    searchable: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    nameTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    searchField: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    technologyTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    usageTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    resultPeriodTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    descriptionTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    preparationTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    conditionTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    methodOfUseTr: z
      .union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ])
      .optional(),
    isTreatedByDoctor: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    expireType: z
      .union([
        z.lazy(() => ExpireTypeSchema),
        z.lazy(() => EnumExpireTypeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    expireAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    expireDurationDay: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    tagPrice: z
      .union([
        z.number(),
        z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    cost: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    commissionRate: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    partnerId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    branches: z
      .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    deletedAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    v2DrugItemId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    pictures: z
      .lazy(
        () => ProcedurePictureUpdateManyWithoutProcedureNestedInputObjectSchema,
      )
      .optional(),
    categories: z
      .lazy(
        () =>
          ProcedureCategoryUpdateManyWithoutProcedureNestedInputObjectSchema,
      )
      .optional(),
    flashsaleItem: z
      .lazy(
        () => FlashsaleItemUpdateManyWithoutProcedureNestedInputObjectSchema,
      )
      .optional(),
    carouselItems: z
      .lazy(() => CarouselItemUpdateManyWithoutProcedureNestedInputObjectSchema)
      .optional(),
    procedureReviews: z
      .lazy(
        () => ProcedureReviewUpdateManyWithoutProcedureNestedInputObjectSchema,
      )
      .optional(),
    procedureReviewSummary: z
      .lazy(
        () =>
          ProcedureReviewSummaryUpdateOneWithoutProcedureNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProcedureUpdateInputObjectSchema = Schema
