import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ReceiptUncheckedCreateWithoutOrderInput> = z
  .object({
    receiptNumber: z.string(),
    receiptUrl: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    receiptData: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    isValid: z.boolean().optional(),
    note: z.string().optional().nullable(),
  })
  .strict()

export const ReceiptUncheckedCreateWithoutOrderInputObjectSchema = Schema
