import { z } from 'zod'
import { TeleconsultCreateWithoutAppointmentInputObjectSchema } from './TeleconsultCreateWithoutAppointmentInput.schema'
import { TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema } from './TeleconsultUncheckedCreateWithoutAppointmentInput.schema'
import { TeleconsultCreateOrConnectWithoutAppointmentInputObjectSchema } from './TeleconsultCreateOrConnectWithoutAppointmentInput.schema'
import { TeleconsultUpsertWithoutAppointmentInputObjectSchema } from './TeleconsultUpsertWithoutAppointmentInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultUpdateWithoutAppointmentInputObjectSchema } from './TeleconsultUpdateWithoutAppointmentInput.schema'
import { TeleconsultUncheckedUpdateWithoutAppointmentInputObjectSchema } from './TeleconsultUncheckedUpdateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpdateOneWithoutAppointmentNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => TeleconsultCreateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => TeleconsultCreateOrConnectWithoutAppointmentInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => TeleconsultUpsertWithoutAppointmentInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => TeleconsultUpdateWithoutAppointmentInputObjectSchema),
          z.lazy(
            () => TeleconsultUncheckedUpdateWithoutAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const TeleconsultUpdateOneWithoutAppointmentNestedInputObjectSchema =
  Schema
