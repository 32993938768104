import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { ConsentRelationFilterObjectSchema } from './ConsentRelationFilter.schema'
import { ConsentWhereInputObjectSchema } from './ConsentWhereInput.schema'
import { PatientRelationFilterObjectSchema } from './PatientRelationFilter.schema'
import { PatientWhereInputObjectSchema } from './PatientWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => AcceptedConsentWhereInputObjectSchema),
        z.lazy(() => AcceptedConsentWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => AcceptedConsentWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => AcceptedConsentWhereInputObjectSchema),
        z.lazy(() => AcceptedConsentWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    consentKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    consent: z
      .union([
        z.lazy(() => ConsentRelationFilterObjectSchema),
        z.lazy(() => ConsentWhereInputObjectSchema),
      ])
      .optional(),
    patient: z
      .union([
        z.lazy(() => PatientRelationFilterObjectSchema),
        z.lazy(() => PatientWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const AcceptedConsentWhereInputObjectSchema = Schema
