import { z } from 'zod'
import { ProductPictureCreateInputObjectSchema } from './objects/ProductPictureCreateInput.schema'
import { ProductPictureUncheckedCreateInputObjectSchema } from './objects/ProductPictureUncheckedCreateInput.schema'

export const ProductPictureCreateOneSchema = z.object({
  data: z.union([
    ProductPictureCreateInputObjectSchema,
    ProductPictureUncheckedCreateInputObjectSchema,
  ]),
})
