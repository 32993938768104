import { z } from 'zod'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantCreateWithoutFlashsaleItemInputObjectSchema } from './VariantCreateWithoutFlashsaleItemInput.schema'
import { VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema } from './VariantUncheckedCreateWithoutFlashsaleItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateOrConnectWithoutFlashsaleItemInput> =
  z
    .object({
      where: z.lazy(() => VariantWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => VariantCreateWithoutFlashsaleItemInputObjectSchema),
        z.lazy(
          () => VariantUncheckedCreateWithoutFlashsaleItemInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantCreateOrConnectWithoutFlashsaleItemInputObjectSchema =
  Schema
