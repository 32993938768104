import AgoraRTC, {
  AgoraVideoPlayer,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  createClient,
  createMicrophoneAndCameraTracks,
} from 'agora-rtc-react'
import React, { FC, useEffect, useState } from 'react'

interface VideoChatProps {
  appId: string
  channelName: string
  token: string
  uid: number | string | null
  status: string
}
const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}
const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()

export const AgoraVideo: FC<VideoChatProps> = ({
  appId,
  channelName,
  token,
  uid,
  status,
}) => {
  const [inCall, setInCall] = useState(false)
  return (
    <div>
      <h1 className="heading">Agora RTC NG SDK React Wrapper</h1>
      {inCall ? (
        <VideoCall
          setInCall={setInCall}
          appId={appId}
          channelName={channelName}
          token={token}
          uid={uid}
          status={status}
        />
      ) : (
        <ChannelForm setInCall={setInCall} />
      )}
    </div>
  )
}
const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  appId: string
  channelName: string
  token: string
  uid: number | string | null
  status: string
}) => {
  const { setInCall, appId, channelName, token, uid, status } = props
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const [start, setStart] = useState<boolean>(false)
  const client = useClient()
  const { ready, tracks } = useMicrophoneAndCameraTracks()
  useEffect(() => {
    // function to initialise the SDK
    const init = async (
      appId: string,
      channelName: string,
      token: string,
      uid: number | string | null,
      status: string,
    ) => {
      console.log('init', channelName)
      AgoraRTC.setLogLevel(4)
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType)
        console.log('subscribe success')
        if (mediaType === 'video') {
          setUsers((prevUsers) => {
            return [...prevUsers, user]
          })
        }
        if (mediaType === 'audio') {
          user.audioTrack?.play()
        }
      })

      client.on('user-unpublished', (user, type) => {
        console.log('unpublished', user, type)
        if (type === 'audio') {
          user.audioTrack?.stop()
        }
        if (type === 'video') {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid)
          })
        }
      })

      client.on('user-left', (user) => {
        console.log('leaving', user)
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid)
        })
      })

      await client.join(appId, channelName, token, uid)
      if (tracks) await client.publish([tracks[0], tracks[1]])
      setStart(true)
    }

    if (ready && tracks) {
      console.log('init ready')
      init(appId, channelName, token, uid, status)
    }
  }, [channelName, client, ready, tracks, status, appId, token, uid])
  return (
    <div className="App">
      {ready && tracks && (
        <Controls
          tracks={tracks}
          setStart={setStart}
          setInCall={setInCall}
          status={status}
        />
      )}
      {start && tracks && <Videos users={users} tracks={tracks} />}
    </div>
  )
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[]
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
}) => {
  const { users, tracks } = props

  return (
    <div style={{ display: 'flex', width: '100%', height: '400px' }}>
      <div
        id="videos"
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <AgoraVideoPlayer
          className="vid"
          videoTrack={tracks[1]}
          style={{ height: '95%', width: '95%' }}
        />
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer
                  className="vid"
                  videoTrack={user.videoTrack}
                  style={{ height: '95%', width: '95%' }}
                  key={user.uid}
                />
              )
            } else return null
          })}
      </div>
    </div>
  )
}
export const Controls = (props: {
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
  setStart: React.Dispatch<React.SetStateAction<boolean>>
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  status: string
}) => {
  const client = useClient()
  const { tracks, setStart, setInCall, status } = props
  const [trackState, setTrackState] = useState({ video: true, audio: true })
  const mute = async (type: 'audio' | 'video') => {
    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio)
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio }
      })
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video)
      setTrackState((ps) => {
        return { ...ps, video: !ps.video }
      })
    }
  }

  const leaveChannel = async () => {
    await client.leave()
    client.removeAllListeners()
    tracks[0].close()
    tracks[1].close()
    setStart(false)
    setInCall(false)
  }

  useEffect(() => {
    if (status === 'SUCCESS' || status === 'UNSUCCESS') {
      leaveChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <div className="controls">
      <button
        className={trackState.audio ? 'on' : ''}
        onClick={() => mute('audio')}
      >
        {trackState.audio ? 'MuteAudio' : 'UnmuteAudio'}
      </button>
      <button
        className={trackState.video ? 'on' : ''}
        onClick={() => mute('video')}
      >
        {trackState.video ? 'MuteVideo' : 'UnmuteVideo'}
      </button>
      {<button onClick={() => leaveChannel()}>Leave</button>}
    </div>
  )
}

const ChannelForm = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { setInCall } = props

  return (
    <form className="join">
      <button
        onClick={(e) => {
          e.preventDefault()
          setInCall(true)
        }}
      >
        Join
      </button>
    </form>
  )
}
