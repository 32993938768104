import { z } from 'zod'
import { CoverPictureScalarWhereInputObjectSchema } from './CoverPictureScalarWhereInput.schema'
import { CoverPictureUpdateManyMutationInputObjectSchema } from './CoverPictureUpdateManyMutationInput.schema'
import { CoverPictureUncheckedUpdateManyWithoutCoverPicturesInputObjectSchema } from './CoverPictureUncheckedUpdateManyWithoutCoverPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureUpdateManyWithWhereWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => CoverPictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CoverPictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            CoverPictureUncheckedUpdateManyWithoutCoverPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoverPictureUpdateManyWithWhereWithoutPartnerInputObjectSchema =
  Schema
