import { z } from 'zod'
import { CaseSummaryWhereInputObjectSchema } from './objects/CaseSummaryWhereInput.schema'
import { CaseSummaryOrderByWithAggregationInputObjectSchema } from './objects/CaseSummaryOrderByWithAggregationInput.schema'
import { CaseSummaryScalarWhereWithAggregatesInputObjectSchema } from './objects/CaseSummaryScalarWhereWithAggregatesInput.schema'
import { CaseSummaryScalarFieldEnumSchema } from './enums/CaseSummaryScalarFieldEnum.schema'

export const CaseSummaryGroupBySchema = z.object({
  where: CaseSummaryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    CaseSummaryOrderByWithAggregationInputObjectSchema,
    CaseSummaryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: CaseSummaryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(CaseSummaryScalarFieldEnumSchema),
})
