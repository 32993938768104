import { z } from 'zod'
import { ShippingProviderArgsObjectSchema } from './ShippingProviderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaSelect> = z
  .object({
    id: z.boolean().optional(),
    postCode: z.boolean().optional(),
    shippingProvider: z
      .union([z.boolean(), z.lazy(() => ShippingProviderArgsObjectSchema)])
      .optional(),
    shippingProviderId: z.boolean().optional(),
  })
  .strict()

export const ExpressShippingAreaSelectObjectSchema = Schema
