import { z } from 'zod'
import { OfficeHourCreateInputObjectSchema } from './objects/OfficeHourCreateInput.schema'
import { OfficeHourUncheckedCreateInputObjectSchema } from './objects/OfficeHourUncheckedCreateInput.schema'

export const OfficeHourCreateOneSchema = z.object({
  data: z.union([
    OfficeHourCreateInputObjectSchema,
    OfficeHourUncheckedCreateInputObjectSchema,
  ]),
})
