import * as z from "zod"
import { CompleteConsent, RelatedConsentModel, CompletePatient, RelatedPatientModel } from "./index"

export const AcceptedConsentModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  consentKey: z.string(),
  patientId: z.number().int(),
})

export interface CompleteAcceptedConsent extends z.infer<typeof AcceptedConsentModel> {
  consent: CompleteConsent
  patient: CompletePatient
}

/**
 * RelatedAcceptedConsentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedAcceptedConsentModel: z.ZodSchema<CompleteAcceptedConsent> = z.lazy(() => AcceptedConsentModel.extend({
  consent: RelatedConsentModel,
  patient: RelatedPatientModel,
}))
