import { z } from 'zod'
import { ProductReviewSummaryOrderByWithRelationInputObjectSchema } from './objects/ProductReviewSummaryOrderByWithRelationInput.schema'
import { ProductReviewSummaryWhereInputObjectSchema } from './objects/ProductReviewSummaryWhereInput.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryCountAggregateInputObjectSchema } from './objects/ProductReviewSummaryCountAggregateInput.schema'
import { ProductReviewSummaryMinAggregateInputObjectSchema } from './objects/ProductReviewSummaryMinAggregateInput.schema'
import { ProductReviewSummaryMaxAggregateInputObjectSchema } from './objects/ProductReviewSummaryMaxAggregateInput.schema'
import { ProductReviewSummaryAvgAggregateInputObjectSchema } from './objects/ProductReviewSummaryAvgAggregateInput.schema'

export const ProductReviewSummaryAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductReviewSummaryOrderByWithRelationInputObjectSchema,
      ProductReviewSummaryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewSummaryWhereInputObjectSchema.optional(),
  cursor: ProductReviewSummaryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ProductReviewSummaryCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ProductReviewSummaryMinAggregateInputObjectSchema.optional(),
  _max: ProductReviewSummaryMaxAggregateInputObjectSchema.optional(),
  _avg: ProductReviewSummaryAvgAggregateInputObjectSchema.optional(),
})
