import { z } from 'zod'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { NestedEnumSalesTypeWithAggregatesFilterObjectSchema } from './NestedEnumSalesTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumSalesTypeFilterObjectSchema } from './NestedEnumSalesTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumSalesTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => SalesTypeSchema).optional(),
    in: z
      .lazy(() => SalesTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => SalesTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => SalesTypeSchema),
        z.lazy(() => NestedEnumSalesTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumSalesTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumSalesTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumSalesTypeWithAggregatesFilterObjectSchema = Schema
