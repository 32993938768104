import { z } from 'zod'
import { CaseSummaryDiagnosisCreateManyCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateManyCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisCreateManyCaseSummaryInputObjectSchema,
        ),
        z
          .lazy(
            () => CaseSummaryDiagnosisCreateManyCaseSummaryInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelopeObjectSchema =
  Schema
