import * as z from "zod"
import { CompleteArticle, RelatedArticleModel } from "./index"

export const ArticlePictureModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  articleId: z.number().int(),
  url: z.string(),
  rank: z.number().int(),
})

export interface CompleteArticlePicture extends z.infer<typeof ArticlePictureModel> {
  article: CompleteArticle
}

/**
 * RelatedArticlePictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedArticlePictureModel: z.ZodSchema<CompleteArticlePicture> = z.lazy(() => ArticlePictureModel.extend({
  article: RelatedArticleModel,
}))
