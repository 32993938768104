import { z } from 'zod'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUncheckedCreateWithoutPatientInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      orderId: z.number().optional().nullable(),
      status: z.lazy(() => CoinTransactionStatusSchema),
      type: z.lazy(() => CoinTransactionTypeSchema),
      amount: z.number(),
      note: z.string().optional().nullable(),
    })
    .strict()

export const CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema =
  Schema
