import { Box } from '@mui/material'
import {
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  ListProps,
  Pagination,
  RowClickFunction,
  TextField,
  useRedirect,
} from 'react-admin'
import {
  CustomBooleanField,
  CustomI18nField,
} from '../../components/CustomField'
import { DiscountField } from '../../components/DiscountField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FilterListForm } from './list-section/filterlistForm'

interface ListViewProps extends ListProps {
  rowClick: string | RowClickFunction | false
}

const rowsPerPage = [5, 10, 20]

const CouponListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <ExportButton />
      </Box>
    </Box>
  )
}

export const CouponList = (props: Partial<ListViewProps>) => {
  const redirect = useRedirect()

  const viewResource = (
    action?: string,
    resource?: string,
    id?: number | null,
  ) => {
    if (action && resource && id) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            textDecoration: 'underline',
          }}
          onClick={() => {
            redirect(action, resource, id)
          }}
        >
          {id}
        </Box>
      )
    } else {
      return <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>-</Box>
    }
  }
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={<CouponListAction />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        resource="coupon"
        {...props}
      >
        <Datagrid
          size="small"
          rowClick={props.rowClick !== undefined ? props.rowClick : 'edit'}
          bulkActionButtons={props.actions ? props.actions : false}
        >
          <TextField source="id" />
          <TextField source="code" />
          <TextField source="type" />
          <CustomI18nField source="titleTr" fieldTr="titleTr" />
          <CustomI18nField
            source="descriptionTr"
            fieldTr="descriptionTr"
            ellipsis
          />
          <FunctionField
            source="discount"
            textAlign="center"
            render={(record: { discount: number; discountType: string }) =>
              DiscountField(record.discount, record.discountType)
            }
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
          <LocaleDateField source="startAt" showTime />
          <LocaleDateField source="expireAt" showTime />
          <LocaleDateField source="usedAt" showTime />
          <CustomBooleanField textAlign="center" source="isPublic" />
          <CustomBooleanField textAlign="center" source="isOnlyNewPatient" />
          <LocaleDateField source="createdAt" showTime emptyText="-" />
          <TextField source="orderId" label="Order ID" emptyText="-" />
          <FunctionField
            label="Patient ID"
            render={(record: { patientId: number | null }) =>
              viewResource('edit', 'patient', record.patientId)
            }
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
    </Box>
  )
}
