import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumIdentityTypeFilterObjectSchema } from './EnumIdentityTypeFilter.schema'
import { IdentityTypeSchema } from '../enums/IdentityType.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => IdentityScalarWhereInputObjectSchema),
        z.lazy(() => IdentityScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => IdentityScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => IdentityScalarWhereInputObjectSchema),
        z.lazy(() => IdentityScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    identityId: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumIdentityTypeFilterObjectSchema),
        z.lazy(() => IdentityTypeSchema),
      ])
      .optional(),
    password: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    meta: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const IdentityScalarWhereInputObjectSchema = Schema
