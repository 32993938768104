import { z } from 'zod'
import { HolidayWhereUniqueInputObjectSchema } from './objects/HolidayWhereUniqueInput.schema'
import { HolidayCreateInputObjectSchema } from './objects/HolidayCreateInput.schema'
import { HolidayUncheckedCreateInputObjectSchema } from './objects/HolidayUncheckedCreateInput.schema'
import { HolidayUpdateInputObjectSchema } from './objects/HolidayUpdateInput.schema'
import { HolidayUncheckedUpdateInputObjectSchema } from './objects/HolidayUncheckedUpdateInput.schema'

export const HolidayUpsertSchema = z.object({
  where: HolidayWhereUniqueInputObjectSchema,
  create: z.union([
    HolidayCreateInputObjectSchema,
    HolidayUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    HolidayUpdateInputObjectSchema,
    HolidayUncheckedUpdateInputObjectSchema,
  ]),
})
