import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    caseId: z.number().optional(),
    scheduleId: z.number().optional(),
    teleconsultId: z.number().optional(),
  })
  .strict()

export const AppointmentWhereUniqueInputObjectSchema = Schema
