import { Box } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  ImageField,
  List,
  NumberField,
  Pagination,
  TextField,
} from 'react-admin'
import { CustomI18nField } from '../../components/CustomField'
import { DiscountField } from '../../components/DiscountField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FilterListForm } from './list-section/filterlistForm'

const rowsPerPage = [5, 10, 20]

const CouponGroupListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}

export const CouponGroupList = () => (
  <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
    <List
      disableSyncWithLocation
      perPage={rowsPerPage[2]}
      pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
      actions={<CouponGroupListAction />}
    >
      <Datagrid size="small" rowClick="show" bulkActionButtons={false}>
        <ImageField
          source="picture.src"
          label="resources.couponGroup.fields.picture"
          sx={{
            textAlign: 'center',
            '& img': { width: 'auto !important', height: '100% !important' },
          }}
          textAlign="center"
        />
        <CustomI18nField
          label="resources.couponGroup.fields.titleTr"
          fieldTr="titleTr"
          sortable={false}
        />
        <FunctionField
          label="resources.couponGroup.fields.codeType"
          render={({ prefix }: { prefix?: string }) =>
            prefix ? 'UNIQUE' : 'CODE'
          }
        />
        <FunctionField
          label="resources.couponGroup.fields.code"
          render={({ prefix, code }: { prefix?: string; code?: string }) =>
            [prefix, code].join('')
          }
        />
        <TextField source="type" sortable={false} />
        <FunctionField
          source="discount"
          textAlign="center"
          render={(record: { discount: number; discountType: string }) =>
            DiscountField(record.discount, record.discountType)
          }
        />
        <LocaleDateField source="startAt" sortable={false} />
        <LocaleDateField source="expireAt" sortable={false} />
        <NumberField source="quota" textAlign="center" sortable={false} />
        <FunctionField
          label="resources.couponGroup.fields.inPayment"
          textAlign="center"
          render={({ reserved, used }: { reserved: number; used: number }) =>
            reserved - used
          }
        />
        <NumberField source="used" textAlign="center" sortable={false} />
      </Datagrid>
    </List>
  </Box>
)
