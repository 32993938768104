import { z } from 'zod'
import { NotificationCreateWithoutPatientsInputObjectSchema } from './NotificationCreateWithoutPatientsInput.schema'
import { NotificationUncheckedCreateWithoutPatientsInputObjectSchema } from './NotificationUncheckedCreateWithoutPatientsInput.schema'
import { NotificationCreateOrConnectWithoutPatientsInputObjectSchema } from './NotificationCreateOrConnectWithoutPatientsInput.schema'
import { NotificationUpsertWithoutPatientsInputObjectSchema } from './NotificationUpsertWithoutPatientsInput.schema'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'
import { NotificationUpdateWithoutPatientsInputObjectSchema } from './NotificationUpdateWithoutPatientsInput.schema'
import { NotificationUncheckedUpdateWithoutPatientsInputObjectSchema } from './NotificationUncheckedUpdateWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpdateOneRequiredWithoutPatientsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => NotificationCreateWithoutPatientsInputObjectSchema),
          z.lazy(
            () => NotificationUncheckedCreateWithoutPatientsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => NotificationCreateOrConnectWithoutPatientsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => NotificationUpsertWithoutPatientsInputObjectSchema)
        .optional(),
      connect: z
        .lazy(() => NotificationWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(() => NotificationUpdateWithoutPatientsInputObjectSchema),
          z.lazy(
            () => NotificationUncheckedUpdateWithoutPatientsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const NotificationUpdateOneRequiredWithoutPatientsNestedInputObjectSchema =
  Schema
