import { z } from 'zod'
import { SkuCreateInputObjectSchema } from './objects/SkuCreateInput.schema'
import { SkuUncheckedCreateInputObjectSchema } from './objects/SkuUncheckedCreateInput.schema'

export const SkuCreateOneSchema = z.object({
  data: z.union([
    SkuCreateInputObjectSchema,
    SkuUncheckedCreateInputObjectSchema,
  ]),
})
