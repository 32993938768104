import { z } from 'zod'

export const UseTimeSchema = z.enum([
  'AS_NEED',
  'MORNING',
  'NOON',
  'EVENING',
  'BEFORE_BEDTIME',
  'MORNING_AND_EVENING',
  'MORNING_AND_BEFORE_BEDTIME',
  'NOON_AND_BEFORE_BEDTIME',
  'MORNING_NOON_AND_EVENING',
  'MORNING_NOON_EVENING_AND_BEFORE_BEDTIME',
  'EVERY_MONDAY',
  'EVERY_TUESDAY',
  'EVERY_WEDNESDAY',
  'EVERY_THURSDAY',
  'EVERY_FRIDAY',
  'EVERY_SATURDAY',
  'EVERY_SUNDAY',
  'TUESDAY_AND_THURSDAY',
  'MONDAY_WEDNESDAY_AND_FRIDAY',
  'ON_EVEN_DAY',
  'ON_ODD_DAY',
  'AT_6_10_14_18_22_HR',
  'IMMEDIATELY',
  'NA',
])
