import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ConsentOrderByWithRelationInputObjectSchema } from './ConsentOrderByWithRelationInput.schema'
import { PatientOrderByWithRelationInputObjectSchema } from './PatientOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    consentKey: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    consent: z
      .lazy(() => ConsentOrderByWithRelationInputObjectSchema)
      .optional(),
    patient: z
      .lazy(() => PatientOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const AcceptedConsentOrderByWithRelationInputObjectSchema = Schema
