import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    nameTr: z.literal(true).optional(),
    address: z.literal(true).optional(),
    subDistrict: z.literal(true).optional(),
    subDistrictKey: z.literal(true).optional(),
    district: z.literal(true).optional(),
    districtKey: z.literal(true).optional(),
    province: z.literal(true).optional(),
    provinceKey: z.literal(true).optional(),
    postcode: z.literal(true).optional(),
    googleMapUrl: z.literal(true).optional(),
    lat: z.literal(true).optional(),
    long: z.literal(true).optional(),
    phone: z.literal(true).optional(),
    active: z.literal(true).optional(),
    isExpertise: z.literal(true).optional(),
    remark: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    v2BranchId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const BranchCountAggregateInputObjectSchema = Schema
