import { z } from 'zod'
import { BranchOrderByWithRelationInputObjectSchema } from './objects/BranchOrderByWithRelationInput.schema'
import { BranchWhereInputObjectSchema } from './objects/BranchWhereInput.schema'
import { BranchWhereUniqueInputObjectSchema } from './objects/BranchWhereUniqueInput.schema'
import { BranchCountAggregateInputObjectSchema } from './objects/BranchCountAggregateInput.schema'
import { BranchMinAggregateInputObjectSchema } from './objects/BranchMinAggregateInput.schema'
import { BranchMaxAggregateInputObjectSchema } from './objects/BranchMaxAggregateInput.schema'
import { BranchAvgAggregateInputObjectSchema } from './objects/BranchAvgAggregateInput.schema'
import { BranchSumAggregateInputObjectSchema } from './objects/BranchSumAggregateInput.schema'

export const BranchAggregateSchema = z.object({
  orderBy: z
    .union([
      BranchOrderByWithRelationInputObjectSchema,
      BranchOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: BranchWhereInputObjectSchema.optional(),
  cursor: BranchWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), BranchCountAggregateInputObjectSchema])
    .optional(),
  _min: BranchMinAggregateInputObjectSchema.optional(),
  _max: BranchMaxAggregateInputObjectSchema.optional(),
  _avg: BranchAvgAggregateInputObjectSchema.optional(),
  _sum: BranchSumAggregateInputObjectSchema.optional(),
})
