import { z } from 'zod'

export const AuxiliaryLabelSchema = z.enum([
  'FOR_EXTERNAL_USE_ONLY',
  'CONTINUE_THIS_MEDICATION_UNTIL_FINISHED',
  'CONSULT_DOCTOR_OR_PHARMACIST_IF_PREGNANT_OR_BREASTFEEDING',
  'AS_NEEDED',
  'TAKE_AFTER_MEAL_IMMEDIATELY_DO_NOT_TAKE_ON_EMPTY_STOMACH',
  'MAY_MAKE_YOU_FEEL_DROWSY',
  'ONCE_MIXED_CAN_ONLY_BE_KEPT_7_DAYS',
  'ONCE_MIXED_KEEP_IN_REFRIGERATOR_DO_NOT_FREEZE',
  'USE_THIS_MEDICATION_ACCORDING_TO_DOCTOR_ADVICE',
  'DO_NOT_TAKE_WITH_MILK_ANTACIDS_CALCIUM_IRON_ZINC_MAGNESIUM_MANGANESE',
  'SEE_PACKAGE_INSERT_FOR_COMPLETE_INFORMATION',
  'TAKE_AS_INSTRUCTED',
  'THIS_MEDICINE_MUST_BE_STORED_AT_2_TO_8_CELSIUS',
  'DO_NOT_DONATE_BLOOD_WHILE_TAKING_THIS_MEDICINE',
  'MUST_USE_BIRTH_CONTROL_WHILE_TAKING_THIS_MEDICINE',
])
