import { z } from 'zod'
import { ArticleCreateWithoutPicturesInputObjectSchema } from './ArticleCreateWithoutPicturesInput.schema'
import { ArticleUncheckedCreateWithoutPicturesInputObjectSchema } from './ArticleUncheckedCreateWithoutPicturesInput.schema'
import { ArticleCreateOrConnectWithoutPicturesInputObjectSchema } from './ArticleCreateOrConnectWithoutPicturesInput.schema'
import { ArticleUpsertWithoutPicturesInputObjectSchema } from './ArticleUpsertWithoutPicturesInput.schema'
import { ArticleWhereUniqueInputObjectSchema } from './ArticleWhereUniqueInput.schema'
import { ArticleUpdateWithoutPicturesInputObjectSchema } from './ArticleUpdateWithoutPicturesInput.schema'
import { ArticleUncheckedUpdateWithoutPicturesInputObjectSchema } from './ArticleUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ArticleCreateWithoutPicturesInputObjectSchema),
          z.lazy(() => ArticleUncheckedCreateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ArticleCreateOrConnectWithoutPicturesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ArticleUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ArticleWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ArticleUpdateWithoutPicturesInputObjectSchema),
          z.lazy(() => ArticleUncheckedUpdateWithoutPicturesInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const ArticleUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
