import { z } from 'zod'
import { CasePictureWhereUniqueInputObjectSchema } from './CasePictureWhereUniqueInput.schema'
import { CasePictureUpdateWithoutCaseInputObjectSchema } from './CasePictureUpdateWithoutCaseInput.schema'
import { CasePictureUncheckedUpdateWithoutCaseInputObjectSchema } from './CasePictureUncheckedUpdateWithoutCaseInput.schema'
import { CasePictureCreateWithoutCaseInputObjectSchema } from './CasePictureCreateWithoutCaseInput.schema'
import { CasePictureUncheckedCreateWithoutCaseInputObjectSchema } from './CasePictureUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureUpsertWithWhereUniqueWithoutCaseInput> =
  z
    .object({
      where: z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => CasePictureUpdateWithoutCaseInputObjectSchema),
        z.lazy(() => CasePictureUncheckedUpdateWithoutCaseInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => CasePictureCreateWithoutCaseInputObjectSchema),
        z.lazy(() => CasePictureUncheckedCreateWithoutCaseInputObjectSchema),
      ]),
    })
    .strict()

export const CasePictureUpsertWithWhereUniqueWithoutCaseInputObjectSchema =
  Schema
