import { z } from 'zod'
import { ArticleArgsObjectSchema } from './ArticleArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureInclude> = z
  .object({
    article: z
      .union([z.boolean(), z.lazy(() => ArticleArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ArticlePictureIncludeObjectSchema = Schema
