import { z } from 'zod'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'
import { NestedEnumDiscountScopeWithAggregatesFilterObjectSchema } from './NestedEnumDiscountScopeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumDiscountScopeFilterObjectSchema } from './NestedEnumDiscountScopeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountScopeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => DiscountScopeSchema).optional(),
    in: z
      .lazy(() => DiscountScopeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountScopeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountScopeSchema),
        z.lazy(() => NestedEnumDiscountScopeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumDiscountScopeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumDiscountScopeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumDiscountScopeWithAggregatesFilterObjectSchema = Schema
