import { z } from 'zod'

export const BrandScalarFieldEnumSchema = z.enum([
  'id',
  'name',
  'isShow',
  'coporateName',
  'countryName',
  'logo',
  'priority',
  'createdAt',
  'updatedAt',
])
