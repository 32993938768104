import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCountOutputTypeSelect> = z
  .object({
    items: z.boolean().optional(),
  })
  .strict()

export const FlashsaleCountOutputTypeSelectObjectSchema = Schema
