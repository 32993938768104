import { z } from 'zod'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './objects/ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalCreateInputObjectSchema } from './objects/ProductItemOriginalCreateInput.schema'
import { ProductItemOriginalUncheckedCreateInputObjectSchema } from './objects/ProductItemOriginalUncheckedCreateInput.schema'
import { ProductItemOriginalUpdateInputObjectSchema } from './objects/ProductItemOriginalUpdateInput.schema'
import { ProductItemOriginalUncheckedUpdateInputObjectSchema } from './objects/ProductItemOriginalUncheckedUpdateInput.schema'

export const ProductItemOriginalUpsertSchema = z.object({
  where: ProductItemOriginalWhereUniqueInputObjectSchema,
  create: z.union([
    ProductItemOriginalCreateInputObjectSchema,
    ProductItemOriginalUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductItemOriginalUpdateInputObjectSchema,
    ProductItemOriginalUncheckedUpdateInputObjectSchema,
  ]),
})
