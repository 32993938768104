import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { required, useEditContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import { FormTextInput } from '../../../components/FormInput/input'
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor'
import { validateHttpsUrl } from '../../../utils'

export const PartnerDescription: FC = () => {
  const { setValue } = useFormContext()
  const { record } = useEditContext()

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        รายละเอียด
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="inherit">รายละเอียด </Typography>
          <RichTextEditor
            detailValue={record?.descriptionTr?.th}
            saveContent={(value: string) => {
              setValue('descriptionTr.th', value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="inherit">รายละเอียด (Eng.)</Typography>
          <RichTextEditor
            detailValue={record?.descriptionTr?.en}
            saveContent={(value: string) => {
              setValue('descriptionTr.en', value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="Website"
            source="website"
            validate={validateHttpsUrl}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="เบอร์ติดต่อ"
            source="phone"
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  )
}
