import { z } from 'zod'
import { ProcedureReviewCreateInputObjectSchema } from './objects/ProcedureReviewCreateInput.schema'
import { ProcedureReviewUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewUncheckedCreateInput.schema'

export const ProcedureReviewCreateOneSchema = z.object({
  data: z.union([
    ProcedureReviewCreateInputObjectSchema,
    ProcedureReviewUncheckedCreateInputObjectSchema,
  ]),
})
