import { z } from 'zod'
import { CaseSummaryCreateWithoutCaseInputObjectSchema } from './CaseSummaryCreateWithoutCaseInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseInput.schema'
import { CaseSummaryCreateOrConnectWithoutCaseInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutCaseInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUncheckedCreateNestedOneWithoutCaseInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseSummaryCreateWithoutCaseInputObjectSchema),
          z.lazy(() => CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseSummaryCreateOrConnectWithoutCaseInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseSummaryUncheckedCreateNestedOneWithoutCaseInputObjectSchema =
  Schema
