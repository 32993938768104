import { FileValue } from '../../components/types'

export const transformDoctorKycData = async (form: {
  picture: FileValue
  bankPicture: FileValue
  idCardPicture: FileValue
  licensePicture: FileValue
  certificate: FileValue[]
  validator: { [key: string]: string }
  errorReason: { [key: string]: string }
}) => {
  const {
    picture,
    bankPicture,
    idCardPicture,
    licensePicture,
    certificate,
    validator,
    errorReason,
  } = form
  const result: { [key: string]: unknown } = form

  if (picture?.uploadPromise) {
    const url = await picture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.picture = {
        ...picture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (bankPicture?.uploadPromise) {
    const url = await bankPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.bankPicture = {
        ...bankPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (idCardPicture?.uploadPromise) {
    const url = await idCardPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.idCardPicture = {
        ...idCardPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (licensePicture?.uploadPromise) {
    const url = await licensePicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.licensePicture = {
        ...licensePicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }

  const formCert = [] as unknown[]
  if (certificate) {
    for (const cert of certificate) {
      if (cert.uploadPromise) {
        const url = await cert.uploadPromise
        if (url && !Array.isArray(url)) {
          formCert.push({
            ...cert,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload failed')
        }
      } else {
        formCert.push(cert)
      }
    }
  }
  result.certificate = formCert

  const rejectField = [] as { description: string; field: string }[]
  Object.keys(validator).forEach((key) => {
    if (validator[key] === '0') {
      rejectField.push({
        field: key,
        description: errorReason[key] ?? '',
      })
    }
  })
  result.rejectField = rejectField

  // remove custom fields for validating reject condition
  delete result.validator
  delete result.errorReason

  return result
}
