import { z } from 'zod'
import { ProcedureReviewCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedureReviewCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedureReviewCreateManyProcedureInputEnvelope.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInputObjectSchema } from './ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInput.schema'
import { ProcedureReviewUpdateManyWithWhereWithoutProcedureInputObjectSchema } from './ProcedureReviewUpdateManyWithWhereWithoutProcedureInput.schema'
import { ProcedureReviewScalarWhereInputObjectSchema } from './ProcedureReviewScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUncheckedUpdateManyWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureReviewCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewUpdateManyWithWhereWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewUpdateManyWithWhereWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedureReviewScalarWhereInputObjectSchema),
          z.lazy(() => ProcedureReviewScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewUncheckedUpdateManyWithoutProcedureNestedInputObjectSchema =
  Schema
