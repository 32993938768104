import { z } from 'zod'
import { ProcedureItemCreateWithoutOrderInputObjectSchema } from './ProcedureItemCreateWithoutOrderInput.schema'
import { ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema } from './ProcedureItemUncheckedCreateWithoutOrderInput.schema'
import { ProcedureItemCreateOrConnectWithoutOrderInputObjectSchema } from './ProcedureItemCreateOrConnectWithoutOrderInput.schema'
import { ProcedureItemCreateManyOrderInputEnvelopeObjectSchema } from './ProcedureItemCreateManyOrderInputEnvelope.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemCreateNestedManyWithoutOrderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureItemCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => ProcedureItemCreateWithoutOrderInputObjectSchema)
            .array(),
          z.lazy(
            () => ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProcedureItemCreateOrConnectWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemCreateOrConnectWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureItemCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureItemCreateNestedManyWithoutOrderInputObjectSchema = Schema
