import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { ExpressShippingAreaCreateNestedManyWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaCreateNestedManyWithoutShippingProviderInput.schema'
import { OrderCreateNestedManyWithoutShippingProviderInputObjectSchema } from './OrderCreateNestedManyWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCreateInput> = z
  .object({
    priority: z.number().optional(),
    name: z.string(),
    detail: z.string(),
    moreDetail: z.string().optional().nullable(),
    shippingExpectation: z.string().optional(),
    trackingUrl: z.string().optional().nullable(),
    type: z.lazy(() => ShippingTypeSchema),
    price: z.number(),
    freeShippingMinimumPrice: z.number().optional().nullable(),
    active: z.boolean().optional(),
    areas: z
      .lazy(
        () =>
          ExpressShippingAreaCreateNestedManyWithoutShippingProviderInputObjectSchema,
      )
      .optional(),
    orders: z
      .lazy(() => OrderCreateNestedManyWithoutShippingProviderInputObjectSchema)
      .optional(),
  })
  .strict()

export const ShippingProviderCreateInputObjectSchema = Schema
