import { z } from 'zod'
import { BrandUpdateWithoutBannersInputObjectSchema } from './BrandUpdateWithoutBannersInput.schema'
import { BrandUncheckedUpdateWithoutBannersInputObjectSchema } from './BrandUncheckedUpdateWithoutBannersInput.schema'
import { BrandCreateWithoutBannersInputObjectSchema } from './BrandCreateWithoutBannersInput.schema'
import { BrandUncheckedCreateWithoutBannersInputObjectSchema } from './BrandUncheckedCreateWithoutBannersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpsertWithoutBannersInput> = z
  .object({
    update: z.union([
      z.lazy(() => BrandUpdateWithoutBannersInputObjectSchema),
      z.lazy(() => BrandUncheckedUpdateWithoutBannersInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => BrandCreateWithoutBannersInputObjectSchema),
      z.lazy(() => BrandUncheckedCreateWithoutBannersInputObjectSchema),
    ]),
  })
  .strict()

export const BrandUpsertWithoutBannersInputObjectSchema = Schema
