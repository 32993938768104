import { z } from 'zod'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { NestedEnumRefundStatusFilterObjectSchema } from './NestedEnumRefundStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumRefundStatusFilter> = z
  .object({
    equals: z.lazy(() => RefundStatusSchema).optional(),
    in: z
      .lazy(() => RefundStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => RefundStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => RefundStatusSchema),
        z.lazy(() => NestedEnumRefundStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumRefundStatusFilterObjectSchema = Schema
