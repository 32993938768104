import { Box, Card, CardContent, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SaveButton, SimpleForm, Toolbar, useRedirect } from 'react-admin'
import { useSearchParams } from 'react-router-dom'
import { CreateSaveRouteBack } from '../../components/CustomReactAdmin/CreateSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { adminGatewayClient } from '../../service'
import { AppointmentDetail } from './create-edit-section/appointmentDetail'
import { CaseDetail } from './create-edit-section/caseDetail'
import { ScheduleDetail } from './create-edit-section/scheduleDetail'
import { transformAppointment } from './transform'

const AppointmentCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctorKycData" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const AppointmentCreateForm = ({
  scheduleData,
}: {
  scheduleData: { [x: string]: unknown }
}) => {
  return (
    <SimpleForm
      toolbar={<AppointmentCreateToolbar />}
      defaultValues={{
        scheduleId: scheduleData.id,
        appointmentAt: scheduleData.availableAt,
        doctorId: scheduleData.doctorId,
        schedule: scheduleData,
      }}
    >
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
        Create appointment
      </Typography>
      <ScheduleDetail />
      <AppointmentDetail />
      <CaseDetail />
    </SimpleForm>
  )
}

export const AppointmentCreate = () => {
  const [searchParams] = useSearchParams()
  const redirect = useRedirect()
  const [scheduleData, setScheduleData] = useState<{
    [x: string]: unknown
  } | null>(null)

  useEffect(() => {
    const scheduleId = Number(searchParams.get('scheduleId'))
    if (isNaN(scheduleId)) {
      redirect('list', 'doctorScheduler')
    } else {
      adminGatewayClient.schedule.getOneScheduleWithDoctor
        .query({ scheduleId: scheduleId })
        .then((res) => setScheduleData(res))
        .catch((err: { message?: string }) => {
          alert('Error: ' + (err.message ?? ''))
          redirect('list', 'doctorScheduler')
        })
    }
  }, [redirect, searchParams])

  const mutationOption = {
    onSuccess: () => {
      redirect('list', 'doctorScheduler')
    },
  }

  return (
    <CreateSaveRouteBack
      transform={transformAppointment}
      mutationOptions={mutationOption}
    >
      <Card>
        <CardContent sx={{ p: 2 }}>
          {scheduleData ? (
            <AppointmentCreateForm scheduleData={scheduleData} />
          ) : null}
        </CardContent>
      </Card>
    </CreateSaveRouteBack>
  )
}
