import { z } from 'zod'
import { CategoryCreateNestedOneWithoutProductsInputObjectSchema } from './CategoryCreateNestedOneWithoutProductsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateWithoutProductInput> = z
  .object({
    priority: z.number().optional(),
    category: z.lazy(
      () => CategoryCreateNestedOneWithoutProductsInputObjectSchema,
    ),
  })
  .strict()

export const ProductCategoryCreateWithoutProductInputObjectSchema = Schema
