import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'
import { NestedEnumUnitNullableFilterObjectSchema } from './NestedEnumUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUnitNullableFilter> = z
  .object({
    equals: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UnitSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UnitSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UnitSchema),
        z.lazy(() => NestedEnumUnitNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumUnitNullableFilterObjectSchema = Schema
