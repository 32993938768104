import { z } from 'zod'
import { PaymentCreateWithoutOrderInputObjectSchema } from './PaymentCreateWithoutOrderInput.schema'
import { PaymentUncheckedCreateWithoutOrderInputObjectSchema } from './PaymentUncheckedCreateWithoutOrderInput.schema'
import { PaymentCreateOrConnectWithoutOrderInputObjectSchema } from './PaymentCreateOrConnectWithoutOrderInput.schema'
import { PaymentUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './PaymentUpsertWithWhereUniqueWithoutOrderInput.schema'
import { PaymentCreateManyOrderInputEnvelopeObjectSchema } from './PaymentCreateManyOrderInputEnvelope.schema'
import { PaymentWhereUniqueInputObjectSchema } from './PaymentWhereUniqueInput.schema'
import { PaymentUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './PaymentUpdateWithWhereUniqueWithoutOrderInput.schema'
import { PaymentUpdateManyWithWhereWithoutOrderInputObjectSchema } from './PaymentUpdateManyWithWhereWithoutOrderInput.schema'
import { PaymentScalarWhereInputObjectSchema } from './PaymentScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentUpdateManyWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema),
        z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => PaymentCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(() => PaymentUpsertWithWhereUniqueWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentUpsertWithWhereUniqueWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => PaymentCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => PaymentWhereUniqueInputObjectSchema),
        z.lazy(() => PaymentWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => PaymentWhereUniqueInputObjectSchema),
        z.lazy(() => PaymentWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => PaymentWhereUniqueInputObjectSchema),
        z.lazy(() => PaymentWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => PaymentWhereUniqueInputObjectSchema),
        z.lazy(() => PaymentWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(() => PaymentUpdateWithWhereUniqueWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentUpdateWithWhereUniqueWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => PaymentUpdateManyWithWhereWithoutOrderInputObjectSchema),
        z
          .lazy(() => PaymentUpdateManyWithWhereWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => PaymentScalarWhereInputObjectSchema),
        z.lazy(() => PaymentScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const PaymentUpdateManyWithoutOrderNestedInputObjectSchema = Schema
