import { z } from 'zod'
import { DiagnosisWhereInputObjectSchema } from './objects/DiagnosisWhereInput.schema'
import { DiagnosisOrderByWithAggregationInputObjectSchema } from './objects/DiagnosisOrderByWithAggregationInput.schema'
import { DiagnosisScalarWhereWithAggregatesInputObjectSchema } from './objects/DiagnosisScalarWhereWithAggregatesInput.schema'
import { DiagnosisScalarFieldEnumSchema } from './enums/DiagnosisScalarFieldEnum.schema'

export const DiagnosisGroupBySchema = z.object({
  where: DiagnosisWhereInputObjectSchema.optional(),
  orderBy: z.union([
    DiagnosisOrderByWithAggregationInputObjectSchema,
    DiagnosisOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: DiagnosisScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(DiagnosisScalarFieldEnumSchema),
})
