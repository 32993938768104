import { z } from 'zod'

export const CategoryScalarFieldEnumSchema = z.enum([
  'id',
  'nameTr',
  'descriptionTr',
  'priority',
  'icon',
  'active',
  'path',
  'parentId',
  'createdAt',
  'updatedAt',
])
