import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    active: z.literal(true).optional(),
    prefix: z.literal(true).optional(),
    code: z.literal(true).optional(),
    type: z.literal(true).optional(),
    picture: z.literal(true).optional(),
    discount: z.literal(true).optional(),
    discountType: z.literal(true).optional(),
    minTotal: z.literal(true).optional(),
    maxDiscount: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    expireAt: z.literal(true).optional(),
    isPublic: z.literal(true).optional(),
    isOnlyNewPatient: z.literal(true).optional(),
    note: z.literal(true).optional(),
    quota: z.literal(true).optional(),
    quotaPerUser: z.literal(true).optional(),
    createdBy: z.literal(true).optional(),
    updatedBy: z.literal(true).optional(),
    v2CreatedBy: z.literal(true).optional(),
    v2UpdatedBy: z.literal(true).optional(),
  })
  .strict()

export const CouponGroupMaxAggregateInputObjectSchema = Schema
