import { z } from 'zod'
import { FlashsaleOrderByWithRelationInputObjectSchema } from './objects/FlashsaleOrderByWithRelationInput.schema'
import { FlashsaleWhereInputObjectSchema } from './objects/FlashsaleWhereInput.schema'
import { FlashsaleWhereUniqueInputObjectSchema } from './objects/FlashsaleWhereUniqueInput.schema'
import { FlashsaleScalarFieldEnumSchema } from './enums/FlashsaleScalarFieldEnum.schema'

export const FlashsaleFindFirstSchema = z.object({
  orderBy: z
    .union([
      FlashsaleOrderByWithRelationInputObjectSchema,
      FlashsaleOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: FlashsaleWhereInputObjectSchema.optional(),
  cursor: FlashsaleWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(FlashsaleScalarFieldEnumSchema).optional(),
})
