import { z } from 'zod'
import { MyReviewCreateWithoutPatientInputObjectSchema } from './MyReviewCreateWithoutPatientInput.schema'
import { MyReviewUncheckedCreateWithoutPatientInputObjectSchema } from './MyReviewUncheckedCreateWithoutPatientInput.schema'
import { MyReviewCreateOrConnectWithoutPatientInputObjectSchema } from './MyReviewCreateOrConnectWithoutPatientInput.schema'
import { MyReviewUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './MyReviewUpsertWithWhereUniqueWithoutPatientInput.schema'
import { MyReviewCreateManyPatientInputEnvelopeObjectSchema } from './MyReviewCreateManyPatientInputEnvelope.schema'
import { MyReviewWhereUniqueInputObjectSchema } from './MyReviewWhereUniqueInput.schema'
import { MyReviewUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './MyReviewUpdateWithWhereUniqueWithoutPatientInput.schema'
import { MyReviewUpdateManyWithWhereWithoutPatientInputObjectSchema } from './MyReviewUpdateManyWithWhereWithoutPatientInput.schema'
import { MyReviewScalarWhereInputObjectSchema } from './MyReviewScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewUncheckedUpdateManyWithoutPatientNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema),
          z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema).array(),
          z.lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => MyReviewCreateOrConnectWithoutPatientInputObjectSchema),
          z
            .lazy(() => MyReviewCreateOrConnectWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () => MyReviewUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                MyReviewUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => MyReviewCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
          z.lazy(() => MyReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () => MyReviewUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                MyReviewUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () => MyReviewUpdateManyWithWhereWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () => MyReviewUpdateManyWithWhereWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => MyReviewScalarWhereInputObjectSchema),
          z.lazy(() => MyReviewScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const MyReviewUncheckedUpdateManyWithoutPatientNestedInputObjectSchema =
  Schema
