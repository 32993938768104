import { z } from 'zod'
import { HolidayWhereInputObjectSchema } from './objects/HolidayWhereInput.schema'
import { HolidayOrderByWithAggregationInputObjectSchema } from './objects/HolidayOrderByWithAggregationInput.schema'
import { HolidayScalarWhereWithAggregatesInputObjectSchema } from './objects/HolidayScalarWhereWithAggregatesInput.schema'
import { HolidayScalarFieldEnumSchema } from './enums/HolidayScalarFieldEnum.schema'

export const HolidayGroupBySchema = z.object({
  where: HolidayWhereInputObjectSchema.optional(),
  orderBy: z.union([
    HolidayOrderByWithAggregationInputObjectSchema,
    HolidayOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: HolidayScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(HolidayScalarFieldEnumSchema),
})
