import { Box } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BrandForm } from './create-edit/brandForm'
import { transformBrand } from './transform'

const ProductBrandEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="productBrand" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

export const ProductBrandEdit = () => (
  <EditSaveRouteBack redirect="list" transform={transformBrand}>
    <SimpleForm toolbar={<ProductBrandEditToolbar />}>
      <BrandForm title="Edit Brand" />
    </SimpleForm>
  </EditSaveRouteBack>
)
