import { z } from 'zod'
import { SegmentFindManySchema } from '../findManySegment.schema'
import { NotificationPatientFindManySchema } from '../findManyNotificationPatient.schema'
import { NotificationCountOutputTypeArgsObjectSchema } from './NotificationCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationInclude> = z
  .object({
    segments: z
      .union([z.boolean(), z.lazy(() => SegmentFindManySchema)])
      .optional(),
    patients: z
      .union([z.boolean(), z.lazy(() => NotificationPatientFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => NotificationCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NotificationIncludeObjectSchema = Schema
