import { z } from 'zod'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponUpdateWithoutOrderInputObjectSchema } from './CouponUpdateWithoutOrderInput.schema'
import { CouponUncheckedUpdateWithoutOrderInputObjectSchema } from './CouponUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponUpdateWithWhereUniqueWithoutOrderInput> = z
  .object({
    where: z.lazy(() => CouponWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => CouponUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => CouponUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const CouponUpdateWithWhereUniqueWithoutOrderInputObjectSchema = Schema
