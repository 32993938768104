import { z } from 'zod'
import { AddressScalarWhereInputObjectSchema } from './AddressScalarWhereInput.schema'
import { AddressUpdateManyMutationInputObjectSchema } from './AddressUpdateManyMutationInput.schema'
import { AddressUncheckedUpdateManyWithoutAddressInputObjectSchema } from './AddressUncheckedUpdateManyWithoutAddressInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressUpdateManyWithWhereWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AddressScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => AddressUpdateManyMutationInputObjectSchema),
        z.lazy(() => AddressUncheckedUpdateManyWithoutAddressInputObjectSchema),
      ]),
    })
    .strict()

export const AddressUpdateManyWithWhereWithoutPatientInputObjectSchema = Schema
