import { z } from 'zod'
import { ProcedureOrderOrderByWithRelationInputObjectSchema } from './objects/ProcedureOrderOrderByWithRelationInput.schema'
import { ProcedureOrderWhereInputObjectSchema } from './objects/ProcedureOrderWhereInput.schema'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './objects/ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderScalarFieldEnumSchema } from './enums/ProcedureOrderScalarFieldEnum.schema'

export const ProcedureOrderFindManySchema = z.object({
  orderBy: z
    .union([
      ProcedureOrderOrderByWithRelationInputObjectSchema,
      ProcedureOrderOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureOrderWhereInputObjectSchema.optional(),
  cursor: ProcedureOrderWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureOrderScalarFieldEnumSchema).optional(),
})
