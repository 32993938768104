import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SegmentOrderByRelationAggregateInputObjectSchema } from './SegmentOrderByRelationAggregateInput.schema'
import { NotificationPatientOrderByRelationAggregateInputObjectSchema } from './NotificationPatientOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    actionType: z.lazy(() => SortOrderSchema).optional(),
    action: z.lazy(() => SortOrderSchema).optional(),
    onesignalMessageId: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    appointmentId: z.lazy(() => SortOrderSchema).optional(),
    appointmentEvent: z.lazy(() => SortOrderSchema).optional(),
    segments: z
      .lazy(() => SegmentOrderByRelationAggregateInputObjectSchema)
      .optional(),
    patients: z
      .lazy(() => NotificationPatientOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const NotificationOrderByWithRelationInputObjectSchema = Schema
