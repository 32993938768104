import { z } from 'zod'
import { ProductReviewPictureCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateOrConnectWithoutProductReviewInput.schema'
import { ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInput.schema'
import { ProductReviewPictureCreateManyProductReviewInputEnvelopeObjectSchema } from './ProductReviewPictureCreateManyProductReviewInputEnvelope.schema'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInput.schema'
import { ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInput.schema'
import { ProductReviewPictureScalarWhereInputObjectSchema } from './ProductReviewPictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureUpdateManyWithoutProductReviewNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureUpsertWithWhereUniqueWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            ProductReviewPictureCreateManyProductReviewInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureUpdateWithWhereUniqueWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductReviewPictureScalarWhereInputObjectSchema),
          z
            .lazy(() => ProductReviewPictureScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewPictureUpdateManyWithoutProductReviewNestedInputObjectSchema =
  Schema
