import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './objects/DeliveryWhereUniqueInput.schema'
import { DeliveryCreateInputObjectSchema } from './objects/DeliveryCreateInput.schema'
import { DeliveryUncheckedCreateInputObjectSchema } from './objects/DeliveryUncheckedCreateInput.schema'
import { DeliveryUpdateInputObjectSchema } from './objects/DeliveryUpdateInput.schema'
import { DeliveryUncheckedUpdateInputObjectSchema } from './objects/DeliveryUncheckedUpdateInput.schema'

export const DeliveryUpsertSchema = z.object({
  where: DeliveryWhereUniqueInputObjectSchema,
  create: z.union([
    DeliveryCreateInputObjectSchema,
    DeliveryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    DeliveryUpdateInputObjectSchema,
    DeliveryUncheckedUpdateInputObjectSchema,
  ]),
})
