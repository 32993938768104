import { z } from 'zod'
import { OrderNotificationCreateInputObjectSchema } from './objects/OrderNotificationCreateInput.schema'
import { OrderNotificationUncheckedCreateInputObjectSchema } from './objects/OrderNotificationUncheckedCreateInput.schema'

export const OrderNotificationCreateOneSchema = z.object({
  data: z.union([
    OrderNotificationCreateInputObjectSchema,
    OrderNotificationUncheckedCreateInputObjectSchema,
  ]),
})
