import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    couponId: z.literal(true).optional(),
    discount: z.literal(true).optional(),
    coin: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    v2CouponId: z.literal(true).optional(),
  })
  .strict()

export const CouponSumAggregateInputObjectSchema = Schema
