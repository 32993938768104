import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    flashsaleId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    price: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedBySkinX: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedByPartner: z.lazy(() => SortOrderSchema).optional(),
    saleLimit: z.lazy(() => SortOrderSchema).optional(),
    sold: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const FlashsaleItemSumOrderByAggregateInputObjectSchema = Schema
