import * as z from "zod"
import { CompleteCaseSummary, RelatedCaseSummaryModel } from "./index"

export const SuggestedProcedureItemModel = z.object({
  id: z.number().int(),
  caseSummaryId: z.number().int(),
  procedureId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  amount: z.number().int(),
  note: z.string().nullish(),
})

export interface CompleteSuggestedProcedureItem extends z.infer<typeof SuggestedProcedureItemModel> {
  caseSummary: CompleteCaseSummary
}

/**
 * RelatedSuggestedProcedureItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedSuggestedProcedureItemModel: z.ZodSchema<CompleteSuggestedProcedureItem> = z.lazy(() => SuggestedProcedureItemModel.extend({
  caseSummary: RelatedCaseSummaryModel,
}))
