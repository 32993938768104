import { z } from 'zod'
import { SuggestedProductItemSelectObjectSchema } from './SuggestedProductItemSelect.schema'
import { SuggestedProductItemIncludeObjectSchema } from './SuggestedProductItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemArgs> = z
  .object({
    select: z.lazy(() => SuggestedProductItemSelectObjectSchema).optional(),
    include: z.lazy(() => SuggestedProductItemIncludeObjectSchema).optional(),
  })
  .strict()

export const SuggestedProductItemArgsObjectSchema = Schema
