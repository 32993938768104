import { z } from 'zod'
import { ProductPictureWhereInputObjectSchema } from './ProductPictureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureListRelationFilter> = z
  .object({
    every: z.lazy(() => ProductPictureWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProductPictureWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProductPictureWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductPictureListRelationFilterObjectSchema = Schema
