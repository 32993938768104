import * as z from "zod"
import { CompleteCase, RelatedCaseModel, CompleteSchedule, RelatedScheduleModel, CompleteTeleconsult, RelatedTeleconsultModel, CompleteCaseSummary, RelatedCaseSummaryModel } from "./index"

export const AppointmentModel = z.object({
  id: z.number().int(),
  doctorId: z.number().int(),
  patientId: z.number().int(),
  type: z.enum(['APPOINTMENT', 'FOLLOW_UP']),
  status: z.enum(['IN_PROGRESS', 'CANCELED', 'WAIT_TO_SUMMARIZE', 'COMPLETED', 'FAILED']),
  caseId: z.number().int(),
  scheduleId: z.number().int().nullish(),
  appointmentAt: z.date(),
  cancelAt: z.date().nullish(),
  cancelDetail: z.string().nullish(),
  teleconsultId: z.number().int().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteAppointment extends z.infer<typeof AppointmentModel> {
  case: CompleteCase
  schedule?: CompleteSchedule | null
  teleconsult?: CompleteTeleconsult | null
  followUpCaseSummary?: CompleteCaseSummary | null
}

/**
 * RelatedAppointmentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedAppointmentModel: z.ZodSchema<CompleteAppointment> = z.lazy(() => AppointmentModel.extend({
  case: RelatedCaseModel,
  schedule: RelatedScheduleModel.nullish(),
  teleconsult: RelatedTeleconsultModel.nullish(),
  followUpCaseSummary: RelatedCaseSummaryModel.nullish(),
}))
