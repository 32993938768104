import { z } from 'zod'
import { ProductReviewCreateWithoutVariantInputObjectSchema } from './ProductReviewCreateWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateWithoutVariantInput.schema'
import { ProductReviewCreateOrConnectWithoutVariantInputObjectSchema } from './ProductReviewCreateOrConnectWithoutVariantInput.schema'
import { ProductReviewUpsertWithWhereUniqueWithoutVariantInputObjectSchema } from './ProductReviewUpsertWithWhereUniqueWithoutVariantInput.schema'
import { ProductReviewCreateManyVariantInputEnvelopeObjectSchema } from './ProductReviewCreateManyVariantInputEnvelope.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithWhereUniqueWithoutVariantInputObjectSchema } from './ProductReviewUpdateWithWhereUniqueWithoutVariantInput.schema'
import { ProductReviewUpdateManyWithWhereWithoutVariantInputObjectSchema } from './ProductReviewUpdateManyWithWhereWithoutVariantInput.schema'
import { ProductReviewScalarWhereInputObjectSchema } from './ProductReviewScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateManyWithoutVariantNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductReviewCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductReviewCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpdateManyWithWhereWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateManyWithWhereWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema),
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewUpdateManyWithoutVariantNestedInputObjectSchema =
  Schema
