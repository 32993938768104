import { CheckCircle, HighlightOff } from '@mui/icons-material'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'
import {
  BooleanField,
  BooleanFieldProps,
  FunctionField,
  FunctionFieldProps,
  useLocaleState,
} from 'react-admin'

export const CustomI18nField: FC<
  Partial<FunctionFieldProps> & { fieldTr: string; ellipsis?: boolean }
> = ({ sx, ...props }) => {
  const { fieldTr, ellipsis, ...restProps } = props
  const [locale] = useLocaleState()
  return (
    <FunctionField
      {...restProps}
      sx={{ ...sx }}
      render={(record: { [x: string]: unknown }) => {
        if (
          !record[fieldTr] ||
          !(record[fieldTr] as { [x: string]: unknown })[locale]
        ) {
          return ''
        }
        const str = (record[fieldTr] as { [x: string]: unknown })[
          locale
        ] as string
        return str
          ? ellipsis && str.length > 20
            ? str.slice(0, 20) + '...'
            : str
          : null
      }}
    />
  )
}

export const CustomBooleanField: FC<BooleanFieldProps> = ({ sx, ...props }) => {
  const TrueIcon: typeof SvgIcon = (props: SvgIconProps) => {
    return <CheckCircle {...props} color="success" />
  }
  TrueIcon.muiName = 'FalseIcon'

  const FalseIcon: typeof SvgIcon = (props: SvgIconProps) => {
    return <HighlightOff {...props} color="error" />
  }
  FalseIcon.muiName = 'FalseIcon'

  return (
    <BooleanField
      {...props}
      sx={{ ...sx }}
      TrueIcon={TrueIcon}
      FalseIcon={FalseIcon}
      textAlign="center"
    />
  )
}
