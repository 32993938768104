import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NullableEnumPaymentGatewayFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => PaymentGatewaySchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumPaymentGatewayFieldUpdateOperationsInputObjectSchema =
  Schema
