import { Box } from '@mui/material'
import { FC, useState } from 'react'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  Pagination,
  TextField,
} from 'react-admin'
import { CustomButton } from '../../components/CustomButton'
import { CustomBooleanField } from '../../components/CustomField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { CarouselProcedureImportModal } from './importCarousel/importModal'

const CarouselProcedureListActions: FC<{
  onOpenImportModal: (state: boolean) => void
}> = ({ onOpenImportModal }) => {
  const handleImportClick = () => {
    onOpenImportModal(true)
  }
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <CustomButton
          onClick={handleImportClick}
          label="resources.carouselProcedure.action.import_button"
        />
        <ExportButton />
      </Box>
    </Box>
  )
}

const renderProcedureName = (
  record: {
    id: number
    nameTr: { en: string; th: string }
  } | null,
) => {
  if (!record) {
    return null
  }

  return (
    <div>
      <p style={{ margin: '0.25rem 0' }}>TH: {record.nameTr.th ?? ''}</p>
      <p style={{ margin: '0.25rem 0' }}>EN: {record.nameTr.en ?? ''}</p>
    </div>
  )
}

export const CarouselProcedureList = () => {
  const [openImportModal, setOpenImportModal] = useState(false)
  const rowsPerPage = [5, 10, 20, 30, 50]

  const handleOpenImportModal = (state: boolean) => {
    setOpenImportModal(state)
  }

  return (
    <Box>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[4]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={
          <CarouselProcedureListActions
            onOpenImportModal={handleOpenImportModal}
          />
        }
        sort={{ field: 'startAt,priority,id', order: 'DESC,DESC,ASC' }}
      >
        <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
          <TextField label="ID" source="id" sortable={false} />
          <LocaleDateField showTime source="startAt" sortable={false} />
          <LocaleDateField showTime source="endAt" sortable={false} />
          <FunctionField
            label="Carousel name"
            render={renderProcedureName}
            sortable={false}
          />
          <CustomBooleanField source="active" sortable={false} />
          <TextField source="priority" sortable={false} />
        </Datagrid>
      </List>
      <CarouselProcedureImportModal
        open={openImportModal}
        onClose={() => {
          handleOpenImportModal(false)
        }}
        disableCloseOnBackdrop
      />
    </Box>
  )
}
