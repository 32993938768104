import { z } from 'zod'
import { CarouselItemCreateWithoutProcedureInputObjectSchema } from './CarouselItemCreateWithoutProcedureInput.schema'
import { CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema } from './CarouselItemUncheckedCreateWithoutProcedureInput.schema'
import { CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema } from './CarouselItemCreateOrConnectWithoutProcedureInput.schema'
import { CarouselItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema } from './CarouselItemUpsertWithWhereUniqueWithoutProcedureInput.schema'
import { CarouselItemCreateManyProcedureInputEnvelopeObjectSchema } from './CarouselItemCreateManyProcedureInputEnvelope.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema } from './CarouselItemUpdateWithWhereUniqueWithoutProcedureInput.schema'
import { CarouselItemUpdateManyWithWhereWithoutProcedureInputObjectSchema } from './CarouselItemUpdateManyWithWhereWithoutProcedureInput.schema'
import { CarouselItemScalarWhereInputObjectSchema } from './CarouselItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUncheckedUpdateManyWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CarouselItemCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => CarouselItemCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () => CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CarouselItemCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CarouselItemUpdateManyWithWhereWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CarouselItemUpdateManyWithWhereWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CarouselItemScalarWhereInputObjectSchema),
          z.lazy(() => CarouselItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CarouselItemUncheckedUpdateManyWithoutProcedureNestedInputObjectSchema =
  Schema
