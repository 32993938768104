import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutProcedureItemsInputObjectSchema } from './OrderCreateWithoutProcedureItemsInput.schema'
import { OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutProcedureItemsInput> =
  z
    .object({
      where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => OrderCreateWithoutProcedureItemsInputObjectSchema),
        z.lazy(
          () => OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OrderCreateOrConnectWithoutProcedureItemsInputObjectSchema = Schema
