import { z } from 'zod'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewOrderItemIdOrderItemTypeCompoundUniqueInput> =
  z
    .object({
      orderItemId: z.number(),
      orderItemType: z.lazy(() => OrderItemTypeSchema),
    })
    .strict()

export const MyReviewOrderItemIdOrderItemTypeCompoundUniqueInputObjectSchema =
  Schema
