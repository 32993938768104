import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NotificationTypeSchema } from '../enums/NotificationType.schema'
import { EnumNotificationTypeFieldUpdateOperationsInputObjectSchema } from './EnumNotificationTypeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { EnumNotificationActionTypeFieldUpdateOperationsInputObjectSchema } from './EnumNotificationActionTypeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'
import { NullableEnumAppointmentEventFieldUpdateOperationsInputObjectSchema } from './NullableEnumAppointmentEventFieldUpdateOperationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUncheckedUpdateManyWithoutNotificationsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      type: z
        .union([
          z.lazy(() => NotificationTypeSchema),
          z.lazy(
            () => EnumNotificationTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      picture: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      title: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      message: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      actionType: z
        .union([
          z.lazy(() => NotificationActionTypeSchema),
          z.lazy(
            () =>
              EnumNotificationActionTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      action: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      onesignalMessageId: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      startAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      appointmentId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      appointmentEvent: z
        .union([
          z.lazy(() => AppointmentEventSchema),
          z.lazy(
            () =>
              NullableEnumAppointmentEventFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
    })
    .strict()

export const NotificationUncheckedUpdateManyWithoutNotificationsInputObjectSchema =
  Schema
