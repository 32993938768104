import { z } from 'zod'
import { HealthInfoOrderByWithRelationInputObjectSchema } from './objects/HealthInfoOrderByWithRelationInput.schema'
import { HealthInfoWhereInputObjectSchema } from './objects/HealthInfoWhereInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './objects/HealthInfoWhereUniqueInput.schema'
import { HealthInfoScalarFieldEnumSchema } from './enums/HealthInfoScalarFieldEnum.schema'

export const HealthInfoFindManySchema = z.object({
  orderBy: z
    .union([
      HealthInfoOrderByWithRelationInputObjectSchema,
      HealthInfoOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HealthInfoWhereInputObjectSchema.optional(),
  cursor: HealthInfoWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(HealthInfoScalarFieldEnumSchema).optional(),
})
