import { z } from 'zod'
import { PatientCreateWithoutCoinTransactionsInputObjectSchema } from './PatientCreateWithoutCoinTransactionsInput.schema'
import { PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema } from './PatientUncheckedCreateWithoutCoinTransactionsInput.schema'
import { PatientCreateOrConnectWithoutCoinTransactionsInputObjectSchema } from './PatientCreateOrConnectWithoutCoinTransactionsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateNestedOneWithoutCoinTransactionsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutCoinTransactionsInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedCreateWithoutCoinTransactionsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => PatientCreateOrConnectWithoutCoinTransactionsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const PatientCreateNestedOneWithoutCoinTransactionsInputObjectSchema =
  Schema
