import { z } from 'zod'
import { ProcedureCategoryUpdateInputObjectSchema } from './objects/ProcedureCategoryUpdateInput.schema'
import { ProcedureCategoryUncheckedUpdateInputObjectSchema } from './objects/ProcedureCategoryUncheckedUpdateInput.schema'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './objects/ProcedureCategoryWhereUniqueInput.schema'

export const ProcedureCategoryUpdateOneSchema = z.object({
  data: z.union([
    ProcedureCategoryUpdateInputObjectSchema,
    ProcedureCategoryUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureCategoryWhereUniqueInputObjectSchema,
})
