import { z } from 'zod'
import { VariantWarehouseSelectObjectSchema } from './VariantWarehouseSelect.schema'
import { VariantWarehouseIncludeObjectSchema } from './VariantWarehouseInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseArgs> = z
  .object({
    select: z.lazy(() => VariantWarehouseSelectObjectSchema).optional(),
    include: z.lazy(() => VariantWarehouseIncludeObjectSchema).optional(),
  })
  .strict()

export const VariantWarehouseArgsObjectSchema = Schema
