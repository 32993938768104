import { z } from 'zod'
import { CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelopeObjectSchema } from './CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelope.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutDiagnosisInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () => CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateOrConnectWithoutDiagnosisInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutDiagnosisInputObjectSchema =
  Schema
