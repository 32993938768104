import { z } from 'zod'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumConsultingStatusFilter> = z
  .object({
    equals: z.lazy(() => ConsultingStatusSchema).optional(),
    in: z
      .lazy(() => ConsultingStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConsultingStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConsultingStatusSchema),
        z.lazy(() => NestedEnumConsultingStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumConsultingStatusFilterObjectSchema = Schema
