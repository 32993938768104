import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumPartnerTypeFilterObjectSchema } from './EnumPartnerTypeFilter.schema'
import { PartnerTypeSchema } from '../enums/PartnerType.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { UserListRelationFilterObjectSchema } from './UserListRelationFilter.schema'
import { BranchListRelationFilterObjectSchema } from './BranchListRelationFilter.schema'
import { CoverPictureListRelationFilterObjectSchema } from './CoverPictureListRelationFilter.schema'
import { SkuListRelationFilterObjectSchema } from './SkuListRelationFilter.schema'
import { ProcedureOrderListRelationFilterObjectSchema } from './ProcedureOrderListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => PartnerWhereInputObjectSchema),
        z.lazy(() => PartnerWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => PartnerWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => PartnerWhereInputObjectSchema),
        z.lazy(() => PartnerWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    type: z
      .union([
        z.lazy(() => EnumPartnerTypeFilterObjectSchema),
        z.lazy(() => PartnerTypeSchema),
      ])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    logo: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    website: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    phone: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    isExpertise: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    isSXEasy: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    searchField: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    taxId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    v2HospitalId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    v2StoreId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    users: z.lazy(() => UserListRelationFilterObjectSchema).optional(),
    branches: z.lazy(() => BranchListRelationFilterObjectSchema).optional(),
    coverPictures: z
      .lazy(() => CoverPictureListRelationFilterObjectSchema)
      .optional(),
    sku: z.lazy(() => SkuListRelationFilterObjectSchema).optional(),
    ProcedureOrder: z
      .lazy(() => ProcedureOrderListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const PartnerWhereInputObjectSchema = Schema
