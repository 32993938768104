import { z } from 'zod'
import { ArticleCreateNestedOneWithoutPicturesInputObjectSchema } from './ArticleCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    url: z.string(),
    rank: z.number().optional(),
    article: z.lazy(
      () => ArticleCreateNestedOneWithoutPicturesInputObjectSchema,
    ),
  })
  .strict()

export const ArticlePictureCreateInputObjectSchema = Schema
