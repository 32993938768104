import { z } from 'zod'
import { ProcedureReviewSummaryUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUpdateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryUpsertWithoutProcedureInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => ProcedureReviewSummaryUpdateWithoutProcedureInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureReviewSummaryUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewSummaryUpsertWithoutProcedureInputObjectSchema =
  Schema
