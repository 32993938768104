import { z } from 'zod'
import { ConsentUpdateInputObjectSchema } from './objects/ConsentUpdateInput.schema'
import { ConsentUncheckedUpdateInputObjectSchema } from './objects/ConsentUncheckedUpdateInput.schema'
import { ConsentWhereUniqueInputObjectSchema } from './objects/ConsentWhereUniqueInput.schema'

export const ConsentUpdateOneSchema = z.object({
  data: z.union([
    ConsentUpdateInputObjectSchema,
    ConsentUncheckedUpdateInputObjectSchema,
  ]),
  where: ConsentWhereUniqueInputObjectSchema,
})
