import * as z from "zod"
import { CompleteProcedure, RelatedProcedureModel, CompleteProcedureReviewPicture, RelatedProcedureReviewPictureModel } from "./index"

export const ProcedureReviewModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  procedureId: z.number().int(),
  partnerId: z.number().int(),
  overallRating: z.number().int(),
  environmentRating: z.number().int(),
  serviceRating: z.number().int(),
  specialtyRating: z.number().int(),
  valueRating: z.number().int(),
  message: z.string().nullish(),
  orderId: z.number().int(),
  patientId: z.number().int(),
  patientName: z.string(),
  isAnonymous: z.boolean(),
  priority: z.number().int(),
})

export interface CompleteProcedureReview extends z.infer<typeof ProcedureReviewModel> {
  procedure: CompleteProcedure
  pictures: CompleteProcedureReviewPicture[]
}

/**
 * RelatedProcedureReviewModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureReviewModel: z.ZodSchema<CompleteProcedureReview> = z.lazy(() => ProcedureReviewModel.extend({
  procedure: RelatedProcedureModel,
  pictures: RelatedProcedureReviewPictureModel.array(),
}))
