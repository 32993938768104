import { z } from 'zod'
import { CaseCreateNestedOneWithoutPicturesInputObjectSchema } from './CaseCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureCreateInput> = z
  .object({
    pictureUrl: z.string(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    case: z.lazy(() => CaseCreateNestedOneWithoutPicturesInputObjectSchema),
  })
  .strict()

export const CasePictureCreateInputObjectSchema = Schema
