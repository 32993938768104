import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import { ProductModel, VariantModel } from '@ssch-backend/products'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { FC, useEffect, useState } from 'react'
import {
  BooleanInput,
  OptionTextFunc,
  ReferenceInput,
  required,
  useChoicesContext,
  useLocaleState,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteInput,
  FormNumberInput,
} from '../../../components/FormInput/input'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { VariantDetailCard } from './variantDetail'

const VariantModelWithProduct = VariantModel.extend({
  product: ProductModel.partial().optional(),
  pictures: z
    .array(z.object({ src: z.string(), gsPath: z.string() }))
    .optional(),
})

export type VariantModelWithProduct = z.infer<typeof VariantModelWithProduct>

const VariantAutoCompleteInput = ({
  disabled = false,
  setSelectedVariant,
  optionTextVariant,
  ...props
}: {
  disabled: boolean
  setSelectedVariant: (variant: VariantModelWithProduct | null) => void
  optionTextVariant: OptionTextFunc
}) => {
  const { selectedChoices } = useChoicesContext()

  useEffect(() => {
    if (selectedChoices[0]) {
      setSelectedVariant(selectedChoices[0] as VariantModelWithProduct)
    }
  }, [selectedChoices, setSelectedVariant])

  const filterToQuery = (textSearch: string) => {
    if (textSearch) {
      return {
        textSearch: { contains: textSearch.trim() },
      }
    }
    return { id: { in: [] } }
  }

  const handleVariantChange = (id: number | undefined) => {
    if (!id) {
      setSelectedVariant(null)
    }
  }

  return (
    <FormAutoCompleteInput
      {...props}
      fullWidth
      optionText={optionTextVariant}
      label="Variant"
      debounce={100}
      filterToQuery={filterToQuery}
      validate={required()}
      parse={(id) => parseInt(id) || undefined}
      onChange={(id) => handleVariantChange(id)}
      disabled={disabled}
    />
  )
}

export const VariantInfoSection: FC<{ title?: string }> = ({ title }) => {
  const [variantData, setVariantData] =
    useState<VariantModelWithProduct | null>(null)
  const [searchParams] = useSearchParams()
  const record = useRecordContext()
  const { setValue } = useFormContext()
  const notify = useNotify()
  const [locale] = useLocaleState()

  useEffect(() => {
    const flashsaleId = Number(searchParams.get('flashsaleId'))
    if (!isNaN(flashsaleId)) {
      setValue('flashsaleId', flashsaleId)
    }
  }, [searchParams, setValue])

  const handleSetSelectedVariant = (
    variant: VariantModelWithProduct | null,
  ) => {
    setVariantData(variant)
  }

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const active = e.target.checked
    try {
      await adminGatewayClient.flashsaleItemProduct.setActive.mutate({
        id: Number(id),
        active,
      })
      notify(`FlashsaleItem is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', !active)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const handleOptionTextVariant: OptionTextFunc = (choice) => {
    return `${getTr(choice.descriptionTr, locale as Language)} (${getTr(
      choice.product.nameTr,
      locale as Language,
    )})`
  }

  const priceValidation = (value: number) => {
    if (!variantData) {
      return 'Please select variant'
    } else if (value < variantData.cost) {
      return `Flash Sale price must more than/equal to cost ( ${variantData.cost} )`
    } else if (value > variantData.sellingPrice) {
      return `Flash Sale price should not set over a normal price ( ${variantData.sellingPrice} )`
    }
    return undefined
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {title || 'Add Flashsale item (product)'}
        </Typography>
        {record?.id && (
          <BooleanInput
            source="active"
            onChange={(e) => handleActiveChanged(record.id, e)}
          />
        )}
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ReferenceInput source="variantId" reference="productVariantSearch">
            <VariantAutoCompleteInput
              optionTextVariant={handleOptionTextVariant}
              setSelectedVariant={handleSetSelectedVariant}
              disabled={record?.id ? true : false}
            />
          </ReferenceInput>
          {variantData && <VariantDetailCard variant={variantData} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <FormNumberInput
              source="price"
              min={0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">฿</InputAdornment>
                ),
              }}
              validate={[required(), priceValidation]}
            />
          </Grid>
          <Grid item xs={12}>
            <FormNumberInput
              label="Quota"
              source="saleLimit"
              min={1}
              step={1}
              inputProps={{ style: { textAlign: 'center' } }}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12}>
            <FormNumberInput
              source="priority"
              defaultValue={0}
              min={0}
              step={1}
              inputProps={{ style: { textAlign: 'center' } }}
              validate={required()}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
