import { z } from 'zod'
import { UsageSchema } from '../enums/Usage.schema'
import { NestedEnumUsageNullableWithAggregatesFilterObjectSchema } from './NestedEnumUsageNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumUsageNullableFilterObjectSchema } from './NestedEnumUsageNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUsageNullableWithAggregatesFilter> = z
  .object({
    equals: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UsageSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UsageSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UsageSchema),
        z.lazy(() => NestedEnumUsageNullableWithAggregatesFilterObjectSchema),
      ])
      .optional()
      .nullable(),
    _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumUsageNullableFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumUsageNullableFilterObjectSchema).optional(),
  })
  .strict()

export const EnumUsageNullableWithAggregatesFilterObjectSchema = Schema
