import { OrderPaidEventModel } from '@ssch-backend/share-model'
import { z } from 'zod'

export const PartnerCreateEventModel = z.object({
  partnerId: z.number(),
})
export type PartnerCreateEventModel = z.infer<typeof PartnerCreateEventModel>

export const PartnerUpdateEventModel = z.object({
  partnerId: z.number(),
})
export type PartnerUpdateEventModel = z.infer<typeof PartnerUpdateEventModel>

export const ProcedureOrderCreateEventModel = OrderPaidEventModel

export type ProcedureOrderCreateEventModel = z.infer<
  typeof ProcedureOrderCreateEventModel
>
