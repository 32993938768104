import { z } from 'zod'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './objects/SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemCreateInputObjectSchema } from './objects/SuggestedProcedureItemCreateInput.schema'
import { SuggestedProcedureItemUncheckedCreateInputObjectSchema } from './objects/SuggestedProcedureItemUncheckedCreateInput.schema'
import { SuggestedProcedureItemUpdateInputObjectSchema } from './objects/SuggestedProcedureItemUpdateInput.schema'
import { SuggestedProcedureItemUncheckedUpdateInputObjectSchema } from './objects/SuggestedProcedureItemUncheckedUpdateInput.schema'

export const SuggestedProcedureItemUpsertSchema = z.object({
  where: SuggestedProcedureItemWhereUniqueInputObjectSchema,
  create: z.union([
    SuggestedProcedureItemCreateInputObjectSchema,
    SuggestedProcedureItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    SuggestedProcedureItemUpdateInputObjectSchema,
    SuggestedProcedureItemUncheckedUpdateInputObjectSchema,
  ]),
})
