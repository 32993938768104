import { Box } from '@mui/material'
import {
  Datagrid,
  ExportButton,
  List,
  ReferenceOneField,
  TextField,
} from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FilterListForm } from './list-section/filterlistForm'
import { FullnameField } from './list-section/fullname'
import { StatusField } from './list-section/status'

const PatientKycDataListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1 }}>
        <ExportButton />
      </Box>
    </Box>
  )
}

export const PatientKycDdataList = () => (
  <List
    disableSyncWithLocation
    actions={<PatientKycDataListAction />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
  >
    <Datagrid
      size="small"
      rowClick="edit"
      sx={{
        '& .RaDatagrid-headerCell': {
          alignItems: 'baseline',
          whiteSpace: 'nowrap',
        },
        '& .RaDatagrid-rowCell': {
          whiteSpace: 'nowrap',
        },
      }}
    >
      <TextField source="patientId" label="PID" sortable={false} />
      <LocaleDateField
        source="createdAt"
        label="Date"
        showTime
        sortable={false}
      />
      <FullnameField label="Name" />
      <TextField source="gender" label="Gender" sortable={false} />
      <ReferenceOneField
        label="Tel."
        reference="patientIdentity"
        target="patientId"
        source="patientId"
        filter={{ type_equals: 'PHONE' }}
        sortable={false}
      >
        <TextField source="identityId" />
      </ReferenceOneField>
      <TextField source="channel" label="Channel" sortable={false} />
      <StatusField label="Status" sortable={false} />
      <LocaleDateField
        source="updatedAt"
        label="Update Status date"
        showTime
        sortable={false}
      />
    </Datagrid>
  </List>
)
