import * as z from "zod"
import { CompleteOrder, RelatedOrderModel } from "./index"

export const PaymentModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  transactionId: z.string().nullish(),
  amount: z.number(),
  currency: z.string(),
  paymentAt: z.date().nullish(),
  paymentGateway: z.enum(['OMISE', 'GBPRIME', 'MPAY', 'OFFLINE']).nullish(),
  paymentMethod: z.enum(['NO_PAYMENT_REQUIRED', 'CREDIT_CARD', 'INSTALLMENT_SCB', 'INSTALLMENT_KBANK', 'INSTALLMENT_BAY', 'INSTALLMENT_BBL', 'INSTALLMENT_FIRST_CHOICE', 'INSTALLMENT_KTC', 'INSTALLMENT_UOB', 'INSTALLMENT_TTB', 'PROMPTPAY', 'MOBILE_BANKING', 'MOBILE_BANKING_SCB', 'MOBILE_BANKING_KBANK', 'MOBILE_BANKING_BBL', 'MOBILE_BANKING_KTB', 'MOBILE_BANKING_KMA']),
  installmentTerm: z.number().int().nullish(),
  status: z.enum(['IN_PROGRESS', 'PENDING', 'SUCCESS', 'FAILED', 'EXPIRED', 'DISPUTED', 'DISPUTE_WON', 'DISPUTE_LOST']),
  confirmedBy: z.number().int().nullish(),
  evidencePicture: z.string().nullish(),
  reminderNote: z.string().nullish(),
  orderId: z.number().int(),
})

export interface CompletePayment extends z.infer<typeof PaymentModel> {
  order: CompleteOrder
}

/**
 * RelatedPaymentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPaymentModel: z.ZodSchema<CompletePayment> = z.lazy(() => PaymentModel.extend({
  order: RelatedOrderModel,
}))
