import { z } from 'zod'
import { ShippingProviderUpdateInputObjectSchema } from './objects/ShippingProviderUpdateInput.schema'
import { ShippingProviderUncheckedUpdateInputObjectSchema } from './objects/ShippingProviderUncheckedUpdateInput.schema'
import { ShippingProviderWhereUniqueInputObjectSchema } from './objects/ShippingProviderWhereUniqueInput.schema'

export const ShippingProviderUpdateOneSchema = z.object({
  data: z.union([
    ShippingProviderUpdateInputObjectSchema,
    ShippingProviderUncheckedUpdateInputObjectSchema,
  ]),
  where: ShippingProviderWhereUniqueInputObjectSchema,
})
