import { z } from 'zod'
import { ProductReviewCreateWithoutBrandInputObjectSchema } from './ProductReviewCreateWithoutBrandInput.schema'
import { ProductReviewUncheckedCreateWithoutBrandInputObjectSchema } from './ProductReviewUncheckedCreateWithoutBrandInput.schema'
import { ProductReviewCreateOrConnectWithoutBrandInputObjectSchema } from './ProductReviewCreateOrConnectWithoutBrandInput.schema'
import { ProductReviewUpsertWithWhereUniqueWithoutBrandInputObjectSchema } from './ProductReviewUpsertWithWhereUniqueWithoutBrandInput.schema'
import { ProductReviewCreateManyBrandInputEnvelopeObjectSchema } from './ProductReviewCreateManyBrandInputEnvelope.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithWhereUniqueWithoutBrandInputObjectSchema } from './ProductReviewUpdateWithWhereUniqueWithoutBrandInput.schema'
import { ProductReviewUpdateManyWithWhereWithoutBrandInputObjectSchema } from './ProductReviewUpdateManyWithWhereWithoutBrandInput.schema'
import { ProductReviewScalarWhereInputObjectSchema } from './ProductReviewScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateManyWithoutBrandNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutBrandInputObjectSchema),
          z
            .lazy(() => ProductReviewCreateWithoutBrandInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewUncheckedCreateWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductReviewCreateOrConnectWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewCreateOrConnectWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpsertWithWhereUniqueWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpsertWithWhereUniqueWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductReviewCreateManyBrandInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpdateWithWhereUniqueWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateWithWhereUniqueWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () => ProductReviewUpdateManyWithWhereWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateManyWithWhereWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema),
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewUpdateManyWithoutBrandNestedInputObjectSchema = Schema
