import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    kycDataId: z.number().optional().nullable(),
    field: z.string(),
    reason: z.string(),
  })
  .strict()

export const RejectReasonCreateManyInputObjectSchema = Schema
