import { z } from 'zod'

export const OrderScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'status',
  'expiredAt',
  'shouldExpiresAt',
  'orderNumber',
  'patientId',
  'customerName',
  'customerTelNo',
  'orderType',
  'purchaseOrigin',
  'purchaseRef',
  'editedProductItemsAt',
  'editedProductItemsBy',
  'shippingFee',
  'shippingFeeDiscount',
  'shippingType',
  'shippingProviderId',
  'recipient',
  'shippingAddress',
  'shippingTelNo',
  'subDistrict',
  'subDistrictKey',
  'district',
  'districtKey',
  'province',
  'provinceKey',
  'postcode',
  'isPaid',
  'isHold',
  'approvedBy',
  'approvedAt',
  'canceledBy',
  'canceledAt',
  'cancelChannel',
  'noteToPatient',
  'useCoin',
  'v2InvoiceId',
  'holdingNote',
  'holdingBy',
  'holdingAt',
  'isDeclinedSuggestedItems',
  'declinedSuggestedItemsReason',
])
