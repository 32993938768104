import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumPaymentGatewayFilterObjectSchema } from './EnumPaymentGatewayFilter.schema'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { EnumRefundStatusFilterObjectSchema } from './EnumRefundStatusFilter.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => RefundScalarWhereInputObjectSchema),
        z.lazy(() => RefundScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => RefundScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => RefundScalarWhereInputObjectSchema),
        z.lazy(() => RefundScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    amount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    currency: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    refundAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    paymentGateway: z
      .union([
        z.lazy(() => EnumPaymentGatewayFilterObjectSchema),
        z.lazy(() => PaymentGatewaySchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumRefundStatusFilterObjectSchema),
        z.lazy(() => RefundStatusSchema),
      ])
      .optional(),
    reason: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const RefundScalarWhereInputObjectSchema = Schema
