import { z } from 'zod'
import { BrandBannerWhereInputObjectSchema } from './objects/BrandBannerWhereInput.schema'
import { BrandBannerOrderByWithAggregationInputObjectSchema } from './objects/BrandBannerOrderByWithAggregationInput.schema'
import { BrandBannerScalarWhereWithAggregatesInputObjectSchema } from './objects/BrandBannerScalarWhereWithAggregatesInput.schema'
import { BrandBannerScalarFieldEnumSchema } from './enums/BrandBannerScalarFieldEnum.schema'

export const BrandBannerGroupBySchema = z.object({
  where: BrandBannerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    BrandBannerOrderByWithAggregationInputObjectSchema,
    BrandBannerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: BrandBannerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(BrandBannerScalarFieldEnumSchema),
})
