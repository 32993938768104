import { Box, Grid, Typography } from '@mui/material'
import { ProductType } from '@prisma/client/products'
import {
  AuxiliaryLabelSchema,
  DrugTypeSchema,
  ProductAttribute,
  ProductConcern,
  ProductForm,
  SkinType,
} from '@ssch-backend/products'
import { FC, useEffect } from 'react'
import { required, useLocaleState } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteArrayInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import {
  getProductAttributeLabel,
  getProductAuxiliaryLabelLabel,
  getProductConcernLabel,
  getProductDrugTypeLabel,
  getProductFormLabel,
  getProductSkinTypeLabel,
} from '../../../enumLabel'

export const ProductPropertySection: FC = () => {
  const productTypes = [
    {
      name: ProductType.SKINCARE,
      fields: {
        productForms: { validate: required() },
        descriptionTr: { validate: required() },
        cautionTr: { validate: undefined },
        skinType: { validate: required() },
      },
    },
    {
      name: ProductType.SUPPLEMENTS,
      fields: {
        productForms: { validate: undefined },
        descriptionTr: { validate: required() },
        cautionTr: { validate: undefined },
        skinType: { validate: required() },
      },
    },
    {
      name: ProductType.TOOLS,
      fields: {
        descriptionTr: { validate: undefined },
        cautionTr: { validate: required() },
        skinType: { validate: required() },
      },
    },
    {
      name: ProductType.DRUG,
      fields: {
        productForms: { validate: undefined },
        auxiliaryLabel: { validate: required() },
        drugType: { validate: required() },
        propertyTr: { validate: required() },
        descriptionTr: { validate: required() },
        sideEffectTr: { validate: required() },
        cautionTr: { validate: undefined },
        skinType: { validate: required() },
      },
    },
  ]

  const allFields = [
    'auxiliaryLabel',
    'drugType',
    'productForms',
    'skinType',
    'propertyTr',
    'descriptionTr',
    'sideEffectTr',
    'cautionTr',
  ]

  const [locale] = useLocaleState()
  const { watch, setValue, resetField } = useFormContext()
  const productTypeInput = watch('type')
  const skinTypeInput = watch('skinType')

  const productType =
    productTypes.find((pT) => pT.name === productTypeInput) ?? productTypes[3]
  const { fields } = productType

  const productSkinTypeChoices = () => {
    if (
      !skinTypeInput ||
      (!skinTypeInput.includes(SkinType.ALL) &&
        !skinTypeInput.includes(SkinType.NA))
    ) {
      return Object.keys(SkinType).map((key: unknown) => {
        return {
          id: key,
          name: getProductSkinTypeLabel(key as keyof typeof SkinType)[
            locale === 'th' ? 'th' : 'en'
          ],
        }
      })
    }

    if (skinTypeInput.includes(SkinType.ALL)) {
      return [
        {
          id: SkinType.ALL,
          name: getProductSkinTypeLabel(SkinType.ALL)[
            locale === 'th' ? 'th' : 'en'
          ],
        },
      ]
    } else {
      return [
        {
          id: SkinType.NA,
          name: getProductSkinTypeLabel(SkinType.NA)[
            locale === 'th' ? 'th' : 'en'
          ],
        },
      ]
    }
  }

  useEffect(() => {
    // clear fields that not existing in productTypeInput
    allFields
      .filter((field: string) => !Object.keys(fields).includes(field))
      .forEach((field: string) => {
        if (field.endsWith('Tr')) {
          resetField(`${field}.th`, { defaultValue: null })
          resetField(`${field}.en`, { defaultValue: null })
        } else {
          resetField(field, { defaultValue: null })
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypeInput])

  useEffect(() => {
    if (skinTypeInput) {
      if (skinTypeInput.includes(SkinType.ALL)) {
        setValue('skinType', [SkinType.ALL])
      } else if (skinTypeInput.includes(SkinType.NA)) {
        setValue('skinType', [SkinType.NA])
      }
    }
  }, [setValue, skinTypeInput, skinTypeInput?.length])

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        คุณสมบัติ
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={
            fields.auxiliaryLabel || fields.drugType ? undefined : 'none'
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gridGap: '16px',
              width: '100%',
            }}
          >
            <FormSelectInput
              label={'ฉลากช่วย'}
              source="auxiliaryLabel"
              validate={fields.auxiliaryLabel?.validate}
              choices={Object.values(AuxiliaryLabelSchema.Enum).map((key) => ({
                id: key,
                name: getProductAuxiliaryLabelLabel(
                  key as keyof typeof AuxiliaryLabelSchema.Enum,
                )[locale === 'th' ? 'th' : 'en'],
              }))}
              sx={
                !fields.auxiliaryLabel
                  ? { display: 'none' }
                  : {
                      width: 'calc(50% - 8px)',
                      '@media (max-width: 900px)': {
                        width: '100%',
                      },
                    }
              }
            />
            <FormSelectInput
              label={'ประเภทยา'}
              source="drugType"
              validate={fields.drugType?.validate}
              choices={Object.keys(DrugTypeSchema.Enum).map((key: unknown) => {
                return {
                  id: key,
                  name: getProductDrugTypeLabel(
                    key as keyof typeof DrugTypeSchema.Enum,
                  )[locale === 'th' ? 'th' : 'en'],
                }
              })}
              sx={
                !fields.drugType
                  ? { display: 'none' }
                  : {
                      width: 'calc(50% - 8px)',
                      '@media (max-width: 900px)': {
                        width: '100%',
                      },
                    }
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display={fields.productForms || fields.skinType ? undefined : 'none'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gridGap: '16px',
              width: '100%',
            }}
          >
            <FormAutoCompleteArrayInput
              label="รูปแบบเภสัชภัณฑ์"
              source="productForms"
              validate={fields.productForms?.validate}
              choices={Object.keys(ProductForm).map((key: unknown) => {
                return {
                  id: key,
                  name: getProductFormLabel(key as keyof typeof ProductForm)[
                    locale === 'th' ? 'th' : 'en'
                  ],
                }
              })}
              sx={
                !fields.productForms
                  ? { display: 'none' }
                  : {
                      width: 'calc(50% - 8px)',
                      '@media (max-width: 900px)': {
                        width: '100%',
                      },
                    }
              }
            />
            <FormAutoCompleteArrayInput
              label="เหมาะกับผิว"
              source="skinType"
              validate={fields.skinType?.validate}
              choices={productSkinTypeChoices()}
              sx={
                !fields.skinType
                  ? { display: 'none' }
                  : {
                      width: 'calc(50% - 8px)',
                      '@media (max-width: 900px)': {
                        width: '100%',
                      },
                    }
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutoCompleteArrayInput
            label="Concern"
            source="concerns"
            validate={required()}
            choices={Object.keys(ProductConcern).map((key: unknown) => {
              return {
                id: key,
                name: getProductConcernLabel(
                  key as keyof typeof ProductConcern,
                )[locale === 'th' ? 'th' : 'en'],
              }
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutoCompleteArrayInput
            label="Attribute"
            source="attributes"
            validate={required()}
            choices={Object.keys(ProductAttribute).map((key: unknown) => {
              return {
                id: key,
                name: getProductAttributeLabel(
                  key as keyof typeof ProductAttribute,
                )[locale === 'th' ? 'th' : 'en'],
              }
            })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.propertyTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ข้อบ่งชี้ / สรรพคุณ"
            source="propertyTr.th"
            multiline
            rows={3}
            validate={fields.propertyTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.propertyTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ข้อบ่งชี้ / สรรพคุณ (Eng.)"
            source="propertyTr.en"
            multiline
            rows={3}
            validate={fields.propertyTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.descriptionTr ? 'none' : undefined}
        >
          <FormTextInput
            label="รายละเอียด"
            source="descriptionTr.th"
            multiline
            rows={3}
            validate={fields.descriptionTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.descriptionTr ? 'none' : undefined}
        >
          <FormTextInput
            label="รายละเอียด (Eng.)"
            source="descriptionTr.en"
            multiline
            rows={3}
            validate={fields.descriptionTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.sideEffectTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ผลข้างเคียง"
            source="sideEffectTr.th"
            validate={fields.sideEffectTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.sideEffectTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ผลข้างเคียง (Eng.)"
            source="sideEffectTr.en"
            validate={fields.sideEffectTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.cautionTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ข้อควรระวัง"
            source="cautionTr.th"
            validate={fields.cautionTr?.validate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={!fields.cautionTr ? 'none' : undefined}
        >
          <FormTextInput
            label="ข้อควรระวัง (Eng.)"
            source="cautionTr.en"
            validate={fields.cautionTr?.validate}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ส่วนประกอบ"
            source="ingredient"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </>
  )
}
