import { z } from 'zod'
import { PatientCountOutputTypeSelectObjectSchema } from './PatientCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => PatientCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const PatientCountOutputTypeArgsObjectSchema = Schema
