import { z } from 'zod'
import { NotificationPatientCreateInputObjectSchema } from './objects/NotificationPatientCreateInput.schema'
import { NotificationPatientUncheckedCreateInputObjectSchema } from './objects/NotificationPatientUncheckedCreateInput.schema'

export const NotificationPatientCreateOneSchema = z.object({
  data: z.union([
    NotificationPatientCreateInputObjectSchema,
    NotificationPatientUncheckedCreateInputObjectSchema,
  ]),
})
