import { z } from 'zod'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureUpdateWithoutProcedureInputObjectSchema } from './ProcedurePictureUpdateWithoutProcedureInput.schema'
import { ProcedurePictureUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedurePictureUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedurePictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedurePictureUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () =>
            ProcedurePictureUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedurePictureUpdateWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
