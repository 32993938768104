import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductPictureCountOrderByAggregateInputObjectSchema } from './ProductPictureCountOrderByAggregateInput.schema'
import { ProductPictureAvgOrderByAggregateInputObjectSchema } from './ProductPictureAvgOrderByAggregateInput.schema'
import { ProductPictureMaxOrderByAggregateInputObjectSchema } from './ProductPictureMaxOrderByAggregateInput.schema'
import { ProductPictureMinOrderByAggregateInputObjectSchema } from './ProductPictureMinOrderByAggregateInput.schema'
import { ProductPictureSumOrderByAggregateInputObjectSchema } from './ProductPictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductPictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductPictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductPictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductPictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductPictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductPictureOrderByWithAggregationInputObjectSchema = Schema
