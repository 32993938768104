import { FC } from 'react'
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  AutocompleteInput,
  AutocompleteInputProps,
  DateInput,
  DateInputProps,
  DateTimeInput,
  DateTimeInputProps,
  NumberInput,
  NumberInputProps,
  PasswordInput,
  PasswordInputProps,
  SelectArrayInput,
  SelectArrayInputProps,
  SelectInput,
  SelectInputProps,
  TextInput,
  TextInputProps,
} from 'react-admin'

export const CustomTextInput: FC<TextInputProps> = (props) => (
  <TextInput {...props} InputLabelProps={{ shrink: true }} />
)

export const CustomNumberInput: FC<NumberInputProps> = (props) => (
  <NumberInput {...props} InputLabelProps={{ shrink: true }} />
)

export const CustomSelectInput: FC<SelectInputProps> = (props) => (
  <SelectInput {...props} InputLabelProps={{ shrink: true }} />
)

export const CustomPasswordInput: FC<PasswordInputProps> = (props) => (
  <PasswordInput {...props} InputLabelProps={{ shrink: true }} />
)

export const CustomDateInput: FC<DateInputProps> = (props) => (
  <DateInput
    {...props}
    InputLabelProps={{ shrink: true }}
    parse={(value) => (value ? new Date(value) : null)}
  />
)
export const CustomDateTimeInput: FC<DateTimeInputProps> = (props) => (
  <DateTimeInput {...props} InputLabelProps={{ shrink: true }} />
)

export const CustomAutoCompleteInput: FC<AutocompleteInputProps> = ({
  sx,
  ...props
}) => (
  <AutocompleteInput
    {...props}
    sx={{ '& legend': { maxWidth: '100% !important' }, ...sx }}
  />
)

export const CustomAutoCompleteArrayInput: FC<AutocompleteArrayInputProps> = ({
  sx,
  ...props
}) => (
  <AutocompleteArrayInput
    {...props}
    sx={{ '& legend': { maxWidth: '100% !important' }, ...sx }}
  />
)

export const CustomSelectArrayInput: FC<SelectArrayInputProps> = ({
  sx,
  ...props
}) => (
  <SelectArrayInput
    {...props}
    sx={{ '& legend': { maxWidth: '100% !important' }, ...sx }}
  />
)
