import { z } from 'zod'
import { DoctorItemCreateInputObjectSchema } from './objects/DoctorItemCreateInput.schema'
import { DoctorItemUncheckedCreateInputObjectSchema } from './objects/DoctorItemUncheckedCreateInput.schema'

export const DoctorItemCreateOneSchema = z.object({
  data: z.union([
    DoctorItemCreateInputObjectSchema,
    DoctorItemUncheckedCreateInputObjectSchema,
  ]),
})
