import { z } from 'zod'
import { BranchCreateWithoutOfficeHoursInputObjectSchema } from './BranchCreateWithoutOfficeHoursInput.schema'
import { BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedCreateWithoutOfficeHoursInput.schema'
import { BranchCreateOrConnectWithoutOfficeHoursInputObjectSchema } from './BranchCreateOrConnectWithoutOfficeHoursInput.schema'
import { BranchUpsertWithoutOfficeHoursInputObjectSchema } from './BranchUpsertWithoutOfficeHoursInput.schema'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchUpdateWithoutOfficeHoursInputObjectSchema } from './BranchUpdateWithoutOfficeHoursInput.schema'
import { BranchUncheckedUpdateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedUpdateWithoutOfficeHoursInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpdateOneRequiredWithoutOfficeHoursNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => BranchCreateWithoutOfficeHoursInputObjectSchema),
          z.lazy(
            () => BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => BranchCreateOrConnectWithoutOfficeHoursInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => BranchUpsertWithoutOfficeHoursInputObjectSchema)
        .optional(),
      connect: z.lazy(() => BranchWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => BranchUpdateWithoutOfficeHoursInputObjectSchema),
          z.lazy(
            () => BranchUncheckedUpdateWithoutOfficeHoursInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const BranchUpdateOneRequiredWithoutOfficeHoursNestedInputObjectSchema =
  Schema
