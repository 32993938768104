import { z } from 'zod'
import { BranchCreateWithoutPartnerInputObjectSchema } from './BranchCreateWithoutPartnerInput.schema'
import { BranchUncheckedCreateWithoutPartnerInputObjectSchema } from './BranchUncheckedCreateWithoutPartnerInput.schema'
import { BranchCreateOrConnectWithoutPartnerInputObjectSchema } from './BranchCreateOrConnectWithoutPartnerInput.schema'
import { BranchUpsertWithWhereUniqueWithoutPartnerInputObjectSchema } from './BranchUpsertWithWhereUniqueWithoutPartnerInput.schema'
import { BranchCreateManyPartnerInputEnvelopeObjectSchema } from './BranchCreateManyPartnerInputEnvelope.schema'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchUpdateWithWhereUniqueWithoutPartnerInputObjectSchema } from './BranchUpdateWithWhereUniqueWithoutPartnerInput.schema'
import { BranchUpdateManyWithWhereWithoutPartnerInputObjectSchema } from './BranchUpdateManyWithWhereWithoutPartnerInput.schema'
import { BranchScalarWhereInputObjectSchema } from './BranchScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUpdateManyWithoutPartnerNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema),
        z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema).array(),
        z.lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema),
        z
          .lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => BranchCreateOrConnectWithoutPartnerInputObjectSchema),
        z
          .lazy(() => BranchCreateOrConnectWithoutPartnerInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => BranchUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
        ),
        z
          .lazy(
            () => BranchUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => BranchCreateManyPartnerInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => BranchWhereUniqueInputObjectSchema),
        z.lazy(() => BranchWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => BranchWhereUniqueInputObjectSchema),
        z.lazy(() => BranchWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => BranchWhereUniqueInputObjectSchema),
        z.lazy(() => BranchWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => BranchWhereUniqueInputObjectSchema),
        z.lazy(() => BranchWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => BranchUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
        ),
        z
          .lazy(
            () => BranchUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => BranchUpdateManyWithWhereWithoutPartnerInputObjectSchema),
        z
          .lazy(() => BranchUpdateManyWithWhereWithoutPartnerInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => BranchScalarWhereInputObjectSchema),
        z.lazy(() => BranchScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const BranchUpdateManyWithoutPartnerNestedInputObjectSchema = Schema
