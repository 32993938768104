import { z } from 'zod'
import { ProductCategoryOrderByWithRelationInputObjectSchema } from './objects/ProductCategoryOrderByWithRelationInput.schema'
import { ProductCategoryWhereInputObjectSchema } from './objects/ProductCategoryWhereInput.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './objects/ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryCountAggregateInputObjectSchema } from './objects/ProductCategoryCountAggregateInput.schema'
import { ProductCategoryMinAggregateInputObjectSchema } from './objects/ProductCategoryMinAggregateInput.schema'
import { ProductCategoryMaxAggregateInputObjectSchema } from './objects/ProductCategoryMaxAggregateInput.schema'
import { ProductCategoryAvgAggregateInputObjectSchema } from './objects/ProductCategoryAvgAggregateInput.schema'
import { ProductCategorySumAggregateInputObjectSchema } from './objects/ProductCategorySumAggregateInput.schema'

export const ProductCategoryAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductCategoryOrderByWithRelationInputObjectSchema,
      ProductCategoryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductCategoryWhereInputObjectSchema.optional(),
  cursor: ProductCategoryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductCategoryCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductCategoryMinAggregateInputObjectSchema.optional(),
  _max: ProductCategoryMaxAggregateInputObjectSchema.optional(),
  _avg: ProductCategoryAvgAggregateInputObjectSchema.optional(),
  _sum: ProductCategorySumAggregateInputObjectSchema.optional(),
})
