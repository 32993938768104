import { z } from 'zod'
import { ProductReviewSummaryCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutProductInput.schema'
import { ProductReviewSummaryCreateOrConnectWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateOrConnectWithoutProductInput.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUncheckedCreateNestedOneWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductReviewSummaryCreateWithoutProductInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductReviewSummaryCreateOrConnectWithoutProductInputObjectSchema,
        )
        .optional(),
      connect: z
        .lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProductReviewSummaryUncheckedCreateNestedOneWithoutProductInputObjectSchema =
  Schema
