import { z } from 'zod'
import { OrderCreateWithoutRefundsInputObjectSchema } from './OrderCreateWithoutRefundsInput.schema'
import { OrderUncheckedCreateWithoutRefundsInputObjectSchema } from './OrderUncheckedCreateWithoutRefundsInput.schema'
import { OrderCreateOrConnectWithoutRefundsInputObjectSchema } from './OrderCreateOrConnectWithoutRefundsInput.schema'
import { OrderUpsertWithoutRefundsInputObjectSchema } from './OrderUpsertWithoutRefundsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutRefundsInputObjectSchema } from './OrderUpdateWithoutRefundsInput.schema'
import { OrderUncheckedUpdateWithoutRefundsInputObjectSchema } from './OrderUncheckedUpdateWithoutRefundsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutRefundsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutRefundsInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutRefundsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutRefundsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutRefundsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutRefundsInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutRefundsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutRefundsNestedInputObjectSchema =
  Schema
