import { z } from 'zod'
import { ProductCategoryCreateWithoutProductInputObjectSchema } from './ProductCategoryCreateWithoutProductInput.schema'
import { ProductCategoryUncheckedCreateWithoutProductInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutProductInput.schema'
import { ProductCategoryCreateOrConnectWithoutProductInputObjectSchema } from './ProductCategoryCreateOrConnectWithoutProductInput.schema'
import { ProductCategoryUpsertWithWhereUniqueWithoutProductInputObjectSchema } from './ProductCategoryUpsertWithWhereUniqueWithoutProductInput.schema'
import { ProductCategoryCreateManyProductInputEnvelopeObjectSchema } from './ProductCategoryCreateManyProductInputEnvelope.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryUpdateWithWhereUniqueWithoutProductInputObjectSchema } from './ProductCategoryUpdateWithWhereUniqueWithoutProductInput.schema'
import { ProductCategoryUpdateManyWithWhereWithoutProductInputObjectSchema } from './ProductCategoryUpdateManyWithWhereWithoutProductInput.schema'
import { ProductCategoryScalarWhereInputObjectSchema } from './ProductCategoryScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUncheckedUpdateManyWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductCategoryCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductCategoryCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpsertWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpsertWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductCategoryCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
          z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpdateWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpdateWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductCategoryUpdateManyWithWhereWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductCategoryUpdateManyWithWhereWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductCategoryScalarWhereInputObjectSchema),
          z.lazy(() => ProductCategoryScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductCategoryUncheckedUpdateManyWithoutProductNestedInputObjectSchema =
  Schema
