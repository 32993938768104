import { z } from 'zod'
import { OfficeHourWhereUniqueInputObjectSchema } from './OfficeHourWhereUniqueInput.schema'
import { OfficeHourUpdateWithoutBranchInputObjectSchema } from './OfficeHourUpdateWithoutBranchInput.schema'
import { OfficeHourUncheckedUpdateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedUpdateWithoutBranchInput.schema'
import { OfficeHourCreateWithoutBranchInputObjectSchema } from './OfficeHourCreateWithoutBranchInput.schema'
import { OfficeHourUncheckedCreateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedCreateWithoutBranchInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUpsertWithWhereUniqueWithoutBranchInput> =
  z
    .object({
      where: z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => OfficeHourUpdateWithoutBranchInputObjectSchema),
        z.lazy(() => OfficeHourUncheckedUpdateWithoutBranchInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema),
        z.lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema),
      ]),
    })
    .strict()

export const OfficeHourUpsertWithWhereUniqueWithoutBranchInputObjectSchema =
  Schema
