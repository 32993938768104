import { z } from 'zod'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureCreateWithoutProductReviewInput.schema'
import { ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedCreateWithoutProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureCreateOrConnectWithoutProductReviewInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewPictureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => ProductReviewPictureCreateWithoutProductReviewInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProductReviewPictureUncheckedCreateWithoutProductReviewInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewPictureCreateOrConnectWithoutProductReviewInputObjectSchema =
  Schema
