import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentUpdateWithoutPatientInputObjectSchema } from './AcceptedConsentUpdateWithoutPatientInput.schema'
import { AcceptedConsentUncheckedUpdateWithoutPatientInputObjectSchema } from './AcceptedConsentUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => AcceptedConsentUpdateWithoutPatientInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedUpdateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
