import { z } from 'zod'
import { OrderUpdateWithoutProductItemsInputObjectSchema } from './OrderUpdateWithoutProductItemsInput.schema'
import { OrderUncheckedUpdateWithoutProductItemsInputObjectSchema } from './OrderUncheckedUpdateWithoutProductItemsInput.schema'
import { OrderCreateWithoutProductItemsInputObjectSchema } from './OrderCreateWithoutProductItemsInput.schema'
import { OrderUncheckedCreateWithoutProductItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutProductItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutProductItemsInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutProductItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutProductItemsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutProductItemsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutProductItemsInputObjectSchema = Schema
