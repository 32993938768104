import { z } from 'zod'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentCreateWithoutTeleconsultInputObjectSchema } from './AppointmentCreateWithoutTeleconsultInput.schema'
import { AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema } from './AppointmentUncheckedCreateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateOrConnectWithoutTeleconsultInput> =
  z
    .object({
      where: z.lazy(() => AppointmentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => AppointmentCreateWithoutTeleconsultInputObjectSchema),
        z.lazy(
          () => AppointmentUncheckedCreateWithoutTeleconsultInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AppointmentCreateOrConnectWithoutTeleconsultInputObjectSchema =
  Schema
