import { z } from 'zod'
import { ProcedureWhereInputObjectSchema } from './objects/ProcedureWhereInput.schema'
import { ProcedureOrderByWithAggregationInputObjectSchema } from './objects/ProcedureOrderByWithAggregationInput.schema'
import { ProcedureScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureScalarWhereWithAggregatesInput.schema'
import { ProcedureScalarFieldEnumSchema } from './enums/ProcedureScalarFieldEnum.schema'

export const ProcedureGroupBySchema = z.object({
  where: ProcedureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureOrderByWithAggregationInputObjectSchema,
    ProcedureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureScalarFieldEnumSchema),
})
