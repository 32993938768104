import { z } from 'zod'
import { ProcedureUpdateWithoutCarouselItemsInputObjectSchema } from './ProcedureUpdateWithoutCarouselItemsInput.schema'
import { ProcedureUncheckedUpdateWithoutCarouselItemsInputObjectSchema } from './ProcedureUncheckedUpdateWithoutCarouselItemsInput.schema'
import { ProcedureCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateWithoutCarouselItemsInput.schema'
import { ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureUncheckedCreateWithoutCarouselItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutCarouselItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureUpdateWithoutCarouselItemsInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedUpdateWithoutCarouselItemsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutCarouselItemsInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProcedureUpsertWithoutCarouselItemsInputObjectSchema = Schema
