import { z } from 'zod'

export const CarouselScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'createdBy',
  'updatedBy',
  'active',
  'priority',
  'startAt',
  'endAt',
  'nameTr',
  'searchField',
])
