import { z } from 'zod'
import { PartnerCreateWithoutSkuInputObjectSchema } from './PartnerCreateWithoutSkuInput.schema'
import { PartnerUncheckedCreateWithoutSkuInputObjectSchema } from './PartnerUncheckedCreateWithoutSkuInput.schema'
import { PartnerCreateOrConnectWithoutSkuInputObjectSchema } from './PartnerCreateOrConnectWithoutSkuInput.schema'
import { PartnerUpsertWithoutSkuInputObjectSchema } from './PartnerUpsertWithoutSkuInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerUpdateWithoutSkuInputObjectSchema } from './PartnerUpdateWithoutSkuInput.schema'
import { PartnerUncheckedUpdateWithoutSkuInputObjectSchema } from './PartnerUncheckedUpdateWithoutSkuInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutSkuNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PartnerCreateWithoutSkuInputObjectSchema),
          z.lazy(() => PartnerUncheckedCreateWithoutSkuInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PartnerCreateOrConnectWithoutSkuInputObjectSchema)
        .optional(),
      upsert: z.lazy(() => PartnerUpsertWithoutSkuInputObjectSchema).optional(),
      connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PartnerUpdateWithoutSkuInputObjectSchema),
          z.lazy(() => PartnerUncheckedUpdateWithoutSkuInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PartnerUpdateOneRequiredWithoutSkuNestedInputObjectSchema = Schema
