import { z } from 'zod'
import { DiscountSettingScalarWhereInputObjectSchema } from './DiscountSettingScalarWhereInput.schema'
import { DiscountSettingUpdateManyMutationInputObjectSchema } from './DiscountSettingUpdateManyMutationInput.schema'
import { DiscountSettingUncheckedUpdateManyWithoutDiscountSettingInputObjectSchema } from './DiscountSettingUncheckedUpdateManyWithoutDiscountSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingUpdateManyWithWhereWithoutGroupInput> =
  z
    .object({
      where: z.lazy(() => DiscountSettingScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => DiscountSettingUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            DiscountSettingUncheckedUpdateManyWithoutDiscountSettingInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DiscountSettingUpdateManyWithWhereWithoutGroupInputObjectSchema =
  Schema
