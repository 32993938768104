import { Typography } from '@mui/material'
import { useRecordContext, useTranslate } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import { DiscountSettngList } from '../../couponGroupDiscountSetting/list'

export const DiscountSetting = () => {
  const record = useRecordContext()
  const translate = useTranslate()
  if (!record) return null
  return (
    <>
      <Typography variant="h5" sx={{ my: 2 }}>
        {translate('resources.couponGroup.title.discount_setting')}
      </Typography>
      <FormDivider />
      <DiscountSettngList
        title={<div></div>}
        filter={{ groupId: record.id }}
        pagination={false}
        actions={false}
        rowClick={false}
        empty={false}
      />
    </>
  )
}
