import { z } from 'zod'
import { BrandBannerSelectObjectSchema } from './BrandBannerSelect.schema'
import { BrandBannerIncludeObjectSchema } from './BrandBannerInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerArgs> = z
  .object({
    select: z.lazy(() => BrandBannerSelectObjectSchema).optional(),
    include: z.lazy(() => BrandBannerIncludeObjectSchema).optional(),
  })
  .strict()

export const BrandBannerArgsObjectSchema = Schema
