import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteCouponGroup, RelatedCouponGroupModel } from "./index"

export const CouponModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  code: z.string(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  titleTr: z.object({ en: z.string(), th: z.string() }),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  discount: z.number(),
  discountType: z.enum(['FIXED', 'PERCENT']),
  isPublic: z.boolean(),
  isOnlyNewPatient: z.boolean(),
  startAt: z.date(),
  expireAt: z.date(),
  orderId: z.number().int().nullish(),
  patientId: z.number().int().nullish(),
  usedAt: z.date().nullish(),
  groupId: z.number().int(),
})

export const CouponModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  code: z.string(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  titleTr: jsonSchema,
  descriptionTr: jsonSchema,
  discount: z.number(),
  discountType: z.enum(['FIXED', 'PERCENT']),
  isPublic: z.boolean(),
  isOnlyNewPatient: z.boolean(),
  startAt: z.date(),
  expireAt: z.date(),
  orderId: z.number().int().nullish(),
  patientId: z.number().int().nullish(),
  usedAt: z.date().nullish(),
  groupId: z.number().int(),
})

export interface CompleteCoupon extends z.infer<typeof CouponModel> {
  group: CompleteCouponGroup
}

/**
 * RelatedCouponModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCouponModel: z.ZodSchema<CompleteCoupon> = z.lazy(() => CouponModel.extend({
  group: RelatedCouponGroupModel,
}))
