import { z } from 'zod'
import { CaseSummaryUpdateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUpdateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutFollowUpAppointmentInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => CaseSummaryUpdateWithoutFollowUpAppointmentInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedUpdateWithoutFollowUpAppointmentInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryUpsertWithoutFollowUpAppointmentInputObjectSchema =
  Schema
