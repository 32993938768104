import { z } from 'zod'
import { ProcedureUpdateWithoutFlashsaleItemInputObjectSchema } from './ProcedureUpdateWithoutFlashsaleItemInput.schema'
import { ProcedureUncheckedUpdateWithoutFlashsaleItemInputObjectSchema } from './ProcedureUncheckedUpdateWithoutFlashsaleItemInput.schema'
import { ProcedureCreateWithoutFlashsaleItemInputObjectSchema } from './ProcedureCreateWithoutFlashsaleItemInput.schema'
import { ProcedureUncheckedCreateWithoutFlashsaleItemInputObjectSchema } from './ProcedureUncheckedCreateWithoutFlashsaleItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutFlashsaleItemInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureUpdateWithoutFlashsaleItemInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedUpdateWithoutFlashsaleItemInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutFlashsaleItemInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedCreateWithoutFlashsaleItemInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProcedureUpsertWithoutFlashsaleItemInputObjectSchema = Schema
