import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.WaitingBannerWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
  })
  .strict()

export const WaitingBannerWhereUniqueInputObjectSchema = Schema
