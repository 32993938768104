import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () =>
            CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInputObjectSchema =
  Schema
