import { z } from 'zod'
import { UsageSchema } from '../enums/Usage.schema'
import { NestedEnumUsageNullableFilterObjectSchema } from './NestedEnumUsageNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUsageNullableFilter> = z
  .object({
    equals: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => UsageSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => UsageSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => UsageSchema),
        z.lazy(() => NestedEnumUsageNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumUsageNullableFilterObjectSchema = Schema
