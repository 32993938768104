import { z } from 'zod'
import { SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateManyCaseSummaryInputEnvelopeObjectSchema } from './SuggestedProcedureItemCreateManyCaseSummaryInputEnvelope.schema'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemScalarWhereInputObjectSchema } from './SuggestedProcedureItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUncheckedUpdateManyWithoutCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            SuggestedProcedureItemCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema),
          z
            .lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const SuggestedProcedureItemUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema =
  Schema
