import { z } from 'zod'
import { CaseSummarySelectObjectSchema } from './CaseSummarySelect.schema'
import { CaseSummaryIncludeObjectSchema } from './CaseSummaryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryArgs> = z
  .object({
    select: z.lazy(() => CaseSummarySelectObjectSchema).optional(),
    include: z.lazy(() => CaseSummaryIncludeObjectSchema).optional(),
  })
  .strict()

export const CaseSummaryArgsObjectSchema = Schema
