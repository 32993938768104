import { z } from 'zod'
import { ProductItemOriginalCreateManyOrderInputObjectSchema } from './ProductItemOriginalCreateManyOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalCreateManyOrderInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => ProductItemOriginalCreateManyOrderInputObjectSchema),
        z
          .lazy(() => ProductItemOriginalCreateManyOrderInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProductItemOriginalCreateManyOrderInputEnvelopeObjectSchema =
  Schema
