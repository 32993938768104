import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    editedProductItemsBy: z.literal(true).optional(),
    shippingFee: z.literal(true).optional(),
    shippingFeeDiscount: z.literal(true).optional(),
    shippingProviderId: z.literal(true).optional(),
    approvedBy: z.literal(true).optional(),
    canceledBy: z.literal(true).optional(),
    useCoin: z.literal(true).optional(),
    v2InvoiceId: z.literal(true).optional(),
    holdingBy: z.literal(true).optional(),
  })
  .strict()

export const OrderAvgAggregateInputObjectSchema = Schema
