import { z } from 'zod'
import { CouponCreateWithoutOrderInputObjectSchema } from './CouponCreateWithoutOrderInput.schema'
import { CouponUncheckedCreateWithoutOrderInputObjectSchema } from './CouponUncheckedCreateWithoutOrderInput.schema'
import { CouponCreateOrConnectWithoutOrderInputObjectSchema } from './CouponCreateOrConnectWithoutOrderInput.schema'
import { CouponUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './CouponUpsertWithWhereUniqueWithoutOrderInput.schema'
import { CouponCreateManyOrderInputEnvelopeObjectSchema } from './CouponCreateManyOrderInputEnvelope.schema'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './CouponUpdateWithWhereUniqueWithoutOrderInput.schema'
import { CouponUpdateManyWithWhereWithoutOrderInputObjectSchema } from './CouponUpdateManyWithWhereWithoutOrderInput.schema'
import { CouponScalarWhereInputObjectSchema } from './CouponScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponUncheckedUpdateManyWithoutOrderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CouponCreateWithoutOrderInputObjectSchema),
          z.lazy(() => CouponCreateWithoutOrderInputObjectSchema).array(),
          z.lazy(() => CouponUncheckedCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => CouponUncheckedCreateWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => CouponCreateOrConnectWithoutOrderInputObjectSchema),
          z
            .lazy(() => CouponCreateOrConnectWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () => CouponUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () => CouponUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CouponCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => CouponWhereUniqueInputObjectSchema),
          z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CouponWhereUniqueInputObjectSchema),
          z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CouponWhereUniqueInputObjectSchema),
          z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CouponWhereUniqueInputObjectSchema),
          z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () => CouponUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () => CouponUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(() => CouponUpdateManyWithWhereWithoutOrderInputObjectSchema),
          z
            .lazy(() => CouponUpdateManyWithWhereWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CouponScalarWhereInputObjectSchema),
          z.lazy(() => CouponScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CouponUncheckedUpdateManyWithoutOrderNestedInputObjectSchema =
  Schema
