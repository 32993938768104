import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { RejectReasonCountOrderByAggregateInputObjectSchema } from './RejectReasonCountOrderByAggregateInput.schema'
import { RejectReasonAvgOrderByAggregateInputObjectSchema } from './RejectReasonAvgOrderByAggregateInput.schema'
import { RejectReasonMaxOrderByAggregateInputObjectSchema } from './RejectReasonMaxOrderByAggregateInput.schema'
import { RejectReasonMinOrderByAggregateInputObjectSchema } from './RejectReasonMinOrderByAggregateInput.schema'
import { RejectReasonSumOrderByAggregateInputObjectSchema } from './RejectReasonSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    kycDataId: z.lazy(() => SortOrderSchema).optional(),
    field: z.lazy(() => SortOrderSchema).optional(),
    reason: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => RejectReasonCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => RejectReasonAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => RejectReasonMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => RejectReasonMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => RejectReasonSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const RejectReasonOrderByWithAggregationInputObjectSchema = Schema
