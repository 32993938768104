import { z } from 'zod'
import { CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    codePlain: z.string(),
    term: z.string(),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutDiagnosisInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const DiagnosisUncheckedCreateInputObjectSchema = Schema
