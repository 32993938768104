import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Edit, SaveButton, SimpleForm, useTranslate } from 'react-admin'
import { FormDivider } from '../../components/FormInput/divider'
import { CarouselItemSection } from './create-edit-section/carouselItem'
import { CarouselProcedureInfo } from './create-edit-section/carouselProcedureInfo'

const CarouselProcedureEditToolbar = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}
  >
    <SaveButton />
  </Box>
)

const CarouselProcedureEditForm = () => {
  const translate = useTranslate()
  return (
    <SimpleForm toolbar={false} sx={{ p: '16px !important' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {translate('resources.carouselProcedure.title.campaign_section')}
      </Typography>
      <FormDivider />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <CarouselProcedureInfo mode="EDIT" />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CarouselProcedureEditToolbar />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const CarouselProcedureEdit = () => {
  return (
    <Edit redirect="list" component="div" mutationMode="pessimistic">
      <Box>
        <Card>
          <CardContent>
            <CarouselProcedureEditForm />
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ p: 2 }}>
              <CarouselItemSection />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Edit>
  )
}
