import { z } from 'zod'
import { MyReviewOrderByWithRelationInputObjectSchema } from './objects/MyReviewOrderByWithRelationInput.schema'
import { MyReviewWhereInputObjectSchema } from './objects/MyReviewWhereInput.schema'
import { MyReviewWhereUniqueInputObjectSchema } from './objects/MyReviewWhereUniqueInput.schema'
import { MyReviewCountAggregateInputObjectSchema } from './objects/MyReviewCountAggregateInput.schema'
import { MyReviewMinAggregateInputObjectSchema } from './objects/MyReviewMinAggregateInput.schema'
import { MyReviewMaxAggregateInputObjectSchema } from './objects/MyReviewMaxAggregateInput.schema'
import { MyReviewAvgAggregateInputObjectSchema } from './objects/MyReviewAvgAggregateInput.schema'
import { MyReviewSumAggregateInputObjectSchema } from './objects/MyReviewSumAggregateInput.schema'

export const MyReviewAggregateSchema = z.object({
  orderBy: z
    .union([
      MyReviewOrderByWithRelationInputObjectSchema,
      MyReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: MyReviewWhereInputObjectSchema.optional(),
  cursor: MyReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), MyReviewCountAggregateInputObjectSchema])
    .optional(),
  _min: MyReviewMinAggregateInputObjectSchema.optional(),
  _max: MyReviewMaxAggregateInputObjectSchema.optional(),
  _avg: MyReviewAvgAggregateInputObjectSchema.optional(),
  _sum: MyReviewSumAggregateInputObjectSchema.optional(),
})
