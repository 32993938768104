import { z } from 'zod'
import { TeleconsultCreateWithoutCaseInputObjectSchema } from './TeleconsultCreateWithoutCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutCaseInput.schema'
import { TeleconsultCreateOrConnectWithoutCaseInputObjectSchema } from './TeleconsultCreateOrConnectWithoutCaseInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateNestedOneWithoutCaseInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => TeleconsultCreateWithoutCaseInputObjectSchema),
        z.lazy(() => TeleconsultUncheckedCreateWithoutCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => TeleconsultCreateOrConnectWithoutCaseInputObjectSchema)
      .optional(),
    connect: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const TeleconsultCreateNestedOneWithoutCaseInputObjectSchema = Schema
