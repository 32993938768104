import { z } from 'zod'
import { HomeBannerUpdateInputObjectSchema } from './objects/HomeBannerUpdateInput.schema'
import { HomeBannerUncheckedUpdateInputObjectSchema } from './objects/HomeBannerUncheckedUpdateInput.schema'
import { HomeBannerWhereUniqueInputObjectSchema } from './objects/HomeBannerWhereUniqueInput.schema'

export const HomeBannerUpdateOneSchema = z.object({
  data: z.union([
    HomeBannerUpdateInputObjectSchema,
    HomeBannerUncheckedUpdateInputObjectSchema,
  ]),
  where: HomeBannerWhereUniqueInputObjectSchema,
})
