import { z } from 'zod'
import { RejectReasonCreateInputObjectSchema } from './objects/RejectReasonCreateInput.schema'
import { RejectReasonUncheckedCreateInputObjectSchema } from './objects/RejectReasonUncheckedCreateInput.schema'

export const RejectReasonCreateOneSchema = z.object({
  data: z.union([
    RejectReasonCreateInputObjectSchema,
    RejectReasonUncheckedCreateInputObjectSchema,
  ]),
})
