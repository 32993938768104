import { z } from 'zod'
import { OrderScalarWhereInputObjectSchema } from './OrderScalarWhereInput.schema'
import { OrderUpdateManyMutationInputObjectSchema } from './OrderUpdateManyMutationInput.schema'
import { OrderUncheckedUpdateManyWithoutOrdersInputObjectSchema } from './OrderUncheckedUpdateManyWithoutOrdersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateManyWithWhereWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => OrderScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => OrderUpdateManyMutationInputObjectSchema),
        z.lazy(() => OrderUncheckedUpdateManyWithoutOrdersInputObjectSchema),
      ]),
    })
    .strict()

export const OrderUpdateManyWithWhereWithoutShippingProviderInputObjectSchema =
  Schema
