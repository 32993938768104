import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteDelivery, RelatedDeliveryModel } from "./index"

export const DeliveryLogModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deliveryId: z.number().int().nullish(),
  statusCode: z.string(),
  statusText: z.string(),
  statusDate: z.date(),
  rawData: jsonSchema,
})

export interface CompleteDeliveryLog extends z.infer<typeof DeliveryLogModel> {
  delivery?: CompleteDelivery | null
}

/**
 * RelatedDeliveryLogModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDeliveryLogModel: z.ZodSchema<CompleteDeliveryLog> = z.lazy(() => DeliveryLogModel.extend({
  delivery: RelatedDeliveryModel.nullish(),
}))
