import { Box } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  ImageField,
  List,
  Pagination,
  TextField,
} from 'react-admin'
import { CustomBooleanField } from '../../components/CustomField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { WebsiteField } from '../../components/WebsiteField'
import { FilterListForm } from './list-section/filterlistForm'

const rowsPerPage = [5, 10, 20]

const PartnerListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}

export const PartnerList = () => (
  <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
    <List
      disableSyncWithLocation
      perPage={rowsPerPage[2]}
      pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
      actions={<PartnerListAction />}
      sort={{ field: 'priority', order: 'DESC' }}
    >
      <Datagrid size="small" rowClick="edit">
        <TextField source="id" />
        <ImageField
          source="logo.src"
          label="Logo"
          sx={{
            textAlign: 'center',
            '& img': {
              width: 'auto !important',
              height: 'auto !important',
              maxWidth: '100px !important',
            },
          }}
          textAlign="center"
          sortable={false}
        />
        <TextField source="nameTr.th" label="Name" />
        <TextField source="phone" />
        <FunctionField
          label="Website"
          render={(record: { website: string }) => WebsiteField(record.website)}
          onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
        />
        <LocaleDateField source="updatedAt" showTime label="Updated At" />
        <TextField source="priority" textAlign="center" />
        <CustomBooleanField source="active" label="Active" />
        <CustomBooleanField source="isSXEasy" label="SXEasy" />
      </Datagrid>
    </List>
  </Box>
)
