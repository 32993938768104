import { z } from 'zod'
import { DoctorItemWhereInputObjectSchema } from './DoctorItemWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemRelationFilter> = z
  .object({
    is: z
      .lazy(() => DoctorItemWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => DoctorItemWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const DoctorItemRelationFilterObjectSchema = Schema
