import { z } from 'zod'
import { WarehouseUpdateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUpdateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedUpdateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedUpdateWithoutVariantWarehousesInput.schema'
import { WarehouseCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateWithoutVariantWarehousesInput.schema'
import { WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './WarehouseUncheckedCreateWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUpsertWithoutVariantWarehousesInput> = z
  .object({
    update: z.union([
      z.lazy(() => WarehouseUpdateWithoutVariantWarehousesInputObjectSchema),
      z.lazy(
        () => WarehouseUncheckedUpdateWithoutVariantWarehousesInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => WarehouseCreateWithoutVariantWarehousesInputObjectSchema),
      z.lazy(
        () => WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const WarehouseUpsertWithoutVariantWarehousesInputObjectSchema = Schema
