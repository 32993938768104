import { Box } from '@mui/material'
import { addMinutes, endOfDay, format, startOfDay } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useLoading } from '../../components/GlobalLoading'
import { adminGatewayClient } from '../../service'
import { FullCalendarApp } from './calendar'
import { FilterListForm } from './filterlistForm'
import { CalendarSlotInfoProps } from './schedule.interface'

export const DoctorScheduler = () => {
  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const [doctorId, setDoctorId] = useState<number | null>(null)
  const [patientId, setPatientId] = useState<number | null>(null)
  const [from, setFrom] = useState<Date>(firstDay)
  const [to, setTo] = useState<Date>(lastDay)
  const [availableSlot, setAvailableSlot] = useState<CalendarSlotInfoProps[]>(
    [],
  )
  const { increaseLoading, decreaseLoading } = useLoading()

  const fetchAvailableSlot = useCallback(async () => {
    if ((doctorId || patientId) && from && to) {
      increaseLoading()
      try {
        const response =
          await adminGatewayClient.schedule.getScheduleByDateRange.query({
            doctorId,
            patientId,
            from: startOfDay(from),
            to: endOfDay(to),
          })
        const slots = []
        for (const slot of response) {
          const appointment = slot.appointment || null
          const patient = slot.appointment?.patient || null
          slots.push({
            title: format(slot.availableAt, 'd MMMM yyyy HH:mm'),
            start: slot.availableAt,
            end: addMinutes(slot.availableAt, 60),
            extendedProps: {
              slot: {
                id: slot.id,
                doctorId: slot.doctorId,
                availableAt: slot.availableAt,
                appointment: appointment
                  ? {
                      id: appointment.id,
                      doctorId: appointment.doctorId,
                      patientId: appointment.patientId,
                      type: appointment.type,
                      status: appointment.status,
                      caseId: appointment.caseId,
                      scheduleId: appointment.scheduleId,
                      appointmentAt: appointment.appointmentAt,
                      teleconsultId: appointment.teleconsultId,
                      patient: patient
                        ? {
                            id: patient.id,
                            prefix: patient.prefix || '',
                            firstname: patient.firstname || '',
                            lastname: patient.lastname || '',
                          }
                        : null,
                    }
                  : null,
                doctor: {
                  id: slot.doctor.id,
                  prefix: slot.doctor.prefix,
                  firstname: slot.doctor.firstname,
                  lastname: slot.doctor.lastname,
                },
              },
            },
          })
        }
        setAvailableSlot(slots)
      } catch (error) {
        console.error(error)
        alert(error)
      } finally {
        decreaseLoading()
      }
    }
  }, [decreaseLoading, doctorId, from, increaseLoading, patientId, to])

  useEffect(() => {
    fetchAvailableSlot()
  }, [fetchAvailableSlot, from, to])

  return (
    <Box sx={{ padding: '1rem 0' }}>
      <Box sx={{ padding: '1rem 0' }}>
        <FilterListForm
          fetchAvailableSlot={fetchAvailableSlot}
          setDoctorId={setDoctorId}
          setPatientId={setPatientId}
        />
      </Box>
      <FullCalendarApp
        fetchAvailableSlot={fetchAvailableSlot}
        availableSlot={availableSlot}
        setFrom={setFrom}
        setTo={setTo}
      />
    </Box>
  )
}
