import { z } from 'zod'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemUpdateWithoutRefundInputObjectSchema } from './ProcedureItemUpdateWithoutRefundInput.schema'
import { ProcedureItemUncheckedUpdateWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedUpdateWithoutRefundInput.schema'
import { ProcedureItemCreateWithoutRefundInputObjectSchema } from './ProcedureItemCreateWithoutRefundInput.schema'
import { ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedCreateWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemUpsertWithWhereUniqueWithoutRefundInput> =
  z
    .object({
      where: z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProcedureItemUpdateWithoutRefundInputObjectSchema),
        z.lazy(
          () => ProcedureItemUncheckedUpdateWithoutRefundInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProcedureItemCreateWithoutRefundInputObjectSchema),
        z.lazy(
          () => ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema =
  Schema
