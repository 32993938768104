import { z } from 'zod'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewCreateOrConnectWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema =
  Schema
