import { z } from 'zod'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumUseFrequencyFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => UseFrequencySchema).optional(),
  })
  .strict()

export const EnumUseFrequencyFieldUpdateOperationsInputObjectSchema = Schema
