import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultMaxOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    videoRoomId: z.lazy(() => SortOrderSchema).optional(),
    doctorVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    patientVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    callTime: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    missedCallCaseId: z.lazy(() => SortOrderSchema).optional(),
    remark: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const TeleconsultMaxOrderByAggregateInputObjectSchema = Schema
