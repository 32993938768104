import { z } from 'zod'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutMissedCallCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateOrConnectWithoutMissedCallCaseInput> =
  z
    .object({
      where: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema),
        z.lazy(
          () =>
            TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const TeleconsultCreateOrConnectWithoutMissedCallCaseInputObjectSchema =
  Schema
