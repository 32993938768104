import { z } from 'zod'
import { ArticlePictureWhereUniqueInputObjectSchema } from './ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureUpdateWithoutArticleInputObjectSchema } from './ArticlePictureUpdateWithoutArticleInput.schema'
import { ArticlePictureUncheckedUpdateWithoutArticleInputObjectSchema } from './ArticlePictureUncheckedUpdateWithoutArticleInput.schema'
import { ArticlePictureCreateWithoutArticleInputObjectSchema } from './ArticlePictureCreateWithoutArticleInput.schema'
import { ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema } from './ArticlePictureUncheckedCreateWithoutArticleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureUpsertWithWhereUniqueWithoutArticleInput> =
  z
    .object({
      where: z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ArticlePictureUpdateWithoutArticleInputObjectSchema),
        z.lazy(
          () => ArticlePictureUncheckedUpdateWithoutArticleInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ArticlePictureCreateWithoutArticleInputObjectSchema),
        z.lazy(
          () => ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ArticlePictureUpsertWithWhereUniqueWithoutArticleInputObjectSchema =
  Schema
