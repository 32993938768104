import { z } from 'zod'
import { DiscountSettingCreateWithoutGroupInputObjectSchema } from './DiscountSettingCreateWithoutGroupInput.schema'
import { DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema } from './DiscountSettingUncheckedCreateWithoutGroupInput.schema'
import { DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema } from './DiscountSettingCreateOrConnectWithoutGroupInput.schema'
import { DiscountSettingCreateManyGroupInputEnvelopeObjectSchema } from './DiscountSettingCreateManyGroupInputEnvelope.schema'
import { DiscountSettingWhereUniqueInputObjectSchema } from './DiscountSettingWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingCreateNestedManyWithoutGroupInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DiscountSettingCreateWithoutGroupInputObjectSchema),
          z
            .lazy(() => DiscountSettingCreateWithoutGroupInputObjectSchema)
            .array(),
          z.lazy(
            () => DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingUncheckedCreateWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () => DiscountSettingCreateOrConnectWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => DiscountSettingCreateManyGroupInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema),
          z.lazy(() => DiscountSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const DiscountSettingCreateNestedManyWithoutGroupInputObjectSchema =
  Schema
