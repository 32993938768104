import { z } from 'zod'
import { ProductCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewSummaryInput.schema'
import { ProductCreateOrConnectWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateOrConnectWithoutProductReviewSummaryInput.schema'
import { ProductUpsertWithoutProductReviewSummaryInputObjectSchema } from './ProductUpsertWithoutProductReviewSummaryInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutProductReviewSummaryInputObjectSchema } from './ProductUpdateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedUpdateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedUpdateWithoutProductReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutProductReviewSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProductCreateWithoutProductReviewSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProductCreateOrConnectWithoutProductReviewSummaryInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutProductReviewSummaryInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => ProductUpdateWithoutProductReviewSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProductUncheckedUpdateWithoutProductReviewSummaryInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutProductReviewSummaryNestedInputObjectSchema =
  Schema
