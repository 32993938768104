import { z } from 'zod'
import { ConsentWhereInputObjectSchema } from './objects/ConsentWhereInput.schema'
import { ConsentOrderByWithAggregationInputObjectSchema } from './objects/ConsentOrderByWithAggregationInput.schema'
import { ConsentScalarWhereWithAggregatesInputObjectSchema } from './objects/ConsentScalarWhereWithAggregatesInput.schema'
import { ConsentScalarFieldEnumSchema } from './enums/ConsentScalarFieldEnum.schema'

export const ConsentGroupBySchema = z.object({
  where: ConsentWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ConsentOrderByWithAggregationInputObjectSchema,
    ConsentOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ConsentScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ConsentScalarFieldEnumSchema),
})
