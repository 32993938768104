import { z } from 'zod'

export const KycDataScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'patientId',
  'channel',
  'status',
  'approvedAt',
  'approvedBy',
  'prefix',
  'firstname',
  'lastname',
  'gender',
  'birthDate',
  'idCardNumber',
  'idCardPicture',
  'selfieIdCardPicture',
  'passportNumber',
  'passportPicture',
  'selfiePassportPicture',
  'rejectAt',
  'rejectBy',
])
