import { z } from 'zod'
import { CasePictureWhereUniqueInputObjectSchema } from './CasePictureWhereUniqueInput.schema'
import { CasePictureUpdateWithoutCaseInputObjectSchema } from './CasePictureUpdateWithoutCaseInput.schema'
import { CasePictureUncheckedUpdateWithoutCaseInputObjectSchema } from './CasePictureUncheckedUpdateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureUpdateWithWhereUniqueWithoutCaseInput> =
  z
    .object({
      where: z.lazy(() => CasePictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CasePictureUpdateWithoutCaseInputObjectSchema),
        z.lazy(() => CasePictureUncheckedUpdateWithoutCaseInputObjectSchema),
      ]),
    })
    .strict()

export const CasePictureUpdateWithWhereUniqueWithoutCaseInputObjectSchema =
  Schema
