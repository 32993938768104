import { Box } from '@mui/material'
import { FC } from 'react'
import {
  AutocompleteArrayInput,
  FilterPayload,
  ReferenceArrayInput,
  Validator,
} from 'react-admin'

export interface FormReferenceArrayInputProps {
  source: string
  reference: string
  optionText: string
  label: string
  validator?: Validator
  filter?: FilterPayload
}

export const FormReferenceArrayInput: FC<FormReferenceArrayInputProps> = ({
  source,
  reference,
  optionText,
  label,
  validator,
  filter,
}) => {
  const filterToQuery = (searchText: string) => {
    const filterObject = {} as { [key: string]: string }
    filterObject[optionText] = searchText
    return filterObject
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <ReferenceArrayInput
            source={source}
            reference={reference}
            sort={{ field: 'id', order: 'ASC' }}
            filter={filter}
          >
            <AutocompleteArrayInput
              fullWidth
              optionText={optionText}
              label={label}
              filterToQuery={filterToQuery}
              disableClearable
              sx={{ '& legend': { maxWidth: '100% !important', mb: 1 } }}
              validate={validator}
            />
          </ReferenceArrayInput>
        </Box>
      </Box>
    </Box>
  )
}
