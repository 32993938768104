import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OrderOrderByWithRelationInputObjectSchema } from './OrderOrderByWithRelationInput.schema'
import { ProductItemOrderByRelationAggregateInputObjectSchema } from './ProductItemOrderByRelationAggregateInput.schema'
import { DeliveryLogOrderByRelationAggregateInputObjectSchema } from './DeliveryLogOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    shippingProvider: z.lazy(() => SortOrderSchema).optional(),
    trackingNumber: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    deliveredAt: z.lazy(() => SortOrderSchema).optional(),
    partnerName: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    order: z.lazy(() => OrderOrderByWithRelationInputObjectSchema).optional(),
    productItems: z
      .lazy(() => ProductItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    deliveryLogs: z
      .lazy(() => DeliveryLogOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryOrderByWithRelationInputObjectSchema = Schema
