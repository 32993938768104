import { z } from 'zod'
import { ProcedureOrderCreateManyPartnerInputObjectSchema } from './ProcedureOrderCreateManyPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderCreateManyPartnerInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => ProcedureOrderCreateManyPartnerInputObjectSchema),
      z.lazy(() => ProcedureOrderCreateManyPartnerInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const ProcedureOrderCreateManyPartnerInputEnvelopeObjectSchema = Schema
