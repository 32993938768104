import { z } from 'zod'
import { ArticlePictureFindManySchema } from '../findManyArticlePicture.schema'
import { ArticleCountOutputTypeArgsObjectSchema } from './ArticleCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    coverPicture: z.boolean().optional(),
    titleTr: z.boolean().optional(),
    detailTr: z.boolean().optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ArticlePictureFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ArticleCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ArticleSelectObjectSchema = Schema
