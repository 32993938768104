import { z } from 'zod'
import { ProcedureItemCreateWithoutRefundInputObjectSchema } from './ProcedureItemCreateWithoutRefundInput.schema'
import { ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedCreateWithoutRefundInput.schema'
import { ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema } from './ProcedureItemCreateOrConnectWithoutRefundInput.schema'
import { ProcedureItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema } from './ProcedureItemUpsertWithWhereUniqueWithoutRefundInput.schema'
import { ProcedureItemCreateManyRefundInputEnvelopeObjectSchema } from './ProcedureItemCreateManyRefundInputEnvelope.schema'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema } from './ProcedureItemUpdateWithWhereUniqueWithoutRefundInput.schema'
import { ProcedureItemUpdateManyWithWhereWithoutRefundInputObjectSchema } from './ProcedureItemUpdateManyWithWhereWithoutRefundInput.schema'
import { ProcedureItemScalarWhereInputObjectSchema } from './ProcedureItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemUpdateManyWithoutRefundNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureItemCreateWithoutRefundInputObjectSchema),
          z
            .lazy(() => ProcedureItemCreateWithoutRefundInputObjectSchema)
            .array(),
          z.lazy(
            () => ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemUncheckedCreateWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () => ProcedureItemCreateOrConnectWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProcedureItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureItemUpsertWithWhereUniqueWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureItemCreateManyRefundInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProcedureItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProcedureItemUpdateManyWithWhereWithoutRefundInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureItemUpdateManyWithWhereWithoutRefundInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProcedureItemScalarWhereInputObjectSchema),
          z.lazy(() => ProcedureItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureItemUpdateManyWithoutRefundNestedInputObjectSchema =
  Schema
