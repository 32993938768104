import { z } from 'zod'

export const VariantPictureScalarFieldEnumSchema = z.enum([
  'id',
  'url',
  'variantSku',
  'rank',
  'createdAt',
  'updatedAt',
])
