import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCountOutputTypeSelect> = z
  .object({
    productItemsOriginal: z.boolean().optional(),
    productItems: z.boolean().optional(),
    procedureItems: z.boolean().optional(),
    coupons: z.boolean().optional(),
    payments: z.boolean().optional(),
    refunds: z.boolean().optional(),
    deliveries: z.boolean().optional(),
  })
  .strict()

export const OrderCountOutputTypeSelectObjectSchema = Schema
