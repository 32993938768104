import { z } from 'zod'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'
import { NotificationUpdateWithoutSegmentsInputObjectSchema } from './NotificationUpdateWithoutSegmentsInput.schema'
import { NotificationUncheckedUpdateWithoutSegmentsInputObjectSchema } from './NotificationUncheckedUpdateWithoutSegmentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpdateWithWhereUniqueWithoutSegmentsInput> =
  z
    .object({
      where: z.lazy(() => NotificationWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => NotificationUpdateWithoutSegmentsInputObjectSchema),
        z.lazy(
          () => NotificationUncheckedUpdateWithoutSegmentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationUpdateWithWhereUniqueWithoutSegmentsInputObjectSchema =
  Schema
