import { FC, useCallback, useState } from 'react'
import {
  Lightbox as LightboxBase,
  LightboxExternalProps,
  SlideImage,
} from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import 'yet-another-react-lightbox/plugins/captions.css'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import Rotate from './Modules/Rotate'
import './lightbox.css'

export const Lightbox: FC<LightboxExternalProps> = (props) => {
  const {
    animation = {},
    styles = {},
    plugins = [Fullscreen, Captions, Zoom, Rotate],
    carousel = {},
    captions = {},
    slides = [],
    zoom = {},
    ...rest
  } = props
  const lightboxAnimation = {
    fade: 0,
    swipe: 0,
    ...animation,
  }
  const lightboxStyles = {
    ...styles,
    root: {
      '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)',
      '--yarl__slide_captions_container_background': 'rgba(0, 0, 0, 0)',
      '--yarl__slide_description_text_align': 'center',
      ...styles.root,
    },
  }
  const lightboxCarousel = {
    finite: true,
    padding: '64px',
    ...carousel,
  }
  const lightboxCaptions: LightboxExternalProps['captions'] = {
    descriptionTextAlign: 'center',
    ...captions,
  }
  const lightboxSlides = slides.map((slide, index) => ({
    description: `${(slide as SlideImage).alt}\n${index + 1} / ${
      slides.length
    }`,
    ...slide,
  }))
  const lightboxZoom: LightboxExternalProps['zoom'] = {
    maxZoomPixelRatio: 10,
    ...zoom,
  }
  return (
    <LightboxBase
      styles={lightboxStyles}
      animation={lightboxAnimation}
      plugins={plugins}
      carousel={lightboxCarousel}
      captions={lightboxCaptions}
      slides={lightboxSlides}
      zoom={lightboxZoom}
      {...rest}
    />
  )
}

export const useLightbox = () => {
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const openLightbox = useCallback((index = 0) => {
    setOpen(true)
    setIndex(index)
  }, [])
  const closeLightbox = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    open,
    index,
    openLightbox,
    closeLightbox,
  }
}
