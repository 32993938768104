import { z } from 'zod'
import { FlashsaleItemCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutFlashsaleInput.schema'
import { FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInput.schema'
import { FlashsaleItemCreateManyFlashsaleInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyFlashsaleInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInput.schema'
import { FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInput.schema'
import { FlashsaleItemScalarWhereInputObjectSchema } from './FlashsaleItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateManyWithoutFlashsaleNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpsertWithWhereUniqueWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyFlashsaleInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateWithWhereUniqueWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateManyWithWhereWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemUpdateManyWithoutFlashsaleNestedInputObjectSchema =
  Schema
