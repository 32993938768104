import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'
import { VariantWarehouseCreateNestedManyWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateNestedManyWithoutWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCreateInput> = z
  .object({
    name: z.string(),
    code: z.lazy(() => WarehouseCodeSchema),
    variantWarehouses: z
      .lazy(
        () => VariantWarehouseCreateNestedManyWithoutWarehouseInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const WarehouseCreateInputObjectSchema = Schema
