import { z } from 'zod'
import { FlashsaleItemSelectObjectSchema } from './FlashsaleItemSelect.schema'
import { FlashsaleItemIncludeObjectSchema } from './FlashsaleItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemArgs> = z
  .object({
    select: z.lazy(() => FlashsaleItemSelectObjectSchema).optional(),
    include: z.lazy(() => FlashsaleItemIncludeObjectSchema).optional(),
  })
  .strict()

export const FlashsaleItemArgsObjectSchema = Schema
