import { z } from 'zod'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'
import { ProcedureReviewPictureFindManySchema } from '../findManyProcedureReviewPicture.schema'
import { ProcedureReviewCountOutputTypeArgsObjectSchema } from './ProcedureReviewCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    procedureId: z.boolean().optional(),
    partnerId: z.boolean().optional(),
    overallRating: z.boolean().optional(),
    environmentRating: z.boolean().optional(),
    serviceRating: z.boolean().optional(),
    specialtyRating: z.boolean().optional(),
    valueRating: z.boolean().optional(),
    message: z.boolean().optional(),
    orderId: z.boolean().optional(),
    patientId: z.boolean().optional(),
    patientName: z.boolean().optional(),
    isAnonymous: z.boolean().optional(),
    priority: z.boolean().optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProcedureReviewPictureFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureReviewCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureReviewSelectObjectSchema = Schema
