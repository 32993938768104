import { z } from 'zod'
import { ProductReviewSummaryCreateWithoutBrandInputObjectSchema } from './ProductReviewSummaryCreateWithoutBrandInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutBrandInput.schema'
import { ProductReviewSummaryCreateOrConnectWithoutBrandInputObjectSchema } from './ProductReviewSummaryCreateOrConnectWithoutBrandInput.schema'
import { ProductReviewSummaryCreateManyBrandInputEnvelopeObjectSchema } from './ProductReviewSummaryCreateManyBrandInputEnvelope.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryCreateNestedManyWithoutBrandInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewSummaryCreateWithoutBrandInputObjectSchema),
          z
            .lazy(() => ProductReviewSummaryCreateWithoutBrandInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductReviewSummaryCreateOrConnectWithoutBrandInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewSummaryCreateOrConnectWithoutBrandInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => ProductReviewSummaryCreateManyBrandInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewSummaryCreateNestedManyWithoutBrandInputObjectSchema =
  Schema
