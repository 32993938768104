import { z } from 'zod'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultUpdateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUpdateWithoutMissedCallCaseInput.schema'
import { TeleconsultUncheckedUpdateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUncheckedUpdateWithoutMissedCallCaseInput.schema'
import { TeleconsultCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultCreateWithoutMissedCallCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutMissedCallCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInput> =
  z
    .object({
      where: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => TeleconsultUpdateWithoutMissedCallCaseInputObjectSchema),
        z.lazy(
          () =>
            TeleconsultUncheckedUpdateWithoutMissedCallCaseInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => TeleconsultCreateWithoutMissedCallCaseInputObjectSchema),
        z.lazy(
          () =>
            TeleconsultUncheckedCreateWithoutMissedCallCaseInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const TeleconsultUpsertWithWhereUniqueWithoutMissedCallCaseInputObjectSchema =
  Schema
