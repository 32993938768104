import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { DiagnosisUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema } from './DiagnosisUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInput.schema'
import { CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema } from './CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    diagnosis: z
      .lazy(
        () =>
          DiagnosisUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema,
      )
      .optional(),
    caseSummary: z
      .lazy(
        () =>
          CaseSummaryUpdateOneRequiredWithoutCaseSummaryDiagnosisNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryDiagnosisUpdateInputObjectSchema = Schema
