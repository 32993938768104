import * as z from "zod"
import { CompleteBranch, RelatedBranchModel } from "./index"

export const OfficeHourModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  weekday: z.enum(['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']),
  openAt: z.string(),
  closeAt: z.string(),
  timezone: z.string(),
  branchId: z.number().int(),
})

export interface CompleteOfficeHour extends z.infer<typeof OfficeHourModel> {
  branch: CompleteBranch
}

/**
 * RelatedOfficeHourModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedOfficeHourModel: z.ZodSchema<CompleteOfficeHour> = z.lazy(() => OfficeHourModel.extend({
  branch: RelatedBranchModel,
}))
