import { z } from 'zod'
import { AddressTagSchema } from '../enums/AddressTag.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NullableEnumAddressTagFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => AddressTagSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumAddressTagFieldUpdateOperationsInputObjectSchema =
  Schema
