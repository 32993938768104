import { z } from 'zod'
import { ProductItemOriginalCreateInputObjectSchema } from './objects/ProductItemOriginalCreateInput.schema'
import { ProductItemOriginalUncheckedCreateInputObjectSchema } from './objects/ProductItemOriginalUncheckedCreateInput.schema'

export const ProductItemOriginalCreateOneSchema = z.object({
  data: z.union([
    ProductItemOriginalCreateInputObjectSchema,
    ProductItemOriginalUncheckedCreateInputObjectSchema,
  ]),
})
