import * as z from "zod"
import { CompleteDiagnosis, RelatedDiagnosisModel, CompleteCaseSummary, RelatedCaseSummaryModel } from "./index"

export const CaseSummaryDiagnosisModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  diagnosisId: z.number().int(),
  caseSummaryId: z.number().int(),
})

export interface CompleteCaseSummaryDiagnosis extends z.infer<typeof CaseSummaryDiagnosisModel> {
  diagnosis: CompleteDiagnosis
  caseSummary: CompleteCaseSummary
}

/**
 * RelatedCaseSummaryDiagnosisModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCaseSummaryDiagnosisModel: z.ZodSchema<CompleteCaseSummaryDiagnosis> = z.lazy(() => CaseSummaryDiagnosisModel.extend({
  diagnosis: RelatedDiagnosisModel,
  caseSummary: RelatedCaseSummaryModel,
}))
