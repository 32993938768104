import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { NullableEnumSymptomDurationUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumSymptomDurationUnitFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { EnumCaseStatusFieldUpdateOperationsInputObjectSchema } from './EnumCaseStatusFieldUpdateOperationsInput.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { EnumConsultingChannelFieldUpdateOperationsInputObjectSchema } from './EnumConsultingChannelFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { CaseUncheckedUpdateOneWithoutFollowupCaseNestedInputObjectSchema } from './CaseUncheckedUpdateOneWithoutFollowupCaseNestedInput.schema'
import { CasePictureUncheckedUpdateManyWithoutCaseNestedInputObjectSchema } from './CasePictureUncheckedUpdateManyWithoutCaseNestedInput.schema'
import { AppointmentUncheckedUpdateOneWithoutCaseNestedInputObjectSchema } from './AppointmentUncheckedUpdateOneWithoutCaseNestedInput.schema'
import { TeleconsultUncheckedUpdateOneWithoutCaseNestedInputObjectSchema } from './TeleconsultUncheckedUpdateOneWithoutCaseNestedInput.schema'
import { CaseSummaryUncheckedUpdateOneWithoutCaseNestedInputObjectSchema } from './CaseSummaryUncheckedUpdateOneWithoutCaseNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUncheckedUpdateWithoutMissedCallTeleconsultInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      patientId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      doctorId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      symptom: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      drugAllergy: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      congenitalDisease: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      symptomDuration: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      symptomDurationUnit: z
        .union([
          z.lazy(() => SymptomDurationUnitSchema),
          z.lazy(
            () =>
              NullableEnumSymptomDurationUnitFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      followupCaseId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      status: z
        .union([
          z.lazy(() => CaseStatusSchema),
          z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      consultingChannel: z
        .union([
          z.lazy(() => ConsultingChannelSchema),
          z.lazy(
            () => EnumConsultingChannelFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2RecordId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      previousCase: z
        .lazy(
          () =>
            CaseUncheckedUpdateOneWithoutFollowupCaseNestedInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () =>
            CasePictureUncheckedUpdateManyWithoutCaseNestedInputObjectSchema,
        )
        .optional(),
      appointment: z
        .lazy(
          () => AppointmentUncheckedUpdateOneWithoutCaseNestedInputObjectSchema,
        )
        .optional(),
      teleconsult: z
        .lazy(
          () => TeleconsultUncheckedUpdateOneWithoutCaseNestedInputObjectSchema,
        )
        .optional(),
      caseSummary: z
        .lazy(
          () => CaseSummaryUncheckedUpdateOneWithoutCaseNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CaseUncheckedUpdateWithoutMissedCallTeleconsultInputObjectSchema =
  Schema
