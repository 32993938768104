import { z } from 'zod'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { NestedEnumSymptomDurationUnitNullableFilterObjectSchema } from './NestedEnumSymptomDurationUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumSymptomDurationUnitNullableFilter> = z
  .object({
    equals: z
      .lazy(() => SymptomDurationUnitSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => SymptomDurationUnitSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => SymptomDurationUnitSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => SymptomDurationUnitSchema),
        z.lazy(() => NestedEnumSymptomDurationUnitNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumSymptomDurationUnitNullableFilterObjectSchema = Schema
