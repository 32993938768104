import { z } from 'zod'
import { ProcedureCategoryScalarWhereInputObjectSchema } from './ProcedureCategoryScalarWhereInput.schema'
import { ProcedureCategoryUpdateManyMutationInputObjectSchema } from './ProcedureCategoryUpdateManyMutationInput.schema'
import { ProcedureCategoryUncheckedUpdateManyWithoutProceduresInputObjectSchema } from './ProcedureCategoryUncheckedUpdateManyWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateManyWithWhereWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureCategoryUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedUpdateManyWithoutProceduresInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema =
  Schema
