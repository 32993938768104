import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductReviewSummaryCountOrderByAggregateInputObjectSchema } from './ProductReviewSummaryCountOrderByAggregateInput.schema'
import { ProductReviewSummaryAvgOrderByAggregateInputObjectSchema } from './ProductReviewSummaryAvgOrderByAggregateInput.schema'
import { ProductReviewSummaryMaxOrderByAggregateInputObjectSchema } from './ProductReviewSummaryMaxOrderByAggregateInput.schema'
import { ProductReviewSummaryMinOrderByAggregateInputObjectSchema } from './ProductReviewSummaryMinOrderByAggregateInput.schema'
import { ProductReviewSummarySumOrderByAggregateInputObjectSchema } from './ProductReviewSummarySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      productId: z.lazy(() => SortOrderSchema).optional(),
      brandId: z.lazy(() => SortOrderSchema).optional(),
      overallRating: z.lazy(() => SortOrderSchema).optional(),
      qualityRating: z.lazy(() => SortOrderSchema).optional(),
      shippingRating: z.lazy(() => SortOrderSchema).optional(),
      packagingRating: z.lazy(() => SortOrderSchema).optional(),
      countForRating5: z.lazy(() => SortOrderSchema).optional(),
      countForRating4: z.lazy(() => SortOrderSchema).optional(),
      countForRating3: z.lazy(() => SortOrderSchema).optional(),
      countForRating2: z.lazy(() => SortOrderSchema).optional(),
      countForRating1: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => ProductReviewSummaryCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => ProductReviewSummaryAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ProductReviewSummaryMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ProductReviewSummaryMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ProductReviewSummarySumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProductReviewSummaryOrderByWithAggregationInputObjectSchema =
  Schema
