import { z } from 'zod'
import { CategorySelectObjectSchema } from './CategorySelect.schema'
import { CategoryIncludeObjectSchema } from './CategoryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryArgs> = z
  .object({
    select: z.lazy(() => CategorySelectObjectSchema).optional(),
    include: z.lazy(() => CategoryIncludeObjectSchema).optional(),
  })
  .strict()

export const CategoryArgsObjectSchema = Schema
