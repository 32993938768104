import { z } from 'zod'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummarySelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    procedureId: z.boolean().optional(),
    partnerId: z.boolean().optional(),
    overallRating: z.boolean().optional(),
    environmentRating: z.boolean().optional(),
    serviceRating: z.boolean().optional(),
    specialtyRating: z.boolean().optional(),
    valueRating: z.boolean().optional(),
    countForRating5: z.boolean().optional(),
    countForRating4: z.boolean().optional(),
    countForRating3: z.boolean().optional(),
    countForRating2: z.boolean().optional(),
    countForRating1: z.boolean().optional(),
  })
  .strict()

export const ProcedureReviewSummarySelectObjectSchema = Schema
