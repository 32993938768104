import { preprocessDate } from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'

export const MpayEventModel = z.object({
  order_id: z.string(),
  merchant_id: z.string(),
  txn_id: z.string(),
  txn_type: z.string(),
  status: z.enum(['FAIL', 'SUCCESS', 'AUTHORIZED']),
  status_code: z.string(),
  status_message: z.string(),
  amount: z.number(),
  amount_net: z.number(),
  amount_cust_fee: z.number().nullish(),
  currency: z.string(),
  redeem_amount: z.number().nullish(),
  redeem_point: z.number().nullish(),
  service_id: z.string(),
  channel_type: z.enum(['QR', 'WEBSITE', 'KIOSK', 'APPLICATION', 'COUNTER']),
  cust_id: z.string(),
  ref_1: z.string().nullish(),
  ref_2: z.string().nullish(),
  ref_3: z.string().nullish(),
  ref_4: z.string().nullish(),
  ref_5: z.string().nullish(),
  metadata: z.object({
    package: z
      .object({
        clinicId: z.number().nullish(),
        clinicName: z.string().nullish(),
        createdAt: z.string().nullish(),
        id: z.number().nullish(),
        isActive: z.boolean().nullish(),
        name: z.string().nullish(),
        price: z.number().nullish(),
        updatedAt: z.string().nullish(),
      })
      .nullish(),
    sourceChannel: z.enum(['SXEasy', 'SkinX']),
  }),
  capture: z.boolean().nullish(),
  card: z
    .object({
      card_holder_name: z.string().nullish(),
      card_no: z.string().nullish(),
      card_type: z.string().nullish(),
      card_expire: z.string().nullish(),
      card_ref: z.string().nullish(),
      card_bank_issuer_name: z.string().nullish(),
      card_first_name: z.string().nullish(),
      card_last_name: z.string().nullish(),
      card_email: z.string().nullish(),
      card_phone_number: z.string().nullish(),
      card_street1: z.string().nullish(),
      card_city: z.string().nullish(),
      card_state: z.string().nullish(),
      card_postal_code: z.string().nullish(),
      card_country: z.string().nullish(),
      approval_code: z.string().nullish(),
    })
    .nullish(),
  installment: z
    .object({
      bank_issuer: z.string().nullish(),
      term: z.number().nullish(),
      amount_per_term: z.number().nullish(),
    })
    .nullish(),
  bank: z
    .object({
      account_last_digits: z.string().nullish(),
      account_name: z.string().nullish(),
      bank_code: z.string().nullish(),
    })
    .nullish(),
  // loan: z.any(), // Bank Loan: not used
  // rlp: z.any(), // Rabbit Line Pay: not used
  rmb_flag: z.string().nullish(), // Y/N
  sof_txn_id: z.string().nullish(),
  created_at: z.string().nullish(),
  success_at: z.string().nullish(),
  fail_at: z.string().nullish(),
  sof_type: z.string().nullish(),
  settlement: z
    .object({
      is_auto_settlement: z.boolean(),
      auto_settlement_at: z.preprocess(preprocessDate, z.date()).nullish(),
      settlement_status: z.enum([
        'PENDING',
        'CONFIRMED',
        'PROCESSING',
        'SETTLED',
      ]),
      pending_at: z.preprocess(preprocessDate, z.date()).nullish(),
      confirmed_at: z.preprocess(preprocessDate, z.date()).nullish(),
    })
    .nullish(),
})
