import { z } from 'zod'
import { CarouselItemOrderByWithRelationInputObjectSchema } from './objects/CarouselItemOrderByWithRelationInput.schema'
import { CarouselItemWhereInputObjectSchema } from './objects/CarouselItemWhereInput.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './objects/CarouselItemWhereUniqueInput.schema'
import { CarouselItemScalarFieldEnumSchema } from './enums/CarouselItemScalarFieldEnum.schema'

export const CarouselItemFindFirstSchema = z.object({
  orderBy: z
    .union([
      CarouselItemOrderByWithRelationInputObjectSchema,
      CarouselItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CarouselItemWhereInputObjectSchema.optional(),
  cursor: CarouselItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CarouselItemScalarFieldEnumSchema).optional(),
})
