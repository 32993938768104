import { z } from 'zod'
import { ProductItemCreateWithoutDeliveryInputObjectSchema } from './ProductItemCreateWithoutDeliveryInput.schema'
import { ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateWithoutDeliveryInput.schema'
import { ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema } from './ProductItemCreateOrConnectWithoutDeliveryInput.schema'
import { ProductItemCreateManyDeliveryInputEnvelopeObjectSchema } from './ProductItemCreateManyDeliveryInputEnvelope.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemCreateNestedManyWithoutDeliveryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema),
          z
            .lazy(() => ProductItemCreateWithoutDeliveryInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemUncheckedCreateWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductItemCreateOrConnectWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemCreateManyDeliveryInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemCreateNestedManyWithoutDeliveryInputObjectSchema =
  Schema
