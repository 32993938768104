import { z } from 'zod'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundUpdateWithoutOrderInputObjectSchema } from './RefundUpdateWithoutOrderInput.schema'
import { RefundUncheckedUpdateWithoutOrderInputObjectSchema } from './RefundUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateWithWhereUniqueWithoutOrderInput> = z
  .object({
    where: z.lazy(() => RefundWhereUniqueInputObjectSchema),
    data: z.union([
      z.lazy(() => RefundUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => RefundUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const RefundUpdateWithWhereUniqueWithoutOrderInputObjectSchema = Schema
