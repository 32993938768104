import { z } from 'zod'
import { ProcedureCreateNestedOneWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateNestedOneWithoutCarouselItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateWithoutCarouselInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutCarouselItemsInputObjectSchema,
    ),
  })
  .strict()

export const CarouselItemCreateWithoutCarouselInputObjectSchema = Schema
