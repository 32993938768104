import { z } from 'zod'
import { CarouselUpdateWithoutItemsInputObjectSchema } from './CarouselUpdateWithoutItemsInput.schema'
import { CarouselUncheckedUpdateWithoutItemsInputObjectSchema } from './CarouselUncheckedUpdateWithoutItemsInput.schema'
import { CarouselCreateWithoutItemsInputObjectSchema } from './CarouselCreateWithoutItemsInput.schema'
import { CarouselUncheckedCreateWithoutItemsInputObjectSchema } from './CarouselUncheckedCreateWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselUpsertWithoutItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => CarouselUpdateWithoutItemsInputObjectSchema),
      z.lazy(() => CarouselUncheckedUpdateWithoutItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CarouselCreateWithoutItemsInputObjectSchema),
      z.lazy(() => CarouselUncheckedCreateWithoutItemsInputObjectSchema),
    ]),
  })
  .strict()

export const CarouselUpsertWithoutItemsInputObjectSchema = Schema
