import { z } from 'zod'
import { SuggestedProcedureItemOrderByWithRelationInputObjectSchema } from './objects/SuggestedProcedureItemOrderByWithRelationInput.schema'
import { SuggestedProcedureItemWhereInputObjectSchema } from './objects/SuggestedProcedureItemWhereInput.schema'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './objects/SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemScalarFieldEnumSchema } from './enums/SuggestedProcedureItemScalarFieldEnum.schema'

export const SuggestedProcedureItemFindManySchema = z.object({
  orderBy: z
    .union([
      SuggestedProcedureItemOrderByWithRelationInputObjectSchema,
      SuggestedProcedureItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProcedureItemWhereInputObjectSchema.optional(),
  cursor: SuggestedProcedureItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(SuggestedProcedureItemScalarFieldEnumSchema).optional(),
})
