import { z } from 'zod'

export const RefundScalarFieldEnumSchema = z.enum([
  'id',
  'amount',
  'currency',
  'refundAt',
  'paymentGateway',
  'status',
  'reason',
  'orderId',
])
