import { z } from 'zod'
import { PatientCreateWithoutMyReviewsInputObjectSchema } from './PatientCreateWithoutMyReviewsInput.schema'
import { PatientUncheckedCreateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedCreateWithoutMyReviewsInput.schema'
import { PatientCreateOrConnectWithoutMyReviewsInputObjectSchema } from './PatientCreateOrConnectWithoutMyReviewsInput.schema'
import { PatientUpsertWithoutMyReviewsInputObjectSchema } from './PatientUpsertWithoutMyReviewsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutMyReviewsInputObjectSchema } from './PatientUpdateWithoutMyReviewsInput.schema'
import { PatientUncheckedUpdateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedUpdateWithoutMyReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutMyReviewsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutMyReviewsInputObjectSchema),
          z.lazy(() => PatientUncheckedCreateWithoutMyReviewsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PatientCreateOrConnectWithoutMyReviewsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutMyReviewsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutMyReviewsInputObjectSchema),
          z.lazy(() => PatientUncheckedUpdateWithoutMyReviewsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutMyReviewsNestedInputObjectSchema =
  Schema
