import { z } from 'zod'
import { KycDataWhereUniqueInputObjectSchema } from './objects/KycDataWhereUniqueInput.schema'
import { KycDataCreateInputObjectSchema } from './objects/KycDataCreateInput.schema'
import { KycDataUncheckedCreateInputObjectSchema } from './objects/KycDataUncheckedCreateInput.schema'
import { KycDataUpdateInputObjectSchema } from './objects/KycDataUpdateInput.schema'
import { KycDataUncheckedUpdateInputObjectSchema } from './objects/KycDataUncheckedUpdateInput.schema'

export const KycDataUpsertSchema = z.object({
  where: KycDataWhereUniqueInputObjectSchema,
  create: z.union([
    KycDataCreateInputObjectSchema,
    KycDataUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    KycDataUpdateInputObjectSchema,
    KycDataUncheckedUpdateInputObjectSchema,
  ]),
})
