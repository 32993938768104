import { z } from 'zod'
import { ArticlePictureUpdateInputObjectSchema } from './objects/ArticlePictureUpdateInput.schema'
import { ArticlePictureUncheckedUpdateInputObjectSchema } from './objects/ArticlePictureUncheckedUpdateInput.schema'
import { ArticlePictureWhereUniqueInputObjectSchema } from './objects/ArticlePictureWhereUniqueInput.schema'

export const ArticlePictureUpdateOneSchema = z.object({
  data: z.union([
    ArticlePictureUpdateInputObjectSchema,
    ArticlePictureUncheckedUpdateInputObjectSchema,
  ]),
  where: ArticlePictureWhereUniqueInputObjectSchema,
})
