import { z } from 'zod'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { EnumPaymentGatewayFieldUpdateOperationsInputObjectSchema } from './EnumPaymentGatewayFieldUpdateOperationsInput.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { EnumRefundStatusFieldUpdateOperationsInputObjectSchema } from './EnumRefundStatusFieldUpdateOperationsInput.schema'
import { ProductItemUpdateManyWithoutRefundNestedInputObjectSchema } from './ProductItemUpdateManyWithoutRefundNestedInput.schema'
import { ProcedureItemUpdateManyWithoutRefundNestedInputObjectSchema } from './ProcedureItemUpdateManyWithoutRefundNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateWithoutOrderInput> = z
  .object({
    amount: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    currency: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    refundAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    paymentGateway: z
      .union([
        z.lazy(() => PaymentGatewaySchema),
        z.lazy(() => EnumPaymentGatewayFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => RefundStatusSchema),
        z.lazy(() => EnumRefundStatusFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    reason: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    productItems: z
      .lazy(() => ProductItemUpdateManyWithoutRefundNestedInputObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemUpdateManyWithoutRefundNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const RefundUpdateWithoutOrderInputObjectSchema = Schema
