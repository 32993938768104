import { z } from 'zod'
import { ProductUpdateWithoutProductReviewsInputObjectSchema } from './ProductUpdateWithoutProductReviewsInput.schema'
import { ProductUncheckedUpdateWithoutProductReviewsInputObjectSchema } from './ProductUncheckedUpdateWithoutProductReviewsInput.schema'
import { ProductCreateWithoutProductReviewsInputObjectSchema } from './ProductCreateWithoutProductReviewsInput.schema'
import { ProductUncheckedCreateWithoutProductReviewsInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutProductReviewsInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductUpdateWithoutProductReviewsInputObjectSchema),
      z.lazy(
        () => ProductUncheckedUpdateWithoutProductReviewsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProductCreateWithoutProductReviewsInputObjectSchema),
      z.lazy(
        () => ProductUncheckedCreateWithoutProductReviewsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProductUpsertWithoutProductReviewsInputObjectSchema = Schema
