import { z } from 'zod'
import { AddressWhereInputObjectSchema } from './objects/AddressWhereInput.schema'
import { AddressOrderByWithAggregationInputObjectSchema } from './objects/AddressOrderByWithAggregationInput.schema'
import { AddressScalarWhereWithAggregatesInputObjectSchema } from './objects/AddressScalarWhereWithAggregatesInput.schema'
import { AddressScalarFieldEnumSchema } from './enums/AddressScalarFieldEnum.schema'

export const AddressGroupBySchema = z.object({
  where: AddressWhereInputObjectSchema.optional(),
  orderBy: z.union([
    AddressOrderByWithAggregationInputObjectSchema,
    AddressOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: AddressScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(AddressScalarFieldEnumSchema),
})
