import { z } from 'zod'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.NestedEnumDiscountTypeFilter> = z
  .object({
    equals: z.lazy(() => DiscountTypeSchema).optional(),
    in: z
      .lazy(() => DiscountTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountTypeSchema),
        z.lazy(() => NestedEnumDiscountTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumDiscountTypeFilterObjectSchema = Schema
