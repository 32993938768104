import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateManyPatientInput> = z
  .object({
    notificationId: z.number(),
    readAt: z.date().optional().nullable(),
  })
  .strict()

export const NotificationPatientCreateManyPatientInputObjectSchema = Schema
