import { z } from 'zod'
import { ProcedureOrderWhereInputObjectSchema } from './objects/ProcedureOrderWhereInput.schema'
import { ProcedureOrderOrderByWithAggregationInputObjectSchema } from './objects/ProcedureOrderOrderByWithAggregationInput.schema'
import { ProcedureOrderScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureOrderScalarWhereWithAggregatesInput.schema'
import { ProcedureOrderScalarFieldEnumSchema } from './enums/ProcedureOrderScalarFieldEnum.schema'

export const ProcedureOrderGroupBySchema = z.object({
  where: ProcedureOrderWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureOrderOrderByWithAggregationInputObjectSchema,
    ProcedureOrderOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedureOrderScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureOrderScalarFieldEnumSchema),
})
