import { z } from 'zod'
import { PatientUpdateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUpdateWithoutHealthCheckUpInfoInput.schema'
import { PatientUncheckedUpdateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUncheckedUpdateWithoutHealthCheckUpInfoInput.schema'
import { PatientCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientCreateWithoutHealthCheckUpInfoInput.schema'
import { PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUncheckedCreateWithoutHealthCheckUpInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutHealthCheckUpInfoInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutHealthCheckUpInfoInputObjectSchema),
      z.lazy(
        () => PatientUncheckedUpdateWithoutHealthCheckUpInfoInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutHealthCheckUpInfoInputObjectSchema),
      z.lazy(
        () => PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const PatientUpsertWithoutHealthCheckUpInfoInputObjectSchema = Schema
