import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { ProductRelationFilterObjectSchema } from './ProductRelationFilter.schema'
import { ProductWhereInputObjectSchema } from './ProductWhereInput.schema'
import { BrandRelationFilterObjectSchema } from './BrandRelationFilter.schema'
import { BrandWhereInputObjectSchema } from './BrandWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductReviewSummaryWhereInputObjectSchema),
        z.lazy(() => ProductReviewSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductReviewSummaryWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductReviewSummaryWhereInputObjectSchema),
        z.lazy(() => ProductReviewSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    productId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    brandId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    overallRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    qualityRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    shippingRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    packagingRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    countForRating5: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating4: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating3: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating2: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating1: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    product: z
      .union([
        z.lazy(() => ProductRelationFilterObjectSchema),
        z.lazy(() => ProductWhereInputObjectSchema),
      ])
      .optional(),
    brand: z
      .union([
        z.lazy(() => BrandRelationFilterObjectSchema),
        z.lazy(() => BrandWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductReviewSummaryWhereInputObjectSchema = Schema
