import { Box, Grid, Typography } from '@mui/material'
import {
  BooleanInput,
  TextField,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomNumberInput } from '../../../components/CustomInput'
import { FormDivider } from '../../../components/FormInput/divider'
import { adminGatewayClient, isTRPCClientError } from '../../../service'

export const ShippingProviderInfo = () => {
  const record = useRecordContext()
  const notify = useNotify()

  const { setValue } = useFormContext()

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const active = e.target.checked
    try {
      await adminGatewayClient.shippingProvider.setActive.mutate({
        id: Number(id),
        active,
      })
      notify(`${record.name} is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', !active)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  console.log(record)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Shipping provider: {record?.name || ''}
        </Typography>
        {record?.id && (
          <Box display={'flex'}>
            <BooleanInput
              source="active"
              onChange={(e) => handleActiveChanged(record.id, e)}
            />
          </Box>
        )}
      </Box>
      <FormDivider />
      <Grid container columnSpacing={6} rowSpacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">
            <b>Detail</b>
          </Typography>
          <TextField source="detail" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ pt: 1 }}>
            <CustomNumberInput source="priority" />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">
            <b>More detail</b>
          </Typography>
          {record?.moreDetail ? (
            <div
              style={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{ __html: record.moreDetail }}
            />
          ) : (
            <div>-</div>
          )}
        </Grid>
      </Grid>
    </>
  )
}
