import { z } from 'zod'
import { ProductVideoSelectObjectSchema } from './ProductVideoSelect.schema'
import { ProductVideoIncludeObjectSchema } from './ProductVideoInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoArgs> = z
  .object({
    select: z.lazy(() => ProductVideoSelectObjectSchema).optional(),
    include: z.lazy(() => ProductVideoIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductVideoArgsObjectSchema = Schema
