import { z } from 'zod'
import { ProductPictureWhereUniqueInputObjectSchema } from './ProductPictureWhereUniqueInput.schema'
import { ProductPictureUpdateWithoutProductInputObjectSchema } from './ProductPictureUpdateWithoutProductInput.schema'
import { ProductPictureUncheckedUpdateWithoutProductInputObjectSchema } from './ProductPictureUncheckedUpdateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureUpdateWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductPictureUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductPictureUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductPictureUpdateWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
