import { z } from 'zod'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalCreateInputObjectSchema } from './objects/SuggestedProductItemOriginalCreateInput.schema'
import { SuggestedProductItemOriginalUncheckedCreateInputObjectSchema } from './objects/SuggestedProductItemOriginalUncheckedCreateInput.schema'
import { SuggestedProductItemOriginalUpdateInputObjectSchema } from './objects/SuggestedProductItemOriginalUpdateInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateInputObjectSchema } from './objects/SuggestedProductItemOriginalUncheckedUpdateInput.schema'

export const SuggestedProductItemOriginalUpsertSchema = z.object({
  where: SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
  create: z.union([
    SuggestedProductItemOriginalCreateInputObjectSchema,
    SuggestedProductItemOriginalUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    SuggestedProductItemOriginalUpdateInputObjectSchema,
    SuggestedProductItemOriginalUncheckedUpdateInputObjectSchema,
  ]),
})
