import { z } from 'zod'
import { AcceptedConsentUpdateInputObjectSchema } from './objects/AcceptedConsentUpdateInput.schema'
import { AcceptedConsentUncheckedUpdateInputObjectSchema } from './objects/AcceptedConsentUncheckedUpdateInput.schema'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './objects/AcceptedConsentWhereUniqueInput.schema'

export const AcceptedConsentUpdateOneSchema = z.object({
  data: z.union([
    AcceptedConsentUpdateInputObjectSchema,
    AcceptedConsentUncheckedUpdateInputObjectSchema,
  ]),
  where: AcceptedConsentWhereUniqueInputObjectSchema,
})
