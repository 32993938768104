import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  useEditContext,
  useRedirect,
} from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import CancelForm from './cancelForm'
import { AppointmentDetail } from './create-edit-section/appointmentDetail'
import { CaseDetail } from './create-edit-section/caseDetail'
import { ScheduleDetail } from './create-edit-section/scheduleDetail'
import { transformAppointment } from './transform'

const AppointmentEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctorKycData" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

interface AppointmentEditFormProps {
  onClickCancelAppointment: () => void
}

const AppointmentEditForm = ({
  onClickCancelAppointment,
}: AppointmentEditFormProps) => {
  const redirect = useRedirect()
  const { record, isLoading } = useEditContext()

  if (isLoading) {
    return null
  } else if (!record) {
    redirect('list', 'doctorScheduler')
    return null
  } else if (!record.scheduleId) {
    // Change this part if need to show cancelled appointment
    redirect('list', 'doctorScheduler')
    return null
  }

  const handleClickCancel = () => {
    onClickCancelAppointment()
  }

  return (
    <SimpleForm
      toolbar={<AppointmentEditToolbar />}
      defaultValues={{
        scheduleId: record.schedule.id,
        appointmentAt: record.schedule.availableAt,
        doctorId: record.schedule.doctorId,
        schedule: record.schedule,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
          Edit appointment
        </Typography>
        <Button
          startIcon={<CancelIcon fontSize="large" />}
          variant="contained"
          color="error"
          onClick={() => handleClickCancel()}
          sx={{ height: '3rem' }}
          disabled={record.status !== 'IN_PROGRESS'}
        >
          Cancel Appointment
        </Button>
      </Box>
      <ScheduleDetail />
      <AppointmentDetail mode="EDIT" />
      <CaseDetail />
    </SimpleForm>
  )
}

export const AppointmentEdit = () => {
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const redirect = useRedirect()

  const handleSetOpenCancelModal = (value: boolean) => {
    setOpenCancelModal(value)
  }

  const mutationOption = {
    onSuccess: () => {
      redirect('list', 'doctorScheduler')
    },
  }

  return (
    <EditSaveRouteBack
      transform={transformAppointment}
      mutationOptions={mutationOption}
      mutationMode="pessimistic"
    >
      <Card>
        <CancelForm
          openModal={openCancelModal}
          setOpenModal={handleSetOpenCancelModal}
        />
        <CardContent sx={{ p: 2 }}>
          <AppointmentEditForm
            onClickCancelAppointment={() => {
              handleSetOpenCancelModal(true)
            }}
          />
        </CardContent>
      </Card>
    </EditSaveRouteBack>
  )
}
