import { z } from 'zod'
import { DoctorItemOrderByWithRelationInputObjectSchema } from './objects/DoctorItemOrderByWithRelationInput.schema'
import { DoctorItemWhereInputObjectSchema } from './objects/DoctorItemWhereInput.schema'
import { DoctorItemWhereUniqueInputObjectSchema } from './objects/DoctorItemWhereUniqueInput.schema'
import { DoctorItemCountAggregateInputObjectSchema } from './objects/DoctorItemCountAggregateInput.schema'
import { DoctorItemMinAggregateInputObjectSchema } from './objects/DoctorItemMinAggregateInput.schema'
import { DoctorItemMaxAggregateInputObjectSchema } from './objects/DoctorItemMaxAggregateInput.schema'
import { DoctorItemAvgAggregateInputObjectSchema } from './objects/DoctorItemAvgAggregateInput.schema'
import { DoctorItemSumAggregateInputObjectSchema } from './objects/DoctorItemSumAggregateInput.schema'

export const DoctorItemAggregateSchema = z.object({
  orderBy: z
    .union([
      DoctorItemOrderByWithRelationInputObjectSchema,
      DoctorItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DoctorItemWhereInputObjectSchema.optional(),
  cursor: DoctorItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), DoctorItemCountAggregateInputObjectSchema])
    .optional(),
  _min: DoctorItemMinAggregateInputObjectSchema.optional(),
  _max: DoctorItemMaxAggregateInputObjectSchema.optional(),
  _avg: DoctorItemAvgAggregateInputObjectSchema.optional(),
  _sum: DoctorItemSumAggregateInputObjectSchema.optional(),
})
