import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteArticlePicture, RelatedArticlePictureModel } from "./index"

export const ArticleModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  coverPicture: z.string().nullish(),
  titleTr: z.object({ en: z.string(), th: z.string() }),
  detailTr: z.object({ en: z.string(), th: z.string() }),
})

export const ArticleModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  coverPicture: z.string().nullish(),
  titleTr: jsonSchema,
  detailTr: jsonSchema,
})

export interface CompleteArticle extends z.infer<typeof ArticleModel> {
  pictures: CompleteArticlePicture[]
}

/**
 * RelatedArticleModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedArticleModel: z.ZodSchema<CompleteArticle> = z.lazy(() => ArticleModel.extend({
  pictures: RelatedArticlePictureModel.array(),
}))
