import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumShippingTypeFilterObjectSchema } from './EnumShippingTypeFilter.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { ExpressShippingAreaListRelationFilterObjectSchema } from './ExpressShippingAreaListRelationFilter.schema'
import { OrderListRelationFilterObjectSchema } from './OrderListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ShippingProviderWhereInputObjectSchema),
        z.lazy(() => ShippingProviderWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ShippingProviderWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ShippingProviderWhereInputObjectSchema),
        z.lazy(() => ShippingProviderWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    name: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    detail: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    moreDetail: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    shippingExpectation: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    trackingUrl: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    type: z
      .union([
        z.lazy(() => EnumShippingTypeFilterObjectSchema),
        z.lazy(() => ShippingTypeSchema),
      ])
      .optional(),
    price: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    freeShippingMinimumPrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    areas: z
      .lazy(() => ExpressShippingAreaListRelationFilterObjectSchema)
      .optional(),
    orders: z.lazy(() => OrderListRelationFilterObjectSchema).optional(),
  })
  .strict()

export const ShippingProviderWhereInputObjectSchema = Schema
