import { z } from 'zod'
import { DoctorItemWhereUniqueInputObjectSchema } from './objects/DoctorItemWhereUniqueInput.schema'
import { DoctorItemCreateInputObjectSchema } from './objects/DoctorItemCreateInput.schema'
import { DoctorItemUncheckedCreateInputObjectSchema } from './objects/DoctorItemUncheckedCreateInput.schema'
import { DoctorItemUpdateInputObjectSchema } from './objects/DoctorItemUpdateInput.schema'
import { DoctorItemUncheckedUpdateInputObjectSchema } from './objects/DoctorItemUncheckedUpdateInput.schema'

export const DoctorItemUpsertSchema = z.object({
  where: DoctorItemWhereUniqueInputObjectSchema,
  create: z.union([
    DoctorItemCreateInputObjectSchema,
    DoctorItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    DoctorItemUpdateInputObjectSchema,
    DoctorItemUncheckedUpdateInputObjectSchema,
  ]),
})
