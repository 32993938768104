import { z } from 'zod'
import { ProcedureCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewsInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewsInput.schema'
import { ProcedureCreateOrConnectWithoutProcedureReviewsInputObjectSchema } from './ProcedureCreateOrConnectWithoutProcedureReviewsInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateNestedOneWithoutProcedureReviewsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutProcedureReviewsInputObjectSchema),
          z.lazy(
            () =>
              ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProcedureCreateOrConnectWithoutProcedureReviewsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProcedureCreateNestedOneWithoutProcedureReviewsInputObjectSchema =
  Schema
