import { preprocessDate } from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'

export const UserStatusModel = z.object({
  conversationId: z.string(),
  id: z.number(),
  online: z.boolean(),
})

export const ConversationModel = z.object({
  id: z.string(),
  doctorTyping: z.preprocess(preprocessDate, z.date().optional()),
  doctorReadOn: z.preprocess(preprocessDate, z.date().optional()),
  doctor: UserStatusModel,
  doctorConnectedOn: z.preprocess(preprocessDate, z.date().optional()),
  patientTyping: z.preprocess(preprocessDate, z.date().optional()),
  patientReadOn: z.preprocess(preprocessDate, z.date().optional()),
  patient: UserStatusModel,
  patientConnectedOn: z.preprocess(preprocessDate, z.date().optional()),
  createdOn: z.preprocess(preprocessDate, z.date()), // createdOn is convention for createdAt
  updatedOn: z.preprocess(preprocessDate, z.date()), // updatedOn is convention for updatedAt
})
export type Conversation = z.infer<typeof ConversationModel>

export const UserTypeModel = z.enum(['DOCTOR', 'PATIENT', 'SYSTEM'])
export const UserType = UserTypeModel.enum

const MessageBaseModel = z.object({
  id: z.string(),
  senderId: z.number(),
  senderType: UserTypeModel,
  text: z.string().optional(),
  createdOn: z.preprocess(preprocessDate, z.date()),
})

const EventActorModel = z.object({
  actorId: z.number(),
  actorType: UserTypeModel,
  actorName: z.string(),
})

const MessageEventModel = MessageBaseModel.extend({
  type: z.literal('EVENT'),
  eventType: z.enum(['JOIN', 'LEAVE', 'END']),
  eventActor: EventActorModel.optional(),
})

const MessageTextModel = MessageBaseModel.extend({
  type: z.literal('TEXT'),
  text: z.string(),
})

const MessageImageModel = MessageBaseModel.extend({
  type: z.literal('IMAGE'),
  images: z.array(
    z.object({
      url: z.string(),
      thubmnail: z.string(),
      gsPath: z.string(),
      name: z.string(),
      width: z.number(),
      height: z.number(),
    }),
  ),
})

const MessageVideoModel = MessageBaseModel.extend({
  type: z.literal('VIDEO'),
  videos: z.array(
    z.object({
      url: z.string(),
      thubmnail: z.string(),
      gsPath: z.string(),
      name: z.string(),
      width: z.number(),
      height: z.number(),
      durationSec: z.number(),
    }),
  ),
})

const MessageAudioModel = MessageBaseModel.extend({
  type: z.literal('AUDIO'),
  audios: z.array(
    z.object({
      url: z.string(),
      gsPath: z.string(),
      name: z.string(),
      durationSec: z.number(),
    }),
  ),
})

const MessageFileModel = MessageBaseModel.extend({
  type: z.literal('FILE'),
  files: z.array(
    z.object({
      url: z.string(),
      gsPath: z.string(),
      name: z.string(),
      fileType: z.string().optional(),
      fileSize: z.number().optional(),
    }),
  ),
})

export const MessageModel = z.union([
  MessageEventModel,
  MessageTextModel,
  MessageImageModel,
  MessageVideoModel,
  MessageAudioModel,
  MessageFileModel,
])

export type Message = z.infer<typeof MessageModel>

const CreateMessageBaseModel = z.object({
  senderId: z.number(),
  text: z.string().optional(),
})

const CreateMessageEventModel = CreateMessageBaseModel.extend({
  type: z.literal('EVENT'),
  eventType: z.enum(['JOIN', 'LEAVE', 'END']),
  eventActor: EventActorModel.optional(),
})

const CreateMessageTextModel = CreateMessageBaseModel.extend({
  type: z.literal('TEXT'),
  text: z.string(),
})

const CreateMessageImageModel = CreateMessageBaseModel.extend({
  type: z.literal('IMAGE'),
  images: z.array(
    z.object({
      gsPath: z.string(),
      name: z.string(),
      width: z.number(),
      height: z.number(),
    }),
  ),
})

const CreateMessageVideoModel = CreateMessageBaseModel.extend({
  type: z.literal('VIDEO'),
  videos: z.array(
    z.object({
      gsPath: z.string(),
      thumbnailGsPath: z.string(),
      name: z.string(),
      width: z.number(),
      height: z.number(),
      durationSec: z.number(),
    }),
  ),
})

const CreateMessageAudioModel = CreateMessageBaseModel.extend({
  type: z.literal('AUDIO'),
  audios: z.array(
    z.object({
      gsPath: z.string(),
      name: z.string(),
      durationSec: z.number(),
    }),
  ),
})

const CreateMessageFileModel = CreateMessageBaseModel.extend({
  type: z.literal('FILE'),
  files: z.array(
    z.object({
      gsPath: z.string(),
      name: z.string(),
      fileType: z.string().optional(),
      fileSize: z.number().optional(),
    }),
  ),
})

export const CreateMessageModel = z.union([
  CreateMessageEventModel,
  CreateMessageTextModel,
  CreateMessageImageModel,
  CreateMessageVideoModel,
  CreateMessageAudioModel,
  CreateMessageFileModel,
])
