import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { TeleconsultCountOrderByAggregateInputObjectSchema } from './TeleconsultCountOrderByAggregateInput.schema'
import { TeleconsultAvgOrderByAggregateInputObjectSchema } from './TeleconsultAvgOrderByAggregateInput.schema'
import { TeleconsultMaxOrderByAggregateInputObjectSchema } from './TeleconsultMaxOrderByAggregateInput.schema'
import { TeleconsultMinOrderByAggregateInputObjectSchema } from './TeleconsultMinOrderByAggregateInput.schema'
import { TeleconsultSumOrderByAggregateInputObjectSchema } from './TeleconsultSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    videoRoomId: z.lazy(() => SortOrderSchema).optional(),
    doctorVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    patientVideoRoomUid: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    callTime: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    missedCallCaseId: z.lazy(() => SortOrderSchema).optional(),
    remark: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => TeleconsultCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => TeleconsultAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => TeleconsultMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => TeleconsultMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => TeleconsultSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const TeleconsultOrderByWithAggregationInputObjectSchema = Schema
