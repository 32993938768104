import { z } from 'zod'
import { DiscountSettingOrderByWithRelationInputObjectSchema } from './objects/DiscountSettingOrderByWithRelationInput.schema'
import { DiscountSettingWhereInputObjectSchema } from './objects/DiscountSettingWhereInput.schema'
import { DiscountSettingWhereUniqueInputObjectSchema } from './objects/DiscountSettingWhereUniqueInput.schema'
import { DiscountSettingCountAggregateInputObjectSchema } from './objects/DiscountSettingCountAggregateInput.schema'
import { DiscountSettingMinAggregateInputObjectSchema } from './objects/DiscountSettingMinAggregateInput.schema'
import { DiscountSettingMaxAggregateInputObjectSchema } from './objects/DiscountSettingMaxAggregateInput.schema'
import { DiscountSettingAvgAggregateInputObjectSchema } from './objects/DiscountSettingAvgAggregateInput.schema'
import { DiscountSettingSumAggregateInputObjectSchema } from './objects/DiscountSettingSumAggregateInput.schema'

export const DiscountSettingAggregateSchema = z.object({
  orderBy: z
    .union([
      DiscountSettingOrderByWithRelationInputObjectSchema,
      DiscountSettingOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DiscountSettingWhereInputObjectSchema.optional(),
  cursor: DiscountSettingWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), DiscountSettingCountAggregateInputObjectSchema])
    .optional(),
  _min: DiscountSettingMinAggregateInputObjectSchema.optional(),
  _max: DiscountSettingMaxAggregateInputObjectSchema.optional(),
  _avg: DiscountSettingAvgAggregateInputObjectSchema.optional(),
  _sum: DiscountSettingSumAggregateInputObjectSchema.optional(),
})
