import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { NotificationListRelationFilterObjectSchema } from './NotificationListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => SegmentWhereInputObjectSchema),
        z.lazy(() => SegmentWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => SegmentWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => SegmentWhereInputObjectSchema),
        z.lazy(() => SegmentWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    segmentId: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    notifications: z
      .lazy(() => NotificationListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const SegmentWhereInputObjectSchema = Schema
