import { z } from 'zod'
import { SkuSelectObjectSchema } from './SkuSelect.schema'
import { SkuIncludeObjectSchema } from './SkuInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.SkuArgs> = z
  .object({
    select: z.lazy(() => SkuSelectObjectSchema).optional(),
    include: z.lazy(() => SkuIncludeObjectSchema).optional(),
  })
  .strict()

export const SkuArgsObjectSchema = Schema
