import { z } from 'zod'
import { ProductCategoryWhereInputObjectSchema } from './ProductCategoryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryListRelationFilter> = z
  .object({
    every: z.lazy(() => ProductCategoryWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProductCategoryWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProductCategoryWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductCategoryListRelationFilterObjectSchema = Schema
