import { z } from 'zod'
import { ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaCreateWithoutShippingProviderInput.schema'
import { ExpressShippingAreaUncheckedCreateWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUncheckedCreateWithoutShippingProviderInput.schema'
import { ExpressShippingAreaCreateOrConnectWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaCreateOrConnectWithoutShippingProviderInput.schema'
import { ExpressShippingAreaUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUpsertWithWhereUniqueWithoutShippingProviderInput.schema'
import { ExpressShippingAreaCreateManyShippingProviderInputEnvelopeObjectSchema } from './ExpressShippingAreaCreateManyShippingProviderInputEnvelope.schema'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInput.schema'
import { ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInputObjectSchema } from './ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInput.schema'
import { ExpressShippingAreaScalarWhereInputObjectSchema } from './ExpressShippingAreaScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaUpdateManyWithoutShippingProviderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ExpressShippingAreaUncheckedCreateWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaUncheckedCreateWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ExpressShippingAreaCreateOrConnectWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaCreateOrConnectWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ExpressShippingAreaUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaUpsertWithWhereUniqueWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            ExpressShippingAreaCreateManyShippingProviderInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema),
          z.lazy(() => ExpressShippingAreaWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ExpressShippingAreaUpdateManyWithWhereWithoutShippingProviderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ExpressShippingAreaScalarWhereInputObjectSchema),
          z.lazy(() => ExpressShippingAreaScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ExpressShippingAreaUpdateManyWithoutShippingProviderNestedInputObjectSchema =
  Schema
