import { Box, Button } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useEffect, useState } from 'react'
import { useListContext, useTranslate } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CustomTextInput } from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()
  const translate = useTranslate()

  const [phoneNumber, setPhoneNumber] = useState<string>()
  const form = useForm({
    defaultValues: {
      nameOrPid: '',
      phoneNumber: '',
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.nameOrPid
    delete newFilter.phoneNumber

    if (values.nameOrPid) {
      const pid = parseInt(values.nameOrPid as string)
      if (!isNaN(pid)) {
        newFilter.id = pid
      } else {
        newFilter.OR = [
          { firstname: values.nameOrPid },
          { lastname: values.nameOrPid },
        ]
      }
    }
    if (values.phoneNumber) {
      const phoneNumber = (values.phoneNumber as string).replace(/\s/g, '')
      newFilter.identities = {
        some: { identityId: { contains: phoneNumber } },
      }
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }
  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    setPhoneNumber('')
    navigate('')
  }
  const handlePhoneNumberChange = (number: string) => {
    setPhoneNumber(number)
    form.setValue('phoneNumber', number)
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          OR: {
            formKey: 'nameOrPid',
            valuePathForForm: '[0].firstname',
          },
          id: {
            formKey: 'nameOrPid',
          },
          identities: {
            formKey: 'phoneNumber',
            valuePathForForm: 'some.identityId.contains',
            setStateField: setPhoneNumber,
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="nameOrPid"
          />
          <MuiTelInput
            label={translate('resources.patient.fields.phoneNumber')}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            margin="none"
            defaultCountry="TH"
          />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <Button variant="contained" type="submit">
              {translate('ra.action.search')}
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              {translate('ra.action.reset')}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
