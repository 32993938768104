import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryUpdateWithoutCategoryInputObjectSchema } from './ProductCategoryUpdateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedUpdateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedUpdateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUpdateWithWhereUniqueWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductCategoryUpdateWithoutCategoryInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedUpdateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryUpdateWithWhereUniqueWithoutCategoryInputObjectSchema =
  Schema
