import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    description: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const FlashsaleMinOrderByAggregateInputObjectSchema = Schema
