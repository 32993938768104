import { z } from 'zod'
import { CoverPictureOrderByWithRelationInputObjectSchema } from './objects/CoverPictureOrderByWithRelationInput.schema'
import { CoverPictureWhereInputObjectSchema } from './objects/CoverPictureWhereInput.schema'
import { CoverPictureWhereUniqueInputObjectSchema } from './objects/CoverPictureWhereUniqueInput.schema'
import { CoverPictureScalarFieldEnumSchema } from './enums/CoverPictureScalarFieldEnum.schema'

export const CoverPictureFindFirstSchema = z.object({
  orderBy: z
    .union([
      CoverPictureOrderByWithRelationInputObjectSchema,
      CoverPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CoverPictureWhereInputObjectSchema.optional(),
  cursor: CoverPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CoverPictureScalarFieldEnumSchema).optional(),
})
