import { z } from 'zod'
import { NotificationTypeSchema } from '../enums/NotificationType.schema'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'
import { NotificationPatientCreateNestedManyWithoutNotificationInputObjectSchema } from './NotificationPatientCreateNestedManyWithoutNotificationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationCreateWithoutSegmentsInput> = z
  .object({
    createdAt: z.date().optional(),
    type: z.lazy(() => NotificationTypeSchema),
    picture: z.string().optional().nullable(),
    title: z.string(),
    message: z.string(),
    actionType: z.lazy(() => NotificationActionTypeSchema).optional(),
    action: z.string().optional().nullable(),
    onesignalMessageId: z.string().optional().nullable(),
    startAt: z.date().optional(),
    appointmentId: z.number().optional().nullable(),
    appointmentEvent: z
      .lazy(() => AppointmentEventSchema)
      .optional()
      .nullable(),
    patients: z
      .lazy(
        () =>
          NotificationPatientCreateNestedManyWithoutNotificationInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const NotificationCreateWithoutSegmentsInputObjectSchema = Schema
