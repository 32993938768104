import { FileValue } from '../../components/types'

export const transformAdmin = async (form: {
  picture: FileValue
  password: string
  confirmPassword: string
}) => {
  const { picture, password } = form
  const result: { [key: string]: unknown } = form
  if (password) {
    result.password = password
  }
  if (picture?.uploadPromise) {
    const url = await picture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.picture = {
        ...picture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  return result
}
