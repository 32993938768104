import { z } from 'zod'
import { CaseCreateWithoutFollowupCaseInputObjectSchema } from './CaseCreateWithoutFollowupCaseInput.schema'
import { CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedCreateWithoutFollowupCaseInput.schema'
import { CaseCreateOrConnectWithoutFollowupCaseInputObjectSchema } from './CaseCreateOrConnectWithoutFollowupCaseInput.schema'
import { CaseUpsertWithoutFollowupCaseInputObjectSchema } from './CaseUpsertWithoutFollowupCaseInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseUpdateWithoutFollowupCaseInputObjectSchema } from './CaseUpdateWithoutFollowupCaseInput.schema'
import { CaseUncheckedUpdateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedUpdateWithoutFollowupCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUncheckedUpdateOneWithoutFollowupCaseNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CaseCreateWithoutFollowupCaseInputObjectSchema),
          z.lazy(() => CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CaseCreateOrConnectWithoutFollowupCaseInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CaseUpsertWithoutFollowupCaseInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CaseUpdateWithoutFollowupCaseInputObjectSchema),
          z.lazy(() => CaseUncheckedUpdateWithoutFollowupCaseInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const CaseUncheckedUpdateOneWithoutFollowupCaseNestedInputObjectSchema =
  Schema
