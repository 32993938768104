import { Box } from '@mui/material'
import { FC } from 'react'
import { RadioButtonGroupInput, Validator } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CustomTextInput } from '../../../components/CustomInput'

export interface FormTextInputProps {
  source: string
  label: string
  validate?: Validator | Validator[]
}

export const FormTextInput: FC<FormTextInputProps> = ({
  source,
  label,
  validate,
}) => {
  const { getValues } = useFormContext()

  const isValid = (fieldKey: string): boolean => {
    let isValid = true
    const { ...data } = getValues()
    if (data?.validator) {
      isValid = data?.validator[fieldKey] !== '0'
    }

    return isValid
  }
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <CustomTextInput
            fullWidth
            source={source}
            label={label}
            validate={validate}
          />
        </Box>
        {!isValid(source) && (
          <CustomTextInput
            sx={{
              '& label, input': { color: 'red' },
              '& fieldset': {
                borderColor: 'red !important',
              },
            }}
            fullWidth
            source={`errorReason.${source}`}
            label={`สาเหตุ`}
            placeholder={`สาเหตุที่ข้อมูล ${label} ผิด`}
            color="error"
          />
        )}
      </Box>
      <Box>
        <RadioButtonGroupInput
          disabled={getValues()?.status === 'APPROVED'}
          label={false}
          sx={{ mt: -0.1 }}
          source={`validator.${source}`}
          defaultValue="1"
          choices={[
            { id: '1', name: 'ถูกต้อง' },
            { id: '0', name: 'ไม่ถูกต้อง' },
          ]}
        />
      </Box>
    </Box>
  )
}
