import { z } from 'zod'
import { ConsentWhereUniqueInputObjectSchema } from './ConsentWhereUniqueInput.schema'
import { ConsentCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedCreateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCreateOrConnectWithoutAcceptedConsentsInput> =
  z
    .object({
      where: z.lazy(() => ConsentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ConsentCreateWithoutAcceptedConsentsInputObjectSchema),
        z.lazy(
          () => ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ConsentCreateOrConnectWithoutAcceptedConsentsInputObjectSchema =
  Schema
