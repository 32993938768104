import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryProductIdCategoryIdCompoundUniqueInput> =
  z
    .object({
      productId: z.number(),
      categoryId: z.number(),
    })
    .strict()

export const ProductCategoryProductIdCategoryIdCompoundUniqueInputObjectSchema =
  Schema
