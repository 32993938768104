import { z } from 'zod'
import { VariantUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema } from './VariantUpdateOneRequiredWithoutVariantWarehousesNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateWithoutWarehouseInput> = z
  .object({
    variant: z
      .lazy(
        () =>
          VariantUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantWarehouseUpdateWithoutWarehouseInputObjectSchema = Schema
