import { z } from 'zod'
import { ProductReviewSummarySelectObjectSchema } from './ProductReviewSummarySelect.schema'
import { ProductReviewSummaryIncludeObjectSchema } from './ProductReviewSummaryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryArgs> = z
  .object({
    select: z.lazy(() => ProductReviewSummarySelectObjectSchema).optional(),
    include: z.lazy(() => ProductReviewSummaryIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductReviewSummaryArgsObjectSchema = Schema
