import { z } from 'zod'
import { ConditionScopeSchema } from '../enums/ConditionScope.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.NestedEnumConditionScopeFilter> = z
  .object({
    equals: z.lazy(() => ConditionScopeSchema).optional(),
    in: z
      .lazy(() => ConditionScopeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConditionScopeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConditionScopeSchema),
        z.lazy(() => NestedEnumConditionScopeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumConditionScopeFilterObjectSchema = Schema
