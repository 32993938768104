import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerCreateWithoutUsersInputObjectSchema } from './PartnerCreateWithoutUsersInput.schema'
import { PartnerUncheckedCreateWithoutUsersInputObjectSchema } from './PartnerUncheckedCreateWithoutUsersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutUsersInput> = z
  .object({
    where: z.lazy(() => PartnerWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutUsersInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutUsersInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerCreateOrConnectWithoutUsersInputObjectSchema = Schema
