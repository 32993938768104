import { z } from 'zod'
import { ConditionSettingOrderByWithRelationInputObjectSchema } from './objects/ConditionSettingOrderByWithRelationInput.schema'
import { ConditionSettingWhereInputObjectSchema } from './objects/ConditionSettingWhereInput.schema'
import { ConditionSettingWhereUniqueInputObjectSchema } from './objects/ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingCountAggregateInputObjectSchema } from './objects/ConditionSettingCountAggregateInput.schema'
import { ConditionSettingMinAggregateInputObjectSchema } from './objects/ConditionSettingMinAggregateInput.schema'
import { ConditionSettingMaxAggregateInputObjectSchema } from './objects/ConditionSettingMaxAggregateInput.schema'
import { ConditionSettingAvgAggregateInputObjectSchema } from './objects/ConditionSettingAvgAggregateInput.schema'
import { ConditionSettingSumAggregateInputObjectSchema } from './objects/ConditionSettingSumAggregateInput.schema'

export const ConditionSettingAggregateSchema = z.object({
  orderBy: z
    .union([
      ConditionSettingOrderByWithRelationInputObjectSchema,
      ConditionSettingOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ConditionSettingWhereInputObjectSchema.optional(),
  cursor: ConditionSettingWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ConditionSettingCountAggregateInputObjectSchema])
    .optional(),
  _min: ConditionSettingMinAggregateInputObjectSchema.optional(),
  _max: ConditionSettingMaxAggregateInputObjectSchema.optional(),
  _avg: ConditionSettingAvgAggregateInputObjectSchema.optional(),
  _sum: ConditionSettingSumAggregateInputObjectSchema.optional(),
})
