import { z } from 'zod'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumOrderItemTypeFilter> = z
  .object({
    equals: z.lazy(() => OrderItemTypeSchema).optional(),
    in: z
      .lazy(() => OrderItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => OrderItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => OrderItemTypeSchema),
        z.lazy(() => NestedEnumOrderItemTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumOrderItemTypeFilterObjectSchema = Schema
