import { Box, Button } from '@mui/material'
import { KycStatus } from '@prisma/client/patients'
import { useEffect } from 'react'
import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomDateInput,
  CustomSelectInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      nameOrPid: undefined,
      status: undefined,
      fromDate: undefined,
      toDate: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.status
    delete newFilter.nameOrPid
    delete newFilter.fromDate
    delete newFilter.toDate

    if (values.status) {
      newFilter.status_equals = values.status
    }
    if (values.nameOrPid) {
      const pid = parseInt(values.nameOrPid as string)
      newFilter.OR = [
        { firstname: values.nameOrPid },
        { lastname: values.nameOrPid },
      ]

      if (!isNaN(pid)) {
        ;(newFilter.OR as unknown[]).push({ patientId_equals: pid })
      }
    }
    if (values.fromDate) {
      newFilter.createdAt_DATE_gte = new Date(values.fromDate as string)
    }
    if (values.toDate) {
      newFilter.createdAt_DATE_lte = new Date(values.toDate as string)
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }

  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          status_equals: {
            formKey: 'status',
          },
          OR: {
            formKey: 'nameOrPid',
            valuePathForForm: '[0].firstname',
          },
          createdAt_DATE_gte: {
            formKey: 'fromDate',
          },
          createdAt_DATE_lte: {
            formKey: 'toDate',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="nameOrPid"
            label="Search PID/Name"
          />
          <CustomSelectInput
            source="status"
            label="Filter Status"
            helperText={false}
            sx={{ mt: 0.5 }}
            choices={[
              { id: KycStatus.PENDING, name: 'Pending' },
              { id: KycStatus.APPROVED, name: 'Approved' },
              { id: KycStatus.REJECTED, name: 'Rejected' },
            ]}
          />
          <CustomDateInput
            source="fromDate"
            label="เลือกวันที่ (ตั้งแต่)"
            helperText={false}
          />
          <CustomDateInput
            source="toDate"
            label="เลือกวันที่ (จนถึง)"
            helperText={false}
          />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
