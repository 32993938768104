import { z } from 'zod'
import { CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryCreateOrConnectWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUpsertWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUpsertWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryUpdateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUpdateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateOneRequiredWithoutSuggestedProductItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutSuggestedProductItemsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => CaseSummaryUpsertWithoutSuggestedProductItemsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CaseSummaryUpdateWithoutSuggestedProductItemsInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryUpdateOneRequiredWithoutSuggestedProductItemsNestedInputObjectSchema =
  Schema
