import { z } from 'zod'
import { CaseSummaryUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => CaseSummaryUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryUpsertWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
