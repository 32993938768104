import { z } from 'zod'
import { ProductReviewSummaryWhereInputObjectSchema } from './objects/ProductReviewSummaryWhereInput.schema'
import { ProductReviewSummaryOrderByWithAggregationInputObjectSchema } from './objects/ProductReviewSummaryOrderByWithAggregationInput.schema'
import { ProductReviewSummaryScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductReviewSummaryScalarWhereWithAggregatesInput.schema'
import { ProductReviewSummaryScalarFieldEnumSchema } from './enums/ProductReviewSummaryScalarFieldEnum.schema'

export const ProductReviewSummaryGroupBySchema = z.object({
  where: ProductReviewSummaryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductReviewSummaryOrderByWithAggregationInputObjectSchema,
    ProductReviewSummaryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProductReviewSummaryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductReviewSummaryScalarFieldEnumSchema),
})
