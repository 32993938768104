import * as z from "zod"
import { CompleteFlashsaleItem, RelatedFlashsaleItemModel } from "./index"

export const FlashsaleModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdBy: z.number().int(),
  updatedBy: z.number().int(),
  name: z.string(),
  description: z.string().nullish(),
  startAt: z.date(),
  endAt: z.date(),
  active: z.boolean(),
})

export interface CompleteFlashsale extends z.infer<typeof FlashsaleModel> {
  items: CompleteFlashsaleItem[]
}

/**
 * RelatedFlashsaleModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedFlashsaleModel: z.ZodSchema<CompleteFlashsale> = z.lazy(() => FlashsaleModel.extend({
  items: RelatedFlashsaleItemModel.array(),
}))
