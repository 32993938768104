import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUncheckedCreateWithoutPatientInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      consentKey: z.string(),
    })
    .strict()

export const AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema =
  Schema
