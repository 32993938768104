import { z } from 'zod'
import { OfficeHourWhereUniqueInputObjectSchema } from './OfficeHourWhereUniqueInput.schema'
import { OfficeHourUpdateWithoutBranchInputObjectSchema } from './OfficeHourUpdateWithoutBranchInput.schema'
import { OfficeHourUncheckedUpdateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedUpdateWithoutBranchInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourUpdateWithWhereUniqueWithoutBranchInput> =
  z
    .object({
      where: z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => OfficeHourUpdateWithoutBranchInputObjectSchema),
        z.lazy(() => OfficeHourUncheckedUpdateWithoutBranchInputObjectSchema),
      ]),
    })
    .strict()

export const OfficeHourUpdateWithWhereUniqueWithoutBranchInputObjectSchema =
  Schema
