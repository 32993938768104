import { Backdrop, CircularProgress } from '@mui/material'
import { FC } from 'react'

interface LoadingBackdrop {
  open: boolean
}

export const LoadingBackdrop: FC<LoadingBackdrop> = ({ open }) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
)
