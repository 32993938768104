import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCountOutputTypeSelect> = z
  .object({
    coupons: z.boolean().optional(),
    discountSetting: z.boolean().optional(),
    conditionSetting: z.boolean().optional(),
  })
  .strict()

export const CouponGroupCountOutputTypeSelectObjectSchema = Schema
