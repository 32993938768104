import { Box } from '@mui/material'
import {
  Datagrid,
  FunctionField,
  List,
  NumberField,
  Pagination,
  TextField,
} from 'react-admin'
import { CustomBooleanField } from '../../components/CustomField'

const rowsPerPage = [5, 10, 20]

export const ShippingProviderList = () => {
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        sort={{ field: 'type,priority', order: 'ASC,DESC' }}
      >
        <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="type" />
          <TextField source="name" />
          <TextField source="detail" emptyText="-" />
          <FunctionField
            source="moreDetail"
            textAlign="center"
            render={(record: { moreDetail?: string }) => (
              <div
                dangerouslySetInnerHTML={{ __html: record.moreDetail ?? '-' }}
              />
            )}
          />
          <NumberField source="price" />
          <NumberField source="freeShippingMinimumPrice" />
          <TextField source="priority" />
          <CustomBooleanField source="active" label="เปิดใช้งาน" />
        </Datagrid>
      </List>
    </Box>
  )
}
