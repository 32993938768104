import { z } from 'zod'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductCreateWithoutVideoInputObjectSchema } from './ProductCreateWithoutVideoInput.schema'
import { ProductUncheckedCreateWithoutVideoInputObjectSchema } from './ProductUncheckedCreateWithoutVideoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateOrConnectWithoutVideoInput> = z
  .object({
    where: z.lazy(() => ProductWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductCreateWithoutVideoInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutVideoInputObjectSchema),
    ]),
  })
  .strict()

export const ProductCreateOrConnectWithoutVideoInputObjectSchema = Schema
