import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductBannerCreateManyInput> = z
  .object({
    id: z.number().optional(),
    name: z.string(),
    picture: z.string(),
    actionUri: z.string().optional().nullable(),
    active: z.boolean().optional(),
    rank: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const ProductBannerCreateManyInputObjectSchema = Schema
