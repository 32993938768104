import { z } from 'zod'
import { CoinTransactionOrderByWithRelationInputObjectSchema } from './objects/CoinTransactionOrderByWithRelationInput.schema'
import { CoinTransactionWhereInputObjectSchema } from './objects/CoinTransactionWhereInput.schema'
import { CoinTransactionWhereUniqueInputObjectSchema } from './objects/CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionCountAggregateInputObjectSchema } from './objects/CoinTransactionCountAggregateInput.schema'
import { CoinTransactionMinAggregateInputObjectSchema } from './objects/CoinTransactionMinAggregateInput.schema'
import { CoinTransactionMaxAggregateInputObjectSchema } from './objects/CoinTransactionMaxAggregateInput.schema'
import { CoinTransactionAvgAggregateInputObjectSchema } from './objects/CoinTransactionAvgAggregateInput.schema'
import { CoinTransactionSumAggregateInputObjectSchema } from './objects/CoinTransactionSumAggregateInput.schema'

export const CoinTransactionAggregateSchema = z.object({
  orderBy: z
    .union([
      CoinTransactionOrderByWithRelationInputObjectSchema,
      CoinTransactionOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CoinTransactionWhereInputObjectSchema.optional(),
  cursor: CoinTransactionWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CoinTransactionCountAggregateInputObjectSchema])
    .optional(),
  _min: CoinTransactionMinAggregateInputObjectSchema.optional(),
  _max: CoinTransactionMaxAggregateInputObjectSchema.optional(),
  _avg: CoinTransactionAvgAggregateInputObjectSchema.optional(),
  _sum: CoinTransactionSumAggregateInputObjectSchema.optional(),
})
