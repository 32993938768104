import { z } from 'zod'
import { CaseSummaryCreateInputObjectSchema } from './objects/CaseSummaryCreateInput.schema'
import { CaseSummaryUncheckedCreateInputObjectSchema } from './objects/CaseSummaryUncheckedCreateInput.schema'

export const CaseSummaryCreateOneSchema = z.object({
  data: z.union([
    CaseSummaryCreateInputObjectSchema,
    CaseSummaryUncheckedCreateInputObjectSchema,
  ]),
})
