import { z } from 'zod'
import { ProductCreateNestedOneWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateNestedOneWithoutProductReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryCreateWithoutBrandInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    overallRating: z.number().optional(),
    qualityRating: z.number().optional(),
    shippingRating: z.number().optional(),
    packagingRating: z.number().optional(),
    countForRating5: z.number().optional(),
    countForRating4: z.number().optional(),
    countForRating3: z.number().optional(),
    countForRating2: z.number().optional(),
    countForRating1: z.number().optional(),
    product: z.lazy(
      () => ProductCreateNestedOneWithoutProductReviewSummaryInputObjectSchema,
    ),
  })
  .strict()

export const ProductReviewSummaryCreateWithoutBrandInputObjectSchema = Schema
