import { z } from 'zod'
import {
  DoctorItemModel,
  OrderModel,
  ProcedureItemModel,
  ProductItemModel,
} from '../orders'

export const CouponOrderDetails = OrderModel.pick({
  shippingFee: true,
  shippingFeeDiscount: true,
  shippingType: true,
  shippingProviderId: true,
  useCoin: true,
}).extend({
  freeShippingMinimumPrice: z.number().optional().default(0),
  totalAmount: z.number().optional().default(0),
  doctorItem: DoctorItemModel.pick({
    doctorFee: true,
    doctorFeeDiscount: true,
    consultingChannel: true,
    doctorId: true,
  })
    .extend({
      categories: z.object({ id: z.number() }).array().optional().default([]),
    })
    .nullish(),
  productItems: ProductItemModel.pick({
    variantId: true,
    sellingPrice: true,
    quantity: true,
    flashSaleId: true,
    drugType: true,
  })
    .extend({
      product: z.object({ brandId: z.number().nullable() }).nullish(),
      categories: z.object({ id: z.number() }).array().optional().default([]),
    })
    .array(),
  procedureItems: ProcedureItemModel.pick({
    procedureId: true,
    partnerId: true,
    sellingPrice: true,
    quantity: true,
    flashSaleId: true,
  })
    .extend({
      categories: z.object({ id: z.number() }).array().optional().default([]),
    })
    .array(),
})
export type CouponOrderDetailsType = z.infer<typeof CouponOrderDetails>
