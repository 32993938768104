import { z } from 'zod'
import { AddressUpdateInputObjectSchema } from './objects/AddressUpdateInput.schema'
import { AddressUncheckedUpdateInputObjectSchema } from './objects/AddressUncheckedUpdateInput.schema'
import { AddressWhereUniqueInputObjectSchema } from './objects/AddressWhereUniqueInput.schema'

export const AddressUpdateOneSchema = z.object({
  data: z.union([
    AddressUpdateInputObjectSchema,
    AddressUncheckedUpdateInputObjectSchema,
  ]),
  where: AddressWhereUniqueInputObjectSchema,
})
