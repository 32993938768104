import { z } from 'zod'
import { ProductReviewSummaryWhereInputObjectSchema } from './ProductReviewSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryListRelationFilter> = z
  .object({
    every: z.lazy(() => ProductReviewSummaryWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProductReviewSummaryWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProductReviewSummaryWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductReviewSummaryListRelationFilterObjectSchema = Schema
