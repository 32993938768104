import { z } from 'zod'
import { FlashsaleItemCreateManyFlashsaleInputObjectSchema } from './FlashsaleItemCreateManyFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateManyFlashsaleInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => FlashsaleItemCreateManyFlashsaleInputObjectSchema),
        z.lazy(() => FlashsaleItemCreateManyFlashsaleInputObjectSchema).array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const FlashsaleItemCreateManyFlashsaleInputEnvelopeObjectSchema = Schema
