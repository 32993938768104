import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DoctorItemOrderByWithRelationInputObjectSchema } from './DoctorItemOrderByWithRelationInput.schema'
import { ProductItemOriginalOrderByRelationAggregateInputObjectSchema } from './ProductItemOriginalOrderByRelationAggregateInput.schema'
import { ProductItemOrderByRelationAggregateInputObjectSchema } from './ProductItemOrderByRelationAggregateInput.schema'
import { ProcedureItemOrderByRelationAggregateInputObjectSchema } from './ProcedureItemOrderByRelationAggregateInput.schema'
import { CouponOrderByRelationAggregateInputObjectSchema } from './CouponOrderByRelationAggregateInput.schema'
import { ShippingProviderOrderByWithRelationInputObjectSchema } from './ShippingProviderOrderByWithRelationInput.schema'
import { PaymentOrderByRelationAggregateInputObjectSchema } from './PaymentOrderByRelationAggregateInput.schema'
import { RefundOrderByRelationAggregateInputObjectSchema } from './RefundOrderByRelationAggregateInput.schema'
import { DeliveryOrderByRelationAggregateInputObjectSchema } from './DeliveryOrderByRelationAggregateInput.schema'
import { HealthInfoOrderByWithRelationInputObjectSchema } from './HealthInfoOrderByWithRelationInput.schema'
import { ReceiptOrderByWithRelationInputObjectSchema } from './ReceiptOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    expiredAt: z.lazy(() => SortOrderSchema).optional(),
    shouldExpiresAt: z.lazy(() => SortOrderSchema).optional(),
    orderNumber: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    customerName: z.lazy(() => SortOrderSchema).optional(),
    customerTelNo: z.lazy(() => SortOrderSchema).optional(),
    orderType: z.lazy(() => SortOrderSchema).optional(),
    purchaseOrigin: z.lazy(() => SortOrderSchema).optional(),
    purchaseRef: z.lazy(() => SortOrderSchema).optional(),
    editedProductItemsAt: z.lazy(() => SortOrderSchema).optional(),
    editedProductItemsBy: z.lazy(() => SortOrderSchema).optional(),
    shippingFee: z.lazy(() => SortOrderSchema).optional(),
    shippingFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    shippingType: z.lazy(() => SortOrderSchema).optional(),
    shippingProviderId: z.lazy(() => SortOrderSchema).optional(),
    recipient: z.lazy(() => SortOrderSchema).optional(),
    shippingAddress: z.lazy(() => SortOrderSchema).optional(),
    shippingTelNo: z.lazy(() => SortOrderSchema).optional(),
    subDistrict: z.lazy(() => SortOrderSchema).optional(),
    subDistrictKey: z.lazy(() => SortOrderSchema).optional(),
    district: z.lazy(() => SortOrderSchema).optional(),
    districtKey: z.lazy(() => SortOrderSchema).optional(),
    province: z.lazy(() => SortOrderSchema).optional(),
    provinceKey: z.lazy(() => SortOrderSchema).optional(),
    postcode: z.lazy(() => SortOrderSchema).optional(),
    isPaid: z.lazy(() => SortOrderSchema).optional(),
    isHold: z.lazy(() => SortOrderSchema).optional(),
    approvedBy: z.lazy(() => SortOrderSchema).optional(),
    approvedAt: z.lazy(() => SortOrderSchema).optional(),
    canceledBy: z.lazy(() => SortOrderSchema).optional(),
    canceledAt: z.lazy(() => SortOrderSchema).optional(),
    cancelChannel: z.lazy(() => SortOrderSchema).optional(),
    noteToPatient: z.lazy(() => SortOrderSchema).optional(),
    useCoin: z.lazy(() => SortOrderSchema).optional(),
    v2InvoiceId: z.lazy(() => SortOrderSchema).optional(),
    holdingNote: z.lazy(() => SortOrderSchema).optional(),
    holdingBy: z.lazy(() => SortOrderSchema).optional(),
    holdingAt: z.lazy(() => SortOrderSchema).optional(),
    isDeclinedSuggestedItems: z.lazy(() => SortOrderSchema).optional(),
    declinedSuggestedItemsReason: z.lazy(() => SortOrderSchema).optional(),
    doctorItem: z
      .lazy(() => DoctorItemOrderByWithRelationInputObjectSchema)
      .optional(),
    productItemsOriginal: z
      .lazy(() => ProductItemOriginalOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productItems: z
      .lazy(() => ProductItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    coupons: z
      .lazy(() => CouponOrderByRelationAggregateInputObjectSchema)
      .optional(),
    shippingProvider: z
      .lazy(() => ShippingProviderOrderByWithRelationInputObjectSchema)
      .optional(),
    payments: z
      .lazy(() => PaymentOrderByRelationAggregateInputObjectSchema)
      .optional(),
    refunds: z
      .lazy(() => RefundOrderByRelationAggregateInputObjectSchema)
      .optional(),
    deliveries: z
      .lazy(() => DeliveryOrderByRelationAggregateInputObjectSchema)
      .optional(),
    healthInfo: z
      .lazy(() => HealthInfoOrderByWithRelationInputObjectSchema)
      .optional(),
    receipt: z
      .lazy(() => ReceiptOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const OrderOrderByWithRelationInputObjectSchema = Schema
