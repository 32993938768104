import { z } from 'zod'
import { FlashsaleCountOutputTypeSelectObjectSchema } from './FlashsaleCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => FlashsaleCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const FlashsaleCountOutputTypeArgsObjectSchema = Schema
