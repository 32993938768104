import { z } from 'zod'

export const UnitSchema = z.enum([
  'TUBE',
  'STRIP',
  'BOTTLE',
  'BOX',
  'JAR',
  'SACHET',
  'PACK',
  'UNIT',
  'ROLL',
  'SHEET',
  'TABLET',
  'SET',
  'STICK',
  'CAPSULE',
  'CASSETTE',
  'ML',
  'GRAM',
  'AMPULE',
  'PIECE',
  'MACHINE',
  'LUMP',
  'HANDLE',
  'PRESS',
  'MEASURING_SPOON',
])
