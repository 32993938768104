import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { OrderUncheckedCreateNestedManyWithoutShippingProviderInputObjectSchema } from './OrderUncheckedCreateNestedManyWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderUncheckedCreateWithoutAreasInput> =
  z
    .object({
      id: z.number().optional(),
      priority: z.number().optional(),
      name: z.string(),
      detail: z.string(),
      moreDetail: z.string().optional().nullable(),
      shippingExpectation: z.string().optional(),
      trackingUrl: z.string().optional().nullable(),
      type: z.lazy(() => ShippingTypeSchema),
      price: z.number(),
      freeShippingMinimumPrice: z.number().optional().nullable(),
      active: z.boolean().optional(),
      orders: z
        .lazy(
          () =>
            OrderUncheckedCreateNestedManyWithoutShippingProviderInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema =
  Schema
