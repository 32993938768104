import { z } from 'zod'
import { BrandCreateNestedOneWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateNestedOneWithoutProductReviewSummariesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryCreateWithoutProductInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      overallRating: z.number().optional(),
      qualityRating: z.number().optional(),
      shippingRating: z.number().optional(),
      packagingRating: z.number().optional(),
      countForRating5: z.number().optional(),
      countForRating4: z.number().optional(),
      countForRating3: z.number().optional(),
      countForRating2: z.number().optional(),
      countForRating1: z.number().optional(),
      brand: z.lazy(
        () =>
          BrandCreateNestedOneWithoutProductReviewSummariesInputObjectSchema,
      ),
    })
    .strict()

export const ProductReviewSummaryCreateWithoutProductInputObjectSchema = Schema
