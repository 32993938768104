import { z } from 'zod'
import { KycStatusSchema } from '../enums/KycStatus.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumKycStatusFilterObjectSchema } from './NestedEnumKycStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumKycStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => KycStatusSchema).optional(),
    in: z
      .lazy(() => KycStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => KycStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => KycStatusSchema),
        z.lazy(() => NestedEnumKycStatusWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumKycStatusFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumKycStatusFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumKycStatusWithAggregatesFilterObjectSchema = Schema
