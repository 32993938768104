import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutMyReviewsInputObjectSchema } from './PatientCreateWithoutMyReviewsInput.schema'
import { PatientUncheckedCreateWithoutMyReviewsInputObjectSchema } from './PatientUncheckedCreateWithoutMyReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutMyReviewsInput> = z
  .object({
    where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PatientCreateWithoutMyReviewsInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutMyReviewsInputObjectSchema),
    ]),
  })
  .strict()

export const PatientCreateOrConnectWithoutMyReviewsInputObjectSchema = Schema
