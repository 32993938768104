import { Button, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react'
import { useDataProvider, useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { adminGatewayClient } from '../../../service'
import { transformPatientKycData } from '../transform'

interface SaveAndApproveButtonProps {
  disabled: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const SaveAndApproveButton: FC<SaveAndApproveButtonProps> = ({
  disabled,
  setIsLoading,
}) => {
  const navigate = useNavigate()
  const { getValues } = useFormContext()
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const confirm = useConfirm()

  const handleClickSaveAndApprove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const { id, ...data } = getValues()
    confirm({
      title: 'Confirm patient KYC',
      description: (
        <Typography>
          Do you want to approve{' '}
          <b>
            {data.prefix} {data.firstname} {data.lastname}
          </b>{' '}
          KYC?
        </Typography>
      ),
    })
      .then(async () => {
        setIsLoading(true)
        const savedData = await transformPatientKycData({
          ...data,
          idCardPicture: data.idCardPicture,
          selfieIdCardPicture: data.selfieIdCardPicture,
          passportPicture: data.passportPicture,
          selfiePassportPicture: data.selfiePassportPicture,
          validator: data.validator,
          errorReason: data.errorReason,
        })

        dataProvider
          .update('patientKycData', {
            id,
            data: savedData,
            previousData: record,
          })
          .then(() => {
            adminGatewayClient.patientKycData.approve
              .mutate({ kycDataId: id })
              .then(() => {
                setIsLoading(false)
                navigate(-1)
              })
              .catch((err) => {
                console.error(err)
              })
          })
          .catch((error) => {
            console.error(error)
          })
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={handleClickSaveAndApprove}
      sx={{
        backgroundColor: '#64B95E',
        ':disabled': {
          opacity: '0.3',
          backgroundColor: '#64B95E',
          color: 'white',
        },
      }}
    >
      Save & Approve
    </Button>
  )
}
