import { z } from 'zod'
import { OrderCreateWithoutProductItemsInputObjectSchema } from './OrderCreateWithoutProductItemsInput.schema'
import { OrderUncheckedCreateWithoutProductItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsInput.schema'
import { OrderCreateOrConnectWithoutProductItemsInputObjectSchema } from './OrderCreateOrConnectWithoutProductItemsInput.schema'
import { OrderUpsertWithoutProductItemsInputObjectSchema } from './OrderUpsertWithoutProductItemsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutProductItemsInputObjectSchema } from './OrderUpdateWithoutProductItemsInput.schema'
import { OrderUncheckedUpdateWithoutProductItemsInputObjectSchema } from './OrderUncheckedUpdateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutProductItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => OrderUncheckedCreateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutProductItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutProductItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => OrderUncheckedUpdateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutProductItemsNestedInputObjectSchema =
  Schema
