import { z } from 'zod'
import { SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUncheckedCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemCreateOrConnectWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUpsertWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProductItemCreateManyCaseSummaryInputEnvelopeObjectSchema } from './SuggestedProductItemCreateManyCaseSummaryInputEnvelope.schema'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInput.schema'
import { SuggestedProductItemScalarWhereInputObjectSchema } from './SuggestedProductItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemUpdateManyWithoutCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            SuggestedProductItemCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => SuggestedProductItemScalarWhereInputObjectSchema),
          z
            .lazy(() => SuggestedProductItemScalarWhereInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const SuggestedProductItemUpdateManyWithoutCaseSummaryNestedInputObjectSchema =
  Schema
