import { Datagrid, List, Pagination, TextField } from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'

const rowsPerPage = [5, 10, 20]

export const DoctorConsentList = () => (
  <List
    disableSyncWithLocation
    perPage={rowsPerPage[2]}
    pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
  >
    <Datagrid size="small" rowClick="edit" bulkActionButtons={false}>
      <TextField source="key" />
      <LocaleDateField
        source="createdAt"
        options={{ day: 'numeric', month: 'long', year: 'numeric' }}
      />
      <LocaleDateField
        source="updatedAt"
        options={{ day: 'numeric', month: 'long', year: 'numeric' }}
      />
    </Datagrid>
  </List>
)
