import { z } from 'zod'
import { ProcedureBannerOrderByWithRelationInputObjectSchema } from './objects/ProcedureBannerOrderByWithRelationInput.schema'
import { ProcedureBannerWhereInputObjectSchema } from './objects/ProcedureBannerWhereInput.schema'
import { ProcedureBannerWhereUniqueInputObjectSchema } from './objects/ProcedureBannerWhereUniqueInput.schema'
import { ProcedureBannerScalarFieldEnumSchema } from './enums/ProcedureBannerScalarFieldEnum.schema'

export const ProcedureBannerFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProcedureBannerOrderByWithRelationInputObjectSchema,
      ProcedureBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureBannerWhereInputObjectSchema.optional(),
  cursor: ProcedureBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureBannerScalarFieldEnumSchema).optional(),
})
