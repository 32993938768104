import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { DiscountType } from '@prisma/client/coupons'
import { CouponTypeSchema, DiscountTypeSchema } from '@ssch-backend/coupons'
import {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useState,
} from 'react'
import {
  BooleanInput,
  RaRecord,
  TranslatableInputs,
  required,
  useCreateSuggestionContext,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { z } from 'zod'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteArrayInput,
  FormDateTimeInput,
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
interface TagChoice {
  id: string
  nameTr: {
    en: string
    th: string
  }
}
const tags: TagChoice[] = []
const CouponCodeTypeSchema = z.enum(['PREFIX', 'CODE'])

const CreateCouponTagModal: FC<{
  tags: TagChoice[]
}> = ({ tags }) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [tagTH, setTagTH] = useState(filter || '')
  const [tagEN, setTagEN] = useState(filter || '')
  const translate = useTranslate()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newOption = {
      id: [tagTH, tagEN].join(','),
      nameTr: { th: tagTH, en: tagEN },
    }
    tags.push(newOption)
    setTagTH('')
    setTagEN('')
    onCreate(newOption)
  }

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {translate('resources.couponGroup.action.tag.create')}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={translate('resources.couponGroup.action.tag.tag_th')}
                value={tagTH}
                onChange={(event) => setTagTH(event.target.value)}
                required
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={translate('resources.couponGroup.action.tag.tag_en')}
                value={tagEN}
                onChange={(event) => setTagEN(event.target.value)}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ padding: '1rem', justifyContent: 'space-between' }}
        >
          <Button onClick={onCancel} size="small">
            {translate('ra.action.cancel')}
          </Button>
          <Button type="submit" variant="contained" size="small">
            {translate('resources.couponGroup.action.tag.create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const CreateCouponSection: FC<{
  setHideSettingSections: Dispatch<SetStateAction<boolean>>
}> = ({ setHideSettingSections }) => {
  const [generateCodeType, setGenerateCodeType] = useState<
    z.infer<typeof CouponCodeTypeSchema>
  >(CouponCodeTypeSchema.Enum.PREFIX)
  const [discountType, setDiscountType] = useState<DiscountType>(
    DiscountTypeSchema.Enum.FIXED,
  )
  const { setValue } = useFormContext()
  const translate = useTranslate()

  const handleGenerateTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: z.infer<typeof CouponCodeTypeSchema> | null,
  ) => {
    if (!newAlignment) {
      return
    }
    setValue(generateCodeType.toLocaleLowerCase(), undefined)
    if (newAlignment === 'PREFIX') setValue('isPublic', false)
    setGenerateCodeType(newAlignment)
  }

  const handleCouponTypeChange = (event: ChangeEvent | RaRecord) => {
    switch (event.target.value) {
      case CouponTypeSchema.Enum.SHIPPING:
        setValue('discountSetting', [])
        setValue('conditionSetting', [])
        setHideSettingSections(true)
        break
      default:
        setHideSettingSections(false)
        break
    }
  }

  const handleDiscountTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: DiscountType | null,
  ) => {
    if (!newAlignment) {
      return
    }
    setDiscountType(newAlignment)
    setValue('maxDiscount', null)
  }

  const getInputAdornment = (position: 'start' | 'end', symbol: string) => {
    return {
      [`${position}Adornment`]: (
        <InputAdornment position={position}>{symbol}</InputAdornment>
      ),
    }
  }

  const getDiscountAdornmentSymbol = (
    DiscountTypeEnum: keyof typeof DiscountTypeSchema.enum,
  ) => {
    setValue('discountType', DiscountTypeEnum)
    switch (DiscountTypeEnum) {
      case 'FIXED':
        return getInputAdornment('start', '฿')
      case 'PERCENT':
        return getInputAdornment('end', '%')
      default:
        return {}
    }
  }

  const discountValidation = (
    value: number,
    allValues: { discountType: keyof typeof DiscountTypeSchema.enum },
  ) => {
    const { discountType } = allValues
    if (discountType === DiscountTypeSchema.Enum.PERCENT && value > 100) {
      return {
        message: 'ra.validation.maxValue',
        args: { max: '100%' },
      }
    }
    return undefined
  }

  const generateCodeValidation = (
    value: string,
    couponCodeType: z.infer<typeof CouponCodeTypeSchema>,
  ) => {
    const prefixConditions = { minLength: 1, maxLength: 6 }
    const codeConditions = { minLength: 1, maxLength: 12 }
    const conditions =
      couponCodeType === CouponCodeTypeSchema.Enum.PREFIX
        ? prefixConditions
        : codeConditions

    if (
      value.trim().length > conditions.maxLength ||
      value.trim().length < conditions.minLength
    ) {
      return {
        message: 'resources.couponGroup.validation.code_type_length',
        args: {
          codeType: couponCodeType,
          minLength: conditions.minLength,
          maxLength: conditions.maxLength,
        },
      }
    }
    return undefined
  }

  const dateRangeValidation = (
    value: string,
    allValues: { startAt: string; expireAt: string },
  ) => {
    if (!allValues.startAt || !allValues.expireAt) {
      return undefined
    }
    const startAt = new Date(allValues.startAt)
    const expireAt = new Date(allValues.expireAt)
    if (startAt > expireAt) {
      if (allValues.startAt === value) {
        return 'resources.couponGroup.validation.start_date'
      } else {
        return 'resources.couponGroup.validation.expire_at'
      }
    } else {
      return undefined
    }
  }

  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('resources.couponGroup.title.generate_coupon')}
        </Typography>
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            value={generateCodeType}
            exclusive
            onChange={handleGenerateTypeChange}
            aria-label="Platform"
          >
            {Object.values(CouponCodeTypeSchema.Enum).map((codeType) => (
              <ToggleButton value={codeType} key={codeType}>
                {codeType}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            source={generateCodeType.toLocaleLowerCase()}
            validate={[
              required(),
              (value: string) =>
                generateCodeValidation(value, generateCodeType),
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item display={'flex'} columnGap={'1rem'}>
            <Grid item xs={12} md={6}>
              <FormDateTimeInput
                source="startAt"
                validate={[required(), dateRangeValidation]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateTimeInput
                source="expireAt"
                validate={[required(), dateRangeValidation]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TranslatableInputs locales={['th', 'en']}>
            <FormTextInput source="titleTr" validate={required()} />
            <FormTextInput source="descriptionTr" multiline rows={3} />
          </TranslatableInputs>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: 'end' }}>
          <Grid container display={'flex'} columnSpacing={1}>
            {generateCodeType === 'CODE' && (
              <Grid item xs={12} md={6}>
                <BooleanInput source="isPublic" defaultValue={false} />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <BooleanInput source="isOnlyNewPatient" defaultValue={false} />
            </Grid>
            <Grid item xs={12}>
              <FormSelectInput
                source="type"
                choices={Object.values(CouponTypeSchema.Enum).map((option) => ({
                  id: option,
                  name: ['resources.couponGroup.title.couponType', option].join(
                    '.',
                  ),
                }))}
                onChange={handleCouponTypeChange}
                validate={required()}
              />
            </Grid>
          </Grid>
          <Grid item display={'flex'} columnGap={'1rem'}>
            <Grid item xs={12} md={6}>
              <FormNumberInput
                source="discount"
                min={0}
                InputProps={getDiscountAdornmentSymbol(discountType)}
                validate={[required(), discountValidation]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ToggleButtonGroup
                color="primary"
                value={discountType}
                exclusive
                onChange={handleDiscountTypeChange}
                aria-label="Platform"
              >
                {Object.values(DiscountTypeSchema.Enum).map((discountType) => (
                  <ToggleButton value={discountType} key={discountType}>
                    {translate(
                      [
                        'resources.couponGroup.title.discountType',
                        discountType,
                      ].join('.'),
                    )}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item display={'flex'} columnGap={'1rem'}>
            <Grid item xs={12} md={6}>
              <FormNumberInput
                source="minTotal"
                min={0}
                InputProps={getInputAdornment('start', '฿')}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {discountType === DiscountTypeSchema.Enum.PERCENT && (
                <FormNumberInput
                  source="maxDiscount"
                  min={0}
                  InputProps={getInputAdornment('start', '฿')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormAutoCompleteArrayInput
            source="tags"
            style={{ margin: '10px 0' }}
            choices={tags}
            optionText={(choice: TagChoice) =>
              [choice.nameTr.th, choice.nameTr.en].join(' | ')
            }
            create={<CreateCouponTagModal tags={tags} />}
            parse={(value) =>
              value &&
              value
                .filter((v: string | undefined) => v !== undefined)
                .map((v: string) => {
                  const nameTr = v.split(',')
                  return {
                    th: nameTr[0],
                    en: nameTr[1],
                  }
                })
            }
            format={(value) =>
              value &&
              value
                .filter((v: string | undefined) => v !== undefined)
                .map((v: { th: string; en: string }) => {
                  return [v.th, v.en].join(',')
                })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item display={'flex'} columnGap={'1rem'}>
            <Grid item xs={12} md={6}>
              <FormNumberInput
                source="quota"
                min={1}
                defaultValue={1}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumberInput source="quotaPerUser" min={1} defaultValue={1} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormTextInput source="note" validate={required()} />
        </Grid>
      </Grid>
    </>
  )
}
