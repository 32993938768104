import { z } from 'zod'
import { PatientCreateNestedOneWithoutNotificationsInputObjectSchema } from './PatientCreateNestedOneWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateWithoutNotificationInput> =
  z
    .object({
      readAt: z.date().optional().nullable(),
      patient: z.lazy(
        () => PatientCreateNestedOneWithoutNotificationsInputObjectSchema,
      ),
    })
    .strict()

export const NotificationPatientCreateWithoutNotificationInputObjectSchema =
  Schema
