import { z } from 'zod'

export const ReceiptScalarFieldEnumSchema = z.enum([
  'receiptNumber',
  'receiptUrl',
  'createdAt',
  'updatedAt',
  'orderId',
  'receiptData',
  'isValid',
  'note',
])
