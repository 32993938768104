import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    rank: z.literal(true).optional(),
    brandId: z.literal(true).optional(),
  })
  .strict()

export const BrandBannerAvgAggregateInputObjectSchema = Schema
