import { z } from 'zod'
import { ProductItemOrderByWithRelationInputObjectSchema } from './objects/ProductItemOrderByWithRelationInput.schema'
import { ProductItemWhereInputObjectSchema } from './objects/ProductItemWhereInput.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './objects/ProductItemWhereUniqueInput.schema'
import { ProductItemCountAggregateInputObjectSchema } from './objects/ProductItemCountAggregateInput.schema'
import { ProductItemMinAggregateInputObjectSchema } from './objects/ProductItemMinAggregateInput.schema'
import { ProductItemMaxAggregateInputObjectSchema } from './objects/ProductItemMaxAggregateInput.schema'
import { ProductItemAvgAggregateInputObjectSchema } from './objects/ProductItemAvgAggregateInput.schema'
import { ProductItemSumAggregateInputObjectSchema } from './objects/ProductItemSumAggregateInput.schema'

export const ProductItemAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductItemOrderByWithRelationInputObjectSchema,
      ProductItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductItemWhereInputObjectSchema.optional(),
  cursor: ProductItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductItemCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductItemMinAggregateInputObjectSchema.optional(),
  _max: ProductItemMaxAggregateInputObjectSchema.optional(),
  _avg: ProductItemAvgAggregateInputObjectSchema.optional(),
  _sum: ProductItemSumAggregateInputObjectSchema.optional(),
})
