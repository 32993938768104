import { z } from 'zod'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { NestedEnumPurchaseOriginNullableFilterObjectSchema } from './NestedEnumPurchaseOriginNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumPurchaseOriginNullableFilter> = z
  .object({
    equals: z
      .lazy(() => PurchaseOriginSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => PurchaseOriginSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => PurchaseOriginSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => PurchaseOriginSchema),
        z.lazy(() => NestedEnumPurchaseOriginNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumPurchaseOriginNullableFilterObjectSchema = Schema
