import { z } from 'zod'
import { ProcedureReviewScalarWhereInputObjectSchema } from './ProcedureReviewScalarWhereInput.schema'
import { ProcedureReviewUpdateManyMutationInputObjectSchema } from './ProcedureReviewUpdateManyMutationInput.schema'
import { ProcedureReviewUncheckedUpdateManyWithoutProcedureReviewsInputObjectSchema } from './ProcedureReviewUncheckedUpdateManyWithoutProcedureReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUpdateManyWithWhereWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureReviewUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProcedureReviewUncheckedUpdateManyWithoutProcedureReviewsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewUpdateManyWithWhereWithoutProcedureInputObjectSchema =
  Schema
