import { Box, Grid, Typography } from '@mui/material'
import { UnitSchema } from '@ssch-backend/products'
import { useEffect } from 'react'
import {
  BooleanInput,
  ReferenceInput,
  required,
  useRecordContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteInput,
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { FormReferenceArrayInput } from './formReferenceArrayInput'

interface WarehouseOption {
  warehouse: {
    id: number
  }
}

const handleFilter = (searchText: string) => {
  if (searchText && searchText.trim().length >= 3) {
    return {
      searchField_contains: searchText.toLowerCase().trim(),
    }
  }
  return { id: 0 }
}

const validateProductVariant = async (
  value: number,
  allValues: { [x: string]: unknown },
) => {
  const cost = allValues['cost'] as number
  if (value < cost) {
    return 'SellingPrice cannot be less than cost.'
  }

  return undefined
}

const validateRequiredReference = (value: number) => {
  if (value === 0) {
    return 'จำเป็น'
  }
  return undefined
}

export const VariantInfoSection = ({
  title,
  onChangeActiveToggle,
}: {
  title?: string
  onChangeActiveToggle?: (isActive: boolean) => void
}) => {
  const [searchParams] = useSearchParams()
  const { setValue } = useFormContext()
  const record = useRecordContext()
  useEffect(() => {
    const productId = Number(searchParams.get('productId'))
    if (!isNaN(productId)) {
      setValue('productId', productId)
    }
  }, [searchParams, setValue])

  const handleActiveChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onChangeActiveToggle && onChangeActiveToggle(e.target.checked)
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {title || 'Add SKU'}
        </Typography>
        {record?.id && (
          <BooleanInput
            source="active"
            defaultValue={true}
            onChange={(e) => handleActiveChanged(e)}
          />
        )}
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <FormTextInput
            label="รหัสสินค้า"
            source="sku"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <ReferenceInput
            allowEmpty
            source="productId"
            reference="product"
            enableGetChoices={({ q }) => q && q.length > 2}
          >
            <FormAutoCompleteInput
              label="สินค้าหลัก"
              optionText="nameTr.th"
              filterToQuery={handleFilter}
              validate={[required(), validateRequiredReference]}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="คำแสดงผล"
            source="descriptionTr.th"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="คำแสดงผล (Eng.)"
            source="descriptionTr.en"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormNumberInput label="ขนาด (size)" source="size" />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelectInput
            label="หน่วย (unit)"
            source="unit"
            choices={Object.keys(UnitSchema.Enum).map((key: unknown) => {
              return {
                id: key,
                name: UnitSchema.Enum[key as keyof typeof UnitSchema.Enum],
              }
            })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelectInput
            label="หน่วยขาย (selling unit)"
            source="sellingUnit"
            validate={required()}
            choices={Object.keys(UnitSchema.Enum).map((key: unknown) => {
              return {
                id: key,
                name: UnitSchema.Enum[key as keyof typeof UnitSchema.Enum],
              }
            })}
          />
        </Grid>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={3}>
          <FormNumberInput
            label="ต้นทุน (cost)"
            source="cost"
            validate={required()}
            min={0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormNumberInput
            label="ราคาป้าย (tag price)"
            source="tagPrice"
            min={0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormNumberInput
            label="ราคาขาย (selling price)"
            source="sellingPrice"
            validate={[required(), validateProductVariant]}
            min={0}
          />
        </Grid>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={3}>
          <FormNumberInput source="inventory" defaultValue={0} disabled />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormReferenceArrayInput
            source="warehouse"
            parse={(value: WarehouseOption[] | number[]) => {
              return (
                value &&
                value.map((v) => {
                  return typeof v === 'object' ? v.warehouse.id : v
                })
              )
            }}
            format={(value: WarehouseOption[] | number[]) => {
              return (
                value &&
                value.map((v) => {
                  return typeof v === 'object' ? v.warehouse.id : v
                })
              )
            }}
            reference="warehouse"
            optionText="name"
            label="คลังสินค้า (Warehouse)"
            validator={required()}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BooleanInput
            label="ส่งด่วน (express shipping)"
            source="canExpress"
          />
        </Grid>
      </Grid>
    </>
  )
}
