import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { SuggestedProcedureItemCountOrderByAggregateInputObjectSchema } from './SuggestedProcedureItemCountOrderByAggregateInput.schema'
import { SuggestedProcedureItemAvgOrderByAggregateInputObjectSchema } from './SuggestedProcedureItemAvgOrderByAggregateInput.schema'
import { SuggestedProcedureItemMaxOrderByAggregateInputObjectSchema } from './SuggestedProcedureItemMaxOrderByAggregateInput.schema'
import { SuggestedProcedureItemMinOrderByAggregateInputObjectSchema } from './SuggestedProcedureItemMinOrderByAggregateInput.schema'
import { SuggestedProcedureItemSumOrderByAggregateInputObjectSchema } from './SuggestedProcedureItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      caseSummaryId: z.lazy(() => SortOrderSchema).optional(),
      procedureId: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      amount: z.lazy(() => SortOrderSchema).optional(),
      note: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(
          () => SuggestedProcedureItemCountOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _avg: z
        .lazy(() => SuggestedProcedureItemAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => SuggestedProcedureItemMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => SuggestedProcedureItemMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => SuggestedProcedureItemSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const SuggestedProcedureItemOrderByWithAggregationInputObjectSchema =
  Schema
