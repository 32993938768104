import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemMaxOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    doctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    consultingChannel: z.lazy(() => SortOrderSchema).optional(),
    doctorName: z.lazy(() => SortOrderSchema).optional(),
    doctorId: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const DoctorItemMaxOrderByAggregateInputObjectSchema = Schema
