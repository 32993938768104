import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumDiscountScopeFilterObjectSchema } from './EnumDiscountScopeFilter.schema'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'
import { EnumDiscountItemTypeFilterObjectSchema } from './EnumDiscountItemTypeFilter.schema'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { JsonNullableFilterObjectSchema } from './JsonNullableFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => DiscountSettingScalarWhereInputObjectSchema),
        z.lazy(() => DiscountSettingScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => DiscountSettingScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => DiscountSettingScalarWhereInputObjectSchema),
        z.lazy(() => DiscountSettingScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    scope: z
      .union([
        z.lazy(() => EnumDiscountScopeFilterObjectSchema),
        z.lazy(() => DiscountScopeSchema),
      ])
      .optional(),
    itemType: z
      .union([
        z.lazy(() => EnumDiscountItemTypeFilterObjectSchema),
        z.lazy(() => DiscountItemTypeSchema),
      ])
      .optional(),
    items: z.lazy(() => JsonNullableFilterObjectSchema).optional(),
    settingGroupId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    groupId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const DiscountSettingScalarWhereInputObjectSchema = Schema
