import { z } from 'zod'
import { ArticlePictureCreateWithoutArticleInputObjectSchema } from './ArticlePictureCreateWithoutArticleInput.schema'
import { ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema } from './ArticlePictureUncheckedCreateWithoutArticleInput.schema'
import { ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema } from './ArticlePictureCreateOrConnectWithoutArticleInput.schema'
import { ArticlePictureCreateManyArticleInputEnvelopeObjectSchema } from './ArticlePictureCreateManyArticleInputEnvelope.schema'
import { ArticlePictureWhereUniqueInputObjectSchema } from './ArticlePictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureCreateNestedManyWithoutArticleInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ArticlePictureCreateWithoutArticleInputObjectSchema),
          z
            .lazy(() => ArticlePictureCreateWithoutArticleInputObjectSchema)
            .array(),
          z.lazy(
            () => ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ArticlePictureCreateManyArticleInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ArticlePictureCreateNestedManyWithoutArticleInputObjectSchema =
  Schema
