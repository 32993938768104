import { z } from 'zod'
import { CaseSummaryCountOutputTypeSelectObjectSchema } from './CaseSummaryCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCountOutputTypeArgs> = z
  .object({
    select: z
      .lazy(() => CaseSummaryCountOutputTypeSelectObjectSchema)
      .optional(),
  })
  .strict()

export const CaseSummaryCountOutputTypeArgsObjectSchema = Schema
