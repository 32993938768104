import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemCreateWithoutRefundInputObjectSchema } from './ProductItemCreateWithoutRefundInput.schema'
import { ProductItemUncheckedCreateWithoutRefundInputObjectSchema } from './ProductItemUncheckedCreateWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemCreateOrConnectWithoutRefundInput> = z
  .object({
    where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductItemCreateWithoutRefundInputObjectSchema),
      z.lazy(() => ProductItemUncheckedCreateWithoutRefundInputObjectSchema),
    ]),
  })
  .strict()

export const ProductItemCreateOrConnectWithoutRefundInputObjectSchema = Schema
