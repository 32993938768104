import { z } from 'zod'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultCreateWithoutCaseInputObjectSchema } from './TeleconsultCreateWithoutCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultCreateOrConnectWithoutCaseInput> = z
  .object({
    where: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => TeleconsultCreateWithoutCaseInputObjectSchema),
      z.lazy(() => TeleconsultUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const TeleconsultCreateOrConnectWithoutCaseInputObjectSchema = Schema
