import { z } from 'zod'
import { CaseSummaryDiagnosisFindManySchema } from '../findManyCaseSummaryDiagnosis.schema'
import { DiagnosisCountOutputTypeArgsObjectSchema } from './DiagnosisCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisSelect> = z
  .object({
    id: z.boolean().optional(),
    codePlain: z.boolean().optional(),
    term: z.boolean().optional(),
    caseSummaryDiagnosis: z
      .union([z.boolean(), z.lazy(() => CaseSummaryDiagnosisFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => DiagnosisCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const DiagnosisSelectObjectSchema = Schema
