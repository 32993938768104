import { Box } from '@mui/material'
import get from 'lodash.get'
import { FC } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

export interface FormReferenceInputProps {
  source: string
  reference: string
  optionText: string[]
  label: string
}

export const FormReferenceInput: FC<FormReferenceInputProps> = ({
  source,
  reference,
  optionText,
  label,
}) => {
  const { watch } = useFormContext()
  const currentCategoryId = watch('id') ?? -1

  const optionTextRender = (choice: { [x: string]: string }) => {
    const showText = optionText.map((text) => get(choice, text).trim())
    return `${showText[0]} (${showText[1]})`
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <ReferenceInput
            source={source}
            reference={reference}
            sort={{ field: 'id', order: 'ASC' }}
            filter={{ parentId: null, id_notIn: [currentCategoryId] }}
          >
            <SelectInput
              fullWidth
              optionText={optionTextRender}
              label={label}
              sx={{ '& legend': { maxWidth: '100% !important', mb: 1 } }}
            />
          </ReferenceInput>
        </Box>
      </Box>
    </Box>
  )
}
