import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpBlacklistSelect> = z
  .object({
    id: z.boolean().optional(),
    ipAddress: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    expiredAt: z.boolean().optional(),
  })
  .strict()

export const OtpBlacklistSelectObjectSchema = Schema
