import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateWithoutKycDataInput> = z
  .object({
    createdAt: z.date().optional(),
    field: z.string(),
    reason: z.string(),
  })
  .strict()

export const RejectReasonCreateWithoutKycDataInputObjectSchema = Schema
