import { z } from 'zod'
import { SegmentCreateWithoutNotificationsInputObjectSchema } from './SegmentCreateWithoutNotificationsInput.schema'
import { SegmentUncheckedCreateWithoutNotificationsInputObjectSchema } from './SegmentUncheckedCreateWithoutNotificationsInput.schema'
import { SegmentCreateOrConnectWithoutNotificationsInputObjectSchema } from './SegmentCreateOrConnectWithoutNotificationsInput.schema'
import { SegmentWhereUniqueInputObjectSchema } from './SegmentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentCreateNestedManyWithoutNotificationsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema),
          z
            .lazy(() => SegmentCreateWithoutNotificationsInputObjectSchema)
            .array(),
          z.lazy(
            () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () => SegmentUncheckedCreateWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => SegmentCreateOrConnectWithoutNotificationsInputObjectSchema,
          ),
          z
            .lazy(
              () => SegmentCreateOrConnectWithoutNotificationsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => SegmentWhereUniqueInputObjectSchema),
          z.lazy(() => SegmentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const SegmentCreateNestedManyWithoutNotificationsInputObjectSchema =
  Schema
