import { z } from 'zod'
import { IdentityCreateInputObjectSchema } from './objects/IdentityCreateInput.schema'
import { IdentityUncheckedCreateInputObjectSchema } from './objects/IdentityUncheckedCreateInput.schema'

export const IdentityCreateOneSchema = z.object({
  data: z.union([
    IdentityCreateInputObjectSchema,
    IdentityUncheckedCreateInputObjectSchema,
  ]),
})
