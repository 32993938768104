import { z } from 'zod'
import { CouponGroupOrderByWithRelationInputObjectSchema } from './objects/CouponGroupOrderByWithRelationInput.schema'
import { CouponGroupWhereInputObjectSchema } from './objects/CouponGroupWhereInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './objects/CouponGroupWhereUniqueInput.schema'
import { CouponGroupScalarFieldEnumSchema } from './enums/CouponGroupScalarFieldEnum.schema'

export const CouponGroupFindManySchema = z.object({
  orderBy: z
    .union([
      CouponGroupOrderByWithRelationInputObjectSchema,
      CouponGroupOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CouponGroupWhereInputObjectSchema.optional(),
  cursor: CouponGroupWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CouponGroupScalarFieldEnumSchema).optional(),
})
