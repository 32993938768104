import { z } from 'zod'
import { TeleconsultWhereInputObjectSchema } from './TeleconsultWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultRelationFilter> = z
  .object({
    is: z
      .lazy(() => TeleconsultWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => TeleconsultWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const TeleconsultRelationFilterObjectSchema = Schema
