import { z } from 'zod'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisUpdateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUpdateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedUpdateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutDiagnosisInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisUpdateWithoutDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedUpdateWithoutDiagnosisInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedCreateWithoutDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryDiagnosisUpsertWithWhereUniqueWithoutDiagnosisInputObjectSchema =
  Schema
