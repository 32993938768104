import { z } from 'zod'
import {
  CouponModel,
  PaymentModel,
  ProcedureItemModelResponse,
  ProductItemModelResponse,
} from '../orders'

export const OrderPaidEventModel = z
  .object({
    orderId: z.number(),
    patientId: z.number(),
    coinAmount: z.number(),
    caseId: z.number().nullish(),
    orderNumber: z.string(),
    customerName: z.string(),
    customerTelNo: z.string(),
    shippingProviderId: z.number().nullable(),
    shippingAddress: z.string().nullish(),
    shippingFee: z.number().default(0),
    province: z.string().nullish(),
    district: z.string().nullish(),
    postcode: z.string().nullish(),
    coupons: z.array(CouponModel.pick({ id: true, code: true, type: true })),
    productItems: z.array(ProductItemModelResponse).nullish(),
    procedureItems: z
      .array(
        ProcedureItemModelResponse.pick({
          id: true,
          nameTr: true,
          procedureId: true,
          sellingPrice: true,
          expireType: true,
          expireAt: true,
          expireDurationDay: true,
          priceAbsorbedBySkinX: true,
          priceAbsorbedByPartner: true,
          commissionRate: true,
          quantity: true,
          partnerId: true,
        }).merge(z.object({ flashsalePrice: z.number().nullish() })),
      )
      .nullish(),
  })
  .merge(
    PaymentModel.pick({
      paymentMethod: true,
      installmentTerm: true,
    }),
  )
export type OrderPaidEventModel = z.infer<typeof OrderPaidEventModel>
