import { z } from 'zod'
import { KycDataArgsObjectSchema } from './KycDataArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    kycData: z
      .union([z.boolean(), z.lazy(() => KycDataArgsObjectSchema)])
      .optional(),
    kycDataId: z.boolean().optional(),
    field: z.boolean().optional(),
    reason: z.boolean().optional(),
  })
  .strict()

export const RejectReasonSelectObjectSchema = Schema
