import { z } from 'zod'
import { AcceptedConsentOrderByWithRelationInputObjectSchema } from './objects/AcceptedConsentOrderByWithRelationInput.schema'
import { AcceptedConsentWhereInputObjectSchema } from './objects/AcceptedConsentWhereInput.schema'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './objects/AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentScalarFieldEnumSchema } from './enums/AcceptedConsentScalarFieldEnum.schema'

export const AcceptedConsentFindManySchema = z.object({
  orderBy: z
    .union([
      AcceptedConsentOrderByWithRelationInputObjectSchema,
      AcceptedConsentOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: AcceptedConsentWhereInputObjectSchema.optional(),
  cursor: AcceptedConsentWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(AcceptedConsentScalarFieldEnumSchema).optional(),
})
