import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductCategoryCountOrderByAggregateInputObjectSchema } from './ProductCategoryCountOrderByAggregateInput.schema'
import { ProductCategoryAvgOrderByAggregateInputObjectSchema } from './ProductCategoryAvgOrderByAggregateInput.schema'
import { ProductCategoryMaxOrderByAggregateInputObjectSchema } from './ProductCategoryMaxOrderByAggregateInput.schema'
import { ProductCategoryMinOrderByAggregateInputObjectSchema } from './ProductCategoryMinOrderByAggregateInput.schema'
import { ProductCategorySumOrderByAggregateInputObjectSchema } from './ProductCategorySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    categoryId: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductCategoryCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductCategoryAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductCategoryMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductCategoryMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductCategorySumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductCategoryOrderByWithAggregationInputObjectSchema = Schema
