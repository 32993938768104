import { z } from 'zod'
import { NotificationPatientCreateWithoutPatientInputObjectSchema } from './NotificationPatientCreateWithoutPatientInput.schema'
import { NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutPatientInput.schema'
import { NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema } from './NotificationPatientCreateOrConnectWithoutPatientInput.schema'
import { NotificationPatientUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './NotificationPatientUpsertWithWhereUniqueWithoutPatientInput.schema'
import { NotificationPatientCreateManyPatientInputEnvelopeObjectSchema } from './NotificationPatientCreateManyPatientInputEnvelope.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './NotificationPatientUpdateWithWhereUniqueWithoutPatientInput.schema'
import { NotificationPatientUpdateManyWithWhereWithoutPatientInputObjectSchema } from './NotificationPatientUpdateManyWithWhereWithoutPatientInput.schema'
import { NotificationPatientScalarWhereInputObjectSchema } from './NotificationPatientScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUncheckedUpdateManyWithoutPatientNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => NotificationPatientCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationPatientCreateWithoutPatientInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUncheckedCreateWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientCreateOrConnectWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => NotificationPatientCreateManyPatientInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              NotificationPatientUpdateManyWithWhereWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationPatientUpdateManyWithWhereWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => NotificationPatientScalarWhereInputObjectSchema),
          z.lazy(() => NotificationPatientScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationPatientUncheckedUpdateManyWithoutPatientNestedInputObjectSchema =
  Schema
