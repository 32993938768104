import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumAuxiliaryLabelNullableFilterObjectSchema } from './EnumAuxiliaryLabelNullableFilter.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumProductTypeFilterObjectSchema } from './EnumProductTypeFilter.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { EnumDrugTypeNullableFilterObjectSchema } from './EnumDrugTypeNullableFilter.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { EnumSalesTypeFilterObjectSchema } from './EnumSalesTypeFilter.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { EnumUsageNullableFilterObjectSchema } from './EnumUsageNullableFilter.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { EnumUnitNullableFilterObjectSchema } from './EnumUnitNullableFilter.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { EnumUseFrequencyFilterObjectSchema } from './EnumUseFrequencyFilter.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseTimeFilterObjectSchema } from './EnumUseTimeFilter.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { BrandRelationFilterObjectSchema } from './BrandRelationFilter.schema'
import { BrandWhereInputObjectSchema } from './BrandWhereInput.schema'
import { ProductCategoryListRelationFilterObjectSchema } from './ProductCategoryListRelationFilter.schema'
import { VariantListRelationFilterObjectSchema } from './VariantListRelationFilter.schema'
import { ProductPictureListRelationFilterObjectSchema } from './ProductPictureListRelationFilter.schema'
import { ProductVideoListRelationFilterObjectSchema } from './ProductVideoListRelationFilter.schema'
import { ProductReviewListRelationFilterObjectSchema } from './ProductReviewListRelationFilter.schema'
import { ProductReviewSummaryRelationFilterObjectSchema } from './ProductReviewSummaryRelationFilter.schema'
import { ProductReviewSummaryWhereInputObjectSchema } from './ProductReviewSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductWhereInputObjectSchema),
        z.lazy(() => ProductWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductWhereInputObjectSchema),
        z.lazy(() => ProductWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    searchable: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    searchField: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    regNo: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    auxiliaryLabel: z
      .union([
        z.lazy(() => EnumAuxiliaryLabelNullableFilterObjectSchema),
        z.lazy(() => AuxiliaryLabelSchema),
      ])
      .optional()
      .nullable(),
    instructionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    sideEffectTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    cautionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    pharmacyNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    ingredient: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    type: z
      .union([
        z.lazy(() => EnumProductTypeFilterObjectSchema),
        z.lazy(() => ProductTypeSchema),
      ])
      .optional(),
    drugType: z
      .union([
        z.lazy(() => EnumDrugTypeNullableFilterObjectSchema),
        z.lazy(() => DrugTypeSchema),
      ])
      .optional()
      .nullable(),
    salesType: z
      .union([
        z.lazy(() => EnumSalesTypeFilterObjectSchema),
        z.lazy(() => SalesTypeSchema),
      ])
      .optional(),
    productForms: z.lazy(() => JsonFilterObjectSchema).optional(),
    skinType: z.lazy(() => JsonFilterObjectSchema).optional(),
    propertyTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    concerns: z.lazy(() => JsonFilterObjectSchema).optional(),
    attributes: z.lazy(() => JsonFilterObjectSchema).optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    usage: z
      .union([
        z.lazy(() => EnumUsageNullableFilterObjectSchema),
        z.lazy(() => UsageSchema),
      ])
      .optional()
      .nullable(),
    useAmount: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    useUnit: z
      .union([
        z.lazy(() => EnumUnitNullableFilterObjectSchema),
        z.lazy(() => UnitSchema),
      ])
      .optional()
      .nullable(),
    useFrequency: z
      .union([
        z.lazy(() => EnumUseFrequencyFilterObjectSchema),
        z.lazy(() => UseFrequencySchema),
      ])
      .optional(),
    useTime: z
      .union([
        z.lazy(() => EnumUseTimeFilterObjectSchema),
        z.lazy(() => UseTimeSchema),
      ])
      .optional(),
    brandId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    deletedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    v2DrugItemId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    brand: z
      .union([
        z.lazy(() => BrandRelationFilterObjectSchema),
        z.lazy(() => BrandWhereInputObjectSchema),
      ])
      .optional(),
    categories: z
      .lazy(() => ProductCategoryListRelationFilterObjectSchema)
      .optional(),
    variants: z.lazy(() => VariantListRelationFilterObjectSchema).optional(),
    pictures: z
      .lazy(() => ProductPictureListRelationFilterObjectSchema)
      .optional(),
    video: z.lazy(() => ProductVideoListRelationFilterObjectSchema).optional(),
    productReviews: z
      .lazy(() => ProductReviewListRelationFilterObjectSchema)
      .optional(),
    productReviewSummary: z
      .union([
        z.lazy(() => ProductReviewSummaryRelationFilterObjectSchema),
        z.lazy(() => ProductReviewSummaryWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ProductWhereInputObjectSchema = Schema
