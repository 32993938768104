import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemCreateWithoutVariantInputObjectSchema } from './FlashsaleItemCreateWithoutVariantInput.schema'
import { FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateOrConnectWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => FlashsaleItemCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemCreateOrConnectWithoutVariantInputObjectSchema =
  Schema
