import { z } from 'zod'
import { CoinTransactionStatusSchema } from '../enums/CoinTransactionStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumCoinTransactionStatusFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => CoinTransactionStatusSchema).optional(),
    })
    .strict()

export const EnumCoinTransactionStatusFieldUpdateOperationsInputObjectSchema =
  Schema
