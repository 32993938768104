import { z } from 'zod'
import { WarehouseWhereInputObjectSchema } from './objects/WarehouseWhereInput.schema'
import { WarehouseOrderByWithAggregationInputObjectSchema } from './objects/WarehouseOrderByWithAggregationInput.schema'
import { WarehouseScalarWhereWithAggregatesInputObjectSchema } from './objects/WarehouseScalarWhereWithAggregatesInput.schema'
import { WarehouseScalarFieldEnumSchema } from './enums/WarehouseScalarFieldEnum.schema'

export const WarehouseGroupBySchema = z.object({
  where: WarehouseWhereInputObjectSchema.optional(),
  orderBy: z.union([
    WarehouseOrderByWithAggregationInputObjectSchema,
    WarehouseOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: WarehouseScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(WarehouseScalarFieldEnumSchema),
})
