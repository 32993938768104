/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material'
import escapeHtml from 'escape-html'
import isHotkey, { KeyboardEventLike } from 'is-hotkey'
import { FC, useCallback, useEffect, useState } from 'react'
import { Descendant, Node as SlateNode, Text, createEditor } from 'slate'
import { jsx } from 'slate-hyperscript'
import { Editable, Slate, withReact } from 'slate-react'
import { withHistory } from 'slate-history'

import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Link,
} from '@mui/icons-material'

import { Element, Leaf } from './RenderComponent'
import {
  SlateToolbarBlockButton,
  SlateToolbarInsertLinkButton,
  SlateToolbarMarkButton,
  toggleMark,
} from './ToolbarComponent'

const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
}

const withInlines = (editor: any) => {
  const { insertText, isInline, isSelectable } = editor

  editor.isInline = (element: any) =>
    ['link', 'button', 'badge'].includes(element.type) || isInline(element)

  editor.isSelectable = (element: any) =>
    element.type !== 'badge' && isSelectable(element)

  editor.insertText = (text: any) => {
    insertText(text)
  }

  return editor
}

interface RichTextEditorProps {
  saveContent: (value: string) => void
  detailValue: string
}
const RichTextEditor: FC<RichTextEditorProps> = ({
  saveContent,
  detailValue,
}) => {
  const [editor] = useState(() =>
    withInlines(withHistory(withReact(createEditor()))),
  )
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])

  const [slateValue, setSlateValue] = useState<Descendant[]>([])

  useEffect(() => {
    const initialSlateValue: Descendant[] = []
    if (detailValue) {
      const htmlVal = new DOMParser().parseFromString(detailValue, 'text/html')
        .body.childNodes

      htmlVal.forEach((val) => {
        if (val.nodeType !== Node.TEXT_NODE || val.nodeValue?.trim() !== '') {
          initialSlateValue.push(deserializeHtml(val) as Descendant)
        }
      })
    }
    if (initialSlateValue.length === 0) {
      initialSlateValue.push(
        jsx('element', { type: 'paragraph' }, [jsx('text', {}, '')]),
      )
    }

    setSlateValue(initialSlateValue)
  }, [detailValue])

  if (slateValue.length === 0) {
    return null
  }

  return (
    <Slate
      editor={editor}
      value={slateValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op: any) => 'set_selection' !== op.type,
        )

        if (isAstChange) {
          const plainTextVal = serializePlainText(value)
          if (plainTextVal.replace(/(?:\r\n|\r|\n)/g, '').trim() === '') {
            saveContent('')
          } else {
            saveContent(
              serializeHtml({ children: value }).replace(/(?:\r\n|\r|\n)/g, ''),
            )
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          border: '1px solid #c4c4c4',
          borderRadius: '6px 6px 0 0',
          p: 1,
        }}
      >
        <SlateToolbarMarkButton format="bold" icon={<FormatBold />} />
        <SlateToolbarMarkButton format="italic" icon={<FormatItalic />} />
        <SlateToolbarMarkButton
          format="underline"
          icon={<FormatUnderlined />}
        />
        <SlateToolbarBlockButton
          format="numbered-list"
          icon={<FormatListNumbered />}
        />
        <SlateToolbarBlockButton
          format="bulleted-list"
          icon={<FormatListBulleted />}
        />
        <SlateToolbarInsertLinkButton icon={<Link />} />
      </Box>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        autoFocus
        placeholder="Enter post content…"
        style={{
          padding: '1rem',
          border: '1px solid #c4c4c4',
          borderRadius: '0 0 6px 6px',
          height: '450px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as KeyboardEventLike)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
      />
    </Slate>
  )
}

export default RichTextEditor

const serializePlainText = (nodes: SlateNode[]): string => {
  return nodes.map((n: SlateNode) => SlateNode.string(n)).join('\n')
}
const serializeHtml = (node: SlateNode) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    const nodeText = node as Text
    if (nodeText['bold' as keyof Text]) string = `<b>${string}</b>`
    if (nodeText['italic' as keyof Text]) string = `<i>${string}</i>`
    if (nodeText['underline' as keyof Text]) string = `<u>${string}</u>`

    return string
  }

  const children = node.children.map((n) => serializeHtml(n)).join('') as string

  switch (node['type' as keyof SlateNode]) {
    case 'link':
      return `<a href="${escapeHtml((node as any).url)}">${children}</a>`
    case 'paragraph':
      return `<div>${children}</div>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    default:
      return children
  }
}

const deserializeHtml = (
  el: ChildNode,
  markAttributes: Record<string, unknown> = {},
  isChildren = false,
): Descendant | Descendant[] => {
  if (el.nodeType === Node.TEXT_NODE) {
    if (isChildren) {
      return jsx('text', markAttributes, el.textContent)
    } else {
      return jsx('element', { type: 'paragraph' }, [
        jsx('text', markAttributes, el.textContent),
      ])
    }
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    throw new Error('Not supported HTMLNode')
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  if (['STRONG', 'B'].includes(el.nodeName)) {
    nodeAttributes.bold = true
  } else if (['EM', 'I'].includes(el.nodeName)) {
    nodeAttributes.italic = true
  } else if (['U'].includes(el.nodeName)) {
    nodeAttributes.underline = true
  }

  const children = Array.from(el.childNodes)
    .map((node) => {
      return deserializeHtml(node, nodeAttributes, true)
    })
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'BR':
      return jsx('element', { type: 'paragraph' }, [
        jsx('text', nodeAttributes, ''),
      ])
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: (el as any).getAttribute('href') },
        children,
      )
    case 'P':
    case 'DIV':
      return jsx('element', { type: 'paragraph' }, children)
    default:
      if (isChildren) {
        return children
      } else {
        return jsx('element', { type: 'paragraph' }, children)
      }
  }
}
