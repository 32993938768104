import { z } from 'zod'
import { BranchArgsObjectSchema } from './BranchArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    weekday: z.boolean().optional(),
    openAt: z.boolean().optional(),
    closeAt: z.boolean().optional(),
    timezone: z.boolean().optional(),
    branch: z
      .union([z.boolean(), z.lazy(() => BranchArgsObjectSchema)])
      .optional(),
    branchId: z.boolean().optional(),
  })
  .strict()

export const OfficeHourSelectObjectSchema = Schema
