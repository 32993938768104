import { z } from 'zod'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumExpireTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => ExpireTypeSchema).optional(),
  })
  .strict()

export const EnumExpireTypeFieldUpdateOperationsInputObjectSchema = Schema
