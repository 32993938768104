import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithoutProcedureInputObjectSchema } from './CarouselItemUpdateWithoutProcedureInput.schema'
import { CarouselItemUncheckedUpdateWithoutProcedureInputObjectSchema } from './CarouselItemUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpdateWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CarouselItemUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
