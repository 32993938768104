import { z } from 'zod'
import { RefundUpdateWithoutProcedureItemsInputObjectSchema } from './RefundUpdateWithoutProcedureItemsInput.schema'
import { RefundUncheckedUpdateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedUpdateWithoutProcedureItemsInput.schema'
import { RefundCreateWithoutProcedureItemsInputObjectSchema } from './RefundCreateWithoutProcedureItemsInput.schema'
import { RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpsertWithoutProcedureItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => RefundUpdateWithoutProcedureItemsInputObjectSchema),
      z.lazy(() => RefundUncheckedUpdateWithoutProcedureItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => RefundCreateWithoutProcedureItemsInputObjectSchema),
      z.lazy(() => RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema),
    ]),
  })
  .strict()

export const RefundUpsertWithoutProcedureItemsInputObjectSchema = Schema
