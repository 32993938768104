import { z } from 'zod'
import { ProductReviewPictureScalarWhereInputObjectSchema } from './ProductReviewPictureScalarWhereInput.schema'
import { ProductReviewPictureUpdateManyMutationInputObjectSchema } from './ProductReviewPictureUpdateManyMutationInput.schema'
import { ProductReviewPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema } from './ProductReviewPictureUncheckedUpdateManyWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewPictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductReviewPictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewPictureUpdateManyWithWhereWithoutProductReviewInputObjectSchema =
  Schema
