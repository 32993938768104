import { z } from 'zod'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'
import { NestedEnumPregnancyStateNullableWithAggregatesFilterObjectSchema } from './NestedEnumPregnancyStateNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumPregnancyStateNullableFilterObjectSchema } from './NestedEnumPregnancyStateNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumPregnancyStateNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => PregnancyStateSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => PregnancyStateSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => PregnancyStateSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => PregnancyStateSchema),
          z.lazy(
            () =>
              NestedEnumPregnancyStateNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumPregnancyStateNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumPregnancyStateNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const EnumPregnancyStateNullableWithAggregatesFilterObjectSchema = Schema
