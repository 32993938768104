import { z } from 'zod'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInputObjectSchema =
  Schema
