import { z } from 'zod'
import { AddressWhereUniqueInputObjectSchema } from './AddressWhereUniqueInput.schema'
import { AddressUpdateWithoutPatientInputObjectSchema } from './AddressUpdateWithoutPatientInput.schema'
import { AddressUncheckedUpdateWithoutPatientInputObjectSchema } from './AddressUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AddressWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => AddressUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => AddressUncheckedUpdateWithoutPatientInputObjectSchema),
      ]),
    })
    .strict()

export const AddressUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
