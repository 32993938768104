import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { FormDivider } from '../../../components/FormInput/divider'

interface ArticleFormHeaderProps {
  mode: 'CREATE' | 'EDIT'
}

export const ArticleFormHeader: FC<ArticleFormHeaderProps> = ({ mode }) => {
  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {mode === 'CREATE' ? 'Create new' : 'Update'} article
        </Typography>
      </Box>
      <FormDivider />
    </>
  )
}
