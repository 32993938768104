import { Typography } from '@mui/material'
import { FC } from 'react'
import { FormImageInput } from './formImageInput'
import { FormTextInput } from './formTextInput'

export const IdCardSection: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลบัตรประชาชน
      </Typography>
      <FormTextInput source="idCardNumber" label="เลขที่บัตรประจำตัวประชาชน" />
      <FormImageInput source="idCardPicture" label="รูปบัตรประจำตัวประชาชน" />
    </>
  )
}
