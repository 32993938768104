import { z } from 'zod'
import { PatientCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientCreateWithoutAcceptedConsentsInput.schema'
import { PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientUncheckedCreateWithoutAcceptedConsentsInput.schema'
import { PatientCreateOrConnectWithoutAcceptedConsentsInputObjectSchema } from './PatientCreateOrConnectWithoutAcceptedConsentsInput.schema'
import { PatientUpsertWithoutAcceptedConsentsInputObjectSchema } from './PatientUpsertWithoutAcceptedConsentsInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutAcceptedConsentsInputObjectSchema } from './PatientUpdateWithoutAcceptedConsentsInput.schema'
import { PatientUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema } from './PatientUncheckedUpdateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutAcceptedConsentsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutAcceptedConsentsInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => PatientCreateOrConnectWithoutAcceptedConsentsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutAcceptedConsentsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutAcceptedConsentsInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema =
  Schema
