import { z } from 'zod'
import { ProcedureOrderCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderCreateWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedCreateWithoutPartnerInput.schema'
import { ProcedureOrderCreateOrConnectWithoutPartnerInputObjectSchema } from './ProcedureOrderCreateOrConnectWithoutPartnerInput.schema'
import { ProcedureOrderCreateManyPartnerInputEnvelopeObjectSchema } from './ProcedureOrderCreateManyPartnerInputEnvelope.schema'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './ProcedureOrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderCreateNestedManyWithoutPartnerInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureOrderCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => ProcedureOrderCreateWithoutPartnerInputObjectSchema)
            .array(),
          z.lazy(
            () => ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProcedureOrderCreateOrConnectWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureOrderCreateOrConnectWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureOrderCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureOrderWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureOrderWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureOrderCreateNestedManyWithoutPartnerInputObjectSchema =
  Schema
