import * as z from "zod"
import { CompleteCaseSummaryDiagnosis, RelatedCaseSummaryDiagnosisModel } from "./index"

export const DiagnosisModel = z.object({
  id: z.number().int(),
  codePlain: z.string(),
  term: z.string(),
})

export interface CompleteDiagnosis extends z.infer<typeof DiagnosisModel> {
  caseSummaryDiagnosis: CompleteCaseSummaryDiagnosis[]
}

/**
 * RelatedDiagnosisModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDiagnosisModel: z.ZodSchema<CompleteDiagnosis> = z.lazy(() => DiagnosisModel.extend({
  caseSummaryDiagnosis: RelatedCaseSummaryDiagnosisModel.array(),
}))
