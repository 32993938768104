import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUpdateWithoutProcedureInput.schema'
import { ProcedureCategoryUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureCategoryUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureCategoryUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryUpdateWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
