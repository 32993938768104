import { z } from 'zod'
import { KycChannelSchema } from '../enums/KycChannel.schema'
import { KycStatusSchema } from '../enums/KycStatus.schema'
import { GenderSchema } from '../enums/Gender.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    patientId: z.number(),
    channel: z.lazy(() => KycChannelSchema),
    status: z.lazy(() => KycStatusSchema),
    approvedAt: z.date().optional().nullable(),
    approvedBy: z.number().optional().nullable(),
    prefix: z.string().optional().nullable(),
    firstname: z.string().optional().nullable(),
    lastname: z.string().optional().nullable(),
    gender: z
      .lazy(() => GenderSchema)
      .optional()
      .nullable(),
    birthDate: z.date().optional().nullable(),
    idCardNumber: z.string().optional().nullable(),
    idCardPicture: z.string().optional().nullable(),
    selfieIdCardPicture: z.string().optional().nullable(),
    passportNumber: z.string().optional().nullable(),
    passportPicture: z.string().optional().nullable(),
    selfiePassportPicture: z.string().optional().nullable(),
    rejectAt: z.date().optional().nullable(),
    rejectBy: z.number().optional().nullable(),
  })
  .strict()

export const KycDataCreateManyInputObjectSchema = Schema
