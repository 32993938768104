import { z } from 'zod'
import { ProcedureReviewPictureOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewPictureOrderByWithRelationInput.schema'
import { ProcedureReviewPictureWhereInputObjectSchema } from './objects/ProcedureReviewPictureWhereInput.schema'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './objects/ProcedureReviewPictureWhereUniqueInput.schema'
import { ProcedureReviewPictureCountAggregateInputObjectSchema } from './objects/ProcedureReviewPictureCountAggregateInput.schema'
import { ProcedureReviewPictureMinAggregateInputObjectSchema } from './objects/ProcedureReviewPictureMinAggregateInput.schema'
import { ProcedureReviewPictureMaxAggregateInputObjectSchema } from './objects/ProcedureReviewPictureMaxAggregateInput.schema'
import { ProcedureReviewPictureAvgAggregateInputObjectSchema } from './objects/ProcedureReviewPictureAvgAggregateInput.schema'
import { ProcedureReviewPictureSumAggregateInputObjectSchema } from './objects/ProcedureReviewPictureSumAggregateInput.schema'

export const ProcedureReviewPictureAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewPictureOrderByWithRelationInputObjectSchema,
      ProcedureReviewPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewPictureWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ProcedureReviewPictureCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ProcedureReviewPictureMinAggregateInputObjectSchema.optional(),
  _max: ProcedureReviewPictureMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureReviewPictureAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureReviewPictureSumAggregateInputObjectSchema.optional(),
})
