import { z } from 'zod'

export const ShippingProviderScalarFieldEnumSchema = z.enum([
  'id',
  'priority',
  'name',
  'detail',
  'moreDetail',
  'shippingExpectation',
  'trackingUrl',
  'type',
  'price',
  'freeShippingMinimumPrice',
  'active',
])
