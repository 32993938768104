/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaultTheme } from 'react-admin'

export const theme = {
  ...defaultTheme,
  // palette: {
  //   primary: {
  //     main: '#e59f90',
  //   },
  //   secondary: {
  //     main: '#5BC0BE',
  //   },
  // },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const,
        size: 'small' as const,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    RaLoadingIndicator: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
}
