import { z } from 'zod'
import { PatientCreateWithoutAddressInputObjectSchema } from './PatientCreateWithoutAddressInput.schema'
import { PatientUncheckedCreateWithoutAddressInputObjectSchema } from './PatientUncheckedCreateWithoutAddressInput.schema'
import { PatientCreateOrConnectWithoutAddressInputObjectSchema } from './PatientCreateOrConnectWithoutAddressInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateNestedOneWithoutAddressInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PatientCreateWithoutAddressInputObjectSchema),
        z.lazy(() => PatientUncheckedCreateWithoutAddressInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PatientCreateOrConnectWithoutAddressInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PatientCreateNestedOneWithoutAddressInputObjectSchema = Schema
