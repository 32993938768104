import { z } from 'zod'
import { RejectReasonUpdateInputObjectSchema } from './objects/RejectReasonUpdateInput.schema'
import { RejectReasonUncheckedUpdateInputObjectSchema } from './objects/RejectReasonUncheckedUpdateInput.schema'
import { RejectReasonWhereUniqueInputObjectSchema } from './objects/RejectReasonWhereUniqueInput.schema'

export const RejectReasonUpdateOneSchema = z.object({
  data: z.union([
    RejectReasonUpdateInputObjectSchema,
    RejectReasonUncheckedUpdateInputObjectSchema,
  ]),
  where: RejectReasonWhereUniqueInputObjectSchema,
})
