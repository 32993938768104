import { z } from 'zod'
import { ProductUpdateWithoutVideoInputObjectSchema } from './ProductUpdateWithoutVideoInput.schema'
import { ProductUncheckedUpdateWithoutVideoInputObjectSchema } from './ProductUncheckedUpdateWithoutVideoInput.schema'
import { ProductCreateWithoutVideoInputObjectSchema } from './ProductCreateWithoutVideoInput.schema'
import { ProductUncheckedCreateWithoutVideoInputObjectSchema } from './ProductUncheckedCreateWithoutVideoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutVideoInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductUpdateWithoutVideoInputObjectSchema),
      z.lazy(() => ProductUncheckedUpdateWithoutVideoInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProductCreateWithoutVideoInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutVideoInputObjectSchema),
    ]),
  })
  .strict()

export const ProductUpsertWithoutVideoInputObjectSchema = Schema
