import { z } from 'zod'
import { ProductItemOriginalWhereInputObjectSchema } from './objects/ProductItemOriginalWhereInput.schema'
import { ProductItemOriginalOrderByWithAggregationInputObjectSchema } from './objects/ProductItemOriginalOrderByWithAggregationInput.schema'
import { ProductItemOriginalScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductItemOriginalScalarWhereWithAggregatesInput.schema'
import { ProductItemOriginalScalarFieldEnumSchema } from './enums/ProductItemOriginalScalarFieldEnum.schema'

export const ProductItemOriginalGroupBySchema = z.object({
  where: ProductItemOriginalWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductItemOriginalOrderByWithAggregationInputObjectSchema,
    ProductItemOriginalOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProductItemOriginalScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductItemOriginalScalarFieldEnumSchema),
})
