import * as z from "zod"
import { CompleteBrand, RelatedBrandModel } from "./index"

export const BrandBannerModel = z.object({
  id: z.number().int(),
  url: z.string(),
  rank: z.number().int(),
  brandId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteBrandBanner extends z.infer<typeof BrandBannerModel> {
  brand: CompleteBrand
}

/**
 * RelatedBrandBannerModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBrandBannerModel: z.ZodSchema<CompleteBrandBanner> = z.lazy(() => BrandBannerModel.extend({
  brand: RelatedBrandModel,
}))
