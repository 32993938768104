import { z } from 'zod'
import { CaseCreateWithoutPicturesInputObjectSchema } from './CaseCreateWithoutPicturesInput.schema'
import { CaseUncheckedCreateWithoutPicturesInputObjectSchema } from './CaseUncheckedCreateWithoutPicturesInput.schema'
import { CaseCreateOrConnectWithoutPicturesInputObjectSchema } from './CaseCreateOrConnectWithoutPicturesInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutPicturesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutPicturesInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutPicturesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutPicturesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CaseCreateNestedOneWithoutPicturesInputObjectSchema = Schema
