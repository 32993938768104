import { SimCardDownloadRounded as SimCardDownloadRoundedIcon } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { FC } from 'react'
import {
  BooleanInput,
  DateTimeInput,
  FileField,
  FileInput,
  required,
  useTranslate,
} from 'react-admin'
import { CustomTextInput } from '../../../components/CustomInput'

export const FlashsaleProductImportForm: FC<{
  mode: 'CREATE' | 'UPDATE'
}> = ({ mode }) => {
  const translate = useTranslate()
  const isUpdateMode = mode === 'UPDATE' ? true : false
  const dateTimeValidation = (
    value: Date,
    allValues: { startAt: Date | undefined; endAt: Date | undefined },
  ) => {
    if (!allValues.startAt || !allValues.endAt) {
      return undefined
    }
    if (allValues.startAt >= allValues.endAt) {
      return 'Start date must be set ahead of End date'
    } else {
      return undefined
    }
  }

  return (
    <Grid container spacing={1} overflow="hidden">
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="startAt"
          validate={[required(), dateTimeValidation]}
          sx={{ width: '100%' }}
          disabled={isUpdateMode}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateTimeInput
          source="endAt"
          validate={[required(), dateTimeValidation]}
          sx={{ width: '100%' }}
          disabled={isUpdateMode}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          source="name"
          fullWidth
          multiline
          validate={required()}
          disabled={isUpdateMode}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          source="description"
          fullWidth
          multiline
          disabled={isUpdateMode}
        />
      </Grid>
      <Grid item xs={12}>
        <FileInput
          label={translate('resources.flashsaleProduct.action.upload_file')}
          source="flashsaleItems"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          endIcon={<SimCardDownloadRoundedIcon />}
          size="small"
          href="https://docs.google.com/spreadsheets/d/1t1G6dmMNXxoTyBRc3jnHaTj6zCtPMRAFemqAf6awkbU/edit#gid=0"
          target="_blank"
        >
          {translate(
            'resources.flashsaleProduct.action.download_import_template',
          )}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          width: '100%',
          justifyContent: 'end',
        }}
        display={isUpdateMode ? 'flex' : 'none'}
      >
        <BooleanInput source="replace" />
      </Grid>
    </Grid>
  )
}
