import { z } from 'zod'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './objects/ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewCreateInputObjectSchema } from './objects/ProcedureReviewCreateInput.schema'
import { ProcedureReviewUncheckedCreateInputObjectSchema } from './objects/ProcedureReviewUncheckedCreateInput.schema'
import { ProcedureReviewUpdateInputObjectSchema } from './objects/ProcedureReviewUpdateInput.schema'
import { ProcedureReviewUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewUncheckedUpdateInput.schema'

export const ProcedureReviewUpsertSchema = z.object({
  where: ProcedureReviewWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureReviewCreateInputObjectSchema,
    ProcedureReviewUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureReviewUpdateInputObjectSchema,
    ProcedureReviewUncheckedUpdateInputObjectSchema,
  ]),
})
