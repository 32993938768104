import { z } from 'zod'
import { ProcedureReviewOrderByWithRelationInputObjectSchema } from './objects/ProcedureReviewOrderByWithRelationInput.schema'
import { ProcedureReviewWhereInputObjectSchema } from './objects/ProcedureReviewWhereInput.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './objects/ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewScalarFieldEnumSchema } from './enums/ProcedureReviewScalarFieldEnum.schema'

export const ProcedureReviewFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProcedureReviewOrderByWithRelationInputObjectSchema,
      ProcedureReviewOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureReviewWhereInputObjectSchema.optional(),
  cursor: ProcedureReviewWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProcedureReviewScalarFieldEnumSchema).optional(),
})
