import { z } from 'zod'
import { CoverPictureSelectObjectSchema } from './CoverPictureSelect.schema'
import { CoverPictureIncludeObjectSchema } from './CoverPictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureArgs> = z
  .object({
    select: z.lazy(() => CoverPictureSelectObjectSchema).optional(),
    include: z.lazy(() => CoverPictureIncludeObjectSchema).optional(),
  })
  .strict()

export const CoverPictureArgsObjectSchema = Schema
