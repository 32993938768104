import { z } from 'zod'
import { IdentityCreateManyPatientInputObjectSchema } from './IdentityCreateManyPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityCreateManyPatientInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => IdentityCreateManyPatientInputObjectSchema),
      z.lazy(() => IdentityCreateManyPatientInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const IdentityCreateManyPatientInputEnvelopeObjectSchema = Schema
