import { z } from 'zod'
import { DoctorItemOrderByWithRelationInputObjectSchema } from './objects/DoctorItemOrderByWithRelationInput.schema'
import { DoctorItemWhereInputObjectSchema } from './objects/DoctorItemWhereInput.schema'
import { DoctorItemWhereUniqueInputObjectSchema } from './objects/DoctorItemWhereUniqueInput.schema'
import { DoctorItemScalarFieldEnumSchema } from './enums/DoctorItemScalarFieldEnum.schema'

export const DoctorItemFindFirstSchema = z.object({
  orderBy: z
    .union([
      DoctorItemOrderByWithRelationInputObjectSchema,
      DoctorItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DoctorItemWhereInputObjectSchema.optional(),
  cursor: DoctorItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(DoctorItemScalarFieldEnumSchema).optional(),
})
