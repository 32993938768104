import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { ProductItemUncheckedCreateNestedManyWithoutRefundInputObjectSchema } from './ProductItemUncheckedCreateNestedManyWithoutRefundInput.schema'
import { ProcedureItemUncheckedCreateNestedManyWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedCreateNestedManyWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    amount: z.number(),
    currency: z.string(),
    refundAt: z.date(),
    paymentGateway: z.lazy(() => PaymentGatewaySchema),
    status: z.lazy(() => RefundStatusSchema),
    reason: z.string(),
    orderId: z.number(),
    productItems: z
      .lazy(
        () =>
          ProductItemUncheckedCreateNestedManyWithoutRefundInputObjectSchema,
      )
      .optional(),
    procedureItems: z
      .lazy(
        () =>
          ProcedureItemUncheckedCreateNestedManyWithoutRefundInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const RefundUncheckedCreateInputObjectSchema = Schema
