import { z } from 'zod'
import { ProductItemOrderByWithRelationInputObjectSchema } from './objects/ProductItemOrderByWithRelationInput.schema'
import { ProductItemWhereInputObjectSchema } from './objects/ProductItemWhereInput.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './objects/ProductItemWhereUniqueInput.schema'
import { ProductItemScalarFieldEnumSchema } from './enums/ProductItemScalarFieldEnum.schema'

export const ProductItemFindManySchema = z.object({
  orderBy: z
    .union([
      ProductItemOrderByWithRelationInputObjectSchema,
      ProductItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductItemWhereInputObjectSchema.optional(),
  cursor: ProductItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductItemScalarFieldEnumSchema).optional(),
})
