import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryMaxOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    presentIllness: z.lazy(() => SortOrderSchema).optional(),
    peNote: z.lazy(() => SortOrderSchema).optional(),
    isTeleMedNotRecommend: z.lazy(() => SortOrderSchema).optional(),
    recommendation: z.lazy(() => SortOrderSchema).optional(),
    doctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    commissionPrice: z.lazy(() => SortOrderSchema).optional(),
    netDoctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorNote: z.lazy(() => SortOrderSchema).optional(),
    pharmacistNote: z.lazy(() => SortOrderSchema).optional(),
    followUpAppointmentId: z.lazy(() => SortOrderSchema).optional(),
    recommedNumberOfTreatment: z.lazy(() => SortOrderSchema).optional(),
    additionalSuggestedProcedure: z.lazy(() => SortOrderSchema).optional(),
    editedSuggestedProductItemAt: z.lazy(() => SortOrderSchema).optional(),
    editedSuggestedProductItemBy: z.lazy(() => SortOrderSchema).optional(),
    v2CaseSummaryId: z.lazy(() => SortOrderSchema).optional(),
    caseUrl: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const CaseSummaryMaxOrderByAggregateInputObjectSchema = Schema
