import { z } from 'zod'
import { ProcedureModel, ProcedurePictureModel } from '../generated/zod'

export const ProcedureSchema = ProcedureModel.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  searchField: true,
}).extend({
  active: z.boolean().default(false),
  searchable: z.boolean().default(true),
  priority: z.number().default(0),
  categories: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  pictures: z.array(
    ProcedurePictureModel.omit({
      id: true,
      procedureId: true,
      createdAt: true,
      updatedAt: true,
    }),
  ),
})

export type ProcedureType = z.infer<typeof ProcedureSchema>
