import { z } from 'zod'
import { OrderCreateWithoutRefundsInputObjectSchema } from './OrderCreateWithoutRefundsInput.schema'
import { OrderUncheckedCreateWithoutRefundsInputObjectSchema } from './OrderUncheckedCreateWithoutRefundsInput.schema'
import { OrderCreateOrConnectWithoutRefundsInputObjectSchema } from './OrderCreateOrConnectWithoutRefundsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutRefundsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutRefundsInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutRefundsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutRefundsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutRefundsInputObjectSchema = Schema
