import { z } from 'zod'
import { CategoryScalarWhereInputObjectSchema } from './CategoryScalarWhereInput.schema'
import { CategoryUpdateManyMutationInputObjectSchema } from './CategoryUpdateManyMutationInput.schema'
import { CategoryUncheckedUpdateManyWithoutChildrenInputObjectSchema } from './CategoryUncheckedUpdateManyWithoutChildrenInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryUpdateManyWithWhereWithoutParentInput> =
  z
    .object({
      where: z.lazy(() => CategoryScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CategoryUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => CategoryUncheckedUpdateManyWithoutChildrenInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CategoryUpdateManyWithWhereWithoutParentInputObjectSchema = Schema
