import { z } from 'zod'
import { RefundCountOutputTypeSelectObjectSchema } from './RefundCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => RefundCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const RefundCountOutputTypeArgsObjectSchema = Schema
