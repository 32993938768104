import { z } from 'zod'
import { ArticlePictureWhereUniqueInputObjectSchema } from './objects/ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureCreateInputObjectSchema } from './objects/ArticlePictureCreateInput.schema'
import { ArticlePictureUncheckedCreateInputObjectSchema } from './objects/ArticlePictureUncheckedCreateInput.schema'
import { ArticlePictureUpdateInputObjectSchema } from './objects/ArticlePictureUpdateInput.schema'
import { ArticlePictureUncheckedUpdateInputObjectSchema } from './objects/ArticlePictureUncheckedUpdateInput.schema'

export const ArticlePictureUpsertSchema = z.object({
  where: ArticlePictureWhereUniqueInputObjectSchema,
  create: z.union([
    ArticlePictureCreateInputObjectSchema,
    ArticlePictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ArticlePictureUpdateInputObjectSchema,
    ArticlePictureUncheckedUpdateInputObjectSchema,
  ]),
})
