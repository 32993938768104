import { z } from 'zod'
import { ExpressShippingAreaSelectObjectSchema } from './ExpressShippingAreaSelect.schema'
import { ExpressShippingAreaIncludeObjectSchema } from './ExpressShippingAreaInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaArgs> = z
  .object({
    select: z.lazy(() => ExpressShippingAreaSelectObjectSchema).optional(),
    include: z.lazy(() => ExpressShippingAreaIncludeObjectSchema).optional(),
  })
  .strict()

export const ExpressShippingAreaArgsObjectSchema = Schema
