import * as z from "zod"
import { CompleteAcceptedConsent, RelatedAcceptedConsentModel } from "./index"

export const ConsentModel = z.object({
  key: z.string(),
  detail: z.string(),
  rejectDetail: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteConsent extends z.infer<typeof ConsentModel> {
  acceptedConsents: CompleteAcceptedConsent[]
}

/**
 * RelatedConsentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedConsentModel: z.ZodSchema<CompleteConsent> = z.lazy(() => ConsentModel.extend({
  acceptedConsents: RelatedAcceptedConsentModel.array(),
}))
