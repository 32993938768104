import { z } from 'zod'
import { ProcedureReviewSelectObjectSchema } from './ProcedureReviewSelect.schema'
import { ProcedureReviewIncludeObjectSchema } from './ProcedureReviewInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewArgs> = z
  .object({
    select: z.lazy(() => ProcedureReviewSelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureReviewIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewArgsObjectSchema = Schema
