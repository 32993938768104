import { z } from 'zod'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'
import { BrandCreateWithoutProductReviewsInputObjectSchema } from './BrandCreateWithoutProductReviewsInput.schema'
import { BrandUncheckedCreateWithoutProductReviewsInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateOrConnectWithoutProductReviewsInput> =
  z
    .object({
      where: z.lazy(() => BrandWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => BrandCreateWithoutProductReviewsInputObjectSchema),
        z.lazy(
          () => BrandUncheckedCreateWithoutProductReviewsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const BrandCreateOrConnectWithoutProductReviewsInputObjectSchema = Schema
