import { z } from 'zod'
import { PartnerUpdateInputObjectSchema } from './objects/PartnerUpdateInput.schema'
import { PartnerUncheckedUpdateInputObjectSchema } from './objects/PartnerUncheckedUpdateInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './objects/PartnerWhereUniqueInput.schema'

export const PartnerUpdateOneSchema = z.object({
  data: z.union([
    PartnerUpdateInputObjectSchema,
    PartnerUncheckedUpdateInputObjectSchema,
  ]),
  where: PartnerWhereUniqueInputObjectSchema,
})
