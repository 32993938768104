import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    diagnosisId: z.number(),
    caseSummaryId: z.number(),
  })
  .strict()

export const CaseSummaryDiagnosisCreateManyInputObjectSchema = Schema
