import { z } from 'zod'
import { ReceiptWhereInputObjectSchema } from './ReceiptWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptRelationFilter> = z
  .object({
    is: z
      .lazy(() => ReceiptWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => ReceiptWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const ReceiptRelationFilterObjectSchema = Schema
