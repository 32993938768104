import { Box, Grid, Typography } from '@mui/material'
import { SymptomDurationUnitSchema } from '@ssch-backend/teleconsults'
import { FC } from 'react'
import { required, useLocaleState } from 'react-admin'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { getSymptomDurationUnitLabel } from '../../../enumLabel'

export const CaseDetail: FC = () => {
  const [locale] = useLocaleState()

  return (
    <Box sx={{ mb: 3, width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Case Detail
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="อาการ"
            source="case.symptom"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ประวัติการแพ้ยา/อาหาร"
            source="case.drugAllergy"
            multiline
            rows={3}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="โรคประจำตัว"
            source="case.congenitalDisease"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormNumberInput
              label="มีอาการมาแล้ว (จำนวน)"
              source="case.symptomDuration"
              sx={{ flexGrow: 1 }}
              validate={required()}
            />
            <FormSelectInput
              label="มีอาการมาแล้ว (หน่วย)"
              source="case.symptomDurationUnit"
              choices={Object.keys(SymptomDurationUnitSchema.Enum).map(
                (key: unknown) => {
                  return {
                    id: key,
                    name: getSymptomDurationUnitLabel(
                      key as keyof typeof SymptomDurationUnitSchema.Enum,
                    )[locale === 'th' ? 'th' : 'en'],
                  }
                },
              )}
              validate={required()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
