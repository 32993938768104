import { z } from 'zod'
import { ProductReviewCreateWithoutVariantInputObjectSchema } from './ProductReviewCreateWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateWithoutVariantInput.schema'
import { ProductReviewCreateOrConnectWithoutVariantInputObjectSchema } from './ProductReviewCreateOrConnectWithoutVariantInput.schema'
import { ProductReviewCreateManyVariantInputEnvelopeObjectSchema } from './ProductReviewCreateManyVariantInputEnvelope.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUncheckedCreateNestedManyWithoutVariantInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductReviewCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductReviewCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewUncheckedCreateNestedManyWithoutVariantInputObjectSchema =
  Schema
