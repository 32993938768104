import { z } from 'zod'
import { ProductVideoWhereInputObjectSchema } from './objects/ProductVideoWhereInput.schema'
import { ProductVideoOrderByWithAggregationInputObjectSchema } from './objects/ProductVideoOrderByWithAggregationInput.schema'
import { ProductVideoScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductVideoScalarWhereWithAggregatesInput.schema'
import { ProductVideoScalarFieldEnumSchema } from './enums/ProductVideoScalarFieldEnum.schema'

export const ProductVideoGroupBySchema = z.object({
  where: ProductVideoWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductVideoOrderByWithAggregationInputObjectSchema,
    ProductVideoOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductVideoScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductVideoScalarFieldEnumSchema),
})
