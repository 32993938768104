import { z } from 'zod'
import { DeliverySelectObjectSchema } from './DeliverySelect.schema'
import { DeliveryIncludeObjectSchema } from './DeliveryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryArgs> = z
  .object({
    select: z.lazy(() => DeliverySelectObjectSchema).optional(),
    include: z.lazy(() => DeliveryIncludeObjectSchema).optional(),
  })
  .strict()

export const DeliveryArgsObjectSchema = Schema
