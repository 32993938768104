import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProductCreateManyInput> = z
  .object({
    id: z.number().optional(),
    active: z.boolean().optional(),
    searchable: z.boolean().optional(),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    searchField: z.string(),
    regNo: z.string(),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    auxiliaryLabel: z
      .lazy(() => AuxiliaryLabelSchema)
      .optional()
      .nullable(),
    instructionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    sideEffectTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    cautionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    pharmacyNote: z.string().optional().nullable(),
    ingredient: z.string().optional().nullable(),
    type: z.lazy(() => ProductTypeSchema),
    drugType: z
      .lazy(() => DrugTypeSchema)
      .optional()
      .nullable(),
    salesType: z.lazy(() => SalesTypeSchema),
    productForms: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    skinType: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    propertyTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    concerns: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    attributes: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
    priority: z.number().optional(),
    usage: z
      .lazy(() => UsageSchema)
      .optional()
      .nullable(),
    useAmount: z.number().optional().nullable(),
    useUnit: z
      .lazy(() => UnitSchema)
      .optional()
      .nullable(),
    useFrequency: z.lazy(() => UseFrequencySchema),
    useTime: z.lazy(() => UseTimeSchema),
    brandId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    deletedAt: z.date().optional().nullable(),
    v2DrugItemId: z.number().optional().nullable(),
  })
  .strict()

export const ProductCreateManyInputObjectSchema = Schema
