import { z } from 'zod'
import { CouponGroupCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutConditionSettingInput.schema'
import { CouponGroupCreateOrConnectWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateOrConnectWithoutConditionSettingInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCreateNestedOneWithoutConditionSettingInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CouponGroupCreateWithoutConditionSettingInputObjectSchema,
          ),
          z.lazy(
            () =>
              CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CouponGroupCreateOrConnectWithoutConditionSettingInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CouponGroupCreateNestedOneWithoutConditionSettingInputObjectSchema =
  Schema
