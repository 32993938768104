import { z } from 'zod'
import {
  CouponModelResponse,
  DoctorItemModel,
  OrderModel,
  PaymentModel,
  ProcedureItemModelResponse,
  ProductItemModelResponse,
} from '../orders'

export const CreateOrderReceiptModel = OrderModel.extend({
  doctorItem: DoctorItemModel.nullable(),
  productItems: z.array(ProductItemModelResponse),
  procedureItems: z.array(ProcedureItemModelResponse),
  coupons: z.array(CouponModelResponse),
  payments: z.array(
    PaymentModel.pick({
      id: true,
      paymentMethod: true,
      installmentTerm: true,
      paymentAt: true,
      amount: true,
    }),
  ),
})
export type CreateOrderReceiptModel = z.infer<typeof CreateOrderReceiptModel>
