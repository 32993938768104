import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptCountAggregateInputType> = z
  .object({
    receiptNumber: z.literal(true).optional(),
    receiptUrl: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    receiptData: z.literal(true).optional(),
    isValid: z.literal(true).optional(),
    note: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ReceiptCountAggregateInputObjectSchema = Schema
