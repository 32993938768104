import { z } from 'zod'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    status: z.lazy(() => OrderStatusSchema).optional(),
    expiredAt: z.date().optional().nullable(),
    shouldExpiresAt: z.date().optional().nullable(),
    orderNumber: z.string(),
    patientId: z.number(),
    customerName: z.string(),
    customerTelNo: z.string().optional(),
    orderType: z.lazy(() => OrderTypeSchema),
    purchaseOrigin: z
      .lazy(() => PurchaseOriginSchema)
      .optional()
      .nullable(),
    purchaseRef: z.string().optional().nullable(),
    editedProductItemsAt: z.date().optional().nullable(),
    editedProductItemsBy: z.number().optional().nullable(),
    shippingFee: z.number().optional(),
    shippingFeeDiscount: z.number().optional(),
    shippingType: z
      .lazy(() => ShippingTypeSchema)
      .optional()
      .nullable(),
    shippingProviderId: z.number().optional().nullable(),
    recipient: z.string().optional().nullable(),
    shippingAddress: z.string().optional().nullable(),
    shippingTelNo: z.string().optional().nullable(),
    subDistrict: z.string().optional().nullable(),
    subDistrictKey: z.string().optional().nullable(),
    district: z.string().optional().nullable(),
    districtKey: z.string().optional().nullable(),
    province: z.string().optional().nullable(),
    provinceKey: z.string().optional().nullable(),
    postcode: z.string().optional().nullable(),
    isPaid: z.boolean().optional(),
    isHold: z.boolean().optional(),
    approvedBy: z.number().optional().nullable(),
    approvedAt: z.date().optional().nullable(),
    canceledBy: z.number().optional().nullable(),
    canceledAt: z.date().optional().nullable(),
    cancelChannel: z
      .lazy(() => CancelChannelSchema)
      .optional()
      .nullable(),
    noteToPatient: z.string().optional().nullable(),
    useCoin: z.number().optional(),
    v2InvoiceId: z.number().optional().nullable(),
    holdingNote: z.string().optional().nullable(),
    holdingBy: z.number().optional().nullable(),
    holdingAt: z.date().optional().nullable(),
    isDeclinedSuggestedItems: z.boolean().optional(),
    declinedSuggestedItemsReason: z
      .lazy(() => DeclinedSuggestedItemsReasonSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const OrderCreateManyInputObjectSchema = Schema
