import { z } from 'zod'
import { ArticlePictureCreateWithoutArticleInputObjectSchema } from './ArticlePictureCreateWithoutArticleInput.schema'
import { ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema } from './ArticlePictureUncheckedCreateWithoutArticleInput.schema'
import { ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema } from './ArticlePictureCreateOrConnectWithoutArticleInput.schema'
import { ArticlePictureUpsertWithWhereUniqueWithoutArticleInputObjectSchema } from './ArticlePictureUpsertWithWhereUniqueWithoutArticleInput.schema'
import { ArticlePictureCreateManyArticleInputEnvelopeObjectSchema } from './ArticlePictureCreateManyArticleInputEnvelope.schema'
import { ArticlePictureWhereUniqueInputObjectSchema } from './ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureUpdateWithWhereUniqueWithoutArticleInputObjectSchema } from './ArticlePictureUpdateWithWhereUniqueWithoutArticleInput.schema'
import { ArticlePictureUpdateManyWithWhereWithoutArticleInputObjectSchema } from './ArticlePictureUpdateManyWithWhereWithoutArticleInput.schema'
import { ArticlePictureScalarWhereInputObjectSchema } from './ArticlePictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureUpdateManyWithoutArticleNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ArticlePictureCreateWithoutArticleInputObjectSchema),
          z
            .lazy(() => ArticlePictureCreateWithoutArticleInputObjectSchema)
            .array(),
          z.lazy(
            () => ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureUncheckedCreateWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureCreateOrConnectWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ArticlePictureUpsertWithWhereUniqueWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureUpsertWithWhereUniqueWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ArticlePictureCreateManyArticleInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
          z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ArticlePictureUpdateWithWhereUniqueWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureUpdateWithWhereUniqueWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ArticlePictureUpdateManyWithWhereWithoutArticleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ArticlePictureUpdateManyWithWhereWithoutArticleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ArticlePictureScalarWhereInputObjectSchema),
          z.lazy(() => ArticlePictureScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ArticlePictureUpdateManyWithoutArticleNestedInputObjectSchema =
  Schema
