import ErrorIcon from '@mui/icons-material/Error'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import { CircularProgress, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useTranslate } from 'ra-core'
import { FC, ReactNode } from 'react'
import { CircularProgressWithLabel } from './ProgressWithLabel'
import { Progress, ProgressStatus } from './types'

export interface FileStorageInputPreviewContainerProps {
  children: ReactNode
  className?: string
  onRemove: () => void
  progress?: Progress
}

export const FileStorageInputPreviewContainer: FC<
  FileStorageInputPreviewContainerProps
> = (props) => {
  const { children, className, onRemove, progress, ...rest } = props

  const translate = useTranslate()

  return (
    <Root className={className} {...rest}>
      {progress?.status === ProgressStatus.loading ? (
        <div className={FileInputPreviewClasses.overlay}>
          {typeof progress?.progress === 'number' ? (
            <CircularProgressWithLabel
              value={Math.round(progress.progress * 100)}
            />
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : null}

      {progress?.status === ProgressStatus.error ? (
        <div
          className={clsx(
            FileInputPreviewClasses.overlay,
            FileInputPreviewClasses.uploadError,
          )}
        >
          <ErrorIcon />
          <Typography variant="body2">{translate('upload.error')}</Typography>
        </div>
      ) : null}
      <IconButton
        className={FileInputPreviewClasses.removeButton}
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
        size="small"
      >
        <RemoveCircle className={FileInputPreviewClasses.removeIcon} />
      </IconButton>
      {children}
    </Root>
  )
}

const PREFIX = 'RaFileInputPreview'

const FileInputPreviewClasses = {
  removeButton: `${PREFIX}-removeButton`,
  removeIcon: `${PREFIX}-removeIcon`,
  overlay: `${PREFIX}-overlay`,
  uploadError: `${PREFIX}-upload-error`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  overflow: 'hidden',
  [`& .${FileInputPreviewClasses.removeButton}`]: {},

  [`& .${FileInputPreviewClasses.removeIcon}`]: {
    color: theme.palette.error.main,
  },
  [`& .${FileInputPreviewClasses.overlay}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  [`& .${FileInputPreviewClasses.uploadError}`]: {
    color: theme.palette.error.main,
  },
}))
