import { z } from 'zod'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureCreateWithoutPicturesInputObjectSchema } from './ProcedureCreateWithoutPicturesInput.schema'
import { ProcedureUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateOrConnectWithoutPicturesInput> = z
  .object({
    where: z.lazy(() => ProcedureWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProcedureUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ProcedureCreateOrConnectWithoutPicturesInputObjectSchema = Schema
