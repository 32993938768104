import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { FlashsaleItemCountOrderByAggregateInputObjectSchema } from './FlashsaleItemCountOrderByAggregateInput.schema'
import { FlashsaleItemAvgOrderByAggregateInputObjectSchema } from './FlashsaleItemAvgOrderByAggregateInput.schema'
import { FlashsaleItemMaxOrderByAggregateInputObjectSchema } from './FlashsaleItemMaxOrderByAggregateInput.schema'
import { FlashsaleItemMinOrderByAggregateInputObjectSchema } from './FlashsaleItemMinOrderByAggregateInput.schema'
import { FlashsaleItemSumOrderByAggregateInputObjectSchema } from './FlashsaleItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    flashsaleId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    endAt: z.lazy(() => SortOrderSchema).optional(),
    price: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedBySkinX: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedByPartner: z.lazy(() => SortOrderSchema).optional(),
    saleLimit: z.lazy(() => SortOrderSchema).optional(),
    sold: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => FlashsaleItemCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => FlashsaleItemAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => FlashsaleItemMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => FlashsaleItemMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => FlashsaleItemSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const FlashsaleItemOrderByWithAggregationInputObjectSchema = Schema
