import { z } from 'zod'

export const MyReviewScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'patientId',
  'picture',
  'titleTr',
  'subtitleTr',
  'orderItemId',
  'orderItemType',
  'productId',
  'procedureId',
  'quantity',
  'drugType',
  'reviewId',
  'reviewStartAt',
  'reviewExpireAt',
])
