import { z } from 'zod'
import { AcceptedConsentCreateManyPatientInputObjectSchema } from './AcceptedConsentCreateManyPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateManyPatientInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => AcceptedConsentCreateManyPatientInputObjectSchema),
        z.lazy(() => AcceptedConsentCreateManyPatientInputObjectSchema).array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const AcceptedConsentCreateManyPatientInputEnvelopeObjectSchema = Schema
