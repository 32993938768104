import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    scope: z.literal(true).optional(),
    itemType: z.literal(true).optional(),
    items: z.literal(true).optional(),
    settingGroupId: z.literal(true).optional(),
    groupId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ConditionSettingCountAggregateInputObjectSchema = Schema
