import { z } from 'zod'
import { CarouselItemCreateInputObjectSchema } from './objects/CarouselItemCreateInput.schema'
import { CarouselItemUncheckedCreateInputObjectSchema } from './objects/CarouselItemUncheckedCreateInput.schema'

export const CarouselItemCreateOneSchema = z.object({
  data: z.union([
    CarouselItemCreateInputObjectSchema,
    CarouselItemUncheckedCreateInputObjectSchema,
  ]),
})
