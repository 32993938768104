import { z } from 'zod'
import { ProductPictureWhereUniqueInputObjectSchema } from './ProductPictureWhereUniqueInput.schema'
import { ProductPictureUpdateWithoutProductInputObjectSchema } from './ProductPictureUpdateWithoutProductInput.schema'
import { ProductPictureUncheckedUpdateWithoutProductInputObjectSchema } from './ProductPictureUncheckedUpdateWithoutProductInput.schema'
import { ProductPictureCreateWithoutProductInputObjectSchema } from './ProductPictureCreateWithoutProductInput.schema'
import { ProductPictureUncheckedCreateWithoutProductInputObjectSchema } from './ProductPictureUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureUpsertWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductPictureUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductPictureUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductPictureCreateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductPictureUpsertWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
