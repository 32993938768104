import { Box, Card, CardContent } from '@mui/material'
import { Edit, SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ShippingProviderInfo } from './create-edit-section/shippingProviderInfo'

const ShippingProviderEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="shippingProvider" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

export const ShippingProviderEdit = () => {
  return (
    <Edit component="div" redirect="list" mutationMode="pessimistic">
      <Box>
        <Card>
          <CardContent sx={{ p: 2 }}>
            <SimpleForm toolbar={<ShippingProviderEditToolbar />}>
              <ShippingProviderInfo />
            </SimpleForm>
          </CardContent>
        </Card>
      </Box>
    </Edit>
  )
}
