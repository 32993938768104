import { z } from 'zod'
import { CasePictureSelectObjectSchema } from './CasePictureSelect.schema'
import { CasePictureIncludeObjectSchema } from './CasePictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureArgs> = z
  .object({
    select: z.lazy(() => CasePictureSelectObjectSchema).optional(),
    include: z.lazy(() => CasePictureIncludeObjectSchema).optional(),
  })
  .strict()

export const CasePictureArgsObjectSchema = Schema
