import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumWeekdayFilterObjectSchema } from './EnumWeekdayFilter.schema'
import { WeekdaySchema } from '../enums/Weekday.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { BranchRelationFilterObjectSchema } from './BranchRelationFilter.schema'
import { BranchWhereInputObjectSchema } from './BranchWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => OfficeHourWhereInputObjectSchema),
        z.lazy(() => OfficeHourWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => OfficeHourWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => OfficeHourWhereInputObjectSchema),
        z.lazy(() => OfficeHourWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    weekday: z
      .union([
        z.lazy(() => EnumWeekdayFilterObjectSchema),
        z.lazy(() => WeekdaySchema),
      ])
      .optional(),
    openAt: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    closeAt: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    timezone: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    branchId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    branch: z
      .union([
        z.lazy(() => BranchRelationFilterObjectSchema),
        z.lazy(() => BranchWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const OfficeHourWhereInputObjectSchema = Schema
