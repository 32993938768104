import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { AddressCountOrderByAggregateInputObjectSchema } from './AddressCountOrderByAggregateInput.schema'
import { AddressAvgOrderByAggregateInputObjectSchema } from './AddressAvgOrderByAggregateInput.schema'
import { AddressMaxOrderByAggregateInputObjectSchema } from './AddressMaxOrderByAggregateInput.schema'
import { AddressMinOrderByAggregateInputObjectSchema } from './AddressMinOrderByAggregateInput.schema'
import { AddressSumOrderByAggregateInputObjectSchema } from './AddressSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    recipient: z.lazy(() => SortOrderSchema).optional(),
    address: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    province: z.lazy(() => SortOrderSchema).optional(),
    district: z.lazy(() => SortOrderSchema).optional(),
    subDistrict: z.lazy(() => SortOrderSchema).optional(),
    postCode: z.lazy(() => SortOrderSchema).optional(),
    provinceKey: z.lazy(() => SortOrderSchema).optional(),
    districtKey: z.lazy(() => SortOrderSchema).optional(),
    subDistrictKey: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    tag: z.lazy(() => SortOrderSchema).optional(),
    isDefault: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => AddressCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => AddressAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => AddressMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => AddressMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => AddressSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const AddressOrderByWithAggregationInputObjectSchema = Schema
