import { z } from 'zod'
import { ShippingProviderOrderByWithRelationInputObjectSchema } from './objects/ShippingProviderOrderByWithRelationInput.schema'
import { ShippingProviderWhereInputObjectSchema } from './objects/ShippingProviderWhereInput.schema'
import { ShippingProviderWhereUniqueInputObjectSchema } from './objects/ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderScalarFieldEnumSchema } from './enums/ShippingProviderScalarFieldEnum.schema'

export const ShippingProviderFindManySchema = z.object({
  orderBy: z
    .union([
      ShippingProviderOrderByWithRelationInputObjectSchema,
      ShippingProviderOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ShippingProviderWhereInputObjectSchema.optional(),
  cursor: ShippingProviderWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ShippingProviderScalarFieldEnumSchema).optional(),
})
