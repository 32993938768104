import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    branchId: z.literal(true).optional(),
  })
  .strict()

export const OfficeHourSumAggregateInputObjectSchema = Schema
