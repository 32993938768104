import { z } from 'zod'
import { VariantPictureCreateWithoutVariantInputObjectSchema } from './VariantPictureCreateWithoutVariantInput.schema'
import { VariantPictureUncheckedCreateWithoutVariantInputObjectSchema } from './VariantPictureUncheckedCreateWithoutVariantInput.schema'
import { VariantPictureCreateOrConnectWithoutVariantInputObjectSchema } from './VariantPictureCreateOrConnectWithoutVariantInput.schema'
import { VariantPictureUpsertWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantPictureUpsertWithWhereUniqueWithoutVariantInput.schema'
import { VariantPictureCreateManyVariantInputEnvelopeObjectSchema } from './VariantPictureCreateManyVariantInputEnvelope.schema'
import { VariantPictureWhereUniqueInputObjectSchema } from './VariantPictureWhereUniqueInput.schema'
import { VariantPictureUpdateWithWhereUniqueWithoutVariantInputObjectSchema } from './VariantPictureUpdateWithWhereUniqueWithoutVariantInput.schema'
import { VariantPictureUpdateManyWithWhereWithoutVariantInputObjectSchema } from './VariantPictureUpdateManyWithWhereWithoutVariantInput.schema'
import { VariantPictureScalarWhereInputObjectSchema } from './VariantPictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureUpdateManyWithoutVariantNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantPictureCreateWithoutVariantInputObjectSchema),
          z
            .lazy(() => VariantPictureCreateWithoutVariantInputObjectSchema)
            .array(),
          z.lazy(
            () => VariantPictureUncheckedCreateWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureUncheckedCreateWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => VariantPictureCreateOrConnectWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureCreateOrConnectWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              VariantPictureUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureUpsertWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => VariantPictureCreateManyVariantInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
          z.lazy(() => VariantPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              VariantPictureUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureUpdateWithWhereUniqueWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              VariantPictureUpdateManyWithWhereWithoutVariantInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantPictureUpdateManyWithWhereWithoutVariantInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => VariantPictureScalarWhereInputObjectSchema),
          z.lazy(() => VariantPictureScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const VariantPictureUpdateManyWithoutVariantNestedInputObjectSchema =
  Schema
