import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationMaxOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    actionType: z.lazy(() => SortOrderSchema).optional(),
    action: z.lazy(() => SortOrderSchema).optional(),
    onesignalMessageId: z.lazy(() => SortOrderSchema).optional(),
    startAt: z.lazy(() => SortOrderSchema).optional(),
    appointmentId: z.lazy(() => SortOrderSchema).optional(),
    appointmentEvent: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const NotificationMaxOrderByAggregateInputObjectSchema = Schema
