import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompletePatient, RelatedPatientModel } from "./index"

export const IdentityModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  identityId: z.string(),
  type: z.enum(['PHONE', 'EMAIL', 'FACEBOOK', 'GOOGLE', 'APPLE']),
  password: z.string().nullish(),
  meta: jsonSchema,
  patientId: z.number().int(),
})

export interface CompleteIdentity extends z.infer<typeof IdentityModel> {
  patient: CompletePatient
}

/**
 * RelatedIdentityModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedIdentityModel: z.ZodSchema<CompleteIdentity> = z.lazy(() => IdentityModel.extend({
  patient: RelatedPatientModel,
}))
