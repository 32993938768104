import { z } from 'zod'
import { PartnerCreateInputObjectSchema } from './objects/PartnerCreateInput.schema'
import { PartnerUncheckedCreateInputObjectSchema } from './objects/PartnerUncheckedCreateInput.schema'

export const PartnerCreateOneSchema = z.object({
  data: z.union([
    PartnerCreateInputObjectSchema,
    PartnerUncheckedCreateInputObjectSchema,
  ]),
})
