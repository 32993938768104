import { z } from 'zod'
import { PartnerTypeSchema } from '../enums/PartnerType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.EnumPartnerTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => PartnerTypeSchema).optional(),
  })
  .strict()

export const EnumPartnerTypeFieldUpdateOperationsInputObjectSchema = Schema
