import { z } from 'zod'
import { NotificationPatientUpdateInputObjectSchema } from './objects/NotificationPatientUpdateInput.schema'
import { NotificationPatientUncheckedUpdateInputObjectSchema } from './objects/NotificationPatientUncheckedUpdateInput.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './objects/NotificationPatientWhereUniqueInput.schema'

export const NotificationPatientUpdateOneSchema = z.object({
  data: z.union([
    NotificationPatientUpdateInputObjectSchema,
    NotificationPatientUncheckedUpdateInputObjectSchema,
  ]),
  where: NotificationPatientWhereUniqueInputObjectSchema,
})
