import { Box } from '@mui/material'
import get from 'lodash.get'
import { FC } from 'react'
import { ReferenceInput, Validator } from 'react-admin'
import { CustomAutoCompleteInput } from '../../../components/CustomInput'

export interface FormReferenceInputProps {
  source: string
  reference: string
  optionText: string[]
  label: string
  validate: Validator | Validator[] | undefined
  disabled?: boolean
  filterSearch: (searchText: string) => unknown
}

export const FormReferenceInput: FC<FormReferenceInputProps> = ({
  source,
  reference,
  optionText,
  label,
  validate,
  disabled = false,
  filterSearch,
}) => {
  const optionTextRender = (choice: { [x: string]: string | null }) => {
    const showText = optionText.map((text) =>
      get(choice, text)?.toString().trim(),
    )
    return `${showText[0]}: ${showText[1] ?? ''} ${showText[2]} ${showText[3]}`
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <ReferenceInput
            source={source}
            reference={reference}
            sort={{ field: 'id', order: 'ASC' }}
          >
            <CustomAutoCompleteInput
              fullWidth
              validate={validate}
              optionText={optionTextRender}
              filterToQuery={filterSearch}
              label={label}
              disabled={disabled}
              helperText={false}
              disableClearable={false}
            />
          </ReferenceInput>
        </Box>
      </Box>
    </Box>
  )
}
