import { z } from 'zod'
import { CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUncheckedCreateNestedOneWithoutFollowUpAppointmentInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryCreateWithoutFollowUpAppointmentInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutFollowUpAppointmentInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutFollowUpAppointmentInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CaseSummaryUncheckedCreateNestedOneWithoutFollowUpAppointmentInputObjectSchema =
  Schema
