import { z } from 'zod'
import { BranchCreateWithoutPartnerInputObjectSchema } from './BranchCreateWithoutPartnerInput.schema'
import { BranchUncheckedCreateWithoutPartnerInputObjectSchema } from './BranchUncheckedCreateWithoutPartnerInput.schema'
import { BranchCreateOrConnectWithoutPartnerInputObjectSchema } from './BranchCreateOrConnectWithoutPartnerInput.schema'
import { BranchCreateManyPartnerInputEnvelopeObjectSchema } from './BranchCreateManyPartnerInputEnvelope.schema'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchUncheckedCreateNestedManyWithoutPartnerInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema),
          z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema).array(),
          z.lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => BranchCreateOrConnectWithoutPartnerInputObjectSchema),
          z
            .lazy(() => BranchCreateOrConnectWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => BranchCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => BranchWhereUniqueInputObjectSchema),
          z.lazy(() => BranchWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const BranchUncheckedCreateNestedManyWithoutPartnerInputObjectSchema =
  Schema
