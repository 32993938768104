import { z } from 'zod'
import { HealthInfoUpdateWithoutPatientInputObjectSchema } from './HealthInfoUpdateWithoutPatientInput.schema'
import { HealthInfoUncheckedUpdateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedUpdateWithoutPatientInput.schema'
import { HealthInfoCreateWithoutPatientInputObjectSchema } from './HealthInfoCreateWithoutPatientInput.schema'
import { HealthInfoUncheckedCreateWithoutPatientInputObjectSchema } from './HealthInfoUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoUpsertWithoutPatientInput> = z
  .object({
    update: z.union([
      z.lazy(() => HealthInfoUpdateWithoutPatientInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedUpdateWithoutPatientInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => HealthInfoCreateWithoutPatientInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const HealthInfoUpsertWithoutPatientInputObjectSchema = Schema
