import { z } from 'zod'
import { TeleconsultCreateInputObjectSchema } from './objects/TeleconsultCreateInput.schema'
import { TeleconsultUncheckedCreateInputObjectSchema } from './objects/TeleconsultUncheckedCreateInput.schema'

export const TeleconsultCreateOneSchema = z.object({
  data: z.union([
    TeleconsultCreateInputObjectSchema,
    TeleconsultUncheckedCreateInputObjectSchema,
  ]),
})
