import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema),
        z
          .lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema),
        z
          .lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema)
          .array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    caseSummaryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    amount: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    note: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
  })
  .strict()

export const SuggestedProcedureItemScalarWhereInputObjectSchema = Schema
