import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './objects/PartnerWhereUniqueInput.schema'
import { PartnerCreateInputObjectSchema } from './objects/PartnerCreateInput.schema'
import { PartnerUncheckedCreateInputObjectSchema } from './objects/PartnerUncheckedCreateInput.schema'
import { PartnerUpdateInputObjectSchema } from './objects/PartnerUpdateInput.schema'
import { PartnerUncheckedUpdateInputObjectSchema } from './objects/PartnerUncheckedUpdateInput.schema'

export const PartnerUpsertSchema = z.object({
  where: PartnerWhereUniqueInputObjectSchema,
  create: z.union([
    PartnerCreateInputObjectSchema,
    PartnerUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    PartnerUpdateInputObjectSchema,
    PartnerUncheckedUpdateInputObjectSchema,
  ]),
})
