import { z } from 'zod'
import { CoinTransactionWhereUniqueInputObjectSchema } from './CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionCreateWithoutPatientInputObjectSchema } from './CoinTransactionCreateWithoutPatientInput.schema'
import { CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionCreateOrConnectWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CoinTransactionCreateWithoutPatientInputObjectSchema),
        z.lazy(
          () => CoinTransactionUncheckedCreateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoinTransactionCreateOrConnectWithoutPatientInputObjectSchema =
  Schema
