import { z } from 'zod'
import { ArticleCountOutputTypeSelectObjectSchema } from './ArticleCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => ArticleCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const ArticleCountOutputTypeArgsObjectSchema = Schema
