import { z } from 'zod'
import { ProcedureReviewPictureUncheckedCreateNestedManyWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUncheckedCreateNestedManyWithoutProcedureReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUncheckedCreateWithoutProcedureInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      partnerId: z.number(),
      overallRating: z.number(),
      environmentRating: z.number(),
      serviceRating: z.number(),
      specialtyRating: z.number(),
      valueRating: z.number(),
      message: z.string().optional().nullable(),
      orderId: z.number(),
      patientId: z.number(),
      patientName: z.string(),
      isAnonymous: z.boolean(),
      priority: z.number(),
      pictures: z
        .lazy(
          () =>
            ProcedureReviewPictureUncheckedCreateNestedManyWithoutProcedureReviewInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema =
  Schema
