import { z } from 'zod'
import { RefundWhereInputObjectSchema } from './objects/RefundWhereInput.schema'
import { RefundOrderByWithAggregationInputObjectSchema } from './objects/RefundOrderByWithAggregationInput.schema'
import { RefundScalarWhereWithAggregatesInputObjectSchema } from './objects/RefundScalarWhereWithAggregatesInput.schema'
import { RefundScalarFieldEnumSchema } from './enums/RefundScalarFieldEnum.schema'

export const RefundGroupBySchema = z.object({
  where: RefundWhereInputObjectSchema.optional(),
  orderBy: z.union([
    RefundOrderByWithAggregationInputObjectSchema,
    RefundOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: RefundScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(RefundScalarFieldEnumSchema),
})
