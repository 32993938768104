import { z } from 'zod'
import { RefundScalarWhereInputObjectSchema } from './RefundScalarWhereInput.schema'
import { RefundUpdateManyMutationInputObjectSchema } from './RefundUpdateManyMutationInput.schema'
import { RefundUncheckedUpdateManyWithoutRefundsInputObjectSchema } from './RefundUncheckedUpdateManyWithoutRefundsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpdateManyWithWhereWithoutOrderInput> = z
  .object({
    where: z.lazy(() => RefundScalarWhereInputObjectSchema),
    data: z.union([
      z.lazy(() => RefundUpdateManyMutationInputObjectSchema),
      z.lazy(() => RefundUncheckedUpdateManyWithoutRefundsInputObjectSchema),
    ]),
  })
  .strict()

export const RefundUpdateManyWithWhereWithoutOrderInputObjectSchema = Schema
