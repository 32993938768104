import { z } from 'zod'
import { RefundCreateInputObjectSchema } from './objects/RefundCreateInput.schema'
import { RefundUncheckedCreateInputObjectSchema } from './objects/RefundUncheckedCreateInput.schema'

export const RefundCreateOneSchema = z.object({
  data: z.union([
    RefundCreateInputObjectSchema,
    RefundUncheckedCreateInputObjectSchema,
  ]),
})
