import { z } from 'zod'
import { DeliveryUpdateWithoutProductItemsInputObjectSchema } from './DeliveryUpdateWithoutProductItemsInput.schema'
import { DeliveryUncheckedUpdateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedUpdateWithoutProductItemsInput.schema'
import { DeliveryCreateWithoutProductItemsInputObjectSchema } from './DeliveryCreateWithoutProductItemsInput.schema'
import { DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpsertWithoutProductItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => DeliveryUpdateWithoutProductItemsInputObjectSchema),
      z.lazy(() => DeliveryUncheckedUpdateWithoutProductItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => DeliveryCreateWithoutProductItemsInputObjectSchema),
      z.lazy(() => DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema),
    ]),
  })
  .strict()

export const DeliveryUpsertWithoutProductItemsInputObjectSchema = Schema
