import { z } from 'zod'
import { AcceptedConsentCreateInputObjectSchema } from './objects/AcceptedConsentCreateInput.schema'
import { AcceptedConsentUncheckedCreateInputObjectSchema } from './objects/AcceptedConsentUncheckedCreateInput.schema'

export const AcceptedConsentCreateOneSchema = z.object({
  data: z.union([
    AcceptedConsentCreateInputObjectSchema,
    AcceptedConsentUncheckedCreateInputObjectSchema,
  ]),
})
