import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OldPatientCountOrderByAggregateInputObjectSchema } from './OldPatientCountOrderByAggregateInput.schema'
import { OldPatientAvgOrderByAggregateInputObjectSchema } from './OldPatientAvgOrderByAggregateInput.schema'
import { OldPatientMaxOrderByAggregateInputObjectSchema } from './OldPatientMaxOrderByAggregateInput.schema'
import { OldPatientMinOrderByAggregateInputObjectSchema } from './OldPatientMinOrderByAggregateInput.schema'
import { OldPatientSumOrderByAggregateInputObjectSchema } from './OldPatientSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.OldPatientOrderByWithAggregationInput> = z
  .object({
    patientId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => OldPatientCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => OldPatientAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => OldPatientMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => OldPatientMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => OldPatientSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const OldPatientOrderByWithAggregationInputObjectSchema = Schema
