import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultInclude> = z
  .object({
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    missedCallCase: z
      .union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)])
      .optional(),
    appointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const TeleconsultIncludeObjectSchema = Schema
