import { z } from 'zod'
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema'
import { UserCreateWithoutPartnerInputObjectSchema } from './UserCreateWithoutPartnerInput.schema'
import { UserUncheckedCreateWithoutPartnerInputObjectSchema } from './UserUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserCreateOrConnectWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => UserWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => UserCreateWithoutPartnerInputObjectSchema),
      z.lazy(() => UserUncheckedCreateWithoutPartnerInputObjectSchema),
    ]),
  })
  .strict()

export const UserCreateOrConnectWithoutPartnerInputObjectSchema = Schema
