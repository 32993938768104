import { z } from 'zod'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumPregnancyStateNullableFilter> = z
  .object({
    equals: z
      .lazy(() => PregnancyStateSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => PregnancyStateSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => PregnancyStateSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => PregnancyStateSchema),
        z.lazy(() => NestedEnumPregnancyStateNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const NestedEnumPregnancyStateNullableFilterObjectSchema = Schema
