import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutFollowupCaseInputObjectSchema } from './CaseCreateWithoutFollowupCaseInput.schema'
import { CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema } from './CaseUncheckedCreateWithoutFollowupCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutFollowupCaseInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutFollowupCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutFollowupCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutFollowupCaseInputObjectSchema = Schema
