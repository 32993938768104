import { z } from 'zod'
import { HealthInfoWhereUniqueInputObjectSchema } from './HealthInfoWhereUniqueInput.schema'
import { HealthInfoCreateWithoutOrderInputObjectSchema } from './HealthInfoCreateWithoutOrderInput.schema'
import { HealthInfoUncheckedCreateWithoutOrderInputObjectSchema } from './HealthInfoUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => HealthInfoWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => HealthInfoCreateWithoutOrderInputObjectSchema),
      z.lazy(() => HealthInfoUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const HealthInfoCreateOrConnectWithoutOrderInputObjectSchema = Schema
