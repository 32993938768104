import { z } from 'zod'
import { SkuWhereInputObjectSchema } from './objects/SkuWhereInput.schema'
import { SkuOrderByWithAggregationInputObjectSchema } from './objects/SkuOrderByWithAggregationInput.schema'
import { SkuScalarWhereWithAggregatesInputObjectSchema } from './objects/SkuScalarWhereWithAggregatesInput.schema'
import { SkuScalarFieldEnumSchema } from './enums/SkuScalarFieldEnum.schema'

export const SkuGroupBySchema = z.object({
  where: SkuWhereInputObjectSchema.optional(),
  orderBy: z.union([
    SkuOrderByWithAggregationInputObjectSchema,
    SkuOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: SkuScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(SkuScalarFieldEnumSchema),
})
