import { z } from 'zod'
import { PartnerOrderByWithRelationInputObjectSchema } from './objects/PartnerOrderByWithRelationInput.schema'
import { PartnerWhereInputObjectSchema } from './objects/PartnerWhereInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './objects/PartnerWhereUniqueInput.schema'
import { PartnerScalarFieldEnumSchema } from './enums/PartnerScalarFieldEnum.schema'

export const PartnerFindManySchema = z.object({
  orderBy: z
    .union([
      PartnerOrderByWithRelationInputObjectSchema,
      PartnerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: PartnerWhereInputObjectSchema.optional(),
  cursor: PartnerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(PartnerScalarFieldEnumSchema).optional(),
})
