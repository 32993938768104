import { z } from 'zod'
import { ConditionSettingWhereUniqueInputObjectSchema } from './ConditionSettingWhereUniqueInput.schema'
import { ConditionSettingCreateWithoutGroupInputObjectSchema } from './ConditionSettingCreateWithoutGroupInput.schema'
import { ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema } from './ConditionSettingUncheckedCreateWithoutGroupInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingCreateOrConnectWithoutGroupInput> =
  z
    .object({
      where: z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ConditionSettingCreateWithoutGroupInputObjectSchema),
        z.lazy(
          () => ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema =
  Schema
