import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    overallRating: z.literal(true).optional(),
    environmentRating: z.literal(true).optional(),
    serviceRating: z.literal(true).optional(),
    specialtyRating: z.literal(true).optional(),
    valueRating: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    priority: z.literal(true).optional(),
  })
  .strict()

export const ProcedureReviewSumAggregateInputObjectSchema = Schema
