import get from 'lodash.get'
import React from 'react'
import { useLocaleState } from 'react-admin'
import { UseFormReturn } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

type HandleListPageQueryParamsProps = {
  queryParam: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterPair: {
    [key: string]: {
      formKey: string
      valuePathForForm?: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setStateField?: React.Dispatch<React.SetStateAction<any>>
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayedFilters: any,
    debounce?: boolean | undefined,
  ) => void
}

export const handleListPageQueryParams = (
  props: HandleListPageQueryParamsProps,
) => {
  const { queryParam, filterPair, form, setFilters } = props
  const filterStringList = queryParam.slice(1).split('&')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterObject: { [key: string]: any } = {}

  filterStringList.forEach((f) => {
    const pairFilter = f.split('=')
    if (!['page', 'size'].includes(pairFilter[0])) {
      filterObject[pairFilter[0]] = JSON.parse(
        decodeURIComponent(pairFilter[1]),
      )
    }
  })

  Object.keys(filterObject).forEach((key) => {
    if (filterPair[key]) {
      const formValue = filterPair[key].valuePathForForm
      form.setValue(
        filterPair[key].formKey,
        formValue ? get(filterObject[key], formValue) : filterObject[key],
      )

      const setCustomState = filterPair[key].setStateField
      if (setCustomState) {
        setCustomState(formValue)
      }
    }
  })

  setFilters(filterObject, [])

  return filterObject
}

export const generateFilterQueryParams = (filter: { [x: string]: unknown }) => {
  let queryString = ''
  if (filter) {
    Object.keys(filter).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (filter![key]) {
        if (queryString) {
          queryString += '&'
        }
        queryString += `${key}=${encodeURIComponent(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          JSON.stringify(filter![key]),
        )}`
      }
    })
  }

  return queryString
}

export const validateHttpsUrl = (value: string) => {
  if (value) {
    const urlPattern = /^http[s]?:\/\/.*/
    if (!urlPattern.test(value)) {
      return 'URL must start with "http://" or "https://"'
    }
  }
  return undefined // Validation passed
}

export const validatePhoneNumber = (value: string) => {
  if (value) {
    const phonePattern = /^02\d{7}|0\d{9}$/
    if (!phonePattern.test(value)) {
      return 'Phone number is invalid'
    }
  }
  return undefined // Validation passed
}

export const useI18nFormatter = () => {
  const [locale] = useLocaleState()
  return <T>(value: { [key: string]: T }) => value[locale]
}

export const downloadExcel = (blob: BlobPart, filename: string) => {
  const fakeLink = document.createElement('a')
  fakeLink.style.display = 'none'
  document.body.appendChild(fakeLink)
  const excelBlob = new Blob([blob], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  })
  fakeLink.setAttribute('href', URL.createObjectURL(excelBlob))
  fakeLink.setAttribute('download', `${filename}.xlsx`)
  fakeLink.click()
}

export const handleFilterFullnameQuery = (searchText: string) => {
  const splittedSearchText = searchText.trim().split(/\s+/)
  const searchOption = {
    '1': [['id'], ['prefix'], ['firstname'], ['lastname']],
    '2': [
      ['prefix', 'firstname'],
      ['firstname', 'lastname'],
    ],
    '3': [['prefix', 'firstname', 'lastname']],
  }

  if (!splittedSearchText.length) {
    return {
      OR: [],
    }
  }

  const orFilterList = [] as { [x: string]: string | number }[]
  if (splittedSearchText.length <= 3) {
    searchOption[
      splittedSearchText.length.toString() as '1' | '2' | '3'
    ].forEach((conidtionList) => {
      const conditionObject = {} as { [x: string]: string | number }
      for (let i = 0; i < splittedSearchText.length; i++) {
        if (conidtionList[i] === 'id') {
          const idCondition = parseInt(splittedSearchText[i])
          if (!isNaN(idCondition)) {
            conditionObject[conidtionList[i]] = idCondition
          }
        } else {
          conditionObject[conidtionList[i]] = splittedSearchText[i]
        }
      }

      orFilterList.push(conditionObject)
    })
  } else {
    orFilterList.push({
      prefix: splittedSearchText[0],
      firstname: splittedSearchText[1],
      lastname: splittedSearchText.slice(2).join(' '),
    })
    orFilterList.push({
      prefix: splittedSearchText[0],
      firstname: splittedSearchText
        .slice(1, splittedSearchText.length - 1)
        .join(' '),
      lastname: splittedSearchText[splittedSearchText.length - 1],
    })
  }

  return {
    OR: orFilterList,
  }
}

export const toCamelCase = (string: string, splitType = '_') => {
  const stringSpliteds = string.toLocaleLowerCase().split(splitType)
  const camelCase = stringSpliteds
    .map((string, index) =>
      index > 0 ? string.charAt(0).toUpperCase() + string.slice(1) : string,
    )
    .join('')
  return camelCase
}

// group discountSettings/conditionSettings by settingGroupId
export const groupBySettingGroupId = (
  settings: {
    settingGroupId?: string
  }[],
) => {
  return settings.reduce((group, c) => {
    const settingGroupId = c.settingGroupId || uuidv4()
    group[settingGroupId] = group[settingGroupId] || []
    group[settingGroupId].push(c)
    return group
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as { [key: string]: any })
}
