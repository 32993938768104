import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutPreviousCaseInputObjectSchema } from './CaseCreateWithoutPreviousCaseInput.schema'
import { CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema } from './CaseUncheckedCreateWithoutPreviousCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutPreviousCaseInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutPreviousCaseInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutPreviousCaseInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutPreviousCaseInputObjectSchema = Schema
