import { z } from 'zod'
import { NotificationPatientOrderByWithRelationInputObjectSchema } from './objects/NotificationPatientOrderByWithRelationInput.schema'
import { NotificationPatientWhereInputObjectSchema } from './objects/NotificationPatientWhereInput.schema'
import { NotificationPatientWhereUniqueInputObjectSchema } from './objects/NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientCountAggregateInputObjectSchema } from './objects/NotificationPatientCountAggregateInput.schema'
import { NotificationPatientMinAggregateInputObjectSchema } from './objects/NotificationPatientMinAggregateInput.schema'
import { NotificationPatientMaxAggregateInputObjectSchema } from './objects/NotificationPatientMaxAggregateInput.schema'
import { NotificationPatientAvgAggregateInputObjectSchema } from './objects/NotificationPatientAvgAggregateInput.schema'
import { NotificationPatientSumAggregateInputObjectSchema } from './objects/NotificationPatientSumAggregateInput.schema'

export const NotificationPatientAggregateSchema = z.object({
  orderBy: z
    .union([
      NotificationPatientOrderByWithRelationInputObjectSchema,
      NotificationPatientOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: NotificationPatientWhereInputObjectSchema.optional(),
  cursor: NotificationPatientWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      NotificationPatientCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: NotificationPatientMinAggregateInputObjectSchema.optional(),
  _max: NotificationPatientMaxAggregateInputObjectSchema.optional(),
  _avg: NotificationPatientAvgAggregateInputObjectSchema.optional(),
  _sum: NotificationPatientSumAggregateInputObjectSchema.optional(),
})
