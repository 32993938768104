import { z } from 'zod'
import { AddressOrderByWithRelationInputObjectSchema } from './objects/AddressOrderByWithRelationInput.schema'
import { AddressWhereInputObjectSchema } from './objects/AddressWhereInput.schema'
import { AddressWhereUniqueInputObjectSchema } from './objects/AddressWhereUniqueInput.schema'
import { AddressScalarFieldEnumSchema } from './enums/AddressScalarFieldEnum.schema'

export const AddressFindManySchema = z.object({
  orderBy: z
    .union([
      AddressOrderByWithRelationInputObjectSchema,
      AddressOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: AddressWhereInputObjectSchema.optional(),
  cursor: AddressWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(AddressScalarFieldEnumSchema).optional(),
})
