import { z } from 'zod'
import { ProcedureReviewPictureWhereInputObjectSchema } from './objects/ProcedureReviewPictureWhereInput.schema'
import { ProcedureReviewPictureOrderByWithAggregationInputObjectSchema } from './objects/ProcedureReviewPictureOrderByWithAggregationInput.schema'
import { ProcedureReviewPictureScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureReviewPictureScalarWhereWithAggregatesInput.schema'
import { ProcedureReviewPictureScalarFieldEnumSchema } from './enums/ProcedureReviewPictureScalarFieldEnum.schema'

export const ProcedureReviewPictureGroupBySchema = z.object({
  where: ProcedureReviewPictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureReviewPictureOrderByWithAggregationInputObjectSchema,
    ProcedureReviewPictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    ProcedureReviewPictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureReviewPictureScalarFieldEnumSchema),
})
