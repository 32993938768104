import { z } from 'zod'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { NestedEnumProductTypeFilterObjectSchema } from './NestedEnumProductTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumProductTypeFilter> = z
  .object({
    equals: z.lazy(() => ProductTypeSchema).optional(),
    in: z
      .lazy(() => ProductTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ProductTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ProductTypeSchema),
        z.lazy(() => NestedEnumProductTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumProductTypeFilterObjectSchema = Schema
