import { z } from 'zod'
import { CouponGroupWhereUniqueInputObjectSchema } from './objects/CouponGroupWhereUniqueInput.schema'
import { CouponGroupCreateInputObjectSchema } from './objects/CouponGroupCreateInput.schema'
import { CouponGroupUncheckedCreateInputObjectSchema } from './objects/CouponGroupUncheckedCreateInput.schema'
import { CouponGroupUpdateInputObjectSchema } from './objects/CouponGroupUpdateInput.schema'
import { CouponGroupUncheckedUpdateInputObjectSchema } from './objects/CouponGroupUncheckedUpdateInput.schema'

export const CouponGroupUpsertSchema = z.object({
  where: CouponGroupWhereUniqueInputObjectSchema,
  create: z.union([
    CouponGroupCreateInputObjectSchema,
    CouponGroupUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CouponGroupUpdateInputObjectSchema,
    CouponGroupUncheckedUpdateInputObjectSchema,
  ]),
})
