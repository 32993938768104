import { z } from 'zod'
import { ArticlePictureWhereInputObjectSchema } from './objects/ArticlePictureWhereInput.schema'
import { ArticlePictureOrderByWithAggregationInputObjectSchema } from './objects/ArticlePictureOrderByWithAggregationInput.schema'
import { ArticlePictureScalarWhereWithAggregatesInputObjectSchema } from './objects/ArticlePictureScalarWhereWithAggregatesInput.schema'
import { ArticlePictureScalarFieldEnumSchema } from './enums/ArticlePictureScalarFieldEnum.schema'

export const ArticlePictureGroupBySchema = z.object({
  where: ArticlePictureWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ArticlePictureOrderByWithAggregationInputObjectSchema,
    ArticlePictureOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ArticlePictureScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ArticlePictureScalarFieldEnumSchema),
})
