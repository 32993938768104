import { z } from 'zod'
import { ProcedureReviewPictureCreateManyProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateManyProcedureReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCreateManyProcedureReviewInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () =>
            ProcedureReviewPictureCreateManyProcedureReviewInputObjectSchema,
        ),
        z
          .lazy(
            () =>
              ProcedureReviewPictureCreateManyProcedureReviewInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProcedureReviewPictureCreateManyProcedureReviewInputEnvelopeObjectSchema =
  Schema
