import { z } from 'zod'
import { PatientCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientCreateWithoutHealthCheckUpInfoInput.schema'
import { PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUncheckedCreateWithoutHealthCheckUpInfoInput.schema'
import { PatientCreateOrConnectWithoutHealthCheckUpInfoInputObjectSchema } from './PatientCreateOrConnectWithoutHealthCheckUpInfoInput.schema'
import { PatientUpsertWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUpsertWithoutHealthCheckUpInfoInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUpdateWithoutHealthCheckUpInfoInput.schema'
import { PatientUncheckedUpdateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUncheckedUpdateWithoutHealthCheckUpInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutHealthCheckUpInfoNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutHealthCheckUpInfoInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => PatientCreateOrConnectWithoutHealthCheckUpInfoInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutHealthCheckUpInfoInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutHealthCheckUpInfoInputObjectSchema),
          z.lazy(
            () =>
              PatientUncheckedUpdateWithoutHealthCheckUpInfoInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutHealthCheckUpInfoNestedInputObjectSchema =
  Schema
