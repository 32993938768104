import { z } from 'zod'
import { ProcedureCategorySelectObjectSchema } from './ProcedureCategorySelect.schema'
import { ProcedureCategoryIncludeObjectSchema } from './ProcedureCategoryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryArgs> = z
  .object({
    select: z.lazy(() => ProcedureCategorySelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureCategoryIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureCategoryArgsObjectSchema = Schema
