import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { EnumPaymentGatewayNullableFilterObjectSchema } from './EnumPaymentGatewayNullableFilter.schema'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { EnumPaymentMethodFilterObjectSchema } from './EnumPaymentMethodFilter.schema'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { EnumPaymentStatusFilterObjectSchema } from './EnumPaymentStatusFilter.schema'
import { PaymentStatusSchema } from '../enums/PaymentStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => PaymentScalarWhereInputObjectSchema),
        z.lazy(() => PaymentScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => PaymentScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => PaymentScalarWhereInputObjectSchema),
        z.lazy(() => PaymentScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    transactionId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    amount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    currency: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    paymentAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    paymentGateway: z
      .union([
        z.lazy(() => EnumPaymentGatewayNullableFilterObjectSchema),
        z.lazy(() => PaymentGatewaySchema),
      ])
      .optional()
      .nullable(),
    paymentMethod: z
      .union([
        z.lazy(() => EnumPaymentMethodFilterObjectSchema),
        z.lazy(() => PaymentMethodSchema),
      ])
      .optional(),
    installmentTerm: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => EnumPaymentStatusFilterObjectSchema),
        z.lazy(() => PaymentStatusSchema),
      ])
      .optional(),
    confirmedBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    evidencePicture: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    reminderNote: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const PaymentScalarWhereInputObjectSchema = Schema
