import { Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CustomTextInput } from '../../../components/CustomInput'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

export const FilterListForm = () => {
  const navigate = useNavigate()
  const { setFilters } = useListContext()
  const form = useForm({
    defaultValues: {
      name: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    delete newFilter.name
    delete newFilter.sku

    if (values.name) {
      newFilter.product = {
        searchField: {
          contains: values.name,
        },
      }
    }

    if (values.sku) {
      newFilter.sku = { contains: values.sku }
    }

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }
  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          product: {
            formKey: 'name',
            valuePathForForm: 'searchField.contains',
          },
          sku: {
            formKey: 'sku',
            valuePathForForm: 'contains',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="name"
            label="Product Name "
          />
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            resettable
            helperText={false}
            source="sku"
            label="sku"
          />
          <Box sx={{ display: 'flex', gap: 1, mt: -0.5 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
