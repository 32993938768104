import { z } from 'zod'

export const ProductScalarFieldEnumSchema = z.enum([
  'id',
  'active',
  'searchable',
  'nameTr',
  'searchField',
  'regNo',
  'descriptionTr',
  'auxiliaryLabel',
  'instructionTr',
  'sideEffectTr',
  'cautionTr',
  'pharmacyNote',
  'ingredient',
  'type',
  'drugType',
  'salesType',
  'productForms',
  'skinType',
  'propertyTr',
  'concerns',
  'attributes',
  'priority',
  'usage',
  'useAmount',
  'useUnit',
  'useFrequency',
  'useTime',
  'brandId',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'v2DrugItemId',
])
