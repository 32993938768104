import { z } from 'zod'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { AppointmentStatusSchema } from '../enums/AppointmentStatus.schema'
import { CaseCreateNestedOneWithoutAppointmentInputObjectSchema } from './CaseCreateNestedOneWithoutAppointmentInput.schema'
import { ScheduleCreateNestedOneWithoutAppointmentInputObjectSchema } from './ScheduleCreateNestedOneWithoutAppointmentInput.schema'
import { CaseSummaryCreateNestedOneWithoutFollowUpAppointmentInputObjectSchema } from './CaseSummaryCreateNestedOneWithoutFollowUpAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateWithoutTeleconsultInput> = z
  .object({
    doctorId: z.number(),
    patientId: z.number(),
    type: z.lazy(() => AppointmentTypeSchema),
    status: z.lazy(() => AppointmentStatusSchema),
    appointmentAt: z.date(),
    cancelAt: z.date().optional().nullable(),
    cancelDetail: z.string().optional().nullable(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    case: z.lazy(() => CaseCreateNestedOneWithoutAppointmentInputObjectSchema),
    schedule: z
      .lazy(() => ScheduleCreateNestedOneWithoutAppointmentInputObjectSchema)
      .optional(),
    followUpCaseSummary: z
      .lazy(
        () =>
          CaseSummaryCreateNestedOneWithoutFollowUpAppointmentInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const AppointmentCreateWithoutTeleconsultInputObjectSchema = Schema
