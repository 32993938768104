import { z } from 'zod'
import { DeliveryLogWhereUniqueInputObjectSchema } from './objects/DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogCreateInputObjectSchema } from './objects/DeliveryLogCreateInput.schema'
import { DeliveryLogUncheckedCreateInputObjectSchema } from './objects/DeliveryLogUncheckedCreateInput.schema'
import { DeliveryLogUpdateInputObjectSchema } from './objects/DeliveryLogUpdateInput.schema'
import { DeliveryLogUncheckedUpdateInputObjectSchema } from './objects/DeliveryLogUncheckedUpdateInput.schema'

export const DeliveryLogUpsertSchema = z.object({
  where: DeliveryLogWhereUniqueInputObjectSchema,
  create: z.union([
    DeliveryLogCreateInputObjectSchema,
    DeliveryLogUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    DeliveryLogUpdateInputObjectSchema,
    DeliveryLogUncheckedUpdateInputObjectSchema,
  ]),
})
