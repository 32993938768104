import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerCreateWithoutProcedureOrderInputObjectSchema } from './PartnerCreateWithoutProcedureOrderInput.schema'
import { PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema } from './PartnerUncheckedCreateWithoutProcedureOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutProcedureOrderInput> =
  z
    .object({
      where: z.lazy(() => PartnerWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PartnerCreateWithoutProcedureOrderInputObjectSchema),
        z.lazy(
          () => PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PartnerCreateOrConnectWithoutProcedureOrderInputObjectSchema =
  Schema
