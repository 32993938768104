import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    amount: z.literal(true).optional(),
    currency: z.literal(true).optional(),
    refundAt: z.literal(true).optional(),
    paymentGateway: z.literal(true).optional(),
    status: z.literal(true).optional(),
    reason: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
  })
  .strict()

export const RefundMaxAggregateInputObjectSchema = Schema
