import { z } from 'zod'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentCreateWithoutCaseInputObjectSchema } from './AppointmentCreateWithoutCaseInput.schema'
import { AppointmentUncheckedCreateWithoutCaseInputObjectSchema } from './AppointmentUncheckedCreateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateOrConnectWithoutCaseInput> = z
  .object({
    where: z.lazy(() => AppointmentWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => AppointmentCreateWithoutCaseInputObjectSchema),
      z.lazy(() => AppointmentUncheckedCreateWithoutCaseInputObjectSchema),
    ]),
  })
  .strict()

export const AppointmentCreateOrConnectWithoutCaseInputObjectSchema = Schema
