import { z } from 'zod'
import { ProductVideoUpdateInputObjectSchema } from './objects/ProductVideoUpdateInput.schema'
import { ProductVideoUncheckedUpdateInputObjectSchema } from './objects/ProductVideoUncheckedUpdateInput.schema'
import { ProductVideoWhereUniqueInputObjectSchema } from './objects/ProductVideoWhereUniqueInput.schema'

export const ProductVideoUpdateOneSchema = z.object({
  data: z.union([
    ProductVideoUpdateInputObjectSchema,
    ProductVideoUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductVideoWhereUniqueInputObjectSchema,
})
