import { z } from 'zod'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerCreateWithoutBranchesInputObjectSchema } from './PartnerCreateWithoutBranchesInput.schema'
import { PartnerUncheckedCreateWithoutBranchesInputObjectSchema } from './PartnerUncheckedCreateWithoutBranchesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateOrConnectWithoutBranchesInput> = z
  .object({
    where: z.lazy(() => PartnerWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutBranchesInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutBranchesInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerCreateOrConnectWithoutBranchesInputObjectSchema = Schema
