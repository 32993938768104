import { Avatar, Box, Button, TextField, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import TreeView, { INode } from 'react-accessible-treeview'
import {
  ListBase,
  useListContext,
  useLocaleState,
  useRedirect,
} from 'react-admin'

import { ArrowDropDown, ArrowRight } from '@mui/icons-material'

import './list.css'

interface SearchCategoryProps {
  searchString: string
  setSearchString: Dispatch<SetStateAction<string>>
}

const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => {
  if (isOpen) {
    return <ArrowDropDown />
  } else {
    return <ArrowRight />
  }
}

const ProcedureCategoryListHeader: FC<SearchCategoryProps> = ({
  searchString,
  setSearchString,
}) => {
  const redirect = useRedirect()
  const [filterText, setFilterText] = useState(searchString)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          หมวดหมู่หัตถการ
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#64B95E' }}
          onClick={() => {
            redirect('create', 'procedureCategory')
          }}
        >
          สร้างหมวดหมู่หัตถการ
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 1,
          my: 2,
        }}
      >
        <TextField
          InputLabelProps={{ shrink: true }}
          label="ชื่อหมวดหมู่หัตถการ"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value)
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setSearchString(filterText)
          }}
        >
          ค้นหา
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setFilterText('')
            setSearchString('')
          }}
        >
          ล้างค้นหา
        </Button>
      </Box>
    </>
  )
}

const ProcedureCategoryTree: FC<SearchCategoryProps> = ({ searchString }) => {
  const { data, isLoading } = useListContext()
  const redirect = useRedirect()
  const [locale] = useLocaleState()
  const [filteredData, setFilteredData] = useState<INode[]>([])

  useEffect(() => {
    if (data && data.length > 0) {
      let filteredIds = data.map((category) => category.id as number)
      if (searchString !== '') {
        const filteredSearchCategory = data.filter((category) => {
          return (
            category.nameTr.th
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            category.nameTr.en
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        })

        const tempIds: number[] = []
        // main category
        filteredSearchCategory
          .filter((category: { path: string }) => !category.path)
          .forEach((category) => {
            tempIds.push(category.id)
          })
        // sub category and its path
        filteredSearchCategory
          .filter((category: { path: string }) => category.path)
          .forEach((category) => {
            category.path.split('/').forEach((id: string) => {
              tempIds.push(parseInt(id))
            })

            tempIds.push(category.id)
          })

        filteredIds = [...new Set(tempIds)]
        filteredIds.sort(
          (a, b) =>
            data.findIndex((elem) => elem.id === a) -
            data.findIndex((elem) => elem.id === b),
        )
      }

      const mainCategoryIds: number[] = []
      const rootTree: INode = {
        id: 0,
        name: '',
        children: [] as number[],
        parent: null,
      }
      const categoryNodes = data
        .filter((category) => filteredIds.includes(category.id))
        .map((category) => {
          const idIndex =
            filteredIds.findIndex((elem) => elem === category.id) + 1
          if (!category.parentId) {
            mainCategoryIds.push(idIndex)
          }

          const childrenIds = category.children
            .filter((subCategory: { id: number }) => {
              return filteredIds.includes(subCategory.id)
            })
            .map((elem: { id: number }) => elem.id) as number[]

          childrenIds.sort(
            (a, b) =>
              data.findIndex((elem) => elem.id === a) -
              data.findIndex((elem) => elem.id === b),
          )

          return {
            name: category.id,
            id: idIndex,
            children: childrenIds.map((subCategoryId) => {
              return filteredIds.findIndex((elem) => elem === subCategoryId) + 1
            }) as number[],
            parent: category.parentId
              ? filteredIds.findIndex((elem) => elem === category.parentId) + 1
              : 0,
          }
        })

      rootTree.children = mainCategoryIds
      setFilteredData([rootTree, ...categoryNodes])
    }
  }, [data, searchString, locale])

  if (isLoading || filteredData.length === 0) {
    return null
  }

  return (
    <TreeView
      data={filteredData}
      nodeRenderer={({
        element,
        isBranch,
        isExpanded,
        getNodeProps,
        handleExpand,
      }) => {
        return (
          <Box
            {...getNodeProps({ onClick: handleExpand })}
            sx={{
              '&:hover': {
                backgroundColor: '#eeeeee',
              },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isBranch ? (
                <ArrowIcon isOpen={isExpanded} />
              ) : (
                <Box sx={{ width: '24px', height: '24px' }} />
              )}
              {data.find((elem) => elem.id === parseInt(element.name))?.icon
                ?.src ? (
                <Avatar
                  src={
                    data.find((elem) => elem.id === parseInt(element.name)).icon
                      .src
                  }
                  style={{
                    width: parseInt('25', 10),
                    height: parseInt('25', 10),
                  }}
                />
              ) : null}
              <Typography>
                {locale === 'th'
                  ? data.find((elem) => elem.id === parseInt(element.name))
                      .nameTr.th
                  : data.find((elem) => elem.id === parseInt(element.name))
                      .nameTr.en}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>
                {`[${
                  data.find((elem) => elem.id === parseInt(element.name))
                    .priority
                }]`}
              </Typography>
              <Button
                size="small"
                sx={{ mt: 0.25 }}
                onClick={() => {
                  redirect('edit', 'procedureCategory', parseInt(element.name))
                }}
              >
                แก้ไข
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}

export const ProcedureCategoryList = () => {
  const [searchString, setSearchString] = useState('')

  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <ProcedureCategoryListHeader
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <ListBase
        disableSyncWithLocation
        sort={{ field: 'priority', order: 'DESC' }}
        perPage={5000}
      >
        <ProcedureCategoryTree
          searchString={searchString}
          setSearchString={setSearchString}
        />
      </ListBase>
    </Box>
  )
}
