import { z } from 'zod'
import { CouponCreateWithoutGroupInputObjectSchema } from './CouponCreateWithoutGroupInput.schema'
import { CouponUncheckedCreateWithoutGroupInputObjectSchema } from './CouponUncheckedCreateWithoutGroupInput.schema'
import { CouponCreateOrConnectWithoutGroupInputObjectSchema } from './CouponCreateOrConnectWithoutGroupInput.schema'
import { CouponUpsertWithWhereUniqueWithoutGroupInputObjectSchema } from './CouponUpsertWithWhereUniqueWithoutGroupInput.schema'
import { CouponCreateManyGroupInputEnvelopeObjectSchema } from './CouponCreateManyGroupInputEnvelope.schema'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'
import { CouponUpdateWithWhereUniqueWithoutGroupInputObjectSchema } from './CouponUpdateWithWhereUniqueWithoutGroupInput.schema'
import { CouponUpdateManyWithWhereWithoutGroupInputObjectSchema } from './CouponUpdateManyWithWhereWithoutGroupInput.schema'
import { CouponScalarWhereInputObjectSchema } from './CouponScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponUpdateManyWithoutGroupNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CouponCreateWithoutGroupInputObjectSchema),
        z.lazy(() => CouponCreateWithoutGroupInputObjectSchema).array(),
        z.lazy(() => CouponUncheckedCreateWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponUncheckedCreateWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => CouponCreateOrConnectWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponCreateOrConnectWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(() => CouponUpsertWithWhereUniqueWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponUpsertWithWhereUniqueWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => CouponCreateManyGroupInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => CouponWhereUniqueInputObjectSchema),
        z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => CouponWhereUniqueInputObjectSchema),
        z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => CouponWhereUniqueInputObjectSchema),
        z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => CouponWhereUniqueInputObjectSchema),
        z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(() => CouponUpdateWithWhereUniqueWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponUpdateWithWhereUniqueWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => CouponUpdateManyWithWhereWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponUpdateManyWithWhereWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => CouponScalarWhereInputObjectSchema),
        z.lazy(() => CouponScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const CouponUpdateManyWithoutGroupNestedInputObjectSchema = Schema
