import { z } from 'zod'
import { CarouselCreateInputObjectSchema } from './objects/CarouselCreateInput.schema'
import { CarouselUncheckedCreateInputObjectSchema } from './objects/CarouselUncheckedCreateInput.schema'

export const CarouselCreateOneSchema = z.object({
  data: z.union([
    CarouselCreateInputObjectSchema,
    CarouselUncheckedCreateInputObjectSchema,
  ]),
})
