import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    availableAt: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ScheduleCountAggregateInputObjectSchema = Schema
