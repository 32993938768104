import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataMinOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    channel: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    approvedAt: z.lazy(() => SortOrderSchema).optional(),
    approvedBy: z.lazy(() => SortOrderSchema).optional(),
    prefix: z.lazy(() => SortOrderSchema).optional(),
    firstname: z.lazy(() => SortOrderSchema).optional(),
    lastname: z.lazy(() => SortOrderSchema).optional(),
    gender: z.lazy(() => SortOrderSchema).optional(),
    birthDate: z.lazy(() => SortOrderSchema).optional(),
    idCardNumber: z.lazy(() => SortOrderSchema).optional(),
    idCardPicture: z.lazy(() => SortOrderSchema).optional(),
    selfieIdCardPicture: z.lazy(() => SortOrderSchema).optional(),
    passportNumber: z.lazy(() => SortOrderSchema).optional(),
    passportPicture: z.lazy(() => SortOrderSchema).optional(),
    selfiePassportPicture: z.lazy(() => SortOrderSchema).optional(),
    rejectAt: z.lazy(() => SortOrderSchema).optional(),
    rejectBy: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const KycDataMinOrderByAggregateInputObjectSchema = Schema
