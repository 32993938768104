import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    procedureItemId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    installmentTerm: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    flashsalePrice: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    priceAbsorbedBySkinX: z.literal(true).optional(),
    priceAbsorbedByPartner: z.literal(true).optional(),
    commission: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    vat: z.literal(true).optional(),
    vatRate: z.literal(true).optional(),
    netIncome: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    v2StoreVoucherId: z.literal(true).optional(),
  })
  .strict()

export const ProcedureOrderSumAggregateInputObjectSchema = Schema
