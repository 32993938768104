import { z } from 'zod'
import { OrderCreateWithoutReceiptInputObjectSchema } from './OrderCreateWithoutReceiptInput.schema'
import { OrderUncheckedCreateWithoutReceiptInputObjectSchema } from './OrderUncheckedCreateWithoutReceiptInput.schema'
import { OrderCreateOrConnectWithoutReceiptInputObjectSchema } from './OrderCreateOrConnectWithoutReceiptInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutReceiptInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutReceiptInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutReceiptInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutReceiptInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutReceiptInputObjectSchema = Schema
