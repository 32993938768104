import { z } from 'zod'
import { VariantWarehouseOrderByWithRelationInputObjectSchema } from './objects/VariantWarehouseOrderByWithRelationInput.schema'
import { VariantWarehouseWhereInputObjectSchema } from './objects/VariantWarehouseWhereInput.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './objects/VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseCountAggregateInputObjectSchema } from './objects/VariantWarehouseCountAggregateInput.schema'
import { VariantWarehouseMinAggregateInputObjectSchema } from './objects/VariantWarehouseMinAggregateInput.schema'
import { VariantWarehouseMaxAggregateInputObjectSchema } from './objects/VariantWarehouseMaxAggregateInput.schema'
import { VariantWarehouseAvgAggregateInputObjectSchema } from './objects/VariantWarehouseAvgAggregateInput.schema'
import { VariantWarehouseSumAggregateInputObjectSchema } from './objects/VariantWarehouseSumAggregateInput.schema'

export const VariantWarehouseAggregateSchema = z.object({
  orderBy: z
    .union([
      VariantWarehouseOrderByWithRelationInputObjectSchema,
      VariantWarehouseOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantWarehouseWhereInputObjectSchema.optional(),
  cursor: VariantWarehouseWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), VariantWarehouseCountAggregateInputObjectSchema])
    .optional(),
  _min: VariantWarehouseMinAggregateInputObjectSchema.optional(),
  _max: VariantWarehouseMaxAggregateInputObjectSchema.optional(),
  _avg: VariantWarehouseAvgAggregateInputObjectSchema.optional(),
  _sum: VariantWarehouseSumAggregateInputObjectSchema.optional(),
})
