import { z } from 'zod'
import { IdentityTypeSchema } from '../enums/IdentityType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityIdentityIdTypeCompoundUniqueInput> = z
  .object({
    identityId: z.string(),
    type: z.lazy(() => IdentityTypeSchema),
  })
  .strict()

export const IdentityIdentityIdTypeCompoundUniqueInputObjectSchema = Schema
