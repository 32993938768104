import { z } from 'zod'
import { TeleconsultUpdateInputObjectSchema } from './objects/TeleconsultUpdateInput.schema'
import { TeleconsultUncheckedUpdateInputObjectSchema } from './objects/TeleconsultUncheckedUpdateInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './objects/TeleconsultWhereUniqueInput.schema'

export const TeleconsultUpdateOneSchema = z.object({
  data: z.union([
    TeleconsultUpdateInputObjectSchema,
    TeleconsultUncheckedUpdateInputObjectSchema,
  ]),
  where: TeleconsultWhereUniqueInputObjectSchema,
})
