import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    createdBy: z.literal(true).optional(),
    updatedBy: z.literal(true).optional(),
    active: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    endAt: z.literal(true).optional(),
    searchField: z.literal(true).optional(),
  })
  .strict()

export const CarouselMaxAggregateInputObjectSchema = Schema
