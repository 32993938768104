import {
  DiscountItemTypeSchema,
  DiscountScopeSchema,
  DiscountSettingModel,
} from '@ssch-backend/coupons'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

const DiscountSettingInput = DiscountSettingModel.pick({
  itemType: true,
  items: true,
  scope: true,
  settingGroupId: true,
}).extend({
  discountItemType: DiscountItemTypeSchema,
  flashsaleCondition: DiscountItemTypeSchema.optional(),
})

type DiscountSettingInput = z.infer<typeof DiscountSettingInput>

export const transformCreateCouponGroup = async (form: {
  prefix?: string
  code?: string
  isPublic?: boolean
  discountSetting: DiscountSettingInput[]
}) => {
  const result: { [key: string]: unknown } = form

  if (form.prefix) {
    result.prefix = form.prefix.trim()
  } else if (form.code) {
    result.code = form.code.trim()
  }
  // If coupon is Unique isPublic field likely to undefined
  result.isPublic = form.isPublic || false

  const discountSettings = form.discountSetting.flatMap((setting) => {
    const { discountItemType, flashsaleCondition, ...rest } = setting
    const settingGroupId = uuidv4() // AND condition
    const discountSetting: Omit<
      DiscountSettingInput,
      'discountItemType' | 'flashsaleCondition'
    >[] = []

    // Doctor type
    if (!flashsaleCondition) {
      return [{ ...rest }]
    }

    // Check is Only flash sale item and no type specified.
    const onlyFlashsaleAndNoType =
      (setting.itemType === DiscountItemTypeSchema.Values.VARIANT ||
        setting.itemType === DiscountItemTypeSchema.Values.PROCEDURE) &&
      flashsaleCondition === [setting.itemType, 'FLASHSALE'].join('_')
    const notIncludeFlashsale = flashsaleCondition !== setting.discountItemType
    if (!onlyFlashsaleAndNoType) {
      discountSetting.push({
        ...rest,
        settingGroupId: notIncludeFlashsale ? settingGroupId : null,
      })
    }
    // This push is pushed เฉพาะ flash sale and ไม่รวม flashsale
    if (notIncludeFlashsale) {
      discountSetting.push({
        itemType: flashsaleCondition,
        scope: onlyFlashsaleAndNoType
          ? setting.scope
          : DiscountScopeSchema.Values.ALL,
        items: onlyFlashsaleAndNoType ? setting.items : [],
        settingGroupId: onlyFlashsaleAndNoType ? null : settingGroupId,
      })
    }
    return discountSetting
  })

  result.discountSetting = discountSettings
  // Temporary logic. In case of conditionSettings are same as discountSettings situation
  result.conditionSetting = discountSettings

  return result
}
