import { TranslationMessages } from 'react-admin'

export const i18nTh: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'เพิ่มตัวกรอง',
      add: 'เพิ่ม',
      back: 'กลับ',
      bulk_actions: 'เลือก 1 รายการ |||| เลือก %{smart_count} รายการ',
      cancel: 'ยกเลิก',
      reset: 'ล้าง',
      clear_input_value: 'ล้างค่า',
      clone: 'ทำสำเนา',
      confirm: 'ยืนยัน',
      create: 'สร้าง',
      create_item: 'สร้าง %{item}',
      delete: 'ลบ',
      edit: 'แก้ไข',
      import: 'นำเข้า',
      export: 'ส่งออก',
      list: 'รายการ',
      refresh: 'รีเฟรช',
      remove_filter: 'ลบตัวกรอง',
      remove_all_filters: 'ลบตัวกรองทั้งหมด',
      remove: 'ลบ',
      save: 'บันทึก',
      search: 'ค้นหา',
      select_all: 'เลือกทั้งหมด',
      select_row: 'เลือกแถวนี้',
      show: 'แสดง',
      sort: 'เรียงลำดับ',
      undo: 'ยกเลิก',
      unselect: 'ยกเลิกการเลือก',
      expand: 'ขยาย',
      close: 'ปิด',
      open_menu: 'เปิดเมนู',
      close_menu: 'ปิดเมนู',
      update: 'อัพเดท',
      upload_file: 'อัพโหลดไฟล์',
      move_up: 'เลื่อนขึ้น',
      move_down: 'เลื่อนลง',
      open: 'เปิด',
      toggle_theme: 'สลับธีม',
      select_columns: 'เลือกคอลัมน์',
      clear_array_input: 'Clear the list',
    },
    boolean: {
      true: 'ใช่',
      false: 'ไม่ใช่',
      null: ' ',
    },
    page: {
      create: 'สร้าง %{name}',
      dashboard: 'แดชบอร์ด',
      edit: '%{name} %{recordRepresentation}',
      error: 'เกิดข้อผิดพลาด',
      list: '%{name}',
      loading: 'กำลังโหลด',
      not_found: 'ไม่พบ',
      show: '%{name} %{recordRepresentation}',
      empty: 'ยังไม่มี %{name}',
      invite: 'คุณต้องการเพิ่มหรือไม่?',
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.',
      },
      video: {
        upload_several: 'Drop some videos to upload, or click to select one.',
        upload_single: 'Drop a video to upload, or click to select it.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing:
          'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'ซ่อนรหัสผ่าน',
        toggle_hidden: 'แสดงรหัสผ่าน',
      },
    },
    message: {
      about: 'เกี่ยวกับ',
      are_you_sure: 'คุณแน่ใจหรือไม่?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      bulk_update_content:
        'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title: 'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'คุณแน่ใจหรือไม่ที่จะลบรายการนี้?',
      delete_title: 'ลบ %{name} #%{id}',
      details: 'รายละเอียด',
      error: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์',
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'ไม่',
      not_found: 'URL ที่คุณพิมพ์ไม่ถูกต้องหรือลิงค์ที่คุณคลิกไม่ถูกต้อง',
      yes: 'ใช่',
      unsaved_changes:
        'บางอย่างยังไม่ได้ถูกบันทึก คุณแน่ใจหรือไม่ที่จะละทิ้งการเปลี่ยนแปลง?',
      auth_error: 'A error occurred while validating the authentication token.',
      clear_array_input: 'Are you sure you want to clear the whole list?',
    },
    navigation: {
      no_results: 'ไม่พบผลลัพธ์',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      partial_page_range_info:
        '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
      current_page: 'Page %{page}',
      page: 'Go to page %{page}',
      first: 'Go to first page',
      last: 'Go to last page',
      next: 'Go to next page',
      previous: 'Go to previous page',
      page_rows_per_page: 'Rows per page:',
      skip_nav: 'Skip to content',
    },
    sort: {
      sort_by: 'เรียงโดย %{field} %{order}',
      ASC: 'มากไปน้อย',
      DESC: 'น้อยไปมาก',
    },
    auth: {
      auth_check_error: 'กรุณาเข้าสู่ระบบเพื่อดำเนินการต่อ',
      user_menu: 'โปรไฟล์',
      username: 'Username',
      password: 'Password',
      sign_in: 'เข้าสู่ระบบ',
      sign_in_error: 'เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
      logout: 'ออกจากระบบ',
    },
    notification: {
      updated:
        'อัพเดทรายการเรียบร้อย |||| อัพเดท %{smart_count} รายการเรียบร้อย',
      created: 'สร้างรายการเรียบร้อย',
      deleted: 'ลบรายการเรียบร้อย |||| ลบ %{smart_count} รายการเรียบร้อย',
      bad_item: 'รายการไม่ถูกต้อง',
      item_doesnt_exist: 'ไม่พบรายการ',
      http_error: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'ยกเลิกการดำเนินการเรียบร้อย',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: 'จำเป็น',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'ต้องมากกว่าหรือเท่ากับ %{min}',
      maxValue: 'ต้องน้อยกว่าหรือเท่ากับ %{max}',
      number: 'Must be a number',
      email: 'Must be a valid email',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
      file_invalid: 'File has some errors',
    },
    saved_queries: {
      label: 'Saved queries',
      query_name: 'Query name',
      new_label: 'Save current query...',
      new_dialog_title: 'Save current query as',
      remove_label: 'Remove saved query',
      remove_label_with_name: 'Remove query "%{name}"',
      remove_dialog_title: 'Remove saved query?',
      remove_message:
        'Are you sure you want to remove that item from your list of saved queries?',
      help: 'Filter the list and save this query for later',
    },
    configurable: {
      customize: 'Customize',
      configureMode: 'Configure this page',
      inspector: {
        title: 'Inspector',
        content: 'Hover the application UI elements to configure them',
        reset: 'Reset Settings',
        hideAll: 'Hide All',
        showAll: 'Show All',
      },
      SimpleList: {
        title: 'List',
        primaryText: 'Primary text',
        secondaryText: 'Secondary text',
        tertiaryText: 'Tertiary text',
      },
      Datagrid: {
        title: 'Datagrid',
        unlabeled: 'Unlabeled column #%{column}',
      },
      SimpleForm: {
        title: 'Form',
        unlabeled: 'Unlabeled input #%{input}',
      },
    },
  },
  upload: {
    error: 'File upload error',
  },
  operator: {
    and: 'และ',
    or: 'หรือ',
  },
  resources: {
    doctor: {
      action: {
        export_review: 'ดาวน์โหลดรีวิวแพทย์',
      },
      title: {
        export_review: 'ดาวน์โหลดรีวิวแพทย์',
      },
    },
    doctorScheduler: {
      fields: {
        from: 'ตั้งแต่',
        to: 'ถึง',
      },
      action: {
        create_slot: 'สร้าง Slot',
      },
      title: {
        export_modal: 'ส่งออกไฟล์ (excel)',
        appointment: 'นัดหมาย',
        search_doctor: 'ค้นหาชื่อหมอ',
      },
    },
    patient: {
      fields: {
        id: 'ID',
        firstname: 'ชื่อจริง',
        lastname: 'นามสกุล',
        gender: 'เพศ',
        nickname: 'ชื่อเล่น',
        birthDate: 'วันเกิด (วว/ดด/ปปปป)',
        phoneNumber: 'เบอร์โทรศัพท์',
        nameOrPid: 'ค้นหา PID/ชื่อ/นามสกุล',
        picture: {
          src: 'รูปภาพ',
        },
      },
      title: {
        general: 'ข้อมูลเบื้องต้น',
        identity: 'ข้อมูลตัวตน',
      },
    },
    product: {
      title: {
        product: 'สินค้า',
        import_modal: 'อัพโหลดสินค้า (excel)',
      },
      action: {
        validate_xlsx_file: 'ตรวจสอบ',
        download_import_template: 'โหลดตัวอย่างไฟล์',
      },
    },
    productBrand: {
      fields: {
        id: 'ID',
        logo: {
          src: 'โลโก้',
        },
        name: 'ชื่อ',
        isShow: 'แสดงผล',
        priority: 'ลำดับ',
        coporateName: 'ชื่อบริษัท',
        countryName: 'ประเทศ',
      },
      action: {
        download_import_template: 'โหลดตัวอย่างไฟล์',
        import_button: 'อัพเดทแบรนด์',
      },
      title: {
        brand: 'แบรนด์',
        import_modal: 'อัพโหลดแบรนด์ (excel)',
      },
    },
    procedure: {
      fields: {
        nameTr: {
          th: 'ชื่อดีลหัตถการ (TH)',
          en: 'ชื่อดีลหัตถการ (EN)',
        },
        categories: 'หมวดหมู่',
        tagPrice: 'ราคาป้าย',
        sellingPrice: 'ราคาขาย',
        cost: 'ราคาต้นทุน',
        commissionRate: 'อัตราค่าคอมมิชชั่น',
        expireType: 'ประเภทวันหมดอายุ',
        expireAt: 'ใช้ได้ถึงวันที่',
        expireDurationDay: 'หมดอายุใน',
        priority: 'Priority',
        descriptionTr: {
          th: 'รายละเอียดการบริการ (TH)',
          en: 'รายละเอียดการบริการ (EN)',
        },
        technologyTr: {
          th: 'เทคโนโลยีที่ใช้ (TH)',
          en: 'เทคโนโลยีที่ใช้ (EN)',
        },
        methodOfUseTr: {
          th: 'วิธีการใช้งาน (TH)',
          en: 'วิธีการใช้งาน (EN)',
        },
        usageTr: {
          th: 'ปริมาณที่ใช้ (TH)',
          en: 'ปริมาณที่ใช้ (EN)',
        },
        resultPeriodTr: {
          th: 'ระยะเวลาเห็นผล (TH)',
          en: 'ระยะเวลาเห็นผล (EN)',
        },
        preparationTr: {
          th: 'ขั้นตอนการเตรียมตัว (TH)',
          en: 'ขั้นตอนการเตรียมตัว (EN)',
        },
        conditionTr: {
          th: 'เงื่อนไข (TH)',
          en: 'เงื่อนไข (EN)',
        },
        partner: 'คลินิก',
        isTreatedByDoctor: 'รักษาโดยแพทย์',
        searchable: 'ค้นหาได้',
        active: 'Active',
      },
      title: {
        information_section: 'ข้อมูลดีลหัตถการ',
        property_section: 'คุณสมบัติ',
        picture_section: 'รูปภาพ',
        partner_section: 'คลินิก และสาขา',
        branches: 'สาขา',
        procedure: 'ดีลหัตถการ',
        expireType: {
          DATE: 'ใช้ได้ถึงวันที่',
          DURATION: 'นับจากวันที่ซื้อ',
        },
      },
      validation: {
        select_clinic: 'โปรดเลือกคลินิก',
        no_branch: 'ไม่มีสาขา',
      },
    },
    flashsaleProduct: {
      fields: {
        name: 'ชื่อแคมเปญ',
        startAt: 'วันเริ่ม',
        endAt: 'วันสุดท้าย',
        description: 'คำอธิบาย',
        replace: 'แทนที่ข้อมูลเดิม',
      },
      title: {
        campaign_section: 'Flash Sale (สินค้า)',
      },
      action: {
        import_button: 'นำเข้าไฟล์สินค้า',
        upload_file: 'อัพโหลดไฟล์',
        download_import_template: 'โหลดตัวอย่างไฟล์',
        add_flashsale_item: 'เพิ่ม SKU ไอเทม',
      },
    },
    flashsaleProcedure: {
      fields: {
        name: 'ชื่อแคมเปญ',
        startAt: 'วันเริ่ม',
        endAt: 'วันสุดท้าย',
        description: 'คำอธิบาย',
        replace: 'แทนที่ข้อมูลเดิม',
      },
      title: {
        campaign_section: 'Flash Sale (ดีลหัตถการ)',
      },
      action: {
        import_button: 'นำเข้าไฟล์ดีลหัตถการ',
        upload_file: 'อัพโหลดไฟล์',
        download_import_template: 'โหลดตัวอย่างไฟล์',
        add_flashsale_item: 'เพิ่มดีลหัตถการ',
      },
    },
    flashsaleItemProduct: {
      fields: {
        sku: 'SKU',
      },
      title: {
        product_item: 'Flash Sale item',
        import_modal: 'อัพโหลด Flash Sale item (excel)',
      },
    },
    flashsaleItemProcedure: {
      fields: {
        procedure: 'ดีลหัตถการ',
        price: 'ราคา',
        saleLimit: 'โควต้า',
        priority: 'Priority',
        priceAbsorbedByPartner: 'Absorbed โดยคลินิก',
        priceAbsorbedBySkinX: 'Absorbed โดย SkinX',
        id: 'ID',
        cost: 'ราคาทุน',
        sellingPrice: 'ราคาขาย',
        tagPrice: 'ราคาป้าย',
        nameTr: 'ชื่อดีลหัตถการ',
        active: 'Active',
      },
      title: {
        procedure_item: 'Flash Sale item',
        procedure_detail: 'รายละเอียดดีลหัตถการ',
        add_flashsale: 'เพิ่มแฟลชเซลล์ไอเทม (ดีลหัตถการ)',
        import_modal: 'อัพโหลด Flash Sale item (excel)',
      },
    },
    couponGroup: {
      fields: {
        prefix: 'Unique',
        startAt: 'วันที่เริ่ม',
        expireAt: 'วันสุดท้าย',
        codeType: 'รูปแบบโค้ด',
        code: 'Code',
        titleTr: 'ชื่อ',
        descriptionTr: 'รายละเอียด',
        type: 'ประเภทคูปอง',
        isPublic: 'คูปองสาธารณะ',
        isOnlyNewPatient: 'เฉพาะผู้ใช้ใหม่',
        discount: 'ส่วนลด',
        minTotal: 'ยอดซื้อขั้นต่ำ (บาท)',
        maxDiscount: 'ลดสูงสุดไม่เกิน (บาท)',
        note: 'โน้ต',
        quota: 'จำนวนคูปอง',
        quotaPerUser: 'จำกัดการใช้​ (ใบ/คน)',
        tags: 'คูปองแท็ก (TH, EN)',
        discountSetting: {
          discountItemType: 'ใช้ลด',
          itemType: 'ประเภท',
          flashsaleCondition: 'เงื่อนไข Flash Sale',
          scope: 'เงื่อนไข',
          items: 'รายการ',
        },
        conditionSetting: {
          itemType: 'ใช้ได้กับ',
          scope: 'เงื่อนไข',
          items: 'รายการ',
        },
        picture: 'ไอคอน',
        inPayment: 'กำลังชำระ',
        used: 'ใช้แล้ว',
        createdAt: 'สร้างเมื่อ',
      },
      icon: {
        shipping: 'ขนส่ง',
        pill: 'ยา',
        sx: 'SX',
        flashsale: 'Flash Sale',
        doctor: 'แพทย์',
        ticket: 'ดีลหัตถการ',
      },
      title: {
        generate_coupon: 'สร้างคูปอง',
        discount_setting: 'ส่วนลด',
        condition_setting: 'เงื่อนไข',
        icon: 'ไอคอน',
        coupon: 'คูปอง',
        coupon_preview: 'ภาพตัวอย่าง',
        coupon_title: 'ชื่อคูปอง',
        coupon_expireAt: 'หมดอายุ',
        couponType: {
          GENERAL: 'ทั่วไป',
          SHIPPING: 'การจัดส่ง',
          COIN_CASHBACK: 'คอยน์คืน',
        },
        discountType: {
          FIXED: 'จำนวน',
          PERCENT: 'เปอร์เซ็นต์',
        },
        scope: {
          ALL: 'ทั้งหมด',
          ONLY: 'ใช้ได้เฉพาะ',
          EXCEPT: 'ใช้ได้ทั้งหมดยกเว้น',
        },
        shippingType: {
          NORMAL: 'ส่งธรรมดา',
          EXPRESS: 'ส่งด่วน',
        },
        discountItemType: {
          DOCTOR: 'แพทย์',
          VARIANT: 'สินค้า',
          PROCEDURE: 'ดีลหัตถการ',
          SHIPPING_TYPE: 'ประเภทขนส่ง',
          SHIPPING_PROVIDER: 'ชื่อขนส่ง',
        },
        itemType: {
          DOCTOR: 'ไม่มี',
          DOCTOR_CATEGORY: 'หมวดหมู่',
          VARIANT: 'ไม่มี',
          VARIANT_BRAND: 'แบรนด์',
          VARIANT_CATEGORY: 'หมวดหมู่',
          PROCEDURE: 'ไม่มี',
          PROCEDURE_PARTNER: 'คลินิก/โรงพยาบาล',
          PROCEDURE_CATEGORY: 'หมวดหมู่',
        },
        flashsaleCondition: {
          VARIANT: 'ทั้งหมด',
          VARIANT_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          VARIANT_NO_FLASHSALE: 'ไม่เอา Flash Sale',
          PROCEDURE: 'ทั้งหมด',
          PROCEDURE_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'ไม่เอา Flash Sale',
        },
      },
      action: {
        tag: {
          create: 'สร้างแท็ก',
          tag_th: 'แท็ก (ภาษาไทย)',
          tag_en: 'แท็ก (ภาษาอังกฤษ)',
        },
        discount_setting: {
          add_button: 'เพิ่มรายการ',
        },
        condition_setting: {
          add_button: 'เพิ่มเงื่อนไข',
        },
      },
      validation: {
        code_type_length:
          '%{codeType} ต้องอยู่ระหว่าง %{minLength} digit ถึง %{maxLength} digits',
        start_date: 'ต้องเริ่มก่อนวันสุดท้าย',
        expire_at: 'ต้องสิ้นสุดหลังวันที่เริ่ม',
      },
    },
    couponGroupDiscountSetting: {
      fields: {
        type: 'ใช้ลด',
        scope: 'เงื่อนไข',
        items: 'รายการ',
        itemType: 'ประเภท',
      },
      title: {
        itemType: {
          DOCTOR: 'ทั้งหมด',
          DOCTOR_CATEGORY: 'หมวดหมู่',
          VARIANT: 'ทั้งหมด',
          VARIANT_BRAND: 'แบรนด์',
          VARIANT_CATEGORY: 'หมวดหมู่',
          PROCEDURE: 'ทั้งหมด',
          PROCEDURE_PARTNER: 'คลินิก/โรงพยาบาล',
          PROCEDURE_CATEGORY: 'หมวดหมู่',
          VARIANT_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          VARIANT_NO_FLASHSALE: 'ไม่เอา Flash Sale',
          PROCEDURE_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'ไม่เอา Flash Sale',
        },
      },
    },
    couponGroupConditionSetting: {
      fields: {
        type: 'ใช้ได้กับ',
        scope: 'เงื่อนไข',
        items: 'รายการ',
        itemType: 'ถ้าเป็น',
      },
      title: {
        itemType: {
          DOCTOR: 'ทั้งหมด',
          DOCTOR_CATEGORY: 'หมวดหมู่',
          VARIANT: 'ทั้งหมด',
          VARIANT_BRAND: 'แบรนด์',
          VARIANT_CATEGORY: 'หมวดหมู่',
          PROCEDURE: 'ทั้งหมด',
          PROCEDURE_PARTNER: 'คลินิก/โรงพยาบาล',
          PROCEDURE_CATEGORY: 'หมวดหมู่',
          VARIANT_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          VARIANT_NO_FLASHSALE: 'ไม่เอา Flash Sale',
          PROCEDURE_FLASHSALE: 'แค่เฉพาะ Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'ไม่เอา Flash Sale',
        },
      },
    },
    coupon: {
      fields: {
        id: 'ID',
        code: 'โค้ด',
        type: 'ประเภทคูปอง',
        titleTr: 'ชื่อ',
        descriptionTr: 'รายละเอียด',
        discount: 'ส่วนลด',
        startAt: 'วันที่เริ่ม',
        expireAt: 'วันสุดท้าย',
        usedAt: 'ถูกใช้เมื่อ',
        isPublic: 'Public',
        isOnlyNewPatient: 'เฉพาะผู้ใช้ใหม่',
        createdAt: 'สร้างเมื่อ',
      },
      action: {
        export_coupon: 'ส่งออกคูปอง',
      },
    },
    carouselProcedure: {
      fields: {
        name: 'ชื่อแคมเปญ',
        startAt: 'วันเริ่ม',
        endAt: 'วันสุดท้าย',
        replace: 'แทนที่ข้อมูลเดิม',
      },
      title: {
        campaign_section: 'Carousel (ดีลหัตถการ)',
      },
      action: {
        import_button: 'นำเข้าไฟล์ดีลหัตถการ',
        upload_file: 'อัพโหลดไฟล์',
        download_import_template: 'โหลดตัวอย่างไฟล์',
        add_carousel_item: 'เพิ่มดีลหัตถการ',
      },
    },
    carouselItemProcedure: {
      fields: {
        procedure: 'ดีลหัตถการ',
        priority: 'Priority',
        id: 'ID',
        nameTr: 'ชื่อดีลหัตถการ',
        active: 'Active',
        cost: 'ราคาทุน',
        sellingPrice: 'ราคาขาย',
        tagPrice: 'ราคาป้าย',
      },
      title: {
        procedure_item: 'Carousel item',
        procedure_detail: 'รายละเอียดดีลหัตถการ',
        add_carousel: 'เพิ่ม Carousel item (ดีลหัตถการ)',
        import_modal: 'อัพโหลด Carousel item (excel)',
      },
    },
  },
}
