import { z } from 'zod'
import { SkuWhereUniqueInputObjectSchema } from './objects/SkuWhereUniqueInput.schema'
import { SkuCreateInputObjectSchema } from './objects/SkuCreateInput.schema'
import { SkuUncheckedCreateInputObjectSchema } from './objects/SkuUncheckedCreateInput.schema'
import { SkuUpdateInputObjectSchema } from './objects/SkuUpdateInput.schema'
import { SkuUncheckedUpdateInputObjectSchema } from './objects/SkuUncheckedUpdateInput.schema'

export const SkuUpsertSchema = z.object({
  where: SkuWhereUniqueInputObjectSchema,
  create: z.union([
    SkuCreateInputObjectSchema,
    SkuUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    SkuUpdateInputObjectSchema,
    SkuUncheckedUpdateInputObjectSchema,
  ]),
})
