import { z } from 'zod'
import { CarouselItemScalarWhereInputObjectSchema } from './CarouselItemScalarWhereInput.schema'
import { CarouselItemUpdateManyMutationInputObjectSchema } from './CarouselItemUpdateManyMutationInput.schema'
import { CarouselItemUncheckedUpdateManyWithoutItemsInputObjectSchema } from './CarouselItemUncheckedUpdateManyWithoutItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpdateManyWithWhereWithoutCarouselInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => CarouselItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedUpdateManyWithoutItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemUpdateManyWithWhereWithoutCarouselInputObjectSchema =
  Schema
