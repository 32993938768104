import { z } from 'zod'
import { DeliveryLogCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedCreateWithoutDeliveryInput.schema'
import { DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateOrConnectWithoutDeliveryInput.schema'
import { DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema } from './DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInput.schema'
import { DeliveryLogCreateManyDeliveryInputEnvelopeObjectSchema } from './DeliveryLogCreateManyDeliveryInputEnvelope.schema'
import { DeliveryLogWhereUniqueInputObjectSchema } from './DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema } from './DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInput.schema'
import { DeliveryLogUpdateManyWithWhereWithoutDeliveryInputObjectSchema } from './DeliveryLogUpdateManyWithWhereWithoutDeliveryInput.schema'
import { DeliveryLogScalarWhereInputObjectSchema } from './DeliveryLogScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogUpdateManyWithoutDeliveryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryLogCreateWithoutDeliveryInputObjectSchema),
          z
            .lazy(() => DeliveryLogCreateWithoutDeliveryInputObjectSchema)
            .array(),
          z.lazy(
            () => DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DeliveryLogUpsertWithWhereUniqueWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => DeliveryLogCreateManyDeliveryInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              DeliveryLogUpdateManyWithWhereWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                DeliveryLogUpdateManyWithWhereWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => DeliveryLogScalarWhereInputObjectSchema),
          z.lazy(() => DeliveryLogScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const DeliveryLogUpdateManyWithoutDeliveryNestedInputObjectSchema =
  Schema
