import { z } from 'zod'
import { CategoryCreateWithoutProceduresInputObjectSchema } from './CategoryCreateWithoutProceduresInput.schema'
import { CategoryUncheckedCreateWithoutProceduresInputObjectSchema } from './CategoryUncheckedCreateWithoutProceduresInput.schema'
import { CategoryCreateOrConnectWithoutProceduresInputObjectSchema } from './CategoryCreateOrConnectWithoutProceduresInput.schema'
import { CategoryWhereUniqueInputObjectSchema } from './CategoryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryCreateNestedOneWithoutProceduresInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CategoryCreateWithoutProceduresInputObjectSchema),
          z.lazy(
            () => CategoryUncheckedCreateWithoutProceduresInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CategoryCreateOrConnectWithoutProceduresInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CategoryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CategoryCreateNestedOneWithoutProceduresInputObjectSchema = Schema
