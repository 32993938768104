import { z } from 'zod'
import { DeliveryLogCreateInputObjectSchema } from './objects/DeliveryLogCreateInput.schema'
import { DeliveryLogUncheckedCreateInputObjectSchema } from './objects/DeliveryLogUncheckedCreateInput.schema'

export const DeliveryLogCreateOneSchema = z.object({
  data: z.union([
    DeliveryLogCreateInputObjectSchema,
    DeliveryLogUncheckedCreateInputObjectSchema,
  ]),
})
