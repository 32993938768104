import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    name: z.literal(true).optional(),
    detail: z.literal(true).optional(),
    moreDetail: z.literal(true).optional(),
    shippingExpectation: z.literal(true).optional(),
    trackingUrl: z.literal(true).optional(),
    type: z.literal(true).optional(),
    price: z.literal(true).optional(),
    freeShippingMinimumPrice: z.literal(true).optional(),
    active: z.literal(true).optional(),
  })
  .strict()

export const ShippingProviderMinAggregateInputObjectSchema = Schema
