import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupAvgOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    discount: z.lazy(() => SortOrderSchema).optional(),
    minTotal: z.lazy(() => SortOrderSchema).optional(),
    maxDiscount: z.lazy(() => SortOrderSchema).optional(),
    quota: z.lazy(() => SortOrderSchema).optional(),
    quotaPerUser: z.lazy(() => SortOrderSchema).optional(),
    createdBy: z.lazy(() => SortOrderSchema).optional(),
    updatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2CreatedBy: z.lazy(() => SortOrderSchema).optional(),
    v2UpdatedBy: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const CouponGroupAvgOrderByAggregateInputObjectSchema = Schema
