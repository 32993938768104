import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductBannerSelect> = z
  .object({
    id: z.boolean().optional(),
    name: z.boolean().optional(),
    picture: z.boolean().optional(),
    actionUri: z.boolean().optional(),
    active: z.boolean().optional(),
    rank: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const ProductBannerSelectObjectSchema = Schema
