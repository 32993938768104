import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteOrder, RelatedOrderModel } from "./index"

export const ReceiptModel = z.object({
  receiptNumber: z.string(),
  receiptUrl: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  orderId: z.number().int(),
  receiptData: jsonSchema,
  isValid: z.boolean(),
  note: z.string().nullish(),
})

export interface CompleteReceipt extends z.infer<typeof ReceiptModel> {
  order: CompleteOrder
}

/**
 * RelatedReceiptModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedReceiptModel: z.ZodSchema<CompleteReceipt> = z.lazy(() => ReceiptModel.extend({
  order: RelatedOrderModel,
}))
