import { z } from 'zod'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema =
  Schema
