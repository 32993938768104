import { z } from 'zod'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureCreateWithoutCategoriesInputObjectSchema } from './ProcedureCreateWithoutCategoriesInput.schema'
import { ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedCreateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateOrConnectWithoutCategoriesInput> =
  z
    .object({
      where: z.lazy(() => ProcedureWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureCreateWithoutCategoriesInputObjectSchema),
        z.lazy(
          () => ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCreateOrConnectWithoutCategoriesInputObjectSchema = Schema
