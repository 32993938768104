import { z } from 'zod'
import { ProductItemOriginalScalarWhereInputObjectSchema } from './ProductItemOriginalScalarWhereInput.schema'
import { ProductItemOriginalUpdateManyMutationInputObjectSchema } from './ProductItemOriginalUpdateManyMutationInput.schema'
import { ProductItemOriginalUncheckedUpdateManyWithoutProductItemsOriginalInputObjectSchema } from './ProductItemOriginalUncheckedUpdateManyWithoutProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalUpdateManyWithWhereWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProductItemOriginalScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductItemOriginalUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductItemOriginalUncheckedUpdateManyWithoutProductItemsOriginalInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductItemOriginalUpdateManyWithWhereWithoutOrderInputObjectSchema =
  Schema
