import { z } from 'zod'
import { ProductItemOriginalSelectObjectSchema } from './ProductItemOriginalSelect.schema'
import { ProductItemOriginalIncludeObjectSchema } from './ProductItemOriginalInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalArgs> = z
  .object({
    select: z.lazy(() => ProductItemOriginalSelectObjectSchema).optional(),
    include: z.lazy(() => ProductItemOriginalIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductItemOriginalArgsObjectSchema = Schema
