import { z } from 'zod'
import { ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema } from './ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInput.schema'
import { ProcedureReviewPictureCreateManyProcedureReviewInputEnvelopeObjectSchema } from './ProcedureReviewPictureCreateManyProcedureReviewInputEnvelope.schema'
import { ProcedureReviewPictureWhereUniqueInputObjectSchema } from './ProcedureReviewPictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureCreateNestedManyWithoutProcedureReviewInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureCreateWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureUncheckedCreateWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewPictureCreateOrConnectWithoutProcedureReviewInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            ProcedureReviewPictureCreateManyProcedureReviewInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema),
          z
            .lazy(() => ProcedureReviewPictureWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewPictureCreateNestedManyWithoutProcedureReviewInputObjectSchema =
  Schema
