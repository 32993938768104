import { z } from 'zod'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { NestedEnumOrderTypeFilterObjectSchema } from './NestedEnumOrderTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumOrderTypeFilter> = z
  .object({
    equals: z.lazy(() => OrderTypeSchema).optional(),
    in: z
      .lazy(() => OrderTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => OrderTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => OrderTypeSchema),
        z.lazy(() => NestedEnumOrderTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumOrderTypeFilterObjectSchema = Schema
