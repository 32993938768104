import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleCreateWithoutItemsInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    createdBy: z.number().optional(),
    updatedBy: z.number().optional(),
    name: z.string(),
    description: z.string().optional().nullable(),
    startAt: z.date(),
    endAt: z.date(),
    active: z.boolean().optional(),
  })
  .strict()

export const FlashsaleCreateWithoutItemsInputObjectSchema = Schema
