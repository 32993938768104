import { z } from 'zod'
import { SuggestedProductItemUpdateInputObjectSchema } from './objects/SuggestedProductItemUpdateInput.schema'
import { SuggestedProductItemUncheckedUpdateInputObjectSchema } from './objects/SuggestedProductItemUncheckedUpdateInput.schema'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemWhereUniqueInput.schema'

export const SuggestedProductItemUpdateOneSchema = z.object({
  data: z.union([
    SuggestedProductItemUpdateInputObjectSchema,
    SuggestedProductItemUncheckedUpdateInputObjectSchema,
  ]),
  where: SuggestedProductItemWhereUniqueInputObjectSchema,
})
