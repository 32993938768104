import { z } from 'zod'
import { PatientCreateWithoutIdentitiesInputObjectSchema } from './PatientCreateWithoutIdentitiesInput.schema'
import { PatientUncheckedCreateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedCreateWithoutIdentitiesInput.schema'
import { PatientCreateOrConnectWithoutIdentitiesInputObjectSchema } from './PatientCreateOrConnectWithoutIdentitiesInput.schema'
import { PatientUpsertWithoutIdentitiesInputObjectSchema } from './PatientUpsertWithoutIdentitiesInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutIdentitiesInputObjectSchema } from './PatientUpdateWithoutIdentitiesInput.schema'
import { PatientUncheckedUpdateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedUpdateWithoutIdentitiesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutIdentitiesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutIdentitiesInputObjectSchema),
          z.lazy(
            () => PatientUncheckedCreateWithoutIdentitiesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PatientCreateOrConnectWithoutIdentitiesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutIdentitiesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutIdentitiesInputObjectSchema),
          z.lazy(
            () => PatientUncheckedUpdateWithoutIdentitiesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutIdentitiesNestedInputObjectSchema =
  Schema
