import { Box, Typography } from '@mui/material'
import {
  Datagrid,
  List,
  Pagination,
  ReferenceOneField,
  TextField,
} from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'
import { ExportAppointmentReportButton } from '../doctorScheduler/exportAppointmentReportButton'
import { AppointmentStatusField } from './list-section/appointmentStatus'
import { AppointmentTypeField } from './list-section/appointmentType'
import { FilterListForm } from './list-section/filterlistForm'
import { FullnameField } from './list-section/fullname'

const rowsPerPage = [5, 10, 20]

const AppointmentListHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        ข้อมูลนัดหมาย
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <ExportAppointmentReportButton />
      </Box>
    </Box>
  )
}

const AppointmentListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}></Box>
    </Box>
  )
}

export const AppointmentList = () => {
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <AppointmentListHeader />
      <List
        disableSyncWithLocation
        actions={<AppointmentListAction />}
        sort={{ field: 'appointmentAt', order: 'DESC' }}
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
      >
        <Datagrid
          bulkActionButtons={false}
          size="small"
          rowClick={(_id, _resource, record) => {
            window.open(`/appointment/${record.id}`, '_blank')
            return false
          }}
          sx={{
            '& .RaDatagrid-headerCell': {
              alignItems: 'baseline',
              whiteSpace: 'nowrap',
            },
            '& .RaDatagrid-rowCell': {
              whiteSpace: 'nowrap',
            },
            '& .column-cancelDetail': {
              whiteSpace: 'break-spaces',
            },
          }}
        >
          <TextField source="id" label="ID" sortable={false} />
          <AppointmentTypeField source="type" label="Type" sortable={false} />
          <ReferenceOneField
            label="Doctor name"
            reference="doctor"
            target="id"
            source="doctorId"
            sortable={false}
          >
            <FullnameField label="Doctor name" sortable={false} />
          </ReferenceOneField>
          <ReferenceOneField
            label="Patient name"
            reference="patient"
            target="id"
            source="patientId"
            sortable={false}
          >
            <FullnameField label="Patient name" sortable={false} />
          </ReferenceOneField>
          <ReferenceOneField
            label="Telephone"
            reference="patientIdentity"
            target="patientId"
            source="patientId"
            filter={{ type_equals: 'PHONE' }}
            sortable={false}
          >
            <TextField label="Telephone" source="identityId" />
          </ReferenceOneField>
          <LocaleDateField
            label="Appointment at"
            source="appointmentAt"
            options={{ day: 'numeric', month: 'long', year: 'numeric' }}
            showTime
            sortable={false}
          />
          <LocaleDateField
            label="Create at"
            source="createdAt"
            options={{ day: 'numeric', month: 'long', year: 'numeric' }}
            sortable={false}
          />
          <AppointmentStatusField
            source="status"
            label="Status"
            sortable={false}
          />
          <LocaleDateField
            source="cancelAt"
            label="Cancel at"
            options={{ day: 'numeric', month: 'long', year: 'numeric' }}
            sortable={false}
          />
          <TextField
            source="cancelDetail"
            label="Cancel detail"
            sortable={false}
          />
        </Datagrid>
      </List>
    </Box>
  )
}
