import { z } from 'zod'
import { DoctorItemArgsObjectSchema } from './DoctorItemArgs.schema'
import { ProductItemOriginalFindManySchema } from '../findManyProductItemOriginal.schema'
import { ProductItemFindManySchema } from '../findManyProductItem.schema'
import { ProcedureItemFindManySchema } from '../findManyProcedureItem.schema'
import { CouponFindManySchema } from '../findManyCoupon.schema'
import { ShippingProviderArgsObjectSchema } from './ShippingProviderArgs.schema'
import { PaymentFindManySchema } from '../findManyPayment.schema'
import { RefundFindManySchema } from '../findManyRefund.schema'
import { DeliveryFindManySchema } from '../findManyDelivery.schema'
import { HealthInfoArgsObjectSchema } from './HealthInfoArgs.schema'
import { ReceiptArgsObjectSchema } from './ReceiptArgs.schema'
import { OrderCountOutputTypeArgsObjectSchema } from './OrderCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderInclude> = z
  .object({
    doctorItem: z
      .union([z.boolean(), z.lazy(() => DoctorItemArgsObjectSchema)])
      .optional(),
    productItemsOriginal: z
      .union([z.boolean(), z.lazy(() => ProductItemOriginalFindManySchema)])
      .optional(),
    productItems: z
      .union([z.boolean(), z.lazy(() => ProductItemFindManySchema)])
      .optional(),
    procedureItems: z
      .union([z.boolean(), z.lazy(() => ProcedureItemFindManySchema)])
      .optional(),
    coupons: z
      .union([z.boolean(), z.lazy(() => CouponFindManySchema)])
      .optional(),
    shippingProvider: z
      .union([z.boolean(), z.lazy(() => ShippingProviderArgsObjectSchema)])
      .optional(),
    payments: z
      .union([z.boolean(), z.lazy(() => PaymentFindManySchema)])
      .optional(),
    refunds: z
      .union([z.boolean(), z.lazy(() => RefundFindManySchema)])
      .optional(),
    deliveries: z
      .union([z.boolean(), z.lazy(() => DeliveryFindManySchema)])
      .optional(),
    healthInfo: z
      .union([z.boolean(), z.lazy(() => HealthInfoArgsObjectSchema)])
      .optional(),
    receipt: z
      .union([z.boolean(), z.lazy(() => ReceiptArgsObjectSchema)])
      .optional(),
    _count: z
      .union([z.boolean(), z.lazy(() => OrderCountOutputTypeArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const OrderIncludeObjectSchema = Schema
