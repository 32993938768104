import { z } from 'zod'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumNotificationActionTypeFilterObjectSchema } from './NestedEnumNotificationActionTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumNotificationActionTypeWithAggregatesFilter> =
  z
    .object({
      equals: z.lazy(() => NotificationActionTypeSchema).optional(),
      in: z
        .lazy(() => NotificationActionTypeSchema)
        .array()
        .optional(),
      notIn: z
        .lazy(() => NotificationActionTypeSchema)
        .array()
        .optional(),
      not: z
        .union([
          z.lazy(() => NotificationActionTypeSchema),
          z.lazy(
            () =>
              NestedEnumNotificationActionTypeWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional(),
      _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumNotificationActionTypeFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumNotificationActionTypeFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumNotificationActionTypeWithAggregatesFilterObjectSchema =
  Schema
