import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemCountAggregateInputType> =
  z
    .object({
      id: z.literal(true).optional(),
      caseSummaryId: z.literal(true).optional(),
      procedureId: z.literal(true).optional(),
      createdAt: z.literal(true).optional(),
      updatedAt: z.literal(true).optional(),
      amount: z.literal(true).optional(),
      note: z.literal(true).optional(),
      _all: z.literal(true).optional(),
    })
    .strict()

export const SuggestedProcedureItemCountAggregateInputObjectSchema = Schema
