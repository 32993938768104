import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { VariantWarehouseCountOrderByAggregateInputObjectSchema } from './VariantWarehouseCountOrderByAggregateInput.schema'
import { VariantWarehouseAvgOrderByAggregateInputObjectSchema } from './VariantWarehouseAvgOrderByAggregateInput.schema'
import { VariantWarehouseMaxOrderByAggregateInputObjectSchema } from './VariantWarehouseMaxOrderByAggregateInput.schema'
import { VariantWarehouseMinOrderByAggregateInputObjectSchema } from './VariantWarehouseMinOrderByAggregateInput.schema'
import { VariantWarehouseSumOrderByAggregateInputObjectSchema } from './VariantWarehouseSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    warehouseId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => VariantWarehouseCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => VariantWarehouseAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => VariantWarehouseMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => VariantWarehouseMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => VariantWarehouseSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const VariantWarehouseOrderByWithAggregationInputObjectSchema = Schema
