import { z } from 'zod'
import { CasePictureOrderByWithRelationInputObjectSchema } from './objects/CasePictureOrderByWithRelationInput.schema'
import { CasePictureWhereInputObjectSchema } from './objects/CasePictureWhereInput.schema'
import { CasePictureWhereUniqueInputObjectSchema } from './objects/CasePictureWhereUniqueInput.schema'
import { CasePictureCountAggregateInputObjectSchema } from './objects/CasePictureCountAggregateInput.schema'
import { CasePictureMinAggregateInputObjectSchema } from './objects/CasePictureMinAggregateInput.schema'
import { CasePictureMaxAggregateInputObjectSchema } from './objects/CasePictureMaxAggregateInput.schema'
import { CasePictureAvgAggregateInputObjectSchema } from './objects/CasePictureAvgAggregateInput.schema'
import { CasePictureSumAggregateInputObjectSchema } from './objects/CasePictureSumAggregateInput.schema'

export const CasePictureAggregateSchema = z.object({
  orderBy: z
    .union([
      CasePictureOrderByWithRelationInputObjectSchema,
      CasePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CasePictureWhereInputObjectSchema.optional(),
  cursor: CasePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CasePictureCountAggregateInputObjectSchema])
    .optional(),
  _min: CasePictureMinAggregateInputObjectSchema.optional(),
  _max: CasePictureMaxAggregateInputObjectSchema.optional(),
  _avg: CasePictureAvgAggregateInputObjectSchema.optional(),
  _sum: CasePictureSumAggregateInputObjectSchema.optional(),
})
