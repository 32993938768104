import { z } from 'zod'
import { CaseCreateWithoutTeleconsultInputObjectSchema } from './CaseCreateWithoutTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutTeleconsultInput.schema'
import { CaseCreateOrConnectWithoutTeleconsultInputObjectSchema } from './CaseCreateOrConnectWithoutTeleconsultInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateNestedOneWithoutTeleconsultInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutTeleconsultInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutTeleconsultInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutTeleconsultInputObjectSchema)
      .optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const CaseCreateNestedOneWithoutTeleconsultInputObjectSchema = Schema
