import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => DeliveryLogScalarWhereInputObjectSchema),
        z.lazy(() => DeliveryLogScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => DeliveryLogScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => DeliveryLogScalarWhereInputObjectSchema),
        z.lazy(() => DeliveryLogScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    deliveryId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    statusCode: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    statusText: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    statusDate: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    rawData: z.lazy(() => JsonFilterObjectSchema).optional(),
  })
  .strict()

export const DeliveryLogScalarWhereInputObjectSchema = Schema
