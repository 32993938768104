import { z } from 'zod'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './objects/ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaCreateInputObjectSchema } from './objects/ExpressShippingAreaCreateInput.schema'
import { ExpressShippingAreaUncheckedCreateInputObjectSchema } from './objects/ExpressShippingAreaUncheckedCreateInput.schema'
import { ExpressShippingAreaUpdateInputObjectSchema } from './objects/ExpressShippingAreaUpdateInput.schema'
import { ExpressShippingAreaUncheckedUpdateInputObjectSchema } from './objects/ExpressShippingAreaUncheckedUpdateInput.schema'

export const ExpressShippingAreaUpsertSchema = z.object({
  where: ExpressShippingAreaWhereUniqueInputObjectSchema,
  create: z.union([
    ExpressShippingAreaCreateInputObjectSchema,
    ExpressShippingAreaUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ExpressShippingAreaUpdateInputObjectSchema,
    ExpressShippingAreaUncheckedUpdateInputObjectSchema,
  ]),
})
