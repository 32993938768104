import { Box, Card, CardContent, Divider, Grid } from '@mui/material'
import { useState } from 'react'
import {
  ReferenceOneField,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { LoadingBackdrop } from '../../components/LoadingBackdrop'
import { LocaleDateField } from '../../components/LocaleDateField'
import { RejectButton } from './edit-section/buttonReject'
import { SaveAndApproveButton } from './edit-section/buttonSaveAndApprove'
import { ImageSection } from './edit-section/image'
import { TextSection } from './edit-section/text'
import { transformPatientKycData } from './transform'

const PatientKycDataEditToolbar = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { getValues } = useFormContext()

  const canReject = () => {
    let canReject = false
    const { ...data } = getValues()

    if (data?.validator) {
      for (const value of Object.values(data.validator)) {
        if (value === '0') {
          canReject = true
          break
        }
      }
    }

    return canReject
  }

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <LoadingBackdrop open={isLoading} />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="patientKycData" />
        <SaveButton alwaysEnable />
        {getValues()?.status === 'PENDING' && [
          <SaveAndApproveButton
            disabled={canReject()}
            setIsLoading={setIsLoading}
          />,
          <RejectButton disabled={!canReject()} setIsLoading={setIsLoading} />,
        ]}
      </Box>
    </Toolbar>
  )
}

const PatientKycDataStatus = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <b>Channel</b> <TextField source="channel" />
        </Box>
        <Box>
          <b>Update Status Date</b>{' '}
          <LocaleDateField
            source="updatedAt"
            label="Update Status date"
            showTime
            sortable={false}
          />
        </Box>
        <Box>
          <b>เบอร์โทรศัพท์</b>{' '}
          <ReferenceOneField
            label="Tel."
            reference="patientIdentity"
            target="patientId"
            source="patientId"
            filter={{ type_equals: 'PHONE' }}
            sortable={false}
          >
            <TextField source="identityId" />
          </ReferenceOneField>
        </Box>
      </Box>
      <Divider sx={{ border: '1px solid black', my: 3 }} />
    </>
  )
}

const PatientKycDataEditForm = () => {
  return (
    <SimpleForm toolbar={<PatientKycDataEditToolbar />}>
      <Box sx={{ width: '100%' }}>
        <PatientKycDataStatus />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <ImageSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextSection />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const PatientKycDataEdit = () => (
  <EditSaveRouteBack
    transform={transformPatientKycData}
    mutationMode="pessimistic"
    redirect="list"
  >
    <Card>
      <CardContent sx={{ p: 2 }}>
        <PatientKycDataEditForm />
      </CardContent>
    </Card>
  </EditSaveRouteBack>
)
