import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProductCategoryScalarWhereInputObjectSchema),
        z.lazy(() => ProductCategoryScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProductCategoryScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProductCategoryScalarWhereInputObjectSchema),
        z.lazy(() => ProductCategoryScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    productId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    categoryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const ProductCategoryScalarWhereInputObjectSchema = Schema
