import { Box, Button } from '@mui/material'
import {
  ProductAttribute,
  ProductConcern,
  ProductForm,
  ProductTypeSchema,
  SalesTypeSchema,
  SkinType,
} from '@ssch-backend/products'
import { useEffect } from 'react'
import { ReferenceInput, useListContext, useLocaleState } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  CustomAutoCompleteInput,
  CustomSelectArrayInput,
  CustomSelectInput,
  CustomTextInput,
} from '../../../components/CustomInput'
import {
  getProductAttributeLabel,
  getProductConcernLabel,
  getProductFormLabel,
  getProductSalesTypeLabel,
  getProductSkinTypeLabel,
  getProductTypeLabel,
} from '../../../enumLabel'
import {
  generateFilterQueryParams,
  handleListPageQueryParams,
} from '../../../utils'

const addSpecialColumnTypeFilter = (
  values: { [key: string]: unknown },
  newFilter: { [key: string]: unknown },
  field: string,
  filterCommand: string,
) => {
  delete newFilter[field]

  if (values[field]) {
    newFilter[`${field}_${filterCommand}`] = values[field]
  }
}

export const FilterListForm = () => {
  const navigate = useNavigate()
  const [locale] = useLocaleState()
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      brandId: undefined,
      type: undefined,
      salesType: undefined,
      skinType: undefined,
      concerns: undefined,
      attributes: undefined,
      searchField: undefined,
    },
  })

  const onSubmit = (values: { [key: string]: unknown }) => {
    const newFilter = { ...values }
    addSpecialColumnTypeFilter(values, newFilter, 'type', 'equals')
    addSpecialColumnTypeFilter(values, newFilter, 'salesType', 'equals')
    addSpecialColumnTypeFilter(
      values,
      newFilter,
      'productForms',
      'arrayContains',
    )
    addSpecialColumnTypeFilter(values, newFilter, 'skinType', 'arrayContains')
    addSpecialColumnTypeFilter(values, newFilter, 'concerns', 'arrayContains')
    addSpecialColumnTypeFilter(values, newFilter, 'attributes', 'arrayContains')

    setFilters(newFilter, [])

    const queryString = generateFilterQueryParams(newFilter)
    navigate(queryString ? `?${queryString}` : '')
  }

  const resetFilter = () => {
    setFilters({}, [])
    form.reset()
    navigate('')
  }

  const filterBrand = (searchText: string) => ({ name: searchText })

  useEffect(() => {
    if (window.location.search) {
      handleListPageQueryParams({
        queryParam: window.location.search,
        filterPair: {
          type_equals: {
            formKey: 'type',
          },
          salesType_equals: {
            formKey: 'salesType',
          },
          productForms_arrayContains: {
            formKey: 'productForms',
          },
          skinType_arrayContains: {
            formKey: 'skinType',
          },
          concerns_arrayContains: {
            formKey: 'concerns',
          },
          attributes_arrayContains: {
            formKey: 'attributes',
          },
          searchField: {
            formKey: 'searchField',
          },
        },
        form,

        setFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <ReferenceInput
            source="brandId"
            reference="productBrand"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <CustomAutoCompleteInput
              sx={{ mt: 0.5, flexGrow: 1 }}
              optionText="name"
              label="Brand"
              helperText={false}
              filterToQuery={filterBrand}
            />
          </ReferenceInput>
          <CustomSelectInput
            label={'ประเภทสินค้า'}
            source="type"
            helperText={false}
            choices={Object.values(ProductTypeSchema.Enum).map((option) => ({
              id: option,
              name: getProductTypeLabel(
                option as keyof typeof ProductTypeSchema.Enum,
              )[locale === 'th' ? 'th' : 'en'],
            }))}
          />
          <CustomSelectInput
            label={'ประเภทการขาย'}
            source="salesType"
            helperText={false}
            choices={Object.values(SalesTypeSchema.Enum).map((option) => ({
              id: option,
              name: getProductSalesTypeLabel(
                option as keyof typeof SalesTypeSchema.Enum,
              )[locale === 'th' ? 'th' : 'en'],
            }))}
          />
          <CustomSelectArrayInput
            label={'รูปแบบเภสัชภัณฑ์'}
            source="productForms"
            helperText={false}
            sx={{ mt: 0.5, flexGrow: 1 }}
            choices={Object.keys(ProductForm).map((key: unknown) => {
              return {
                id: key,
                name: getProductFormLabel(key as keyof typeof ProductForm)[
                  locale === 'th' ? 'th' : 'en'
                ],
              }
            })}
          />
          <CustomSelectArrayInput
            label={'เหมาะกับผิว'}
            source="skinType"
            helperText={false}
            sx={{ mt: 0.5, flexGrow: 1 }}
            choices={Object.keys(SkinType).map((key: unknown) => {
              return {
                id: key,
                name: getProductSkinTypeLabel(key as keyof typeof SkinType)[
                  locale === 'th' ? 'th' : 'en'
                ],
              }
            })}
          />
          <CustomSelectArrayInput
            label={'Concern'}
            source="concerns"
            helperText={false}
            sx={{ mt: 0.5, flexGrow: 1 }}
            choices={Object.keys(ProductConcern).map((key: unknown) => {
              return {
                id: key,
                name: getProductConcernLabel(
                  key as keyof typeof ProductConcern,
                )[locale === 'th' ? 'th' : 'en'],
              }
            })}
          />
          <CustomSelectArrayInput
            label={'Attribute'}
            source="attributes"
            helperText={false}
            sx={{ mt: 0.5, flexGrow: 1 }}
            choices={Object.keys(ProductAttribute).map((key: unknown) => {
              return {
                id: key,
                name: getProductAttributeLabel(
                  key as keyof typeof ProductAttribute,
                )[locale === 'th' ? 'th' : 'en'],
              }
            })}
          />
          <CustomTextInput
            sx={{ mt: 0.5, flexGrow: 1 }}
            resettable
            helperText={false}
            source="searchField"
            label="Name"
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" type="submit">
              Filter
            </Button>
            <Button variant="outlined" onClick={resetFilter}>
              Reset
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}
