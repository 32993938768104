import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteCoupon, RelatedCouponModel, CompleteDiscountSetting, RelatedDiscountSettingModel, CompleteConditionSetting, RelatedConditionSettingModel } from "./index"

export const CouponGroupModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  active: z.boolean(),
  prefix: z.string().nullish(),
  code: z.string().nullish(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  titleTr: z.object({ en: z.string(), th: z.string() }),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  tags: jsonSchema,
  picture: z.string(),
  discount: z.number(),
  discountType: z.enum(['FIXED', 'PERCENT']),
  minTotal: z.number(),
  maxDiscount: z.number().nullish(),
  startAt: z.date(),
  expireAt: z.date(),
  isPublic: z.boolean(),
  isOnlyNewPatient: z.boolean(),
  note: z.string(),
  quota: z.number().int(),
  quotaPerUser: z.number().int().nullish(),
  createdBy: z.number().int(),
  updatedBy: z.number().int(),
  v2CreatedBy: z.number().int().nullish(),
  v2UpdatedBy: z.number().int().nullish(),
})

export const CouponGroupModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  active: z.boolean(),
  prefix: z.string().nullish(),
  code: z.string().nullish(),
  type: z.enum(['GENERAL', 'SHIPPING', 'COIN_CASHBACK']),
  titleTr: jsonSchema,
  descriptionTr: jsonSchema,
  tags: jsonSchema,
  picture: z.string(),
  discount: z.number(),
  discountType: z.enum(['FIXED', 'PERCENT']),
  minTotal: z.number(),
  maxDiscount: z.number().nullish(),
  startAt: z.date(),
  expireAt: z.date(),
  isPublic: z.boolean(),
  isOnlyNewPatient: z.boolean(),
  note: z.string(),
  quota: z.number().int(),
  quotaPerUser: z.number().int().nullish(),
  createdBy: z.number().int(),
  updatedBy: z.number().int(),
  v2CreatedBy: z.number().int().nullish(),
  v2UpdatedBy: z.number().int().nullish(),
})

export interface CompleteCouponGroup extends z.infer<typeof CouponGroupModel> {
  coupons: CompleteCoupon[]
  discountSetting: CompleteDiscountSetting[]
  conditionSetting: CompleteConditionSetting[]
}

/**
 * RelatedCouponGroupModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCouponGroupModel: z.ZodSchema<CompleteCouponGroup> = z.lazy(() => CouponGroupModel.extend({
  coupons: RelatedCouponModel.array(),
  discountSetting: RelatedDiscountSettingModel.array(),
  conditionSetting: RelatedConditionSettingModel.array(),
}))
