import { z } from 'zod'
import { BrandUpdateWithoutProductReviewsInputObjectSchema } from './BrandUpdateWithoutProductReviewsInput.schema'
import { BrandUncheckedUpdateWithoutProductReviewsInputObjectSchema } from './BrandUncheckedUpdateWithoutProductReviewsInput.schema'
import { BrandCreateWithoutProductReviewsInputObjectSchema } from './BrandCreateWithoutProductReviewsInput.schema'
import { BrandUncheckedCreateWithoutProductReviewsInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpsertWithoutProductReviewsInput> = z
  .object({
    update: z.union([
      z.lazy(() => BrandUpdateWithoutProductReviewsInputObjectSchema),
      z.lazy(() => BrandUncheckedUpdateWithoutProductReviewsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => BrandCreateWithoutProductReviewsInputObjectSchema),
      z.lazy(() => BrandUncheckedCreateWithoutProductReviewsInputObjectSchema),
    ]),
  })
  .strict()

export const BrandUpsertWithoutProductReviewsInputObjectSchema = Schema
