import { z } from 'zod'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderUpdateWithoutPartnerInputObjectSchema } from './ProcedureOrderUpdateWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedUpdateWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedUpdateWithoutPartnerInput.schema'
import { ProcedureOrderCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderCreateWithoutPartnerInput.schema'
import { ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema } from './ProcedureOrderUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderUpsertWithWhereUniqueWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => ProcedureOrderWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProcedureOrderUpdateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => ProcedureOrderUncheckedUpdateWithoutPartnerInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProcedureOrderCreateWithoutPartnerInputObjectSchema),
        z.lazy(
          () => ProcedureOrderUncheckedCreateWithoutPartnerInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureOrderUpsertWithWhereUniqueWithoutPartnerInputObjectSchema =
  Schema
