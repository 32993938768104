import { z } from 'zod'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemUpdateWithoutRefundInputObjectSchema } from './ProcedureItemUpdateWithoutRefundInput.schema'
import { ProcedureItemUncheckedUpdateWithoutRefundInputObjectSchema } from './ProcedureItemUncheckedUpdateWithoutRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemUpdateWithWhereUniqueWithoutRefundInput> =
  z
    .object({
      where: z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureItemUpdateWithoutRefundInputObjectSchema),
        z.lazy(
          () => ProcedureItemUncheckedUpdateWithoutRefundInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureItemUpdateWithWhereUniqueWithoutRefundInputObjectSchema =
  Schema
