import { z } from 'zod'
import { VariantWarehouseScalarWhereInputObjectSchema } from './VariantWarehouseScalarWhereInput.schema'
import { VariantWarehouseUpdateManyMutationInputObjectSchema } from './VariantWarehouseUpdateManyMutationInput.schema'
import { VariantWarehouseUncheckedUpdateManyWithoutVariantWarehousesInputObjectSchema } from './VariantWarehouseUncheckedUpdateManyWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateManyWithWhereWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantWarehouseUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            VariantWarehouseUncheckedUpdateManyWithoutVariantWarehousesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseUpdateManyWithWhereWithoutVariantInputObjectSchema =
  Schema
