import { z } from 'zod'
import { FlashsaleCreateInputObjectSchema } from './objects/FlashsaleCreateInput.schema'
import { FlashsaleUncheckedCreateInputObjectSchema } from './objects/FlashsaleUncheckedCreateInput.schema'

export const FlashsaleCreateOneSchema = z.object({
  data: z.union([
    FlashsaleCreateInputObjectSchema,
    FlashsaleUncheckedCreateInputObjectSchema,
  ]),
})
