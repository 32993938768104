import { z } from 'zod'

export const PatientScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'prefix',
  'firstname',
  'lastname',
  'nickname',
  'gender',
  'birthDate',
  'picture',
  'isTest',
  'active',
  'registered',
  'drugAllergy',
  'congenitalDisease',
  'receiveNotifications',
  'activityNotifications',
  'chatNotifications',
  'orderNotifications',
  'omiseCustomerId',
  'freshchatUserId',
  'freshchatRestoreId',
  'coin',
  'v2PatientId',
])
