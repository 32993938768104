import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithoutProcedureInputObjectSchema } from './CarouselItemUpdateWithoutProcedureInput.schema'
import { CarouselItemUncheckedUpdateWithoutProcedureInputObjectSchema } from './CarouselItemUncheckedUpdateWithoutProcedureInput.schema'
import { CarouselItemCreateWithoutProcedureInputObjectSchema } from './CarouselItemCreateWithoutProcedureInput.schema'
import { CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema } from './CarouselItemUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpsertWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => CarouselItemUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => CarouselItemCreateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
