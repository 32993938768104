import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryNestedInput.schema'
import { SuggestedProductItemUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProductItemUncheckedUpdateManyWithoutCaseSummaryNestedInput.schema'
import { SuggestedProductItemOriginalUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProductItemOriginalUncheckedUpdateManyWithoutCaseSummaryNestedInput.schema'
import { SuggestedProcedureItemUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema } from './SuggestedProcedureItemUncheckedUpdateManyWithoutCaseSummaryNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUncheckedUpdateInput> = z
  .object({
    id: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    caseId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    presentIllness: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    peNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isTeleMedNotRecommend: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    recommendation: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorFee: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorFeeDiscount: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    commissionRate: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    commissionPrice: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    netDoctorFee: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    pharmacistNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    followUpAppointmentId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    recommedNumberOfTreatment: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    additionalSuggestedProcedure: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedSuggestedProductItemAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedSuggestedProductItemBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    v2CaseSummaryId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    caseUrl: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    caseSummaryDiagnosis: z
      .lazy(
        () =>
          CaseSummaryDiagnosisUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProductItems: z
      .lazy(
        () =>
          SuggestedProductItemUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(
        () =>
          SuggestedProductItemOriginalUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
    suggestedProcedureItems: z
      .lazy(
        () =>
          SuggestedProcedureItemUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryUncheckedUpdateInputObjectSchema = Schema
