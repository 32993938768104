import { z } from 'zod'
import { CoinTransactionWhereUniqueInputObjectSchema } from './CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionUpdateWithoutPatientInputObjectSchema } from './CoinTransactionUpdateWithoutPatientInput.schema'
import { CoinTransactionUncheckedUpdateWithoutPatientInputObjectSchema } from './CoinTransactionUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionUpdateWithWhereUniqueWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => CoinTransactionWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CoinTransactionUpdateWithoutPatientInputObjectSchema),
        z.lazy(
          () => CoinTransactionUncheckedUpdateWithoutPatientInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CoinTransactionUpdateWithWhereUniqueWithoutPatientInputObjectSchema =
  Schema
