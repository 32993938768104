import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    codePlain: z.literal(true).optional(),
    term: z.literal(true).optional(),
  })
  .strict()

export const DiagnosisMinAggregateInputObjectSchema = Schema
