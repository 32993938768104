import { Box, Card, CardContent } from '@mui/material'
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useEditContext,
} from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BranchAddress } from './create-edit-section/branchAddress'
import { BranchInfo } from './create-edit-section/branchInfo'
import { BranchOfficeHour } from './create-edit-section/branchOfficeHour'
import { transformPartnerBranch } from './transform'

const ProductVariantEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerBranchEditForm = () => {
  return (
    <SimpleForm toolbar={<ProductVariantEditToolbar />}>
      <BranchInfo />
      <BranchAddress />
      <BranchOfficeHour />
    </SimpleForm>
  )
}

export const PartnerBranchEdit = () => {
  const { isLoading } = useEditContext()
  if (isLoading) {
    return null
  }
  return (
    <Edit
      transform={transformPartnerBranch}
      mutationMode="pessimistic"
      redirect={(resource, id, data, state) => {
        return `partner/${data?.partnerId}`
      }}
    >
      <Card>
        <CardContent sx={{ p: 2 }}>
          <PartnerBranchEditForm />
        </CardContent>
      </Card>
    </Edit>
  )
}
