import { z } from 'zod'
import { VariantPictureCreateInputObjectSchema } from './objects/VariantPictureCreateInput.schema'
import { VariantPictureUncheckedCreateInputObjectSchema } from './objects/VariantPictureUncheckedCreateInput.schema'

export const VariantPictureCreateOneSchema = z.object({
  data: z.union([
    VariantPictureCreateInputObjectSchema,
    VariantPictureUncheckedCreateInputObjectSchema,
  ]),
})
