import { z } from 'zod'
import { CouponTypeSchema } from '../enums/CouponType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumCouponTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => CouponTypeSchema).optional(),
  })
  .strict()

export const EnumCouponTypeFieldUpdateOperationsInputObjectSchema = Schema
