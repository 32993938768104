import * as z from "zod"
import { CompleteProductReview, RelatedProductReviewModel } from "./index"

export const ProductReviewPictureModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  url: z.string(),
  productReviewId: z.number().int(),
})

export interface CompleteProductReviewPicture extends z.infer<typeof ProductReviewPictureModel> {
  productReview: CompleteProductReview
}

/**
 * RelatedProductReviewPictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProductReviewPictureModel: z.ZodSchema<CompleteProductReviewPicture> = z.lazy(() => ProductReviewPictureModel.extend({
  productReview: RelatedProductReviewModel,
}))
