import { z } from 'zod'
import { CoinTransactionSelectObjectSchema } from './CoinTransactionSelect.schema'
import { CoinTransactionIncludeObjectSchema } from './CoinTransactionInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionArgs> = z
  .object({
    select: z.lazy(() => CoinTransactionSelectObjectSchema).optional(),
    include: z.lazy(() => CoinTransactionIncludeObjectSchema).optional(),
  })
  .strict()

export const CoinTransactionArgsObjectSchema = Schema
