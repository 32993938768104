import { OpenInNew } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import {
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  Pagination,
  TextField,
  useRedirect,
} from 'react-admin'
import { SearchForm } from './list-section/searchForm'

const rowsPerPage = [5, 10, 20]

const ProductListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <SearchForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <ExportButton />
      </Box>
    </Box>
  )
}

const renderBrand = (record: { brand: { name: string } }) => {
  return <span>{record.brand.name}</span>
}

const renderCategory = (record: {
  categories: { id: number; nameTr: { en: string; th: string } }[]
}) => {
  return (
    <div>
      {record.categories?.map((o) => (
        <p key={`cat-${o.id}`} style={{ margin: '0.25rem 0' }}>
          {o.nameTr?.th ?? ''} {o.nameTr?.en ? `/ ${o.nameTr.en}` : ''}
        </p>
      )) ?? []}
    </div>
  )
}

const renderProductName = (
  record: {
    id: number
    nameTr: { en: string; th: string }
  } | null,
) => {
  if (!record) {
    return null
  }

  return (
    <div>
      <p style={{ margin: '0.25rem 0' }}>TH: {record.nameTr.th ?? ''}</p>
      <p style={{ margin: '0.25rem 0' }}>EN: {record.nameTr.en ?? ''}</p>
    </div>
  )
}

export const ProductSearchAppPlaygroundList = () => {
  const redirect = useRedirect()
  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
        actions={<ProductListAction />}
      >
        <Datagrid
          size="small"
          rowClick={(_id, _resource, record) => {
            redirect('edit', 'product', record.id)
            return false
          }}
          bulkActionButtons={false}
        >
          <TextField
            source="id"
            label="ID"
            textAlign="center"
            sortable={false}
          />
          <FunctionField label="Brand" render={renderBrand} />
          <FunctionField label="Product name" render={renderProductName} />
          <FunctionField label="Category (TH/EN)" render={renderCategory} />
          <TextField source="priority" textAlign="center" sortable={false} />
          <TextField
            source="sellingPrice"
            textAlign="center"
            sortable={false}
          />
          <TextField
            source="searchScore"
            label="Score"
            textAlign="center"
            sortable={false}
          />
          <FunctionField
            label="Action"
            render={(record: { id: number }) => (
              <IconButton
                onClick={() => {
                  window.open(`/product/${record.id}`, '_blank')
                }}
              >
                <OpenInNew />
              </IconButton>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
    </Box>
  )
}
