import { z } from 'zod'
import { DoctorItemWhereUniqueInputObjectSchema } from './DoctorItemWhereUniqueInput.schema'
import { DoctorItemCreateWithoutOrderInputObjectSchema } from './DoctorItemCreateWithoutOrderInput.schema'
import { DoctorItemUncheckedCreateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => DoctorItemWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => DoctorItemCreateWithoutOrderInputObjectSchema),
      z.lazy(() => DoctorItemUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const DoctorItemCreateOrConnectWithoutOrderInputObjectSchema = Schema
