import { z } from 'zod'
import { ProductItemWhereInputObjectSchema } from './ProductItemWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemListRelationFilter> = z
  .object({
    every: z.lazy(() => ProductItemWhereInputObjectSchema).optional(),
    some: z.lazy(() => ProductItemWhereInputObjectSchema).optional(),
    none: z.lazy(() => ProductItemWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductItemListRelationFilterObjectSchema = Schema
