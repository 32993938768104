import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { PartnerOrderByWithRelationInputObjectSchema } from './PartnerOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    partner: z
      .lazy(() => PartnerOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const CoverPictureOrderByWithRelationInputObjectSchema = Schema
