import { z } from 'zod'
import { OfficeHourCreateWithoutBranchInputObjectSchema } from './OfficeHourCreateWithoutBranchInput.schema'
import { OfficeHourUncheckedCreateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedCreateWithoutBranchInput.schema'
import { OfficeHourCreateOrConnectWithoutBranchInputObjectSchema } from './OfficeHourCreateOrConnectWithoutBranchInput.schema'
import { OfficeHourCreateManyBranchInputEnvelopeObjectSchema } from './OfficeHourCreateManyBranchInputEnvelope.schema'
import { OfficeHourWhereUniqueInputObjectSchema } from './OfficeHourWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCreateNestedManyWithoutBranchInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema),
        z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema).array(),
        z.lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema),
        z
          .lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => OfficeHourCreateOrConnectWithoutBranchInputObjectSchema),
        z
          .lazy(() => OfficeHourCreateOrConnectWithoutBranchInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => OfficeHourCreateManyBranchInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
        z.lazy(() => OfficeHourWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const OfficeHourCreateNestedManyWithoutBranchInputObjectSchema = Schema
