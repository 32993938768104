import { z } from 'zod'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'
import { CouponGroupCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutConditionSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCreateOrConnectWithoutConditionSettingInput> =
  z
    .object({
      where: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => CouponGroupCreateWithoutConditionSettingInputObjectSchema),
        z.lazy(
          () =>
            CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CouponGroupCreateOrConnectWithoutConditionSettingInputObjectSchema =
  Schema
