import { z } from 'zod'
import { MyReviewWhereInputObjectSchema } from './objects/MyReviewWhereInput.schema'
import { MyReviewOrderByWithAggregationInputObjectSchema } from './objects/MyReviewOrderByWithAggregationInput.schema'
import { MyReviewScalarWhereWithAggregatesInputObjectSchema } from './objects/MyReviewScalarWhereWithAggregatesInput.schema'
import { MyReviewScalarFieldEnumSchema } from './enums/MyReviewScalarFieldEnum.schema'

export const MyReviewGroupBySchema = z.object({
  where: MyReviewWhereInputObjectSchema.optional(),
  orderBy: z.union([
    MyReviewOrderByWithAggregationInputObjectSchema,
    MyReviewOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: MyReviewScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(MyReviewScalarFieldEnumSchema),
})
