import { z } from 'zod'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumProductTypeFilterObjectSchema } from './NestedEnumProductTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumProductTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ProductTypeSchema).optional(),
    in: z
      .lazy(() => ProductTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ProductTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ProductTypeSchema),
        z.lazy(() => NestedEnumProductTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumProductTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumProductTypeFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumProductTypeWithAggregatesFilterObjectSchema = Schema
