import { z } from 'zod'
import { AppointmentCreateWithoutScheduleInputObjectSchema } from './AppointmentCreateWithoutScheduleInput.schema'
import { AppointmentUncheckedCreateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedCreateWithoutScheduleInput.schema'
import { AppointmentCreateOrConnectWithoutScheduleInputObjectSchema } from './AppointmentCreateOrConnectWithoutScheduleInput.schema'
import { AppointmentUpsertWithoutScheduleInputObjectSchema } from './AppointmentUpsertWithoutScheduleInput.schema'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentUpdateWithoutScheduleInputObjectSchema } from './AppointmentUpdateWithoutScheduleInput.schema'
import { AppointmentUncheckedUpdateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedUpdateWithoutScheduleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUncheckedUpdateOneWithoutScheduleNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AppointmentCreateWithoutScheduleInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedCreateWithoutScheduleInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => AppointmentCreateOrConnectWithoutScheduleInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => AppointmentUpsertWithoutScheduleInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => AppointmentWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => AppointmentUpdateWithoutScheduleInputObjectSchema),
          z.lazy(
            () => AppointmentUncheckedUpdateWithoutScheduleInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const AppointmentUncheckedUpdateOneWithoutScheduleNestedInputObjectSchema =
  Schema
