import { z } from 'zod'
import { CoinTransactionOrderByWithRelationInputObjectSchema } from './objects/CoinTransactionOrderByWithRelationInput.schema'
import { CoinTransactionWhereInputObjectSchema } from './objects/CoinTransactionWhereInput.schema'
import { CoinTransactionWhereUniqueInputObjectSchema } from './objects/CoinTransactionWhereUniqueInput.schema'
import { CoinTransactionScalarFieldEnumSchema } from './enums/CoinTransactionScalarFieldEnum.schema'

export const CoinTransactionFindManySchema = z.object({
  orderBy: z
    .union([
      CoinTransactionOrderByWithRelationInputObjectSchema,
      CoinTransactionOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CoinTransactionWhereInputObjectSchema.optional(),
  cursor: CoinTransactionWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CoinTransactionScalarFieldEnumSchema).optional(),
})
