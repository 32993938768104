import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryUpdateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUpdateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedUpdateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedUpdateWithoutCategoryInput.schema'
import { ProcedureCategoryCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProcedureCategoryUpdateWithoutCategoryInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedUpdateWithoutCategoryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema =
  Schema
