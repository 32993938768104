import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { CategoryArgsObjectSchema } from './CategoryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategorySelect> = z
  .object({
    id: z.boolean().optional(),
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    productId: z.boolean().optional(),
    category: z
      .union([z.boolean(), z.lazy(() => CategoryArgsObjectSchema)])
      .optional(),
    categoryId: z.boolean().optional(),
    priority: z.boolean().optional(),
  })
  .strict()

export const ProductCategorySelectObjectSchema = Schema
