import { Button } from '@mui/material'
import { FC } from 'react'
import { Identifier } from 'react-admin'
import { useNavigate } from 'react-router-dom'

interface GoBackButtonProps {
  action?: string
  resource: string
  id?: Identifier
}

export const GoBackButton: FC<GoBackButtonProps> = () => {
  const navigate = useNavigate()

  return (
    <Button
      variant="outlined"
      onClick={() => {
        if (window.history.length > 1) {
          navigate(-1)
        } else {
          window.close()
        }
      }}
    >
      Back
    </Button>
  )
}
