import { z } from 'zod'

export const WaitingBannerScalarFieldEnumSchema = z.enum([
  'id',
  'name',
  'picture',
  'actionUri',
  'active',
  'rank',
  'createdAt',
  'updatedAt',
])
