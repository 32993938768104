import { z } from 'zod'
import { AppointmentTypeSchema } from '../enums/AppointmentType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumAppointmentTypeFilterObjectSchema } from './NestedEnumAppointmentTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumAppointmentTypeWithAggregatesFilter> =
  z
    .object({
      equals: z.lazy(() => AppointmentTypeSchema).optional(),
      in: z
        .lazy(() => AppointmentTypeSchema)
        .array()
        .optional(),
      notIn: z
        .lazy(() => AppointmentTypeSchema)
        .array()
        .optional(),
      not: z
        .union([
          z.lazy(() => AppointmentTypeSchema),
          z.lazy(
            () => NestedEnumAppointmentTypeWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional(),
      _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumAppointmentTypeFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumAppointmentTypeFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumAppointmentTypeWithAggregatesFilterObjectSchema = Schema
