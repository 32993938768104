import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentCountOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    transactionId: z.lazy(() => SortOrderSchema).optional(),
    amount: z.lazy(() => SortOrderSchema).optional(),
    currency: z.lazy(() => SortOrderSchema).optional(),
    paymentAt: z.lazy(() => SortOrderSchema).optional(),
    paymentGateway: z.lazy(() => SortOrderSchema).optional(),
    paymentMethod: z.lazy(() => SortOrderSchema).optional(),
    installmentTerm: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    confirmedBy: z.lazy(() => SortOrderSchema).optional(),
    evidencePicture: z.lazy(() => SortOrderSchema).optional(),
    reminderNote: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const PaymentCountOrderByAggregateInputObjectSchema = Schema
