import { z } from 'zod'
import { CarouselItemSelectObjectSchema } from './CarouselItemSelect.schema'
import { CarouselItemIncludeObjectSchema } from './CarouselItemInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemArgs> = z
  .object({
    select: z.lazy(() => CarouselItemSelectObjectSchema).optional(),
    include: z.lazy(() => CarouselItemIncludeObjectSchema).optional(),
  })
  .strict()

export const CarouselItemArgsObjectSchema = Schema
