import { z } from 'zod'
import { FlashsaleOrderByWithRelationInputObjectSchema } from './objects/FlashsaleOrderByWithRelationInput.schema'
import { FlashsaleWhereInputObjectSchema } from './objects/FlashsaleWhereInput.schema'
import { FlashsaleWhereUniqueInputObjectSchema } from './objects/FlashsaleWhereUniqueInput.schema'
import { FlashsaleCountAggregateInputObjectSchema } from './objects/FlashsaleCountAggregateInput.schema'
import { FlashsaleMinAggregateInputObjectSchema } from './objects/FlashsaleMinAggregateInput.schema'
import { FlashsaleMaxAggregateInputObjectSchema } from './objects/FlashsaleMaxAggregateInput.schema'
import { FlashsaleAvgAggregateInputObjectSchema } from './objects/FlashsaleAvgAggregateInput.schema'
import { FlashsaleSumAggregateInputObjectSchema } from './objects/FlashsaleSumAggregateInput.schema'

export const FlashsaleAggregateSchema = z.object({
  orderBy: z
    .union([
      FlashsaleOrderByWithRelationInputObjectSchema,
      FlashsaleOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: FlashsaleWhereInputObjectSchema.optional(),
  cursor: FlashsaleWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), FlashsaleCountAggregateInputObjectSchema])
    .optional(),
  _min: FlashsaleMinAggregateInputObjectSchema.optional(),
  _max: FlashsaleMaxAggregateInputObjectSchema.optional(),
  _avg: FlashsaleAvgAggregateInputObjectSchema.optional(),
  _sum: FlashsaleSumAggregateInputObjectSchema.optional(),
})
