import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithoutVariantInputObjectSchema } from './VariantWarehouseUpdateWithoutVariantInput.schema'
import { VariantWarehouseUncheckedUpdateWithoutVariantInputObjectSchema } from './VariantWarehouseUncheckedUpdateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => VariantWarehouseUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantWarehouseUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseUpdateWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
