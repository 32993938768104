import { z } from 'zod'
import { OrderNotificationSelectObjectSchema } from './OrderNotificationSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationArgs> = z
  .object({
    select: z.lazy(() => OrderNotificationSelectObjectSchema).optional(),
  })
  .strict()

export const OrderNotificationArgsObjectSchema = Schema
