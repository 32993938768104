import { z } from 'zod'
import { PartnerOrderByWithRelationInputObjectSchema } from './objects/PartnerOrderByWithRelationInput.schema'
import { PartnerWhereInputObjectSchema } from './objects/PartnerWhereInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './objects/PartnerWhereUniqueInput.schema'
import { PartnerCountAggregateInputObjectSchema } from './objects/PartnerCountAggregateInput.schema'
import { PartnerMinAggregateInputObjectSchema } from './objects/PartnerMinAggregateInput.schema'
import { PartnerMaxAggregateInputObjectSchema } from './objects/PartnerMaxAggregateInput.schema'
import { PartnerAvgAggregateInputObjectSchema } from './objects/PartnerAvgAggregateInput.schema'
import { PartnerSumAggregateInputObjectSchema } from './objects/PartnerSumAggregateInput.schema'

export const PartnerAggregateSchema = z.object({
  orderBy: z
    .union([
      PartnerOrderByWithRelationInputObjectSchema,
      PartnerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: PartnerWhereInputObjectSchema.optional(),
  cursor: PartnerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), PartnerCountAggregateInputObjectSchema])
    .optional(),
  _min: PartnerMinAggregateInputObjectSchema.optional(),
  _max: PartnerMaxAggregateInputObjectSchema.optional(),
  _avg: PartnerAvgAggregateInputObjectSchema.optional(),
  _sum: PartnerSumAggregateInputObjectSchema.optional(),
})
