import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    orderId: z.number().optional(),
  })
  .strict()

export const DoctorItemWhereUniqueInputObjectSchema = Schema
