import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemCreateWithoutOrderInputObjectSchema } from './ProductItemCreateWithoutOrderInput.schema'
import { ProductItemUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ProductItemCreateWithoutOrderInputObjectSchema),
      z.lazy(() => ProductItemUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const ProductItemCreateOrConnectWithoutOrderInputObjectSchema = Schema
