import { Typography } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormReferenceArrayInput } from './formReferenceArrayInput'
import { FormReferenceInput } from './formReferenceInput'

export const ClinicHospitalSection: FC = () => {
  const { watch } = useFormContext()
  const selectedMainHospital = watch('mainHospitalId')
  const selectedSecondaryHospital = watch('secondaryHospitals')

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        คลินิก/โรงพยาบาล
      </Typography>
      <FormReferenceInput
        source="mainHospitalId"
        reference="doctorClinicHospital"
        optionText="name"
        label="คลินิก/โรงพยาบาลหลัก"
        filter={
          selectedSecondaryHospital
            ? { id_notIn: selectedSecondaryHospital }
            : undefined
        }
      />
      <FormReferenceArrayInput
        source="secondaryHospitals"
        reference="doctorClinicHospital"
        optionText="name"
        label="คลินิก/โรงพยาบาลรอง"
        filter={
          selectedMainHospital ? { id_not: selectedMainHospital } : undefined
        }
      />
    </>
  )
}
