import { z } from 'zod'
import { ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureCreateOrConnectWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureCreateOrConnectWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUpsertWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUpsertWithoutProcedureReviewSummaryInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureUpdateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUpdateWithoutProcedureReviewSummaryInput.schema'
import { ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInputObjectSchema } from './ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpdateOneRequiredWithoutProcedureReviewSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProcedureUncheckedCreateWithoutProcedureReviewSummaryInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProcedureCreateOrConnectWithoutProcedureReviewSummaryInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () => ProcedureUpsertWithoutProcedureReviewSummaryInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () => ProcedureUpdateWithoutProcedureReviewSummaryInputObjectSchema,
          ),
          z.lazy(
            () =>
              ProcedureUncheckedUpdateWithoutProcedureReviewSummaryInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureUpdateOneRequiredWithoutProcedureReviewSummaryNestedInputObjectSchema =
  Schema
