import { z } from 'zod'
import { ProductCategoryCreateManyProductInputObjectSchema } from './ProductCategoryCreateManyProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateManyProductInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => ProductCategoryCreateManyProductInputObjectSchema),
        z.lazy(() => ProductCategoryCreateManyProductInputObjectSchema).array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProductCategoryCreateManyProductInputEnvelopeObjectSchema = Schema
