import { z } from 'zod'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './ProcedureReviewSummaryWhereUniqueInput.schema'
import { ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryCreateWithoutProcedureInput.schema'
import { ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewSummaryUncheckedCreateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryCreateOrConnectWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(
          () => ProcedureReviewSummaryCreateWithoutProcedureInputObjectSchema,
        ),
        z.lazy(
          () =>
            ProcedureReviewSummaryUncheckedCreateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewSummaryCreateOrConnectWithoutProcedureInputObjectSchema =
  Schema
