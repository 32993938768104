import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationSelect> = z
  .object({
    key: z.boolean().optional(),
    value: z.boolean().optional(),
  })
  .strict()

export const ConfigurationSelectObjectSchema = Schema
