import { z } from 'zod'
import { ProductPictureCreateWithoutProductInputObjectSchema } from './ProductPictureCreateWithoutProductInput.schema'
import { ProductPictureUncheckedCreateWithoutProductInputObjectSchema } from './ProductPictureUncheckedCreateWithoutProductInput.schema'
import { ProductPictureCreateOrConnectWithoutProductInputObjectSchema } from './ProductPictureCreateOrConnectWithoutProductInput.schema'
import { ProductPictureUpsertWithWhereUniqueWithoutProductInputObjectSchema } from './ProductPictureUpsertWithWhereUniqueWithoutProductInput.schema'
import { ProductPictureCreateManyProductInputEnvelopeObjectSchema } from './ProductPictureCreateManyProductInputEnvelope.schema'
import { ProductPictureWhereUniqueInputObjectSchema } from './ProductPictureWhereUniqueInput.schema'
import { ProductPictureUpdateWithWhereUniqueWithoutProductInputObjectSchema } from './ProductPictureUpdateWithWhereUniqueWithoutProductInput.schema'
import { ProductPictureUpdateManyWithWhereWithoutProductInputObjectSchema } from './ProductPictureUpdateManyWithWhereWithoutProductInput.schema'
import { ProductPictureScalarWhereInputObjectSchema } from './ProductPictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureUpdateManyWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductPictureCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductPictureCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductPictureCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductPictureUpsertWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureUpsertWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductPictureCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductPictureUpdateWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureUpdateWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductPictureUpdateManyWithWhereWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureUpdateManyWithWhereWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductPictureScalarWhereInputObjectSchema),
          z.lazy(() => ProductPictureScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductPictureUpdateManyWithoutProductNestedInputObjectSchema =
  Schema
