import { z } from 'zod'
import { AppointmentSelectObjectSchema } from './AppointmentSelect.schema'
import { AppointmentIncludeObjectSchema } from './AppointmentInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentArgs> = z
  .object({
    select: z.lazy(() => AppointmentSelectObjectSchema).optional(),
    include: z.lazy(() => AppointmentIncludeObjectSchema).optional(),
  })
  .strict()

export const AppointmentArgsObjectSchema = Schema
