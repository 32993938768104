import type { Role } from '@prisma/client/admins'
import type { AdminGatewayRouter } from '@ssch-backend/gateways/admin-gateway'
import { TRPCClientError } from '@trpc/client'
import { signInWithCustomToken } from 'firebase/auth'
import { AuthProvider } from 'react-admin'
import { firebaseAuth, firebaseAuthInitialized } from './firebase'
import { adminGatewayClient } from './service'

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const result = await adminGatewayClient.loginAdmin.mutate({
      username,
      password,
    })
    await signInWithCustomToken(firebaseAuth, result.token)
    return { adminId: result.adminId, role: result.role }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    await firebaseAuth.signOut()
  },
  // called when the API returns an error
  checkError: async (error: TRPCClientError<AdminGatewayRouter>) => {
    if (error.shape?.code === -32001) {
      // unauthorized
      await firebaseAuth.signOut()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    await firebaseAuthInitialized
    if (!firebaseAuth.currentUser) {
      throw new Error('Not authenticated')
    }
  },
  getIdentity: async () => {
    const user = await adminGatewayClient.admin.me.query()
    return {
      id: user.id,
      fullName: `${user.firstname} ${user.lastname} (${user.role})`,
      avatar: user.picture?.src,
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    await firebaseAuthInitialized
    if (!firebaseAuth.currentUser) {
      throw new Error('Not authenticated')
    }
    const user = await firebaseAuth.currentUser.getIdTokenResult()
    return user.claims.role as Role
  },
}

export default authProvider
