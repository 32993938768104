import { z } from 'zod'
import { DeliveryCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedCreateWithoutDeliveryLogsInput.schema'
import { DeliveryCreateOrConnectWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateOrConnectWithoutDeliveryLogsInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateNestedOneWithoutDeliveryLogsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryCreateWithoutDeliveryLogsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => DeliveryCreateOrConnectWithoutDeliveryLogsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => DeliveryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const DeliveryCreateNestedOneWithoutDeliveryLogsInputObjectSchema =
  Schema
