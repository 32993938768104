import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithoutCarouselInputObjectSchema } from './CarouselItemUpdateWithoutCarouselInput.schema'
import { CarouselItemUncheckedUpdateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedUpdateWithoutCarouselInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpdateWithWhereUniqueWithoutCarouselInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => CarouselItemUpdateWithoutCarouselInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedUpdateWithoutCarouselInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemUpdateWithWhereUniqueWithoutCarouselInputObjectSchema =
  Schema
