import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { BrandBannerListRelationFilterObjectSchema } from './BrandBannerListRelationFilter.schema'
import { ProductListRelationFilterObjectSchema } from './ProductListRelationFilter.schema'
import { ProductReviewListRelationFilterObjectSchema } from './ProductReviewListRelationFilter.schema'
import { ProductReviewSummaryListRelationFilterObjectSchema } from './ProductReviewSummaryListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => BrandWhereInputObjectSchema),
        z.lazy(() => BrandWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => BrandWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => BrandWhereInputObjectSchema),
        z.lazy(() => BrandWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    name: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    isShow: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    coporateName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    countryName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    logo: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    banners: z.lazy(() => BrandBannerListRelationFilterObjectSchema).optional(),
    products: z.lazy(() => ProductListRelationFilterObjectSchema).optional(),
    productReviews: z
      .lazy(() => ProductReviewListRelationFilterObjectSchema)
      .optional(),
    productReviewSummaries: z
      .lazy(() => ProductReviewSummaryListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const BrandWhereInputObjectSchema = Schema
