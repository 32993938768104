import { z } from 'zod'
import { OrderUpdateWithoutDeliveriesInputObjectSchema } from './OrderUpdateWithoutDeliveriesInput.schema'
import { OrderUncheckedUpdateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedUpdateWithoutDeliveriesInput.schema'
import { OrderCreateWithoutDeliveriesInputObjectSchema } from './OrderCreateWithoutDeliveriesInput.schema'
import { OrderUncheckedCreateWithoutDeliveriesInputObjectSchema } from './OrderUncheckedCreateWithoutDeliveriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutDeliveriesInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutDeliveriesInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutDeliveriesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutDeliveriesInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutDeliveriesInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutDeliveriesInputObjectSchema = Schema
