import { z } from 'zod'
import { IdentityTypeSchema } from '../enums/IdentityType.schema'
import { NestedEnumIdentityTypeFilterObjectSchema } from './NestedEnumIdentityTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumIdentityTypeFilter> = z
  .object({
    equals: z.lazy(() => IdentityTypeSchema).optional(),
    in: z
      .lazy(() => IdentityTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => IdentityTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => IdentityTypeSchema),
        z.lazy(() => NestedEnumIdentityTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumIdentityTypeFilterObjectSchema = Schema
