import { z } from 'zod'
import { ProcedureOrderByWithRelationInputObjectSchema } from './objects/ProcedureOrderByWithRelationInput.schema'
import { ProcedureWhereInputObjectSchema } from './objects/ProcedureWhereInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './objects/ProcedureWhereUniqueInput.schema'
import { ProcedureCountAggregateInputObjectSchema } from './objects/ProcedureCountAggregateInput.schema'
import { ProcedureMinAggregateInputObjectSchema } from './objects/ProcedureMinAggregateInput.schema'
import { ProcedureMaxAggregateInputObjectSchema } from './objects/ProcedureMaxAggregateInput.schema'
import { ProcedureAvgAggregateInputObjectSchema } from './objects/ProcedureAvgAggregateInput.schema'
import { ProcedureSumAggregateInputObjectSchema } from './objects/ProcedureSumAggregateInput.schema'

export const ProcedureAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureOrderByWithRelationInputObjectSchema,
      ProcedureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureWhereInputObjectSchema.optional(),
  cursor: ProcedureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureMinAggregateInputObjectSchema.optional(),
  _max: ProcedureMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureSumAggregateInputObjectSchema.optional(),
})
