import { z } from 'zod'
import { DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInput.schema'
import { CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    diagnosis: z.lazy(
      () =>
        DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema,
    ),
    caseSummary: z.lazy(
      () =>
        CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema,
    ),
  })
  .strict()

export const CaseSummaryDiagnosisCreateInputObjectSchema = Schema
