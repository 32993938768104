import { z } from 'zod'
import { ProductReviewCreateNestedOneWithoutPicturesInputObjectSchema } from './ProductReviewCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    url: z.string(),
    productReview: z.lazy(
      () => ProductReviewCreateNestedOneWithoutPicturesInputObjectSchema,
    ),
  })
  .strict()

export const ProductReviewPictureCreateInputObjectSchema = Schema
