import { z } from 'zod'
import { ProductCategoryScalarWhereInputObjectSchema } from './ProductCategoryScalarWhereInput.schema'
import { ProductCategoryUpdateManyMutationInputObjectSchema } from './ProductCategoryUpdateManyMutationInput.schema'
import { ProductCategoryUncheckedUpdateManyWithoutProductsInputObjectSchema } from './ProductCategoryUncheckedUpdateManyWithoutProductsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUpdateManyWithWhereWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductCategoryUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductCategoryUncheckedUpdateManyWithoutProductsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryUpdateManyWithWhereWithoutCategoryInputObjectSchema =
  Schema
