import { z } from 'zod'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => DiscountTypeSchema).optional(),
  })
  .strict()

export const EnumDiscountTypeFieldUpdateOperationsInputObjectSchema = Schema
