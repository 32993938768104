import { z } from 'zod'
import { FlashsaleSelectObjectSchema } from './FlashsaleSelect.schema'
import { FlashsaleIncludeObjectSchema } from './FlashsaleInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleArgs> = z
  .object({
    select: z.lazy(() => FlashsaleSelectObjectSchema).optional(),
    include: z.lazy(() => FlashsaleIncludeObjectSchema).optional(),
  })
  .strict()

export const FlashsaleArgsObjectSchema = Schema
