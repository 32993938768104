import { Box, Card, CardContent, Grid } from '@mui/material'
import { Create, SaveButton, SimpleForm, Toolbar, required } from 'react-admin'
import {
  CustomNumberInput,
  CustomTextInput,
} from '../../components/CustomInput'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { FormImageInput } from './create-edit-section/formImageInput'
import { FormReferenceInput } from './create-edit-section/formReferenceInput'
import { transformProcedureCategory } from './transform'

const ProcedureCategoryCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="procedureCategory" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProcedureCategoryCreateForm = () => (
  <SimpleForm toolbar={<ProcedureCategoryCreateToolbar />}>
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <FormReferenceInput
          source="parentId"
          reference="procedureCategory"
          optionText={['nameTr.th', 'nameTr.en']}
          label="หมวดหมู่หัตถการหลัก"
        />
      </Grid>
      <Grid item xs={0} lg={6} />
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          validate={required()}
          source="nameTr.th"
          label="ชื่อหมวดหมู่หัตถการ (ไทย)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          source="nameTr.en"
          label="ชื่อหมวดหมู่หัตถการ (อังกฤษ)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          validate={required()}
          source="descriptionTr.th"
          label="คำอธิบายหมวดหมู่หัตถการ (ไทย)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          source="descriptionTr.en"
          label="คำอธิบายหมวดหมู่หัตถการ (อังกฤษ)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormImageInput source="icon" label="ไอคอน" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomNumberInput
          fullWidth
          validate={required()}
          source="priority"
          label="Priority"
          min={0}
          defaultValue={0}
        />
      </Grid>
    </Grid>
  </SimpleForm>
)

export const ProcedureCategoryCreate = () => {
  return (
    <Create transform={transformProcedureCategory} redirect="list">
      <Card>
        <CardContent sx={{ p: 2 }}>
          <ProcedureCategoryCreateForm />
        </CardContent>
      </Card>
    </Create>
  )
}
