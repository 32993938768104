import { z } from 'zod'

export const AddressScalarFieldEnumSchema = z.enum([
  'id',
  'recipient',
  'address',
  'phone',
  'province',
  'district',
  'subDistrict',
  'postCode',
  'provinceKey',
  'districtKey',
  'subDistrictKey',
  'note',
  'tag',
  'isDefault',
  'patientId',
  'createdAt',
  'updatedAt',
])
