import { z } from 'zod'
import { VariantPictureSelectObjectSchema } from './VariantPictureSelect.schema'
import { VariantPictureIncludeObjectSchema } from './VariantPictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureArgs> = z
  .object({
    select: z.lazy(() => VariantPictureSelectObjectSchema).optional(),
    include: z.lazy(() => VariantPictureIncludeObjectSchema).optional(),
  })
  .strict()

export const VariantPictureArgsObjectSchema = Schema
