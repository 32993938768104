import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutIdentitiesInputObjectSchema } from './PatientCreateWithoutIdentitiesInput.schema'
import { PatientUncheckedCreateWithoutIdentitiesInputObjectSchema } from './PatientUncheckedCreateWithoutIdentitiesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutIdentitiesInput> = z
  .object({
    where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => PatientCreateWithoutIdentitiesInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutIdentitiesInputObjectSchema),
    ]),
  })
  .strict()

export const PatientCreateOrConnectWithoutIdentitiesInputObjectSchema = Schema
