import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { AppointmentRelationFilterObjectSchema } from './AppointmentRelationFilter.schema'
import { AppointmentWhereInputObjectSchema } from './AppointmentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ScheduleWhereInputObjectSchema),
        z.lazy(() => ScheduleWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ScheduleWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ScheduleWhereInputObjectSchema),
        z.lazy(() => ScheduleWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    doctorId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    availableAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    appointment: z
      .union([
        z.lazy(() => AppointmentRelationFilterObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ScheduleWhereInputObjectSchema = Schema
