import { z } from 'zod'
import { VariantCountOutputTypeSelectObjectSchema } from './VariantCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => VariantCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const VariantCountOutputTypeArgsObjectSchema = Schema
