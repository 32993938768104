import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { AcceptedConsentCountOrderByAggregateInputObjectSchema } from './AcceptedConsentCountOrderByAggregateInput.schema'
import { AcceptedConsentAvgOrderByAggregateInputObjectSchema } from './AcceptedConsentAvgOrderByAggregateInput.schema'
import { AcceptedConsentMaxOrderByAggregateInputObjectSchema } from './AcceptedConsentMaxOrderByAggregateInput.schema'
import { AcceptedConsentMinOrderByAggregateInputObjectSchema } from './AcceptedConsentMinOrderByAggregateInput.schema'
import { AcceptedConsentSumOrderByAggregateInputObjectSchema } from './AcceptedConsentSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    consentKey: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => AcceptedConsentCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => AcceptedConsentAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => AcceptedConsentMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => AcceptedConsentMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => AcceptedConsentSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const AcceptedConsentOrderByWithAggregationInputObjectSchema = Schema
