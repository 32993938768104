import { z } from 'zod'
import { ProductCategoryWhereInputObjectSchema } from './objects/ProductCategoryWhereInput.schema'
import { ProductCategoryOrderByWithAggregationInputObjectSchema } from './objects/ProductCategoryOrderByWithAggregationInput.schema'
import { ProductCategoryScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductCategoryScalarWhereWithAggregatesInput.schema'
import { ProductCategoryScalarFieldEnumSchema } from './enums/ProductCategoryScalarFieldEnum.schema'

export const ProductCategoryGroupBySchema = z.object({
  where: ProductCategoryWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductCategoryOrderByWithAggregationInputObjectSchema,
    ProductCategoryOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductCategoryScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductCategoryScalarFieldEnumSchema),
})
