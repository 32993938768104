import { z } from 'zod'
import { ProductVideoOrderByWithRelationInputObjectSchema } from './objects/ProductVideoOrderByWithRelationInput.schema'
import { ProductVideoWhereInputObjectSchema } from './objects/ProductVideoWhereInput.schema'
import { ProductVideoWhereUniqueInputObjectSchema } from './objects/ProductVideoWhereUniqueInput.schema'
import { ProductVideoScalarFieldEnumSchema } from './enums/ProductVideoScalarFieldEnum.schema'

export const ProductVideoFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProductVideoOrderByWithRelationInputObjectSchema,
      ProductVideoOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductVideoWhereInputObjectSchema.optional(),
  cursor: ProductVideoWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductVideoScalarFieldEnumSchema).optional(),
})
