import { FileValue } from '../../components/types'

export const transformProduct = async (form: {
  pictures: FileValue[]
  video: FileValue[] | FileValue
  priority: number | null
}) => {
  const { pictures, video, priority } = form
  const result: { [key: string]: unknown } = form

  const formPictures = [] as unknown[]
  if (pictures) {
    for (const pic of pictures) {
      if (pic.uploadPromise) {
        const url = await pic.uploadPromise
        if (url && !Array.isArray(url)) {
          formPictures.push({
            ...pic,
            gsPath: url.gsPath,
          })
        } else {
          throw new Error('Upload pictures failed')
        }
      } else {
        formPictures.push(pic)
      }
    }
  }
  result.pictures = formPictures

  const formVideo = [] as unknown[]
  if (video) {
    if (Array.isArray(video)) {
      for (const vid of video) {
        if (vid.uploadPromise) {
          const videoAndThumbnailUrl = await vid.uploadPromise
          if (videoAndThumbnailUrl && Array.isArray(videoAndThumbnailUrl)) {
            formVideo.push({
              ...video,
              gsPath: videoAndThumbnailUrl[0].gsPath,
              thumbnailGsPath: videoAndThumbnailUrl[1].gsPath,
            })
          } else {
            throw new Error('Upload video failed')
          }
        } else {
          formVideo.push(vid)
        }
      }
    } else {
      if (video.uploadPromise) {
        const videoAndThumbnailUrl = await video.uploadPromise
        if (videoAndThumbnailUrl && Array.isArray(videoAndThumbnailUrl)) {
          formVideo.push({
            ...video,
            gsPath: videoAndThumbnailUrl[0].gsPath,
            thumbnailGsPath: videoAndThumbnailUrl[1].gsPath,
          })
        } else {
          throw new Error('Upload video failed')
        }
      }
    }
  }
  result.video = formVideo

  // assign & remove properties
  result.priority = Number(priority) || 0
  delete result.variants

  return result
}
