import { z } from 'zod'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateOrConnectWithoutProductReviewSummaryInput> =
  z
    .object({
      where: z.lazy(() => ProductWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductCreateWithoutProductReviewSummaryInputObjectSchema),
        z.lazy(
          () =>
            ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCreateOrConnectWithoutProductReviewSummaryInputObjectSchema =
  Schema
