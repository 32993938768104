import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ArticleCountOrderByAggregateInputObjectSchema } from './ArticleCountOrderByAggregateInput.schema'
import { ArticleAvgOrderByAggregateInputObjectSchema } from './ArticleAvgOrderByAggregateInput.schema'
import { ArticleMaxOrderByAggregateInputObjectSchema } from './ArticleMaxOrderByAggregateInput.schema'
import { ArticleMinOrderByAggregateInputObjectSchema } from './ArticleMinOrderByAggregateInput.schema'
import { ArticleSumOrderByAggregateInputObjectSchema } from './ArticleSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    coverPicture: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    detailTr: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ArticleCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => ArticleAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => ArticleMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => ArticleMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => ArticleSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const ArticleOrderByWithAggregationInputObjectSchema = Schema
