import { Box, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const placeHolderColor = '#c1c1c1'

export const ArticlePreview: FC = () => {
  const { watch } = useFormContext()
  const [coverPictureSrc, setCoverPictureSrc] = useState<string | null>(null)
  const [picturesSrcList, setPicturesSrcList] = useState<string[]>([])

  const coverPicture = watch('coverPicture') ?? null
  const pictures = watch('pictures') ?? null
  const titleTh = watch('titleTr.th') ?? ''
  const detailTh = watch('detailTr.th') ?? ''

  useEffect(() => {
    if (coverPicture?.rawFile) {
      setCoverPictureSrc(URL.createObjectURL(coverPicture.rawFile))
    } else if (coverPicture?.src) {
      setCoverPictureSrc(coverPicture.src)
    } else {
      setCoverPictureSrc(null)
    }

    const showPics: string[] = []
    if (pictures && pictures.length) {
      pictures.forEach((p: { rawFile?: File | null; src?: string | null }) => {
        if (p.rawFile) {
          showPics.push(URL.createObjectURL(p.rawFile))
        } else if (p.src) {
          showPics.push(p.src)
        }
      })
    }
    setPicturesSrcList(showPics)
  }, [coverPicture, pictures])

  return (
    <>
      <Typography variant="h6" sx={{ pl: 1, fontWeight: 'bold' }}>
        Preview
      </Typography>
      <Box
        sx={{
          m: 1,
          p: 1,
          border: '5px solid black',
          borderRadius: '16px',
          height: '500px',
          overflowY: 'auto',
        }}
      >
        {coverPictureSrc ? (
          <img
            src={coverPictureSrc}
            alt="cover-pic"
            style={{ maxWidth: '100%' }}
          />
        ) : (
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', mb: 4, color: placeHolderColor }}
          >{`[Cover picture]`}</Typography>
        )}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              ...(titleTh ? {} : { color: placeHolderColor }),
            }}
          >
            {titleTh || `[Title]`}
          </Typography>
        </Box>
        <Box>
          {detailTh ? (
            <div dangerouslySetInnerHTML={{ __html: detailTh }} />
          ) : (
            <Typography
              sx={{ color: placeHolderColor }}
            >{`[Detail]`}</Typography>
          )}
        </Box>
        {picturesSrcList.length ? (
          <Box sx={{ textAlign: 'center' }}>
            {picturesSrcList.map((ps, i) => (
              <img
                key={i}
                src={ps}
                alt={`pic-${i}`}
                style={{ maxWidth: '100%' }}
              />
            ))}
          </Box>
        ) : (
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', mt: 4, color: placeHolderColor }}
          >{`[Additional pictures]`}</Typography>
        )}
      </Box>
    </>
  )
}
