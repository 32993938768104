import { z } from 'zod'
import { ProcedureReviewSummarySelectObjectSchema } from './ProcedureReviewSummarySelect.schema'
import { ProcedureReviewSummaryIncludeObjectSchema } from './ProcedureReviewSummaryInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryArgs> = z
  .object({
    select: z.lazy(() => ProcedureReviewSummarySelectObjectSchema).optional(),
    include: z.lazy(() => ProcedureReviewSummaryIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedureReviewSummaryArgsObjectSchema = Schema
