import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { EnumCouponTypeFilterObjectSchema } from './EnumCouponTypeFilter.schema'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { EnumDiscountTypeFilterObjectSchema } from './EnumDiscountTypeFilter.schema'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { CouponListRelationFilterObjectSchema } from './CouponListRelationFilter.schema'
import { DiscountSettingListRelationFilterObjectSchema } from './DiscountSettingListRelationFilter.schema'
import { ConditionSettingListRelationFilterObjectSchema } from './ConditionSettingListRelationFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CouponGroupWhereInputObjectSchema),
        z.lazy(() => CouponGroupWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CouponGroupWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CouponGroupWhereInputObjectSchema),
        z.lazy(() => CouponGroupWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    prefix: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    code: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    type: z
      .union([
        z.lazy(() => EnumCouponTypeFilterObjectSchema),
        z.lazy(() => CouponTypeSchema),
      ])
      .optional(),
    titleTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    tags: z.lazy(() => JsonFilterObjectSchema).optional(),
    picture: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    discount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    discountType: z
      .union([
        z.lazy(() => EnumDiscountTypeFilterObjectSchema),
        z.lazy(() => DiscountTypeSchema),
      ])
      .optional(),
    minTotal: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    maxDiscount: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    startAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    expireAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    isPublic: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    isOnlyNewPatient: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    note: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    quota: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    quotaPerUser: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    createdBy: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    updatedBy: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    v2CreatedBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    v2UpdatedBy: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    coupons: z.lazy(() => CouponListRelationFilterObjectSchema).optional(),
    discountSetting: z
      .lazy(() => DiscountSettingListRelationFilterObjectSchema)
      .optional(),
    conditionSetting: z
      .lazy(() => ConditionSettingListRelationFilterObjectSchema)
      .optional(),
  })
  .strict()

export const CouponGroupWhereInputObjectSchema = Schema
