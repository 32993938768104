import { z } from 'zod'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { NestedEnumCaseStatusFilterObjectSchema } from './NestedEnumCaseStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumCaseStatusFilter> = z
  .object({
    equals: z.lazy(() => CaseStatusSchema).optional(),
    in: z
      .lazy(() => CaseStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CaseStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CaseStatusSchema),
        z.lazy(() => NestedEnumCaseStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumCaseStatusFilterObjectSchema = Schema
