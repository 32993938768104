import { z } from 'zod'
import { VariantWarehouseFindManySchema } from '../findManyVariantWarehouse.schema'
import { WarehouseCountOutputTypeArgsObjectSchema } from './WarehouseCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseInclude> = z
  .object({
    variantWarehouses: z
      .union([z.boolean(), z.lazy(() => VariantWarehouseFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => WarehouseCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const WarehouseIncludeObjectSchema = Schema
