import { z } from 'zod'
import { CasePictureOrderByWithRelationInputObjectSchema } from './objects/CasePictureOrderByWithRelationInput.schema'
import { CasePictureWhereInputObjectSchema } from './objects/CasePictureWhereInput.schema'
import { CasePictureWhereUniqueInputObjectSchema } from './objects/CasePictureWhereUniqueInput.schema'
import { CasePictureScalarFieldEnumSchema } from './enums/CasePictureScalarFieldEnum.schema'

export const CasePictureFindManySchema = z.object({
  orderBy: z
    .union([
      CasePictureOrderByWithRelationInputObjectSchema,
      CasePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CasePictureWhereInputObjectSchema.optional(),
  cursor: CasePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(CasePictureScalarFieldEnumSchema).optional(),
})
