import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material'
import {
  NotificationActionTypeSchema,
  NotificationTypeSchema,
} from '@ssch-backend/patients'
import React, { FC } from 'react'
import {
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useLocaleState,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { FormDivider } from '../../components/FormInput/divider'
import {
  FormDateTimeInput,
  FormSelectInput,
  FormTextInput,
} from '../../components/FormInput/input'
import {
  getNotificationActionTypeSchemaLabel,
  getNotificationTypeSchemaLabel,
} from '../../enumLabel'
import { transformNotification } from './transform'

const NotificationCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="notification" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const NotificationFormDetail: FC = () => {
  const [locale] = useLocaleState()
  const { watch, setValue } = useFormContext()
  const selectedActionType: string = watch('actionType') ?? ''
  const text: string = watch('message') ?? ''

  const getActionPlaceholder = () => {
    if (selectedActionType === NotificationActionTypeSchema.Enum.URL) {
      return 'Insert external URL (e.g. https://www.google.com)'
    } else if (
      selectedActionType === NotificationActionTypeSchema.Enum.NAVIGATE
    ) {
      return 'Insert in-app link'
    } else {
      return ''
    }
  }

  const handleActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // Need console.log for getting env value
    console.log(import.meta.env.NX_DEEPLINK_PATIENT_APP)
    const updatedValue = value.replace(
      import.meta.env.NX_APP_PATIENT_DOMAIN,
      import.meta.env.NX_DEEPLINK_PATIENT_APP,
    )

    setValue('action', updatedValue)
    e.target.value = updatedValue
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูล Notification
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput source="title" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDateTimeInput
            source="startAt"
            label="Sent At (Leave the field blank to send at the same time upon submission.)"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormTextInput
            source="message"
            multiline
            validate={[
              required(),
              (val) =>
                [...val].length > 190
                  ? 'Message must not exceed 190 characters'
                  : undefined,
            ]}
            helperText={`${[...text].length} character(s)`}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormSelectInput
            source="type"
            validate={required()}
            choices={Object.keys(NotificationTypeSchema.Enum).map(
              (key: unknown) => {
                return {
                  id: key,
                  name: getNotificationTypeSchemaLabel(
                    key as keyof typeof NotificationTypeSchema.Enum,
                  )[locale === 'th' ? 'th' : 'en'],
                }
              },
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormSelectInput
            source="actionType"
            validate={required()}
            choices={Object.keys(NotificationActionTypeSchema.Enum).map(
              (key: unknown) => {
                return {
                  id: key,
                  name: getNotificationActionTypeSchemaLabel(
                    key as keyof typeof NotificationActionTypeSchema.Enum,
                  )[locale === 'th' ? 'th' : 'en'],
                }
              },
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormTextInput
            source="action"
            validate={
              selectedActionType ===
                NotificationActionTypeSchema.Enum.NAVIGATE ||
              selectedActionType === NotificationActionTypeSchema.Enum.URL
                ? required()
                : undefined
            }
            disabled={
              selectedActionType !==
                NotificationActionTypeSchema.Enum.NAVIGATE &&
              selectedActionType !== NotificationActionTypeSchema.Enum.URL
            }
            placeholder={getActionPlaceholder()}
            helperText={false}
            onChange={handleActionChange}
          />
          {selectedActionType === NotificationActionTypeSchema.Enum.NAVIGATE ? (
            <Link
              href="https://docs.google.com/spreadsheets/d/1ikoMBFW3C8kH7E-piocM65VNYuy_RB3cUx-UPYzUpd8/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              ตัวอย่าง in-app link
            </Link>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FormTextInput
            source="patientIds"
            placeholder="Leave blank to send notification to all patients or specific patient ids separate with comma example: 1,2,3,...,999"
          />
        </Grid>
      </Grid>
    </>
  )
}

const NotificationCreateForm = () => {
  return (
    <SimpleForm toolbar={<NotificationCreateToolbar />}>
      <NotificationFormDetail />
    </SimpleForm>
  )
}

export const NotificationCreate = () => (
  <Create transform={transformNotification} redirect="list">
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <NotificationCreateForm />
        </CardContent>
      </Card>
    </Box>
  </Create>
)
