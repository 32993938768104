import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ArticlePictureCountOrderByAggregateInputObjectSchema } from './ArticlePictureCountOrderByAggregateInput.schema'
import { ArticlePictureAvgOrderByAggregateInputObjectSchema } from './ArticlePictureAvgOrderByAggregateInput.schema'
import { ArticlePictureMaxOrderByAggregateInputObjectSchema } from './ArticlePictureMaxOrderByAggregateInput.schema'
import { ArticlePictureMinOrderByAggregateInputObjectSchema } from './ArticlePictureMinOrderByAggregateInput.schema'
import { ArticlePictureSumOrderByAggregateInputObjectSchema } from './ArticlePictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    articleId: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ArticlePictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ArticlePictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ArticlePictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ArticlePictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ArticlePictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ArticlePictureOrderByWithAggregationInputObjectSchema = Schema
