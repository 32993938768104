import { z } from 'zod'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { OrderCreateNestedOneWithoutCouponsInputObjectSchema } from './OrderCreateNestedOneWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CouponCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    couponId: z.number(),
    code: z.string(),
    type: z.lazy(() => CouponTypeSchema),
    descriptionTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    discount: z.number().optional(),
    coin: z.number().optional(),
    v2CouponId: z.number().optional().nullable(),
    order: z.lazy(() => OrderCreateNestedOneWithoutCouponsInputObjectSchema),
  })
  .strict()

export const CouponCreateInputObjectSchema = Schema
