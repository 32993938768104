import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureOrderByWithRelationInputObjectSchema } from './ProcedureOrderByWithRelationInput.schema'
import { ProcedureReviewPictureOrderByRelationAggregateInputObjectSchema } from './ProcedureReviewPictureOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    overallRating: z.lazy(() => SortOrderSchema).optional(),
    environmentRating: z.lazy(() => SortOrderSchema).optional(),
    serviceRating: z.lazy(() => SortOrderSchema).optional(),
    specialtyRating: z.lazy(() => SortOrderSchema).optional(),
    valueRating: z.lazy(() => SortOrderSchema).optional(),
    message: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    patientName: z.lazy(() => SortOrderSchema).optional(),
    isAnonymous: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    procedure: z
      .lazy(() => ProcedureOrderByWithRelationInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(
        () => ProcedureReviewPictureOrderByRelationAggregateInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProcedureReviewOrderByWithRelationInputObjectSchema = Schema
