import { z } from 'zod'
import { ProcedureItemWhereInputObjectSchema } from './objects/ProcedureItemWhereInput.schema'
import { ProcedureItemOrderByWithAggregationInputObjectSchema } from './objects/ProcedureItemOrderByWithAggregationInput.schema'
import { ProcedureItemScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureItemScalarWhereWithAggregatesInput.schema'
import { ProcedureItemScalarFieldEnumSchema } from './enums/ProcedureItemScalarFieldEnum.schema'

export const ProcedureItemGroupBySchema = z.object({
  where: ProcedureItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureItemOrderByWithAggregationInputObjectSchema,
    ProcedureItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedureItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureItemScalarFieldEnumSchema),
})
