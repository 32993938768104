import { Box, Button, Typography } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  Pagination,
  TextField,
  useRedirect,
} from 'react-admin'
import { AvatarField } from '../../components/AvatarField'
import { CustomBooleanField } from '../../components/CustomField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FilterListForm } from './list-section/filterlistForm'
import { FullnameField } from './list-section/fullname'
import { RegisterStatusField } from './list-section/registerStatus'
import { DoctorReviewExportButton } from './list-section/doctorReviewExportButton'

const rowsPerPage = [5, 10, 20]

const DoctorListHeader = () => {
  const redirect = useRedirect()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        แพทย์
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <DoctorReviewExportButton />
        <Button
          variant="contained"
          sx={{ backgroundColor: '#64B95E' }}
          onClick={() => {
            redirect('list', 'doctorKycData')
          }}
        >
          ดูการยืนยันตัวตน (แพทย์)
        </Button>
      </Box>
    </Box>
  )
}

const DoctorListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <FilterListForm />
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
        <ExportButton />
      </Box>
    </Box>
  )
}

export const DoctorList = () => (
  <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
    <DoctorListHeader />
    <List
      disableSyncWithLocation
      actions={<DoctorListAction />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={rowsPerPage[2]}
      pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
    >
      <Datagrid
        size="small"
        rowClick="edit"
        sx={{
          '& .RaDatagrid-headerCell': {
            alignItems: 'baseline',
            whiteSpace: 'nowrap',
          },
          '& .RaDatagrid-rowCell': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TextField source="id" label="ไอดี" sortable={false} />
        <CustomBooleanField
          source="active"
          label="สถานะบัญชี"
          sortable={false}
        />
        <RegisterStatusField label="สถานะการสมัคร" sortable={false} />
        <AvatarField source="picture.src" label="รูป" sortable={false} />
        <FullnameField label="ชื่อ" sortable={false} />
        <TextField source="nickname" label="ชื่อเล่น" sortable={false} />
        <TextField source="gender" label="เพศ" sortable={false} />
        <TextField source="phone" label="เบอร์โทรศัพท์" sortable={false} />
        <LocaleDateField
          source="createdAt"
          showTime
          label="สมัครเมื่อ"
          sortable={false}
        />
      </Datagrid>
    </List>
  </Box>
)
