import { z } from 'zod'
import { CouponGroupCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupCreateWithoutDiscountSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutDiscountSettingInput.schema'
import { CouponGroupCreateOrConnectWithoutDiscountSettingInputObjectSchema } from './CouponGroupCreateOrConnectWithoutDiscountSettingInput.schema'
import { CouponGroupWhereUniqueInputObjectSchema } from './CouponGroupWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupCreateNestedOneWithoutDiscountSettingInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CouponGroupCreateWithoutDiscountSettingInputObjectSchema,
          ),
          z.lazy(
            () =>
              CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CouponGroupCreateOrConnectWithoutDiscountSettingInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CouponGroupWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const CouponGroupCreateNestedOneWithoutDiscountSettingInputObjectSchema =
  Schema
