import { Box, Card, CardContent, Grid } from '@mui/material'
import { Create, SaveButton, SimpleForm, Toolbar, required } from 'react-admin'
import {
  CustomNumberInput,
  CustomTextInput,
} from '../../components/CustomInput'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { FormImageInput } from './create-edit-section/formImageInput'
import { FormReferenceInput } from './create-edit-section/formReferenceInput'
import { transformProductCategory } from './transform'

const ProductCategoryCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="productCategory" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProductCategoryCreateForm = () => (
  <SimpleForm toolbar={<ProductCategoryCreateToolbar />}>
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <FormReferenceInput
          source="parentId"
          reference="productCategory"
          optionText={['nameTr.th', 'nameTr.en']}
          label="หมวดหมู่สินค้าหลัก"
        />
      </Grid>
      <Grid item xs={0} lg={6} />
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          validate={required()}
          source="nameTr.th"
          label="ชื่อหมวดหมู่สินค้า (ไทย)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          source="nameTr.en"
          label="ชื่อหมวดหมู่สินค้า (อังกฤษ)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          validate={required()}
          source="descriptionTr.th"
          label="คำอธิบายหมวดหมู่สินค้า (ไทย)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextInput
          fullWidth
          source="descriptionTr.en"
          label="คำอธิบายหมวดหมู่สินค้า (อังกฤษ)"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormImageInput source="icon" label="ไอคอน" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomNumberInput
          fullWidth
          validate={required()}
          source="priority"
          label="Priority"
          min={0}
          defaultValue={0}
        />
      </Grid>
    </Grid>
  </SimpleForm>
)

export const ProductCategoryCreate = () => {
  return (
    <Create transform={transformProductCategory} redirect="list">
      <Card>
        <CardContent sx={{ p: 2 }}>
          <ProductCategoryCreateForm />
        </CardContent>
      </Card>
    </Create>
  )
}
