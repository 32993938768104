import { preprocessDate } from '@ssch-backend/utils/zod-utils'
import { z } from 'zod'

export const ReviewInitializePayloadModel = z.object({
  patientId: z.number().int(),
  picture: z.string(),
  titleTr: z.object({ en: z.string(), th: z.string() }),
  subtitleTr: z.object({ en: z.string(), th: z.string() }),
  orderItemId: z.number().int(),
  orderItemType: z.enum(['PRODUCT', 'PROCEDURE']),
  productId: z.number().nullable(),
  procedureId: z.number().nullable(),
  quantity: z.number().int(),
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullable(),
  reviewStartAt: z.preprocess(preprocessDate, z.date()),
  reviewExpireAt: z.preprocess(preprocessDate, z.date()),
})
export type ReviewInitializePayloadModel = z.infer<
  typeof ReviewInitializePayloadModel
>
