import { z } from 'zod'
import { OfficeHourOrderByWithRelationInputObjectSchema } from './objects/OfficeHourOrderByWithRelationInput.schema'
import { OfficeHourWhereInputObjectSchema } from './objects/OfficeHourWhereInput.schema'
import { OfficeHourWhereUniqueInputObjectSchema } from './objects/OfficeHourWhereUniqueInput.schema'
import { OfficeHourCountAggregateInputObjectSchema } from './objects/OfficeHourCountAggregateInput.schema'
import { OfficeHourMinAggregateInputObjectSchema } from './objects/OfficeHourMinAggregateInput.schema'
import { OfficeHourMaxAggregateInputObjectSchema } from './objects/OfficeHourMaxAggregateInput.schema'
import { OfficeHourAvgAggregateInputObjectSchema } from './objects/OfficeHourAvgAggregateInput.schema'
import { OfficeHourSumAggregateInputObjectSchema } from './objects/OfficeHourSumAggregateInput.schema'

export const OfficeHourAggregateSchema = z.object({
  orderBy: z
    .union([
      OfficeHourOrderByWithRelationInputObjectSchema,
      OfficeHourOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OfficeHourWhereInputObjectSchema.optional(),
  cursor: OfficeHourWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), OfficeHourCountAggregateInputObjectSchema])
    .optional(),
  _min: OfficeHourMinAggregateInputObjectSchema.optional(),
  _max: OfficeHourMaxAggregateInputObjectSchema.optional(),
  _avg: OfficeHourAvgAggregateInputObjectSchema.optional(),
  _sum: OfficeHourSumAggregateInputObjectSchema.optional(),
})
