import { z } from 'zod'
import { ProcedureWhereInputObjectSchema } from './ProcedureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureRelationFilter> = z
  .object({
    is: z.lazy(() => ProcedureWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => ProcedureWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureRelationFilterObjectSchema = Schema
