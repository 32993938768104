import { z } from 'zod'
import { OrderCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsOriginalInput.schema'
import { OrderCreateOrConnectWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateOrConnectWithoutProductItemsOriginalInput.schema'
import { OrderUpsertWithoutProductItemsOriginalInputObjectSchema } from './OrderUpsertWithoutProductItemsOriginalInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutProductItemsOriginalInputObjectSchema } from './OrderUpdateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedUpdateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedUpdateWithoutProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutProductItemsOriginalNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutProductItemsOriginalInputObjectSchema),
          z.lazy(
            () =>
              OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            OrderCreateOrConnectWithoutProductItemsOriginalInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutProductItemsOriginalInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutProductItemsOriginalInputObjectSchema),
          z.lazy(
            () =>
              OrderUncheckedUpdateWithoutProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutProductItemsOriginalNestedInputObjectSchema =
  Schema
