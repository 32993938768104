import { z } from 'zod'
import { VariantCreateNestedOneWithoutVariantWarehousesInputObjectSchema } from './VariantCreateNestedOneWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateWithoutWarehouseInput> = z
  .object({
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutVariantWarehousesInputObjectSchema,
    ),
  })
  .strict()

export const VariantWarehouseCreateWithoutWarehouseInputObjectSchema = Schema
