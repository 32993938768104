import { z } from 'zod'
import { CoverPictureCreateWithoutPartnerInputObjectSchema } from './CoverPictureCreateWithoutPartnerInput.schema'
import { CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema } from './CoverPictureUncheckedCreateWithoutPartnerInput.schema'
import { CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema } from './CoverPictureCreateOrConnectWithoutPartnerInput.schema'
import { CoverPictureUpsertWithWhereUniqueWithoutPartnerInputObjectSchema } from './CoverPictureUpsertWithWhereUniqueWithoutPartnerInput.schema'
import { CoverPictureCreateManyPartnerInputEnvelopeObjectSchema } from './CoverPictureCreateManyPartnerInputEnvelope.schema'
import { CoverPictureWhereUniqueInputObjectSchema } from './CoverPictureWhereUniqueInput.schema'
import { CoverPictureUpdateWithWhereUniqueWithoutPartnerInputObjectSchema } from './CoverPictureUpdateWithWhereUniqueWithoutPartnerInput.schema'
import { CoverPictureUpdateManyWithWhereWithoutPartnerInputObjectSchema } from './CoverPictureUpdateManyWithWhereWithoutPartnerInput.schema'
import { CoverPictureScalarWhereInputObjectSchema } from './CoverPictureScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureUpdateManyWithoutPartnerNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CoverPictureCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => CoverPictureCreateWithoutPartnerInputObjectSchema)
            .array(),
          z.lazy(
            () => CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              CoverPictureUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoverPictureUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CoverPictureCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CoverPictureUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoverPictureUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              CoverPictureUpdateManyWithWhereWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CoverPictureUpdateManyWithWhereWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => CoverPictureScalarWhereInputObjectSchema),
          z.lazy(() => CoverPictureScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CoverPictureUpdateManyWithoutPartnerNestedInputObjectSchema =
  Schema
