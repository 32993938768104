import { z } from 'zod'
import { ProductReviewPictureOrderByWithRelationInputObjectSchema } from './objects/ProductReviewPictureOrderByWithRelationInput.schema'
import { ProductReviewPictureWhereInputObjectSchema } from './objects/ProductReviewPictureWhereInput.schema'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './objects/ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureScalarFieldEnumSchema } from './enums/ProductReviewPictureScalarFieldEnum.schema'

export const ProductReviewPictureFindManySchema = z.object({
  orderBy: z
    .union([
      ProductReviewPictureOrderByWithRelationInputObjectSchema,
      ProductReviewPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductReviewPictureWhereInputObjectSchema.optional(),
  cursor: ProductReviewPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductReviewPictureScalarFieldEnumSchema).optional(),
})
