import { z } from 'zod'
import { ArticlePictureWhereInputObjectSchema } from './ArticlePictureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureListRelationFilter> = z
  .object({
    every: z.lazy(() => ArticlePictureWhereInputObjectSchema).optional(),
    some: z.lazy(() => ArticlePictureWhereInputObjectSchema).optional(),
    none: z.lazy(() => ArticlePictureWhereInputObjectSchema).optional(),
  })
  .strict()

export const ArticlePictureListRelationFilterObjectSchema = Schema
