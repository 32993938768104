import { z } from 'zod'
import { ProductReviewCreateWithoutProductInputObjectSchema } from './ProductReviewCreateWithoutProductInput.schema'
import { ProductReviewUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewUncheckedCreateWithoutProductInput.schema'
import { ProductReviewCreateOrConnectWithoutProductInputObjectSchema } from './ProductReviewCreateOrConnectWithoutProductInput.schema'
import { ProductReviewUpsertWithWhereUniqueWithoutProductInputObjectSchema } from './ProductReviewUpsertWithWhereUniqueWithoutProductInput.schema'
import { ProductReviewCreateManyProductInputEnvelopeObjectSchema } from './ProductReviewCreateManyProductInputEnvelope.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithWhereUniqueWithoutProductInputObjectSchema } from './ProductReviewUpdateWithWhereUniqueWithoutProductInput.schema'
import { ProductReviewUpdateManyWithWhereWithoutProductInputObjectSchema } from './ProductReviewUpdateManyWithWhereWithoutProductInput.schema'
import { ProductReviewScalarWhereInputObjectSchema } from './ProductReviewScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUncheckedUpdateManyWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductReviewCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductReviewCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpsertWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpsertWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductReviewCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpdateWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductReviewUpdateManyWithWhereWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductReviewUpdateManyWithWhereWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema),
          z.lazy(() => ProductReviewScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewUncheckedUpdateManyWithoutProductNestedInputObjectSchema =
  Schema
