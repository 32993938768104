import { z } from 'zod'
import { KycDataSelectObjectSchema } from './KycDataSelect.schema'
import { KycDataIncludeObjectSchema } from './KycDataInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataArgs> = z
  .object({
    select: z.lazy(() => KycDataSelectObjectSchema).optional(),
    include: z.lazy(() => KycDataIncludeObjectSchema).optional(),
  })
  .strict()

export const KycDataArgsObjectSchema = Schema
