import { z } from 'zod'
import { CarouselUpdateInputObjectSchema } from './objects/CarouselUpdateInput.schema'
import { CarouselUncheckedUpdateInputObjectSchema } from './objects/CarouselUncheckedUpdateInput.schema'
import { CarouselWhereUniqueInputObjectSchema } from './objects/CarouselWhereUniqueInput.schema'

export const CarouselUpdateOneSchema = z.object({
  data: z.union([
    CarouselUpdateInputObjectSchema,
    CarouselUncheckedUpdateInputObjectSchema,
  ]),
  where: CarouselWhereUniqueInputObjectSchema,
})
