import { z } from 'zod'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumCaseStatusFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => CaseStatusSchema).optional(),
  })
  .strict()

export const EnumCaseStatusFieldUpdateOperationsInputObjectSchema = Schema
