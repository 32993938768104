import { z } from 'zod'
import { ProcedureReviewUpdateWithoutPicturesInputObjectSchema } from './ProcedureReviewUpdateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedUpdateWithoutPicturesInput.schema'
import { ProcedureReviewCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureReviewUpdateWithoutPicturesInputObjectSchema),
      z.lazy(
        () => ProcedureReviewUncheckedUpdateWithoutPicturesInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProcedureReviewCreateWithoutPicturesInputObjectSchema),
      z.lazy(
        () => ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProcedureReviewUpsertWithoutPicturesInputObjectSchema = Schema
