import { z } from 'zod'
import { ProductPictureCreateWithoutProductInputObjectSchema } from './ProductPictureCreateWithoutProductInput.schema'
import { ProductPictureUncheckedCreateWithoutProductInputObjectSchema } from './ProductPictureUncheckedCreateWithoutProductInput.schema'
import { ProductPictureCreateOrConnectWithoutProductInputObjectSchema } from './ProductPictureCreateOrConnectWithoutProductInput.schema'
import { ProductPictureCreateManyProductInputEnvelopeObjectSchema } from './ProductPictureCreateManyProductInputEnvelope.schema'
import { ProductPictureWhereUniqueInputObjectSchema } from './ProductPictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureCreateNestedManyWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductPictureCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductPictureCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductPictureCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductPictureCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductPictureCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema),
          z.lazy(() => ProductPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductPictureCreateNestedManyWithoutProductInputObjectSchema =
  Schema
