import { z } from 'zod'

export const DeliveryScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'shippingProvider',
  'trackingNumber',
  'status',
  'deliveredAt',
  'partnerName',
  'orderId',
])
