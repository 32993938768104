import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummarySumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    productId: z.literal(true).optional(),
    brandId: z.literal(true).optional(),
    overallRating: z.literal(true).optional(),
    qualityRating: z.literal(true).optional(),
    shippingRating: z.literal(true).optional(),
    packagingRating: z.literal(true).optional(),
    countForRating5: z.literal(true).optional(),
    countForRating4: z.literal(true).optional(),
    countForRating3: z.literal(true).optional(),
    countForRating2: z.literal(true).optional(),
    countForRating1: z.literal(true).optional(),
  })
  .strict()

export const ProductReviewSummarySumAggregateInputObjectSchema = Schema
