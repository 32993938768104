import { z } from 'zod'
import { CarouselItemCreateManyCarouselInputObjectSchema } from './CarouselItemCreateManyCarouselInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateManyCarouselInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => CarouselItemCreateManyCarouselInputObjectSchema),
      z.lazy(() => CarouselItemCreateManyCarouselInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const CarouselItemCreateManyCarouselInputEnvelopeObjectSchema = Schema
