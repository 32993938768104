import { z } from 'zod'
import { CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema } from './CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateWithoutDiagnosisInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      caseSummary: z.lazy(
        () =>
          CaseSummaryCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema,
      ),
    })
    .strict()

export const CaseSummaryDiagnosisCreateWithoutDiagnosisInputObjectSchema =
  Schema
