import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    categoryId: z.literal(true).optional(),
    priority: z.literal(true).optional(),
  })
  .strict()

export const ProcedureCategoryMaxAggregateInputObjectSchema = Schema
