import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { EnumExpireTypeFilterObjectSchema } from './EnumExpireTypeFilter.schema'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { ProcedurePictureListRelationFilterObjectSchema } from './ProcedurePictureListRelationFilter.schema'
import { ProcedureCategoryListRelationFilterObjectSchema } from './ProcedureCategoryListRelationFilter.schema'
import { FlashsaleItemListRelationFilterObjectSchema } from './FlashsaleItemListRelationFilter.schema'
import { CarouselItemListRelationFilterObjectSchema } from './CarouselItemListRelationFilter.schema'
import { ProcedureReviewListRelationFilterObjectSchema } from './ProcedureReviewListRelationFilter.schema'
import { ProcedureReviewSummaryRelationFilterObjectSchema } from './ProcedureReviewSummaryRelationFilter.schema'
import { ProcedureReviewSummaryWhereInputObjectSchema } from './ProcedureReviewSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProcedureWhereInputObjectSchema),
        z.lazy(() => ProcedureWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProcedureWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProcedureWhereInputObjectSchema),
        z.lazy(() => ProcedureWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    searchable: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    searchField: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    technologyTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    usageTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    resultPeriodTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    descriptionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    preparationTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    conditionTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    methodOfUseTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    isTreatedByDoctor: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    expireType: z
      .union([
        z.lazy(() => EnumExpireTypeFilterObjectSchema),
        z.lazy(() => ExpireTypeSchema),
      ])
      .optional(),
    expireAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    expireDurationDay: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    tagPrice: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    sellingPrice: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    cost: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    commissionRate: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    partnerId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    branches: z.lazy(() => JsonFilterObjectSchema).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    deletedAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    v2DrugItemId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    pictures: z
      .lazy(() => ProcedurePictureListRelationFilterObjectSchema)
      .optional(),
    categories: z
      .lazy(() => ProcedureCategoryListRelationFilterObjectSchema)
      .optional(),
    flashsaleItem: z
      .lazy(() => FlashsaleItemListRelationFilterObjectSchema)
      .optional(),
    carouselItems: z
      .lazy(() => CarouselItemListRelationFilterObjectSchema)
      .optional(),
    procedureReviews: z
      .lazy(() => ProcedureReviewListRelationFilterObjectSchema)
      .optional(),
    procedureReviewSummary: z
      .union([
        z.lazy(() => ProcedureReviewSummaryRelationFilterObjectSchema),
        z.lazy(() => ProcedureReviewSummaryWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ProcedureWhereInputObjectSchema = Schema
