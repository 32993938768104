import { z } from 'zod'
import { PatientCreateWithoutAddressInputObjectSchema } from './PatientCreateWithoutAddressInput.schema'
import { PatientUncheckedCreateWithoutAddressInputObjectSchema } from './PatientUncheckedCreateWithoutAddressInput.schema'
import { PatientCreateOrConnectWithoutAddressInputObjectSchema } from './PatientCreateOrConnectWithoutAddressInput.schema'
import { PatientUpsertWithoutAddressInputObjectSchema } from './PatientUpsertWithoutAddressInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientUpdateWithoutAddressInputObjectSchema } from './PatientUpdateWithoutAddressInput.schema'
import { PatientUncheckedUpdateWithoutAddressInputObjectSchema } from './PatientUncheckedUpdateWithoutAddressInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpdateOneRequiredWithoutAddressNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PatientCreateWithoutAddressInputObjectSchema),
          z.lazy(() => PatientUncheckedCreateWithoutAddressInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => PatientCreateOrConnectWithoutAddressInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => PatientUpsertWithoutAddressInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PatientUpdateWithoutAddressInputObjectSchema),
          z.lazy(() => PatientUncheckedUpdateWithoutAddressInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const PatientUpdateOneRequiredWithoutAddressNestedInputObjectSchema =
  Schema
