import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientCreateWithoutHealthCheckUpInfoInput.schema'
import { PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema } from './PatientUncheckedCreateWithoutHealthCheckUpInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutHealthCheckUpInfoInput> =
  z
    .object({
      where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PatientCreateWithoutHealthCheckUpInfoInputObjectSchema),
        z.lazy(
          () => PatientUncheckedCreateWithoutHealthCheckUpInfoInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PatientCreateOrConnectWithoutHealthCheckUpInfoInputObjectSchema =
  Schema
