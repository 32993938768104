import { z } from 'zod'
import { AcceptedConsentWhereInputObjectSchema } from './AcceptedConsentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentListRelationFilter> = z
  .object({
    every: z.lazy(() => AcceptedConsentWhereInputObjectSchema).optional(),
    some: z.lazy(() => AcceptedConsentWhereInputObjectSchema).optional(),
    none: z.lazy(() => AcceptedConsentWhereInputObjectSchema).optional(),
  })
  .strict()

export const AcceptedConsentListRelationFilterObjectSchema = Schema
