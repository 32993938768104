import { z } from 'zod'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NullableEnumCancelChannelFieldUpdateOperationsInput> =
  z
    .object({
      set: z
        .lazy(() => CancelChannelSchema)
        .optional()
        .nullable(),
    })
    .strict()

export const NullableEnumCancelChannelFieldUpdateOperationsInputObjectSchema =
  Schema
