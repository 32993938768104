import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'
import { ScheduleArgsObjectSchema } from './ScheduleArgs.schema'
import { TeleconsultArgsObjectSchema } from './TeleconsultArgs.schema'
import { CaseSummaryArgsObjectSchema } from './CaseSummaryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentInclude> = z
  .object({
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    schedule: z
      .union([z.boolean(), z.lazy(() => ScheduleArgsObjectSchema)])
      .optional(),
    teleconsult: z
      .union([z.boolean(), z.lazy(() => TeleconsultArgsObjectSchema)])
      .optional(),
    followUpCaseSummary: z
      .union([z.boolean(), z.lazy(() => CaseSummaryArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const AppointmentIncludeObjectSchema = Schema
