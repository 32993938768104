import { z } from 'zod'
import { PartnerCreateWithoutProcedureOrderInputObjectSchema } from './PartnerCreateWithoutProcedureOrderInput.schema'
import { PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema } from './PartnerUncheckedCreateWithoutProcedureOrderInput.schema'
import { PartnerCreateOrConnectWithoutProcedureOrderInputObjectSchema } from './PartnerCreateOrConnectWithoutProcedureOrderInput.schema'
import { PartnerUpsertWithoutProcedureOrderInputObjectSchema } from './PartnerUpsertWithoutProcedureOrderInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'
import { PartnerUpdateWithoutProcedureOrderInputObjectSchema } from './PartnerUpdateWithoutProcedureOrderInput.schema'
import { PartnerUncheckedUpdateWithoutProcedureOrderInputObjectSchema } from './PartnerUncheckedUpdateWithoutProcedureOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpdateOneRequiredWithoutProcedureOrderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => PartnerCreateWithoutProcedureOrderInputObjectSchema),
          z.lazy(
            () => PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => PartnerCreateOrConnectWithoutProcedureOrderInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => PartnerUpsertWithoutProcedureOrderInputObjectSchema)
        .optional(),
      connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => PartnerUpdateWithoutProcedureOrderInputObjectSchema),
          z.lazy(
            () => PartnerUncheckedUpdateWithoutProcedureOrderInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const PartnerUpdateOneRequiredWithoutProcedureOrderNestedInputObjectSchema =
  Schema
