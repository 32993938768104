import { z } from 'zod'
import { ProcedureItemCreateManyRefundInputObjectSchema } from './ProcedureItemCreateManyRefundInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemCreateManyRefundInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => ProcedureItemCreateManyRefundInputObjectSchema),
      z.lazy(() => ProcedureItemCreateManyRefundInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const ProcedureItemCreateManyRefundInputEnvelopeObjectSchema = Schema
