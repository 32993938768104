import { z } from 'zod'
import { SuggestedProductItemOriginalSelectObjectSchema } from './SuggestedProductItemOriginalSelect.schema'
import { SuggestedProductItemOriginalIncludeObjectSchema } from './SuggestedProductItemOriginalInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalArgs> = z
  .object({
    select: z
      .lazy(() => SuggestedProductItemOriginalSelectObjectSchema)
      .optional(),
    include: z
      .lazy(() => SuggestedProductItemOriginalIncludeObjectSchema)
      .optional(),
  })
  .strict()

export const SuggestedProductItemOriginalArgsObjectSchema = Schema
