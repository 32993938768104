import { z } from 'zod'
import { CarouselSelectObjectSchema } from './CarouselSelect.schema'
import { CarouselIncludeObjectSchema } from './CarouselInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselArgs> = z
  .object({
    select: z.lazy(() => CarouselSelectObjectSchema).optional(),
    include: z.lazy(() => CarouselIncludeObjectSchema).optional(),
  })
  .strict()

export const CarouselArgsObjectSchema = Schema
