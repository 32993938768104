import { z } from 'zod'
import { ProductCategoryOrderByWithRelationInputObjectSchema } from './objects/ProductCategoryOrderByWithRelationInput.schema'
import { ProductCategoryWhereInputObjectSchema } from './objects/ProductCategoryWhereInput.schema'
import { ProductCategoryWhereUniqueInputObjectSchema } from './objects/ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryScalarFieldEnumSchema } from './enums/ProductCategoryScalarFieldEnum.schema'

export const ProductCategoryFindFirstSchema = z.object({
  orderBy: z
    .union([
      ProductCategoryOrderByWithRelationInputObjectSchema,
      ProductCategoryOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductCategoryWhereInputObjectSchema.optional(),
  cursor: ProductCategoryWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductCategoryScalarFieldEnumSchema).optional(),
})
