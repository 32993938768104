import * as z from "zod"
import { CompleteBrandBanner, RelatedBrandBannerModel, CompleteProduct, RelatedProductModel, CompleteProductReview, RelatedProductReviewModel, CompleteProductReviewSummary, RelatedProductReviewSummaryModel } from "./index"

export const BrandModel = z.object({
  id: z.number().int(),
  name: z.string(),
  isShow: z.boolean(),
  coporateName: z.string(),
  countryName: z.string(),
  logo: z.string().nullish(),
  priority: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteBrand extends z.infer<typeof BrandModel> {
  banners: CompleteBrandBanner[]
  products: CompleteProduct[]
  productReviews: CompleteProductReview[]
  productReviewSummaries: CompleteProductReviewSummary[]
}

/**
 * RelatedBrandModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBrandModel: z.ZodSchema<CompleteBrand> = z.lazy(() => BrandModel.extend({
  banners: RelatedBrandBannerModel.array(),
  products: RelatedProductModel.array(),
  productReviews: RelatedProductReviewModel.array(),
  productReviewSummaries: RelatedProductReviewSummaryModel.array(),
}))
