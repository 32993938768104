import { z } from 'zod'

export const IdentityScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'identityId',
  'type',
  'password',
  'meta',
  'patientId',
])
