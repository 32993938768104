import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateManyKycDataInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    field: z.string(),
    reason: z.string(),
  })
  .strict()

export const RejectReasonCreateManyKycDataInputObjectSchema = Schema
