import { z } from 'zod'
import { ProductReviewPictureCreateInputObjectSchema } from './objects/ProductReviewPictureCreateInput.schema'
import { ProductReviewPictureUncheckedCreateInputObjectSchema } from './objects/ProductReviewPictureUncheckedCreateInput.schema'

export const ProductReviewPictureCreateOneSchema = z.object({
  data: z.union([
    ProductReviewPictureCreateInputObjectSchema,
    ProductReviewPictureUncheckedCreateInputObjectSchema,
  ]),
})
