import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { IdentityCountOrderByAggregateInputObjectSchema } from './IdentityCountOrderByAggregateInput.schema'
import { IdentityAvgOrderByAggregateInputObjectSchema } from './IdentityAvgOrderByAggregateInput.schema'
import { IdentityMaxOrderByAggregateInputObjectSchema } from './IdentityMaxOrderByAggregateInput.schema'
import { IdentityMinOrderByAggregateInputObjectSchema } from './IdentityMinOrderByAggregateInput.schema'
import { IdentitySumOrderByAggregateInputObjectSchema } from './IdentitySumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    identityId: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    password: z.lazy(() => SortOrderSchema).optional(),
    meta: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => IdentityCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => IdentityAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => IdentityMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => IdentityMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => IdentitySumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const IdentityOrderByWithAggregationInputObjectSchema = Schema
