import { z } from 'zod'
import { UserCreateWithoutPartnerInputObjectSchema } from './UserCreateWithoutPartnerInput.schema'
import { UserUncheckedCreateWithoutPartnerInputObjectSchema } from './UserUncheckedCreateWithoutPartnerInput.schema'
import { UserCreateOrConnectWithoutPartnerInputObjectSchema } from './UserCreateOrConnectWithoutPartnerInput.schema'
import { UserUpsertWithWhereUniqueWithoutPartnerInputObjectSchema } from './UserUpsertWithWhereUniqueWithoutPartnerInput.schema'
import { UserCreateManyPartnerInputEnvelopeObjectSchema } from './UserCreateManyPartnerInputEnvelope.schema'
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema'
import { UserUpdateWithWhereUniqueWithoutPartnerInputObjectSchema } from './UserUpdateWithWhereUniqueWithoutPartnerInput.schema'
import { UserUpdateManyWithWhereWithoutPartnerInputObjectSchema } from './UserUpdateManyWithWhereWithoutPartnerInput.schema'
import { UserScalarWhereInputObjectSchema } from './UserScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserUncheckedUpdateManyWithoutPartnerNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => UserCreateWithoutPartnerInputObjectSchema),
          z.lazy(() => UserCreateWithoutPartnerInputObjectSchema).array(),
          z.lazy(() => UserUncheckedCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => UserUncheckedCreateWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => UserCreateOrConnectWithoutPartnerInputObjectSchema),
          z
            .lazy(() => UserCreateOrConnectWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () => UserUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => UserUpsertWithWhereUniqueWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => UserCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () => UserUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => UserUpdateWithWhereUniqueWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(() => UserUpdateManyWithWhereWithoutPartnerInputObjectSchema),
          z
            .lazy(() => UserUpdateManyWithWhereWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => UserScalarWhereInputObjectSchema),
          z.lazy(() => UserScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const UserUncheckedUpdateManyWithoutPartnerNestedInputObjectSchema =
  Schema
