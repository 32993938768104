import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { ProcedureRelationFilterObjectSchema } from './ProcedureRelationFilter.schema'
import { ProcedureWhereInputObjectSchema } from './ProcedureWhereInput.schema'
import { CategoryRelationFilterObjectSchema } from './CategoryRelationFilter.schema'
import { CategoryWhereInputObjectSchema } from './CategoryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProcedureCategoryWhereInputObjectSchema),
        z.lazy(() => ProcedureCategoryWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProcedureCategoryWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProcedureCategoryWhereInputObjectSchema),
        z.lazy(() => ProcedureCategoryWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    categoryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedure: z
      .union([
        z.lazy(() => ProcedureRelationFilterObjectSchema),
        z.lazy(() => ProcedureWhereInputObjectSchema),
      ])
      .optional(),
    category: z
      .union([
        z.lazy(() => CategoryRelationFilterObjectSchema),
        z.lazy(() => CategoryWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureCategoryWhereInputObjectSchema = Schema
