import { z } from 'zod'
import { AddressCreateWithoutPatientInputObjectSchema } from './AddressCreateWithoutPatientInput.schema'
import { AddressUncheckedCreateWithoutPatientInputObjectSchema } from './AddressUncheckedCreateWithoutPatientInput.schema'
import { AddressCreateOrConnectWithoutPatientInputObjectSchema } from './AddressCreateOrConnectWithoutPatientInput.schema'
import { AddressUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './AddressUpsertWithWhereUniqueWithoutPatientInput.schema'
import { AddressCreateManyPatientInputEnvelopeObjectSchema } from './AddressCreateManyPatientInputEnvelope.schema'
import { AddressWhereUniqueInputObjectSchema } from './AddressWhereUniqueInput.schema'
import { AddressUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './AddressUpdateWithWhereUniqueWithoutPatientInput.schema'
import { AddressUpdateManyWithWhereWithoutPatientInputObjectSchema } from './AddressUpdateManyWithWhereWithoutPatientInput.schema'
import { AddressScalarWhereInputObjectSchema } from './AddressScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressUpdateManyWithoutPatientNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => AddressCreateWithoutPatientInputObjectSchema),
        z.lazy(() => AddressCreateWithoutPatientInputObjectSchema).array(),
        z.lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema),
        z
          .lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => AddressCreateOrConnectWithoutPatientInputObjectSchema),
        z
          .lazy(() => AddressCreateOrConnectWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => AddressUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
        ),
        z
          .lazy(
            () => AddressUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => AddressCreateManyPatientInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => AddressWhereUniqueInputObjectSchema),
        z.lazy(() => AddressWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => AddressWhereUniqueInputObjectSchema),
        z.lazy(() => AddressWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => AddressWhereUniqueInputObjectSchema),
        z.lazy(() => AddressWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => AddressWhereUniqueInputObjectSchema),
        z.lazy(() => AddressWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => AddressUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
        ),
        z
          .lazy(
            () => AddressUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => AddressUpdateManyWithWhereWithoutPatientInputObjectSchema),
        z
          .lazy(() => AddressUpdateManyWithWhereWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => AddressScalarWhereInputObjectSchema),
        z.lazy(() => AddressScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const AddressUpdateManyWithoutPatientNestedInputObjectSchema = Schema
