import { z } from 'zod'
import { ProcedureCreateWithoutFlashsaleItemInputObjectSchema } from './ProcedureCreateWithoutFlashsaleItemInput.schema'
import { ProcedureUncheckedCreateWithoutFlashsaleItemInputObjectSchema } from './ProcedureUncheckedCreateWithoutFlashsaleItemInput.schema'
import { ProcedureCreateOrConnectWithoutFlashsaleItemInputObjectSchema } from './ProcedureCreateOrConnectWithoutFlashsaleItemInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateNestedOneWithoutFlashsaleItemInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutFlashsaleItemInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutFlashsaleItemInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProcedureCreateOrConnectWithoutFlashsaleItemInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProcedureCreateNestedOneWithoutFlashsaleItemInputObjectSchema =
  Schema
