import { z } from 'zod'
import { AcceptedConsentCreateWithoutPatientInputObjectSchema } from './AcceptedConsentCreateWithoutPatientInput.schema'
import { AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema } from './AcceptedConsentUncheckedCreateWithoutPatientInput.schema'
import { AcceptedConsentCreateOrConnectWithoutPatientInputObjectSchema } from './AcceptedConsentCreateOrConnectWithoutPatientInput.schema'
import { AcceptedConsentUpsertWithWhereUniqueWithoutPatientInputObjectSchema } from './AcceptedConsentUpsertWithWhereUniqueWithoutPatientInput.schema'
import { AcceptedConsentCreateManyPatientInputEnvelopeObjectSchema } from './AcceptedConsentCreateManyPatientInputEnvelope.schema'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentUpdateWithWhereUniqueWithoutPatientInputObjectSchema } from './AcceptedConsentUpdateWithWhereUniqueWithoutPatientInput.schema'
import { AcceptedConsentUpdateManyWithWhereWithoutPatientInputObjectSchema } from './AcceptedConsentUpdateManyWithWhereWithoutPatientInput.schema'
import { AcceptedConsentScalarWhereInputObjectSchema } from './AcceptedConsentScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateManyWithoutPatientNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => AcceptedConsentCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => AcceptedConsentCreateWithoutPatientInputObjectSchema)
            .array(),
          z.lazy(
            () => AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentUncheckedCreateWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => AcceptedConsentCreateOrConnectWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentCreateOrConnectWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              AcceptedConsentUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentUpsertWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => AcceptedConsentCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
          z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              AcceptedConsentUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentUpdateWithWhereUniqueWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              AcceptedConsentUpdateManyWithWhereWithoutPatientInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                AcceptedConsentUpdateManyWithWhereWithoutPatientInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => AcceptedConsentScalarWhereInputObjectSchema),
          z.lazy(() => AcceptedConsentScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const AcceptedConsentUpdateManyWithoutPatientNestedInputObjectSchema =
  Schema
