import { z } from 'zod'
import { SuggestedProductItemWhereInputObjectSchema } from './objects/SuggestedProductItemWhereInput.schema'
import { SuggestedProductItemOrderByWithAggregationInputObjectSchema } from './objects/SuggestedProductItemOrderByWithAggregationInput.schema'
import { SuggestedProductItemScalarWhereWithAggregatesInputObjectSchema } from './objects/SuggestedProductItemScalarWhereWithAggregatesInput.schema'
import { SuggestedProductItemScalarFieldEnumSchema } from './enums/SuggestedProductItemScalarFieldEnum.schema'

export const SuggestedProductItemGroupBySchema = z.object({
  where: SuggestedProductItemWhereInputObjectSchema.optional(),
  orderBy: z.union([
    SuggestedProductItemOrderByWithAggregationInputObjectSchema,
    SuggestedProductItemOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    SuggestedProductItemScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(SuggestedProductItemScalarFieldEnumSchema),
})
