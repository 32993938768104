import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptSumAggregateInputType> = z
  .object({
    orderId: z.literal(true).optional(),
  })
  .strict()

export const ReceiptSumAggregateInputObjectSchema = Schema
