import { z } from 'zod'
import { ProductReviewPictureUncheckedCreateNestedManyWithoutProductReviewInputObjectSchema } from './ProductReviewPictureUncheckedCreateNestedManyWithoutProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    variantId: z.number(),
    productId: z.number(),
    brandId: z.number(),
    overallRating: z.number(),
    qualityRating: z.number(),
    shippingRating: z.number(),
    packagingRating: z.number(),
    message: z.string().optional().nullable(),
    orderId: z.number(),
    patientId: z.number(),
    patientName: z.string(),
    isAnonymous: z.boolean(),
    priority: z.number(),
    pictures: z
      .lazy(
        () =>
          ProductReviewPictureUncheckedCreateNestedManyWithoutProductReviewInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProductReviewUncheckedCreateInputObjectSchema = Schema
