import { z } from 'zod'
import { SkuOrderByWithRelationInputObjectSchema } from './objects/SkuOrderByWithRelationInput.schema'
import { SkuWhereInputObjectSchema } from './objects/SkuWhereInput.schema'
import { SkuWhereUniqueInputObjectSchema } from './objects/SkuWhereUniqueInput.schema'
import { SkuCountAggregateInputObjectSchema } from './objects/SkuCountAggregateInput.schema'
import { SkuMinAggregateInputObjectSchema } from './objects/SkuMinAggregateInput.schema'
import { SkuMaxAggregateInputObjectSchema } from './objects/SkuMaxAggregateInput.schema'
import { SkuAvgAggregateInputObjectSchema } from './objects/SkuAvgAggregateInput.schema'
import { SkuSumAggregateInputObjectSchema } from './objects/SkuSumAggregateInput.schema'

export const SkuAggregateSchema = z.object({
  orderBy: z
    .union([
      SkuOrderByWithRelationInputObjectSchema,
      SkuOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SkuWhereInputObjectSchema.optional(),
  cursor: SkuWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), SkuCountAggregateInputObjectSchema])
    .optional(),
  _min: SkuMinAggregateInputObjectSchema.optional(),
  _max: SkuMaxAggregateInputObjectSchema.optional(),
  _avg: SkuAvgAggregateInputObjectSchema.optional(),
  _sum: SkuSumAggregateInputObjectSchema.optional(),
})
