import { z } from 'zod'
import { CouponGroupUpdateWithoutConditionSettingInputObjectSchema } from './CouponGroupUpdateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedUpdateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedUpdateWithoutConditionSettingInput.schema'
import { CouponGroupCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupCreateWithoutConditionSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutConditionSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupUpsertWithoutConditionSettingInput> =
  z
    .object({
      update: z.union([
        z.lazy(() => CouponGroupUpdateWithoutConditionSettingInputObjectSchema),
        z.lazy(
          () =>
            CouponGroupUncheckedUpdateWithoutConditionSettingInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => CouponGroupCreateWithoutConditionSettingInputObjectSchema),
        z.lazy(
          () =>
            CouponGroupUncheckedCreateWithoutConditionSettingInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CouponGroupUpsertWithoutConditionSettingInputObjectSchema = Schema
