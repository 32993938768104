import { z } from 'zod'
import { OtpBlacklistCreateInputObjectSchema } from './objects/OtpBlacklistCreateInput.schema'
import { OtpBlacklistUncheckedCreateInputObjectSchema } from './objects/OtpBlacklistUncheckedCreateInput.schema'

export const OtpBlacklistCreateOneSchema = z.object({
  data: z.union([
    OtpBlacklistCreateInputObjectSchema,
    OtpBlacklistUncheckedCreateInputObjectSchema,
  ]),
})
