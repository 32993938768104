import { z } from 'zod'
import { SuggestedProductItemWhereUniqueInputObjectSchema } from './objects/SuggestedProductItemWhereUniqueInput.schema'
import { SuggestedProductItemCreateInputObjectSchema } from './objects/SuggestedProductItemCreateInput.schema'
import { SuggestedProductItemUncheckedCreateInputObjectSchema } from './objects/SuggestedProductItemUncheckedCreateInput.schema'
import { SuggestedProductItemUpdateInputObjectSchema } from './objects/SuggestedProductItemUpdateInput.schema'
import { SuggestedProductItemUncheckedUpdateInputObjectSchema } from './objects/SuggestedProductItemUncheckedUpdateInput.schema'

export const SuggestedProductItemUpsertSchema = z.object({
  where: SuggestedProductItemWhereUniqueInputObjectSchema,
  create: z.union([
    SuggestedProductItemCreateInputObjectSchema,
    SuggestedProductItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    SuggestedProductItemUpdateInputObjectSchema,
    SuggestedProductItemUncheckedUpdateInputObjectSchema,
  ]),
})
