import { z } from 'zod'
import { VariantUpdateWithoutProductReviewsInputObjectSchema } from './VariantUpdateWithoutProductReviewsInput.schema'
import { VariantUncheckedUpdateWithoutProductReviewsInputObjectSchema } from './VariantUncheckedUpdateWithoutProductReviewsInput.schema'
import { VariantCreateWithoutProductReviewsInputObjectSchema } from './VariantCreateWithoutProductReviewsInput.schema'
import { VariantUncheckedCreateWithoutProductReviewsInputObjectSchema } from './VariantUncheckedCreateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpsertWithoutProductReviewsInput> = z
  .object({
    update: z.union([
      z.lazy(() => VariantUpdateWithoutProductReviewsInputObjectSchema),
      z.lazy(
        () => VariantUncheckedUpdateWithoutProductReviewsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => VariantCreateWithoutProductReviewsInputObjectSchema),
      z.lazy(
        () => VariantUncheckedCreateWithoutProductReviewsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const VariantUpsertWithoutProductReviewsInputObjectSchema = Schema
