import { z } from 'zod'
import { ProcedureCountOutputTypeSelectObjectSchema } from './ProcedureCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => ProcedureCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const ProcedureCountOutputTypeArgsObjectSchema = Schema
