import { Box } from '@mui/material'
import { FC } from 'react'
import { Validator } from 'react-admin'
import { CustomSelectInput } from '../../../components/CustomInput'

export interface FormSelectInputProps {
  source: string
  label: string
  choices: { id: string; name: string }[]
  validator?: Validator
}

export const FormSelectInput: FC<FormSelectInputProps> = ({
  source,
  label,
  choices,
  validator,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <CustomSelectInput
          sx={{ mb: 1 }}
          fullWidth
          source={source}
          label={label}
          choices={choices}
          validate={validator}
        />
      </Box>
    </Box>
  )
}
