import { z } from 'zod'
import { ScheduleWhereUniqueInputObjectSchema } from './ScheduleWhereUniqueInput.schema'
import { ScheduleCreateWithoutAppointmentInputObjectSchema } from './ScheduleCreateWithoutAppointmentInput.schema'
import { ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema } from './ScheduleUncheckedCreateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleCreateOrConnectWithoutAppointmentInput> =
  z
    .object({
      where: z.lazy(() => ScheduleWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ScheduleCreateWithoutAppointmentInputObjectSchema),
        z.lazy(
          () => ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ScheduleCreateOrConnectWithoutAppointmentInputObjectSchema = Schema
