import { z } from 'zod'
import { ExpressShippingAreaCreateManyShippingProviderInputObjectSchema } from './ExpressShippingAreaCreateManyShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaCreateManyShippingProviderInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () => ExpressShippingAreaCreateManyShippingProviderInputObjectSchema,
        ),
        z
          .lazy(
            () =>
              ExpressShippingAreaCreateManyShippingProviderInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ExpressShippingAreaCreateManyShippingProviderInputEnvelopeObjectSchema =
  Schema
