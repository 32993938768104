import { Box, Card, CardContent } from '@mui/material'
import { Create, SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BranchAddress } from './create-edit-section/branchAddress'
import { BranchInfo } from './create-edit-section/branchInfo'
import { BranchOfficeHour } from './create-edit-section/branchOfficeHour'
import { transformPartnerBranch } from './transform'

const PartnerBranchCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerBranchCreateForm = () => {
  return (
    <SimpleForm toolbar={<PartnerBranchCreateToolbar />}>
      <BranchInfo />
      <BranchAddress />
      <BranchOfficeHour />
    </SimpleForm>
  )
}

export const PartnerBranchCreate = () => {
  return (
    <Create transform={transformPartnerBranch}>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <PartnerBranchCreateForm />
        </CardContent>
      </Card>
    </Create>
  )
}
