import { z } from 'zod'
import { ShippingProviderCreateInputObjectSchema } from './objects/ShippingProviderCreateInput.schema'
import { ShippingProviderUncheckedCreateInputObjectSchema } from './objects/ShippingProviderUncheckedCreateInput.schema'

export const ShippingProviderCreateOneSchema = z.object({
  data: z.union([
    ShippingProviderCreateInputObjectSchema,
    ShippingProviderUncheckedCreateInputObjectSchema,
  ]),
})
