import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    ipAddress: z.string(),
    phone: z.string(),
    reference: z.string().optional(),
    createdAt: z.date().optional(),
    expiredAt: z.date(),
    verifiedAt: z.date().optional().nullable(),
    otp: z.string(),
  })
  .strict()

export const OtpUncheckedCreateInputObjectSchema = Schema
