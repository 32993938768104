import { z } from 'zod'
import { AppointmentArgsObjectSchema } from './AppointmentArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleInclude> = z
  .object({
    appointment: z
      .union([z.boolean(), z.lazy(() => AppointmentArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ScheduleIncludeObjectSchema = Schema
