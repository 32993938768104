import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { FlashsaleRelationFilterObjectSchema } from './FlashsaleRelationFilter.schema'
import { FlashsaleWhereInputObjectSchema } from './FlashsaleWhereInput.schema'
import { VariantRelationFilterObjectSchema } from './VariantRelationFilter.schema'
import { VariantWhereInputObjectSchema } from './VariantWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => FlashsaleItemWhereInputObjectSchema),
        z.lazy(() => FlashsaleItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => FlashsaleItemWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => FlashsaleItemWhereInputObjectSchema),
        z.lazy(() => FlashsaleItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    flashsaleId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    startAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    endAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    price: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    saleLimit: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    sold: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    flashsale: z
      .union([
        z.lazy(() => FlashsaleRelationFilterObjectSchema),
        z.lazy(() => FlashsaleWhereInputObjectSchema),
      ])
      .optional(),
    variant: z
      .union([
        z.lazy(() => VariantRelationFilterObjectSchema),
        z.lazy(() => VariantWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const FlashsaleItemWhereInputObjectSchema = Schema
