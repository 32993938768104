import { z } from 'zod'
import { WarehouseCreateInputObjectSchema } from './objects/WarehouseCreateInput.schema'
import { WarehouseUncheckedCreateInputObjectSchema } from './objects/WarehouseUncheckedCreateInput.schema'

export const WarehouseCreateOneSchema = z.object({
  data: z.union([
    WarehouseCreateInputObjectSchema,
    WarehouseUncheckedCreateInputObjectSchema,
  ]),
})
