import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { UserOrderByRelationAggregateInputObjectSchema } from './UserOrderByRelationAggregateInput.schema'
import { BranchOrderByRelationAggregateInputObjectSchema } from './BranchOrderByRelationAggregateInput.schema'
import { CoverPictureOrderByRelationAggregateInputObjectSchema } from './CoverPictureOrderByRelationAggregateInput.schema'
import { SkuOrderByRelationAggregateInputObjectSchema } from './SkuOrderByRelationAggregateInput.schema'
import { ProcedureOrderOrderByRelationAggregateInputObjectSchema } from './ProcedureOrderOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    logo: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    website: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    isExpertise: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    isSXEasy: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    taxId: z.lazy(() => SortOrderSchema).optional(),
    v2HospitalId: z.lazy(() => SortOrderSchema).optional(),
    v2StoreId: z.lazy(() => SortOrderSchema).optional(),
    users: z
      .lazy(() => UserOrderByRelationAggregateInputObjectSchema)
      .optional(),
    branches: z
      .lazy(() => BranchOrderByRelationAggregateInputObjectSchema)
      .optional(),
    coverPictures: z
      .lazy(() => CoverPictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
    sku: z.lazy(() => SkuOrderByRelationAggregateInputObjectSchema).optional(),
    ProcedureOrder: z
      .lazy(() => ProcedureOrderOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const PartnerOrderByWithRelationInputObjectSchema = Schema
