import { z } from 'zod'
import { CategoryArgsObjectSchema } from './CategoryArgs.schema'
import { CategoryFindManySchema } from '../findManyCategory.schema'
import { ProductCategoryFindManySchema } from '../findManyProductCategory.schema'
import { CategoryCountOutputTypeArgsObjectSchema } from './CategoryCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryInclude> = z
  .object({
    parent: z
      .union([z.boolean(), z.lazy(() => CategoryArgsObjectSchema)])
      .optional(),
    children: z
      .union([z.boolean(), z.lazy(() => CategoryFindManySchema)])
      .optional(),
    products: z
      .union([z.boolean(), z.lazy(() => ProductCategoryFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CategoryCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CategoryIncludeObjectSchema = Schema
