import { z } from 'zod'
import { FlashsaleItemUncheckedCreateNestedManyWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedCreateNestedManyWithoutFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    createdBy: z.number().optional(),
    updatedBy: z.number().optional(),
    name: z.string(),
    description: z.string().optional().nullable(),
    startAt: z.date(),
    endAt: z.date(),
    active: z.boolean().optional(),
    items: z
      .lazy(
        () =>
          FlashsaleItemUncheckedCreateNestedManyWithoutFlashsaleInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const FlashsaleUncheckedCreateInputObjectSchema = Schema
