import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    active: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    flashsaleId: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    endAt: z.literal(true).optional(),
    price: z.literal(true).optional(),
    priceAbsorbedBySkinX: z.literal(true).optional(),
    priceAbsorbedByPartner: z.literal(true).optional(),
    saleLimit: z.literal(true).optional(),
    sold: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const FlashsaleItemCountAggregateInputObjectSchema = Schema
