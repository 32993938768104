import { z } from 'zod'
import { WarehouseUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema } from './WarehouseUpdateOneRequiredWithoutVariantWarehousesNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateWithoutVariantInput> = z
  .object({
    warehouse: z
      .lazy(
        () =>
          WarehouseUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const VariantWarehouseUpdateWithoutVariantInputObjectSchema = Schema
