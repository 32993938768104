import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUncheckedCreateWithoutPicturesInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      procedureId: z.number(),
      partnerId: z.number(),
      overallRating: z.number(),
      environmentRating: z.number(),
      serviceRating: z.number(),
      specialtyRating: z.number(),
      valueRating: z.number(),
      message: z.string().optional().nullable(),
      orderId: z.number(),
      patientId: z.number(),
      patientName: z.string(),
      isAnonymous: z.boolean(),
      priority: z.number(),
    })
    .strict()

export const ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema =
  Schema
