import { z } from 'zod'
import { ProductUpdateWithoutProductReviewSummaryInputObjectSchema } from './ProductUpdateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedUpdateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedUpdateWithoutProductReviewSummaryInput.schema'
import { ProductCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductCreateWithoutProductReviewSummaryInput.schema'
import { ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutProductReviewSummaryInput> =
  z
    .object({
      update: z.union([
        z.lazy(() => ProductUpdateWithoutProductReviewSummaryInputObjectSchema),
        z.lazy(
          () =>
            ProductUncheckedUpdateWithoutProductReviewSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCreateWithoutProductReviewSummaryInputObjectSchema),
        z.lazy(
          () =>
            ProductUncheckedCreateWithoutProductReviewSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductUpsertWithoutProductReviewSummaryInputObjectSchema = Schema
