import { z } from 'zod'
import { DeliveryWhereInputObjectSchema } from './DeliveryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryListRelationFilter> = z
  .object({
    every: z.lazy(() => DeliveryWhereInputObjectSchema).optional(),
    some: z.lazy(() => DeliveryWhereInputObjectSchema).optional(),
    none: z.lazy(() => DeliveryWhereInputObjectSchema).optional(),
  })
  .strict()

export const DeliveryListRelationFilterObjectSchema = Schema
