import {
  CloseRounded as CloseRoundedIcon,
  Publish as PublishIcon,
} from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from '@mui/material'
import { TRPCError } from '@trpc/server'
import { FC } from 'react'
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { FieldValues } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { adminGatewayClient, isTRPCClientError } from '../../../service'
import { FlashsaleProductImportForm } from './importForm'
interface FlashsaleItemProductCreate {
  variantId: number
  price: number
  saleLimit: number
  priority: number
}
const FlashsaleProceductImportToolbar: FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <CardActions sx={{ display: 'flex', gap: 2 }}>
        <Button label="cancel" onClick={() => onCancel()} />
        <SaveButton label="import" icon={<PublishIcon />} alwaysEnable />
      </CardActions>
    </Toolbar>
  )
}

export const FlashsaleProductImportModal: FC<{
  open?: boolean
  disableCloseOnBackdrop?: boolean
  mode?: 'CREATE' | 'UPDATE'
  onClose?: () => void
}> = ({
  open = false,
  disableCloseOnBackdrop = false,
  mode = 'CREATE',
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const readXlsxProductItemsFile = (file: File) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const bstr = e.target?.result
        const workbook = XLSX.read(bstr, { type: 'binary' })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const readedFile = XLSX.utils.sheet_to_json<any>(
          workbook.Sheets[workbook.SheetNames[0]],
        )
        const result = readedFile.map((x, index) => {
          if (
            !x['Item ID'] ||
            !x['Campaign Price'] ||
            !x['Quota'] ||
            isNaN(Number(x['Item ID'])) ||
            isNaN(Number(x['Campaign Price'].toString().replace(/,/g, ''))) ||
            isNaN(Number(x['Quota'])) ||
            Number(x['Campaign Price'].toString().replace(/,/g, '')) <= 0 ||
            Number(x['Quota']) <= 0
          ) {
            reject(
              new TRPCError({
                code: 'FORBIDDEN',
                message: 'Excel format is incorrect',
              }),
            )
            return null
          }

          return {
            variantId: Number(x['Item ID']),
            price: Number(x['Campaign Price'].toString().replace(/,/g, '')),
            saleLimit: Number(x['Quota']),
            priority: readedFile.length - index,
          }
        }) as FlashsaleItemProductCreate[]
        resolve(result)
      }
      reader.readAsBinaryString(file)
    })
  }

  const validateFlashsaleItemProducts = (
    items: FlashsaleItemProductCreate[],
  ) => {
    // Check duplicated item
    const uniqueSetItems = new Set(items.map((deal) => deal.variantId))
    const duplicatedItems = Array.from(
      new Set(
        items.filter((item) => {
          if (uniqueSetItems.has(item.variantId)) {
            uniqueSetItems.delete(item.variantId)
            return false
          } else {
            return item.variantId + ' '
          }
        }),
      ),
    )

    if (duplicatedItems.length > 0) {
      throw new TRPCError({
        code: 'FORBIDDEN',
        message: `Item id: ${duplicatedItems.map(
          (item) => item.variantId,
        )} is/are shown more than once`,
      })
    }
  }

  const handleSubmitForm = async (flashsale: FieldValues) => {
    try {
      const variants = (await readXlsxProductItemsFile(
        flashsale.flashsaleItems.rawFile,
      )) as FlashsaleItemProductCreate[]
      validateFlashsaleItemProducts(variants)
      const payload = {
        name: flashsale.name as string,
        description: flashsale.description as string,
        startAt: new Date(flashsale.startAt),
        endAt: new Date(flashsale.endAt),
        flashsaleItems: variants,
      }
      if (mode === 'UPDATE') {
        await adminGatewayClient.flashsaleProduct.updateOneByXlsx.mutate({
          where: { id: flashsale.id },
          data: payload.flashsaleItems,
          replace: flashsale.replace,
        })
        notify(
          `Flash Sale: ${payload.name} and ${payload.flashsaleItems.length} items were updated`,
          { type: 'success' },
        )
      } else {
        await adminGatewayClient.flashsaleProduct.createOneByXlsx.mutate({
          data: payload,
        })
        notify(
          `Flash Sale: ${payload.name} and ${payload.flashsaleItems.length} items were created`,
          { type: 'success' },
        )
      }
      handleClose()
      refresh()
    } catch (e) {
      if (isTRPCClientError(e) || e instanceof TRPCError) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => !disableCloseOnBackdrop && handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title={translate('resources.flashsaleItemProduct.title.import_modal')}
          subheader={translate('resources.product.title.product')}
        />
        <SimpleForm
          toolbar={
            <FlashsaleProceductImportToolbar onCancel={() => handleClose()} />
          }
          onSubmit={handleSubmitForm}
        >
          <CardContent sx={{ p: 0 }}>
            <FlashsaleProductImportForm mode={mode} />
          </CardContent>
        </SimpleForm>
      </Card>
    </Modal>
  )
}
