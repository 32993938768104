import { z } from 'zod'
import { ProcedureUpdateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUpdateWithoutProcedureReviewsInput.schema'
import { ProcedureUncheckedUpdateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUncheckedUpdateWithoutProcedureReviewsInput.schema'
import { ProcedureCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewsInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpsertWithoutProcedureReviewsInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProcedureUpdateWithoutProcedureReviewsInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedUpdateWithoutProcedureReviewsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProcedureCreateWithoutProcedureReviewsInputObjectSchema),
      z.lazy(
        () => ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProcedureUpsertWithoutProcedureReviewsInputObjectSchema = Schema
