import { z } from 'zod'
import { CouponSelectObjectSchema } from './CouponSelect.schema'
import { CouponIncludeObjectSchema } from './CouponInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponArgs> = z
  .object({
    select: z.lazy(() => CouponSelectObjectSchema).optional(),
    include: z.lazy(() => CouponIncludeObjectSchema).optional(),
  })
  .strict()

export const CouponArgsObjectSchema = Schema
