import { z } from 'zod'
import { CoverPictureWhereInputObjectSchema } from './CoverPictureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureListRelationFilter> = z
  .object({
    every: z.lazy(() => CoverPictureWhereInputObjectSchema).optional(),
    some: z.lazy(() => CoverPictureWhereInputObjectSchema).optional(),
    none: z.lazy(() => CoverPictureWhereInputObjectSchema).optional(),
  })
  .strict()

export const CoverPictureListRelationFilterObjectSchema = Schema
