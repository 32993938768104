import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCountOutputTypeSelect> = z
  .object({
    productItems: z.boolean().optional(),
    procedureItems: z.boolean().optional(),
  })
  .strict()

export const RefundCountOutputTypeSelectObjectSchema = Schema
