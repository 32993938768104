import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { SymptomDurationUnitSchema } from '../enums/SymptomDurationUnit.schema'
import { NullableEnumSymptomDurationUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumSymptomDurationUnitFieldUpdateOperationsInput.schema'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { EnumCaseStatusFieldUpdateOperationsInputObjectSchema } from './EnumCaseStatusFieldUpdateOperationsInput.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { EnumConsultingChannelFieldUpdateOperationsInputObjectSchema } from './EnumConsultingChannelFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { CaseUpdateOneWithoutPreviousCaseNestedInputObjectSchema } from './CaseUpdateOneWithoutPreviousCaseNestedInput.schema'
import { CaseUpdateOneWithoutFollowupCaseNestedInputObjectSchema } from './CaseUpdateOneWithoutFollowupCaseNestedInput.schema'
import { CasePictureUpdateManyWithoutCaseNestedInputObjectSchema } from './CasePictureUpdateManyWithoutCaseNestedInput.schema'
import { AppointmentUpdateOneWithoutCaseNestedInputObjectSchema } from './AppointmentUpdateOneWithoutCaseNestedInput.schema'
import { TeleconsultUpdateOneWithoutCaseNestedInputObjectSchema } from './TeleconsultUpdateOneWithoutCaseNestedInput.schema'
import { CaseSummaryUpdateOneWithoutCaseNestedInputObjectSchema } from './CaseSummaryUpdateOneWithoutCaseNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpdateWithoutMissedCallTeleconsultInput> = z
  .object({
    patientId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    doctorId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    symptom: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    drugAllergy: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    symptomDuration: z
      .union([
        z.number(),
        z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    symptomDurationUnit: z
      .union([
        z.lazy(() => SymptomDurationUnitSchema),
        z.lazy(
          () =>
            NullableEnumSymptomDurationUnitFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => CaseStatusSchema),
        z.lazy(() => EnumCaseStatusFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    consultingChannel: z
      .union([
        z.lazy(() => ConsultingChannelSchema),
        z.lazy(
          () => EnumConsultingChannelFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    v2RecordId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    followupCase: z
      .lazy(() => CaseUpdateOneWithoutPreviousCaseNestedInputObjectSchema)
      .optional(),
    previousCase: z
      .lazy(() => CaseUpdateOneWithoutFollowupCaseNestedInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(() => CasePictureUpdateManyWithoutCaseNestedInputObjectSchema)
      .optional(),
    appointment: z
      .lazy(() => AppointmentUpdateOneWithoutCaseNestedInputObjectSchema)
      .optional(),
    teleconsult: z
      .lazy(() => TeleconsultUpdateOneWithoutCaseNestedInputObjectSchema)
      .optional(),
    caseSummary: z
      .lazy(() => CaseSummaryUpdateOneWithoutCaseNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const CaseUpdateWithoutMissedCallTeleconsultInputObjectSchema = Schema
