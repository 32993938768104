import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'
import { NestedEnumWarehouseCodeFilterObjectSchema } from './NestedEnumWarehouseCodeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumWarehouseCodeFilter> = z
  .object({
    equals: z.lazy(() => WarehouseCodeSchema).optional(),
    in: z
      .lazy(() => WarehouseCodeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => WarehouseCodeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => WarehouseCodeSchema),
        z.lazy(() => NestedEnumWarehouseCodeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumWarehouseCodeFilterObjectSchema = Schema
