import { z } from 'zod'
import { NotificationUpdateWithoutPatientsInputObjectSchema } from './NotificationUpdateWithoutPatientsInput.schema'
import { NotificationUncheckedUpdateWithoutPatientsInputObjectSchema } from './NotificationUncheckedUpdateWithoutPatientsInput.schema'
import { NotificationCreateWithoutPatientsInputObjectSchema } from './NotificationCreateWithoutPatientsInput.schema'
import { NotificationUncheckedCreateWithoutPatientsInputObjectSchema } from './NotificationUncheckedCreateWithoutPatientsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpsertWithoutPatientsInput> = z
  .object({
    update: z.union([
      z.lazy(() => NotificationUpdateWithoutPatientsInputObjectSchema),
      z.lazy(() => NotificationUncheckedUpdateWithoutPatientsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => NotificationCreateWithoutPatientsInputObjectSchema),
      z.lazy(() => NotificationUncheckedCreateWithoutPatientsInputObjectSchema),
    ]),
  })
  .strict()

export const NotificationUpsertWithoutPatientsInputObjectSchema = Schema
