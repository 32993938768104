import * as z from "zod"
import { CompleteShippingProvider, RelatedShippingProviderModel } from "./index"

export const ExpressShippingAreaModel = z.object({
  id: z.number().int(),
  postCode: z.string(),
  shippingProviderId: z.number().int(),
})

export interface CompleteExpressShippingArea extends z.infer<typeof ExpressShippingAreaModel> {
  shippingProvider: CompleteShippingProvider
}

/**
 * RelatedExpressShippingAreaModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedExpressShippingAreaModel: z.ZodSchema<CompleteExpressShippingArea> = z.lazy(() => ExpressShippingAreaModel.extend({
  shippingProvider: RelatedShippingProviderModel,
}))
