import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumUnitFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => UnitSchema).optional(),
  })
  .strict()

export const EnumUnitFieldUpdateOperationsInputObjectSchema = Schema
