import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureReviewPictureCountOrderByAggregateInputObjectSchema } from './ProcedureReviewPictureCountOrderByAggregateInput.schema'
import { ProcedureReviewPictureAvgOrderByAggregateInputObjectSchema } from './ProcedureReviewPictureAvgOrderByAggregateInput.schema'
import { ProcedureReviewPictureMaxOrderByAggregateInputObjectSchema } from './ProcedureReviewPictureMaxOrderByAggregateInput.schema'
import { ProcedureReviewPictureMinOrderByAggregateInputObjectSchema } from './ProcedureReviewPictureMinOrderByAggregateInput.schema'
import { ProcedureReviewPictureSumOrderByAggregateInputObjectSchema } from './ProcedureReviewPictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureOrderByWithAggregationInput> =
  z
    .object({
      id: z.lazy(() => SortOrderSchema).optional(),
      createdAt: z.lazy(() => SortOrderSchema).optional(),
      updatedAt: z.lazy(() => SortOrderSchema).optional(),
      url: z.lazy(() => SortOrderSchema).optional(),
      procedureReviewId: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(
          () => ProcedureReviewPictureCountOrderByAggregateInputObjectSchema,
        )
        .optional(),
      _avg: z
        .lazy(() => ProcedureReviewPictureAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => ProcedureReviewPictureMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => ProcedureReviewPictureMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => ProcedureReviewPictureSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const ProcedureReviewPictureOrderByWithAggregationInputObjectSchema =
  Schema
