import { z } from 'zod'
import { KycDataCreateWithoutPatientInputObjectSchema } from './KycDataCreateWithoutPatientInput.schema'
import { KycDataUncheckedCreateWithoutPatientInputObjectSchema } from './KycDataUncheckedCreateWithoutPatientInput.schema'
import { KycDataCreateOrConnectWithoutPatientInputObjectSchema } from './KycDataCreateOrConnectWithoutPatientInput.schema'
import { KycDataUpsertWithoutPatientInputObjectSchema } from './KycDataUpsertWithoutPatientInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'
import { KycDataUpdateWithoutPatientInputObjectSchema } from './KycDataUpdateWithoutPatientInput.schema'
import { KycDataUncheckedUpdateWithoutPatientInputObjectSchema } from './KycDataUncheckedUpdateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataUpdateOneWithoutPatientNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => KycDataCreateWithoutPatientInputObjectSchema),
        z.lazy(() => KycDataUncheckedCreateWithoutPatientInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => KycDataCreateOrConnectWithoutPatientInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => KycDataUpsertWithoutPatientInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => KycDataWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => KycDataUpdateWithoutPatientInputObjectSchema),
        z.lazy(() => KycDataUncheckedUpdateWithoutPatientInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const KycDataUpdateOneWithoutPatientNestedInputObjectSchema = Schema
