import { z } from 'zod'
import { CoinTransactionTypeSchema } from '../enums/CoinTransactionType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumCoinTransactionTypeFilterObjectSchema } from './NestedEnumCoinTransactionTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NestedEnumCoinTransactionTypeWithAggregatesFilter> =
  z
    .object({
      equals: z.lazy(() => CoinTransactionTypeSchema).optional(),
      in: z
        .lazy(() => CoinTransactionTypeSchema)
        .array()
        .optional(),
      notIn: z
        .lazy(() => CoinTransactionTypeSchema)
        .array()
        .optional(),
      not: z
        .union([
          z.lazy(() => CoinTransactionTypeSchema),
          z.lazy(
            () => NestedEnumCoinTransactionTypeWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional(),
      _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumCoinTransactionTypeFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumCoinTransactionTypeFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumCoinTransactionTypeWithAggregatesFilterObjectSchema =
  Schema
