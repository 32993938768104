import { weekday } from './create-edit-section/branchOfficeHour'

const getTimeFromDate = (date: Date | string) => {
  if (date && date instanceof Date) {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedTime = `${formattedHours}:${formattedMinutes}`
    return formattedTime
  } else {
    return date
  }
}

export const transformPartnerBranch = async (form: {
  [key: string]: unknown
}) => {
  const {
    nameTr,
    partnerId,
    active,
    isExpertise,
    address,
    province,
    provinceKey,
    district,
    districtKey,
    subDistrict,
    subDistrictKey,
    postcode,
    googleMapUrl,
    lat,
    long,
    phone,
  } = form
  const officeHours = []
  const timezone = Intl.DateTimeFormat(undefined, {
    timeZoneName: 'long',
  }).resolvedOptions().timeZone
  for (const day of Object.keys(weekday)) {
    if (form[day]) {
      const openAt = form[`${day}_openAt`] as Date | string
      const closeAt = form[`${day}_closeAt`] as Date | string
      officeHours.push({
        weekday: day,
        timezone,
        openAt: getTimeFromDate(openAt),
        closeAt: getTimeFromDate(closeAt),
      })
    }
  }
  const result = {
    nameTr,
    partnerId,
    active,
    isExpertise,
    address,
    province,
    provinceKey,
    district,
    districtKey,
    subDistrict,
    subDistrictKey,
    postcode,
    googleMapUrl,
    lat,
    long,
    phone,
    officeHours,
  }
  return result
}
