import { z } from 'zod'
import { ProcedurePictureFindManySchema } from '../findManyProcedurePicture.schema'
import { ProcedureCategoryFindManySchema } from '../findManyProcedureCategory.schema'
import { FlashsaleItemFindManySchema } from '../findManyFlashsaleItem.schema'
import { CarouselItemFindManySchema } from '../findManyCarouselItem.schema'
import { ProcedureReviewFindManySchema } from '../findManyProcedureReview.schema'
import { ProcedureReviewSummaryArgsObjectSchema } from './ProcedureReviewSummaryArgs.schema'
import { ProcedureCountOutputTypeArgsObjectSchema } from './ProcedureCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureInclude> = z
  .object({
    pictures: z
      .union([z.boolean(), z.lazy(() => ProcedurePictureFindManySchema)])
      .optional(),
    categories: z
      .union([z.boolean(), z.lazy(() => ProcedureCategoryFindManySchema)])
      .optional(),
    flashsaleItem: z
      .union([z.boolean(), z.lazy(() => FlashsaleItemFindManySchema)])
      .optional(),
    carouselItems: z
      .union([z.boolean(), z.lazy(() => CarouselItemFindManySchema)])
      .optional(),
    procedureReviews: z
      .union([z.boolean(), z.lazy(() => ProcedureReviewFindManySchema)])
      .optional(),
    procedureReviewSummary: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureReviewSummaryArgsObjectSchema),
      ])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProcedureCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureIncludeObjectSchema = Schema
