import { z } from 'zod'
import { AcceptedConsentScalarWhereInputObjectSchema } from './AcceptedConsentScalarWhereInput.schema'
import { AcceptedConsentUpdateManyMutationInputObjectSchema } from './AcceptedConsentUpdateManyMutationInput.schema'
import { AcceptedConsentUncheckedUpdateManyWithoutAcceptedConsentsInputObjectSchema } from './AcceptedConsentUncheckedUpdateManyWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateManyWithWhereWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => AcceptedConsentUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            AcceptedConsentUncheckedUpdateManyWithoutAcceptedConsentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentUpdateManyWithWhereWithoutPatientInputObjectSchema =
  Schema
