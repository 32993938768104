import { z } from 'zod'
import { FlashsaleItemCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateWithoutProcedureInput.schema'
import { FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutProcedureInput.schema'
import { FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutProcedureInput.schema'
import { FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema } from './FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInput.schema'
import { FlashsaleItemCreateManyProcedureInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyProcedureInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema } from './FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInput.schema'
import { FlashsaleItemUpdateManyWithWhereWithoutProcedureInputObjectSchema } from './FlashsaleItemUpdateManyWithWhereWithoutProcedureInput.schema'
import { FlashsaleItemScalarWhereInputObjectSchema } from './FlashsaleItemScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemUpdateManyWithoutProcedureNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpsertWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateWithWhereUniqueWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              FlashsaleItemUpdateManyWithWhereWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUpdateManyWithWhereWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
          z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemUpdateManyWithoutProcedureNestedInputObjectSchema =
  Schema
