import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderAvgOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    editedProductItemsBy: z.lazy(() => SortOrderSchema).optional(),
    shippingFee: z.lazy(() => SortOrderSchema).optional(),
    shippingFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    shippingProviderId: z.lazy(() => SortOrderSchema).optional(),
    approvedBy: z.lazy(() => SortOrderSchema).optional(),
    canceledBy: z.lazy(() => SortOrderSchema).optional(),
    useCoin: z.lazy(() => SortOrderSchema).optional(),
    v2InvoiceId: z.lazy(() => SortOrderSchema).optional(),
    holdingBy: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const OrderAvgOrderByAggregateInputObjectSchema = Schema
