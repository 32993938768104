import * as z from "zod"
import { CompleteCase, RelatedCaseModel } from "./index"

export const CasePictureModel = z.object({
  id: z.number().int(),
  pictureUrl: z.string(),
  caseId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteCasePicture extends z.infer<typeof CasePictureModel> {
  case: CompleteCase
}

/**
 * RelatedCasePictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCasePictureModel: z.ZodSchema<CompleteCasePicture> = z.lazy(() => CasePictureModel.extend({
  case: RelatedCaseModel,
}))
