import { z } from 'zod'
import { ProcedureCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureCreateWithoutProcedureReviewsInput.schema'
import { ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUncheckedCreateWithoutProcedureReviewsInput.schema'
import { ProcedureCreateOrConnectWithoutProcedureReviewsInputObjectSchema } from './ProcedureCreateOrConnectWithoutProcedureReviewsInput.schema'
import { ProcedureUpsertWithoutProcedureReviewsInputObjectSchema } from './ProcedureUpsertWithoutProcedureReviewsInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureUpdateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUpdateWithoutProcedureReviewsInput.schema'
import { ProcedureUncheckedUpdateWithoutProcedureReviewsInputObjectSchema } from './ProcedureUncheckedUpdateWithoutProcedureReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpdateOneRequiredWithoutProcedureReviewsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutProcedureReviewsInputObjectSchema),
          z.lazy(
            () =>
              ProcedureUncheckedCreateWithoutProcedureReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            ProcedureCreateOrConnectWithoutProcedureReviewsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProcedureUpsertWithoutProcedureReviewsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProcedureUpdateWithoutProcedureReviewsInputObjectSchema),
          z.lazy(
            () =>
              ProcedureUncheckedUpdateWithoutProcedureReviewsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureUpdateOneRequiredWithoutProcedureReviewsNestedInputObjectSchema =
  Schema
