import { z } from 'zod'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumConsultingChannelFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => ConsultingChannelSchema).optional(),
    })
    .strict()

export const EnumConsultingChannelFieldUpdateOperationsInputObjectSchema =
  Schema
