import { z } from 'zod'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'
import { CarouselItemUpdateWithoutCarouselInputObjectSchema } from './CarouselItemUpdateWithoutCarouselInput.schema'
import { CarouselItemUncheckedUpdateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedUpdateWithoutCarouselInput.schema'
import { CarouselItemCreateWithoutCarouselInputObjectSchema } from './CarouselItemCreateWithoutCarouselInput.schema'
import { CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedCreateWithoutCarouselInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUpsertWithWhereUniqueWithoutCarouselInput> =
  z
    .object({
      where: z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => CarouselItemUpdateWithoutCarouselInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedUpdateWithoutCarouselInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema),
        z.lazy(
          () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CarouselItemUpsertWithWhereUniqueWithoutCarouselInputObjectSchema =
  Schema
