import { z } from 'zod'

export const ProcedureCreateEventModel = z.object({
  procedureIds: z.array(z.number()),
})
export type ProcedureCreateEventModel = z.infer<
  typeof ProcedureCreateEventModel
>

export const ProcedureUpdateEventModel = z.object({
  procedureIds: z.array(z.number()),
})
export type ProcedureUpdateEventModel = z.infer<
  typeof ProcedureUpdateEventModel
>

export const ProcedureReviewCreateEventModel = z.object({
  procedureReviewId: z.number().int(),
  orderProcedureItemId: z.number().int(),
  patientId: z.number().int(),
  receivedCoin: z.number(),
})
export type ProcedureReviewCreateEventModel = z.infer<
  typeof ProcedureReviewCreateEventModel
>
