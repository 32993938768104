import z from 'zod'
import { VariantModel } from '../generated/zod'

export const VariantSchema = VariantModel.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
}).extend({
  active: z.boolean().default(false),
  rank: z.number().default(0),
})
