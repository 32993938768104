import { z } from 'zod'
import { SkuOrderByWithRelationInputObjectSchema } from './objects/SkuOrderByWithRelationInput.schema'
import { SkuWhereInputObjectSchema } from './objects/SkuWhereInput.schema'
import { SkuWhereUniqueInputObjectSchema } from './objects/SkuWhereUniqueInput.schema'
import { SkuScalarFieldEnumSchema } from './enums/SkuScalarFieldEnum.schema'

export const SkuFindFirstSchema = z.object({
  orderBy: z
    .union([
      SkuOrderByWithRelationInputObjectSchema,
      SkuOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SkuWhereInputObjectSchema.optional(),
  cursor: SkuWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(SkuScalarFieldEnumSchema).optional(),
})
