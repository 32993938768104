import { z } from 'zod'
import { SuggestedProductItemOriginalCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalCreateOrConnectWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalCreateManyCaseSummaryInputEnvelopeObjectSchema } from './SuggestedProductItemOriginalCreateManyCaseSummaryInputEnvelope.schema'
import { SuggestedProductItemOriginalWhereUniqueInputObjectSchema } from './SuggestedProductItemOriginalWhereUniqueInput.schema'
import { SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema } from './SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInput.schema'
import { SuggestedProductItemOriginalScalarWhereInputObjectSchema } from './SuggestedProductItemOriginalScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalUncheckedUpdateManyWithoutCaseSummaryNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            SuggestedProductItemOriginalCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(
            () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
          ),
          z
            .lazy(
              () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(
            () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
          ),
          z
            .lazy(
              () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(
            () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
          ),
          z
            .lazy(
              () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(
            () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
          ),
          z
            .lazy(
              () => SuggestedProductItemOriginalWhereUniqueInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalUpdateWithWhereUniqueWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                SuggestedProductItemOriginalUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(
            () => SuggestedProductItemOriginalScalarWhereInputObjectSchema,
          ),
          z
            .lazy(
              () => SuggestedProductItemOriginalScalarWhereInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
    })
    .strict()

export const SuggestedProductItemOriginalUncheckedUpdateManyWithoutCaseSummaryNestedInputObjectSchema =
  Schema
