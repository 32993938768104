import { z } from 'zod'
import { ProductCreateWithoutPicturesInputObjectSchema } from './ProductCreateWithoutPicturesInput.schema'
import { ProductUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductUncheckedCreateWithoutPicturesInput.schema'
import { ProductCreateOrConnectWithoutPicturesInputObjectSchema } from './ProductCreateOrConnectWithoutPicturesInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutPicturesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProductCreateWithoutPicturesInputObjectSchema),
        z.lazy(() => ProductUncheckedCreateWithoutPicturesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ProductCreateOrConnectWithoutPicturesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ProductCreateNestedOneWithoutPicturesInputObjectSchema = Schema
