import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    videoRoomId: z.literal(true).optional(),
    doctorVideoRoomUid: z.literal(true).optional(),
    patientVideoRoomUid: z.literal(true).optional(),
    startAt: z.literal(true).optional(),
    endAt: z.literal(true).optional(),
    callTime: z.literal(true).optional(),
    status: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    missedCallCaseId: z.literal(true).optional(),
    remark: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
  })
  .strict()

export const TeleconsultMaxAggregateInputObjectSchema = Schema
