import { z } from 'zod'
import { FlashsaleItemCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateOrConnectWithoutFlashsaleInput.schema'
import { FlashsaleItemCreateManyFlashsaleInputEnvelopeObjectSchema } from './FlashsaleItemCreateManyFlashsaleInputEnvelope.schema'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemUncheckedCreateNestedManyWithoutFlashsaleInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema),
          z
            .lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema)
            .array(),
          z.lazy(
            () => FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => FlashsaleItemCreateManyFlashsaleInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
          z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const FlashsaleItemUncheckedCreateNestedManyWithoutFlashsaleInputObjectSchema =
  Schema
