import { z } from 'zod'
import { VariantCreateNestedOneWithoutVariantWarehousesInputObjectSchema } from './VariantCreateNestedOneWithoutVariantWarehousesInput.schema'
import { WarehouseCreateNestedOneWithoutVariantWarehousesInputObjectSchema } from './WarehouseCreateNestedOneWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateInput> = z
  .object({
    variant: z.lazy(
      () => VariantCreateNestedOneWithoutVariantWarehousesInputObjectSchema,
    ),
    warehouse: z.lazy(
      () => WarehouseCreateNestedOneWithoutVariantWarehousesInputObjectSchema,
    ),
  })
  .strict()

export const VariantWarehouseCreateInputObjectSchema = Schema
