import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'
import { ProcedurePictureCreateNestedManyWithoutProcedureInputObjectSchema } from './ProcedurePictureCreateNestedManyWithoutProcedureInput.schema'
import { ProcedureCategoryCreateNestedManyWithoutProcedureInputObjectSchema } from './ProcedureCategoryCreateNestedManyWithoutProcedureInput.schema'
import { FlashsaleItemCreateNestedManyWithoutProcedureInputObjectSchema } from './FlashsaleItemCreateNestedManyWithoutProcedureInput.schema'
import { CarouselItemCreateNestedManyWithoutProcedureInputObjectSchema } from './CarouselItemCreateNestedManyWithoutProcedureInput.schema'
import { ProcedureReviewCreateNestedManyWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateNestedManyWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProcedureCreateWithoutProcedureReviewSummaryInput> =
  z
    .object({
      active: z.boolean().optional(),
      searchable: z.boolean().optional(),
      nameTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      searchField: z.string(),
      technologyTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      usageTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      resultPeriodTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      preparationTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      conditionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      methodOfUseTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      isTreatedByDoctor: z.boolean().optional(),
      priority: z.number().optional(),
      expireType: z.lazy(() => ExpireTypeSchema),
      expireAt: z.date().optional().nullable(),
      expireDurationDay: z.number().optional().nullable(),
      tagPrice: z.number().optional().nullable(),
      sellingPrice: z.number(),
      cost: z.number(),
      commissionRate: z.number().optional(),
      partnerId: z.number(),
      branches: z.union([z.lazy(() => JsonNullValueInputSchema), jsonSchema]),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      deletedAt: z.date().optional().nullable(),
      v2DrugItemId: z.number().optional().nullable(),
      pictures: z
        .lazy(
          () =>
            ProcedurePictureCreateNestedManyWithoutProcedureInputObjectSchema,
        )
        .optional(),
      categories: z
        .lazy(
          () =>
            ProcedureCategoryCreateNestedManyWithoutProcedureInputObjectSchema,
        )
        .optional(),
      flashsaleItem: z
        .lazy(
          () => FlashsaleItemCreateNestedManyWithoutProcedureInputObjectSchema,
        )
        .optional(),
      carouselItems: z
        .lazy(
          () => CarouselItemCreateNestedManyWithoutProcedureInputObjectSchema,
        )
        .optional(),
      procedureReviews: z
        .lazy(
          () =>
            ProcedureReviewCreateNestedManyWithoutProcedureInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const ProcedureCreateWithoutProcedureReviewSummaryInputObjectSchema =
  Schema
