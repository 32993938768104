import { z } from 'zod'
import { IdentityWhereInputObjectSchema } from './objects/IdentityWhereInput.schema'
import { IdentityOrderByWithAggregationInputObjectSchema } from './objects/IdentityOrderByWithAggregationInput.schema'
import { IdentityScalarWhereWithAggregatesInputObjectSchema } from './objects/IdentityScalarWhereWithAggregatesInput.schema'
import { IdentityScalarFieldEnumSchema } from './enums/IdentityScalarFieldEnum.schema'

export const IdentityGroupBySchema = z.object({
  where: IdentityWhereInputObjectSchema.optional(),
  orderBy: z.union([
    IdentityOrderByWithAggregationInputObjectSchema,
    IdentityOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: IdentityScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(IdentityScalarFieldEnumSchema),
})
