import { z } from 'zod'
import { ProductReviewPictureCreateManyProductReviewInputObjectSchema } from './ProductReviewPictureCreateManyProductReviewInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureCreateManyProductReviewInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () => ProductReviewPictureCreateManyProductReviewInputObjectSchema,
        ),
        z
          .lazy(
            () => ProductReviewPictureCreateManyProductReviewInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const ProductReviewPictureCreateManyProductReviewInputEnvelopeObjectSchema =
  Schema
