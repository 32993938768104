import { z } from 'zod'
import { CouponGroupUpdateWithoutDiscountSettingInputObjectSchema } from './CouponGroupUpdateWithoutDiscountSettingInput.schema'
import { CouponGroupUncheckedUpdateWithoutDiscountSettingInputObjectSchema } from './CouponGroupUncheckedUpdateWithoutDiscountSettingInput.schema'
import { CouponGroupCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupCreateWithoutDiscountSettingInput.schema'
import { CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema } from './CouponGroupUncheckedCreateWithoutDiscountSettingInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupUpsertWithoutDiscountSettingInput> = z
  .object({
    update: z.union([
      z.lazy(() => CouponGroupUpdateWithoutDiscountSettingInputObjectSchema),
      z.lazy(
        () => CouponGroupUncheckedUpdateWithoutDiscountSettingInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => CouponGroupCreateWithoutDiscountSettingInputObjectSchema),
      z.lazy(
        () => CouponGroupUncheckedCreateWithoutDiscountSettingInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const CouponGroupUpsertWithoutDiscountSettingInputObjectSchema = Schema
