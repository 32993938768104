import { FileValue } from '../../components/types'

export const transformPatientKycData = async (form: {
  idCardPicture: FileValue
  selfieIdCardPicture: FileValue
  passportPicture: FileValue
  selfiePassportPicture: FileValue
  validator: { [key: string]: string }
  errorReason: { [key: string]: string }
}) => {
  const {
    idCardPicture,
    selfieIdCardPicture,
    passportPicture,
    selfiePassportPicture,
    validator,
    errorReason,
  } = form
  const result: { [key: string]: unknown } = form

  if (idCardPicture?.uploadPromise) {
    const url = await idCardPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.idCardPicture = {
        ...idCardPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (selfieIdCardPicture?.uploadPromise) {
    const url = await selfieIdCardPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.selfieIdCardPicture = {
        ...selfieIdCardPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (passportPicture?.uploadPromise) {
    const url = await passportPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.passportPicture = {
        ...passportPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (selfiePassportPicture?.uploadPromise) {
    const url = await selfiePassportPicture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.selfiePassportPicture = {
        ...selfiePassportPicture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }

  const rejectReason = [] as { reason: string; field: string }[]
  Object.keys(validator).forEach((key) => {
    if (validator[key] === '0') {
      rejectReason.push({
        field: key,
        reason: errorReason[key] ?? '',
      })
    }
  })
  result.rejectReason = rejectReason

  // remove custom fields for validating reject condition
  delete result.validator
  delete result.errorReason

  return result
}
