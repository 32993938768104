import { z } from 'zod'
import { ReceiptSelectObjectSchema } from './ReceiptSelect.schema'
import { ReceiptIncludeObjectSchema } from './ReceiptInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptArgs> = z
  .object({
    select: z.lazy(() => ReceiptSelectObjectSchema).optional(),
    include: z.lazy(() => ReceiptIncludeObjectSchema).optional(),
  })
  .strict()

export const ReceiptArgsObjectSchema = Schema
