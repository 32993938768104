import { z } from 'zod'
import { DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateWithoutCaseSummaryInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      diagnosis: z.lazy(
        () =>
          DiagnosisCreateNestedOneWithoutCaseSummaryDiagnosisInputObjectSchema,
      ),
    })
    .strict()

export const CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema =
  Schema
