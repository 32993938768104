import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { MyReviewCountOrderByAggregateInputObjectSchema } from './MyReviewCountOrderByAggregateInput.schema'
import { MyReviewAvgOrderByAggregateInputObjectSchema } from './MyReviewAvgOrderByAggregateInput.schema'
import { MyReviewMaxOrderByAggregateInputObjectSchema } from './MyReviewMaxOrderByAggregateInput.schema'
import { MyReviewMinOrderByAggregateInputObjectSchema } from './MyReviewMinOrderByAggregateInput.schema'
import { MyReviewSumOrderByAggregateInputObjectSchema } from './MyReviewSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    subtitleTr: z.lazy(() => SortOrderSchema).optional(),
    orderItemId: z.lazy(() => SortOrderSchema).optional(),
    orderItemType: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    quantity: z.lazy(() => SortOrderSchema).optional(),
    drugType: z.lazy(() => SortOrderSchema).optional(),
    reviewId: z.lazy(() => SortOrderSchema).optional(),
    reviewStartAt: z.lazy(() => SortOrderSchema).optional(),
    reviewExpireAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => MyReviewCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => MyReviewAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => MyReviewMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => MyReviewMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => MyReviewSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const MyReviewOrderByWithAggregationInputObjectSchema = Schema
