import { z } from 'zod'
import { ProductItemOriginalCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateWithoutOrderInput.schema'
import { ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema } from './ProductItemOriginalUncheckedCreateWithoutOrderInput.schema'
import { ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema } from './ProductItemOriginalCreateOrConnectWithoutOrderInput.schema'
import { ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInput.schema'
import { ProductItemOriginalCreateManyOrderInputEnvelopeObjectSchema } from './ProductItemOriginalCreateManyOrderInputEnvelope.schema'
import { ProductItemOriginalWhereUniqueInputObjectSchema } from './ProductItemOriginalWhereUniqueInput.schema'
import { ProductItemOriginalUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './ProductItemOriginalUpdateWithWhereUniqueWithoutOrderInput.schema'
import { ProductItemOriginalUpdateManyWithWhereWithoutOrderInputObjectSchema } from './ProductItemOriginalUpdateManyWithWhereWithoutOrderInput.schema'
import { ProductItemOriginalScalarWhereInputObjectSchema } from './ProductItemOriginalScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalUncheckedUpdateManyWithoutOrderNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => ProductItemOriginalCreateWithoutOrderInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalUncheckedCreateWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalCreateOrConnectWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductItemOriginalCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema),
          z.lazy(() => ProductItemOriginalWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductItemOriginalUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductItemOriginalUpdateManyWithWhereWithoutOrderInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductItemOriginalUpdateManyWithWhereWithoutOrderInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductItemOriginalScalarWhereInputObjectSchema),
          z.lazy(() => ProductItemOriginalScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductItemOriginalUncheckedUpdateManyWithoutOrderNestedInputObjectSchema =
  Schema
