import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CoverPictureCountOrderByAggregateInputObjectSchema } from './CoverPictureCountOrderByAggregateInput.schema'
import { CoverPictureAvgOrderByAggregateInputObjectSchema } from './CoverPictureAvgOrderByAggregateInput.schema'
import { CoverPictureMaxOrderByAggregateInputObjectSchema } from './CoverPictureMaxOrderByAggregateInput.schema'
import { CoverPictureMinOrderByAggregateInputObjectSchema } from './CoverPictureMinOrderByAggregateInput.schema'
import { CoverPictureSumOrderByAggregateInputObjectSchema } from './CoverPictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CoverPictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CoverPictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CoverPictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CoverPictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CoverPictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CoverPictureOrderByWithAggregationInputObjectSchema = Schema
