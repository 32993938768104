import { z } from 'zod'
import { BrandCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateWithoutProductReviewSummariesInput.schema'
import { BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewSummariesInput.schema'
import { BrandCreateOrConnectWithoutProductReviewSummariesInputObjectSchema } from './BrandCreateOrConnectWithoutProductReviewSummariesInput.schema'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateNestedOneWithoutProductReviewSummariesInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => BrandCreateWithoutProductReviewSummariesInputObjectSchema,
          ),
          z.lazy(
            () =>
              BrandUncheckedCreateWithoutProductReviewSummariesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            BrandCreateOrConnectWithoutProductReviewSummariesInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => BrandWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const BrandCreateNestedOneWithoutProductReviewSummariesInputObjectSchema =
  Schema
