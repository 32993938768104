import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUncheckedCreateWithoutProductInput> =
  z
    .object({
      id: z.number().optional(),
      categoryId: z.number(),
      priority: z.number().optional(),
    })
    .strict()

export const ProductCategoryUncheckedCreateWithoutProductInputObjectSchema =
  Schema
