import { z } from 'zod'
import { DiagnosisUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisCreateWithoutCaseSummaryDiagnosisInput.schema'
import { DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema } from './DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisUpsertWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => DiagnosisUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            DiagnosisUncheckedUpdateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
        z.lazy(
          () =>
            DiagnosisUncheckedCreateWithoutCaseSummaryDiagnosisInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DiagnosisUpsertWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
