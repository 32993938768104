import { z } from 'zod'
import { CaseSummaryUpdateInputObjectSchema } from './objects/CaseSummaryUpdateInput.schema'
import { CaseSummaryUncheckedUpdateInputObjectSchema } from './objects/CaseSummaryUncheckedUpdateInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './objects/CaseSummaryWhereUniqueInput.schema'

export const CaseSummaryUpdateOneSchema = z.object({
  data: z.union([
    CaseSummaryUpdateInputObjectSchema,
    CaseSummaryUncheckedUpdateInputObjectSchema,
  ]),
  where: CaseSummaryWhereUniqueInputObjectSchema,
})
