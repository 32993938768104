import { z } from 'zod'
import { TeleconsultOrderByWithRelationInputObjectSchema } from './objects/TeleconsultOrderByWithRelationInput.schema'
import { TeleconsultWhereInputObjectSchema } from './objects/TeleconsultWhereInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './objects/TeleconsultWhereUniqueInput.schema'
import { TeleconsultScalarFieldEnumSchema } from './enums/TeleconsultScalarFieldEnum.schema'

export const TeleconsultFindFirstSchema = z.object({
  orderBy: z
    .union([
      TeleconsultOrderByWithRelationInputObjectSchema,
      TeleconsultOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: TeleconsultWhereInputObjectSchema.optional(),
  cursor: TeleconsultWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(TeleconsultScalarFieldEnumSchema).optional(),
})
