import { z } from 'zod'

export const listModel = (omiseModel: z.ZodTypeAny) =>
  z.object({
    object: z.string(),
    data: omiseModel.array(),
    limit: z.number().int(),
    offset: z.number().int(),
    total: z.number().int(),
    location: z.string().nullish(),
    order: z.string(),
    from: z.string().datetime(),
    to: z.string().datetime(),
  })

export const OmiseWebhookDeliveryModel = z.object({
  object: z.string(),
  id: z.string(),
  uri: z.string().nullish(),
  status: z.number().nullish(),
  key: z.string().nullish(),
  error: z.string().nullish(),
  payload: z.string().nullish(),
  created_at: z.date().nullish(),
})

export const OmiseEventModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  created_at: z.date().nullish(),
  data: z.any().nullish(),
  key: z.string(),
  webhook_deliveries: OmiseWebhookDeliveryModel.array().nullish(),
})

export const OmiseCardModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  bank: z.string().nullish(),
  brand: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  created_at: z.string().datetime().nullish(),
  deleted: z.boolean().nullish(),
  expiration_month: z.number().int().nullish(),
  expiration_year: z.number().int().nullish(),
  financing: z
    .union([z.enum(['credit', 'debit', 'prepaid']), z.string()])
    .nullish(),
  fingerprint: z.string().nullish(),
  first_digits: z.string().nullish(),
  last_digits: z.string().nullish(),
  name: z.string().nullish(),
  phone_number: z.string().nullish(),
  postal_code: z.string().nullish(),
  state: z.string().nullish(),
  street1: z.string().nullish(),
  street2: z.string().nullish(),
  tokenization_method: z.string().nullish(),
})

export const OmiseDisputeModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  admin_message: z.string().nullish(),
  amount: z.number().int().nullish(),
  charge: z.union([z.string(), z.any()]).nullish(), // recuresive charge model (zod not support)
  closed_at: z.string().datetime().nullish(),
  created_at: z.string().datetime().nullish(),
  currency: z.string().nullish(),
  documents: listModel(z.any()).nullish(), // document model
  funding_amount: z.number().int().nullish(),
  funding_currency: z.string().nullish(),
  message: z.string().nullish(),
  metadata: z.any().nullish(),
  reason_code: z.string().nullish(),
  reason_message: z.string().nullish(),
  status: z
    .union([z.enum(['open', 'pending', 'won', 'lost']), z.string()])
    .nullish(),
  transactions: z.object({}).array().nullish(), // transaction model
})

export const OmisePlatformFeeModel = z.object({
  fixed: z.number().nullish(),
  amount: z.number().nullish(),
  percentage: z.number().nullish(),
})

export const OmiseTransactionFeeModel = z.object({
  fee_flat: z.string().nullish(),
  fee_rate: z.string().nullish(),
  vat_rate: z.string().nullish(),
})

export const OmiseSourceModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  absorption_type: z.string().nullish(),
  amount: z.number().int().nullish(),
  bank: z.string().nullish(),
  barcode: z.string().nullish(),
  billing: z.any().nullish(),
  charge_status: z.string().nullish(),
  created_at: z.string().datetime().nullish(),
  currency: z.string().nullish(),
  discounts: z.any().array().nullish(),
  email: z.string().nullish(),
  flow: z.string().nullish(),
  installment_term: z.number().int().nullish(),
  ip: z.string().nullish(),
  items: z.any().array().nullish(),
  mobile_number: z.string().nullish(),
  name: z.string().nullish(),
  phone_number: z.string().nullish(),
  platform_type: z.string().nullish(),
  provider_references: z.any().nullish(),
  receipt_amount: z.number().int().nullish(),
  references: z.any().nullish(),
  scannable_code: z.any().nullish(),
  shipping: z.any().nullish(),
  store_id: z.string().nullish(),
  store_name: z.string().nullish(),
  terminal_id: z.string().nullish(),
  type: z.string().nullish(),
  zero_interest_installments: z.boolean().nullish(),
})

export const OmiseRefundModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  amount: z.number().int().nullish(),
  charge: z.string().nullish(),
  created_at: z.string().datetime().nullish(),
  currency: z.string().nullish(),
  funding_amount: z.number().int().nullish(),
  funding_currency: z.string().nullish(),
  metadata: z.any().nullish(),
  transaction: z.string().nullish(),
  voided: z.boolean().nullish(),
})

export const OmiseCustomerModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  cards: listModel(OmiseCardModel).nullish(),
  created_at: z.string().datetime().nullish(),
  default_card: z.string().nullish(),
  deleted: z.boolean().nullish(),
  description: z.string().nullish(),
  email: z.string().nullish(),
  metadata: z.any().nullish(),
})

export const OmiseChargeModel = z.object({
  object: z.string(),
  id: z.string(),
  livemode: z.boolean().nullish(),
  location: z.string().nullish(),
  amount: z.number().int().nullish(),
  authorization_type: z.string().nullish(),
  authorize_uri: z.string().nullish(),
  authorized: z.boolean().nullish(),
  authorized_amount: z.number().int().nullish(),
  branch: z.string().nullish(),
  capturable: z.boolean().nullish(),
  capture: z.boolean().nullish(),
  captured_amount: z.number().int().nullish(),
  card: OmiseCardModel.nullish(),
  created_at: z.string().datetime().nullish(),
  currency: z.string().nullish(),
  customer: z.union([z.string(), OmiseCustomerModel]).nullish(),
  description: z.string().nullish(),
  device: z.string().nullish(),
  disputable: z.boolean().nullish(),
  dispute: z.union([z.string(), OmiseDisputeModel]).nullish(),
  expired: z.boolean().nullish(),
  expired_at: z.string().datetime().nullish(),
  expires_at: z.string().datetime().nullish(),
  failure_code: z.string().nullish(),
  failure_message: z.string().nullish(),
  fee: z.number().int().nullish(),
  fee_vat: z.number().int().nullish(),
  funding_amount: z.number().int().nullish(),
  funding_currency: z.string().nullish(),
  interest: z.number().int().nullish(),
  interest_vat: z.number().int().nullish(),
  ip: z.string().nullish(),
  link: z.union([z.string(), z.any()]).nullish(),
  metadata: z.any().nullish(),
  net: z.number().int().nullish(),
  paid: z.boolean().nullish(),
  paid_at: z.string().datetime().nullish(),
  platform_fee: OmisePlatformFeeModel.nullish(),
  refundable: z.boolean().nullish(),
  refunded_amount: z.number().int().nullish(),
  refunds: listModel(OmiseRefundModel).nullish(),
  return_uri: z.string().nullish(),
  reversed: z.boolean().nullish(),
  reversed_at: z.string().datetime().nullish(),
  reversible: z.boolean().nullish(),
  schedule: z.union([z.string(), z.any()]).nullish(),
  source: OmiseSourceModel.nullish(),
  status: z
    .union([
      z.enum(['failed', 'expired', 'pending', 'reversed', 'successful']),
      z.string(),
    ])
    .nullish(),
  terminal: z.string().nullish(),
  transaction: z.union([z.string(), z.any()]).nullish(),
  transaction_fees: OmiseTransactionFeeModel.nullish(),
  unmanaged_payment: z.any().nullish(),
  voided: z.boolean().nullish(),
  zero_interest_installments: z.boolean().nullish(),
})
