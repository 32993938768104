import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourSumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    branchId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const OfficeHourSumOrderByAggregateInputObjectSchema = Schema
