import { z } from 'zod'
import { CarouselItemOrderByWithRelationInputObjectSchema } from './objects/CarouselItemOrderByWithRelationInput.schema'
import { CarouselItemWhereInputObjectSchema } from './objects/CarouselItemWhereInput.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './objects/CarouselItemWhereUniqueInput.schema'
import { CarouselItemCountAggregateInputObjectSchema } from './objects/CarouselItemCountAggregateInput.schema'
import { CarouselItemMinAggregateInputObjectSchema } from './objects/CarouselItemMinAggregateInput.schema'
import { CarouselItemMaxAggregateInputObjectSchema } from './objects/CarouselItemMaxAggregateInput.schema'
import { CarouselItemAvgAggregateInputObjectSchema } from './objects/CarouselItemAvgAggregateInput.schema'
import { CarouselItemSumAggregateInputObjectSchema } from './objects/CarouselItemSumAggregateInput.schema'

export const CarouselItemAggregateSchema = z.object({
  orderBy: z
    .union([
      CarouselItemOrderByWithRelationInputObjectSchema,
      CarouselItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CarouselItemWhereInputObjectSchema.optional(),
  cursor: CarouselItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CarouselItemCountAggregateInputObjectSchema])
    .optional(),
  _min: CarouselItemMinAggregateInputObjectSchema.optional(),
  _max: CarouselItemMaxAggregateInputObjectSchema.optional(),
  _avg: CarouselItemAvgAggregateInputObjectSchema.optional(),
  _sum: CarouselItemSumAggregateInputObjectSchema.optional(),
})
