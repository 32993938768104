import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ShippingProviderOrderByWithRelationInputObjectSchema } from './ShippingProviderOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    postCode: z.lazy(() => SortOrderSchema).optional(),
    shippingProviderId: z.lazy(() => SortOrderSchema).optional(),
    shippingProvider: z
      .lazy(() => ShippingProviderOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ExpressShippingAreaOrderByWithRelationInputObjectSchema = Schema
