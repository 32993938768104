import { z } from 'zod'
import { BrandBannerUncheckedCreateNestedManyWithoutBrandInputObjectSchema } from './BrandBannerUncheckedCreateNestedManyWithoutBrandInput.schema'
import { ProductUncheckedCreateNestedManyWithoutBrandInputObjectSchema } from './ProductUncheckedCreateNestedManyWithoutBrandInput.schema'
import { ProductReviewSummaryUncheckedCreateNestedManyWithoutBrandInputObjectSchema } from './ProductReviewSummaryUncheckedCreateNestedManyWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUncheckedCreateWithoutProductReviewsInput> =
  z
    .object({
      id: z.number().optional(),
      name: z.string(),
      isShow: z.boolean().optional(),
      coporateName: z.string(),
      countryName: z.string(),
      logo: z.string().optional().nullable(),
      priority: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      banners: z
        .lazy(
          () =>
            BrandBannerUncheckedCreateNestedManyWithoutBrandInputObjectSchema,
        )
        .optional(),
      products: z
        .lazy(
          () => ProductUncheckedCreateNestedManyWithoutBrandInputObjectSchema,
        )
        .optional(),
      productReviewSummaries: z
        .lazy(
          () =>
            ProductReviewSummaryUncheckedCreateNestedManyWithoutBrandInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const BrandUncheckedCreateWithoutProductReviewsInputObjectSchema = Schema
