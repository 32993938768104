import { z } from 'zod'
import { MyReviewWhereUniqueInputObjectSchema } from './objects/MyReviewWhereUniqueInput.schema'
import { MyReviewCreateInputObjectSchema } from './objects/MyReviewCreateInput.schema'
import { MyReviewUncheckedCreateInputObjectSchema } from './objects/MyReviewUncheckedCreateInput.schema'
import { MyReviewUpdateInputObjectSchema } from './objects/MyReviewUpdateInput.schema'
import { MyReviewUncheckedUpdateInputObjectSchema } from './objects/MyReviewUncheckedUpdateInput.schema'

export const MyReviewUpsertSchema = z.object({
  where: MyReviewWhereUniqueInputObjectSchema,
  create: z.union([
    MyReviewCreateInputObjectSchema,
    MyReviewUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    MyReviewUpdateInputObjectSchema,
    MyReviewUncheckedUpdateInputObjectSchema,
  ]),
})
