import { z } from 'zod'
import { DeliveryCreateWithoutProductItemsInputObjectSchema } from './DeliveryCreateWithoutProductItemsInput.schema'
import { DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedCreateWithoutProductItemsInput.schema'
import { DeliveryCreateOrConnectWithoutProductItemsInputObjectSchema } from './DeliveryCreateOrConnectWithoutProductItemsInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateNestedOneWithoutProductItemsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryCreateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => DeliveryCreateOrConnectWithoutProductItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => DeliveryWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const DeliveryCreateNestedOneWithoutProductItemsInputObjectSchema =
  Schema
