import { z } from 'zod'
import { ProcedureBannerOrderByWithRelationInputObjectSchema } from './objects/ProcedureBannerOrderByWithRelationInput.schema'
import { ProcedureBannerWhereInputObjectSchema } from './objects/ProcedureBannerWhereInput.schema'
import { ProcedureBannerWhereUniqueInputObjectSchema } from './objects/ProcedureBannerWhereUniqueInput.schema'
import { ProcedureBannerCountAggregateInputObjectSchema } from './objects/ProcedureBannerCountAggregateInput.schema'
import { ProcedureBannerMinAggregateInputObjectSchema } from './objects/ProcedureBannerMinAggregateInput.schema'
import { ProcedureBannerMaxAggregateInputObjectSchema } from './objects/ProcedureBannerMaxAggregateInput.schema'
import { ProcedureBannerAvgAggregateInputObjectSchema } from './objects/ProcedureBannerAvgAggregateInput.schema'
import { ProcedureBannerSumAggregateInputObjectSchema } from './objects/ProcedureBannerSumAggregateInput.schema'

export const ProcedureBannerAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedureBannerOrderByWithRelationInputObjectSchema,
      ProcedureBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedureBannerWhereInputObjectSchema.optional(),
  cursor: ProcedureBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedureBannerCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedureBannerMinAggregateInputObjectSchema.optional(),
  _max: ProcedureBannerMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedureBannerAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedureBannerSumAggregateInputObjectSchema.optional(),
})
