import { z } from 'zod'

export const ProcedureScalarFieldEnumSchema = z.enum([
  'id',
  'active',
  'searchable',
  'nameTr',
  'searchField',
  'technologyTr',
  'usageTr',
  'resultPeriodTr',
  'descriptionTr',
  'preparationTr',
  'conditionTr',
  'methodOfUseTr',
  'isTreatedByDoctor',
  'priority',
  'expireType',
  'expireAt',
  'expireDurationDay',
  'tagPrice',
  'sellingPrice',
  'cost',
  'commissionRate',
  'partnerId',
  'branches',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'v2DrugItemId',
])
