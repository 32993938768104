import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.HolidayCreateInput> = z
  .object({
    date: z.date(),
    description: z.string(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
  .strict()

export const HolidayCreateInputObjectSchema = Schema
