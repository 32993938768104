import { z } from 'zod'
import { MyReviewCreateWithoutPatientInputObjectSchema } from './MyReviewCreateWithoutPatientInput.schema'
import { MyReviewUncheckedCreateWithoutPatientInputObjectSchema } from './MyReviewUncheckedCreateWithoutPatientInput.schema'
import { MyReviewCreateOrConnectWithoutPatientInputObjectSchema } from './MyReviewCreateOrConnectWithoutPatientInput.schema'
import { MyReviewCreateManyPatientInputEnvelopeObjectSchema } from './MyReviewCreateManyPatientInputEnvelope.schema'
import { MyReviewWhereUniqueInputObjectSchema } from './MyReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewCreateNestedManyWithoutPatientInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema),
        z.lazy(() => MyReviewCreateWithoutPatientInputObjectSchema).array(),
        z.lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema),
        z
          .lazy(() => MyReviewUncheckedCreateWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => MyReviewCreateOrConnectWithoutPatientInputObjectSchema),
        z
          .lazy(() => MyReviewCreateOrConnectWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => MyReviewCreateManyPatientInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => MyReviewWhereUniqueInputObjectSchema),
        z.lazy(() => MyReviewWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const MyReviewCreateNestedManyWithoutPatientInputObjectSchema = Schema
