import { z } from 'zod'
import { CasePictureCreateInputObjectSchema } from './objects/CasePictureCreateInput.schema'
import { CasePictureUncheckedCreateInputObjectSchema } from './objects/CasePictureUncheckedCreateInput.schema'

export const CasePictureCreateOneSchema = z.object({
  data: z.union([
    CasePictureCreateInputObjectSchema,
    CasePictureUncheckedCreateInputObjectSchema,
  ]),
})
