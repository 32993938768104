import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    orderItemId: z.literal(true).optional(),
    productId: z.literal(true).optional(),
    procedureId: z.literal(true).optional(),
    quantity: z.literal(true).optional(),
    reviewId: z.literal(true).optional(),
  })
  .strict()

export const MyReviewSumAggregateInputObjectSchema = Schema
