import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { CategoryUncheckedCreateNestedManyWithoutParentInputObjectSchema } from './CategoryUncheckedCreateNestedManyWithoutParentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CategoryUncheckedCreateWithoutProceduresInput> =
  z
    .object({
      id: z.number().optional(),
      nameTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      priority: z.number().optional(),
      icon: z.string().optional().nullable(),
      active: z.boolean().optional(),
      path: z.string(),
      parentId: z.number().optional().nullable(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      children: z
        .lazy(
          () => CategoryUncheckedCreateNestedManyWithoutParentInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const CategoryUncheckedCreateWithoutProceduresInputObjectSchema = Schema
