import { z } from 'zod'
import { CouponCreateWithoutGroupInputObjectSchema } from './CouponCreateWithoutGroupInput.schema'
import { CouponUncheckedCreateWithoutGroupInputObjectSchema } from './CouponUncheckedCreateWithoutGroupInput.schema'
import { CouponCreateOrConnectWithoutGroupInputObjectSchema } from './CouponCreateOrConnectWithoutGroupInput.schema'
import { CouponCreateManyGroupInputEnvelopeObjectSchema } from './CouponCreateManyGroupInputEnvelope.schema'
import { CouponWhereUniqueInputObjectSchema } from './CouponWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponCreateNestedManyWithoutGroupInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CouponCreateWithoutGroupInputObjectSchema),
        z.lazy(() => CouponCreateWithoutGroupInputObjectSchema).array(),
        z.lazy(() => CouponUncheckedCreateWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponUncheckedCreateWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => CouponCreateOrConnectWithoutGroupInputObjectSchema),
        z
          .lazy(() => CouponCreateOrConnectWithoutGroupInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => CouponCreateManyGroupInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => CouponWhereUniqueInputObjectSchema),
        z.lazy(() => CouponWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const CouponCreateNestedManyWithoutGroupInputObjectSchema = Schema
