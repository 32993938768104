import { z } from 'zod'
import { BranchCountOutputTypeSelectObjectSchema } from './BranchCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => BranchCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const BranchCountOutputTypeArgsObjectSchema = Schema
