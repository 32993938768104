import { z } from 'zod'
import { ProductUpdateWithoutPicturesInputObjectSchema } from './ProductUpdateWithoutPicturesInput.schema'
import { ProductUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProductUncheckedUpdateWithoutPicturesInput.schema'
import { ProductCreateWithoutPicturesInputObjectSchema } from './ProductCreateWithoutPicturesInput.schema'
import { ProductUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductUpdateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProductUncheckedUpdateWithoutPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ProductCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ProductUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ProductUpsertWithoutPicturesInputObjectSchema = Schema
