import { z } from 'zod'
import { AddressTagSchema } from '../enums/AddressTag.schema'
import { NestedEnumAddressTagNullableWithAggregatesFilterObjectSchema } from './NestedEnumAddressTagNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumAddressTagNullableFilterObjectSchema } from './NestedEnumAddressTagNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumAddressTagNullableWithAggregatesFilter> = z
  .object({
    equals: z
      .lazy(() => AddressTagSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => AddressTagSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => AddressTagSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => AddressTagSchema),
        z.lazy(
          () => NestedEnumAddressTagNullableWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
    _min: z
      .lazy(() => NestedEnumAddressTagNullableFilterObjectSchema)
      .optional(),
    _max: z
      .lazy(() => NestedEnumAddressTagNullableFilterObjectSchema)
      .optional(),
  })
  .strict()

export const EnumAddressTagNullableWithAggregatesFilterObjectSchema = Schema
