import { z } from 'zod'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { NestedEnumConsultingChannelWithAggregatesFilterObjectSchema } from './NestedEnumConsultingChannelWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumConsultingChannelFilterObjectSchema } from './NestedEnumConsultingChannelFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumConsultingChannelWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => ConsultingChannelSchema).optional(),
    in: z
      .lazy(() => ConsultingChannelSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => ConsultingChannelSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => ConsultingChannelSchema),
        z.lazy(
          () => NestedEnumConsultingChannelWithAggregatesFilterObjectSchema,
        ),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z
      .lazy(() => NestedEnumConsultingChannelFilterObjectSchema)
      .optional(),
    _max: z
      .lazy(() => NestedEnumConsultingChannelFilterObjectSchema)
      .optional(),
  })
  .strict()

export const EnumConsultingChannelWithAggregatesFilterObjectSchema = Schema
