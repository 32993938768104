import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './ProductItemWhereUniqueInput.schema'
import { ProductItemUpdateWithoutOrderInputObjectSchema } from './ProductItemUpdateWithoutOrderInput.schema'
import { ProductItemUncheckedUpdateWithoutOrderInputObjectSchema } from './ProductItemUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemUpdateWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProductItemWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductItemUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => ProductItemUncheckedUpdateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const ProductItemUpdateWithWhereUniqueWithoutOrderInputObjectSchema =
  Schema
