import { z } from 'zod'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'
import { RefundCreateWithoutProductItemsInputObjectSchema } from './RefundCreateWithoutProductItemsInput.schema'
import { RefundUncheckedCreateWithoutProductItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCreateOrConnectWithoutProductItemsInput> =
  z
    .object({
      where: z.lazy(() => RefundWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => RefundCreateWithoutProductItemsInputObjectSchema),
        z.lazy(() => RefundUncheckedCreateWithoutProductItemsInputObjectSchema),
      ]),
    })
    .strict()

export const RefundCreateOrConnectWithoutProductItemsInputObjectSchema = Schema
