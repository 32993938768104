import { z } from 'zod'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureInclude> = z
  .object({
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProcedurePictureIncludeObjectSchema = Schema
