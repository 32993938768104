import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { EnumConsultingChannelFilterObjectSchema } from './EnumConsultingChannelFilter.schema'
import { ConsultingChannelSchema } from '../enums/ConsultingChannel.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { OrderRelationFilterObjectSchema } from './OrderRelationFilter.schema'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => DoctorItemWhereInputObjectSchema),
        z.lazy(() => DoctorItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => DoctorItemWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => DoctorItemWhereInputObjectSchema),
        z.lazy(() => DoctorItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    caseId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    doctorFee: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    doctorFeeDiscount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    consultingChannel: z
      .union([
        z.lazy(() => EnumConsultingChannelFilterObjectSchema),
        z.lazy(() => ConsultingChannelSchema),
      ])
      .optional(),
    doctorName: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    doctorId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    order: z
      .union([
        z.lazy(() => OrderRelationFilterObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const DoctorItemWhereInputObjectSchema = Schema
