import { z } from 'zod'
import { RefundOrderByWithRelationInputObjectSchema } from './objects/RefundOrderByWithRelationInput.schema'
import { RefundWhereInputObjectSchema } from './objects/RefundWhereInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './objects/RefundWhereUniqueInput.schema'
import { RefundScalarFieldEnumSchema } from './enums/RefundScalarFieldEnum.schema'

export const RefundFindFirstSchema = z.object({
  orderBy: z
    .union([
      RefundOrderByWithRelationInputObjectSchema,
      RefundOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: RefundWhereInputObjectSchema.optional(),
  cursor: RefundWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(RefundScalarFieldEnumSchema).optional(),
})
