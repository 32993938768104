import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureBannerMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    name: z.literal(true).optional(),
    picture: z.literal(true).optional(),
    actionUri: z.literal(true).optional(),
    active: z.literal(true).optional(),
    rank: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
  })
  .strict()

export const ProcedureBannerMinAggregateInputObjectSchema = Schema
