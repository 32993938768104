import { z } from 'zod'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.EnumConsultingStatusFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => ConsultingStatusSchema).optional(),
    })
    .strict()

export const EnumConsultingStatusFieldUpdateOperationsInputObjectSchema = Schema
