import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { ProcedureUpdateOneRequiredWithoutCategoriesNestedInputObjectSchema } from './ProcedureUpdateOneRequiredWithoutCategoriesNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryUpdateWithoutCategoryInput> = z
  .object({
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    procedure: z
      .lazy(
        () =>
          ProcedureUpdateOneRequiredWithoutCategoriesNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ProcedureCategoryUpdateWithoutCategoryInputObjectSchema = Schema
