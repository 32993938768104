import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateManyConsentInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    patientId: z.number(),
  })
  .strict()

export const AcceptedConsentCreateManyConsentInputObjectSchema = Schema
