import { z } from 'zod'
import { ProductReviewSummaryCreateInputObjectSchema } from './objects/ProductReviewSummaryCreateInput.schema'
import { ProductReviewSummaryUncheckedCreateInputObjectSchema } from './objects/ProductReviewSummaryUncheckedCreateInput.schema'

export const ProductReviewSummaryCreateOneSchema = z.object({
  data: z.union([
    ProductReviewSummaryCreateInputObjectSchema,
    ProductReviewSummaryUncheckedCreateInputObjectSchema,
  ]),
})
