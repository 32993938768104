import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryUpdateWithoutOrderInputObjectSchema } from './DeliveryUpdateWithoutOrderInput.schema'
import { DeliveryUncheckedUpdateWithoutOrderInputObjectSchema } from './DeliveryUncheckedUpdateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => DeliveryUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => DeliveryUncheckedUpdateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const DeliveryUpdateWithWhereUniqueWithoutOrderInputObjectSchema = Schema
