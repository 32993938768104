import { z } from 'zod'
import { ExpressShippingAreaShippingProviderIdPostCodeCompoundUniqueInputObjectSchema } from './ExpressShippingAreaShippingProviderIdPostCodeCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    shippingProviderId_postCode: z
      .lazy(
        () =>
          ExpressShippingAreaShippingProviderIdPostCodeCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const ExpressShippingAreaWhereUniqueInputObjectSchema = Schema
