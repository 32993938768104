import { z } from 'zod'
import { FlashsaleArgsObjectSchema } from './FlashsaleArgs.schema'
import { ProcedureArgsObjectSchema } from './ProcedureArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    active: z.boolean().optional(),
    priority: z.boolean().optional(),
    flashsale: z
      .union([z.boolean(), z.lazy(() => FlashsaleArgsObjectSchema)])
      .optional(),
    flashsaleId: z.boolean().optional(),
    procedure: z
      .union([z.boolean(), z.lazy(() => ProcedureArgsObjectSchema)])
      .optional(),
    procedureId: z.boolean().optional(),
    startAt: z.boolean().optional(),
    endAt: z.boolean().optional(),
    price: z.boolean().optional(),
    priceAbsorbedBySkinX: z.boolean().optional(),
    priceAbsorbedByPartner: z.boolean().optional(),
    saleLimit: z.boolean().optional(),
    sold: z.boolean().optional(),
  })
  .strict()

export const FlashsaleItemSelectObjectSchema = Schema
