import { z } from 'zod'
import { RejectReasonCreateWithoutKycDataInputObjectSchema } from './RejectReasonCreateWithoutKycDataInput.schema'
import { RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedCreateWithoutKycDataInput.schema'
import { RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema } from './RejectReasonCreateOrConnectWithoutKycDataInput.schema'
import { RejectReasonUpsertWithWhereUniqueWithoutKycDataInputObjectSchema } from './RejectReasonUpsertWithWhereUniqueWithoutKycDataInput.schema'
import { RejectReasonCreateManyKycDataInputEnvelopeObjectSchema } from './RejectReasonCreateManyKycDataInputEnvelope.schema'
import { RejectReasonWhereUniqueInputObjectSchema } from './RejectReasonWhereUniqueInput.schema'
import { RejectReasonUpdateWithWhereUniqueWithoutKycDataInputObjectSchema } from './RejectReasonUpdateWithWhereUniqueWithoutKycDataInput.schema'
import { RejectReasonUpdateManyWithWhereWithoutKycDataInputObjectSchema } from './RejectReasonUpdateManyWithWhereWithoutKycDataInput.schema'
import { RejectReasonScalarWhereInputObjectSchema } from './RejectReasonScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonUncheckedUpdateManyWithoutKycDataNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema),
          z
            .lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema)
            .array(),
          z.lazy(
            () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () => RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              RejectReasonUpsertWithWhereUniqueWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                RejectReasonUpsertWithWhereUniqueWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => RejectReasonCreateManyKycDataInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
          z.lazy(() => RejectReasonWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              RejectReasonUpdateWithWhereUniqueWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                RejectReasonUpdateWithWhereUniqueWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              RejectReasonUpdateManyWithWhereWithoutKycDataInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                RejectReasonUpdateManyWithWhereWithoutKycDataInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => RejectReasonScalarWhereInputObjectSchema),
          z.lazy(() => RejectReasonScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const RejectReasonUncheckedUpdateManyWithoutKycDataNestedInputObjectSchema =
  Schema
