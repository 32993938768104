import { z } from 'zod'
import { ProcedureBannerWhereInputObjectSchema } from './objects/ProcedureBannerWhereInput.schema'
import { ProcedureBannerOrderByWithAggregationInputObjectSchema } from './objects/ProcedureBannerOrderByWithAggregationInput.schema'
import { ProcedureBannerScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureBannerScalarWhereWithAggregatesInput.schema'
import { ProcedureBannerScalarFieldEnumSchema } from './enums/ProcedureBannerScalarFieldEnum.schema'

export const ProcedureBannerGroupBySchema = z.object({
  where: ProcedureBannerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureBannerOrderByWithAggregationInputObjectSchema,
    ProcedureBannerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedureBannerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureBannerScalarFieldEnumSchema),
})
