import { z } from 'zod'
import { CaseUpdateWithoutPicturesInputObjectSchema } from './CaseUpdateWithoutPicturesInput.schema'
import { CaseUncheckedUpdateWithoutPicturesInputObjectSchema } from './CaseUncheckedUpdateWithoutPicturesInput.schema'
import { CaseCreateWithoutPicturesInputObjectSchema } from './CaseCreateWithoutPicturesInput.schema'
import { CaseUncheckedCreateWithoutPicturesInputObjectSchema } from './CaseUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => CaseUpdateWithoutPicturesInputObjectSchema),
      z.lazy(() => CaseUncheckedUpdateWithoutPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CaseCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const CaseUpsertWithoutPicturesInputObjectSchema = Schema
