import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCountOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    weekday: z.lazy(() => SortOrderSchema).optional(),
    openAt: z.lazy(() => SortOrderSchema).optional(),
    closeAt: z.lazy(() => SortOrderSchema).optional(),
    timezone: z.lazy(() => SortOrderSchema).optional(),
    branchId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const OfficeHourCountOrderByAggregateInputObjectSchema = Schema
