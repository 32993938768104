import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CaseSummaryDiagnosisOrderByRelationAggregateInputObjectSchema } from './CaseSummaryDiagnosisOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    codePlain: z.lazy(() => SortOrderSchema).optional(),
    term: z.lazy(() => SortOrderSchema).optional(),
    caseSummaryDiagnosis: z
      .lazy(() => CaseSummaryDiagnosisOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const DiagnosisOrderByWithRelationInputObjectSchema = Schema
