import { Typography } from '@mui/material'
import { FC } from 'react'
import { TextFieldProps, useRecordContext } from 'react-admin'

export const FullnameField: FC<TextFieldProps> = (props) => {
  const record = useRecordContext()
  if (!record) return null

  return (
    <Typography {...props} sx={{ fontSize: '14px' }}>
      {record.prefix} {record.firstname} {record.lastname}
    </Typography>
  )
}
