import { z } from 'zod'
import { ProductCreateWithoutVariantsInputObjectSchema } from './ProductCreateWithoutVariantsInput.schema'
import { ProductUncheckedCreateWithoutVariantsInputObjectSchema } from './ProductUncheckedCreateWithoutVariantsInput.schema'
import { ProductCreateOrConnectWithoutVariantsInputObjectSchema } from './ProductCreateOrConnectWithoutVariantsInput.schema'
import { ProductUpsertWithoutVariantsInputObjectSchema } from './ProductUpsertWithoutVariantsInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutVariantsInputObjectSchema } from './ProductUpdateWithoutVariantsInput.schema'
import { ProductUncheckedUpdateWithoutVariantsInputObjectSchema } from './ProductUncheckedUpdateWithoutVariantsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutVariantsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutVariantsInputObjectSchema),
          z.lazy(() => ProductUncheckedCreateWithoutVariantsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProductCreateOrConnectWithoutVariantsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutVariantsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProductUpdateWithoutVariantsInputObjectSchema),
          z.lazy(() => ProductUncheckedUpdateWithoutVariantsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutVariantsNestedInputObjectSchema =
  Schema
