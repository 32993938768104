import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ArticlePictureOrderByRelationAggregateInputObjectSchema } from './ArticlePictureOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    coverPicture: z.lazy(() => SortOrderSchema).optional(),
    titleTr: z.lazy(() => SortOrderSchema).optional(),
    detailTr: z.lazy(() => SortOrderSchema).optional(),
    pictures: z
      .lazy(() => ArticlePictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ArticleOrderByWithRelationInputObjectSchema = Schema
