import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { EnumOrderStatusFieldUpdateOperationsInputObjectSchema } from './EnumOrderStatusFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { EnumOrderTypeFieldUpdateOperationsInputObjectSchema } from './EnumOrderTypeFieldUpdateOperationsInput.schema'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { NullableEnumPurchaseOriginFieldUpdateOperationsInputObjectSchema } from './NullableEnumPurchaseOriginFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { NullableEnumShippingTypeFieldUpdateOperationsInputObjectSchema } from './NullableEnumShippingTypeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'
import { NullableEnumCancelChannelFieldUpdateOperationsInputObjectSchema } from './NullableEnumCancelChannelFieldUpdateOperationsInput.schema'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInputObjectSchema } from './NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInput.schema'
import { DoctorItemUncheckedUpdateOneWithoutOrderNestedInputObjectSchema } from './DoctorItemUncheckedUpdateOneWithoutOrderNestedInput.schema'
import { ProductItemUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './ProductItemUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { ProcedureItemUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './ProcedureItemUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { CouponUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './CouponUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { PaymentUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './PaymentUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { RefundUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './RefundUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { DeliveryUncheckedUpdateManyWithoutOrderNestedInputObjectSchema } from './DeliveryUncheckedUpdateManyWithoutOrderNestedInput.schema'
import { HealthInfoUncheckedUpdateOneWithoutOrderNestedInputObjectSchema } from './HealthInfoUncheckedUpdateOneWithoutOrderNestedInput.schema'
import { ReceiptUncheckedUpdateOneWithoutOrderNestedInputObjectSchema } from './ReceiptUncheckedUpdateOneWithoutOrderNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUncheckedUpdateWithoutProductItemsOriginalInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      status: z
        .union([
          z.lazy(() => OrderStatusSchema),
          z.lazy(() => EnumOrderStatusFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      expiredAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      shouldExpiresAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      orderNumber: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      patientId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      customerName: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      customerTelNo: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      orderType: z
        .union([
          z.lazy(() => OrderTypeSchema),
          z.lazy(() => EnumOrderTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      purchaseOrigin: z
        .union([
          z.lazy(() => PurchaseOriginSchema),
          z.lazy(
            () =>
              NullableEnumPurchaseOriginFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      purchaseRef: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      editedProductItemsAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      editedProductItemsBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      shippingFee: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      shippingFeeDiscount: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      shippingType: z
        .union([
          z.lazy(() => ShippingTypeSchema),
          z.lazy(
            () =>
              NullableEnumShippingTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      shippingProviderId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      recipient: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      shippingAddress: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      shippingTelNo: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      subDistrict: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      subDistrictKey: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      district: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      districtKey: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      province: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      provinceKey: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      postcode: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      isPaid: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      isHold: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      approvedBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      approvedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      canceledBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      canceledAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      cancelChannel: z
        .union([
          z.lazy(() => CancelChannelSchema),
          z.lazy(
            () =>
              NullableEnumCancelChannelFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      noteToPatient: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useCoin: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      v2InvoiceId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      holdingNote: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      holdingBy: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      holdingAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      isDeclinedSuggestedItems: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      declinedSuggestedItemsReason: z
        .union([
          z.lazy(() => DeclinedSuggestedItemsReasonSchema),
          z.lazy(
            () =>
              NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      doctorItem: z
        .lazy(
          () => DoctorItemUncheckedUpdateOneWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      productItems: z
        .lazy(
          () =>
            ProductItemUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      procedureItems: z
        .lazy(
          () =>
            ProcedureItemUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      coupons: z
        .lazy(
          () => CouponUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      payments: z
        .lazy(
          () => PaymentUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      refunds: z
        .lazy(
          () => RefundUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      deliveries: z
        .lazy(
          () => DeliveryUncheckedUpdateManyWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      healthInfo: z
        .lazy(
          () => HealthInfoUncheckedUpdateOneWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
      receipt: z
        .lazy(
          () => ReceiptUncheckedUpdateOneWithoutOrderNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const OrderUncheckedUpdateWithoutProductItemsOriginalInputObjectSchema =
  Schema
