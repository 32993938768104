import { z } from 'zod'
import { DiscountSettingSelectObjectSchema } from './DiscountSettingSelect.schema'
import { DiscountSettingIncludeObjectSchema } from './DiscountSettingInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingArgs> = z
  .object({
    select: z.lazy(() => DiscountSettingSelectObjectSchema).optional(),
    include: z.lazy(() => DiscountSettingIncludeObjectSchema).optional(),
  })
  .strict()

export const DiscountSettingArgsObjectSchema = Schema
