import { z } from 'zod'
import { ProcedureItemWhereUniqueInputObjectSchema } from './objects/ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemCreateInputObjectSchema } from './objects/ProcedureItemCreateInput.schema'
import { ProcedureItemUncheckedCreateInputObjectSchema } from './objects/ProcedureItemUncheckedCreateInput.schema'
import { ProcedureItemUpdateInputObjectSchema } from './objects/ProcedureItemUpdateInput.schema'
import { ProcedureItemUncheckedUpdateInputObjectSchema } from './objects/ProcedureItemUncheckedUpdateInput.schema'

export const ProcedureItemUpsertSchema = z.object({
  where: ProcedureItemWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureItemCreateInputObjectSchema,
    ProcedureItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureItemUpdateInputObjectSchema,
    ProcedureItemUncheckedUpdateInputObjectSchema,
  ]),
})
