import { z } from 'zod'
import { ProcedureCategoryWhereUniqueInputObjectSchema } from './ProcedureCategoryWhereUniqueInput.schema'
import { ProcedureCategoryCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryCreateWithoutCategoryInput.schema'
import { ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProcedureCategoryUncheckedCreateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCategoryCreateOrConnectWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProcedureCategoryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureCategoryCreateWithoutCategoryInputObjectSchema),
        z.lazy(
          () =>
            ProcedureCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureCategoryCreateOrConnectWithoutCategoryInputObjectSchema =
  Schema
