import { z } from 'zod'
import { RejectReasonWhereUniqueInputObjectSchema } from './RejectReasonWhereUniqueInput.schema'
import { RejectReasonUpdateWithoutKycDataInputObjectSchema } from './RejectReasonUpdateWithoutKycDataInput.schema'
import { RejectReasonUncheckedUpdateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedUpdateWithoutKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonUpdateWithWhereUniqueWithoutKycDataInput> =
  z
    .object({
      where: z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => RejectReasonUpdateWithoutKycDataInputObjectSchema),
        z.lazy(
          () => RejectReasonUncheckedUpdateWithoutKycDataInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const RejectReasonUpdateWithWhereUniqueWithoutKycDataInputObjectSchema =
  Schema
