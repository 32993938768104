import { z } from 'zod'
import { CaseStatusSchema } from '../enums/CaseStatus.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumCaseStatusFilterObjectSchema } from './NestedEnumCaseStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumCaseStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => CaseStatusSchema).optional(),
    in: z
      .lazy(() => CaseStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CaseStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CaseStatusSchema),
        z.lazy(() => NestedEnumCaseStatusWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumCaseStatusFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumCaseStatusFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumCaseStatusWithAggregatesFilterObjectSchema = Schema
