import { z } from 'zod'
import { DiscountSettingUpdateInputObjectSchema } from './objects/DiscountSettingUpdateInput.schema'
import { DiscountSettingUncheckedUpdateInputObjectSchema } from './objects/DiscountSettingUncheckedUpdateInput.schema'
import { DiscountSettingWhereUniqueInputObjectSchema } from './objects/DiscountSettingWhereUniqueInput.schema'

export const DiscountSettingUpdateOneSchema = z.object({
  data: z.union([
    DiscountSettingUpdateInputObjectSchema,
    DiscountSettingUncheckedUpdateInputObjectSchema,
  ]),
  where: DiscountSettingWhereUniqueInputObjectSchema,
})
