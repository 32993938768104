import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseUncheckedCreateWithoutVariantWarehousesInput> =
  z
    .object({
      id: z.number().optional(),
      name: z.string(),
      code: z.lazy(() => WarehouseCodeSchema),
    })
    .strict()

export const WarehouseUncheckedCreateWithoutVariantWarehousesInputObjectSchema =
  Schema
