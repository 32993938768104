import { z } from 'zod'
import { CoverPictureWhereUniqueInputObjectSchema } from './objects/CoverPictureWhereUniqueInput.schema'
import { CoverPictureCreateInputObjectSchema } from './objects/CoverPictureCreateInput.schema'
import { CoverPictureUncheckedCreateInputObjectSchema } from './objects/CoverPictureUncheckedCreateInput.schema'
import { CoverPictureUpdateInputObjectSchema } from './objects/CoverPictureUpdateInput.schema'
import { CoverPictureUncheckedUpdateInputObjectSchema } from './objects/CoverPictureUncheckedUpdateInput.schema'

export const CoverPictureUpsertSchema = z.object({
  where: CoverPictureWhereUniqueInputObjectSchema,
  create: z.union([
    CoverPictureCreateInputObjectSchema,
    CoverPictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CoverPictureUpdateInputObjectSchema,
    CoverPictureUncheckedUpdateInputObjectSchema,
  ]),
})
