import { z } from 'zod'
import { RejectReasonOrderByWithRelationInputObjectSchema } from './objects/RejectReasonOrderByWithRelationInput.schema'
import { RejectReasonWhereInputObjectSchema } from './objects/RejectReasonWhereInput.schema'
import { RejectReasonWhereUniqueInputObjectSchema } from './objects/RejectReasonWhereUniqueInput.schema'
import { RejectReasonScalarFieldEnumSchema } from './enums/RejectReasonScalarFieldEnum.schema'

export const RejectReasonFindFirstSchema = z.object({
  orderBy: z
    .union([
      RejectReasonOrderByWithRelationInputObjectSchema,
      RejectReasonOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: RejectReasonWhereInputObjectSchema.optional(),
  cursor: RejectReasonWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(RejectReasonScalarFieldEnumSchema).optional(),
})
