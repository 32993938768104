import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OrderNotificationCountOrderByAggregateInputObjectSchema } from './OrderNotificationCountOrderByAggregateInput.schema'
import { OrderNotificationAvgOrderByAggregateInputObjectSchema } from './OrderNotificationAvgOrderByAggregateInput.schema'
import { OrderNotificationMaxOrderByAggregateInputObjectSchema } from './OrderNotificationMaxOrderByAggregateInput.schema'
import { OrderNotificationMinOrderByAggregateInputObjectSchema } from './OrderNotificationMinOrderByAggregateInput.schema'
import { OrderNotificationSumOrderByAggregateInputObjectSchema } from './OrderNotificationSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    onesignalMessageId: z.lazy(() => SortOrderSchema).optional(),
    orderItemType: z.lazy(() => SortOrderSchema).optional(),
    title: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    sendAt: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => OrderNotificationCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => OrderNotificationAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => OrderNotificationMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => OrderNotificationMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => OrderNotificationSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const OrderNotificationOrderByWithAggregationInputObjectSchema = Schema
