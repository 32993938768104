import { z } from 'zod'
import { ProductVideoCreateWithoutProductInputObjectSchema } from './ProductVideoCreateWithoutProductInput.schema'
import { ProductVideoUncheckedCreateWithoutProductInputObjectSchema } from './ProductVideoUncheckedCreateWithoutProductInput.schema'
import { ProductVideoCreateOrConnectWithoutProductInputObjectSchema } from './ProductVideoCreateOrConnectWithoutProductInput.schema'
import { ProductVideoUpsertWithWhereUniqueWithoutProductInputObjectSchema } from './ProductVideoUpsertWithWhereUniqueWithoutProductInput.schema'
import { ProductVideoCreateManyProductInputEnvelopeObjectSchema } from './ProductVideoCreateManyProductInputEnvelope.schema'
import { ProductVideoWhereUniqueInputObjectSchema } from './ProductVideoWhereUniqueInput.schema'
import { ProductVideoUpdateWithWhereUniqueWithoutProductInputObjectSchema } from './ProductVideoUpdateWithWhereUniqueWithoutProductInput.schema'
import { ProductVideoUpdateManyWithWhereWithoutProductInputObjectSchema } from './ProductVideoUpdateManyWithWhereWithoutProductInput.schema'
import { ProductVideoScalarWhereInputObjectSchema } from './ProductVideoScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUpdateManyWithoutProductNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductVideoCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductVideoCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductVideoUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductVideoUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductVideoCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductVideoCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              ProductVideoUpsertWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductVideoUpsertWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductVideoCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      set: z
        .union([
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
          z.lazy(() => ProductVideoWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              ProductVideoUpdateWithWhereUniqueWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductVideoUpdateWithWhereUniqueWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              ProductVideoUpdateManyWithWhereWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProductVideoUpdateManyWithWhereWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => ProductVideoScalarWhereInputObjectSchema),
          z.lazy(() => ProductVideoScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductVideoUpdateManyWithoutProductNestedInputObjectSchema =
  Schema
