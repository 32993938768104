import { z } from 'zod'
import { NotificationTypeSchema } from '../enums/NotificationType.schema'
import { NotificationActionTypeSchema } from '../enums/NotificationActionType.schema'
import { AppointmentEventSchema } from '../enums/AppointmentEvent.schema'
import { SegmentUncheckedCreateNestedManyWithoutNotificationsInputObjectSchema } from './SegmentUncheckedCreateNestedManyWithoutNotificationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUncheckedCreateWithoutPatientsInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      type: z.lazy(() => NotificationTypeSchema),
      picture: z.string().optional().nullable(),
      title: z.string(),
      message: z.string(),
      actionType: z.lazy(() => NotificationActionTypeSchema).optional(),
      action: z.string().optional().nullable(),
      onesignalMessageId: z.string().optional().nullable(),
      startAt: z.date().optional(),
      appointmentId: z.number().optional().nullable(),
      appointmentEvent: z
        .lazy(() => AppointmentEventSchema)
        .optional()
        .nullable(),
      segments: z
        .lazy(
          () =>
            SegmentUncheckedCreateNestedManyWithoutNotificationsInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const NotificationUncheckedCreateWithoutPatientsInputObjectSchema =
  Schema
