import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { NotificationPatientCountOrderByAggregateInputObjectSchema } from './NotificationPatientCountOrderByAggregateInput.schema'
import { NotificationPatientAvgOrderByAggregateInputObjectSchema } from './NotificationPatientAvgOrderByAggregateInput.schema'
import { NotificationPatientMaxOrderByAggregateInputObjectSchema } from './NotificationPatientMaxOrderByAggregateInput.schema'
import { NotificationPatientMinOrderByAggregateInputObjectSchema } from './NotificationPatientMinOrderByAggregateInput.schema'
import { NotificationPatientSumOrderByAggregateInputObjectSchema } from './NotificationPatientSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientOrderByWithAggregationInput> =
  z
    .object({
      patientId: z.lazy(() => SortOrderSchema).optional(),
      notificationId: z.lazy(() => SortOrderSchema).optional(),
      readAt: z.lazy(() => SortOrderSchema).optional(),
      _count: z
        .lazy(() => NotificationPatientCountOrderByAggregateInputObjectSchema)
        .optional(),
      _avg: z
        .lazy(() => NotificationPatientAvgOrderByAggregateInputObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NotificationPatientMaxOrderByAggregateInputObjectSchema)
        .optional(),
      _min: z
        .lazy(() => NotificationPatientMinOrderByAggregateInputObjectSchema)
        .optional(),
      _sum: z
        .lazy(() => NotificationPatientSumOrderByAggregateInputObjectSchema)
        .optional(),
    })
    .strict()

export const NotificationPatientOrderByWithAggregationInputObjectSchema = Schema
