import { z } from 'zod'
import { DeliveryCreateWithoutOrderInputObjectSchema } from './DeliveryCreateWithoutOrderInput.schema'
import { DeliveryUncheckedCreateWithoutOrderInputObjectSchema } from './DeliveryUncheckedCreateWithoutOrderInput.schema'
import { DeliveryCreateOrConnectWithoutOrderInputObjectSchema } from './DeliveryCreateOrConnectWithoutOrderInput.schema'
import { DeliveryUpsertWithWhereUniqueWithoutOrderInputObjectSchema } from './DeliveryUpsertWithWhereUniqueWithoutOrderInput.schema'
import { DeliveryCreateManyOrderInputEnvelopeObjectSchema } from './DeliveryCreateManyOrderInputEnvelope.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryUpdateWithWhereUniqueWithoutOrderInputObjectSchema } from './DeliveryUpdateWithWhereUniqueWithoutOrderInput.schema'
import { DeliveryUpdateManyWithWhereWithoutOrderInputObjectSchema } from './DeliveryUpdateManyWithWhereWithoutOrderInput.schema'
import { DeliveryScalarWhereInputObjectSchema } from './DeliveryScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateManyWithoutOrderNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema),
        z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema).array(),
        z.lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema),
        z
          .lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => DeliveryCreateOrConnectWithoutOrderInputObjectSchema),
        z
          .lazy(() => DeliveryCreateOrConnectWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => DeliveryUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
        ),
        z
          .lazy(
            () => DeliveryUpsertWithWhereUniqueWithoutOrderInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => DeliveryCreateManyOrderInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
        z.lazy(() => DeliveryWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => DeliveryUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
        ),
        z
          .lazy(
            () => DeliveryUpdateWithWhereUniqueWithoutOrderInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(() => DeliveryUpdateManyWithWhereWithoutOrderInputObjectSchema),
        z
          .lazy(() => DeliveryUpdateManyWithWhereWithoutOrderInputObjectSchema)
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => DeliveryScalarWhereInputObjectSchema),
        z.lazy(() => DeliveryScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const DeliveryUpdateManyWithoutOrderNestedInputObjectSchema = Schema
