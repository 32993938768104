import { z } from 'zod'
import { PartnerWhereInputObjectSchema } from './PartnerWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerRelationFilter> = z
  .object({
    is: z.lazy(() => PartnerWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => PartnerWhereInputObjectSchema).optional(),
  })
  .strict()

export const PartnerRelationFilterObjectSchema = Schema
