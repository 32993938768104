import { z } from 'zod'
import { ProductReviewWhereInputObjectSchema } from './objects/ProductReviewWhereInput.schema'
import { ProductReviewOrderByWithAggregationInputObjectSchema } from './objects/ProductReviewOrderByWithAggregationInput.schema'
import { ProductReviewScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductReviewScalarWhereWithAggregatesInput.schema'
import { ProductReviewScalarFieldEnumSchema } from './enums/ProductReviewScalarFieldEnum.schema'

export const ProductReviewGroupBySchema = z.object({
  where: ProductReviewWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductReviewOrderByWithAggregationInputObjectSchema,
    ProductReviewOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductReviewScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductReviewScalarFieldEnumSchema),
})
