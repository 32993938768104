import { z } from 'zod'
import { CoverPictureCreateManyPartnerInputObjectSchema } from './CoverPictureCreateManyPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureCreateManyPartnerInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => CoverPictureCreateManyPartnerInputObjectSchema),
      z.lazy(() => CoverPictureCreateManyPartnerInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const CoverPictureCreateManyPartnerInputEnvelopeObjectSchema = Schema
