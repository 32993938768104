import { z } from 'zod'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemCreateWithoutCaseSummaryInput.schema'
import { SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProcedureItemWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () => SuggestedProcedureItemUpdateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProcedureItemUncheckedUpdateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => SuggestedProcedureItemCreateWithoutCaseSummaryInputObjectSchema,
        ),
        z.lazy(
          () =>
            SuggestedProcedureItemUncheckedCreateWithoutCaseSummaryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProcedureItemUpsertWithWhereUniqueWithoutCaseSummaryInputObjectSchema =
  Schema
