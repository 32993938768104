import AddIcon from '@mui/icons-material/Add'
import { Button, Typography } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin'
import { CustomBooleanField } from '../../../components/CustomField'
import { FormDivider } from '../../../components/FormInput/divider'
import { WebsiteField } from '../../../components/WebsiteField'

export const Branch: FC = () => {
  const record = useRecordContext()

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        สาขา
      </Typography>
      <FormDivider />
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{ mb: 1 }}
        onClick={() => {
          window.open(`/partnerBranch/create?partnerId=${record.id}`, '_blank')
        }}
      >
        เพิ่มสาขา
      </Button>
      <ReferenceManyField
        reference="partnerBranch"
        target="partnerId"
        perPage={999}
      >
        <Datagrid
          sx={{ width: '100%', minWidth: '800px', overflowX: 'auto' }}
          bulkActionButtons={false}
          rowClick="edit"
        >
          <TextField source="id" sortable={false} />
          <TextField label="Name" source="nameTr.th" sortable={false} />
          <TextField source="phone" sortable={false} />
          <FunctionField
            label="Location"
            render={(record: { googleMapUrl: string }) =>
              WebsiteField(record.googleMapUrl)
            }
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
          <CustomBooleanField source="active" label="Active" />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}
