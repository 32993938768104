import { z } from 'zod'
import { CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema } from './CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInput.schema'
import { CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelopeObjectSchema } from './CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelope.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './CaseSummaryDiagnosisWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutCaseSummaryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () => CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
          z.lazy(
            () =>
              CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisUncheckedCreateWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                CaseSummaryDiagnosisCreateOrConnectWithoutCaseSummaryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () =>
            CaseSummaryDiagnosisCreateManyCaseSummaryInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema),
          z
            .lazy(() => CaseSummaryDiagnosisWhereUniqueInputObjectSchema)
            .array(),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryDiagnosisUncheckedCreateNestedManyWithoutCaseSummaryInputObjectSchema =
  Schema
