import { z } from 'zod'
import { TeleconsultUpdateWithoutAppointmentInputObjectSchema } from './TeleconsultUpdateWithoutAppointmentInput.schema'
import { TeleconsultUncheckedUpdateWithoutAppointmentInputObjectSchema } from './TeleconsultUncheckedUpdateWithoutAppointmentInput.schema'
import { TeleconsultCreateWithoutAppointmentInputObjectSchema } from './TeleconsultCreateWithoutAppointmentInput.schema'
import { TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema } from './TeleconsultUncheckedCreateWithoutAppointmentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpsertWithoutAppointmentInput> = z
  .object({
    update: z.union([
      z.lazy(() => TeleconsultUpdateWithoutAppointmentInputObjectSchema),
      z.lazy(
        () => TeleconsultUncheckedUpdateWithoutAppointmentInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => TeleconsultCreateWithoutAppointmentInputObjectSchema),
      z.lazy(
        () => TeleconsultUncheckedCreateWithoutAppointmentInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const TeleconsultUpsertWithoutAppointmentInputObjectSchema = Schema
