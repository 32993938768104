import { z } from 'zod'
import { BrandBannerOrderByWithRelationInputObjectSchema } from './objects/BrandBannerOrderByWithRelationInput.schema'
import { BrandBannerWhereInputObjectSchema } from './objects/BrandBannerWhereInput.schema'
import { BrandBannerWhereUniqueInputObjectSchema } from './objects/BrandBannerWhereUniqueInput.schema'
import { BrandBannerCountAggregateInputObjectSchema } from './objects/BrandBannerCountAggregateInput.schema'
import { BrandBannerMinAggregateInputObjectSchema } from './objects/BrandBannerMinAggregateInput.schema'
import { BrandBannerMaxAggregateInputObjectSchema } from './objects/BrandBannerMaxAggregateInput.schema'
import { BrandBannerAvgAggregateInputObjectSchema } from './objects/BrandBannerAvgAggregateInput.schema'
import { BrandBannerSumAggregateInputObjectSchema } from './objects/BrandBannerSumAggregateInput.schema'

export const BrandBannerAggregateSchema = z.object({
  orderBy: z
    .union([
      BrandBannerOrderByWithRelationInputObjectSchema,
      BrandBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: BrandBannerWhereInputObjectSchema.optional(),
  cursor: BrandBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), BrandBannerCountAggregateInputObjectSchema])
    .optional(),
  _min: BrandBannerMinAggregateInputObjectSchema.optional(),
  _max: BrandBannerMaxAggregateInputObjectSchema.optional(),
  _avg: BrandBannerAvgAggregateInputObjectSchema.optional(),
  _sum: BrandBannerSumAggregateInputObjectSchema.optional(),
})
