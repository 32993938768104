import { z } from 'zod'
import { CaseSummaryCreateWithoutCaseInputObjectSchema } from './CaseSummaryCreateWithoutCaseInput.schema'
import { CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutCaseInput.schema'
import { CaseSummaryCreateOrConnectWithoutCaseInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutCaseInput.schema'
import { CaseSummaryUpsertWithoutCaseInputObjectSchema } from './CaseSummaryUpsertWithoutCaseInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryUpdateWithoutCaseInputObjectSchema } from './CaseSummaryUpdateWithoutCaseInput.schema'
import { CaseSummaryUncheckedUpdateWithoutCaseInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateOneWithoutCaseNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseSummaryCreateWithoutCaseInputObjectSchema),
        z.lazy(() => CaseSummaryUncheckedCreateWithoutCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseSummaryCreateOrConnectWithoutCaseInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => CaseSummaryUpsertWithoutCaseInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => CaseSummaryUpdateWithoutCaseInputObjectSchema),
        z.lazy(() => CaseSummaryUncheckedUpdateWithoutCaseInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CaseSummaryUpdateOneWithoutCaseNestedInputObjectSchema = Schema
