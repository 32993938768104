import { z } from 'zod'

export const ProductReviewScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'variantId',
  'productId',
  'brandId',
  'overallRating',
  'qualityRating',
  'shippingRating',
  'packagingRating',
  'message',
  'orderId',
  'patientId',
  'patientName',
  'isAnonymous',
  'priority',
])
