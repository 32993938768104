import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { ConditionScopeSchema } from '../enums/ConditionScope.schema'
import { EnumConditionScopeFieldUpdateOperationsInputObjectSchema } from './EnumConditionScopeFieldUpdateOperationsInput.schema'
import { ConditionItemTypeSchema } from '../enums/ConditionItemType.schema'
import { EnumConditionItemTypeFieldUpdateOperationsInputObjectSchema } from './EnumConditionItemTypeFieldUpdateOperationsInput.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ConditionSettingUpdateManyMutationInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    scope: z
      .union([
        z.lazy(() => ConditionScopeSchema),
        z.lazy(() => EnumConditionScopeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    itemType: z
      .union([
        z.lazy(() => ConditionItemTypeSchema),
        z.lazy(
          () => EnumConditionItemTypeFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional(),
    items: z
      .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
      .optional(),
    settingGroupId: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const ConditionSettingUpdateManyMutationInputObjectSchema = Schema
