import { z } from 'zod'
import { CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUpsertWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUpsertWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryWhereUniqueInputObjectSchema } from './CaseSummaryWhereUniqueInput.schema'
import { CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInput.schema'
import { CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpdateOneRequiredWithoutSuggestedProductItemsOriginalNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(
            () =>
              CaseSummaryCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedCreateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () =>
            CaseSummaryCreateOrConnectWithoutSuggestedProductItemsOriginalInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(
          () =>
            CaseSummaryUpsertWithoutSuggestedProductItemsOriginalInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => CaseSummaryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(
            () =>
              CaseSummaryUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
          z.lazy(
            () =>
              CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsOriginalInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CaseSummaryUpdateOneRequiredWithoutSuggestedProductItemsOriginalNestedInputObjectSchema =
  Schema
