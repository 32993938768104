import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteOfficeHour, RelatedOfficeHourModel, CompletePartner, RelatedPartnerModel } from "./index"

export const BranchModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  address: z.string(),
  subDistrict: z.string(),
  subDistrictKey: z.string(),
  district: z.string(),
  districtKey: z.string(),
  province: z.string(),
  provinceKey: z.string(),
  postcode: z.string(),
  googleMapUrl: z.string().nullish(),
  lat: z.number().nullish(),
  long: z.number().nullish(),
  phone: z.string(),
  active: z.boolean(),
  isExpertise: z.boolean(),
  remark: z.string().nullish(),
  partnerId: z.number().int(),
  v2BranchId: z.number().int().nullish(),
})

export const BranchModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  nameTr: jsonSchema,
  address: z.string(),
  subDistrict: z.string(),
  subDistrictKey: z.string(),
  district: z.string(),
  districtKey: z.string(),
  province: z.string(),
  provinceKey: z.string(),
  postcode: z.string(),
  googleMapUrl: z.string().nullish(),
  lat: z.number().nullish(),
  long: z.number().nullish(),
  phone: z.string(),
  active: z.boolean(),
  isExpertise: z.boolean(),
  remark: z.string().nullish(),
  partnerId: z.number().int(),
  v2BranchId: z.number().int().nullish(),
})

export interface CompleteBranch extends z.infer<typeof BranchModel> {
  officeHours: CompleteOfficeHour[]
  partner: CompletePartner
}

/**
 * RelatedBranchModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBranchModel: z.ZodSchema<CompleteBranch> = z.lazy(() => BranchModel.extend({
  officeHours: RelatedOfficeHourModel.array(),
  partner: RelatedPartnerModel,
}))
