import { z } from 'zod'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'
import { NestedEnumOrderItemTypeWithAggregatesFilterObjectSchema } from './NestedEnumOrderItemTypeWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumOrderItemTypeFilterObjectSchema } from './NestedEnumOrderItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumOrderItemTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => OrderItemTypeSchema).optional(),
    in: z
      .lazy(() => OrderItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => OrderItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => OrderItemTypeSchema),
        z.lazy(() => NestedEnumOrderItemTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumOrderItemTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumOrderItemTypeFilterObjectSchema).optional(),
  })
  .strict()

export const EnumOrderItemTypeWithAggregatesFilterObjectSchema = Schema
