import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompletePatient, RelatedPatientModel } from "./index"

export const MyReviewModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  patientId: z.number().int(),
  picture: z.string(),
  titleTr: z.object({ en: z.string(), th: z.string() }),
  subtitleTr: z.object({ en: z.string(), th: z.string() }),
  orderItemId: z.number().int(),
  orderItemType: z.enum(['PRODUCT', 'PROCEDURE']),
  productId: z.number().int().nullish(),
  procedureId: z.number().int().nullish(),
  quantity: z.number().int(),
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
})

export const MyReviewModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  patientId: z.number().int(),
  picture: z.string(),
  titleTr: jsonSchema,
  subtitleTr: jsonSchema,
  orderItemId: z.number().int(),
  orderItemType: z.enum(['PRODUCT', 'PROCEDURE']),
  productId: z.number().int().nullish(),
  procedureId: z.number().int().nullish(),
  quantity: z.number().int(),
  drugType: z.enum(['HOUSEHOLD', 'DANGEROUS', 'SPECIAL_CONTROLLED']).nullish(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
})

export interface CompleteMyReview extends z.infer<typeof MyReviewModel> {
  patient: CompletePatient
}

/**
 * RelatedMyReviewModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMyReviewModel: z.ZodSchema<CompleteMyReview> = z.lazy(() => MyReviewModel.extend({
  patient: RelatedPatientModel,
}))
