import { z } from 'zod'
import { StringWithAggregatesFilterObjectSchema } from './StringWithAggregatesFilter.schema'
import { JsonWithAggregatesFilterObjectSchema } from './JsonWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ConfigurationScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => ConfigurationScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ConfigurationScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ConfigurationScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => ConfigurationScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    key: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    value: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
  })
  .strict()

export const ConfigurationScalarWhereWithAggregatesInputObjectSchema = Schema
