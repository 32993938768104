import { z } from 'zod'
import { ProductVideoCreateInputObjectSchema } from './objects/ProductVideoCreateInput.schema'
import { ProductVideoUncheckedCreateInputObjectSchema } from './objects/ProductVideoUncheckedCreateInput.schema'

export const ProductVideoCreateOneSchema = z.object({
  data: z.union([
    ProductVideoCreateInputObjectSchema,
    ProductVideoUncheckedCreateInputObjectSchema,
  ]),
})
