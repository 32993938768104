import { z } from 'zod'
import { OtpBlacklistOrderByWithRelationInputObjectSchema } from './objects/OtpBlacklistOrderByWithRelationInput.schema'
import { OtpBlacklistWhereInputObjectSchema } from './objects/OtpBlacklistWhereInput.schema'
import { OtpBlacklistWhereUniqueInputObjectSchema } from './objects/OtpBlacklistWhereUniqueInput.schema'
import { OtpBlacklistCountAggregateInputObjectSchema } from './objects/OtpBlacklistCountAggregateInput.schema'
import { OtpBlacklistMinAggregateInputObjectSchema } from './objects/OtpBlacklistMinAggregateInput.schema'
import { OtpBlacklistMaxAggregateInputObjectSchema } from './objects/OtpBlacklistMaxAggregateInput.schema'
import { OtpBlacklistAvgAggregateInputObjectSchema } from './objects/OtpBlacklistAvgAggregateInput.schema'
import { OtpBlacklistSumAggregateInputObjectSchema } from './objects/OtpBlacklistSumAggregateInput.schema'

export const OtpBlacklistAggregateSchema = z.object({
  orderBy: z
    .union([
      OtpBlacklistOrderByWithRelationInputObjectSchema,
      OtpBlacklistOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OtpBlacklistWhereInputObjectSchema.optional(),
  cursor: OtpBlacklistWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), OtpBlacklistCountAggregateInputObjectSchema])
    .optional(),
  _min: OtpBlacklistMinAggregateInputObjectSchema.optional(),
  _max: OtpBlacklistMaxAggregateInputObjectSchema.optional(),
  _avg: OtpBlacklistAvgAggregateInputObjectSchema.optional(),
  _sum: OtpBlacklistSumAggregateInputObjectSchema.optional(),
})
