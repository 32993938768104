import { z } from 'zod'
import { DeliveryLogOrderByWithRelationInputObjectSchema } from './objects/DeliveryLogOrderByWithRelationInput.schema'
import { DeliveryLogWhereInputObjectSchema } from './objects/DeliveryLogWhereInput.schema'
import { DeliveryLogWhereUniqueInputObjectSchema } from './objects/DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogCountAggregateInputObjectSchema } from './objects/DeliveryLogCountAggregateInput.schema'
import { DeliveryLogMinAggregateInputObjectSchema } from './objects/DeliveryLogMinAggregateInput.schema'
import { DeliveryLogMaxAggregateInputObjectSchema } from './objects/DeliveryLogMaxAggregateInput.schema'
import { DeliveryLogAvgAggregateInputObjectSchema } from './objects/DeliveryLogAvgAggregateInput.schema'
import { DeliveryLogSumAggregateInputObjectSchema } from './objects/DeliveryLogSumAggregateInput.schema'

export const DeliveryLogAggregateSchema = z.object({
  orderBy: z
    .union([
      DeliveryLogOrderByWithRelationInputObjectSchema,
      DeliveryLogOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DeliveryLogWhereInputObjectSchema.optional(),
  cursor: DeliveryLogWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), DeliveryLogCountAggregateInputObjectSchema])
    .optional(),
  _min: DeliveryLogMinAggregateInputObjectSchema.optional(),
  _max: DeliveryLogMaxAggregateInputObjectSchema.optional(),
  _avg: DeliveryLogAvgAggregateInputObjectSchema.optional(),
  _sum: DeliveryLogSumAggregateInputObjectSchema.optional(),
})
