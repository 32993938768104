import { z } from 'zod'

export const CarouselItemScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'active',
  'priority',
  'carouselId',
  'procedureId',
])
