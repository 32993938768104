import { z } from 'zod'
import { ProductPictureUpdateInputObjectSchema } from './objects/ProductPictureUpdateInput.schema'
import { ProductPictureUncheckedUpdateInputObjectSchema } from './objects/ProductPictureUncheckedUpdateInput.schema'
import { ProductPictureWhereUniqueInputObjectSchema } from './objects/ProductPictureWhereUniqueInput.schema'

export const ProductPictureUpdateOneSchema = z.object({
  data: z.union([
    ProductPictureUpdateInputObjectSchema,
    ProductPictureUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductPictureWhereUniqueInputObjectSchema,
})
