import { UseFrequencySchema } from '../generated/trpc/schemas'

export enum SkinType {
  ALL = 'ALL',
  COMBINATION = 'COMBINATION',
  SENSITIVE = 'SENSITIVE',
  OILY = 'OILY',
  NORMAL = 'NORMAL',
  DRY = 'DRY',
  NA = 'NA',
}

export enum ProductForm {
  MASK = 'MASK',
  POWDER = 'POWDER',
  TONER = 'TONER',
  SERUM = 'SERUM',
  ESSENCES = 'ESSENCES',
  LOTION = 'LOTION',
  SOLUTION = 'SOLUTION',
  EMULSION = 'EMULSION',
  SUSPENSION = 'SUSPENSION',
  OINTMENT = 'OINTMENT',
  CREAM = 'CREAM',
  PASTE = 'PASTE',
  GEL = 'GEL',
  GEL_CREAM = 'GEL_CREAM',
  TAB_TABLET = 'TAB_TABLET',
  CAP_CAPSULE = 'CAP_CAPSULE',
  SYRUP = 'SYRUP',
  SPRAY = 'SPRAY',
  WATER_PROOF = 'WATER_PROOF',
  LIGHT_TEXTURE = 'LIGHT_TEXTURE',
  DUST = 'DUST',
  WATER = 'WATER',
  FOAM = 'FOAM',
  WHIPPED_FOAM = 'WHIPPED_FOAM',
  OIL = 'OIL',
  NA = 'NA',
  SHAMPOO = 'SHAMPOO',
  PATCH = 'PATCH',
}

export enum ProductConcern {
  BRIGHTENING = 'BRIGHTENING',
  MOISTURIZING = 'MOISTURIZING',
  ANTI_AGING = 'ANTI_AGING',
  SKIN_CLEANSING = 'SKIN_CLEANSING',
  SOOTHING = 'SOOTHING',
  PORE_CARE = 'PORE_CARE',
  OILINESS_CONTROL = 'OILINESS_CONTROL',
  ACNE_CARE = 'ACNE_CARE',
  SKIN_REJUVENATION = 'SKIN_REJUVENATION',
  HYPER_PIGMENTATION = 'HYPER_PIGMENTATION',
  SKIN_FIRM = 'SKIN_FIRM',
  SKIN_CARE = 'SKIN_CARE',
  UV_PROTECTION = 'UV_PROTECTION',
  OTHER = 'OTHER',
}

export enum ProductAttribute {
  ALCOHOL_FREE = 'ALCOHOL_FREE',
  PARABEN_FREE = 'PARABEN_FREE',
  SILICONE_FREE = 'SILICONE_FREE',
  SULFATE_FREE = 'SULFATE_FREE',
  ALLERGEN_FREE = 'ALLERGEN_FREE',
  CRUELTY_FREE = 'CRUELTY_FREE',
  FRAGRANCE_FREE = 'FRAGRANCE_FREE',
  VEGAN = 'VEGAN',
  PREGNANCY_FRIENDLY = 'PREGNANCY_FRIENDLY',
  NON_COMEDOGENIC = 'NON_COMEDOGENIC',
  OIL_FREE = 'OIL_FREE',
  FUNGAL_ACNE_SAFE = 'FUNGAL_ACNE_SAFE',
  ECZEMA_SAFE = 'ECZEMA_SAFE',
  CORALS_SAFE = 'CORALS_SAFE',
  NA = 'NA',
}

export const getUseTimeChoicesByUseFrequency = (
  useFrequency: keyof typeof UseFrequencySchema.Enum,
) => {
  if (!useFrequency) return []

  let useTimeChoice = [] as string[]

  switch (useFrequency) {
    case 'ONCE_DAILY':
      useTimeChoice = ['MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME']
      break
    case 'ONCE_DAILY_AFTER_MEAL':
      useTimeChoice = ['MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME']
      break
    case 'ONCE_DAILY_BEFORE_MEAL':
      useTimeChoice = ['MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME']
      break
    case 'ONCE_DAILY_WITH_MEAL':
      useTimeChoice = ['MORNING', 'NOON', 'EVENING', 'BEFORE_BEDTIME']
      break
    case 'TWICE_DAILY':
      useTimeChoice = [
        'MORNING_AND_EVENING',
        'MORNING_AND_BEFORE_BEDTIME',
        'NOON_AND_BEFORE_BEDTIME',
      ]
      break
    case 'TWICE_DAILY_AFTER_MEAL':
      useTimeChoice = [
        'MORNING_AND_EVENING',
        'MORNING_AND_BEFORE_BEDTIME',
        'NOON_AND_BEFORE_BEDTIME',
      ]
      break
    case 'TWICE_DAILY_BEFORE_MEAL':
      useTimeChoice = [
        'MORNING_AND_EVENING',
        'MORNING_AND_BEFORE_BEDTIME',
        'NOON_AND_BEFORE_BEDTIME',
      ]
      break
    case 'TWICE_DAILY_WITH_MEAL':
      useTimeChoice = [
        'MORNING_AND_EVENING',
        'MORNING_AND_BEFORE_BEDTIME',
        'NOON_AND_BEFORE_BEDTIME',
      ]
      break
    case 'THRICE_DAILY':
      useTimeChoice = ['MORNING_NOON_AND_EVENING']
      break
    case 'THRICE_DAILY_AFTER_MEAL':
      useTimeChoice = ['MORNING_NOON_AND_EVENING']
      break
    case 'THRICE_DAILY_BEFORE_MEAL':
      useTimeChoice = ['MORNING_NOON_AND_EVENING']
      break
    case 'THRICE_DAILY_WITH_MEAL':
      useTimeChoice = ['MORNING_NOON_AND_EVENING']
      break
    case 'FOUR_TIMES_DAILY':
      useTimeChoice = ['MORNING_NOON_EVENING_AND_BEFORE_BEDTIME']
      break
    case 'FOUR_TIMES_DAILY_AFTER_MEAL':
      useTimeChoice = ['MORNING_NOON_EVENING_AND_BEFORE_BEDTIME']
      break
    case 'FOUR_TIMES_DAILY_BEFORE_MEAL':
      useTimeChoice = ['MORNING_NOON_EVENING_AND_BEFORE_BEDTIME']
      break
    case 'FOUR_TIMES_DAILY_WITH_MEAL':
      useTimeChoice = ['MORNING_NOON_EVENING_AND_BEFORE_BEDTIME']
      break
    case 'FIVE_TIMES_DAILY':
      useTimeChoice = ['AT_6_10_14_18_22_HR']
      break
    case 'EVERY_OTHER_DAY':
      useTimeChoice = ['ON_EVEN_DAY', 'ON_ODD_DAY']
      break
    case 'ONCE_A_WEEK':
      useTimeChoice = [
        'EVERY_MONDAY',
        'EVERY_TUESDAY',
        'EVERY_WEDNESDAY',
        'EVERY_THURSDAY',
        'EVERY_FRIDAY',
        'EVERY_SATURDAY',
        'EVERY_SUNDAY',
      ]
      break
    case 'TWICE_A_WEEK':
      useTimeChoice = ['TUESDAY_AND_THURSDAY']
      break
    case 'THRICE_A_WEEK':
      useTimeChoice = ['MONDAY_WEDNESDAY_AND_FRIDAY']
      break
    case 'EVERY_4_TO_6_HR':
      useTimeChoice = []
      break
    case 'EVERY_4_HR':
      useTimeChoice = []
      break
    case 'EVERY_6_HR':
      useTimeChoice = []
      break
    case 'EVERY_8_HR':
      useTimeChoice = []
      break
    case 'EVERY_12_HR':
      useTimeChoice = []
      break
    case 'EVERY_24_HR':
      useTimeChoice = []
      break
    default:
      break
  }

  useTimeChoice.push('AS_NEED')
  useTimeChoice.push('IMMEDIATELY')

  return useTimeChoice
}
