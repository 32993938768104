import { FileValue } from '../../components/types'

export const transformPatient = async (form: {
  picture: FileValue
  birthDate: string | Date
}) => {
  const { picture, birthDate } = form
  const result: { [key: string]: unknown } = form

  if (picture?.uploadPromise) {
    const url = await picture.uploadPromise
    if (url && !Array.isArray(url)) {
      result.picture = {
        ...picture,
        gsPath: url.gsPath,
      }
    } else {
      throw new Error('Upload failed')
    }
  }
  if (birthDate) {
    result.birthDate = new Date(birthDate)
  }

  return result
}
