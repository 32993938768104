import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { CarouselItemUncheckedCreateNestedManyWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedCreateNestedManyWithoutCarouselInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CarouselUncheckedCreateInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    createdBy: z.number().optional(),
    updatedBy: z.number().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    startAt: z.date(),
    endAt: z.date(),
    nameTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    searchField: z.string(),
    items: z
      .lazy(
        () =>
          CarouselItemUncheckedCreateNestedManyWithoutCarouselInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CarouselUncheckedCreateInputObjectSchema = Schema
