import { z } from 'zod'
import { RefillTimeUnitSchema } from '../enums/RefillTimeUnit.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumRefillTimeUnitNullableFilterObjectSchema } from './NestedEnumRefillTimeUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumRefillTimeUnitNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => RefillTimeUnitSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => RefillTimeUnitSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => RefillTimeUnitSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => RefillTimeUnitSchema),
          z.lazy(
            () =>
              NestedEnumRefillTimeUnitNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumRefillTimeUnitNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumRefillTimeUnitNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumRefillTimeUnitNullableWithAggregatesFilterObjectSchema =
  Schema
