import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'
import { EnumDiscountScopeFieldUpdateOperationsInputObjectSchema } from './EnumDiscountScopeFieldUpdateOperationsInput.schema'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { EnumDiscountItemTypeFieldUpdateOperationsInputObjectSchema } from './EnumDiscountItemTypeFieldUpdateOperationsInput.schema'
import { NullableJsonNullValueInputSchema } from '../enums/NullableJsonNullValueInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.DiscountSettingUncheckedUpdateManyWithoutDiscountSettingInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      scope: z
        .union([
          z.lazy(() => DiscountScopeSchema),
          z.lazy(() => EnumDiscountScopeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      itemType: z
        .union([
          z.lazy(() => DiscountItemTypeSchema),
          z.lazy(
            () => EnumDiscountItemTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      items: z
        .union([z.lazy(() => NullableJsonNullValueInputSchema), jsonSchema])
        .optional(),
      settingGroupId: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
    })
    .strict()

export const DiscountSettingUncheckedUpdateManyWithoutDiscountSettingInputObjectSchema =
  Schema
