import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CaseOrderByWithRelationInputObjectSchema } from './CaseOrderByWithRelationInput.schema'
import { CaseSummaryDiagnosisOrderByRelationAggregateInputObjectSchema } from './CaseSummaryDiagnosisOrderByRelationAggregateInput.schema'
import { AppointmentOrderByWithRelationInputObjectSchema } from './AppointmentOrderByWithRelationInput.schema'
import { SuggestedProductItemOrderByRelationAggregateInputObjectSchema } from './SuggestedProductItemOrderByRelationAggregateInput.schema'
import { SuggestedProductItemOriginalOrderByRelationAggregateInputObjectSchema } from './SuggestedProductItemOriginalOrderByRelationAggregateInput.schema'
import { SuggestedProcedureItemOrderByRelationAggregateInputObjectSchema } from './SuggestedProcedureItemOrderByRelationAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    caseId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    presentIllness: z.lazy(() => SortOrderSchema).optional(),
    peNote: z.lazy(() => SortOrderSchema).optional(),
    isTeleMedNotRecommend: z.lazy(() => SortOrderSchema).optional(),
    recommendation: z.lazy(() => SortOrderSchema).optional(),
    doctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorFeeDiscount: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    commissionPrice: z.lazy(() => SortOrderSchema).optional(),
    netDoctorFee: z.lazy(() => SortOrderSchema).optional(),
    doctorNote: z.lazy(() => SortOrderSchema).optional(),
    pharmacistNote: z.lazy(() => SortOrderSchema).optional(),
    followUpAppointmentId: z.lazy(() => SortOrderSchema).optional(),
    recommedNumberOfTreatment: z.lazy(() => SortOrderSchema).optional(),
    additionalSuggestedProcedure: z.lazy(() => SortOrderSchema).optional(),
    editedSuggestedProductItemAt: z.lazy(() => SortOrderSchema).optional(),
    editedSuggestedProductItemBy: z.lazy(() => SortOrderSchema).optional(),
    v2CaseSummaryId: z.lazy(() => SortOrderSchema).optional(),
    caseUrl: z.lazy(() => SortOrderSchema).optional(),
    case: z.lazy(() => CaseOrderByWithRelationInputObjectSchema).optional(),
    caseSummaryDiagnosis: z
      .lazy(() => CaseSummaryDiagnosisOrderByRelationAggregateInputObjectSchema)
      .optional(),
    followUpAppointment: z
      .lazy(() => AppointmentOrderByWithRelationInputObjectSchema)
      .optional(),
    suggestedProductItems: z
      .lazy(() => SuggestedProductItemOrderByRelationAggregateInputObjectSchema)
      .optional(),
    suggestedProductItemsOriginal: z
      .lazy(
        () =>
          SuggestedProductItemOriginalOrderByRelationAggregateInputObjectSchema,
      )
      .optional(),
    suggestedProcedureItems: z
      .lazy(
        () => SuggestedProcedureItemOrderByRelationAggregateInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const CaseSummaryOrderByWithRelationInputObjectSchema = Schema
