import { z } from 'zod'
import { ProductCreateNestedOneWithoutVideoInputObjectSchema } from './ProductCreateNestedOneWithoutVideoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoCreateInput> = z
  .object({
    url: z.string(),
    thumbnail: z.string(),
    rank: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    product: z.lazy(() => ProductCreateNestedOneWithoutVideoInputObjectSchema),
  })
  .strict()

export const ProductVideoCreateInputObjectSchema = Schema
