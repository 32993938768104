import { z } from 'zod'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutWarehouseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateOrConnectWithoutWarehouseInput> =
  z
    .object({
      where: z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema),
        z.lazy(
          () =>
            VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema =
  Schema
