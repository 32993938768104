import { z } from 'zod'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumOrderItemTypeFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => OrderItemTypeSchema).optional(),
  })
  .strict()

export const EnumOrderItemTypeFieldUpdateOperationsInputObjectSchema = Schema
