import { z } from 'zod'
import { CarouselCountOutputTypeSelectObjectSchema } from './CarouselCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => CarouselCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const CarouselCountOutputTypeArgsObjectSchema = Schema
