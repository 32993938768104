import { z } from 'zod'
import { ExpressShippingAreaOrderByWithRelationInputObjectSchema } from './objects/ExpressShippingAreaOrderByWithRelationInput.schema'
import { ExpressShippingAreaWhereInputObjectSchema } from './objects/ExpressShippingAreaWhereInput.schema'
import { ExpressShippingAreaWhereUniqueInputObjectSchema } from './objects/ExpressShippingAreaWhereUniqueInput.schema'
import { ExpressShippingAreaCountAggregateInputObjectSchema } from './objects/ExpressShippingAreaCountAggregateInput.schema'
import { ExpressShippingAreaMinAggregateInputObjectSchema } from './objects/ExpressShippingAreaMinAggregateInput.schema'
import { ExpressShippingAreaMaxAggregateInputObjectSchema } from './objects/ExpressShippingAreaMaxAggregateInput.schema'
import { ExpressShippingAreaAvgAggregateInputObjectSchema } from './objects/ExpressShippingAreaAvgAggregateInput.schema'
import { ExpressShippingAreaSumAggregateInputObjectSchema } from './objects/ExpressShippingAreaSumAggregateInput.schema'

export const ExpressShippingAreaAggregateSchema = z.object({
  orderBy: z
    .union([
      ExpressShippingAreaOrderByWithRelationInputObjectSchema,
      ExpressShippingAreaOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ExpressShippingAreaWhereInputObjectSchema.optional(),
  cursor: ExpressShippingAreaWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      ExpressShippingAreaCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: ExpressShippingAreaMinAggregateInputObjectSchema.optional(),
  _max: ExpressShippingAreaMaxAggregateInputObjectSchema.optional(),
  _avg: ExpressShippingAreaAvgAggregateInputObjectSchema.optional(),
  _sum: ExpressShippingAreaSumAggregateInputObjectSchema.optional(),
})
