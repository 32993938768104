import { z } from 'zod'
import { OtpWhereUniqueInputObjectSchema } from './objects/OtpWhereUniqueInput.schema'
import { OtpCreateInputObjectSchema } from './objects/OtpCreateInput.schema'
import { OtpUncheckedCreateInputObjectSchema } from './objects/OtpUncheckedCreateInput.schema'
import { OtpUpdateInputObjectSchema } from './objects/OtpUpdateInput.schema'
import { OtpUncheckedUpdateInputObjectSchema } from './objects/OtpUncheckedUpdateInput.schema'

export const OtpUpsertSchema = z.object({
  where: OtpWhereUniqueInputObjectSchema,
  create: z.union([
    OtpCreateInputObjectSchema,
    OtpUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    OtpUpdateInputObjectSchema,
    OtpUncheckedUpdateInputObjectSchema,
  ]),
})
