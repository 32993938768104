import { z } from 'zod'
import { RefundOrderByWithRelationInputObjectSchema } from './objects/RefundOrderByWithRelationInput.schema'
import { RefundWhereInputObjectSchema } from './objects/RefundWhereInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './objects/RefundWhereUniqueInput.schema'
import { RefundCountAggregateInputObjectSchema } from './objects/RefundCountAggregateInput.schema'
import { RefundMinAggregateInputObjectSchema } from './objects/RefundMinAggregateInput.schema'
import { RefundMaxAggregateInputObjectSchema } from './objects/RefundMaxAggregateInput.schema'
import { RefundAvgAggregateInputObjectSchema } from './objects/RefundAvgAggregateInput.schema'
import { RefundSumAggregateInputObjectSchema } from './objects/RefundSumAggregateInput.schema'

export const RefundAggregateSchema = z.object({
  orderBy: z
    .union([
      RefundOrderByWithRelationInputObjectSchema,
      RefundOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: RefundWhereInputObjectSchema.optional(),
  cursor: RefundWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), RefundCountAggregateInputObjectSchema])
    .optional(),
  _min: RefundMinAggregateInputObjectSchema.optional(),
  _max: RefundMaxAggregateInputObjectSchema.optional(),
  _avg: RefundAvgAggregateInputObjectSchema.optional(),
  _sum: RefundSumAggregateInputObjectSchema.optional(),
})
