import { z } from 'zod'
import { ShippingProviderWhereUniqueInputObjectSchema } from './ShippingProviderWhereUniqueInput.schema'
import { ShippingProviderCreateWithoutOrdersInputObjectSchema } from './ShippingProviderCreateWithoutOrdersInput.schema'
import { ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutOrdersInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCreateOrConnectWithoutOrdersInput> =
  z
    .object({
      where: z.lazy(() => ShippingProviderWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ShippingProviderCreateWithoutOrdersInputObjectSchema),
        z.lazy(
          () => ShippingProviderUncheckedCreateWithoutOrdersInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ShippingProviderCreateOrConnectWithoutOrdersInputObjectSchema =
  Schema
