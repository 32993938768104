import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCreateWithoutCaseSummaryDiagnosisInput> =
  z
    .object({
      codePlain: z.string(),
      term: z.string(),
    })
    .strict()

export const DiagnosisCreateWithoutCaseSummaryDiagnosisInputObjectSchema =
  Schema
