import * as z from "zod"

export const ProcedureBannerModel = z.object({
  id: z.number().int(),
  name: z.string(),
  picture: z.string(),
  actionUri: z.string().nullish(),
  active: z.boolean(),
  rank: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})
