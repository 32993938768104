import { z } from 'zod'
import { AddressCreateWithoutPatientInputObjectSchema } from './AddressCreateWithoutPatientInput.schema'
import { AddressUncheckedCreateWithoutPatientInputObjectSchema } from './AddressUncheckedCreateWithoutPatientInput.schema'
import { AddressCreateOrConnectWithoutPatientInputObjectSchema } from './AddressCreateOrConnectWithoutPatientInput.schema'
import { AddressCreateManyPatientInputEnvelopeObjectSchema } from './AddressCreateManyPatientInputEnvelope.schema'
import { AddressWhereUniqueInputObjectSchema } from './AddressWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressCreateNestedManyWithoutPatientInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => AddressCreateWithoutPatientInputObjectSchema),
        z.lazy(() => AddressCreateWithoutPatientInputObjectSchema).array(),
        z.lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema),
        z
          .lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => AddressCreateOrConnectWithoutPatientInputObjectSchema),
        z
          .lazy(() => AddressCreateOrConnectWithoutPatientInputObjectSchema)
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => AddressCreateManyPatientInputEnvelopeObjectSchema)
      .optional(),
    connect: z
      .union([
        z.lazy(() => AddressWhereUniqueInputObjectSchema),
        z.lazy(() => AddressWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const AddressCreateNestedManyWithoutPatientInputObjectSchema = Schema
