import { z } from 'zod'
import { AppointmentUpdateWithoutScheduleInputObjectSchema } from './AppointmentUpdateWithoutScheduleInput.schema'
import { AppointmentUncheckedUpdateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedUpdateWithoutScheduleInput.schema'
import { AppointmentCreateWithoutScheduleInputObjectSchema } from './AppointmentCreateWithoutScheduleInput.schema'
import { AppointmentUncheckedCreateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedCreateWithoutScheduleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentUpsertWithoutScheduleInput> = z
  .object({
    update: z.union([
      z.lazy(() => AppointmentUpdateWithoutScheduleInputObjectSchema),
      z.lazy(() => AppointmentUncheckedUpdateWithoutScheduleInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => AppointmentCreateWithoutScheduleInputObjectSchema),
      z.lazy(() => AppointmentUncheckedCreateWithoutScheduleInputObjectSchema),
    ]),
  })
  .strict()

export const AppointmentUpsertWithoutScheduleInputObjectSchema = Schema
