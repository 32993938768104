import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    patientId: z.boolean().optional(),
    picture: z.boolean().optional(),
    titleTr: z.boolean().optional(),
    subtitleTr: z.boolean().optional(),
    orderItemId: z.boolean().optional(),
    orderItemType: z.boolean().optional(),
    productId: z.boolean().optional(),
    procedureId: z.boolean().optional(),
    quantity: z.boolean().optional(),
    drugType: z.boolean().optional(),
    reviewId: z.boolean().optional(),
    reviewStartAt: z.boolean().optional(),
    reviewExpireAt: z.boolean().optional(),
  })
  .strict()

export const MyReviewSelectObjectSchema = Schema
