import { z } from 'zod'
import { PaymentWhereUniqueInputObjectSchema } from './PaymentWhereUniqueInput.schema'
import { PaymentUpdateWithoutOrderInputObjectSchema } from './PaymentUpdateWithoutOrderInput.schema'
import { PaymentUncheckedUpdateWithoutOrderInputObjectSchema } from './PaymentUncheckedUpdateWithoutOrderInput.schema'
import { PaymentCreateWithoutOrderInputObjectSchema } from './PaymentCreateWithoutOrderInput.schema'
import { PaymentUncheckedCreateWithoutOrderInputObjectSchema } from './PaymentUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentUpsertWithWhereUniqueWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => PaymentWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => PaymentUpdateWithoutOrderInputObjectSchema),
        z.lazy(() => PaymentUncheckedUpdateWithoutOrderInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => PaymentCreateWithoutOrderInputObjectSchema),
        z.lazy(() => PaymentUncheckedCreateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const PaymentUpsertWithWhereUniqueWithoutOrderInputObjectSchema = Schema
