import { z } from 'zod'
import { DeliveryCreateWithoutOrderInputObjectSchema } from './DeliveryCreateWithoutOrderInput.schema'
import { DeliveryUncheckedCreateWithoutOrderInputObjectSchema } from './DeliveryUncheckedCreateWithoutOrderInput.schema'
import { DeliveryCreateOrConnectWithoutOrderInputObjectSchema } from './DeliveryCreateOrConnectWithoutOrderInput.schema'
import { DeliveryCreateManyOrderInputEnvelopeObjectSchema } from './DeliveryCreateManyOrderInputEnvelope.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUncheckedCreateNestedManyWithoutOrderInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema),
          z.lazy(() => DeliveryCreateWithoutOrderInputObjectSchema).array(),
          z.lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema),
          z
            .lazy(() => DeliveryUncheckedCreateWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => DeliveryCreateOrConnectWithoutOrderInputObjectSchema),
          z
            .lazy(() => DeliveryCreateOrConnectWithoutOrderInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => DeliveryCreateManyOrderInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const DeliveryUncheckedCreateNestedManyWithoutOrderInputObjectSchema =
  Schema
