import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureCreateWithoutPartnerInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    rank: z.number().optional(),
    url: z.string(),
  })
  .strict()

export const CoverPictureCreateWithoutPartnerInputObjectSchema = Schema
