import { z } from 'zod'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderRelationFilter> = z
  .object({
    is: z.lazy(() => OrderWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => OrderWhereInputObjectSchema).optional(),
  })
  .strict()

export const OrderRelationFilterObjectSchema = Schema
