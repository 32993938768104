import { z } from 'zod'
import { ProcedureReviewCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutPicturesInput.schema'
import { ProcedureReviewCreateOrConnectWithoutPicturesInputObjectSchema } from './ProcedureReviewCreateOrConnectWithoutPicturesInput.schema'
import { ProcedureReviewUpsertWithoutPicturesInputObjectSchema } from './ProcedureReviewUpsertWithoutPicturesInput.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewUpdateWithoutPicturesInputObjectSchema } from './ProcedureReviewUpdateWithoutPicturesInput.schema'
import { ProcedureReviewUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProcedureReviewUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureReviewCreateWithoutPicturesInputObjectSchema),
          z.lazy(
            () =>
              ProcedureReviewUncheckedCreateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProcedureReviewCreateOrConnectWithoutPicturesInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProcedureReviewUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z
        .lazy(() => ProcedureReviewWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(() => ProcedureReviewUpdateWithoutPicturesInputObjectSchema),
          z.lazy(
            () =>
              ProcedureReviewUncheckedUpdateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
