import { z } from 'zod'
import { PartnerCreateNestedOneWithoutCoverPicturesInputObjectSchema } from './PartnerCreateNestedOneWithoutCoverPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    rank: z.number().optional(),
    url: z.string(),
    partner: z.lazy(
      () => PartnerCreateNestedOneWithoutCoverPicturesInputObjectSchema,
    ),
  })
  .strict()

export const CoverPictureCreateInputObjectSchema = Schema
