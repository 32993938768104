import { z } from 'zod'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseCreateWithoutTeleconsultInputObjectSchema } from './CaseCreateWithoutTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCreateOrConnectWithoutTeleconsultInput> = z
  .object({
    where: z.lazy(() => CaseWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CaseCreateWithoutTeleconsultInputObjectSchema),
      z.lazy(() => CaseUncheckedCreateWithoutTeleconsultInputObjectSchema),
    ]),
  })
  .strict()

export const CaseCreateOrConnectWithoutTeleconsultInputObjectSchema = Schema
