import { z } from 'zod'
import { OfficeHourWhereInputObjectSchema } from './objects/OfficeHourWhereInput.schema'
import { OfficeHourOrderByWithAggregationInputObjectSchema } from './objects/OfficeHourOrderByWithAggregationInput.schema'
import { OfficeHourScalarWhereWithAggregatesInputObjectSchema } from './objects/OfficeHourScalarWhereWithAggregatesInput.schema'
import { OfficeHourScalarFieldEnumSchema } from './enums/OfficeHourScalarFieldEnum.schema'

export const OfficeHourGroupBySchema = z.object({
  where: OfficeHourWhereInputObjectSchema.optional(),
  orderBy: z.union([
    OfficeHourOrderByWithAggregationInputObjectSchema,
    OfficeHourOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: OfficeHourScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(OfficeHourScalarFieldEnumSchema),
})
