import { z } from 'zod'
import { ProductBannerWhereInputObjectSchema } from './objects/ProductBannerWhereInput.schema'
import { ProductBannerOrderByWithAggregationInputObjectSchema } from './objects/ProductBannerOrderByWithAggregationInput.schema'
import { ProductBannerScalarWhereWithAggregatesInputObjectSchema } from './objects/ProductBannerScalarWhereWithAggregatesInput.schema'
import { ProductBannerScalarFieldEnumSchema } from './enums/ProductBannerScalarFieldEnum.schema'

export const ProductBannerGroupBySchema = z.object({
  where: ProductBannerWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProductBannerOrderByWithAggregationInputObjectSchema,
    ProductBannerOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProductBannerScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProductBannerScalarFieldEnumSchema),
})
