import { z } from 'zod'
import { ProductCreateWithoutVideoInputObjectSchema } from './ProductCreateWithoutVideoInput.schema'
import { ProductUncheckedCreateWithoutVideoInputObjectSchema } from './ProductUncheckedCreateWithoutVideoInput.schema'
import { ProductCreateOrConnectWithoutVideoInputObjectSchema } from './ProductCreateOrConnectWithoutVideoInput.schema'
import { ProductUpsertWithoutVideoInputObjectSchema } from './ProductUpsertWithoutVideoInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'
import { ProductUpdateWithoutVideoInputObjectSchema } from './ProductUpdateWithoutVideoInput.schema'
import { ProductUncheckedUpdateWithoutVideoInputObjectSchema } from './ProductUncheckedUpdateWithoutVideoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductUpdateOneRequiredWithoutVideoNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutVideoInputObjectSchema),
          z.lazy(() => ProductUncheckedCreateWithoutVideoInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProductCreateOrConnectWithoutVideoInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProductUpsertWithoutVideoInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProductUpdateWithoutVideoInputObjectSchema),
          z.lazy(() => ProductUncheckedUpdateWithoutVideoInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const ProductUpdateOneRequiredWithoutVideoNestedInputObjectSchema =
  Schema
