import { z } from 'zod'
import { ProcedureCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateWithoutCarouselItemsInput.schema'
import { ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema } from './ProcedureUncheckedCreateWithoutCarouselItemsInput.schema'
import { ProcedureCreateOrConnectWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateOrConnectWithoutCarouselItemsInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateNestedOneWithoutCarouselItemsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutCarouselItemsInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutCarouselItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProcedureCreateOrConnectWithoutCarouselItemsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProcedureCreateNestedOneWithoutCarouselItemsInputObjectSchema =
  Schema
