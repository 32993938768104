import { z } from 'zod'
import { VariantCreateWithoutVariantWarehousesInputObjectSchema } from './VariantCreateWithoutVariantWarehousesInput.schema'
import { VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './VariantUncheckedCreateWithoutVariantWarehousesInput.schema'
import { VariantCreateOrConnectWithoutVariantWarehousesInputObjectSchema } from './VariantCreateOrConnectWithoutVariantWarehousesInput.schema'
import { VariantUpsertWithoutVariantWarehousesInputObjectSchema } from './VariantUpsertWithoutVariantWarehousesInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantUpdateWithoutVariantWarehousesInputObjectSchema } from './VariantUpdateWithoutVariantWarehousesInput.schema'
import { VariantUncheckedUpdateWithoutVariantWarehousesInputObjectSchema } from './VariantUncheckedUpdateWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpdateOneRequiredWithoutVariantWarehousesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantCreateWithoutVariantWarehousesInputObjectSchema),
          z.lazy(
            () =>
              VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => VariantCreateOrConnectWithoutVariantWarehousesInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => VariantUpsertWithoutVariantWarehousesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => VariantUpdateWithoutVariantWarehousesInputObjectSchema),
          z.lazy(
            () =>
              VariantUncheckedUpdateWithoutVariantWarehousesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const VariantUpdateOneRequiredWithoutVariantWarehousesNestedInputObjectSchema =
  Schema
