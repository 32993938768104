import { z } from 'zod'
import { ConsentCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedCreateWithoutAcceptedConsentsInput.schema'
import { ConsentCreateOrConnectWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateOrConnectWithoutAcceptedConsentsInput.schema'
import { ConsentWhereUniqueInputObjectSchema } from './ConsentWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCreateNestedOneWithoutAcceptedConsentsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ConsentCreateWithoutAcceptedConsentsInputObjectSchema),
          z.lazy(
            () =>
              ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ConsentCreateOrConnectWithoutAcceptedConsentsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ConsentWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ConsentCreateNestedOneWithoutAcceptedConsentsInputObjectSchema =
  Schema
