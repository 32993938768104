import { z } from 'zod'
import { ReceiptWhereUniqueInputObjectSchema } from './ReceiptWhereUniqueInput.schema'
import { ReceiptCreateWithoutOrderInputObjectSchema } from './ReceiptCreateWithoutOrderInput.schema'
import { ReceiptUncheckedCreateWithoutOrderInputObjectSchema } from './ReceiptUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptCreateOrConnectWithoutOrderInput> = z
  .object({
    where: z.lazy(() => ReceiptWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => ReceiptCreateWithoutOrderInputObjectSchema),
      z.lazy(() => ReceiptUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const ReceiptCreateOrConnectWithoutOrderInputObjectSchema = Schema
