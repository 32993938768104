import { z } from 'zod'
import { DoctorItemUpdateWithoutOrderInputObjectSchema } from './DoctorItemUpdateWithoutOrderInput.schema'
import { DoctorItemUncheckedUpdateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedUpdateWithoutOrderInput.schema'
import { DoctorItemCreateWithoutOrderInputObjectSchema } from './DoctorItemCreateWithoutOrderInput.schema'
import { DoctorItemUncheckedCreateWithoutOrderInputObjectSchema } from './DoctorItemUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DoctorItemUpsertWithoutOrderInput> = z
  .object({
    update: z.union([
      z.lazy(() => DoctorItemUpdateWithoutOrderInputObjectSchema),
      z.lazy(() => DoctorItemUncheckedUpdateWithoutOrderInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => DoctorItemCreateWithoutOrderInputObjectSchema),
      z.lazy(() => DoctorItemUncheckedCreateWithoutOrderInputObjectSchema),
    ]),
  })
  .strict()

export const DoctorItemUpsertWithoutOrderInputObjectSchema = Schema
