import { z } from 'zod'

export const ConditionItemTypeSchema = z.enum([
  'DOCTOR',
  'DOCTOR_CATEGORY',
  'VARIANT',
  'VARIANT_FLASHSALE',
  'VARIANT_NO_FLASHSALE',
  'VARIANT_BRAND',
  'VARIANT_CATEGORY',
  'PROCEDURE',
  'PROCEDURE_FLASHSALE',
  'PROCEDURE_NO_FLASHSALE',
  'PROCEDURE_PARTNER',
  'PROCEDURE_CATEGORY',
  'SHIPPING_TYPE',
  'SHIPPING_PROVIDER',
])
