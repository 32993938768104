import { Box } from '@mui/material'

export const WebsiteField = (website: string) => {
  if (!website) {
    return <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>-</Box>
  }

  return (
    <a
      style={{ wordBreak: 'break-all' }}
      href={website}
      target="_blank"
      rel="noreferrer"
    >
      {website}
    </a>
  )
}
