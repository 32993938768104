import { z } from 'zod'
import { CategoryWhereUniqueInputObjectSchema } from './CategoryWhereUniqueInput.schema'
import { CategoryCreateWithoutChildrenInputObjectSchema } from './CategoryCreateWithoutChildrenInput.schema'
import { CategoryUncheckedCreateWithoutChildrenInputObjectSchema } from './CategoryUncheckedCreateWithoutChildrenInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CategoryCreateOrConnectWithoutChildrenInput> = z
  .object({
    where: z.lazy(() => CategoryWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => CategoryCreateWithoutChildrenInputObjectSchema),
      z.lazy(() => CategoryUncheckedCreateWithoutChildrenInputObjectSchema),
    ]),
  })
  .strict()

export const CategoryCreateOrConnectWithoutChildrenInputObjectSchema = Schema
