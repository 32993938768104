import { z } from 'zod'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { NestedEnumOrderStatusWithAggregatesFilterObjectSchema } from './NestedEnumOrderStatusWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumOrderStatusFilterObjectSchema } from './NestedEnumOrderStatusFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumOrderStatusWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => OrderStatusSchema).optional(),
    in: z
      .lazy(() => OrderStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => OrderStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => OrderStatusSchema),
        z.lazy(() => NestedEnumOrderStatusWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumOrderStatusFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumOrderStatusFilterObjectSchema).optional(),
  })
  .strict()

export const EnumOrderStatusWithAggregatesFilterObjectSchema = Schema
