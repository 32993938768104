import { z } from 'zod'
import { ProcedureCreateNestedOneWithoutFlashsaleItemInputObjectSchema } from './ProcedureCreateNestedOneWithoutFlashsaleItemInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateWithoutFlashsaleInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    startAt: z.date(),
    endAt: z.date(),
    price: z.number(),
    priceAbsorbedBySkinX: z.number().optional(),
    priceAbsorbedByPartner: z.number().optional(),
    saleLimit: z.number(),
    sold: z.number(),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutFlashsaleItemInputObjectSchema,
    ),
  })
  .strict()

export const FlashsaleItemCreateWithoutFlashsaleInputObjectSchema = Schema
