import { z } from 'zod'
import { AddressTagSchema } from '../enums/AddressTag.schema'
import { NestedEnumAddressTagNullableFilterObjectSchema } from './NestedEnumAddressTagNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.EnumAddressTagNullableFilter> = z
  .object({
    equals: z
      .lazy(() => AddressTagSchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => AddressTagSchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => AddressTagSchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => AddressTagSchema),
        z.lazy(() => NestedEnumAddressTagNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const EnumAddressTagNullableFilterObjectSchema = Schema
