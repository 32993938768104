import { z } from 'zod'
import { HolidayUpdateInputObjectSchema } from './objects/HolidayUpdateInput.schema'
import { HolidayUncheckedUpdateInputObjectSchema } from './objects/HolidayUncheckedUpdateInput.schema'
import { HolidayWhereUniqueInputObjectSchema } from './objects/HolidayWhereUniqueInput.schema'

export const HolidayUpdateOneSchema = z.object({
  data: z.union([
    HolidayUpdateInputObjectSchema,
    HolidayUncheckedUpdateInputObjectSchema,
  ]),
  where: HolidayWhereUniqueInputObjectSchema,
})
