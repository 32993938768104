import { z } from 'zod'
import { AppointmentWhereInputObjectSchema } from './AppointmentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentRelationFilter> = z
  .object({
    is: z
      .lazy(() => AppointmentWhereInputObjectSchema)
      .optional()
      .nullable(),
    isNot: z
      .lazy(() => AppointmentWhereInputObjectSchema)
      .optional()
      .nullable(),
  })
  .strict()

export const AppointmentRelationFilterObjectSchema = Schema
