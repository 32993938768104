import { z } from 'zod'
import { ProductCreateNestedOneWithoutCategoriesInputObjectSchema } from './ProductCreateNestedOneWithoutCategoriesInput.schema'
import { CategoryCreateNestedOneWithoutProductsInputObjectSchema } from './CategoryCreateNestedOneWithoutProductsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateInput> = z
  .object({
    priority: z.number().optional(),
    product: z.lazy(
      () => ProductCreateNestedOneWithoutCategoriesInputObjectSchema,
    ),
    category: z.lazy(
      () => CategoryCreateNestedOneWithoutProductsInputObjectSchema,
    ),
  })
  .strict()

export const ProductCategoryCreateInputObjectSchema = Schema
