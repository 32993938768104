import { z } from 'zod'
import { DiscountScopeSchema } from '../enums/DiscountScope.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.NestedEnumDiscountScopeFilter> = z
  .object({
    equals: z.lazy(() => DiscountScopeSchema).optional(),
    in: z
      .lazy(() => DiscountScopeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountScopeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountScopeSchema),
        z.lazy(() => NestedEnumDiscountScopeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumDiscountScopeFilterObjectSchema = Schema
