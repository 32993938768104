import { z } from 'zod'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchCreateWithoutPartnerInputObjectSchema } from './BranchCreateWithoutPartnerInput.schema'
import { BranchUncheckedCreateWithoutPartnerInputObjectSchema } from './BranchUncheckedCreateWithoutPartnerInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCreateOrConnectWithoutPartnerInput> = z
  .object({
    where: z.lazy(() => BranchWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => BranchCreateWithoutPartnerInputObjectSchema),
      z.lazy(() => BranchUncheckedCreateWithoutPartnerInputObjectSchema),
    ]),
  })
  .strict()

export const BranchCreateOrConnectWithoutPartnerInputObjectSchema = Schema
