import { Typography } from '@mui/material'
import { AppointmentStatusSchema } from '@ssch-backend/teleconsults'
import { FC } from 'react'
import { TextFieldProps, useLocaleState, useRecordContext } from 'react-admin'
import { getAppointmentStatusLabel } from '../../../enumLabel'

export const AppointmentStatusField: FC<TextFieldProps> = (props) => {
  const [locale] = useLocaleState()
  const record = useRecordContext()
  if (!record) return null

  return (
    <Typography {...props} sx={{ fontSize: '14px' }}>
      {
        getAppointmentStatusLabel(
          record.status as keyof typeof AppointmentStatusSchema.Enum,
        )[locale === 'th' ? 'th' : 'en']
      }
    </Typography>
  )
}
