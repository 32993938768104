import { z } from 'zod'
import { OrderCountOutputTypeSelectObjectSchema } from './OrderCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => OrderCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const OrderCountOutputTypeArgsObjectSchema = Schema
