import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductReviewOrderByWithRelationInputObjectSchema } from './ProductReviewOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewPictureOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    productReviewId: z.lazy(() => SortOrderSchema).optional(),
    productReview: z
      .lazy(() => ProductReviewOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductReviewPictureOrderByWithRelationInputObjectSchema = Schema
