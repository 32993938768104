import { z } from 'zod'
import { ProcedureCreateNestedOneWithoutPicturesInputObjectSchema } from './ProcedureCreateNestedOneWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureCreateInput> = z
  .object({
    url: z.string(),
    rank: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutPicturesInputObjectSchema,
    ),
  })
  .strict()

export const ProcedurePictureCreateInputObjectSchema = Schema
