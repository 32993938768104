import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryCreateManyInput> = z
  .object({
    id: z.number().optional(),
    caseId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    presentIllness: z.string().optional().nullable(),
    peNote: z.string().optional().nullable(),
    isTeleMedNotRecommend: z.boolean(),
    recommendation: z.string(),
    doctorFee: z.number(),
    doctorFeeDiscount: z.number(),
    commissionRate: z.number().optional(),
    commissionPrice: z.number().optional(),
    netDoctorFee: z.number().optional(),
    doctorNote: z.string().optional().nullable(),
    pharmacistNote: z.string().optional().nullable(),
    followUpAppointmentId: z.number().optional().nullable(),
    recommedNumberOfTreatment: z.number().optional().nullable(),
    additionalSuggestedProcedure: z.string().optional().nullable(),
    editedSuggestedProductItemAt: z.date().optional().nullable(),
    editedSuggestedProductItemBy: z.number().optional().nullable(),
    v2CaseSummaryId: z.number().optional().nullable(),
    caseUrl: z.string().optional().nullable(),
  })
  .strict()

export const CaseSummaryCreateManyInputObjectSchema = Schema
