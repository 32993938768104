import { z } from 'zod'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumPaymentGatewayNullableFilter> = z
  .object({
    equals: z
      .lazy(() => PaymentGatewaySchema)
      .optional()
      .nullable(),
    in: z
      .lazy(() => PaymentGatewaySchema)
      .array()
      .optional()
      .nullable(),
    notIn: z
      .lazy(() => PaymentGatewaySchema)
      .array()
      .optional()
      .nullable(),
    not: z
      .union([
        z.lazy(() => PaymentGatewaySchema),
        z.lazy(() => NestedEnumPaymentGatewayNullableFilterObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const NestedEnumPaymentGatewayNullableFilterObjectSchema = Schema
