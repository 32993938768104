import { z } from 'zod'
import { CaseSummaryCreateNestedOneWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryCreateNestedOneWithoutSuggestedProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemCreateInput> = z
  .object({
    procedureId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    amount: z.number(),
    note: z.string().optional().nullable(),
    caseSummary: z.lazy(
      () =>
        CaseSummaryCreateNestedOneWithoutSuggestedProcedureItemsInputObjectSchema,
    ),
  })
  .strict()

export const SuggestedProcedureItemCreateInputObjectSchema = Schema
