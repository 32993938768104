import { z } from 'zod'

export const FlashsaleScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'createdBy',
  'updatedBy',
  'name',
  'description',
  'startAt',
  'endAt',
  'active',
])
