import { z } from 'zod'
import { OfficeHourWhereUniqueInputObjectSchema } from './OfficeHourWhereUniqueInput.schema'
import { OfficeHourCreateWithoutBranchInputObjectSchema } from './OfficeHourCreateWithoutBranchInput.schema'
import { OfficeHourUncheckedCreateWithoutBranchInputObjectSchema } from './OfficeHourUncheckedCreateWithoutBranchInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourCreateOrConnectWithoutBranchInput> = z
  .object({
    where: z.lazy(() => OfficeHourWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OfficeHourCreateWithoutBranchInputObjectSchema),
      z.lazy(() => OfficeHourUncheckedCreateWithoutBranchInputObjectSchema),
    ]),
  })
  .strict()

export const OfficeHourCreateOrConnectWithoutBranchInputObjectSchema = Schema
