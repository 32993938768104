import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.FlashsaleItemScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
        z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => FlashsaleItemScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema),
        z.lazy(() => FlashsaleItemScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    flashsaleId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    variantId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    startAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    endAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    price: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    saleLimit: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    sold: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
  })
  .strict()

export const FlashsaleItemScalarWhereInputObjectSchema = Schema
