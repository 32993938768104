import { z } from 'zod'
import { ProcedureItemScalarWhereInputObjectSchema } from './ProcedureItemScalarWhereInput.schema'
import { ProcedureItemUpdateManyMutationInputObjectSchema } from './ProcedureItemUpdateManyMutationInput.schema'
import { ProcedureItemUncheckedUpdateManyWithoutProcedureItemsInputObjectSchema } from './ProcedureItemUncheckedUpdateManyWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemUpdateManyWithWhereWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProcedureItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProcedureItemUncheckedUpdateManyWithoutProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureItemUpdateManyWithWhereWithoutOrderInputObjectSchema =
  Schema
