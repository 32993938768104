import { z } from 'zod'
import { DeliveryCountOutputTypeSelectObjectSchema } from './DeliveryCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => DeliveryCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const DeliveryCountOutputTypeArgsObjectSchema = Schema
