import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import { ExpireTypeSchema } from '@ssch-backend/procedures'
import { Language, getTr } from '@ssch-backend/utils/translation'
import { endOfDay } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import {
  BooleanInput,
  OptionTextFunc,
  required,
  useGetList,
  useLocaleState,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { z } from 'zod'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormAutoCompleteArrayInput,
  FormDateInput,
  FormNumberInput,
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { adminGatewayClient, isTRPCClientError } from '../../../service'

type ExpireType = z.infer<typeof ExpireTypeSchema>
const tr_resource = 'resources.procedure'

export const ProcedureInfoSection: FC = () => {
  const [locale] = useLocaleState()
  const record = useRecordContext()
  const { watch, setValue } = useFormContext()
  const procedureExpireTypeInput = watch('expireType')
  const [expireType, setExpireType] = useState<ExpireType | null>(null)
  const notify = useNotify()
  const translate = useTranslate()

  useEffect(() => {
    if (procedureExpireTypeInput) {
      setExpireType(procedureExpireTypeInput)
      setValue('expireAt', null)
      setValue('expireDurationDay', null)
    }
  }, [setValue, procedureExpireTypeInput])

  const { data: dataCategory, isLoading: loadingCategory } = useGetList(
    'procedureCategory',
    {
      pagination: { page: 1, perPage: 999 },
    },
  )

  const getInputAdornment = (position: 'start' | 'end', symbol: string) => {
    return {
      [`${position}Adornment`]: (
        <InputAdornment position={position}>{symbol}</InputAdornment>
      ),
    }
  }

  const optionTextCategory: OptionTextFunc = (choice) => {
    const parentCategory = dataCategory?.find(
      (element) => element.id === choice.parentId,
    )

    return `${getTr(parentCategory.nameTr, locale as Language)}: ${getTr(
      choice.nameTr,
      locale as Language,
    )}`
  }

  const handleActiveChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const active = e.target.checked
    try {
      await adminGatewayClient.procedure.setActive.mutate({
        id: Number(id),
        active,
      })
      notify(`Procedure is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      setValue('active', !active)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const handleSearchableChanged = async (
    id: number | string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
    const searchable = e.target.checked
    try {
      await adminGatewayClient.procedure.setSearchable.mutate({
        id: Number(id),
        searchable,
      })
      notify(
        `Procedure is set ${
          searchable ? 'able to search' : 'unable to search'
        }`,
        {
          type: searchable ? 'success' : 'warning',
        },
      )
    } catch (e) {
      setValue('searchable', !searchable)
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  const commissionRateValidation = (value: number) => {
    if (value > 100) {
      return {
        message: 'ra.validation.maxValue',
        args: { max: '100%' },
      }
    }
    return undefined
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate([tr_resource, 'title', 'information_section'].join('.'))}
        </Typography>
        {record?.id && (
          <Box display={'flex'}>
            <BooleanInput
              source="searchable"
              onChange={(e) => handleSearchableChanged(record.id, e)}
            />
            <BooleanInput
              source="active"
              onChange={(e) => handleActiveChanged(record.id, e)}
            />
          </Box>
        )}
      </Box>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput source="nameTr.th" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput source="nameTr.en" validate={required()} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <FormAutoCompleteArrayInput
              fullWidth
              filterSelectedOptions={true}
              source="categories"
              choices={dataCategory
                ?.filter((c) => c.parentId && c.active)
                .sort((a, b) => {
                  if (a.parentId !== b.parentId) {
                    return a.parentId - b.parentId
                  }
                  return b.priority - a.priority
                })}
              loading={loadingCategory}
              optionText={optionTextCategory}
              parse={(value) => value && value.map((v: number) => ({ id: v }))}
              format={(value) =>
                value && value.map((v: { id: number }) => v.id)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} md={6}>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              source="tagPrice"
              min={0}
              InputProps={getInputAdornment('start', '฿')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              source="sellingPrice"
              min={0}
              InputProps={getInputAdornment('start', '฿')}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              source="cost"
              min={0}
              InputProps={getInputAdornment('start', '฿')}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              source="commissionRate"
              min={0}
              InputProps={getInputAdornment('end', '%')}
              validate={[required(), commissionRateValidation]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} md={6}>
          <Grid item xs={12} sm={'auto'} lg={6}>
            <FormSelectInput
              source="expireType"
              validate={required()}
              choices={Object.values(ExpireTypeSchema.Enum).map((option) => ({
                id: option,
                name: ['resources.procedure.title.expireType', option].join(
                  '.',
                ),
              }))}
              sx={{ flexWrap: 'wrap' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={expireType ? { width: '100%' } : { display: 'none' }}
          >
            <FormDateInput
              source="expireAt"
              sx={
                expireType === ExpireTypeSchema.Values.DATE
                  ? { width: '100%' }
                  : { display: 'none' }
              }
              parse={(value) => endOfDay(new Date(value))}
            />
            <FormNumberInput
              source="expireDurationDay"
              min={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">day(s)</InputAdornment>
                ),
              }}
              sx={
                expireType === ExpireTypeSchema.Values.DURATION
                  ? { width: '100%' }
                  : { display: 'none' }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormNumberInput
              source="priority"
              defaultValue={0}
              min={0}
              step={1}
              inputProps={{ style: { textAlign: 'center' } }}
              validate={required()}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
