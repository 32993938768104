import { z } from 'zod'
import { MyReviewScalarWhereInputObjectSchema } from './MyReviewScalarWhereInput.schema'
import { MyReviewUpdateManyMutationInputObjectSchema } from './MyReviewUpdateManyMutationInput.schema'
import { MyReviewUncheckedUpdateManyWithoutMyReviewsInputObjectSchema } from './MyReviewUncheckedUpdateManyWithoutMyReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewUpdateManyWithWhereWithoutPatientInput> =
  z
    .object({
      where: z.lazy(() => MyReviewScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => MyReviewUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => MyReviewUncheckedUpdateManyWithoutMyReviewsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const MyReviewUpdateManyWithWhereWithoutPatientInputObjectSchema = Schema
