import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CarouselItemCountOrderByAggregateInputObjectSchema } from './CarouselItemCountOrderByAggregateInput.schema'
import { CarouselItemAvgOrderByAggregateInputObjectSchema } from './CarouselItemAvgOrderByAggregateInput.schema'
import { CarouselItemMaxOrderByAggregateInputObjectSchema } from './CarouselItemMaxOrderByAggregateInput.schema'
import { CarouselItemMinOrderByAggregateInputObjectSchema } from './CarouselItemMinOrderByAggregateInput.schema'
import { CarouselItemSumOrderByAggregateInputObjectSchema } from './CarouselItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    carouselId: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CarouselItemCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CarouselItemAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CarouselItemMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CarouselItemMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CarouselItemSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CarouselItemOrderByWithAggregationInputObjectSchema = Schema
