import { z } from 'zod'
import { NotificationPatientWhereUniqueInputObjectSchema } from './NotificationPatientWhereUniqueInput.schema'
import { NotificationPatientUpdateWithoutNotificationInputObjectSchema } from './NotificationPatientUpdateWithoutNotificationInput.schema'
import { NotificationPatientUncheckedUpdateWithoutNotificationInputObjectSchema } from './NotificationPatientUncheckedUpdateWithoutNotificationInput.schema'
import { NotificationPatientCreateWithoutNotificationInputObjectSchema } from './NotificationPatientCreateWithoutNotificationInput.schema'
import { NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema } from './NotificationPatientUncheckedCreateWithoutNotificationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientUpsertWithWhereUniqueWithoutNotificationInput> =
  z
    .object({
      where: z.lazy(() => NotificationPatientWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(
          () => NotificationPatientUpdateWithoutNotificationInputObjectSchema,
        ),
        z.lazy(
          () =>
            NotificationPatientUncheckedUpdateWithoutNotificationInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => NotificationPatientCreateWithoutNotificationInputObjectSchema,
        ),
        z.lazy(
          () =>
            NotificationPatientUncheckedCreateWithoutNotificationInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationPatientUpsertWithWhereUniqueWithoutNotificationInputObjectSchema =
  Schema
