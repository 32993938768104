import { z } from 'zod'
import { IdentityOrderByWithRelationInputObjectSchema } from './objects/IdentityOrderByWithRelationInput.schema'
import { IdentityWhereInputObjectSchema } from './objects/IdentityWhereInput.schema'
import { IdentityWhereUniqueInputObjectSchema } from './objects/IdentityWhereUniqueInput.schema'
import { IdentityCountAggregateInputObjectSchema } from './objects/IdentityCountAggregateInput.schema'
import { IdentityMinAggregateInputObjectSchema } from './objects/IdentityMinAggregateInput.schema'
import { IdentityMaxAggregateInputObjectSchema } from './objects/IdentityMaxAggregateInput.schema'
import { IdentityAvgAggregateInputObjectSchema } from './objects/IdentityAvgAggregateInput.schema'
import { IdentitySumAggregateInputObjectSchema } from './objects/IdentitySumAggregateInput.schema'

export const IdentityAggregateSchema = z.object({
  orderBy: z
    .union([
      IdentityOrderByWithRelationInputObjectSchema,
      IdentityOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: IdentityWhereInputObjectSchema.optional(),
  cursor: IdentityWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), IdentityCountAggregateInputObjectSchema])
    .optional(),
  _min: IdentityMinAggregateInputObjectSchema.optional(),
  _max: IdentityMaxAggregateInputObjectSchema.optional(),
  _avg: IdentityAvgAggregateInputObjectSchema.optional(),
  _sum: IdentitySumAggregateInputObjectSchema.optional(),
})
