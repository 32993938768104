import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'
import { EnumOrderStatusFieldUpdateOperationsInputObjectSchema } from './EnumOrderStatusFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { OrderTypeSchema } from '../enums/OrderType.schema'
import { EnumOrderTypeFieldUpdateOperationsInputObjectSchema } from './EnumOrderTypeFieldUpdateOperationsInput.schema'
import { PurchaseOriginSchema } from '../enums/PurchaseOrigin.schema'
import { NullableEnumPurchaseOriginFieldUpdateOperationsInputObjectSchema } from './NullableEnumPurchaseOriginFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { NullableEnumShippingTypeFieldUpdateOperationsInputObjectSchema } from './NullableEnumShippingTypeFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { CancelChannelSchema } from '../enums/CancelChannel.schema'
import { NullableEnumCancelChannelFieldUpdateOperationsInputObjectSchema } from './NullableEnumCancelChannelFieldUpdateOperationsInput.schema'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInputObjectSchema } from './NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInput.schema'
import { DoctorItemUpdateOneWithoutOrderNestedInputObjectSchema } from './DoctorItemUpdateOneWithoutOrderNestedInput.schema'
import { ProductItemOriginalUpdateManyWithoutOrderNestedInputObjectSchema } from './ProductItemOriginalUpdateManyWithoutOrderNestedInput.schema'
import { ProductItemUpdateManyWithoutOrderNestedInputObjectSchema } from './ProductItemUpdateManyWithoutOrderNestedInput.schema'
import { ProcedureItemUpdateManyWithoutOrderNestedInputObjectSchema } from './ProcedureItemUpdateManyWithoutOrderNestedInput.schema'
import { CouponUpdateManyWithoutOrderNestedInputObjectSchema } from './CouponUpdateManyWithoutOrderNestedInput.schema'
import { ShippingProviderUpdateOneWithoutOrdersNestedInputObjectSchema } from './ShippingProviderUpdateOneWithoutOrdersNestedInput.schema'
import { PaymentUpdateManyWithoutOrderNestedInputObjectSchema } from './PaymentUpdateManyWithoutOrderNestedInput.schema'
import { RefundUpdateManyWithoutOrderNestedInputObjectSchema } from './RefundUpdateManyWithoutOrderNestedInput.schema'
import { DeliveryUpdateManyWithoutOrderNestedInputObjectSchema } from './DeliveryUpdateManyWithoutOrderNestedInput.schema'
import { HealthInfoUpdateOneWithoutOrderNestedInputObjectSchema } from './HealthInfoUpdateOneWithoutOrderNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateWithoutReceiptInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => OrderStatusSchema),
        z.lazy(() => EnumOrderStatusFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    expiredAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    shouldExpiresAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    orderNumber: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patientId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    customerName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    customerTelNo: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    orderType: z
      .union([
        z.lazy(() => OrderTypeSchema),
        z.lazy(() => EnumOrderTypeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    purchaseOrigin: z
      .union([
        z.lazy(() => PurchaseOriginSchema),
        z.lazy(
          () =>
            NullableEnumPurchaseOriginFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    purchaseRef: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedProductItemsAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    editedProductItemsBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    shippingFee: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    shippingFeeDiscount: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    shippingType: z
      .union([
        z.lazy(() => ShippingTypeSchema),
        z.lazy(
          () => NullableEnumShippingTypeFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    recipient: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    shippingAddress: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    shippingTelNo: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    subDistrict: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    subDistrictKey: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    district: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    districtKey: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    province: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    provinceKey: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    postcode: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isPaid: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    isHold: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    approvedBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    approvedAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    canceledBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    canceledAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    cancelChannel: z
      .union([
        z.lazy(() => CancelChannelSchema),
        z.lazy(
          () => NullableEnumCancelChannelFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    noteToPatient: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    useCoin: z
      .union([
        z.number(),
        z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    v2InvoiceId: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    holdingNote: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    holdingBy: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    holdingAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    isDeclinedSuggestedItems: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    declinedSuggestedItemsReason: z
      .union([
        z.lazy(() => DeclinedSuggestedItemsReasonSchema),
        z.lazy(
          () =>
            NullableEnumDeclinedSuggestedItemsReasonFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    doctorItem: z
      .lazy(() => DoctorItemUpdateOneWithoutOrderNestedInputObjectSchema)
      .optional(),
    productItemsOriginal: z
      .lazy(
        () => ProductItemOriginalUpdateManyWithoutOrderNestedInputObjectSchema,
      )
      .optional(),
    productItems: z
      .lazy(() => ProductItemUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    coupons: z
      .lazy(() => CouponUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    shippingProvider: z
      .lazy(() => ShippingProviderUpdateOneWithoutOrdersNestedInputObjectSchema)
      .optional(),
    payments: z
      .lazy(() => PaymentUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    refunds: z
      .lazy(() => RefundUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    deliveries: z
      .lazy(() => DeliveryUpdateManyWithoutOrderNestedInputObjectSchema)
      .optional(),
    healthInfo: z
      .lazy(() => HealthInfoUpdateOneWithoutOrderNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const OrderUpdateWithoutReceiptInputObjectSchema = Schema
