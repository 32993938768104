import { RotateRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { createElement, useEffect, useState } from 'react'
import { LightboxProps, PluginMethods, Slide } from 'yet-another-react-lightbox'
import {
  CLASS_FLEX_CENTER,
  CLASS_FULLSIZE,
  ContainerRect,
  cleanup,
  clsx,
  cssClass,
  isImageSlide,
  useContainerRect,
  useEventCallback,
  useEvents,
} from 'yet-another-react-lightbox/core'

const ROTATE = 'rotate'
const ACTION_ROTATE_RIGHT = 'rotate-right'

function RotateButton() {
  const { publish } = useEvents()
  return (
    <IconButton
      onClick={(value) => {
        publish(ACTION_ROTATE_RIGHT)
      }}
    >
      <RotateRight
        sx={{
          color: 'rgb(255, 255, 255)',
          opacity: 0.8,
          ':hover': { opacity: 1 },
          filter: 'drop-shadow(3px 1px 2px rgb(0 0 0 / 1))',
        }}
        fontSize="large"
      />
    </IconButton>
  )
}

function RotateContainer({
  rotate = 0,
  render,
  slide,
  offset,
  rect,
}: Pick<LightboxProps, 'render'> & {
  rotate?: number
  slide: Slide
  offset: number
  rect: ContainerRect
}) {
  const [rotateDeg, setRotateDeg] = useState(rotate)
  const [maxSize, setMaxSize] = useState({
    maxWidth: '100vw',
    maxHeight: '100vh',
  })
  const { subscribe } = useEvents()
  const { setContainerRef } = useContainerRect()
  let _a

  const handleRotateRight = useEventCallback(() => {
    if (rotateDeg === 270) {
      setRotateDeg(0)
    } else {
      setRotateDeg(rotateDeg + 90)
    }
    if ((rotateDeg / 90) % 2 === 0) {
      setMaxSize({ maxWidth: '85vh', maxHeight: '100vw' })
    } else {
      setMaxSize({ maxWidth: '100vw', maxHeight: '100vh' })
    }
  })

  useEffect(() => {
    cleanup(subscribe(ACTION_ROTATE_RIGHT, handleRotateRight))
  }, [handleRotateRight, subscribe])

  const rendered =
    (_a = render.slide) === null || _a === void 0
      ? void 0
      : _a.call(render, slide, offset, rect)
  return createElement(
    'div',
    {
      ref: setContainerRef,
      className: clsx(cssClass(CLASS_FULLSIZE), cssClass(CLASS_FLEX_CENTER)),
      ...{
        style: {
          maxWidth: maxSize.maxWidth,
          maxHeight: maxSize.maxHeight,
          transform: `rotate(${rotateDeg}deg)`,
        },
      },
    },
    rendered,
  )
}

declare module 'yet-another-react-lightbox' {
  interface LightboxProps {
    rotate?: number
  }
}

function myRotate({ augment }: PluginMethods) {
  augment(
    ({
      toolbar: { buttons, ...restToolbar },
      render,
      rotate,
      ...restProps
    }) => ({
      toolbar: {
        buttons: [
          createElement(RotateButton, {
            key: ROTATE,
            labels: restProps.labels,
          }),
          ...buttons,
        ],
        ...restToolbar,
      },
      render: {
        ...render,
        slide: (slide, offset, rect) => {
          let _a
          return isImageSlide(slide)
            ? createElement(RotateContainer, {
                slide: slide,
                offset: offset,
                rect: rect,
                render: render,
                rotate: rotate,
              })
            : (_a = render.slide) === null || _a === void 0
            ? void 0
            : _a.call(render, slide, offset, rect)
        },
      },
      rotate: rotate || 0,
      ...restProps,
    }),
  )
}

export default myRotate
