import { z } from 'zod'
import { ProcedurePictureSelectObjectSchema } from './ProcedurePictureSelect.schema'
import { ProcedurePictureIncludeObjectSchema } from './ProcedurePictureInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureArgs> = z
  .object({
    select: z.lazy(() => ProcedurePictureSelectObjectSchema).optional(),
    include: z.lazy(() => ProcedurePictureIncludeObjectSchema).optional(),
  })
  .strict()

export const ProcedurePictureArgsObjectSchema = Schema
