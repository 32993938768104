import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.NestedEnumUnitFilter> = z
  .object({
    equals: z.lazy(() => UnitSchema).optional(),
    in: z
      .lazy(() => UnitSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => UnitSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => UnitSchema),
        z.lazy(() => NestedEnumUnitFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumUnitFilterObjectSchema = Schema
