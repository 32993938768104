import { Box } from '@mui/material'
import { Datagrid, List, ReferenceOneField, TextField } from 'react-admin'
import { AvatarField } from '../../components/AvatarField'
import { LocaleDateField } from '../../components/LocaleDateField'
import { FilterListForm } from './list-section/filterlistForm'

const PatientListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3, paddingBottom: 2 }}>
      <FilterListForm />
    </Box>
  )
}

export const PatientList = () => (
  <List disableSyncWithLocation actions={<PatientListAction />}>
    <Datagrid size="small" rowClick="edit">
      <TextField source="id" />
      <AvatarField source="picture.src" sortable={false} />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="nickname" />
      <TextField source="gender" />
      <LocaleDateField
        source="birthDate"
        options={{ day: 'numeric', month: 'long', year: 'numeric' }}
      />
      <ReferenceOneField
        label="resources.patient.fields.phoneNumber"
        reference="patientIdentity"
        target="patientId"
        filter={{ type_equals: 'PHONE' }}
        sortable={false}
      >
        <TextField source="identityId" />
      </ReferenceOneField>
    </Datagrid>
  </List>
)
