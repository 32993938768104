import { z } from 'zod'
import { WaitingBannerCreateInputObjectSchema } from './objects/WaitingBannerCreateInput.schema'
import { WaitingBannerUncheckedCreateInputObjectSchema } from './objects/WaitingBannerUncheckedCreateInput.schema'

export const WaitingBannerCreateOneSchema = z.object({
  data: z.union([
    WaitingBannerCreateInputObjectSchema,
    WaitingBannerUncheckedCreateInputObjectSchema,
  ]),
})
