import { Box } from '@mui/material'
import axios from 'axios'
import { FC } from 'react'
import { ImageStorageInput } from '../../../components/ImageStorageInput'
import { adminGatewayClient } from '../../../service'

export interface FormImageInputProps {
  source: string
  label: string
}

export const PartnerLogoPicture: FC<FormImageInputProps> = ({
  source,
  label,
}) => {
  return (
    <Box sx={{ mt: -2 }}>
      <ImageStorageInput
        source={source}
        label={label}
        multiple={false}
        canCrop={true}
        cropOption={{ aspect: 1 }}
        accept={{ 'image/*': [] }}
        upload={async (file, { onProgress, abortController }) => {
          const uploadUrlMeta =
            await adminGatewayClient.partner.generateUploadLogoUrl.query({
              extension: file.name.split('.').pop() ?? '',
            })
          await axios.request({
            method: 'PUT',
            url: uploadUrlMeta.uploadUrl,
            headers: {
              'content-type': file.type,
            },
            data: file,
            onUploadProgress: (progressEvent) => {
              onProgress(progressEvent.loaded / progressEvent.total)
            },
            signal: abortController.signal,
          })
          return uploadUrlMeta
        }}
      ></ImageStorageInput>
    </Box>
  )
}
