import { z } from 'zod'
import { ConditionSettingCreateWithoutGroupInputObjectSchema } from './ConditionSettingCreateWithoutGroupInput.schema'
import { ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema } from './ConditionSettingUncheckedCreateWithoutGroupInput.schema'
import { ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema } from './ConditionSettingCreateOrConnectWithoutGroupInput.schema'
import { ConditionSettingCreateManyGroupInputEnvelopeObjectSchema } from './ConditionSettingCreateManyGroupInputEnvelope.schema'
import { ConditionSettingWhereUniqueInputObjectSchema } from './ConditionSettingWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingCreateNestedManyWithoutGroupInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ConditionSettingCreateWithoutGroupInputObjectSchema),
          z
            .lazy(() => ConditionSettingCreateWithoutGroupInputObjectSchema)
            .array(),
          z.lazy(
            () => ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingUncheckedCreateWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ConditionSettingCreateOrConnectWithoutGroupInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ConditionSettingCreateManyGroupInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema),
          z.lazy(() => ConditionSettingWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ConditionSettingCreateNestedManyWithoutGroupInputObjectSchema =
  Schema
