import { z } from 'zod'
import { NotificationPatientCreateManyNotificationInputObjectSchema } from './NotificationPatientCreateManyNotificationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientCreateManyNotificationInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(
          () => NotificationPatientCreateManyNotificationInputObjectSchema,
        ),
        z
          .lazy(
            () => NotificationPatientCreateManyNotificationInputObjectSchema,
          )
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const NotificationPatientCreateManyNotificationInputEnvelopeObjectSchema =
  Schema
