import { z } from 'zod'
import { PartnerCreateWithoutBranchesInputObjectSchema } from './PartnerCreateWithoutBranchesInput.schema'
import { PartnerUncheckedCreateWithoutBranchesInputObjectSchema } from './PartnerUncheckedCreateWithoutBranchesInput.schema'
import { PartnerCreateOrConnectWithoutBranchesInputObjectSchema } from './PartnerCreateOrConnectWithoutBranchesInput.schema'
import { PartnerWhereUniqueInputObjectSchema } from './PartnerWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCreateNestedOneWithoutBranchesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PartnerCreateWithoutBranchesInputObjectSchema),
        z.lazy(() => PartnerUncheckedCreateWithoutBranchesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PartnerCreateOrConnectWithoutBranchesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PartnerWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PartnerCreateNestedOneWithoutBranchesInputObjectSchema = Schema
