import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentCreateWithoutConsentInputObjectSchema } from './AcceptedConsentCreateWithoutConsentInput.schema'
import { AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema } from './AcceptedConsentUncheckedCreateWithoutConsentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateOrConnectWithoutConsentInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => AcceptedConsentCreateWithoutConsentInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentCreateOrConnectWithoutConsentInputObjectSchema =
  Schema
