import { z } from 'zod'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantCreateWithoutProductReviewsInputObjectSchema } from './VariantCreateWithoutProductReviewsInput.schema'
import { VariantUncheckedCreateWithoutProductReviewsInputObjectSchema } from './VariantUncheckedCreateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantCreateOrConnectWithoutProductReviewsInput> =
  z
    .object({
      where: z.lazy(() => VariantWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => VariantCreateWithoutProductReviewsInputObjectSchema),
        z.lazy(
          () => VariantUncheckedCreateWithoutProductReviewsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantCreateOrConnectWithoutProductReviewsInputObjectSchema =
  Schema
