import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    sku: z.boolean().optional(),
    variantId: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    picture: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    drugType: z.boolean().optional(),
    quantity: z.boolean().optional(),
    tagPrice: z.boolean().optional(),
    sellingPrice: z.boolean().optional(),
    sellingUnit: z.boolean().optional(),
    cost: z.boolean().optional(),
    flashSaleId: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
    v2DrugItemId: z.boolean().optional(),
    canExpress: z.boolean().optional(),
    warehouse: z.boolean().optional(),
  })
  .strict()

export const ProductItemOriginalSelectObjectSchema = Schema
