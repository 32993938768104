import { z } from 'zod'
import { PaymentMethodSchema } from '../enums/PaymentMethod.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumPaymentMethodFieldUpdateOperationsInput> = z
  .object({
    set: z.lazy(() => PaymentMethodSchema).optional(),
  })
  .strict()

export const EnumPaymentMethodFieldUpdateOperationsInputObjectSchema = Schema
