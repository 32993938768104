import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { CoinTransactionCountOrderByAggregateInputObjectSchema } from './CoinTransactionCountOrderByAggregateInput.schema'
import { CoinTransactionAvgOrderByAggregateInputObjectSchema } from './CoinTransactionAvgOrderByAggregateInput.schema'
import { CoinTransactionMaxOrderByAggregateInputObjectSchema } from './CoinTransactionMaxOrderByAggregateInput.schema'
import { CoinTransactionMinOrderByAggregateInputObjectSchema } from './CoinTransactionMinOrderByAggregateInput.schema'
import { CoinTransactionSumOrderByAggregateInputObjectSchema } from './CoinTransactionSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    patientId: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    amount: z.lazy(() => SortOrderSchema).optional(),
    note: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => CoinTransactionCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => CoinTransactionAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => CoinTransactionMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => CoinTransactionMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => CoinTransactionSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const CoinTransactionOrderByWithAggregationInputObjectSchema = Schema
