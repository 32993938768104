import { z } from 'zod'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryCreateWithoutDeliveryLogsInput.schema'
import { DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema } from './DeliveryUncheckedCreateWithoutDeliveryLogsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryCreateOrConnectWithoutDeliveryLogsInput> =
  z
    .object({
      where: z.lazy(() => DeliveryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => DeliveryCreateWithoutDeliveryLogsInputObjectSchema),
        z.lazy(
          () => DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DeliveryCreateOrConnectWithoutDeliveryLogsInputObjectSchema =
  Schema
