import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ConfigurationCountOrderByAggregateInputObjectSchema } from './ConfigurationCountOrderByAggregateInput.schema'
import { ConfigurationMaxOrderByAggregateInputObjectSchema } from './ConfigurationMaxOrderByAggregateInput.schema'
import { ConfigurationMinOrderByAggregateInputObjectSchema } from './ConfigurationMinOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ConfigurationOrderByWithAggregationInput> = z
  .object({
    key: z.lazy(() => SortOrderSchema).optional(),
    value: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ConfigurationCountOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ConfigurationMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ConfigurationMinOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ConfigurationOrderByWithAggregationInputObjectSchema = Schema
