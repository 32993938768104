import { Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { BooleanInput, TimeInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { FormDivider } from '../../../components/FormInput/divider'
import { format } from 'date-fns'

export const weekday = {
  MON: {
    name: 'จันทร์',
    value: false,
  },
  TUE: {
    name: 'อังคาร',
    value: false,
  },
  WED: {
    name: 'พุธ',
    value: false,
  },
  THU: {
    name: 'พฤหัส',
    value: false,
  },
  FRI: {
    name: 'ศุกร์',
    value: false,
  },
  SAT: {
    name: 'เสาร์',
    value: false,
  },
  SUN: {
    name: 'อาทิตย์',
    value: false,
  },
}

const validateOpenAt = (
  openAtValue: Date,
  formValues: { [key: string]: unknown },
  props: { [key: string]: unknown },
) => {
  const field = props.source
  const selectedDay = `${field}`.replace('_openAt', '')

  if (!formValues[selectedDay]) {
    return undefined
  }

  if (!openAtValue) {
    return `Please input open time.`
  }

  const closeAt = formValues[`${selectedDay}_closeAt`]
  const openAtString =
    typeof openAtValue === 'string' || openAtValue instanceof String
      ? openAtValue
      : format(new Date(openAtValue), 'HH:mm')
  if (closeAt && openAtString >= closeAt) {
    console.log('test open failed')
    return `Open time must be lesser than close time.`
  }

  return undefined
}

const validateCloseAt = (
  closeAtValue: Date,
  formValues: { [key: string]: unknown },
  props: { [key: string]: unknown },
) => {
  const field = props.source
  const selectedDay = `${field}`.replace('_closeAt', '')

  if (!formValues[selectedDay]) {
    return undefined
  }

  if (!closeAtValue) {
    return `Please input close time.`
  }

  const openAt = formValues[`${selectedDay}_openAt`]
  const closeAtString =
    typeof closeAtValue === 'string' || closeAtValue instanceof String
      ? closeAtValue
      : format(new Date(closeAtValue), 'HH:mm')

  if (openAt && closeAtString <= openAt) {
    // if (openAt && format(closeAtValue, 'HH:mm') <= openAt) {
    return `Close time must be greather than open time.`
  }

  return undefined
}

export const BranchOfficeHour = () => {
  const { getValues, setValue } = useFormContext()
  const { ...branch } = getValues()
  useEffect(() => {
    if (branch.officeHours && branch.officeHours.length) {
      for (const officeHour of branch.officeHours) {
        const day = officeHour.weekday
        setValue(day, true)
        setValue(`${day}_openAt`, officeHour.openAt)
        setValue(`${day}_closeAt`, officeHour.closeAt)
      }
    }
  }, [branch.officeHours, setValue])
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        เวลาทำการ
      </Typography>
      <FormDivider />
      {Object.entries(weekday).map(([day, item]) => (
        <Grid container spacing={2} key={day}>
          <Grid item sx={{ width: '150px' }}>
            <BooleanInput
              label={item.name}
              source={day}
              defaultValue={item.value}
            />
          </Grid>
          <Grid item sx={{ width: '200px', maxWidth: '150px' }}>
            <TimeInput
              sx={{ width: '100%' }}
              source={`${day}_openAt`}
              label="เปิด"
              validate={[validateOpenAt]}
            />
          </Grid>
          <Grid item sx={{ width: '200px', maxWidth: '150px' }}>
            <TimeInput
              sx={{ width: '100%' }}
              source={`${day}_closeAt`}
              label="ปิด"
              validate={[validateCloseAt]}
            />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
