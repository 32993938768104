import { z } from 'zod'

export const CaseStatusSchema = z.enum([
  'CREATED',
  'IN_PROGRESS',
  'WAIT_TO_SUMMARIZE',
  'WAIT_TO_PAYMENT',
  'COMPLETED',
  'FAILED',
])
