import { z } from 'zod'
import { ProductItemWhereUniqueInputObjectSchema } from './objects/ProductItemWhereUniqueInput.schema'
import { ProductItemCreateInputObjectSchema } from './objects/ProductItemCreateInput.schema'
import { ProductItemUncheckedCreateInputObjectSchema } from './objects/ProductItemUncheckedCreateInput.schema'
import { ProductItemUpdateInputObjectSchema } from './objects/ProductItemUpdateInput.schema'
import { ProductItemUncheckedUpdateInputObjectSchema } from './objects/ProductItemUncheckedUpdateInput.schema'

export const ProductItemUpsertSchema = z.object({
  where: ProductItemWhereUniqueInputObjectSchema,
  create: z.union([
    ProductItemCreateInputObjectSchema,
    ProductItemUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductItemUpdateInputObjectSchema,
    ProductItemUncheckedUpdateInputObjectSchema,
  ]),
})
