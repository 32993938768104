import { z } from 'zod'

export const CreateOrderFailedEventModel = z.object({
  productItems: z.array(
    z.object({
      flashsaleItemId: z.number(),
      quantity: z.number(),
    }),
  ),
  procedureItems: z.array(
    z.object({
      flashsaleItemId: z.number(),
      quantity: z.number(),
    }),
  ),
})
export type CreateOrderFailedEventModel = z.infer<
  typeof CreateOrderFailedEventModel
>
