import { z } from 'zod'
import { OtpOrderByWithRelationInputObjectSchema } from './objects/OtpOrderByWithRelationInput.schema'
import { OtpWhereInputObjectSchema } from './objects/OtpWhereInput.schema'
import { OtpWhereUniqueInputObjectSchema } from './objects/OtpWhereUniqueInput.schema'
import { OtpCountAggregateInputObjectSchema } from './objects/OtpCountAggregateInput.schema'
import { OtpMinAggregateInputObjectSchema } from './objects/OtpMinAggregateInput.schema'
import { OtpMaxAggregateInputObjectSchema } from './objects/OtpMaxAggregateInput.schema'
import { OtpAvgAggregateInputObjectSchema } from './objects/OtpAvgAggregateInput.schema'
import { OtpSumAggregateInputObjectSchema } from './objects/OtpSumAggregateInput.schema'

export const OtpAggregateSchema = z.object({
  orderBy: z
    .union([
      OtpOrderByWithRelationInputObjectSchema,
      OtpOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: OtpWhereInputObjectSchema.optional(),
  cursor: OtpWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), OtpCountAggregateInputObjectSchema])
    .optional(),
  _min: OtpMinAggregateInputObjectSchema.optional(),
  _max: OtpMaxAggregateInputObjectSchema.optional(),
  _avg: OtpAvgAggregateInputObjectSchema.optional(),
  _sum: OtpSumAggregateInputObjectSchema.optional(),
})
