import { z } from 'zod'
import { SuggestedProductItemScalarWhereInputObjectSchema } from './SuggestedProductItemScalarWhereInput.schema'
import { SuggestedProductItemUpdateManyMutationInputObjectSchema } from './SuggestedProductItemUpdateManyMutationInput.schema'
import { SuggestedProductItemUncheckedUpdateManyWithoutSuggestedProductItemsInputObjectSchema } from './SuggestedProductItemUncheckedUpdateManyWithoutSuggestedProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProductItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => SuggestedProductItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            SuggestedProductItemUncheckedUpdateManyWithoutSuggestedProductItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProductItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema =
  Schema
