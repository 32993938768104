import { z } from 'zod'
import { DeliveryShippingProviderTrackingNumberCompoundUniqueInputObjectSchema } from './DeliveryShippingProviderTrackingNumberCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    shippingProvider_trackingNumber: z
      .lazy(
        () =>
          DeliveryShippingProviderTrackingNumberCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const DeliveryWhereUniqueInputObjectSchema = Schema
