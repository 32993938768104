import { z } from 'zod'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryCreateWithoutBrandInputObjectSchema } from './ProductReviewSummaryCreateWithoutBrandInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryCreateOrConnectWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductReviewSummaryCreateWithoutBrandInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedCreateWithoutBrandInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewSummaryCreateOrConnectWithoutBrandInputObjectSchema =
  Schema
