import { z } from 'zod'
import { OrderCreateWithoutProductItemsInputObjectSchema } from './OrderCreateWithoutProductItemsInput.schema'
import { OrderUncheckedCreateWithoutProductItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsInput.schema'
import { OrderCreateOrConnectWithoutProductItemsInputObjectSchema } from './OrderCreateOrConnectWithoutProductItemsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateNestedOneWithoutProductItemsInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => OrderCreateWithoutProductItemsInputObjectSchema),
        z.lazy(() => OrderUncheckedCreateWithoutProductItemsInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => OrderCreateOrConnectWithoutProductItemsInputObjectSchema)
      .optional(),
    connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const OrderCreateNestedOneWithoutProductItemsInputObjectSchema = Schema
