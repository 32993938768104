import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'
import { DeliveryArgsObjectSchema } from './DeliveryArgs.schema'
import { RefundArgsObjectSchema } from './RefundArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemInclude> = z
  .object({
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    delivery: z
      .union([z.boolean(), z.lazy(() => DeliveryArgsObjectSchema)])
      .optional(),
    refund: z
      .union([z.boolean(), z.lazy(() => RefundArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const ProductItemIncludeObjectSchema = Schema
