import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUncheckedCreateWithoutNotificationsInput> =
  z
    .object({
      id: z.number().optional(),
      segmentId: z.string(),
    })
    .strict()

export const SegmentUncheckedCreateWithoutNotificationsInputObjectSchema =
  Schema
