import { z } from 'zod'
import { ScheduleDoctorIdAvailableAtCompoundUniqueInputObjectSchema } from './ScheduleDoctorIdAvailableAtCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    doctorId_availableAt: z
      .lazy(() => ScheduleDoctorIdAvailableAtCompoundUniqueInputObjectSchema)
      .optional(),
  })
  .strict()

export const ScheduleWhereUniqueInputObjectSchema = Schema
