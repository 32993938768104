import { z } from 'zod'
import { NotificationCreateWithoutSegmentsInputObjectSchema } from './NotificationCreateWithoutSegmentsInput.schema'
import { NotificationUncheckedCreateWithoutSegmentsInputObjectSchema } from './NotificationUncheckedCreateWithoutSegmentsInput.schema'
import { NotificationCreateOrConnectWithoutSegmentsInputObjectSchema } from './NotificationCreateOrConnectWithoutSegmentsInput.schema'
import { NotificationUpsertWithWhereUniqueWithoutSegmentsInputObjectSchema } from './NotificationUpsertWithWhereUniqueWithoutSegmentsInput.schema'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'
import { NotificationUpdateWithWhereUniqueWithoutSegmentsInputObjectSchema } from './NotificationUpdateWithWhereUniqueWithoutSegmentsInput.schema'
import { NotificationUpdateManyWithWhereWithoutSegmentsInputObjectSchema } from './NotificationUpdateManyWithWhereWithoutSegmentsInput.schema'
import { NotificationScalarWhereInputObjectSchema } from './NotificationScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpdateManyWithoutSegmentsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => NotificationCreateWithoutSegmentsInputObjectSchema),
          z
            .lazy(() => NotificationCreateWithoutSegmentsInputObjectSchema)
            .array(),
          z.lazy(
            () => NotificationUncheckedCreateWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationUncheckedCreateWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => NotificationCreateOrConnectWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () => NotificationCreateOrConnectWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              NotificationUpsertWithWhereUniqueWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationUpsertWithWhereUniqueWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      set: z
        .union([
          z.lazy(() => NotificationWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => NotificationWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => NotificationWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => NotificationWhereUniqueInputObjectSchema),
          z.lazy(() => NotificationWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              NotificationUpdateWithWhereUniqueWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationUpdateWithWhereUniqueWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              NotificationUpdateManyWithWhereWithoutSegmentsInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                NotificationUpdateManyWithWhereWithoutSegmentsInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => NotificationScalarWhereInputObjectSchema),
          z.lazy(() => NotificationScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const NotificationUpdateManyWithoutSegmentsNestedInputObjectSchema =
  Schema
