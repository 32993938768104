import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { IdentityModelType } from '@ssch-backend/doctors'
import {
  BooleanInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { BankSection } from './create-edit-section/bank'
import { CategorySection } from './create-edit-section/category'
import { CertificateSection } from './create-edit-section/certificate'
import { ClinicHospitalSection } from './create-edit-section/clinicHospital'
import { IdCardSection } from './create-edit-section/idCard'
import { LicenseSection } from './create-edit-section/license'
import { PartnerSection } from './create-edit-section/partner'
import { ProfileSection } from './create-edit-section/profile'
import { SpecialtyAndExpertiseSection } from './create-edit-section/specialtyAndExpertise'
import { transformDoctor } from './transform'

const DoctorEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctor" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const DoctorEditForm = () => {
  const record = useRecordContext()
  const email = (record.identity as IdentityModelType[])?.find(
    (identity) => identity.type === 'EMAIL',
  )?.identityId
  return (
    <SimpleForm
      toolbar={<DoctorEditToolbar />}
      defaultValues={{ ...record, email }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        สถานะบัญชี
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography sx={{ mr: 2, pt: 0.75 }}>Inactive</Typography>
        <BooleanInput source="active" label={false} />
        <Typography sx={{ ml: -1, pt: 0.75 }}>Active</Typography>
      </Box>
      <Divider sx={{ width: '100%', mb: 3, borderColor: 'black' }} />
      <ProfileSection isEdit={!!record.id} />
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <BankSection />
        </Grid>
        <Grid item xs={12} lg={4}>
          <IdCardSection />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LicenseSection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <SpecialtyAndExpertiseSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ClinicHospitalSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PartnerSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CategorySection />
        </Grid>
      </Grid>
      <Divider sx={{ width: '100%', my: 3, borderColor: 'black' }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CertificateSection />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const DoctorEdit = () => (
  <EditSaveRouteBack transform={transformDoctor}>
    <Card>
      <CardContent sx={{ p: 2 }}>
        <DoctorEditForm />
      </CardContent>
    </Card>
  </EditSaveRouteBack>
)
