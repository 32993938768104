import { z } from 'zod'

export const DoctorItemScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'caseId',
  'doctorFee',
  'doctorFeeDiscount',
  'consultingChannel',
  'doctorName',
  'doctorId',
  'orderId',
])
