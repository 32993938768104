import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HealthInfoCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    name: z.literal(true).optional(),
    gender: z.literal(true).optional(),
    birthdate: z.literal(true).optional(),
    drugAllergy: z.literal(true).optional(),
    congenitalDisease: z.literal(true).optional(),
    medication: z.literal(true).optional(),
    pregnancyState: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const HealthInfoCountAggregateInputObjectSchema = Schema
