import CloseIcon from '@mui/icons-material/Close'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { addDays, subDays } from 'date-fns'
import { FC, useState } from 'react'
import { ReferenceInput, useNotify, useTranslate } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { CustomAutoCompleteInput } from '../../../components/CustomInput'
import { adminGatewayClient } from '../../../service'
import { downloadExcel, handleFilterFullnameQuery } from '../../../utils'

const columnHeaderExportDoctorReview = [
  'Review date',
  'Doctor ID',
  'Doctor name',
  'Patient ID',
  'Patient name',
  'Rating',
  'Comment',
]

export const DoctorReviewExportButton: FC = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  const form = useForm({
    defaultValues: {
      doctorId: null,
    },
  })

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }
  const handleCloseDialog = (
    _event: { [key: string]: unknown } = {},
    reason = '',
  ) => {
    if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
      setIsDialogOpen(false)
    }
  }

  const doctorOptionText = (choice: {
    prefix: string
    firstname: string
    lastname: string
  }) => {
    return `${choice.prefix} ${choice.firstname} ${choice.lastname}`
  }

  const handleFromDateChange = (value: Date | null) => {
    setFromDate(value)
  }
  const handleToDateChange = (value: Date | null) => {
    setToDate(value)
  }

  const exportDoctorReview = async () => {
    setIsExporting(true)

    try {
      const reviews =
        await adminGatewayClient.doctorReview.findDoctorReviewReport.query({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          from: fromDate!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          to: toDate!,
          ...(form.getValues('doctorId')
            ? { doctorId: form.getValues('doctorId') }
            : {}),
        })

      const excelInputData: (string | number)[][] = []
      reviews.forEach((review) => {
        excelInputData.push([
          review.reviewDate,
          review.doctorId,
          review.doctorName,
          review.patientId,
          review.patientName,
          review.rating,
          review.message,
        ])
      })

      const ws = XLSX.utils.book_new()
      XLSX.utils.sheet_add_aoa(ws, [columnHeaderExportDoctorReview])
      XLSX.utils.sheet_add_json(ws, excelInputData, {
        origin: 'A2',
        skipHeader: true,
      })
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
      })

      downloadExcel(excelBuffer, 'doctor-reviews')
    } catch (e) {
      console.error(e)
      notify('Export doctor review report error', {
        type: 'error',
      })
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth={'sm'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {translate('resources.doctor.title.export_review')}
          <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseDialog()
            }}
            disabled={isExporting}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <FormProvider {...form}>
                <ReferenceInput
                  source="doctorId"
                  reference="doctor"
                  sort={{ field: 'id', order: 'ASC' }}
                >
                  <CustomAutoCompleteInput
                    optionText={doctorOptionText}
                    filterToQuery={handleFilterFullnameQuery}
                    label="Doctor"
                    helperText={false}
                    sx={{ marginTop: '8px' }}
                  />
                </ReferenceInput>
              </FormProvider>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Review date from <span style={{ color: 'red' }}>*</span>
              </Typography>
              <MobileDatePicker
                format="dd MMMM yyyy"
                slotProps={{ textField: { placeholder: 'DD/MM/YYYY' } }}
                minDate={toDate ? subDays(toDate, 35) : undefined}
                maxDate={toDate ?? undefined}
                onAccept={handleFromDateChange}
                disabled={isExporting}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Review date to <span style={{ color: 'red' }}>*</span>
              </Typography>
              <MobileDatePicker
                format="dd MMMM yyyy"
                slotProps={{ textField: { placeholder: 'DD/MM/YYYY' } }}
                minDate={fromDate ?? undefined}
                maxDate={fromDate ? addDays(fromDate, 35) : undefined}
                onAccept={handleToDateChange}
                disabled={isExporting}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'end' }}>
          <Button
            onClick={() => {
              handleCloseDialog()
            }}
            disabled={isExporting}
          >
            {translate('ra.action.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!fromDate || !toDate || isExporting}
            onClick={exportDoctorReview}
          >
            {translate('resources.doctor.action.export_review')}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="info"
        startIcon={<FileDownloadIcon />}
        onClick={handleOpenDialog}
      >
        {translate('resources.doctor.action.export_review')}
      </Button>
    </>
  )
}
