import { z } from 'zod'
import { TeleconsultOrderByWithRelationInputObjectSchema } from './objects/TeleconsultOrderByWithRelationInput.schema'
import { TeleconsultWhereInputObjectSchema } from './objects/TeleconsultWhereInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './objects/TeleconsultWhereUniqueInput.schema'
import { TeleconsultCountAggregateInputObjectSchema } from './objects/TeleconsultCountAggregateInput.schema'
import { TeleconsultMinAggregateInputObjectSchema } from './objects/TeleconsultMinAggregateInput.schema'
import { TeleconsultMaxAggregateInputObjectSchema } from './objects/TeleconsultMaxAggregateInput.schema'
import { TeleconsultAvgAggregateInputObjectSchema } from './objects/TeleconsultAvgAggregateInput.schema'
import { TeleconsultSumAggregateInputObjectSchema } from './objects/TeleconsultSumAggregateInput.schema'

export const TeleconsultAggregateSchema = z.object({
  orderBy: z
    .union([
      TeleconsultOrderByWithRelationInputObjectSchema,
      TeleconsultOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: TeleconsultWhereInputObjectSchema.optional(),
  cursor: TeleconsultWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), TeleconsultCountAggregateInputObjectSchema])
    .optional(),
  _min: TeleconsultMinAggregateInputObjectSchema.optional(),
  _max: TeleconsultMaxAggregateInputObjectSchema.optional(),
  _avg: TeleconsultAvgAggregateInputObjectSchema.optional(),
  _sum: TeleconsultSumAggregateInputObjectSchema.optional(),
})
