import { z } from 'zod'
import { BranchCreateInputObjectSchema } from './objects/BranchCreateInput.schema'
import { BranchUncheckedCreateInputObjectSchema } from './objects/BranchUncheckedCreateInput.schema'

export const BranchCreateOneSchema = z.object({
  data: z.union([
    BranchCreateInputObjectSchema,
    BranchUncheckedCreateInputObjectSchema,
  ]),
})
