import { z } from 'zod'
import { IdentityOrderByWithRelationInputObjectSchema } from './objects/IdentityOrderByWithRelationInput.schema'
import { IdentityWhereInputObjectSchema } from './objects/IdentityWhereInput.schema'
import { IdentityWhereUniqueInputObjectSchema } from './objects/IdentityWhereUniqueInput.schema'
import { IdentityScalarFieldEnumSchema } from './enums/IdentityScalarFieldEnum.schema'

export const IdentityFindManySchema = z.object({
  orderBy: z
    .union([
      IdentityOrderByWithRelationInputObjectSchema,
      IdentityOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: IdentityWhereInputObjectSchema.optional(),
  cursor: IdentityWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(IdentityScalarFieldEnumSchema).optional(),
})
