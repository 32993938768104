import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BranchCountOrderByAggregateInputObjectSchema } from './BranchCountOrderByAggregateInput.schema'
import { BranchAvgOrderByAggregateInputObjectSchema } from './BranchAvgOrderByAggregateInput.schema'
import { BranchMaxOrderByAggregateInputObjectSchema } from './BranchMaxOrderByAggregateInput.schema'
import { BranchMinOrderByAggregateInputObjectSchema } from './BranchMinOrderByAggregateInput.schema'
import { BranchSumOrderByAggregateInputObjectSchema } from './BranchSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    address: z.lazy(() => SortOrderSchema).optional(),
    subDistrict: z.lazy(() => SortOrderSchema).optional(),
    subDistrictKey: z.lazy(() => SortOrderSchema).optional(),
    district: z.lazy(() => SortOrderSchema).optional(),
    districtKey: z.lazy(() => SortOrderSchema).optional(),
    province: z.lazy(() => SortOrderSchema).optional(),
    provinceKey: z.lazy(() => SortOrderSchema).optional(),
    postcode: z.lazy(() => SortOrderSchema).optional(),
    googleMapUrl: z.lazy(() => SortOrderSchema).optional(),
    lat: z.lazy(() => SortOrderSchema).optional(),
    long: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    isExpertise: z.lazy(() => SortOrderSchema).optional(),
    remark: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    v2BranchId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => BranchCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => BranchAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => BranchMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => BranchMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => BranchSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const BranchOrderByWithAggregationInputObjectSchema = Schema
