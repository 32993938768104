import { z } from 'zod'
import { DeliveryArgsObjectSchema } from './DeliveryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogInclude> = z
  .object({
    delivery: z
      .union([z.boolean(), z.lazy(() => DeliveryArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const DeliveryLogIncludeObjectSchema = Schema
