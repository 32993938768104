import { z } from 'zod'
import { DiscountSettingWhereInputObjectSchema } from './DiscountSettingWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.DiscountSettingListRelationFilter> = z
  .object({
    every: z.lazy(() => DiscountSettingWhereInputObjectSchema).optional(),
    some: z.lazy(() => DiscountSettingWhereInputObjectSchema).optional(),
    none: z.lazy(() => DiscountSettingWhereInputObjectSchema).optional(),
  })
  .strict()

export const DiscountSettingListRelationFilterObjectSchema = Schema
