import { z } from 'zod'
import { OrderUpdateWithoutProcedureItemsInputObjectSchema } from './OrderUpdateWithoutProcedureItemsInput.schema'
import { OrderUncheckedUpdateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedUpdateWithoutProcedureItemsInput.schema'
import { OrderCreateWithoutProcedureItemsInputObjectSchema } from './OrderCreateWithoutProcedureItemsInput.schema'
import { OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './OrderUncheckedCreateWithoutProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutProcedureItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutProcedureItemsInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutProcedureItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutProcedureItemsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutProcedureItemsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutProcedureItemsInputObjectSchema = Schema
