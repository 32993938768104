import { z } from 'zod'
import { ConsentCreateNestedOneWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateNestedOneWithoutAcceptedConsentsInput.schema'
import { PatientCreateNestedOneWithoutAcceptedConsentsInputObjectSchema } from './PatientCreateNestedOneWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    consent: z.lazy(
      () => ConsentCreateNestedOneWithoutAcceptedConsentsInputObjectSchema,
    ),
    patient: z.lazy(
      () => PatientCreateNestedOneWithoutAcceptedConsentsInputObjectSchema,
    ),
  })
  .strict()

export const AcceptedConsentCreateInputObjectSchema = Schema
