import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryUpdateWithoutCategoryInputObjectSchema } from './ProductCategoryUpdateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedUpdateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedUpdateWithoutCategoryInput.schema'
import { ProductCategoryCreateWithoutCategoryInputObjectSchema } from './ProductCategoryCreateWithoutCategoryInput.schema'
import { ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema } from './ProductCategoryUncheckedCreateWithoutCategoryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryUpsertWithWhereUniqueWithoutCategoryInput> =
  z
    .object({
      where: z.lazy(() => ProductCategoryWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductCategoryUpdateWithoutCategoryInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedUpdateWithoutCategoryInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductCategoryCreateWithoutCategoryInputObjectSchema),
        z.lazy(
          () => ProductCategoryUncheckedCreateWithoutCategoryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductCategoryUpsertWithWhereUniqueWithoutCategoryInputObjectSchema =
  Schema
