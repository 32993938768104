import * as z from "zod"
import { CompleteVariant, RelatedVariantModel, CompleteWarehouse, RelatedWarehouseModel } from "./index"

export const VariantWarehouseModel = z.object({
  id: z.number().int(),
  variantId: z.number().int(),
  warehouseId: z.number().int(),
})

export interface CompleteVariantWarehouse extends z.infer<typeof VariantWarehouseModel> {
  variant: CompleteVariant
  warehouse: CompleteWarehouse
}

/**
 * RelatedVariantWarehouseModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVariantWarehouseModel: z.ZodSchema<CompleteVariantWarehouse> = z.lazy(() => VariantWarehouseModel.extend({
  variant: RelatedVariantModel,
  warehouse: RelatedWarehouseModel,
}))
