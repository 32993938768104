import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentCountOutputTypeSelect> = z
  .object({
    acceptedConsents: z.boolean().optional(),
  })
  .strict()

export const ConsentCountOutputTypeSelectObjectSchema = Schema
