import { z } from 'zod'
import { ProductVideoOrderByWithRelationInputObjectSchema } from './objects/ProductVideoOrderByWithRelationInput.schema'
import { ProductVideoWhereInputObjectSchema } from './objects/ProductVideoWhereInput.schema'
import { ProductVideoWhereUniqueInputObjectSchema } from './objects/ProductVideoWhereUniqueInput.schema'
import { ProductVideoCountAggregateInputObjectSchema } from './objects/ProductVideoCountAggregateInput.schema'
import { ProductVideoMinAggregateInputObjectSchema } from './objects/ProductVideoMinAggregateInput.schema'
import { ProductVideoMaxAggregateInputObjectSchema } from './objects/ProductVideoMaxAggregateInput.schema'
import { ProductVideoAvgAggregateInputObjectSchema } from './objects/ProductVideoAvgAggregateInput.schema'
import { ProductVideoSumAggregateInputObjectSchema } from './objects/ProductVideoSumAggregateInput.schema'

export const ProductVideoAggregateSchema = z.object({
  orderBy: z
    .union([
      ProductVideoOrderByWithRelationInputObjectSchema,
      ProductVideoOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductVideoWhereInputObjectSchema.optional(),
  cursor: ProductVideoWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProductVideoCountAggregateInputObjectSchema])
    .optional(),
  _min: ProductVideoMinAggregateInputObjectSchema.optional(),
  _max: ProductVideoMaxAggregateInputObjectSchema.optional(),
  _avg: ProductVideoAvgAggregateInputObjectSchema.optional(),
  _sum: ProductVideoSumAggregateInputObjectSchema.optional(),
})
