import { z } from 'zod'
import { ProductReviewSummaryUpdateWithoutProductInputObjectSchema } from './ProductReviewSummaryUpdateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedUpdateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedUpdateWithoutProductInput.schema'
import { ProductReviewSummaryCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryUpsertWithoutProductInput> =
  z
    .object({
      update: z.union([
        z.lazy(() => ProductReviewSummaryUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductReviewSummaryCreateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewSummaryUpsertWithoutProductInputObjectSchema = Schema
