import { z } from 'zod'
import { CategoryCreateWithoutProceduresInputObjectSchema } from './CategoryCreateWithoutProceduresInput.schema'
import { CategoryUncheckedCreateWithoutProceduresInputObjectSchema } from './CategoryUncheckedCreateWithoutProceduresInput.schema'
import { CategoryCreateOrConnectWithoutProceduresInputObjectSchema } from './CategoryCreateOrConnectWithoutProceduresInput.schema'
import { CategoryUpsertWithoutProceduresInputObjectSchema } from './CategoryUpsertWithoutProceduresInput.schema'
import { CategoryWhereUniqueInputObjectSchema } from './CategoryWhereUniqueInput.schema'
import { CategoryUpdateWithoutProceduresInputObjectSchema } from './CategoryUpdateWithoutProceduresInput.schema'
import { CategoryUncheckedUpdateWithoutProceduresInputObjectSchema } from './CategoryUncheckedUpdateWithoutProceduresInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CategoryUpdateOneRequiredWithoutProceduresNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CategoryCreateWithoutProceduresInputObjectSchema),
          z.lazy(
            () => CategoryUncheckedCreateWithoutProceduresInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => CategoryCreateOrConnectWithoutProceduresInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => CategoryUpsertWithoutProceduresInputObjectSchema)
        .optional(),
      connect: z.lazy(() => CategoryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => CategoryUpdateWithoutProceduresInputObjectSchema),
          z.lazy(
            () => CategoryUncheckedUpdateWithoutProceduresInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const CategoryUpdateOneRequiredWithoutProceduresNestedInputObjectSchema =
  Schema
