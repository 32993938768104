import { z } from 'zod'
import { CaseSummaryArgsObjectSchema } from './CaseSummaryArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    productVariantId: z.boolean().optional(),
    amount: z.boolean().optional(),
    usage: z.boolean().optional(),
    useAmount: z.boolean().optional(),
    useUnit: z.boolean().optional(),
    useFrequency: z.boolean().optional(),
    useTime: z.boolean().optional(),
    useDuration: z.boolean().optional(),
    useDurationUnit: z.boolean().optional(),
    refillTime: z.boolean().optional(),
    refillTimeUnit: z.boolean().optional(),
    note: z.boolean().optional(),
    caseSummary: z
      .union([z.boolean(), z.lazy(() => CaseSummaryArgsObjectSchema)])
      .optional(),
    caseSummaryId: z.boolean().optional(),
    warehouse: z.boolean().optional(),
  })
  .strict()

export const SuggestedProductItemOriginalSelectObjectSchema = Schema
