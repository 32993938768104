import { z } from 'zod'
import { CoverPictureCreateInputObjectSchema } from './objects/CoverPictureCreateInput.schema'
import { CoverPictureUncheckedCreateInputObjectSchema } from './objects/CoverPictureUncheckedCreateInput.schema'

export const CoverPictureCreateOneSchema = z.object({
  data: z.union([
    CoverPictureCreateInputObjectSchema,
    CoverPictureUncheckedCreateInputObjectSchema,
  ]),
})
