import { Box, Grid, Typography } from '@mui/material'
import { PartnerTypeSchema } from '@ssch-backend/partners'
import { FC } from 'react'
import { BooleanInput, required, useLocaleState } from 'react-admin'
import { CustomNumberInput } from '../../../components/CustomInput'
import { FormDivider } from '../../../components/FormInput/divider'
import {
  FormSelectInput,
  FormTextInput,
} from '../../../components/FormInput/input'
import { getPartnerTypeLabel } from '../../../enumLabel'
import { PartnerLogoPicture } from './partnerLogoPicture'

export const PartnerInfo: FC = () => {
  const [locale] = useLocaleState()
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูล Partner
      </Typography>
      <FormDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อ"
            source="nameTr.th"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextInput
            label="ชื่อ (Eng.)"
            source="nameTr.en"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PartnerLogoPicture source="logo" label="รูป logo" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormSelectInput
            label={'ประเภท'}
            source="type"
            validate={required()}
            choices={Object.keys(PartnerTypeSchema.Enum).map((key: unknown) => {
              return {
                id: key,
                name: getPartnerTypeLabel(
                  key as keyof typeof PartnerTypeSchema.Enum,
                )[locale === 'th' ? 'th' : 'en'],
              }
            })}
          />
          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <CustomNumberInput
              validate={required()}
              source="priority"
              label="Priority"
              min={0}
              defaultValue={0}
            />
            <BooleanInput source="active" defaultValue={true} />
            <BooleanInput
              source="isSXEasy"
              defaultValue={false}
              label="SXEasy"
            />
            <BooleanInput source="isExpertise" defaultValue={false} />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
