import { Box, Divider, Typography } from '@mui/material'
import axios from 'axios'
import { Edit, SaveButton, SimpleForm, Toolbar, required } from 'react-admin'
import { CustomBooleanField } from '../../components/CustomField'
import { CustomTextInput } from '../../components/CustomInput'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ImageStorageInput } from '../../components/ImageStorageInput'
import { adminGatewayClient } from '../../service'
import { ActiveButton } from './ActiveButton'
import { transformDoctorBanner } from './transform'

const DoctorBannerEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctorBanner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

export const DoctorBannerEdit = () => {
  return (
    <Edit transform={transformDoctorBanner}>
      <SimpleForm toolbar={<DoctorBannerEditToolbar />}>
        <CustomTextInput source="name" validate={required()} />
        <ImageStorageInput
          source="picture"
          label="Picture"
          accept={{ 'image/*': [] }}
          validate={required()}
          upload={async (file, { onProgress, abortController }) => {
            const uploadUrlMeta =
              await adminGatewayClient.doctorBanner.generateUploadPictureUrl.query(
                { extension: file.name.split('.').pop() ?? '' },
              )
            await axios.request({
              method: 'PUT',
              url: uploadUrlMeta.uploadUrl,
              headers: {
                'content-type': file.type,
              },
              data: file,
              onUploadProgress: (progressEvent) => {
                onProgress(progressEvent.loaded / progressEvent.total)
              },
              signal: abortController.signal,
            })
            return uploadUrlMeta
          }}
        ></ImageStorageInput>
        <CustomTextInput source="actionUri" />
        <Divider sx={{ width: '100%', mb: 2 }} />
        <div style={{ display: 'flex', marginBottom: 4 }}>
          <Typography variant="body2">Show Status: </Typography>
          <CustomBooleanField label="Status" source="active" />
        </div>
        <ActiveButton />
      </SimpleForm>
    </Edit>
  )
}
