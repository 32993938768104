import { z } from 'zod'
import { PartnerUpdateWithoutSkuInputObjectSchema } from './PartnerUpdateWithoutSkuInput.schema'
import { PartnerUncheckedUpdateWithoutSkuInputObjectSchema } from './PartnerUncheckedUpdateWithoutSkuInput.schema'
import { PartnerCreateWithoutSkuInputObjectSchema } from './PartnerCreateWithoutSkuInput.schema'
import { PartnerUncheckedCreateWithoutSkuInputObjectSchema } from './PartnerUncheckedCreateWithoutSkuInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpsertWithoutSkuInput> = z
  .object({
    update: z.union([
      z.lazy(() => PartnerUpdateWithoutSkuInputObjectSchema),
      z.lazy(() => PartnerUncheckedUpdateWithoutSkuInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutSkuInputObjectSchema),
      z.lazy(() => PartnerUncheckedCreateWithoutSkuInputObjectSchema),
    ]),
  })
  .strict()

export const PartnerUpsertWithoutSkuInputObjectSchema = Schema
