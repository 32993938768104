import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { StringWithAggregatesFilterObjectSchema } from './StringWithAggregatesFilter.schema'
import { EnumGenderWithAggregatesFilterObjectSchema } from './EnumGenderWithAggregatesFilter.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { StringNullableWithAggregatesFilterObjectSchema } from './StringNullableWithAggregatesFilter.schema'
import { EnumPregnancyStateNullableWithAggregatesFilterObjectSchema } from './EnumPregnancyStateNullableWithAggregatesFilter.schema'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => HealthInfoScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => HealthInfoScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => HealthInfoScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => HealthInfoScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => HealthInfoScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    id: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    name: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    gender: z
      .union([
        z.lazy(() => EnumGenderWithAggregatesFilterObjectSchema),
        z.lazy(() => GenderSchema),
      ])
      .optional(),
    birthdate: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    drugAllergy: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    medication: z
      .union([
        z.lazy(() => StringNullableWithAggregatesFilterObjectSchema),
        z.string(),
      ])
      .optional()
      .nullable(),
    pregnancyState: z
      .union([
        z.lazy(
          () => EnumPregnancyStateNullableWithAggregatesFilterObjectSchema,
        ),
        z.lazy(() => PregnancyStateSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const HealthInfoScalarWhereWithAggregatesInputObjectSchema = Schema
