import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptSelect> = z
  .object({
    receiptNumber: z.boolean().optional(),
    receiptUrl: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
    receiptData: z.boolean().optional(),
    isValid: z.boolean().optional(),
    note: z.boolean().optional(),
  })
  .strict()

export const ReceiptSelectObjectSchema = Schema
