import { z } from 'zod'
import { DeliveryLogWhereUniqueInputObjectSchema } from './DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogUpdateWithoutDeliveryInputObjectSchema } from './DeliveryLogUpdateWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedUpdateWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedUpdateWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInput> =
  z
    .object({
      where: z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => DeliveryLogUpdateWithoutDeliveryInputObjectSchema),
        z.lazy(
          () => DeliveryLogUncheckedUpdateWithoutDeliveryInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const DeliveryLogUpdateWithWhereUniqueWithoutDeliveryInputObjectSchema =
  Schema
