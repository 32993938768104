import { z } from 'zod'
import { ProductItemUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema } from './ProductItemUncheckedCreateNestedManyWithoutDeliveryInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUncheckedCreateWithoutDeliveryLogsInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      shippingProvider: z.string(),
      trackingNumber: z.string(),
      status: z.string(),
      deliveredAt: z.date().optional().nullable(),
      partnerName: z.string(),
      orderId: z.number(),
      productItems: z
        .lazy(
          () =>
            ProductItemUncheckedCreateNestedManyWithoutDeliveryInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const DeliveryUncheckedCreateWithoutDeliveryLogsInputObjectSchema =
  Schema
