import { z } from 'zod'
import { CoinTransactionCreateManyPatientInputObjectSchema } from './CoinTransactionCreateManyPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.CoinTransactionCreateManyPatientInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => CoinTransactionCreateManyPatientInputObjectSchema),
        z.lazy(() => CoinTransactionCreateManyPatientInputObjectSchema).array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const CoinTransactionCreateManyPatientInputEnvelopeObjectSchema = Schema
