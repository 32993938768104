import { z } from 'zod'

export const ProcedureReviewSummaryScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'procedureId',
  'partnerId',
  'overallRating',
  'environmentRating',
  'serviceRating',
  'specialtyRating',
  'valueRating',
  'countForRating5',
  'countForRating4',
  'countForRating3',
  'countForRating2',
  'countForRating1',
])
