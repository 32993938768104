import { z } from 'zod'
import { CarouselCreateNestedOneWithoutItemsInputObjectSchema } from './CarouselCreateNestedOneWithoutItemsInput.schema'
import { ProcedureCreateNestedOneWithoutCarouselItemsInputObjectSchema } from './ProcedureCreateNestedOneWithoutCarouselItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemCreateInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    active: z.boolean().optional(),
    priority: z.number(),
    carousel: z.lazy(
      () => CarouselCreateNestedOneWithoutItemsInputObjectSchema,
    ),
    procedure: z.lazy(
      () => ProcedureCreateNestedOneWithoutCarouselItemsInputObjectSchema,
    ),
  })
  .strict()

export const CarouselItemCreateInputObjectSchema = Schema
