import { z } from 'zod'
import { SuggestedProcedureItemOrderByWithRelationInputObjectSchema } from './objects/SuggestedProcedureItemOrderByWithRelationInput.schema'
import { SuggestedProcedureItemWhereInputObjectSchema } from './objects/SuggestedProcedureItemWhereInput.schema'
import { SuggestedProcedureItemWhereUniqueInputObjectSchema } from './objects/SuggestedProcedureItemWhereUniqueInput.schema'
import { SuggestedProcedureItemCountAggregateInputObjectSchema } from './objects/SuggestedProcedureItemCountAggregateInput.schema'
import { SuggestedProcedureItemMinAggregateInputObjectSchema } from './objects/SuggestedProcedureItemMinAggregateInput.schema'
import { SuggestedProcedureItemMaxAggregateInputObjectSchema } from './objects/SuggestedProcedureItemMaxAggregateInput.schema'
import { SuggestedProcedureItemAvgAggregateInputObjectSchema } from './objects/SuggestedProcedureItemAvgAggregateInput.schema'
import { SuggestedProcedureItemSumAggregateInputObjectSchema } from './objects/SuggestedProcedureItemSumAggregateInput.schema'

export const SuggestedProcedureItemAggregateSchema = z.object({
  orderBy: z
    .union([
      SuggestedProcedureItemOrderByWithRelationInputObjectSchema,
      SuggestedProcedureItemOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SuggestedProcedureItemWhereInputObjectSchema.optional(),
  cursor: SuggestedProcedureItemWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      SuggestedProcedureItemCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: SuggestedProcedureItemMinAggregateInputObjectSchema.optional(),
  _max: SuggestedProcedureItemMaxAggregateInputObjectSchema.optional(),
  _avg: SuggestedProcedureItemAvgAggregateInputObjectSchema.optional(),
  _sum: SuggestedProcedureItemSumAggregateInputObjectSchema.optional(),
})
