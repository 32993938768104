import { z } from 'zod'
import { RefundWhereUniqueInputObjectSchema } from './objects/RefundWhereUniqueInput.schema'
import { RefundCreateInputObjectSchema } from './objects/RefundCreateInput.schema'
import { RefundUncheckedCreateInputObjectSchema } from './objects/RefundUncheckedCreateInput.schema'
import { RefundUpdateInputObjectSchema } from './objects/RefundUpdateInput.schema'
import { RefundUncheckedUpdateInputObjectSchema } from './objects/RefundUncheckedUpdateInput.schema'

export const RefundUpsertSchema = z.object({
  where: RefundWhereUniqueInputObjectSchema,
  create: z.union([
    RefundCreateInputObjectSchema,
    RefundUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    RefundUpdateInputObjectSchema,
    RefundUncheckedUpdateInputObjectSchema,
  ]),
})
