import { z } from 'zod'
import { BrandBannerCreateWithoutBrandInputObjectSchema } from './BrandBannerCreateWithoutBrandInput.schema'
import { BrandBannerUncheckedCreateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedCreateWithoutBrandInput.schema'
import { BrandBannerCreateOrConnectWithoutBrandInputObjectSchema } from './BrandBannerCreateOrConnectWithoutBrandInput.schema'
import { BrandBannerUpsertWithWhereUniqueWithoutBrandInputObjectSchema } from './BrandBannerUpsertWithWhereUniqueWithoutBrandInput.schema'
import { BrandBannerCreateManyBrandInputEnvelopeObjectSchema } from './BrandBannerCreateManyBrandInputEnvelope.schema'
import { BrandBannerWhereUniqueInputObjectSchema } from './BrandBannerWhereUniqueInput.schema'
import { BrandBannerUpdateWithWhereUniqueWithoutBrandInputObjectSchema } from './BrandBannerUpdateWithWhereUniqueWithoutBrandInput.schema'
import { BrandBannerUpdateManyWithWhereWithoutBrandInputObjectSchema } from './BrandBannerUpdateManyWithWhereWithoutBrandInput.schema'
import { BrandBannerScalarWhereInputObjectSchema } from './BrandBannerScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerUpdateManyWithoutBrandNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema),
        z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema).array(),
        z.lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema),
        z
          .lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema)
          .array(),
      ])
      .optional(),
    connectOrCreate: z
      .union([
        z.lazy(() => BrandBannerCreateOrConnectWithoutBrandInputObjectSchema),
        z
          .lazy(() => BrandBannerCreateOrConnectWithoutBrandInputObjectSchema)
          .array(),
      ])
      .optional(),
    upsert: z
      .union([
        z.lazy(
          () => BrandBannerUpsertWithWhereUniqueWithoutBrandInputObjectSchema,
        ),
        z
          .lazy(
            () => BrandBannerUpsertWithWhereUniqueWithoutBrandInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    createMany: z
      .lazy(() => BrandBannerCreateManyBrandInputEnvelopeObjectSchema)
      .optional(),
    set: z
      .union([
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    disconnect: z
      .union([
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    delete: z
      .union([
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    connect: z
      .union([
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
        z.lazy(() => BrandBannerWhereUniqueInputObjectSchema).array(),
      ])
      .optional(),
    update: z
      .union([
        z.lazy(
          () => BrandBannerUpdateWithWhereUniqueWithoutBrandInputObjectSchema,
        ),
        z
          .lazy(
            () => BrandBannerUpdateWithWhereUniqueWithoutBrandInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    updateMany: z
      .union([
        z.lazy(
          () => BrandBannerUpdateManyWithWhereWithoutBrandInputObjectSchema,
        ),
        z
          .lazy(
            () => BrandBannerUpdateManyWithWhereWithoutBrandInputObjectSchema,
          )
          .array(),
      ])
      .optional(),
    deleteMany: z
      .union([
        z.lazy(() => BrandBannerScalarWhereInputObjectSchema),
        z.lazy(() => BrandBannerScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
  })
  .strict()

export const BrandBannerUpdateManyWithoutBrandNestedInputObjectSchema = Schema
