import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProductItemCountOrderByAggregateInputObjectSchema } from './ProductItemCountOrderByAggregateInput.schema'
import { ProductItemAvgOrderByAggregateInputObjectSchema } from './ProductItemAvgOrderByAggregateInput.schema'
import { ProductItemMaxOrderByAggregateInputObjectSchema } from './ProductItemMaxOrderByAggregateInput.schema'
import { ProductItemMinOrderByAggregateInputObjectSchema } from './ProductItemMinOrderByAggregateInput.schema'
import { ProductItemSumOrderByAggregateInputObjectSchema } from './ProductItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    sku: z.lazy(() => SortOrderSchema).optional(),
    variantId: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    drugType: z.lazy(() => SortOrderSchema).optional(),
    quantity: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingUnit: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    flashSaleId: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    deliveryId: z.lazy(() => SortOrderSchema).optional(),
    refundId: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    canExpress: z.lazy(() => SortOrderSchema).optional(),
    reviewId: z.lazy(() => SortOrderSchema).optional(),
    reviewStartAt: z.lazy(() => SortOrderSchema).optional(),
    reviewExpireAt: z.lazy(() => SortOrderSchema).optional(),
    warehouse: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProductItemCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProductItemAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProductItemMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProductItemMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProductItemSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductItemOrderByWithAggregationInputObjectSchema = Schema
