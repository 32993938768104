import { z } from 'zod'
import { CouponGroupUpdateWithoutCouponsInputObjectSchema } from './CouponGroupUpdateWithoutCouponsInput.schema'
import { CouponGroupUncheckedUpdateWithoutCouponsInputObjectSchema } from './CouponGroupUncheckedUpdateWithoutCouponsInput.schema'
import { CouponGroupCreateWithoutCouponsInputObjectSchema } from './CouponGroupCreateWithoutCouponsInput.schema'
import { CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema } from './CouponGroupUncheckedCreateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.CouponGroupUpsertWithoutCouponsInput> = z
  .object({
    update: z.union([
      z.lazy(() => CouponGroupUpdateWithoutCouponsInputObjectSchema),
      z.lazy(() => CouponGroupUncheckedUpdateWithoutCouponsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => CouponGroupCreateWithoutCouponsInputObjectSchema),
      z.lazy(() => CouponGroupUncheckedCreateWithoutCouponsInputObjectSchema),
    ]),
  })
  .strict()

export const CouponGroupUpsertWithoutCouponsInputObjectSchema = Schema
