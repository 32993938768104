import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'
import { EnumConsultingStatusFieldUpdateOperationsInputObjectSchema } from './EnumConsultingStatusFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { CaseUpdateOneWithoutTeleconsultNestedInputObjectSchema } from './CaseUpdateOneWithoutTeleconsultNestedInput.schema'
import { CaseUpdateOneWithoutMissedCallTeleconsultNestedInputObjectSchema } from './CaseUpdateOneWithoutMissedCallTeleconsultNestedInput.schema'
import { AppointmentUpdateOneWithoutTeleconsultNestedInputObjectSchema } from './AppointmentUpdateOneWithoutTeleconsultNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpdateInput> = z
  .object({
    doctorId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    patientId: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    videoRoomId: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    doctorVideoRoomUid: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    patientVideoRoomUid: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    startAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    endAt: z
      .union([
        z.date(),
        z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    callTime: z
      .union([
        z.number(),
        z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => ConsultingStatusSchema),
        z.lazy(
          () => EnumConsultingStatusFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional(),
    remark: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    case: z
      .lazy(() => CaseUpdateOneWithoutTeleconsultNestedInputObjectSchema)
      .optional(),
    missedCallCase: z
      .lazy(
        () => CaseUpdateOneWithoutMissedCallTeleconsultNestedInputObjectSchema,
      )
      .optional(),
    appointment: z
      .lazy(() => AppointmentUpdateOneWithoutTeleconsultNestedInputObjectSchema)
      .optional(),
  })
  .strict()

export const TeleconsultUpdateInputObjectSchema = Schema
