import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryCreateManyInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    procedureId: z.number(),
    partnerId: z.number(),
    overallRating: z.number(),
    environmentRating: z.number(),
    serviceRating: z.number(),
    specialtyRating: z.number(),
    valueRating: z.number(),
    countForRating5: z.number().optional(),
    countForRating4: z.number().optional(),
    countForRating3: z.number().optional(),
    countForRating2: z.number().optional(),
    countForRating1: z.number().optional(),
  })
  .strict()

export const ProcedureReviewSummaryCreateManyInputObjectSchema = Schema
