import { z } from 'zod'
import { ProcedureReviewSummaryUpdateInputObjectSchema } from './objects/ProcedureReviewSummaryUpdateInput.schema'
import { ProcedureReviewSummaryUncheckedUpdateInputObjectSchema } from './objects/ProcedureReviewSummaryUncheckedUpdateInput.schema'
import { ProcedureReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProcedureReviewSummaryWhereUniqueInput.schema'

export const ProcedureReviewSummaryUpdateOneSchema = z.object({
  data: z.union([
    ProcedureReviewSummaryUpdateInputObjectSchema,
    ProcedureReviewSummaryUncheckedUpdateInputObjectSchema,
  ]),
  where: ProcedureReviewSummaryWhereUniqueInputObjectSchema,
})
