import { z } from 'zod'
import { ProductItemUpdateInputObjectSchema } from './objects/ProductItemUpdateInput.schema'
import { ProductItemUncheckedUpdateInputObjectSchema } from './objects/ProductItemUncheckedUpdateInput.schema'
import { ProductItemWhereUniqueInputObjectSchema } from './objects/ProductItemWhereUniqueInput.schema'

export const ProductItemUpdateOneSchema = z.object({
  data: z.union([
    ProductItemUpdateInputObjectSchema,
    ProductItemUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductItemWhereUniqueInputObjectSchema,
})
