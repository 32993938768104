import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithoutVariantInputObjectSchema } from './ProductReviewUpdateWithoutVariantInput.schema'
import { ProductReviewUncheckedUpdateWithoutVariantInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutVariantInput.schema'
import { ProductReviewCreateWithoutVariantInputObjectSchema } from './ProductReviewCreateWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpsertWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => ProductReviewUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewUpsertWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
