import { z } from 'zod'
import { PatientArgsObjectSchema } from './PatientArgs.schema'
import { RejectReasonFindManySchema } from '../findManyRejectReason.schema'
import { KycDataCountOutputTypeArgsObjectSchema } from './KycDataCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    patient: z
      .union([z.boolean(), z.lazy(() => PatientArgsObjectSchema)])
      .optional(),
    patientId: z.boolean().optional(),
    channel: z.boolean().optional(),
    status: z.boolean().optional(),
    approvedAt: z.boolean().optional(),
    approvedBy: z.boolean().optional(),
    prefix: z.boolean().optional(),
    firstname: z.boolean().optional(),
    lastname: z.boolean().optional(),
    gender: z.boolean().optional(),
    birthDate: z.boolean().optional(),
    idCardNumber: z.boolean().optional(),
    idCardPicture: z.boolean().optional(),
    selfieIdCardPicture: z.boolean().optional(),
    passportNumber: z.boolean().optional(),
    passportPicture: z.boolean().optional(),
    selfiePassportPicture: z.boolean().optional(),
    rejectReason: z
      .union([z.boolean(), z.lazy(() => RejectReasonFindManySchema)])
      .optional(),
    rejectAt: z.boolean().optional(),
    rejectBy: z.boolean().optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => KycDataCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const KycDataSelectObjectSchema = Schema
