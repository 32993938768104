/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton } from '@mui/material'
import { FC, ReactNode } from 'react'
import {
  BaseEditor,
  BaseText,
  Editor,
  Range,
  Element as SlateElement,
  Node as SlateNode,
  Transforms,
} from 'slate'
import { useSlate } from 'slate-react'
import { LinkElement } from './RenderComponent'

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

/*
 *Button status & action
 */
export const isMarkActive = (editor: BaseEditor, format: string): boolean => {
  const mark = Editor.marks(editor) as Omit<BaseText, 'text'> | null
  return mark ? Boolean(mark[format as keyof Omit<BaseText, 'text'>]) : false
}
export const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) Editor.removeMark(editor, format)
  else Editor.addMark(editor, format, true)
}

export const isBlockActive = (
  editor: BaseEditor,
  format: string,
  blockType = 'type',
): boolean => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as never)[blockType] === format,
    }),
  )

  return !!match
}
export const toggleBlock = (editor: BaseEditor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: SlateNode) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n['type' as keyof SlateNode]) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: any
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const insertLink = (editor: BaseEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}
const isLinkActive = (editor: BaseEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n as any).type === 'link',
  })
  return !!link
}

const unwrapLink = (editor: BaseEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n as any).type === 'link',
  })
}
const wrapLink = (editor: BaseEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  console.log(isCollapsed)

  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true, mode: 'all' })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

/*
 *Button component
 */
interface SlateToolbarMarkButtonProps {
  format: string
  icon: ReactNode
}
export const SlateToolbarMarkButton: FC<SlateToolbarMarkButtonProps> = ({
  format,
  icon,
}) => {
  const editor = useSlate()

  return (
    <IconButton
      sx={{ color: isMarkActive(editor, format) ? '#1976d2' : '#666666' }}
      onClick={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </IconButton>
  )
}

interface SlateToolbarBlockButtonProps {
  format: string
  icon: ReactNode
}
export const SlateToolbarBlockButton: FC<SlateToolbarBlockButtonProps> = ({
  format,
  icon,
}) => {
  const editor = useSlate()

  return (
    <IconButton
      sx={{
        color: isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
        )
          ? '#1976d2'
          : '#666666',
      }}
      onClick={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </IconButton>
  )
}

interface SlateToolbarInsertLinkButtonProps {
  icon: ReactNode
}
export const SlateToolbarInsertLinkButton: FC<
  SlateToolbarInsertLinkButtonProps
> = ({ icon }) => {
  const editor = useSlate()

  return (
    <IconButton
      sx={{ color: '#666666' }}
      onClick={(event) => {
        event.preventDefault()
        const url = prompt('Enter a URL')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      {icon}
    </IconButton>
  )
}
