import { z } from 'zod'
import { RefundCreateWithoutProcedureItemsInputObjectSchema } from './RefundCreateWithoutProcedureItemsInput.schema'
import { RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProcedureItemsInput.schema'
import { RefundCreateOrConnectWithoutProcedureItemsInputObjectSchema } from './RefundCreateOrConnectWithoutProcedureItemsInput.schema'
import { RefundWhereUniqueInputObjectSchema } from './RefundWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundCreateNestedOneWithoutProcedureItemsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => RefundCreateWithoutProcedureItemsInputObjectSchema),
          z.lazy(
            () => RefundUncheckedCreateWithoutProcedureItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => RefundCreateOrConnectWithoutProcedureItemsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => RefundWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const RefundCreateNestedOneWithoutProcedureItemsInputObjectSchema =
  Schema
