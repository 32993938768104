import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'
import { DeliveryArgsObjectSchema } from './DeliveryArgs.schema'
import { RefundArgsObjectSchema } from './RefundArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    sku: z.boolean().optional(),
    variantId: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    picture: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    drugType: z.boolean().optional(),
    quantity: z.boolean().optional(),
    tagPrice: z.boolean().optional(),
    sellingPrice: z.boolean().optional(),
    sellingUnit: z.boolean().optional(),
    cost: z.boolean().optional(),
    flashSaleId: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
    delivery: z
      .union([z.boolean(), z.lazy(() => DeliveryArgsObjectSchema)])
      .optional(),
    deliveryId: z.boolean().optional(),
    refund: z
      .union([z.boolean(), z.lazy(() => RefundArgsObjectSchema)])
      .optional(),
    refundId: z.boolean().optional(),
    v2DrugItemId: z.boolean().optional(),
    canExpress: z.boolean().optional(),
    reviewId: z.boolean().optional(),
    reviewStartAt: z.boolean().optional(),
    reviewExpireAt: z.boolean().optional(),
    warehouse: z.boolean().optional(),
  })
  .strict()

export const ProductItemSelectObjectSchema = Schema
