import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressMaxAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    recipient: z.literal(true).optional(),
    address: z.literal(true).optional(),
    phone: z.literal(true).optional(),
    province: z.literal(true).optional(),
    district: z.literal(true).optional(),
    subDistrict: z.literal(true).optional(),
    postCode: z.literal(true).optional(),
    provinceKey: z.literal(true).optional(),
    districtKey: z.literal(true).optional(),
    subDistrictKey: z.literal(true).optional(),
    note: z.literal(true).optional(),
    tag: z.literal(true).optional(),
    isDefault: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
  })
  .strict()

export const AddressMaxAggregateInputObjectSchema = Schema
