import { z } from 'zod'
import { VariantCreateWithoutProductReviewsInputObjectSchema } from './VariantCreateWithoutProductReviewsInput.schema'
import { VariantUncheckedCreateWithoutProductReviewsInputObjectSchema } from './VariantUncheckedCreateWithoutProductReviewsInput.schema'
import { VariantCreateOrConnectWithoutProductReviewsInputObjectSchema } from './VariantCreateOrConnectWithoutProductReviewsInput.schema'
import { VariantUpsertWithoutProductReviewsInputObjectSchema } from './VariantUpsertWithoutProductReviewsInput.schema'
import { VariantWhereUniqueInputObjectSchema } from './VariantWhereUniqueInput.schema'
import { VariantUpdateWithoutProductReviewsInputObjectSchema } from './VariantUpdateWithoutProductReviewsInput.schema'
import { VariantUncheckedUpdateWithoutProductReviewsInputObjectSchema } from './VariantUncheckedUpdateWithoutProductReviewsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpdateOneRequiredWithoutProductReviewsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantCreateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => VariantUncheckedCreateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => VariantCreateOrConnectWithoutProductReviewsInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => VariantUpsertWithoutProductReviewsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => VariantWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => VariantUpdateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => VariantUncheckedUpdateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const VariantUpdateOneRequiredWithoutProductReviewsNestedInputObjectSchema =
  Schema
