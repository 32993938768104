import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemCreateManyInput> = z
  .object({
    id: z.number().optional(),
    caseSummaryId: z.number(),
    procedureId: z.number(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    amount: z.number(),
    note: z.string().optional().nullable(),
  })
  .strict()

export const SuggestedProcedureItemCreateManyInputObjectSchema = Schema
