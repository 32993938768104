import { z } from 'zod'
import { BranchWhereUniqueInputObjectSchema } from './BranchWhereUniqueInput.schema'
import { BranchCreateWithoutOfficeHoursInputObjectSchema } from './BranchCreateWithoutOfficeHoursInput.schema'
import { BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema } from './BranchUncheckedCreateWithoutOfficeHoursInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchCreateOrConnectWithoutOfficeHoursInput> = z
  .object({
    where: z.lazy(() => BranchWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => BranchCreateWithoutOfficeHoursInputObjectSchema),
      z.lazy(() => BranchUncheckedCreateWithoutOfficeHoursInputObjectSchema),
    ]),
  })
  .strict()

export const BranchCreateOrConnectWithoutOfficeHoursInputObjectSchema = Schema
