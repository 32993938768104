import { z } from 'zod'
import { VariantWarehouseCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutWarehouseInput.schema'
import { VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateOrConnectWithoutWarehouseInput.schema'
import { VariantWarehouseCreateManyWarehouseInputEnvelopeObjectSchema } from './VariantWarehouseCreateManyWarehouseInputEnvelope.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateNestedManyWithoutWarehouseInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema),
          z
            .lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantWarehouseCreateManyWarehouseInputEnvelopeObjectSchema,
        )
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const VariantWarehouseCreateNestedManyWithoutWarehouseInputObjectSchema =
  Schema
