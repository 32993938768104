import * as z from "zod"
import { CompleteProcedure, RelatedProcedureModel } from "./index"

export const ProcedurePictureModel = z.object({
  id: z.number().int(),
  url: z.string(),
  rank: z.number().int(),
  procedureId: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteProcedurePicture extends z.infer<typeof ProcedurePictureModel> {
  procedure: CompleteProcedure
}

/**
 * RelatedProcedurePictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedurePictureModel: z.ZodSchema<CompleteProcedurePicture> = z.lazy(() => ProcedurePictureModel.extend({
  procedure: RelatedProcedureModel,
}))
