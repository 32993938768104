import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewAvgAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    variantId: z.literal(true).optional(),
    productId: z.literal(true).optional(),
    brandId: z.literal(true).optional(),
    overallRating: z.literal(true).optional(),
    qualityRating: z.literal(true).optional(),
    shippingRating: z.literal(true).optional(),
    packagingRating: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    priority: z.literal(true).optional(),
  })
  .strict()

export const ProductReviewAvgAggregateInputObjectSchema = Schema
