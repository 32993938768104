import { z } from 'zod'
import { PartnerArgsObjectSchema } from './PartnerArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    rank: z.boolean().optional(),
    url: z.boolean().optional(),
    partner: z
      .union([z.boolean(), z.lazy(() => PartnerArgsObjectSchema)])
      .optional(),
    partnerId: z.boolean().optional(),
  })
  .strict()

export const CoverPictureSelectObjectSchema = Schema
