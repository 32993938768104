import { z } from 'zod'
import { PartnerUpdateWithoutProcedureOrderInputObjectSchema } from './PartnerUpdateWithoutProcedureOrderInput.schema'
import { PartnerUncheckedUpdateWithoutProcedureOrderInputObjectSchema } from './PartnerUncheckedUpdateWithoutProcedureOrderInput.schema'
import { PartnerCreateWithoutProcedureOrderInputObjectSchema } from './PartnerCreateWithoutProcedureOrderInput.schema'
import { PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema } from './PartnerUncheckedCreateWithoutProcedureOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerUpsertWithoutProcedureOrderInput> = z
  .object({
    update: z.union([
      z.lazy(() => PartnerUpdateWithoutProcedureOrderInputObjectSchema),
      z.lazy(
        () => PartnerUncheckedUpdateWithoutProcedureOrderInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => PartnerCreateWithoutProcedureOrderInputObjectSchema),
      z.lazy(
        () => PartnerUncheckedCreateWithoutProcedureOrderInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const PartnerUpsertWithoutProcedureOrderInputObjectSchema = Schema
