import { z } from 'zod'

export const ProductReviewSummaryScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'productId',
  'brandId',
  'overallRating',
  'qualityRating',
  'shippingRating',
  'packagingRating',
  'countForRating5',
  'countForRating4',
  'countForRating3',
  'countForRating2',
  'countForRating1',
])
