import { z } from 'zod'
import { DeliveryLogCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateWithoutDeliveryInput.schema'
import { DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema } from './DeliveryLogUncheckedCreateWithoutDeliveryInput.schema'
import { DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema } from './DeliveryLogCreateOrConnectWithoutDeliveryInput.schema'
import { DeliveryLogCreateManyDeliveryInputEnvelopeObjectSchema } from './DeliveryLogCreateManyDeliveryInputEnvelope.schema'
import { DeliveryLogWhereUniqueInputObjectSchema } from './DeliveryLogWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogCreateNestedManyWithoutDeliveryInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryLogCreateWithoutDeliveryInputObjectSchema),
          z
            .lazy(() => DeliveryLogCreateWithoutDeliveryInputObjectSchema)
            .array(),
          z.lazy(
            () => DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => DeliveryLogUncheckedCreateWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema,
          ),
          z
            .lazy(
              () => DeliveryLogCreateOrConnectWithoutDeliveryInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => DeliveryLogCreateManyDeliveryInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema),
          z.lazy(() => DeliveryLogWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const DeliveryLogCreateNestedManyWithoutDeliveryInputObjectSchema =
  Schema
