import * as z from "zod"
import { CompleteCase, RelatedCaseModel, CompleteAppointment, RelatedAppointmentModel } from "./index"

export const TeleconsultModel = z.object({
  id: z.number().int(),
  doctorId: z.number().int(),
  patientId: z.number().int(),
  videoRoomId: z.string().nullish(),
  doctorVideoRoomUid: z.number().int().nullish(),
  patientVideoRoomUid: z.number().int().nullish(),
  startAt: z.date().nullish(),
  endAt: z.date().nullish(),
  callTime: z.number().int().nullish(),
  status: z.enum(['IN_QUEUE', 'WAITING', 'IN_PROGRESS', 'SUCCESS', 'UNSUCCESS', 'MISSED_CALL']),
  caseId: z.number().int().nullish(),
  missedCallCaseId: z.number().int().nullish(),
  remark: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export interface CompleteTeleconsult extends z.infer<typeof TeleconsultModel> {
  case?: CompleteCase | null
  missedCallCase?: CompleteCase | null
  appointment?: CompleteAppointment | null
}

/**
 * RelatedTeleconsultModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedTeleconsultModel: z.ZodSchema<CompleteTeleconsult> = z.lazy(() => TeleconsultModel.extend({
  case: RelatedCaseModel.nullish(),
  missedCallCase: RelatedCaseModel.nullish(),
  appointment: RelatedAppointmentModel.nullish(),
}))
