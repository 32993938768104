import { z } from 'zod'
import { ProcedureReviewCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedCreateWithoutProcedureInput.schema'
import { ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema } from './ProcedureReviewCreateOrConnectWithoutProcedureInput.schema'
import { ProcedureReviewCreateManyProcedureInputEnvelopeObjectSchema } from './ProcedureReviewCreateManyProcedureInputEnvelope.schema'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUncheckedCreateNestedManyWithoutProcedureInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema),
          z
            .lazy(() => ProcedureReviewCreateWithoutProcedureInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewUncheckedCreateWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                ProcedureReviewCreateOrConnectWithoutProcedureInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProcedureReviewCreateManyProcedureInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProcedureReviewUncheckedCreateNestedManyWithoutProcedureInputObjectSchema =
  Schema
