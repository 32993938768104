import { z } from 'zod'
import { AppointmentWhereUniqueInputObjectSchema } from './AppointmentWhereUniqueInput.schema'
import { AppointmentCreateWithoutScheduleInputObjectSchema } from './AppointmentCreateWithoutScheduleInput.schema'
import { AppointmentUncheckedCreateWithoutScheduleInputObjectSchema } from './AppointmentUncheckedCreateWithoutScheduleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentCreateOrConnectWithoutScheduleInput> =
  z
    .object({
      where: z.lazy(() => AppointmentWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => AppointmentCreateWithoutScheduleInputObjectSchema),
        z.lazy(
          () => AppointmentUncheckedCreateWithoutScheduleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AppointmentCreateOrConnectWithoutScheduleInputObjectSchema = Schema
