import { z } from 'zod'
import { ExpressShippingAreaFindManySchema } from '../findManyExpressShippingArea.schema'
import { OrderFindManySchema } from '../findManyOrder.schema'
import { ShippingProviderCountOutputTypeArgsObjectSchema } from './ShippingProviderCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderSelect> = z
  .object({
    id: z.boolean().optional(),
    priority: z.boolean().optional(),
    name: z.boolean().optional(),
    detail: z.boolean().optional(),
    moreDetail: z.boolean().optional(),
    shippingExpectation: z.boolean().optional(),
    trackingUrl: z.boolean().optional(),
    type: z.boolean().optional(),
    price: z.boolean().optional(),
    freeShippingMinimumPrice: z.boolean().optional(),
    active: z.boolean().optional(),
    areas: z
      .union([z.boolean(), z.lazy(() => ExpressShippingAreaFindManySchema)])
      .optional(),
    orders: z
      .union([z.boolean(), z.lazy(() => OrderFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ShippingProviderCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ShippingProviderSelectObjectSchema = Schema
