import * as z from "zod"
import { jsonSchema } from "./utils/json"
import { CompleteOrder, RelatedOrderModel, CompleteRefund, RelatedRefundModel } from "./index"

export const ProcedureItemModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  procedureId: z.number().int(),
  partnerId: z.number().int(),
  nameTr: z.object({ en: z.string(), th: z.string() }),
  picture: z.string(),
  descriptionTr: z.object({ en: z.string(), th: z.string() }),
  quantity: z.number().int(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  cost: z.number(),
  priceAbsorbedBySkinX: z.number(),
  priceAbsorbedByPartner: z.number(),
  commissionRate: z.number(),
  flashSaleId: z.number().int().nullish(),
  expireType: z.enum(['DATE', 'DURATION']),
  expireAt: z.date().nullish(),
  expireDurationDay: z.number().int().nullish(),
  orderId: z.number().int(),
  refundId: z.number().int().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
})

export const ProcedureItemModelResponse = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  procedureId: z.number().int(),
  partnerId: z.number().int(),
  nameTr: jsonSchema,
  picture: z.string(),
  descriptionTr: jsonSchema,
  quantity: z.number().int(),
  tagPrice: z.number().nullish(),
  sellingPrice: z.number(),
  cost: z.number(),
  priceAbsorbedBySkinX: z.number(),
  priceAbsorbedByPartner: z.number(),
  commissionRate: z.number(),
  flashSaleId: z.number().int().nullish(),
  expireType: z.enum(['DATE', 'DURATION']),
  expireAt: z.date().nullish(),
  expireDurationDay: z.number().int().nullish(),
  orderId: z.number().int(),
  refundId: z.number().int().nullish(),
  v2DrugItemId: z.number().int().nullish(),
  reviewId: z.number().int().nullish(),
  reviewStartAt: z.date().nullish(),
  reviewExpireAt: z.date().nullish(),
})

export interface CompleteProcedureItem extends z.infer<typeof ProcedureItemModel> {
  order: CompleteOrder
  refund?: CompleteRefund | null
}

/**
 * RelatedProcedureItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProcedureItemModel: z.ZodSchema<CompleteProcedureItem> = z.lazy(() => ProcedureItemModel.extend({
  order: RelatedOrderModel,
  refund: RelatedRefundModel.nullish(),
}))
