import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerCountOutputTypeSelect> = z
  .object({
    users: z.boolean().optional(),
    branches: z.boolean().optional(),
    coverPictures: z.boolean().optional(),
    sku: z.boolean().optional(),
    ProcedureOrder: z.boolean().optional(),
  })
  .strict()

export const PartnerCountOutputTypeSelectObjectSchema = Schema
