import { z } from 'zod'
import { BrandBannerOrderByWithRelationInputObjectSchema } from './objects/BrandBannerOrderByWithRelationInput.schema'
import { BrandBannerWhereInputObjectSchema } from './objects/BrandBannerWhereInput.schema'
import { BrandBannerWhereUniqueInputObjectSchema } from './objects/BrandBannerWhereUniqueInput.schema'
import { BrandBannerScalarFieldEnumSchema } from './enums/BrandBannerScalarFieldEnum.schema'

export const BrandBannerFindManySchema = z.object({
  orderBy: z
    .union([
      BrandBannerOrderByWithRelationInputObjectSchema,
      BrandBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: BrandBannerWhereInputObjectSchema.optional(),
  cursor: BrandBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(BrandBannerScalarFieldEnumSchema).optional(),
})
