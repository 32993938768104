import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedurePictureCountOrderByAggregateInputObjectSchema } from './ProcedurePictureCountOrderByAggregateInput.schema'
import { ProcedurePictureAvgOrderByAggregateInputObjectSchema } from './ProcedurePictureAvgOrderByAggregateInput.schema'
import { ProcedurePictureMaxOrderByAggregateInputObjectSchema } from './ProcedurePictureMaxOrderByAggregateInput.schema'
import { ProcedurePictureMinOrderByAggregateInputObjectSchema } from './ProcedurePictureMinOrderByAggregateInput.schema'
import { ProcedurePictureSumOrderByAggregateInputObjectSchema } from './ProcedurePictureSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedurePictureOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    url: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedurePictureCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedurePictureAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedurePictureMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedurePictureMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedurePictureSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedurePictureOrderByWithAggregationInputObjectSchema = Schema
