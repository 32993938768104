import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { DiagnosisRelationFilterObjectSchema } from './DiagnosisRelationFilter.schema'
import { DiagnosisWhereInputObjectSchema } from './DiagnosisWhereInput.schema'
import { CaseSummaryRelationFilterObjectSchema } from './CaseSummaryRelationFilter.schema'
import { CaseSummaryWhereInputObjectSchema } from './CaseSummaryWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CaseSummaryDiagnosisWhereInputObjectSchema),
        z.lazy(() => CaseSummaryDiagnosisWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CaseSummaryDiagnosisWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CaseSummaryDiagnosisWhereInputObjectSchema),
        z.lazy(() => CaseSummaryDiagnosisWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    diagnosisId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    caseSummaryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    diagnosis: z
      .union([
        z.lazy(() => DiagnosisRelationFilterObjectSchema),
        z.lazy(() => DiagnosisWhereInputObjectSchema),
      ])
      .optional(),
    caseSummary: z
      .union([
        z.lazy(() => CaseSummaryRelationFilterObjectSchema),
        z.lazy(() => CaseSummaryWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CaseSummaryDiagnosisWhereInputObjectSchema = Schema
