import { z } from 'zod'
import { OrderUpdateWithoutProductItemsOriginalInputObjectSchema } from './OrderUpdateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedUpdateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedUpdateWithoutProductItemsOriginalInput.schema'
import { OrderCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderCreateWithoutProductItemsOriginalInput.schema'
import { OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema } from './OrderUncheckedCreateWithoutProductItemsOriginalInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutProductItemsOriginalInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutProductItemsOriginalInputObjectSchema),
      z.lazy(
        () => OrderUncheckedUpdateWithoutProductItemsOriginalInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutProductItemsOriginalInputObjectSchema),
      z.lazy(
        () => OrderUncheckedCreateWithoutProductItemsOriginalInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const OrderUpsertWithoutProductItemsOriginalInputObjectSchema = Schema
