import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCountOutputTypeSelect> = z
  .object({
    acceptedConsents: z.boolean().optional(),
    notifications: z.boolean().optional(),
    address: z.boolean().optional(),
    identities: z.boolean().optional(),
    coinTransactions: z.boolean().optional(),
    myReviews: z.boolean().optional(),
  })
  .strict()

export const PatientCountOutputTypeSelectObjectSchema = Schema
