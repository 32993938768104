import { z } from 'zod'
import { NotificationPatientWhereInputObjectSchema } from './objects/NotificationPatientWhereInput.schema'
import { NotificationPatientOrderByWithAggregationInputObjectSchema } from './objects/NotificationPatientOrderByWithAggregationInput.schema'
import { NotificationPatientScalarWhereWithAggregatesInputObjectSchema } from './objects/NotificationPatientScalarWhereWithAggregatesInput.schema'
import { NotificationPatientScalarFieldEnumSchema } from './enums/NotificationPatientScalarFieldEnum.schema'

export const NotificationPatientGroupBySchema = z.object({
  where: NotificationPatientWhereInputObjectSchema.optional(),
  orderBy: z.union([
    NotificationPatientOrderByWithAggregationInputObjectSchema,
    NotificationPatientOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having:
    NotificationPatientScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(NotificationPatientScalarFieldEnumSchema),
})
