import { z } from 'zod'
import { ProductReviewWhereInputObjectSchema } from './ProductReviewWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewRelationFilter> = z
  .object({
    is: z.lazy(() => ProductReviewWhereInputObjectSchema).optional(),
    isNot: z.lazy(() => ProductReviewWhereInputObjectSchema).optional(),
  })
  .strict()

export const ProductReviewRelationFilterObjectSchema = Schema
