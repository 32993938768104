import { Box } from '@mui/material'
import axios from 'axios'
import { Create, required, SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { CustomTextInput } from '../../components/CustomInput'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ImageStorageInput } from '../../components/ImageStorageInput'
import { adminGatewayClient } from '../../service'
import { transformProcedureBanner } from './transform'

const ProcedureBannerCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="procedureBanner" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

export const ProcedureBannerCreate = () => {
  return (
    <Create redirect="list" transform={transformProcedureBanner}>
      <SimpleForm toolbar={<ProcedureBannerCreateToolbar />}>
        <CustomTextInput source="name" validate={required()} />
        <ImageStorageInput
          source="picture"
          label="Picture"
          accept={{ 'image/*': [] }}
          validate={required()}
          upload={async (file, { onProgress, abortController }) => {
            const uploadUrlMeta =
              await adminGatewayClient.procedureBanner.generateUploadPictureUrl.query(
                { extension: file.name.split('.').pop() ?? '' },
              )
            await axios.request({
              method: 'PUT',
              url: uploadUrlMeta.uploadUrl,
              headers: {
                'content-type': file.type,
              },
              data: file,
              onUploadProgress: (progressEvent) => {
                onProgress(progressEvent.loaded / progressEvent.total)
              },
              signal: abortController.signal,
            })
            return uploadUrlMeta
          }}
        ></ImageStorageInput>
        <CustomTextInput source="actionUri" />
      </SimpleForm>
    </Create>
  )
}
