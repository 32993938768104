import { Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { FormImageInput } from './formImageInput'
import { FormSelectInput } from './formSelectInput'
import { FormTextInput } from './formTextInput'

export const ProfileSection: FC = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลเบื้องต้น
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <FormImageInput source="picture" label="รูปคุณหมอ" />
        </Grid>
        <Grid item xs={12} lg={7}>
          <FormTextInput source="prefix" label="คำนำหน้า" />
          <FormTextInput source="firstname" label="ชื่อจริง" />
          <FormTextInput source="lastname" label="นามสกุล" />
          <Divider sx={{ mb: 2.5 }} />
          <FormSelectInput
            source="gender"
            label="เพศ"
            choices={[
              { id: 'MALE', name: 'ชาย' },
              { id: 'FEMALE', name: 'หญิง' },
            ]}
          />
          <FormTextInput source="nickname" label="ชื่อเล่น" />
          <FormTextInput source="phone" label="เบอร์โทรศัพท์" />
        </Grid>
      </Grid>
    </>
  )
}
