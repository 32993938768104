import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewCreateWithoutVariantInputObjectSchema } from './ProductReviewCreateWithoutVariantInput.schema'
import { ProductReviewUncheckedCreateWithoutVariantInputObjectSchema } from './ProductReviewUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateOrConnectWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductReviewCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => ProductReviewUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewCreateOrConnectWithoutVariantInputObjectSchema =
  Schema
