import { z } from 'zod'
import { OrderCreateWithoutPaymentsInputObjectSchema } from './OrderCreateWithoutPaymentsInput.schema'
import { OrderUncheckedCreateWithoutPaymentsInputObjectSchema } from './OrderUncheckedCreateWithoutPaymentsInput.schema'
import { OrderCreateOrConnectWithoutPaymentsInputObjectSchema } from './OrderCreateOrConnectWithoutPaymentsInput.schema'
import { OrderUpsertWithoutPaymentsInputObjectSchema } from './OrderUpsertWithoutPaymentsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutPaymentsInputObjectSchema } from './OrderUpdateWithoutPaymentsInput.schema'
import { OrderUncheckedUpdateWithoutPaymentsInputObjectSchema } from './OrderUncheckedUpdateWithoutPaymentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutPaymentsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutPaymentsInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutPaymentsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutPaymentsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutPaymentsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutPaymentsInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutPaymentsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutPaymentsNestedInputObjectSchema =
  Schema
