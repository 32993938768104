import { z } from 'zod'
import { OrderUpdateWithoutReceiptInputObjectSchema } from './OrderUpdateWithoutReceiptInput.schema'
import { OrderUncheckedUpdateWithoutReceiptInputObjectSchema } from './OrderUncheckedUpdateWithoutReceiptInput.schema'
import { OrderCreateWithoutReceiptInputObjectSchema } from './OrderCreateWithoutReceiptInput.schema'
import { OrderUncheckedCreateWithoutReceiptInputObjectSchema } from './OrderUncheckedCreateWithoutReceiptInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutReceiptInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutReceiptInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutReceiptInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutReceiptInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutReceiptInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutReceiptInputObjectSchema = Schema
