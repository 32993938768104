import { z } from 'zod'

export enum QueueType {
  WALKIN = 'WALKIN',
  APPOINTMENT = 'APPOINTMENT',
}

export const WaitingQueueModel = z.object({
  doctorId: z.number(),
  patientId: z.number(),
  caseId: z.number(),
  queueType: z.nativeEnum(QueueType),
  createdAt: z.date(),
})

export type WaitingQueueModel = z.infer<typeof WaitingQueueModel>
