import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { UserInfo } from './create-edit-section/userInfo'
import { transformPartnerUser } from './transform'

const PartnerUserEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="partnerUser" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const PartnerUserEditForm = () => {
  return (
    <SimpleForm toolbar={<PartnerUserEditToolbar />}>
      <UserInfo />
    </SimpleForm>
  )
}

export const PartnerUserEdit = () => {
  return (
    <EditSaveRouteBack
      transform={transformPartnerUser}
      component="div"
      redirect="list"
      mutationMode="pessimistic"
    >
      <Box>
        <Card>
          <CardContent sx={{ p: 2 }}>
            <PartnerUserEditForm />
          </CardContent>
        </Card>
      </Box>
    </EditSaveRouteBack>
  )
}
