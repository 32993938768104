import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OtpBlacklistCountOrderByAggregateInputObjectSchema } from './OtpBlacklistCountOrderByAggregateInput.schema'
import { OtpBlacklistAvgOrderByAggregateInputObjectSchema } from './OtpBlacklistAvgOrderByAggregateInput.schema'
import { OtpBlacklistMaxOrderByAggregateInputObjectSchema } from './OtpBlacklistMaxOrderByAggregateInput.schema'
import { OtpBlacklistMinOrderByAggregateInputObjectSchema } from './OtpBlacklistMinOrderByAggregateInput.schema'
import { OtpBlacklistSumOrderByAggregateInputObjectSchema } from './OtpBlacklistSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpBlacklistOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    ipAddress: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    expiredAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => OtpBlacklistCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => OtpBlacklistAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => OtpBlacklistMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => OtpBlacklistMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => OtpBlacklistSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const OtpBlacklistOrderByWithAggregationInputObjectSchema = Schema
