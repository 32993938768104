import { z } from 'zod'
import { CouponTypeSchema } from '../enums/CouponType.schema'
import { NestedEnumCouponTypeFilterObjectSchema } from './NestedEnumCouponTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.EnumCouponTypeFilter> = z
  .object({
    equals: z.lazy(() => CouponTypeSchema).optional(),
    in: z
      .lazy(() => CouponTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => CouponTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => CouponTypeSchema),
        z.lazy(() => NestedEnumCouponTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumCouponTypeFilterObjectSchema = Schema
