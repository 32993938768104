import { z } from 'zod'
import { FlashsaleItemCreateInputObjectSchema } from './objects/FlashsaleItemCreateInput.schema'
import { FlashsaleItemUncheckedCreateInputObjectSchema } from './objects/FlashsaleItemUncheckedCreateInput.schema'

export const FlashsaleItemCreateOneSchema = z.object({
  data: z.union([
    FlashsaleItemCreateInputObjectSchema,
    FlashsaleItemUncheckedCreateInputObjectSchema,
  ]),
})
