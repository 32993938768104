import * as z from "zod"
import { CompletePartner, RelatedPartnerModel } from "./index"

export const CoverPictureModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  rank: z.number().int(),
  url: z.string(),
  partnerId: z.number().int(),
})

export interface CompleteCoverPicture extends z.infer<typeof CoverPictureModel> {
  partner: CompletePartner
}

/**
 * RelatedCoverPictureModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCoverPictureModel: z.ZodSchema<CompleteCoverPicture> = z.lazy(() => CoverPictureModel.extend({
  partner: RelatedPartnerModel,
}))
