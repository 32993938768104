import { z } from 'zod'
import { IntWithAggregatesFilterObjectSchema } from './IntWithAggregatesFilter.schema'
import { DateTimeWithAggregatesFilterObjectSchema } from './DateTimeWithAggregatesFilter.schema'
import { StringWithAggregatesFilterObjectSchema } from './StringWithAggregatesFilter.schema'
import { JsonWithAggregatesFilterObjectSchema } from './JsonWithAggregatesFilter.schema'
import { EnumOrderItemTypeWithAggregatesFilterObjectSchema } from './EnumOrderItemTypeWithAggregatesFilter.schema'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'
import { IntNullableWithAggregatesFilterObjectSchema } from './IntNullableWithAggregatesFilter.schema'
import { EnumDrugTypeNullableWithAggregatesFilterObjectSchema } from './EnumDrugTypeNullableWithAggregatesFilter.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { DateTimeNullableWithAggregatesFilterObjectSchema } from './DateTimeNullableWithAggregatesFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.MyReviewScalarWhereWithAggregatesInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => MyReviewScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => MyReviewScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    OR: z
      .lazy(() => MyReviewScalarWhereWithAggregatesInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => MyReviewScalarWhereWithAggregatesInputObjectSchema),
        z
          .lazy(() => MyReviewScalarWhereWithAggregatesInputObjectSchema)
          .array(),
      ])
      .optional(),
    id: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeWithAggregatesFilterObjectSchema), z.date()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    picture: z
      .union([z.lazy(() => StringWithAggregatesFilterObjectSchema), z.string()])
      .optional(),
    titleTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    subtitleTr: z.lazy(() => JsonWithAggregatesFilterObjectSchema).optional(),
    orderItemId: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    orderItemType: z
      .union([
        z.lazy(() => EnumOrderItemTypeWithAggregatesFilterObjectSchema),
        z.lazy(() => OrderItemTypeSchema),
      ])
      .optional(),
    productId: z
      .union([
        z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
        z.number(),
      ])
      .optional()
      .nullable(),
    procedureId: z
      .union([
        z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
        z.number(),
      ])
      .optional()
      .nullable(),
    quantity: z
      .union([z.lazy(() => IntWithAggregatesFilterObjectSchema), z.number()])
      .optional(),
    drugType: z
      .union([
        z.lazy(() => EnumDrugTypeNullableWithAggregatesFilterObjectSchema),
        z.lazy(() => DrugTypeSchema),
      ])
      .optional()
      .nullable(),
    reviewId: z
      .union([
        z.lazy(() => IntNullableWithAggregatesFilterObjectSchema),
        z.number(),
      ])
      .optional()
      .nullable(),
    reviewStartAt: z
      .union([
        z.lazy(() => DateTimeNullableWithAggregatesFilterObjectSchema),
        z.date(),
      ])
      .optional()
      .nullable(),
    reviewExpireAt: z
      .union([
        z.lazy(() => DateTimeNullableWithAggregatesFilterObjectSchema),
        z.date(),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const MyReviewScalarWhereWithAggregatesInputObjectSchema = Schema
