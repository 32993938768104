import { z } from 'zod'
import { PaymentTransactionIdPaymentGatewayCompoundUniqueInputObjectSchema } from './PaymentTransactionIdPaymentGatewayCompoundUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentWhereUniqueInput> = z
  .object({
    id: z.number().optional(),
    transactionId_paymentGateway: z
      .lazy(
        () => PaymentTransactionIdPaymentGatewayCompoundUniqueInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const PaymentWhereUniqueInputObjectSchema = Schema
