import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisScalarWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema),
        z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema),
        z.lazy(() => CaseSummaryDiagnosisScalarWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    diagnosisId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    caseSummaryId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
  })
  .strict()

export const CaseSummaryDiagnosisScalarWhereInputObjectSchema = Schema
