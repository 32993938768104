import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomTextInput } from '../../../components/CustomInput'

export const SearchForm = () => {
  const { setFilters } = useListContext()

  const form = useForm({
    defaultValues: {
      textSearch: undefined,
    },
  })

  const onChange = (values: { [key: string]: unknown }) => {
    setFilters(values, [])
  }

  useEffect(() => {
    setFilters({}, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      <form onChange={form.handleSubmit(onChange)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          <CustomTextInput
            sx={{ flexGrow: 1 }}
            helperText={false}
            source="textSearch"
            label="Search..."
            placeholder="Type any things"
          />
        </Box>
      </form>
    </FormProvider>
  )
}
