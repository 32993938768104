import { z } from 'zod'
import { WarehouseCountOutputTypeSelectObjectSchema } from './WarehouseCountOutputTypeSelect.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCountOutputTypeArgs> = z
  .object({
    select: z.lazy(() => WarehouseCountOutputTypeSelectObjectSchema).optional(),
  })
  .strict()

export const WarehouseCountOutputTypeArgsObjectSchema = Schema
