import { z } from 'zod'
import { ProcedureOrderScalarWhereInputObjectSchema } from './ProcedureOrderScalarWhereInput.schema'
import { ProcedureOrderUpdateManyMutationInputObjectSchema } from './ProcedureOrderUpdateManyMutationInput.schema'
import { ProcedureOrderUncheckedUpdateManyWithoutProcedureOrderInputObjectSchema } from './ProcedureOrderUncheckedUpdateManyWithoutProcedureOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.ProcedureOrderUpdateManyWithWhereWithoutPartnerInput> =
  z
    .object({
      where: z.lazy(() => ProcedureOrderScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureOrderUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProcedureOrderUncheckedUpdateManyWithoutProcedureOrderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureOrderUpdateManyWithWhereWithoutPartnerInputObjectSchema =
  Schema
