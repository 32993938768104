import { z } from 'zod'
import { BrandCreateWithoutProductReviewsInputObjectSchema } from './BrandCreateWithoutProductReviewsInput.schema'
import { BrandUncheckedCreateWithoutProductReviewsInputObjectSchema } from './BrandUncheckedCreateWithoutProductReviewsInput.schema'
import { BrandCreateOrConnectWithoutProductReviewsInputObjectSchema } from './BrandCreateOrConnectWithoutProductReviewsInput.schema'
import { BrandWhereUniqueInputObjectSchema } from './BrandWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandCreateNestedOneWithoutProductReviewsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => BrandCreateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => BrandUncheckedCreateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => BrandCreateOrConnectWithoutProductReviewsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => BrandWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const BrandCreateNestedOneWithoutProductReviewsInputObjectSchema = Schema
