import { Box, Card, CardContent, Grid } from '@mui/material'
import { Create, SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ArticleDetail } from './create-edit-section/articleDetail'
import { ArticlePreview } from './create-edit-section/articlePreview'
import { ArticleFormHeader } from './create-edit-section/headerSection'
import { transformArticle } from './transform'

const ArticleCreateToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="article" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ArticleCreateForm = () => {
  return (
    <SimpleForm toolbar={<ArticleCreateToolbar />}>
      <ArticleFormHeader mode="CREATE" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8.5}>
          <ArticleDetail />
        </Grid>
        <Grid item xs={12} md={3.5}>
          <ArticlePreview />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const ArticleCreate = () => (
  <Create transform={transformArticle} redirect="list">
    <Card>
      <CardContent sx={{ p: 2 }}>
        <ArticleCreateForm />
      </CardContent>
    </Card>
  </Create>
)
