import { z } from 'zod'
import { PatientUpdateWithoutAcceptedConsentsInputObjectSchema } from './PatientUpdateWithoutAcceptedConsentsInput.schema'
import { PatientUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema } from './PatientUncheckedUpdateWithoutAcceptedConsentsInput.schema'
import { PatientCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientCreateWithoutAcceptedConsentsInput.schema'
import { PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientUncheckedCreateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutAcceptedConsentsInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutAcceptedConsentsInputObjectSchema),
      z.lazy(
        () => PatientUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutAcceptedConsentsInputObjectSchema),
      z.lazy(
        () => PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const PatientUpsertWithoutAcceptedConsentsInputObjectSchema = Schema
