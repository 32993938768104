import { z } from 'zod'
import { PatientUpdateWithoutAddressInputObjectSchema } from './PatientUpdateWithoutAddressInput.schema'
import { PatientUncheckedUpdateWithoutAddressInputObjectSchema } from './PatientUncheckedUpdateWithoutAddressInput.schema'
import { PatientCreateWithoutAddressInputObjectSchema } from './PatientCreateWithoutAddressInput.schema'
import { PatientUncheckedCreateWithoutAddressInputObjectSchema } from './PatientUncheckedCreateWithoutAddressInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientUpsertWithoutAddressInput> = z
  .object({
    update: z.union([
      z.lazy(() => PatientUpdateWithoutAddressInputObjectSchema),
      z.lazy(() => PatientUncheckedUpdateWithoutAddressInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => PatientCreateWithoutAddressInputObjectSchema),
      z.lazy(() => PatientUncheckedCreateWithoutAddressInputObjectSchema),
    ]),
  })
  .strict()

export const PatientUpsertWithoutAddressInputObjectSchema = Schema
