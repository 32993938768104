import { z } from 'zod'
import { UserCreateWithoutPartnerInputObjectSchema } from './UserCreateWithoutPartnerInput.schema'
import { UserUncheckedCreateWithoutPartnerInputObjectSchema } from './UserUncheckedCreateWithoutPartnerInput.schema'
import { UserCreateOrConnectWithoutPartnerInputObjectSchema } from './UserCreateOrConnectWithoutPartnerInput.schema'
import { UserCreateManyPartnerInputEnvelopeObjectSchema } from './UserCreateManyPartnerInputEnvelope.schema'
import { UserWhereUniqueInputObjectSchema } from './UserWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.UserUncheckedCreateNestedManyWithoutPartnerInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => UserCreateWithoutPartnerInputObjectSchema),
          z.lazy(() => UserCreateWithoutPartnerInputObjectSchema).array(),
          z.lazy(() => UserUncheckedCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => UserUncheckedCreateWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => UserCreateOrConnectWithoutPartnerInputObjectSchema),
          z
            .lazy(() => UserCreateOrConnectWithoutPartnerInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => UserCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => UserWhereUniqueInputObjectSchema),
          z.lazy(() => UserWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const UserUncheckedCreateNestedManyWithoutPartnerInputObjectSchema =
  Schema
