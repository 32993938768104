import { z } from 'zod'
import { CarouselItemCreateWithoutCarouselInputObjectSchema } from './CarouselItemCreateWithoutCarouselInput.schema'
import { CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema } from './CarouselItemUncheckedCreateWithoutCarouselInput.schema'
import { CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema } from './CarouselItemCreateOrConnectWithoutCarouselInput.schema'
import { CarouselItemCreateManyCarouselInputEnvelopeObjectSchema } from './CarouselItemCreateManyCarouselInputEnvelope.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './CarouselItemWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemUncheckedCreateNestedManyWithoutCarouselInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema),
          z
            .lazy(() => CarouselItemCreateWithoutCarouselInputObjectSchema)
            .array(),
          z.lazy(
            () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () => CarouselItemUncheckedCreateWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema,
          ),
          z
            .lazy(
              () => CarouselItemCreateOrConnectWithoutCarouselInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CarouselItemCreateManyCarouselInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema),
          z.lazy(() => CarouselItemWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CarouselItemUncheckedCreateNestedManyWithoutCarouselInputObjectSchema =
  Schema
