import { TranslationMessages } from 'react-admin'

export const i18nEn: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'Add filter',
      add: 'Add',
      back: 'Go Back',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'Cancel',
      reset: 'Reset',
      clear_input_value: 'Clear value',
      clone: 'Clone',
      confirm: 'Confirm',
      create: 'Create',
      create_item: 'Create %{item}',
      delete: 'Delete',
      edit: 'Edit',
      import: 'Import',
      export: 'Export',
      list: 'List',
      refresh: 'Refresh',
      remove_filter: 'Remove this filter',
      remove_all_filters: 'Remove all filters',
      remove: 'Remove',
      save: 'Save',
      search: 'Search',
      select_all: 'Select all',
      select_row: 'Select this row',
      show: 'Show',
      sort: 'Sort',
      undo: 'Undo',
      unselect: 'Unselect',
      expand: 'Expand',
      close: 'Close',
      open_menu: 'Open menu',
      close_menu: 'Close menu',
      update: 'Update',
      upload_file: 'Upload file',
      move_up: 'Move up',
      move_down: 'Move down',
      open: 'Open',
      toggle_theme: 'Toggle Theme',
      select_columns: 'Columns',
      clear_array_input: 'Clear the list',
    },
    boolean: {
      true: 'Yes',
      false: 'No',
      null: ' ',
    },
    page: {
      create: 'Create %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} %{recordRepresentation}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not Found',
      show: '%{name} %{recordRepresentation}',
      empty: 'No %{name} yet.',
      invite: 'Do you want to add one?',
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.',
      },
      video: {
        upload_several: 'Drop some videos to upload, or click to select one.',
        upload_single: 'Drop a video to upload, or click to select it.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing:
          'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password',
      },
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      bulk_update_content:
        'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title: 'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Details',
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?",
      auth_error: 'A error occurred while validating the authentication token.',
      clear_array_input: 'Are you sure you want to clear the whole list?',
    },
    navigation: {
      no_results: 'No results found',
      no_more_results:
        'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      partial_page_range_info:
        '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
      current_page: 'Page %{page}',
      page: 'Go to page %{page}',
      first: 'Go to first page',
      last: 'Go to last page',
      next: 'Go to next page',
      previous: 'Go to previous page',
      page_rows_per_page: 'Rows per page:',
      skip_nav: 'Skip to content',
    },
    sort: {
      sort_by: 'Sort by %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending',
    },
    auth: {
      auth_check_error: 'Please login to continue',
      user_menu: 'Profile',
      username: 'Username',
      password: 'Password',
      sign_in: 'Sign in',
      sign_in_error: 'Authentication failed, please retry',
      logout: 'Logout',
    },
    notification: {
      updated: 'Element updated |||| %{smart_count} elements updated',
      created: 'Element created',
      deleted: 'Element deleted |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: 'Required',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'Must be at least %{min}',
      maxValue: 'Must be %{max} or less',
      number: 'Must be a number',
      email: 'Must be a valid email',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
      file_invalid: 'File has some errors',
    },
    saved_queries: {
      label: 'Saved queries',
      query_name: 'Query name',
      new_label: 'Save current query...',
      new_dialog_title: 'Save current query as',
      remove_label: 'Remove saved query',
      remove_label_with_name: 'Remove query "%{name}"',
      remove_dialog_title: 'Remove saved query?',
      remove_message:
        'Are you sure you want to remove that item from your list of saved queries?',
      help: 'Filter the list and save this query for later',
    },
    configurable: {
      customize: 'Customize',
      configureMode: 'Configure this page',
      inspector: {
        title: 'Inspector',
        content: 'Hover the application UI elements to configure them',
        reset: 'Reset Settings',
        hideAll: 'Hide All',
        showAll: 'Show All',
      },
      SimpleList: {
        title: 'List',
        primaryText: 'Primary text',
        secondaryText: 'Secondary text',
        tertiaryText: 'Tertiary text',
      },
      Datagrid: {
        title: 'Datagrid',
        unlabeled: 'Unlabeled column #%{column}',
      },
      SimpleForm: {
        title: 'Form',
        unlabeled: 'Unlabeled input #%{input}',
      },
    },
  },
  upload: {
    error: 'File upload error',
  },
  operator: {
    and: 'AND',
    or: 'OR',
  },
  resources: {
    doctor: {
      action: {
        export_review: 'Export doctor review',
      },
      title: {
        export_review: 'Export doctor review',
      },
    },
    doctorScheduler: {
      fields: {
        from: 'From',
        to: 'To',
      },
      action: {
        create_slot: 'Create slot',
      },
      title: {
        export_modal: 'Export (excel)',
        appointment: 'Appointment',
        search_doctor: 'Search doctor',
      },
    },
    patient: {
      fields: {
        id: 'ID',
        firstname: 'First name',
        lastname: 'Given name',
        gender: 'Gender',
        nickname: 'Nickname',
        birthDate: 'DoB (dd/mm/yyyy)',
        phoneNumber: 'Phone',
        nameOrPid: 'Search PID/Name',
        picture: {
          src: 'Picture',
        },
      },
      title: {
        general: 'General',
        identity: 'Identity',
      },
    },
    product: {
      title: {
        product: 'Product',
        import_modal: 'Import Product (excel)',
      },
      action: {
        validate_xlsx_file: 'Validate',
        download_import_template: 'Download template',
      },
    },
    productBrand: {
      fields: {
        id: 'ID',
        logo: {
          src: 'Logo',
        },
        isShow: 'Shown on App',
        countryName: 'Country',
      },
      action: {
        download_import_template: 'Download template',
        import_button: 'Update brands',
      },
      title: {
        brand: 'Brand',
        import_modal: 'Upload brands (excel)',
      },
    },
    procedure: {
      fields: {
        nameTr: {
          th: 'Procedure name (TH)',
          en: 'Procedure name (EN)',
        },
        categories: 'Categories',
        tagPrice: 'Tage price',
        sellingPrice: 'Selling price',
        cost: 'Cost',
        commissionRate: 'Commission rate',
        expireType: 'Expire type',
        expireAt: 'Use by',
        expireDurationDay: 'Expire in',
        priority: 'Priority',
        descriptionTr: {
          th: 'Description (TH)',
          en: 'Description (EN)',
        },
        technologyTr: {
          th: 'Technology (TH)',
          en: 'Technology (EN)',
        },
        methodOfUseTr: {
          th: 'Method of use (TH)',
          en: 'Method of use (EN)',
        },
        usageTr: {
          th: 'Usage (TH)',
          en: 'Usage (EN)',
        },
        resultPeriodTr: {
          th: 'Result period (TH)',
          en: 'Result period (EN)',
        },
        preparationTr: {
          th: 'Preparation (TH)',
          en: 'Preparation (EN)',
        },
        conditionTr: {
          th: 'Condition (TH)',
          en: 'Condition (EN)',
        },
        partner: 'Clinic',
        isTreatedByDoctor: 'Treadted by doctor',
        searchable: 'Seachable',
        active: 'Active',
      },
      title: {
        information_section: 'Procedure information',
        property_section: 'Property',
        picture_section: 'Picture',
        partner_section: 'Clinic and branch',
        branches: 'Branches',
        procedure: 'Procedure',
        expireType: {
          DATE: 'Use by',
          DURATION: 'After purchasing',
        },
      },
      validation: {
        select_clinic: 'Please select clinic',
        no_branch: 'No branch',
      },
    },
    flashsaleProduct: {
      fields: {
        name: 'Name',
        startAt: 'Start date',
        endAt: 'End date',
        description: 'Description',
      },
      title: {
        campaign_section: 'Product Flash Sale',
      },
      action: {
        import_button: 'Import SKU',
        upload_file: 'Upload file',
        download_import_template: 'Download template',
        add_flashsale_item: 'Add SKU Item',
      },
    },
    flashsaleProcedure: {
      fields: {
        name: 'Name',
        startAt: 'Start date',
        endAt: 'End date',
        description: 'Description',
      },
      title: {
        campaign_section: 'Procedure Flash Sale ',
      },
      action: {
        import_button: 'Import procedure',
        upload_file: 'Upload file',
        download_import_template: 'Download template',
        add_flashsale_item: 'Add Item',
      },
    },
    flashsaleItemProduct: {
      title: {
        product_item: 'Flash Sale items',
        import_modal: 'Import Flash Sale item (excel)',
      },
    },
    flashsaleItemProcedure: {
      fields: {
        procedure: 'Procedure',
        price: 'Price',
        saleLimit: 'Quota',
        priority: 'Priority',
        priceAbsorbedByPartner: 'Absorbed by Clinic',
        priceAbsorbedBySkinX: 'Absorbed by SkinX',
        id: 'ID',
        cost: 'Cost',
        sellingPrice: 'Price',
        tagPrice: 'Tag price',
        nameTr: 'Name',
        active: 'Active',
      },
      title: {
        procedure_item: 'Flash Sale items',
        procedure_detail: 'Procedure detail',
        add_flashsale: 'Add Flashsale item (procedure)',
        import_modal: 'Import Flash Sale item (excel)',
      },
    },
    couponGroup: {
      fields: {
        prefix: 'Unique',
        startAt: 'Start date',
        expireAt: 'Expire date',
        codeType: 'Code type',
        code: 'Code',
        titleTr: 'Title',
        descriptionTr: 'Description',
        type: 'Coupon type',
        isPublic: 'Set to public coupon',
        isOnlyNewPatient: 'Only new patient',
        tags: 'Coupon tags (TH, EN)',
        discountSetting: {
          discountItemType: 'Apply to',
          itemType: 'Type',
          flashsaleCondition: 'Flash Sale condition',
          scope: 'Scope',
          items: 'Items',
        },
        conditionSetting: {
          itemType: 'With',
          scope: 'Scope',
          items: 'Items',
        },
        picture: 'Icon',
        inPayment: 'In payment',
        used: 'Used',
      },
      icon: {
        shipping: 'Shipping',
        pill: 'Pill',
        sx: 'SX',
        flashsale: 'Flash Sale',
        doctor: 'Doctor',
        ticket: 'Procedure',
      },
      title: {
        generate_coupon: 'Generate coupon',
        discount_setting: 'Discount setting',
        condition_setting: 'Condition setting',
        icon: 'Icon',
        coupon: 'Coupon',
        coupon_preview: 'Coupon preview',
        coupon_title: 'Coupon title',
        coupon_expireAt: 'Expire at',
        scope: {
          ALL: 'All',
          ONLY: 'Only',
          EXCEPT: 'Except',
        },
        shippingType: {
          NORMAL: 'Normal',
          EXPRESS: 'ส่งธรรมดา',
        },
        couponType: {
          GENERAL: 'General',
          SHIPPING: 'Shipping',
          COIN_CASHBACK: 'Coin cashback',
        },
        discountType: {
          FIXED: 'Fixed',
          PERCENT: 'Percent',
        },
        discountItemType: {
          DOCTOR: 'Doctor',
          VARIANT: 'SKU',
          PROCEDURE: 'Procedure',
          SHIPPING_TYPE: 'Shipping Type',
          SHIPPING_PROVIDER: 'Shipping Provider',
        },
        itemType: {
          DOCTOR: 'None',
          DOCTOR_CATEGORY: 'Category',
          VARIANT: 'None',
          VARIANT_BRAND: 'Brand',
          VARIANT_CATEGORY: 'Category',
          PROCEDURE: 'None',
          PROCEDURE_PARTNER: 'Partner',
          PROCEDURE_CATEGORY: 'Category',
        },
        flashsaleCondition: {
          VARIANT: 'Include Flash Sale',
          VARIANT_FLASHSALE: 'Only Flash Sale',
          VARIANT_NO_FLASHSALE: 'Except Flash Sale',
          PROCEDURE: 'Include Flash Sale',
          PROCEDURE_FLASHSALE: 'Only Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'Except Flash Sale',
        },
      },
      action: {
        tag: {
          create: 'Create tag',
          tag_th: 'Tag (Thai)',
          tag_en: 'Tag (English)',
        },
        discount_setting: {
          add_button: 'Add discount list',
        },
        condition_setting: {
          add_button: 'Add condition',
        },
      },
      validation: {
        code_type_length:
          '%{codeType} must be in between %{minLength} digit to %{maxLength} digits',
        start_date: 'Must less than Expire date',
        expire_at: 'Must more than Start date',
      },
    },
    couponGroupDiscountSetting: {
      fields: {
        type: 'Apply to',
        scope: 'Scope',
        itemType: 'Type',
      },
      title: {
        itemType: {
          DOCTOR: 'All',
          DOCTOR_CATEGORY: 'Category',
          VARIANT: 'All',
          VARIANT_BRAND: 'Brand',
          VARIANT_CATEGORY: 'Category',
          PROCEDURE: 'All',
          PROCEDURE_PARTNER: 'Partner',
          PROCEDURE_CATEGORY: 'Category',
          VARIANT_FLASHSALE: 'Only Flash Sale',
          VARIANT_NO_FLASHSALE: 'Except Flash Sale',
          PROCEDURE_FLASHSALE: 'Only Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'Except Flash Sale',
        },
      },
    },
    couponGroupConditionSetting: {
      fields: {
        type: 'With',
        scope: 'Scope',
        itemType: 'Type',
      },
      title: {
        itemType: {
          DOCTOR: 'All',
          DOCTOR_CATEGORY: 'Category',
          VARIANT: 'All',
          VARIANT_BRAND: 'Brand',
          VARIANT_CATEGORY: 'Category',
          PROCEDURE: 'All',
          PROCEDURE_PARTNER: 'Partner',
          PROCEDURE_CATEGORY: 'Category',
          VARIANT_FLASHSALE: 'Only Flash Sale',
          VARIANT_NO_FLASHSALE: 'Except Flash Sale',
          PROCEDURE_FLASHSALE: 'Only Flash Sale',
          PROCEDURE_NO_FLASHSALE: 'Except Flash Sale',
        },
      },
    },
    coupon: {
      fields: {
        id: 'ID',
        type: 'Coupon type',
        titleTr: 'Title',
        descriptionTr: 'Description',
        isPublic: 'Public',
        isOnlyNewPatient: 'Only new patient',
      },
      action: {
        export_coupon: 'Export coupon',
      },
    },
    carouselProcedure: {
      fields: {
        name: 'Name',
        startAt: 'Start date',
        endAt: 'End date',
      },
      title: {
        campaign_section: 'Procedure Carousel',
      },
      action: {
        import_button: 'Import procedure',
        upload_file: 'Upload file',
        download_import_template: 'Download template',
        add_carousel_item: 'Add Item',
      },
    },
    carouselItemProcedure: {
      fields: {
        procedure: 'Procedure',
        priority: 'Priority',
        id: 'ID',
        nameTr: 'Name',
        active: 'Active',
        cost: 'Cost',
        sellingPrice: 'Price',
        tagPrice: 'Tag price',
      },
      title: {
        procedure_item: 'Flash Sale items',
        procedure_detail: 'Procedure detail',
        add_carousel: 'Add Carousel item (procedure)',
        import_modal: 'Import Carousel item (excel)',
      },
    },
  },
}
