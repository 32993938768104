import { z } from 'zod'
import { ProcedureItemWhereUniqueInputObjectSchema } from './ProcedureItemWhereUniqueInput.schema'
import { ProcedureItemCreateWithoutOrderInputObjectSchema } from './ProcedureItemCreateWithoutOrderInput.schema'
import { ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema } from './ProcedureItemUncheckedCreateWithoutOrderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemCreateOrConnectWithoutOrderInput> =
  z
    .object({
      where: z.lazy(() => ProcedureItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProcedureItemCreateWithoutOrderInputObjectSchema),
        z.lazy(() => ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema),
      ]),
    })
    .strict()

export const ProcedureItemCreateOrConnectWithoutOrderInputObjectSchema = Schema
