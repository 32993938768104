import { z } from 'zod'
import { ArticleUpdateWithoutPicturesInputObjectSchema } from './ArticleUpdateWithoutPicturesInput.schema'
import { ArticleUncheckedUpdateWithoutPicturesInputObjectSchema } from './ArticleUncheckedUpdateWithoutPicturesInput.schema'
import { ArticleCreateWithoutPicturesInputObjectSchema } from './ArticleCreateWithoutPicturesInput.schema'
import { ArticleUncheckedCreateWithoutPicturesInputObjectSchema } from './ArticleUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticleUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ArticleUpdateWithoutPicturesInputObjectSchema),
      z.lazy(() => ArticleUncheckedUpdateWithoutPicturesInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => ArticleCreateWithoutPicturesInputObjectSchema),
      z.lazy(() => ArticleUncheckedCreateWithoutPicturesInputObjectSchema),
    ]),
  })
  .strict()

export const ArticleUpsertWithoutPicturesInputObjectSchema = Schema
