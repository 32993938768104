import { z } from 'zod'
import { ProcedureReviewWhereInputObjectSchema } from './objects/ProcedureReviewWhereInput.schema'
import { ProcedureReviewOrderByWithAggregationInputObjectSchema } from './objects/ProcedureReviewOrderByWithAggregationInput.schema'
import { ProcedureReviewScalarWhereWithAggregatesInputObjectSchema } from './objects/ProcedureReviewScalarWhereWithAggregatesInput.schema'
import { ProcedureReviewScalarFieldEnumSchema } from './enums/ProcedureReviewScalarFieldEnum.schema'

export const ProcedureReviewGroupBySchema = z.object({
  where: ProcedureReviewWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ProcedureReviewOrderByWithAggregationInputObjectSchema,
    ProcedureReviewOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ProcedureReviewScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ProcedureReviewScalarFieldEnumSchema),
})
