import { z } from 'zod'
import { PartnerSelectObjectSchema } from './PartnerSelect.schema'
import { PartnerIncludeObjectSchema } from './PartnerInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.PartnerArgs> = z
  .object({
    select: z.lazy(() => PartnerSelectObjectSchema).optional(),
    include: z.lazy(() => PartnerIncludeObjectSchema).optional(),
  })
  .strict()

export const PartnerArgsObjectSchema = Schema
