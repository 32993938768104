import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ProcedureItemCountOrderByAggregateInputObjectSchema } from './ProcedureItemCountOrderByAggregateInput.schema'
import { ProcedureItemAvgOrderByAggregateInputObjectSchema } from './ProcedureItemAvgOrderByAggregateInput.schema'
import { ProcedureItemMaxOrderByAggregateInputObjectSchema } from './ProcedureItemMaxOrderByAggregateInput.schema'
import { ProcedureItemMinOrderByAggregateInputObjectSchema } from './ProcedureItemMinOrderByAggregateInput.schema'
import { ProcedureItemSumOrderByAggregateInputObjectSchema } from './ProcedureItemSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProcedureItemOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    procedureId: z.lazy(() => SortOrderSchema).optional(),
    partnerId: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    quantity: z.lazy(() => SortOrderSchema).optional(),
    tagPrice: z.lazy(() => SortOrderSchema).optional(),
    sellingPrice: z.lazy(() => SortOrderSchema).optional(),
    cost: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedBySkinX: z.lazy(() => SortOrderSchema).optional(),
    priceAbsorbedByPartner: z.lazy(() => SortOrderSchema).optional(),
    commissionRate: z.lazy(() => SortOrderSchema).optional(),
    flashSaleId: z.lazy(() => SortOrderSchema).optional(),
    expireType: z.lazy(() => SortOrderSchema).optional(),
    expireAt: z.lazy(() => SortOrderSchema).optional(),
    expireDurationDay: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    refundId: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    reviewId: z.lazy(() => SortOrderSchema).optional(),
    reviewStartAt: z.lazy(() => SortOrderSchema).optional(),
    reviewExpireAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ProcedureItemCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ProcedureItemAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ProcedureItemMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ProcedureItemMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ProcedureItemSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProcedureItemOrderByWithAggregationInputObjectSchema = Schema
