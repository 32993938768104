import { z } from 'zod'
import { DeliveryLogOrderByWithRelationInputObjectSchema } from './objects/DeliveryLogOrderByWithRelationInput.schema'
import { DeliveryLogWhereInputObjectSchema } from './objects/DeliveryLogWhereInput.schema'
import { DeliveryLogWhereUniqueInputObjectSchema } from './objects/DeliveryLogWhereUniqueInput.schema'
import { DeliveryLogScalarFieldEnumSchema } from './enums/DeliveryLogScalarFieldEnum.schema'

export const DeliveryLogFindManySchema = z.object({
  orderBy: z
    .union([
      DeliveryLogOrderByWithRelationInputObjectSchema,
      DeliveryLogOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: DeliveryLogWhereInputObjectSchema.optional(),
  cursor: DeliveryLogWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(DeliveryLogScalarFieldEnumSchema).optional(),
})
