import { z } from 'zod'
import { ProductPictureScalarWhereInputObjectSchema } from './ProductPictureScalarWhereInput.schema'
import { ProductPictureUpdateManyMutationInputObjectSchema } from './ProductPictureUpdateManyMutationInput.schema'
import { ProductPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema } from './ProductPictureUncheckedUpdateManyWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureUpdateManyWithWhereWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductPictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductPictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProductPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductPictureUpdateManyWithWhereWithoutProductInputObjectSchema =
  Schema
