import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    active: z.literal(true).optional(),
    searchable: z.literal(true).optional(),
    nameTr: z.literal(true).optional(),
    searchField: z.literal(true).optional(),
    technologyTr: z.literal(true).optional(),
    usageTr: z.literal(true).optional(),
    resultPeriodTr: z.literal(true).optional(),
    descriptionTr: z.literal(true).optional(),
    preparationTr: z.literal(true).optional(),
    conditionTr: z.literal(true).optional(),
    methodOfUseTr: z.literal(true).optional(),
    isTreatedByDoctor: z.literal(true).optional(),
    priority: z.literal(true).optional(),
    expireType: z.literal(true).optional(),
    expireAt: z.literal(true).optional(),
    expireDurationDay: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    commissionRate: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
    branches: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    deletedAt: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const ProcedureCountAggregateInputObjectSchema = Schema
