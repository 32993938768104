import { z } from 'zod'
import { RejectReasonWhereUniqueInputObjectSchema } from './RejectReasonWhereUniqueInput.schema'
import { RejectReasonCreateWithoutKycDataInputObjectSchema } from './RejectReasonCreateWithoutKycDataInput.schema'
import { RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema } from './RejectReasonUncheckedCreateWithoutKycDataInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonCreateOrConnectWithoutKycDataInput> =
  z
    .object({
      where: z.lazy(() => RejectReasonWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => RejectReasonCreateWithoutKycDataInputObjectSchema),
        z.lazy(
          () => RejectReasonUncheckedCreateWithoutKycDataInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const RejectReasonCreateOrConnectWithoutKycDataInputObjectSchema = Schema
