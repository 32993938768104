import { z } from 'zod'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { NestedEnumAuxiliaryLabelNullableWithAggregatesFilterObjectSchema } from './NestedEnumAuxiliaryLabelNullableWithAggregatesFilter.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumAuxiliaryLabelNullableFilterObjectSchema } from './NestedEnumAuxiliaryLabelNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumAuxiliaryLabelNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => AuxiliaryLabelSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => AuxiliaryLabelSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => AuxiliaryLabelSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => AuxiliaryLabelSchema),
          z.lazy(
            () =>
              NestedEnumAuxiliaryLabelNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumAuxiliaryLabelNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumAuxiliaryLabelNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const EnumAuxiliaryLabelNullableWithAggregatesFilterObjectSchema = Schema
