import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { DeliveryOrderByWithRelationInputObjectSchema } from './DeliveryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryLogOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deliveryId: z.lazy(() => SortOrderSchema).optional(),
    statusCode: z.lazy(() => SortOrderSchema).optional(),
    statusText: z.lazy(() => SortOrderSchema).optional(),
    statusDate: z.lazy(() => SortOrderSchema).optional(),
    rawData: z.lazy(() => SortOrderSchema).optional(),
    delivery: z
      .lazy(() => DeliveryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const DeliveryLogOrderByWithRelationInputObjectSchema = Schema
