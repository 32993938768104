import { z } from 'zod'
import { VariantWarehouseCreateManyVariantInputObjectSchema } from './VariantWarehouseCreateManyVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseCreateManyVariantInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => VariantWarehouseCreateManyVariantInputObjectSchema),
        z
          .lazy(() => VariantWarehouseCreateManyVariantInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const VariantWarehouseCreateManyVariantInputEnvelopeObjectSchema = Schema
