import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { HomeBannerCountOrderByAggregateInputObjectSchema } from './HomeBannerCountOrderByAggregateInput.schema'
import { HomeBannerAvgOrderByAggregateInputObjectSchema } from './HomeBannerAvgOrderByAggregateInput.schema'
import { HomeBannerMaxOrderByAggregateInputObjectSchema } from './HomeBannerMaxOrderByAggregateInput.schema'
import { HomeBannerMinOrderByAggregateInputObjectSchema } from './HomeBannerMinOrderByAggregateInput.schema'
import { HomeBannerSumOrderByAggregateInputObjectSchema } from './HomeBannerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.HomeBannerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    actionUri: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => HomeBannerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => HomeBannerAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => HomeBannerMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => HomeBannerMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => HomeBannerSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const HomeBannerOrderByWithAggregationInputObjectSchema = Schema
