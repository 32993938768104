import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleUncheckedCreateWithoutAppointmentInput> =
  z
    .object({
      id: z.number().optional(),
      doctorId: z.number(),
      availableAt: z.date(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
    .strict()

export const ScheduleUncheckedCreateWithoutAppointmentInputObjectSchema = Schema
