import { z } from 'zod'
import { DiscountItemTypeSchema } from '../enums/DiscountItemType.schema'
import { NestedEnumDiscountItemTypeFilterObjectSchema } from './NestedEnumDiscountItemTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.EnumDiscountItemTypeFilter> = z
  .object({
    equals: z.lazy(() => DiscountItemTypeSchema).optional(),
    in: z
      .lazy(() => DiscountItemTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountItemTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountItemTypeSchema),
        z.lazy(() => NestedEnumDiscountItemTypeFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const EnumDiscountItemTypeFilterObjectSchema = Schema
