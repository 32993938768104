import { z } from 'zod'
import { ProductBannerOrderByWithRelationInputObjectSchema } from './objects/ProductBannerOrderByWithRelationInput.schema'
import { ProductBannerWhereInputObjectSchema } from './objects/ProductBannerWhereInput.schema'
import { ProductBannerWhereUniqueInputObjectSchema } from './objects/ProductBannerWhereUniqueInput.schema'
import { ProductBannerScalarFieldEnumSchema } from './enums/ProductBannerScalarFieldEnum.schema'

export const ProductBannerFindManySchema = z.object({
  orderBy: z
    .union([
      ProductBannerOrderByWithRelationInputObjectSchema,
      ProductBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProductBannerWhereInputObjectSchema.optional(),
  cursor: ProductBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(ProductBannerScalarFieldEnumSchema).optional(),
})
