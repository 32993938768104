import { z } from 'zod'
import { NotificationPatientSelectObjectSchema } from './NotificationPatientSelect.schema'
import { NotificationPatientIncludeObjectSchema } from './NotificationPatientInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationPatientArgs> = z
  .object({
    select: z.lazy(() => NotificationPatientSelectObjectSchema).optional(),
    include: z.lazy(() => NotificationPatientIncludeObjectSchema).optional(),
  })
  .strict()

export const NotificationPatientArgsObjectSchema = Schema
