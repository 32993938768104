import { z } from 'zod'
import { DeliveryLogUpdateInputObjectSchema } from './objects/DeliveryLogUpdateInput.schema'
import { DeliveryLogUncheckedUpdateInputObjectSchema } from './objects/DeliveryLogUncheckedUpdateInput.schema'
import { DeliveryLogWhereUniqueInputObjectSchema } from './objects/DeliveryLogWhereUniqueInput.schema'

export const DeliveryLogUpdateOneSchema = z.object({
  data: z.union([
    DeliveryLogUpdateInputObjectSchema,
    DeliveryLogUncheckedUpdateInputObjectSchema,
  ]),
  where: DeliveryLogWhereUniqueInputObjectSchema,
})
