import { z } from 'zod'
import { ShippingProviderUpdateWithoutAreasInputObjectSchema } from './ShippingProviderUpdateWithoutAreasInput.schema'
import { ShippingProviderUncheckedUpdateWithoutAreasInputObjectSchema } from './ShippingProviderUncheckedUpdateWithoutAreasInput.schema'
import { ShippingProviderCreateWithoutAreasInputObjectSchema } from './ShippingProviderCreateWithoutAreasInput.schema'
import { ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutAreasInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderUpsertWithoutAreasInput> = z
  .object({
    update: z.union([
      z.lazy(() => ShippingProviderUpdateWithoutAreasInputObjectSchema),
      z.lazy(
        () => ShippingProviderUncheckedUpdateWithoutAreasInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ShippingProviderCreateWithoutAreasInputObjectSchema),
      z.lazy(
        () => ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ShippingProviderUpsertWithoutAreasInputObjectSchema = Schema
