import {
  CloseRounded as CloseRoundedIcon,
  Publish as PublishIcon,
  SimCardDownloadRounded as SimCardDownloadRoundedIcon,
} from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Button as MuiButton,
} from '@mui/material'
import { TRPCError } from '@trpc/server'
import { FC } from 'react'
import {
  Button,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { FieldValues } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { adminGatewayClient, isTRPCClientError } from '../../../service'

interface BrandUpdate {
  id: number
  name?: string
  isShow?: boolean
  coporateName?: string
  countryName?: string
  priority?: number
}

const ImportBrandToolbar: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <CardActions sx={{ display: 'flex', gap: 2 }}>
        <Button label="ra.action.cancel" onClick={() => onCancel()} />
        <SaveButton
          label="ra.action.update"
          icon={<PublishIcon />}
          alwaysEnable
        />
      </CardActions>
    </Toolbar>
  )
}

export const ImportBrandModal: FC<{
  open?: boolean
  disableCloseOnBackdrop?: boolean
  mode?: 'CREATE' | 'UPDATE'
  onClose?: () => void
}> = ({
  open = false,
  disableCloseOnBackdrop = false,
  mode = 'CREATE',
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const readXlsxBrandsFile = (file: File) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const bstr = e.target?.result
        const workbook = XLSX.read(bstr, { type: 'binary' })
        const result = XLSX.utils
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .sheet_to_json<any>(workbook.Sheets[workbook.SheetNames[0]])
          .map((x, index) => {
            if (
              !x['id'] ||
              !(
                x['name'] ||
                x['isShow'] ||
                x['coporateName'] ||
                x['countryName'] ||
                x['priority']
              ) ||
              isNaN(Number(x['id'])) ||
              isNaN(Number(x['priority'].toString().replace(/,/g, ''))) ||
              Number(x['priority'].toString().replace(/,/g, '')) < 0 ||
              typeof x['isShow'] !== 'boolean'
            ) {
              reject(
                new TRPCError({
                  code: 'FORBIDDEN',
                  message: 'Excel format is incorrect',
                }),
              )
            }
            // Adding else because it still return value and reject above in the same time without any cues
            else {
              return {
                id: Number(x['id']),
                name: x['name'],
                isShow: x['isShow'],
                coporateName: x['coporateName'],
                countryName: x['countryName'],
                priority: Number(x['priority'].toString().replace(/,/g, '')),
              }
            }
            return null
          }) as BrandUpdate[]
        resolve(result)
      }
      reader.readAsBinaryString(file)
    })
  }

  const validateBrands = (brands: BrandUpdate[]) => {
    // Check duplicated item
    const uniqueSetBrands = new Set(brands.map((brand) => brand.id))
    const duplicatedBrands = Array.from(
      new Set(
        brands.filter((brand) => {
          if (uniqueSetBrands.has(brand.id)) {
            uniqueSetBrands.delete(brand.id)
            return false
          } else {
            return brand.id + ' '
          }
        }),
      ),
    )
    if (duplicatedBrands.length > 0) {
      throw new TRPCError({
        code: 'FORBIDDEN',
        message: `Brand id: ${duplicatedBrands.map(
          (brand) => brand.id,
        )} is/are shown more than once`,
      })
    }
  }

  const handleSubmitForm = async (file: FieldValues) => {
    try {
      const brands = (await readXlsxBrandsFile(
        file.brands.rawFile,
      )) as BrandUpdate[]
      validateBrands(brands)
      await adminGatewayClient.productBrand.updateManyByXlsx.mutate({
        data: brands,
      })
      notify(`Brands have been updated`, { type: 'success' })
      handleClose()
      refresh()
    } catch (e) {
      if (isTRPCClientError(e) || e instanceof TRPCError) {
        notify(e.message, { type: 'error' })
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => !disableCloseOnBackdrop && handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardHeader
          action={
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title={translate('resources.productBrand.title.import_modal')}
          subheader={translate('resources.productBrand.title.brand')}
        />
        <SimpleForm
          toolbar={<ImportBrandToolbar onCancel={() => handleClose()} />}
          onSubmit={handleSubmitForm}
        >
          <CardContent sx={{ p: 0 }} style={{ width: '100%' }}>
            <Grid container spacing={1} overflow="hidden">
              <Grid item xs={12}>
                <FileInput
                  label={translate('ra.action.upload_file')}
                  source="brands"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  validate={required()}
                  fullWidth
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiButton
                  endIcon={<SimCardDownloadRoundedIcon />}
                  size="small"
                  href="https://docs.google.com/spreadsheets/d/1VYQI1LQOxKA0uUAwYO9oE_5MvoyyB2sRvIh44kLkS6Y/edit?usp=sharing"
                  target="_blank"
                >
                  {translate(
                    'resources.productBrand.action.download_import_template',
                  )}
                </MuiButton>
              </Grid>
            </Grid>
          </CardContent>
        </SimpleForm>
      </Card>
    </Modal>
  )
}
