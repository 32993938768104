import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { OrderItemTypeSchema } from '../enums/OrderItemType.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.MyReviewCreateManyPatientInput> = z
  .object({
    id: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    picture: z.string(),
    titleTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    subtitleTr: z.union([
      z.lazy(() => JsonNullValueInputSchema),
      z.object({ en: z.string(), th: z.string() }),
    ]),
    orderItemId: z.number(),
    orderItemType: z.lazy(() => OrderItemTypeSchema),
    productId: z.number().optional().nullable(),
    procedureId: z.number().optional().nullable(),
    quantity: z.number(),
    drugType: z
      .lazy(() => DrugTypeSchema)
      .optional()
      .nullable(),
    reviewId: z.number().optional().nullable(),
    reviewStartAt: z.date().optional().nullable(),
    reviewExpireAt: z.date().optional().nullable(),
  })
  .strict()

export const MyReviewCreateManyPatientInputObjectSchema = Schema
