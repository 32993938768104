import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.AppointmentSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    caseId: z.literal(true).optional(),
    scheduleId: z.literal(true).optional(),
    teleconsultId: z.literal(true).optional(),
  })
  .strict()

export const AppointmentSumAggregateInputObjectSchema = Schema
