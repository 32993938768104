import { z } from 'zod'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './objects/ProductReviewPictureWhereUniqueInput.schema'
import { ProductReviewPictureCreateInputObjectSchema } from './objects/ProductReviewPictureCreateInput.schema'
import { ProductReviewPictureUncheckedCreateInputObjectSchema } from './objects/ProductReviewPictureUncheckedCreateInput.schema'
import { ProductReviewPictureUpdateInputObjectSchema } from './objects/ProductReviewPictureUpdateInput.schema'
import { ProductReviewPictureUncheckedUpdateInputObjectSchema } from './objects/ProductReviewPictureUncheckedUpdateInput.schema'

export const ProductReviewPictureUpsertSchema = z.object({
  where: ProductReviewPictureWhereUniqueInputObjectSchema,
  create: z.union([
    ProductReviewPictureCreateInputObjectSchema,
    ProductReviewPictureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductReviewPictureUpdateInputObjectSchema,
    ProductReviewPictureUncheckedUpdateInputObjectSchema,
  ]),
})
