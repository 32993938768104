import { z } from 'zod'
import { VariantPictureOrderByWithRelationInputObjectSchema } from './objects/VariantPictureOrderByWithRelationInput.schema'
import { VariantPictureWhereInputObjectSchema } from './objects/VariantPictureWhereInput.schema'
import { VariantPictureWhereUniqueInputObjectSchema } from './objects/VariantPictureWhereUniqueInput.schema'
import { VariantPictureScalarFieldEnumSchema } from './enums/VariantPictureScalarFieldEnum.schema'

export const VariantPictureFindManySchema = z.object({
  orderBy: z
    .union([
      VariantPictureOrderByWithRelationInputObjectSchema,
      VariantPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: VariantPictureWhereInputObjectSchema.optional(),
  cursor: VariantPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(VariantPictureScalarFieldEnumSchema).optional(),
})
