import { z } from 'zod'
import { WarehouseCodeSchema } from '../enums/WarehouseCode.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.WarehouseCreateWithoutVariantWarehousesInput> = z
  .object({
    name: z.string(),
    code: z.lazy(() => WarehouseCodeSchema),
  })
  .strict()

export const WarehouseCreateWithoutVariantWarehousesInputObjectSchema = Schema
