import { z } from 'zod'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { ExpireTypeSchema } from '../enums/ExpireType.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProcedureItemUncheckedCreateWithoutOrderInput> =
  z
    .object({
      id: z.number().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      procedureId: z.number(),
      partnerId: z.number(),
      nameTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      picture: z.string(),
      descriptionTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      quantity: z.number(),
      tagPrice: z.number().optional().nullable(),
      sellingPrice: z.number(),
      cost: z.number(),
      priceAbsorbedBySkinX: z.number().optional(),
      priceAbsorbedByPartner: z.number().optional(),
      commissionRate: z.number().optional(),
      flashSaleId: z.number().optional().nullable(),
      expireType: z.lazy(() => ExpireTypeSchema).optional(),
      expireAt: z.date().optional().nullable(),
      expireDurationDay: z.number().optional().nullable(),
      refundId: z.number().optional().nullable(),
      v2DrugItemId: z.number().optional().nullable(),
      reviewId: z.number().optional().nullable(),
      reviewStartAt: z.date().optional().nullable(),
      reviewExpireAt: z.date().optional().nullable(),
    })
    .strict()

export const ProcedureItemUncheckedCreateWithoutOrderInputObjectSchema = Schema
