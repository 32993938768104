import { z } from 'zod'
import { SuggestedProductItemOriginalWhereInputObjectSchema } from './SuggestedProductItemOriginalWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProductItemOriginalListRelationFilter> =
  z
    .object({
      every: z
        .lazy(() => SuggestedProductItemOriginalWhereInputObjectSchema)
        .optional(),
      some: z
        .lazy(() => SuggestedProductItemOriginalWhereInputObjectSchema)
        .optional(),
      none: z
        .lazy(() => SuggestedProductItemOriginalWhereInputObjectSchema)
        .optional(),
    })
    .strict()

export const SuggestedProductItemOriginalListRelationFilterObjectSchema = Schema
