import { Typography } from '@mui/material'
import { FC } from 'react'
import { FormState } from '../../../enum'
import { FormImageInput } from './formImageInput'
import { FormTextInput } from './formTextInput'

interface LicenseSectionProps {
  state?: FormState
}

export const LicenseSection: FC<LicenseSectionProps> = ({ state }) => {
  const disabled = state === FormState.EDIT ? true : false
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ข้อมูลใบอนุญาต
      </Typography>
      <FormTextInput
        source="licenseNumber"
        label="เลขที่ใบอนุญาต"
        disabled={disabled}
      />
      <FormImageInput
        source="licensePicture"
        label="รูปใบอนุญาต"
        disabled={disabled}
      />
    </>
  )
}
