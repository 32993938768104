import * as z from "zod"
import { CompleteCarousel, RelatedCarouselModel, CompleteProcedure, RelatedProcedureModel } from "./index"

export const CarouselItemModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  active: z.boolean(),
  priority: z.number().int(),
  carouselId: z.number().int(),
  procedureId: z.number().int(),
})

export interface CompleteCarouselItem extends z.infer<typeof CarouselItemModel> {
  carousel: CompleteCarousel
  procedure: CompleteProcedure
}

/**
 * RelatedCarouselItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCarouselItemModel: z.ZodSchema<CompleteCarouselItem> = z.lazy(() => CarouselItemModel.extend({
  carousel: RelatedCarouselModel,
  procedure: RelatedProcedureModel,
}))
