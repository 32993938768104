import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OfficeHourCountOrderByAggregateInputObjectSchema } from './OfficeHourCountOrderByAggregateInput.schema'
import { OfficeHourAvgOrderByAggregateInputObjectSchema } from './OfficeHourAvgOrderByAggregateInput.schema'
import { OfficeHourMaxOrderByAggregateInputObjectSchema } from './OfficeHourMaxOrderByAggregateInput.schema'
import { OfficeHourMinOrderByAggregateInputObjectSchema } from './OfficeHourMinOrderByAggregateInput.schema'
import { OfficeHourSumOrderByAggregateInputObjectSchema } from './OfficeHourSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.OfficeHourOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    weekday: z.lazy(() => SortOrderSchema).optional(),
    openAt: z.lazy(() => SortOrderSchema).optional(),
    closeAt: z.lazy(() => SortOrderSchema).optional(),
    timezone: z.lazy(() => SortOrderSchema).optional(),
    branchId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => OfficeHourCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => OfficeHourAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => OfficeHourMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => OfficeHourMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => OfficeHourSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const OfficeHourOrderByWithAggregationInputObjectSchema = Schema
