import { z } from 'zod'
import { UseDurationUnitSchema } from '../enums/UseDurationUnit.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumUseDurationUnitNullableFilterObjectSchema } from './NestedEnumUseDurationUnitNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.NestedEnumUseDurationUnitNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => UseDurationUnitSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => UseDurationUnitSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => UseDurationUnitSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => UseDurationUnitSchema),
          z.lazy(
            () =>
              NestedEnumUseDurationUnitNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumUseDurationUnitNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumUseDurationUnitNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumUseDurationUnitNullableWithAggregatesFilterObjectSchema =
  Schema
