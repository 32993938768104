import { z } from 'zod'
import { ProcedureReviewPictureScalarWhereInputObjectSchema } from './ProcedureReviewPictureScalarWhereInput.schema'
import { ProcedureReviewPictureUpdateManyMutationInputObjectSchema } from './ProcedureReviewPictureUpdateManyMutationInput.schema'
import { ProcedureReviewPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema } from './ProcedureReviewPictureUncheckedUpdateManyWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewPictureScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureReviewPictureUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            ProcedureReviewPictureUncheckedUpdateManyWithoutPicturesInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewPictureUpdateManyWithWhereWithoutProcedureReviewInputObjectSchema =
  Schema
