import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { BrandOrderByWithRelationInputObjectSchema } from './BrandOrderByWithRelationInput.schema'
import { ProductCategoryOrderByRelationAggregateInputObjectSchema } from './ProductCategoryOrderByRelationAggregateInput.schema'
import { VariantOrderByRelationAggregateInputObjectSchema } from './VariantOrderByRelationAggregateInput.schema'
import { ProductPictureOrderByRelationAggregateInputObjectSchema } from './ProductPictureOrderByRelationAggregateInput.schema'
import { ProductVideoOrderByRelationAggregateInputObjectSchema } from './ProductVideoOrderByRelationAggregateInput.schema'
import { ProductReviewOrderByRelationAggregateInputObjectSchema } from './ProductReviewOrderByRelationAggregateInput.schema'
import { ProductReviewSummaryOrderByWithRelationInputObjectSchema } from './ProductReviewSummaryOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    searchable: z.lazy(() => SortOrderSchema).optional(),
    nameTr: z.lazy(() => SortOrderSchema).optional(),
    searchField: z.lazy(() => SortOrderSchema).optional(),
    regNo: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    auxiliaryLabel: z.lazy(() => SortOrderSchema).optional(),
    instructionTr: z.lazy(() => SortOrderSchema).optional(),
    sideEffectTr: z.lazy(() => SortOrderSchema).optional(),
    cautionTr: z.lazy(() => SortOrderSchema).optional(),
    pharmacyNote: z.lazy(() => SortOrderSchema).optional(),
    ingredient: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    drugType: z.lazy(() => SortOrderSchema).optional(),
    salesType: z.lazy(() => SortOrderSchema).optional(),
    productForms: z.lazy(() => SortOrderSchema).optional(),
    skinType: z.lazy(() => SortOrderSchema).optional(),
    propertyTr: z.lazy(() => SortOrderSchema).optional(),
    concerns: z.lazy(() => SortOrderSchema).optional(),
    attributes: z.lazy(() => SortOrderSchema).optional(),
    priority: z.lazy(() => SortOrderSchema).optional(),
    usage: z.lazy(() => SortOrderSchema).optional(),
    useAmount: z.lazy(() => SortOrderSchema).optional(),
    useUnit: z.lazy(() => SortOrderSchema).optional(),
    useFrequency: z.lazy(() => SortOrderSchema).optional(),
    useTime: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    deletedAt: z.lazy(() => SortOrderSchema).optional(),
    v2DrugItemId: z.lazy(() => SortOrderSchema).optional(),
    brand: z.lazy(() => BrandOrderByWithRelationInputObjectSchema).optional(),
    categories: z
      .lazy(() => ProductCategoryOrderByRelationAggregateInputObjectSchema)
      .optional(),
    variants: z
      .lazy(() => VariantOrderByRelationAggregateInputObjectSchema)
      .optional(),
    pictures: z
      .lazy(() => ProductPictureOrderByRelationAggregateInputObjectSchema)
      .optional(),
    video: z
      .lazy(() => ProductVideoOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productReviews: z
      .lazy(() => ProductReviewOrderByRelationAggregateInputObjectSchema)
      .optional(),
    productReviewSummary: z
      .lazy(() => ProductReviewSummaryOrderByWithRelationInputObjectSchema)
      .optional(),
  })
  .strict()

export const ProductOrderByWithRelationInputObjectSchema = Schema
