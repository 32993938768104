import { z } from 'zod'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { BrandBannerUpdateManyWithoutBrandNestedInputObjectSchema } from './BrandBannerUpdateManyWithoutBrandNestedInput.schema'
import { ProductReviewUpdateManyWithoutBrandNestedInputObjectSchema } from './ProductReviewUpdateManyWithoutBrandNestedInput.schema'
import { ProductReviewSummaryUpdateManyWithoutBrandNestedInputObjectSchema } from './ProductReviewSummaryUpdateManyWithoutBrandNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandUpdateWithoutProductsInput> = z
  .object({
    name: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    isShow: z
      .union([
        z.boolean(),
        z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    coporateName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    countryName: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    logo: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    priority: z
      .union([
        z.number(),
        z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    banners: z
      .lazy(() => BrandBannerUpdateManyWithoutBrandNestedInputObjectSchema)
      .optional(),
    productReviews: z
      .lazy(() => ProductReviewUpdateManyWithoutBrandNestedInputObjectSchema)
      .optional(),
    productReviewSummaries: z
      .lazy(
        () => ProductReviewSummaryUpdateManyWithoutBrandNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const BrandUpdateWithoutProductsInputObjectSchema = Schema
