import { z } from 'zod'
import { ProcedureOrderWhereUniqueInputObjectSchema } from './objects/ProcedureOrderWhereUniqueInput.schema'
import { ProcedureOrderCreateInputObjectSchema } from './objects/ProcedureOrderCreateInput.schema'
import { ProcedureOrderUncheckedCreateInputObjectSchema } from './objects/ProcedureOrderUncheckedCreateInput.schema'
import { ProcedureOrderUpdateInputObjectSchema } from './objects/ProcedureOrderUpdateInput.schema'
import { ProcedureOrderUncheckedUpdateInputObjectSchema } from './objects/ProcedureOrderUncheckedUpdateInput.schema'

export const ProcedureOrderUpsertSchema = z.object({
  where: ProcedureOrderWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureOrderCreateInputObjectSchema,
    ProcedureOrderUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureOrderUpdateInputObjectSchema,
    ProcedureOrderUncheckedUpdateInputObjectSchema,
  ]),
})
