import { z } from 'zod'
import { ProductCreateWithoutProductReviewsInputObjectSchema } from './ProductCreateWithoutProductReviewsInput.schema'
import { ProductUncheckedCreateWithoutProductReviewsInputObjectSchema } from './ProductUncheckedCreateWithoutProductReviewsInput.schema'
import { ProductCreateOrConnectWithoutProductReviewsInputObjectSchema } from './ProductCreateOrConnectWithoutProductReviewsInput.schema'
import { ProductWhereUniqueInputObjectSchema } from './ProductWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCreateNestedOneWithoutProductReviewsInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductCreateWithoutProductReviewsInputObjectSchema),
          z.lazy(
            () => ProductUncheckedCreateWithoutProductReviewsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProductCreateOrConnectWithoutProductReviewsInputObjectSchema,
        )
        .optional(),
      connect: z.lazy(() => ProductWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const ProductCreateNestedOneWithoutProductReviewsInputObjectSchema =
  Schema
