import { z } from 'zod'
import { AddressWhereUniqueInputObjectSchema } from './AddressWhereUniqueInput.schema'
import { AddressCreateWithoutPatientInputObjectSchema } from './AddressCreateWithoutPatientInput.schema'
import { AddressUncheckedCreateWithoutPatientInputObjectSchema } from './AddressUncheckedCreateWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AddressCreateOrConnectWithoutPatientInput> = z
  .object({
    where: z.lazy(() => AddressWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => AddressCreateWithoutPatientInputObjectSchema),
      z.lazy(() => AddressUncheckedCreateWithoutPatientInputObjectSchema),
    ]),
  })
  .strict()

export const AddressCreateOrConnectWithoutPatientInputObjectSchema = Schema
