import { z } from 'zod'
import { ProductReviewWhereUniqueInputObjectSchema } from './objects/ProductReviewWhereUniqueInput.schema'
import { ProductReviewCreateInputObjectSchema } from './objects/ProductReviewCreateInput.schema'
import { ProductReviewUncheckedCreateInputObjectSchema } from './objects/ProductReviewUncheckedCreateInput.schema'
import { ProductReviewUpdateInputObjectSchema } from './objects/ProductReviewUpdateInput.schema'
import { ProductReviewUncheckedUpdateInputObjectSchema } from './objects/ProductReviewUncheckedUpdateInput.schema'

export const ProductReviewUpsertSchema = z.object({
  where: ProductReviewWhereUniqueInputObjectSchema,
  create: z.union([
    ProductReviewCreateInputObjectSchema,
    ProductReviewUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductReviewUpdateInputObjectSchema,
    ProductReviewUncheckedUpdateInputObjectSchema,
  ]),
})
