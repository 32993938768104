import { z } from 'zod'
import { ProductReviewCreateWithoutPicturesInputObjectSchema } from './ProductReviewCreateWithoutPicturesInput.schema'
import { ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductReviewUncheckedCreateWithoutPicturesInput.schema'
import { ProductReviewCreateOrConnectWithoutPicturesInputObjectSchema } from './ProductReviewCreateOrConnectWithoutPicturesInput.schema'
import { ProductReviewUpsertWithoutPicturesInputObjectSchema } from './ProductReviewUpsertWithoutPicturesInput.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'
import { ProductReviewUpdateWithoutPicturesInputObjectSchema } from './ProductReviewUpdateWithoutPicturesInput.schema'
import { ProductReviewUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpdateOneRequiredWithoutPicturesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutPicturesInputObjectSchema),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ProductReviewCreateOrConnectWithoutPicturesInputObjectSchema,
        )
        .optional(),
      upsert: z
        .lazy(() => ProductReviewUpsertWithoutPicturesInputObjectSchema)
        .optional(),
      connect: z
        .lazy(() => ProductReviewWhereUniqueInputObjectSchema)
        .optional(),
      update: z
        .union([
          z.lazy(() => ProductReviewUpdateWithoutPicturesInputObjectSchema),
          z.lazy(
            () => ProductReviewUncheckedUpdateWithoutPicturesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewUpdateOneRequiredWithoutPicturesNestedInputObjectSchema =
  Schema
