import { z } from 'zod'
import { ScheduleSelectObjectSchema } from './ScheduleSelect.schema'
import { ScheduleIncludeObjectSchema } from './ScheduleInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.ScheduleArgs> = z
  .object({
    select: z.lazy(() => ScheduleSelectObjectSchema).optional(),
    include: z.lazy(() => ScheduleIncludeObjectSchema).optional(),
  })
  .strict()

export const ScheduleArgsObjectSchema = Schema
