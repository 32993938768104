import { z } from 'zod'
import { ProductPictureCreateManyProductInputObjectSchema } from './ProductPictureCreateManyProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductPictureCreateManyProductInputEnvelope> = z
  .object({
    data: z.union([
      z.lazy(() => ProductPictureCreateManyProductInputObjectSchema),
      z.lazy(() => ProductPictureCreateManyProductInputObjectSchema).array(),
    ]),
    skipDuplicates: z.boolean().optional(),
  })
  .strict()

export const ProductPictureCreateManyProductInputEnvelopeObjectSchema = Schema
