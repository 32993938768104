import { z } from 'zod'
import { AcceptedConsentWhereUniqueInputObjectSchema } from './AcceptedConsentWhereUniqueInput.schema'
import { AcceptedConsentUpdateWithoutConsentInputObjectSchema } from './AcceptedConsentUpdateWithoutConsentInput.schema'
import { AcceptedConsentUncheckedUpdateWithoutConsentInputObjectSchema } from './AcceptedConsentUncheckedUpdateWithoutConsentInput.schema'
import { AcceptedConsentCreateWithoutConsentInputObjectSchema } from './AcceptedConsentCreateWithoutConsentInput.schema'
import { AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema } from './AcceptedConsentUncheckedCreateWithoutConsentInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpsertWithWhereUniqueWithoutConsentInput> =
  z
    .object({
      where: z.lazy(() => AcceptedConsentWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => AcceptedConsentUpdateWithoutConsentInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedUpdateWithoutConsentInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => AcceptedConsentCreateWithoutConsentInputObjectSchema),
        z.lazy(
          () => AcceptedConsentUncheckedCreateWithoutConsentInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const AcceptedConsentUpsertWithWhereUniqueWithoutConsentInputObjectSchema =
  Schema
