import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemOriginalSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    variantId: z.literal(true).optional(),
    quantity: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    flashSaleId: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
  })
  .strict()

export const ProductItemOriginalSumAggregateInputObjectSchema = Schema
