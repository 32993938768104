import { z } from 'zod'
import { BrandBannerWhereUniqueInputObjectSchema } from './BrandBannerWhereUniqueInput.schema'
import { BrandBannerCreateWithoutBrandInputObjectSchema } from './BrandBannerCreateWithoutBrandInput.schema'
import { BrandBannerUncheckedCreateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedCreateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerCreateOrConnectWithoutBrandInput> = z
  .object({
    where: z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema),
      z.lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema),
    ]),
  })
  .strict()

export const BrandBannerCreateOrConnectWithoutBrandInputObjectSchema = Schema
