import { z } from 'zod'
import { WaitingBannerOrderByWithRelationInputObjectSchema } from './objects/WaitingBannerOrderByWithRelationInput.schema'
import { WaitingBannerWhereInputObjectSchema } from './objects/WaitingBannerWhereInput.schema'
import { WaitingBannerWhereUniqueInputObjectSchema } from './objects/WaitingBannerWhereUniqueInput.schema'
import { WaitingBannerScalarFieldEnumSchema } from './enums/WaitingBannerScalarFieldEnum.schema'

export const WaitingBannerFindManySchema = z.object({
  orderBy: z
    .union([
      WaitingBannerOrderByWithRelationInputObjectSchema,
      WaitingBannerOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: WaitingBannerWhereInputObjectSchema.optional(),
  cursor: WaitingBannerWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(WaitingBannerScalarFieldEnumSchema).optional(),
})
