import { z } from 'zod'
import { KycDataCreateWithoutRejectReasonInputObjectSchema } from './KycDataCreateWithoutRejectReasonInput.schema'
import { KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema } from './KycDataUncheckedCreateWithoutRejectReasonInput.schema'
import { KycDataCreateOrConnectWithoutRejectReasonInputObjectSchema } from './KycDataCreateOrConnectWithoutRejectReasonInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './KycDataWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.KycDataCreateNestedOneWithoutRejectReasonInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => KycDataCreateWithoutRejectReasonInputObjectSchema),
          z.lazy(
            () => KycDataUncheckedCreateWithoutRejectReasonInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => KycDataCreateOrConnectWithoutRejectReasonInputObjectSchema)
        .optional(),
      connect: z.lazy(() => KycDataWhereUniqueInputObjectSchema).optional(),
    })
    .strict()

export const KycDataCreateNestedOneWithoutRejectReasonInputObjectSchema = Schema
