import { z } from 'zod'
import { DiscountTypeSchema } from '../enums/DiscountType.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumDiscountTypeFilterObjectSchema } from './NestedEnumDiscountTypeFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.NestedEnumDiscountTypeWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => DiscountTypeSchema).optional(),
    in: z
      .lazy(() => DiscountTypeSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => DiscountTypeSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => DiscountTypeSchema),
        z.lazy(() => NestedEnumDiscountTypeWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumDiscountTypeFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumDiscountTypeFilterObjectSchema).optional(),
  })
  .strict()

export const NestedEnumDiscountTypeWithAggregatesFilterObjectSchema = Schema
