import { z } from 'zod'
import { RejectReasonScalarWhereInputObjectSchema } from './RejectReasonScalarWhereInput.schema'
import { RejectReasonUpdateManyMutationInputObjectSchema } from './RejectReasonUpdateManyMutationInput.schema'
import { RejectReasonUncheckedUpdateManyWithoutRejectReasonInputObjectSchema } from './RejectReasonUncheckedUpdateManyWithoutRejectReasonInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonUpdateManyWithWhereWithoutKycDataInput> =
  z
    .object({
      where: z.lazy(() => RejectReasonScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => RejectReasonUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            RejectReasonUncheckedUpdateManyWithoutRejectReasonInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const RejectReasonUpdateManyWithWhereWithoutKycDataInputObjectSchema =
  Schema
