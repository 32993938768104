import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { WaitingBannerCountOrderByAggregateInputObjectSchema } from './WaitingBannerCountOrderByAggregateInput.schema'
import { WaitingBannerAvgOrderByAggregateInputObjectSchema } from './WaitingBannerAvgOrderByAggregateInput.schema'
import { WaitingBannerMaxOrderByAggregateInputObjectSchema } from './WaitingBannerMaxOrderByAggregateInput.schema'
import { WaitingBannerMinOrderByAggregateInputObjectSchema } from './WaitingBannerMinOrderByAggregateInput.schema'
import { WaitingBannerSumOrderByAggregateInputObjectSchema } from './WaitingBannerSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.WaitingBannerOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    name: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    actionUri: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    rank: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => WaitingBannerCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => WaitingBannerAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => WaitingBannerMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => WaitingBannerMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => WaitingBannerSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const WaitingBannerOrderByWithAggregationInputObjectSchema = Schema
