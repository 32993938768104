import { z } from 'zod'
import { IdentityWhereUniqueInputObjectSchema } from './objects/IdentityWhereUniqueInput.schema'
import { IdentityCreateInputObjectSchema } from './objects/IdentityCreateInput.schema'
import { IdentityUncheckedCreateInputObjectSchema } from './objects/IdentityUncheckedCreateInput.schema'
import { IdentityUpdateInputObjectSchema } from './objects/IdentityUpdateInput.schema'
import { IdentityUncheckedUpdateInputObjectSchema } from './objects/IdentityUncheckedUpdateInput.schema'

export const IdentityUpsertSchema = z.object({
  where: IdentityWhereUniqueInputObjectSchema,
  create: z.union([
    IdentityCreateInputObjectSchema,
    IdentityUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    IdentityUpdateInputObjectSchema,
    IdentityUncheckedUpdateInputObjectSchema,
  ]),
})
