import { z } from 'zod'
import { VariantUpdateWithoutVariantWarehousesInputObjectSchema } from './VariantUpdateWithoutVariantWarehousesInput.schema'
import { VariantUncheckedUpdateWithoutVariantWarehousesInputObjectSchema } from './VariantUncheckedUpdateWithoutVariantWarehousesInput.schema'
import { VariantCreateWithoutVariantWarehousesInputObjectSchema } from './VariantCreateWithoutVariantWarehousesInput.schema'
import { VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema } from './VariantUncheckedCreateWithoutVariantWarehousesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantUpsertWithoutVariantWarehousesInput> = z
  .object({
    update: z.union([
      z.lazy(() => VariantUpdateWithoutVariantWarehousesInputObjectSchema),
      z.lazy(
        () => VariantUncheckedUpdateWithoutVariantWarehousesInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => VariantCreateWithoutVariantWarehousesInputObjectSchema),
      z.lazy(
        () => VariantUncheckedCreateWithoutVariantWarehousesInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const VariantUpsertWithoutVariantWarehousesInputObjectSchema = Schema
