import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.OtpOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    ipAddress: z.lazy(() => SortOrderSchema).optional(),
    phone: z.lazy(() => SortOrderSchema).optional(),
    reference: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    expiredAt: z.lazy(() => SortOrderSchema).optional(),
    verifiedAt: z.lazy(() => SortOrderSchema).optional(),
    otp: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const OtpOrderByWithRelationInputObjectSchema = Schema
