import { z } from 'zod'
import { HealthInfoOrderByWithRelationInputObjectSchema } from './objects/HealthInfoOrderByWithRelationInput.schema'
import { HealthInfoWhereInputObjectSchema } from './objects/HealthInfoWhereInput.schema'
import { HealthInfoWhereUniqueInputObjectSchema } from './objects/HealthInfoWhereUniqueInput.schema'
import { HealthInfoCountAggregateInputObjectSchema } from './objects/HealthInfoCountAggregateInput.schema'
import { HealthInfoMinAggregateInputObjectSchema } from './objects/HealthInfoMinAggregateInput.schema'
import { HealthInfoMaxAggregateInputObjectSchema } from './objects/HealthInfoMaxAggregateInput.schema'
import { HealthInfoAvgAggregateInputObjectSchema } from './objects/HealthInfoAvgAggregateInput.schema'
import { HealthInfoSumAggregateInputObjectSchema } from './objects/HealthInfoSumAggregateInput.schema'

export const HealthInfoAggregateSchema = z.object({
  orderBy: z
    .union([
      HealthInfoOrderByWithRelationInputObjectSchema,
      HealthInfoOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: HealthInfoWhereInputObjectSchema.optional(),
  cursor: HealthInfoWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), HealthInfoCountAggregateInputObjectSchema])
    .optional(),
  _min: HealthInfoMinAggregateInputObjectSchema.optional(),
  _max: HealthInfoMaxAggregateInputObjectSchema.optional(),
  _avg: HealthInfoAvgAggregateInputObjectSchema.optional(),
  _sum: HealthInfoSumAggregateInputObjectSchema.optional(),
})
