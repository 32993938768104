import { z } from 'zod'
import { DiagnosisWhereUniqueInputObjectSchema } from './objects/DiagnosisWhereUniqueInput.schema'
import { DiagnosisCreateInputObjectSchema } from './objects/DiagnosisCreateInput.schema'
import { DiagnosisUncheckedCreateInputObjectSchema } from './objects/DiagnosisUncheckedCreateInput.schema'
import { DiagnosisUpdateInputObjectSchema } from './objects/DiagnosisUpdateInput.schema'
import { DiagnosisUncheckedUpdateInputObjectSchema } from './objects/DiagnosisUncheckedUpdateInput.schema'

export const DiagnosisUpsertSchema = z.object({
  where: DiagnosisWhereUniqueInputObjectSchema,
  create: z.union([
    DiagnosisCreateInputObjectSchema,
    DiagnosisUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    DiagnosisUpdateInputObjectSchema,
    DiagnosisUncheckedUpdateInputObjectSchema,
  ]),
})
