import { z } from 'zod'
import { OrderUpdateWithoutHealthInfoInputObjectSchema } from './OrderUpdateWithoutHealthInfoInput.schema'
import { OrderUncheckedUpdateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedUpdateWithoutHealthInfoInput.schema'
import { OrderCreateWithoutHealthInfoInputObjectSchema } from './OrderCreateWithoutHealthInfoInput.schema'
import { OrderUncheckedCreateWithoutHealthInfoInputObjectSchema } from './OrderUncheckedCreateWithoutHealthInfoInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpsertWithoutHealthInfoInput> = z
  .object({
    update: z.union([
      z.lazy(() => OrderUpdateWithoutHealthInfoInputObjectSchema),
      z.lazy(() => OrderUncheckedUpdateWithoutHealthInfoInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => OrderCreateWithoutHealthInfoInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutHealthInfoInputObjectSchema),
    ]),
  })
  .strict()

export const OrderUpsertWithoutHealthInfoInputObjectSchema = Schema
