import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.DiagnosisCountOutputTypeSelect> = z
  .object({
    caseSummaryDiagnosis: z.boolean().optional(),
  })
  .strict()

export const DiagnosisCountOutputTypeSelectObjectSchema = Schema
