import { z } from 'zod'
import { SkuUpdateInputObjectSchema } from './objects/SkuUpdateInput.schema'
import { SkuUncheckedUpdateInputObjectSchema } from './objects/SkuUncheckedUpdateInput.schema'
import { SkuWhereUniqueInputObjectSchema } from './objects/SkuWhereUniqueInput.schema'

export const SkuUpdateOneSchema = z.object({
  data: z.union([
    SkuUpdateInputObjectSchema,
    SkuUncheckedUpdateInputObjectSchema,
  ]),
  where: SkuWhereUniqueInputObjectSchema,
})
