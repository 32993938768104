import { z } from 'zod'
import { GenderSchema } from '../enums/Gender.schema'
import { AcceptedConsentCreateNestedManyWithoutPatientInputObjectSchema } from './AcceptedConsentCreateNestedManyWithoutPatientInput.schema'
import { KycDataCreateNestedOneWithoutPatientInputObjectSchema } from './KycDataCreateNestedOneWithoutPatientInput.schema'
import { HealthInfoCreateNestedOneWithoutPatientInputObjectSchema } from './HealthInfoCreateNestedOneWithoutPatientInput.schema'
import { NotificationPatientCreateNestedManyWithoutPatientInputObjectSchema } from './NotificationPatientCreateNestedManyWithoutPatientInput.schema'
import { AddressCreateNestedManyWithoutPatientInputObjectSchema } from './AddressCreateNestedManyWithoutPatientInput.schema'
import { IdentityCreateNestedManyWithoutPatientInputObjectSchema } from './IdentityCreateNestedManyWithoutPatientInput.schema'
import { MyReviewCreateNestedManyWithoutPatientInputObjectSchema } from './MyReviewCreateNestedManyWithoutPatientInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateWithoutCoinTransactionsInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    prefix: z.string().optional().nullable(),
    firstname: z.string().optional().nullable(),
    lastname: z.string().optional().nullable(),
    nickname: z.string().optional().nullable(),
    gender: z
      .lazy(() => GenderSchema)
      .optional()
      .nullable(),
    birthDate: z.date().optional().nullable(),
    picture: z.string().optional().nullable(),
    isTest: z.boolean().optional(),
    active: z.boolean().optional(),
    registered: z.boolean().optional(),
    drugAllergy: z.string().optional().nullable(),
    congenitalDisease: z.string().optional().nullable(),
    receiveNotifications: z.boolean().optional(),
    activityNotifications: z.boolean().optional(),
    chatNotifications: z.boolean().optional(),
    orderNotifications: z.boolean().optional(),
    omiseCustomerId: z.string().optional(),
    freshchatUserId: z.string().optional().nullable(),
    freshchatRestoreId: z.string().optional().nullable(),
    coin: z.number().optional(),
    v2PatientId: z.number().optional().nullable(),
    acceptedConsents: z
      .lazy(
        () => AcceptedConsentCreateNestedManyWithoutPatientInputObjectSchema,
      )
      .optional(),
    kycData: z
      .lazy(() => KycDataCreateNestedOneWithoutPatientInputObjectSchema)
      .optional(),
    healthCheckUpInfo: z
      .lazy(() => HealthInfoCreateNestedOneWithoutPatientInputObjectSchema)
      .optional(),
    notifications: z
      .lazy(
        () =>
          NotificationPatientCreateNestedManyWithoutPatientInputObjectSchema,
      )
      .optional(),
    address: z
      .lazy(() => AddressCreateNestedManyWithoutPatientInputObjectSchema)
      .optional(),
    identities: z
      .lazy(() => IdentityCreateNestedManyWithoutPatientInputObjectSchema)
      .optional(),
    myReviews: z
      .lazy(() => MyReviewCreateNestedManyWithoutPatientInputObjectSchema)
      .optional(),
  })
  .strict()

export const PatientCreateWithoutCoinTransactionsInputObjectSchema = Schema
