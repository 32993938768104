import { z } from 'zod'
import { ProcedureUsageStatusSchema } from '../enums/ProcedureUsageStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.EnumProcedureUsageStatusFieldUpdateOperationsInput> =
  z
    .object({
      set: z.lazy(() => ProcedureUsageStatusSchema).optional(),
    })
    .strict()

export const EnumProcedureUsageStatusFieldUpdateOperationsInputObjectSchema =
  Schema
