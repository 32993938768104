import * as z from "zod"
import { CompleteExpressShippingArea, RelatedExpressShippingAreaModel, CompleteOrder, RelatedOrderModel } from "./index"

export const ShippingProviderModel = z.object({
  id: z.number().int(),
  priority: z.number().int(),
  name: z.string(),
  detail: z.string(),
  moreDetail: z.string().nullish(),
  shippingExpectation: z.string(),
  trackingUrl: z.string().nullish(),
  type: z.enum(['NORMAL', 'EXPRESS']),
  price: z.number(),
  freeShippingMinimumPrice: z.number().nullish(),
  active: z.boolean(),
})

export interface CompleteShippingProvider extends z.infer<typeof ShippingProviderModel> {
  areas: CompleteExpressShippingArea[]
  orders: CompleteOrder[]
}

/**
 * RelatedShippingProviderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedShippingProviderModel: z.ZodSchema<CompleteShippingProvider> = z.lazy(() => ShippingProviderModel.extend({
  areas: RelatedExpressShippingAreaModel.array(),
  orders: RelatedOrderModel.array(),
}))
