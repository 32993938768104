import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { ProcedureRelationFilterObjectSchema } from './ProcedureRelationFilter.schema'
import { ProcedureWhereInputObjectSchema } from './ProcedureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewSummaryWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => ProcedureReviewSummaryWhereInputObjectSchema),
        z.lazy(() => ProcedureReviewSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => ProcedureReviewSummaryWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => ProcedureReviewSummaryWhereInputObjectSchema),
        z.lazy(() => ProcedureReviewSummaryWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    partnerId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    overallRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    environmentRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    serviceRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    specialtyRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    valueRating: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    countForRating5: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating4: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating3: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating2: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    countForRating1: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedure: z
      .union([
        z.lazy(() => ProcedureRelationFilterObjectSchema),
        z.lazy(() => ProcedureWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProcedureReviewSummaryWhereInputObjectSchema = Schema
