import { z } from 'zod'
import { ConsentCreateNestedOneWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateNestedOneWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentCreateWithoutPatientInput> = z
  .object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    consent: z.lazy(
      () => ConsentCreateNestedOneWithoutAcceptedConsentsInputObjectSchema,
    ),
  })
  .strict()

export const AcceptedConsentCreateWithoutPatientInputObjectSchema = Schema
