import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { FloatFilterObjectSchema } from './FloatFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { EnumPaymentGatewayFilterObjectSchema } from './EnumPaymentGatewayFilter.schema'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { EnumRefundStatusFilterObjectSchema } from './EnumRefundStatusFilter.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { ProductItemListRelationFilterObjectSchema } from './ProductItemListRelationFilter.schema'
import { ProcedureItemListRelationFilterObjectSchema } from './ProcedureItemListRelationFilter.schema'
import { OrderRelationFilterObjectSchema } from './OrderRelationFilter.schema'
import { OrderWhereInputObjectSchema } from './OrderWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => RefundWhereInputObjectSchema),
        z.lazy(() => RefundWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => RefundWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => RefundWhereInputObjectSchema),
        z.lazy(() => RefundWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    amount: z
      .union([z.lazy(() => FloatFilterObjectSchema), z.number()])
      .optional(),
    currency: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    refundAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    paymentGateway: z
      .union([
        z.lazy(() => EnumPaymentGatewayFilterObjectSchema),
        z.lazy(() => PaymentGatewaySchema),
      ])
      .optional(),
    status: z
      .union([
        z.lazy(() => EnumRefundStatusFilterObjectSchema),
        z.lazy(() => RefundStatusSchema),
      ])
      .optional(),
    reason: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    orderId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    productItems: z
      .lazy(() => ProductItemListRelationFilterObjectSchema)
      .optional(),
    procedureItems: z
      .lazy(() => ProcedureItemListRelationFilterObjectSchema)
      .optional(),
    order: z
      .union([
        z.lazy(() => OrderRelationFilterObjectSchema),
        z.lazy(() => OrderWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const RefundWhereInputObjectSchema = Schema
