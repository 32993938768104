import { z } from 'zod'
import { DeclinedSuggestedItemsReasonSchema } from '../enums/DeclinedSuggestedItemsReason.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema } from './NestedEnumDeclinedSuggestedItemsReasonNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumDeclinedSuggestedItemsReasonNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => DeclinedSuggestedItemsReasonSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => DeclinedSuggestedItemsReasonSchema),
          z.lazy(
            () =>
              NestedEnumDeclinedSuggestedItemsReasonNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(
          () =>
            NestedEnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema,
        )
        .optional(),
      _max: z
        .lazy(
          () =>
            NestedEnumDeclinedSuggestedItemsReasonNullableFilterObjectSchema,
        )
        .optional(),
    })
    .strict()

export const NestedEnumDeclinedSuggestedItemsReasonNullableWithAggregatesFilterObjectSchema =
  Schema
