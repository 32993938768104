import { z } from 'zod'
import { FlashsaleItemWhereUniqueInputObjectSchema } from './FlashsaleItemWhereUniqueInput.schema'
import { FlashsaleItemCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemCreateWithoutFlashsaleInput.schema'
import { FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema } from './FlashsaleItemUncheckedCreateWithoutFlashsaleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.FlashsaleItemCreateOrConnectWithoutFlashsaleInput> =
  z
    .object({
      where: z.lazy(() => FlashsaleItemWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => FlashsaleItemCreateWithoutFlashsaleInputObjectSchema),
        z.lazy(
          () => FlashsaleItemUncheckedCreateWithoutFlashsaleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const FlashsaleItemCreateOrConnectWithoutFlashsaleInputObjectSchema =
  Schema
