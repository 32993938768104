import { z } from 'zod'
import { ProductReviewSelectObjectSchema } from './ProductReviewSelect.schema'
import { ProductReviewIncludeObjectSchema } from './ProductReviewInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewArgs> = z
  .object({
    select: z.lazy(() => ProductReviewSelectObjectSchema).optional(),
    include: z.lazy(() => ProductReviewIncludeObjectSchema).optional(),
  })
  .strict()

export const ProductReviewArgsObjectSchema = Schema
