import { z } from 'zod'
import { CoverPictureOrderByWithRelationInputObjectSchema } from './objects/CoverPictureOrderByWithRelationInput.schema'
import { CoverPictureWhereInputObjectSchema } from './objects/CoverPictureWhereInput.schema'
import { CoverPictureWhereUniqueInputObjectSchema } from './objects/CoverPictureWhereUniqueInput.schema'
import { CoverPictureCountAggregateInputObjectSchema } from './objects/CoverPictureCountAggregateInput.schema'
import { CoverPictureMinAggregateInputObjectSchema } from './objects/CoverPictureMinAggregateInput.schema'
import { CoverPictureMaxAggregateInputObjectSchema } from './objects/CoverPictureMaxAggregateInput.schema'
import { CoverPictureAvgAggregateInputObjectSchema } from './objects/CoverPictureAvgAggregateInput.schema'
import { CoverPictureSumAggregateInputObjectSchema } from './objects/CoverPictureSumAggregateInput.schema'

export const CoverPictureAggregateSchema = z.object({
  orderBy: z
    .union([
      CoverPictureOrderByWithRelationInputObjectSchema,
      CoverPictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CoverPictureWhereInputObjectSchema.optional(),
  cursor: CoverPictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CoverPictureCountAggregateInputObjectSchema])
    .optional(),
  _min: CoverPictureMinAggregateInputObjectSchema.optional(),
  _max: CoverPictureMaxAggregateInputObjectSchema.optional(),
  _avg: CoverPictureAvgAggregateInputObjectSchema.optional(),
  _sum: CoverPictureSumAggregateInputObjectSchema.optional(),
})
