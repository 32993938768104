import { z } from 'zod'
import { ProductArgsObjectSchema } from './ProductArgs.schema'
import { VariantPictureFindManySchema } from '../findManyVariantPicture.schema'
import { FlashsaleItemFindManySchema } from '../findManyFlashsaleItem.schema'
import { ProductReviewFindManySchema } from '../findManyProductReview.schema'
import { VariantWarehouseFindManySchema } from '../findManyVariantWarehouse.schema'
import { VariantCountOutputTypeArgsObjectSchema } from './VariantCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantInclude> = z
  .object({
    product: z
      .union([z.boolean(), z.lazy(() => ProductArgsObjectSchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => VariantPictureFindManySchema)])
      .optional(),
    flashsaleItem: z
      .union([z.boolean(), z.lazy(() => FlashsaleItemFindManySchema)])
      .optional(),
    productReviews: z
      .union([z.boolean(), z.lazy(() => ProductReviewFindManySchema)])
      .optional(),
    variantWarehouses: z
      .union([z.boolean(), z.lazy(() => VariantWarehouseFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => VariantCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const VariantIncludeObjectSchema = Schema
