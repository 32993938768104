import { z } from 'zod'
import { ProductReviewCreateWithoutProductInputObjectSchema } from './ProductReviewCreateWithoutProductInput.schema'
import { ProductReviewUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewUncheckedCreateWithoutProductInput.schema'
import { ProductReviewCreateOrConnectWithoutProductInputObjectSchema } from './ProductReviewCreateOrConnectWithoutProductInput.schema'
import { ProductReviewCreateManyProductInputEnvelopeObjectSchema } from './ProductReviewCreateManyProductInputEnvelope.schema'
import { ProductReviewWhereUniqueInputObjectSchema } from './ProductReviewWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewCreateNestedManyWithoutProductInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProductReviewCreateWithoutProductInputObjectSchema),
          z
            .lazy(() => ProductReviewCreateWithoutProductInputObjectSchema)
            .array(),
          z.lazy(
            () => ProductReviewUncheckedCreateWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewUncheckedCreateWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => ProductReviewCreateOrConnectWithoutProductInputObjectSchema,
          ),
          z
            .lazy(
              () => ProductReviewCreateOrConnectWithoutProductInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => ProductReviewCreateManyProductInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema),
          z.lazy(() => ProductReviewWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const ProductReviewCreateNestedManyWithoutProductInputObjectSchema =
  Schema
