import * as z from "zod"
import { CompletePartner, RelatedPartnerModel } from "./index"

export const UserModel = z.object({
  id: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  email: z.string(),
  password: z.string(),
  partnerId: z.number().int(),
  active: z.boolean(),
})

export interface CompleteUser extends z.infer<typeof UserModel> {
  partner: CompletePartner
}

/**
 * RelatedUserModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedUserModel: z.ZodSchema<CompleteUser> = z.lazy(() => UserModel.extend({
  partner: RelatedPartnerModel,
}))
