import { Box, Card, CardContent } from '@mui/material'
import { SaveButton, SimpleForm, Toolbar } from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { ClinicSection } from './create-edit-section/clinic'
import { ProcedureInfoSection } from './create-edit-section/procedureInfo'
import { ProcedurePictureSecion } from './create-edit-section/procedurePicture'
import { ProcedurePropertySection } from './create-edit-section/procedureProperty'
import { transformProcedure } from './transform'

const ProcedureEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="procedure" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProcedureEditForm = () => {
  return (
    <SimpleForm toolbar={<ProcedureEditToolbar />}>
      <ProcedureInfoSection />
      <ProcedurePropertySection />
      <ProcedurePictureSecion />
      <ClinicSection />
    </SimpleForm>
  )
}

export const ProcedureEdit = () => (
  <EditSaveRouteBack
    transform={transformProcedure}
    component="div"
    redirect="list"
    mutationMode="pessimistic"
    sx={{
      alignSelf: 'center',
      paddingBottom: '2rem',
      maxWidth: '900px',
    }}
  >
    <Box>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <ProcedureEditForm />
        </CardContent>
      </Card>
    </Box>
  </EditSaveRouteBack>
)
