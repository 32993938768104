import { Backdrop, CircularProgress } from '@mui/material'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

interface LoadingContextProps {
  loadingCount: number
  increaseLoading: () => void
  decreaseLoading: () => void
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined)

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loadingCount, setLoadingCount] = useState(0)

  const increaseLoading = useCallback(() => {
    setLoadingCount((prevCount) => prevCount + 1)
  }, [])

  const decreaseLoading = useCallback(() => {
    setLoadingCount((prevCount) => Math.max(prevCount - 1, 0))
  }, [])

  const contextValue: LoadingContextProps = {
    loadingCount,
    increaseLoading,
    decreaseLoading,
  }

  return (
    <LoadingContext.Provider value={contextValue}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={contextValue.loadingCount > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
