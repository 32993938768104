import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutRefundsInputObjectSchema } from './OrderCreateWithoutRefundsInput.schema'
import { OrderUncheckedCreateWithoutRefundsInputObjectSchema } from './OrderUncheckedCreateWithoutRefundsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutRefundsInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutRefundsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutRefundsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutRefundsInputObjectSchema = Schema
