import { z } from 'zod'
import { DiscountSettingWhereUniqueInputObjectSchema } from './objects/DiscountSettingWhereUniqueInput.schema'
import { DiscountSettingCreateInputObjectSchema } from './objects/DiscountSettingCreateInput.schema'
import { DiscountSettingUncheckedCreateInputObjectSchema } from './objects/DiscountSettingUncheckedCreateInput.schema'
import { DiscountSettingUpdateInputObjectSchema } from './objects/DiscountSettingUpdateInput.schema'
import { DiscountSettingUncheckedUpdateInputObjectSchema } from './objects/DiscountSettingUncheckedUpdateInput.schema'

export const DiscountSettingUpsertSchema = z.object({
  where: DiscountSettingWhereUniqueInputObjectSchema,
  create: z.union([
    DiscountSettingCreateInputObjectSchema,
    DiscountSettingUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    DiscountSettingUpdateInputObjectSchema,
    DiscountSettingUncheckedUpdateInputObjectSchema,
  ]),
})
