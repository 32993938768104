import { z } from 'zod'
import { ShippingProviderWhereInputObjectSchema } from './objects/ShippingProviderWhereInput.schema'
import { ShippingProviderOrderByWithAggregationInputObjectSchema } from './objects/ShippingProviderOrderByWithAggregationInput.schema'
import { ShippingProviderScalarWhereWithAggregatesInputObjectSchema } from './objects/ShippingProviderScalarWhereWithAggregatesInput.schema'
import { ShippingProviderScalarFieldEnumSchema } from './enums/ShippingProviderScalarFieldEnum.schema'

export const ShippingProviderGroupBySchema = z.object({
  where: ShippingProviderWhereInputObjectSchema.optional(),
  orderBy: z.union([
    ShippingProviderOrderByWithAggregationInputObjectSchema,
    ShippingProviderOrderByWithAggregationInputObjectSchema.array(),
  ]),
  having: ShippingProviderScalarWhereWithAggregatesInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  by: z.array(ShippingProviderScalarFieldEnumSchema),
})
