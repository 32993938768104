import { z } from 'zod'
import { CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema } from './objects/CaseSummaryDiagnosisOrderByWithRelationInput.schema'
import { CaseSummaryDiagnosisWhereInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereInput.schema'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisCountAggregateInputObjectSchema } from './objects/CaseSummaryDiagnosisCountAggregateInput.schema'
import { CaseSummaryDiagnosisMinAggregateInputObjectSchema } from './objects/CaseSummaryDiagnosisMinAggregateInput.schema'
import { CaseSummaryDiagnosisMaxAggregateInputObjectSchema } from './objects/CaseSummaryDiagnosisMaxAggregateInput.schema'
import { CaseSummaryDiagnosisAvgAggregateInputObjectSchema } from './objects/CaseSummaryDiagnosisAvgAggregateInput.schema'

export const CaseSummaryDiagnosisAggregateSchema = z.object({
  orderBy: z
    .union([
      CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema,
      CaseSummaryDiagnosisOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CaseSummaryDiagnosisWhereInputObjectSchema.optional(),
  cursor: CaseSummaryDiagnosisWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([
      z.literal(true),
      CaseSummaryDiagnosisCountAggregateInputObjectSchema,
    ])
    .optional(),
  _min: CaseSummaryDiagnosisMinAggregateInputObjectSchema.optional(),
  _max: CaseSummaryDiagnosisMaxAggregateInputObjectSchema.optional(),
  _avg: CaseSummaryDiagnosisAvgAggregateInputObjectSchema.optional(),
})
