import { z } from 'zod'
import { CaseCreateWithoutTeleconsultInputObjectSchema } from './CaseCreateWithoutTeleconsultInput.schema'
import { CaseUncheckedCreateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedCreateWithoutTeleconsultInput.schema'
import { CaseCreateOrConnectWithoutTeleconsultInputObjectSchema } from './CaseCreateOrConnectWithoutTeleconsultInput.schema'
import { CaseUpsertWithoutTeleconsultInputObjectSchema } from './CaseUpsertWithoutTeleconsultInput.schema'
import { CaseWhereUniqueInputObjectSchema } from './CaseWhereUniqueInput.schema'
import { CaseUpdateWithoutTeleconsultInputObjectSchema } from './CaseUpdateWithoutTeleconsultInput.schema'
import { CaseUncheckedUpdateWithoutTeleconsultInputObjectSchema } from './CaseUncheckedUpdateWithoutTeleconsultInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseUpdateOneWithoutTeleconsultNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => CaseCreateWithoutTeleconsultInputObjectSchema),
        z.lazy(() => CaseUncheckedCreateWithoutTeleconsultInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => CaseCreateOrConnectWithoutTeleconsultInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => CaseUpsertWithoutTeleconsultInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => CaseWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => CaseUpdateWithoutTeleconsultInputObjectSchema),
        z.lazy(() => CaseUncheckedUpdateWithoutTeleconsultInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CaseUpdateOneWithoutTeleconsultNestedInputObjectSchema = Schema
