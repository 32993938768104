import { z } from 'zod'
import { AcceptedConsentFindManySchema } from '../findManyAcceptedConsent.schema'
import { KycDataArgsObjectSchema } from './KycDataArgs.schema'
import { HealthInfoArgsObjectSchema } from './HealthInfoArgs.schema'
import { NotificationPatientFindManySchema } from '../findManyNotificationPatient.schema'
import { AddressFindManySchema } from '../findManyAddress.schema'
import { IdentityFindManySchema } from '../findManyIdentity.schema'
import { CoinTransactionFindManySchema } from '../findManyCoinTransaction.schema'
import { MyReviewFindManySchema } from '../findManyMyReview.schema'
import { PatientCountOutputTypeArgsObjectSchema } from './PatientCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientInclude> = z
  .object({
    acceptedConsents: z
      .union([z.boolean(), z.lazy(() => AcceptedConsentFindManySchema)])
      .optional(),
    kycData: z
      .union([z.boolean(), z.lazy(() => KycDataArgsObjectSchema)])
      .optional(),
    healthCheckUpInfo: z
      .union([z.boolean(), z.lazy(() => HealthInfoArgsObjectSchema)])
      .optional(),
    notifications: z
      .union([z.boolean(), z.lazy(() => NotificationPatientFindManySchema)])
      .optional(),
    address: z
      .union([z.boolean(), z.lazy(() => AddressFindManySchema)])
      .optional(),
    identities: z
      .union([z.boolean(), z.lazy(() => IdentityFindManySchema)])
      .optional(),
    coinTransactions: z
      .union([z.boolean(), z.lazy(() => CoinTransactionFindManySchema)])
      .optional(),
    myReviews: z
      .union([z.boolean(), z.lazy(() => MyReviewFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => PatientCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const PatientIncludeObjectSchema = Schema
