import { z } from 'zod'
import { OrderCreateWithoutCouponsInputObjectSchema } from './OrderCreateWithoutCouponsInput.schema'
import { OrderUncheckedCreateWithoutCouponsInputObjectSchema } from './OrderUncheckedCreateWithoutCouponsInput.schema'
import { OrderCreateOrConnectWithoutCouponsInputObjectSchema } from './OrderCreateOrConnectWithoutCouponsInput.schema'
import { OrderUpsertWithoutCouponsInputObjectSchema } from './OrderUpsertWithoutCouponsInput.schema'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutCouponsInputObjectSchema } from './OrderUpdateWithoutCouponsInput.schema'
import { OrderUncheckedUpdateWithoutCouponsInputObjectSchema } from './OrderUncheckedUpdateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateOneRequiredWithoutCouponsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => OrderCreateWithoutCouponsInputObjectSchema),
          z.lazy(() => OrderUncheckedCreateWithoutCouponsInputObjectSchema),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => OrderCreateOrConnectWithoutCouponsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => OrderUpsertWithoutCouponsInputObjectSchema)
        .optional(),
      connect: z.lazy(() => OrderWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => OrderUpdateWithoutCouponsInputObjectSchema),
          z.lazy(() => OrderUncheckedUpdateWithoutCouponsInputObjectSchema),
        ])
        .optional(),
    })
    .strict()

export const OrderUpdateOneRequiredWithoutCouponsNestedInputObjectSchema =
  Schema
