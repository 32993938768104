import { z } from 'zod'
import { SegmentScalarWhereInputObjectSchema } from './SegmentScalarWhereInput.schema'
import { SegmentUpdateManyMutationInputObjectSchema } from './SegmentUpdateManyMutationInput.schema'
import { SegmentUncheckedUpdateManyWithoutSegmentsInputObjectSchema } from './SegmentUncheckedUpdateManyWithoutSegmentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.SegmentUpdateManyWithWhereWithoutNotificationsInput> =
  z
    .object({
      where: z.lazy(() => SegmentScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => SegmentUpdateManyMutationInputObjectSchema),
        z.lazy(
          () => SegmentUncheckedUpdateManyWithoutSegmentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SegmentUpdateManyWithWhereWithoutNotificationsInputObjectSchema =
  Schema
