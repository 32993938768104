import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { GenderSchema } from '../enums/Gender.schema'
import { EnumGenderFieldUpdateOperationsInputObjectSchema } from './EnumGenderFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { PregnancyStateSchema } from '../enums/PregnancyState.schema'
import { NullableEnumPregnancyStateFieldUpdateOperationsInputObjectSchema } from './NullableEnumPregnancyStateFieldUpdateOperationsInput.schema'
import { OrderUpdateOneRequiredWithoutHealthInfoNestedInputObjectSchema } from './OrderUpdateOneRequiredWithoutHealthInfoNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.HealthInfoUpdateInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    name: z
      .union([
        z.string(),
        z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    gender: z
      .union([
        z.lazy(() => GenderSchema),
        z.lazy(() => EnumGenderFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    birthdate: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    drugAllergy: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    congenitalDisease: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    medication: z
      .union([
        z.string(),
        z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
      ])
      .optional()
      .nullable(),
    pregnancyState: z
      .union([
        z.lazy(() => PregnancyStateSchema),
        z.lazy(
          () =>
            NullableEnumPregnancyStateFieldUpdateOperationsInputObjectSchema,
        ),
      ])
      .optional()
      .nullable(),
    order: z
      .lazy(
        () => OrderUpdateOneRequiredWithoutHealthInfoNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const HealthInfoUpdateInputObjectSchema = Schema
