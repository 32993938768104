import { z } from 'zod'
import { NotificationWhereUniqueInputObjectSchema } from './NotificationWhereUniqueInput.schema'
import { NotificationUpdateWithoutSegmentsInputObjectSchema } from './NotificationUpdateWithoutSegmentsInput.schema'
import { NotificationUncheckedUpdateWithoutSegmentsInputObjectSchema } from './NotificationUncheckedUpdateWithoutSegmentsInput.schema'
import { NotificationCreateWithoutSegmentsInputObjectSchema } from './NotificationCreateWithoutSegmentsInput.schema'
import { NotificationUncheckedCreateWithoutSegmentsInputObjectSchema } from './NotificationUncheckedCreateWithoutSegmentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.NotificationUpsertWithWhereUniqueWithoutSegmentsInput> =
  z
    .object({
      where: z.lazy(() => NotificationWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => NotificationUpdateWithoutSegmentsInputObjectSchema),
        z.lazy(
          () => NotificationUncheckedUpdateWithoutSegmentsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => NotificationCreateWithoutSegmentsInputObjectSchema),
        z.lazy(
          () => NotificationUncheckedCreateWithoutSegmentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const NotificationUpsertWithWhereUniqueWithoutSegmentsInputObjectSchema =
  Schema
