import { z } from 'zod'

export const CaseScalarFieldEnumSchema = z.enum([
  'id',
  'patientId',
  'doctorId',
  'symptom',
  'drugAllergy',
  'congenitalDisease',
  'symptomDuration',
  'symptomDurationUnit',
  'followupCaseId',
  'status',
  'consultingChannel',
  'createdAt',
  'updatedAt',
  'v2RecordId',
])
