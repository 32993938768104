import { z } from 'zod'
import { KycDataArgsObjectSchema } from './KycDataArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.RejectReasonInclude> = z
  .object({
    kycData: z
      .union([z.boolean(), z.lazy(() => KycDataArgsObjectSchema)])
      .optional(),
  })
  .strict()

export const RejectReasonIncludeObjectSchema = Schema
