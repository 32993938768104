import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.PaymentMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    transactionId: z.literal(true).optional(),
    amount: z.literal(true).optional(),
    currency: z.literal(true).optional(),
    paymentAt: z.literal(true).optional(),
    paymentGateway: z.literal(true).optional(),
    paymentMethod: z.literal(true).optional(),
    installmentTerm: z.literal(true).optional(),
    status: z.literal(true).optional(),
    confirmedBy: z.literal(true).optional(),
    evidencePicture: z.literal(true).optional(),
    reminderNote: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
  })
  .strict()

export const PaymentMinAggregateInputObjectSchema = Schema
