import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderCreateWithoutCouponsInputObjectSchema } from './OrderCreateWithoutCouponsInput.schema'
import { OrderUncheckedCreateWithoutCouponsInputObjectSchema } from './OrderUncheckedCreateWithoutCouponsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderCreateOrConnectWithoutCouponsInput> = z
  .object({
    where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
    create: z.union([
      z.lazy(() => OrderCreateWithoutCouponsInputObjectSchema),
      z.lazy(() => OrderUncheckedCreateWithoutCouponsInputObjectSchema),
    ]),
  })
  .strict()

export const OrderCreateOrConnectWithoutCouponsInputObjectSchema = Schema
