import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptWhereUniqueInput> = z
  .object({
    receiptNumber: z.string().optional(),
    orderId: z.number().optional(),
  })
  .strict()

export const ReceiptWhereUniqueInputObjectSchema = Schema
