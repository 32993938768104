import { z } from 'zod'

export const UsageSchema = z.enum([
  'TAKE',
  'APPLY',
  'APPLY_ACNE',
  'APPLY_DARK_SPOT',
  'SPRAY',
  'SHAMPOO',
  'APPLY_AFFECTED_AREA_AND_LEAVE_FOR',
  'EYE_DROPS',
  'PUT_ON',
  'CLEANSE_FACE',
  'INSERT_VAGINA',
  'APPLY_30_MIN_BEFORE_SUN',
  'APPLY_PEA_SIZE',
  'SHOWER',
  'DISSOLVE_ROOM_TEMP_WATER',
  'APPLY_LEAVE_FOR_AND_RINSE',
  'SQUEEZE_IN_A_BRUSH',
  'CLEAN',
  'SLIGHTLY_DISSOLVED_WAIT_FOR_BUBBLE_THEN_DRINK',
  'PUT_ON_HAIR',
  'CLEAN_HIDDEN_SPOT',
  'BRUSH',
  'NA',
  'PASTE',
  'THROAT_SPRAY',
])
