import { GetApp as ExportIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { CouponModel } from '@ssch-backend/coupons'
import {
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceManyField,
  TextField,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { z } from 'zod'
import { CustomButton } from '../../../components/CustomButton'
import { FormDivider } from '../../../components/FormInput/divider'
import { LocaleDateField } from '../../../components/LocaleDateField'
import dataProvider from '../../../dataProvider'
import { downloadExcel } from '../../../utils'

const rowsPerPage = [5, 10, 20]

export const CouponListInGroup = () => {
  const translate = useTranslate()
  const { getValues } = useFormContext()
  const notify = useNotify()
  const exporter = () => {
    notify(`Exporting coupon...`, { type: 'info' })
    const { id } = getValues()
    dataProvider
      .getList('coupon', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { groupId: id },
      })
      .then((res) => {
        const coupons: z.infer<typeof CouponModel>[] = res.data
        const counponsForExport = coupons.map((coupon) => {
          return {
            id: coupon.id,
            createdAt: coupon.createdAt,
            code: coupon.code,
            type: coupon.type,
            nameEn: coupon.titleTr.en,
            nameTh: coupon.titleTr.th,
            discount: coupon.discount,
            discountType: coupon.discountType,
            isPublic: coupon.isPublic ? 'Yes' : 'No',
            isOnlyNewPatient: coupon.isOnlyNewPatient ? 'Yes' : 'No',
            startAt: coupon.startAt,
            expireAt: coupon.expireAt,
            orderId: coupon.orderId,
            patientId: coupon.patientId,
            usedAt: coupon.usedAt,
          }
        })
        const wb = XLSX.utils.book_new()
        const header = [
          'ID',
          'Created at',
          'Code',
          'Type',
          'Name (EN)',
          'Name (TH)',
          'Discount',
          'Discount type',
          'Public',
          'For new user',
          'Start at',
          'Expire at',
          'Order ID',
          'Patient ID',
          'Used At',
        ]
        XLSX.utils.sheet_add_aoa(wb, [header])
        XLSX.utils.sheet_add_json(wb, counponsForExport, {
          origin: 'A2',
          skipHeader: true,
        })
        const ws = { Sheets: { data: wb }, SheetNames: ['data'] }
        const blob = XLSX.write(ws, {
          bookType: 'xlsx',
          type: 'array',
          cellStyles: true,
        })
        downloadExcel(blob, 'coupon')
        notify(`Export coupon success`, { type: 'success' })
      })
      .catch((e) => {
        console.error(e)
        notify(`Can't export coupon`, { type: 'error' })
      })
  }

  return (
    <>
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="h5" sx={{ my: 2 }}>
          {translate('resources.couponGroup.title.coupon')}
        </Typography>
        <CustomButton
          icon={<ExportIcon />}
          onClick={() => exporter()}
          label="resources.coupon.action.export_coupon"
        />
      </Box>
      <FormDivider />
      <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
        <ReferenceManyField
          reference="coupon"
          target="groupId"
          perPage={rowsPerPage[2]}
          pagination={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Pagination rowsPerPageOptions={rowsPerPage} />
            </Box>
          }
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Box>
            <Datagrid size="small" bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="code" />
              <LocaleDateField source="usedAt" showTime />
              <TextField source="orderId" label="Order ID" emptyText="-" />
              <FunctionField
                label="Patient ID"
                render={(record: { patientId: number | null }) =>
                  record.patientId || '-'
                }
                onClick={(e: { stopPropagation: () => void }) =>
                  e.stopPropagation()
                }
              />
            </Datagrid>
          </Box>
        </ReferenceManyField>
      </Box>
    </>
  )
}
