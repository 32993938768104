import { z } from 'zod'
import { CaseSummaryDiagnosisWhereUniqueInputObjectSchema } from './objects/CaseSummaryDiagnosisWhereUniqueInput.schema'
import { CaseSummaryDiagnosisCreateInputObjectSchema } from './objects/CaseSummaryDiagnosisCreateInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateInputObjectSchema } from './objects/CaseSummaryDiagnosisUncheckedCreateInput.schema'
import { CaseSummaryDiagnosisUpdateInputObjectSchema } from './objects/CaseSummaryDiagnosisUpdateInput.schema'
import { CaseSummaryDiagnosisUncheckedUpdateInputObjectSchema } from './objects/CaseSummaryDiagnosisUncheckedUpdateInput.schema'

export const CaseSummaryDiagnosisUpsertSchema = z.object({
  where: CaseSummaryDiagnosisWhereUniqueInputObjectSchema,
  create: z.union([
    CaseSummaryDiagnosisCreateInputObjectSchema,
    CaseSummaryDiagnosisUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    CaseSummaryDiagnosisUpdateInputObjectSchema,
    CaseSummaryDiagnosisUncheckedUpdateInputObjectSchema,
  ]),
})
