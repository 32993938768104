import { z } from 'zod'

export const OtpScalarFieldEnumSchema = z.enum([
  'id',
  'ipAddress',
  'phone',
  'reference',
  'createdAt',
  'expiredAt',
  'verifiedAt',
  'otp',
])
