import { ComponentType } from 'react'
import { usePermissions } from 'react-admin'
import { ForbiddenPage } from '../app/_handlingPage/forbidden'

export function withPermissionRoutePage(
  Component: ComponentType,
  authorizedPermission: string[],
) {
  const PermissionRoutePage = (props: object) => {
    const { isLoading, permissions } = usePermissions()

    if (isLoading) {
      return null
    }

    if (
      permissions !== 'SUPER_ADMIN' &&
      !authorizedPermission.includes(permissions)
    ) {
      return <ForbiddenPage />
    }

    return <Component {...props} />
  }

  return PermissionRoutePage
}
