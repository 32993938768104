import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { JsonFilterObjectSchema } from './JsonFilter.schema'
import { StringFilterObjectSchema } from './StringFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { FloatNullableFilterObjectSchema } from './FloatNullableFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { OfficeHourListRelationFilterObjectSchema } from './OfficeHourListRelationFilter.schema'
import { PartnerRelationFilterObjectSchema } from './PartnerRelationFilter.schema'
import { PartnerWhereInputObjectSchema } from './PartnerWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.BranchWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => BranchWhereInputObjectSchema),
        z.lazy(() => BranchWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => BranchWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => BranchWhereInputObjectSchema),
        z.lazy(() => BranchWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    nameTr: z.lazy(() => JsonFilterObjectSchema).optional(),
    address: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    subDistrict: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    subDistrictKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    district: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    districtKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    province: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    provinceKey: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    postcode: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    googleMapUrl: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    lat: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    long: z
      .union([z.lazy(() => FloatNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    phone: z
      .union([z.lazy(() => StringFilterObjectSchema), z.string()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    isExpertise: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    remark: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    partnerId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    v2BranchId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    officeHours: z
      .lazy(() => OfficeHourListRelationFilterObjectSchema)
      .optional(),
    partner: z
      .union([
        z.lazy(() => PartnerRelationFilterObjectSchema),
        z.lazy(() => PartnerWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const BranchWhereInputObjectSchema = Schema
