import { z } from 'zod'
import { CaseSummaryDiagnosisCreateManyDiagnosisInputObjectSchema } from './CaseSummaryDiagnosisCreateManyDiagnosisInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelope> =
  z
    .object({
      data: z.union([
        z.lazy(() => CaseSummaryDiagnosisCreateManyDiagnosisInputObjectSchema),
        z
          .lazy(() => CaseSummaryDiagnosisCreateManyDiagnosisInputObjectSchema)
          .array(),
      ]),
      skipDuplicates: z.boolean().optional(),
    })
    .strict()

export const CaseSummaryDiagnosisCreateManyDiagnosisInputEnvelopeObjectSchema =
  Schema
