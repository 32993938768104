import { z } from 'zod'
import { ConsentSelectObjectSchema } from './ConsentSelect.schema'
import { ConsentIncludeObjectSchema } from './ConsentInclude.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentArgs> = z
  .object({
    select: z.lazy(() => ConsentSelectObjectSchema).optional(),
    include: z.lazy(() => ConsentIncludeObjectSchema).optional(),
  })
  .strict()

export const ConsentArgsObjectSchema = Schema
