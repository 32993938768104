import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCountOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    prefix: z.lazy(() => SortOrderSchema).optional(),
    firstname: z.lazy(() => SortOrderSchema).optional(),
    lastname: z.lazy(() => SortOrderSchema).optional(),
    nickname: z.lazy(() => SortOrderSchema).optional(),
    gender: z.lazy(() => SortOrderSchema).optional(),
    birthDate: z.lazy(() => SortOrderSchema).optional(),
    picture: z.lazy(() => SortOrderSchema).optional(),
    isTest: z.lazy(() => SortOrderSchema).optional(),
    active: z.lazy(() => SortOrderSchema).optional(),
    registered: z.lazy(() => SortOrderSchema).optional(),
    drugAllergy: z.lazy(() => SortOrderSchema).optional(),
    congenitalDisease: z.lazy(() => SortOrderSchema).optional(),
    receiveNotifications: z.lazy(() => SortOrderSchema).optional(),
    activityNotifications: z.lazy(() => SortOrderSchema).optional(),
    chatNotifications: z.lazy(() => SortOrderSchema).optional(),
    orderNotifications: z.lazy(() => SortOrderSchema).optional(),
    omiseCustomerId: z.lazy(() => SortOrderSchema).optional(),
    freshchatUserId: z.lazy(() => SortOrderSchema).optional(),
    freshchatRestoreId: z.lazy(() => SortOrderSchema).optional(),
    coin: z.lazy(() => SortOrderSchema).optional(),
    v2PatientId: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const PatientCountOrderByAggregateInputObjectSchema = Schema
