import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ProductItemMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    sku: z.literal(true).optional(),
    variantId: z.literal(true).optional(),
    picture: z.literal(true).optional(),
    drugType: z.literal(true).optional(),
    quantity: z.literal(true).optional(),
    tagPrice: z.literal(true).optional(),
    sellingPrice: z.literal(true).optional(),
    sellingUnit: z.literal(true).optional(),
    cost: z.literal(true).optional(),
    flashSaleId: z.literal(true).optional(),
    orderId: z.literal(true).optional(),
    deliveryId: z.literal(true).optional(),
    refundId: z.literal(true).optional(),
    v2DrugItemId: z.literal(true).optional(),
    canExpress: z.literal(true).optional(),
    reviewId: z.literal(true).optional(),
    reviewStartAt: z.literal(true).optional(),
    reviewExpireAt: z.literal(true).optional(),
  })
  .strict()

export const ProductItemMinAggregateInputObjectSchema = Schema
