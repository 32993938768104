import { z } from 'zod'
import { CoverPictureCreateWithoutPartnerInputObjectSchema } from './CoverPictureCreateWithoutPartnerInput.schema'
import { CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema } from './CoverPictureUncheckedCreateWithoutPartnerInput.schema'
import { CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema } from './CoverPictureCreateOrConnectWithoutPartnerInput.schema'
import { CoverPictureCreateManyPartnerInputEnvelopeObjectSchema } from './CoverPictureCreateManyPartnerInputEnvelope.schema'
import { CoverPictureWhereUniqueInputObjectSchema } from './CoverPictureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureCreateNestedManyWithoutPartnerInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => CoverPictureCreateWithoutPartnerInputObjectSchema),
          z
            .lazy(() => CoverPictureCreateWithoutPartnerInputObjectSchema)
            .array(),
          z.lazy(
            () => CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => CoverPictureUncheckedCreateWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () => CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema,
          ),
          z
            .lazy(
              () => CoverPictureCreateOrConnectWithoutPartnerInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => CoverPictureCreateManyPartnerInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema),
          z.lazy(() => CoverPictureWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const CoverPictureCreateNestedManyWithoutPartnerInputObjectSchema =
  Schema
