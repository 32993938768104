import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderNotificationSelect> = z
  .object({
    id: z.boolean().optional(),
    orderId: z.boolean().optional(),
    onesignalMessageId: z.boolean().optional(),
    orderItemType: z.boolean().optional(),
    title: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    sendAt: z.boolean().optional(),
    procedureId: z.boolean().optional(),
  })
  .strict()

export const OrderNotificationSelectObjectSchema = Schema
