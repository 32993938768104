import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCountOutputTypeSelect> = z
  .object({
    categories: z.boolean().optional(),
    variants: z.boolean().optional(),
    pictures: z.boolean().optional(),
    video: z.boolean().optional(),
    productReviews: z.boolean().optional(),
  })
  .strict()

export const ProductCountOutputTypeSelectObjectSchema = Schema
