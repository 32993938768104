import { z } from 'zod'
import { ProductCreateNestedOneWithoutCategoriesInputObjectSchema } from './ProductCreateNestedOneWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductCategoryCreateWithoutCategoryInput> = z
  .object({
    priority: z.number().optional(),
    product: z.lazy(
      () => ProductCreateNestedOneWithoutCategoriesInputObjectSchema,
    ),
  })
  .strict()

export const ProductCategoryCreateWithoutCategoryInputObjectSchema = Schema
