import { z } from 'zod'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryCreateWithoutProductInput.schema'
import { ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema } from './ProductReviewSummaryUncheckedCreateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummaryCreateOrConnectWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductReviewSummaryWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => ProductReviewSummaryCreateWithoutProductInputObjectSchema),
        z.lazy(
          () =>
            ProductReviewSummaryUncheckedCreateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductReviewSummaryCreateOrConnectWithoutProductInputObjectSchema =
  Schema
