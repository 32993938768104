import { z } from 'zod'
import { UnitSchema } from '../enums/Unit.schema'
import { NestedEnumUnitWithAggregatesFilterObjectSchema } from './NestedEnumUnitWithAggregatesFilter.schema'
import { NestedIntFilterObjectSchema } from './NestedIntFilter.schema'
import { NestedEnumUnitFilterObjectSchema } from './NestedEnumUnitFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.EnumUnitWithAggregatesFilter> = z
  .object({
    equals: z.lazy(() => UnitSchema).optional(),
    in: z
      .lazy(() => UnitSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => UnitSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => UnitSchema),
        z.lazy(() => NestedEnumUnitWithAggregatesFilterObjectSchema),
      ])
      .optional(),
    _count: z.lazy(() => NestedIntFilterObjectSchema).optional(),
    _min: z.lazy(() => NestedEnumUnitFilterObjectSchema).optional(),
    _max: z.lazy(() => NestedEnumUnitFilterObjectSchema).optional(),
  })
  .strict()

export const EnumUnitWithAggregatesFilterObjectSchema = Schema
