import { z } from 'zod'
import { IdentityCreateWithoutPatientInputObjectSchema } from './IdentityCreateWithoutPatientInput.schema'
import { IdentityUncheckedCreateWithoutPatientInputObjectSchema } from './IdentityUncheckedCreateWithoutPatientInput.schema'
import { IdentityCreateOrConnectWithoutPatientInputObjectSchema } from './IdentityCreateOrConnectWithoutPatientInput.schema'
import { IdentityCreateManyPatientInputEnvelopeObjectSchema } from './IdentityCreateManyPatientInputEnvelope.schema'
import { IdentityWhereUniqueInputObjectSchema } from './IdentityWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.IdentityUncheckedCreateNestedManyWithoutPatientInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema),
          z.lazy(() => IdentityCreateWithoutPatientInputObjectSchema).array(),
          z.lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema),
          z
            .lazy(() => IdentityUncheckedCreateWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(() => IdentityCreateOrConnectWithoutPatientInputObjectSchema),
          z
            .lazy(() => IdentityCreateOrConnectWithoutPatientInputObjectSchema)
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(() => IdentityCreateManyPatientInputEnvelopeObjectSchema)
        .optional(),
      connect: z
        .union([
          z.lazy(() => IdentityWhereUniqueInputObjectSchema),
          z.lazy(() => IdentityWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const IdentityUncheckedCreateNestedManyWithoutPatientInputObjectSchema =
  Schema
