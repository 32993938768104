import { z } from 'zod'
import { ProcedureCreateInputObjectSchema } from './objects/ProcedureCreateInput.schema'
import { ProcedureUncheckedCreateInputObjectSchema } from './objects/ProcedureUncheckedCreateInput.schema'

export const ProcedureCreateOneSchema = z.object({
  data: z.union([
    ProcedureCreateInputObjectSchema,
    ProcedureUncheckedCreateInputObjectSchema,
  ]),
})
