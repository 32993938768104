import { Box, Card, CardContent, Typography } from '@mui/material'
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useEditContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor'

const DoctorConsentEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="doctorConsent" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const DoctorConsentEditForm = () => {
  const { setValue } = useFormContext()
  const { record, isLoading } = useEditContext()

  if (isLoading) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
        Consent Form: {record?.key ?? ''}
      </Typography>
      {record?.detail ? (
        <RichTextEditor
          detailValue={record.detail}
          saveContent={(value: string) => {
            setValue('detail', value)
          }}
        />
      ) : (
        <div>Loading...</div>
      )}
    </Box>
  )
}

export const DoctorConsentEdit = () => {
  return (
    <Edit>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <SimpleForm toolbar={<DoctorConsentEditToolbar />}>
            <DoctorConsentEditForm />
          </SimpleForm>
        </CardContent>
      </Card>
    </Edit>
  )
}
