import { z } from 'zod'
import { CaseSummaryDiagnosisCreateInputObjectSchema } from './objects/CaseSummaryDiagnosisCreateInput.schema'
import { CaseSummaryDiagnosisUncheckedCreateInputObjectSchema } from './objects/CaseSummaryDiagnosisUncheckedCreateInput.schema'

export const CaseSummaryDiagnosisCreateOneSchema = z.object({
  data: z.union([
    CaseSummaryDiagnosisCreateInputObjectSchema,
    CaseSummaryDiagnosisUncheckedCreateInputObjectSchema,
  ]),
})
