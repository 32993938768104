import { z } from 'zod'
import { ReceiptCreateWithoutOrderInputObjectSchema } from './ReceiptCreateWithoutOrderInput.schema'
import { ReceiptUncheckedCreateWithoutOrderInputObjectSchema } from './ReceiptUncheckedCreateWithoutOrderInput.schema'
import { ReceiptCreateOrConnectWithoutOrderInputObjectSchema } from './ReceiptCreateOrConnectWithoutOrderInput.schema'
import { ReceiptWhereUniqueInputObjectSchema } from './ReceiptWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ReceiptCreateNestedOneWithoutOrderInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ReceiptCreateWithoutOrderInputObjectSchema),
        z.lazy(() => ReceiptUncheckedCreateWithoutOrderInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ReceiptCreateOrConnectWithoutOrderInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ReceiptWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ReceiptCreateNestedOneWithoutOrderInputObjectSchema = Schema
