import { z } from 'zod'
import { SegmentOrderByWithRelationInputObjectSchema } from './objects/SegmentOrderByWithRelationInput.schema'
import { SegmentWhereInputObjectSchema } from './objects/SegmentWhereInput.schema'
import { SegmentWhereUniqueInputObjectSchema } from './objects/SegmentWhereUniqueInput.schema'
import { SegmentScalarFieldEnumSchema } from './enums/SegmentScalarFieldEnum.schema'

export const SegmentFindManySchema = z.object({
  orderBy: z
    .union([
      SegmentOrderByWithRelationInputObjectSchema,
      SegmentOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: SegmentWhereInputObjectSchema.optional(),
  cursor: SegmentWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(SegmentScalarFieldEnumSchema).optional(),
})
