import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCountAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    symptom: z.literal(true).optional(),
    drugAllergy: z.literal(true).optional(),
    congenitalDisease: z.literal(true).optional(),
    symptomDuration: z.literal(true).optional(),
    symptomDurationUnit: z.literal(true).optional(),
    followupCaseId: z.literal(true).optional(),
    status: z.literal(true).optional(),
    consultingChannel: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    v2RecordId: z.literal(true).optional(),
    _all: z.literal(true).optional(),
  })
  .strict()

export const CaseCountAggregateInputObjectSchema = Schema
