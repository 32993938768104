import { z } from 'zod'
import { ProductReviewPictureUpdateInputObjectSchema } from './objects/ProductReviewPictureUpdateInput.schema'
import { ProductReviewPictureUncheckedUpdateInputObjectSchema } from './objects/ProductReviewPictureUncheckedUpdateInput.schema'
import { ProductReviewPictureWhereUniqueInputObjectSchema } from './objects/ProductReviewPictureWhereUniqueInput.schema'

export const ProductReviewPictureUpdateOneSchema = z.object({
  data: z.union([
    ProductReviewPictureUpdateInputObjectSchema,
    ProductReviewPictureUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductReviewPictureWhereUniqueInputObjectSchema,
})
