import { z } from 'zod'
import { ShippingProviderCreateWithoutAreasInputObjectSchema } from './ShippingProviderCreateWithoutAreasInput.schema'
import { ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema } from './ShippingProviderUncheckedCreateWithoutAreasInput.schema'
import { ShippingProviderCreateOrConnectWithoutAreasInputObjectSchema } from './ShippingProviderCreateOrConnectWithoutAreasInput.schema'
import { ShippingProviderWhereUniqueInputObjectSchema } from './ShippingProviderWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ShippingProviderCreateNestedOneWithoutAreasInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ShippingProviderCreateWithoutAreasInputObjectSchema),
          z.lazy(
            () => ShippingProviderUncheckedCreateWithoutAreasInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(
          () => ShippingProviderCreateOrConnectWithoutAreasInputObjectSchema,
        )
        .optional(),
      connect: z
        .lazy(() => ShippingProviderWhereUniqueInputObjectSchema)
        .optional(),
    })
    .strict()

export const ShippingProviderCreateNestedOneWithoutAreasInputObjectSchema =
  Schema
