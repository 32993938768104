import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseCountOutputTypeSelect> = z
  .object({
    pictures: z.boolean().optional(),
    missedCallTeleconsult: z.boolean().optional(),
  })
  .strict()

export const CaseCountOutputTypeSelectObjectSchema = Schema
