import { z } from 'zod'
import { ProcedureCreateWithoutPicturesInputObjectSchema } from './ProcedureCreateWithoutPicturesInput.schema'
import { ProcedureUncheckedCreateWithoutPicturesInputObjectSchema } from './ProcedureUncheckedCreateWithoutPicturesInput.schema'
import { ProcedureCreateOrConnectWithoutPicturesInputObjectSchema } from './ProcedureCreateOrConnectWithoutPicturesInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureCreateNestedOneWithoutPicturesInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => ProcedureCreateWithoutPicturesInputObjectSchema),
        z.lazy(() => ProcedureUncheckedCreateWithoutPicturesInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => ProcedureCreateOrConnectWithoutPicturesInputObjectSchema)
      .optional(),
    connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const ProcedureCreateNestedOneWithoutPicturesInputObjectSchema = Schema
