import { z } from 'zod'
import { SuggestedProcedureItemScalarWhereInputObjectSchema } from './SuggestedProcedureItemScalarWhereInput.schema'
import { SuggestedProcedureItemUpdateManyMutationInputObjectSchema } from './SuggestedProcedureItemUpdateManyMutationInput.schema'
import { SuggestedProcedureItemUncheckedUpdateManyWithoutSuggestedProcedureItemsInputObjectSchema } from './SuggestedProcedureItemUncheckedUpdateManyWithoutSuggestedProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInput> =
  z
    .object({
      where: z.lazy(() => SuggestedProcedureItemScalarWhereInputObjectSchema),
      data: z.union([
        z.lazy(() => SuggestedProcedureItemUpdateManyMutationInputObjectSchema),
        z.lazy(
          () =>
            SuggestedProcedureItemUncheckedUpdateManyWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const SuggestedProcedureItemUpdateManyWithWhereWithoutCaseSummaryInputObjectSchema =
  Schema
