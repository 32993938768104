import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { BoolFilterObjectSchema } from './BoolFilter.schema'
import { CarouselRelationFilterObjectSchema } from './CarouselRelationFilter.schema'
import { CarouselWhereInputObjectSchema } from './CarouselWhereInput.schema'
import { ProcedureRelationFilterObjectSchema } from './ProcedureRelationFilter.schema'
import { ProcedureWhereInputObjectSchema } from './ProcedureWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.CarouselItemWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => CarouselItemWhereInputObjectSchema),
        z.lazy(() => CarouselItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => CarouselItemWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => CarouselItemWhereInputObjectSchema),
        z.lazy(() => CarouselItemWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    active: z
      .union([z.lazy(() => BoolFilterObjectSchema), z.boolean()])
      .optional(),
    priority: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    carouselId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    procedureId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    carousel: z
      .union([
        z.lazy(() => CarouselRelationFilterObjectSchema),
        z.lazy(() => CarouselWhereInputObjectSchema),
      ])
      .optional(),
    procedure: z
      .union([
        z.lazy(() => ProcedureRelationFilterObjectSchema),
        z.lazy(() => ProcedureWhereInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CarouselItemWhereInputObjectSchema = Schema
