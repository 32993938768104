import { z } from 'zod'
import { VariantPictureWhereUniqueInputObjectSchema } from './VariantPictureWhereUniqueInput.schema'
import { VariantPictureUpdateWithoutVariantInputObjectSchema } from './VariantPictureUpdateWithoutVariantInput.schema'
import { VariantPictureUncheckedUpdateWithoutVariantInputObjectSchema } from './VariantPictureUncheckedUpdateWithoutVariantInput.schema'
import { VariantPictureCreateWithoutVariantInputObjectSchema } from './VariantPictureCreateWithoutVariantInput.schema'
import { VariantPictureUncheckedCreateWithoutVariantInputObjectSchema } from './VariantPictureUncheckedCreateWithoutVariantInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantPictureUpsertWithWhereUniqueWithoutVariantInput> =
  z
    .object({
      where: z.lazy(() => VariantPictureWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => VariantPictureUpdateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantPictureUncheckedUpdateWithoutVariantInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(() => VariantPictureCreateWithoutVariantInputObjectSchema),
        z.lazy(
          () => VariantPictureUncheckedCreateWithoutVariantInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const VariantPictureUpsertWithWhereUniqueWithoutVariantInputObjectSchema =
  Schema
