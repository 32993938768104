import { z } from 'zod'
import { WarehouseWhereUniqueInputObjectSchema } from './objects/WarehouseWhereUniqueInput.schema'
import { WarehouseCreateInputObjectSchema } from './objects/WarehouseCreateInput.schema'
import { WarehouseUncheckedCreateInputObjectSchema } from './objects/WarehouseUncheckedCreateInput.schema'
import { WarehouseUpdateInputObjectSchema } from './objects/WarehouseUpdateInput.schema'
import { WarehouseUncheckedUpdateInputObjectSchema } from './objects/WarehouseUncheckedUpdateInput.schema'

export const WarehouseUpsertSchema = z.object({
  where: WarehouseWhereUniqueInputObjectSchema,
  create: z.union([
    WarehouseCreateInputObjectSchema,
    WarehouseUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    WarehouseUpdateInputObjectSchema,
    WarehouseUncheckedUpdateInputObjectSchema,
  ]),
})
