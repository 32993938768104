import { z } from 'zod'
import { KycDataOrderByWithRelationInputObjectSchema } from './objects/KycDataOrderByWithRelationInput.schema'
import { KycDataWhereInputObjectSchema } from './objects/KycDataWhereInput.schema'
import { KycDataWhereUniqueInputObjectSchema } from './objects/KycDataWhereUniqueInput.schema'
import { KycDataScalarFieldEnumSchema } from './enums/KycDataScalarFieldEnum.schema'

export const KycDataFindManySchema = z.object({
  orderBy: z
    .union([
      KycDataOrderByWithRelationInputObjectSchema,
      KycDataOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: KycDataWhereInputObjectSchema.optional(),
  cursor: KycDataWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  distinct: z.array(KycDataScalarFieldEnumSchema).optional(),
})
