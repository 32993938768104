import { z } from 'zod'
import { DeliveryCreateWithoutProductItemsInputObjectSchema } from './DeliveryCreateWithoutProductItemsInput.schema'
import { DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedCreateWithoutProductItemsInput.schema'
import { DeliveryCreateOrConnectWithoutProductItemsInputObjectSchema } from './DeliveryCreateOrConnectWithoutProductItemsInput.schema'
import { DeliveryUpsertWithoutProductItemsInputObjectSchema } from './DeliveryUpsertWithoutProductItemsInput.schema'
import { DeliveryWhereUniqueInputObjectSchema } from './DeliveryWhereUniqueInput.schema'
import { DeliveryUpdateWithoutProductItemsInputObjectSchema } from './DeliveryUpdateWithoutProductItemsInput.schema'
import { DeliveryUncheckedUpdateWithoutProductItemsInputObjectSchema } from './DeliveryUncheckedUpdateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryUpdateOneWithoutProductItemsNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => DeliveryCreateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedCreateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => DeliveryCreateOrConnectWithoutProductItemsInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => DeliveryUpsertWithoutProductItemsInputObjectSchema)
        .optional(),
      disconnect: z.boolean().optional(),
      delete: z.boolean().optional(),
      connect: z.lazy(() => DeliveryWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => DeliveryUpdateWithoutProductItemsInputObjectSchema),
          z.lazy(
            () => DeliveryUncheckedUpdateWithoutProductItemsInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const DeliveryUpdateOneWithoutProductItemsNestedInputObjectSchema =
  Schema
