import { z } from 'zod'
import { IntFilterObjectSchema } from './IntFilter.schema'
import { StringNullableFilterObjectSchema } from './StringNullableFilter.schema'
import { IntNullableFilterObjectSchema } from './IntNullableFilter.schema'
import { DateTimeNullableFilterObjectSchema } from './DateTimeNullableFilter.schema'
import { EnumConsultingStatusFilterObjectSchema } from './EnumConsultingStatusFilter.schema'
import { ConsultingStatusSchema } from '../enums/ConsultingStatus.schema'
import { DateTimeFilterObjectSchema } from './DateTimeFilter.schema'
import { CaseRelationFilterObjectSchema } from './CaseRelationFilter.schema'
import { CaseWhereInputObjectSchema } from './CaseWhereInput.schema'
import { AppointmentRelationFilterObjectSchema } from './AppointmentRelationFilter.schema'
import { AppointmentWhereInputObjectSchema } from './AppointmentWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultWhereInput> = z
  .object({
    AND: z
      .union([
        z.lazy(() => TeleconsultWhereInputObjectSchema),
        z.lazy(() => TeleconsultWhereInputObjectSchema).array(),
      ])
      .optional(),
    OR: z
      .lazy(() => TeleconsultWhereInputObjectSchema)
      .array()
      .optional(),
    NOT: z
      .union([
        z.lazy(() => TeleconsultWhereInputObjectSchema),
        z.lazy(() => TeleconsultWhereInputObjectSchema).array(),
      ])
      .optional(),
    id: z.union([z.lazy(() => IntFilterObjectSchema), z.number()]).optional(),
    doctorId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    patientId: z
      .union([z.lazy(() => IntFilterObjectSchema), z.number()])
      .optional(),
    videoRoomId: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    doctorVideoRoomUid: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    patientVideoRoomUid: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    startAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    endAt: z
      .union([z.lazy(() => DateTimeNullableFilterObjectSchema), z.date()])
      .optional()
      .nullable(),
    callTime: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    status: z
      .union([
        z.lazy(() => EnumConsultingStatusFilterObjectSchema),
        z.lazy(() => ConsultingStatusSchema),
      ])
      .optional(),
    caseId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    missedCallCaseId: z
      .union([z.lazy(() => IntNullableFilterObjectSchema), z.number()])
      .optional()
      .nullable(),
    remark: z
      .union([z.lazy(() => StringNullableFilterObjectSchema), z.string()])
      .optional()
      .nullable(),
    createdAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    updatedAt: z
      .union([z.lazy(() => DateTimeFilterObjectSchema), z.date()])
      .optional(),
    case: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    missedCallCase: z
      .union([
        z.lazy(() => CaseRelationFilterObjectSchema),
        z.lazy(() => CaseWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
    appointment: z
      .union([
        z.lazy(() => AppointmentRelationFilterObjectSchema),
        z.lazy(() => AppointmentWhereInputObjectSchema),
      ])
      .optional()
      .nullable(),
  })
  .strict()

export const TeleconsultWhereInputObjectSchema = Schema
