import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { RefundCountOrderByAggregateInputObjectSchema } from './RefundCountOrderByAggregateInput.schema'
import { RefundAvgOrderByAggregateInputObjectSchema } from './RefundAvgOrderByAggregateInput.schema'
import { RefundMaxOrderByAggregateInputObjectSchema } from './RefundMaxOrderByAggregateInput.schema'
import { RefundMinOrderByAggregateInputObjectSchema } from './RefundMinOrderByAggregateInput.schema'
import { RefundSumOrderByAggregateInputObjectSchema } from './RefundSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    amount: z.lazy(() => SortOrderSchema).optional(),
    currency: z.lazy(() => SortOrderSchema).optional(),
    refundAt: z.lazy(() => SortOrderSchema).optional(),
    paymentGateway: z.lazy(() => SortOrderSchema).optional(),
    status: z.lazy(() => SortOrderSchema).optional(),
    reason: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => RefundCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z.lazy(() => RefundAvgOrderByAggregateInputObjectSchema).optional(),
    _max: z.lazy(() => RefundMaxOrderByAggregateInputObjectSchema).optional(),
    _min: z.lazy(() => RefundMinOrderByAggregateInputObjectSchema).optional(),
    _sum: z.lazy(() => RefundSumOrderByAggregateInputObjectSchema).optional(),
  })
  .strict()

export const RefundOrderByWithAggregationInputObjectSchema = Schema
