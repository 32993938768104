import { z } from 'zod'
import { CaseSummaryUpdateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUpdateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProductItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutSuggestedProductItemsInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () => CaseSummaryUpdateWithoutSuggestedProductItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedUpdateWithoutSuggestedProductItemsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () => CaseSummaryCreateWithoutSuggestedProductItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProductItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryUpsertWithoutSuggestedProductItemsInputObjectSchema =
  Schema
