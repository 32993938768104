import { z } from 'zod'
import { CaseArgsObjectSchema } from './CaseArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CasePictureSelect> = z
  .object({
    id: z.boolean().optional(),
    pictureUrl: z.boolean().optional(),
    case: z.union([z.boolean(), z.lazy(() => CaseArgsObjectSchema)]).optional(),
    caseId: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
  })
  .strict()

export const CasePictureSelectObjectSchema = Schema
