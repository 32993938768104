import { z } from 'zod'
import { ProductItemCreateInputObjectSchema } from './objects/ProductItemCreateInput.schema'
import { ProductItemUncheckedCreateInputObjectSchema } from './objects/ProductItemUncheckedCreateInput.schema'

export const ProductItemCreateOneSchema = z.object({
  data: z.union([
    ProductItemCreateInputObjectSchema,
    ProductItemUncheckedCreateInputObjectSchema,
  ]),
})
