import { z } from 'zod'
import { CarouselItemUpdateInputObjectSchema } from './objects/CarouselItemUpdateInput.schema'
import { CarouselItemUncheckedUpdateInputObjectSchema } from './objects/CarouselItemUncheckedUpdateInput.schema'
import { CarouselItemWhereUniqueInputObjectSchema } from './objects/CarouselItemWhereUniqueInput.schema'

export const CarouselItemUpdateOneSchema = z.object({
  data: z.union([
    CarouselItemUpdateInputObjectSchema,
    CarouselItemUncheckedUpdateInputObjectSchema,
  ]),
  where: CarouselItemWhereUniqueInputObjectSchema,
})
