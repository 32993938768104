import { z } from 'zod'
import { ProcedureReviewWhereUniqueInputObjectSchema } from './ProcedureReviewWhereUniqueInput.schema'
import { ProcedureReviewUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewUpdateWithoutProcedureInput.schema'
import { ProcedureReviewUncheckedUpdateWithoutProcedureInputObjectSchema } from './ProcedureReviewUncheckedUpdateWithoutProcedureInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInput> =
  z
    .object({
      where: z.lazy(() => ProcedureReviewWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProcedureReviewUpdateWithoutProcedureInputObjectSchema),
        z.lazy(
          () => ProcedureReviewUncheckedUpdateWithoutProcedureInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProcedureReviewUpdateWithWhereUniqueWithoutProcedureInputObjectSchema =
  Schema
