import { z } from 'zod'
import { CoverPictureUpdateInputObjectSchema } from './objects/CoverPictureUpdateInput.schema'
import { CoverPictureUncheckedUpdateInputObjectSchema } from './objects/CoverPictureUncheckedUpdateInput.schema'
import { CoverPictureWhereUniqueInputObjectSchema } from './objects/CoverPictureWhereUniqueInput.schema'

export const CoverPictureUpdateOneSchema = z.object({
  data: z.union([
    CoverPictureUpdateInputObjectSchema,
    CoverPictureUncheckedUpdateInputObjectSchema,
  ]),
  where: CoverPictureWhereUniqueInputObjectSchema,
})
