import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { ConditionSettingCountOrderByAggregateInputObjectSchema } from './ConditionSettingCountOrderByAggregateInput.schema'
import { ConditionSettingAvgOrderByAggregateInputObjectSchema } from './ConditionSettingAvgOrderByAggregateInput.schema'
import { ConditionSettingMaxOrderByAggregateInputObjectSchema } from './ConditionSettingMaxOrderByAggregateInput.schema'
import { ConditionSettingMinOrderByAggregateInputObjectSchema } from './ConditionSettingMinOrderByAggregateInput.schema'
import { ConditionSettingSumOrderByAggregateInputObjectSchema } from './ConditionSettingSumOrderByAggregateInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/coupons'

const Schema: z.ZodType<Prisma.ConditionSettingOrderByWithAggregationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    scope: z.lazy(() => SortOrderSchema).optional(),
    itemType: z.lazy(() => SortOrderSchema).optional(),
    items: z.lazy(() => SortOrderSchema).optional(),
    settingGroupId: z.lazy(() => SortOrderSchema).optional(),
    groupId: z.lazy(() => SortOrderSchema).optional(),
    _count: z
      .lazy(() => ConditionSettingCountOrderByAggregateInputObjectSchema)
      .optional(),
    _avg: z
      .lazy(() => ConditionSettingAvgOrderByAggregateInputObjectSchema)
      .optional(),
    _max: z
      .lazy(() => ConditionSettingMaxOrderByAggregateInputObjectSchema)
      .optional(),
    _min: z
      .lazy(() => ConditionSettingMinOrderByAggregateInputObjectSchema)
      .optional(),
    _sum: z
      .lazy(() => ConditionSettingSumOrderByAggregateInputObjectSchema)
      .optional(),
  })
  .strict()

export const ConditionSettingOrderByWithAggregationInputObjectSchema = Schema
