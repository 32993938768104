import { z } from 'zod'
import { RefundUpdateWithoutProductItemsInputObjectSchema } from './RefundUpdateWithoutProductItemsInput.schema'
import { RefundUncheckedUpdateWithoutProductItemsInputObjectSchema } from './RefundUncheckedUpdateWithoutProductItemsInput.schema'
import { RefundCreateWithoutProductItemsInputObjectSchema } from './RefundCreateWithoutProductItemsInput.schema'
import { RefundUncheckedCreateWithoutProductItemsInputObjectSchema } from './RefundUncheckedCreateWithoutProductItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUpsertWithoutProductItemsInput> = z
  .object({
    update: z.union([
      z.lazy(() => RefundUpdateWithoutProductItemsInputObjectSchema),
      z.lazy(() => RefundUncheckedUpdateWithoutProductItemsInputObjectSchema),
    ]),
    create: z.union([
      z.lazy(() => RefundCreateWithoutProductItemsInputObjectSchema),
      z.lazy(() => RefundUncheckedCreateWithoutProductItemsInputObjectSchema),
    ]),
  })
  .strict()

export const RefundUpsertWithoutProductItemsInputObjectSchema = Schema
