import { z } from 'zod'
import { ProductReviewUpdateWithoutPicturesInputObjectSchema } from './ProductReviewUpdateWithoutPicturesInput.schema'
import { ProductReviewUncheckedUpdateWithoutPicturesInputObjectSchema } from './ProductReviewUncheckedUpdateWithoutPicturesInput.schema'
import { ProductReviewCreateWithoutPicturesInputObjectSchema } from './ProductReviewCreateWithoutPicturesInput.schema'
import { ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema } from './ProductReviewUncheckedCreateWithoutPicturesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewUpsertWithoutPicturesInput> = z
  .object({
    update: z.union([
      z.lazy(() => ProductReviewUpdateWithoutPicturesInputObjectSchema),
      z.lazy(
        () => ProductReviewUncheckedUpdateWithoutPicturesInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ProductReviewCreateWithoutPicturesInputObjectSchema),
      z.lazy(
        () => ProductReviewUncheckedCreateWithoutPicturesInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ProductReviewUpsertWithoutPicturesInputObjectSchema = Schema
