import { z } from 'zod'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProductReviewSummaryWhereUniqueInput.schema'
import { ProductReviewSummaryCreateInputObjectSchema } from './objects/ProductReviewSummaryCreateInput.schema'
import { ProductReviewSummaryUncheckedCreateInputObjectSchema } from './objects/ProductReviewSummaryUncheckedCreateInput.schema'
import { ProductReviewSummaryUpdateInputObjectSchema } from './objects/ProductReviewSummaryUpdateInput.schema'
import { ProductReviewSummaryUncheckedUpdateInputObjectSchema } from './objects/ProductReviewSummaryUncheckedUpdateInput.schema'

export const ProductReviewSummaryUpsertSchema = z.object({
  where: ProductReviewSummaryWhereUniqueInputObjectSchema,
  create: z.union([
    ProductReviewSummaryCreateInputObjectSchema,
    ProductReviewSummaryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductReviewSummaryUpdateInputObjectSchema,
    ProductReviewSummaryUncheckedUpdateInputObjectSchema,
  ]),
})
