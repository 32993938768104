import { z } from 'zod'
import { VariantWarehouseCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateWithoutWarehouseInput.schema'
import { VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema } from './VariantWarehouseUncheckedCreateWithoutWarehouseInput.schema'
import { VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema } from './VariantWarehouseCreateOrConnectWithoutWarehouseInput.schema'
import { VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInputObjectSchema } from './VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInput.schema'
import { VariantWarehouseCreateManyWarehouseInputEnvelopeObjectSchema } from './VariantWarehouseCreateManyWarehouseInputEnvelope.schema'
import { VariantWarehouseWhereUniqueInputObjectSchema } from './VariantWarehouseWhereUniqueInput.schema'
import { VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInputObjectSchema } from './VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInput.schema'
import { VariantWarehouseUpdateManyWithWhereWithoutWarehouseInputObjectSchema } from './VariantWarehouseUpdateManyWithWhereWithoutWarehouseInput.schema'
import { VariantWarehouseScalarWhereInputObjectSchema } from './VariantWarehouseScalarWhereInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.VariantWarehouseUpdateManyWithoutWarehouseNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema),
          z
            .lazy(() => VariantWarehouseCreateWithoutWarehouseInputObjectSchema)
            .array(),
          z.lazy(
            () =>
              VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUncheckedCreateWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      connectOrCreate: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseCreateOrConnectWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      upsert: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpsertWithWhereUniqueWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      createMany: z
        .lazy(
          () => VariantWarehouseCreateManyWarehouseInputEnvelopeObjectSchema,
        )
        .optional(),
      set: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      disconnect: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      delete: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      connect: z
        .union([
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema),
          z.lazy(() => VariantWarehouseWhereUniqueInputObjectSchema).array(),
        ])
        .optional(),
      update: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpdateWithWhereUniqueWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      updateMany: z
        .union([
          z.lazy(
            () =>
              VariantWarehouseUpdateManyWithWhereWithoutWarehouseInputObjectSchema,
          ),
          z
            .lazy(
              () =>
                VariantWarehouseUpdateManyWithWhereWithoutWarehouseInputObjectSchema,
            )
            .array(),
        ])
        .optional(),
      deleteMany: z
        .union([
          z.lazy(() => VariantWarehouseScalarWhereInputObjectSchema),
          z.lazy(() => VariantWarehouseScalarWhereInputObjectSchema).array(),
        ])
        .optional(),
    })
    .strict()

export const VariantWarehouseUpdateManyWithoutWarehouseNestedInputObjectSchema =
  Schema
