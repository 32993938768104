import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'
import { OrderOrderByWithRelationInputObjectSchema } from './OrderOrderByWithRelationInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponOrderByWithRelationInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    createdAt: z.lazy(() => SortOrderSchema).optional(),
    updatedAt: z.lazy(() => SortOrderSchema).optional(),
    couponId: z.lazy(() => SortOrderSchema).optional(),
    code: z.lazy(() => SortOrderSchema).optional(),
    type: z.lazy(() => SortOrderSchema).optional(),
    descriptionTr: z.lazy(() => SortOrderSchema).optional(),
    discount: z.lazy(() => SortOrderSchema).optional(),
    coin: z.lazy(() => SortOrderSchema).optional(),
    orderId: z.lazy(() => SortOrderSchema).optional(),
    v2CouponId: z.lazy(() => SortOrderSchema).optional(),
    order: z.lazy(() => OrderOrderByWithRelationInputObjectSchema).optional(),
  })
  .strict()

export const CouponOrderByWithRelationInputObjectSchema = Schema
