import { z } from 'zod'
import { TeleconsultCreateWithoutCaseInputObjectSchema } from './TeleconsultCreateWithoutCaseInput.schema'
import { TeleconsultUncheckedCreateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedCreateWithoutCaseInput.schema'
import { TeleconsultCreateOrConnectWithoutCaseInputObjectSchema } from './TeleconsultCreateOrConnectWithoutCaseInput.schema'
import { TeleconsultUpsertWithoutCaseInputObjectSchema } from './TeleconsultUpsertWithoutCaseInput.schema'
import { TeleconsultWhereUniqueInputObjectSchema } from './TeleconsultWhereUniqueInput.schema'
import { TeleconsultUpdateWithoutCaseInputObjectSchema } from './TeleconsultUpdateWithoutCaseInput.schema'
import { TeleconsultUncheckedUpdateWithoutCaseInputObjectSchema } from './TeleconsultUncheckedUpdateWithoutCaseInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.TeleconsultUpdateOneWithoutCaseNestedInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => TeleconsultCreateWithoutCaseInputObjectSchema),
        z.lazy(() => TeleconsultUncheckedCreateWithoutCaseInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => TeleconsultCreateOrConnectWithoutCaseInputObjectSchema)
      .optional(),
    upsert: z
      .lazy(() => TeleconsultUpsertWithoutCaseInputObjectSchema)
      .optional(),
    disconnect: z.boolean().optional(),
    delete: z.boolean().optional(),
    connect: z.lazy(() => TeleconsultWhereUniqueInputObjectSchema).optional(),
    update: z
      .union([
        z.lazy(() => TeleconsultUpdateWithoutCaseInputObjectSchema),
        z.lazy(() => TeleconsultUncheckedUpdateWithoutCaseInputObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const TeleconsultUpdateOneWithoutCaseNestedInputObjectSchema = Schema
