import { z } from 'zod'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'
import { PatientCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientCreateWithoutAcceptedConsentsInput.schema'
import { PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './PatientUncheckedCreateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateOrConnectWithoutAcceptedConsentsInput> =
  z
    .object({
      where: z.lazy(() => PatientWhereUniqueInputObjectSchema),
      create: z.union([
        z.lazy(() => PatientCreateWithoutAcceptedConsentsInputObjectSchema),
        z.lazy(
          () => PatientUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const PatientCreateOrConnectWithoutAcceptedConsentsInputObjectSchema =
  Schema
