import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { format } from 'date-fns'
import { useState } from 'react'
import { useLoading } from '../../components/GlobalLoading'
import { adminGatewayClient } from '../../service'
import { ScheduleProps } from './schedule.interface'

interface BtnDeleteScheduleProps {
  schedule: ScheduleProps | null
  fetchAvailableSlot: () => Promise<void>
  handleClose: () => void
}

interface ConfirmationDialogRawProps {
  schedule: ScheduleProps
  fetchAvailableSlot: () => Promise<void>
  handleClose: () => void
  showConfirmation: boolean
  setShowComfirmation: React.Dispatch<React.SetStateAction<boolean>>
}

export const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { increaseLoading, decreaseLoading } = useLoading()
  const {
    schedule,
    fetchAvailableSlot,
    handleClose,
    showConfirmation,
    setShowComfirmation,
  } = props
  const handleCancel = () => {
    setShowComfirmation(false)
  }

  const handleDelete = async () => {
    increaseLoading()
    try {
      await adminGatewayClient.schedule.deleteSchedule.mutate({
        id: schedule.id,
      })
      await fetchAvailableSlot()
      handleClose()
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      decreaseLoading()
      setShowComfirmation(false)
    }
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={showConfirmation}
    >
      <DialogContent dividers>
        <Typography
          variant="subtitle1"
          display={'flex'}
          alignItems={'center'}
          fontWeight={'500'}
          color={'#FF4D4F'}
          component={'div'}
        >
          <WarningIcon />
          &nbsp;Delete “{format(schedule.availableAt, 'd MMMM yyyy HH:mm')}”
          Slot?
        </Typography>

        <Typography variant="body1">
          Are you sure you want to delete it?
          <br />
          This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          background: '#E6E6E6',
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          autoFocus
          onClick={handleCancel}
          sx={{
            boxShadow: 'none',
            background: 'white',
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleDelete}
          sx={{
            boxShadow: 'none',
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const BtnDeleteSchedule = ({
  schedule,
  fetchAvailableSlot,
  handleClose,
}: BtnDeleteScheduleProps) => {
  const [showConfirmation, setShowComfirmation] = useState(false)
  if (!schedule || schedule.appointment) {
    return null
  }
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<DeleteIcon />}
        color="error"
        onClick={() => setShowComfirmation(true)}
        sx={{
          boxShadow: 'none',
          background: 'white',
        }}
      >
        Delete Slot
      </Button>
      <ConfirmationDialogRaw
        schedule={schedule}
        fetchAvailableSlot={fetchAvailableSlot}
        handleClose={handleClose}
        showConfirmation={showConfirmation}
        setShowComfirmation={setShowComfirmation}
      />
    </>
  )
}
