import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSumAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    patientId: z.literal(true).optional(),
    doctorId: z.literal(true).optional(),
    symptomDuration: z.literal(true).optional(),
    followupCaseId: z.literal(true).optional(),
    v2RecordId: z.literal(true).optional(),
  })
  .strict()

export const CaseSumAggregateInputObjectSchema = Schema
