import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { FloatFieldUpdateOperationsInputObjectSchema } from './FloatFieldUpdateOperationsInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { PaymentGatewaySchema } from '../enums/PaymentGateway.schema'
import { EnumPaymentGatewayFieldUpdateOperationsInputObjectSchema } from './EnumPaymentGatewayFieldUpdateOperationsInput.schema'
import { RefundStatusSchema } from '../enums/RefundStatus.schema'
import { EnumRefundStatusFieldUpdateOperationsInputObjectSchema } from './EnumRefundStatusFieldUpdateOperationsInput.schema'
import { ProcedureItemUncheckedUpdateManyWithoutRefundNestedInputObjectSchema } from './ProcedureItemUncheckedUpdateManyWithoutRefundNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.RefundUncheckedUpdateWithoutProductItemsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      amount: z
        .union([
          z.number(),
          z.lazy(() => FloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      currency: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      refundAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      paymentGateway: z
        .union([
          z.lazy(() => PaymentGatewaySchema),
          z.lazy(
            () => EnumPaymentGatewayFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional(),
      status: z
        .union([
          z.lazy(() => RefundStatusSchema),
          z.lazy(() => EnumRefundStatusFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      reason: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      orderId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      procedureItems: z
        .lazy(
          () =>
            ProcedureItemUncheckedUpdateManyWithoutRefundNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const RefundUncheckedUpdateWithoutProductItemsInputObjectSchema = Schema
