import { z } from 'zod'

export const CaseSummaryScalarFieldEnumSchema = z.enum([
  'id',
  'caseId',
  'createdAt',
  'updatedAt',
  'presentIllness',
  'peNote',
  'isTeleMedNotRecommend',
  'recommendation',
  'doctorFee',
  'doctorFeeDiscount',
  'commissionRate',
  'commissionPrice',
  'netDoctorFee',
  'doctorNote',
  'pharmacistNote',
  'followUpAppointmentId',
  'recommedNumberOfTreatment',
  'additionalSuggestedProcedure',
  'editedSuggestedProductItemAt',
  'editedSuggestedProductItemBy',
  'v2CaseSummaryId',
  'caseUrl',
])
