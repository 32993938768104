import { z } from 'zod'
import { BrandBannerWhereUniqueInputObjectSchema } from './BrandBannerWhereUniqueInput.schema'
import { BrandBannerUpdateWithoutBrandInputObjectSchema } from './BrandBannerUpdateWithoutBrandInput.schema'
import { BrandBannerUncheckedUpdateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedUpdateWithoutBrandInput.schema'
import { BrandBannerCreateWithoutBrandInputObjectSchema } from './BrandBannerCreateWithoutBrandInput.schema'
import { BrandBannerUncheckedCreateWithoutBrandInputObjectSchema } from './BrandBannerUncheckedCreateWithoutBrandInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.BrandBannerUpsertWithWhereUniqueWithoutBrandInput> =
  z
    .object({
      where: z.lazy(() => BrandBannerWhereUniqueInputObjectSchema),
      update: z.union([
        z.lazy(() => BrandBannerUpdateWithoutBrandInputObjectSchema),
        z.lazy(() => BrandBannerUncheckedUpdateWithoutBrandInputObjectSchema),
      ]),
      create: z.union([
        z.lazy(() => BrandBannerCreateWithoutBrandInputObjectSchema),
        z.lazy(() => BrandBannerUncheckedCreateWithoutBrandInputObjectSchema),
      ]),
    })
    .strict()

export const BrandBannerUpsertWithWhereUniqueWithoutBrandInputObjectSchema =
  Schema
