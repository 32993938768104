import { Box, Card, CardContent } from '@mui/material'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { EditSaveRouteBack } from '../../components/CustomReactAdmin/EditSaveRouteBack'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { adminGatewayClient, isTRPCClientError } from '../../service'
import { VariantInfoSection } from './create-edit-section/variantInfo'
import { VariantPictureSecion } from './create-edit-section/variantPicture'
import { transformProductVariant } from './transform'

const ProductVariantEditToolbar = () => {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton resource="productVariant" />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const ProductVariantEditForm = () => {
  const notify = useNotify()
  const record = useRecordContext()
  const handleActiveToggle = async (active: boolean) => {
    try {
      await adminGatewayClient.productVariant.setActive.mutate({
        id: Number(record.id),
        active: active,
      })
      notify(`Varaint is ${active ? 'activated' : 'inactivated'}`, {
        type: active ? 'success' : 'warning',
      })
    } catch (e) {
      if (isTRPCClientError(e)) {
        notify(e.message, { type: 'error' })
      }
    }
  }
  return (
    <SimpleForm toolbar={<ProductVariantEditToolbar />}>
      <VariantInfoSection
        title="Edit SKU"
        onChangeActiveToggle={(active) => handleActiveToggle(active)}
      />
      <VariantPictureSecion />
    </SimpleForm>
  )
}

export const ProductVariantEdit = () => (
  <EditSaveRouteBack
    transform={transformProductVariant}
    mutationMode="pessimistic"
  >
    <Card>
      <CardContent sx={{ p: 2 }}>
        <ProductVariantEditForm />
      </CardContent>
    </Card>
  </EditSaveRouteBack>
)
