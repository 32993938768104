import { z } from 'zod'
import { CarouselOrderByWithRelationInputObjectSchema } from './objects/CarouselOrderByWithRelationInput.schema'
import { CarouselWhereInputObjectSchema } from './objects/CarouselWhereInput.schema'
import { CarouselWhereUniqueInputObjectSchema } from './objects/CarouselWhereUniqueInput.schema'
import { CarouselCountAggregateInputObjectSchema } from './objects/CarouselCountAggregateInput.schema'
import { CarouselMinAggregateInputObjectSchema } from './objects/CarouselMinAggregateInput.schema'
import { CarouselMaxAggregateInputObjectSchema } from './objects/CarouselMaxAggregateInput.schema'
import { CarouselAvgAggregateInputObjectSchema } from './objects/CarouselAvgAggregateInput.schema'
import { CarouselSumAggregateInputObjectSchema } from './objects/CarouselSumAggregateInput.schema'

export const CarouselAggregateSchema = z.object({
  orderBy: z
    .union([
      CarouselOrderByWithRelationInputObjectSchema,
      CarouselOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: CarouselWhereInputObjectSchema.optional(),
  cursor: CarouselWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), CarouselCountAggregateInputObjectSchema])
    .optional(),
  _min: CarouselMinAggregateInputObjectSchema.optional(),
  _max: CarouselMaxAggregateInputObjectSchema.optional(),
  _avg: CarouselAvgAggregateInputObjectSchema.optional(),
  _sum: CarouselSumAggregateInputObjectSchema.optional(),
})
