import { z } from 'zod'
import { ConsentUpdateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUpdateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedUpdateWithoutAcceptedConsentsInput.schema'
import { ConsentCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentCreateWithoutAcceptedConsentsInput.schema'
import { ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema } from './ConsentUncheckedCreateWithoutAcceptedConsentsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ConsentUpsertWithoutAcceptedConsentsInput> = z
  .object({
    update: z.union([
      z.lazy(() => ConsentUpdateWithoutAcceptedConsentsInputObjectSchema),
      z.lazy(
        () => ConsentUncheckedUpdateWithoutAcceptedConsentsInputObjectSchema,
      ),
    ]),
    create: z.union([
      z.lazy(() => ConsentCreateWithoutAcceptedConsentsInputObjectSchema),
      z.lazy(
        () => ConsentUncheckedCreateWithoutAcceptedConsentsInputObjectSchema,
      ),
    ]),
  })
  .strict()

export const ConsentUpsertWithoutAcceptedConsentsInputObjectSchema = Schema
