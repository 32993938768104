import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.ExpressShippingAreaCreateWithoutShippingProviderInput> =
  z
    .object({
      postCode: z.string(),
    })
    .strict()

export const ExpressShippingAreaCreateWithoutShippingProviderInputObjectSchema =
  Schema
