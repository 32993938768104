import { z } from 'zod'
import { OldPatientWhereUniqueInputObjectSchema } from './objects/OldPatientWhereUniqueInput.schema'
import { OldPatientCreateInputObjectSchema } from './objects/OldPatientCreateInput.schema'
import { OldPatientUncheckedCreateInputObjectSchema } from './objects/OldPatientUncheckedCreateInput.schema'
import { OldPatientUpdateInputObjectSchema } from './objects/OldPatientUpdateInput.schema'
import { OldPatientUncheckedUpdateInputObjectSchema } from './objects/OldPatientUncheckedUpdateInput.schema'

export const OldPatientUpsertSchema = z.object({
  where: OldPatientWhereUniqueInputObjectSchema,
  create: z.union([
    OldPatientCreateInputObjectSchema,
    OldPatientUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    OldPatientUpdateInputObjectSchema,
    OldPatientUncheckedUpdateInputObjectSchema,
  ]),
})
