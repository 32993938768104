import { z } from 'zod'
import { ProductReviewSummaryUpdateInputObjectSchema } from './objects/ProductReviewSummaryUpdateInput.schema'
import { ProductReviewSummaryUncheckedUpdateInputObjectSchema } from './objects/ProductReviewSummaryUncheckedUpdateInput.schema'
import { ProductReviewSummaryWhereUniqueInputObjectSchema } from './objects/ProductReviewSummaryWhereUniqueInput.schema'

export const ProductReviewSummaryUpdateOneSchema = z.object({
  data: z.union([
    ProductReviewSummaryUpdateInputObjectSchema,
    ProductReviewSummaryUncheckedUpdateInputObjectSchema,
  ]),
  where: ProductReviewSummaryWhereUniqueInputObjectSchema,
})
