import { ContentCopy } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  Pagination,
  TextField,
  useNotify,
} from 'react-admin'
import { LocaleDateField } from '../../components/LocaleDateField'

const rowsPerPage = [5, 10, 20]

const ArticleListAction = () => {
  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Box sx={{ width: '100%', mt: 1, textAlign: 'right' }}>
        <CreateButton />
      </Box>
    </Box>
  )
}

export const ArticleList = () => {
  const notify = useNotify()

  return (
    <Box sx={{ p: 2, width: '100%', overflowX: 'auto' }}>
      <List
        disableSyncWithLocation
        actions={<ArticleListAction />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={rowsPerPage[2]}
        pagination={<Pagination rowsPerPageOptions={rowsPerPage} />}
      >
        <Datagrid
          size="small"
          rowClick="edit"
          sx={{
            '& .RaDatagrid-headerCell': {
              alignItems: 'baseline',
              whiteSpace: 'nowrap',
            },
            '& .RaDatagrid-rowCell': {
              whiteSpace: 'nowrap',
            },
          }}
        >
          <TextField source="id" label="ไอดี" sortable={false} />
          <LocaleDateField source="createdAt" showTime />
          <LocaleDateField source="updatedAt" showTime />
          <FunctionField
            label="Title (en,th)"
            render={({ titleTr }: { titleTr: { en: string; th: string } }) => {
              return (
                <div>
                  <p style={{ margin: '0.25rem 0' }}>TH: {titleTr.th ?? ''}</p>
                  <p style={{ margin: '0.25rem 0' }}>EN: {titleTr.en ?? ''}</p>
                </div>
              )
            }}
          />
          <FunctionField
            label="Link"
            render={(record: { id: number }) => (
              <Button
                variant="outlined"
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<ContentCopy />}
                onClick={() => {
                  const appPatientDomain = import.meta.env.NX_APP_PATIENT_DOMAIN
                  navigator.clipboard
                    .writeText(`${appPatientDomain}/article/${record.id}`)
                    .then(() => {
                      notify(`Copied to clipboard!`, {
                        type: 'success',
                      })
                    })
                    .catch((error) => {
                      console.error('Error copying to clipboard:', error)
                      notify('Failed to copy to clipboard. Please try again.', {
                        type: 'success',
                      })
                    })
                }}
              >
                Copy Link
              </Button>
            )}
            onClick={(e: { stopPropagation: () => void }) =>
              e.stopPropagation()
            }
          />
        </Datagrid>
      </List>
    </Box>
  )
}
