import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.DeliveryShippingProviderTrackingNumberCompoundUniqueInput> =
  z
    .object({
      shippingProvider: z.string(),
      trackingNumber: z.string(),
    })
    .strict()

export const DeliveryShippingProviderTrackingNumberCompoundUniqueInputObjectSchema =
  Schema
