import { z } from 'zod'
import { CaseSummaryUpdateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryUpdateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryUncheckedUpdateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryUncheckedUpdateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryCreateWithoutSuggestedProcedureItemsInput.schema'
import { CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema } from './CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/teleconsults'

const Schema: z.ZodType<Prisma.CaseSummaryUpsertWithoutSuggestedProcedureItemsInput> =
  z
    .object({
      update: z.union([
        z.lazy(
          () =>
            CaseSummaryUpdateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedUpdateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
      ]),
      create: z.union([
        z.lazy(
          () =>
            CaseSummaryCreateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
        z.lazy(
          () =>
            CaseSummaryUncheckedCreateWithoutSuggestedProcedureItemsInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const CaseSummaryUpsertWithoutSuggestedProcedureItemsInputObjectSchema =
  Schema
