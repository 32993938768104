import { z } from 'zod'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { ConsentUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema } from './ConsentUpdateOneRequiredWithoutAcceptedConsentsNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.AcceptedConsentUpdateWithoutPatientInput> = z
  .object({
    createdAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    updatedAt: z
      .union([
        z.date(),
        z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
      ])
      .optional(),
    consent: z
      .lazy(
        () =>
          ConsentUpdateOneRequiredWithoutAcceptedConsentsNestedInputObjectSchema,
      )
      .optional(),
  })
  .strict()

export const AcceptedConsentUpdateWithoutPatientInputObjectSchema = Schema
