import { z } from 'zod'
import { ProductCategoryWhereUniqueInputObjectSchema } from './objects/ProductCategoryWhereUniqueInput.schema'
import { ProductCategoryCreateInputObjectSchema } from './objects/ProductCategoryCreateInput.schema'
import { ProductCategoryUncheckedCreateInputObjectSchema } from './objects/ProductCategoryUncheckedCreateInput.schema'
import { ProductCategoryUpdateInputObjectSchema } from './objects/ProductCategoryUpdateInput.schema'
import { ProductCategoryUncheckedUpdateInputObjectSchema } from './objects/ProductCategoryUncheckedUpdateInput.schema'

export const ProductCategoryUpsertSchema = z.object({
  where: ProductCategoryWhereUniqueInputObjectSchema,
  create: z.union([
    ProductCategoryCreateInputObjectSchema,
    ProductCategoryUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProductCategoryUpdateInputObjectSchema,
    ProductCategoryUncheckedUpdateInputObjectSchema,
  ]),
})
