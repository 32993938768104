import { z } from 'zod'
import { FlashsaleUpdateInputObjectSchema } from './objects/FlashsaleUpdateInput.schema'
import { FlashsaleUncheckedUpdateInputObjectSchema } from './objects/FlashsaleUncheckedUpdateInput.schema'
import { FlashsaleWhereUniqueInputObjectSchema } from './objects/FlashsaleWhereUniqueInput.schema'

export const FlashsaleUpdateOneSchema = z.object({
  data: z.union([
    FlashsaleUpdateInputObjectSchema,
    FlashsaleUncheckedUpdateInputObjectSchema,
  ]),
  where: FlashsaleWhereUniqueInputObjectSchema,
})
