import { z } from 'zod'
import { OrderWhereUniqueInputObjectSchema } from './OrderWhereUniqueInput.schema'
import { OrderUpdateWithoutShippingProviderInputObjectSchema } from './OrderUpdateWithoutShippingProviderInput.schema'
import { OrderUncheckedUpdateWithoutShippingProviderInputObjectSchema } from './OrderUncheckedUpdateWithoutShippingProviderInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.OrderUpdateWithWhereUniqueWithoutShippingProviderInput> =
  z
    .object({
      where: z.lazy(() => OrderWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => OrderUpdateWithoutShippingProviderInputObjectSchema),
        z.lazy(
          () => OrderUncheckedUpdateWithoutShippingProviderInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const OrderUpdateWithWhereUniqueWithoutShippingProviderInputObjectSchema =
  Schema
