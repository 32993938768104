import DangerousIcon from '@mui/icons-material/Dangerous'
import { Box, Card, CardContent, Typography } from '@mui/material'

export const ForbiddenPage = () => {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            textAlign: 'center',
            my: 3,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <DangerousIcon sx={{ fontSize: 200 }} />
          </Box>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Forbidden
          </Typography>
          <Typography variant="h6">You don't have permission.</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
