import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/partners'

const Schema: z.ZodType<Prisma.CoverPictureMinAggregateInputType> = z
  .object({
    id: z.literal(true).optional(),
    createdAt: z.literal(true).optional(),
    updatedAt: z.literal(true).optional(),
    rank: z.literal(true).optional(),
    url: z.literal(true).optional(),
    partnerId: z.literal(true).optional(),
  })
  .strict()

export const CoverPictureMinAggregateInputObjectSchema = Schema
