import { z } from 'zod'
import { OrderArgsObjectSchema } from './OrderArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.CouponSelect> = z
  .object({
    id: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    couponId: z.boolean().optional(),
    code: z.boolean().optional(),
    type: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    discount: z.boolean().optional(),
    coin: z.boolean().optional(),
    order: z
      .union([z.boolean(), z.lazy(() => OrderArgsObjectSchema)])
      .optional(),
    orderId: z.boolean().optional(),
    v2CouponId: z.boolean().optional(),
  })
  .strict()

export const CouponSelectObjectSchema = Schema
