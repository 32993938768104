import { z } from 'zod'
import { ShippingTypeSchema } from '../enums/ShippingType.schema'
import { NestedIntNullableFilterObjectSchema } from './NestedIntNullableFilter.schema'
import { NestedEnumShippingTypeNullableFilterObjectSchema } from './NestedEnumShippingTypeNullableFilter.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumShippingTypeNullableWithAggregatesFilter> =
  z
    .object({
      equals: z
        .lazy(() => ShippingTypeSchema)
        .optional()
        .nullable(),
      in: z
        .lazy(() => ShippingTypeSchema)
        .array()
        .optional()
        .nullable(),
      notIn: z
        .lazy(() => ShippingTypeSchema)
        .array()
        .optional()
        .nullable(),
      not: z
        .union([
          z.lazy(() => ShippingTypeSchema),
          z.lazy(
            () =>
              NestedEnumShippingTypeNullableWithAggregatesFilterObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      _count: z.lazy(() => NestedIntNullableFilterObjectSchema).optional(),
      _min: z
        .lazy(() => NestedEnumShippingTypeNullableFilterObjectSchema)
        .optional(),
      _max: z
        .lazy(() => NestedEnumShippingTypeNullableFilterObjectSchema)
        .optional(),
    })
    .strict()

export const NestedEnumShippingTypeNullableWithAggregatesFilterObjectSchema =
  Schema
