import { z } from 'zod'
import { ConsentCreateInputObjectSchema } from './objects/ConsentCreateInput.schema'
import { ConsentUncheckedCreateInputObjectSchema } from './objects/ConsentUncheckedCreateInput.schema'

export const ConsentCreateOneSchema = z.object({
  data: z.union([
    ConsentCreateInputObjectSchema,
    ConsentUncheckedCreateInputObjectSchema,
  ]),
})
