import { z } from 'zod'
import { OrderStatusSchema } from '../enums/OrderStatus.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/orders'

const Schema: z.ZodType<Prisma.NestedEnumOrderStatusFilter> = z
  .object({
    equals: z.lazy(() => OrderStatusSchema).optional(),
    in: z
      .lazy(() => OrderStatusSchema)
      .array()
      .optional(),
    notIn: z
      .lazy(() => OrderStatusSchema)
      .array()
      .optional(),
    not: z
      .union([
        z.lazy(() => OrderStatusSchema),
        z.lazy(() => NestedEnumOrderStatusFilterObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const NestedEnumOrderStatusFilterObjectSchema = Schema
