import { z } from 'zod'
import { SortOrderSchema } from '../enums/SortOrder.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductReviewSummarySumOrderByAggregateInput> = z
  .object({
    id: z.lazy(() => SortOrderSchema).optional(),
    productId: z.lazy(() => SortOrderSchema).optional(),
    brandId: z.lazy(() => SortOrderSchema).optional(),
    overallRating: z.lazy(() => SortOrderSchema).optional(),
    qualityRating: z.lazy(() => SortOrderSchema).optional(),
    shippingRating: z.lazy(() => SortOrderSchema).optional(),
    packagingRating: z.lazy(() => SortOrderSchema).optional(),
    countForRating5: z.lazy(() => SortOrderSchema).optional(),
    countForRating4: z.lazy(() => SortOrderSchema).optional(),
    countForRating3: z.lazy(() => SortOrderSchema).optional(),
    countForRating2: z.lazy(() => SortOrderSchema).optional(),
    countForRating1: z.lazy(() => SortOrderSchema).optional(),
  })
  .strict()

export const ProductReviewSummarySumOrderByAggregateInputObjectSchema = Schema
