import { z } from 'zod'
import { ProcedurePictureOrderByWithRelationInputObjectSchema } from './objects/ProcedurePictureOrderByWithRelationInput.schema'
import { ProcedurePictureWhereInputObjectSchema } from './objects/ProcedurePictureWhereInput.schema'
import { ProcedurePictureWhereUniqueInputObjectSchema } from './objects/ProcedurePictureWhereUniqueInput.schema'
import { ProcedurePictureCountAggregateInputObjectSchema } from './objects/ProcedurePictureCountAggregateInput.schema'
import { ProcedurePictureMinAggregateInputObjectSchema } from './objects/ProcedurePictureMinAggregateInput.schema'
import { ProcedurePictureMaxAggregateInputObjectSchema } from './objects/ProcedurePictureMaxAggregateInput.schema'
import { ProcedurePictureAvgAggregateInputObjectSchema } from './objects/ProcedurePictureAvgAggregateInput.schema'
import { ProcedurePictureSumAggregateInputObjectSchema } from './objects/ProcedurePictureSumAggregateInput.schema'

export const ProcedurePictureAggregateSchema = z.object({
  orderBy: z
    .union([
      ProcedurePictureOrderByWithRelationInputObjectSchema,
      ProcedurePictureOrderByWithRelationInputObjectSchema.array(),
    ])
    .optional(),
  where: ProcedurePictureWhereInputObjectSchema.optional(),
  cursor: ProcedurePictureWhereUniqueInputObjectSchema.optional(),
  take: z.number().optional(),
  skip: z.number().optional(),
  _count: z
    .union([z.literal(true), ProcedurePictureCountAggregateInputObjectSchema])
    .optional(),
  _min: ProcedurePictureMinAggregateInputObjectSchema.optional(),
  _max: ProcedurePictureMaxAggregateInputObjectSchema.optional(),
  _avg: ProcedurePictureAvgAggregateInputObjectSchema.optional(),
  _sum: ProcedurePictureSumAggregateInputObjectSchema.optional(),
})
