import { z } from 'zod'
import { PatientCreateWithoutKycDataInputObjectSchema } from './PatientCreateWithoutKycDataInput.schema'
import { PatientUncheckedCreateWithoutKycDataInputObjectSchema } from './PatientUncheckedCreateWithoutKycDataInput.schema'
import { PatientCreateOrConnectWithoutKycDataInputObjectSchema } from './PatientCreateOrConnectWithoutKycDataInput.schema'
import { PatientWhereUniqueInputObjectSchema } from './PatientWhereUniqueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.PatientCreateNestedOneWithoutKycDataInput> = z
  .object({
    create: z
      .union([
        z.lazy(() => PatientCreateWithoutKycDataInputObjectSchema),
        z.lazy(() => PatientUncheckedCreateWithoutKycDataInputObjectSchema),
      ])
      .optional(),
    connectOrCreate: z
      .lazy(() => PatientCreateOrConnectWithoutKycDataInputObjectSchema)
      .optional(),
    connect: z.lazy(() => PatientWhereUniqueInputObjectSchema).optional(),
  })
  .strict()

export const PatientCreateNestedOneWithoutKycDataInputObjectSchema = Schema
