import { Box, Card, CardContent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from 'react-admin'
import { GoBackButton } from '../../components/FormInput/GoBackButon'
import { VariantInfoSection } from './create-edit-section/variantInfo'

const FlashsaleItemProductEditToolbar: FC<{
  onSetFlashsaleId: (id: number) => void
}> = ({ onSetFlashsaleId }) => {
  const record = useRecordContext()
  useEffect(() => {
    if (record && record.flashsaleId) {
      onSetFlashsaleId(record.flashsaleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'white',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <GoBackButton
          action="edit"
          resource="flashsaleProduct"
          id={record.flashsaleId}
        />
        <SaveButton alwaysEnable />
      </Box>
    </Toolbar>
  )
}

const FlashsaleItemProductEditForm: FC<{
  onSetFlashsaleId: (id: number) => void
}> = ({ onSetFlashsaleId }) => {
  const handleSetFlashsaleId = (id: number) => {
    onSetFlashsaleId(id)
  }
  return (
    <SimpleForm
      toolbar={
        <FlashsaleItemProductEditToolbar
          onSetFlashsaleId={handleSetFlashsaleId}
        />
      }
    >
      <VariantInfoSection title="Edit Flashsale item (product)" />
    </SimpleForm>
  )
}

export const FlashsaleItemProductEdit = () => {
  const [flashsaleId, setFlashsaleId] = useState<number>()

  return (
    <Edit
      mutationMode="pessimistic"
      component="div"
      redirect={() => `flashsaleProduct/${flashsaleId}`}
      sx={{
        alignSelf: 'center',
        paddingBottom: '2rem',
        width: '100%',
        maxWidth: '700px',
      }}
    >
      <Box>
        <Card>
          <CardContent sx={{ p: 2, borderRadius: '1rem' }}>
            <FlashsaleItemProductEditForm onSetFlashsaleId={setFlashsaleId} />
          </CardContent>
        </Card>
      </Box>
    </Edit>
  )
}
