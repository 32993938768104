import { z } from 'zod'
import { ProductVideoWhereUniqueInputObjectSchema } from './ProductVideoWhereUniqueInput.schema'
import { ProductVideoUpdateWithoutProductInputObjectSchema } from './ProductVideoUpdateWithoutProductInput.schema'
import { ProductVideoUncheckedUpdateWithoutProductInputObjectSchema } from './ProductVideoUncheckedUpdateWithoutProductInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductVideoUpdateWithWhereUniqueWithoutProductInput> =
  z
    .object({
      where: z.lazy(() => ProductVideoWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ProductVideoUpdateWithoutProductInputObjectSchema),
        z.lazy(
          () => ProductVideoUncheckedUpdateWithoutProductInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ProductVideoUpdateWithWhereUniqueWithoutProductInputObjectSchema =
  Schema
