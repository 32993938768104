import { z } from 'zod'
import { ProcedureCreateWithoutCategoriesInputObjectSchema } from './ProcedureCreateWithoutCategoriesInput.schema'
import { ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedCreateWithoutCategoriesInput.schema'
import { ProcedureCreateOrConnectWithoutCategoriesInputObjectSchema } from './ProcedureCreateOrConnectWithoutCategoriesInput.schema'
import { ProcedureUpsertWithoutCategoriesInputObjectSchema } from './ProcedureUpsertWithoutCategoriesInput.schema'
import { ProcedureWhereUniqueInputObjectSchema } from './ProcedureWhereUniqueInput.schema'
import { ProcedureUpdateWithoutCategoriesInputObjectSchema } from './ProcedureUpdateWithoutCategoriesInput.schema'
import { ProcedureUncheckedUpdateWithoutCategoriesInputObjectSchema } from './ProcedureUncheckedUpdateWithoutCategoriesInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/procedures'

const Schema: z.ZodType<Prisma.ProcedureUpdateOneRequiredWithoutCategoriesNestedInput> =
  z
    .object({
      create: z
        .union([
          z.lazy(() => ProcedureCreateWithoutCategoriesInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedCreateWithoutCategoriesInputObjectSchema,
          ),
        ])
        .optional(),
      connectOrCreate: z
        .lazy(() => ProcedureCreateOrConnectWithoutCategoriesInputObjectSchema)
        .optional(),
      upsert: z
        .lazy(() => ProcedureUpsertWithoutCategoriesInputObjectSchema)
        .optional(),
      connect: z.lazy(() => ProcedureWhereUniqueInputObjectSchema).optional(),
      update: z
        .union([
          z.lazy(() => ProcedureUpdateWithoutCategoriesInputObjectSchema),
          z.lazy(
            () => ProcedureUncheckedUpdateWithoutCategoriesInputObjectSchema,
          ),
        ])
        .optional(),
    })
    .strict()

export const ProcedureUpdateOneRequiredWithoutCategoriesNestedInputObjectSchema =
  Schema
