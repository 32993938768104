import { z } from 'zod'
import { ArticlePictureWhereUniqueInputObjectSchema } from './ArticlePictureWhereUniqueInput.schema'
import { ArticlePictureUpdateWithoutArticleInputObjectSchema } from './ArticlePictureUpdateWithoutArticleInput.schema'
import { ArticlePictureUncheckedUpdateWithoutArticleInputObjectSchema } from './ArticlePictureUncheckedUpdateWithoutArticleInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/patients'

const Schema: z.ZodType<Prisma.ArticlePictureUpdateWithWhereUniqueWithoutArticleInput> =
  z
    .object({
      where: z.lazy(() => ArticlePictureWhereUniqueInputObjectSchema),
      data: z.union([
        z.lazy(() => ArticlePictureUpdateWithoutArticleInputObjectSchema),
        z.lazy(
          () => ArticlePictureUncheckedUpdateWithoutArticleInputObjectSchema,
        ),
      ]),
    })
    .strict()

export const ArticlePictureUpdateWithWhereUniqueWithoutArticleInputObjectSchema =
  Schema
