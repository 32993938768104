import { z } from 'zod'
import { ProcedureWhereUniqueInputObjectSchema } from './objects/ProcedureWhereUniqueInput.schema'
import { ProcedureCreateInputObjectSchema } from './objects/ProcedureCreateInput.schema'
import { ProcedureUncheckedCreateInputObjectSchema } from './objects/ProcedureUncheckedCreateInput.schema'
import { ProcedureUpdateInputObjectSchema } from './objects/ProcedureUpdateInput.schema'
import { ProcedureUncheckedUpdateInputObjectSchema } from './objects/ProcedureUncheckedUpdateInput.schema'

export const ProcedureUpsertSchema = z.object({
  where: ProcedureWhereUniqueInputObjectSchema,
  create: z.union([
    ProcedureCreateInputObjectSchema,
    ProcedureUncheckedCreateInputObjectSchema,
  ]),
  update: z.union([
    ProcedureUpdateInputObjectSchema,
    ProcedureUncheckedUpdateInputObjectSchema,
  ]),
})
