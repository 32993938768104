import { z } from 'zod'

export const FlashsaleItemScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'active',
  'priority',
  'flashsaleId',
  'procedureId',
  'startAt',
  'endAt',
  'price',
  'priceAbsorbedBySkinX',
  'priceAbsorbedByPartner',
  'saleLimit',
  'sold',
])
